import type {
  Line,
  LineLength,
  AngleBetweenLines,
  Point,
} from "./shapesGeometryTypes.tsx";

import { calculateNextPoint } from "Components/tool-new/utils";

export const snapPosToLineLengthStep = (
  pos: { x: number; y: number },
  LINE_LENGTH_STEP: number
) => {
  const snappedPos = {
    x: Math.round(pos.x / LINE_LENGTH_STEP) * LINE_LENGTH_STEP,
    y: Math.round(pos.y / LINE_LENGTH_STEP) * LINE_LENGTH_STEP,
  };
  return snappedPos;
};

export const findClosestPoint = (
  pos1: Point,
  pos2: Point,
  pointerPos: Point,
  LINE_LENGTH_STEP: number,
  ANGLE_BETWEEN_LINES_STEP: number
) => {
  let closestPoint: Point | undefined;
  let minDistance = Infinity;

  for (
    let length1 = 0;
    length1 <
    2 * Math.max(pos1.x, pos1.y, pos2.x, pos2.y, pointerPos.x, pointerPos.y);
    length1 += LINE_LENGTH_STEP
  ) {
    for (
      let length2 = 0;
      length2 <
      2 * Math.max(pos1.x, pos1.y, pos2.x, pos2.y, pointerPos.x, pointerPos.y);
      length2 += LINE_LENGTH_STEP
    ) {
      for (
        let angle = 0;
        angle < 2 * Math.PI;
        angle += ANGLE_BETWEEN_LINES_STEP
      ) {
        const x = pos1.x + length1 * Math.cos(angle);
        const y = pos1.y + length1 * Math.sin(angle);
        const potentialPoint: Point = { x, y };

        const distanceToPointer = Math.sqrt(
          Math.pow(potentialPoint.x - pointerPos.x, 2) +
            Math.pow(potentialPoint.y - pointerPos.y, 2)
        );
        const distanceToPos2 = Math.sqrt(
          Math.pow(potentialPoint.x - pos2.x, 2) +
            Math.pow(potentialPoint.y - pos2.y, 2)
        );

        if (
          distanceToPos2 >= length2 - 0.1 * LINE_LENGTH_STEP &&
          distanceToPos2 <= length2 + 0.1 * LINE_LENGTH_STEP &&
          distanceToPointer < minDistance
        ) {
          minDistance = distanceToPointer;
          closestPoint = potentialPoint;
        }
      }
    }
  }

  return closestPoint;
};

// return the closest point to the new pos such that the distance between the two points is a multiple of length and the angle between the two points is a multiple of 5 degrees
export const snapNewPosToLengthMultiple = (
  prevPos: { x: number; y: number },
  newPos: { x: number; y: number },
  snapLength: number,
  snapAngle: number
) => {
  const dx = newPos.x - prevPos.x;
  const dy = newPos.y - prevPos.y;
  const dist = Math.sqrt(dx * dx + dy * dy);
  const angle = Math.atan2(dy, dx);

  // round to nearest multiple of 5 degrees
  const snapAngleInRad = (snapAngle * Math.PI) / 180;
  const snappedAngle = Math.round(angle / snapAngleInRad) * snapAngleInRad;
  // round to nearest multiple of length
  const snappedDist = Math.round(dist / snapLength) * snapLength;

  const snappedNewPos = {
    x: prevPos.x + snappedDist * Math.cos(snappedAngle),
    y: prevPos.y + snappedDist * Math.sin(snappedAngle),
  };
  return snappedNewPos;
};

export const endPosFromStartPosAndLength = (
  startPos: { x: number; y: number },
  length: number,
  angle: number
) => {
  const endPos = {
    x: startPos.x + length * Math.cos(angle),
    y: startPos.y + length * Math.sin(angle),
  };
  return endPos;
};

export const getLineLength = (
  pos1: { x: number; y: number },
  pos2: { x: number; y: number }
) => {
  const dx = pos2.x - pos1.x;
  const dy = pos2.y - pos1.y;
  const dist = Math.sqrt(dx * dx + dy * dy);
  return dist;
};

export function getSmallerAngle(line1: Line, line2: Line): number {
  // Calculate the vector for each line
  const vector1 = [line1.x2 - line1.x1, line1.y2 - line1.y1];
  const vector2 = [line2.x2 - line2.x1, line2.y2 - line2.y1];

  // Calculate the dot product of the two vectors
  const dotProduct = vector1[0] * vector2[0] + vector1[1] * vector2[1];

  // Calculate the magnitudes of the vectors
  const mag1 = Math.sqrt(vector1[0] * vector1[0] + vector1[1] * vector1[1]);
  const mag2 = Math.sqrt(vector2[0] * vector2[0] + vector2[1] * vector2[1]);

  // Calculate the cosine of the angle between the vectors
  const cosAngle = dotProduct / (mag1 * mag2);

  // Calculate the angle in radians
  const angle = Math.acos(cosAngle);

  // Convert radians to degrees
  const degrees = Math.round(angle * (180 / Math.PI));

  // Return the smaller angle
  return degrees;
}


export function getFullAngle(line1: Line, line2: Line): number {
  // Calculate the vector for each line
  const vector1 = [line1.x2 - line1.x1, line1.y2 - line1.y1];
  const vector2 = [line2.x2 - line2.x1, line2.y2 - line2.y1];

  let degrees = getSmallerAngle(line1, line2);

  // Check the orientation of the angle
  if (vector1[0] * vector2[1] - vector1[1] * vector2[0] < 0) {
    // If the cross product is negative, the angle is in the second half of the circle
    degrees = 360 - degrees;
  }
  // Round the result to the nearest integer
  degrees = Math.round(degrees);

  // Return the angle in the complete 360-degree range
  return degrees;
}

export const getAnglesBetweenLines = (lines: Line[], freeAngles, freeLineAngles): AngleBetweenLines[] => {
  const angles: AngleBetweenLines[] = [];
  const lineIds = lines.map((line) => line.id);

  for (let i = 0; i < lines.length - 1; i++) {
    const line1 = lines[i];
    const reversedCurrentLine = {
      ...line1,
      x1: line1.x2,
      y1: line1.y2,
      x2: line1.x1,
      y2: line1.y1,
    };
    const line2 = lines[i + 1];
    let angle = getSmallerAngle(reversedCurrentLine, line2);
    if ((line1.x1 === line1.x2 && line1.y1 === line1.y2) || (line2.x1 === line2.x2 && line2.y1 === line2.y2)) {
      angle = freeAngles[i]?.angle || 0;
    }
    else if (angle === null || isNaN(angle)) {
      angle = (freeAngles[i].angle || 0);
    }
    angles.push({
      id: lineIds[i],
      line1Id: line1.id,
      line2Id: line2.id,
      angle: angle,
    });
  }

  return angles;
};

export const getLinesLengthsForDrawing = (lines: Line[]): LineLength[] => {
  const lengths = [];

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    const length = getLineLength(
      { x: line.x1, y: line.y1 },
      { x: line.x2, y: line.y2 }
    );
    const roundedLength = Math.round(length);
    lengths.push({ id: line.id, length: roundedLength });
  }

  return lengths;
};

// calculates next points for lines wrt lineAngle if length of line is zero
const calcNewLinePoints = (line, freeLineAngles) =>{
  if (line.x1 === line.x2 && line.y1 === line.y2) {
    const newPoints = calculateNextPoint(
      {
        x : line.x1,
        y : line.y1,
      },
      freeLineAngles.find((line_angle) => line_angle.id === line.id).angle,
      1,
    )
    return {
      ...line,
      x2: newPoints.x,
      y2: newPoints.y,
    }
  }
  return line;
}

export const rotateLineAroundPoint = (
  line1: line,
  line2: Line,
  angle: number,
  prevAngle: number,
  freeLineAngles: any,
) => {
  
  const dx = line2.x2 - line2.x1;
  const dy = line2.y2 - line2.y1;
  const dist = Math.sqrt(dx * dx + dy * dy);
  const lineAngle = Math.atan2(dy, dx);

    line2 = calcNewLinePoints(line2, freeLineAngles)
    line1 = calcNewLinePoints(line1, freeLineAngles)
  let changeInAngle = (angle - prevAngle);
  const SmallAngle = getSmallerAngle(line1, line2)
  const fullAngle = getFullAngle(line1, line2)

  // Small angle gives the smaller angle between the two lines, while full angle gives complete angle (360)
  // Comparing both angles tells whether the smaller angle is above the axis or below
  // from this we decide if the change in angle is positive or negative
  if(fullAngle === SmallAngle){
    changeInAngle = -(changeInAngle);
  }

  let newLineAngle = lineAngle + (changeInAngle * (Math.PI / 180));

  const newLinePos = {
    x: line2.x1 + dist * Math.cos(newLineAngle),
    y: line2.y1 + dist * Math.sin(newLineAngle),
  };

  const newLine = {
    ...line2,
    x2: newLinePos.x,
    y2: newLinePos.y,
  };

  return [newLine, changeInAngle];
};


export function calculateSecondLinePoint(line, angle) {

  // Convert angle to radians
  const theta = angle * (Math.PI / 180);

  // Calculate new coordinates
  const x3 = line.x1 + Math.cos(theta) * (line.x2 - line.x1) - Math.sin(theta) * (line.y2 - line.y1);
  const y3 = line.y1 + Math.sin(theta) * (line.x2 - line.x1) + Math.cos(theta) * (line.y2 - line.y1);

  return {x1: line.x2, y1:line.y2, x2: x3, y2: y3 };
}

export const CalculateFreeShapeLineAngle = (lines, freeLineAngles) => {
  const LineAngles = [];
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];

    const dx = line.x2 - line.x1;
    const dy = line.y2 - line.y1;
    let lineAngle = Math.atan2(dy, dx);
    lineAngle = lineAngle * (180 / Math.PI).toFixed(2);

    // If line length is zero, instead of calculating new line angle, use the previous line angle
    if(dx === 0 && dy === 0){
      lineAngle = freeLineAngles[i]?.angle;
    }
    LineAngles.push({
      id: line.id,
      angle: lineAngle,
    });
  }
  return LineAngles;
};
