import React, { useCallback, useState, useEffect } from "react";
import { Circle } from "react-konva";

const CornerEdgeController = (props) => {
  const {
    lines,
    onCornerEdgeSelected,
    onEdgeDragStart,
    onCornerEdgeDragMove: onEdgeDragged,
    onEdgeDragEnd,
    scale,
    setIsEdgeHovered,
  } = props;

  const [hoveredEdgeId, setHoveredEdgeId] = useState(null);
  const [cornerEdges, setCornerEdges] = useState([]);

  const handleEdgeSelected = useCallback(
    (edgeId: string) => (e: any) => {
      e.cancelBubble = true;
      onCornerEdgeSelected(edgeId);
    },
    [onCornerEdgeSelected, lines]
  );

  useEffect(() => {
    if (lines.length > 0) {
      if (lines.length === 1) {
        const newLines = [
          { id: "_left_point", x: lines[0].x1, y: lines[0].y1 },
          {
            id: "_right_point",
            x: lines[0].x2,
            y: lines[0].y2,
          },
        ];
        setCornerEdges(newLines);
      } else {
        const lastLine = lines.length - 1;
        const newLines = [
          { id: lines[0].id, x: lines[0].x1, y: lines[0].y1 },
          {
            id: lines[lastLine].id,
            x: lines[lastLine].x2,
            y: lines[lastLine].y2,
          },
        ];
        setCornerEdges(newLines);
      }
    }
  }, [lines]);

  return (
    <>
      {cornerEdges.map((edge) => (
        <Circle
          key={edge.id + "edgeController"}
          id={edge.id}
          x={edge.x}
          y={edge.y}
          radius={hoveredEdgeId === edge.id ? 8 / scale : 5 / scale}
          fill={hoveredEdgeId === edge.id ? "#00B38F" : "#374051"}
          opacity={hoveredEdgeId === edge.id ? 1 : 0.8}
          draggable
          onClick={handleEdgeSelected(edge.id)}
          onTap={handleEdgeSelected(edge.id)}
          onDragStart={(e: any) => {
            e.cancelBubble = true;
            e.evt.preventDefault();
            const edgeId = e.target.id();
            onEdgeDragStart(cornerEdges.find((edge) => edge.id === edgeId));
          }}
          onDragMove={(e: any) => {
            e.evt.preventDefault();
            const edgeId = e.target.id();
            onEdgeDragged(cornerEdges.find((edge) => edge.id === edgeId));
          }}
          onDragEnd={() => {
            onEdgeDragEnd();
          }}
          onMouseEnter={() => {
            setHoveredEdgeId(edge.id);
            setIsEdgeHovered(true);
          }}
          onMouseLeave={() => {
            setHoveredEdgeId(null);
            setIsEdgeHovered(false);
          }}
        />
      ))}
    </>
  );
};
export default CornerEdgeController;
