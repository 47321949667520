import React, { useRef, useState, useEffect, useMemo } from "react";
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { removeBgBorder } from "Constants/general";
import at_icon_1 from "Assets/img/cages/at_1.svg";
import at_icon_2 from "Assets/img/cages/at_2.svg";

import {
  lastPilesWithoutPrice,
  lastPilesWithPrice,
  pilesWithoutPrice,
  pilesWithPrice,
} from "Constants/pile-constants";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getCurrentOrderPiles,
} from "Redux/middlwares/order";

import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import InfiniteScroll from "react-infinite-scroll-component";
import BarLoader from "react-bar-loader";
import DraggableTableRows from "./DraggableTableRows";
import StaticTableRow from "./StaticTableRows";
import { PILE_SUBCLASS_TYPE } from "Constants/pile-constants";

import { getOrderStats } from "Services/order";
import "../index.scss";

const PilesTable = ({
  priceShow,
  openItemModal,
  selectedTool,
  setSelectedTool,
  handleImagePreview,
  pilesData,
  setPilesData,
  copyOrderItem,
  pilePrice,
  edit,
  editOrderId,
  checkRowNums,
  handleUpdate,
  deleteTool,
  scrollToElement,
}: any) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["newOrder", "common", "measurements", "tool"]);
  const currentOrderPiles = useAppSelector(state => state.pile.currentOrderPiles);
  const currentOrderPilePage = useAppSelector(state => state.pile.orderPilePage);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  const nextOrderPilesPage = useAppSelector((state) => state.pile.orderPilePage);

  const [activeId, setActiveId] = useState();
  const [activeIndex, setActiveIndex] = useState();
  const [hasMore, setHasMore] = useState(false);

  const [isMouseOver, setIsMouseOver] = useState(false);
  const [mousePosition, setMousePosition] = useState(0);
  const [inProgress, setInProgress] = useState(false);

  const [pileTons, setPileTons] = useState(0);
  const [pileUnits, setPileUnits] = useState(0);

  const PileTableRef = useRef(null);
  const PilePositionRef = useRef(null);

  useEffect(() => {
    getOrderStats(PILE_SUBCLASS_TYPE, editOrderId)
      .then((res) => {
        setPileUnits(res.data.total_quantity)
        setPileTons((res.data.total_weight/1000).toFixed(2))
      });
  }, [currentOrderPiles, editOrderId]);

  useEffect(() => {
    if (currentOrderPiles) {
      setPilesData(currentOrderPiles);
      if (currentOrderPilePage !== false) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  }, [currentOrderPiles]);


  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    const row = pilesData.find((original) => original.id === activeId);
    return row;
  }, [activeId]);

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  function handleDragStart(event) {
    setActiveId(event.active.id);
    const activeRow = pilesData.filter((data) => data.id === event.active.id);
    const Index = pilesData.indexOf(activeRow[0]);
    setActiveIndex(Index);
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    const activeRow = pilesData.filter((data) => data.id === active.id);
    const overRow = pilesData.filter((data) => data.id === over.id);
    if (active.id !== over.id) {
      let oldIndex;
      let newIndex;
      let moveData;
      setPilesData((data) => {
        oldIndex = pilesData.indexOf(activeRow[0]);
        newIndex = pilesData.indexOf(overRow[0]);
        moveData = arrayMove(data, oldIndex, newIndex);
        return moveData;
      });
      checkRowNums(oldIndex, newIndex, moveData, PILE_SUBCLASS_TYPE);
    }
    setActiveId(null);
    setActiveIndex(null);
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  const onloadFunction = async () => {
    try {
      if (nextOrderPilesPage !== false && nextOrderPilesPage > 1) {
        if (edit) {
          await dispatch(getCurrentOrderPiles(edit, editOrderId, nextOrderPilesPage));
        } else {
          await dispatch(getCurrentOrderPiles(edit, 0, nextOrderPilesPage));
        }
      }
    } catch (error) {
      setHasMore(false);
    }
  };

  useEffect(()=>{
    onloadFunction();
    scrollToElement();
  }, [])

  useEffect(() => {
    const icon = PilePositionRef.current;
    const table = PileTableRef.current;
    if (isMouseOver && icon && table) {
      const rect = icon.getBoundingClientRect();
      const top = rect.top + window.pageYOffset;
      const tab = table.getBoundingClientRect();
      const tabtop = tab.top + window.pageYOffset;
      setMousePosition(top - tabtop);
    }
  }, [isMouseOver]);

  return (
    <section id="cages__table" className="order__tables mt-xl-4">
      <div className="container p-0">
        <div className="table__wrapper">
          {/* <!-- table header  --> */}
          <div className="table__header d-flex flex-row">
            {/* <!-- table title  --> */}
            <div className="table__title d-flex align-items-center justify-content-center">
              <h2 className="text-center text-white text-uppercase m-0">
                {t("Cages")}
              </h2>
            </div>
            <div
              className="table__tit__btn d-flex align-items-center justify-content-center"
              style={{ cursor: "pointer" }}
              onClick={() => openItemModal(3)}
            >
              <span className="text-white bg-danger text-center text-uppercase">
                {t("add new cage", { ns: "newOrder" })}
              </span>
            </div>
          </div>
          {/* <!-- real table  --> */}
          <DndContext
            sensors={sensors}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
          >
            <table
              className={
                "table table mb-0 custom-tool-table " +
                (rightToLeft ? "rightToLeft" : "")
              }
              style={{ position: "relative" }}
              ref={PileTableRef}
            >
              {/* <!-- table head title section  --> */}
              <thead>
                <tr style={priceShow ? pilesWithPrice : pilesWithoutPrice}>
                  <th
                    scope="col"
                    className={
                      "text-capitalize d-flex justify-content-center align-items-center p-0 " +
                      (rightToLeft ? "dark-border-right" : "dark-border-left")
                    }
                  >
                    <span className="th_norm">
                      {t("row", { ns: "common" })}
                    </span>
                  </th>
                  <th
                    scope="col"
                    className={
                      "text-capitalize d-flex justify-content-center align-items-center p-0 " +
                      (rightToLeft ? "dark-border-right" : "dark-border-left")
                    }
                  >
                    <span className="th_norm">
                      {t("sketch", { ns: "newOrder" })}
                    </span>
                  </th>
                  <th
                    scope="col"
                    className={
                      "text-capitalize d-flex align-items-center justify-content-start text-center " +
                      (rightToLeft ? "dark-border-right" : "dark-border-left")
                    }
                  >
                    <span className="th_norm">
                      {t("Name", { ns: "newOrder" })}
                    </span>
                  </th>
                  <th
                    scope="col"
                    className={
                      "text-capitalize d-flex align-items-center justify-content-around text-center sm__wrap p-0 " +
                      (rightToLeft
                        ? "dark-border-right pr-3"
                        : "dark-border-left pl-3")
                    }
                  >
                    <span className="d-flex align-items-center justify-content-start span_50 th_norm ">
                      <p>{t("diameter", { ns: "common" })}</p>
                    </span>

                    <span className="d-flex align-items-center justify-content-start span_50 th_norm ">
                      <p>{t("length", { ns: "common" })}</p>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className={
                      "text-capitalize d-flex align-items-center justify-content-start text-center sm__wrap p-0 " +
                      (rightToLeft
                        ? "dark-border-right pr-3"
                        : "dark-border-left pl-3")
                    }
                  >
                    <span
                      style={{
                        width: "45%",
                        textAlign: rightToLeft ? "right" : "left",
                      }}
                    >
                      <img
                        src={at_icon_1}
                        style={{
                          width: "35px",
                          height: "24px",
                        }}
                        alt="@ 1"
                      />
                    </span>

                    <span
                      style={{
                        width: "45%",
                        textAlign: rightToLeft ? "right" : "left",
                      }}
                    >
                      <img
                        src={at_icon_2}
                        style={{
                          width: "35px",
                          height: "24px",
                        }}
                        alt="@ 2"
                      />
                    </span>

                    <span
                      style={{
                        width: "10%",
                        textAlign: rightToLeft ? "right" : "left",
                      }}
                      className="th_bold"
                    >
                      {t("edge", { ns: "common" })}
                    </span>
                  </th>
                  <th
                    scope="col"
                    className={
                      "text-capitalize d-flex align-items-center justify-content-center text-center p-0 " +
                      (rightToLeft ? "dark-border-right" : "dark-border-left")
                    }
                  >
                    <span
                      className={
                        "d-flex justify-content-center align-item-center p-0 th_norm "
                      }
                    >
                      {t("extras", { ns: "common" })}
                    </span>
                  </th>
                  <th
                    scope="col"
                    className={
                      "text-capitalize d-flex align-items-center justify-content-start text-center th_norm " +
                      (rightToLeft ? "dark-border-right" : "dark-border-left")
                    }
                  >
                    {t("quantity", { ns: "common" })}
                  </th>
                  <th
                    scope="col"
                    className="text-capitalize dark-border-right dark-border-left "
                    style={{ padding: "0px" }}
                  >
                    <div className="two__rows_sec">
                      <div className="top__sec">
                        <span className="th_norm">
                          {t("weight", { ns: "common" })}
                        </span>
                      </div>
                      <div
                        className={
                          "d-flex justify-content-start align-items-center bottom_sec " +
                          (rightToLeft ? "pr-3" : "pl-3")
                        }
                      >
                        <span
                          className={"th_norm "}
                          style={{
                            width: "50%",
                            textAlign: rightToLeft ? "right" : "left",
                          }}
                        >
                          {t("unit", { ns: "common" })}
                        </span>
                        <span
                          className="th_norm"
                          style={{
                            width: "50%",
                            textAlign: rightToLeft ? "right" : "left",
                          }}
                        >
                          {t("total", { ns: "common" })}
                        </span>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              {/* <!-- table body data section  --> */}
              <tbody
                style={
                  currentOrderPiles.length > 9
                    ? {
                        height: "720px",
                        overflow: "auto",
                        display: "block",
                      }
                    : {}
                }
                id="scrollablePilesTable"
              >
                <SortableContext
                  items={pilesData}
                  strategy={verticalListSortingStrategy}
                >
                  <InfiniteScroll
                    dataLength={pilesData.length}
                    next={onloadFunction}
                    hasMore={hasMore}
                    loader={<BarLoader color="#F84343" height="4" />}
                    scrollableTarget="scrollablePilesTable"
                  >
                    {/* <!-- table data row   --> */}
                    {pilesData.length > 0 &&
                      pilesData.map((itemCage, index) => (
                        <DraggableTableRows
                          index={index}
                          key={itemCage.id}
                          id={itemCage.id}
                          itemCage={itemCage}
                          priceShow={priceShow}
                          handleImagePreview={handleImagePreview}
                          setSelectedTool={setSelectedTool}
                          setIsMouseOver={setIsMouseOver}
                          PilePositionRef={PilePositionRef}
                        />
                      ))}
                  </InfiniteScroll>
                </SortableContext>
              </tbody>
              <tbody className="last">
                <tr
                  style={priceShow ? lastPilesWithPrice : lastPilesWithoutPrice}
                >
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={removeBgBorder}></td>
                  <td className="justify-content-center p-0">{pileUnits}</td>
                  <td className="justify-content-center p-0" colSpan={2}>
                    {pileTons}
                  </td>
                </tr>
                <tr
                  style={priceShow ? lastPilesWithPrice : lastPilesWithoutPrice}
                >
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={removeBgBorder}></td>
                  <td className="justify-content-center p-0">
                    {t("units", { ns: "common" })}
                  </td>
                  <td
                    className="justify-content-center p-0"
                    colSpan={2}
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("weight", { ns: "common" })} (
                    {t("ton", { ns: "measurements" })})
                  </td>
                </tr>
              </tbody>
              {isMouseOver && !inProgress && (
                <div
                  className="dropdown-content"
                  style={
                    rightToLeft
                      ? { top: `${mousePosition}px`, right: "45px" }
                      : { top: `${mousePosition}px`, left: "45px" }
                  }
                  onMouseEnter={(e) => {
                    setIsMouseOver(true);
                  }}
                  onMouseLeave={() => setIsMouseOver(false)}
                >
                  <div
                    className="drop-item"
                    onClick={async () => {
                      setIsMouseOver(false);
                      setInProgress(true);
                      copyOrderItem().then(() => {
                        setInProgress(false);
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      className={
                        "item-icon " + (rightToLeft ? "item-ml" : "item-mr")
                      }
                      icon="copy"
                    ></FontAwesomeIcon>
                    <span>{t("Duplicate", { ns: "newOrder" })}</span>
                  </div>
                  <div className="drop-item" onClick={handleUpdate}>
                    <FontAwesomeIcon
                      className={
                        "item-icon " + (rightToLeft ? "item-ml" : "item-mr")
                      }
                      icon="pencil-alt"
                    ></FontAwesomeIcon>
                    <span>{t("edit", { ns: "common" })}</span>
                  </div>
                  <div className="drop-item" onClick={deleteTool}>
                    <FontAwesomeIcon
                      className={
                        "item-icon " + (rightToLeft ? "item-ml" : "item-mr")
                      }
                      icon="trash"
                    ></FontAwesomeIcon>
                    <span>{t("Delete", { ns: "common" })}</span>
                  </div>
                </div>
              )}
            </table>
            <DragOverlay>
              {activeId && (
                <table
                  className={
                    "table table mb-0 custom-tool-table" +
                    (rightToLeft ? " rightToLeft" : "")
                  }
                >
                  <tbody>
                    <StaticTableRow
                      id={selectedRow.id}
                      index={activeIndex}
                      itemCage={selectedRow}
                      priceShow={priceShow}
                      setSelectedTool={setSelectedTool}
                      handleImagePreview={handleImagePreview}
                    />
                  </tbody>
                </table>
              )}
            </DragOverlay>
          </DndContext>
        </div>
      </div>
    </section>
  );
};

export default PilesTable;
