import { useState, useRef } from "react";
import { ContactFilterTypes, CONTACTS_ENUM } from "Constants/contacts";
import { isMobile, isBrowser } from "react-device-detect";
import pen from "../../assets/img/order__page/pen.svg";
import editIcon from "../../assets/img/save_export/edit.svg";
import { Overlay, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "Redux/hooks";
import "./icons.scss";

function ContactTableRow({
  number,
  contact,
  setActiveContact,
  handleEditContact,
  mobileFilter = 1,
}: any) {
  const { t } = useTranslation(["common", "myOrder", "newOrder"]);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const [showEditTooltip, setShowEditTooltip] = useState(false);
  const targetEdit = useRef(null);

  const roleString = (role: number) => {
    switch (role) {
      case CONTACTS_ENUM.SITE_MAN:
        return t("site man", { ns: "newOrder" });
      case CONTACTS_ENUM.SITE_MANAGER:
        return t("site manager", { ns: "newOrder" });
      case CONTACTS_ENUM.PROJECT_MANAGER:
        return t("Project Manager", { ns: "newOrder" });
      case CONTACTS_ENUM.OTHER:
        return t("other", { ns: "newOrder" });
    }
  };

  return (
    <>
      {isBrowser && (
        <tr>
          <td>
            <p className="para text-capitalize">{number}</p>
          </td>
          <td>
            <p className="para text-capitalize">{contact.full_name}</p>
          </td>
          <td>
            <p className="para text-capitalize">{roleString(contact.role)}</p>
          </td>
          <td>
            <p className="para text-capitalize">{contact.phone}</p>
          </td>
          <td
            className={rightToLeft ? "d-flex justify-content-center":""}
            style={
              rightToLeft
                ? { paddingRight: "3px", borderRight: "2px solid #f3f3f3" }
                : { paddingRight: "0px" }
            }
          >
            <button
              className="export__icons"
              onClick={() => {
                setActiveContact(contact);
                handleEditContact();
              }}
              ref={targetEdit}
              onMouseEnter={() => setShowEditTooltip(true)}
              onMouseLeave={() => setShowEditTooltip(false)}
            >
              <img className="m__icon" src={editIcon} alt="edit icon" />
            </button>
            <Overlay
              target={targetEdit.current}
              show={showEditTooltip}
              placement="top"
            >
              {(props) => (
                <Tooltip id="overlay-example-4" {...props}>
                  {t("edit", { ns: "common" })}
                </Tooltip>
              )}
            </Overlay>
          </td>
        </tr>
      )}
      {isMobile && (
        <tr>
          <td>
            <p className="para">{number}</p>
          </td>
          <td>
            <p className="para text-capitalize">{contact.full_name}</p>
          </td>
          <td>
            <p className="para bold">
              {mobileFilter === ContactFilterTypes.phone
                ? contact.phone
                : roleString(contact.role)}
            </p>
          </td>
          <td className="d-flex alingn-items-center justify-content-center">
            <span
              className="icon__wrap mr-0"
              onClick={() => {
                setActiveContact(contact);
                handleEditContact();
              }}
            >
              <img src={pen} alt="eye icon" />
            </span>
          </td>
        </tr>
      )}
    </>
  );
}

export default ContactTableRow;
