import React from "react";
import emtpySet from "Assets/img/icon/empty-set-mathematical-symbol.png";
import email from "Assets/img/icon/email.png";
import cageCircle from "Assets/img/icon/cage-circle.svg";
import cageLength from "Assets/img/icon/cage-length.svg";
import { useAppSelector } from "Redux/hooks";

import { useSortable } from "@dnd-kit/sortable";

import "../index.styled.scss";

const StaticPilesCard = (props) => {
  const { item, index, edit } = props;

  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  const { isDragging } = useSortable({
    id: props.id,
  });

  return (
    <div
      className={"cardItem_pile static-table_row" + (edit ? " active":"")}
      key={"defaultNets" + index}
      style={
        !isDragging
          ? {
              cursor: "grabbing",
              position: "relative",
            }
          : { cursor: "grab", position: "relative" }
      }
    >
      <div className="d-flex flex-column">
        {/* D and L */}
        <div className="card-top-sect dark-border_btm">
          <div className="top-row">
            <img
              src={cageCircle}
              alt="Cage Circle"
              className={rightToLeft ? "pl-1" : "pr-1"}
            />
            <p>{item.hole_diameter}</p>
          </div>
          <div className="top-row">
            <p style={{ fontWeight: "400" }}>L</p>
            <span style={{ fontWeight: "400", margin: "0px 1px" }}>=</span>
            <p>{item.l3_length + item.l2_length + item.l1_length}</p>
          </div>
        </div>
        <div className="card-bottom-sect">
          {/* Card Bottom Left section */}
          <div
            className={
              "card-bottom-left-sect " +
              (rightToLeft ? "bdr-left" : "bdr-right")
            }
          >
            {/* first row */}
            <div className="left-sect-row-bw border__bottom">
              {/* Horizontal Bars */}
              <div className="special__pad left-sect-row">
                <div className={rightToLeft ? "ml-1" : "mr-1"}>
                  <img src={cageLength} alt="cage length icon" />
                </div>
                <p>
                  {item.internal_amount &&
                  item.external_amount &&
                  item.middle_amount
                    ? item.internal_amount +
                      item.external_amount +
                      item.middle_amount
                    : item.internal_amount && item.external_amount
                    ? item.internal_amount + item.external_amount
                    : item.base_amount}
                </p>
              </div>
              {/* Horizontal Bars -  diameter */}
              <div className="special__pad left-sect-row">
                <div className={rightToLeft ? "ml-1" : "mr-1"}>
                  <img src={emtpySet} alt="empty set icon" />
                </div>
                {item.internal_amount ||
                item.middle_amount ||
                item.external_amount ? (
                  <p style={{ fontSize: "7px" }}>mixed</p>
                ) : (
                  <p>{item.base_diameter.steel_diameter}</p>
                )}
              </div>
            </div>
            {/* second row */}
            <div className="left-sect-row-bw border__bottom">
              {/* gap */}
              <div className="special__pad left-sect-row">
                <div className={rightToLeft ? "ml-1" : "mr-1"}>
                  <img src={emtpySet} alt="empty set icon" />
                </div>
                <p>{item.spiral_wire_diameter.steel_diameter}</p>
              </div>
              {/* diameter */}
              <div className="special__pad left-sect-row">
                <div className={rightToLeft ? "ml-1" : "mr-1"}>
                  <img src={email} alt="email icon" />
                </div>
                <p>{item.l2_gap}</p>
              </div>
            </div>
            {/* third row */}
            <div className="left-sect-row-bw">
              {/* gap */}
              <div className="special__pad left-sect-row">
                <div className={rightToLeft ? "ml-1" : "mr-1"}>
                  <img src={emtpySet} alt="empty set icon" />
                </div>
                <p>{item.spiral_wire_diameter.steel_diameter}</p>
              </div>
              {/* diameter */}
              <div className="special__pad left-sect-row">
                <div className={rightToLeft ? "ml-1" : "mr-1"}>
                  <img src={email} alt="email icon" />
                </div>
                <p>{item.l3_gap}</p>
              </div>
            </div>
          </div>
          {/* Card Bottom Right Section */}
          <div className="card-bottom-right-sect">
            {/* gap */}
            <div
              className="section-style border__bottom"
              style={{ height: "27px", marginTop: "1px" }}
            >
              <p style={{ paddingBottom: "1px" }}>{item.l1_length}</p>
            </div>
            <div
              className="section-style border__bottom"
              style={{ height: "27px", marginTop: "1px" }}
            >
              <p style={{ paddingBottom: "1px" }}>{item.l2_length}</p>
            </div>
            <div
              className="section-style align-items-center"
              style={{ height: "27px" }}
            >
              <p>{item.l3_length}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaticPilesCard;
