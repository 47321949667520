import CrossIcon from "../../../assets/img/order-details/cross.svg";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "Redux/hooks";
import { sendEmailData } from "Services/order-pdf";
import { useState } from "react";
import "./index.scss";
import { timeout_secs } from "Constants/alert-modal";

const Reciever = {
  Supplier: 1,
  Coworker: 2,
  Other: 3,
};

const Customer_Type = {
  ExistingCustomer: true,
  NewCustomer: false,
};

function SendbyMail({...props}) {
  const {
    documentUrl,
    orderId,
    userData,
    setResponseMsgModalShow,
    setResponseStatus,
    setResponseMsg,
    setShareStatestoInitial,
    setDocumentUrl,
    changeRouteOnshare,
  } = props;

  const { t } = useTranslation(["emailOrder"]);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [recieverRole, setRecieverRole] = useState("");
  const [customerType, setCustomerType] = useState(false);
  const [note, setNote] = useState("");
  const [loader, setLoader] = useState(false);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setLoader(true);
    const data = {
      order: orderId,
      user: userData.id,
      document_url: documentUrl,
      receipient_email: mail,
      receipient_type: recieverRole,
      supplier_name: name,
      is_esisting_customer: customerType,
      notes: note,
    };

    sendEmailData(data)
      .then((response) => {
        setLoader(false);
        props.onHide();
        setShareStatestoInitial();
        setDocumentUrl("");
        setResponseStatus({ msg: t("order sent",{ns: "emailOrder"}), color: "#00B38F" });
        setResponseMsg({
          msg: t("receive private email", { ns: "emailOrder" }),
          color: "#2C3341",
        });
        setResponseMsgModalShow(true);
        setTimeout(() => {
          setResponseMsgModalShow(false);
          changeRouteOnshare();
        }, timeout_secs);
      })
      .catch((err) => {
        setLoader(false);
        props.onHide();
        setShareStatestoInitial();
        setResponseStatus({ msg: t("order not sent",{ns: "emailOrder"}), color: "#CD2017" });
        setResponseMsg({
          msg: t("email not recieved", {ns: "emailOrder"}),
          color: "#2C3341",
        });
        setResponseMsgModalShow(true);
        setTimeout(() => {
          setResponseMsgModalShow(false);
          changeRouteOnshare();
        }, timeout_secs);
      });
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={"project-modal"}
    >
      <div className="head">
        <h4>{t("send by mail", { ns: "emailOrder" })}</h4>
        <img
          src={CrossIcon}
          alt="cross"
          style={{ cursor: "pointer" }}
          onClick={props.onHide}
        />
      </div>
      <form className="body" onSubmit={(e) => onSubmitHandler(e)}>
        <h5 className="main-head">
          {t("email to send", { ns: "emailOrder" })}
        </h5>
        <div className="custom-form-group">
          <input
            type="email"
            id="mail"
            aria-describedby="mail area"
            placeholder={t("example", { ns: "emailOrder" })}
            required
            value={mail}
            onChange={(e) => setMail(e.target.value)}
          />
        </div>
        <h5 className="main-head">{t("whom to send", { ns: "emailOrder" })}</h5>
        <div className="custom-form-group">
          {Object.entries(Reciever).map(([key, val], index) => (
            <div className="checkbox__inp" key={"role " + index}>
              <input
                key={"reciever-role " + index}
                type="checkbox"
                checked={val === recieverRole}
                onChange={() => {
                  setRecieverRole(val);
                }}
                defaultChecked={false}
                className={rightToLeft ? "checkbox__ml" : "checkbox__mr"}
              />
              <label htmlFor={"radio "} className="mb-0">
                {t(key, { ns: "emailOrder" })}
              </label>
              <br />
            </div>
          ))}
        </div>
        {recieverRole === 1 && (
          <>
            <h5 className="main-head">
              {t("supplier name", { ns: "emailOrder" })}
            </h5>
            <div className="custom-form-group">
              <input
                type="text"
                id="company-name"
                aria-describedby="name"
                placeholder={t("type here", {
                  ns: "emailOrder",
                })}
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <h5 className="main-head">
              {t("customer type", { ns: "emailOrder" })}
            </h5>
            <div className="custom-form-group">
              {Object.entries(Customer_Type).map(([key, val], index) => (
                <div className="checkbox__inp" key={"type " + index}>
                  <input
                    key={"customer-type " + index}
                    type="checkbox"
                    checked={val === customerType}
                    onChange={() => {
                      setCustomerType(val);
                    }}
                    defaultChecked={false}
                    className={rightToLeft ? "checkbox__ml" : "checkbox__mr"}
                  />
                  <label htmlFor={"radio "} className="mb-0">
                    {t(key, { ns: "emailOrder" })}
                  </label>
                  <br />
                </div>
              ))}
            </div>
          </>
        )}
        {recieverRole && (
          <>
            <h5 className="main-head">{t("Note", { ns: "emailOrder" })}</h5>
            <div className="custom-form-group">
              <textarea
                rows="5"
                type="text"
                id="note"
                placeholder={t("message attached", {
                  ns: "emailOrder",
                })}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
            <div className="save-wrapper">
              <button type="submit" className="save-btn">
                {loader && (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    &nbsp;
                  </>
                )}
                {t("Send", { ns: "emailOrder" })}
              </button>
            </div>
          </>
        )}
      </form>
    </Modal>
  );
}

export default SendbyMail;
