export const toolEn = {
    "item": "item",
    'add new': "Add New",
    'edit': 'Edit',
    'filters': 'Filters',
    'add radius': 'Add radius',
    "instruction": "In order to create a new Rebar, enter data selected from the list of standard shapes",
    "instruction net": "In order to create a new Net, enter data selected from the list of standard shapes",
    "uniform gap": "Only Uniform Gap",
    "uniform diameter": "Add Uniform Diameter",
    "shape": "shape",
    "Add New Item": "Add New Item",
    'edit item': 'Edit Item',

    "preview": "Preview",
    "data entry": "Data can be entered in a table or sketch",
    "sketch": "add a sketch",
    "net": "net",
    "cage": "cage",
    "concrete cage": "concrete cage",
    "diameter": "diameter",
    "deep": "deep",
    "Reinforced Concerte": "Reinforced Concerte",

    "spiral": "spiral",
    "cover": "cover",
    "steel": "steel",
    "type": "type",
    "length": "length",
    "amount": "amount",
    "Select Diameter": "Select Diameter",
    "Select Steel Type": "Select Steel Type",

    "number": "number",
    "net size": "net size",
    "steel type": "Steel Stype",
    "units": "units",
    "weight": "weight",
    "unit": "unit",
    "total": "total",
    "notes": "Production Notes",

    "net type": "Net Type",
    'ear': 'Ear',

    "save and add": "save and add",
    "save and finish": "save and finish",
    "finish": "Finish",
    "save":  "save",

    "Add radius": "Add radius",

    "instruction pile": "In order to create a new Pile, enter data selected from the list of standard shapes",
    "cage type": "cage type",
    "base": "base",
    "Split": "Split",
    "internal": "internal",
    "middle": "middle",
    "external": "external",
    "standard shapes": "standard shapes",
    "adjustments": "adjustments",
    "RING": "RING",
    "GAMA": "GAMA",
    "cartesian": "cartesian",
    "radial": "radial",

    "rebar": "rebar",
    "rebar-singular" : "rebar",
    "bend": "bend",
    "degrees": "degrees",
    'weights': 'Weights',
    "sides": "Sides",
    "side" : "side",
    "all" : "All",
    "use arrow to move": "use arrow to move",
    "measurements warning": "We noticed that you did not change shape measurements,",
    "measurements surety":"are you sure this is the measurements you want?",
    "I'm sure":"I'm sure",
    "go back":"Go back",
    "symmetrical shape": "Symmetrical shape",
    "symm info": "Uncheck to change all measurments.",

    "measurements": "Total Size | Diameter | Gap",

    "diff gap": "two different gaps",
    "diff diameters": "two different diameters",
    "size": "size",
    "last used" : "last used",

    "error_net_min": "The minimum size of a net can be 80 cm",
    "error_net_max": "The maximum size of a net can be 750/320 cm",
    "error_net_minmax": "The maximum size of a net can be 750/320 or 320/750 cm",

    "error_net_amount": "Please fill in an amount",
    "error_net_amount_min": "Amount should not be less than 1",
    "error_net_diameter": "Ratio of diameters should be above 0.6, please change X or Y diameters.",

    "error_net_sides": "The length of the net edges does not match the standard",
    "error_net_measure": "impossible measure",

    "error_net_gap_xy": "The numbers marked in red do not match, Please change one of them.",
    
    "net name" : "Net Name",
    "total lengths" : "Total Lengths",
    "cm" : "(cm)",
    "mm" : "(mm)",
    "kg" : "kg",
    "xwidth" : "X - width",
    "ywidth" : "Y - height",

    "horizontal rebars": "horizontal rebars",
    "lengths and gaps": "lengths and gaps",
    "ear type": "ear type",
    "zoll": "(zoll)",
    "cage name": "cage Name",
    "split": "split",

    "min_error_D": "D min is 30 cm",
    "max_error_D": "D max is 150 cm",
    "error_cage_diameter_ratio": "d can't be bigger than D",

    "error_rebar_amount_to_cage": "the amount is too small for this cage diameter (d)",
    "min_error_rebar_amount": "the minimum amount is 5",
    "max_error_rebar_amount": "the amount is too big for this cage diameter (d)",

    "min_error_l1": "L1 min is 5 cm",
    "max_error_l1": "L1 max is 200 cm",

    "min_error_l2": "L2 min is 50 cm",
    "max_error_l2": "L2 max is 2,355 cm",

    "min_error_l3": "L3 min is 50 cm",
    "max_error_l3": "L3 max is 2,355 cm",

    "error_min_total_len": "the total length min is 250 cm",
    "error_max_total_len": "the total length max is 2,400 cm",
    "error_total_len_ratio": "the ratio between length and cage diameter is not according to standard",

    "error_cage_amount": "Please fill in an amount",
    "error_side_min": "The minimum amount is 2",


    "drawing": "Drawing",
    "refresh": "Refresh"
}