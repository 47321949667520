import React, { useState } from "react";
import { useAppSelector } from "Redux/hooks";
import { commaSeparated } from "Constants/general";
import {
  rebarsWithoutPriceBody,
  rebarsWithPrice,
} from "Constants/rebar-constants";
import MoreInfoIcon from "../../../../assets/img/icon/drug.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { RebarImage } from "./index";
import { useSortable } from "@dnd-kit/sortable";

const StaticTableRow = ({ ...props }) => {
  const { t } = useTranslation(["newOrder", "common", "measurements"]);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const steelTypes = useAppSelector((state) => state.steelType.steelTypes);

  const {
    index,
    itemRebar,
    priceShow,
    setSelectedTool,
    handleImagePreview,
  } = props;

  const {
    isDragging,
  } = useSortable({
    id: props.id,
  });

  return (
    <tr
      className="static-table_row"
      style={
        priceShow
          ? {
              ...rebarsWithPrice,
              position: "relative",
            }
          : {
              ...rebarsWithoutPriceBody,
              position: "relative",
              borderTop: "2px solid #E68F8B",
            }
      }
      onMouseEnter={() => {
        setSelectedTool(itemRebar);
      }}
    >
      <td className={"d-flex justify-content-center"}>
        {!isDragging ? (
          <div className="dropsdown">
            <img
              src={MoreInfoIcon}
              alt={"icon for options"}
              style={
                !isDragging
                  ? { cursor: "grabbing", width: "22px" }
                  : { cursor: "grab", width: "22px" }
              }
            />
          </div>
        ) : (
          <span className="table__para">{itemRebar.row_number}</span>
        )}
      </td>
      <td
        style={{
          height: "80px",
          padding: "0px 6px 0px 15px",
        }}
        className={
          "justify-content-lg-between " +
          (rightToLeft ? "dark-border-right" : "dark-border-left")
        }
      >
        <RebarImage
          preview_image={itemRebar.tool.preview_image}
          index={index}
        />
        <span
          className="search__icon mr-2"
          style={{ cursor: "pointer" }}
          onClick={() => handleImagePreview(itemRebar.tool.preview_image)}
        >
          <FontAwesomeIcon icon="search"></FontAwesomeIcon>
        </span>
      </td>
      <td
        className={
          "justify-content-start name_header_font " +
          (rightToLeft ? "dark-border-right" : "dark-border-left")
        }
      >
        <span
          style={
            itemRebar.tool.name
              ? itemRebar.tool?.name.includes(" ")
                ? {}
                : { wordBreak: "break-all" }
              : {}
          }
        >
          {itemRebar.tool.name}
        </span>
      </td>
      <td
        style={itemRebar.production_notes ? { alignItems: "flex-start" } : {}}
        className={
          "justify-content-start " +
          (rightToLeft ? "dark-border-right" : "dark-border-left")
        }
      >
        <span style={itemRebar.production_notes ? { marginTop: "10px" } : {}}>
          {steelTypes.length > 0 &&
            steelTypes.find((item) => item.id === itemRebar.tool.steel_type) &&
            steelTypes.find((item) => item.id === itemRebar.tool.steel_type)
              .name}
        </span>
      </td>
      <td
        style={itemRebar.production_notes ? { alignItems: "flex-start" } : {}}
        className={
          "justify-content-start " +
          (rightToLeft ? "dark-border-right" : "dark-border-left")
        }
      >
        <span style={itemRebar.production_notes ? { marginTop: "10px" } : {}}>
          {itemRebar.tool.diameter.steel_diameter}
        </span>
      </td>
      <td
        style={itemRebar.production_notes ? { alignItems: "flex-start" } : {}}
        className={
          "justify-content-start " +
          (rightToLeft ? "dark-border-right" : "dark-border-left")
        }
      >
        <span style={itemRebar.production_notes ? { marginTop: "10px" } : {}}>
          {itemRebar.tool.rebar_side.reduce(
            (previousValue: number, item: any) =>
              previousValue + parseInt(item.length),
            0
          )}
        </span>
      </td>
      <td
        className={
          "justify-content-start " +
          (rightToLeft ? "dark-border-right" : "dark-border-left")
        }
      >
        <span>
          {itemRebar.quantity.toString().replace(commaSeparated, ",")}
        </span>
      </td>
      <td
        className={
          "justify-content-start " +
          (rightToLeft ? "dark-border-right" : "dark-border-left")
        }
      >
        <span>
          {itemRebar.tool.unit_weight.toString().replace(commaSeparated, ",")}
        </span>
      </td>
      <td
        className={
          "justify-content-start " +
          (rightToLeft ? "dark-border-left" : "dark-border-right")
        }
      >
        <span>
          {(itemRebar.tool.unit_weight * itemRebar.quantity)
            .toFixed(2)
            .toString()
            .replace(commaSeparated, ",")}
        </span>
      </td>
      {priceShow && (
        <>
          <td className="justify-content-start">
            <span>
              {itemRebar.unit_price
                ? parseFloat(itemRebar.unit_price)
                    .toFixed(2)
                    .toString()
                    .replace(commaSeparated, ",")
                : "-"}
            </span>
          </td>
          <td className="justify-content-start">
            <span>
              {itemRebar.unit_price
                ? (parseFloat(itemRebar.unit_price) * itemRebar.quantity)
                    .toFixed(0)
                    .toString()
                    .replace(commaSeparated, ",")
                : "-"}
            </span>
          </td>
        </>
      )}
      {itemRebar.production_notes && (
        <td
          colSpan={2}
          className="light-border-top note-td"
          style={{
            padding: "0px",
            position: "absolute",
            marginTop: "56px",
            alignItems: "normal",
            justifyContent: "flex-start",
            width: priceShow ? "73%" : "31.4%",
            ...(rightToLeft
              ? {
                  right: priceShow ? "250px" : "calc(100% - 57.4%)",
                  textAlign: "right",
                }
              : {
                  left: priceShow ? "250px" : "calc(100% - 57.4%)",
                }),
          }}
        >
          <p style={{ margin: "0 5px", fontSize: "15px" }}>
            {t("note", { ns: "common" })}: {itemRebar.production_notes}
          </p>
        </td>
      )}
    </tr>
  );
};

export default StaticTableRow;
