import React, { useEffect } from "react";
import {
  Stage,
  Layer,
  Shape,
  Text,
  // Line, Rect
} from "react-konva";
import { useState } from "react";
import Measure from "react-measure";
import { calculateNextPoint, reverseAngle, transfromAngle } from "../../utils";
import { isMobile } from "react-device-detect";
import {
  // mPadX,
  // mPadY,
  // padX,
  // padY,
  // pctInnerheight,
  // pctInnerWidth,
  resize_image,
  previewFontSize,
  rebarInputSize,
  previewWidth,
  previewHeight,
  sideLengthWithoutTextInput,
  angleTextSize,
} from "./preview-constants";
import { SHAPE_MODE } from "Constants/rebar-constants";
import {
  calculateInputPoints,
  calculateAnglePoints,
  getSideAngle,
  getRectangleCorners,
  isOverlap,
} from "Components/tool-new/tool-utils/screenShotUtils";
function RebarShapeSmall({
  shapeObj,
  stageRef,
  setAllPoints,
  initiateImage,
  isRotatable,
  rebarShapeMode,
  path,
  freeLineAngles,
}: any) {
  const [canvasWidth, setCanvasWidth] = useState(30);
  const [canvasHeight, setCanvasHeight] = useState(30);
  const [inputPoints, setInputPoints] = useState([] as any);
  const [angleTextPoints, setAngleTextPoints] = useState([] as any);
  const [screnshotCreated, setScrenshotCreated] = useState(false);
  const [resizedImage, setResizedImage] = useState({
    first_point: {
      x: 0,
      y: 0,
    },
    rescale_factor: 1,
    sides: [{ length: 0, angle: 0 }],
  });
  function canvasSizeChanged(contentRect: any) {
    setCanvasHeight(contentRect.bounds.height);
    setCanvasWidth(contentRect.bounds.width);
  }

  const calculateAllPoints = (_resizedImage) => {
    let previousPoint = _resizedImage.first_point;
    let points = [];
    points.push(previousPoint);
    shapeObj.rebar_side.forEach((side: any, index: number) => {
      let sideAngle = getSideAngle(side, rebarShapeMode, isRotatable);
      const length = _resizedImage.sides[index].length;
      // Calculate Points for saving Image preview
      const currentPoint = calculateNextPoint(previousPoint, sideAngle, length);
      previousPoint = currentPoint;
      points.push({ ...currentPoint });
    });
    return points;
  };

  useEffect(() => {
    if (
      shapeObj.rebar_side &&
      canvasWidth > 200 &&
      canvasHeight > 200 &&
      shapeObj.rebar_side.length > 0 &&
      !screnshotCreated
    ) {
      const _midpoints: any[] = [];
      const _inputboxes: any[] = [];
      const _anglePoints: any[] = [];
      setScrenshotCreated(true);

      const sidesMapped = shapeObj.rebar_side.map((item: any) => ({
        angle: isRotatable ? transfromAngle(item.line_angle) : item.line_angle,
        length: item.length,
      }));

      const _resizedImage = resize_image(
        canvasWidth,
        canvasHeight,
        sidesMapped,
        isMobile
      );
      setResizedImage(_resizedImage as any);

      if (isMobile)
        _resizedImage.rescale_factor = _resizedImage.rescale_factor - 0.3;

      let previousPoint = _resizedImage.first_point;
      const _allPoints = calculateAllPoints(_resizedImage);
      shapeObj.rebar_side.forEach((side: any, index: number) => {
        let sideAngle = getSideAngle(side, rebarShapeMode, isRotatable);
        const currentPoint = _allPoints[index + 1];

        // Calculate Points for inputs on the canvas
        const invertedLine = previousPoint.x > currentPoint.x; // if difference is negactive b/w Xs
        let pointToInput = { x: 0, y: 0 };
        pointToInput = calculateInputPoints(
          rebarShapeMode,
          (path && path.lines) || [],
          shapeObj,
          index,
          isRotatable,
          _allPoints,
          _resizedImage
        );

        // calculate points of rectangle covering current label
        let inputBox = {
          index,
          ...getRectangleCorners(
            pointToInput.x,
            pointToInput.y,
            Number(side.length.toString().length * 22),
            30,
            rebarShapeMode === SHAPE_MODE.catalog
              ? invertedLine
                ? (sideAngle + 180) % 360
                : sideAngle
              : Number(invertedLine ? sideAngle - 180 : sideAngle + 180)
          ),
        };

        // check if current label is overlaping with any other label
        let firstOverlap = isOverlap(inputBox, _inputboxes);
        // if there are any overlaps
        if (firstOverlap.index > -1) {
          // Calculate Overlaps by flipping current Label to opposite side of line
          const pointOneFlipped = calculateInputPoints(
            rebarShapeMode,
            (path && path.lines) || [],
            shapeObj,
            index,
            isRotatable,
            _allPoints,
            _resizedImage,
            true
          );
          const pointOneFlippedBox = {
            index,
            ...getRectangleCorners(
              pointOneFlipped.x,
              pointOneFlipped.y,
              Number(side.length.toString().length * 22),
              30,
              rebarShapeMode === SHAPE_MODE.catalog
                ? invertedLine
                  ? (sideAngle + 180) % 360
                  : sideAngle
                : Number(invertedLine ? sideAngle - 180 : sideAngle + 180)
            ),
          };
          const pointOneFlippedOverlap = isOverlap(pointOneFlippedBox, _inputboxes);

          // Also calculate Overlaps by flipping the other label to the opposite side
          const pointTwoFlipped = calculateInputPoints(
            rebarShapeMode,
            (path && path.lines) || [],
            shapeObj,
            firstOverlap.index,
            isRotatable,
            _allPoints,
            _resizedImage,
            true
          );
          const pointTwoInvertedLine =
            _allPoints[firstOverlap.index].x >
            _allPoints[firstOverlap.index + 1].x;
          const pointTwoSideAngle = getSideAngle(
            shapeObj.rebar_side[firstOverlap.index],
            SHAPE_MODE.catalog,
            isRotatable
          );
          const pointTwoFlippedBox = {
            index,
            ...getRectangleCorners(
              pointTwoFlipped.x,
              pointTwoFlipped.y,
              Number(shapeObj.rebar_side[index].length.toString().length * 22),
              30,
              rebarShapeMode === SHAPE_MODE.catalog
                ? pointTwoInvertedLine
                  ? (pointTwoSideAngle + 180) % 360
                  : pointTwoSideAngle
                : Number(
                    pointTwoInvertedLine
                      ? pointTwoSideAngle - 180
                      : pointTwoSideAngle + 180
                  )
            ),
          };

          const boxesPointTwo = [..._inputboxes];
          boxesPointTwo[firstOverlap.index] = pointTwoFlippedBox;
          const pointTwoFlippedOverlap = isOverlap(pointTwoFlippedBox, boxesPointTwo);
          const pointOneOverlp = isOverlap(inputBox, boxesPointTwo);
          const flippedPointTwoMaxArea = Math.max(pointTwoFlippedOverlap.area, pointOneOverlp.area);
          // If flipping current label results in minimum overlapping
          if (
            pointOneFlippedOverlap.area <= flippedPointTwoMaxArea &&
            pointOneFlippedOverlap.area < firstOverlap.area
          ) {
            pointToInput = pointOneFlipped;
            inputBox = pointOneFlippedBox;
          } // If flipping the other label results in minimum overlapping
          else if (
            flippedPointTwoMaxArea <= pointOneFlippedOverlap.area &&
            flippedPointTwoMaxArea < firstOverlap.area
          ) {
            // change the previously calculated points for the other label
            _midpoints[firstOverlap.index] = {
              input: pointTwoFlipped,
              pointTwoInvertedLine,
            };
            _inputboxes[firstOverlap.index] = pointTwoFlippedBox;
          }
        }

        _inputboxes.push(inputBox);
        _midpoints.push({
          input: pointToInput,
          invertedLine,
        });

        //Calculate angle text point on canvas
        if (shapeObj.rebar_side.length - 1 !== index) {
          const pointToAngleText = calculateAnglePoints(
            shapeObj,
            side,
            index,
            path.lines,
            rebarShapeMode,
            currentPoint,
            _resizedImage
          );
          const angle = side.angle;
          _anglePoints.push({
            x: pointToAngleText.x - angle.toString().length * 3.5,
            y: pointToAngleText.y,
          });
        }
        previousPoint = currentPoint;
      });
      setAllPoints(_allPoints);
      setInputPoints(_midpoints);
      setAngleTextPoints(_anglePoints);

      return () => {
        setAllPoints([]);
        setInputPoints([]);
        setAngleTextPoints([]);
        setScrenshotCreated(false)
      };
    }
  }, [
    shapeObj.rebar_side,
    canvasWidth,
    canvasHeight,
    setAllPoints,
    initiateImage,
    isRotatable,
    rebarShapeMode,
  ]);

  const angleForTheInputPoint = (item, index) => {
    if (rebarShapeMode === SHAPE_MODE.catalog) {
      return item.invertedLine
        ? reverseAngle(
            isRotatable
              ? transfromAngle(shapeObj.rebar_side[index]?.line_angle)
              : shapeObj.rebar_side[index]?.line_angle
          )
        : isRotatable
        ? transfromAngle(shapeObj.rebar_side[index]?.line_angle)
        : shapeObj.rebar_side[index]?.line_angle;
    } else {
      return item.invertedLine
        ? reverseAngle(Number(shapeObj.rebar_side[index]?.line_angle))
        : shapeObj.rebar_side[index]?.line_angle;
    }
  };

  return (
    <Measure bounds onResize={canvasSizeChanged}>
      {({ measureRef }) => {
        return (
          <div
            ref={measureRef}
            style={{
              width: previewWidth + "px",
              height: previewHeight + "px",
            }}
          >
            <Stage width={canvasWidth} height={canvasHeight} ref={stageRef}>
              <Layer>
                <Shape
                  sceneFunc={(context, shape) => {
                    if (
                      shapeObj.rebar_side &&
                      canvasWidth > 200 &&
                      canvasHeight > 200 &&
                      shapeObj.rebar_side.length > 0 &&
                      resizedImage.sides.length === shapeObj.rebar_side.length
                    ) {
                      context.beginPath();

                      if (isMobile)
                        resizedImage.rescale_factor =
                          resizedImage.rescale_factor - 0.3;

                      context.moveTo(
                        resizedImage.first_point.x,
                        resizedImage.first_point.y
                      );
                      let previousPoint = resizedImage.first_point;

                      shapeObj.rebar_side.map((side: any, index: number) => {
                        const length = resizedImage.sides[index].length;
                        const currentPoint = calculateNextPoint(
                          previousPoint,
                          isRotatable
                            ? transfromAngle(side.line_angle)
                            : side.line_angle,
                          length
                        );
                        if (
                          // side.radius !== null &&
                          // side.radius > 0 &&
                          index + 1 <=
                          shapeObj.rebar_side.length - 1
                        ) {
                          const radius = resizedImage.rescale_factor * 5;
                          const nextAngle = isRotatable
                            ? transfromAngle(
                                shapeObj.rebar_side[index + 1].line_angle
                              )
                            : shapeObj.rebar_side[index + 1].line_angle;
                          const radiusStartPoint = calculateNextPoint(
                            previousPoint,
                            isRotatable
                              ? transfromAngle(side.line_angle)
                              : side.line_angle,
                            length - radius
                          );
                          const radiusEndPoint = calculateNextPoint(
                            currentPoint,
                            nextAngle,
                            radius
                          );

                          context.lineTo(
                            radiusStartPoint.x,
                            radiusStartPoint.y
                          );
                          context.quadraticCurveTo(
                            currentPoint.x,
                            currentPoint.y,
                            radiusEndPoint.x,
                            radiusEndPoint.y
                          );
                        } else {
                          context.lineTo(currentPoint.x, currentPoint.y);
                        }
                        previousPoint = currentPoint;
                        return side.length;
                      });
                      // (!) Konva specific method, it is very important
                      context.fillStrokeShape(shape);
                    }
                  }}
                  lineCap="round"
                  stroke="black"
                  strokeWidth={isMobile ? 3 : 6}
                />
              </Layer>
              <Layer>
                {inputPoints.length > 0 &&
                  shapeObj.rebar_side &&
                  shapeObj.rebar_side.length > 0 &&
                  !shapeObj.rebar_side.reduce(
                    (prev: any, curr: any) =>
                      prev && (curr.length > 0 ? false : true),
                    true
                  ) &&
                  inputPoints.map((item: any, index: number) => (
                    <React.Fragment key={"rebar_inputs" + index}>
                      <Text
                        fontSize={previewFontSize}
                        text={shapeObj.rebar_side[index]?.length.toFixed(0)}
                        x={item.input.x}
                        y={item.input.y}
                        rotation={angleForTheInputPoint(item, index)}
                        fill={"#000"}
                      />
                    </React.Fragment>
                  ))}
                {/* Here the angles are printed on the points calculated above */}
                {angleTextPoints.length > 0 &&
                  shapeObj.rebar_side &&
                  shapeObj.rebar_side.length > 0 &&
                  angleTextPoints.map((item: any, index: number) => (
                    <React.Fragment key={"rebar_angles" + index}>
                      {/* check if angles is 90,135,45 do not print */}
                      {shapeObj.rebar_side[index]?.angle !== 90 &&
                        shapeObj.rebar_side[index]?.angle !== 135 &&
                        shapeObj.rebar_side[index]?.angle !== 45 && (
                          <Text
                            fontSize={angleTextSize}
                            text={shapeObj.rebar_side[index]?.angle + "°"}
                            x={item.x}
                            y={item.y}
                            fill={"#899499"}
                          />
                        )}
                    </React.Fragment>
                  ))}
              </Layer>
              {/* <Layer>
                <Line
                  points={[0,canvasHeight/2, canvasWidth, canvasHeight/2]}
                  stroke="#df4b26"
                  strokeWidth={1}
                />
                <Line
                  points={[canvasWidth/2, 0, canvasWidth/2, canvasHeight]}
                  stroke="#df4b26"
                  strokeWidth={1}
                />
                <Rect
                  x={(canvasWidth/2) - (((canvasWidth - padX) * pctInnerWidth)/2)}
                  y={(canvasHeight/2) - (((canvasHeight - padY) * pctInnerheight)/2)}
                  width={(canvasWidth - padX) * pctInnerWidth}
                  height={(canvasHeight - padY) * pctInnerheight}
                  stroke='black'
                  strokeWidth={1}
                />
                <Rect
                  x={(isMobile ? mPadX : padX)/2}
                  y={(isMobile ? mPadY : padY)/2}
                  width={canvasWidth - (isMobile ? mPadX : padX)}
                  height={canvasHeight - (isMobile ? mPadY : padY)}
                  stroke='black'
                  strokeWidth={1}
                />
              </Layer> */}
            </Stage>
          </div>
        );
      }}
    </Measure>
  );
}

export default React.memo(RebarShapeSmall);
