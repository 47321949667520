import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface RebarState {
  currentOrderRebars: any[];
  currentOrderPdfRebars: any[];
  defaultRebars: any[];
  rebarFilters: any[];
  page: string;
  rebarPageRefs: any[];
  orderRebarPage: string;
  currentOrderRebarCount: any;
}

const initialState: RebarState = {
  currentOrderRebars: [],
  currentOrderPdfRebars: [],
  defaultRebars: [],
  rebarFilters: [],
  page: "",
  rebarPageRefs: [],
  orderRebarPage: '1',
  currentOrderRebarCount: 0,
};

export const rebarSlice = createSlice({
  name: "rebar",
  initialState,
  reducers: {
    addRebarToCurrentOrder: (state, action: PayloadAction<any>) => {
      const updatedRebars = [...state.currentOrderRebars, action.payload];
      updatedRebars.sort((a, b) => a.row_number - b.row_number);
    
      return {
        ...state,
        currentOrderRebars: updatedRebars
      };
    },    
    setDefaultRebars: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        defaultRebars: action.payload,
      };
    },
    setRebarFilters: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        rebarFilters: action.payload,
      };
    },
    setCurrentOrderRebars: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        currentOrderRebars: action.payload,
      };
    },
    addNextPageRebarsToCurrentOrder: (state, action: PayloadAction<any>) => {
      const uniqueRowNumbers = new Set(state.currentOrderRebars.map(rebar => rebar.row_number));
      const uniqueRebars = action.payload.filter(rebar => !uniqueRowNumbers.has(rebar.row_number));
    
      return {
        ...state,
        currentOrderRebars: [...state.currentOrderRebars, ...uniqueRebars],
      };
    },
    setCurrentOrderRebarPage: (state, action: PayloadAction<any>) => {
      state.orderRebarPage = action.payload;
    },
    setCurrentOrderPdfRebars: (state, action: PayloadAction<any>) => {
      const sortedRebars = action.payload;
      sortedRebars.sort((a, b) => a.row_number - b.row_number);
      return {
        ...state,
        currentOrderPdfRebars: sortedRebars,
      };
    },
    setCurrentOrderRebarCount: (state, action: PayloadAction<any>) => {
      state.currentOrderRebarCount = action.payload;
    },
    removeCurrentOrderRebar: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        currentOrderRebars: [
          ...state.currentOrderRebars.filter(
            (item) => item.id !== action.payload
          ),
        ],
      };
    },
    removeCurrentOrderRebars: (state) => {
      return {
        ...state,
        currentOrderRebarCount: 0,
        currentOrderRebars: [],
      };
    },
    updateCurrentOrderRebar: (state, action: PayloadAction<any>) => {
      const current = [...state.currentOrderRebars];
      const inPlaceIndex = current.map((x) => x.id).indexOf(action.payload.id);
      current[inPlaceIndex] = action.payload;
      state.currentOrderRebars = current;
    },
    updateRebarPageRef: (state, action: PayloadAction<any>) => {
      state.rebarPageRefs = action.payload;
    },
    setRebarPage: (state, action: PayloadAction<any>) => {
      state.page = action.payload;
    },
    updatePageRebars(state, action: PayloadAction<any>) {
      const uniqueRebars = action.payload.filter((rebar: any) => {
        return !state.currentOrderRebars.some(
          (existingRebar: any) => existingRebar.id === rebar.id
        );
      });
      return {
        ...state,
        currentOrderRebars: [...state.currentOrderRebars, ...uniqueRebars],
      };
    },
  },   
});

// Action creators are generated for each case reducer function
export const {
  addRebarToCurrentOrder,
  setDefaultRebars,
  addNextPageRebarsToCurrentOrder,
  setCurrentOrderRebars,
  setCurrentOrderRebarPage,
  setCurrentOrderPdfRebars,
  removeCurrentOrderRebar,
  removeCurrentOrderRebars,
  updateCurrentOrderRebar,
  updateRebarPageRef,
  setRebarPage,
  setRebarFilters,
  setCurrentOrderRebarCount,
  updatePageRebars,
} = rebarSlice.actions;

export default rebarSlice.reducer