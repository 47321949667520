import React, { useEffect, useRef } from "react";
import {
  Stage,
  Layer,
  Shape,
  Text,
  // Line, Rect
} from "react-konva";
import { Html } from "react-konva-utils";
import { useState } from "react";
import Measure from "react-measure";
import {
  labels,
  rebarErrorTypes,
  sideMaxLength,
  sideMinLength,
  SHAPE_MODE,
} from "Constants/rebar-constants";
import {
  calculateNextPoint,
  drawAngleSymbol,
  resize_image,
  reverseAngle,
} from "../utils";
import { isMobile } from "react-device-detect";
import {
  angleTextSize,
  previewFontSize,
  rebarInputSize,
  sideLengthTextWithoutInput,
  radiusLineEndPointLength,
  radiusLabelDistance,
} from "../constant";
// import { mPadX, mPadY, padX, padY, pctInnerheight, pctInnerWidth } from "../constant";
import { ReactComponent as InfoIcon } from "../../../assets/img/icon/info.svg";
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import { Overlay, Tooltip } from "react-bootstrap";

import { shapeLinesForHeight } from "../tool-utils/heightUtils.ts";
import {
  getControlPoints,
  calculateMiddlePoint,
  radiusRescaleFactor,
  getRadiusTextFactor,
} from "../tool-utils/radiusUtils.ts";

function RebarShape({
  setShape,
  shapeObj,
  setAllPoints,
  setSides,
  initiateImage,
  errorText,
  setSymmetrical,
  setShapeChanged,
  setRebarShapeMode,
  setShowCatalog,
  setRebarHeight,
  rebarHeight,
  showHeight,
  handleHeightChange,
  loader,
}: any) {
  const { t } = useTranslation(["tool"]);
  const symmInfo = useRef<HTMLElement>(null);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const [showToolTip, setShowToolTip] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(30);
  const [canvasHeight, setCanvasHeight] = useState(30);
  const [inputPoints, setInputPoints] = useState([] as any);
  const [angleTextPoints, setAngleTextPoints] = useState([] as any);
  const [resizedImage, setResizedImage] = useState({
    first_point: {
      x: 0,
      y: 0,
    },
    rescale_factor: 1,
    sides: [{ length: 0, angle: 0 }],
  });
  const [allSidesZero, setAllSidesZero] = useState(false);
  const [allHeightLines, setAllHeightLines] = useState([]);
  const [pointsCalculated, setPointsCalculated] = useState([]);
  const [shapesData, setShapesData] = useState([]);

  function canvasSizeChanged(contentRect: any) {
    setCanvasHeight(contentRect.bounds.height);
    setCanvasWidth(contentRect.bounds.width);
  }

  useEffect(() => {
    if (shapeObj.rebar_side) {
      const allSidesZero = shapeObj.rebar_side.reduce(
        (prev: any, curr: any) => prev && (curr.length === 0 ? true : false),
        true
      );
      setAllSidesZero(allSidesZero);
    }
  }, [shapeObj.rebar_side]);

  useEffect(() => {
    if (
      shapeObj.rebar_side &&
      canvasWidth > 200 &&
      canvasHeight > 200 &&
      shapeObj.rebar_side.length > 0
    ) {
      const sidesMapped = shapeObj.rebar_side.map((item: any) => ({
        angle: item.line_angle,
        length: item.length,
      }));
      const _resizedImage = resize_image(
        canvasWidth,
        canvasHeight,
        sidesMapped,
        isMobile
      );
      setResizedImage(_resizedImage as any);

      if (isMobile)
        _resizedImage.rescale_factor = _resizedImage.rescale_factor - 0.3;

      let previousPoint = _resizedImage.first_point;
      let pointsCalculated = [];
      pointsCalculated.push({ ..._resizedImage.first_point });
      const _midpoints: any[] = [];
      const _anglePoints: any[] = [];
      shapeObj.rebar_side.forEach((side: any, index: number) => {
        const length = _resizedImage.sides[index].length;
        // Calculate Points for saving Image preview
        const currentPoint = calculateNextPoint(
          previousPoint,
          side.line_angle,
          length
        );
        pointsCalculated.push({ ...currentPoint });
        // if the angle is outter angle
        const outterInput =
          side.outer ||
          (index >= 1 &&
            !shapeObj.rebar_side[index - 1].change_previous_point &&
            shapeObj.rebar_side[index - 1].outer);
        // Calculate Points for inputs on the canvas
        // find midpoint of line - 25 as intermediate point
        const invertedLine = previousPoint.x > currentPoint.x; // if difference is negactive b/w Xs
        const inputSizeMid =
          initiateImage || isMobile
            ? sideLengthTextWithoutInput
            : rebarInputSize / 2;
        const intermedia = calculateNextPoint(
          previousPoint,
          side.line_angle,
          invertedLine ? length / 2 + inputSizeMid : length / 2 - inputSizeMid
        );
        // revert the point at 90 degrees to get oposite point
        const pointToInput = calculateNextPoint(
          intermedia,
          outterInput ? side.line_angle - 90 : side.line_angle + 90,
          outterInput || invertedLine
            ? initiateImage
              ? 46
              : 27
            : initiateImage
            ? 12
            : 4
        );
        let pointToText = { x: 0, y: 0 };
        if (invertedLine) {
          pointToText = pointToText = calculateNextPoint(
            pointToInput as any,
            outterInput ? side.line_angle - 150 : side.line_angle + 150,
            22
          );
        } else {
          pointToText = calculateNextPoint(
            pointToInput as any,
            outterInput
              ? (side.line_angle - (initiateImage ? 60 : 32)) % 360
              : (side.line_angle + 51) % 360,
            outterInput ? (initiateImage ? 15 : 23) : initiateImage ? 16 : 32
          );
        }
        _midpoints.push({
          input: pointToInput,
          text: pointToText,
          invertedLine,
        });
        //Calculate angle text point on canvas
        if (shapeObj.rebar_side.length - 1 !== index) {
          // get midpoint between the total angle
          const middleAngle = side.angle / 2;
          const angleOfText = side.outer
            ? (shapeObj.rebar_side[index + 1].line_angle + middleAngle) % 360
            : (shapeObj.rebar_side[index + 1].line_angle - middleAngle) % 360;
          const pointToAngleText = calculateNextPoint(
            currentPoint,
            angleOfText,
            (side.angle >= 100 ? 40 : 35) * _resizedImage.rescale_factor
          );
          const angle = side.angle;
          _anglePoints.push({
            x: pointToAngleText.x - angle.toString().length * 3.5,
            y: pointToAngleText.y - 6,
          });
        }
        previousPoint = currentPoint;
      });
      setAllPoints(pointsCalculated);
      setInputPoints(_midpoints);
      setAngleTextPoints(_anglePoints);

      return () => {
        setAllPoints([]);
        setInputPoints([]);
        setAngleTextPoints([]);
      };
    }
  }, [
    shapeObj.rebar_side,
    canvasWidth,
    canvasHeight,
    setAllPoints,
    initiateImage,
  ]);

  //Calculates Height points
  //Stores Shapes Points {current point and prev point} in state
  useEffect(() => {
    if (
      shapeObj.rebar_side &&
      canvasWidth > 200 &&
      canvasHeight > 200 &&
      shapeObj.rebar_side.length > 0 &&
      resizedImage.sides.length === shapeObj.rebar_side.length
    ) {
      let previousPoint = resizedImage.first_point;
      let allHeightLine = [];

      let pointsCalculated = [];
      pointsCalculated.push({
        ...resizedImage.first_point,
        outer: false,
        radius: null,
        angle: 0,
        lineAngle: 0,
      });

      shapeObj.rebar_side.forEach((side: any, index: number) => {
        const length = resizedImage.sides[index].length;
        const currentPoint = calculateNextPoint(
          previousPoint,
          side.line_angle,
          length
        );

        pointsCalculated.push({
          ...currentPoint,
          outer: side.outer ? side.outer : false,
          radius: side.radius,
          angle: side.angle,
          lineAngle: side.line_angle,
          length: side.length,
        });

        if (rebarHeight.length > 0) {
          const sideHeight = rebarHeight.find((obj) => obj.id === side.id);
          if (sideHeight) {
            const lineToDraw = shapeLinesForHeight(
              side,
              shapeObj.rebar_side,
              currentPoint,
              previousPoint,
              index,
              inputPoints
            );
            allHeightLine.push(lineToDraw);
            setAllHeightLines(allHeightLine);
          }
        }
        previousPoint = currentPoint;
      });
      setPointsCalculated(pointsCalculated);

      return () => {
        setAllHeightLines([]);
      };
    }
  }, [
    shapeObj.rebar_side,
    resizedImage,
    canvasWidth,
    canvasHeight,
    rebarHeight,
    inputPoints,
  ]);

  const calculateRadiusLinePoints = (
    q1,
    q2,
    edge,
    middle1,
    middle2,
    side,
    linesAngle
  ) => {
    let m1 = {
      x: (q1.x + edge.x) / 2,
      y: (q1.y + edge.y) / 2,
    };

    let m2 = {
      x: (edge.x + q2.x) / 2,
      y: (edge.y + q2.y) / 2,
    };

    //Middle point of the curve from where the line will be starting
    let lineStartPoint = {
      x: (m1.x + m2.x) / 2,
      y: (m1.y + m2.y) / 2,
    };

    //Half of the angle b/w two lines
    const middleAngle = side.angle / 2;
    const angleOfText = side.outer
      ? (linesAngle + middleAngle) % 360
      : (linesAngle - middleAngle) % 360;

    //Dashed Dotted radius Line end points which are fixed
    let lineEndPoint = calculateNextPoint(
      lineStartPoint,
      angleOfText,
      radiusLineEndPointLength
    );

    //angle in which direction text will be shown (from Ltr/RTL)
    let deltaX, deltaY, angle;
    if (lineEndPoint.x > lineStartPoint.x) {
      deltaX = lineEndPoint.x - lineStartPoint.x;
      deltaY = lineEndPoint.y - lineStartPoint.y;
    } else {
      deltaX = lineStartPoint.x - lineEndPoint.x;
      deltaY = lineStartPoint.y - lineEndPoint.y;
    }
    angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);

    //Angle of the Dashed Line
    let dX = lineEndPoint.x - lineStartPoint.x;
    let dY = lineEndPoint.y - lineStartPoint.y;
    let lineAngle = Math.atan2(dY, dX) * (180 / Math.PI);

    //text factor is applied by checking points
    const textFactor = getRadiusTextFactor(lineStartPoint, lineEndPoint);
    //Points where text will be shown
    let textSide = calculateNextPoint(
      lineStartPoint,
      lineAngle + textFactor.angleFactor,
      radiusLabelDistance + textFactor.lengthFactor
    );

    return { lineStartPoint, lineEndPoint, textSide, angle };
  };

  useEffect(() => {
    if (
      shapeObj.rebar_side &&
      canvasWidth > 200 &&
      canvasHeight > 200 &&
      shapeObj.rebar_side.length > 0 &&
      resizedImage.sides.length === shapeObj.rebar_side.length &&
      pointsCalculated.length > 0
    ) {
      const length = pointsCalculated.length;
      let shapeData = [];
      const radius_rescale_factor = radiusRescaleFactor(pointsCalculated);

      //Data returned from here is used to
      //draw shape lines, dashed actual lines (at the place of shape),
      //dashed radius lines, dots and text
      pointsCalculated.forEach((side, index) => {
        if (index < length - 2) {
          const line1 = {
            x1: side.x,
            y1: side.y,
            x2: pointsCalculated[index + 1].x,
            y2: pointsCalculated[index + 1].y,
          };
          const line2 = {
            x1: pointsCalculated[index + 1].x,
            y1: pointsCalculated[index + 1].y,
            x2: pointsCalculated[index + 2].x,
            y2: pointsCalculated[index + 2].y,
          };

          //connecting point
          const edge = { x: line1.x2, y: line1.y2 };
          //line-1 middle
          const middle1 = calculateMiddlePoint(
            line1.x1,
            line1.y1,
            line1.x2,
            line1.y2
          );
          //line-2 middle
          const middle2 = calculateMiddlePoint(
            line2.x1,
            line2.y1,
            line2.x2,
            line2.y2
          );
          //control points
          const [q1, q2] = getControlPoints(
            middle1,
            edge,
            middle2,
            pointsCalculated[index + 1].radius * radius_rescale_factor
          );

          const dashedRadiusLinePoints = calculateRadiusLinePoints(
            q1,
            q2,
            edge,
            middle1,
            middle2,
            pointsCalculated[index + 1],
            pointsCalculated[index + 2].lineAngle
          );

          shapeData.push({
            edge: edge,
            q1: q1,
            q2: q2,
            middle1: middle1,
            middle2: middle2,
            dashedRadiusLinePoints: dashedRadiusLinePoints,
            radius: pointsCalculated[index + 1].radius,
          });
        } else if (index === length - 2) {
          const lastLine = {
            x1: pointsCalculated[length - 2].x,
            y1: pointsCalculated[length - 2].y,
            x2: pointsCalculated[length - 1].x,
            y2: pointsCalculated[length - 1].y,
          };
          const lastLineMiddle = calculateMiddlePoint(
            lastLine.x1,
            lastLine.y1,
            lastLine.x2,
            lastLine.y2
          );
          const [q1, q2] = getControlPoints(
            lastLineMiddle,
            lastLineMiddle,
            { ...lastLine.x2, ...lastLine.y2 },
            0
          );
          shapeData.push({
            lastLineMiddle: lastLineMiddle,
            q1: q1,
            q2: q2,
            lastLine: lastLine,
          });
        }
      });
      setShapesData(shapeData);
    }
  }, [
    shapeObj.rebar_side,
    resizedImage,
    canvasWidth,
    canvasHeight,
    pointsCalculated,
  ]);

  return (
    <Measure bounds onResize={canvasSizeChanged}>
      {({ measureRef }) => {
        return (
          <div
            className={
              isMobile ? "right__img w-xs-100 mobile-height" : "high__img"
            }
            ref={measureRef}
          >
            <Stage
              width={canvasWidth}
              height={canvasHeight}
              onClick={() => {
                if (!loader) {
                  setRebarShapeMode(SHAPE_MODE.freeform);
                  setShowCatalog(false);
                  setRebarHeight([]);
                }
              }}
            >
              <Layer>
                {/* <Text text={shapeObj.id} /> */}
                <Shape
                  sceneFunc={(context, shape) => {
                    if (pointsCalculated.length > 0) {
                      context.beginPath();
                      context.moveTo(
                        resizedImage.first_point.x,
                        resizedImage.first_point.y
                      );
                      // (!) Konva specific method, it is very important
                      context.fillStrokeShape(shape);
                      // draw the angle symbols on corners
                      for (var i = 0; i < pointsCalculated.length - 2; i++) {
                        drawAngleSymbol(
                          context,
                          pointsCalculated[i],
                          pointsCalculated[i + 1],
                          pointsCalculated[i + 2],
                          !pointsCalculated[i + 1].outer,
                          pointsCalculated[i + 1].angle === 90,
                          pointsCalculated[i + 1].lineAngle
                        );
                      }
                    }
                  }}
                />
                <Shape
                  sceneFunc={(context, shape) => {
                    context.beginPath();
                    shapesData.forEach((side, index) => {
                      if (index < shapesData.length - 1 && side.radius > 1) {
                        context.setLineDash([6, 6]);
                        context.strokeStyle = "#D7D9DC";
                        context.lineWidth = 2;

                        // Draw the dashed lines
                        context.moveTo(side.edge.x, side.edge.y);
                        context.lineTo(side.q1.x, side.q1.y);
                        context.moveTo(side.edge.x, side.edge.y);
                        context.lineTo(side.q2.x, side.q2.y);
                      }
                    });
                    //context.closePath();
                    context.stroke();
                  }}
                />
                <Shape
                  sceneFunc={(context, shape) => {
                    shapesData.forEach((side, index) => {
                      if (index < shapesData.length - 1 && side.radius > 1) {
                        context.beginPath();
                        context.setLineDash([6, 4]);
                        context.strokeStyle = "#878C97";
                        context.lineWidth = 2;

                        let lineStartPoint =
                          side.dashedRadiusLinePoints.lineStartPoint;
                        let lineEndPoint =
                          side.dashedRadiusLinePoints.lineEndPoint;

                        // Drawing the dashed lines
                        context.moveTo(lineStartPoint.x, lineStartPoint.y);
                        context.lineTo(lineEndPoint.x, lineEndPoint.y);
                        context.stroke();

                        // Drawing dot at the lineEndPoint
                        context.beginPath();
                        context.arc(
                          lineEndPoint.x,
                          lineEndPoint.y,
                          4,
                          0,
                          2 * Math.PI,
                          false
                        );
                        context.fillStyle = "#878C97";
                        context.fill();
                      }
                    });
                  }}
                  stroke="#D7D9DC"
                  strokeWidth={3}
                />
                <Shape
                  sceneFunc={(context, shape) => {
                    if (shapesData.length > 0) {
                      context.beginPath();

                      if (isMobile)
                        resizedImage.rescale_factor =
                          resizedImage.rescale_factor - 0.3;

                      context.moveTo(
                        resizedImage.first_point.x,
                        resizedImage.first_point.y
                      );

                      shapesData.forEach((side, index) => {
                        if (index < shapesData.length - 1) {
                          context.lineTo(side.q1.x, side.q1.y);
                          context.quadraticCurveTo(
                            side.edge.x,
                            side.edge.y,
                            side.q2.x,
                            side.q2.y
                          );
                          context.lineTo(side.q2.x, side.q2.y);
                        } else {
                          context.moveTo(
                            shapesData[shapesData.length - 1].q2.x,
                            shapesData[shapesData.length - 1].q2.y
                          );
                          context.lineTo(side.q1.x, side.q1.y);
                          context.quadraticCurveTo(
                            side.lastLineMiddle.x,
                            side.lastLineMiddle.y,
                            side.q2.x,
                            side.q2.y
                          );
                          context.lineTo(side.lastLine.x2, side.lastLine.y2);
                        }
                      });
                      // (!) Konva specific method, it is very important
                      context.fillStrokeShape(shape);
                    }
                  }}
                  lineCap="round"
                  stroke="black"
                  strokeWidth={isMobile ? 3 : initiateImage ? 6 : 4}
                />
              </Layer>
              <Layer>
                {shapesData.length > 0 ? (
                  <React.Fragment>
                    {shapesData.map((side, index) =>
                      index < shapesData.length - 1 && side.radius > 1 ? (
                        <Text
                          fontSize={12}
                          text={"r=" + side.radius}
                          x={side.dashedRadiusLinePoints.textSide.x}
                          y={side.dashedRadiusLinePoints.textSide.y}
                          rotation={side.dashedRadiusLinePoints.angle}
                          fill={"#878C97"}
                        />
                      ) : (
                        <></>
                      )
                    )}
                  </React.Fragment>
                ) : (
                  <></>
                )}
              </Layer>
              <Layer>
                <Shape
                  sceneFunc={(context, shape) => {
                    context.beginPath();
                    if (
                      allHeightLines.length > 0 &&
                      rebarHeight.length > 0 &&
                      showHeight
                    ) {
                      allHeightLines.forEach((side, index) => {
                        //horizontalLine
                        context.moveTo(side?.firstLine?.x1, side.firstLine?.y1);
                        context.lineTo(side?.firstLine?.x2, side.firstLine?.y2);

                        //verticalLine
                        context.moveTo(side?.midLine?.x1, side.midLine?.y1);
                        context.lineTo(side?.midLine?.x2, side.midLine?.y2);

                        //horixontalLine
                        context.moveTo(
                          side?.secondLine?.x1,
                          side?.secondLine?.y1
                        );
                        context.lineTo(
                          side?.secondLine?.x2,
                          side?.secondLine?.y2
                        );

                        //top arrow left line
                        context.moveTo(side?.topArrow?.x, side?.topArrow?.y);
                        context.lineTo(
                          side?.topArrow?.x - 5,
                          side?.topArrow?.y + 5
                        );

                        //top arrow right line
                        context.moveTo(side?.topArrow?.x, side?.topArrow?.y);
                        context.lineTo(
                          side?.topArrow?.x + 5,
                          side?.topArrow?.y + 5
                        );

                        //bottom arrow left line
                        context.moveTo(
                          side?.bottomArrow?.x,
                          side?.bottomArrow?.y
                        );
                        context.lineTo(
                          side?.bottomArrow?.x - 5,
                          side?.bottomArrow?.y - 5
                        );

                        //bottom arrow right line
                        context.moveTo(
                          side?.bottomArrow?.x,
                          side?.bottomArrow?.y
                        );
                        context.lineTo(
                          side?.bottomArrow?.x + 5,
                          side?.bottomArrow?.y - 5
                        );
                      });
                    }
                    context.closePath();
                    context.stroke();
                  }}
                  stroke="grey"
                  strokeWidth={1}
                />
              </Layer>
              <Layer>
                {showHeight &&
                  rebarHeight.length > 0 &&
                  allHeightLines.map((side, index) => (
                    <React.Fragment key={"rebar_height" + index}>
                      <Html
                        divProps={{
                          style: {
                            position: "absolute",
                            top: `${side.inputBox?.y - 12}px`,
                            left: `${side.inputBox?.x}px`,
                          },
                        }}
                      >
                        <input
                          onFocus={(e) => e.target.select()}
                          className={"inputField "}
                          type="number"
                          style={{ width: rebarInputSize + "px" }}
                          value={rebarHeight[index]?.height || ""}
                          onChange={(e) => {
                            handleHeightChange(
                              parseInt(e.target.value),
                              index,
                              rebarHeight[index].id,
                              rebarHeight[index].angle
                            );
                          }}
                        />
                      </Html>
                      <Text
                        fontSize={14}
                        text={"h" + parseInt(index + 1)}
                        x={side.label?.x}
                        y={side.label?.y}
                        fill={"#000"}
                      />
                    </React.Fragment>
                  ))}
              </Layer>
              <Layer>
                {inputPoints.length > 0 &&
                  shapeObj.rebar_side &&
                  shapeObj.rebar_side.length > 0 &&
                  !shapeObj.rebar_side.reduce(
                    (prev: any, curr: any) =>
                      prev && (curr.length >= 0 ? false : true),
                    true
                  ) &&
                  inputPoints.map((item: any, index: number) => (
                    <React.Fragment key={"rebar_inputs" + index}>
                      {!isMobile ? (
                        <>
                          {!initiateImage && (
                            <Text
                              fontSize={14}
                              visible={!allSidesZero}
                              text={labels[shapeObj.rebar_side[index]?.label]}
                              x={item.text.x}
                              y={item.text.y}
                              rotation={
                                item.invertedLine
                                  ? reverseAngle(
                                      shapeObj.rebar_side[index]?.line_angle
                                    )
                                  : shapeObj.rebar_side[index]?.line_angle
                              }
                            />
                          )}
                          {initiateImage ? (
                            <Text
                              fontSize={previewFontSize}
                              text={shapeObj.rebar_side[index]?.length.toFixed(
                                0
                              )}
                              x={item.input.x}
                              y={item.input.y}
                              rotation={
                                item.invertedLine
                                  ? reverseAngle(
                                      shapeObj.rebar_side[index]?.line_angle
                                    )
                                  : shapeObj.rebar_side[index]?.line_angle
                              }
                              fill={"#000"}
                            />
                          ) : (
                            <Html
                              divProps={{
                                style: {
                                  position: "absolute",
                                  top: !allSidesZero
                                    ? `${item.input.y}px`
                                    : "50%",
                                  left: !allSidesZero
                                    ? `${item.input.x}px`
                                    : "50%",
                                  transform: `rotate(${
                                    item.invertedLine
                                      ? reverseAngle(
                                          shapeObj.rebar_side[index]?.line_angle
                                        )
                                      : shapeObj.rebar_side[index]?.line_angle
                                  }deg)`,
                                },
                              }}
                            >
                              <input
                                onFocus={(e) => e.target.select()}
                                className={
                                  "inputField " +
                                  (!(
                                    shapeObj.rebar_side[index]?.length >=
                                      sideMinLength &&
                                    shapeObj.rebar_side[index]?.length <
                                      sideMaxLength
                                  )
                                    ? "shape_error"
                                    : "")
                                }
                                type="number"
                                style={{ width: rebarInputSize + "px" }}
                                value={
                                  shapeObj.rebar_side[index]?.length.toFixed(
                                    0
                                  ) || ""
                                }
                                onChange={(e) => {
                                  setSides(parseInt(e.target.value), index);
                                  setShapeChanged && setShapeChanged(true);
                                }}
                              />
                            </Html>
                          )}
                        </>
                      ) : (
                        <Text
                          fontSize={14}
                          text={
                            labels[shapeObj.rebar_side[index]?.label] +
                            " " +
                            shapeObj.rebar_side[index]?.length.toFixed(0)
                          }
                          x={item.input.x}
                          y={item.input.y}
                          rotation={shapeObj.rebar_side[index]?.line_angle}
                          fill={"#000"}
                        />
                      )}
                    </React.Fragment>
                  ))}
                {angleTextPoints.length > 0 &&
                  shapeObj.rebar_side &&
                  shapeObj.rebar_side.length > 0 &&
                  angleTextPoints.map((item: any, index: number) => (
                    <React.Fragment key={"rebar_angles" + index}>
                      {shapeObj.rebar_side[index]?.angle !== 90 && (
                        <Text
                          fontSize={angleTextSize}
                          text={shapeObj.rebar_side[index]?.angle + "°"}
                          x={item.x}
                          y={item.y}
                          fill={initiateImage ? "#899499" : "#000"}
                        />
                      )}
                    </React.Fragment>
                  ))}
              </Layer>
              {/* <Layer>
                <Line
                  points={[0,canvasHeight/2, canvasWidth, canvasHeight/2]}
                  stroke="#df4b26"
                  strokeWidth={1}
                />
                <Line
                  points={[canvasWidth/2, 0, canvasWidth/2, canvasHeight]}
                  stroke="#df4b26"
                  strokeWidth={1}
                />
                <Rect
                  x={(canvasWidth/2) - (((canvasWidth - padX) * pctInnerWidth)/2)}
                  y={(canvasHeight/2) - (((canvasHeight - padY) * pctInnerheight)/2)}
                  width={(canvasWidth - padX) * pctInnerWidth}
                  height={(canvasHeight - padY) * pctInnerheight}
                  stroke='black'
                  strokeWidth={1}
                />
                <Rect
                  x={(isMobile ? mPadX : padX)/2}
                  y={(isMobile ? mPadY : padY)/2}
                  width={canvasWidth - (isMobile ? mPadX : padX)}
                  height={canvasHeight - (isMobile ? mPadY : padY)}
                  stroke='black'
                  strokeWidth={1}
                />
              </Layer> */}
              {shapeObj.can_be_symmetric && !initiateImage && (
                <Layer>
                  <Html
                    divProps={{
                      style: {
                        position: "absolute",
                        top: "6px",
                        left: "30px",
                        ...(rightToLeft
                          ? { right: "30px", left: "initial" }
                          : {}),
                      },
                      className:
                        "symmetric-checkbox" +
                        (rightToLeft ? " right-to-left" : ""),
                    }}
                  >
                    <input
                      type="checkbox"
                      id="is_symmetrical"
                      checked={shapeObj.is_symmetrical ? true : false}
                      onChange={({ target }) => setSymmetrical(target.checked)}
                    />
                    <label htmlFor="is_symmetrical">
                      {t("symmetrical shape", "tool")}
                    </label>
                    <Overlay
                      target={symmInfo?.current}
                      show={showToolTip}
                      placement="top"
                    >
                      {(props) => (
                        <Tooltip id="overlay-example-1" {...props}>
                          {t("symm info", "tool")}
                        </Tooltip>
                      )}
                    </Overlay>
                    <span
                      ref={symmInfo}
                      onMouseEnter={() => setShowToolTip(true)}
                      onMouseLeave={() => setShowToolTip(false)}
                    >
                      <InfoIcon
                        style={
                          rightToLeft
                            ? { marginRight: "9px" }
                            : { marginLeft: "9px" }
                        }
                      />
                    </span>
                  </Html>
                </Layer>
              )}
            </Stage>
          </div>
        );
      }}
    </Measure>
  );
}

export default React.memo(RebarShape);
