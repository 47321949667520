import { addToUserBusinesses, removeFromUserBusinesses, setUserBusinesses, updateToUserBusinesses } from "Redux/features/business";
import { AppThunk } from 'Redux/store';
import { deleteBusiness, getBusinesses, patchBusiness, postBusiness } from "Services/business";
import { isAxiosResponse } from "Services/index";

export const createBusiness = (business: any, company:boolean, next: CallableFunction): AppThunk => async dispatch => {
  const response = await postBusiness(business,company);
  if (isAxiosResponse(response)) {
    await dispatch(addToUserBusinesses(response.data));
    return next(true);
  }
}

export const fetchBusinesses = (): AppThunk => async dispatch => {
  const response = await getBusinesses();
  if (isAxiosResponse(response) && response.data.results.length > 0) {
    await dispatch(setUserBusinesses(response.data.results));
  }
}

export const updateBusiness = (id: number, business: any, next: CallableFunction): AppThunk => async dispatch => {
  const response = await patchBusiness(id, business);
  if (isAxiosResponse(response)) {
    await dispatch(updateToUserBusinesses(response.data));
    return next(true);
  }
}

export const removeBusiness = (id: number, next: CallableFunction): AppThunk => async dispatch => {
  const response = await deleteBusiness(id);
  if (isAxiosResponse(response)) {
    await dispatch(removeFromUserBusinesses(id));
    return next(true);
  }
}