import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faFile, faPen, faLocationArrow, faFilter, faCaretDown, faCaretUp,
  faMinusCircle, faCheckCircle, faSearch, faPaperclip, faPencilAlt, faTrash, faCopy,
  faExpandAlt, faWeightHanging, faStar, faMinus, faAt, faCheck, faUser, faBriefcase,
  faBell, faGlobe, faFileUpload, faEllipsisH, faSearchLocation
} from '@fortawesome/free-solid-svg-icons';
import {
  faStar as farStar,
  faEye as farEye
} from '@fortawesome/free-regular-svg-icons';
import './assets/bootstrap/bootstrap.min.css';
import './assets/bootstrap/bootstrapCustom.width.css';
import './styles/main.scss';
import './i18n';

import 'jquery/dist/jquery';
import 'bootstrap/dist/js/bootstrap.bundle';
import './assets/fonts/oscar.otf';


import { store } from './redux/store'
import { Provider } from 'react-redux'

library.add(faPlus, faFile, faPen, faLocationArrow, faFilter,faCaretUp, faCaretDown,
  faMinusCircle, faCheckCircle, faSearch, faPaperclip, faPencilAlt, faTrash, faCopy,
  faExpandAlt, faWeightHanging, faStar, farStar, faMinus, faAt, farEye, faCheck,
  faUser, faBriefcase, faBell, faGlobe, faFileUpload, faEllipsisH, faSearchLocation
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
