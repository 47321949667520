import { createSlice } from '@reduxjs/toolkit'

export interface UserState {
	rightToLeft: any;
}

const initialState: UserState = {
	rightToLeft: true,
}

export const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    setRightToLeft: (state, action) => {
      return {
        ...state,
        rightToLeft: action.payload
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setRightToLeft } = rootSlice.actions;

export default rootSlice.reducer;