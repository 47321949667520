import React, { useState, useEffect, useMemo, useRef } from "react";
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { removeBgBorder } from "Constants/general";
import {
  lastRebarsWithoutPrice,
  lastRebarsWithPrice,
  rebarsWithoutPriceHead,
  rebarsWithoutPriceBody,
  rebarsWithPrice,
} from "Constants/rebar-constants";
import other from "../../../../assets/img/order__page/other.png";
import diameterIcon from "../../../../assets/img/icon/diameter-blue.svg";
import steelTypeIcon from "../../../../assets/img/icon/steeltype-blue.svg";
import priceIcon from "../../../../assets/img/icon/price.svg";
import { useTranslation } from "react-i18next";
import { getCurrentOrderRebars } from "Redux/middlwares/order";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DraggableTableRows from "./DraggableTableRows";
import StaticTableRow from "./StaticTableRow";

import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import InfiniteScroll from "react-infinite-scroll-component";
import BarLoader from "react-bar-loader";
import { getOrderStats } from "Services/order";
import { REBAR_SUBCLASS_TYPE } from "Constants/rebar-constants";

import "../index.scss";

export const RebarImage = ({ preview_image, index }: any) => {
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const [flip, setFlip] = useState(0);
  const [flip1, setFlip1] = useState(0);

  return (
    <div
      style={
        flip > 1.35
          ? {
              transform: "rotate(270deg)",
              transformOrigin: "center",
              display: "flex",
              justifyContent: "center",
              height: "290px",
              width: "70px",
              ...(rightToLeft
                ? { marginRight: "130px" }
                : { marginLeft: "100px" }),
            }
          : {
              width: "290px",
              height: "70px",
              ...(flip >= 0.3 && flip <= 1.349
                ? {
                    display: "flex",
                    justifyContent: "center",
                  }
                : {}),
            }
      }
    >
      <img
        className="img__icon"
        id={"rebarPreviewImage" + index}
        src={preview_image ? preview_image.file_field : other}
        style={flip >= 0.3 && flip <= 1.349 ? { width: flip1 * 70 + "px" } : {}}
        onLoad={(e: any) => {
          const fp = e.target.naturalHeight / e.target.naturalWidth;
          setFlip(fp);
          setFlip1(e.target.naturalWidth / e.target.naturalHeight);
        }}
        alt="map icon"
      />
    </div>
  );
};

const RebarsTable = ({
  priceShow,
  openItemModal,
  selectedTool,
  setSelectedTool,
  handleImagePreview,
  rebarPrice,
  openPriceListModal,
  copyOrderItem,
  handleUpdate,
  deleteTool,
  setRebarData,
  rebarData,
  checkRowNums,
  edit,
  editOrderId,
}: any) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["newOrder", "common", "measurements"]);
  const userData = useAppSelector((state) => state.user.userData);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const currentOrderRebars = useAppSelector(
    (state) => state.rebar.currentOrderRebars
  );

  const nextOrderRebarPage = useAppSelector(
    (state) => state.rebar.orderRebarPage
  );

  const [activeId, setActiveId] = useState();
  const [activeIndex, setActiveIndex] = useState();
  const [hasMore, setHasMore] = useState(false);

  const [isMouseOver, setIsMouseOver] = useState(false);
  const [mousePosition, setMousePosition] = useState(0);
  const [inProgress, setInProgress] = useState(false);
  const [rebarUnits, setRebarUnits] = useState(0);
  const [rebarTons, setRebarTons] = useState(0);

  const RebarPositionRef = useRef(null);
  const RebarTableRef = useRef(null);

  useEffect(() => {
    getOrderStats(REBAR_SUBCLASS_TYPE, editOrderId)
      .then((res) => {
        setRebarUnits(res.data.total_quantity)
        setRebarTons((res.data.total_weight/1000).toFixed(2) )
      });
  }, [currentOrderRebars, editOrderId]);

  useEffect(() => {
    if (currentOrderRebars) {
      setRebarData(currentOrderRebars);
      if (nextOrderRebarPage !== false) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  }, [currentOrderRebars]);

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    const row = rebarData.find((original) => original.id === activeId);
    return row;
  }, [activeId]);

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  function handleDragStart(event) {
    setActiveId(event.active.id);
    const activeRow = rebarData.filter((data) => data.id === event.active.id);
    const Index = rebarData.indexOf(activeRow[0]);

    setActiveIndex(Index);
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    const activeRow = rebarData.filter((data) => data.id === active.id);
    const overRow = rebarData.filter((data) => data.id === over.id);

    if (active.id !== over.id) {
      let oldIndex;
      let newIndex;
      let moveData;
      setRebarData((data) => {
        oldIndex = rebarData.indexOf(activeRow[0]);
        newIndex = rebarData.indexOf(overRow[0]);
        moveData = arrayMove(data, oldIndex, newIndex);
        return moveData;
      });

      checkRowNums(oldIndex, newIndex, moveData, REBAR_SUBCLASS_TYPE);
    }

    setActiveId(null);
    setActiveIndex(null);
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  const onloadFunction = async () => {
    try {
      if (nextOrderRebarPage !== false && nextOrderRebarPage > 1) {
        if (edit) {
          await dispatch(getCurrentOrderRebars(edit, editOrderId, nextOrderRebarPage));
        } else {
          await dispatch(getCurrentOrderRebars(edit, 0, nextOrderRebarPage));
        }
      }
    } catch (error) {
      setHasMore(false);
    }
  };

  useEffect(() => {
    const icon = RebarPositionRef.current;
    const table = RebarTableRef.current;
    if (isMouseOver && icon && table) {
      const rect = icon.getBoundingClientRect();
      const top = rect.top + window.pageYOffset;
      const tab = table.getBoundingClientRect();
      const tabtop = tab.top + window.pageYOffset;
      setMousePosition(top - tabtop);
    }
  }, [isMouseOver]);

  return (
    <section id="rebars__table" className="order__tables mt-5">
      <div className="container p-0">
        <div className="table__wrapper">
          {/* <!-- table header  --> */}
          <div className="d-flex justify-content-between">
            <div className="table__header d-flex flex-row">
              {/* <!-- table title  --> */}
              <div className="table__title d-flex align-items-center justify-content-center">
                <h2 className="text-center text-white text-uppercase m-0">
                  {t("Rebars", { ns: "common" })}
                </h2>
              </div>
              <div
                className={
                  "table__tit__btn d-flex align-items-center justify-content-center "
                  // + (rightToLeft ? "mr-2" : "ml-2")
                }
                style={{ cursor: "pointer" }}
                onClick={() => openItemModal(1)}
              >
                <span className="text-white bg-danger text-center text-uppercase">
                  {t("Add New Rebar")}
                </span>
              </div>
            </div>
            {/* {userData !== null && (
              <button onClick={openPriceListModal} className="price-button d-flex align-items-center">
                <span className={rightToLeft ? 'ml-2' : 'mr-2'}>
                  <img src={priceIcon} alt="priceIcon" style={{width:'22px', height: '22px'}}/>
                </span>
                {t("Enter your prices")}
              </button>
            )} */}
          </div>
          {/* <!-- real table  --> */}
          <div>
            <DndContext
              sensors={sensors}
              onDragEnd={handleDragEnd}
              onDragStart={handleDragStart}
              onDragCancel={handleDragCancel}
              collisionDetection={closestCenter}
              modifiers={[restrictToVerticalAxis]}
            >
              <table
                className={
                  "table table mb-0 custom-tool-table" +
                  (rightToLeft ? " rightToLeft" : "")
                }
                style={{ position: "relative" }}
                ref={RebarTableRef}
              >
                {/* <!-- table head title section  --> */}
                <thead>
                  <tr style={priceShow ? rebarsWithPrice : rebarsWithoutPriceHead}>
                    <th
                      className={
                        "d-flex justify-content-center align-items-center"
                      }
                    >
                      {t("row", { ns: "common" })}
                    </th>
                    <th
                      scope="col"
                      className={
                        "text-capitalize d-flex justify-content-start align-items-center " +
                        (rightToLeft ? "dark-border-right" : "dark-border-left")
                      }
                    >
                      {t("sketch")}
                    </th>
                    <th
                      scope="col"
                      className={
                        "text-capitalize d-flex align-items-center justify-content-start text-center sm__wrap " +
                        (rightToLeft ? "dark-border-right" : "dark-border-left")
                      }
                    >
                      <span
                        className={
                          "d-flex flex-column align-items-start  " +
                          (rightToLeft ? "mr-1" : "ml-1")
                        }
                      >
                        <span>{t("Name", { ns: "newOrder" })}</span>
                      </span>
                    </th>

                    <th
                      scope="col"
                      className={
                        "text-capitalize d-flex align-items-center justify-content-start text-center sm__wrap " +
                        (rightToLeft ? "dark-border-right" : "dark-border-left")
                      }
                    >
                      <img
                        className="hei__icon"
                        src={steelTypeIcon}
                        alt="steel"
                        style={{
                          height: "20px",
                          width: "15px",
                        }}
                      />
                      <span className="d-flex align-items-start">
                        <p className={"ml-1 mr-1"}>
                          {rightToLeft
                            ? t("type", { ns: "common" })
                            : t("steel", { ns: "newOrder" })}
                        </p>
                        <p>
                          {rightToLeft
                            ? t("steel", { ns: "newOrder" })
                            : t("type", { ns: "common" })}
                        </p>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className={
                        "text-capitalize d-flex align-items-center justify-content-start text-center sm__wrap " +
                        (rightToLeft ? "dark-border-right" : "dark-border-left")
                      }
                    >
                      <span>
                        <img
                          className="img__icon"
                          src={diameterIcon}
                          alt="diameter"
                          style={{
                            height: "15px",
                            width: "15px",
                          }}
                        />
                      </span>
                      <span
                        className={
                          "text-capitalize d-flex align-items-center  " +
                          (rightToLeft ? "mr-1" : "ml-1")
                        }
                      >
                        <span>{t("diameter", { ns: "common" })}</span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className={
                        "text-capitalize d-flex align-items-center " +
                        (rightToLeft ? "dark-border-right" : "dark-border-left")
                      }
                    >
                      <span className="text-capitalize">
                        {t("length", { ns: "common" })}
                      </span>
                    </th>
                    <th
                      scope="col"
                      className={
                        "text-capitalize d-flex align-items-center justify-content-start text-center " +
                        (rightToLeft ? "dark-border-right" : "dark-border-left")
                      }
                    >
                      {t("quantity", { ns: "common" })}
                    </th>

                    <th
                      scope="col"
                      className="text-capitalize dark-border-right dark-border-left"
                      style={{ padding: "0px", width: "100%" }}
                    >
                      <div className="two__rows_sec">
                        <div className="top__sec">
                          <span>{t("weight", { ns: "common" })}</span>
                        </div>
                        <div
                          className={
                            "d-flex justify-content-start align-items-center bottom_sec h-100 pb-1 " +
                            (rightToLeft
                              ? "bottom_sec-pr text-right"
                              : "bottom_sec-pl text-left")
                          }
                        >
                          <span style={{ width: "50%" }}>
                            {t("unit", { ns: "common" })}
                          </span>
                          <span>{t("total", { ns: "common" })}</span>
                        </div>
                      </div>
                    </th>
                    {priceShow && <></>}
                  </tr>
                </thead>
                {/* <!-- table body data section  --> */}
                <tbody
                  style={
                    currentOrderRebars.length > 9
                      ? {
                          height: "720px",
                          overflow: "auto",
                          display: "block",
                          scrollbarWidth: "none",
                        }
                      : {
                        scrollbarWidth: "none",
                      }
                  }
                  id="scrollableTable"
                >
                  {/* <!-- table data row   --> */}
                  <SortableContext
                    items={rebarData}
                    strategy={verticalListSortingStrategy}
                  >
                    <InfiniteScroll
                      dataLength={rebarData.length}
                      next={onloadFunction}
                      hasMore={hasMore}
                      loader={<BarLoader color="#F84343" height="4" />}
                      scrollableTarget="scrollableTable"
                      style={{
                        scrollbarWidth: "none",
                      }}
                    >
                      {rebarData.length > 0 &&
                        rebarData.map((itemRebar, index) => (
                          <DraggableTableRows
                            index={index}
                            key={itemRebar.id}
                            id={itemRebar.id}
                            itemRebar={itemRebar}
                            priceShow={priceShow}
                            setSelectedTool={setSelectedTool}
                            handleImagePreview={handleImagePreview}
                            setIsMouseOver={setIsMouseOver}
                            RebarPositionRef={RebarPositionRef}
                          />
                        ))}
                    </InfiniteScroll>
                  </SortableContext>
                </tbody>
                <tbody className="last">
                  <tr
                    style={
                      priceShow ? lastRebarsWithPrice : lastRebarsWithoutPrice
                    }
                  >
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={removeBgBorder}></td>
                    <td className="justify-content-center">{rebarUnits}</td>
                    <td className="justify-content-center" colSpan={2}>
                      {rebarTons}
                    </td>
                    {priceShow && <td colSpan={2}>{rebarPrice}</td>}
                  </tr>
                  <tr
                    style={
                      priceShow ? lastRebarsWithPrice : lastRebarsWithoutPrice
                    }
                  >
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={removeBgBorder}></td>
                    <td className="justify-content-center">
                      {t("units", { ns: "common" })}
                    </td>
                    <td
                      className="justify-content-center"
                      colSpan={2}
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("weight", { ns: "common" })} (
                      {t("ton", { ns: "measurements" })})
                    </td>
                    {priceShow && (
                      <td colSpan={2}>{t("Price", { ns: "common" })} (NIS)</td>
                    )}
                  </tr>
                </tbody>
                {isMouseOver && !inProgress && (
                  <div
                    className="dropdown-content"
                    style={
                      rightToLeft
                        ? { top: `${mousePosition}px`, right: "45px" }
                        : { top: `${mousePosition}px`, left: "45px" }
                    }
                    onMouseEnter={(e) => {
                      setIsMouseOver(true);
                    }}
                    onMouseLeave={() => setIsMouseOver(false)}
                  >
                    <div
                      className="drop-item"
                      onClick={async () => {
                        setIsMouseOver(false);
                        setInProgress(true);
                        copyOrderItem().then(() => {
                          setInProgress(false);
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        className={
                          "item-icon " + (rightToLeft ? "item-ml" : "item-mr")
                        }
                        icon="copy"
                      ></FontAwesomeIcon>
                      <span>{t("Duplicate", { ns: "newOrder" })}</span>
                    </div>
                    <div className="drop-item" onClick={handleUpdate}>
                      <FontAwesomeIcon
                        className={
                          "item-icon " + (rightToLeft ? "item-ml" : "item-mr")
                        }
                        icon="pencil-alt"
                      ></FontAwesomeIcon>
                      <span>{t("edit", { ns: "common" })}</span>
                    </div>
                    <div className="drop-item" onClick={deleteTool}>
                      <FontAwesomeIcon
                        className={
                          "item-icon " + (rightToLeft ? "item-ml" : "item-mr")
                        }
                        icon="trash"
                      ></FontAwesomeIcon>
                      <span>{t("Delete", { ns: "common" })}</span>
                    </div>
                  </div>
                )}
              </table>
              <DragOverlay>
                {activeId && (
                  <table
                    className={
                      "table table mb-0 custom-tool-table" +
                      (rightToLeft ? " rightToLeft" : "")
                    }
                  >
                    <tbody>
                      <StaticTableRow
                        id={selectedRow.id}
                        index={activeIndex}
                        itemRebar={selectedRow}
                        copyOrderItem={copyOrderItem}
                        handleUpdate={handleUpdate}
                        deleteTool={deleteTool}
                        priceShow={priceShow}
                        setSelectedTool={setSelectedTool}
                        handleImagePreview={handleImagePreview}
                      />
                    </tbody>
                  </table>
                )}
              </DragOverlay>
            </DndContext>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RebarsTable;
