import { useState, useEffect, useRef, useMemo } from "react";
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import "../index.scss";
import order from "Redux/features/order";
import LeftArrow from "../../../../../assets/img/left-arrow.svg";
import error_sign from "../../../../../assets/img/error_sign.svg";
import warning_sign from "../../../../../assets/img/warning_sign.svg";
import { commaSeparated } from "Constants/general";
import {
  angles,
  labels,
  rebarErrorTypes,
  sideMaxLength,
  sideMinLength,
  rebarSidebarInput,
} from "Constants/rebar-constants";
import { fetchSteelDiameters } from "Redux/middlwares/steelType";
import add from "../../../../../assets/img/add_note.svg";
import remove from "../../../../../assets/img/remove_note.svg";
import { SHAPE_MODE } from "../../../../../constants/rebar-constants.ts";
import { polygonSteelType } from "Constants/rebar-constants";

function InputSidebar({
  orderItem,
  setOrderItem,
  handleChangeDiamter,
  stageRef,
  setAllPoints,
  initiateImage,
  errorsText,
  setErrorsText,
  steelTypeRef,
  diameterRef,
  sidesRef,
  heightsRef,
  amountRef,
  addButtonRef,
  finishButtonRef,
  selectedShape,
  standardShapes,
  setSymmetrical,
  isRotatable,
  shape,
  setShape,
  setSides,
  setAngle,
  onlyRightAngles,
  saveRebar,
  edit,
  addError,
  removeError,
  setIsShapesError,
  isShapesError,
  setShowCatalog,
  setDefaultShape,
  setRebarShapeMode,
  rebarShapeMode,
  freeAngles,
  freeLengths,
  onLineLengthChange,
  onAngleChange,
  setPath,
  path,
  saveFreeFormShapes,
  setRebarHeight,
  rebarHeight,
  handleHeightChange,
  setShowHeight,
  showHeight,
  resetFreeFormShape,
  setIsStopped,
  changeShape,
  setSelectedShape,
  previousShape,
  setPreviousShape,
  setRadius,
  radiusMinMaxValidation,
  setHasDisplayedMin,
  hasDisplayedMin,
  radiusChecks,
  indexToChar,
}: any) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "tool", "measurements", "newOrder"]);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const steelDiameters = useAppSelector(
    (state) => state.steelType.steelDiameters
  );
  const steelTypes = useAppSelector((state) => state.steelType.steelTypes);
  const [showNote, setShowNote] = useState(false);
  const siderBarRefs = useRef<any[]>([]);
  const sidebarBody = useRef(null);
  const sidebarFooter = useRef(null);
  const [shapeChanged, setShapeChanged] = useState(false);

  const editModeClass = useMemo(() => {
    if (edit) {
      return "_updated";
    } else return "";
  }, [edit]);

  const handleFocus = () => {
    if (isShapesError) {
      addButtonRef.current.classList.add("add_btn_error");
      addButtonRef.current.style.border = "2px solid #CD2017";
    } else {
      addButtonRef.current.classList.remove("add_btn_error");
      addButtonRef.current.style.border = "2px solid #00B38F";
    }
  };

  const addUpdatedClass = (element) => {
    if (element) {
      const className = element.className;
      if (!className.includes("_updated")) {
        element.classList.remove("rebar_sidebar_input");
        element.classList.add("rebar_sidebar_input_updated");
      }
    }
  };

  useEffect(() => {
    sidesRef.current = [];
    heightsRef.current = [];
    setErrorsText([]);
  }, [shape.id]);

  useEffect(() => {
    siderBarRefs.current[rebarSidebarInput.REBAR_NAME].focus();
  }, [shape.id]);

  const handleLineLengthChange = (event) => {
    const newLineLength = {
      id: event.target.name,
      length: Math.round(Number(event.target.value)) || 0,
    };
    onLineLengthChange(newLineLength);
  };

  // angle input handlers
  const handleAngleChange = (event) => {
    //event.preventDefault();
    const newAngle = Number(event.target.value);
    const angleBetweenLines = freeAngles.find(
      (angle) => angle.id === event.target.name || angle.id === parseInt(event.target.name)
    );
    if (angleBetweenLines) {
      onAngleChange(
        {
          ...angleBetweenLines,
          angle: newAngle,
        },
        angleBetweenLines.angle
      );
    }
  };

  const handleRadiusChange = (value, index) => {
    let newEdgeRadius = path.edges.slice();

    let radius = parseInt(value);
    if (radius < 0) return;

    radius = radiusChecks(radius, value, index);

    const line1 = freeLengths[index].length;
    const line2 = freeLengths[index + 1].length;

    const minLength = line1 < line2 ? line1 : line2;
    if (radius !== null && radius >= 0) {
      newEdgeRadius[index] = {
        ...newEdgeRadius[index],
        cornerRadius: radius,
      };
    }

    radiusMinMaxValidation(freeLengths, radius, index, minLength, true);

    const newPath = {
      ...path,
      edges: newEdgeRadius,
    };
    setPath(newPath);
  };

  useEffect(() => {
    if (sidebarBody.current.scrollHeight > sidebarBody.current.clientHeight) {
      sidebarFooter.current.classList.add("footer_shadow");
    } else {
      sidebarFooter.current.classList.remove("footer_shadow");
    }
  }, [shapeChanged]);

  const handleAddButton = async () => {
    if (rebarShapeMode === SHAPE_MODE.catalog) {
      saveRebar(true, true)
    } else if (rebarShapeMode === SHAPE_MODE.freeform) {
      await saveFreeFormShapes(true, true);
    }
  };


  useEffect(()=>{
    if(shape.diameter === null || shape.diameter === undefined){
      if(steelDiameters[polygonSteelType]){
        const steelDiameterWeight = steelDiameters[polygonSteelType][0];
        setShape((prev: any) => ({
          ...prev,
          steel_type: polygonSteelType,
          diameter: steelDiameterWeight,
          unit_weight: (
            (shape.rebar_side.reduce(
              (sum: any, b: any) => sum + b.length,
              0
            ) /
              100) *
            parseFloat(steelDiameterWeight.weight)
          ).toFixed(2),
        }));
      }
    }
  }, [shape.diameter])

  return (
    <>
      {
        <div className="rebar_left">
          <div className="sidebar_body" ref={sidebarBody}>
            {/* Shapes */}
            <div
              className="rebar_sidebar_container border_bottom ht_25"
              style={{ scrollbarWidth: "none" }}
            >
              <div
                className="back_img"
                onClick={() => {
                  setRebarHeight([]);
                  setSelectedShape(
                    {gIndex: -1, sIndex:-1}
                  )
                  changeShape(-1,-1)
                  setPreviousShape(null)
                  setShowCatalog(true);
                  setPath({ id: "path", lines: [], edges: [] });
                  setShowHeight(false);
                  resetFreeFormShape();
                  setIsStopped(true);
                }}
              >
                <img
                  src={LeftArrow}
                  style={rightToLeft ? { scale: "-1" } : {}}
                  alt="<"
                />
              </div>

              <h1
                className="rebar_units mb-0"
                style={
                  rightToLeft
                    ? {
                        paddingRight: "15px",
                      }
                    : { paddingLeft: "15px" }
                }
              >
                {t("shapes", { ns: "common" })}
              </h1>
            </div>

            {/* Rebar Name */}
            <div className="rebar_sidebar_container border_bottom">
              <div className="net_name">
                <h1 className="nets_sidebar_label mb-0">
                  {t("rebar name", { ns: "common" })}
                </h1>
              </div>

              <input
                type="text"
                className={
                  "para text-center text-ash input-inside rebar_sidebar_input" +
                  editModeClass
                }
                style={
                  rightToLeft
                    ? {
                        marginRight: "auto",
                      }
                    : { marginLeft: "auto" }
                }
                value={shape.name || ""}
                onFocus={(e: any) => e.target.select()}
                onChange={(e) => setShape({ ...shape, name: e.target.value })}
                maxLength={20}
                onBlur={() => {
                  addUpdatedClass(
                    siderBarRefs.current[rebarSidebarInput.REBAR_NAME]
                  );
                }}
                ref={(el) => {
                  siderBarRefs.current[rebarSidebarInput.REBAR_NAME] = el;
                }}
                onKeyDown={(e) => {
                  if (e.key === "ArrowDown" || e.key === "Enter") {
                    siderBarRefs.current[rebarSidebarInput.DIAMETER].focus();
                  }
                }}
              />
            </div>

            {/* Diameter */}
            <div className="rebar_sidebar_container">
              <div className="total_lengths">
                <h1 className="nets_sidebar_label mb-0">
                  {t("diameter", { ns: "tool" })}
                  <span
                    className={"rebar_units " + (rightToLeft ? "pr-1" : "pl-1")}
                    style={
                      rightToLeft
                        ? {
                            paddingRight: "3px",
                          }
                        : { paddingLeft: "3px" }
                    }
                  >
                    {t("mm", { ns: "tool" })}
                  </span>
                </h1>
              </div>
              <select
                className={
                  "form-control form-control-sm  rebar_sidebar_input" +
                  editModeClass +
                  (errorsText.some(
                    (error) => error.errorType === rebarErrorTypes.DIAMETER
                  )
                    ? "_error"
                    : "")
                }
                style={
                  rightToLeft
                    ? {
                        marginRight: "auto",
                      }
                    : { marginLeft: "auto" }
                }
                value={
                  shape.diameter && shape.diameter.id ? shape.diameter.id : ""
                }
                onChange={handleChangeDiamter}
                ref={(el) => {
                  siderBarRefs.current[rebarSidebarInput.DIAMETER] = el;
                }}
                onBlur={() => {
                  addUpdatedClass(
                    siderBarRefs.current[rebarSidebarInput.DIAMETER]
                  );
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "ArrowDown") {
                    e.preventDefault();
                    if (sidesRef.current && sidesRef.current.length > 0)
                      sidesRef.current[0].focus();
                  }
                  if (e.key === "ArrowUp") {
                    e.preventDefault();
                    siderBarRefs.current[rebarSidebarInput.REBAR_NAME].focus();
                  }
                }}
              >
                {shape.steel_type in steelDiameters &&
                  steelDiameters[shape.steel_type].map(
                    (reDi: any, index: number) => (
                      <option
                        key={"rebar-diameter-options-" + index}
                        value={reDi.id}
                      >
                        {parseFloat(reDi?.steel_diameter)}
                      </option>
                    )
                  )}
              </select>
            </div>

            {/* Steel Type */}
            <div className="rebar_sidebar_container border_bottom">
              <div className="total_lengths">
                <h1 className="rebar_sidebar_label mb-0">
                  {t("steel type", { ns: "common" })}
                  <span
                    className={"rebar_units " + (rightToLeft ? "pr-1" : "pl-1")}
                    style={
                      rightToLeft
                        ? {
                            paddingRight: "3px",
                          }
                        : { paddingLeft: "3px" }
                    }
                  ></span>
                </h1>
              </div>
              <select
                className="form-control form-control-sm rebar_sidebar_input_grey rebar_input_select"
                style={
                  rightToLeft
                    ? {
                        marginRight: "auto",
                      }
                    : { marginLeft: "auto" }
                }
                value={shape.steel_type || ""}
                onChange={({ target }) => {
                  const prev_diameter = shape.diameter;
                  let steelDiameterWeight; 
                  if (prev_diameter){
                    steelDiameterWeight = steelDiameters[
                      parseInt(target.value)
                    ]?.find(
                      (item: any) =>
                        item.steel_diameter === prev_diameter.steel_diameter
                    );  
                  }
                  else{
                    steelDiameterWeight = steelDiameters[
                      parseInt(target.value)
                    ][0];
                  }
                  setShape((prev: any) => ({
                    ...prev,
                    steel_type: parseInt(target.value),
                    diameter: steelDiameterWeight,
                    unit_weight: (
                      (shape.rebar_side.reduce(
                        (sum: any, b: any) => sum + b.length,
                        0
                      ) /
                        100) *
                      parseFloat(steelDiameterWeight.weight)
                    ).toFixed(2),
                  }));
                  setShapeChanged(true);
                }}
                ref={(el) => {
                  siderBarRefs.current[rebarSidebarInput.STEEL_TYPE] = el;
                }}
              >
                {steelTypes.map((st: { id: number; name: string }) => (
                  <option
                    key={"rebar-steeltype-options-" + st.id}
                    value={st.id}
                  >
                    {st.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Sides & Angles */}
            <div className="sidebar_container">
              <div className="lengths">
                <h1 className="nets_sidebar_label_large mb-0">
                  {t("lengths", { ns: "common" })}
                  <span
                    className={"rebar_units " + (rightToLeft ? "pr-1" : "pl-1")}
                    style={
                      rightToLeft
                        ? {
                            paddingRight: "3px",
                          }
                        : { paddingLeft: "3px" }
                    }
                  >
                    {t("cm", { ns: "tool" })}
                  </span>
                </h1>
              </div>

              <div className="angles">
                <h1
                  className="rebar_sidebar_label_large mb-0"
                  style={
                    rightToLeft
                      ? {
                          paddingLeft: "25px",
                        }
                      : { paddingRight: "15px" }
                  }
                >
                  {t("angles", { ns: "common" })}
                </h1>
              </div>
              {/* <div className="angles">
                <h1
                  className="rebar_sidebar_label_large mb-0"
                  style={
                    rightToLeft
                      ? {
                          paddingLeft: "25px",
                        }
                      : { paddingRight: "15px" }
                  }
                >
                  {t("radius", { ns: "common" })}
                </h1>
              </div> */}
            </div>

            {shape.rebar_side &&
              rebarShapeMode === SHAPE_MODE.catalog &&
              shape.rebar_side.map((side: any, index: number) => (
                <div
                  className={"rebar_sidebar_container "}
                  key={"rebar_sidebar_" + index}
                >
                  <div
                    className={
                      "rebar_sides_label" + (rightToLeft ? "_heb" : "")
                    }
                  >
                    <p>{labels[side.label]}</p>
                  </div>
                  <input
                    key={"len-d" + index}
                    type="number"
                    className={
                      "para text-center text-ash rebar_sidebar_input" +
                      editModeClass +
                      (errorsText.some(
                        (error) => error.errorType === rebarErrorTypes.SIDE
                      ) &&
                      !(
                        side.length >= sideMinLength &&
                        side.length < sideMaxLength
                      )
                        ? "_error"
                        : (errorsText.some(
                            (error) => error.errorType === rebarErrorTypes.EAR
                          ) &&
                            index === 0 &&
                            side.length < shape.diameter.steel_diameter) ||
                          (shape.diameter.steel_diameter &&
                            index === shape.rebar_side.length - 1 &&
                            side.length < shape.diameter.steel_diameter)
                        ? "_warning"
                        : "")
                    }
                    // style={{ width: "60px" }}
                    onBlur={() => {
                      addUpdatedClass(sidesRef.current[index]);
                    }}
                    ref={(el) => {
                      if (el) sidesRef.current[index] = el;
                    }}
                    onFocus={(e) => e.target.select()}
                    value={side.length || ""}
                    onChange={(e) => {
                      setSides(e.target.value, index);
                      setShapeChanged(true);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === "ArrowDown") {
                        e.preventDefault();
                        if (index !== shape.rebar_side.length - 1) {
                          sidesRef.current[index + 1].focus();
                        } else {
                          if (rebarHeight.length > 0) {
                            if (showHeight) {
                              if (
                                heightsRef.current &&
                                heightsRef.current.length > 0
                              )
                                heightsRef.current[0].focus();
                            } else if (showNote) {
                              siderBarRefs.current[
                                rebarSidebarInput.NOTE
                              ].focus();
                            } else {
                              siderBarRefs.current[
                                rebarSidebarInput.QUANTITY
                              ].focus();
                            }
                          } else {
                            if (showNote) {
                              siderBarRefs.current[
                                rebarSidebarInput.NOTE
                              ].focus();
                            } else {
                              siderBarRefs.current[
                                rebarSidebarInput.QUANTITY
                              ].focus();
                            }
                          }
                        }
                      } else if (e.key === "ArrowUp") {
                        e.preventDefault();
                        if (index !== 0) {
                          sidesRef.current[index - 1].focus();
                        } else {
                          siderBarRefs.current[
                            rebarSidebarInput.DIAMETER
                          ].focus();
                        }
                      }
                    }}
                  />
                  {index < shape.rebar_side.length - 1 && (
                    <input
                      key={"angle-d" + index}
                      type="number"
                      className="para text-center text-ash rebar_sidebar_input_angle"
                      style={
                        rightToLeft
                          ? {
                              marginRight: "auto",
                            }
                          : {
                              marginLeft: "auto",
                            }
                      }
                      onFocus={(e) => e.target.select()}
                      value={side.angle || ""}
                      onChange={(e) => {
                        setAngle(e.target.value, index);
                        setShapeChanged(true);
                      }}
                      disabled={onlyRightAngles}
                    />
                  )}
                  {/* {index < shape.rebar_side.length - 1 && (
                    <input
                      key={"radius-d" + index}
                      type="text"
                      className={
                        "para text-center text-ash rebar_sidebar_input_radius" +
                        ((errorsText.some(
                          (error) =>
                            error.errorType === rebarErrorTypes.RADIUS ||
                            error.errorType === rebarErrorTypes.RADIUS_MAX
                        ) &&
                          side.radius <= 0) ||
                        side.radius >
                          (side.length < shape.rebar_side[index + 1].length
                            ? side.length / 2
                            : shape.rebar_side[index + 1].length / 2)
                          ? "_error"
                          : "")
                      }
                      style={
                        rightToLeft
                          ? {
                              marginRight: "auto",
                            }
                          : {
                              marginLeft: "auto",
                            }
                      }
                      onFocus={(e) => e.target.select()}
                      value={
                        side.radius <= 1
                          ? hasDisplayedMin[index] && side.radius >= 1
                            ? side.radius
                            : side.radius === ""
                            ? ""
                            : "min"
                          : side.radius
                      }
                      onKeyDown={(e) => {
                        if (e.key === "ArrowDown") {
                          if (e.target.value === "min") {
                            setRadius("", index);
                          } else {
                            setRadius(e.target.value - 1, index);
                          }
                        } else if (e.key === "ArrowUp") {
                          if (e.target.value === "min") {
                            setRadius(Number(1), index);
                          } else {
                            setRadius(Number(e.target.value) + 1, index);
                          }
                        }
                      }}
                      onChange={(e) => {
                        setRadius(e.target.value, index);
                      }}
                    />
                  )} */}
                </div>
              ))}

            {freeLengths.length > 0 &&
              freeLengths.map((side: any, index: number) => (
                <>
                  <div className={"rebar_sidebar_container "}>
                    <div
                      className={
                        "rebar_sides_label" + (rightToLeft ? "_heb" : "")
                      }
                    >
                      <p>{indexToChar(index)}</p>
                    </div>
                    <input
                      name={side.id}
                      key={"len-d" + index}
                      type="number"
                      className={
                        "para text-center text-ash rebar_sidebar_input"
                        // (errorsText.some(
                        //   (error) => error.errorType === rebarErrorTypes.SIDE
                        // ) &&
                        // !(
                        //   side.length >= sideMinLength &&
                        //   side.length < sideMaxLength
                        // )
                        //   ? "_error"
                        //   : (errorsText.some(
                        //       (error) => error.errorType === rebarErrorTypes.EAR
                        //     ) &&
                        //       index === 0 &&
                        //       side.length < shape.diameter.steel_diameter) ||
                        //     (shape.diameter.steel_diameter &&
                        //       index === shape.rebar_side.length - 1 &&
                        //       side.length < shape.diameter.steel_diameter)
                        //   ? "_warning"
                        //   : "")
                      }
                      // style={{ width: "60px" }}
                      ref={(el) => {
                        if (el) sidesRef.current[index] = el;
                      }}
                      onFocus={(e) => e.target.select()}
                      value={side.length || ""}
                      onChange={handleLineLengthChange}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === "ArrowDown") {
                          e.preventDefault();
                          if (index !== freeLengths.length - 1) {
                            sidesRef.current[index + 1].focus();
                          } else {
                            if (rebarHeight.length > 0) {
                              if (showHeight) {
                                if (
                                  heightsRef.current &&
                                  heightsRef.current.length > 0
                                )
                                  heightsRef.current[0].focus();
                              } else if (showNote) {
                                siderBarRefs.current[
                                  rebarSidebarInput.NOTE
                                ].focus();
                              } else {
                                siderBarRefs.current[
                                  rebarSidebarInput.QUANTITY
                                ].focus();
                              }
                            } else {
                              if (showNote) {
                                siderBarRefs.current[
                                  rebarSidebarInput.NOTE
                                ].focus();
                              } else {
                                siderBarRefs.current[
                                  rebarSidebarInput.QUANTITY
                                ].focus();
                              }
                            }
                          }
                        } else if (e.key === "ArrowUp") {
                          e.preventDefault();
                          if (index !== 0) {
                            sidesRef.current[index - 1].focus();
                          } else {
                            siderBarRefs.current[
                              rebarSidebarInput.DIAMETER
                            ].focus();
                          }
                        }
                      }}
                    />
                    {index < freeLengths.length - 1 && (
                      <input
                        name={freeAngles[index].id}
                        key={"angle-d" + index}
                        type="number"
                        className="para text-center text-ash rebar_sidebar_input_angle"
                        style={
                          rightToLeft
                            ? {
                                marginRight: "auto",
                              }
                            : {
                                marginLeft: "auto",
                              }
                        }
                        onFocus={(e) => e.target.select()}
                        value={freeAngles[index].angle || ""}
                        onChange={handleAngleChange}
                        disabled={onlyRightAngles}
                      />
                    )}
                    {/* {index < freeLengths.length - 1 && (
                      <input
                        name={path.edges[index]?.id}
                        key={"radius-d" + index}
                        type="text"
                        className={
                          "para text-center text-ash rebar_sidebar_input_radius" +
                          ((errorsText.some(
                            (error) =>
                              error.errorType === rebarErrorTypes.RADIUS ||
                              error.errorType === rebarErrorTypes.RADIUS_MAX
                          ) &&
                            path.edges[index]?.cornerRadius <= 0) ||
                          path.edges[index]?.cornerRadius >
                            (freeLengths[index].length <
                            freeLengths[index + 1].length
                              ? freeLengths[index].length / 2
                              : freeLengths[index + 1].length / 2)
                            ? "_error"
                            : "")
                        }
                        style={
                          rightToLeft
                            ? {
                                marginRight: "auto",
                              }
                            : {
                                marginLeft: "auto",
                              }
                        }
                        onFocus={(e) => e.target.select()}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowDown") {
                            if (e.target.value === "min") {
                              handleRadiusChange("", index);
                            } else {
                              handleRadiusChange(e.target.value - 1, index);
                            }
                          } else if (e.key === "ArrowUp") {
                            if (e.target.value === "min") {
                              handleRadiusChange(Number(1), index);
                            } else {
                              handleRadiusChange(
                                Number(e.target.value) + 1,
                                index
                              );
                            }
                          }
                        }}
                        value={
                          path.edges[index]?.cornerRadius <= 1
                            ? hasDisplayedMin[index] &&
                              path.edges[index]?.cornerRadius >= 1
                              ? path.edges[index]?.cornerRadius
                              : path.edges[index]?.cornerRadius === ""
                              ? ""
                              : "min"
                            : path.edges[index]?.cornerRadius
                        }
                        onChange={(e) =>
                          handleRadiusChange(e.target.value, index)
                        }
                      />
                    )} */}
                  </div>
                </>
              ))}

            {errorsText.some(
              (error) => error.errorType === rebarErrorTypes.EAR
            ) ? (
              <div className="nets_error_continer" key={"ear_warning"}>
                <img className="error_img" src={warning_sign} alt="error" />
                <p
                  className={
                    "rebar_error_text " + (rightToLeft ? "pr-2" : "pl-2")
                  }
                >
                  {t("error_rebar_ear", { ns: "newOrder" })}
                </p>
              </div>
            ) : (
              <></>
            )}

            {errorsText.some(
              (error) => error.errorType === rebarErrorTypes.TOTAL_LENGTH
            ) ? (
              <div className="nets_error_continer" key={"length_warning"}>
                <img src={warning_sign} alt="error" />
                <p
                  className={
                    "rebar_error_text " + (rightToLeft ? "pr-2" : "pl-2")
                  }
                >
                  {t("error_rebar_length", { ns: "newOrder" })}
                </p>
              </div>
            ) : (
              <></>
            )}

            {/* Height
            {rebarHeight.length > 0 &&
              rebarShapeMode === SHAPE_MODE.catalog && (
                <>
                  <div
                    className={
                      "sidebar_container border_top ht_25" +
                      (!showHeight ? " border_bottom" : "")
                    }
                  >
                    <div className="nets_note">
                      <h1 className="rebar_notes_label mb-0">
                        {t("height", { ns: "common" })}
                      </h1>
                      <div
                        className="note_img"
                        onClick={() => {
                          setShowHeight(!showHeight);
                          setTimeout(() => {
                            if (siderBarRefs.current[rebarSidebarInput.NOTE]) {
                              siderBarRefs.current[
                                rebarSidebarInput.NOTE
                              ].focus();
                            }
                          }, 10);
                        }}
                      >
                        {showHeight ? (
                          <img src={remove} className="nets_note_img" alt="+" />
                        ) : (
                          <img src={add} className="nets_note_img" alt="+" />
                        )}
                      </div>
                    </div>
                  </div>
                  {showHeight &&
                    rebarHeight.map((side, index) => (
                      <div className={"rebar_sidebar_container "}>
                        <div
                          className={
                            "rebar_sides_height_label" +
                            (rightToLeft ? "_heb" : "")
                          }
                        >
                          <p>
                            h{" "}
                            <span style={{ fontSize: "12px" }}>
                              {index + 1}
                            </span>
                          </p>
                        </div>
                        <input
                          name={side.id}
                          key={"hei-d" + index}
                          type="number"
                          className={
                            "para text-center text-ash rebar_sidebar_input"
                          }
                          ref={(el) => {
                            if (el) heightsRef.current[index] = el;
                          }}
                          onFocus={(e) => e.target.select()}
                          value={side.height || ""}
                          onChange={(e) => {
                            handleHeightChange(
                              e.target.value,
                              index,
                              side.id,
                              side.angle
                            );
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === "ArrowDown") {
                              e.preventDefault();
                              if (index !== rebarHeight.length - 1) {
                                heightsRef.current[index + 1].focus();
                              } else {
                                if (showNote) {
                                  siderBarRefs.current[
                                    rebarSidebarInput.NOTE
                                  ].focus();
                                } else {
                                  siderBarRefs.current[
                                    rebarSidebarInput.QUANTITY
                                  ].focus();
                                }
                              }
                            } else if (e.key === "ArrowUp") {
                              e.preventDefault();
                              if (index !== 0) {
                                heightsRef.current[index - 1].focus();
                              } else {
                                if (
                                  sidesRef.current &&
                                  sidesRef.current.length > 0
                                )
                                  sidesRef.current[
                                    sidesRef.current.length - 1
                                  ].focus();
                              }
                            }
                          }}
                        />
                      </div>
                    ))}
                </>
              )} */}

            {/* Note */}
            <div
              className={
                "sidebar_container border_top ht_25" +
                (!showNote ? " border_bottom" : "")
              }
            >
              <div className="nets_note">
                <h1 className="rebar_notes_label mb-0">
                  {t("note", { ns: "common" })}
                </h1>
                <div
                  className="note_img"
                  onClick={() => {
                    setShowNote(!showNote);
                    setTimeout(() => {
                      if (siderBarRefs.current[rebarSidebarInput.NOTE]) {
                        siderBarRefs.current[rebarSidebarInput.NOTE].focus();
                      }
                    }, 10);
                  }}
                >
                  {showNote ? (
                    <img src={remove} className="nets_note_img" alt="+" />
                  ) : (
                    <img src={add} className="nets_note_img" alt="+" />
                  )}
                </div>
              </div>
            </div>

            <div
              className={
                "rebar_sidebar_container note_container " +
                (showNote ? " border_bottom" : "")
              }
              style={{ paddingBottom: showNote ? "9px" : "" }}
            >
              {showNote && (
                <input
                  type="text"
                  className="form-control rebar_sidebar_input_note"
                  value={orderItem.production_notes || ""}
                  onChange={(e) =>
                    setOrderItem({
                      ...orderItem,
                      production_notes: e.target.value,
                    })
                  }
                  maxLength={50}
                  ref={(el) => {
                    siderBarRefs.current[rebarSidebarInput.NOTE] = el;
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === "ArrowDown") {
                      siderBarRefs.current[rebarSidebarInput.QUANTITY].focus();
                    }
                    if (e.key === "ArrowUp") {
                      if (
                        showHeight &&
                        rebarHeight.length > 0 &&
                        heightsRef.current &&
                        heightsRef.current.length > 0
                      ) {
                        heightsRef.current[
                          heightsRef.current.length - 1
                        ].focus();
                      } else if (
                        sidesRef.current &&
                        sidesRef.current.length > 0
                      ) {
                        sidesRef.current[sidesRef.current.length - 1].focus();
                      }
                    }
                  }}
                />
              )}
            </div>
          </div>
          <div className="nets_sidebar_footer" ref={sidebarFooter}>
            {/* Weight */}
            <div className="sidebar_container rebar_weight">
              <div className="nets_weight_unit">
                <p className="rebar_units">{t("unit", { ns: "common" })}</p>
                {rebarShapeMode === SHAPE_MODE.catalog ? (
                  <p className="rebar_units">
                    {shape?.rebar_side?.reduce(
                      (acc, val) => acc + val.length,
                      0
                    )}
                    <span className={rightToLeft ? "pr-1" : "pl-1"}>
                      {t("cm", { ns: "measurements" })}
                    </span>
                  </p>
                ) : (
                  <p className="rebar_units">
                    {freeLengths?.reduce((acc, val) => acc + val.length, 0)}
                    <span className={rightToLeft ? "pr-1" : "pl-1"}>
                      {t("cm", { ns: "measurements" })}
                    </span>
                  </p>
                )}

                <p className="rebar_units">
                  {isNaN(shape.unit_weight) ? 0 : shape.unit_weight}
                  <span className={rightToLeft ? "pr-1" : "pl-1"}>
                    {t("kg", { ns: "tool" })}
                  </span>
                </p>
              </div>

              <div
                className="nets_weight_total"
                style={rightToLeft ? { left: "75px" } : { left: "140px" }}
              >
                <p className="rebar_units">{t("total", { ns: "common" })}</p>

                {rebarShapeMode === SHAPE_MODE.catalog ? (
                  <p className="rebar_units">
                    {shape?.rebar_side?.reduce(
                      (acc, val) => acc + val.length,
                      0
                    ) * (orderItem.quantity || 1)}
                    <span className={rightToLeft ? "pr-1" : "pl-1"}>
                      {t("cm", { ns: "measurements" })}
                    </span>
                  </p>
                ) : (
                  <p className="rebar_units">
                    {freeLengths?.reduce((acc, val) => acc + val.length, 0) *
                      (orderItem.quantity || 1)}
                    <span className={rightToLeft ? "pr-1" : "pl-1"}>
                      {t("cm", { ns: "measurements" })}
                    </span>
                  </p>
                )}

                <p className="rebar_units">
                  {isNaN(shape.unit_weight * orderItem.quantity) ||
                  orderItem.quantity < 1
                    ? isNaN(shape.unit_weight)
                      ? 0
                      : "-"
                    : (shape.unit_weight * orderItem.quantity)
                        .toFixed(2)
                        .toString()
                        .replace(commaSeparated, ",")}
                  <span className={rightToLeft ? "pr-1" : "pl-1"}>
                    {t("kg", { ns: "tool" })}
                  </span>
                </p>
              </div>
            </div>

            {/* Quantity */}
            <div className="sidebar_container">
              <div className="quantity">
                <h1 className="nets_sidebar_label_large mb-0">
                  {t("quantity", { ns: "common" })}
                </h1>
              </div>
              <input
                type="number"
                className={
                  "para text-center text-ash input-inside rebar_sidebar_input" +
                  editModeClass +
                  (errorsText.some(
                    (error) => error.errorType === rebarErrorTypes.AMOUNT
                  )
                    ? "_error"
                    : "")
                }
                value={orderItem.quantity || ""}
                onFocus={(e: any) => e.target.select()}
                onChange={(e) => {
                  removeError(rebarErrorTypes.AMOUNT);
                  setOrderItem({
                    ...orderItem,
                    quantity:
                      parseInt(e.target.value) >= 0
                        ? parseInt(e.target.value)
                        : "",
                  });
                }}
                onBlur={(e) => {
                  addUpdatedClass(
                    siderBarRefs.current[rebarSidebarInput.QUANTITY]
                  );
                  if (!(parseInt(e.target.value) > 0)) {
                    addError({
                      message: t("error_rebar_amount", { ns: "newOrder" }),
                      errorType: rebarErrorTypes.AMOUNT,
                    });
                  }
                }}
                ref={(el) => {
                  siderBarRefs.current[rebarSidebarInput.QUANTITY] = el;
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "ArrowDown") {
                    if (orderItem.quantity > 0) {
                      addButtonRef.current.focus();
                    }
                    e.preventDefault();
                  } else if (e.key === "ArrowUp") {
                    e.preventDefault();
                    if (showNote) {
                      siderBarRefs.current[rebarSidebarInput.NOTE].focus();
                    } else {
                      if (
                        showHeight &&
                        rebarHeight.length > 0 &&
                        heightsRef.current &&
                        heightsRef.current.length > 0
                      ) {
                        heightsRef.current[
                          heightsRef.current.length - 1
                        ].focus();
                      } else if (
                        sidesRef.current &&
                        sidesRef.current.length > 0
                      ) {
                        sidesRef.current[sidesRef.current.length - 1].focus();
                      }
                    }
                  }
                }}
              />
            </div>

            {/* Error Text */}
            {errorsText.filter(
              (error) =>
                error.errorType !== rebarErrorTypes.STEEL_TYPE &&
                error.errorType !== rebarErrorTypes.EAR &&
                error.errorType !== rebarErrorTypes.TOTAL_LENGTH
            ).length > 0 ? (
              <div className="errors_text border_top">
                {errorsText.slice(-2).map(
                  (error, index) =>
                    error.errorType !== rebarErrorTypes.STEEL_TYPE &&
                    error.errorType !== rebarErrorTypes.EAR &&
                    error.errorTypes !== rebarErrorTypes.TOTAL_LENGTH && (
                      <div
                        className="nets_error_continer"
                        key={"errors_" + index}
                      >
                        <img
                          className="error_img"
                          src={error_sign}
                          alt="error"
                        />
                        <p
                          className={
                            "rebar_error_text " +
                            (rightToLeft ? "pr-2" : "pl-2")
                          }
                        >
                          {error.message} {setIsShapesError(true)}
                        </p>
                      </div>
                    )
                )}
              </div>
            ) : (
              <> {orderItem.quantity > 0 && setIsShapesError(false)}</>
            )}

            <div
              className="sidebar_container add_button"
              style={
                rightToLeft
                  ? { paddingLeft: "169px" }
                  : { paddingRight: "169px" }
              }
            >
              <button
                onClick={handleAddButton}
                ref={addButtonRef}
                onFocus={handleFocus}
                onBlur={(e) => {
                  e.target.style.border = "none";
                }}
                onKeyDown={(e) => {
                  e.preventDefault();
                  if (e.key === "ArrowUp") {
                    siderBarRefs.current[rebarSidebarInput.QUANTITY].focus();
                  } else {
                    if (
                      (e.key === "ArrowRight" && !rightToLeft) ||
                      (e.key === "ArrowLeft" && rightToLeft)
                    ) {
                      finishButtonRef.current.focus();
                    } else if (e.key === "Enter") {
                      handleAddButton();
                      addButtonRef.current.focus();
                    }
                  }
                }}
              >
                {edit
                  ? t("update", { ns: "common" })
                  : t("add", { ns: "common" })}
              </button>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default InputSidebar;
