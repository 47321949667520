import { useEffect } from "react";
import Pin from "../../assets/img/map-pin.svg";
import GoogleMapReact from "google-map-react";
import { getAddresByGeo, decodingAddress } from "../../services/google-maps";
import './index.scss';
import { googleMapsAPIKey } from "Services/location";
import { useAppSelector } from "Redux/hooks";

export const DynamicMap = ({
  coordinates,
  setCoordinates,
  deps,
  addressChange,
  isInputChanges,
  setIsInputChanges,
}) => {
  const rightToLeft = useAppSelector(state => state.root.rightToLeft);
  useEffect(() => {
    if (!deps.find((item) => !item) && isInputChanges) {
      getCoordinates();
    }
  }, [isInputChanges, deps]);

  const getCoordinates = () => {
    decodingAddress(`${deps[0]} ${deps[1]} ${deps[2]} ${deps[3]}`).then(
      (response) => {
        if (response.results.length)
          setCoordinates(
            response.results[0]?.geometry &&
              response.results[0]?.geometry?.location
          );
        setIsInputChanges(false);
      }
    );
  };
  const handleMapCoordinates = (data) => {
    const lat = data.center.lat();
    const lng = data.center.lng();
    const coordinatesData = { lat: lat, lng: lng };
    if (lat && lng)
      getAddresByGeo(coordinatesData).then((res) => {
        if (res.results[0]) {
          const gottenAddress = res.results[0].address_components;
          setCoordinates(coordinatesData);
          const addressObject = {
            st_address: `${gottenAddress[0].short_name} ${gottenAddress[1].long_name}`,
            apt_address: gottenAddress[1].short_name,
            city: gottenAddress[3]?.short_name,
            zip_code: gottenAddress[7]?.short_name,
            state:
              gottenAddress[5]?.short_name === "US"
                ? gottenAddress[4]?.short_name
                : gottenAddress[5]?.short_name,
          };
          addressChange(addressObject, coordinatesData);
        }
      });
  };

  return (
    <div className="dynamic-map-container">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: googleMapsAPIKey,
          language: rightToLeft ? 'he' : 'en',
        }}
        defaultZoom={15}
        center={
          coordinates || {
            lat: 32.092767,
            lng: 34.781395,
          }
        }
        options={{
          panControl: true,
          mapTypeControl: false,
          scrollwheel: false,
        }}
        onDragEnd={handleMapCoordinates}
      />
      <div className="mark-map" key={`cluster-1`}>
        <img src={Pin} alt="pin" />
      </div>
    </div>
  );
};
