import React,{useState, useEffect} from "react";
import { useAppSelector, useAppDispatch } from "Redux/hooks";

import PrintCard from './print-card';
import SupplierName from "./supplier-name";
import WebsiteLink from "./website-link";
import InReview from "./in-review";
import TrialFinished from "./trial-finished";
import PackageFinished from "./package-finished";
import { addSuppier } from "Redux/middlwares/suppliers";
import { useHistory } from "react-router-dom";
import { fetchSupplierStatus } from "Redux/middlwares/suppliers";


const SupplierPopups = (props) => {

  const {
    inReview, 
    packageExpire,
    trialExpire,
    noData,
    supplier_renewal_date
  } = props

  const dispatch = useAppDispatch()
  const userData = useAppSelector((state) => state.user.userData);
  const [supplierDetails, setSupplierDetails] = useState({user: userData.id});

  const [onboardingPopup, setOnboardingPopup] = useState(noData);
  const [supplierNamePopup, setSupplierNamePopup] = useState(false);
  const [websiteLinkPopup, setWebsiteLinkPoup] = useState(false);
  const [inReviewPopup, setInReviewPopup] = useState(inReview);
  const [packageFinishedPopup, setPackageFinishedPopup] = useState(packageExpire);
  const [trialFinishedPopup, setTrialFinishedPopup] = useState(trialExpire);
  const history = useHistory()

  const addSupplier = async(details)=> {
    await dispatch(addSuppier(details));
    await dispatch(fetchSupplierStatus());
  }

  const exitPopup = ()=> {
    history.push('/new-order')
  }
  
  return (
    <>
      <div className="supplier_popups_background">
        <div className="container">
        <PrintCard
          show = {onboardingPopup}
          onHide = {()=>{setOnboardingPopup(false)
            exitPopup()}
          }
          close = {()=>setOnboardingPopup(false)}
          nextpopup = {()=>{setSupplierNamePopup(true)}}
        />
        <SupplierName
          show = {supplierNamePopup}
          onHide = {()=>{setSupplierNamePopup(false)
            exitPopup()}}
          nextpopup = {()=>{setWebsiteLinkPoup(true)}}
          prevpopup = {()=>{setOnboardingPopup(true)}}
          close = {()=>setSupplierNamePopup(false)}
          exit = {exitPopup}
          suppliername = {supplierDetails.name}
          setsuppliername = {(name) => {
            setSupplierDetails( {...supplierDetails, name: name})
          }}      />
        <WebsiteLink
          show = {websiteLinkPopup}
          onHide = {()=>{setWebsiteLinkPoup(false)
            exitPopup()}}
          nextpopup = {()=>{setInReviewPopup(true)}}
          prevpopup = {()=>{setSupplierNamePopup(true)}}
          close = {()=>setWebsiteLinkPoup(false)}
          addSupplier = {addSupplier}
          exit = {exitPopup}
          supplierlink = {supplierDetails.link}
          setsupplierlink = {(web_link) => {
              setSupplierDetails( {...supplierDetails, link: web_link});
          }}
          supplierDetails = {supplierDetails}
        />
        <InReview
          show = {inReviewPopup}
          onHide = {()=>{setInReviewPopup(false)
            exitPopup()}
          }
        />

        <TrialFinished
          show = {trialFinishedPopup}
          onHide = {()=>{setTrialFinishedPopup(false)
            exitPopup()}
          }
        />
        <PackageFinished
          show = {packageFinishedPopup}
          onHide = {()=>{setPackageFinishedPopup(false)
            exitPopup()}}
          supplier_renewal_date = {supplier_renewal_date}
        />
        </div>
      </div>
    </>
  )
}

export default SupplierPopups
