import React, { useState, useEffect, useMemo } from "react";
import { Card } from "react-bootstrap";
import { useAppDispatch } from "Redux/hooks";
import error from "../../../assets/img/ticket__create/error.svg";
import { WHATSAPP_LINK } from "Constants/ticket-creator";
import { OrderPdfType } from "Constants/order";
import { useTranslation } from "react-i18next";
import {
  fetchSupplierPriceList,
  fetchPaginatedPriceList,
  calculatedOrderPricing,
} from "Services/suppliers";
import { commaSeparated } from "Constants/general";
import {
  CalculateStdVal,
  CalculatePaymentFee,
  CalculateEasyBarFee,
} from "./utils.ts";
import { DebounceInput } from "react-debounce-input";

import "../index.scss";

const CreateBid = (props) => {
  const {
    onOpenPreviewHandle,
    setPdfType,
    handleCalculatedPricingData,
    setBidFees,
    bidFees,
    setPdfLanguage,
    pdfLanguage,
    setFeeType,
    feeType,
  } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["ticketCreator"]);

  const [orderNum, setOrderNum] = useState("");
  const [isOrderNotFound, setIsOrderNotFound] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [calcStdVal, setCalcStdVal] = useState("");
  const [calcEasyBarFee, setCalcEasyBarFee] = useState("");
  const [calcPayFee, setCalcFee] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [pricingList, setPricingList] = useState([]);
  const [loader, setLoader] = useState(false);

  const [supplierPricingData, setSupplierPricingData] = useState([]);
  const [nextPricingPage, setNextPricingPage] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [orderId, setOrderId] = useState(0);

  //Total Price (Sum of unit Prices x unit Quantity)
  const totalPricing = useMemo(
    () =>
      pricingList.length > 0
        ? pricingList
            .reduce(
              (pre: any, curr: any) => pre + curr.unit_price * curr.quantity,
              0
            )
            .toFixed(2)
        : "0",
    [pricingList]
  );

  useEffect(() => {
    const setDefaultState = () => {
      setIsOrderNotFound(true);
      setLoader(false);
      setBidFees({
        stdVal: "",
        easyBarFee: "",
        paymentFee: "",
        totalOrderFee: "",
      });
    };
    if (
      orderNum !== "" &&
      orderNum.length >= 14 &&
      orderNum.length <= 16 &&
      selectedSupplier !== ""
    ) {
      setLoader(true);
      setIsOrderNotFound(false);
      calculatedOrderPricing(orderNum, selectedSupplier.id)
        .then((res) => {
          setOrderId(res.data.id);
          setPricingList(res.data.order_item);
          handleCalculatedPricingData(res.data);
          setLoader(false);
          calculateUpdatedPrices(res.data.order_item);
        })
        .catch((err) => {
          setDefaultState();
        });
    }
  }, [orderNum, selectedSupplier, dispatch]);

  useEffect(() => {
    if (isOpen || searchValue !== "") {
      fetchSupplierPriceList(searchValue).then((response) => {
        if (response.data.results.length > 0) {
          const page = response.data.next
            ? response.data.next.split("cursor=")[1]
            : null;
          if (page) {
            setHasMore(true);
          } else if (searchValue !== "") {
            setHasMore(false);
          }
          setNextPricingPage(page);
          setSupplierPricingData(response.data.results);
        }
      });
    }
  }, [isOpen, searchValue]);

  useEffect(() => {
    //If easyBar Fee type changes or std val changes
    if (bidFees.easyBarFee > 0 && calcStdVal > 0) {
      let ezyFee = 0;
      let totalPayFee = 0;
      if (feeType === 0) {
        ezyFee = CalculateEasyBarFee(
          Number(calcStdVal),
          Number(bidFees.easyBarFee)
        );
      } else {
        ezyFee = Number(bidFees.easyBarFee);
      }

      setCalcEasyBarFee(ezyFee);
      if (bidFees.paymentFee > 0) {
        const totalFee = Number(calcStdVal) + Number(ezyFee);
        totalPayFee = CalculatePaymentFee(bidFees.paymentFee, totalFee);
        setCalcFee(totalPayFee);
      }
      setBidFees({
        ...bidFees,
        totalOrderFee: (
          Number(calcStdVal) +
          Number(ezyFee) +
          Number(totalPayFee)
        ).toFixed(2),
      });
    }
  }, [feeType, bidFees.stdVal]);

  //Recalculating Prices if Order/Supplier Changes
  const calculateUpdatedPrices = (priceList) => {
    const totalPrice = priceList
      .reduce((pre: any, curr: any) => pre + curr.unit_price * curr.quantity, 0)
      .toFixed(2);

    if (bidFees.stdVal > 0) {
      const stdVal = CalculateStdVal(
        Number(bidFees.stdVal),
        Number(totalPrice)
      );
      setCalcStdVal(Number(stdVal));

      let ezyBarFee = 0;
      if (feeType === 0) {
        ezyBarFee = CalculateEasyBarFee(
          Number(stdVal),
          Number(bidFees.easyBarFee)
        );
        setCalcEasyBarFee(ezyBarFee);
      } else {
        ezyBarFee = Number(bidFees.easyBarFee);
        setCalcEasyBarFee(ezyBarFee);
      }

      const totalFee = Number(stdVal) + Number(ezyBarFee);
      const perPayFee = CalculatePaymentFee(Number(bidFees.paymentFee), Number(totalFee));
      const calcTotalFee = Number(totalFee) + Number(perPayFee);
      setCalcFee(perPayFee);

      setBidFees({
        ...bidFees,
        totalOrderFee: Number(calcTotalFee).toFixed(2),
      });
    }
  };

  const handleStdChange = (e) => {
    if (e.target.value === "") {
      setBidFees({
        stdVal: "",
        easyBarFee: "",
        paymentFee: "",
        totalOrderFee: "",
      });
      setCalcStdVal("");
      setCalcEasyBarFee("");
      setCalcFee("");
    } else {
      const val = CalculateStdVal(Number(e.target.value), Number(totalPricing));
      setCalcStdVal(Number(val));
      setBidFees({
        ...bidFees,
        stdVal: e.target.value,
        totalOrderFee: (
          Number(val) +
          Number(calcEasyBarFee) +
          Number(calcPayFee)
        ).toFixed(2),
      });
    }
  };

  const handleEasyBarFeeChange = (e) => {
    if (e.target.value === "") {
      setBidFees({
        ...bidFees,
        easyBarFee: "",
        paymentFee: "",
      });
      setCalcEasyBarFee("");
      setCalcFee("");
      setBidFees({
        ...bidFees,
        easyBarFee: e.target.value,
        totalOrderFee: Number(calcStdVal),
      });
    } else {
      let ezyFee = 0;
      let totalPayFee = 0;
      if (feeType === 0) {
        ezyFee = CalculateEasyBarFee(
          Number(e.target.value),
          Number(calcStdVal)
        );
      } else {
        ezyFee = Number(e.target.value);
      }
      setCalcEasyBarFee(ezyFee);

      if (bidFees.paymentFee > 0) {
        const totalFee = Number(calcStdVal) + Number(ezyFee);
        totalPayFee = CalculatePaymentFee(
          Number(bidFees.paymentFee),
          Number(totalFee)
        );
        setCalcFee(totalPayFee);
      }
      setBidFees({
        ...bidFees,
        easyBarFee: e.target.value,
        totalOrderFee: (
          Number(calcStdVal) +
          Number(ezyFee) +
          Number(totalPayFee)
        ).toFixed(2),
      });
    }
  };

  const handlePaymentFeeChange = (e) => {
    const totalFee = Number(calcStdVal) + Number(calcEasyBarFee);
    const perPayFee = CalculatePaymentFee(
      Number(e.target.value),
      Number(totalFee)
    );
    setCalcFee(perPayFee);
    setBidFees({
      ...bidFees,
      paymentFee: e.target.value,
      totalOrderFee: (Number(totalFee) + Number(perPayFee)).toFixed(2),
    });
  };

  const onLoadFunction = () => {
    if (nextPricingPage !== null && nextPricingPage !== "") {
      fetchPaginatedPriceList(nextPricingPage).then((response) => {
        if (response.data.results.length > 0) {
          const page = response.data.next
            ? response.data.next.split("cursor=")[1]
            : null;
          if (page) {
            setHasMore(true);
          } else {
            setHasMore(false);
          }
          setNextPricingPage(page);
          setSupplierPricingData([
            ...supplierPricingData,
            ...response.data.results,
          ]);
        }
      });
    }
  };

  const handleScroll = (event) => {
    const { target } = event;

    if (
      target.scrollHeight - target.scrollTop === target.clientHeight &&
      hasMore
    ) {
      onLoadFunction();
    }
  };

  const handleOptionChange = (event) => {
    setSelectedSupplier(JSON.parse(event.target.getAttribute("data-value")));
    setIsOpen(false);
    setSearchValue(JSON.parse(event.target.getAttribute("data-value")).name);
  };

  const toggleDropdown = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const checkForDecimals = (price: number) => {
    //This function is to check if the price is below 0.5
    //then round it to two decimal otherwise 1
    if (price <= 0.5) {
      return price.toFixed(2);
    } else {
      return price.toFixed(1);
    }
  };

  return (
    <div>
      <p className="py-4">Create Bid</p>
      <Card>
        <Card.Body>
          <div className="bids-container">
            <div className="bids_main_heading">
              <p className="create-bid_text">Create Bid</p>
              <span className="bids_border_btm"></span>
            </div>

            <div className="supplier_order_info">
              <div className="mb-2">
                <label htmlFor="supplier" className="supplier-label">
                  Choose a Supplier
                </label>
                <div className="custom-dropdown">
                  <DebounceInput
                    type="text"
                    value={searchValue}
                    placeholder="Select Supplier"
                    debounceTimeout={500}
                    forceNotifyByEnter={true}
                    onClick={toggleDropdown}
                    onChange={(e) => {
                      setIsOpen(true);
                      setSearchValue(e.target.value);
                    }}
                  />

                  {isOpen && (
                    <div className="dropdown-options" onScroll={handleScroll}>
                      {supplierPricingData.map((item, index) => (
                        <div
                          className="option"
                          key={index}
                          onClick={handleOptionChange}
                          data-value={JSON.stringify(item)}
                        >
                          {item.name}
                        </div>
                      ))}
                      {hasMore && (
                        <div className="loading">Loading more...</div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-2">
                <label htmlFor="" className="order-label">
                  Order Number
                </label>
                <input
                  className="mr-2"
                  type="text"
                  placeholder="type order number"
                  value={orderNum}
                  onChange={(e) => {
                    setIsOrderNotFound(false);
                    setOrderNum(e.target.value);
                  }}
                />
                {loader && (
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                )}
              </div>
              {isOrderNotFound && (
                <div className="d-flex align-items-center">
                  <div className="pr-2">
                    <img src={error} alt="404" />
                  </div>
                  <div className="pr-1">
                    <p className="error-textt">
                      {t("error_admin_order", { ns: "ticketCreator" })}
                      <a
                        href={WHATSAPP_LINK}
                        className="error_linkk"
                        rel="noreferrer"
                        target="_blank"
                      >
                        {t("error_link", { ns: "ticketCreator" })}
                      </a>
                    </p>
                  </div>
                </div>
              )}
              <span className="bids_border_btm"></span>
            </div>

            <div className="fees-section">
              <p>Fees (apply on all rows)</p>

              <span className="std-dev">
                <p>Standard Deviation</p>
                <span>%</span>
                <input
                  type="number"
                  value={bidFees.stdVal}
                  onChange={handleStdChange}
                  disabled={!pricingList.length > 0}
                />
                {bidFees.stdVal > 0 && (
                  <p className="std-val">
                    {Number(calcStdVal)
                      .toFixed(2)
                      .toString()
                      .replace(commaSeparated, ",") + "  : Standard Deviation"}
                  </p>
                )}
              </span>

              <span className="ezy-fee">
                <p>Easybar fee</p>
                <span
                  className="ezy-Ils"
                  style={
                    feeType === 1
                      ? {
                          background:
                            "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #374051",
                          color: "#fff",
                        }
                      : { background: "#D9D9D9", color: "#000" }
                  }
                  onClick={() => setFeeType(1)}
                >
                  ILS
                </span>
                <span
                  className="ezy-Per"
                  style={
                    feeType === 0
                      ? {
                          background:
                            "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #374051",
                          color: "#fff",
                        }
                      : { background: "#D9D9D9", color: "#000" }
                  }
                  onClick={() => setFeeType(0)}
                >
                  %
                </span>
                <input
                  type="number"
                  value={bidFees.easyBarFee}
                  onChange={handleEasyBarFeeChange}
                  disabled={bidFees.stdVal === ""}
                />
                {bidFees.easyBarFee && (
                  <p className="calc-ezy-fee">
                    {Number(calcEasyBarFee)
                      .toFixed(2)
                      .toString()
                      .replace(commaSeparated, ",") + " : EasyBar Fee"}
                  </p>
                )}
              </span>

              <span className="std-dev mb-3">
                <p>Payment Fee</p>
                <span>%</span>
                <input
                  type="number"
                  value={bidFees.paymentFee}
                  disabled={bidFees.easyBarFee === ""}
                  onChange={handlePaymentFeeChange}
                />
                {bidFees.paymentFee > 0 && (
                  <p className="std-val">
                    {Number(calcPayFee)
                      .toFixed(2)
                      .toString()
                      .replace(commaSeparated, ",") + " : Payment Fee"}
                  </p>
                )}
              </span>

              <span className="bids_border_btm"></span>
            </div>

            <div className="total-fee">
              <span>
                <p>Total order</p>
                <p className="font-weight-bold">
                  {(checkForDecimals(Number(bidFees.totalOrderFee)))
                    .toString()
                    .replace(commaSeparated, ",")}{" "}
                  ILS
                </p>
              </span>
              <span className="bids_border_btm"></span>
            </div>

            <div className="pdf-language-menu">
              <span>Choose Pdf Language</span>
              <select
                value={pdfLanguage}
                onChange={(e) => {
                  setPdfLanguage(e.target.value);
                }}
              >
                <option value="en">English</option>
                <option value="he">Hebrew</option>
              </select>
            </div>

            <div className="bids-btns">
              <button
                style={{
                  background:
                    "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #374051",
                  marginRight: "15px",
                }}
                onClick={() => {
                  setPdfType(OrderPdfType.PRICES_PDF);
                  onOpenPreviewHandle(true, orderId);
                }}
                disabled={bidFees.paymentFee === ""}
              >
                Prices Pdf
              </button>
              <button
                style={{
                  background:
                    "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #374051",
                  marginRight: "15px",
                }}
                onClick={() => {
                  setPdfType(OrderPdfType.PRICES_PDF);
                  onOpenPreviewHandle(false, orderId);
                }}
                disabled={bidFees.paymentFee === ""}
              >
                Prices Pdf Preview
              </button>
              <button
                style={{ background: "#5F6674", marginRight: "15px" }}
                onClick={() => {
                  setPdfType(OrderPdfType.ORIGINAL_PDF);
                  onOpenPreviewHandle(true, orderId);
                }}
                disabled={pricingList.length <= 0}
              >
                Original Pdf
              </button>
              <button
                style={{ background: "#5F6674" }}
                onClick={() => {
                  setPdfType(OrderPdfType.ORIGINAL_PDF);
                  onOpenPreviewHandle(false, orderId);
                }}
                disabled={pricingList.length <= 0}
              >
                Original Pdf Preview
              </button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CreateBid;
