export const CalculateStdVal = (std: number, totalPricing: number) => {
  const stdVal = (std * totalPricing) / 100;
  return (stdVal + totalPricing);
};

export const CalculateEasyBarFee = (easyBarVal: number, stdVal: number) => {
  return ((easyBarVal * stdVal) / 100);
};

export const CalculatePaymentFee = (payVal: number, totalFee: number) => {
  return ((totalFee * payVal) / 100);
};
