import { format } from "date-fns";
import React from "react";
import brand_logo_dark from './logo-black.svg';
import Headerdetails from "./headers-details";
import { useTranslation } from "react-i18next";

const PreviewHeader = ({ order, showDetails, pageNo, totalPages, rightToLeft }: any) => {
  const { t } = useTranslation(['common', 'pdfOrder']);
  return (
    <>
      <div
        className={
          "header-top " +
          (rightToLeft ? "rightToLeft rotate_right" : "rotate_left")
        }
      >
        <div className="branding">
          <p className={rightToLeft ? "created_wr" : "created_wl"}>
            {t("order created by", { ns: "pdfOrder" })}
          </p>
          <img src={brand_logo_dark} alt="logo" />
        </div>
        <p className={rightToLeft ? "site_mr" : "site_ml"}>www.easybar.co.il</p>
      </div>
      <div className="header-body" style={{marginTop: "15px"}}>
        <div className="document-type">{t('request quote', {ns: 'pdfOrder'})}</div>
        <div className="document-info">
          <div className="divider"></div>
          <div className="item">
            <span className="key">{t('last updated', {ns: 'pdfOrder'})}</span>
            <span className="val">{order.last_modified ? format(new Date(order.last_modified), 'd.M.yyyy') : format(new Date(), 'd.M.yyyy')}</span>
          </div>
          <div className="divider"></div>
          <div className="item">
            <span className="key">{t('number of pages', {ns: 'pdfOrder'})}</span>
            <span className="val">{pageNo} / {Object.keys(totalPages).reduce((prev, ent) => prev + (totalPages[ent]), 0)}</span>
          </div>
          <div className="divider"></div>
          <div className="item">
            <span className="key">{t('document number', {ns: 'pdfOrder'})}</span>
            <span className="val">{order.order_no}</span>
          </div>
          <div className="divider"></div>
        </div>
      </div>
      <div className="divider" style={{ margin: "0 40px" }}></div>
      <div className="name-of-client">
        <span>{t('client', {ns: 'pdfOrder'})}: </span>
        <span className="name">
          {order.business &&
            (order.business.name || "")}
        </span>
      </div>
      <div
        className={showDetails ? "divider-gray" : "divider"}
        style={{ margin: "0 40px" }}
      ></div>
      {showDetails && <Headerdetails order={order} rightToLeft={rightToLeft} />}
    </>
  );
};

export default PreviewHeader;
