import React, { forwardRef, useEffect, useMemo, useState } from "react";
import html2canvas from "html2canvas";
import { Spinner } from "react-bootstrap";
import PreviewTicketPdf from "./a4-prints/index";
import { getPrintingCount } from "Redux/middlwares/suppliers";
import { useAppDispatch } from "Redux/hooks";

const TicketPdfPreview = forwardRef(
  (
    {
      setTicketLoader,
      ticketLoader,
      onHide,
      rebars,
      orderNo,
      selectedRowCount,
    }: any,
    ref
  ) => {
    const dispatch = useAppDispatch();

    const pageSize = 1210;
    const [rebarsRefs, setRebarsRef] = useState();

    const totalPages = useMemo(() => {
      let total = {};
      if (rebarsRefs && (rebarsRefs as any).current)
        total = {
          rebars: (rebarsRefs as any).current.length,
        };

      return total;
    }, [rebarsRefs]);

    useEffect(() => {
      if (
        rebarsRefs &&
        (rebarsRefs as any).current &&
        (rebarsRefs as any).current.length > 0
      ) {
        setTicketLoader(true);
        setTimeout(() => {
          openPrintCanvas();
          dispatch(getPrintingCount());
        }, 100);
      }
    }, [rebarsRefs]);

    // helper to convert html to canvas
    const getHtmlToCanvas = (ref: any) => {
      return new Promise((resolve, reject) => {
        html2canvas(ref, { allowTaint: true, useCORS: true, logging: true })
          .then((canvas) => {
            resolve(canvas);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    };

    function openPrintCanvas() {
      let Canvases = generateCanvases();
      let windowContent: any = "";
      let dataUrl;
      Promise.all(Canvases)
        .then((values) => {
          values.forEach((canvas: any) => {
            dataUrl = canvas.toDataURL();
            windowContent += "<div style={break-after:page}>";
            windowContent += `<img src=${dataUrl} style="width: 100%; height: auto"/>`;
            windowContent += "</div>";
          });
          setTicketLoader(false);
          onHide();
          let printWin = window.open(
            "https://easybar.co.il/ticket-creator",
            "Easybar.co.il",
            "top=40,left=40,height=700,width=1000,focused=false,resizable=no|0,scrollbars=no,location=no|0"
          );
          // @ts-ignore: Object is possibly 'null'.
          printWin.document.open();

          if (printWin) {
            // @ts-ignore: Object is possibly 'null'.
            printWin.document.write(`
            <!DOCTYPE html>
            <html>
              <head>
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title></title>
                <style>
                  @page {
                    margin: 0;
                    padding: 0;
                    size: A4 Landscape;
                  }
                </style>
              </head>
              <body style="width:100%; height: 100%; margin:0 auto;">
              ${windowContent}
              <script>
                function triggerPrint(event) {
                  window.removeEventListener('load', triggerPrint, false);
                  setTimeout(function() {
                  closeWindow(window.print());
                  }, 100);
                }
                function closeWindow(){
                  window.close();
                }
                window.addEventListener('load', triggerPrint, false);
              </script>
              </body>
            </html>
          `);
            // @ts-ignore: Object is possibly 'null'.
            printWin.document.close();
          }
        })
        .catch((err) => console.log(err));
    }

    function generateCanvases() {
      const canvases = [];
      if (rebarsRefs) {
        // @ts-ignore: Object is possibly
        for (let i = 0; i < rebarsRefs.current.length; i++) {
          // @ts-ignore: Object is possibly
          const canvas = getHtmlToCanvas(rebarsRefs.current[i]);
          canvases.push(canvas);
        }
      }
      return canvases;
    }

    // handlers
    const handleRebarsRefs = (refs: any) => {
      setRebarsRef(refs);
    };

    return (
      <>
        <div
          id="PDF-Preview-main"
          style={
            ticketLoader
              ? {
                  pointerEvents: "none",
                }
              : {}
          }
        >
          <PreviewTicketPdf
            pageSize={pageSize}
            loader={ticketLoader}
            rebars={rebars}
            orderNo={orderNo}
            handleRefs={handleRebarsRefs}
            totalPages={totalPages}
            selectedRowCount={selectedRowCount}
          />
        </div>
        {ticketLoader && (
          <div
            className="overloaded-wrapper"
            style={{ position: "absolute", zIndex: "12" }}
          >
            <div className="overloaded-spinner">
              <Spinner animation="border" role="status">
                <span className="visually-hidden"></span>
              </Spinner>
              <p>Generating PDF</p>
            </div>
          </div>
        )}
      </>
    );
  }
);

export default TicketPdfPreview;
