import { addToUserProjects, removeFromUserProjects, setUserProjects, updateToUserProjects } from "Redux/features/project";
import { AppThunk } from 'Redux/store';
import { deleteProject, getProjects, patchProject, postProject } from "Services/project";
import { isAxiosError, isAxiosResponse } from "Services/index";

export const createProject = (project: any, contacts: Object, next: CallableFunction): AppThunk => async dispatch => {
  const response = await postProject({...project, contacts});
  if (isAxiosResponse(response)) {
    await dispatch(addToUserProjects(response.data));
    return next(true);
  }
  if (isAxiosError(response)) {
    return next(false);
  }
}

export const fetchProjects = (): AppThunk => async dispatch => {
  const response = await getProjects();
  if (isAxiosResponse(response) && response.data.results.length > 0) {
    await dispatch(setUserProjects(response.data.results));
  }
}

export const updateProject = (id: number, project: any, next: CallableFunction): AppThunk => async dispatch => {
  const response = await patchProject(id, project);
  if (isAxiosResponse(response)) {
    await dispatch(updateToUserProjects(response.data));
    return next(true);
  }
}

export const removeProject = (id: number, next: CallableFunction): AppThunk => async dispatch => {
  const response = await deleteProject(id);
  if (isAxiosResponse(response)) {
    await dispatch(removeFromUserProjects(id));
    return next(true);
  }
}