import {useEffect, useState} from 'react';
import { Modal } from 'react-bootstrap';
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import cross from "../../../../assets/img/ticket__create/cross.svg"
import backward from "../../../../assets/img/ticket__create/forward.svg"

import "../index.scss"


function SupplierName({ ...props }) {
  const {
    show,
    onHide,
    nextpopup,
    prevpopup,
    close,
    suppliername,
    setsuppliername
  } = props

	const [supplierName, setSupplierName] = useState(suppliername)
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const [hasInput, setHasInput] = useState(true);
  const { t } = useTranslation(['ticketCreator']);

  const handleBackward = () => {
    close()
    prevpopup()
    setsuppliername(supplierName)
  }

  const handleExit = () => {
    onHide()
  }

  const handleNext = () =>{
    close()
    nextpopup()
    setsuppliername(supplierName)
  }

  useEffect(() => {
    supplierName?.length > 0 ? setHasInput(true) : setHasInput(false);
  }, [supplierName])


  return (
    <>
      <Modal
        show = {show}
        onHide = {onHide}
        className="supplier_popups"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onShow={() => {setHasInput(true)}}
      >
        <>
          <div className="container popup_container">
            <div className="popup_content">
              <div className="row popup_header justify-content-between px-4">
                <div className={"col-1 popup_icon " + (rightToLeft ? "order-1" : "")} onClick={handleExit}>
                  <img src={cross} alt="X" />
                </div>
                <div className="col-1 popup_icon" onClick={handleBackward}>
                  <img src={backward} alt=">" />
                </div>
              </div>
              <p className="supplier_name pt-2 pb-3">
              {t("supplier_name", { ns: "ticketCreator" })}
              </p>
              <input
                  type="text"
                  value={supplierName}
                  className ={hasInput ? "supplier_name_input " : "supplier_name_input_empty"}
                  onChange={({ target }) => {
                    setSupplierName(target.value);
                  }}
                  placeholder={t("type_name", { ns: "ticketCreator" })}
                                  
                />
								<br></br>
								<button className="popup_button_small my-4" onClick={()=>{supplierName?.length > 0 ? handleNext() : setHasInput(false)}}>
                  {t("next", { ns: "ticketCreator" })}
                </button>
                <a href='https://www.easybar.co.il/suppliers' target="_blank" rel="noreferrer">
                  <p className="popup_details pb-5">
                    {t("more_details", { ns: "ticketCreator" })}
                  </p>
                </a>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}

export default SupplierName;
