import React, { useState } from "react";
import { useAppSelector } from "Redux/hooks";
import { commaSeparated } from "Constants/general";
import diameterIcon from "../../../../assets/img/icon/diameter-blue.svg";
import MoreInfoIcon from "../../../../assets/img/icon/drug.svg";
import gap_icon from "Assets/img/cages/gap.svg";
import diameter_icon from "Assets/img/cages/diameter.svg";
import extra_on from "Assets/img/cages/extra_on.svg";
import extra_off from "Assets/img/cages/extra_off.svg";
import cage_preview_icon from "Assets/img/cages/cage_preview_icon.svg";

import { pilesWithoutPrice, pilesWithPrice } from "Constants/pile-constants";
import { useTranslation } from "react-i18next";

import { useSortable } from "@dnd-kit/sortable";

const StaticTableRow = ({ ...props }) => {
  const {
    index,
    key,
    itemCage,
    priceShow,
    handleImagePreview,
    setSelectedTool,
  } = props;

  const { t } = useTranslation(["newOrder", "common", "measurements", "tool"]);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  const [isHovered, setIsHovered] = useState(-1);

  const { isDragging } = useSortable({
    id: props.id,
  });

  return (
    <tr
      className="static-table_row"
      key={"itemCage" + index}
      style={
        priceShow
          ? { ...pilesWithPrice, position: "relative" }
          : {
              ...pilesWithoutPrice,
              position: "relative",
              borderTop: "2px solid #E68F8B",
            }
      }
      onMouseEnter={() => {
        setSelectedTool(itemCage);
        setIsHovered(index);
      }}
      onMouseLeave={() => setIsHovered(-1)}
    >
      <td
        className="d-flex justify-content-center p-0"
        style={{ cursor: "pointer" }}
      >
        {!isDragging ? (
          <div className="dropsdown">
            <img
              src={MoreInfoIcon}
              alt={"icon for options"}
              style={
                !isDragging
                  ? { cursor: "grabbing", width: "22px" }
                  : { cursor: "grab", width: "22px" }
              }
            />
          </div>
        ) : (
          <span className="td_norm">{itemCage.row_number}</span>
        )}
      </td>
      <td
        className={
          "d-flex justify-content-center align-item-center p-0 " +
          (rightToLeft ? "dark-border-right" : "dark-border-left")
        }
      >
        <img
          className="cage_sketch_preview"
          src={cage_preview_icon}
          alt="previewNet"
          style={{ cursor: "pointer" }}
          onClick={() => handleImagePreview(itemCage.tool.preview_image)}
        />
      </td>
      <td
        className={
          "d-flex justify-content-center align-item-center " +
          (rightToLeft ? "dark-border-right" : "dark-border-left")
        }
        style={rightToLeft ? { paddingRight: "14px" } : { paddingLeft: "14px" }}
      >
        <span
          className="name_header_font"
          style={
            itemCage.tool.name
              ? itemCage.tool?.name.includes(" ")
                ? {}
                : { wordBreak: "break-all" }
              : {}
          }
        >
          {itemCage.tool.name}
        </span>
      </td>

      <td
        className={
          rightToLeft ? "dark-border-right pr-3" : "dark-border-left pl-3"
        }
      >
        <span className="d-flex justify-content-start align-item-center p-0 cage_diameter">
          {itemCage.tool.cage_diameter}
        </span>

        <span className="d-flex justify-content-start align-item-center p-0 cage_length">
          {itemCage.tool.l1_length +
            itemCage.tool.l2_length +
            itemCage.tool.l3_length}
        </span>
      </td>

      <td
        className={
          rightToLeft
            ? "dark-border-right pr-3 pl-0"
            : "dark-border-left pl-3 pr-0"
        }
        style={itemCage.production_notes ? { paddingBottom: "16px" } : {}}
      >
        <span
          className={
            "d-flex justify-content-start align-item-center p-0 cage_len_1 "
          }
        >
          <span className={"td_bold " + (rightToLeft ? "pl-3" : "pr-3")}>
            {itemCage.tool.l3_length}
          </span>
          <span className={"td_norm " + (rightToLeft ? "pl-3" : "pr-3")}>
            <img className="img__icon" src={gap_icon} alt="previewNet" />
            {itemCage.tool.l3_gap}
          </span>

          <span className={"td_norm " + (rightToLeft ? "pl-3" : "pr-3")}>
            <img className="img__icon" src={diameter_icon} alt="previewNet" />
            {itemCage.tool.base_diameter.steel_diameter}
          </span>
        </span>

        <span className="d-flex justify-content-start align-item-center p-0 cage_len_2">
          <span className={"td_bold " + (rightToLeft ? "pl-3" : "pr-3")}>
            {itemCage.tool.l2_length}
          </span>

          <span className={"td_norm " + (rightToLeft ? "pl-3" : "pr-3")}>
            <img className="img__icon" src={gap_icon} alt="previewNet" />
            {itemCage.tool.l2_gap}
          </span>

          <span className={"td_norm " + (rightToLeft ? "pl-3" : "pr-3")}>
            <img className="img__icon" src={diameter_icon} alt="previewNet" />
            {itemCage.tool.base_diameter.steel_diameter}
          </span>
        </span>

        <span className="d-flex justify-content-start align-item-center p-0 cage_edge">
          <span className="td_bold">{itemCage.tool.l1_length}</span>
        </span>
      </td>
      <td
        className={
          "d-flex justify-content-center align-items-center p-0 " +
          (rightToLeft ? "dark-border-right" : "dark-border-left")
        }
      >
        {itemCage.tool.ring_diameter === null &&
        itemCage.tool.gama_diameter === null &&
        itemCage.tool.ear_type === null ? (
          <span
            className={
              "d-flex justify-content-center align-items-center extra_img_icon"
            }
          >
            <img className="extra_img_icon" src={extra_off} alt="previewNet" />
          </span>
        ) : (
          <span
            className={
              "d-flex justify-content-center align-items-center extra_img_icon"
            }
          >
            <img className="extra_img_icon" src={extra_on} alt="previewNet" />
          </span>
        )}
      </td>

      <td
        className={
          "d-flex justify-content-start " +
          (rightToLeft ? "dark-border-right pr-3" : "dark-border-left pl-3")
        }
      >
        <span className="td_norm">{itemCage.quantity}</span>
      </td>

      <td
        className={
          "d-flex justify-content-start dark-border-right dark-border-left " +
          (rightToLeft ? "pr-3" : "pl-3")
        }
      >
        <span className="td_norm" style={{ width: "50%" }}>
          {itemCage.tool.unit_weight.toString().replace(commaSeparated, ",")}
        </span>
        <span className="td_norm">
          {(itemCage.tool.unit_weight * itemCage.quantity)
            .toFixed(2)
            .toString()
            .replace(commaSeparated, ",")}
        </span>
      </td>
      {itemCage.production_notes && (
        <td
          colSpan={1}
          className="light-border-top note-td"
          style={{
            padding: "0px",
            position: "absolute",
            marginTop: "56px",
            alignItems: "normal",
            justifyContent: "flex-start",
            width: priceShow ? "68%" : "36.9%",
            ...(rightToLeft
              ? {
                  right: priceShow ? "250px" : "calc(100% - 66.9%)",
                  textAlign: "right",
                }
              : {
                  left: priceShow ? "250px" : "calc(100% - 66.9%)",
                }),
          }}
        >
          <p className="mx-3" style={{ fontSize: "15px" }}>
            {t("note", { ns: "common" })}: {itemCage.production_notes}
          </p>
        </td>
      )}
    </tr>
  );
};

export default StaticTableRow;
