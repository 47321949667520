
// ========= Rebar =============
export const padX = 125;
export const padY = 50;
export const mPadX = 25;
export const mPadY = 20;
export const resacleRate = 0.0001;
export const pctInnerheight = 0.80;
export const pctInnerWidth = 0.75;
export const rebarInputSize = 50;
export const sideLengthTextWithoutInput = 18;
export const previewPad = 50;
export const previewFontSize = 40;
export const angleTextSize = 14;
export const catalogRadiusRescaleFactor = 3.5;
export const catalogRadiusRescaleRate = 0.3;
export const radiusLineEndPointLength = 100;
export const radiusLabelDistance = 80;
