import React, { useState, useEffect } from "react";
import { Stage, Layer, Shape, Text } from "react-konva";
import { Html } from "react-konva-utils";
import Measure from "react-measure";

import {
  calculate_refactored_net_coordinates,
  calculateNextPoint,
  calculate_partitions,
} from "../utils";
import { isMobile } from "react-device-detect";
import {
  gapOptions,
  netXLengthMax,
  netXLengthMin,
  netYLengthMax,
  netYLengthMin,
  netErrorTypes,
} from "Constants/net-constants";
import { DebounceInput } from "react-debounce-input";
import { useTranslation } from "react-i18next";
import "./index.scss";

function NetShape({
  isLoading,
  shapeObj,
  setRescaleFactor,
  partitionX,
  setPartitionX,
  partitionY,
  setPartitionY,
  labelingPoints,
  setLabelingPoints,
  setShape,
  stageRef,
  customizeView,
  setXUnits,
  setYUnits,
  setErrorText,
  errorText,
  addError,
  removeError,
  errorsText,
  validateVals,
  validateMidVals,
  onX1Change,
  onX2Change,
  onX3Change,
  onY1Change,
  onY2Change,
  onY3Change,
  checkLengths,
  showStandard,
  edgeChanged_X,
  setEdgeChanged_X,
  edgeChanged_Y,
  setEdgeChanged_Y,
  initiateImage,
}: any) {
  const { t } = useTranslation(["tool"]);
  const [canvasWidth, setCanvasWidth] = useState(30);
  const [canvasHeight, setCanvasHeight] = useState(30);
  const Y_OFFSET = 6
  const X_OFFSET = 6

  //TODO: These constant variables has to be placed in a separate
  //constant file. Also, too many other hardcoded values are used
  //those are also needed to be changed.

  const XCENTERCONST = 25;
  const CENTERCONST = 10;
  const TEXTCONST = 20;
  const XTEXTCONST = 5;
  const YTEXT_PAD = 50;
  const YLABELS_PAD = 70;

  const [labelY, setLabelY] = useState({
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0,
  });

  const [labelY1, setLabelY1] = useState({
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0,
  });

  const [labelY2, setLabelY2] = useState({
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0,
  });

  const [labelY3, setLabelY3] = useState({
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0,
  });

  const [labelX, setLabelX] = useState({
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0,
  });

  const [labelX1, setLabelX1] = useState({
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0,
  });

  const [labelX2, setLabelX2] = useState({
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0,
  });

  const [labelX3, setLabelX3] = useState({
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0,
  });

  const [X1LabelPoints, setX1LabelPoints] = useState({
    right: 0,
    left: 0,
  });

  const [X2LabelPoints, setX2LabelPoints] = useState({
    right: 0,
    left: 0,
  });

  const [X3LabelPoints, setX3LabelPoints] = useState({
    right: 0,
    left: 0,
  });

  const [Y1LabelPoints, setY1LabelPoints] = useState({
    right: 0,
    left: 0,
  });

  const [Y2LabelPoints, setY2LabelPoints] = useState({
    right: 0,
    left: 0,
  });

  const [Y3LabelPoints, setY3LabelPoints] = useState({
    right: 0,
    left: 0,
  });

  useEffect(() => {
    if (labelX1.x1 > 0 && labelX1.x2 > 0) {
      const LeftPoint = (labelX1.x1 + labelX1.x2) / 2 - XCENTERCONST;
      const RightPoint = (labelX1.x1 + labelX1.x2) / 2 - XCENTERCONST + 50;
      setX1LabelPoints({
        right: RightPoint,
        left: LeftPoint,
      });
    }
  }, [labelX1]);

  useEffect(() => {
    if (labelX2.x1 > 0 && labelX2.x2 > 0) {
      const LeftPoint = (labelX2.x1 + labelX2.x2) / 2 - XCENTERCONST;
      const RightPoint = (labelX2.x1 + labelX2.x2) / 2 - XCENTERCONST + 50;
      setX2LabelPoints({
        right: RightPoint,
        left: LeftPoint,
      });
    }
  }, [labelX2]);

  useEffect(() => {
    if (labelX3.x1 > 0 && labelX3.x2 > 0) {
      const LeftPoint = (labelX3.x1 + labelX3.x2) / 2 - XCENTERCONST;
      const RightPoint = (labelX3.x1 + labelX3.x2) / 2 - XCENTERCONST + 50;
      setX3LabelPoints({
        right: RightPoint,
        left: LeftPoint,
      });
    }
  }, [labelX3]);

  useEffect(() => {
    if (labelY1.y1 > 0 && labelY1.y2 > 0) {
      const TopPoint = (labelY1.y1 + labelY1.y2) / 2 - CENTERCONST;
      const BottomPoint = (labelY1.y1 + labelY1.y2) / 2 - CENTERCONST + 20;
      setY1LabelPoints({
        top: TopPoint,
        bottom: BottomPoint,
      });
    }
  }, [labelY1]);

  useEffect(() => {
    if (labelY2.y1 > 0 && labelY2.y2 > 0) {
      const TopPoint = (labelY2.y1 + labelY2.y2) / 2 - CENTERCONST;
      const BottomPoint = (labelY2.y1 + labelY2.y2) / 2 - CENTERCONST + 20;
      setY2LabelPoints({
        top: TopPoint,
        bottom: BottomPoint,
      });
    }
  }, [labelY2]);

  useEffect(() => {
    if (labelY3.y1 > 0 && labelY3.y2 > 0) {
      const TopPoint = (labelY3.y1 + labelY3.y2) / 2 - CENTERCONST;
      const BottomPoint = (labelY3.y1 + labelY3.y2) / 2 - CENTERCONST + 20;
      setY3LabelPoints({
        top: TopPoint,
        bottom: BottomPoint,
      });
    }
  }, [labelY3]);

  const [leftLinePoints, setLeftLinePoints] = useState(0);
  const [topLinePoints, setTopLinePoints] = useState(0);
  const [rightLinePoints, setRightLinePoints] = useState(0);

  const [bottomHzPoint, setBottomHzPoint] = useState(0);
  const [endHzPoint, setEndHzPoint] = useState(0);

  const [startPointY, setStartPointY] = useState(0);
  const [startPointX, setStartPointX] = useState(0);

  function canvasSizeChanged(contentRect: any) {
    setCanvasHeight(contentRect.bounds.height);
    setCanvasWidth(contentRect.bounds.width);
  }

  const cornerGap = {
    x: (labelingPoints.topPtStart.x || 0) - (labelingPoints.leftPtStart.x || 0),
    y: (labelingPoints.leftPtStart.y || 0) - (labelingPoints.topPtStart.y || 0),
  }; // leftTop

  const rightTopCornerGap = {
    x: (labelingPoints.rightPtStart.x || 0) - (labelingPoints.topPtEnd.x || 0),
    y: (labelingPoints.rightPtStart.y || 0) - (labelingPoints.topPtEnd.y || 0),
  };

  const leftBottomCornerGap = {
    x:
      (labelingPoints.bottomPtStart.x || 0) - (labelingPoints.leftPtEnd.x || 0),
    y:
      (labelingPoints.bottomPtStart.y || 0) - (labelingPoints.leftPtEnd.y || 0),
  };

  const innerGap = {
    x:
      (labelingPoints.bottomPtEnd.x || 0) -
        labelingPoints.bottomPtSecondLast.x || 0,
    y:
      (labelingPoints.rightPtEnd.y || 0) -
      (labelingPoints.rightPtSecondLast.y || 0),
  };
  const steps = 20 + (cornerGap.x + cornerGap.y) / 2 || 0;

  const width =
    (labelingPoints.rightPtStart.x || 0) - (labelingPoints.leftPtStart.x || 0);
  const height =
    (labelingPoints.bottomPtStart.y || 0) - (labelingPoints.topPtStart.y || 0);

  return (
    !isLoading && (
      <Measure bounds onResize={canvasSizeChanged}>
      {({ measureRef }) => {
        return (
          <div
            className={
              isMobile ? "right__img w-xs-100 mobile-height" : "high__img"
            }
            ref={measureRef}
          >
            <Stage width={canvasWidth} height={canvasHeight} ref={stageRef}>
              <Layer>
                <Shape
                  sceneFunc={(context, shape) => {
                    let xLength = shapeObj.x_length;
                    let yLength = shapeObj.y_length;

                    if (shapeObj.x_length < netXLengthMin) {
                      xLength = netXLengthMin;
                    }
                    if (shapeObj.x_length >= netXLengthMax) {
                      xLength = netXLengthMax - 1;
                    }
                    if (shapeObj.y_length < netYLengthMin) {
                      yLength = netYLengthMin;
                    }
                    if (shapeObj.y_length >= netYLengthMax) {
                      yLength = netYLengthMax - 1;
                    }

                    if (shapeObj && canvasHeight > 150 && canvasWidth > 150) {
                      const resizedImage = calculate_refactored_net_coordinates(
                        xLength,
                        yLength,
                        canvasWidth,
                        canvasHeight,
                        isMobile
                      );

                      const rescale_factor = resizedImage.rescale_factor;
                      setRescaleFactor(rescale_factor);
                      const start_point = resizedImage.first_point;

                      const net_width = xLength * rescale_factor;
                      const net_height = yLength * rescale_factor;

                      const gap_x = shapeObj.x_gap * rescale_factor;
                      const gap_y = shapeObj.y_gap * rescale_factor;

                      context.beginPath();

                      let width_length =
                        (partitionX._2 / shapeObj.x_length) * net_width;
                      const width_bars =
                        Math.round(
                          ((partitionX._1 / shapeObj.x_length) * net_width) /
                            gap_x
                        ) + 1;
                      setYUnits(width_bars);
                      const horizontal_point_second_point = calculateNextPoint(
                        start_point,
                        90,
                        net_height
                      );

                      setLabelingPoints((previousState: any) => ({
                        ...previousState,
                        topPtStart: {
                          x: start_point.x + width_length,
                          y: start_point.y,
                        },
                        bottomPtStart: {
                          x: horizontal_point_second_point.x + width_length,
                          y: horizontal_point_second_point.y,
                        },
                      }));

                      //to set top points
                      setTopLinePoints(start_point.y);
                      setStartPointX(start_point.x + width_length);

                      for (let i = 0; i < width_bars; i++) {
                        context.moveTo(
                          start_point.x + width_length,
                          start_point.y
                        );
                        context.lineTo(
                          horizontal_point_second_point.x + width_length,
                          horizontal_point_second_point.y
                        );
                        width_length = width_length + gap_x;
                      }

                      setLabelingPoints((previousState: any) => ({
                        ...previousState,
                        topPtEnd: {
                          x: start_point.x + width_length - gap_x,
                          y: start_point.y,
                        },
                        bottomPtSecondLast: {
                          x:
                            horizontal_point_second_point.x +
                            width_length -
                            2 * gap_x,
                          y: horizontal_point_second_point.y,
                        },
                        bottomPtEnd: {
                          x:
                            horizontal_point_second_point.x +
                            width_length -
                            gap_x,
                          y: horizontal_point_second_point.y,
                        },
                      }));

                      let height_length =
                        (partitionY._3 / shapeObj.y_length) * net_height;
                      const heigth_bars =
                        Math.round(
                          ((partitionY._1 / shapeObj.y_length) * net_height) /
                            gap_y
                        ) + 1;
                      setXUnits(heigth_bars);
                      const vertical_point_second_point = calculateNextPoint(
                        start_point,
                        0,
                        net_width
                      );

                      setLabelingPoints((previousState: any) => ({
                        ...previousState,
                        leftPtStart: {
                          x: start_point.x,
                          y: start_point.y + height_length,
                        },
                        rightPtStart: {
                          x: vertical_point_second_point.x,
                          y: vertical_point_second_point.y + height_length,
                        },
                      }));

                      //to set the labels/lines/input boxes accrording to the start point
                      setLeftLinePoints(start_point.x);
                      setStartPointY(start_point.y + height_length);

                      for (let i = 0; i < heigth_bars; i++) {
                        if (heigth_bars - i <= 2) {
                        }
                        context.moveTo(
                          start_point.x,
                          start_point.y + height_length
                        );
                        context.lineTo(
                          vertical_point_second_point.x,
                          vertical_point_second_point.y + height_length
                        );
                        height_length = height_length + gap_y;
                      }

                      setRightLinePoints(vertical_point_second_point.x);

                      setLabelingPoints((previousState: any) => ({
                        ...previousState,
                        leftPtEnd: {
                          x: start_point.x,
                          y: start_point.y + height_length - gap_y,
                        },
                        rightPtSecondLast: {
                          x: vertical_point_second_point.x,
                          y:
                            vertical_point_second_point.y +
                            height_length -
                            2 * gap_y,
                        },
                        rightPtEnd: {
                          x: vertical_point_second_point.x,
                          y:
                            vertical_point_second_point.y +
                            height_length -
                            gap_y,
                        },
                      }));

                      // (!) Konva specific method, it is very important
                      context.fillStrokeShape(shape);
                    }
                  }}
                  stroke="black"
                  strokeWidth={4}
                  lineJoin={"round"}
                  lineCap={"round"}
                />
              </Layer>
              <Layer>
                <Shape
                  sceneFunc={(context, shape) => {
                    if (shapeObj && canvasHeight > 150 && canvasWidth > 150) {
                      context.beginPath();
                      // <---------------------verticle line top left------------------------------------->
                      //left line
                      const topLeftPoint = labelingPoints.topPtStart;
                      let actualStartingPoint = {
                        x: topLeftPoint.x - cornerGap.x,
                        y: topLeftPoint.y + cornerGap.y,
                      };
                      let nextPoint = calculateNextPoint(
                        actualStartingPoint,
                        270,
                        steps * 1.4
                      );
                      context.moveTo(
                        actualStartingPoint.x - 1,
                        topLinePoints - 75
                      );
                      context.lineTo(nextPoint.x - 1, startPointY);

                      setLabelX((prev) => ({
                        ...prev,
                        x1: actualStartingPoint.x,
                      }));

                      setLabelX2((prev) => ({
                        ...prev,
                        x1: actualStartingPoint.x,
                      }));

                      // first top horizontal line
                      actualStartingPoint = { x: nextPoint.x, y: nextPoint.y };
                      nextPoint = calculateNextPoint(
                        actualStartingPoint,
                        360,
                        width
                      );
                      context.moveTo(actualStartingPoint.x, topLinePoints - 65);
                      context.lineTo(nextPoint.x + 2, topLinePoints - 65);

                      //-----------------left arrow-------------

                      //top
                      context.moveTo(actualStartingPoint.x, topLinePoints - 65);
                      context.lineTo(
                        actualStartingPoint.x + 10,
                        topLinePoints - 70
                      );

                      //bottom
                      context.moveTo(actualStartingPoint.x, topLinePoints - 65);
                      context.lineTo(
                        actualStartingPoint.x + 10,
                        topLinePoints - 60
                      );

                      //-----------------right arrow-------------

                      //top
                      context.moveTo(nextPoint.x + 2, topLinePoints - 65);
                      context.lineTo(nextPoint.x - 8, topLinePoints - 70);

                      //bottom
                      context.moveTo(nextPoint.x + 2, topLinePoints - 65);
                      context.lineTo(nextPoint.x - 8, topLinePoints - 60);

                      // right line
                      actualStartingPoint = {
                        x: topLeftPoint.x,
                        y: topLeftPoint.y,
                      };
                      nextPoint = calculateNextPoint(
                        actualStartingPoint,
                        270,
                        steps - cornerGap.y
                      );
                      context.moveTo(
                        actualStartingPoint.x - 1,
                        topLinePoints - 28
                      );
                      context.lineTo(nextPoint.x - 1, topLinePoints);

                      //-----left side arrow----
                      context.moveTo(
                        actualStartingPoint.x - 1,
                        topLinePoints - 20
                      );
                      context.lineTo(nextPoint.x - 10, topLinePoints - 15);

                      context.moveTo(
                        actualStartingPoint.x - 1,
                        topLinePoints - 20
                      );
                      context.lineTo(nextPoint.x - 10, topLinePoints - 25);

                      //-----right side arrow----
                      context.moveTo(
                        actualStartingPoint.x - 1,
                        topLinePoints - 20
                      );
                      context.lineTo(nextPoint.x + 10, topLinePoints - 15);

                      context.moveTo(
                        actualStartingPoint.x - 1,
                        topLinePoints - 20
                      );
                      context.lineTo(nextPoint.x + 10, topLinePoints - 25);

                      setLabelX1((prev) => ({
                        ...prev,
                        x1: actualStartingPoint.x,
                      }));

                      setLabelX2((prev) => ({
                        ...prev,
                        x2: actualStartingPoint.x,
                      }));

                      // lower horizontal line
                      actualStartingPoint = {
                        x: nextPoint.x - cornerGap.x,
                        y: nextPoint.y,
                      };
                      nextPoint = calculateNextPoint(
                        actualStartingPoint,
                        360,
                        width
                      );
                      context.moveTo(actualStartingPoint.x, topLinePoints - 20);
                      context.lineTo(nextPoint.x + 2, topLinePoints - 20);

                      //----------------left-most arrow------------------

                      //top
                      context.moveTo(actualStartingPoint.x, topLinePoints - 20);
                      context.lineTo(
                        actualStartingPoint.x + 10,
                        topLinePoints - 25
                      );

                      //bottom
                      context.moveTo(actualStartingPoint.x, topLinePoints - 20);
                      context.lineTo(
                        actualStartingPoint.x + 10,
                        topLinePoints - 15
                      );

                      //----------------right-most arrow------------------

                      //top
                      context.moveTo(nextPoint.x + 2, topLinePoints - 20);
                      context.lineTo(nextPoint.x - 8, topLinePoints - 25);

                      //bottom
                      context.moveTo(nextPoint.x + 2, topLinePoints - 20);
                      context.lineTo(nextPoint.x - 8, topLinePoints - 15);

                      // <-------------------------horizontal line top left----------------------------------->
                      //lower
                      actualStartingPoint = labelingPoints.leftPtStart; //{x:topLeftPoint.x - cornerGap.x, y:topLeftPoint.y + cornerGap.y};
                      nextPoint = calculateNextPoint(
                        actualStartingPoint,
                        180,
                        steps - cornerGap.x
                      );
                      context.moveTo(
                        leftLinePoints - 30,
                        actualStartingPoint.y - 1
                      );
                      context.lineTo(leftLinePoints, nextPoint.y - 1);

                      //top side arrow
                      context.moveTo(
                        leftLinePoints - 30,
                        actualStartingPoint.y - 1
                      );
                      context.lineTo(leftLinePoints - 25, nextPoint.y - 10);

                      context.moveTo(
                        leftLinePoints - 30,
                        actualStartingPoint.y - 1
                      );
                      context.lineTo(leftLinePoints - 35, nextPoint.y - 10);

                      //bottom side arrow
                      context.moveTo(
                        leftLinePoints - 30,
                        actualStartingPoint.y - 1
                      );
                      context.lineTo(leftLinePoints - 25, nextPoint.y + 8);

                      context.moveTo(
                        leftLinePoints - 30,
                        actualStartingPoint.y - 1
                      );
                      context.lineTo(leftLinePoints - 35, nextPoint.y + 8);

                      setLabelY1((previousState: any) => ({
                        ...previousState,
                        y1: actualStartingPoint.y,
                      }));
                      setLabelY3((previousState: any) => ({
                        ...previousState,
                        y2: actualStartingPoint.y,
                      }));

                      //upper
                      actualStartingPoint = {
                        x: topLeftPoint.x,
                        y: topLeftPoint.y,
                      };
                      nextPoint = calculateNextPoint(
                        actualStartingPoint,
                        180,
                        steps * 1.4
                      );
                      context.moveTo(
                        leftLinePoints - 105,
                        actualStartingPoint.y
                      );
                      context.lineTo(startPointX, nextPoint.y);

                      setLabelY3((previousState: any) => ({
                        ...previousState,
                        y1: actualStartingPoint.y,
                      }));

                      //<------------------------- horizontal line bottom left ------------------------------->
                      const bottomLeftPoint = labelingPoints.leftPtEnd; //{x:58,y:287}

                      //upper
                      actualStartingPoint = {
                        x: bottomLeftPoint.x,
                        y: bottomLeftPoint.y,
                      };
                      nextPoint = calculateNextPoint(
                        actualStartingPoint,
                        180,
                        steps - cornerGap.x
                      );
                      context.moveTo(
                        leftLinePoints - 30,
                        actualStartingPoint.y + 2
                      );
                      context.lineTo(leftLinePoints, nextPoint.y + 2);

                      //top arrow
                      context.moveTo(
                        leftLinePoints - 30,
                        actualStartingPoint.y + 2
                      );
                      context.lineTo(
                        leftLinePoints - 25,
                        actualStartingPoint.y - 8
                      );

                      context.moveTo(
                        leftLinePoints - 30,
                        actualStartingPoint.y + 2
                      );
                      context.lineTo(
                        leftLinePoints - 35,
                        actualStartingPoint.y - 8
                      );

                      //bottom arrow
                      context.moveTo(
                        leftLinePoints - 30,
                        actualStartingPoint.y + 2
                      );
                      context.lineTo(
                        leftLinePoints - 25,
                        actualStartingPoint.y + 10
                      );

                      context.moveTo(
                        leftLinePoints - 30,
                        actualStartingPoint.y + 2
                      );
                      context.lineTo(
                        leftLinePoints - 35,
                        actualStartingPoint.y + 10
                      );

                      setLabelY1((previousState: any) => ({
                        ...previousState,
                        y2: actualStartingPoint.y,
                      }));

                      setLabelY2((previousState: any) => ({
                        ...previousState,
                        y1: actualStartingPoint.y,
                      }));

                      //left-most vertical line
                      actualStartingPoint = {
                        x: nextPoint.x,
                        y: nextPoint.y + leftBottomCornerGap.y,
                      };
                      nextPoint = calculateNextPoint(
                        actualStartingPoint,
                        270,
                        height
                      );
                      context.moveTo(
                        leftLinePoints - 95,
                        actualStartingPoint.y + 2
                      );
                      context.lineTo(leftLinePoints - 95, nextPoint.y);
                      setLabelY((prev) => ({
                        ...prev,
                        y1: actualStartingPoint.y,
                        y2: nextPoint.y,
                      }));

                      //----------top arrow---------

                      //right
                      context.moveTo(leftLinePoints - 95, nextPoint.y);
                      context.lineTo(leftLinePoints - 90, nextPoint.y + 10);

                      //left
                      context.moveTo(leftLinePoints - 95, nextPoint.y);
                      context.lineTo(leftLinePoints - 100, nextPoint.y + 10);

                      //----------bottom arrow---------

                      //right
                      context.moveTo(
                        leftLinePoints - 95,
                        actualStartingPoint.y + 2
                      );
                      context.lineTo(
                        leftLinePoints - 90,
                        actualStartingPoint.y - 8
                      );

                      //left
                      context.moveTo(
                        leftLinePoints - 95,
                        actualStartingPoint.y + 2
                      );
                      context.lineTo(
                        leftLinePoints - 100,
                        actualStartingPoint.y - 8
                      );

                      //lower line
                      actualStartingPoint = {
                        x: bottomLeftPoint.x + leftBottomCornerGap.x,
                        y: bottomLeftPoint.y + leftBottomCornerGap.y,
                      };
                      nextPoint = calculateNextPoint(
                        actualStartingPoint,
                        180,
                        steps * 1.4
                      );
                      context.moveTo(
                        leftLinePoints - 105,
                        actualStartingPoint.y + 2
                      );
                      context.lineTo(startPointX, nextPoint.y + 2);
                      setLabelY2((previousState: any) => ({
                        ...previousState,
                        y2: actualStartingPoint.y,
                      }));

                      // left verticle line
                      actualStartingPoint = { x: nextPoint.x, y: nextPoint.y };
                      nextPoint = calculateNextPoint(
                        actualStartingPoint,
                        270,
                        height
                      );
                      context.moveTo(
                        leftLinePoints - 30,
                        actualStartingPoint.y + 2
                      );
                      context.lineTo(leftLinePoints - 30, nextPoint.y);

                      //bottom most arrow
                      context.moveTo(
                        leftLinePoints - 30,
                        actualStartingPoint.y + 2
                      );
                      context.lineTo(
                        leftLinePoints - 25,
                        actualStartingPoint.y - 8
                      );

                      context.moveTo(
                        leftLinePoints - 30,
                        actualStartingPoint.y + 2
                      );
                      context.lineTo(
                        leftLinePoints - 35,
                        actualStartingPoint.y - 8
                      );

                      //top most arrow
                      context.moveTo(leftLinePoints - 30, nextPoint.y);
                      context.lineTo(leftLinePoints - 25, nextPoint.y + 8);

                      context.moveTo(leftLinePoints - 30, nextPoint.y);
                      context.lineTo(leftLinePoints - 35, nextPoint.y + 8);

                      //<----------------------------verticle line top right--------------------------->

                      const topRightPoint = labelingPoints.topPtEnd;
                      actualStartingPoint = {
                        x: topRightPoint.x,
                        y: topRightPoint.y,
                      };
                      nextPoint = calculateNextPoint(
                        actualStartingPoint,
                        270,
                        steps - cornerGap.y
                      );
                      context.moveTo(
                        actualStartingPoint.x + 2,
                        topLinePoints - 28
                      );
                      context.lineTo(nextPoint.x + 2, topLinePoints);

                      //right side arrow
                      context.moveTo(
                        actualStartingPoint.x + 2,
                        topLinePoints - 20
                      );
                      context.lineTo(nextPoint.x + 10, topLinePoints - 15);

                      context.moveTo(
                        actualStartingPoint.x + 2,
                        topLinePoints - 20
                      );
                      context.lineTo(nextPoint.x + 10, topLinePoints - 25);

                      //left side arrow
                      context.moveTo(
                        actualStartingPoint.x + 2,
                        topLinePoints - 20
                      );
                      context.lineTo(nextPoint.x - 8, topLinePoints - 15);

                      context.moveTo(
                        actualStartingPoint.x + 2,
                        topLinePoints - 20
                      );
                      context.lineTo(nextPoint.x - 8, topLinePoints - 25);

                      setLabelX1((prev) => ({
                        ...prev,
                        x2: actualStartingPoint.x,
                      }));
                      setLabelX3((prev) => ({
                        ...prev,
                        x1: actualStartingPoint.x,
                      }));

                      actualStartingPoint = {
                        x: topRightPoint.x + rightTopCornerGap.x,
                        y: topRightPoint.y + rightTopCornerGap.y,
                      };
                      nextPoint = calculateNextPoint(
                        actualStartingPoint,
                        270,
                        steps * 1.4
                      );
                      context.moveTo(
                        actualStartingPoint.x + 2,
                        topLinePoints - 75
                      );
                      context.lineTo(nextPoint.x + 2, startPointY);
                      setLabelX((prev) => ({
                        ...prev,
                        x2: actualStartingPoint.x,
                      }));
                      setLabelX3((prev) => ({
                        ...prev,
                        x2: actualStartingPoint.x,
                      }));

                      //<---------------------------------------------vertical line bottom right --------------------------------->

                      //left
                      const bottomRightPoint =
                        labelingPoints.bottomPtSecondLast;
                      actualStartingPoint = {
                        x: bottomRightPoint.x,
                        y: bottomRightPoint.y,
                      };
                      nextPoint = calculateNextPoint(
                        actualStartingPoint,
                        90,
                        steps - cornerGap.y
                      );
                      context.moveTo(
                        actualStartingPoint.x,
                        actualStartingPoint.y
                      );
                      context.lineTo(nextPoint.x, actualStartingPoint.y + 30);

                      // second verticle line (right)
                      actualStartingPoint = {
                        x: bottomRightPoint.x + innerGap.x,
                        y: bottomRightPoint.y,
                      };
                      nextPoint = calculateNextPoint(
                        actualStartingPoint,
                        90,
                        steps - cornerGap.y
                      );
                      context.moveTo(
                        actualStartingPoint.x,
                        actualStartingPoint.y
                      );
                      context.lineTo(nextPoint.x, actualStartingPoint.y + 30);

                      setEndHzPoint(nextPoint.x);

                      //<----------------------------------------------horizontal line bottom right----------------------------------------->
                      const horizontalBottomRightPoint =
                        labelingPoints.rightPtEnd; //{x:593,y:126}
                      //bottom line
                      actualStartingPoint = {
                        x: horizontalBottomRightPoint.x,
                        y: horizontalBottomRightPoint.y,
                      };
                      nextPoint = calculateNextPoint(
                        actualStartingPoint,
                        360,
                        steps
                      );
                      context.moveTo(
                        actualStartingPoint.x,
                        actualStartingPoint.y
                      );
                      context.lineTo(actualStartingPoint.x + 30, nextPoint.y);

                      setBottomHzPoint(actualStartingPoint.y);

                      //top line
                      actualStartingPoint = {
                        x: horizontalBottomRightPoint.x,
                        y: horizontalBottomRightPoint.y - innerGap.y,
                      };
                      nextPoint = calculateNextPoint(
                        actualStartingPoint,
                        360,
                        steps
                      );
                      context.moveTo(
                        actualStartingPoint.x,
                        actualStartingPoint.y
                      );
                      context.lineTo(actualStartingPoint.x + 30, nextPoint.y);

                      // (!) Konva specific method, it is very important
                      context.fillStrokeShape(shape);
                    }
                  }}
                  stroke="grey"
                  strokeWidth={1}
                />
                <Shape
                  sceneFunc={(context, shape) => {
                    const horizontalBottomRightPoint =
                      labelingPoints.rightPtEnd; //{x:593,y:126}
                    let actualStartingPoint = {
                      x: horizontalBottomRightPoint.x,
                      y: horizontalBottomRightPoint.y - innerGap.y,
                    };
                    let nextPoint = calculateNextPoint(
                      actualStartingPoint,
                      360,
                      steps
                    );
                    if (shapeObj && canvasHeight > 150 && canvasWidth > 150) {
                      context.beginPath();
                      //right bottom vertical line below two horizontal lines
                      if (customizeView.uniformGap) {
                        actualStartingPoint = {
                          x: nextPoint.x,
                          y: nextPoint.y,
                        };
                        nextPoint = calculateNextPoint(
                          actualStartingPoint,
                          90,
                          innerGap.y
                        );
                        context.moveTo(rightLinePoints + 20, bottomHzPoint);
                        context.lineTo(
                          rightLinePoints + 20,
                          labelingPoints.bottomPtSecondLast.y + 20
                        );
                      }

                      //---------center line b/w horizontal lines----------------
                      context.moveTo(
                        rightLinePoints + 20,
                        actualStartingPoint.y
                      );
                      context.lineTo(rightLinePoints + 20, bottomHzPoint);

                      //--------top arrow---------
                      //left
                      context.moveTo(
                        rightLinePoints + 20,
                        actualStartingPoint.y
                      );
                      context.lineTo(
                        rightLinePoints + 15,
                        actualStartingPoint.y + 3
                      );

                      //left
                      context.moveTo(
                        rightLinePoints + 20,
                        actualStartingPoint.y
                      );
                      context.lineTo(
                        rightLinePoints + 25,
                        actualStartingPoint.y + 3
                      );

                      //--------bottom arrow---------
                      //left
                      context.moveTo(rightLinePoints + 20, bottomHzPoint);
                      context.lineTo(rightLinePoints + 15, bottomHzPoint - 3);

                      //left
                      context.moveTo(rightLinePoints + 20, bottomHzPoint);
                      context.lineTo(rightLinePoints + 25, bottomHzPoint - 3);

                      //end horizontal line right to two vertical lines
                      const bottomRightPoint =
                        labelingPoints.bottomPtSecondLast;
                      actualStartingPoint = {
                        x: bottomRightPoint.x,
                        y: bottomRightPoint.y,
                      };
                      nextPoint = calculateNextPoint(
                        actualStartingPoint,
                        90,
                        steps - cornerGap.y
                      );
                      actualStartingPoint = { x: nextPoint.x, y: nextPoint.y };
                      nextPoint = calculateNextPoint(
                        actualStartingPoint,
                        360,
                        steps + innerGap.x + cornerGap.x
                      );
                      if (customizeView.uniformGap) {
                        // horizontal line
                        context.moveTo(endHzPoint, bottomRightPoint.y + 20);
                        context.lineTo(
                          rightLinePoints + 20,
                          bottomRightPoint.y + 20
                        );
                      }

                      //----------center line b/w vertical lines--------
                      context.moveTo(
                        actualStartingPoint.x,
                        bottomRightPoint.y + 20
                      );
                      context.lineTo(endHzPoint, bottomRightPoint.y + 20);

                      //----left arrow-----

                      //bottom
                      context.moveTo(
                        actualStartingPoint.x,
                        bottomRightPoint.y + 20
                      );
                      context.lineTo(
                        actualStartingPoint.x + 3,
                        bottomRightPoint.y + 25
                      );

                      //top
                      context.moveTo(
                        actualStartingPoint.x,
                        bottomRightPoint.y + 20
                      );
                      context.lineTo(
                        actualStartingPoint.x + 3,
                        bottomRightPoint.y + 15
                      );

                      //----right arrow-----

                      //bottom
                      context.moveTo(endHzPoint, bottomRightPoint.y + 20);
                      context.lineTo(endHzPoint - 3, bottomRightPoint.y + 25);

                      //top
                      context.moveTo(endHzPoint, bottomRightPoint.y + 20);
                      context.lineTo(endHzPoint - 3, bottomRightPoint.y + 15);

                      // (!) Konva specific method, it is very important
                      context.fillStrokeShape(shape);
                    }
                  }}
                  stroke="grey"
                  strokeWidth={1}
                />

                {!customizeView.uniformGap ? (
                  <>
                    <Text
                      text="@1"
                      x={rightLinePoints + 50}
                      y={labelingPoints.bottomPtEnd.y   - (initiateImage ? Y_OFFSET+2 : 0)}
                    />
                    {!isMobile && ( initiateImage ? (
                      <Text
                        text={shapeObj.x_gap || ""}
                        x={rightLinePoints + 50}
                        y={labelingPoints.bottomPtEnd.y + Y_OFFSET + 2}
                      />
                    
                    ) : (
                      <Html
                      divProps={{
                        style: {
                          position: "absolute",
                          top: `${labelingPoints.bottomPtEnd.y + 15}px`,
                          left: `${rightLinePoints + 35}px`,
                        },
                      }}
                    >
                      <select
                        className={
                          "form-control form-control-sm inputField" +
                          (errorsText.some(
                            (error) =>
                              error.errorType ===
                              netErrorTypes.GAP_X1_VALIDATION
                          ) ||
                          errorsText.some(
                            (error) =>
                              error.errorType ===
                              netErrorTypes.GAP_X1Y1_VALIDATION
                          )
                            ? " error-input"
                            : "")
                        }
                        value={shapeObj.x_gap || ""}
                        onChange={(e) => {
                          setShape({
                            ...shapeObj,
                            x_gap: parseInt(e.target.value),
                            y_gap: customizeView.uniformGap
                              ? parseInt(e.target.value)
                              : shapeObj.y_gap,
                          });
                          if (!edgeChanged_X) {
                            const widthPartitions = calculate_partitions(
                              parseInt(e.target.value),
                              shapeObj.x_length,
                              shapeObj.x_diameter.steel_diameter
                            );
                            setPartitionX(widthPartitions);
                            removeError(netErrorTypes.GAP_X1_VALIDATION);
                            removeError(netErrorTypes.GAP_X1Y1_VALIDATION);
                            removeError(netErrorTypes._X2);
                          } else {
                            if (
                              partitionX._1 % parseInt(e.target.value) !==
                                0 &&
                              partitionY._1 % shapeObj.y_gap !== 0
                            ) {
                              addError({
                                errorType: netErrorTypes.GAP_X1Y1_VALIDATION,
                                message: t("error_net_gap_xy", {
                                  ns: "tool",
                                }),
                              });
                            } else if (
                              partitionX._1 % parseInt(e.target.value) !==
                              0
                            ) {
                              addError({
                                errorType: netErrorTypes.GAP_X1_VALIDATION,
                                message: t("error_net_gap_xy", {
                                  ns: "tool",
                                }),
                              });
                            } else {
                              removeError(netErrorTypes.MIN_X2PART);
                              removeError(netErrorTypes._X2);
                              removeError(netErrorTypes.GAP_X1Y1_VALIDATION);
                              removeError(netErrorTypes.GAP_X1_VALIDATION);
                            }
                          }
                        }}
                      >
                        {gapOptions.map((item, index) => (
                          <option
                            className="shp_option"
                            key={"gap-options-1" + index}
                          >
                            {item}
                          </option>
                        ))}
                      </select>
                    </Html>

                    )
                    )}
                  </>
                ) : (
                  <></>
                )}

                <Text
                  text={customizeView.uniformGap ? "@" : "@2"}
                  x={rightLinePoints + 50}
                  y={labelingPoints.rightPtEnd.y - innerGap.y / 2 - 30 - (initiateImage ? Y_OFFSET+2 : 0)}
                />

                {!isMobile && (
                  initiateImage ? (
                    <Text
                      text={shapeObj.y_gap || ""}
                      x={rightLinePoints + 50}
                      y={labelingPoints.rightPtEnd.y - innerGap.y / 2 - 30 + Y_OFFSET + 2}
                    />
                  ) : (
                    <Html
                    divProps={{
                      style: {
                        position: "absolute",
                        top: `${
                          labelingPoints.rightPtEnd.y - innerGap.y / 2 - 12
                        }px`,
                        left: `${rightLinePoints + 35}px`,
                      },
                    }}  
                  >
                    <select
                      className={
                        "form-control form-control-sm inputField" +
                        (errorsText.some(
                          (error) =>
                            error.errorType === netErrorTypes.GAP_Y1_VALIDATION
                        ) ||
                        errorsText.some(
                          (error) =>
                            error.errorType ===
                            netErrorTypes.GAP_X1Y1_VALIDATION
                        )
                          ? " error-input"
                          : "")
                      }
                      value={shapeObj.y_gap || ""}
                      onChange={(e) => {
                        setShape({
                          ...shapeObj,
                          y_gap: parseInt(e.target.value),
                          x_gap: customizeView.uniformGap
                            ? parseInt(e.target.value)
                            : shapeObj.x_gap,
                        });
                        if (!edgeChanged_Y) {
                          const heightPartitions = calculate_partitions(
                            parseInt(e.target.value),
                            shapeObj.y_length,
                            shapeObj.y_diameter.steel_diameter
                          );
                          setPartitionY(heightPartitions);
                          removeError(netErrorTypes.GAP_X1Y1_VALIDATION);
                          removeError(netErrorTypes.GAP_Y1_VALIDATION);
                          removeError(netErrorTypes._Y2);
                        } else {
                          if (
                            partitionX._1 % shapeObj.x_gap !== 0 &&
                            partitionY._1 % parseInt(e.target.value) !== 0
                          ) {
                            addError({
                              errorType: netErrorTypes.GAP_X1Y1_VALIDATION,
                              message: t("error_net_gap_xy", { ns: "tool" }),
                            });
                          } else if (
                            partitionY._1 % parseInt(e.target.value) !==
                            0
                          ) {
                            addError({
                              errorType: netErrorTypes.GAP_Y1_VALIDATION,
                              message: t("error_net_gap_xy", { ns: "tool" }),
                            });
                          } else {
                            removeError(netErrorTypes.GAP_X1Y1_VALIDATION);
                            removeError(netErrorTypes.GAP_Y1_VALIDATION);
                          }
                        }
                      }}
                    >
                      {gapOptions.map((item, index) => (
                        <option key={"gap-options-1" + index}>{item}</option>
                      ))}
                    </select>
                  </Html>

                  )
                )}

                {!isMobile && (
                  <>
                    <Text
                      text="X"
                      x={(labelX.x1 + labelX.x2) / 2 - XTEXTCONST}
                      y={topLinePoints - 90 - (initiateImage ? Y_OFFSET : 0)}
                    />
                    {
                      initiateImage ? (
                        <Text
                          text={shapeObj.x_length || ""}
                          x={(labelX.x1 + labelX.x2) / 2 - XTEXTCONST}
                          y={(topLinePoints - 90) + Y_OFFSET}
                        />
                      ) : 
                      (
                        <Html
                        divProps={{
                          style: {
                            position: "absolute",
                            top: `${topLinePoints - 80}px`,
                            left: `${
                              (labelX.x1 + labelX.x2) / 2 - XCENTERCONST
                            }px`,
                          },
                        }}
                      >
                        <DebounceInput
                        style={{ width: "50px" }}
                        className={
                          "inputField " +
                          (shapeObj.x_length < netXLengthMin ||
                          shapeObj.x_length >= netXLengthMax ||
                          errorsText.some(
                            (error) => error.errorType === netErrorTypes.MAXMIN
                          )
                            ? "error-input"
                            : "")
                        }
                        debounceTimeout={700}
                        type="number"
                        onFocus={(e: any) => e.target.select()}
                        value={shapeObj.x_length || ""}
                        onChange={(e) => {
                          const x_val = Math.abs(parseInt(e.target.value));
                          removeError(netErrorTypes.MIN);
                          removeError(netErrorTypes.MAX);
                          removeError(netErrorTypes.MAXMIN);
    
                          setShape({
                            ...shapeObj,
                            x_length: x_val,
                          });
                          let partX1;
                          if(!edgeChanged_X 
                            || (partitionX._2 + partitionX._3 >= x_val)
                            ){
                              const widthPartitions = calculate_partitions(
                                shapeObj.x_gap,
                                x_val,
                                shapeObj.x_diameter.steel_diameter
                              );
                              setPartitionX(widthPartitions);
                              removeError(netErrorTypes.GAP_X1Y1_VALIDATION);
                              removeError(netErrorTypes.GAP_X1_VALIDATION);
                              setEdgeChanged_X(false);
                              partX1 = widthPartitions._1;
                          }else{
                            setPartitionX({
                              ...partitionX,
                              _1: x_val- (partitionX._2 + partitionX._3),
                            })
                            partX1 = x_val - (partitionX._2 + partitionX._3);
                            if (
                              partX1 % shapeObj.x_gap !== 0 &&
                              partitionY._1 % shapeObj.y_gap !== 0
                            ) {
                              addError({
                                errorType: netErrorTypes.GAP_X1Y1_VALIDATION,
                                message: t("error_net_gap_xy", { ns: "tool" }),
                              });
                            } else if (partX1 % shapeObj.x_gap !== 0) {
                              addError({
                                errorType: netErrorTypes.GAP_X1_VALIDATION,
                                message: t("error_net_gap_xy", { ns: "tool" }),
                              });
                            } else {
                              removeError(netErrorTypes.GAP_X1Y1_VALIDATION);
                              removeError(netErrorTypes.GAP_X1_VALIDATION);
                            }
                          }
                        }}
                        onBlur={checkLengths}
                      />
                      </Html>
                      )
                    }
                  </>
                )}

                <Text
                  text="X1"
                  x={(labelX1.x1 + labelX1.x2) / 2 - XTEXTCONST}
                  y={topLinePoints - 40 - (initiateImage ? Y_OFFSET : 0)}
                />

                {!isMobile &&
                  X2LabelPoints.right !== X1LabelPoints.left &&
                  X2LabelPoints.right < X1LabelPoints.left &&
                  X3LabelPoints.left !== X1LabelPoints.right &&
                  X3LabelPoints.left > X1LabelPoints.right && (
                    initiateImage ? (
                      <Text
                        text={Number(partitionX._1).toFixed(0)}
                        x={(labelX1.x1 + labelX1.x2) / 2 - XTEXTCONST}
                        y={topLinePoints - 40 + 10}
                      />
    
                    ) : (
                      <Html
                      divProps={{
                        style: {
                          position: "absolute",
                          top: `${topLinePoints - 30}px`,
                          left: `${
                            (labelX1.x1 + labelX1.x2) / 2 - XCENTERCONST
                          }px`,
                        },
                      }}
                    >
                      <div
                        className={
                          "nets_shape_val" +
                          (errorsText.some(
                            (error) => error.errorType === netErrorTypes._X1
                          ) ||
                          errorsText.some(
                            (error) =>
                              error.errorType ===
                              netErrorTypes.GAP_X1_VALIDATION
                          ) ||
                          errorsText.some(
                            (error) =>
                              error.errorType ===
                              netErrorTypes.GAP_X1Y1_VALIDATION
                          )
                            ? "_error"
                            : "")
                        }
                      >
                        {Number(partitionX._1).toFixed(0) || ""}
                      </div>
                    </Html>
                    )
                  )}

                <Text
                  text="X2"
                  x={(labelX2.x1 + labelX2.x2) / 2 - XTEXTCONST}
                  y={topLinePoints - 40  - (initiateImage ? Y_OFFSET : 0)}
                />

                {!isMobile &&
                  X2LabelPoints.right !== X3LabelPoints.left &&
                  X2LabelPoints.right < X3LabelPoints.left && (
                    initiateImage ? (
                      <Text
                        text={partitionX._2 || ""}
                        x={(labelX2.x1 + labelX2.x2) / 2 - XTEXTCONST}
                        y={topLinePoints - 40 + Y_OFFSET}
                      />
                    ) : (
                      <Html
                      divProps={{
                        style: {
                          position: "absolute",
                          top: `${topLinePoints - 30}px`,
                          left: `${
                            (labelX2.x1 + labelX2.x2) / 2 - XCENTERCONST
                          }px`,
                        },
                      }}
                    >
                      {showStandard ? (
                        <div
                          className={
                            "nets_shape_val" +
                            (errorsText.some(
                              (error) => error.errorType === netErrorTypes._X2
                            ) ||
                            errorsText.some(
                              (error) =>
                                error.errorType === netErrorTypes.MIN_X2PART
                            )
                              ? "_error"
                              : "")
                          }
                        >
                          {partitionX._2 || ""}
                        </div>
                      ) : (
                        <input
                          type="number"
                          min="0"
                          className={
                            "inputField" +
                            (errorsText.some(
                              (error) => error.errorType === netErrorTypes._X2
                            ) ||
                            errorsText.some(
                              (error) =>
                                error.errorType === netErrorTypes.MIN_X2PART
                            )
                              ? " error-input"
                              : "")
                          }
                          style={
                            errorsText.some(
                              (error) => error.errorType === netErrorTypes._X2
                            ) ||
                            errorsText.some(
                              (error) =>
                                error.errorType === netErrorTypes.MIN_X2PART
                            )
                              ? {
                                  border: "2px solid #f84242",
                                  outlineColor: "#f84242",
                                }
                              : {}
                          }
                          value={partitionX._2 || ""}
                          //debounceTimeout={300}
                          onFocus={(e: any) => e.target.select()}
                          onChange={onX2Change}
                        />
                      )}
                    </Html>
                    )
                  )}

                <Text
                  text="X3"
                  x={(labelX3.x1 + labelX3.x2) / 2 - XTEXTCONST}
                  y={topLinePoints - 40  - (initiateImage ? Y_OFFSET : 0)}
                />

                {!isMobile &&
                  X3LabelPoints.left !== X2LabelPoints.right &&
                  X3LabelPoints.left > X2LabelPoints.right && (
                    initiateImage ? (
                      <Text
                        text={partitionX._3 || ""}
                        x={(labelX3.x1 + labelX3.x2) / 2 - XTEXTCONST}
                        y={topLinePoints - 40 + Y_OFFSET}
                      />
                    ) : (
                      <Html
                      divProps={{
                        style: {
                          position: "absolute",
                          top: `${topLinePoints - 30}px`,
                          left: `${
                            (labelX3.x1 + labelX3.x2) / 2 - XCENTERCONST
                          }px`,
                        },
                      }}
                    >
                      {showStandard ? (
                        <div
                          className={
                            "nets_shape_val" +
                            (errorsText.some(
                              (error) => error.errorType === netErrorTypes._X3
                            ) ||
                            errorsText.some(
                              (error) =>
                                error.errorType === netErrorTypes.MIN_X3PART
                            )
                              ? "_error"
                              : "")
                          }
                        >
                          {partitionX._3 || ""}
                        </div>
                      ) : (
                        <input
                          type="number"
                          min="0"
                          className={
                            "inputField" +
                            (errorsText.some(
                              (error) => error.errorType === netErrorTypes._X3
                            ) ||
                            errorsText.some(
                              (error) =>
                                error.errorType === netErrorTypes.MIN_X3PART
                            )
                              ? " error-input"
                              : "")
                          }
                          style={
                            errorsText.some(
                              (error) => error.errorType === netErrorTypes._X3
                            ) ||
                            errorsText.some(
                              (error) =>
                                error.errorType === netErrorTypes.MIN_X3PART
                            )
                              ? {
                                  border: "2px solid #f84242",
                                  outlineColor: "#f84242",
                                }
                              : {}
                          }
                          value={partitionX._3 || ""}
                          //debounceTimeout={300}
                          onFocus={(e: any) => e.target.select()}
                          onChange={onX3Change}
                        />
                      )}
                    </Html>

                    )
                  )}

                {!isMobile && (
                  <>
                    <Text
                      text="Y"
                      x={leftLinePoints - YTEXT_PAD - 60}
                      y={(labelY.y1 + labelY.y2) / 2 - TEXTCONST  - (initiateImage ? Y_OFFSET : 0)}
                    />

                    {
                      initiateImage ? (
                        <Text
                          text={shapeObj.y_length}
                          x={leftLinePoints - YTEXT_PAD - 60}
                          y={(labelY.y1 + labelY.y2) / 2 - TEXTCONST + Y_OFFSET}
                        />
                      ) : (
                        <Html
                        divProps={{
                          style: {
                            position: "absolute",
                            top: `${(labelY.y1 + labelY.y2) / 2 - CENTERCONST}px`,
                            left: `${leftLinePoints - YLABELS_PAD - 60}px`,
                            //transform: "rotate(90deg)"
                          },
                        }}
                      >
                        <DebounceInput
                          className={
                            "inputField " +
                            (shapeObj.y_length < netYLengthMin ||
                            shapeObj.y_length >= netYLengthMax ||
                            errorsText.some(
                              (error) => error.errorType === netErrorTypes.MAXMIN
                            )
                              ? "error-input"
                              : "")
                          }
                          debounceTimeout={700}
                          type="number"
                          onFocus={(e: any) => e.target.select()}
                          value={shapeObj.y_length}
                          onChange={(e) => {
                            const y_val = Math.abs(parseInt(e.target.value));
                            removeError(netErrorTypes.MIN);
                            removeError(netErrorTypes.MAX);
                            removeError(netErrorTypes.MAXMIN);
                            setShape({
                              ...shapeObj,
                              y_length: y_val,
                            });
                            let partY1;
                            if (
                              !edgeChanged_Y ||
                              partitionY._2 + partitionY._3 >=
                              y_val
                            ) {
                              const heightPartitions = calculate_partitions(
                                shapeObj.y_gap,
                                y_val,
                                shapeObj.y_diameter.steel_diameter
                              );
                              setEdgeChanged_Y(false);
                              setPartitionY(heightPartitions);
                              removeError(netErrorTypes.GAP_Y1_VALIDATION);
                              removeError(netErrorTypes.GAP_X1Y1_VALIDATION);
                              partY1 = heightPartitions._1;
                            } else {
                              setPartitionY({
                                ...partitionY,
                                _1:
                                  y_val -
                                  (partitionY._2 + partitionY._3),
                              });
                              partY1 =
                                y_val -
                                (partitionY._2 + partitionY._3);
                                if (
                                  partitionX._1 % shapeObj.x_gap !== 0 &&
                                  partY1 % shapeObj.y_gap !== 0
                                ) {
                                  addError({
                                    errorType: netErrorTypes.GAP_X1Y1_VALIDATION,
                                    message: t("error_net_gap_xy", { ns: "tool" }),
                                  });
                                } else if (partY1 % shapeObj.y_gap !== 0) {
                                  addError({
                                    errorType: netErrorTypes.GAP_Y1_VALIDATION,
                                    message: t("error_net_gap_xy", { ns: "tool" }),
                                  });
                                } else {
                                  removeError(netErrorTypes.GAP_X1Y1_VALIDATION);
                                  removeError(netErrorTypes.GAP_Y1_VALIDATION);
                                }
                            }
                          }}
                          onBlur={checkLengths}
                        />
                      </Html>  
                      )
                    }
                  </>
                )}

                <Text
                  text="Y1"
                  x={leftLinePoints - YTEXT_PAD}
                  y={(labelY1.y1 + labelY1.y2) / 2 - TEXTCONST  - (initiateImage ? Y_OFFSET : 0) }
                />

                {!isMobile &&
                  Y3LabelPoints.bottom !== Y1LabelPoints.top &&
                  Y3LabelPoints.bottom < Y1LabelPoints.top &&
                  Y2LabelPoints.top !== Y1LabelPoints.bottom &&
                  Y2LabelPoints.top > Y1LabelPoints.bottom && (
                    initiateImage ? (
                      <Text
                        text={Number(partitionY._1).toFixed(0)}
                        x={leftLinePoints - YTEXT_PAD}
                        y={(labelY1.y1 + labelY1.y2) / 2 - TEXTCONST + Y_OFFSET}
                      />
                    ) : (
                      <Html
                      divProps={{
                        style: {
                          position: "absolute",
                          top: `${
                            (labelY1.y1 + labelY1.y2) / 2 - CENTERCONST
                          }px`,
                          left: `${leftLinePoints - YLABELS_PAD}px`,
                          //transform: "rotate(90deg)"
                        },
                      }}
                    >
                      {" "}
                      <div
                        className={
                          "nets_shape_val" +
                          (errorsText.some(
                            (error) => error.errorType === netErrorTypes._Y1
                          ) ||
                          errorsText.some(
                            (error) =>
                              error.errorType ===
                              netErrorTypes.GAP_Y1_VALIDATION
                          ) ||
                          errorsText.some(
                            (error) =>
                              error.errorType ===
                              netErrorTypes.GAP_X1Y1_VALIDATION
                          )
                            ? "_error"
                            : "")
                        }
                      >
                        {Number(partitionY._1).toFixed(0) || ""}
                      </div>
                    </Html>
                    )
                  )}

                <Text
                  text="Y2"
                  x={leftLinePoints - YTEXT_PAD}
                  y={(labelY2.y1 + labelY2.y2) / 2 - TEXTCONST  - (initiateImage ? Y_OFFSET : 0)}
                />

                {!isMobile && (
                  initiateImage ? (
                    <Text
                      text={partitionY._2 || ""}
                      x={leftLinePoints - YTEXT_PAD}
                      y={(labelY2.y1 + labelY2.y2) / 2 - TEXTCONST + Y_OFFSET}
                    />
  
                  ) : (
                    <Html
                    divProps={{
                      style: {
                        position: "absolute",
                        top: `${(labelY2.y1 + labelY2.y2) / 2 - CENTERCONST}px`,
                        left: `${leftLinePoints - YLABELS_PAD}px`,
                        //transform: "rotate(90deg)"
                      },
                    }}
                  >
                    {showStandard ? (
                      <div
                        className={
                          "nets_shape_val" +
                          (errorsText.some(
                            (error) => error.errorType === netErrorTypes._Y2
                          ) ||
                          errorsText.some(
                            (error) =>
                              error.errorType === netErrorTypes.MIN_Y2PART
                          )
                            ? "_error"
                            : "")
                        }
                      >
                        {partitionY._2 || ""}
                      </div>
                    ) : (
                      <input
                        type="number"
                        min="0"
                        className={
                          "inputField" +
                          (errorsText.some(
                            (error) => error.errorType === netErrorTypes._Y2
                          ) ||
                          errorsText.some(
                            (error) =>
                              error.errorType === netErrorTypes.MIN_Y2PART
                          )
                            ? " error-input"
                            : "")
                        }
                        style={
                          errorsText.some(
                            (error) => error.errorType === netErrorTypes._Y2
                          ) ||
                          errorsText.some(
                            (error) =>
                              error.errorType === netErrorTypes.MIN_Y2PART
                          )
                            ? {
                                border: "2px solid #f84242",
                                outlineColor: "#f84242",
                              }
                            : {}
                        }
                        value={partitionY._2 || ""}
                        //debounceTimeout={300}
                        onFocus={(e: any) => e.target.select()}
                        onChange={onY2Change}
                      />
                    )}
                  </Html>
                  )
                )}

                <Text
                  text="Y3"
                  x={leftLinePoints - YTEXT_PAD}
                  y={(labelY3.y1 + labelY3.y2) / 2 - TEXTCONST   - (initiateImage ? Y_OFFSET : 0)}
                />

                {!isMobile && (
                  initiateImage ? (
                    <Text
                      text={partitionY._3 || ""}
                      x={leftLinePoints - YTEXT_PAD}
                      y={(labelY3.y1 + labelY3.y2) / 2 - TEXTCONST + Y_OFFSET}
                    />
  
                  ) : (
                    <Html
                    divProps={{
                      style: {
                        position: "absolute",
                        top: `${(labelY3.y1 + labelY3.y2) / 2 - CENTERCONST}px`,
                        left: `${leftLinePoints - YLABELS_PAD}px`,
                        //transform: "rotate(90deg)"
                      },
                    }}
                  >
                    {showStandard ? (
                      <div
                        className={
                          "nets_shape_val" +
                          (errorsText.some(
                            (error) => error.errorType === netErrorTypes._Y3
                          ) ||
                          errorsText.some(
                            (error) =>
                              error.errorType === netErrorTypes.MIN_Y3PART
                          )
                            ? "_error"
                            : "")
                        }
                      >
                        {partitionY._3 || ""}
                      </div>
                    ) : (
                      <input
                        type="number"
                        min="0"
                        className={
                          "inputField" +
                          (errorsText.some(
                            (error) => error.errorType === netErrorTypes._Y3
                          ) ||
                          errorsText.some(
                            (error) =>
                              error.errorType === netErrorTypes.MIN_Y3PART
                          )
                            ? " error-input"
                            : "")
                        }
                        style={
                          errorsText.some(
                            (error) => error.errorType === netErrorTypes._Y3
                          ) ||
                          errorsText.some(
                            (error) =>
                              error.errorType === netErrorTypes.MIN_Y3PART
                          )
                            ? {
                                border: "2px solid #f84242",
                                outlineColor: "#f84242",
                              }
                            : {}
                        }
                        value={partitionY._3 || ""}
                        onFocus={(e: any) => e.target.select()}
                        onChange={onY3Change}
                      />
                    )}
                  </Html>
                  )
                )}
              </Layer>
            </Stage>
          </div>
        );
      }}
    </Measure>
    )
  );
}

export default NetShape;
