import InfiniteScroll from "react-infinite-scroll-component";
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import { MediaHost } from "Constants/general";
import {
  ALL_Filter,
  SHAPE_MODE,
  radiusMin,
  rebarErrorTypes,
} from "Constants/rebar-constants";
import FilterIcon from "../../../../assets/img/icon/filters.svg";
import React, { useEffect } from "react";
import useArrowKeyNavigation from "Utils/hooks/use-arrow";
import Lottie from "react-lottie";
import animationData from "./circle-mark.json";
import "./index.scss";

function ShapeList(props: any) {
  const {
    rebarContainer,
    isStopped,
    setIsStopped,
    checkShapeFilterActive,
    checkShapesExist,
    t,
    edit,
    previousShape,
    setPreviousShape,
    showCatalog,
    setRebarSHapeMode,
    setSelectedShape
  } = props;


  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const parentRef: any = useArrowKeyNavigation({
    selectors: ".defaultShapeCircle",
    rightToLeft,
    changeShape: props.changeShape,
    setPreviousShape,
  });

  useEffect(() => {
    const handleArrowKeyPress = (event: any) => {
      
      if (event.key.startsWith('Arrow')) {
        if (rebarContainer.current && !edit) {
          if(props.selectedShape.sIndex > 0 && props.selectedShape.sIndex > 0)
            document.getElementById(`standardShape.${props.selectedShape.gIndex}.${props.selectedShape.sIndex}`)?.focus()
          else{
            rebarContainer.current.focus();
            setIsStopped(false);
          }
          window.removeEventListener('keydown', handleArrowKeyPress);
        }
      }
    };
    window.addEventListener('keydown', handleArrowKeyPress);
    return () => {
      window.removeEventListener('keydown', handleArrowKeyPress);
    };
  }, []);

  useEffect(()=>{
    if(showCatalog && previousShape){
      props.changeShape(previousShape.gindex, previousShape.index);
    }
  }, [showCatalog])

  return (
    <div
      className="shape__list p-lg-3 p-xl-4"
      style={{ width: "288px", overflowX: "hidden" }}
      id={"shape=01"}
      ref={parentRef}
    >
      {/* <!-- left shape list  --> */}
      <div className="left__shaped" tabIndex={0}>
        {/* <h6 className="text-uppercase">{t('shape', {ns: 'tool'})}</h6> */}
        {props.standardShapes &&
          props.standardShapes.length > 0 &&
          props.standardShapes.map((group: any, gindex: number) => (
            <React.Fragment key={"group" + gindex}>
              {checkShapesExist(group.rebars) && (
                <div
                  className="shape__wrapper d-flex flex-wrap py-3 align-items-center"
                  style={gindex === 0 ? { position: "relative" } : {}}
                >
                  {group.rebars.map((defaultShape: any, index: number) => (
                    <React.Fragment key={"shape" + gindex + index}>
                      {checkShapeFilterActive(defaultShape.rebar_filter) && (
                        <div
                          tabIndex={-1}
                          id={"standardShape." + gindex + "." + index}
                          ref={(el) => {
                            if (el && gindex === 0 && index === 0) {
                              rebarContainer.current = el;
                            }
                          }}
                          onBlur={() => {
                            if (gindex === 0 && index === 0 && !isStopped) {
                              setIsStopped(true);
                            }
                          }}
                          style={{ zIndex: 10 }}
                          onClick={(e) => {
                            props.changeShape(gindex, index);
                            props.setShowCatalog(false);
                            setPreviousShape({gindex: gindex, index: index})
                            rebarContainer.current = e.target;
                          }}
                          onMouseEnter={()=>{
                            props.changeShape(gindex, index);
                            props.setShowCatalog(true);
                          }}
                          onMouseLeave={(e)=>{
                            if(previousShape){
                              props.changeShape(previousShape.gindex, previousShape.index);
                            }
                            else{
                              props.setSelectedShape(
                                {
                                  gIndex: -1,
                                  sIndex: -1
                                }
                              )
                              props.setRebarShapeMode(SHAPE_MODE.freeform)
                              e.target.classList.remove("active");
                            }
                            props.setShowCatalog(true);
                          }}
                          className={
                            "tool__wrap d-flex align-items-center justify-content-center defaultShapeCircle" +
                            (props.selectedShape.gIndex === gindex &&
                            props.selectedShape.sIndex === index
                              ? " active"
                              : "")
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              props.changeShape(gindex, index);
                              props.setShowCatalog(true);
                            }
                          }}
                        >
                          <img
                            className="d-inline-block"
                            src={
                              process.env.REACT_APP_ICONS_URI +
                              defaultShape?.icon?.file_field
                            }
                            alt={defaultShape?.icon?.alt_text || "UnKnown"}
                          />
                        </div>
                      )}
                    {gindex === 0 && index === 0 && !isStopped && !edit && (
                        <>
                          <Lottie
                            options={{
                              loop: true,
                              autoplay: false,
                              animationData: animationData,
                              rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                              },
                            }}
                            height={67}
                            width={67}
                            isStopped={isStopped}
                            style={{
                              marginRight: "0px",
                              position: "absolute",
                              ...(rightToLeft
                                ? {
                                    right: "-10.5px",
                                  }
                                : {
                                    left: "-11.5px",
                                  }),
                            }}
                          />
                          <div
                            style={{
                              background: "#2eb48f",
                              color: "#fff",
                              padding: "5px 10px",
                              borderRadius: "2px",
                            }}
                          >
                            {t("use arrow to move", { ns: "tool" })}
                          </div>
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              )}
            </React.Fragment>
          ))}
      </div>
    </div>
  );
}

function RebarCatalog(props: any) {
  // getDefaultRebars
  const page = useAppSelector((state) => state.rebar.page);
  const radiuses = ["a", "b", "c", "d", "e", "f"];
  const { t } = useTranslation(["common", "tool", "measurements"]);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  const checkShapeFilterActive = (shapeFilters: any[]) => {
    if (props.activeFilters.includes(ALL_Filter)) {
      return true;
    }
    for (let i = 0; i < shapeFilters.length; i++) {
      if (props.activeFilters.includes(shapeFilters[i].filter_name)) {
        return true;
      }
    }
    return false;
  };

  const checkShapesExist = (shapes: any[]) => {
    if (props.activeFilters.includes(ALL_Filter)) {
      return true;
    }
    for (let i = 0; i < shapes.length; i++) {
      for (let j = 0; j < shapes[i].rebar_filter.length; j++) {
        if (
          props.activeFilters.includes(shapes[i].rebar_filter[j].filter_name)
        ) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <div
      id="scrollableDiv"
      className="left"
      style={{ overflowY: "scroll", height: "688px", overflowX: "hidden" }}
    >
      <div className="catalog_container">
        <InfiniteScroll
          dataLength={props.standardShapes.length}
          next={props.getDefaultRebars}
          scrollableTarget="scrollableDiv"
          hasMore={!!page || page === ""}
          loader={props.loader}
          style={{
            overflowX: "hidden",
            width: "280px",
            scrollbarWidth: "none",
          }}
        >
          {/* <!-- filter section  --> */}
          <div className={"filter pt-3 " + (rightToLeft ? "pr-4" : "pl-4")}>
            <h6
              className={
                "text-uppercase text-dark-500 " +
                (rightToLeft ? "text-right" : "text-left")
              }
            >
              {t("filters", { ns: "tool" })}
            </h6>
          </div>
          {/* Shape Filters */}
          <div
            className={
              "d-flex py-3 border__bottom " +
              (rightToLeft ? "pr-4 pl-3" : "pl-4 pr-3")
            }
          >
            <img
              src={FilterIcon}
              alt="filters icon"
              width="31"
              height="20"
              style={
                rightToLeft ? { marginLeft: "10px" } : { marginRight: "10px" }
              }
            />
            <div
              className="d-flex flex-wrap"
              style={{
                columnGap: "10px",
                rowGap: "8px",
              }}
            >
              <div
                className="rebar-Filter"
                style={{
                  background:
                    props.activeFilters.length > 0 &&
                    props.activeFilters[0] === ALL_Filter
                      ? "#F84343"
                      : "#5F6674",
                }}
                onClick={() => props.setActiveFilters([ALL_Filter])}
              >
                {t("all", { ns: "tool" })}
              </div>
              {props.filters.map((ft: any) => (
                <div
                  className="rebar-Filter"
                  key={"rebar-filters" + ft.id}
                  style={{
                    background: props.activeFilters.includes(ft.filter_name)
                      ? "#F84343"
                      : "#5F6674",
                  }}
                  onClick={() => {
                    let cpFilters = [...props.activeFilters];
                    if (cpFilters.includes(ALL_Filter)) {
                      const index = cpFilters.indexOf(ALL_Filter);
                      cpFilters.splice(index, 1);
                    }
                    if (cpFilters.includes(ft.filter_name)) {
                      const index = cpFilters.indexOf(ft.filter_name);
                      cpFilters.splice(index, 1);
                      if (cpFilters.length === 0) cpFilters.push(ALL_Filter);
                      props.setActiveFilters(cpFilters);
                    } else {
                      props.setActiveFilters([...cpFilters, ft.filter_name]);
                    }
                  }}
                >
                  {ft.filter_name}
                </div>
              ))}
            </div>
          </div>
          {/* <!-- shape list  --> */}
          {props.standardShapes.length > 0 && (
            <ShapeList
              {...props}
              checkShapeFilterActive={checkShapeFilterActive}
              checkShapesExist={checkShapesExist}
              t={t}
            />
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default RebarCatalog;
