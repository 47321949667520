import React, {useEffect, useMemo, useState} from "react";
import { Stage, Layer, Shape, Circle, Text, Line } from 'react-konva';
import { calculateNextPoint, calculateSidePoints, calculateSidePointsSplitTwo } from 'Components/tool/utils';
import { externalStartDegrees, internalEndDegrees } from 'Constants/pile-constants';

function SideStage({
  sideSpilt,
  canvasWidth,
  canvasHeight,
  shapeObj,
}: any) {
  const [sideDots, setSideDots] = useState([] as any);
  const [circleLine1Points, setcircleLine1Points] = useState([] as any);
  const [circleLine2Points, setcircleLine2Points] = useState([] as any);
  const [external, setExternal] = useState({ x: 0, y: 0 });
  const [middle, setMiddle] = useState({ x: 0, y: 0 });
  const [internal, setInternal] = useState({ x: 0, y: 0 });

  const center = useMemo(() => {
    return {
      x: canvasWidth / 2,
      y: canvasHeight / 2,
    };
  }, [canvasWidth, canvasHeight]);
  const [innerRadius, setInnerRadius] = useState(0);
  const [outterRadius, setOutterRadius] = useState(0);

  useEffect(() => {

    const radius = shapeObj.cage_diameter / 2;
    setInnerRadius(radius);
    const oRadius = shapeObj.hole_diameter / 2;
    setOutterRadius(oRadius);

    
    const pad = 6;
    if (sideSpilt === 3) {
      const internalLinePoint = calculateNextPoint({ x: center.x, y: center.y }, internalEndDegrees, radius - pad);
      setcircleLine1Points([internalLinePoint.x - 80 * 2.5, internalLinePoint.y - 4, internalLinePoint.x + 100, internalLinePoint.y - 4]);
  
      const externalLinePoint = calculateNextPoint({ x: center.x, y: center.y }, externalStartDegrees, radius - pad);
      setcircleLine2Points([externalLinePoint.x - 80 * 2.5, externalLinePoint.y + 4, externalLinePoint.x + 100, externalLinePoint.y + 4]);

      const xApart = 20, yApart = 20;
      setExternal({ x: externalLinePoint.x + xApart, y: externalLinePoint.y - yApart });
      setMiddle({ x: internalLinePoint.x + xApart + 10, y: internalLinePoint.y - yApart });
      setInternal({ x: internalLinePoint.x + xApart, y: internalLinePoint.y + yApart });

      const pointsOfinnerCircle = calculateSidePoints(shapeObj, center.x, center.y, radius, pad);
      setSideDots(pointsOfinnerCircle);
    } else {
      const internalLinePoint = calculateNextPoint({ x: center.x, y: center.y }, 0, radius - pad);
      setcircleLine1Points([internalLinePoint.x - 80 * 2.5, internalLinePoint.y, internalLinePoint.x + 100, internalLinePoint.y]);

      const pointsOfinnerCircle = calculateSidePointsSplitTwo(shapeObj, center.x, center.y, radius, pad);
      setSideDots(pointsOfinnerCircle);
    }


  }, [
    shapeObj,
    shapeObj.base_amount,
    shapeObj.hole_diameter,
    shapeObj.cage_diameter,
    sideSpilt,
    center.x,
    center.y,
  ]);
  
  return (
    <Stage width={canvasWidth} height={canvasHeight}>
      <Layer>
        <Shape
          sceneFunc={(context, shape) => {
            if (shapeObj && canvasHeight > 150 && canvasWidth > 150) {
              context.beginPath();
              context.arc(center.x, center.y, outterRadius, 0, 2 * Math.PI, 1.5 * Math.PI);
              context.fillStrokeShape(shape);
            }
          }}
          stroke="black"
          strokeWidth={2}
        />
        <Shape
          sceneFunc={(context, shape) => {
            if (shapeObj && canvasHeight > 150 && canvasWidth > 150) {
              context.beginPath();
              context.arc(center.x, center.y, innerRadius, 0, 2 * Math.PI, 1.5 * Math.PI);
              context.fillStrokeShape(shape);
            }
          }}
          stroke="black"
          strokeWidth={4}
        />
        {sideDots.map((dt: any, index: number) =>
          <Circle key={'sideDot-'+index} x={dt.x} y={dt.y} radius={3} fill="black" />
        )}
        {sideSpilt === 3 ? (
          <>
            <Line points={circleLine1Points} strokeWidth={1} stroke="grey" />
            <Line points={circleLine2Points} strokeWidth={1} stroke="grey" />
          </>
        ) : (
          <Line points={circleLine1Points} strokeWidth={1} stroke="grey" />
        )}
        
        {shapeObj.external_amount && shapeObj.external_diameter.steel_diameter &&
          <Text
            text={shapeObj.external_amount + " \u0424 " + shapeObj.external_diameter.steel_diameter}
            x={external.x}
            y={external.y}
            fontSize={18}
          />
        }
        {shapeObj.middle_amount && shapeObj.middle_diameter.steel_diameter &&
          <Text
            text={shapeObj.middle_amount + " \u0424 " + shapeObj.middle_diameter.steel_diameter}
            x={middle.x}
            y={middle.y - 10}
            fontSize={18}
          />
        }
        {shapeObj.internal_amount && shapeObj.internal_diameter.steel_diameter &&
          <Text
            text={shapeObj.internal_amount + " \u0424 " + shapeObj.internal_diameter.steel_diameter}
            x={internal.x}
            y={internal.y}
            fontSize={18}
          />
        }
      </Layer>
    </Stage>
  );
}

export default SideStage;