import {
  addNetToCurrentOrder,
  setDefaultDiameters,
  setDefaultGaps,
  setDefaultNets,
  setDefaultShapes,
  setLastUsedNets,
  removeLastUsedNets,
  updateCurrentOrderNet,
} from "Redux/features/net";
import { netStore } from "Redux/offline";
import { AppThunk } from 'Redux/store';
import { isAxiosResponse } from "Services/index";
import { getDefaultNets, getLastUsedShapes } from "Services/net";
import { postOrderItem, updateOrderItem, getItemsCount } from "Services/order";
import { getMedia } from "Services/media";
import { NET_SUBCLASS_TYPE } from "Constants/net-constants";

// import { MediaHost } from "Constants/general";

//NOTE: row_number is 0 when creating new item and > 0 when duplicating item
export const createNet = (net: any, orderItem: any, next = (val: boolean) => {}, row_num: number, orderId = 0): AppThunk => async (dispatch, getState) => {
  return new Promise(async(resolve, reject) => {
    const user = getState().user.userData;
    if (user) {
      getItemsCount(NET_SUBCLASS_TYPE, orderId).then(
        async (result)=>{
          orderItem.row_number = row_num > 0 ? row_num : result.data.row_number + 1;
          let res;
          if (orderId > 0){
            res = await postOrderItem({
              ...orderItem,
              order: orderId,
              tool: net,
              user: user.id,
            });
          }
          else{
            res = await postOrderItem({
              ...orderItem,
              tool: net,
              user: user.id,
            });
          }
          if (isAxiosResponse(res)) {
            const previewID = res.data.tool.preview_image;
            const resMe = await getMedia(previewID);
            if (isAxiosResponse(resMe)) {
              const preview = {...resMe.data};
              // preview.file_field = preview.file_field.replace(MediaHost, '');
              const orderIt = {
                ...res.data,
                tool: {
                  ...res.data.tool,
                  preview_image: preview,
                }
              };
      
              const nets = getState().net.currentOrderNets
              if(nets.length === 0 || res.data.row_number - nets.slice(-1)[0].row_number === 1 || res.data.row_number < nets.slice(-1)[0].row_number){
                await dispatch(addNetToCurrentOrder(orderIt));
              }
              resolve();
              return next(true);
            }
          }
        }
      )
    } else {
      const netData = {
        ...orderItem,
        tool: net,
      };
      const previewID = netData.tool.preview_image;
      const resMe = await getMedia(previewID);
      if (isAxiosResponse(resMe)) {
        const preview = {...resMe.data};
        // preview.file_field = preview.file_field.replace(MediaHost, '');
        const orderIt = {
          ...netData,
          tool: {
            ...netData.tool,
            preview_image: preview,
          }
        };
        netStore.setItem(orderIt);
        await dispatch(addNetToCurrentOrder(orderIt));
      }
    }
  
  })
}

export const updateNet = (
  orderItemID: number,
  net: any,
  orderItem: any,
  next = (val: boolean) => {}
): AppThunk => async (dispatch, getState) => {
  const user = getState().user.userData;
  if (user) {
    const res = await updateOrderItem(orderItemID, {
      ...orderItem,
      tool: net,
    });
    if (isAxiosResponse(res)) {
      const previewID = res.data.tool.preview_image;
      const resMe = await getMedia(previewID);
      if (isAxiosResponse(resMe)) {
        const preview = {...resMe.data};
        // preview.file_field = preview.file_field.replace(MediaHost, '');
        const orderIt = {
          ...res.data,
          tool: {
            ...res.data.tool,
            preview_image: preview,
          }
        };
        await dispatch(updateCurrentOrderNet(orderIt));
        return next(true);
      }
    }
  }
}

export const fetchDefaultsNets = (): AppThunk => async dispatch => {
  const shapes = await getDefaultNets(2);
  if (shapes.length > 0) {
    const diameters: number[] = [], gaps: number[] = [];
    let xyLen = [];
    shapes.forEach((item: any) => {
      diameters.push(item.x_diameter.steel_diameter);
      gaps.push(item.x_gap);
      xyLen.push({ val: { x: item.x_length, y: item.y_length } });
    });

    const uniqueXYLen = Object.values(
      xyLen.reduce((item, item1) => {
        item[item1.val.x + "|" + item1.val.y] = item1;
        return item;
      }, {})
    );

    let standardShapes = [];
    uniqueXYLen.forEach((item) => {
      let netGaps = [] , netDiams = [], stTypes=[], uniqueDiams = [];
      shapes.forEach((val)=>{
        if(item.val.x === val.x_length && item.val.y === val.y_length){
          netDiams.push(val.x_diameter)
          uniqueDiams = netDiams.reduce((unique, obj) => {
            if (!unique.some((o) => o.steel_diameter === obj.steel_diameter)) {
              unique.push(obj);
            }
            return unique;
          }, []);
          uniqueDiams = uniqueDiams.sort((a:any, b:any) => a.steel_diameter - b.steel_diameter);
          netGaps.push(val.y_gap)
          netGaps = Array.from(new Set(netGaps))
                    .sort((a: number, b: number) => (a - b))
          stTypes.push(val.steel_type)
        }
      })
      standardShapes.push({lens: item.val, gaps: netGaps, dia: uniqueDiams, stTypes})
    })
    await dispatch(setDefaultNets(shapes));
    await dispatch(setDefaultShapes(standardShapes));
    await dispatch(setDefaultDiameters(
      Array.from(new Set(diameters))
      .sort((a: number, b: number) => (a - b))
    ));
    await dispatch(setDefaultGaps(
      Array.from(new Set(gaps))
      .sort((a: number, b: number) => (a - b))
    ));
  }
}

export const fetchLastUsedNets = (): AppThunk => async dispatch => {
  const lastUsedShapes = await getLastUsedShapes();
  if(lastUsedShapes.length > 0){
    await dispatch(setLastUsedNets(lastUsedShapes));
  } else{
    dispatch(removeLastUsedNets());
  }
}