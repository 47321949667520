import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { newOrderEn } from "Translations/en/new-order";
import { newOrderHe } from "Translations/he/new-order";
import { userProfileEn } from "Translations/en/user-profile";
import { userProfileHe } from "Translations/he/user-profile";
import { myOrderEn } from "Translations/en/my-order";
import { myOrderHe } from "Translations/he/my-order";
import { commonEn } from "Translations/en/common";
import { commonHe } from "Translations/he/common";
import { toolEn } from "Translations/en/tool";
import { toolHe } from "Translations/he/tool";
import { newPasswordEn } from "Translations/en/new-password";
import { newPasswordHe } from "Translations/he/new-password";
import { measurementsHe } from "Translations/he/measurements";
import { measurementsEn } from "Translations/en/measurements";
import { HeaderEn } from "Translations/en/header";
import { HeaderHe } from "Translations/he/header";
import { FooterEn } from "Translations/en/footer";
import { FooterHe } from "Translations/he/footer";
import { pdfOrderHe } from "Translations/he/order-pdf";
import { pdfOrderEn } from "Translations/en/order-pdf";
import { warningHe } from "Translations/he/warning";
import { warningEn } from "Translations/en/warning";
import { signupEn } from "Translations/en/signup";
import { signupHe } from "Translations/he/signup";
import { emailOrderEn } from "Translations/en/order-email";
import { emailOrderHe } from "Translations/he/order-email";
import { ticketCreatorEn } from "Translations/en/ticket-creator";
import { ticketCreatorHe } from "Translations/he/ticket-creator";

import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-http-backend" // <---- add this

const options = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
};

i18n
  .use(XHR) // <---- add this
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: options,
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        newOrder: newOrderEn,
        common: commonEn,
        userProfile: userProfileEn,
        myOrder: myOrderEn,
        tool: toolEn,
        newPassword: newPasswordEn,
        header: HeaderEn,
        footer: FooterEn,
        measurements: measurementsEn,
        pdfOrder: pdfOrderEn,
        warning: warningEn,
        signup: signupEn,
        emailOrder: emailOrderEn,
        ticketCreator: ticketCreatorEn,
      },
      he: {
        newOrder: newOrderHe,
        common: commonHe,
        userProfile: userProfileHe,
        myOrder: myOrderHe,
        tool: toolHe,
        newPassword: newPasswordHe,
        header: HeaderHe,
        footer: FooterHe,
        measurements: measurementsHe,
        pdfOrder: pdfOrderHe,
        warning: warningHe,
        signup: signupHe,
        emailOrder: emailOrderHe,
        ticketCreator: ticketCreatorHe,
      },
    },
    lng: "he", // if you're using a language detector, do not define the lng option
    fallbackLng: "he",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });