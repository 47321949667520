import Request from "./index";

export const postProject = (data: Object) =>
  Request.post('project/', data);

export const postProjectContacts = (data: Object) =>
  Request.post('project-contact/', data);

export const getProjects = () =>
  Request.get('project/');

export const getProject = (projectID: number) =>
  Request.get(`project/${projectID}/`);

export const patchProject = (projectID: number, data: Object) =>
  Request.patch(`project/${projectID}/`, data);

export const deleteProject = (projectID: number) =>
  Request.remove(`project/${projectID}/`);

export const deleteProjectContact = (projectcontactID: number) =>
  Request.remove(`project-contact/${projectcontactID}/`);