import { Modal } from 'react-bootstrap';
import { useAppSelector } from "Redux/hooks";
import './alert__modal.scss';

function AlertModal({ ...props }: any) {
  const { alertText, alertIcon } = props;

  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <section
          className="d-flex rounded custom__alert__modal"
          style={{ backgroundColor: "#00B38F" }}
        >
          <div className={rightToLeft ? "icon_margin_left" : "icon_margin_right"}>
            <img src={alertIcon} alt="alert icon" />
          </div>
          <div>
            <p className="alert__text">{alertText}</p>
          </div>
        </section>
      </Modal>
    </>
  );
}

export default AlertModal;
