import {useState} from 'react';
import { Modal } from 'react-bootstrap';
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

import "../index.scss"
function InReview({ ...props }) {
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  const { t } = useTranslation(['ticketCreator']);

  const {
    show,
    onHide
  } = props

  return (
    <>
      <Modal
        show = {show}
        onHide = {onHide}
        className="supplier_popups"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <>
          <div className="container spopup_container">
           
            <div className="popup_content">
              <p className="detail_message pt-5"
                style={ rightToLeft ? {} : {marginLeft: "150px", marginRight: "150px" }}>
              {t("your_details", { ns: "ticketCreator" })}
             </p>
             
             <p className="detail_message_2 pt-5 pb-3">
              {t("soon_print", { ns: "ticketCreator" })}
             </p>
             <Link to="/new-order">
             <p className="popup_details go_back pb-5">
                  {t("go_back", { ns: "ticketCreator" })}
                </p>
             </Link>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}

export default InReview;
