import React from "react";
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import Diameter from "../../../../assets/img/ticket-print-pops/diam.svg";
import { commaSeparated } from "Constants/general";
import { format } from "date-fns";

import "./index.scss";

const A4TicketCardRebars = ({ ...props }) => {
  const { rebar, orderNo, ticketIndex, printTotalCount, printCurrentCount } =
    props;
  const currentOrderRebars = useAppSelector(
    (state) => state.supplier.completeOrderDetails
  );

  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const { t } = useTranslation(["ticketCreator"]);
  return (
    <div className="a4-card">
      <div className="top-left-circle"></div>
      <div className="sketch-box">
        <img
          src={rebar.tool.preview_image.file_field.replace(
            "https://easybar-s3.s3.amazonaws.com",
            "https://app.easybar.co.il"
          )}
          alt="rebar_sketch"
        />
        {rebar.tool.name && (
          <p className="net_nickname_standard">
            {t("nickname", { ns: "ticketCreator" }) + ": " + rebar.tool.name}
          </p>
        )}
      </div>
      <div className="info-section">
        <p
          className={
            "sketch-Note ticket-border__bottom " +
            (rightToLeft ? "info-pr" : "info-pl")
          }
          style={rebar.production_notes === "" ? { paddingBottom: "30px" } : {}}
        >
          {rebar?.production_notes}
        </p>
        <div
          className={
            "rebar-detail ticket-border__bottom " +
            (rightToLeft ? "info-pr" : "info-pl")
          }
        >
          <p
            className="detail-title"
            style={
              rightToLeft ? { paddingLeft: "52px" } : { paddingRight: "12px" }
            }
          >
            {t("customer", { ns: "ticketCreator" })}
          </p>
          <p className="detail-sub">{currentOrderRebars.customer_name}</p>
        </div>
        <div
          className={
            "rebar-detail ticket-border__bottom " +
            (rightToLeft ? "info-pr" : "info-pl")
          }
        >
          <p
            className="detail-title"
            style={
              rightToLeft ? { paddingLeft: "12px" } : { paddingRight: "25px" }
            }
          >
            {t("delivery", { ns: "ticketCreator" })}
          </p>
          <p className="detail-sub">
            {format(new Date(currentOrderRebars.delivery_date), "dd/MM/yyy")}
          </p>
        </div>
        <div
          className={
            "rebar-detail ticket-border__bottom " +
            (rightToLeft ? "info-pr" : "info-pl")
          }
        >
          <p
            className="detail-title"
            style={
              rightToLeft ? { paddingLeft: "85px" } : { paddingRight: "60px" }
            }
          >
            {t("site", { ns: "ticketCreator" })}
          </p>
          <p className="detail-sub">{currentOrderRebars.site_address}</p>
        </div>
        <div className="weight-section ticket-border__bottom">
          <p>
            {t("item weight", { ns: "ticketCreator" })}
            {rebar.tool.unit_weight.toString().replace(commaSeparated, ",")}
          </p>
          <p>
            {t("total weight", { ns: "ticketCreator" })}
            {(rebar.tool.unit_weight * rebar.quantity)
              .toFixed(2)
              .toString()
              .replace(commaSeparated, ",")}
          </p>
        </div>
      </div>
      <div className="types-section">
        <div className="detail-box">
          <p>{t("type|diam", { ns: "ticketCreator" })}</p>
          <div className="info-box">
            <img
              src={Diameter}
              alt="diameter icon"
              style={{ paddingBottom: "10px" }}
            />
            <p>{rebar.tool.diameter.steel_diameter}</p>
          </div>
        </div>
        <div className="detail-box">
          <p>{t("length", { ns: "ticketCreator" })}</p>
          <div className="info-box">
            <p>
              {rebar.tool.rebar_side.reduce(
                (previousValue: number, item: any) =>
                  previousValue + parseInt(item.length),
                0
              )}
            </p>
          </div>
        </div>
        <div className="detail-box">
          <p>{t("amount", { ns: "ticketCreator" })}</p>
          <div className="info-box">
            <p>{rebar.quantity.toString().replace(commaSeparated, ",")}</p>
          </div>
        </div>
      </div>
      <div className="footer-section">
        {rightToLeft ? (
          <div className="order-no">
            <p className="ticket_print_count_txt">
              ({printCurrentCount}/{printTotalCount})
            </p>
            <p>{`${ticketIndex} / ${orderNo}`}</p>
          </div>
        ) : (
          <div className="order-no">
            <p>{`${orderNo} / ${ticketIndex}`}</p>
            <p className="ticket_print_count_txt">
              ({printCurrentCount}/{printTotalCount})
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default A4TicketCardRebars;
