export const MediaHost = process.env.REACT_APP_MEDIA_IP;
export const PI = 3.14;

export const removeBgBorder = {
  border: 'none',
  background: 'transparent',
}

export const availablelangauges = {
  ENGLISH: 0,
  HEBREW: 1,
}

export const commaSeparated = /\B(?=(\d{3})+(?!\d))/g;

export const defualtLat = 31.0461;
export const defaultLng = 34.8516;

export const STEEL_TYPES = [
  3, 9
]

export const CLAIM_TYPES = {
  TERMS_AND_CONDITIONS : "TC"
}