import CustomerLayout from "../../layouts/customer";
import ContactTable from "./contacttable";
import OrderTable from "./ordertable";
import SiteTable from "./sitestable";
import { isBrowser, isMobile} from 'react-device-detect';
import CreateNewContact from "./modals/contact";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { fetchContacts } from "Redux/middlwares/contact";
import AddNewProject from "Components/new-order/add-new-project/index-copy";
import { fetchProjects } from "Redux/middlwares/project";
import { getWeeklyStats } from 'Services/order';
import { deletedOrder, setOrderShareStatus } from "Redux/features/order";
import PdfPreviewModal from '../pdf-preview-modal';
import { useTranslation } from "react-i18next";
import { getCurrentOrderTools } from "Redux/middlwares/order";
import SavedOrderDeleteModal from "./saved-order-delete-modal/index";
import { Spinner } from "react-bootstrap";
import SendConfirm from "Components/supplier-popups/send-confirm";
import RecipientEmail from "Components/supplier-popups/recipient-email";
import NotEasyBarUser from "Components/supplier-popups/not-easybar-user";
import { getCopyOrder } from "Services/order";
import { postDocumentName, uploadDocument, sendEmailNonSupplier } from "Services/order-pdf";
import checkIcon from "../../assets/img/icon/check.svg";
import crossIcon from "../../assets/img/close.svg"
import { patchOrder, getOrders } from "Services/order";
import { OrderPdfType } from "Constants/order";
import toast, { Toaster } from "react-hot-toast";

function MyOrder () {
  document.body.classList.add('my-order-screen');
  const { t } = useTranslation(["common", "emailOrder", "warning"]);

  const dispatch = useAppDispatch();
  const userContacts = useAppSelector(state => state.contact.userContacts);
  const userProjects = useAppSelector(state => state.project.userProjects);
  const isDeleted = useAppSelector(state => state.order.isDeleted);
  const [contactModalShow, setContactModalShow] = useState(false);
  const [activeContact, setActiveContact] = useState({});
  const [editContact, setEditContact] = useState(false);
  const [projectModalShow, setProjectModalShow] = useState(false);
  const [activeProject, setActiveProject] = useState({});
  const [editProject, setEditProject] = useState(false);
  const [orderStats, setOrderStats] = useState({});
  const [pdfPreviewShow, setPdfPreviewShow] = useState(false);
  const [order, setOrder] = useState({});
  const [orderPdfDownload, setOrderPdfDownload] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [showSendConfirm, setShowSendConfirm] = useState(false);
  const [showRecipientEmail, setShowRecipientEmail] = useState(false);
  const [showNotEasyBarUser, setShowNotEasyBarUser] = useState(false);
  const [recipientEmail, setRecipientEmail] = useState("");
  const [orderShareId, setOrderShareId] = useState();
  const [generatePdfFlag, setGeneratePdfFlag] = useState(false);
  const [pdfFile, setPdfFile]= useState("")
  const [nextOrderPage, setNextOrderPage] = useState(0);
  const [userOrders, setUserOrders] = useState([]);

  const currentOrderPdfRebars = useAppSelector(state => state.rebar.currentOrderPdfRebars);
  const currentOrderPdfNets = useAppSelector(state => state.net.currentOrderPdfNets);
  const currentOrderPdfPiles = useAppSelector(state => state.pile.currentOrderPdfPiles);
  const userData = useAppSelector((state) => state.user.userData);
  const reqStatus = useAppSelector((state) => state.order.reqStatus);
  const reqOrder = useAppSelector((state) => state.order.orderId);

  useEffect(() => {
    const initalFetch = async () => {
      await dispatch(fetchContacts());
      await dispatch(fetchProjects());
    }
    initalFetch();
  }, []);

  useEffect(() => {

    const fetchUpdateOrders = async () => {
      if (reqStatus && reqOrder > 0) {
        let orderPromise = patchOrder(reqOrder, { shared_status: reqStatus });
        await dispatch(setOrderShareStatus({ req: null, orderId: 0 }));
        orderPromise
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              if (res.data.shared_status === "ACCEPTED") {
                notifyAccepted();
              } else {
                notifyError("Order has been declined!");
              }
              initalOrdersFetch();
            }
          })
          .catch((err) => {
            if (err.response.status === 400) {
              notifyError(err.response.data.shared_status_error);
            } else if (err.response.status === 403) {
              notifyError(err.response.data.detail);
            } else if (err.response.status === 404){
              notifyError("Order Not Found!");
            } 
            initalOrdersFetch();
          });
      } else {
        initalOrdersFetch();
      }
    }    

    fetchUpdateOrders();
  },[])

  useEffect(() => {
		getWeeklyStats()
			.then((res) => {
				setOrderStats({
					...res.data.data,
				});
			})
			.catch((err) => console.log(err));    
	}, [dispatch]);

  useEffect(()=>{
    window.scrollTo(0,0);
    if(isDeleted){
      toast((t("delete alert", { ns: "warning" })));
      dispatch(deletedOrder(false));
    }
  },[isDeleted,dispatch])

  const initalOrdersFetch = async () => {
    const response = await getOrders();
    let nextPage = null;
    if (response.data.results.length > 0) {
      if (response.data.next !== null) {
        nextPage = response.data.next.split("cursor=");
        setNextOrderPage(nextPage[1]);
      } else if (response.data.next === null) {
        setNextOrderPage(nextPage);
      }
      setUserOrders(response.data.results);
    } else {
      setNextOrderPage(nextPage);
    }
  };

  const notifyAccepted = () =>
    toast.success("Order has been accepted!", {
      duration: 2500,
      position: "top-right",
    });

  const notifyError = (errMsg) =>
    toast.error(`${errMsg}`, {
      duration: 2500,
      position: "top-right",
    });

  const onOpenPreviewHandle = (orderData: any, isPdfDownload:boolean) => {
    dispatch(getCurrentOrderTools(true,orderData)).then((res) => {
      setOrder(res);
      setPdfPreviewShow(true);
      if (isPdfDownload) {
        setOrderPdfDownload(true);
      }
      setIsDataFetched(false);
    }).catch((err)=>{
      setIsDataFetched(false);
    })
    
  }

  const onClosePreview = () => {
    setPdfPreviewShow(false);
    setOrder({});
  }
  const onShareHandler = (orderID: any, mail: any) => {
      getCopyOrder(orderID, mail).then(
        (res)=>{
          toast((t("order has been sent", { ns: "emailOrder" })), {
            icon: <img src={checkIcon} alt="alert icon" />,
          });
        }
      ).catch(
        (err)=>{
          if(err.response && err.response.data.error === "supplier not registered on this email!")
            setShowNotEasyBarUser(true);
      }
      )
  };

  const sharePDFmail = (orderID: any, mail: any) => {
    setGeneratePdfFlag(true);
    setOrderPdfDownload(false);
    onOpenPreviewHandle(selectedOrder.id, false)
  }  

  const handlePDFMailSent = (success: boolean) =>{
    setLoader(false);
    setPdfPreviewShow(false);
    setPdfFile("");
    setGeneratePdfFlag(false);
    
    if (success){
      toast((t("order sent by email", { ns: "emailOrder" })), {
        icon: <img src={checkIcon} alt="alert icon" />,
      });
    }else{
      toast((t("order cant send", { ns: "emailOrder" })), {
        icon: <img src={crossIcon} alt="alert icon" />,
      });
    }   
  }

  useEffect(() => {
    if (pdfFile) {
      const data = {
        document_name: selectedOrder.order_no + ".pdf",
      };
  
      postDocumentName(data)
        .then((res) => {
          const url = res.data.data.presigned_url;
          const doc_url = res.data.data.document_url;
          const data = {
            order: selectedOrder.id,
            user: userData.id,
            document_url: doc_url,
            receipient_email: recipientEmail,
          };
  
          return uploadDocument(url, pdfFile)
          .then((res) => {
            return sendEmailNonSupplier(data);
          });
      })
      .then((res) => {
        handlePDFMailSent(true);
      })
      .catch((err) => {
        handlePDFMailSent(false);
      });

    }
  }, [pdfFile]);
  

  return (
    <>
      <CustomerLayout>
        {isBrowser && (
          <div
            style={
              isDataFetched
                ? {
                    pointerEvents: "none",
                    position: "relative",
                  }
                : { position: "relative" }
            }
          >
            <OrderTable
              onOpenPreviewHandle={onOpenPreviewHandle}
              setSelectedOrder={setSelectedOrder}
              selectedOrder={selectedOrder}
              setDeleteModalShow={setDeleteModalShow}
              setIsDataFetched={setIsDataFetched}
              isDataFetched={isDataFetched}
              setOrderShareId={setOrderShareId}
              setShowSendConfirm={setShowSendConfirm}
              setNextOrderPage={setNextOrderPage}
              nextOrderPage={nextOrderPage}
              setUserOrders={setUserOrders}
              userOrders={userOrders}
            />
            <SiteTable
              openModal={() => {
                setProjectModalShow(true);
                setEditProject(false);
              }}
              setActiveProject={setActiveProject}
              handleEditProject={() => {
                setProjectModalShow(true);
                setEditProject(true);
              }}
            />
            <ContactTable
              openModal={() => {
                setContactModalShow(true);
                setEditContact(false);
              }}
              setActiveContact={setActiveContact}
              handleEditContact={() => {
                setContactModalShow(true);
                setEditContact(true);
              }}
            />
            {isDataFetched && (
              <div className="overloaded-wrapper" style={{ bottom: "50px" }}>
                <div className="overloaded-spinner">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden"></span>
                  </Spinner>
                  <p>Generating PDF</p>
                </div>
              </div>
            )}
          </div>
        )}
        {isMobile && (
          <section className="orderSmScreenBody">
            <div className="container">
              <OrderTable></OrderTable>
              <SiteTable
                openModal={() => {
                  setProjectModalShow(true);
                  setEditProject(false);
                }}
                setActiveProject={setActiveProject}
                handleEditProject={() => {
                  setProjectModalShow(true);
                  setEditProject(true);
                }}
              />
              <ContactTable
                openModal={() => {
                  setContactModalShow(true);
                  setEditContact(false);
                }}
                setActiveContact={setActiveContact}
                handleEditContact={() => {
                  setContactModalShow(true);
                  setEditContact(true);
                }}
              />
            </div>
          </section>
        )}
      </CustomerLayout>
      <CreateNewContact
        show={contactModalShow}
        onHide={() => setContactModalShow(false)}
        edit={editContact}
        activeContact={activeContact}
      />
      <AddNewProject
        show={projectModalShow}
        onHide={() => {
          setProjectModalShow(false);
          setActiveProject({});
        }}
        edit={editProject}
        project={activeProject}
      />
      <PdfPreviewModal
        show={pdfPreviewShow}
        onHide={onClosePreview}
        setDownloadFlag={setOrderPdfDownload}
        downloadFlag={orderPdfDownload}
        printCanvas={false}
        setGeneratePdfFlag={setGeneratePdfFlag}
        generatePdfFlag={generatePdfFlag}
        pdfFile={pdfFile}
        setPdfFile={setPdfFile}
        setLoader={setLoader}
        loader={loader}
        order={order}
        rebars={currentOrderPdfRebars}
        nets={currentOrderPdfNets}
        piles={currentOrderPdfPiles}
        pdfType={OrderPdfType.ORIGINAL_PDF}
        bidFees={""} //admin panel only prop
        pdfLanguage={""} //admin panel only prop
        feeType={""} //admin panel only prop
      />
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            backgroundColor: '#00B38F',
            color: "#fff",
          },
        }}      
      />
      <SavedOrderDeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        orderData={selectedOrder}
        initalOrdersFetch={initalOrdersFetch}
      />
      <SendConfirm
        show={showSendConfirm}
        onHide={() => setShowSendConfirm(false)}
        nextPopup={() => setShowRecipientEmail(true)}
      />
      <RecipientEmail
        show={showRecipientEmail}
        onHide={() => setShowRecipientEmail(false)}
        recipientEmail={recipientEmail}
        setRecipientEmail={setRecipientEmail}
        onShareHandler={onShareHandler}
        orderShareId={orderShareId}
      />
      <NotEasyBarUser
        show={showNotEasyBarUser}
        onHide={() => setShowNotEasyBarUser(false)}
        recipientEmail={recipientEmail}
        orderShareId={orderShareId}
        sharePDFmail={sharePDFmail}
      />
    </>
  );
}

export default MyOrder;
