import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAppSelector } from "Redux/hooks";

const AdminRoute = ({ children, ...rest }: any) => {
  const user = useAppSelector((state) => state.user.userData);
  return (
    <Route
      {...rest}
      render={(props) =>
        user && user.roles === 0 ? <>{children}</> : <Redirect to="/new-order" />
      }
    />
  );
};

export default AdminRoute;
