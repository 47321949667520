import supplier_popup_eng from "../../../assets/img/ticket__create/supplier_popup_eng.svg";
import supplier_popup_heb from "../../../assets/img/ticket__create/supplier_popup_heb.svg";
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import exit from "../../../assets/img/ticket__create/exit.svg";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";

import "./index.scss";

const SupplierPopup = ({ ...props }) => {
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const { t } = useTranslation(['ticketCreator']);
  const {
    show,
    onHide,
    onSubmit
  } = props

  return (
    <>
      <Modal
        animation={false}
        show = {show}
        onHide = {onHide}
        className="spopup_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="spopup_content px-5">
          <div className="row"> 
            <img
              className="sclose_button"
              onClick={onHide}
              src={exit}
              alt=""
            />
          </div>
          <div className="container row justift-content-between align-items-center">
            <div
              className={"col spopup_left " + (rightToLeft ? "onb_pr5" : "onb_pl5")}
            >
              <h1 className={"spopup_heading_" + (rightToLeft ? "heb" : "eng")}>
                {t("welcome", { ns: "ticketCreator" })}
              </h1>
              <p className="spopup_text_eng">
                {t("welcome_text", { ns: "ticketCreator" })}
              </p>
              <button className="spopup_button" onClick={()=> {
                onHide()
                onSubmit()
              }}
            >
                {t("start", { ns: "ticketCreator" })}
              </button>
            </div>
            <div className="col">
              <div className="row justify-content-end">
                <img
                  src={rightToLeft ? supplier_popup_heb : supplier_popup_eng}
                  className="main_image_onboarding_popup"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SupplierPopup;
