export const signupHe = {
  "tagline": "עם איזיבר קל ליצור, לנהל ולעקוב אחרי הזמנות הברזל שלכם",
  "signup": "פתחו חשבון בחינם",
  "login": "יש לי חשבון",
  "already have account": "התחבר לחשבון שלי",
  "login to account": "התחברו לחשבון שלכם",
  "get started": "קצת פרטים ונתחיל",
  "first name": "שם פרטי",
  "last name": "שם משפחה",
  "email": "דואר אלקטרוני (לכתובת זו ישלח מייל אימות)",
  "number": "מספר פלאפון",
  "password": "בחרו סיסמה",
  "password again": "הקלידו שוב את הסיסמה",
  "numbers only": "מספרים בלבד ללא תווים",
  "min char": "מינימום 4 תווים",
  "password match": "רק לודא שהכל תקין", 
  "confirm": "אני מאשר כי קראתי, ואני מבין את",
  "terms of use": "תנאי השימוש",
  "and": "ואת",
  "privacy": "מדיניות הפרטיות",
  "agree": "מערכת, ומסכים להם.",
  "marketing email": "אני מאשר לקבל מידע מקצועי וחומר שיווקי לדואר האלקטרוני שלי.",
  "next": "לפתיחת חשבון בחינם",
  "email verification": "שלחנו לכם קישור לדואר האלקטרוני, לחצו עליו על מנת לסיים את התהליך.",
  "verification email sent": "דוא'ל אימות נשלח!",
  "not recieved": "לא קיבלת קוד",
  "confirm email": "אשר שזה המייל שלך:",
  "true": "מאשר",
  "deny": "לא מאשר",
  "free use": "השימוש בחינם",

  "open from computer": "התחברות דרך המחשב ותתחילו ליצור הזמנות ברזל",
  "under construction": "הגרסה לפלאפון בעבודה",

  "joined": "שמחים שהצטרפתם",
  "mobile instructions": "מהיום תוכלו להנות מיצירת הזמנות ברזל במהירות ובנוחות.כל ההזמנות שלך ישמרו עם שם המשתמש שלך, ויהיו נגישות לך מכל מחשב.",
  "details emailed": "שלחנו לכם דואר אלקטרוני עם כל הפרטים שאתם צריכים.",

  "whatsapp": "בוואטסאפ",
  "not receive email": "לא קיבלתם את המייל?",
  "check spam note": "תבדקו בספאם או בקידומי מכירות, אולי אנחנו שם בטעות.",
  "not get it": "לא קיבל את זה ב",
  "resend email": "שלח אימייל מחדש",
  "still can't find it" : "עדיין לא מוצאים? ",
  "send us a message": "שלחו לנו הודעה",

  "greetings customers": "לקוחות איזיבר שלום,",
  "updated policy": "הוספנו אפשרויות חדשות באתר ועדכנו את תנאי השימוש.",
  "what's new_1": "איזיבר מתחילה לעבוד גם עם ספקי ברזל :)",
  "what's new_2": "איזיבר מאמינה בייעול שרשרת האספקה מקצה לקצה ודואגת להתפתח ולתת מענה לכל הצדדים.",
  "to continue approve": "כדי להמשיך, קראו את",
  "terms": " תנאי השימוש ",
  "accept it": "המעודכנים אשרו בלחיצה על כפתור ״אישור״",
  "read terms of use": "קראתי את תנאי השימוש החדשים",
  "agree btn": "קראתי את תנאי השימוש, ואני מאשר",
}
