import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContactFilterTypes } from "Constants/contacts";
import { useEffect, useState } from "react";
import { isBrowser, isMobile } from "react-device-detect";
import { useAppSelector } from "Redux/hooks";
import ContactTableRow from "./contacttablerow";
import { useTranslation } from "react-i18next";

function ContactTable({ openModal, setActiveContact, handleEditContact }: any) {
  const _userContacts = useAppSelector((state) => state.contact.userContacts);
  const [userContacts, setUserContacts] = useState([] as any);
  const [mobileFilter, setMobileFilter] = useState(ContactFilterTypes.phone);
  const { t } = useTranslation(["common", "myOrder"]);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  useEffect(() => {
    if (_userContacts.length > 0) {
      const lookup = _userContacts.reduce((a, e) => {
        a[e.phone] = ++a[e.phone] || 0;
        return a;
      }, {});
      const userContactsFiltered = [
        ..._userContacts.filter((e) => {
          if (lookup[e.phone] === 0) {
            // contacts repeated once
            return true;
          } else if (lookup[e.phone] > 0) {
            // duplicate contacts added once
            lookup[e.phone] = -1;
            return true;
          } else {
            // remove duplicate contacts
            return false;
          }
        }),
      ];
      setUserContacts(userContactsFiltered);
    }
  }, [_userContacts]);

  return (
    <>
      {isBrowser && (
        <section id="contactTable" className="tableSection mb-4 mb-xl-5">
          <div className="container py-4">
            {/* <!--------table header--------> */}
            <div className="tableHeader d-flex mb-3">
              <h6
                className={
                  "text-uppercase text-dark-500 mb-0 " +
                  (rightToLeft ? "ml-3" : "mr-3")
                }
              >
                {t("contact", { ns: "myOrder" })}
              </h6>
              <div
                className="right d-flex"
                onClick={openModal}
                style={{ cursor: "pointer" }}
              >
                <span
                  className={"icon__wrap " + (rightToLeft ? "ml-2" : "mr-2")}
                >
                  <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
                </span>
                <p className="para text-uppercase">
                  {t("add new contact", { ns: "myOrder" })}
                </p>
              </div>
            </div>
            <div className="row">
              {/* <!-- table calender column  --> */}
              <div className="calender__column col-12 col-xl-6">
                {/* <!-------- table section  --------> */}
                <table className="table table-striped custom-table-alignment">
                  {/* <!-- table head  --> */}
                  <thead>
                    <tr>
                      <td></td>
                      <td>
                        <h6 className="text-uppercase text-dark-500 mb-0">
                          {t("Full name")}
                        </h6>
                      </td>
                      <td>
                        <h6 className="text-uppercase text-dark-500 mb-0">
                          {t("Job")}
                        </h6>
                      </td>
                      <td>
                        <h6 className="text-uppercase text-dark-500 mb-0">
                          {t("Phone")}
                        </h6>
                      </td>
                      <td
                        style={
                          rightToLeft
                            ? { borderRight: "2px solid #f3f3f3" }
                            : {}
                        }
                      ></td>
                    </tr>
                  </thead>
                  {/* <!-- tabody  --> */}
                  <tbody>
                    {/* <!-- table row  --> */}
                    {userContacts.map((item: any, index: number) => {
                      if (index >= 0 && index <= 6) {
                        return (
                          <ContactTableRow
                            key={"contact1" + index}
                            contact={item}
                            number={index + 1}
                            setActiveContact={setActiveContact}
                            handleEditContact={handleEditContact}
                          ></ContactTableRow>
                        );
                      }
                      return <></>;
                    })}
                  </tbody>
                </table>
              </div>
              {userContacts.length > 7 && (
                <div className="calender__column col-12 col-xl-6">
                  {/* <!-------- table section  --------> */}
                  <table className="table table-striped">
                    {/* <!-- table head  --> */}
                    <thead>
                      <tr>
                        <td></td>
                        <td>
                          <h6 className="text-uppercaser text-dark-500 mb-0">
                            {t("full name")}
                          </h6>
                        </td>
                        <td>
                          <h6 className="text-uppercase text-dark-500 mb-0">
                            {t("job")}
                          </h6>
                        </td>
                        <td>
                          <h6 className="text-uppercase text-dark-500 mb-0 ml-3">
                            {t("phone")}
                          </h6>
                        </td>
                        <td></td>
                      </tr>
                    </thead>
                    {/* <!-- tabody  --> */}
                    <tbody>
                      {/* <!-- table row  --> */}
                      {userContacts.map((item: any, index: number) => {
                        if (index >= 7 && index <= 13) {
                          return (
                            <ContactTableRow
                              key={"contact2" + index}
                              contact={item}
                              number={index + 1}
                              setActiveContact={setActiveContact}
                              handleEditContact={handleEditContact}
                            ></ContactTableRow>
                          );
                        }
                        return <></>;
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </section>
      )}
      {isMobile && (
        <div className="myOrderTableSm mt-4 mt-md-5">
          {/* <!-- ----------------table header---------------  --> */}
          <div className="btn__icon__head d-flex justify-content-between border__bottom py-3 tableHeader">
            {/* <!-- icon and heading  --> */}
            <div className="icon__head d-flex align-items-center justify-content-center">
              <h3 className="big__para text-uppercase mb-0">
                {t("contacts", { ns: "myOrder" })}
              </h3>
            </div>
            {/* <!-- buttons  --> */}
            <button className="btn bg-dark-500 text-dark d-flex justify-content-between align-items-center cus__btn">
              <span className="icon__wrap">
                <svg
                  className="svg-inline--fa fa-plus fa-w-14"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="plus"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                  ></path>
                </svg>
              </span>
              <p onClick={openModal} className="ml-2  text-uppercase">
                {t("add new", { ns: "myOrder" })}
              </p>
            </button>
          </div>
          {/* <!-- -----------------table control ------------- --> */}
          <div className="icon__form d-flex justify-content-between align-items-start py-2 py-sm-3 table__control__panel">
            {/* <!-- left section  --> */}
            <div className="left__sec d-flex align-items-center">
              {/* <!-- icon  --> */}
              <div className={"icon__wrap " + (rightToLeft ? "ml-3" : "mr-3")}>
                <FontAwesomeIcon icon="filter"></FontAwesomeIcon>
              </div>
              {/* <!-- para  --> */}
              <p
                onClick={() => setMobileFilter(ContactFilterTypes.phone)}
                className={
                  "para text-capitalize" +
                  (mobileFilter === ContactFilterTypes.phone &&
                    "text-dark-500 active")
                }
              >
                {t("phone number", { ns: "myOrder" })}
              </p>
            </div>
            {/* <!-- right section  --> */}
            <p
              onClick={() => setMobileFilter(ContactFilterTypes.job)}
              className={
                "para text-capitalize " +
                (rightToLeft
                  ? "ml-2 ml-sm-3 ml-md-4"
                  : "mr-2 mr-sm-3 mr-md-4") +
                (mobileFilter === ContactFilterTypes.job &&
                  "text-dark-500 active")
              }
            >
              {t("job title", { ns: "myOrder" })}
            </p>
          </div>
          {/* <!-- ---------------main table ------------ --> */}
          <table className="table">
            <thead>
              <tr>
                <th></th>
                <th>
                  <p className="para text-uppercase text-dark">
                    {t("name", { ns: "myOrder" })}
                  </p>
                </th>
                <th>
                  <p className="para text-uppercase text-dark">
                    {mobileFilter === ContactFilterTypes.phone
                      ? "phone number"
                      : "job title"}
                  </p>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {/* <!-- table row  --> */}
              {userContacts.map((item: any, index: number) => {
                if (index >= 0 && index <= 6) {
                  return (
                    <ContactTableRow
                      key={"contact2" + index}
                      mobileFilter={mobileFilter}
                      contact={item}
                      number={index + 1}
                      setActiveContact={setActiveContact}
                      handleEditContact={handleEditContact}
                    />
                  );
                }
                return <></>;
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

export default ContactTable;
