function handleEnter({ event, currentIndex, activeElement }) {
  if (currentIndex === -1) return;

  activeElement.click();
  event.preventDefault();
}

function numberOfCircles(width) {
  if (width >= 110 && width <= 174) {
    return 2;
  }
  if (width >= 175 && width <= 239) {
    return 3;
  }
  if (width >= 240 && width <= 304) {
    return 4;
  }
  if (width >= 305 && width <= 369) {
    return 5;
  }
  if (width >= 370 && width <= 434) {
    return 6;
  }
}

function handleArrowKey({
  event,
  currentIndex,
  availableElements,
  rightToLeft,
  changeShape,
  setPreviousShape,
}) {
  // If the focus isn't in the container, focus on the first thing
  if (currentIndex === -1) {
    availableElements[0].focus();
    currentIndex = 0;
  }

  let currentIndex2d = [0, 0];
  const parentWidth = availableElements[currentIndex].parentNode.clientWidth;
  const chunkSize = numberOfCircles(parentWidth);
  const parsing = Array.from(availableElements).map((it) => it.id);
  const groups = {};
  parsing.forEach((element, i) => {
    const gr = element.split(".")[1];
    groups[gr] = groups[gr] ? [...groups[gr], i] : [i];
  });
  const rendered = [];
  Object.keys(groups).forEach((key) => {
    for (let i = 0; i < groups[key].length; i += chunkSize) {
      const chunk = groups[key].slice(i, i + chunkSize);
      const ind = chunk.indexOf(currentIndex);
      if (ind > -1 && ind < chunkSize) currentIndex2d = [rendered.length, ind];
      rendered.push(chunk);
    }
  });

  // Move the focus up or down
  let nextElement;
  if (event.key === "ArrowDown") {
    const downInd =
      currentIndex2d[0] === rendered.length - 1
        ? rendered.length - 1
        : currentIndex2d[0] + 1;
    const nextLine = rendered[downInd];
    let nextInd = rendered[downInd][nextLine.length - 1];
    if (currentIndex2d[1] < nextLine.length) {
      nextInd = rendered[downInd][currentIndex2d[1]];
    }
    nextElement = availableElements[nextInd];
  }

  if (event.key === "ArrowUp") {
    const upInd = currentIndex2d[0] === 0 ? 0 : currentIndex2d[0] - 1;
    const nextLine = rendered[upInd];
    let nextInd = rendered[upInd][nextLine.length - 1];
    if (currentIndex2d[1] < nextLine.length) {
      nextInd = rendered[upInd][currentIndex2d[1]];
    }
    nextElement = availableElements[nextInd];
  }

  if (rightToLeft ? event.key === "ArrowLeft" : event.key === "ArrowRight") {
    const rightInd =
      currentIndex2d[1] + 1 < rendered[currentIndex2d[0]].length - 1
        ? currentIndex2d[1] + 1
        : rendered[currentIndex2d[0]].length - 1;
    const nextInd = rendered[currentIndex2d[0]][rightInd];
    nextElement = availableElements[nextInd];
  }

  if (rightToLeft ? event.key === "ArrowRight" : event.key === "ArrowLeft") {
    const leftInd = currentIndex2d[1] - 1 > 0 ? currentIndex2d[1] - 1 : 0;
    const nextInd = rendered[currentIndex2d[0]][leftInd];
    nextElement = availableElements[nextInd];
  }

  if (nextElement && nextElement.id) {
    const gr = nextElement.id.split(".");
    changeShape(gr[1], gr[2]);
    setPreviousShape({gindex:gr[1], index:gr[2] });
  }
  nextElement && nextElement.focus();
  event.preventDefault();
}

export default function handleEvents({
  event,
  parentNode,
  selectors = "a,button,input",
  rightToLeft,
  changeShape,
  setPreviousShape
}) {
  if (!parentNode) return;

  const key = event.key;
  if (
    !["ArrowUp", "ArrowDown", "ArrowRight", "ArrowLeft", "Enter"].includes(key)
  ) {
    return;
  }

  const activeElement = document.activeElement;

  // If we're not inside the container, don't do anything
  if (!parentNode.contains(activeElement)) return;

  // Get the list of elements we're allowed to scroll through
  const availableElements = parentNode.querySelectorAll(selectors);

  // No elements are available to loop through.
  if (!availableElements.length) return;

  // Which index is currently selected
  const currentIndex = Array.from(availableElements).findIndex(
    (availableElement) => availableElement === activeElement
  );

  if (key === "Enter") {
    handleEnter({ event, currentIndex, activeElement });
  }
  handleArrowKey({
    event,
    currentIndex,
    availableElements,
    rightToLeft,
    changeShape,
    setPreviousShape,
  });
}
