export const newPasswordHe = {

    "password reset": "איפוס סיסמא",
    "password create": "יצירת סיסמה חדשה",
    "new pass": "סיסמה חדשה",
    "confirm pass": "אשר סיסמה",
    "password confirmation": "הסיסמה שונתה בהצלחה",
    "your": "שלך",
    "ok": "בסדר",
    "old password": "סיסמה ישנה",
    "old pass required": "נדרשת סיסמה ישנה",
    "New Pass required": "נדרשת סיסמה חדשה",
    "confirm pass required": "נדרש אישור סיסמה",
    "cofirm pass not match": "אשר שהסיסמה אינה תואמת",
    "enter email": "הכנס את האימייל שלך",
    "Enter old password": "הכנס סיסמה ישנה",
    "Enter new password": "הכנס סיסמה חדשה",
    "Required Fields": "שדות חובה",

    "Change Password": "שנה סיסמה",
    "submit": "שלח",

    "forget pass instruction": "הקלד את הדואר האלקטרוני שנרשמת איתו וישלח אליך מייל לאיפוס הסיסמה",
    "Write again for confirmation":"כתוב בשנית לאישור"
}