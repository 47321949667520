import { Button, Modal } from 'react-bootstrap';
import { useAppDispatch } from 'Redux/hooks';
import { removeOrderItem } from 'Redux/middlwares/order';
import close from '../../../assets/img/new__user__form/close.png';
import { fetchLastUsedNets } from "Redux/middlwares/net";
import { useTranslation } from "react-i18next";

function DeleteOrderItemModal (props: any) {
  const {
    onHide,
    seletedOrderItem,
    setSelectedTool,
    edit,
    editId,
  } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common',"newOrder"]);

  const deleteTool = () => {
    if (seletedOrderItem.id) {
      dispatch(removeOrderItem(seletedOrderItem, edit, editId)).then(()=>{
        dispatch(fetchLastUsedNets);
      });
      onHide();
      setSelectedTool({
        id: 0,
      });
    }
  }
  return (
    <>
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-90w"
    >
      <section className="forms buisness">
        <div className="container">
          <div className="row py-5 mx-auto">
            <div className="
                                form__header
                                col-sm-12
                                p-4
                                d-flex
                                justify-content-between
                                pt-3
                            ">
              <h1 className="text-white text-uppercase mb-0">
              {t('Deleting an item', {ns: 'newOrder'})}
              </h1>
              <span className="close__btn" onClick={onHide}>
                <img className="w-100" src={close} alt="close icon" />
              </span>
            </div>
            <div className="col-sm-12 bg-white form__body py-4">
              <div className="form__title pb-md-3 border__bottom pb-2">
                <h3 className="mb-0">{t('Delete the item?', {ns: 'newOrder'})}</h3>
              </div>
              <div className="pt-4">
                <img
                  className="img-fluid"
                  src={seletedOrderItem?.tool?.preview_image?.file_field}
                  alt="map icon"
                />
                <div className="
                                        btn__wrap
                                        py-2
                                        pt-4
                                        d-flex
                                        justify-content-between
                                    ">
                  <Button variant="form" onClick={props.onHide}>{t('cancel', {ns: 'common'})}</Button>
                  <button className="btn form__btn__black"
                  onClick={deleteTool}>
                    {t('Delete', {ns: 'common'})}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Modal>
    </>
  );
}

export default DeleteOrderItemModal;
