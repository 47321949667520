export const newOrderEn = {
  "Order Details": "Order Details",
  "Create Date": "Creation Date",
  "Creator Job": "Creator Job",
  "Business Name": "Business Name",
  "Project Name": "Project name",
  "Project Manager": "Project Manager",
  "Order Notes": "Order Notes",
  "Order Summary": "Order Summary",
  "Rebars": "Rebars",
  "Nets": "Nets",
  "Cages": "Cages",
  "Total": "Total",
  "Typing Note": 'Type your note here',
  "Units": "units",
  "Weight": "Weight",
  "Ton": "Ton",
  "Add New Item": "Add New Item",
  "edit item": "Edit Item",
  "Add New Rebar": "Add New Rebar",
  "add new net": "add new net",
  "add new cage": "add new cage",
  "Enter your prices": "Enter your prices",
  "sketch": "sketch",
  "steel": "steel",
  "Ask for Bid": "Ask for a Bid",

  "add new business": "add new business",
  "business details": "business details",
  "required fields": "required fields",
  "delete this business": "delete this business",
  "name": "name",
  "address": "Delivery Address",
  "city": "City",
  "mail": "mail",
  "phone": "Phone",
  "bn number": "BN Number",
  "cancel": "cancel",
  "save": "save",
  "contact": "contact",

  "business name": "Type business name",
  "business address": "Type business full address",
  "business city": "Type business city",
  "business mail": "Type business mail",
  "phone warning": "number should only contain 9-10 digits",
  "business phone": "Type business relevent phone",

  "add new project": "Add New Project",
  "site": "site",
  "project details": "Project details",
  "delete this project": "delete this project",
  "project name": "project name",
  "delete":  "delete",
  "add another contact":  "Add another contact",
  "location": "Location",
  "location notes": "location notes",
  "notes": "notes",
  "site man": "Site Man",
  "site manager": "Site Manager",

  "add buisness name": "add buisness name",
  "add project name": "add project name",

  'ring': 'ring',
  'gamma': 'gamma',
  'ear': 'ear',


  'preview': 'Preview',
  'download': 'Download',
  'email' : 'Email',

  'type project name': 'Type project name',
  'type an address': 'Type an address',
  'type a general notes': 'Type a general notes',
  'create your user': 'create your user',

  "add project": "add project to continue",
  "add a company": "add a company to continue",
  "add delivery date": "add delivery date to continue",
  "add created date": "add created date to continue",
  "add one tool": "add atleast one tool to continue",

  "Last update": "Last update",
  "Order number": "Order number",
  "Order name": "Order name",
  "Delivery date": "Delivery date required",
  "Company details": "Company details",
  "first site": "add your first site",
  "add company details": "add company details",
  "before typing": "before typing anything",
  "Order creator": "Order creator details",
  "Delivery details": "Delivery details",
  "Full name": "Full name",
  "Job title": "Job title",
  "Street": "Street",
  "contact man": "contact man on site",
  "Driver notes": "notes for the driver",
  "Edit project":"Edit project",
  "the site manager": "I'm the site manager",
  "line1": "Address line 1",
  "line2": "Address line 2",
  "delivery contacts": "Contacts for delivery",
  "the delivery contact": "Im the contact for delivery",
  "Phone num": "Phone number",
  "error marks":"The errors mark in red",
  "Identify project": "this is how you identify the project",
  "Numbers only": "number only",
  "Project city": "In which city is the project located?",
  "Street NandN": "street name and number",
  "Delivery msg": "this is a message for the delivery driver",
  "Apartment num": "Apartment number",
  "the Delivery Address": "Delivery address is the same as project address",

  "Edit company": "Edit Company",
  "Add new company": "Add New Company",
  "Company name": "Company name",
  "Logo": "Logo",
  "addresss": "Address", 
  "position": "Position",
  "Company position": "What is your position in the company?",
  "official name": "official name of the company",
  "official email": "company official email",
  "official phone number": "company official phone number",
  "company located": "In which city is company located?",
  "owner": "Owner",
  "employee": "Employee",
  "service provider": "Servicer Provider",
  "other": "Other",
  "company logo": "click to add company logo",
  "add company logo": "add company logo",

  "Duplicate": "Duplicate",
  "OWNER": "Owner",
  "EMPLOYEE": "Employee",
  "SERVICE_PROVIDER": "Service provider",
  "OTHER": "other",
  "add order name": "Add Order Name",

  "error_rebar_steel": "Please select a steel type",
  "error_rebar_diameter": "Please select a diameter",
  "error_rebar_measure": "Impossible measure",
  "error_rebar_length": "The total length is too long",
  "error_rebar_amount": "Please fill in an amount",
  "error_rebar_radius_min": "Minimum radius should be 1",
  "error_rebar_radius_max": "Radius value greater than max value",

  "previewNetsInfo": "A breakdown of all measurements will appear in the final file. You can click on preview at the bottom of the screen to see the final file.",
  "previewMeasInfo": "Detailed data of the dimensions will appear in the final file. You can click on preview at the bottom of the screen to see the final file.",
  "error_rebar_ear": "The ear should be 10 times larger than the diameter of the rebar",
  
  "edit logo": "Edit Logo",
  "add logo": "Add Logo",

  "Deleting an item" : "DELETE TOOL",
  "Delete the item?" : "Do you went to delete the following tool?",

  "company settings" : "Company settings",
  "user settings":"User settings",

  "Name" : "name",

};
