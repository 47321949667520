import React, { useRef, useEffect, useMemo } from "react";
import rebarDefault from "../../../assets/img/order__page/other.png";

import { useAppSelector } from "Redux/hooks";
import PreviewHeader from "../header";
import { useTranslation } from "react-i18next";
import { commaSeparated } from "Constants/general";

import Gap from  "../../../assets/img/pdf-preview/gap.svg";
import SteelType from  "../../../assets/img/pdf-preview/steel-type.svg";
import Diameter from  "../../../assets/img/pdf-preview/diameter.svg";
import Quantity from  "../../../assets/img/pdf-preview/quantity.svg";
import UnitWeight from  "../../../assets/img/pdf-preview/unit-weight.svg";
import Weight from  "../../../assets/img/pdf-preview/weight.svg";
import UnitPrice from  "../../../assets/img/pdf-preview/unit-price.svg";
import Price from  "../../../assets/img/pdf-preview/price.svg";

const PreviewStandardNets = ({
  pageSize,
  order,
  nets,
  handleRefs,
  totalPages,
  priceShow,
  setStandardNetTran,
  summaryDetails,
  pdfType,
  rebarsTotalPrice,
  standardNetsTotalPrice,
  calculatePrice,
  rightToLeft,
  checkForDecimals,
}: any) => {
  const { t } = useTranslation(["pdfOrder", "common", "measurements"]);
  const steelTypes = useAppSelector((state) => state.steelType.steelTypes);
  const pageRef: any = useRef([]);

  const netUnits = useMemo(
    () =>
      nets.length > 0
        ? nets.reduce((pre: any, curr: any) => pre + curr.quantity, 0)
        : 0,
    [nets]
  );

  const netTons = useMemo(
    () =>
      nets.length > 0
        ? (
            nets.reduce(
              (pre: any, curr: any) =>
                pre + curr.tool.unit_weight * curr.quantity,
              0
            ) / 1000
          ).toFixed(4)
        : "0",
    [nets]
  );

  const standardNetPricing = useMemo(
    () =>
      nets.length > 0
        ? nets
            .reduce(
              (pre: any, curr: any) => pre + curr.unit_price * curr.quantity,
              0
            )
            .toFixed(2)
        : "0",
    [nets]
  );

  useEffect(() => {
    setStandardNetTran({
      units: netUnits,
      weight: netTons,
    });
  }, [netUnits, netTons, setStandardNetTran]);

  useEffect(() => {
    handleRefs(pageRef);
  });

  const printpage = (
    data: any,
    refIndex: any,
    last: boolean,
    lastLast: boolean
  ) => {
    return (
      <>
        <div
          style={{ height: pageSize + "px" }}
          className={lastLast ? "last-page" : ""}
          ref={(el) => {
            pageRef.current[refIndex] = el;
          }}
        >
          <div>
            <PreviewHeader
              order={order}
              showDetails={refIndex === 0}
              pageNo={totalPages.rebars + 1 + refIndex}
              totalPages={totalPages}
              rightToLeft={rightToLeft}
            />
            {data !== -1 && (
              <div className="table-wrapper">
                <div className="table-head">
                  {t("standard nets", { ns: "pdfOrder" })}
                </div>
                <table
                  className={
                    "standard-net" +
                    (lastLast ? " last" : "") +
                    (priceShow ? "" : " without-price")
                  }
                >
                  <thead>
                    <tr className="dark-border-bottom dark-border-top">
                      <th
                        className={
                          (rightToLeft
                            ? "dark-border-left"
                            : "dark-border-right") +
                          " d-flex justify-content-center align-items-center"
                        }
                      >
                        <div className={"sub-head_text "}>
                          {t("row", { ns: "common" })}
                        </div>
                      </th>

                      <th
                        className={
                          (rightToLeft
                            ? "dark-border-left"
                            : "dark-border-right") +
                          " d-flex justify-content-center align-items-center"
                        }
                      >
                        <div className={"sub-head_text"}>
                          {t("name", { ns: "pdfOrder" })}
                        </div>
                      </th>

                      <th
                        className={
                          rightToLeft
                            ? "light-border-left"
                            : "light-border-right"
                        }
                      >
                        <span className="last-element main_XY">X</span>
                        <span className="light-border-bottom text-capitalize sub-head_text">
                          {t("length", { ns: "common" })}
                        </span>
                        <span className="unit">
                          {t("cm", { ns: "measurements" })}
                        </span>
                      </th>
                      <th
                        className={
                          rightToLeft ? "dark-border-left" : "dark-border-right"
                        }
                      >
                        <span className="last-element main_XY">Y</span>
                        <span className="light-border-bottom text-capitalize sub-head_text">
                          {t("length", { ns: "common" })}
                        </span>
                        <span className="unit">
                          {t("cm", { ns: "measurements" })}
                        </span>
                      </th>
                      <th
                        className={
                          rightToLeft
                            ? "light-border-left"
                            : "light-border-right"
                        }
                      >
                        <span className="last-element">
                          <img
                            style={{
                              width: "23px",
                              height: "23px",
                            }}
                            src={Diameter}
                            alt="diameter logo"
                          />
                        </span>
                        <span className="light-border-bottom text-capitalize sub-head_text">
                          {t("diameter", { ns: "common" })}
                        </span>
                        <span className="unit">
                          {t("mm", { ns: "measurements" })}
                        </span>
                      </th>
                      <th
                        className={
                          rightToLeft
                            ? "light-border-left"
                            : "light-border-right"
                        }
                      >
                        <span className="last-element">
                          <img
                            style={{
                              width: "28px",
                              height: "28px",
                            }}
                            src={Gap}
                            alt="length logo"
                          />
                        </span>
                        <span className="light-border-bottom sub-head_text">
                          {t("gap", { ns: "pdfOrder" })}
                        </span>
                        <span className="unit">
                          {t("cm", { ns: "measurements" })}
                        </span>
                      </th>
                      <th
                        className={
                          rightToLeft ? "dark-border-left" : "dark-border-right"
                        }
                      >
                        <span className="last-element">
                          <img
                            style={{
                              width: "24px",
                              height: "24px",
                            }}
                            src={SteelType}
                            alt="steel type logo"
                          />
                        </span>
                        <span className="light-border-bottom text-capitalize sub-head_text">
                          {t("steel type", { ns: "pdfOrder" })}
                        </span>
                        <span className="unit">
                          {t("cm", { ns: "measurements" })}
                        </span>
                      </th>
                      <th
                        className={
                          rightToLeft ? "dark-border-left" : "dark-border-right"
                        }
                      >
                        <span className="last-element">
                          <img
                            style={{
                              width: "24px",
                              height: "24px",
                            }}
                            src={Quantity}
                            alt="quantity logo"
                          />
                        </span>
                        <span className="light-border-bottom sub-head_text">
                          {t("quantity", { ns: "common" })}
                        </span>
                        <span className="unit">
                          {t("units", { ns: "measurements" })}
                        </span>
                      </th>
                      <th
                        className={
                          priceShow
                            ? rightToLeft
                              ? "dark-border-left"
                              : "dark-border-right"
                            : ""
                        }
                      >
                        <span
                          className="text-capitalize sub-head_text"
                          style={{ paddingBottom: "4px" }}
                        >
                          {pdfType === 1
                            ? t("Price", { ns: "common" })
                            : t("weight", { ns: "common" })}
                        </span>
                        <div className="two-columns light-border-top">
                          <div
                            className={
                              "single-column " +
                              (rightToLeft
                                ? "light-border-left"
                                : "light-border-right")
                            }
                          >
                            <span className="light-border-bottom text-capitalize sub-head_text">
                              {t("unit", { ns: "common" })}
                            </span>
                            <span className="unit">
                              {pdfType === 1
                                ? t("ILS", { ns: "measurements" })
                                : t("kg", { ns: "measurements" })}
                            </span>
                          </div>
                          <div className="single-column">
                            <span className="light-border-bottom sub-head_text">
                              {t("total", { ns: "common" })}
                            </span>
                            <span className="unit">
                              {pdfType === 1
                                ? t("ILS", { ns: "measurements" })
                                : t("kg", { ns: "measurements" })}
                            </span>
                          </div>
                        </div>
                      </th>
                      {priceShow && (
                        <th>
                          <span>Price</span>
                          <div className="two-columns light-border-top">
                            <div className="single-column light-border-right">
                              <span>
                                <img
                                  src={UnitPrice}
                                  alt="unit price logo"
                                />
                              </span>
                              <span className="light-border-bottom">Total</span>
                              <span className="unit">NIS</span>
                            </div>
                            <div className="single-column">
                              <span>
                                <img
                                  src={Price}
                                  alt="price logo"
                                />
                              </span>
                              <span className="light-border-bottom">Total</span>
                              <span className="unit">NIS</span>
                            </div>
                          </div>
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((itemNet: any, index: number) => (
                      <tr className="dark-border-bottom net-tr">
                        <td
                          className={
                            (rightToLeft
                              ? "dark-border-left"
                              : "dark-border-right") + " height-inherit"
                          }
                        >
                          {itemNet.row_number}
                        </td>
                        <td
                          className={
                            (rightToLeft
                              ? "dark-border-left"
                              : "dark-border-right") + " height-inherit"
                          }
                        >
                          <span
                            style={
                              itemNet.tool.name
                                ? itemNet.tool.name.includes(" ")
                                  ? {}
                                  : {
                                      wordBreak: "break-all",
                                      lineHeight: "1.2",
                                    }
                                : {}
                            }
                          >
                            {itemNet.tool.name || ""}
                          </span>
                        </td>
                        <td
                          className={
                            (rightToLeft
                              ? "light-border-left"
                              : "light-border-right") + " height-inherit"
                          }
                          style={
                            itemNet.production_notes
                              ? { paddingBottom: "20px" }
                              : {}
                          }
                        >
                          <strong>{itemNet.tool.x_length}</strong>
                        </td>
                        <td
                          className={
                            (rightToLeft
                              ? "dark-border-left"
                              : "dark-border-right") + " height-inherit"
                          }
                          style={
                            itemNet.production_notes
                              ? { paddingBottom: "20px" }
                              : {}
                          }
                        >
                          <strong>{itemNet.tool.y_length}</strong>
                        </td>
                        <td
                          className={
                            (rightToLeft
                              ? "light-border-left"
                              : "light-border-right") + " height-inherit"
                          }
                          style={
                            itemNet.production_notes
                              ? { paddingBottom: "20px" }
                              : {}
                          }
                        >
                          <span className="d-flex justify-content-center align-items-center">
                            <img
                              style={{
                                width: "22px",
                                height: "22px",
                              }}
                              src={Diameter}
                              alt="diameter logo"
                              className={rightToLeft ? "ml-2" : "mr-2"}
                            />
                            {itemNet.tool.x_diameter.steel_diameter}
                          </span>
                        </td>
                        <td
                          className={
                            (rightToLeft
                              ? "light-border-left"
                              : "light-border-right") + " height-inherit"
                          }
                          style={
                            itemNet.production_notes
                              ? { paddingBottom: "20px" }
                              : {}
                          }
                        >
                          <span className="d-flex justify-content-center align-items-center">
                            <img
                              style={{
                                width: "27px",
                                height: "27px",
                              }}
                              src={Gap}
                              alt="Gap logo"
                              className={rightToLeft ? "ml-2" : "mr-2"}
                            />
                            {itemNet.tool.x_gap}
                          </span>
                        </td>
                        <td
                          className={
                            (rightToLeft
                              ? "dark-border-left"
                              : "dark-border-right") + " height-inherit"
                          }
                          style={
                            itemNet.production_notes
                              ? { paddingBottom: "20px" }
                              : {}
                          }
                        >
                          {steelTypes.length > 0 &&
                            steelTypes.find(
                              (item) => item.id === itemNet.tool.steel_type
                            ) &&
                            steelTypes.find(
                              (item) => item.id === itemNet.tool.steel_type
                            ).name}
                        </td>
                        <td
                          className={
                            (rightToLeft
                              ? "dark-border-left"
                              : "dark-border-right") + " height-inherit"
                          }
                        >
                          {itemNet.quantity}
                        </td>
                        <td
                          className={
                            (rightToLeft
                              ? "light-border-left"
                              : "light-border-right") + " height-inherit"
                          }
                        >
                          {pdfType === 1
                            ? checkForDecimals(
                                calculatePrice(
                                  itemNet.unit_price,
                                  itemNet.quantity
                                )
                              )
                                .toString()
                                .replace(commaSeparated, ",")
                            : itemNet.tool.unit_weight}
                        </td>
                        <td
                          className={
                            "height-inherit " +
                            (priceShow
                              ? rightToLeft
                                ? "dark-border-left"
                                : "dark-border-right"
                              : "")
                          }
                        >
                          {pdfType === 1
                            ? checkForDecimals(
                                calculatePrice(
                                  itemNet.unit_price,
                                  itemNet.unit_price
                                ) * itemNet.quantity
                              )
                                .toString()
                                .replace(commaSeparated, ",")
                            : (
                                itemNet.tool.unit_weight * itemNet.quantity
                              ).toFixed(2)}
                        </td>
                        {priceShow && (
                          <>
                            <td className="light-border-right  height-inherit">
                              2.3
                            </td>
                            <td>1,115</td>
                          </>
                        )}
                        {itemNet.production_notes && (
                          <td
                            className="dark-border-top dark-border-bottom"
                            style={{
                              position: "absolute",
                              marginTop: "50px",
                              alignItems: "normal",
                              justifyContent: "flex-start",
                              height: "30px",
                              width: priceShow ? "73%" : "50.2%",
                              ...(rightToLeft
                                ? {
                                    right: priceShow ? "250px" : "193px",
                                    textAlign: "right",
                                  }
                                : { left: priceShow ? "250px" : "193px" }),
                            }}
                          >
                            <p
                              style={{
                                fontSize: "16px",
                                lineHeight: "30px",
                                ...(rightToLeft
                                  ? { marginRight: "4px" }
                                  : { marginLeft: "4px" }),
                              }}
                            >
                              {t("note")}: {itemNet.production_notes}
                            </p>
                          </td>
                        )}
                      </tr>
                    ))}
                    {lastLast && (
                      <>
                        <tr style={{ border: "none" }}>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>{netUnits}</td>
                          <td>
                            {pdfType === 1
                              ? checkForDecimals(
                                  calculatePrice(
                                    standardNetPricing,
                                    -1, //bcz here we have total price of all items combined
                                    true,
                                    false,
                                    true
                                  )
                                )
                                  .toString()
                                  .replace(commaSeparated, ",")
                              : parseFloat(netTons) === 0.0
                              ? parseFloat(netTons).toFixed(3)
                              : parseFloat(netTons).toFixed(2)}
                          </td>
                          {priceShow && <td>34</td>}
                        </tr>
                        <tr style={{ border: "none" }}>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>{t("quantity", { ns: "common" })}</td>
                          <td className="d-flex align-items-center text-capitalize">
                            <span className={rightToLeft ? "ml-1" : "mr-1"}>
                              {pdfType === 1
                                ? t("Price", { ns: "common" })
                                : t("weight", { ns: "common" })}
                            </span>
                            <span style={{ fontSize: "12px" }}>
                              (
                              {pdfType === 1
                                ? t("ILS", { ns: "measurements" })
                                : t("ton", { ns: "measurements" })}
                              )
                            </span>
                          </td>
                          {priceShow && <td>Price (NIS)</td>}
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            {last &&
              summaryDetails.special_nets === 0 &&
              summaryDetails.piles === 0 && (
                <div className="table-wrapper">
                  <div className="table-head">
                    {t("order Summary", { ns: "pdfOrder" })}
                  </div>
                  <table
                    className={
                      "order-summary" +
                      (priceShow ? "" : " without-price") +
                      (rightToLeft ? " rightToLeft" : "")
                    }
                  >
                    <tbody>
                      {summaryDetails.rebars > 0 && (
                        <tr>
                          <td>{t("Rebars", { ns: "common" })}</td>
                          <td>{summaryDetails.rebars}</td>
                          <td>
                            {pdfType === 1
                              ? checkForDecimals(parseFloat(rebarsTotalPrice))
                                  .toString()
                                  .replace(commaSeparated, ",")
                              : parseFloat(summaryDetails.rebars_weight) === 0.0
                              ? parseFloat(
                                  summaryDetails.rebars_weight
                                ).toFixed(3)
                              : parseFloat(
                                  summaryDetails.rebars_weight
                                ).toFixed(2)}
                          </td>
                          {priceShow && <td>54</td>}
                        </tr>
                      )}
                      <tr>
                        <td>{t("standard nets", { ns: "pdfOrder" })}</td>
                        <td>{summaryDetails.standard_nets}</td>
                        <td>
                          {pdfType === 1
                            ? checkForDecimals(
                                parseFloat(standardNetsTotalPrice)
                              )
                                .toString()
                                .replace(commaSeparated, ",")
                            : parseFloat(
                                summaryDetails.standard_nets_weight
                              ) === 0.0
                            ? parseFloat(
                                summaryDetails.standard_nets_weight
                              ).toFixed(3)
                            : parseFloat(
                                summaryDetails.standard_nets_weight
                              ).toFixed(2)}
                        </td>
                        {priceShow && <td>54</td>}
                      </tr>

                      <tr>
                        <td>{t("total", { ns: "common" })}</td>
                        <td>
                          {summaryDetails.rebars + summaryDetails.standard_nets}
                        </td>

                        <td>
                          {pdfType === 1
                            ? checkForDecimals(
                                parseFloat(standardNetsTotalPrice) +
                                  parseFloat(rebarsTotalPrice)
                              )
                                .toString()
                                .replace(commaSeparated, ",")
                            : (
                                parseFloat(summaryDetails.rebars_weight) +
                                parseFloat(summaryDetails.standard_nets_weight)
                              ).toFixed(2)}
                        </td>
                        {priceShow && <td>99</td>}
                      </tr>
                      <tr>
                        <td></td>
                        <td>{t("units", { ns: "measurements" })}</td>
                        <td className="d-flex align-items-center text-capitalize">
                          <span className={rightToLeft ? "ml-1" : "mr-1"}>
                            {pdfType === 1
                              ? t("Price", { ns: "common" })
                              : t("weight", { ns: "common" })}
                          </span>
                          <span style={{ fontSize: "12px" }}>
                            (
                            {pdfType === 1
                              ? t("ILS", { ns: "measurements" })
                              : t("ton", { ns: "measurements" })}
                            )
                          </span>
                        </td>
                        {priceShow && (
                          <td>{t("Price", { ns: "common" })} (NIS)</td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
          </div>
        </div>
        <div
          style={{
            height: "20px",
            background: "#000",
            opacity: "0.5",
          }}
        ></div>
      </>
    );
  };

  if (nets && nets.length) {
    let SLICE = 10,
      FIRST_SLICE = 10,
      LAST_SLICE = 10;
    let nextPageFlag = false;
    if (
      summaryDetails.special_nets === 0 &&
      nets.length > 7 &&
      nets.length <= 11
    ) {
      nextPageFlag = true;
    }

    const dataSlice: any = [];
    let initialSlice = 0,
      firstSlice = true;
    while (nets.length > initialSlice) {
      const remaining = nets.length - initialSlice;
      if (summaryDetails.special_nets === 0) {
        if (remaining > 10 && !firstSlice) {
          nextPageFlag = true;
        } else if (!firstSlice) {
          nextPageFlag = false;
        }
      }
      if (remaining >= 12 && firstSlice) {
        FIRST_SLICE = 11;
        LAST_SLICE = 11;
      }
      if (remaining > 14 && !firstSlice) {
        LAST_SLICE = 14;
        SLICE = 14;
      } else if (!firstSlice) {
        LAST_SLICE = 13;
        SLICE = 13;
      }

      if (
        nets.length === initialSlice + SLICE ||
        nets.length === initialSlice + SLICE - 1 ||
        nets.length === initialSlice + SLICE - 2
      ) {
        dataSlice.push(nets.slice(initialSlice, initialSlice + LAST_SLICE));
        initialSlice = initialSlice + LAST_SLICE;
        if (nets.length > initialSlice)
          dataSlice.push(nets.slice(initialSlice, initialSlice + LAST_SLICE));
      } else {
        dataSlice.push(
          nets.slice(
            initialSlice,
            initialSlice + (firstSlice ? FIRST_SLICE : SLICE)
          )
        );
      }
      initialSlice = initialSlice + (firstSlice ? FIRST_SLICE : SLICE);
      if (firstSlice) firstSlice = false;
    }
    if (nextPageFlag) {
      dataSlice.push(-1);
    }
    return (
      <>
        {dataSlice.map((data: any, index: any) =>
          printpage(
            data,
            index,
            index === dataSlice.length - 1,
            dataSlice[index + 1] === -1 || index === dataSlice.length - 1
          )
        )}
      </>
    );
  }
  return <></>;
};

export default PreviewStandardNets;
