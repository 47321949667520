import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import Sidebar from "../components/common-components/sidebar";
import '../styles/Dashboard.css';

const AdminLayout = ({
  children
}: any) => {

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} id="sidebar-wrapper-admin">      
            <Sidebar />
          </Col>
          <Col className="p-4"  xs={10} id="page-content-wrapper">
            {children}
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default AdminLayout;