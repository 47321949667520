import {useState} from 'react';
import { Modal } from 'react-bootstrap';
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import cross from "../../../../assets/img/ticket__create/cross.svg"
import forward from "../../../../assets/img/ticket__create/forward.svg"

import "../index.scss"

function TrialFinished({ ...props }) {
	const [supplierName, setSupplierName] = useState("")
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const { t } = useTranslation(['ticketCreator']);

  const {
    show,
    onHide
  } = props

  return (
    <>
      <Modal
        show = {show}
        onHide = {onHide}
        className="package_expire_popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <>
          <div className="container popup_container">

            <div className="small_popup_content">
            <p className={"pt-5 pb-2 " + ( (rightToLeft ? "trial_expire_heading" : "trial_expire_heading_heebo")) }>
              {t("trial_finished", { ns: "ticketCreator" })}
              </p>
              
              <a href='https://api.whatsapp.com/message/E7GHBYJXF4DBN1?autoload=1&app_absent=0'> 
                <button className="popup_button_md my-4">
                  {t("connect", { ns: "ticketCreator" })}
                </button>
              </a>

                {rightToLeft ? 
                <a href='https://docs.google.com/forms/d/e/1FAIpQLSdiykSS8csHXz8axZnN3p1fp3Un7sVpKvQDpoumedsnz5ndlQ/viewform?pli=1'>
                  <p className="popup_details pb-5">
                  {t("short_survey", { ns: "ticketCreator" })}
                  </p>
                </a> : <p className='pb-5'></p> 
                }
                
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}

export default TrialFinished;
