import React, { forwardRef, useEffect, useMemo, useState, useRef } from "react";
import "./index.styles.scss";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Spinner } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { fetchSteelTypes } from "Redux/middlwares/steelType";
import checkIcon from "../../assets/img/icon/check.svg";
import { useTranslation } from "react-i18next";
import PreviewRebarTable from "./rebars";
import PreviewStandardNets from "./nets/StandardNet";
import PreviewSpecialNets from "./nets/SpecialNet";
import PreviewPileTable from "./pile";
import { timeout_secs } from "Constants/alert-modal";
import { commaSeparated } from "Constants/general";
import toast from 'react-hot-toast';

const PdfPreview = forwardRef(
  (
    {
      setDownloadFlag,
      downloadFlag,
      setGeneratePdfFlag,
      generatePdfFlag,
      setPdfFile,
      pdfFile,
      printCanvas,
      setPrintCanvas,
      setLoader,
      loader,
      setOrderPdfDownload,
      orderPdfDownload,
      onHide,
      order,
      rebars,
      nets,
      piles,
      priceShow,
      setPdfType,
      pdfType,
      bidFees,
      pdfLanguage,
      feeType,
    }: any,
    ref
  ) => {
    const pageSize = 1400;
    const { i18n, t } = useTranslation(["common", "warning"]);
    const dispatch = useAppDispatch();
    const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
    const steelTypes = useAppSelector((state) => state.steelType.steelTypes);

    const stylingRef = useRef();

    const [rebarsRefs, setRebarsRef] = useState();
    const [standardNetRefs, setStandardNetsRefs] = useState();
    const [specialNetsRef, setSpecialNetsRef] = useState();
    const [pilesRef, setPilesRef] = useState();
    const [standardNetTran, setStandardNetTran] = useState({
      units: 0,
      weight: 0,
    });

    const [rebarsTotalPrice, setRebarsTotalPrice] = useState(0);
    const [standardNetsTotalPrice, setStandardNetsTotalPrice] = useState(0);
    const [specialNetsTotalPrice, setSpecialNetsTotalPrice] = useState(0);
    const [customRightToLeft, setCustomRightToLeft] = useState(rightToLeft);

    const totalPages = useMemo(() => {
      let total = {};
      if (rebarsRefs && (rebarsRefs as any).current)
        total = {
          rebars: (rebarsRefs as any).current.length,
        };

      if (standardNetRefs && (standardNetRefs as any).current)
        total = {
          ...total,
          standardNets: (standardNetRefs as any).current.length,
        };

      if (specialNetsRef && (specialNetsRef as any).current)
        total = {
          ...total,
          specialNets: (specialNetsRef as any).current.length,
        };

      if (pilesRef && (pilesRef as any).current)
        total = {
          ...total,
          piles: (pilesRef as any).current.length,
        };

      return total;
    }, [rebarsRefs, standardNetRefs, specialNetsRef, pilesRef]);

    //For Admin Panel Pricing Calculator Pdfs
    useEffect(() => {
      if (pdfLanguage === "he"){
        i18n.changeLanguage("he");
        stylingRef.current.setAttribute("dir", "rtl");
        stylingRef.current.setAttribute("style", "text-align: right");
        setCustomRightToLeft(true);
      } else if(pdfLanguage === "en"){
        i18n.changeLanguage("en");
        setCustomRightToLeft(false);
      }
    }, [pdfLanguage]);

    useEffect(() => {
      steelTypes.length === 0 && dispatch(fetchSteelTypes());
    }, [steelTypes, dispatch]);

    useEffect(() => {
      if (
        (rebarsRefs &&
          (rebarsRefs as any).current &&
          (rebarsRefs as any).current.length > 0) ||
        (standardNetRefs &&
          (standardNetRefs as any).current &&
          (standardNetRefs as any).current.length > 0) ||
        (specialNetsRef &&
          (specialNetsRef as any).current &&
          (specialNetsRef as any).current.length > 0) ||
        (pilesRef &&
          (pilesRef as any).current &&
          (pilesRef as any).current.length > 0)
      ) {
        if (downloadFlag) {
          setLoader(true);
          print(true);
        } else if (generatePdfFlag) {
          setLoader(true);
          setTimeout(() => {
            print(false);
          }, 100);
        } else if (printCanvas) {
          setLoader(true);
          setTimeout(() => {
            openPrintCanvas();
          }, 100);
        } else if (orderPdfDownload) {
          setLoader(true);
          print(true);
        }
      }
    }, [rebarsRefs, standardNetRefs, specialNetsRef, pilesRef, generatePdfFlag, downloadFlag, printCanvas]);

    // helper to convert html to canvas
    const getHtmlToCanvas = (ref: any) => {
      return new Promise((resolve, reject) => {
        html2canvas(ref, { allowTaint: true, useCORS: true, logging: true })
          .then((canvas) => {
            resolve(canvas);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    };

    function openPrintCanvas() {
      let Canvases = generateCanvases();
      let windowContent: any = "";
      let dataUrl;
      Promise.all(Canvases)
        .then((values) => {
          values.forEach((canvas: any) => {
            dataUrl = canvas.toDataURL();
            windowContent += "<div style={break-after:page}>";
            windowContent += `<img src=${dataUrl} style="width: 100%; height: auto"/>`;
            windowContent += "</div>";
          });
          setLoader(false);
          onHide();
          let printWin = window.open(
            "https://easybar.co.il/new-order",
            "Easybar.co.il",
            "top=40,left=40,height=700,width=1000,focused=false,resizable=no|0,scrollbars=no,location=no|0"
          );
          // @ts-ignore: Object is possibly 'null'.
          printWin.document.open();

          if (printWin) {
            // @ts-ignore: Object is possibly 'null'.
            printWin.document.write(`
          <!DOCTYPE html>
          <html>
            <head>
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title></title>
            </head>
            <body style="width:100%;margin:0 auto;">
            ${windowContent}
            <script>
              function triggerPrint(event) {
                window.removeEventListener('load', triggerPrint, false);
                setTimeout(function() {
                closeWindow(window.print());
                }, 100);
              }
              function closeWindow(){
                window.close();
              }
              window.addEventListener('load', triggerPrint, false);
            </script>
            </body>
          </html>
          `);
            // @ts-ignore: Object is possibly 'null'.
            printWin.document.close();
          }
          setPrintCanvas();
        })
        .catch((err) => console.log(err));
    }

    function generateCanvases() {
      const canvases = [];
      if (rebarsRefs) {
        // @ts-ignore: Object is possibly
        for (let i = 0; i < rebarsRefs.current.length; i++) {
          // @ts-ignore: Object is possibly
          const canvas = getHtmlToCanvas(rebarsRefs.current[i]);
          canvases.push(canvas);
        }
      }

      if (standardNetRefs) {
        // @ts-ignore: Object is possibly
        for (let i = 0; i < standardNetRefs.current.length; i++) {
          // @ts-ignore: Object is possibly
          const canvas = getHtmlToCanvas(standardNetRefs.current[i]);
          canvases.push(canvas);
        }
      }
      if (specialNetsRef) {
        // @ts-ignore: Object is possibly
        for (let i = 0; i < specialNetsRef.current.length; i++) {
          // @ts-ignore: Object is possibly
          const canvas = getHtmlToCanvas(specialNetsRef.current[i]);
          canvases.push(canvas);
        }
      }
      if (pilesRef) {
        // @ts-ignore: Object is possibly
        for (let i = 0; i < pilesRef.current.length; i++) {
          // @ts-ignore: Object is possibly
          const canvas = getHtmlToCanvas(pilesRef.current[i]);
          canvases.push(canvas);
        }
      }
      return canvases;
    }

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    // useImperativeHandle(ref, () => ({
    function print(checkIfDownload: boolean) {
      document.body.classList.add("not-click");
      setLoader(true);

      let canvases = [] as any;
      canvases = generateCanvases();

      Promise.all(canvases)
        .then((values) => {
          const pdf = new jsPDF("p", "pt", "a4", true);
          values.forEach((canvas: any, index: any) => {
            const imgData = canvas.toDataURL("image/png");
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, "PNG", 0, 1, pdfWidth, pdfHeight, "", "FAST");
            if (index < values.length - 1) pdf.addPage();
          });
          if (checkIfDownload) {
            pdf.save(`easybar_${order.order_no}.pdf`);
          }

          if (checkIfDownload) {
            setLoader(false);
            onHide();
            if (downloadFlag) {
              setDownloadFlag();
            } else if (orderPdfDownload) {
              setOrderPdfDownload();
            }
            toast((t("download alert", { ns: "warning" })), {
              icon: <img src={checkIcon} alt="alert icon" />,
            });    
          } else {
            const blob = pdf.output("blob");
            setPdfFile(blob);
            //onHide();
            setGeneratePdfFlag();
          }
          document.body.classList.remove("not-click");
        })
        .catch((err) => console.log(err));
        document.body.classList.remove("not-click");
    }

    // handlers
    const handleRebarsRefs = (refs: any) => {
      setRebarsRef(refs);
    };
    const handleStandardNetRefs = (refs: any) => {
      setStandardNetsRefs(refs);
    };
    const handleSpecialdNetRefs = (refs: any) => {
      setSpecialNetsRef(refs);
    };
    const hanldePileRefs = (refs: any) => {
      setPilesRef(refs);
    };

    // Filters
    const standardNets =
      nets && nets.length
        ? nets.filter((item: any) => item.tool.standard_net)
        : [];
    const SpecialNets =
      nets && nets.length
        ? nets.filter((item: any) => !item.tool.standard_net)
        : [];

    const summaryDetails = {
      rebars: rebars.reduce((sum: any, curr: any) => sum + curr.quantity, 0),
      rebars_weight:
        rebars.reduce(
          (sum: any, i: any) => sum + i.tool.unit_weight * i.quantity,
          0.0
        ) / 1000,
      standard_nets: standardNets.reduce(
        (sum: any, curr: any) => sum + curr.quantity,
        0
      ),
      standard_nets_weight:
        standardNets.reduce(
          (sum: any, i: any) => sum + i.tool.unit_weight * i.quantity,
          0.0
        ) / 1000,
      special_nets: SpecialNets.reduce(
        (sum: any, curr: any) => sum + curr.quantity,
        0
      ),
      special_nets_weight:
        SpecialNets.reduce(
          (sum: any, i: any) => sum + i.tool.unit_weight * i.quantity,
          0.0
        ) / 1000,
      piles: piles.reduce((sum: any, curr: any) => sum + curr.quantity, 0),
      piles_weight:
        piles.reduce(
          (sum: any, i: any) => sum + i.tool.unit_weight * i.quantity,
          0.0
        ) / 1000,
    };

    const rebarPricing = useMemo(
      () =>
        rebars.length > 0
          ? rebars
              .reduce(
                (pre: any, curr: any) =>
                  pre + curr.unit_price * curr.quantity,
                0
              )
              .toFixed(2)
          : "0",
      [rebars]
    );

    const calculatePrice = (
      price: number,
      quantity: number,
      isTotalPrice = false,
      isRebars = false,
      isStanNet = false,
      isSpecNet = false
    ) => {
      let Price = Number(price);

      const stdFee = ((Price * Number(bidFees.stdVal)) / 100);
      let easyBarFee;
      //check for the EasyBar FeeType
      if (feeType === 0) {
        easyBarFee = (
          ((Number(Price) + Number(stdFee)) * Number(bidFees.easyBarFee)) /
          100
        );
        } else {
        //To Check if we are calculating the easyBar fee of 1 unit or not
        if(quantity === -1){
          easyBarFee = Number(bidFees.easyBarFee);
        } else{
          easyBarFee = Number(bidFees.easyBarFee) / Number(quantity);
        }
      }

      const payFee = (
        ((Number(stdFee) + Number(easyBarFee) + Number(Price)) *
          Number(bidFees.paymentFee)) /
        100
      );

      let totalPrice =
        (Number(stdFee) + Number(easyBarFee) + Number(payFee) + Number(Price));
      //Here storing the values in state for the order summary
      if (isTotalPrice) {
        if (isRebars) {
          setRebarsTotalPrice(totalPrice);
        } else if (isStanNet) {
          setStandardNetsTotalPrice(totalPrice);
        } else {
          setSpecialNetsTotalPrice(totalPrice);
        }
      }

      return Number(totalPrice);
    };

    const checkForDecimals = (price: number) => {
      if (price <= 0.5) {
        return Number(price).toFixed(2);
      } else {
        return Number(price).toFixed(1);
      }
    };


    return (
      <>
        {/* <button onClick={print} disabled={loader} className="downloadBtn">
        <DownloadIcon />
        {t('download')}
      </button> */}
        <div
          id="PDF-Preview-main"
          style={
            loader
              ? {
                  pointerEvents: "none",
                }
              : {}
          }
          ref={stylingRef}
        >
          <PreviewRebarTable
            pageSize={pageSize}
            loader={loader}
            order={order}
            rebars={rebars}
            standardNets={standardNets}
            specialNets={SpecialNets}
            handleRefs={handleRebarsRefs}
            totalPages={totalPages}
            priceShow={priceShow}
            summaryDetails={summaryDetails}
            pdfType={pdfType}
            rebarPricing={rebarPricing}
            bidFees={bidFees}
            rebarsTotalPrice={rebarsTotalPrice}
            calculatePrice={calculatePrice}
            rightToLeft={customRightToLeft}
            checkForDecimals={checkForDecimals}
          />
          <PreviewStandardNets
            pageSize={pageSize}
            order={order}
            nets={standardNets}
            handleRefs={handleStandardNetRefs}
            totalPages={totalPages}
            priceShow={priceShow}
            setStandardNetTran={setStandardNetTran}
            summaryDetails={summaryDetails}
            pdfType={pdfType}
            bidFees={bidFees}
            rebarsTotalPrice={rebarsTotalPrice}
            standardNetsTotalPrice={standardNetsTotalPrice}
            calculatePrice={calculatePrice}
            rightToLeft={customRightToLeft}
            checkForDecimals={checkForDecimals}
          />
          <PreviewSpecialNets
            pageSize={pageSize}
            order={order}
            nets={SpecialNets}
            standardNets={standardNets}
            handleRefs={handleSpecialdNetRefs}
            totalPages={totalPages}
            priceShow={priceShow}
            standardNetTran={standardNetTran}
            summaryDetails={summaryDetails}
            pdfType={pdfType}
            rebarPricing={rebarPricing}
            bidFees={bidFees}
            rebarsTotalPrice={rebarsTotalPrice}
            standardNetsTotalPrice={standardNetsTotalPrice}
            specialNetsTotalPrice={specialNetsTotalPrice}
            calculatePrice={calculatePrice}
            rightToLeft={customRightToLeft}
            checkForDecimals={checkForDecimals}
          />
          <PreviewPileTable
            pageSize={pageSize}
            order={order}
            piles={piles}
            handleRefs={hanldePileRefs}
            totalPages={totalPages}
            priceShow={priceShow}
            summaryDetails={summaryDetails}
            pdfType={pdfType}
            rebarsTotalPrice={rebarsTotalPrice}
            standardNetsTotalPrice={standardNetsTotalPrice}
            specialNetsTotalPrice={specialNetsTotalPrice}
            rightToLeft={customRightToLeft}
            checkForDecimals={checkForDecimals}
          />
        </div>
        {loader && (
          <div className="overloaded-wrapper">
            <div className="overloaded-spinner">
              <Spinner animation="border" role="status">
                <span className="visually-hidden"></span>
              </Spinner>
              <p>Generating PDF</p>
            </div>
          </div>
        )}
      </>
    );
  }
);

export default PdfPreview;
