import email from "Assets/img/icon/email.png";
import { useState, useEffect, useRef, useMemo } from "react";
import classNames from "classnames";
import emtpySet from "Assets/img/icon/empty-set-mathematical-symbol.png";
import { DebounceInput } from "react-debounce-input";
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import { calculate_partitions } from "Components/tool/utils";
import add from "../../../../assets/img/add_note.svg";
import remove from "../../../../assets/img/remove_note.svg";
import error_sign from "../../../../assets/img/error_sign.svg";
import warning_sign from "../../../../assets/img/warning_sign.svg";
import horizontal from "../../../../assets/img/horizontal.svg";
import vertical from "../../../../assets/img/vertical.svg";
import sides_bg from "../../../../assets/img/net_sides_bg.svg";

import {
  gapOptions,
  netXLengthMax,
  netXLengthMin,
  netYLengthMax,
  netYLengthMin,
  netXYLengthMax,
  minAmount,
  netErrorTypes,
  netSidebarInput,
  specialNetSidebarErrors,
  netsSidesErrors,
  sidesErrorsX,
  sidesErrorsY,
} from "Constants/net-constants";
import "./index.scss";
import order from "Redux/features/order";
import { commaSeparated } from "Constants/general";

function SideBar({
  customizeView,
  toggleUniformGap,
  toggleUniformDiameter,
  selectedShape,
  setSelectedShape,
  filterDiameter,
  setFilterDiameter,
  filterGap,
  setFilterGap,
  edit,
  partitionX,
  partitionY,
  setPartitionX,
  setPartitionY,
  shapeObj,
  setShape,
  getDiameter,
  lastUsedShapes,
  shapes,
  stanGaps,
  setStanGaps,
  stanDiams,
  setStanDiams,
  errorsText,
  addError,
  removeError,
  orderItem,
  setOrderItem,
  onX1Change,
  onX2Change,
  onX3Change,
  onY1Change,
  onY2Change,
  onY3Change,
  saveNet,
  checkLengths,
  finishButtonRef,
  addButtonRef,
  setIsShapesError,
  isShapesError,
  showStandard,
  setShowStandard,
  edgeChanged_X,
  setEdgeChanged_X,
  edgeChanged_Y,
  setEdgeChanged_Y,
  checkGapConditions,
}: any) {
  const { t } = useTranslation(["common", "tool"]);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const steelDiameters = useAppSelector(
    (state) => state.steelType.steelDiameters
  );
  const steelTypes = useAppSelector((state) => state.steelType.steelTypes);
  const netsCount = useAppSelector((state) => state.net.currentOrderNetsCount);
  const standardShapes = useAppSelector((state) => state.net.defaultNets);

  const [showNote, setShowNote] = useState(false);
  const siderBarRefs = useRef<any[]>([]);
  const [heightUpdated, setHeightUpdated] = useState(false);
  const [widthUpdated, setWidthUpdated] = useState(false);

  const editModeClass = useMemo(() => {
    if (edit) {
      return "_updated";
    } else return "";
  }, [edit]);

  const handleNetTypeToggle = (standard) => {
    siderBarRefs.current[netSidebarInput.NET_NAME].focus();
    setShowStandard(standard);
    if (!standard) {
      customizeView?.uniformDiameter && toggleUniformDiameter();
      customizeView?.uniformGap && toggleUniformGap();
    } else {
      !customizeView?.uniformDiameter && toggleUniformDiameter();
      !customizeView?.uniformGap && toggleUniformGap();

      setShape({
        ...shapeObj,
        y_gap: shapeObj.x_gap,
        y_diameter: shapeObj.x_diameter,
      });

      const widthPartitions = calculate_partitions(
        shapeObj.x_gap,
        shapeObj.x_length,
        shapeObj.x_diameter?.steel_diameter
      );
      setPartitionX(widthPartitions);

      const heigthPartitions = calculate_partitions(
        shapeObj.y_gap,
        shapeObj.y_length,
        shapeObj.y_diameter?.steel_diameter
      );
      setPartitionY(heigthPartitions);

      specialNetSidebarErrors.forEach((errorType) => {
        removeError(errorType);
      });
    }
  };

  const standardExist = standardShapes.find((nItem) => {
    const stanWidthPartitions = calculate_partitions(
      nItem.x_gap,
      nItem.x_length,
      nItem.x_diameter.steel_diameter
    );
    const stanHeigthPartitions = calculate_partitions(
      nItem.y_gap,
      nItem.y_length,
      nItem.y_diameter.steel_diameter
    );
    return (
      nItem.x_length === shapeObj.x_length &&
      nItem.y_length === shapeObj.y_length &&
      nItem.x_diameter.steel_diameter === shapeObj.x_diameter.steel_diameter &&
      nItem.y_diameter.steel_diameter === shapeObj.y_diameter.steel_diameter &&
      nItem.x_gap === shapeObj.x_gap &&
      nItem.y_gap === shapeObj.y_gap &&
      stanWidthPartitions._1 === partitionX._1 &&
      stanWidthPartitions._2 === partitionX._2 &&
      stanWidthPartitions._3 === partitionX._3 &&
      stanHeigthPartitions._1 === partitionY._1 &&
      stanHeigthPartitions._2 === partitionY._2 &&
      stanHeigthPartitions._3 === partitionY._3
    );
  });

  const checkDiameters = (diameter_x: any, diameter_y: any) => {
    if (
      shapeObj?.x_diameter?.steel_diameter !==
      shapeObj?.y_diameter?.steel_diameter
    ) {
      if (
        diameter_x.steel_diameter < diameter_y.steel_diameter * 0.6 ||
        diameter_y.steel_diameter < diameter_x.steel_diameter * 0.6
      ) {
        addError({
          message: t("error_net_diameter", { ns: "tool" }),
          errorType: netErrorTypes.DIAMETER_RATIO,
        });
      } else {
        removeError(netErrorTypes.DIAMETER_RATIO);
      }
    }
  };

  useEffect(() => {
    const calculateWeight = () => {
      if (shapeObj.x_length && shapeObj.y_length) {
        const nx = Math.round(partitionX._1 / shapeObj.x_gap + 1);
        const lx = nx * shapeObj.y_length;
        const qx = (lx / 100) * parseFloat(shapeObj.x_diameter.weight);

        const ny = Math.round(partitionY._1 / shapeObj.y_gap + 1);
        const ly = ny * shapeObj.x_length;
        const qy = (ly / 100) * parseFloat(shapeObj.y_diameter.weight);
        const unitWeight = (qx + qy).toFixed(2);

        setShape((prev: any) => ({ ...prev, unit_weight: unitWeight }));
      }
    };
    if (shapeObj.x_diameter && shapeObj.y_diameter) {
      calculateWeight();
    }
  }, [
    shapeObj.x_diameter,
    shapeObj.y_diameter,
    shapeObj.x_gap,
    shapeObj.y_gap,
    shapeObj.y_length,
    shapeObj.x_length,
    partitionX._1,
    partitionY._1,
  ]);

  useEffect(() => {
    standardExist ? setShowStandard(true) : setShowStandard(false);
  }, [shapeObj.id]);

  useEffect(() => {
    siderBarRefs.current[netSidebarInput.NET_NAME].focus();
  }, []);

  useEffect(() => {
    checkLengths();
  }, [shapeObj]);

  const handleFocus = () => {
    if (isShapesError) {
      addButtonRef.current.classList.add("add_btn_error");
      addButtonRef.current.style.border = "2px solid #CD2017";
    } else {
      addButtonRef.current.classList.remove("add_btn_error");
      addButtonRef.current.style.border = "2px solid #00B38F";
    }
  };

  return (
    <>
      {
        <div className="left" style={{ overflowY: "scroll", height: "710px" }}>
          <div className="sidebar_body">
            <div className="sidebar_container net_type">
              <h1 className="net_type_heading">
                {t("net type", { ns: "tool" })}
              </h1>
              <div className="net_type_select">
                <h5
                  className={
                    "text-lowercase " +
                    (rightToLeft
                      ? "net_type_toggle_left"
                      : "net_type_toggle_right")
                  }
                  style={{
                    backgroundColor: showStandard ? "#5F6674" : "#D7D9DC",
                    fontSize: rightToLeft ? "13.5px" : "10.5px",
                  }}
                  onClick={() => {
                    handleNetTypeToggle(true);
                  }}
                >
                  {t("symmetric", { ns: "common" })}
                </h5>

                <h5
                  className={
                    "text-lowercase " +
                    (rightToLeft
                      ? "net_type_toggle_right"
                      : "net_type_toggle_left")
                  }
                  style={{
                    backgroundColor: showStandard ? "#D7D9DC" : "#5F6674",
                    fontSize: rightToLeft ? "13.5px" : "10.5px",

                  }}
                  onClick={() => {
                    handleNetTypeToggle(false);
                  }}
                >
                  {t("asymmetric", { ns: "common" })}
                </h5>
              </div>
            </div>

            {showStandard ? (
              <>
                {/* Net Name */}
                <div
                  className="sidebar_container"
                  style={{ borderBottom: "1px solid #D9D9D9" }}
                >
                  <div className="net_name">
                    <h1 className="nets_sidebar_label mb-0">
                      {t("net name", { ns: "tool" })}
                    </h1>
                  </div>

                  <input
                    type="text"
                    className={
                      "para text-center text-ash input-inside nets_sidebar_input_updated"
                    }
                    style={{
                      width: "145px",
                    }}
                    value={shapeObj.name || ""}
                    onFocus={(e: any) => e.target.select()}
                    onChange={(e) =>
                      setShape({ ...shapeObj, name: e.target.value })
                    }
                    maxLength={20}
                    ref={(el) => {
                      if (el)
                        siderBarRefs.current[netSidebarInput.NET_NAME] = el;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowDown" || e.key === "Enter") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.X_WIDTH].focus();
                      }
                    }}
                  />
                </div>

                {/* Total Lengths */}
                <div className="sidebar_container">
                  <div className="total_lengths">
                    <h1 className="nets_sidebar_label_large mb-0">
                      {t("total lengths", { ns: "tool" })}
                      <span
                        className={
                          "nets_units " + (rightToLeft ? "pr-1" : "pl-1")
                        }
                        style={{ paddingLeft: "3px" }}
                      >
                        {t("cm", { ns: "tool" })}
                      </span>
                    </h1>
                  </div>
                </div>

                {/* X- Width */}
                <div className="sidebar_container">
                  <div className="total_lengths">
                    <h1 className="nets_sidebar_label mb-0">
                      {t("xwidth", { ns: "tool" })}
                      <span
                        className={
                          "nets_units " + (rightToLeft ? "pr-1" : "pl-1")
                        }
                        style={{ paddingLeft: "3px" }}
                      >
                        {t("cm", { ns: "tool" })}
                      </span>
                    </h1>
                  </div>
                  <input
                    type="number"
                    className={
                      "para text-center text-ash input-inside nets_sidebar_input" + editModeClass + 
                      (shapeObj.x_length < netXLengthMin ||
                      shapeObj.x_length >= netXLengthMax ||
                      (shapeObj.x_length > netXYLengthMax &&
                        shapeObj.y_length > netXYLengthMax)
                        ? "_error"
                        : "")
                    }
                    value={shapeObj.x_length || ""}
                    // debounceTimeout={700}
                    onFocus={(e: any) => e.target.select()}
                    onChange={(e) => {
                      const x_val = Math.abs(parseInt(e.target.value));
                      removeError(netErrorTypes.MIN);
                      removeError(netErrorTypes.MAX);
                      removeError(netErrorTypes.MAXMIN);

                      setShape({
                        ...shapeObj,
                        x_length: x_val,
                      });
                      const widthPartitions = calculate_partitions(
                        shapeObj.x_gap,
                        x_val,
                        shapeObj.x_diameter.steel_diameter
                      );
                      setPartitionX(widthPartitions);
                      sidesErrorsX.forEach((error)=>{
                        removeError(error);
                      })
                    }}
                    onBlur={(e) => {
                      checkLengths();
                      siderBarRefs.current[
                        netSidebarInput.X_WIDTH
                      ].classList.remove("nets_sidebar_input");
                      siderBarRefs.current[
                        netSidebarInput.X_WIDTH
                      ].classList.add("nets_sidebar_input_updated");
                    }}
                    ref={(el) => {
                      if (el)
                        siderBarRefs.current[netSidebarInput.X_WIDTH] = el;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.NET_NAME].focus();
                      } else if (e.key === "ArrowDown" || e.key === "Enter") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.Y_HEIGHT].focus();
                      }
                    }}
                  />
                </div>

                {/* Y- Height */}
                <div
                  className="sidebar_container"
                  style={{ borderBottom: "1px solid #D9D9D9" }}
                >
                  <div className="total_lengths">
                    <h1 className="nets_sidebar_label mb-0">
                      {t("ywidth", { ns: "tool" })}
                      <span
                        className={
                          "nets_units " + (rightToLeft ? "pr-1" : "pl-1")
                        }
                        style={{ paddingLeft: "3px" }}
                      >
                        {t("cm", { ns: "tool" })}
                      </span>
                    </h1>
                  </div>
                  <input
                    type="number"
                    className={
                      "para text-center text-ash input-inside nets_sidebar_input" + editModeClass +
                      (shapeObj.y_length < netYLengthMin ||
                      shapeObj.y_length >= netYLengthMax ||
                      (shapeObj.x_length > netXYLengthMax &&
                        shapeObj.y_length > netXYLengthMax)
                        ? "_error"
                        : "")
                    }
                    value={shapeObj.y_length || ""}
                    // debounceTimeout={700}
                    onFocus={(e: any) => {
                      e.target.select();
                    }}
                    onChange={(e) => {
                      const y_val = Math.abs(parseInt(e.target.value));

                      removeError(netErrorTypes.MIN);
                      removeError(netErrorTypes.MAX);
                      removeError(netErrorTypes.MAXMIN);
                      setShape({
                        ...shapeObj,
                        y_length: y_val,
                      });
                      const heightPartitions = calculate_partitions(
                        shapeObj.y_gap,
                        y_val,
                        shapeObj.y_diameter.steel_diameter
                      );
                      setPartitionY(heightPartitions);
                      sidesErrorsY.forEach((error)=>{
                        removeError(error);
                      })
                    }}
                    onBlur={(e) => {
                      checkLengths();
                      siderBarRefs.current[
                        netSidebarInput.Y_HEIGHT
                      ].classList.remove("nets_sidebar_input");
                      siderBarRefs.current[
                        netSidebarInput.Y_HEIGHT
                      ].classList.add("nets_sidebar_input_updated");
                    }}
                    ref={(el) => {
                      if (el)
                        siderBarRefs.current[netSidebarInput.Y_HEIGHT] = el;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.X_WIDTH].focus();
                      } else if (e.key === "Enter") {
                        siderBarRefs.current[netSidebarInput.DIAMETER].focus();
                      } else if (e.key === "ArrowDown") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.DIAMETER].focus();
                      }
                    }}
                  />
                </div>

                {/* Details */}
                <div className="sidebar_container">
                  <div className="total_lengths">
                    <h1 className="nets_sidebar_label_large mb-0">
                      {t("details", { ns: "common" })}
                      <span
                        className={
                          "nets_units " + (rightToLeft ? "pr-1" : "pl-1")
                        }
                        style={{ paddingLeft: "3px" }}
                      >
                        {t("cm", { ns: "tool" })}
                      </span>
                    </h1>
                  </div>
                </div>

                {/* Diameter */}
                <div className="sidebar_container">
                  <div className="total_lengths">
                    <h1 className="nets_sidebar_label mb-0">
                      {t("diameter", { ns: "tool" })}
                      <span
                        className={
                          "nets_units " + (rightToLeft ? "pr-1" : "pl-1")
                        }
                        style={{ paddingLeft: "3px" }}
                      >
                        {t("mm", { ns: "tool" })}
                      </span>
                    </h1>
                  </div>
                  <select
                    className={
                      "form-control form-control-sm nets_sidebar_input" + editModeClass +
                      (errorsText.some(
                        (error) =>
                          error.errorType === netErrorTypes.DIAMETER_RATIO
                      )
                        ? "_warning"
                        : "")
                    }
                    value={shapeObj.x_diameter ? shapeObj.x_diameter.id : ""}
                    onChange={(e) => {
                      const _diameter = getDiameter(parseInt(e.target.value));
                      if (_diameter) {
                        setShape({
                          ...shapeObj,
                          x_diameter: _diameter,
                          y_diameter: _diameter,
                        });
                      }
                    }}
                    ref={(el) => {
                      if (el)
                        siderBarRefs.current[netSidebarInput.DIAMETER] = el;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.Y_HEIGHT].focus();
                      } else if (e.key === "Enter") {
                        siderBarRefs.current[netSidebarInput.GAP].focus();
                      } else if (e.key === "ArrowDown") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.GAP].focus();
                      }
                    }}
                    onBlur={() => {
                      siderBarRefs.current[
                        netSidebarInput.DIAMETER
                      ].classList.remove("nets_sidebar_input");
                      siderBarRefs.current[
                        netSidebarInput.DIAMETER
                      ].classList.add("nets_sidebar_input_updated");
                    }}
                  >
                    {shapeObj.steel_type in steelDiameters &&
                      steelDiameters[shapeObj.steel_type].map(
                        (reDi: any, index: number) =>
                          reDi?.steel_diameter <= 16 && (
                            <option
                              className="shp_option"
                              key={"net-uni-diameter-options2-" + index}
                              value={reDi.id}
                            >
                              {parseFloat(reDi?.steel_diameter)}
                            </option>
                          )
                      )}
                  </select>
                </div>

                {/* Gap */}
                <div className="sidebar_container">
                  <div className="total_lengths">
                    <h1 className="nets_sidebar_label mb-0">
                      {t("gap", { ns: "common" })}
                      <span
                        className={
                          "nets_units " + (rightToLeft ? "pr-1" : "pl-1")
                        }
                        style={{ paddingLeft: "3px" }}
                      >
                        {t("cm", { ns: "tool" })}
                      </span>
                    </h1>
                  </div>
                  <select
                    className={"form-control form-control-sm nets_sidebar_input" +  editModeClass}
                    value={shapeObj.x_gap || ""}
                    onChange={(e) => {
                      setShape({
                        ...shapeObj,
                        x_gap: parseInt(e.target.value),
                        y_gap: parseInt(e.target.value),
                      });
                      const widthPartitions = calculate_partitions(
                        parseInt(e.target.value),
                        shapeObj.x_length,
                        shapeObj.x_diameter.steel_diameter
                      );
                      setPartitionX(widthPartitions);
                      const heightPartitions = calculate_partitions(
                        parseInt(e.target.value),
                        shapeObj.y_length,
                        shapeObj.x_diameter.steel_diameter
                      );
                      setPartitionY(heightPartitions);
                      sidesErrorsX.forEach((error)=>{
                        removeError(error);
                      })
                    }}
                    ref={(el) => {
                      if (el) siderBarRefs.current[netSidebarInput.GAP] = el;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.DIAMETER].focus();
                      } else if (e.key === "Enter") {
                        e.preventDefault();
                        if (showNote) {
                          siderBarRefs.current[netSidebarInput.NOTE].focus();
                        } else {
                          siderBarRefs.current[
                            netSidebarInput.QUANTITY
                          ].focus();
                        }
                      } else if (e.key === "ArrowDown") {
                        e.preventDefault();
                        siderBarRefs.current[
                          netSidebarInput.STEEL_TYPE
                        ].focus();
                      }
                    }}
                    onBlur={() => {
                      siderBarRefs.current[
                        netSidebarInput.GAP
                      ].classList.remove("nets_sidebar_input");
                      siderBarRefs.current[netSidebarInput.GAP].classList.add(
                        "nets_sidebar_input_updated"
                      );
                    }}
                  >
                    {gapOptions.map((item, index) => (
                      <option key={"gap-options-1" + index}>{item}</option>
                    ))}
                  </select>
                </div>

                {/* Steel Type */}
                <div
                  className="sidebar_container"
                  style={{ borderBottom: "1px solid #D9D9D9" }}
                >
                  <div className="total_lengths">
                    <h1
                      className="nets_sidebar_label mb-0"
                      style={{
                        color: "#878C97",
                      }}
                    >
                      {t("steel type", { ns: "common" })}
                    </h1>
                  </div>
                  <select
                    className="form-control form-control-sm nets_sidebar_input_grey"
                    style={{
                      backgroundColor: "#F5F5F5",
                    }}
                    value={shapeObj.steel_type || ""}
                    onChange={({ target }) => {
                      const stType = parseInt(target.value);
                      if (stType in steelDiameters) {
                        setShape((prev: any) => ({
                          ...prev,
                          steel_type: parseInt(target.value),
                          x_diameter: steelDiameters[stType]?.find(
                            (item: any) => item.steel_diameter === shapeObj.x_diameter.steel_diameter
                          ),
                          y_diameter: steelDiameters[stType]?.find(
                            (item: any) => item.steel_diameter === shapeObj.y_diameter.steel_diameter
                          ),                        
                        }));
                      } else {
                        setShape((prev: any) => ({
                          ...prev,
                          steel_type: stType,
                          x_diameter: steelDiameters[stType]?.find(
                            (item: any) => item.steel_diameter === shapeObj.x_diameter.steel_diameter
                          ),
                          y_diameter: steelDiameters[stType]?.find(
                            (item: any) => item.steel_diameter === shapeObj.y_diameter.steel_diameter
                          ),                        
                        }));
                      }
                    }}
                    ref={(el) => {
                      if (el)
                        siderBarRefs.current[netSidebarInput.STEEL_TYPE] = el;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.GAP].focus();
                      } else if (e.key === "ArrowDown" || e.key === "Enter") {
                        e.preventDefault();
                        if (showNote) {
                          siderBarRefs.current[netSidebarInput.NOTE].focus();
                        } else {
                          siderBarRefs.current[
                            netSidebarInput.QUANTITY
                          ].focus();
                        }
                      }
                    }}
                    onBlur={() => {
                      siderBarRefs.current[
                        netSidebarInput.STEEL_TYPE
                      ].classList.remove("nets_sidebar_input");
                      siderBarRefs.current[
                        netSidebarInput.STEEL_TYPE
                      ].classList.add("nets_sidebar_input_updated");
                    }}
                  >
                    {steelTypes.map((st: { id: number; name: string }) => (
                      <option
                        key={"rebar-steeltype-options-" + st.id}
                        value={st.id}
                      >
                        {st.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Note */}
                <div
                  className="sidebar_container"
                  style={{
                    height: "25px",
                    borderBottom: !showNote ? "1px solid #D9D9D9" : "",
                  }}
                >
                  <div className="nets_note">
                    <h1
                      className="nets_sidebar_label mb-0"
                      style={{
                        fontSize: "10.5px",
                        lineHeight: "15px",
                        color: "#878C97",
                      }}
                    >
                      {t("note", { ns: "common" })}
                    </h1>
                    <div
                      className="note_img"
                      onClick={() => {
                        setShowNote(!showNote);
                      }}
                    >
                      {showNote ? (
                        <img src={remove} className="nets_note_img" alt="+" />
                      ) : (
                        <img src={add} className="nets_note_img" alt="+" />
                      )}
                    </div>
                  </div>
                </div>

                <div className="sidebar_container">
                  {showNote && (
                    <input
                      type="text"
                      className={"form-control nets_sidebar_input" +  editModeClass}
                      style={{ width: "240px", height: "30px" }}
                      value={orderItem.production_notes || ""}
                      onChange={(e) =>
                        setOrderItem({
                          ...orderItem,
                          production_notes: e.target.value,
                        })
                      }
                      maxLength={50}
                      ref={(el) => {
                        if (el) siderBarRefs.current[netSidebarInput.NOTE] = el;
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "ArrowUp") {
                          e.preventDefault();
                          siderBarRefs.current[
                            netSidebarInput.STEEL_TYPE
                          ].focus();
                        } else if (e.key === "ArrowDown" || e.key === "Enter") {
                          e.preventDefault();
                          siderBarRefs.current[
                            netSidebarInput.QUANTITY
                          ].focus();
                        }
                      }}
                      onBlur={() => {
                        siderBarRefs.current[
                          netSidebarInput.NOTE
                        ].classList.remove("nets_sidebar_input");
                        siderBarRefs.current[
                          netSidebarInput.NOTE
                        ].classList.add("nets_sidebar_input_updated");
                      }}
                    />
                  )}
                </div>
              </>
            ) : (
              <>
                {/* Net Name */}
                <div
                  className="sidebar_container"
                  style={{ borderBottom: "1px solid #D9D9D9" }}
                >
                  <div className="net_name">
                    <h1 className="nets_sidebar_label mb-0">
                      {t("net name", { ns: "tool" })}
                    </h1>
                  </div>

                  <input
                    type="text"
                    className={
                      "para text-center text-ash input-inside nets_sidebar_input_updated"
                    }
                    style={{
                      width: "145px",
                    }}
                    value={shapeObj.name || ""}
                    onFocus={(e: any) => e.target.select()}
                    onChange={(e) =>
                      setShape({ ...shapeObj, name: e.target.value })
                    }
                    maxLength={20}
                    ref={(el) => {
                      if (el)
                        siderBarRefs.current[netSidebarInput.NET_NAME] = el;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowDown" || e.key === "Enter") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.X_WIDTH].focus();
                      }
                    }}
                  />
                </div>

                {/* Total Lengths */}
                <div className="sidebar_container">
                  <div className="total_lengths">
                    <h1 className="nets_sidebar_label_large mb-0">
                      {t("total lengths", { ns: "tool" })}
                      <span
                        className={
                          "nets_units " + (rightToLeft ? "pr-1" : "pl-1")
                        }
                        style={{ paddingLeft: "3px" }}
                      >
                        {t("cm", { ns: "tool" })}
                      </span>
                    </h1>
                  </div>
                </div>

                {/* X- Width */}
                <div className="sidebar_container">
                  <div className="total_lengths">
                    <h1 className="nets_sidebar_label mb-0">
                      {t("xwidth", { ns: "tool" })}
                      <span
                        className={
                          "nets_units " + (rightToLeft ? "pr-1" : "pl-1")
                        }
                        style={{ paddingLeft: "3px" }}
                      >
                        {t("cm", { ns: "tool" })}
                      </span>
                    </h1>
                  </div>
                  <DebounceInput
                    type="number"
                    className={
                      "para text-center text-ash input-inside nets_sidebar_input" +  editModeClass +
                      (shapeObj.x_length < netXLengthMin ||
                      shapeObj.x_length >= netXLengthMax ||
                      (shapeObj.x_length > netXYLengthMax &&
                        shapeObj.y_length > netXYLengthMax)
                        ? "_error"
                        : "")
                    }
                    value={shapeObj.x_length || ""}
                    debounceTimeout={700}
                    onFocus={(e: any) => e.target.select()}
                    onChange={(e) => {
                      const x_val = Math.abs(parseInt(e.target.value) || 0);
                      removeError(netErrorTypes.MIN);
                      removeError(netErrorTypes.MAX);
                      removeError(netErrorTypes.MAXMIN);

                      setShape({
                        ...shapeObj,
                        x_length: x_val,
                      });
                      let partX1;
                      if(!edgeChanged_X 
                        || (partitionX._2 + partitionX._3 >= x_val)
                        ){
                          const widthPartitions = calculate_partitions(
                            shapeObj.x_gap,
                            x_val,
                            shapeObj.x_diameter.steel_diameter
                          );
                          setPartitionX(widthPartitions);
                          sidesErrorsX.forEach((error)=>{
                            removeError(error);
                          })
                          setEdgeChanged_X(false);
                          partX1 = widthPartitions._1;
                      }else{
                        setPartitionX({
                          ...partitionX,
                          _1: x_val- (partitionX._2 + partitionX._3),
                        })
                        partX1 = x_val - (partitionX._2 + partitionX._3);
                        if (
                          partX1 % shapeObj.x_gap !== 0 &&
                          partitionY._1 % shapeObj.y_gap !== 0
                        ) {
                          addError({
                            errorType: netErrorTypes.GAP_X1Y1_VALIDATION,
                            message: t("error_net_gap_xy", { ns: "tool" }),
                          });
                        } else if (partX1 % shapeObj.x_gap !== 0) {
                          addError({
                            errorType: netErrorTypes.GAP_X1_VALIDATION,
                            message: t("error_net_gap_xy", { ns: "tool" }),
                          });
                        } else {
                          removeError(netErrorTypes.GAP_X1Y1_VALIDATION);
                          removeError(netErrorTypes.GAP_X1_VALIDATION);
                        }
                      }
                    }}
                    onBlur={(e) => {
                      checkLengths();
                      setWidthUpdated(true);
                    }}
                    inputRef={(el) => {
                      if (el)
                        siderBarRefs.current[netSidebarInput.X_WIDTH] = el;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.NET_NAME].focus();
                      } else if (e.key === "ArrowDown" || e.key === "Enter") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.Y_HEIGHT].focus();
                      }
                    }}
                  />
                </div>

                {/* Y- Height */}
                <div
                  className="sidebar_container"
                  style={{ borderBottom: "1px solid #D9D9D9" }}
                >
                  <div className="total_lengths">
                    <h1 className="nets_sidebar_label mb-0">
                      {t("ywidth", { ns: "tool" })}
                      <span
                        className={
                          "nets_units " + (rightToLeft ? "pr-1" : "pl-1")
                        }
                        style={{ paddingLeft: "3px" }}
                      >
                        {t("cm", { ns: "tool" })}
                      </span>
                    </h1>
                  </div>
                  <DebounceInput
                    type="number"
                    className={
                      "para text-center text-ash input-inside nets_sidebar_input" +  editModeClass +
                      (shapeObj.y_length < netYLengthMin ||
                      shapeObj.y_length >= netYLengthMax ||
                      (shapeObj.x_length > netXYLengthMax &&
                        shapeObj.y_length > netXYLengthMax)
                        ? "_error"
                        : "")
                    }
                    value={shapeObj.y_length || ""}
                    debounceTimeout={700}
                    onFocus={(e: any) => e.target.select()}
                    onChange={(e) => {
                      const y_val = Math.abs(parseInt(e.target.value) || 0);
                      removeError(netErrorTypes.MIN);
                      removeError(netErrorTypes.MAX);
                      removeError(netErrorTypes.MAXMIN);
                      setShape({
                        ...shapeObj,
                        y_length: y_val,
                      });
                      let partY1;
                      if (
                        !edgeChanged_Y ||
                        partitionY._2 + partitionY._3 >=
                        y_val
                      ) {
                        const heightPartitions = calculate_partitions(
                          shapeObj.y_gap,
                          y_val,
                          shapeObj.y_diameter.steel_diameter
                        );
                        setEdgeChanged_Y(false);
                        setPartitionY(heightPartitions);
                        sidesErrorsY.forEach((error)=>{
                          removeError(error);
                        })                        
                        partY1 = heightPartitions._1;
                      } else {
                        setPartitionY({
                          ...partitionY,
                          _1:
                          y_val -
                            (partitionY._2 + partitionY._3),
                        });
                        partY1 =
                          y_val -
                          (partitionY._2 + partitionY._3);
                          if (
                            partitionX._1 % shapeObj.x_gap !== 0 &&
                            partY1 % shapeObj.y_gap !== 0
                          ) {
                            addError({
                              errorType: netErrorTypes.GAP_X1Y1_VALIDATION,
                              message: t("error_net_gap_xy", { ns: "tool" }),
                            });
                          } else if (partY1 % shapeObj.y_gap !== 0) {
                            addError({
                              errorType: netErrorTypes.GAP_Y1_VALIDATION,
                              message: t("error_net_gap_xy", { ns: "tool" }),
                            });
                          } else {
                            removeError(netErrorTypes.GAP_X1Y1_VALIDATION);
                            removeError(netErrorTypes.GAP_Y1_VALIDATION);
                          }
                      }
                    }}
                    onBlur={(e) => {
                      checkLengths();
                      setHeightUpdated(true);
                    }}
                    inputRef={(el) => {
                      if (el)
                        siderBarRefs.current[netSidebarInput.Y_HEIGHT] = el;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.X_WIDTH].focus();
                      } else if (e.key === "Enter") {
                        siderBarRefs.current[
                          netSidebarInput.DIAMETER_X
                        ].focus();
                      } else if (e.key === "ArrowDown") {
                        e.preventDefault();
                        siderBarRefs.current[
                          netSidebarInput.DIAMETER_X
                        ].focus();
                      }
                    }}
                  />
                </div>

                {/* X */}
                <div className="sidebar_container">
                  <div className="total_lengths">
                    <h1 className="nets_sidebar_label_large mb-0">
                      {t("X", { ns: "common" })}
                    </h1>
                  </div>

                  <img src={horizontal} alt="" />
                </div>

                {/* X Diameter */}
                <div className="sidebar_container">
                  <div className="total_lengths">
                    <h1 className="nets_sidebar_label mb-0">
                      {t("diameter", { ns: "tool" })}
                      <span
                        className={
                          "nets_units " + (rightToLeft ? "pr-1" : "pl-1")
                        }
                        style={{ paddingLeft: "3px" }}
                      >
                        {t("mm", { ns: "tool" })}
                      </span>
                    </h1>
                  </div>
                  <select
                    className={
                      "form-control form-control-sm  nets_sidebar_input" + editModeClass +
                      (errorsText.some(
                        (error) =>
                          error.errorType === netErrorTypes.DIAMETER_RATIO
                      )
                        ? "_warning"
                        : "")
                    }
                    value={shapeObj.x_diameter ? shapeObj.x_diameter.id : ""}
                    onChange={(e) => {
                      const _diameter = getDiameter(parseInt(e.target.value));
                      if (_diameter) {
                        setShape({ ...shapeObj, x_diameter: _diameter });
                      }
                      checkDiameters(_diameter, shapeObj.y_diameter);
                    }}
                    ref={(el) => {
                      if (el)
                        siderBarRefs.current[netSidebarInput.DIAMETER_X] = el;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.Y_HEIGHT].focus();
                      } else if (e.key === "Enter") {
                        siderBarRefs.current[netSidebarInput.GAP_X].focus();
                      } else if (e.key === "ArrowDown") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.GAP_X].focus();
                      }
                    }}
                    onBlur={() => {
                      siderBarRefs.current[
                        netSidebarInput.DIAMETER_X
                      ].classList.remove("nets_sidebar_input");
                      siderBarRefs.current[
                        netSidebarInput.DIAMETER_X
                      ].classList.add("nets_sidebar_input_updated");
                    }}
                  >
                    {shapeObj.steel_type in steelDiameters &&
                      steelDiameters[shapeObj.steel_type].map(
                        (reDi: any, index: number) =>
                          reDi?.steel_diameter <= 16 && (
                            <option
                              className="shp_option"
                              key={"net-uni-diameter-options2-" + index}
                              value={reDi.id}
                            >
                              {parseFloat(reDi?.steel_diameter)}
                            </option>
                          )
                      )}
                  </select>
                </div>

                {errorsText.some(
                  (error) => error.errorType === netErrorTypes.DIAMETER_RATIO
                ) && (
                  <div className="nets_error_continer">
                    <img
                      src={warning_sign}
                      alt={"warning"}
                      style={{ height: "22px", marginRight: "0px" }}
                    />
                    <p
                      className={"nets_error_text"}
                      style={{
                        fontWeight: 400,
                        fontSize: "10.5px",
                        lineHeight: "15px",
                        color: "#878C97",
                        paddingLeft: "10px",
                      }}
                    >
                      {t("error_net_diameter", { ns: "tool" })}
                    </p>
                  </div>
                )}

                {/* X Gap */}
                <div className="sidebar_container">
                  <div className="total_lengths">
                    <h1 className="nets_sidebar_label mb-0">
                      {t("gap", { ns: "common" })}
                      <span
                        className={
                          "nets_units " + (rightToLeft ? "pr-1" : "pl-1")
                        }
                        style={{ paddingLeft: "3px" }}
                      >
                        {t("cm", { ns: "tool" })}
                      </span>
                    </h1>
                  </div>
                  <select
                    className={
                      "form-control form-control-sm nets_sidebar_input" + editModeClass +
                      (errorsText.some(
                        (error) =>
                          error.errorType === netErrorTypes.GAP_X1_VALIDATION
                      ) ||
                      errorsText.some(
                        (error) =>
                          error.errorType === netErrorTypes.GAP_X1Y1_VALIDATION
                      )
                        ? "_error"
                        : "")
                    }
                    value={shapeObj.x_gap || ""}
                    onChange={(e) => {
                      setShape({
                        ...shapeObj,
                        x_gap: parseInt(e.target.value),
                      });
                      if (!edgeChanged_X) {
                        const widthPartitions = calculate_partitions(
                          parseInt(e.target.value),
                          shapeObj.x_length,
                          shapeObj.x_diameter.steel_diameter
                        );
                        setPartitionX(widthPartitions);
                        sidesErrorsX.forEach((error)=>{
                          removeError(error);
                        })
                      } else {
                        if (
                          partitionX._1 % parseInt(e.target.value) !== 0 &&
                          partitionY._1 % shapeObj.y_gap !== 0
                        ) {
                          addError({
                            errorType: netErrorTypes.GAP_X1Y1_VALIDATION,
                            message: t("error_net_gap_xy", { ns: "tool" }),
                          });
                        } else if (
                          partitionX._1 % parseInt(e.target.value) !==
                          0
                        ) {
                          addError({
                            errorType: netErrorTypes.GAP_X1_VALIDATION,
                            message: t("error_net_gap_xy", { ns: "tool" }),
                          });
                        } else {
                          removeError(netErrorTypes.MIN_X2PART);
                          removeError(netErrorTypes._X1);
                          removeError(netErrorTypes.GAP_X1Y1_VALIDATION);
                          removeError(netErrorTypes.GAP_X1_VALIDATION);
                        }
                      }
                    }}
                    ref={(el) => {
                      if (el) siderBarRefs.current[netSidebarInput.GAP_X] = el;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp") {
                        e.preventDefault();
                        siderBarRefs.current[
                          netSidebarInput.DIAMETER_X
                        ].focus();
                      } else if (e.key === "Enter") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.X_2].focus();
                      } else if (e.key === "ArrowDown") {
                        e.preventDefault();
                        siderBarRefs.current[
                          netSidebarInput.STEEL_TYPE_X
                        ].focus();
                      }
                    }}
                    onBlur={() => {
                      siderBarRefs.current[
                        netSidebarInput.GAP_X
                      ].classList.remove("nets_sidebar_input");
                      siderBarRefs.current[netSidebarInput.GAP_X].classList.add(
                        "nets_sidebar_input_updated"
                      );
                    }}
                  >
                    {gapOptions.map((item, index) => (
                      <option key={"gap-options-1" + index}>{item}</option>
                    ))}
                  </select>
                </div>

                {/* X Steel Type */}

                <div className="sidebar_container">
                  <div className="total_lengths">
                    <h1
                      className="nets_sidebar_label mb-0"
                      style={{
                        color: "#878C97",
                      }}
                    >
                      {t("steel type", { ns: "common" })}
                    </h1>
                  </div>
                  <select
                    className="form-control form-control-sm nets_sidebar_input_grey"
                    value={shapeObj.steel_type || ""}
                    onChange={({ target }) => {
                      const stType = parseInt(target.value);
                      if (stType in steelDiameters) {
                        setShape((prev: any) => ({
                          ...prev,
                          steel_type: parseInt(target.value),
                          x_diameter: steelDiameters[stType]?.find(
                            (item: any) => item.steel_diameter === shapeObj.x_diameter.steel_diameter
                          ),
                          y_diameter: steelDiameters[stType]?.find(
                            (item: any) => item.steel_diameter === shapeObj.y_diameter.steel_diameter
                          ),                        
                        }));
                      } else {
                        setShape((prev: any) => ({
                          ...prev,
                          steel_type: stType,
                          x_diameter: steelDiameters[stType]?.find(
                            (item: any) => item.steel_diameter === shapeObj.x_diameter.steel_diameter
                          ),
                          y_diameter: steelDiameters[stType]?.find(
                            (item: any) => item.steel_diameter === shapeObj.y_diameter.steel_diameter
                          ),                        
                        }));
                      }
                    }}
                    ref={(el) => {
                      if (el)
                        siderBarRefs.current[netSidebarInput.STEEL_TYPE_X] = el;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.GAP_X].focus();
                      } else if (e.key === "ArrowDown" || e.key === "Enter") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.X_2].focus();
                      }
                    }}
                    onBlur={() => {
                      siderBarRefs.current[
                        netSidebarInput.STEEL_TYPE_X
                      ].classList.remove("nets_sidebar_input");
                      siderBarRefs.current[
                        netSidebarInput.STEEL_TYPE_X
                      ].classList.add("nets_sidebar_input_updated");
                    }}
                  >
                    {steelTypes.map((st: { id: number; name: string }) => (
                      <option
                        key={"rebar-steeltype-options-" + st.id}
                        value={st.id}
                      >
                        {st.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Net X Sides */}
                <div
                  className="nets_sides"
                  style={{ borderBottom: "1px solid #D9D9D9" }}
                >
                  <div className="nets_sides_input">
                    <div className="input_X2">
                      <h1
                        className="nets_sidebar_label m-0"
                        style={
                          rightToLeft
                            ? { paddingRight: "27px", paddingBottom: "2px" }
                            : { paddingLeft: "27px", paddingBottom: "2px" }
                        }
                      >
                        X<span className="small_txt">2</span>
                      </h1>

                      <input
                        type="number"
                        min="0"
                        className={
                          "para text-center text-ash input-inside nets_sidebar_input" + editModeClass +
                          (errorsText.some(
                            (error) =>
                              error.errorType === netErrorTypes.MIN_X2PART
                          ) ||
                          errorsText.some(
                            (error) => error.errorType === netErrorTypes._X2
                          )
                            ? "_error"
                            : "") +
                          (rightToLeft
                            ? " ns_marginLeft ns_box_mr"
                            : " ns_marginRight ns_box_ml")
                        }
                        style={
                          errorsText.some(
                            (error) => error.errorType === netErrorTypes._X2
                          ) ||
                          errorsText.some(
                            (error) =>
                              error.errorType === netErrorTypes.MIN_X2PART
                          )
                            ? {
                                border: "2px solid #f84242",
                                width: "60px",
                              }
                            : {
                                width: "60px",
                              }
                        }
                        value={partitionX._2 || ""}
                        //debounceTimeout={300}
                        onFocus={(e: any) => e.target.select()}
                        onChange={onX2Change}
                        ref={(el) => {
                          if (el)
                            siderBarRefs.current[netSidebarInput.X_2] = el;
                        }}
                        onKeyDown={(e) => {
                          if (rightToLeft) {
                            if (e.key === "ArrowUp" || e.key === "ArrowRight") {
                              e.preventDefault();
                              siderBarRefs.current[
                                netSidebarInput.STEEL_TYPE_X
                              ].focus();
                            } else if (
                              e.key === "ArrowLeft" ||
                              e.key === "Enter"
                            ) {
                              e.preventDefault();
                              siderBarRefs.current[netSidebarInput.X_3].focus();
                            }
                          } else {
                            if (e.key === "ArrowUp" || e.key === "ArrowLeft") {
                              e.preventDefault();
                              siderBarRefs.current[
                                netSidebarInput.STEEL_TYPE_X
                              ].focus();
                            } else if (
                              e.key === "ArrowRight" ||
                              e.key === "Enter"
                            ) {
                              e.preventDefault();
                              siderBarRefs.current[netSidebarInput.X_3].focus();
                            }
                          }

                          if (e.key === "ArrowDown") {
                            e.preventDefault();
                            siderBarRefs.current[
                              netSidebarInput.DIAMETER_Y
                            ].focus();
                          }
                        }}
                        onBlur={() => {
                          siderBarRefs.current[
                            netSidebarInput.X_2
                          ].classList.remove("nets_sidebar_input");
                          siderBarRefs.current[
                            netSidebarInput.X_2
                          ].classList.add("nets_sidebar_input_updated");
                        }}
                      />
                    </div>

                    <div className="inputX_1">
                      <h1
                        className="nets_sidebar_label m-0"
                        style={
                          rightToLeft
                            ? { paddingRight: "18px", paddingBottom: "2px" }
                            : { paddingLeft: "23px", paddingBottom: "2px" }
                        }
                      >
                        X<span className="small_txt">1</span>
                      </h1>

                      <div
                        className={
                          "net_sides_fixed_val" +
                          (errorsText.some(
                            (error) => error.errorType === netErrorTypes._X1
                          ) ||
                          errorsText.some(
                            (error) =>
                              error.errorType ===
                              netErrorTypes.GAP_X1Y1_VALIDATION
                          ) ||
                          errorsText.some(
                            (error) =>
                              error.errorType ===
                              netErrorTypes.GAP_X1_VALIDATION
                          )
                            ? "_error "
                            : " ") +
                          (rightToLeft ? " ns_marginLeft" : " ns_marginRight")
                        }
                      >
                        {partitionX._1}
                      </div>
                    </div>

                    <div className="inputX_3">
                      <h1
                        className="nets_sidebar_label m-0"
                        style={
                          rightToLeft
                            ? { paddingRight: "18px", paddingBottom: "2px" }
                            : { paddingLeft: "23px", paddingBottom: "2px" }
                        }
                      >
                        X<span className="small_txt">3</span>
                      </h1>
                      <input
                        type="number"
                        min="0"
                        className={
                          "para text-center text-ash input-inside nets_sidebar_input" + editModeClass +
                          (errorsText.some(
                            (error) =>
                              error.errorType === netErrorTypes.MIN_X3PART
                          ) ||
                          errorsText.some(
                            (error) => error.errorType === netErrorTypes._X3
                          )
                            ? "_error"
                            : "") +
                          (rightToLeft ? " ns_marginLeft" : " ns_marginRight")
                        }
                        style={
                          errorsText.some(
                            (error) => error.errorType === netErrorTypes._X3
                          ) ||
                          errorsText.some(
                            (error) =>
                              error.errorType === netErrorTypes.MIN_X3PART
                          )
                            ? {
                                border: "2px solid #f84242",
                                width: "60px",
                              }
                            : {
                                width: "60px",
                              }
                        }
                        value={partitionX._3 || ""}
                        //debounceTimeout={300}
                        onFocus={(e: any) => e.target.select()}
                        onChange={onX3Change}
                        ref={(el) => {
                          if (el)
                            siderBarRefs.current[netSidebarInput.X_3] = el;
                        }}
                        onKeyDown={(e) => {
                          if (rightToLeft) {
                            if (
                              e.key === "ArrowDown" ||
                              e.key === "ArrowLeft"
                            ) {
                              e.preventDefault();
                              siderBarRefs.current[
                                netSidebarInput.DIAMETER_Y
                              ].focus();
                            } else if (e.key === "ArrowRight") {
                              e.preventDefault();
                              siderBarRefs.current[netSidebarInput.X_3].focus();
                            }
                          } else {
                            if (
                              e.key === "ArrowDown" ||
                              e.key === "ArrowRight"
                            ) {
                              e.preventDefault();
                              siderBarRefs.current[
                                netSidebarInput.DIAMETER_Y
                              ].focus();
                            } else if (e.key === "ArrowLeft") {
                              e.preventDefault();
                              siderBarRefs.current[netSidebarInput.X_2].focus();
                            }
                          }
                          if (e.key === "ArrowUp") {
                            e.preventDefault();
                            siderBarRefs.current[
                              netSidebarInput.STEEL_TYPE_X
                            ].focus();
                          } else if (e.key === "Enter") {
                            siderBarRefs.current[
                              netSidebarInput.DIAMETER_Y
                            ].focus();
                          }
                        }}
                        onBlur={() => {
                          siderBarRefs.current[
                            netSidebarInput.X_3
                          ].classList.remove("nets_sidebar_input");
                          siderBarRefs.current[
                            netSidebarInput.X_3
                          ].classList.add("nets_sidebar_input_updated");
                        }}
                      />
                    </div>
                  </div>
                  <div className="nets_bg_image">
                    <img src={sides_bg} alt="" />
                  </div>
                </div>

                {errorsText.some(
                  (error) =>
                    error.errorType === netErrorTypes.MIN_X2PART ||
                    error.errorType === netErrorTypes.MIN_X3PART
                ) && (
                  <div className="nets_error_continer sides_error_X">
                    <img
                      src={warning_sign}
                      alt={"warning"}
                      style={{ height: "22px", marginRight: "0px" }}
                    />
                    <p
                      className={"nets_error_text"}
                      style={{
                        fontWeight: 400,
                        fontSize: "10.5px",
                        lineHeight: "15px",
                        color: "#878C97",
                        paddingLeft: "10px",
                      }}
                    >
                      {t("error_net_sides", { ns: "tool" })}
                    </p>
                  </div>
                )}

                {/* Y */}
                <div className="sidebar_container">
                  <div className="total_lengths">
                    <h1 className="nets_sidebar_label_large mb-0">
                      {t("Y", { ns: "common" })}
                    </h1>
                  </div>
                  <img src={vertical} alt="Y-Bar" />
                </div>

                {/* Y Diameter */}
                <div className="sidebar_container">
                  <div className="total_lengths">
                    <h1 className="nets_sidebar_label mb-0">
                      {t("diameter", { ns: "tool" })}
                      <span
                        className={
                          "nets_units " + (rightToLeft ? "pr-1" : "pl-1")
                        }
                        style={{ paddingLeft: "3px" }}
                      >
                        {t("mm", { ns: "tool" })}
                      </span>
                    </h1>
                  </div>
                  <select
                    className={
                      "form-control form-control-sm  nets_sidebar_input" + editModeClass +
                      (errorsText.some(
                        (error) =>
                          error.errorType === netErrorTypes.DIAMETER_RATIO
                      )
                        ? "_warning"
                        : "")
                    }
                    value={shapeObj.y_diameter ? shapeObj.y_diameter.id : ""}
                    onChange={(e) => {
                      const _diameter = getDiameter(parseInt(e.target.value));
                      if (_diameter) {
                        setShape({ ...shapeObj, y_diameter: _diameter });
                      }
                      checkDiameters(shapeObj.x_diameter, _diameter);
                    }}
                    ref={(el) => {
                      if (el)
                        siderBarRefs.current[netSidebarInput.DIAMETER_Y] = el;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.X_3].focus();
                      } else if (e.key === "Enter") {
                        siderBarRefs.current[netSidebarInput.GAP_Y].focus();
                      } else if (e.key === "ArrowDown") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.GAP_Y].focus();
                      }
                    }}
                    onBlur={() => {
                      siderBarRefs.current[
                        netSidebarInput.DIAMETER_Y
                      ].classList.remove("nets_sidebar_input");
                      siderBarRefs.current[
                        netSidebarInput.DIAMETER_Y
                      ].classList.add("nets_sidebar_input_updated");
                    }}
                  >
                    {shapeObj.steel_type in steelDiameters &&
                      steelDiameters[shapeObj.steel_type].map(
                        (reDi: any, index: number) =>
                          reDi?.steel_diameter <= 16 && (
                            <option
                              className="shp_option"
                              key={"net-uni-diameter-options2-" + index}
                              value={reDi.id}
                            >
                              {parseFloat(reDi?.steel_diameter)}
                            </option>
                          )
                      )}
                  </select>
                </div>

                {errorsText.some(
                  (error) => error.errorType === netErrorTypes.DIAMETER_RATIO
                ) && (
                  <div className="nets_error_continer">
                    <img
                      src={warning_sign}
                      alt={"warning"}
                      style={{ height: "22px", marginRight: "0px" }}
                    />
                    <p
                      className={"nets_error_text"}
                      style={{
                        fontWeight: 400,
                        fontSize: "10.5px",
                        lineHeight: "15px",
                        color: "#878C97",
                        paddingLeft: "10px",
                      }}
                    >
                      {t("error_net_diameter", { ns: "tool" })}
                    </p>
                  </div>
                )}

                {/* Y Gap */}
                <div className="sidebar_container">
                  <div className="total_lengths">
                    <h1 className="nets_sidebar_label mb-0">
                      {t("gap", { ns: "common" })}
                      <span
                        className={
                          "nets_units " + (rightToLeft ? "pr-1" : "pl-1")
                        }
                        style={{ paddingLeft: "3px" }}
                      >
                        {t("cm", { ns: "tool" })}
                      </span>
                    </h1>
                  </div>
                  <select
                    className={
                      "form-control form-control-sm nets_sidebar_input" + editModeClass + 
                      (errorsText.some(
                        (error) =>
                          error.errorType === netErrorTypes.GAP_Y1_VALIDATION
                      ) ||
                      errorsText.some(
                        (error) =>
                          error.errorType === netErrorTypes.GAP_X1Y1_VALIDATION
                      )
                        ? "_error"
                        : "")
                    }
                    value={shapeObj.y_gap || ""}
                    onChange={(e) => {
                      setShape({
                        ...shapeObj,
                        y_gap: parseInt(e.target.value),
                      });
                      if (!edgeChanged_Y) {
                        const heightPartitions = calculate_partitions(
                          parseInt(e.target.value),
                          shapeObj.y_length,
                          shapeObj.y_diameter.steel_diameter
                        );
                        setPartitionY(heightPartitions);
                        sidesErrorsY.forEach((error)=>{
                          removeError(error);
                        })
                      } else {
                        if (
                          partitionX._1 % shapeObj.x_gap !== 0 &&
                          partitionY._1 % parseInt(e.target.value) !== 0
                        ) {
                          addError({
                            errorType: netErrorTypes.GAP_X1Y1_VALIDATION,
                            message: t("error_net_gap_xy", { ns: "tool" }),
                          });
                        } else if (
                          partitionY._1 % parseInt(e.target.value) !==
                          0
                        ) {
                          addError({
                            errorType: netErrorTypes.GAP_Y1_VALIDATION,
                            message: t("error_net_gap_xy", { ns: "tool" }),
                          });
                        } else {
                          removeError(netErrorTypes.GAP_X1Y1_VALIDATION);
                          removeError(netErrorTypes.GAP_Y1_VALIDATION);
                        }
                      }
                    }}
                    ref={(el) => {
                      if (el) siderBarRefs.current[netSidebarInput.GAP_Y] = el;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp") {
                        e.preventDefault();
                        siderBarRefs.current[
                          netSidebarInput.DIAMETER_Y
                        ].focus();
                      } else if (e.key === "Enter") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.Y_2].focus();
                      } else if (e.key === "ArrowDown") {
                        e.preventDefault();
                        siderBarRefs.current[
                          netSidebarInput.STEEL_TYPE_Y
                        ].focus();
                      }
                    }}
                    onBlur={() => {
                      siderBarRefs.current[
                        netSidebarInput.GAP_Y
                      ].classList.remove("nets_sidebar_input");
                      siderBarRefs.current[netSidebarInput.GAP_Y].classList.add(
                        "nets_sidebar_input_updated"
                      );
                    }}
                  >
                    {gapOptions.map((item, index) => (
                      <option key={"gap-options-1" + index}>{item}</option>
                    ))}
                  </select>
                </div>

                {/* Y Steel Type */}
                <div className="sidebar_container">
                  <div className="total_lengths">
                    <h1
                      className="nets_sidebar_label mb-0"
                      style={{
                        color: "#878C97",
                      }}
                    >
                      {t("steel type", { ns: "common" })}
                    </h1>
                  </div>
                  <select
                    className="form-control form-control-sm nets_sidebar_input_grey"
                    style={{
                      backgroundColor: "#F5F5F5",
                    }}
                    value={shapeObj.steel_type || ""}
                    onChange={({ target }) => {
                      const stType = parseInt(target.value);
                      if (stType in steelDiameters) {
                        setShape((prev: any) => ({
                          ...prev,
                          steel_type: parseInt(target.value),
                          x_diameter: steelDiameters[stType]?.find(
                            (item: any) => item.steel_diameter === shapeObj.x_diameter.steel_diameter
                          ),
                          y_diameter: steelDiameters[stType]?.find(
                            (item: any) => item.steel_diameter === shapeObj.y_diameter.steel_diameter
                          ),                        
                        }));
                      } else {
                        setShape((prev: any) => ({
                          ...prev,
                          steel_type: stType,
                          x_diameter: steelDiameters[stType]?.find(
                            (item: any) => item.steel_diameter === shapeObj.x_diameter.steel_diameter
                          ),
                          y_diameter: steelDiameters[stType]?.find(
                            (item: any) => item.steel_diameter === shapeObj.y_diameter.steel_diameter
                          ),                        
                        }));
                      }
                    }}
                    ref={(el) => {
                      if (el)
                        siderBarRefs.current[netSidebarInput.STEEL_TYPE_Y] = el;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.GAP_Y].focus();
                      } else if (e.key === "ArrowDown" || e.key === "Enter") {
                        e.preventDefault();
                        siderBarRefs.current[netSidebarInput.Y_2].focus();
                      }
                    }}
                    onBlur={() => {
                      siderBarRefs.current[
                        netSidebarInput.STEEL_TYPE_Y
                      ].classList.remove("nets_sidebar_input");
                      siderBarRefs.current[
                        netSidebarInput.STEEL_TYPE_Y
                      ].classList.add("nets_sidebar_input_updated");
                    }}
                  >
                    {steelTypes.map((st: { id: number; name: string }) => (
                      <option
                        key={"rebar-steeltype-options-" + st.id}
                        value={st.id}
                      >
                        {st.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Net Y Sides */}
                <div className="nets_sides">
                  <div className="nets_sides_input">
                    <div className="input_Y2">
                      <h1
                        className="nets_sidebar_label m-0"
                        style={
                          rightToLeft
                            ? { paddingRight: "27px", paddingBottom: "2px" }
                            : { paddingLeft: "27px", paddingBottom: "2px" }
                        }
                      >
                        Y<span className="small_txt">2</span>
                      </h1>

                      <input
                        type="number"
                        min="0"
                        className={
                          "para text-center text-ash input-inside nets_sidebar_input" + editModeClass +
                          (errorsText.some(
                            (error) => error.errorType === netErrorTypes._Y2
                          ) ||
                          errorsText.some(
                            (error) =>
                              error.errorType === netErrorTypes.MIN_Y2PART
                          )
                            ? "_error"
                            : "") +
                          (rightToLeft
                            ? " ns_marginLeft ns_box_mr"
                            : " ns_marginRight ns_box_ml")
                        }
                        style={
                          errorsText.some(
                            (error) => error.errorType === netErrorTypes._Y2
                          ) ||
                          errorsText.some(
                            (error) =>
                              error.errorType === netErrorTypes.MIN_Y2PART
                          )
                            ? {
                                border: "2px solid #f84242",
                                width: "60px",
                              }
                            : {
                                width: "60px",
                              }
                        }
                        value={partitionY._2 || ""}
                        //debounceTimeout={300}
                        onFocus={(e: any) => e.target.select()}
                        onChange={onY2Change}
                        ref={(el) => {
                          if (el)
                            siderBarRefs.current[netSidebarInput.Y_2] = el;
                        }}
                        onKeyDown={(e) => {
                          if (rightToLeft) {
                            if (e.key === "ArrowUp" || e.key === "ArrowRight") {
                              e.preventDefault();
                              siderBarRefs.current[
                                netSidebarInput.STEEL_TYPE_Y
                              ].focus();
                            } else if (
                              e.key === "ArrowLeft" ||
                              e.key === "Enter"
                            ) {
                              e.preventDefault();
                              siderBarRefs.current[netSidebarInput.Y_3].focus();
                            }
                          } else {
                            if (e.key === "ArrowUp" || e.key === "ArrowLeft") {
                              e.preventDefault();
                              siderBarRefs.current[
                                netSidebarInput.STEEL_TYPE_Y
                              ].focus();
                            } else if (
                              e.key === "ArrowRight" ||
                              e.key === "Enter"
                            ) {
                              e.preventDefault();
                              siderBarRefs.current[netSidebarInput.Y_3].focus();
                            }
                          }
                          if (e.key === "ArrowDown") {
                            e.preventDefault();
                            if (showNote) {
                              siderBarRefs.current[
                                netSidebarInput.NOTE
                              ].focus();
                            } else {
                              siderBarRefs.current[
                                netSidebarInput.QUANTITY
                              ].focus();
                            }
                          }
                        }}
                        onBlur={() => {
                          siderBarRefs.current[
                            netSidebarInput.Y_2
                          ].classList.remove("nets_sidebar_input");
                          siderBarRefs.current[
                            netSidebarInput.Y_2
                          ].classList.add("nets_sidebar_input_updated");
                        }}
                      />
                    </div>

                    <div className="inputY_1">
                      <h1
                        className="nets_sidebar_label m-0"
                        style={
                          rightToLeft
                            ? { paddingRight: "18px", paddingBottom: "2px" }
                            : { paddingLeft: "23px", paddingBottom: "2px" }
                        }
                      >
                        Y<span className="small_txt">1</span>
                      </h1>

                      <div
                        className={
                          "net_sides_fixed_val" +
                          (errorsText.some(
                            (error) => error.errorType === netErrorTypes._Y1
                          ) ||
                          errorsText.some(
                            (error) =>
                              error.errorType ===
                              netErrorTypes.GAP_X1Y1_VALIDATION
                          ) ||
                          errorsText.some(
                            (error) =>
                              error.errorType ===
                              netErrorTypes.GAP_Y1_VALIDATION
                          )
                            ? "_error "
                            : " ") +
                          (rightToLeft ? " ns_marginLeft" : " ns_marginRight")
                        }
                      >
                        <p>{partitionY._1}</p>
                      </div>
                    </div>

                    <div className="inputY_3">
                      <h1
                        className="nets_sidebar_label m-0"
                        style={
                          rightToLeft
                            ? { paddingRight: "18px", paddingBottom: "2px" }
                            : { paddingLeft: "23px", paddingBottom: "2px" }
                        }
                      >
                        Y<span className="small_txt">3</span>
                      </h1>
                      <input
                        type="number"
                        min="0"
                        className={
                          "para text-center text-ash input-inside nets_sidebar_input" + editModeClass +
                          (errorsText.some(
                            (error) => error.errorType === netErrorTypes._Y3
                          ) ||
                          errorsText.some(
                            (error) =>
                              error.errorType === netErrorTypes.MIN_Y3PART
                          )
                            ? "_error"
                            : "") +
                          (rightToLeft ? " ns_marginLeft" : " ns_marginRight")
                        }
                        style={
                          errorsText.some(
                            (error) => error.errorType === netErrorTypes._Y3
                          ) ||
                          errorsText.some(
                            (error) =>
                              error.errorType === netErrorTypes.MIN_Y3PART
                          )
                            ? {
                                border: "2px solid #f84242",
                                width: "60px",
                              }
                            : {
                                width: "60px",
                              }
                        }
                        value={partitionY._3 || ""}
                        //debounceTimeout={300}
                        onFocus={(e: any) => e.target.select()}
                        onChange={onY3Change}
                        ref={(el) => {
                          if (el)
                            siderBarRefs.current[netSidebarInput.Y_3] = el;
                        }}
                        onKeyDown={(e) => {
                          if (rightToLeft) {
                            if (
                              e.key === "ArrowDown" ||
                              e.key === "ArrowLeft" ||
                              e.key === "Enter"
                            ) {
                              e.preventDefault();
                              if (showNote) {
                                siderBarRefs.current[
                                  netSidebarInput.NOTE
                                ].focus();
                              } else {
                                siderBarRefs.current[
                                  netSidebarInput.QUANTITY
                                ].focus();
                              }
                            } else if (e.key === "ArrowRight") {
                              e.preventDefault();
                              siderBarRefs.current[netSidebarInput.Y_3].focus();
                            }
                          } else {
                            if (
                              e.key === "ArrowDown" ||
                              e.key === "ArrowRight" ||
                              e.key === "Enter"
                            ) {
                              e.preventDefault();
                              if (showNote) {
                                siderBarRefs.current[
                                  netSidebarInput.NOTE
                                ].focus();
                              } else {
                                siderBarRefs.current[
                                  netSidebarInput.QUANTITY
                                ].focus();
                              }
                            } else if (e.key === "ArrowLeft") {
                              e.preventDefault();
                              siderBarRefs.current[netSidebarInput.Y_2].focus();
                            }
                          }
                          if (e.key === "ArrowUp") {
                            e.preventDefault();
                            siderBarRefs.current[
                              netSidebarInput.STEEL_TYPE_Y
                            ].focus();
                          }
                        }}
                        onBlur={() => {
                          siderBarRefs.current[
                            netSidebarInput.Y_3
                          ].classList.remove("nets_sidebar_input");
                          siderBarRefs.current[
                            netSidebarInput.Y_3
                          ].classList.add("nets_sidebar_input_updated");
                        }}
                      />
                    </div>
                  </div>
                  <div className="nets_bg_image">
                    <img src={sides_bg} alt="" />
                  </div>
                </div>

                {errorsText.some(
                  (error) =>
                    error.errorType === netErrorTypes.MIN_Y2PART ||
                    error.errorType === netErrorTypes.MIN_Y3PART
                ) && (
                  <div className="nets_error_continer sides_error_Y">
                    <img
                      src={warning_sign}
                      alt={"warning"}
                      style={{ height: "22px", marginRight: "0px" }}
                    />
                    <p
                      className={"nets_error_text"}
                      style={{
                        fontWeight: 400,
                        fontSize: "10.5px",
                        lineHeight: "15px",
                        color: "#878C97",
                        paddingLeft: "10px",
                      }}
                    >
                      {t("error_net_sides", { ns: "tool" })}
                    </p>
                  </div>
                )}

                {/* Note */}
                <div
                  className="sidebar_container"
                  style={{
                    borderBottom: showNote ? "" : "1px solid #D9D9D9",
                    borderTop: "1px solid #D9D9D9",
                    height: "25px",
                  }}
                >
                  <div className="nets_note">
                    <h1
                      className="nets_sidebar_label mb-0"
                      style={{
                        fontSize: "10.5px",
                        lineHeight: "15px",
                        color: "#878C97",
                      }}
                    >
                      {t("note", { ns: "common" })}
                    </h1>
                    <div
                      className="note_img"
                      onClick={() => {
                        setShowNote(!showNote);
                      }}
                    >
                      {showNote ? (
                        <img src={remove} className="nets_note_img" alt="+" />
                      ) : (
                        <img src={add} className="nets_note_img" alt="+" />
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="sidebar_container"
                  style={{ marginBottom: "12px" }}
                >
                  {showNote && (
                    <input
                      type="text"
                      className={"form-control nets_sidebar_input" + editModeClass}
                      style={{ width: "240px", height: "30px" }}
                      value={orderItem.production_notes || ""}
                      onChange={(e) =>
                        setOrderItem({
                          ...orderItem,
                          production_notes: e.target.value,
                        })
                      }
                      maxLength={50}
                      ref={(el) => {
                        if (el) siderBarRefs.current[netSidebarInput.NOTE] = el;
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "ArrowUp") {
                          e.preventDefault();
                          siderBarRefs.current[netSidebarInput.Y_3].focus();
                        } else if (e.key === "ArrowDown" || e.key === "Enter") {
                          e.preventDefault();
                          siderBarRefs.current[
                            netSidebarInput.QUANTITY
                          ].focus();
                        }
                      }}
                      onBlur={() => {
                        siderBarRefs.current[
                          netSidebarInput.NOTE
                        ].classList.remove("nets_sidebar_input");
                        siderBarRefs.current[
                          netSidebarInput.NOTE
                        ].classList.add("nets_sidebar_input_updated");
                      }}
                    />
                  )}
                </div>
              </>
            )}
          </div>

          <div className={"nets_sidebar_footer " + (showStandard ? "" : "nets_footer_shadow")}>
            {/* Weight */}
            <div
              className="sidebar_container nets_weight"
              style={{
                borderBottom: "1px solid #D9D9D9",
                borderTop: "1px solid #D9D9D9",
                height: "35px",
              }}
            >
              <div className="nets_weight_unit">
                <p className="nets_units" style={{ color: "#374051" }}>
                  {t("unit", { ns: "common" })}
                  {": " + shapeObj.unit_weight || 0}
                  <span className={rightToLeft ? "pr-1" : "pl-1"}>
                    {t("kg", { ns: "tool" })}
                  </span>
                </p>
              </div>

              <div
                className="nets_weight_total"
                style={rightToLeft ? { left: "75px" } : { left: "140px" }}
              >
                <p className="nets_units" style={{ color: "#374051" }}>
                  {t("total", { ns: "common" })} {": "}
                  {isNaN(shapeObj.unit_weight * orderItem.quantity) ||
                  orderItem.quantity < 1
                    ? "-"
                    : (shapeObj.unit_weight * orderItem.quantity)
                        .toFixed(0)
                        .toString()
                        .replace(commaSeparated, ",")}
                  <span className={rightToLeft ? "pr-1" : "pl-1"}>
                    {t("kg", { ns: "tool" })}
                  </span>
                </p>
              </div>
            </div>

            {/* Quantity */}
            <div
              className="sidebar_container"
              style={
                errorsText.some(
                  (error) => error.errorType === netErrorTypes.AMOUNT_NEG
                )
                  ? {}
                  : { borderBottom: "1px solid #D9D9D9" }
              }
            >
              <div className="quantity">
                <h1 className="nets_sidebar_label_large mb-0">
                  {t("quantity", { ns: "common" })}
                </h1>
              </div>
              <input
                type="number"
                className={
                  "para text-center text-ash input-inside nets_sidebar_input" + editModeClass +
                  (errorsText.some(
                    (error) => error.errorType === netErrorTypes.AMOUNT
                  )
                    ? "_error"
                    : errorsText.some(
                        (error) => error.errorType === netErrorTypes.AMOUNT_NEG
                      )
                    ? "_warning"
                    : "")
                }
                value={orderItem.quantity || ""}
                onFocus={(e: any) => e.target.select()}
                onChange={(e) => {
                  removeError(netErrorTypes.AMOUNT);
                  removeError(netErrorTypes.AMOUNT_NEG);

                  setOrderItem({
                    ...orderItem,
                    quantity: parseInt(e.target.value) >= 0 ? parseInt(e.target.value) : "",
                  });
                }}
                onBlur={(e) => {
                  if (
                    parseInt(e.target.value) === 0 ||
                    Number.isNaN(parseInt(e.target.value))
                  ) {
                    addError({
                      message: t("error_net_amount", { ns: "tool" }),
                      errorType: netErrorTypes.AMOUNT,
                    });
                  } else if (parseInt(e.target.value) < 0) {
                    addError({
                      message: t("error_net_amount_min", { ns: "tool" }),
                      errorType: netErrorTypes.AMOUNT_NEG,
                    });
                  }
                  siderBarRefs.current[
                    netSidebarInput.QUANTITY
                  ].classList.remove("nets_sidebar_input");
                  siderBarRefs.current[netSidebarInput.QUANTITY].classList.add(
                    "nets_sidebar_input_updated"
                  );
                }}
                ref={(el) => {
                  if (el) siderBarRefs.current[netSidebarInput.QUANTITY] = el;
                }}
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp") {
                    e.preventDefault();
                    if (showNote) {
                      siderBarRefs.current[netSidebarInput.NOTE].focus();
                    } else if (!showStandard) {
                      siderBarRefs.current[netSidebarInput.Y_3].focus();
                    } else {
                      siderBarRefs.current[netSidebarInput.STEEL_TYPE].focus();
                    }
                  } else if (e.key === "ArrowDown" || e.key === "Enter") {
                    e.preventDefault();
                    if (parseInt(orderItem.quantity) > 0) {
                      addButtonRef.current.focus();
                    } else {
                      siderBarRefs.current[netSidebarInput.QUANTITY].focus();
                    }
                  }
                }}
              />
            </div>

            {errorsText.some(
              (error) => error.errorType === netErrorTypes.AMOUNT_NEG
            ) && (
              <div
                className="nets_error_continer"
                style={{ borderBottom: "1px solid #D9D9D9" }}
              >
                <img
                  src={warning_sign}
                  alt={"warning"}
                  style={{ height: "22px", marginRight: "0px" }}
                />
                <p
                  className={
                    "nets_error_text " + (rightToLeft ? "pr-2" : "pl-2")
                  }
                  style={{
                    fontWeight: 400,
                    fontSize: "10.5px",
                    lineHeight: "15px",
                    color: "#878C97",
                  }}
                >
                  {t("error_net_amount_min", { ns: "tool" })}
                </p>
              </div>
            )}

            {/* Error Text */}
            {errorsText.filter(
              (error) =>
                error.errorType !== netErrorTypes.DIAMETER_RATIO &&
                error.errorType !== netErrorTypes.AMOUNT_NEG &&
                error.errorType &&
                !netsSidesErrors.includes(error.errorType)
            ).length > 0 ? (
              <div className="errors_text">
                {errorsText
                  .filter((error) => !netsSidesErrors.includes(error.errorType)
                  && error.errorType !== netErrorTypes.AMOUNT_NEG
                  && error.errorType !== netErrorTypes.DIAMETER_RATIO
                  )
                  .slice(-2)
                  .map((error, index) => (
                    <div className="nets_error_continer" key={index}>
                      <img
                        src={error_sign}
                        alt="error"
                        style={{ height: "22px", marginRight: "0px" }}
                      />
                      <p
                        className={
                          "nets_error_text " + (rightToLeft ? "pr-2" : "pl-2")
                        }
                        style={{
                          fontWeight: 400,
                          fontSize: "10.5px",
                          lineHeight: "15px",
                          color: "#878C97",
                        }}
                      >
                        {error.message} {setIsShapesError(true)}
                      </p>
                    </div>
                  ))}
              </div>
            ) : (
              <>{setIsShapesError(false)}</>
            )}

            <div
              className="sidebar_container add_button"
              style={
                rightToLeft
                  ? { paddingLeft: "169px" }
                  : { paddingRight: "169px" }
              }
            >
              <button
                onClick={async () => {
                  await saveNet(
                    true,
                    edit ? shapeObj.tool_number : netsCount + 1
                  );
                  setTimeout(() => {
                    siderBarRefs.current[netSidebarInput.NET_NAME].focus();
                  }, 3000);
                }}
                onFocus={handleFocus}
                onBlur={(e) => {
                  e.target.style.border = "none";
                  siderBarRefs.current[
                    netSidebarInput.NET_NAME
                  ].classList.remove("nets_sidebar_input");
                  siderBarRefs.current[netSidebarInput.NET_NAME].classList.add(
                    "nets_sidebar_input_updated"
                  );
                }}
                ref={addButtonRef}
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp") {
                    e.preventDefault();
                    siderBarRefs.current[netSidebarInput.QUANTITY].focus();
                  }
                  if (rightToLeft) {
                    if (e.key === "ArrowLeft") {
                      e.preventDefault();
                      if (finishButtonRef) {
                        finishButtonRef.current?.focus();
                      } else {
                        addButtonRef.current.focus();
                      }
                    }
                  } else {
                    if (e.key === "ArrowRight") {
                      e.preventDefault();
                      if (finishButtonRef) {
                        finishButtonRef.current?.focus();
                      } else {
                        addButtonRef.current.focus();
                      }
                    }
                  }
                }}
              >
                {edit
                  ? t("update", { ns: "common" })
                  : t("add", { ns: "common" })}
              </button>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default SideBar;
