import React, { useState, useEffect } from "react";
import { isBrowser, isMobile } from "react-device-detect";
import BarLoader from "react-bar-loader";

import "./tool.scss";
import "./index.styled.scss";

import close from "../../assets/img/icon/close.png";
import cross from "../../assets/img/cages/cross.svg";
import one from "../../assets/img/cages/one.svg";
import fench from "../../assets/img/cages/fench.svg";
import three from "../../assets/img/cages/three.svg";

import Net from "./net/net";
import Rebar from "./rebar/rebar";
import Pile from "./pile/pile";

import { fetchSteelTypes } from "Redux/middlwares/steelType";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import animationData from "./save.json";

import { NET_SUBCLASS_TYPE } from "Constants/net-constants";
import { REBAR_SUBCLASS_TYPE } from "Constants/rebar-constants";
import { PILE_SUBCLASS_TYPE } from "Constants/pile-constants";
import {
  setCurrentOrderRebars,
  setCurrentOrderRebarCount,
} from "Redux/features/rebar";
import {
  setCurrentOrderNets,
  setCurrentOrderNetsCount,
} from "Redux/features/net";
import {
  setCurrentOrderPiles,
  setCurrentOrderPilesCount,
} from "Redux/features/pile";
import {
  setRowNumbers,
  removeOrderItem,
  setDuplicateRows,
} from "Redux/middlwares/order";
import { createRebar } from "Redux/middlwares/rebar";
import { createNet } from "Redux/middlwares/net";
import { createPile } from "Redux/middlwares/pile";

function Tool(props: any) {
  const {
    tab,
    setTab,
    edit,
    setEditTool,
    selectedTool,
    setSelectedTool,
    editOrderId,
    editMode,
    order,
    editToolId,
  } = props;
  const dispatch = useAppDispatch();
  const steelTypes = useAppSelector((state) => state.steelType.steelTypes);
  const currentOrderRebars = useAppSelector(
    (state) => state.rebar.currentOrderRebars
  );
  const currentOrderNets = useAppSelector(
    (state) => state.net.currentOrderNets
  );
  const currentOrderPiles = useAppSelector(
    (state) => state.pile.currentOrderPiles
  );

  const currentOrderRebarCount = useAppSelector(
    (state) => state.rebar.currentOrderRebarCount
  );
  const currentOrderNetsCount = useAppSelector(
    (state) => state.net.currentOrderNetsCount
  );
  const currentOrderPilesCount = useAppSelector(
    (state) => state.pile.currentOrderPilesCount
  );

  const [loader, setLoader] = useState(false);
  const [isStopped, setIsStopped] = useState(true);
  const { t } = useTranslation(["common", "tool"]);

  useEffect(() => {
    steelTypes.length === 0 && dispatch(fetchSteelTypes());
  });

  const checkRowNums = async (
    oldIndex: number,
    newIndex: number,
    data: any,
    subClassType: string
  ) => {
    let currentData;
    if (oldIndex < newIndex) {
      currentData = data.slice(oldIndex, newIndex + 1);
    } else {
      currentData = data.slice(newIndex, oldIndex + 1);
    }

    const pos_IdObj = currentData.reduce((acc, val) => {
      const Index = data.indexOf(val);
      acc[val.id] = Index + 1;
      return acc;
    }, {});

    let rowNums = {};
    if (NET_SUBCLASS_TYPE === subClassType) {
      rowNums = {
        net: pos_IdObj,
      };
    } else if (REBAR_SUBCLASS_TYPE === subClassType) {
      rowNums = {
        rebar: pos_IdObj,
      };
    } else if (PILE_SUBCLASS_TYPE === subClassType) {
      rowNums = {
        pile: pos_IdObj,
      };
    }

    dispatch(setRowNumbers(rowNums)).then(() => {
      if (NET_SUBCLASS_TYPE === subClassType) {
        const updatedOrderNets = currentOrderNets.map((element) => {
          if (pos_IdObj[element.id] !== undefined) {
            return {
              ...element,
              row_number: pos_IdObj[element.id],
            };
          } else {
            return element;
          }
        });
        updatedOrderNets.sort((a, b) => a.row_number - b.row_number);
        dispatch(setCurrentOrderNets(updatedOrderNets));
      } else if (REBAR_SUBCLASS_TYPE === subClassType) {
        const updatedOrderRebars = currentOrderRebars.map((element) => {
          if (pos_IdObj[element.id] !== undefined) {
            return {
              ...element,
              row_number: pos_IdObj[element.id],
            };
          } else {
            return element;
          }
        });
        updatedOrderRebars.sort((a, b) => a.row_number - b.row_number);
        dispatch(setCurrentOrderRebars(updatedOrderRebars));
      } else if (PILE_SUBCLASS_TYPE === subClassType) {
        const updatedOrderPiles = currentOrderPiles.map((element) => {
          if (pos_IdObj[element.id] !== undefined) {
            return {
              ...element,
              row_number: pos_IdObj[element.id],
            };
          } else {
            return element;
          }
        });
        updatedOrderPiles.sort((a, b) => a.row_number - b.row_number);
        dispatch(setCurrentOrderPiles(updatedOrderPiles));
      }
    });
  };

  const deleteTool = (seletedOrderItem: any) => {
    if (seletedOrderItem.id) {
      dispatch(removeOrderItem(seletedOrderItem, editMode, editOrderId));
    }
  };

  const modifyRowNumbers = async (
    row: number,
    type: string,
    orderId: number
  ) => {
    if (type === REBAR_SUBCLASS_TYPE) {
      const item = currentOrderRebars.find(
        (element) => element.row_number === row
      );
      if (item) {
        const rowNums = {
          rebar: { [item.id]: item.row_number },
        };

        await dispatch(setDuplicateRows(rowNums));
      }
      // updating the row numbers of rebars after duplucated rebar
      const updatedRebars = currentOrderRebars.map((rebar) => {
        if (rebar?.row_number > row) {
          return {
            ...rebar,
            row_number: rebar?.row_number + 1,
          };
        }
        return rebar;
      });
      await dispatch(setCurrentOrderRebars(updatedRebars));
    } else if (type === NET_SUBCLASS_TYPE) {
      const item = currentOrderNets.find(
        (element) => element.row_number === row
      );
      if (item) {
        const rowNums = {
          net: { [item.id]: item.row_number },
        };

        await dispatch(setDuplicateRows(rowNums));
      }
      // updating the row numbers of nets after duplucated rebar
      const updatedNets = currentOrderNets.map((rebar) => {
        if (rebar?.row_number > row) {
          return {
            ...rebar,
            row_number: rebar?.row_number + 1,
          };
        }
        return rebar;
      });
      await dispatch(setCurrentOrderNets(updatedNets));
    } else if (type === PILE_SUBCLASS_TYPE) {
      const item = currentOrderPiles.find(
        (element) => element.row_number === row
      );
      if (item) {
        const rowNums = {
          pile: { [item.id]: item.row_number },
        };

        await dispatch(setDuplicateRows(rowNums));
      }
      // updating the row numbers of nets after duplucated rebar
      const updatedPiles = currentOrderPiles.map((pile) => {
        if (pile?.row_number > row) {
          return {
            ...pile,
            row_number: pile?.row_number + 1,
          };
        }
        return pile;
      });
      await dispatch(setCurrentOrderPiles(updatedPiles));
    }
  };

  const copyOrderItem = async (selectedTool: any) => {
    return new Promise((resolve, reject) => {
      const _toolItem = selectedTool as any;
      if (_toolItem.id !== 0) {
        // setCoping(true);
        // setTooltipText("Copied!");

        const callBack = (val: boolean) => {
          if (val) {
            // setCoping(false);
            // setShowTooltip(true);
            // setTimeout(() => setShowTooltip(false), 500);
          }
        };

        const _tool = { ..._toolItem.tool };
        const deleteKeysTool = [
          "id",
          "created",
          "last_modified",
          "default_image",
        ];
        deleteKeysTool.forEach((k: string) => delete _tool[k]);

        _tool.preview_image = _tool.preview_image.id;
        const _orderItem = { ..._toolItem };
        const deleteKeys = ["id", "tool", "created", "last_modified", "order"];
        deleteKeys.forEach((k: string) => delete _orderItem[k]);
        if (_toolItem.tool.subclass_type === REBAR_SUBCLASS_TYPE) {
          delete _tool.icon;
          delete _tool.rebar_filter;
          _tool.diameter = _tool.diameter.id;
          _tool.rebar_side = [..._tool.rebar_side];
          for (let i = 0; i < _tool.rebar_side.length; i = i + 1) {
            const copySide = { ..._tool.rebar_side[i] };
            delete copySide.id;
            delete copySide.rebar;
            _tool.rebar_side[i] = copySide;
          }
          modifyRowNumbers(
            _toolItem.row_number,
            REBAR_SUBCLASS_TYPE,
            order.order_id
          ).then(() => {
            // Creating Duplicate Row
            dispatch(
              createRebar(
                _tool,
                _orderItem,
                callBack,
                _toolItem.row_number + 1,
                order.order_id
              )
            ).then(() => {
              dispatch(setCurrentOrderRebarCount(currentOrderRebarCount + 1));
              resolve(true);
            });
          });
        } else if (_toolItem.tool.subclass_type === NET_SUBCLASS_TYPE) {
          _tool.x_diameter = _tool.x_diameter.id;
          _tool.y_diameter = _tool.y_diameter.id;
          let num = currentOrderNets.length + 1;
          _tool.tool_number = num;
          modifyRowNumbers(
            _toolItem.row_number,
            NET_SUBCLASS_TYPE,
            order.order_id
          ).then(() => {
            dispatch(
              createNet(
                _tool,
                _orderItem,
                callBack,
                _toolItem.row_number + 1,
                order.order_id
              )
            ).then(() => {
              dispatch(setCurrentOrderNetsCount(currentOrderNetsCount + 1));
              resolve(true);
            });
          });
        } else if (_toolItem.tool.subclass_type === PILE_SUBCLASS_TYPE) {
          _tool.spiral_wire_diameter = _tool.spiral_wire_diameter.id;

          let deleteKeysPile = [] as any;
          if (!_tool.ring_diameter)
            deleteKeysPile = [
              ...deleteKeysPile,
              "ring_diameter",
              "ring_quantity",
            ];
          if (!_tool.gama_diameter)
            deleteKeysPile = [
              ...deleteKeysPile,
              "gama_diameter",
              "gama_quantity",
            ];
          if (!_tool.ear_type)
            deleteKeysPile = [...deleteKeysPile, "ear_type", "ear_length"];

          if (_tool.base_diameter.id) {
            deleteKeysPile = [
              ...deleteKeysPile,
              "external_diameter",
              "external_amount",
              "middle_diameter",
              "middle_amount",
              "internal_diameter",
              "internal_amount",
            ];
            _tool.base_diameter = _tool.base_diameter.id;
          } else if (
            _tool.external_diameter.id &&
            _tool.middle_diameter.id &&
            _tool.internal_diameter.id
          ) {
            deleteKeysPile = [
              ...deleteKeysPile,
              "base_diameter",
              "base_amount",
            ];
            _tool.external_diameter = _tool.external_diameter.id;
            _tool.middle_diameter = _tool.middle_diameter.id;
            _tool.internal_diameter = _tool.internal_diameter.id;
          } else if (
            _tool.external_diameter.id &&
            _tool.internal_diameter.id
          ) {
            deleteKeysPile = [
              ...deleteKeysPile,
              "base_diameter",
              "base_amount",
              "middle_diameter",
              "middle_amount",
            ];
            _tool.external_diameter = _tool.external_diameter.id;
            _tool.internal_diameter = _tool.internal_diameter.id;
          }

          deleteKeysPile.forEach((k: string) => delete _tool[k]);

          modifyRowNumbers(
            _toolItem.row_number,
            PILE_SUBCLASS_TYPE,
            order.order_id
          ).then(() => {
            dispatch(
              createPile(
                _tool,
                _orderItem,
                callBack,
                _toolItem.row_number + 1,
                order.order_id
              )
            ).then(() => {
              dispatch(setCurrentOrderPilesCount(currentOrderPilesCount + 1));
              resolve(true);
            });
          });
        }
      } else {
        // setTooltipText("Select tool to make a copy");
        // setShowTooltip(true);
        // setTimeout(() => setShowTooltip(false), 1000);
        resolve(true);
      }
    });
  };



  return (
    <>
      {isBrowser && (
        <section
          id="add__rebak"
          className={
            "tools " +
            (tab === 2 ? "net__tools" : "") +
            (tab === 3 ? "pile__tools" : "")
          }
        >
          <div className="tool-container">
            {tab === 1 && (
              <Rebar
                loader={loader}
                setLoader={setLoader}
                handleClose={props.onHide}
                edit={edit}
                setEditTool={setEditTool}
                selectedTool={selectedTool}
                setSelectedTool={setSelectedTool}
                setIsStopped={setIsStopped}
                tab={tab}
                setTab={setTab}
                checkRowNums={checkRowNums}
                editOrderId={editOrderId}
                editMode={editMode}
                deleteTool={deleteTool}
                copyOrderItem={copyOrderItem}
                order={order}
                editToolId={editToolId}
              />
            )}
            {tab === 2 && (
              <Net
                setLoader={setLoader}
                handleClose={props.onHide}
                edit={edit}
                setEditTool={setEditTool}
                selectedTool={selectedTool}
                setSelectedTool={setSelectedTool}
                setIsStopped={setIsStopped}
                tab={tab}
                setTab={setTab}
                checkRowNums={checkRowNums}
                editOrderId={editOrderId}
                editMode={editMode}
                deleteTool={deleteTool}
                copyOrderItem={copyOrderItem}
                order={order}
                editToolId={editToolId}
              />
            )}
            {tab === 3 && (
              <Pile
                setLoader={setLoader}
                handleClose={props.onHide}
                edit={edit}
                setEditTool={setEditTool}
                selectedTool={selectedTool}
                setSelectedTool={setSelectedTool}
                setIsStopped={setIsStopped}
                tab={tab}
                setTab={setTab}
                checkRowNums={checkRowNums}
                editOrderId={editOrderId}
                editMode={editMode}
                deleteTool={deleteTool}
                copyOrderItem={copyOrderItem}
                order={order}
                editToolId={editToolId}
              />
            )}
            <div
              className="animation-container"
              style={isStopped ? { display: "none" } : {}}
            >
              <Lottie
                options={{
                  loop: false,
                  autoplay: false,
                  animationData: animationData,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={200}
                width={200}
                isStopped={isStopped}
                speed={2}
              />
            </div>
          </div>
        </section>
      )}
      {isMobile && (
        <div className="cage__wrapper add__rebar">
          <section className="top__section py-5">
            <div className="container d-flex flex-column">
              <div className="close">
                <div className="icon__wrap text-right justify-content-end">
                  <div
                    className="icon__wrap d-inline-block text-white"
                    onClick={props.onHide}
                  >
                    <img src={cross} alt="cross icon" />
                  </div>
                </div>
              </div>
              <div className="tittle py-3">
                <h3 className="text-uppercase text-center text-white mb-0">
                  choose your shape
                </h3>
              </div>
              {/* <!-- icons row  --> */}
              <div className="row m-0 bg-white py-4 border__bottom">
                <div className="col-4 text-center">
                  <span
                    onClick={() => setTab(1)}
                    className={
                      "icon__wrap one mx-auto" + (tab === 1 ? " active-bg" : "")
                    }
                  >
                    <img src={one} alt="icon" />
                  </span>
                  <p className="para text-center text-uppercase text-dark-500 mt-2">
                    rebar
                  </p>
                </div>
                <div className="col-4 text-center">
                  <span
                    onClick={() => setTab(2)}
                    className={
                      "icon__wrap two mx-auto" + (tab === 2 ? " active-bg" : "")
                    }
                  >
                    <img src={fench} alt="icon" />
                  </span>
                  <p className="para text-center text-uppercase text-dark-500 mt-2">
                    net
                  </p>
                </div>
                <div className="col-4 text-center">
                  <span
                    onClick={() => setTab(3)}
                    className={
                      "icon__wrap three mx-auto" +
                      (tab === 3 ? " active-bg" : "")
                    }
                  >
                    <img src={three} alt="icon" />
                  </span>
                  <p className="para text-center text-uppercase text-dark-500 mt-2">
                    Cage
                  </p>
                </div>
              </div>

              {tab === 1 && (
                <Rebar
                  setLoader={setLoader}
                  handleClose={props.onHide}
                  edit={edit}
                  selectedTool={selectedTool}
                />
              )}
              {tab === 2 && (
                <Net
                  setLoader={setLoader}
                  handleClose={props.onHide}
                  edit={edit}
                  selectedTool={selectedTool}
                />
              )}
              {tab === 3 && (
                <Pile
                  setLoader={setLoader}
                  handleClose={props.onHide}
                  edit={edit}
                  selectedTool={selectedTool}
                />
              )}
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default Tool;
