import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import CustomerLayout from "../../layouts/customer";
import { Button } from "react-bootstrap";
import { resetPasswordCall } from "Services/user";
import OrderCreated from '../../assets/img/icon/order-created.svg';
import brandLogo from "../../assets/img/logo-dark.svg";
import { useTranslation } from "react-i18next";
import { useQuery } from "Components/tool/utils";



const PasswordReset = () => {
  const query = useQuery();
  const router = useHistory();
  const [loader, setLoader] = useState(false);
  const [reseted, setReseted] = useState(false);
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { t } = useTranslation(['common', 'newPassword','signup']);

  useEffect(() => {
    const token = query.get('token');
    if (token) {
      setToken(token);
    } else {
      router.push('/new-order');
    }
  }, [query, router]);

  const onSubmitHandler =  (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoader(true);
    if (password === confirmPassword) {
      resetPasswordCall({
        token,
        password,
      })
      .then((res) => {
        if (res.data.status === "OK") {
          setReseted(true);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoader(false));
    } else {
      return;
    }
  }

  return (
    <CustomerLayout>
      <section className="forms" style={{minHeight: 'inherit', marginTop: '50px', marginBottom: '50px'}}>
        <div className="container price-list">
          <div className="row mx-auto">
            <div className="
              form__header
              col-sm-12
              p-4
              d-flex
              justify-content-between
              pt-3
            ">
              <h1 className="text-white text-uppercase mb-0">
                {t('password reset', {ns: 'newPassword'})}
              </h1>
            </div>

              <div className="col-sm-12 bg-white form__body py-4">
                {!reseted ? (
                  <>
                    <div className="form__title pb-md-3 border__bottom pb-2">
                      <p className="mb-1">{t('password create', {ns: 'newPassword'})}</p>
                    </div>
                    <form onSubmit={onSubmitHandler}>
                      <div className="inputs py-4 border__bottom">
                        <div className="form-group d-md-flex justify-content-md-between align-items-center">
                          <label htmlFor="name" >{t('new pass', {ns: 'newPassword'})}</label>
                          <input type="password" className="form-control w-50" aria-describedby="new password"
                            placeholder={t('min char', {ns: 'signup'})} required autoFocus
                            value={password || ''}
                            onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="form-group d-md-flex justify-content-md-between align-items-center">
                          <label htmlFor="name" >{t('confirm pass', {ns: 'newPassword'})}</label>
                          <input type="password" className="form-control w-50" aria-describedby="confirm password"
                            placeholder={t('Write again for confirmation', {ns: 'newPassword'})} required
                            value={confirmPassword || ''}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>
                      </div>
                      <div className="
                                              btn__wrap
                                              py-2
                                              pt-4
                                              d-flex
                                              justify-content-between
                                          ">
                        <Button variant="form" onClick={() => router.push('/new-order')}>{t('cancel')}</Button>
                        <button type="submit" className="btn form__btn__black"
                          disabled={loader
                            || password === ''
                            || confirmPassword === ''
                            || password !== confirmPassword
                          }
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            {loader &&
                              <>
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              &nbsp;
                              </>
                            }
                            {t('Save', {ns: 'common'})}
                          </div>
                        </button>
                      </div>
                    </form>
                  </>
                ) : (
                  <div className="d-flex flex-column align-items-center py-3 px-2"
                  style={{width: '100%'}}>
                    <img src={OrderCreated} alt="Order Created"
                    style={{
                      width: '160px',
                      height: '160px',
                    }}
                    />
                    <p
                      style={{
                        marginTop: '25px',
                        textAlign: 'center',
                        font: 'normal normal normal 22px/26px Heebo',
                        letterSpacing: '0.6px',
                        color: '#383B3E',
                      }}
                    >{t('your', {ns: 'newPassword'})} <img src={brandLogo} alt="logo"/>{t('password confirmation', {ns: 'newPassword'})}</p>
                    <button className="btn form__btn__black w-xs-100 mt-2"
                    onClick={() => router.push('/login')}>
                      {t('OK', {ns: 'newPassword'})}
                    </button>
                  </div>
                )}
              </div>
          </div>
        </div>
      </section>
    </CustomerLayout>
  )
}

export default PasswordReset;