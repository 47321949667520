import Request, { isAxiosResponse } from "./index";

export const getDefaultRebars = (page: string) =>
  Request.get(`rebar-grouping/?cursor=${page}`).then((response) => {
    if (isAxiosResponse(response) && response.data.results.length > 0) {
      const paresedGroups = response.data.results.map((group: any) => {
        const parsedShapes = group.rebars.map((item: any) => {
          const sides = item.rebar_side.map((sideItem: any) => ({
            label: sideItem.label,
            clockwise: sideItem.clockwise,
            change_previous_point: sideItem.change_previous_point,
            radius: sideItem.radius,
            length: sideItem.length ? parseInt(sideItem.length) : sideItem.length,
            line_angle: sideItem.line_angle ? parseInt(sideItem.line_angle) : sideItem.line_angle,
            shifted_angle: sideItem.shifted_angle ? parseInt(sideItem.shifted_angle) : sideItem.shifted_angle,
            angle: sideItem.angle ? parseInt(sideItem.angle) : sideItem.angle,
            outer: sideItem.outer_angle,
            id: sideItem.id,
            symmetrical_side: sideItem.symmetrical_side,
          }));
          return {
            ...item,
            startpoint: { x: 300, y: 50 },
            rebar_side: sides,
          };
        });
        return {
          ...group,
          rebars: parsedShapes,
        };
      });
      return {
        ...response.data,
        results: paresedGroups,
      };
    }
    return response.data;
  }).catch((err) => {
    console.log(err);
    return {
      count: 0,
      next: null,
      previous: null,
      results: [],
    };
  });

export const postRebar = (rebar: any) =>
  Request.post('tool/rebar/create/', rebar);

export const getFilters = () =>
  Request.get('rebar-filters/');