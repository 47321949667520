import React, { useState, useMemo } from "react";
import emtpySet from "Assets/img/icon/empty-set-mathematical-symbol.png";
import email from "Assets/img/icon/email.png";

import emailGrey from "Assets/img/icon/email-grey.svg";
import emptySetGrey from "Assets/img/icon/empty-set-grey.svg";

import deleteIcon from "Assets/img/blocks/delete.svg";
import duplicateIcon from "Assets/img/blocks/duplicate.svg";

import { useAppSelector } from "Redux/hooks";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import "../index.styled.scss";

const NetsCard = (props) => {
  const {
    item,
    index,
    deleteTool,
    orderItem,
    copyOrderItem,
    setEditTool,
    setSelectedTool,
    setSelectedShape,
    selectedShape,
    setErrorsText,
    setInProgress,
    inProgress,
    editToolId,
    edit,
  } = props;

  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  const [isHovered, setIsHovered] = useState(0);
  const [hoveredIcon, setHoveredIcon] = useState(false);

  const editModeChecks = useMemo(() => {
    //returns true for the cards opened from the table / progress bar in edit more
    if ((editToolId === orderItem.id && edit) || !edit || editToolId === -1) {
      return true;
    } else return false; //false if opened from outside and item not selected for edit
  }, [editToolId, edit, orderItem.id]);

  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
  } = useSortable({
    id: props.id,
    disabled: hoveredIcon ? true : false,
  });

  const style = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };

  const deleteItem = () => {
    deleteTool(orderItem);
  };

  const copyItem = () => {
    setInProgress(true);
    setIsHovered(0);
    copyOrderItem(orderItem).then(() => {
      setInProgress(false);
    });
  };

  const overlayStyle = {
    backgroundColor: "rgba(175, 179, 185, 0.2)",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display:
      editToolId !== orderItem.id && edit && editToolId !== -1 ? "block" : "",
    borderRadius: "5px",
  };

  return (
    <div
      className={
        "cardItem" +
        (selectedShape === orderItem.id && editToolId === -1 ? " active" : "") +
        (editToolId === orderItem.id
          ? " active"
          : edit && editToolId >= 0
          ? " not-active"
          : "")
      }
      key={"defaultNets" + index}
      ref={setNodeRef}
      style={
        isDragging
          ? {
              cursor: "grabbing",
              position: "relative",
              ...style,
            }
          : {
              cursor: "grab",
              position: "relative",
              ...style,
            }
      }
      {...attributes}
      {...listeners}
      onMouseEnter={() => {
        setIsHovered(item.id);
      }}
      onMouseLeave={() => setIsHovered(0)}
    >
      {isDragging ? (
        <div style={{ background: "rgba(255, 255, 255, 0.3)", height: "98px" }}>
          &nbsp;
        </div>
      ) : (
        <>
          {isHovered === item.id && !inProgress && (
            <div
              className="d-flex flex-column justify-content-center"
              style={
                rightToLeft
                  ? { position: "absolute", top: "20%", right: "92%" }
                  : { position: "absolute", top: "20%", left: "92%" }
              }
            >
              <div
                className="card-hover-icons delete-Icon mb-2"
                onClick={deleteItem}
                onMouseEnter={() => setHoveredIcon(true)}
                onMouseLeave={() => setHoveredIcon(false)}
              >
                <img src={deleteIcon} alt="delete Icon" />
              </div>
              <div
                className="card-hover-icons duplicate-Icon"
                onClick={copyItem}
                onMouseEnter={() => setHoveredIcon(true)}
                onMouseLeave={() => setHoveredIcon(false)}
              >
                <img src={duplicateIcon} alt="duplicate Icon" />
              </div>
            </div>
          )}
          {editToolId !== orderItem.id && edit && editToolId !== -1 && (
            <div style={overlayStyle}></div>
          )}
          <div
            className={
              "d-flex flex-column " +
              (editModeChecks ? "normal-color" : "not-active-color")
            }
            onClick={() => {
              if (selectedShape !== orderItem.id && editToolId === -1) {
                setEditTool(true);
                setSelectedTool(orderItem);
                setSelectedShape(orderItem.id);
                setErrorsText([]);
              }
            }}
          >
            {/* X/Y Size */}
            <div className="d-flex justify-content-center border__bottom">
              <p className="card__text">
                {item.x_length}/{item.y_length}
              </p>
            </div>
            {/* Two types of cards based on the standard/special */}
            {item.x_gap === item.y_gap &&
            item.x_diameter.id === item.y_diameter.id ? (
              <div className="d-flex flex-column justify-content-center">
                {/* first row gap */}
                <div className="standard__pad d-flex justify-content-center align-items-center border__bottom">
                  <div className={rightToLeft ? "ml-2" : "mr-2"}>
                    <img
                      src={editModeChecks ? emtpySet : emptySetGrey}
                      alt="empty set icon"
                    />
                  </div>
                  <p>{item.x_diameter.steel_diameter}</p>
                </div>
                {/* second row diameter */}
                <div className="standard__pad d-flex justify-content-center align-items-center">
                  <div className={rightToLeft ? "ml-2" : "mr-2"}>
                    <img
                      src={editModeChecks ? email : emailGrey}
                      alt="email icon"
                    />
                  </div>
                  <p>{item.x_gap}</p>
                </div>
              </div>
            ) : (
              <div className="d-flex flex-column justify-content-center">
                {/* first row */}
                <div className="d-flex justify-content-center align-items-center border__bottom">
                  {/* gap */}
                  <div className="special__pad d-flex justify-content-center align-items-center">
                    <div className={rightToLeft ? "ml-2" : "mr-2"}>
                      <img
                        src={editModeChecks ? emtpySet : emptySetGrey}
                        alt="empty set icon"
                      />
                    </div>
                    <p>{item.x_diameter.steel_diameter}</p>
                  </div>
                  {/* diameter */}
                  <div className="special__pad d-flex justify-content-center align-items-center">
                    <div className={rightToLeft ? "ml-2" : "mr-2"}>
                      <img
                        src={editModeChecks ? email : emailGrey}
                        alt="email icon"
                      />
                    </div>
                    <p>{item.x_gap}</p>
                  </div>
                </div>
                {/* second row */}
                <div className="d-flex justify-content-center align-items-center">
                  {/* gap */}
                  <div className="special__pad d-flex justify-content-center align-items-center">
                    <div className={rightToLeft ? "ml-2" : "mr-2"}>
                      <img
                        src={editModeChecks ? emtpySet : emptySetGrey}
                        alt="empty set icon"
                      />
                    </div>
                    <p>{item.y_diameter.steel_diameter}</p>
                  </div>
                  {/* diameter */}
                  <div className="special__pad d-flex justify-content-center align-items-center">
                    <div className={rightToLeft ? "ml-2" : "mr-2"}>
                      <img
                        src={editModeChecks ? email : emailGrey}
                        alt="email icon"
                      />
                    </div>
                    <p>{item.y_gap}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default NetsCard;
