import { Modal, Button } from "react-bootstrap";
import React, { useState } from "react";
import classNames from "classnames";
import { forgetPasswordCall, login } from "Services/user";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { fetchUser, getProfileImg } from "Redux/middlwares/user";
import { getCurrentOrderRebars, getCurrentOrderNets, getCurrentOrderPiles } from "Redux/middlwares/order";
import { useTranslation } from "react-i18next";
import close from "../../assets/img/new__user__form/close.png";
import brandLogo from "../../assets/img/logo.svg";
import { useHistory } from "react-router-dom";
import { availablelangauges } from "Constants/general";
import { setRightToLeft } from "Redux/features/root";

import useRefEffect from "../../utils/hooks/use-RefEffect.js";

function Login(props: any) {
  const router = useHistory();
  const dispatch = useAppDispatch();
  
  const reqStatus = useAppSelector((state) => state.order.reqStatus);
  const reqOrder = useAppSelector((state) => state.order.orderId);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [forgetPassword, setForgetPassword] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const { i18n, t } = useTranslation(["common", "myOrder", "header", "warning", "newPassword"]);
  const [error, setError] = useState("");

  const emailBoxRef = useRefEffect({}); //custom-Hook

  const goToNewOrderPage = () => {
    if (reqStatus && reqOrder > 0) {
      router.push("/my-order");
    } else {
      router.push("/new-order");
    }
  };

  const onSubmitHandler = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoader(true);
    if (forgetPassword) {
      forgetPasswordCall({
        email: email.toLowerCase(),
      })
        .then((res) => {
          if (res.data.status === "OK") {
            setEmailSent(true);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoader(false));
    } else {
      login({
        email: email.toLowerCase(),
        password,
      })
        .then(() => {
          dispatch(
            fetchUser((exist:boolean, userDT: any) => {
              if (exist) {
                props.onHide();
                if (
                  (userDT as any).preferred_language === availablelangauges.ENGLISH
                ) {
                  i18n.changeLanguage("en");
                  document.body.setAttribute("dir", "ltl");
                  document.body.setAttribute("style", "text-align: left");
                  dispatch(setRightToLeft(false));
                } else if (
                  (userDT as any).preferred_language === availablelangauges.HEBREW
                ) {
                  i18n.changeLanguage("he");
                  document.body.setAttribute("dir", "rtl");
                  document.body.setAttribute("style", "text-align: right");
                  require("../../styles/right-to-left.scss");
                  dispatch(setRightToLeft(true));
                }
                // dispatch(syncLocalTools());
                dispatch(getCurrentOrderRebars(false));
                dispatch(getCurrentOrderNets(false));
                dispatch(getCurrentOrderPiles(false));
                if (userDT.profile_picture) {
                  dispatch(getProfileImg(userDT.profile_picture));
                }
                goToNewOrderPage();
              } else {
                setError(t("error login", { ns: "warning" }));
              }
            })
          );
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setForgetPassword(false);
          setLoader(false);
        });
    }
  };

  const forgotPassword = () => {
    setForgetPassword(true);
    setError("");
  };

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={!props.showCloseButton ? "static" : true}
      >
        <section className="forms">
          <div className="container">
            <div className="row py-5 mx-auto">
              <div className="form__header col-sm-12 d-flex justify-content-between login__modal__header">
                <div>
                  <img
                    className="brand__icon"
                    src={brandLogo}
                    alt="brand icon"
                  />
                  <p className="text-white text-uppercase mb-0 modal-title">
                    {forgetPassword
                      ? t("forget pass", { ns: "common" })
                      : emailSent
                      ? t("email sent", { ns: "common" })
                      : t("welcome", { ns: "common" })}
                  </p>
                </div>
                {props.showCloseButton && (
                  <span
                    className="close__btn"
                    onClick={() => {
                      props.onHide();
                      setForgetPassword(false);
                    }}
                  >
                    <img className="w-100" src={close} alt="close icon" />
                  </span>
                )}
              </div>
              {emailSent ? (
                <div className="col-sm-12 bg-white p-5">
                  <p className="pb-3">
                    {t("email send", { ns: "common" })} {email}.{" "}
                    {t("email check", { ns: "common" })}.
                  </p>
                  <Button
                    variant="form"
                    className="w-xs-100"
                    onClick={() => {
                      // props.onHide();
                      setForgetPassword(false);
                      setEmailSent(false);
                    }}
                  >
                    {t("ok", { ns: "common" })}
                  </Button>
                </div>
              ) : (
                <div className="col-sm-12 form__body py-4">
                  <div className="form__title pb-md-3 pb-2">
                    <h3 className={"mb-0 text-white " + (forgetPassword ? "" : "text-uppercase")}>
                    {forgetPassword ? t("forget pass instruction", { ns: "newPassword" }) : t("user details", { ns: "common" })}
                    </h3>
                    {/* <span className="text-capitalize">{t('required fields', {ns: 'newOrder'})}*</span> */}
                  </div>
                  <form onSubmit={onSubmitHandler}>
                    <div className="inputs pb-4">
                      <div className="form-group">
                        <label
                          htmlFor="email"
                          className="text-uppercase text-white"
                        >
                          {t("Email", { ns: "common" })}
                        </label>
                        <input
                          type="email"
                          className={"form-control " + (error ? "invalid" : "")}
                          id="email"
                          aria-describedby="emailHelp"
                          placeholder={t("type email", { ns: "common" })}
                          value={email || ""}
                          onChange={(e) => setEmail(e.target.value)}
                          ref={emailBoxRef}
                        />
                      </div>
                      {!forgetPassword && (
                        <>
                          <div className="form-group ">
                            <label
                              htmlFor="password"
                              className="text-uppercase text-white"
                            >
                              {t("Password", { ns: "common" })}
                            </label>
                            <input
                              type="password"
                              className={
                                "form-control " + (error ? "invalid" : "")
                              }
                              id="password"
                              aria-describedby="passwordHelp"
                              placeholder={t("type password", { ns: "common" })}
                              value={password || ""}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                          <div className="d-flex flex-row">
                            <p
                              className="forgot__password__text"
                              onClick={forgotPassword}
                            >
                              {t("forget pass", { ns: "common" })}
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                    {error && (
                      <div className="error__message mt-0 text-center">
                        {error}
                      </div>
                    )}
                    <div
                      className={classNames("btn__wrap py-2 pt-4 d-flex", {
                        "justify-content-between": props.showCloseButton,
                        "justify-content-center": !props.showCloseButton,
                      })}
                    >
                      {props.showCloseButton && (
                        <Button
                          variant="form"
                          onClick={() => {
                            props.onHide();
                            setForgetPassword(false);
                          }}
                        >
                          {t("cancel", { ns: "common" })}
                        </Button>
                      )}
                      <button
                        type="submit"
                        className={classNames("btn form__btn__black", {
                          form__btn__green: !props.showCloseButton,
                        })}
                        disabled={
                          email === "" ||
                          (!forgetPassword && password === "") ||
                          loader
                        }
                      >
                        {loader ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : forgetPassword ? (
                          t("submit", { ns: "common" })
                        ) : (
                          t("login", { ns: "header" })
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
}

export default Login;
