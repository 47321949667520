import React from "react";
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import diameter from "../../../../assets/img/ticket__create/diameter_icon.svg";
import gap from "../../../../assets/img/ticket__create/gap_icon.svg";
import { commaSeparated } from "Constants/general";
import { format } from "date-fns";

import "./index.scss";

const A4TicketCardStandardNets = ({ ...props }) => {
  const {
    net,
    orderNo,
    index,
    ticketIndex,
    printTotalCount,
    printCurrentCount,
  } = props;
  const steelTypes = useAppSelector((state) => state.steelType.steelTypes);
  const currentOrderRebars = useAppSelector(
    (state) => state.supplier.completeOrderDetails
  );

  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const { t } = useTranslation([
    "ticketCreator",
    "newOrder",
    "common",
    "measurements",
    "tool",
  ]);

  return (
    <div className="a4-card">
      <div className="top-left-circle"></div>
      <div className="sketch-box" style={{ paddingTop: "120px" }}>
        <p className="net_type_standard">
          {t("standard net", { ns: "ticketCreator" })}
        </p>
        {net.tool.name && (
          <p className="net_nickname_standard">
            {t("nickname", { ns: "ticketCreator" }) + ": " + net.tool.name}
          </p>
        )}
        <img
          src={net.tool.preview_image.file_field.replace(
            "https://easybar-s3.s3.amazonaws.com",
            "https://app.easybar.co.il"
          )}
          alt="rebar_sketch"
        />
      </div>
      <div className="info-section">
        <p
          className={
            "sketch-Note ticket-border__bottom " +
            (rightToLeft ? "info-pr" : "info-pl")
          }
          style={net.production_notes === "" ? { paddingBottom: "30px" } : {}}
        >
          {net.production_notes &&
            t("Note", { ns: "ticketCreator" }) + " : " + net.production_notes}
        </p>
        <div
          className={
            "rebar-detail ticket-border__bottom " +
            (rightToLeft ? "info-pr" : "info-pl")
          }
        >
          <p
            className="detail-title"
            style={
              rightToLeft ? { paddingLeft: "52px" } : { paddingRight: "12px" }
            }
          >
            {t("customer", { ns: "ticketCreator" })}
          </p>
          <p className="detail-sub">{currentOrderRebars.customer_name}</p>
        </div>
        <div
          className={
            "rebar-detail ticket-border__bottom " +
            (rightToLeft ? "info-pr" : "info-pl")
          }
        >
          <p
            className="detail-title"
            style={
              rightToLeft ? { paddingLeft: "12px" } : { paddingRight: "25px" }
            }
          >
            {t("delivery", { ns: "ticketCreator" })}
          </p>
          <p className="detail-sub">
            {format(new Date(currentOrderRebars.delivery_date), "dd/MM/yyy")}
          </p>
        </div>
        <div
          className={
            "rebar-detail ticket-border__bottom " +
            (rightToLeft ? "info-pr" : "info-pl")
          }
        >
          <p
            className="detail-title"
            style={
              rightToLeft ? { paddingLeft: "85px" } : { paddingRight: "60px" }
            }
          >
            {t("site", { ns: "ticketCreator" })}
          </p>
          <p className="detail-sub">{currentOrderRebars.site_address}</p>
        </div>
        <div className="weight-section ticket-border__bottom">
          <p>
            {t("item weight", { ns: "ticketCreator" })}
            {net.tool.unit_weight.toString().replace(commaSeparated, ",")}
          </p>
          <p>
            {t("total weight", { ns: "ticketCreator" })}
            {(net.tool.unit_weight * net.quantity)
              .toFixed(2)
              .toString()
              .replace(commaSeparated, ",")}
          </p>
        </div>
      </div>

      <div className="types-section">
        <div className="detail-box">
          <p>{t("diameter", { ns: "tool" })}</p>
          <div className="info-box" style={{ width: "100px" }}>
            <img
              src={diameter}
              alt="diameter icon"
              style={
                rightToLeft
                  ? { marginLeft: "10px", paddingBottom: "2px" }
                  : { marginRight: "10px", paddingBottom: "2px" }
              }
            />
            <p>{net.tool.x_diameter.steel_diameter}</p>
          </div>
        </div>
        <div className="detail-box">
          <p>{t("gap", { ns: "ticketCreator" })}</p>
          <div className="info-box" style={{ width: "100px" }}>
            <img
              src={gap}
              alt="gap icon"
              style={
                rightToLeft
                  ? { marginRight: "0px", paddingBottom: "2px" }
                  : { marginRight: "5px", paddingBottom: "2px" }
              }
            />{" "}
            <p>{net.tool.x_gap}</p>
          </div>
        </div>

        <div className="detail-box">
          <p>{t("type", { ns: "ticketCreator" })}</p>
          <div className="info-box" style={{ width: "54px" }}>
            <p>
              {steelTypes.length > 0 &&
                steelTypes.find((item) => item.id === net.tool.steel_type) &&
                steelTypes.find((item) => item.id === net.tool.steel_type)
                  .name[0]}
            </p>
          </div>
        </div>

        <div className="detail-box">
          <p>{t("amount", { ns: "ticketCreator" })}</p>
          <div className="info-box" style={{ width: "100px" }}>
            <p>{net.quantity.toString().replace(commaSeparated, ",")}</p>
          </div>
        </div>
      </div>

      <div className="footer-section">
        {rightToLeft ? (
          <div className="order-no">
            <p className="ticket_print_count_txt">
              ({printCurrentCount}/{printTotalCount})
            </p>
            <p>{`${ticketIndex} / ${orderNo}`}</p>
          </div>
        ) : (
          <div className="order-no">
            <p>{`${orderNo} / ${ticketIndex}`}</p>
            <p className="ticket_print_count_txt">
              ({printCurrentCount}/{printTotalCount})
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default A4TicketCardStandardNets;
