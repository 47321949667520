import {useState,useRef, useEffect} from 'react';
import "./index.scss";
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import info from "../../../assets/img/ticket__create/info.svg";
import submit_order_no from "../../../assets/img/ticket__create/submit_order_no.svg";
import submit_order_no_2 from "../../../assets/img/ticket__create/submit_order_no_2.svg";
import reload_order_no from "../../../assets/img/ticket__create/reload_order_no.svg";
import orderNo_details from "../../../assets/img/ticket__create/orderNo-details.png";
import { format, addDays} from "date-fns";
import { fetchTicketOrder } from "Redux/middlwares/suppliers";
import { useTranslation } from "react-i18next";
import { Overlay, Tooltip } from "react-bootstrap";
import { PACKAGE, WHATSAPP_LINK, EASYBAR_LINK } from "Constants/ticket-creator";
import error from "../../../assets/img/ticket__create/error.svg";
import { setCurrentOrderNets } from 'Redux/features/net';
import { useHistory, useLocation } from "react-router-dom";

import Loader from 'Components/common-components/custom-loader/loader';

const CreatorDetails = ({...props}) => {
  const {setOrder, supplierStatus, inReview,packageExpire,trialExpire,noData} = props;

  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["ticketCreator", "common"]);

  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const currentOrderRebars = useAppSelector(
    (state) => state.supplier.ticketOrderDetails
  );
  const currentOrderNets = useAppSelector(
    (state) => state.supplier.ticketOrderNets
  );
  const currentOrderPiles = useAppSelector(
    (state) => state.supplier.ticketOrderPiles
  );

  const [pressed, setPressed] = useState(false);
  const [orderNo, setOrderNo] = useState('');
  const [showSubmitTooltip, setShowSubmitTooltip] = useState(false);
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  const [isOrderFound, setIsOrderFound] = useState(false);
  const targetSubmit = useRef(null);
  const targetInfo = useRef(null);
  const [barLoader, setBarLoader] = useState(false);
  
  useEffect(() => {
    if ((!inReview && !packageExpire && !trialExpire && !noData)) {
      const queryParams = new URLSearchParams(location.search);
      const searchTerm = queryParams.get("order_no");
      if (searchTerm) {
        setOrderNo(searchTerm);
        setBarLoader(true);
        dispatch(fetchTicketOrder(searchTerm)).then((res) => {
          setBarLoader(false);
          if (res.data.results.length > 0) {
            setIsOrderFound(false);
          } else {
            setIsOrderFound(true);
          }
        });
        setOrder(searchTerm);
        setPressed(true);
      }
    } else {
      setBarLoader(false);
      setOrder("");
      setOrderNo("");
      setPressed(false);
    }
  }, [inReview, packageExpire, trialExpire, noData]);

  const handleRemoveQueryParams = () => {
    const { pathname } = location;
    history.replace(pathname);
  };

  const handleClick = (e) => {
    e.preventDefault();
    if(orderNo !== ""){
      setBarLoader(true)
      handleRemoveQueryParams();
      dispatch(fetchTicketOrder(orderNo))
        .then((res) => {
          setBarLoader(false)
          if(res.data.results.length > 0){
            setIsOrderFound(false);
          }
          else{
            setIsOrderFound(true);
          }
        });
      setOrder(orderNo);
      setPressed(true);
    }
  }

  const getEndDate = (days, approved_date)=> { 
    const endDate = addDays(new Date(approved_date), days)
    return format(new Date(endDate), 'd/M/yyy')
  }

  return (
    <>
      <div className="background-t" style={(currentOrderRebars.length === 0 && currentOrderNets.length === 0 && currentOrderPiles.length === 0) ? {marginBottom: "350px"} : {}}>
        <div className="container" style={{position : "relative"}}>
        <div className="row align-items-center justify-content-between">
          <div className={"col-md-6 " + (rightToLeft ? "pr-0" : "pl-0")}>
            <h3 className={ (rightToLeft ? "ticket-creator_text" : "ticket-creator_text_heebo") }>
              {t("ticket_creator", { ns: "ticketCreator" })}
            </h3>
            <div className="d-flex align-items-center no-gutters">
              <div className="col-auto">
                <p className="label-orderno">
                  {" "}
                  {t("order_no", { ns: "ticketCreator" })}
                </p>
              </div>
              <div 
                  ref={targetInfo}
                  onMouseEnter={() => setShowInfoTooltip(true)}
                  onMouseLeave={() => setShowInfoTooltip(false)}
                  className="col-auto info_icon"
              >
                <img src={info} alt="i" />
              </div>
              <Overlay
                  target={targetInfo.current}
                  show={showInfoTooltip}
                  placement="top"
                >
                  {(props) => (
                    <Tooltip id="overlay-example-4" {...props}>
                      <img src={orderNo_details} alt="orderNo Info" style={{width: "180px"}} />
                      <p className="orderNo_detials_text">{t("orderNo details", { ns: "ticketCreator" })}</p>
                    </Tooltip>
                  )}
                </Overlay>
              <form onSubmit={handleClick} className="p-0 m-0 creator_form">
                <div
                  className={
                    "col-auto input_order_no " + (rightToLeft ? "ml-2" : "mr-2")
                  }
                >
                  <input
                    type="text"
                    value={orderNo}
                    className={ isOrderFound ? "order_no_input_warning" : "order_no_input"}
                    onChange={({ target }) => {
                      setOrderNo(target.value);
                    }}
                    placeholder={t("input_placeholder", {
                      ns: "ticketCreator",
                    })}
                  />
                </div>
                <button type="submit" className="col-auto submit_order mt-2">
                  <div
                    ref={targetSubmit}
                    onMouseEnter={() => setShowSubmitTooltip(true)}
                    onMouseLeave={() => setShowSubmitTooltip(false)}
                  >
                    {pressed ? (
                      <img src={reload_order_no} alt=">" />
                    ) : (
                      <img
                        src={rightToLeft ? submit_order_no_2 : submit_order_no}
                        alt=">"
                      />
                    )}
                  </div>
                </button>
                <Overlay
                  target={targetSubmit.current}
                  show={showSubmitTooltip}
                  placement="top"
                >
                  {(props) => (
                    <Tooltip id="overlay-example-4" {...props}>
                      {t("Load an order", { ns: "ticketCreator" })}
                    </Tooltip>
                  )}
                </Overlay>
              </form>
            </div>
            {
              isOrderFound && (
                <div className={"error_404_container"}>
                  <div className="row align-items-center">
                    <div className="col-1">
                      <img src={error} alt="404" />
                    </div>
                    <div className={"col align-self-end " + (rightToLeft ? "pl-1"  : "pr-1")}>
                      <p className="error-textt">
                        {t("404 error", { ns: "ticketCreator" })}
                        <a href={WHATSAPP_LINK} className="error_linkk" rel="noreferrer" target="_blank">
                          {t("404 error link", { ns: "ticketCreator" })}  
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              )
            }
            
          </div>

          <div className={"col-md-5 mt-3"}>
            <div className="py-4 creator_info_text">
              {
                (rightToLeft) ? (
                  <p className='px-3'>
                    <strong>רוצה לחסוך זמן וכסף?</strong>
                    <br />
                      &#x2022; שלח את הקישור ללקוחות <span>
                          <a href={EASYBAR_LINK} className="underlined" rel="noreferrer" target="_blank">
                          www.easybar.co.il
                          </a>
                        </span>
                    <br />
                      &#x2022; הלקוח יקליד את ההזמנות בשבילך
                    <br />
                      &#x2022; אתה תקבל מספר הזמנה ותוכל להדפיס את הכרטיסיות
                    <br />
                      <span style={{}}> 
                        עדיין צריכים עזרה? אנחנו זמינים 
                        <a href={WHATSAPP_LINK} className="underlined whatsapp-link" rel="noreferrer" target="_blank">
                            בוואטסאפ  
                            </a>
                      </span>
                  </p>
                )  : (
                  <p className='px-3'>
                      Do you want to get order number and convert it to ticket
                      without typing it again?  send this link to your customers >
                      <a href={EASYBAR_LINK} className="underlined" rel="noreferrer" target="_blank">
                        www.easybar.co.il
                      </a> 
                      &nbsp;and you will be able to enjoy readable orders,
                      without  unnecessary questions.
                      <br />
                      Need help? we are available on this&nbsp;
                      <a href={WHATSAPP_LINK} className="underlined" rel="noreferrer" target="_blank">
                          Whatsapp  
                      </a>
                  </p>
                )
              }
            
              <p className="px-3 py-2">
                <span className="ticket_status">
                  {t("status", { ns: "ticketCreator" })}
                </span>


                {(supplierStatus.package === PACKAGE.FREE_TRIAL) && (
                  <>
                    <span
                      className={
                        "highlighted_txt px-2 " + (rightToLeft ? "mr-3" : "ml-3")}>
                          {t("free_trial_status_text", { ns: "ticketCreator" })}
                          {getEndDate(supplierStatus.free_trail_days, supplierStatus.account_approved_at)}

                    </span>

                    <a href="https://www.easybar.co.il/suppliers" rel="noreferrer" target="_blank" className={"underlined " + (rightToLeft ? "pr-2":"pl-2")}>
                      {t("free_trial_link", { ns: "ticketCreator" })}
                    </a>  
                    
                  </>
                )}


                { 
                (supplierStatus.package === PACKAGE.LIMITED) && (
                  <>
                    <span
                      className={
                        "highlighted_txt_blue px-1 " + (rightToLeft ? "mr-3" : "ml-3")}>
                          {t("limited_status_text_heb", { ns: "ticketCreator" })}
                          {supplierStatus.printing_limit}
                          {t("limited_status_text", { ns: "ticketCreator" })}
                    </span>

                    <span className={"ticket_status " + (rightToLeft ? "pr-2":"pl-2")}>
                      {t("used", {ns: "ticketCreator"})}
                    </span>

                    <span className='px-2'>
                      {(rightToLeft ?
                        <>
                          <span>
                            {`${supplierStatus.printing_limit} / `}
                          </span>
                          <span className="ticket_status">
                            {`${supplierStatus.printing_count}`}
                          </span>
                        </>
                        :
                        <>
                        <span className="ticket_status">
                          {`${supplierStatus.printing_count}`}
                        </span>
                        <span>
                          {` / ${supplierStatus.printing_limit}`}
                        </span>
                        </>
                        
                      )}
                    </span>

                    <a href="https://www.easybar.co.il/suppliers" rel="noreferrer" target="_blank" className={"underlined"}>
                      {t("limited_link", { ns: "ticketCreator" })}
                    </a>  
                  </>
                )}


                {(supplierStatus.package === PACKAGE.UNLIMITED) && (
                  <>
                    <span
                      className={
                        "highlighted_txt_blue px-1 " + (rightToLeft ? "mr-3" : "ml-3")}>
                          {t("unlimited_Status_text", { ns: "ticketCreator" })}
                    </span>
                  
                    <a href="https://www.easybar.co.il/suppliers" rel="noreferrer" target="_blank" className={"underlined " + (rightToLeft ? "pr-2":"pl-2")}>
                      {t("unlimited_link", { ns: "ticketCreator" })}
                    </a> 
                  </>
                  
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style={{paddingTop: "15px"}}>
        {barLoader && <Loader /> }
      </div>
    </div>
  </>
  );
}

export default CreatorDetails;