export const FooterHe = {

    'support' : 'תמיכה',
    'question' : 'שאלות ותשובות',
    'support sytem' : 'מערכת תמיכה',
    'contact us' : 'צור קשר',
    'partners' : 'שותפים',
    'our supplyers' : 'הספקים שלנו',
    'add as supplier' : 'להוסיף את עצמך כספק',
    'talk to us' : 'דברו איתנו'
}