import {useState} from 'react';
import { Modal } from 'react-bootstrap';
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import cross from "../../../../assets/img/ticket__create/cross.svg"
import backward from "../../../../assets/img/ticket__create/forward.svg"

import "../index.scss"

function WebsiteLink({ ...props }) {
  const {
    show,
    onHide,
    nextpopup,
    prevpopup,
    close,
    supplierlink,
    setsupplierlink,
    addSupplier,
    supplierDetails,
  } = props

  const [websiteLink, setWebsiteLink] = useState(supplierlink)
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const { t } = useTranslation(['ticketCreator']);

  const handleBackward = () => {
    close()
    prevpopup()
    setsupplierlink(websiteLink)
  }

  const handleExit = () => {
    onHide()
  }

  const handleNext = async () =>{
    setsupplierlink(websiteLink);
    await addSupplier({
      user: supplierDetails.user,
      name: supplierDetails.name,
      link: websiteLink,
    })
    close()
    nextpopup()
  }

  return (
    <>
      <Modal
        show = {show}
        onHide = {onHide}
        className="supplier_popups"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <>
          <div className="container popup_container">
            <div className="popup_content">
            <div className="row popup_header justify-content-between px-4">
                <div className={"col-1 popup_icon " + (rightToLeft ? "order-1" : "")} onClick={handleExit}>
                  <img src={cross} alt="X" />
                </div>
                <div className="col-1 popup_icon" onClick={handleBackward}>
                  <img src={backward} alt=">" />
                </div>
              </div>
              <p className="supplier_name pt-2 pb-3">
              {t("add_Link_1", { ns: "ticketCreator" })}
              <br />
              {t("add_Link_2", { ns: "ticketCreator" })}
              </p>
              <input
                  type="text"
                  value={websiteLink}
                  className ="supplier_name_input"
                  onChange={({ target }) => {
                    setWebsiteLink(target.value);
                  }}
                  placeholder={t("paste_link", { ns: "ticketCreator" })}
                />
								<br></br>
								<button className="popup_button_small my-4" onClick={handleNext}>
                  {t("next", { ns: "ticketCreator" })}
                </button>
                <a href='https://www.easybar.co.il/suppliers' target="_blank" rel="noreferrer">
                  <p className="popup_details pb-5">
                    {t("more_details", { ns: "ticketCreator" })}
                  </p>
                </a>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}

export default WebsiteLink;
