import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import close from "../../../../assets/img/close.svg";
import Gmail from "../../../../assets/img/share__options/gmail.svg";
import Whatsapp from "../../../../assets/img/share__options/whatsapp.svg";

function ShareOptionsModal({ ...props }: any) {
  const {
    setSendByEmailShow,
    onHide,
    documentUrl,
  } = props;
  const { t } = useTranslation(["common", "warning", "emailOrder"]);

  const EmailModalShow = () => {
    onHide("emailModal");
    setSendByEmailShow(true);
  };

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <section
          className="d-flex flex-column p-3 rounded custom__modal"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="pb-3">
            <img
              src={close}
              alt="close icon"
              style={{ cursor: "pointer" }}
              onClick={() => {
                onHide();
              }}
            />
          </div>
          <div className="custom__child">
            <div className="delete__title pb-md-4 pb-2">
              <p className="mb-0">{t("share options", { ns: "warning" })}</p>
            </div>
            <div
              className="
                                        pt-2
                                        pb-4
                                        custom__btn_wrapper
                                    "
            >
              <a
                href={`https://wa.me/?text=${t("link text", {ns: "emailOrder"}) + documentUrl}`}
                onClick={() => {
                  onHide();
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={Whatsapp}
                  alt="whatsapp Icon"
                  style={{ cursor: "pointer" }}
                />
              </a>
              <img
                src={Gmail}
                alt="gmail Icon"
                onClick={EmailModalShow}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
}

export default ShareOptionsModal;
