import SectionHeader from "../section-header/sectionHeader";
import { availablelangauges } from 'Constants/general';
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { patchUser } from "Redux/middlwares/user";
import { useTranslation } from "react-i18next";

const Language = () => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(state => state.user.userData);
  const { t } = useTranslation(['common', 'userProfile']);

  const changeLanguage = async (e: any) => {
    await dispatch(patchUser(userData.id, {preferred_language: parseInt(e.target.value)}));
    window.location.reload();
  }

  return (
    <>
      <section style={{ background: "white", marginBottom: "200px" }}>
        <SectionHeader heading={t('Language', {ns: 'userProfile'})} icon="globe" />
        <div style={{ padding: "50px" }}>
          <div style={{ display: "flex", gap: 20 }}>
            <label>{t('Choose Language', {ns: 'userProfile'})}</label>

            <select style={{ width: "250px", fontSize: "18px" }}
            value={userData.preferred_language}
            onChange={changeLanguage}
            >
              <option value={availablelangauges.ENGLISH}>English</option>
              <option value={availablelangauges.HEBREW}>עברית</option>
            </select>
          </div>
        </div>
      </section>
    </>
  );
};

export default Language;
