import React, { useEffect, useState } from "react";
import { Button, Card, Form, Table } from "react-bootstrap";
import { ReactComponent as DNDlogo } from "../../assets/img/admin/dnd-logo.svg";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import AdminLayout from "../../layouts/admin";
import {
  fetchAdminSteelDiameters,
  fetchSteelTypes,
  removeSteelType,
  updateSteelTypeName,
} from "Redux/middlwares/steelType";
import {
  addSteelType,
  removeDiameterRow,
  updateAdminSteelWeight,
} from "Redux/features/steelType";
import {
  createSteelDiameter,
  createSteelType,
  deleteSteelDiameter,
  updateSteelDiameterWeight,
} from "Services/steelType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AdminSteelTypes = () => {
  const userData = useAppSelector((state) => state.user.userData);
  const steelTypes = useAppSelector((state) => state.steelType.steelTypes);
  const adminDiameters = useAppSelector(
    (state) => state.steelType.adminDiameters
  );
  const dispatch = useAppDispatch();
  const [steelEdit, setSteelEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [newSteelType, setNewSteelType] = useState(false);
  const [steelTypeName, setSteelTypeName] = useState("");
  const [newDiameter, setNewDiameter] = useState(false);
  const [newDiameterVal, setNewDiameterVal] = useState(0);
  const [editSteels, setEditSteels] = useState([]);
  const [editableSteelTypes, setEditableSteelTypes] = useState([] as any);

  const handleChangeInputValue = (steelObj: any, val: any) => {
    dispatch(updateAdminSteelWeight(val));
    const copyOfEditSteels = [...(editSteels as any)];
    const currentObject = editSteels.find(
      (item: any) => item.id === steelObj.id
    );
    if (currentObject) {
      const index = editSteels.indexOf(currentObject);
      copyOfEditSteels[index] = {
        ...copyOfEditSteels[index],
        weight: val.val,
      };
    } else {
      copyOfEditSteels.push({
        ...steelObj,
        weight: val.val,
      });
    }
    setEditSteels(copyOfEditSteels as any);
  };

  const handleAddNewSteelType = (e: React.SyntheticEvent) => {
    e.preventDefault();
    createSteelType({
      name: steelTypeName,
    }).then((res) => {
      dispatch(addSteelType(res.data));
      dispatch(fetchAdminSteelDiameters(res.data.id));
      setNewSteelType(false);
      setSteelTypeName("");
    });
  };

  const handleAddNewSteelDiameter = (e: React.SyntheticEvent) => {
    e.preventDefault();
    createSteelDiameter({
      diameter: newDiameterVal,
    }).then((res) => {
      steelTypes.forEach((item) => dispatch(fetchAdminSteelDiameters(item.id)));
      setNewDiameter(false);
      setNewDiameterVal(0);
    });
  };

  const handleSaveSteelWeights = () => {
    setLoader(true);
    const copyOfEditSteels = [...(editSteels as any)];
    const promises = [];
    for (let i = 0; i < copyOfEditSteels.length; i++) {
      promises.push(
        updateSteelDiameterWeight(copyOfEditSteels[i].id, {
          weight: copyOfEditSteels[i].weight,
        })
      );
    }
    Promise.all(promises)
      .then(() => {
        steelTypes.forEach((item) =>
          dispatch(fetchAdminSteelDiameters(item.id))
        );
        setSteelEdit(false);
      })
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    steelTypes.length === 0 && dispatch(fetchSteelTypes());
    steelTypes.length > 0 && setEditableSteelTypes((JSON.parse(JSON.stringify(steelTypes)) as any));
  }, [steelTypes.length, dispatch, steelTypes]);

  useEffect(() => {
    if (userData && steelTypes.length > 0) {
      steelTypes.forEach((item) => dispatch(fetchAdminSteelDiameters(item.id)));
    }
  }, [userData, steelTypes, dispatch]);

  const renderSteelInput = (item: any, st: any, outterIndex: number) => {
    const obj = item.weights.find(
      (stWeight: any) => stWeight.steel_type === st.id
    );
    const innerIndex = item.weights.indexOf(obj);
    return obj ? (
      <input
        type="number"
        className="input-inside"
        value={obj.weight}
        onChange={(e) =>
          handleChangeInputValue(
            { ...obj },
            {
              outterIndex,
              innerIndex,
              val: parseFloat(e.target.value),
            }
          )
        }
      />
    ) : (
      "0.000"
    );
  };

  const deleteDiameterRow = (diameterID: number) => {
    deleteSteelDiameter(diameterID).then(() => {
      dispatch(removeDiameterRow(diameterID));
    });
  };

  return (
    <AdminLayout>
      <p className="py-4">Steel Type &#38; Weights</p>
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between pb-3">
            <p className="font-weight-bold">Steel Type &#38; Weights</p>
            <div className="px-2">
              <Button
                disabled={newDiameter}
                variant="light"
                className="mr-2"
                onClick={() => setNewDiameter(true)}
              >
                add new row
              </Button>
              <Button
                disabled={newSteelType}
                variant="light"
                className="mr-2"
                onClick={() => setNewSteelType(true)}
              >
                add new steel type
              </Button>
              <input
                type="checkbox"
                id="edit-text-cage"
                className="mr-1"
                checked={steelEdit}
                onChange={() => setSteelEdit(!steelEdit)}
              />
              <label htmlFor="edit-text-cage">Edit Text</label>
              {steelEdit && (
                <>
                  <Button
                    variant="secondary"
                    className="mr-2 ml-2"
                    onClick={() => {
                      setSteelEdit(!steelEdit);
                    }}
                  >
                    cancel
                  </Button>
                  <Button
                    variant="success"
                    onClick={handleSaveSteelWeights}
                    disabled={loader}
                  >
                    save
                    {loader && (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        &nbsp;
                      </>
                    )}
                  </Button>
                </>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-end pb-3">
            {newDiameter && (
              <Form onSubmit={handleAddNewSteelDiameter}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Diameter</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter diameter"
                    value={newDiameterVal || ""}
                    onChange={(e) =>
                      setNewDiameterVal(parseFloat(e.target.value))
                    }
                  />
                </Form.Group>
                <Button
                  variant="secondary"
                  onClick={() => setNewDiameter(false)}
                >
                  cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  className="ml-2"
                  disabled={newDiameterVal === 0}
                >
                  Add
                </Button>
              </Form>
            )}
            {newSteelType && (
              <Form className="ml-3" onSubmit={handleAddNewSteelType}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Steel Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter steel type name"
                    value={steelTypeName}
                    onChange={(e) => setSteelTypeName(e.target.value)}
                  />
                </Form.Group>
                <Button
                  variant="secondary"
                  onClick={() => setNewSteelType(false)}
                >
                  cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  className="ml-2"
                  disabled={steelTypeName === ""}
                >
                  Add
                </Button>
              </Form>
            )}
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th></th>
                <th>rebar diameters</th>
                {editableSteelTypes.map((item: any, index: number) => (
                  <React.Fragment key={'steel-types'+index}>
                    {steelEdit ? (
                      <th key={"steel-name" + index}>
                        <div style={{ display: "flex" }}>
                          <input
                            type="text"
                            className="input-inside"
                            value={item.name}
                            onChange={(e) => {
                              const cpOfSteelTypes = [...editableSteelTypes];
                              cpOfSteelTypes[index] = {
                                ...cpOfSteelTypes[index],
                                name: e.target.value,
                              };
                              setEditableSteelTypes(cpOfSteelTypes);
                            }}
                          />
                          <button
                            style={{
                              border: "none",
                              background: "transparent",
                            }}
                            onClick={() => dispatch(updateSteelTypeName(item.id, {
                              name: editableSteelTypes[index].name
                            }))}
                          >
                            <FontAwesomeIcon icon="file-upload"></FontAwesomeIcon>
                          </button>
                          <button
                            style={{
                              border: "none",
                              background: "transparent",
                            }}
                            onClick={() => dispatch(removeSteelType(item.id))}
                          >
                            <FontAwesomeIcon icon="trash"></FontAwesomeIcon>
                          </button>
                        </div>
                      </th>
                    ) : (
                      <th key={"steel-name" + index}>{item.name}</th>
                    )}
                  </React.Fragment>
                ))}
                {steelEdit && <th></th>}
              </tr>
            </thead>
            <tbody>
              {adminDiameters.length > 0 &&
                adminDiameters.map((item: any, outterIndex: number) => (
                  <tr key={"steel-types-with-weights" + outterIndex}>
                    {steelEdit ? (
                      <>
                        <td>
                          <DNDlogo />
                        </td>
                        <td>{item.diameter}</td>
                        {steelTypes.map((st, index) => (
                          <td key={"steel-weight" + index}>
                            {renderSteelInput(item, st, outterIndex)}
                          </td>
                        ))}
                        <td>
                          <button
                            style={{
                              border: "none",
                              background: "transparent",
                            }}
                            onClick={() => deleteDiameterRow(item.id)}
                          >
                            &#9587;
                          </button>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          <DNDlogo />
                        </td>
                        <td>{item.diameter}</td>
                        {steelTypes.map((st, index) => (
                          <td key={"steel-weight" + index}>
                            {item.weights.find(
                              (stWeight: any) => stWeight.steel_type === st.id
                            )
                              ? item.weights.find(
                                  (stWeight: any) =>
                                    stWeight.steel_type === st.id
                                ).weight
                              : "0.000"}
                          </td>
                        ))}
                      </>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </AdminLayout>
  );
};

export default AdminSteelTypes;
