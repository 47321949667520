import React from "react";
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import steel from "../../../../assets/img/ticket__create/black_steel.svg";
import diameter_b from "../../../../assets/img/ticket__create/black_diameter.svg";
import gap_b from "../../../../assets/img/ticket__create/black_gap.svg";
import { commaSeparated } from "Constants/general";
import { format } from "date-fns";

import "./index.scss";

const A4TicketCardSpecialNets = ({ ...props }) => {
  const {
    net,
    orderNo,
    index,
    ticketIndex,
    printTotalCount,
    printCurrentCount,
  } = props;
  const currentOrderRebars = useAppSelector(
    (state) => state.supplier.completeOrderDetails
  );
  const steelTypes = useAppSelector((state) => state.steelType.steelTypes);

  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const { t } = useTranslation([
    "ticketCreator",
    "newOrder",
    "common",
    "measurements",
    "tool",
  ]);

  return (
    <div className="a4-card">
      <div className="top-left-circle"></div>
      <div className="sketch-box">
        {
          <div>
            <p className="net_type_special">
              {t("special net", { ns: "ticketCreator" })}
            </p>
            {net.tool.name && (
              <p className="net_nickname_standard">
                {t("nickname", { ns: "ticketCreator" }) + ": " + net.tool.name}
              </p>
            )}
            <table
              className={
                "table table mb-0 custom-tool-table tickets-table-net" +
                (rightToLeft ? "-heb" : "-eng")
              }
            >
              <thead>
                <tr>
                  <th
                    className={
                      "d-flex justify-content-center align-items-center " +
                      rightToLeft
                        ? "db2"
                        : "db2l"
                    }
                    style={{ width: "23px" }}
                  >
                    {" "}
                  </th>
                  <th
                    className={
                      "d-flex justify-content-center align-items-center " +
                      rightToLeft
                        ? "db2"
                        : "db2l"
                    }
                    style={{ width: "55px" }}
                  >
                    <p>{t("steel type", { ns: "ticketCreator" })}</p>
                  </th>
                  <th
                    className={
                      "d-flex justify-content-center align-items-center " +
                      rightToLeft
                        ? "db2"
                        : "db2l"
                    }
                    style={{ width: "55px" }}
                  >
                    <p>{t("diameter", { ns: "tool" })}</p>
                  </th>
                  <th
                    className={
                      "d-flex justify-content-center align-items-center " +
                      rightToLeft
                        ? "db2"
                        : "db2l"
                    }
                    style={{ width: "55px" }}
                  >
                    <p>{t("gap", { ns: "ticketCreator" })}</p>
                  </th>
                  <th
                    className={
                      "d-flex justify-content-center align-items-center " +
                      rightToLeft
                        ? "db2"
                        : "db2l"
                    }
                    style={{ width: "165px" }}
                  >
                    <p>{t("lengths ", { ns: "ticketCreator" })}</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className={
                      "d-flex justify-content-center align-items-center " +
                      rightToLeft
                        ? "db2"
                        : "db2l"
                    }
                  >
                    <strong className="v-align" style={{ border: "none" }}>
                      X
                    </strong>
                  </td>
                  <td className={rightToLeft ? "db2" : "db2l"}>
                    <div className="d-flex justify-content-center align-items-center v-align ">
                      <img
                        src={steel}
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />
                      <p>
                        {steelTypes.length > 0 &&
                          steelTypes.find(
                            (item) => item.id === net.tool.steel_type
                          ) &&
                          steelTypes.find(
                            (item) => item.id === net.tool.steel_type
                          ).name[0]}
                      </p>
                    </div>
                  </td>
                  <td className={rightToLeft ? "db2" : "db2l"}>
                    <div className="d-flex justify-content-center align-items-center v-align ">
                      <img
                        src={diameter_b}
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />
                      <p>{net.tool.x_diameter.steel_diameter}</p>
                    </div>
                  </td>
                  <td className={rightToLeft ? "db2" : "db2l"}>
                    <div className="d-flex justify-content-center align-items-center v-align">
                      <img
                        src={gap_b}
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />
                      <p>{net.tool.x_gap}</p>
                    </div>
                  </td>
                  <td className={rightToLeft ? "db2" : "db2l"}>
                    <div className="two__rows_sec">
                      <div className="top__sec top_col_1">
                        <span>
                          <strong>{net.tool.x_length}</strong>
                        </span>
                      </div>
                      <div
                        className={
                          "d-flex justify-content-start align-items-center bottom_sec" +
                          (rightToLeft
                            ? "bottom_sec-pr text-right"
                            : "bottom_sec-pl text-left")
                        }
                        style={{ position: "static" }}
                      >
                        <span style={{ width: "55px", textAlign: "center" }}>
                          {net.tool.x_2}
                        </span>
                        <span
                          className="bottom_col_1"
                          style={{ width: "55px", textAlign: "center" }}
                        >
                          {net.tool.x_length - (net.tool.x_2 + net.tool.x_3)}
                        </span>
                        <span style={{ width: "55px", textAlign: "center" }}>
                          {net.tool.x_3}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td
                    className={
                      "d-flex justify-content-center align-items-center " +
                      rightToLeft
                        ? "db2"
                        : "db2l"
                    }
                  >
                    <strong className="v-align">Y</strong>
                  </td>
                  <td className={rightToLeft ? "db2" : "db2l"}>
                    <div className="d-flex justify-content-center align-items-center v-align ">
                      <img
                        src={steel}
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />
                      <p>
                        {steelTypes.length > 0 &&
                          steelTypes.find(
                            (item) => item.id === net.tool.steel_type
                          ) &&
                          steelTypes.find(
                            (item) => item.id === net.tool.steel_type
                          ).name[0]}
                      </p>
                    </div>
                  </td>
                  <td className={rightToLeft ? "db2" : "db2l"}>
                    <div className="d-flex justify-content-center align-items-center v-align ">
                      <img
                        src={diameter_b}
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />
                      <p>{net.tool.y_diameter.steel_diameter}</p>
                    </div>
                  </td>
                  <td className={rightToLeft ? "db2" : "db2l"}>
                    <div className="d-flex justify-content-center align-items-center v-align">
                      <img
                        src={gap_b}
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />
                      <p>{net.tool.y_gap}</p>
                    </div>
                  </td>
                  <td className={rightToLeft ? "db2" : "db2l"}>
                    <div className="two__rows_sec">
                      <div className="top__sec top_col_1">
                        <span>
                          <strong>{net.tool.y_length}</strong>
                        </span>
                      </div>
                      <div
                        className={
                          "d-flex justify-content-start align-items-center bottom_sec" +
                          (rightToLeft
                            ? "bottom_sec-pr text-right"
                            : "bottom_sec-pl text-left")
                        }
                        style={{ position: "static" }}
                      >
                        <span style={{ width: "55px", textAlign: "center" }}>
                          {net.tool.y_2}
                        </span>
                        <span
                          className="bottom_col_1"
                          style={{ width: "55px", textAlign: "center" }}
                        >
                          {net.tool.y_length - (net.tool.y_2 + net.tool.y_3)}
                        </span>
                        <span style={{ width: "55px", textAlign: "center" }}>
                          {net.tool.y_3}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }
      </div>
      <div className="info-section">
        <p
          className={
            "sketch-Note ticket-border__bottom " +
            (rightToLeft ? "info-pr" : "info-pl")
          }
          style={net.production_notes === "" ? { paddingBottom: "30px" } : {}}
        >
          {net.production_notes &&
            t("Note", { ns: "ticketCreator" }) + " : " + net.production_notes}
        </p>
        <div
          className={
            "rebar-detail ticket-border__bottom " +
            (rightToLeft ? "info-pr" : "info-pl")
          }
        >
          <p
            className="detail-title"
            style={
              rightToLeft ? { paddingLeft: "52px" } : { paddingRight: "12px" }
            }
          >
            {t("customer", { ns: "ticketCreator" })}
          </p>
          <p className="detail-sub">{currentOrderRebars.customer_name}</p>
        </div>
        <div
          className={
            "rebar-detail ticket-border__bottom " +
            (rightToLeft ? "info-pr" : "info-pl")
          }
        >
          <p
            className="detail-title"
            style={
              rightToLeft ? { paddingLeft: "12px" } : { paddingRight: "25px" }
            }
          >
            {t("delivery", { ns: "ticketCreator" })}
          </p>
          <p className="detail-sub">
            {format(new Date(currentOrderRebars.delivery_date), "dd/MM/yyy")}
          </p>
        </div>
        <div
          className={
            "rebar-detail ticket-border__bottom " +
            (rightToLeft ? "info-pr" : "info-pl")
          }
        >
          <p
            className="detail-title"
            style={
              rightToLeft ? { paddingLeft: "85px" } : { paddingRight: "60px" }
            }
          >
            {t("site", { ns: "ticketCreator" })}
          </p>
          <p className="detail-sub">{currentOrderRebars.site_address}</p>
        </div>
        <div className="weight-section ticket-border__bottom">
          <p>
            {t("item weight", { ns: "ticketCreator" })}
            {net.tool.unit_weight.toString().replace(commaSeparated, ",")}
          </p>
          <p>
            {t("total weight", { ns: "ticketCreator" })}
            {(net.tool.unit_weight * net.quantity)
              .toFixed(2)
              .toString()
              .replace(commaSeparated, ",")}
          </p>
        </div>
      </div>

      <div className="types-section" style={{ left: "156px" }}>
        <div className="detail-box">
          <p>{t("amount", { ns: "ticketCreator" })}</p>
          <div className="info-box">
            <p>{net.quantity.toString().replace(commaSeparated, ",")}</p>
          </div>
        </div>
      </div>

      <div className="footer-section">
        {rightToLeft ? (
          <div className="order-no">
            <p className="ticket_print_count_txt">
              ({printCurrentCount}/{printTotalCount})
            </p>
            <p>{`${ticketIndex} / ${orderNo}`}</p>
          </div>
        ) : (
          <div className="order-no">
            <p>{`${orderNo} / ${ticketIndex}`}</p>
            <p className="ticket_print_count_txt">
              ({printCurrentCount}/{printTotalCount})
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default A4TicketCardSpecialNets;
