import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface RebarState {
  steelTypes: any[];
  steelDiameters: any;
  adminDiameters: any[],
}

const initialState: RebarState = {
  steelTypes: [],
  steelDiameters: {},
  adminDiameters: [],
}

export const steelTypeSlice = createSlice({
  name: 'steelType',
  initialState,
  reducers: {
    setSteelTypes: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        steelTypes: action.payload
      }
    },
    addSteelType: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        steelTypes: [...state.steelTypes, action.payload],
      }
    },
    addSteelDiameters: (state, action: PayloadAction<any>) => {
      const {steelID, steelDiameters} = action.payload;
      const stateSteelDiamters = {...state.steelDiameters};
      stateSteelDiamters[steelID] = steelDiameters;
      state.steelDiameters = stateSteelDiamters
    },
    addAdminSteelDiameters: (state, action: PayloadAction<any>) => {
      const steelWithWeights = action.payload;
      let diameterWeights = [...state.adminDiameters];
      steelWithWeights.forEach((diWeight: any) => {
        const diamterObj = diameterWeights.find(
          item => item.diameter === diWeight.steel_diameter
        );
        if (diamterObj) {
          const index = diameterWeights.indexOf(diamterObj);
          diameterWeights[index] = {
            ...diameterWeights[index],
            weights: [...diameterWeights[index].weights, diWeight],
          }
        } else {
          diameterWeights = [
            ...diameterWeights,
            {
              id: diWeight.steel_diameter_id,
              diameter: diWeight.steel_diameter,
              weights: [diWeight],
            },
          ];
        }
      });
      state.adminDiameters = diameterWeights.sort((a, b) => a.diameter - b.diameter);
    },
    updateAdminSteelWeight: (state, action: PayloadAction<any>) => {
      const {outterIndex, innerIndex, val} = action.payload;
      const obj = {...state.adminDiameters[outterIndex]};
      obj.weights[innerIndex] = {
        ...obj.weights[innerIndex],
        weight: val,
      };
      state.adminDiameters[outterIndex] = obj;
    },
    updateSteelTypeIns: (state, action: PayloadAction<any>) => {
      const cpOfST = JSON.parse(JSON.stringify(state.steelTypes));
      const instance = cpOfST.find((it: any) => it.id === action.payload.id);
      if (instance) {
        const ind = cpOfST.indexOf(instance);
        cpOfST[ind] = action.payload;
        state.steelTypes = cpOfST
      }
    },
    removeDiameterRow: (state, action: PayloadAction<any>) => {
      state.adminDiameters = [...state.adminDiameters.filter(item => item.id !== action.payload)]
    },
    removeAdminSteelType: (state, action: PayloadAction<any>) => {
      const steelTypeID = action.payload;
      state.steelTypes = state.steelTypes.filter(it => it.id !== steelTypeID);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSteelTypes,
  addSteelType,
  addSteelDiameters,
  addAdminSteelDiameters,
  updateAdminSteelWeight,
  removeDiameterRow,
  removeAdminSteelType,
  updateSteelTypeIns,
} = steelTypeSlice.actions

export default steelTypeSlice.reducer