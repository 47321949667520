import React, { useEffect, useMemo, useRef, useCallback } from "react";
import cageSpiral from "Assets/img/order__page/cage-spiral.svg";
import cageCircle from "Assets/img/order__page/cage-circle.svg";
import cageDots from "Assets/img/order__page/cage-dots.svg";
import steeltype from "Assets/img/order__page/steeltype.svg";

import { useAppSelector } from "Redux/hooks";
import { EarTypes } from "Constants/pile-constants";
import PreviewHeader from "../header/index";
import cageImage from "./Cage1.svg";
import cage2Image from "./cage2.svg";
import { useTranslation } from "react-i18next";
import { commaSeparated } from "Constants/general";

import Diameter from  "../../../assets/img/pdf-preview/diameter.svg";
import Quantity from  "../../../assets/img/pdf-preview/quantity.svg";
import UnitWeight from  "../../../assets/img/pdf-preview/unit-weight.svg";
import Weight from  "../../../assets/img/pdf-preview/weight.svg";
import UnitPrice from  "../../../assets/img/pdf-preview/unit-price.svg";
import Price from  "../../../assets/img/pdf-preview/price.svg";

const PreviewPileTable = ({
  pageSize,
  order,
  piles,
  handleRefs,
  totalPages,
  priceShow,
  summaryDetails,
  pdfType,
  rebarsTotalPrice,
  standardNetsTotalPrice,
  specialNetsTotalPrice,
  rightToLeft,
  checkForDecimals,
}: any) => {
  const { t } = useTranslation(["common", "pdfOrder", "measurements"]);
  const steelTypes = useAppSelector((state) => state.steelType.steelTypes);
  const pageRef: any = useRef([]);

  const pilesUnits = useMemo(
    () =>
      piles.length > 0
        ? piles.reduce((pre: any, curr: any) => pre + curr.quantity, 0)
        : 0,
    [piles]
  );

  const pilesTons = useMemo(
    () =>
      piles.length > 0
        ? (
            piles.reduce(
              (pre: any, curr: any) =>
                pre + curr.tool.unit_weight * curr.quantity,
              0
            ) / 1000
          ).toFixed(2)
        : "0",
    [piles]
  );

  //This function returns 1/2 which is used when we have notes/extras
  //and by checking these values we are adding custom styling
  const checkingNotesAndExtraVals = useCallback(
    (notes, ring_quantity, gama_quantity, ear_length) => {
      if (notes && (ring_quantity > 0 || gama_quantity > 0 || ear_length > 0)) {
        return 1;
      } else if (
        notes ||
        ring_quantity > 0 ||
        gama_quantity > 0 ||
        ear_length > 0
      ) {
        return 2;
      }
    },
    []
  );

  //This function return the padding which is added when we have notes/extras
  const checkingNotesAndExtras = useCallback(
    (notes, ring_quantity, gama_quantity, ear_length) => {
      if (notes && (ring_quantity > 0 || gama_quantity > 0 || ear_length > 0)) {
        return { paddingBottom: "48px" };
      } else if (
        notes ||
        ring_quantity > 0 ||
        gama_quantity > 0 ||
        ear_length > 0
      ) {
        return { paddingBottom: "20px" };
      } else return {};
    },
    []
  );

  //This func is used when we have 3 split cage
  //and here we are checking for the notes and extras
  const checkingSplitCageChecks = useCallback(
    (notes, ring_quantity, gama_quantity, ear_length) => {
      if (
        (notes === "" || notes) &&
        (ring_quantity >= 0 || gama_quantity >= 0 || ear_length >= 0)
      ) {
        return true;
      } else return false;
    },
    []
  );

  //returns px values for the notes line based on the
  // notes/extras and split/base cage
  const checkingNotesAndSides = useCallback(
    (notes, internal_amount, external_amount, middle_amount) => {
      if (notes && external_amount && internal_amount && middle_amount) {
        return "75px";
      } else if (
        (!notes && external_amount && internal_amount && middle_amount) ||
        (notes && !external_amount && !internal_amount && !middle_amount)
      ) {
        return "74px";
      } else {
        return "72px";
      }
    },
    []
  );

  //returns px values for the extras line based on the
  // notes/extras and split/base cage
  const checkingExtrasAndSides = useCallback(
    (
      ring_quantity,
      gama_quantity,
      ear_length,
      internal_amount,
      external_amount,
      middle_amount
    ) => {
      if (
        (ring_quantity > 0 || gama_quantity > 0 || ear_length > 0) &&
        external_amount &&
        internal_amount &&
        middle_amount
      ) {
        return "97px";
      } else if (
        (ring_quantity > 0 || gama_quantity > 0 || ear_length > 0) &&
        external_amount &&
        internal_amount
      ) {
        return "95px";
      } else if (
        (ring_quantity > 0 || gama_quantity > 0 || ear_length > 0) &&
        !external_amount &&
        !internal_amount &&
        !middle_amount
      ) {
        return "95px"; //extras and notes in base cage
      } else if (
        !ring_quantity > 0 &&
        !gama_quantity > 0 &&
        !ear_length > 0 &&
        external_amount &&
        internal_amount &&
        middle_amount
      ) {
        return "74px"; //extras and notes in base cage
      } else {
        return "72px"; //base cage no extras only notes
      }
    },
    []
  );

  useEffect(() => {
    handleRefs(pageRef);
  });

  const printpage = (
    data: any,
    refIndex: any,
    last: boolean,
    lastLast: boolean
  ) => {
    return (
      <>
        <div
          style={{ height: pageSize + "px" }}
          className={last ? "last-page" : ""}
          ref={(el) => {
            pageRef.current[refIndex] = el;
          }}
        >
          <div>
            <PreviewHeader
              order={order}
              showDetails={refIndex === 0}
              pageNo={
                totalPages.rebars +
                totalPages.standardNets +
                totalPages.specialNets +
                1 +
                refIndex
              }
              totalPages={totalPages}
              rightToLeft={rightToLeft}
            />
            {data !== -1 && (
              <div className="table-wrapper">
                <div className="table-head">
                  {t("pile cage", { ns: "pdfOrder" })}
                </div>
                <div className="special-net-diagram">
                  <img className="img-1" src={cageImage} alt="cage" />
                  <img className="img-2" src={cage2Image} alt="cage side" />
                </div>
                <table
                  className={
                    "pile" +
                    (lastLast ? " last" : "") +
                    (priceShow ? "" : " without-price")
                  }
                >
                  <thead>
                    <tr className="dark-border-bottom">
                      <th
                        className={
                          (rightToLeft
                            ? "dark-border-left"
                            : "dark-border-right") + " number-container"
                        }
                      ></th>
                      <th
                        className={
                          (rightToLeft
                            ? "dark-border-left"
                            : "dark-border-right") +
                          " d-flex justify-content-center"
                        }
                      >
                        <div className={rightToLeft ? "rightToLeft" : ""}>
                          {t("name", { ns: "pdfOrder" })}
                        </div>
                      </th>
                      <th
                        className={
                          rightToLeft ? "dark-border-left" : "dark-border-right"
                        }
                      >
                        <span className="py-1">
                          <img
                            src={cageCircle}
                            alt="rings logo"
                            style={{ height: "24px" }}
                          />
                        </span>
                        <div className="rings-holo-info light-border-bottom dark-border-top flex-grow-1 h-100">
                          <span
                            className={
                              (rightToLeft
                                ? "light-border-left"
                                : "light-border-right") + " last-element"
                            }
                          >
                            D
                          </span>
                          <span className={"last-element"}>d</span>
                        </div>
                        <div className="rings-holo-info flex-grow-1">
                          <span
                            className={
                              (rightToLeft
                                ? "light-border-left"
                                : "light-border-right") + " unit center-aligned"
                            }
                          >
                            {t("cm", { ns: "measurements" })}
                          </span>
                          <span className="unit center-aligned">
                            {t("cm", { ns: "measurements" })}
                          </span>
                        </div>
                      </th>
                      <th
                        className={
                          rightToLeft ? "dark-border-left" : "dark-border-right"
                        }
                      >
                        <span className="py-1">
                          <img
                            src={cageDots}
                            alt="dots logo"
                            style={{ height: "24px" }}
                          />
                        </span>
                        <div className="rebar-info dark-border-top flex-grow-1">
                          <div
                            className={
                              "single-column " +
                              (rightToLeft
                                ? "dark-border-left"
                                : "dark-border-right")
                            }
                          >
                            <div className="rings-info light-border-bottom last-element h-100">
                              <span
                                className={
                                  (rightToLeft
                                    ? "light-border-left"
                                    : "light-border-right") +
                                  " h-100 vertical-center-aligned"
                                }
                              >
                                <img
                                  src={steeltype}
                                  alt="steel type logo"
                                  className="small-img mb-1"
                                />
                                {t("type", { ns: "common" })}
                              </span>
                              <span
                                className={
                                  (rightToLeft
                                    ? "light-border-left"
                                    : "light-border-right") +
                                  " h-100 vertical-center-aligned"
                                }
                              >
                                <img
                                  src={Diameter}
                                  alt="diameter logo"
                                  className="small-img mb-1"
                                />
                                {t("diam", { ns: "common" })}
                              </span>
                              <span className="vertical-center-aligned last-element h-100">
                                <img
                                  src={cageDots}
                                  alt="dots logo"
                                  style={{
                                    width: "22px",
                                    height: "22px",
                                    paddingbottom: "4px",
                                  }}
                                  className="mb-1"
                                />
                                {t("qunt", { ns: "common" })}
                              </span>
                            </div>

                            <div className="rings-info last-element">
                              <span
                                className={
                                  "unit h-100 " +
                                  (rightToLeft
                                    ? "light-border-left"
                                    : "light-border-right")
                                }
                              ></span>
                              <span
                                className={
                                  (rightToLeft
                                    ? "light-border-left"
                                    : "light-border-right") + " unit"
                                }
                              >
                                {t("mm", { ns: "measurements" })}
                              </span>
                              <span className="unit">
                                {t("units", { ns: "measurements" })}
                              </span>
                            </div>
                          </div>
                          <div className="single-column">
                            <span
                              style={{ fontWeight: "700", height: "32px" }}
                              className="center-aligned"
                            >
                              L
                            </span>
                            <div className="lengths-info light-border-bottom light-border-top last-element">
                              <span
                                className={
                                  (rightToLeft
                                    ? "light-border-left"
                                    : "light-border-right") +
                                  " h-100 center-aligned"
                                }
                                style={{ fontWeight: "700" }}
                              >
                                L1
                              </span>
                              <span
                                className={
                                  (rightToLeft
                                    ? "light-border-left"
                                    : "light-border-right") +
                                  " h-100 center-aligned"
                                }
                                style={{ fontWeight: "700" }}
                              >
                                L2
                              </span>
                              <span
                                className="h-100 center-aligned"
                                style={{ fontWeight: "700" }}
                              >
                                L3
                              </span>
                            </div>
                            <span className="unit">
                              {t("cm", { ns: "measurements" })}
                            </span>
                          </div>
                        </div>
                      </th>
                      <th
                        className={
                          rightToLeft ? "dark-border-left" : "dark-border-right"
                        }
                      >
                        <span className="py-1">
                          <img
                            src={cageSpiral}
                            alt="sprial logo"
                            style={{ height: "24px" }}
                          />
                        </span>
                        <div className="sprial-info dark-border-top flex-grow-1">
                          <span
                            className={
                              (rightToLeft
                                ? "light-border-left"
                                : "light-border-right") +
                              " last-element vertical-center-aligned"
                            }
                          >
                            <img
                              src={steeltype}
                              alt="steel type logo"
                              className="small-img mb-1"
                            />
                            {t("type", { ns: "common" })}
                          </span>
                          <span
                            className={
                              (rightToLeft
                                ? "light-border-left"
                                : "light-border-right") +
                              " last-element vertical-center-aligned"
                            }
                          >
                            <img
                              src={Diameter}
                              alt="diameter logo"
                              className="small-img mb-1"
                            />
                            {t("diam", { ns: "common" })}
                          </span>
                          <span
                            className={
                              (rightToLeft
                                ? "light-border-left"
                                : "light-border-right") + " last-element"
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18.6"
                              height="19.6"
                              viewBox="0 0 18.6 19.6"
                            >
                              <text
                                id="_1"
                                data-name="@1"
                                transform={
                                  rightToLeft
                                    ? "translate(13 0.3)"
                                    : "translate(0.3 0.3)"
                                }
                                fill="#161615"
                                stroke="#161615"
                                stroke-miterlimit="10"
                                strokeWidth="0.3"
                                font-size="14"
                                font-family="Heebo-Medium, Heebo"
                                font-weight="500"
                              >
                                <tspan x="0.882" y="13">
                                  @
                                </tspan>
                                <tspan
                                  x={rightToLeft ? "6" : ""}
                                  y={rightToLeft ? "" : "13"}
                                  font-size="8"
                                >
                                  1
                                </tspan>
                              </text>
                            </svg>
                          </span>
                          <span className="last-element">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18.6"
                              height="19.6"
                              viewBox="0 0 18.6 19.6"
                            >
                              <text
                                id="_2"
                                data-name="@2"
                                transform={
                                  rightToLeft
                                    ? "translate(13 0.3)"
                                    : "translate(0.3 0.3)"
                                }
                                fill="#161615"
                                stroke="#161615"
                                stroke-miterlimit="10"
                                strokeWidth="0.3"
                                font-size="14"
                                font-family="Heebo-Medium, Heebo"
                                font-weight="500"
                              >
                                <tspan x="0.882" y="13">
                                  @
                                </tspan>
                                <tspan
                                  x={rightToLeft ? "6" : ""}
                                  y={rightToLeft ? "" : "13"}
                                  font-size="8"
                                >
                                  2
                                </tspan>
                              </text>
                            </svg>
                          </span>
                        </div>
                        <div className="sprial-info-units light-border-top">
                          <span
                            className={
                              "unit " +
                              (rightToLeft
                                ? "light-border-left"
                                : "light-border-right")
                            }
                          ></span>
                          <span
                            className={
                              "unit " +
                              (rightToLeft
                                ? "light-border-left"
                                : "light-border-right")
                            }
                          >
                            {t("mm", { ns: "measurements" })}
                          </span>
                          <span
                            className={
                              "unit " +
                              (rightToLeft
                                ? "light-border-left"
                                : "light-border-right")
                            }
                          >
                            {t("cm", { ns: "measurements" })}
                          </span>
                          <span className={"unit "}>
                            {t("cm", { ns: "measurements" })}
                          </span>
                        </div>
                      </th>
                      <th
                        className={
                          rightToLeft ? "dark-border-left" : "dark-border-right"
                        }
                      >
                        <span className="last-element">
                          <img
                            src={Quantity}
                            alt="quantity logo"
                          />
                        </span>
                        <span
                          className="light-border-bottom text-lowercase"
                          style={{ paddingBottom: "7px" }}
                        >
                          {t("quantity", { ns: "common" })}
                        </span>
                        <span className="unit">
                          {t("units", { ns: "measurements" })}
                        </span>
                      </th>
                      <th
                        className={
                          priceShow
                            ? rightToLeft
                              ? "dark-border-left"
                              : "dark-border-right"
                            : "h-100"
                        }
                        style={{ justifyContent: "flex-start" }}
                      >
                        <span className="piles-weight py-1">
                          {t("weight", { ns: "common" })}
                        </span>
                        <div className="rings-holo-info flex-grow-1 h-100 light-border-bottom dark-border-top">
                          <div
                            className={
                              "vertical-center-aligned " +
                              (rightToLeft
                                ? "light-border-left"
                                : "light-border-right")
                            }
                          >
                            <span>
                              <img
                                src={UnitWeight}
                                alt="unit weight logo"
                                style={{ height: "24px", width: "24px" }}
                              />
                            </span>
                            <span>{t("unit", { ns: "measurements" })}</span>
                          </div>
                          <div className="vertical-center-aligned">
                            <span>
                              <img
                                src={Weight}
                                alt="weight logo"
                                style={{ height: "24px", width: "24px" }}
                              />
                            </span>
                            <span>{t("total", { ns: "common" })}</span>
                          </div>
                        </div>
                        <div className="rings-holo-info flex-grow-1">
                          <span
                            className={
                              (rightToLeft
                                ? "light-border-left"
                                : "light-border-right") + " unit center-aligned"
                            }
                          >
                            {t("kg", { ns: "measurements" })}
                          </span>
                          <span className="unit center-aligned">
                            {t("kg", { ns: "measurements" })}
                          </span>
                        </div>
                      </th>
                      {priceShow && (
                        <>
                          <th>
                            <span>{t("Price", { ns: "common" })}</span>
                            <div className="two-columns dark-border-top">
                              <div className="single-column light-border-right">
                                <span>
                                  <img
                                    src={UnitPrice}
                                    alt="unit weight logo"
                                  />
                                </span>
                                <span className="light-border-bottom">
                                  {t("unit", { ns: "measurements" })}
                                </span>
                                <span className="unit">NIS</span>
                              </div>
                              <div className="single-column">
                                <span>
                                  <img
                                    src={Price}
                                    alt="weight logo"
                                  />
                                </span>
                                <span className="light-border-bottom">
                                  {t("total", { ns: "common" })}
                                </span>
                                <span className="unit">NIS</span>
                              </div>
                            </div>
                          </th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((itemPile: any, index: number) => {
                      const stylesForNotesAndExtras = checkingNotesAndExtras(
                        itemPile.production_notes,
                        itemPile.tool.ring_quantity,
                        itemPile.tool.gama_quantity,
                        itemPile.tool.ear_length
                      );

                      const stylesForNotesAndExtraVals =
                        checkingNotesAndExtraVals(
                          itemPile.production_notes,
                          itemPile.tool.ring_quantity,
                          itemPile.tool.gama_quantity,
                          itemPile.tool.ear_length
                        );

                      return (
                        <tr
                          className={
                            itemPile.production_notes &&
                            (itemPile.tool.ring_quantity > 0 ||
                              itemPile.tool.gama_quantity > 0 ||
                              itemPile.tool.ear_length > 0)
                              ? "special_piles-tr"
                              : "mid_piles-tr"
                          }
                          style={
                            itemPile.tool.external_diameter.steel_diameter &&
                            itemPile.tool.internal_diameter.steel_diameter &&
                            itemPile.tool.middle_diameter.steel_diameter
                              ? { height: "98.1%" }
                              : {}
                          }
                        >
                          <td
                            className={
                              rightToLeft
                                ? "dark-border-left"
                                : "dark-border-right"
                            }
                          >
                            {itemPile.row_number}
                          </td>
                          <td
                            className={
                              (rightToLeft
                                ? "dark-border-left"
                                : "dark-border-right") + " preview"
                            }
                          >
                            <p
                              style={
                                itemPile.tool.name
                                  ? itemPile.tool.name.includes(" ")
                                    ? { fontSize: "16px" }
                                    : {
                                        wordBreak: "break-all",
                                        lineHeight: "normal",
                                        fontSize: "16px",
                                      }
                                  : {}
                              }
                            >
                              {itemPile.tool.name}
                            </p>
                          </td>
                          <td
                            className={
                              rightToLeft
                                ? "light-border-left"
                                : "light-border-right"
                            }
                            style={stylesForNotesAndExtras}
                          >
                            {itemPile.tool.hole_diameter}
                          </td>
                          <td
                            className={
                              rightToLeft
                                ? "dark-border-left"
                                : "dark-border-right"
                            }
                            style={stylesForNotesAndExtras}
                          >
                            {itemPile.tool.cage_diameter}
                          </td>
                          <td
                            className={
                              rightToLeft
                                ? "light-border-left"
                                : "light-border-right"
                            }
                            style={stylesForNotesAndExtras}
                          >
                            {steelTypes.length > 0 &&
                              steelTypes.find(
                                (item) =>
                                  item.id === itemPile.tool.length_steel_type
                              ) &&
                              steelTypes.find(
                                (item) =>
                                  item.id === itemPile.tool.length_steel_type
                              ).name[0]}
                          </td>
                          <td
                            className={
                              (rightToLeft
                                ? "light-border-left"
                                : "light-border-right") + ""
                            }
                            style={
                              stylesForNotesAndExtraVals === 1
                                ? { paddingBottom: "48px", height: "119px" }
                                : stylesForNotesAndExtras
                            }
                          >
                            {itemPile.tool.external_diameter.steel_diameter &&
                            itemPile.tool.internal_diameter.steel_diameter &&
                            itemPile.tool.middle_diameter.steel_diameter ? (
                              <div
                                className=" d-flex flex-column w-100"
                                style={
                                  checkingSplitCageChecks
                                    ? { height: "71px" }
                                    : {}
                                }
                              >
                                <span
                                  style={
                                    checkingSplitCageChecks
                                      ? { height: "24px" }
                                      : {}
                                  }
                                  className="light-border-bottom center-aligned"
                                >
                                  {
                                    itemPile.tool.internal_diameter
                                      .steel_diameter
                                  }
                                </span>
                                <span
                                  style={
                                    checkingSplitCageChecks
                                      ? { height: "23px" }
                                      : {}
                                  }
                                  className="light-border-bottom center-aligned"
                                >
                                  {itemPile.tool.middle_diameter.steel_diameter}
                                </span>
                                <span
                                  style={
                                    checkingSplitCageChecks
                                      ? { height: "23px" }
                                      : {}
                                  }
                                  className="center-aligned"
                                >
                                  {
                                    itemPile.tool.external_diameter
                                      .steel_diameter
                                  }
                                </span>
                              </div>
                            ) : itemPile.tool.external_diameter
                                .steel_diameter &&
                              itemPile.tool.internal_diameter.steel_diameter ? (
                              <div className=" d-flex flex-column w-100 h-100">
                                {" "}
                                <span className="light-border-bottom center-aligned h-100">
                                  {
                                    itemPile.tool.internal_diameter
                                      .steel_diameter
                                  }
                                </span>
                                <span className="center-aligned h-100">
                                  {
                                    itemPile.tool.external_diameter
                                      .steel_diameter
                                  }
                                </span>
                              </div>
                            ) : (
                              <p>
                                {itemPile.tool.base_diameter.steel_diameter}
                              </p>
                            )}
                          </td>
                          <td
                            className={
                              rightToLeft
                                ? "dark-border-left"
                                : "dark-border-right"
                            }
                            style={
                              stylesForNotesAndExtraVals === 1
                                ? { paddingBottom: "48px", height: "119px" }
                                : stylesForNotesAndExtraVals === 2
                                ? { paddingBottom: "20px", height: "96px" }
                                : {}
                            }
                          >
                            {itemPile.tool.external_amount &&
                            itemPile.tool.internal_amount &&
                            itemPile.tool.middle_amount ? (
                              <div
                                style={
                                  checkingSplitCageChecks
                                    ? { height: "71px" }
                                    : {}
                                }
                                className=" d-flex flex-column w-100"
                              >
                                <span
                                  style={
                                    checkingSplitCageChecks
                                      ? { height: "24px" }
                                      : {}
                                  }
                                  className="light-border-bottom center-aligned "
                                >
                                  {itemPile.tool.internal_amount}
                                </span>
                                <span
                                  style={
                                    checkingSplitCageChecks
                                      ? { height: "23px" }
                                      : {}
                                  }
                                  className="light-border-bottom center-aligned"
                                >
                                  {itemPile.tool.middle_amount}
                                </span>
                                <span
                                  style={
                                    checkingSplitCageChecks
                                      ? {
                                          height: "23px",
                                        }
                                      : {}
                                  }
                                  className="center-aligned"
                                >
                                  {itemPile.tool.external_amount}
                                </span>
                              </div>
                            ) : itemPile.tool.external_amount &&
                              itemPile.tool.internal_amount ? (
                              <div className=" d-flex flex-column w-100 h-100">
                                {" "}
                                <span className="light-border-bottom center-aligned h-100">
                                  {itemPile.tool.internal_amount}
                                </span>
                                <span className="center-aligned h-100">
                                  {itemPile.tool.external_amount}
                                </span>
                              </div>
                            ) : (
                              <p>{itemPile.tool.base_amount}</p>
                            )}
                          </td>
                          <td
                            className={
                              (rightToLeft
                                ? "dark-border-left"
                                : "dark-border-right") + " w-100"
                            }
                            style={
                              stylesForNotesAndExtraVals === 1
                                ? { paddingBottom: "40px" }
                                : stylesForNotesAndExtras
                            }
                          >
                            <div className="len_container">
                              <div className="len_top">
                                <span className="total_length">
                                  {itemPile.tool.l1_length +
                                    itemPile.tool.l2_length +
                                    itemPile.tool.l3_length}
                                </span>
                              </div>

                              <div className="len_bottom">
                                <span
                                  className={
                                    (rightToLeft
                                      ? "light-border-left"
                                      : "light-border-right") +
                                    " h-100 len_bottom-item"
                                  }
                                >
                                  {itemPile.tool.l1_length}
                                </span>
                                <span
                                  className={
                                    (rightToLeft
                                      ? "light-border-left"
                                      : "light-border-right") +
                                    " h-100 len_bottom-item"
                                  }
                                >
                                  {itemPile.tool.l2_length}
                                </span>
                                <span className="h-100 len_bottom-item">
                                  {itemPile.tool.l3_length}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td
                            className={
                              rightToLeft
                                ? "light-border-left"
                                : "light-border-right"
                            }
                            style={stylesForNotesAndExtras}
                          >
                            {steelTypes.length > 0 &&
                              steelTypes.find(
                                (item) =>
                                  item.id === itemPile.tool.cage_steel_type
                              ) &&
                              steelTypes.find(
                                (item) =>
                                  item.id === itemPile.tool.cage_steel_type
                              ).name[0]}
                          </td>
                          <td
                            className={
                              rightToLeft
                                ? "light-border-left"
                                : "light-border-right"
                            }
                            style={stylesForNotesAndExtras}
                          >
                            {itemPile.tool.spiral_wire_diameter.steel_diameter}
                          </td>
                          <td
                            className={
                              rightToLeft
                                ? "light-border-left"
                                : "light-border-right"
                            }
                            style={stylesForNotesAndExtras}
                          >
                            {itemPile.tool.l2_gap}
                          </td>
                          <td
                            className={
                              rightToLeft
                                ? "dark-border-left"
                                : "dark-border-right"
                            }
                            style={stylesForNotesAndExtras}
                          >
                            {itemPile.tool.l3_gap}
                          </td>
                          <td
                            className={
                              rightToLeft
                                ? "dark-border-left"
                                : "dark-border-right"
                            }
                          >
                            {itemPile.quantity}
                          </td>
                          <td
                            className={
                              rightToLeft
                                ? "light-border-left"
                                : "light-border-right"
                            }
                          >
                            {itemPile.tool.unit_weight}
                          </td>
                          <td
                            className={
                              priceShow
                                ? rightToLeft
                                  ? "dark-border-left"
                                  : "dark-border-right"
                                : ""
                            }
                          >
                            {(
                              itemPile.tool.unit_weight * itemPile.quantity
                            ).toFixed(2)}
                          </td>
                          {priceShow && (
                            <>
                              <td
                                className={
                                  rightToLeft
                                    ? "light-border-left"
                                    : "light-border-right"
                                }
                              >
                                2.3
                              </td>
                              <td>1,115</td>
                            </>
                          )}
                          {(itemPile.tool.ring_quantity > 0 ||
                            itemPile.tool.gama_quantity > 0 ||
                            itemPile.tool.ear_length > 0) && (
                            <td
                              className="dark-border-top dark-border-bottom extras_bg"
                              style={{
                                position: "absolute",
                                marginTop: checkingNotesAndSides(
                                  itemPile.production_notes,
                                  itemPile.tool.external_amount,
                                  itemPile.tool.internal_amount,
                                  itemPile.tool.middle_amount
                                ),
                                alignItems: "center",
                                justifyContent: "flex-start",
                                height: "24px",
                                width: priceShow ? "73%" : "53.9%",
                                ...(rightToLeft
                                  ? {
                                      right: priceShow ? "250px" : "165px",
                                      textAlign: "right",
                                    }
                                  : { left: priceShow ? "250px" : "165px" }),
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "16px",
                                  lineHeight: "30px",
                                  ...(rightToLeft
                                    ? { marginRight: "4px" }
                                    : { marginLeft: "4px" }),
                                }}
                              >
                                <span className="extras_dark_font">
                                  {t("extras")} /{" "}
                                </span>
                                {itemPile.tool.ear_length > 0 && (
                                  <span>
                                    <span className="extras_dark_font">
                                      {t("ear", { ns: "pdfOrder" })}:{" "}
                                    </span>
                                    <span className="extras_light_font">
                                      {rightToLeft
                                        ? EarTypes[itemPile.tool.ear_type + 1]
                                        : EarTypes[itemPile.tool.ear_type - 1]}
                                      {", " + itemPile.tool.ear_length}{" "}
                                      {t("cm", { ns: "measurements" })}
                                    </span>
                                  </span>
                                )}
                                {itemPile.tool.ring_quantity > 0 && (
                                  <span>
                                    {itemPile.tool.ear_length > 0 && (
                                      <span className="mx-1 extras_dark_font">
                                        /
                                      </span>
                                    )}
                                    <span className="extras_dark_font">
                                      {t("ring", { ns: "pdfOrder" })}:
                                    </span>
                                    <span className="extras_light_font">
                                      {rightToLeft
                                        ? " " +
                                          itemPile.tool.ring_quantity +
                                          " " +
                                          t("units", { ns: "measurements" }) +
                                          ", " +
                                          t("diameter", { ns: "common" }) +
                                          " " +
                                          itemPile.tool.ring_diameter
                                        : " " +
                                          itemPile.tool.ring_quantity +
                                          " " +
                                          t("units", { ns: "measurements" }) +
                                          ", " +
                                          itemPile.tool.ring_diameter +
                                          " " +
                                          t("diameter", { ns: "common" })}
                                    </span>
                                  </span>
                                )}
                                {itemPile.tool.gama_quantity > 0 && (
                                  <span>
                                    {(itemPile.tool.ring_quantity > 0 ||
                                      itemPile.tool.ear_length > 0) && (
                                      <span className={"mx-1 extras_dark_font"}>
                                        /
                                      </span>
                                    )}
                                    <span className="extras_dark_font">
                                      {t("gama", { ns: "pdfOrder" })}:{" "}
                                    </span>{" "}
                                    <span className="extras_light_font">
                                      {itemPile.tool.gama_quantity}{" "}
                                      {t("units", { ns: "measurements" })}
                                      {", " + itemPile.tool.gama_diameter}{" "}
                                      {t("zol", { ns: "measurements" })}
                                    </span>
                                  </span>
                                )}
                              </p>
                            </td>
                          )}
                          {itemPile.production_notes && (
                            <td
                              className="dark-border-top dark-border-bottom"
                              style={{
                                position: "absolute",
                                marginTop: checkingExtrasAndSides(
                                  itemPile.tool.ring_quantity,
                                  itemPile.tool.gama_quantity,
                                  itemPile.tool.ear_length,
                                  itemPile.tool.external_amount,
                                  itemPile.tool.internal_amount,
                                  itemPile.tool.middle_amount
                                ),
                                alignItems: "center",
                                justifyContent: "flex-start",
                                height: "24px",
                                width: priceShow ? "73%" : "53.9%",
                                ...(rightToLeft
                                  ? {
                                      right: priceShow ? "250px" : "165px",
                                      textAlign: "right",
                                    }
                                  : { left: priceShow ? "250px" : "165px" }),
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "16px",
                                  lineHeight: "30px",
                                  ...(rightToLeft
                                    ? { marginRight: "4px" }
                                    : { marginLeft: "4px" }),
                                }}
                              >
                                <span className="extras_dark_font">
                                  {t("note")}:
                                </span>{" "}
                                {itemPile.production_notes}
                              </p>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                    {lastLast && (
                      <>
                        <tr style={{ border: "none" }}>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>{pilesUnits}</td>
                          <td>{pilesTons}</td>
                          {priceShow && <td>34</td>}
                        </tr>
                        <tr style={{ border: "none" }}>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{ fontSize: "18px" }}>
                            {t("quantity", { ns: "common" })}
                          </td>
                          <td>
                            <span className={rightToLeft ? "ml-1" : "mr-1"}>
                              {t("weight", { ns: "common" })}
                            </span>
                            <span style={{ fontSize: "12px" }}>
                              {" "}
                              ({t("ton", { ns: "measurements" })})
                            </span>
                          </td>
                          {priceShow && (
                            <td>{t("Price", { ns: "common" })} (NIS)</td>
                          )}
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            {/* Summary of Special Nets*/}

            {last && (
              <div className="table-wrapper">
                <div className="table-head">
                  {t("order Summary", { ns: "pdfOrder" })}
                </div>
                <table
                  className={
                    "order-summary" +
                    (priceShow ? "" : " without-price") +
                    (rightToLeft ? " rightToLeft" : "")
                  }
                >
                  <tbody>
                    {summaryDetails.rebars > 0 && (
                      <tr>
                        <td>{t("Rebars", { ns: "common" })}</td>
                        <td>{summaryDetails.rebars}</td>
                        <td>
                          {pdfType === 1
                            ? checkForDecimals(parseFloat(rebarsTotalPrice))
                                .toString()
                                .replace(commaSeparated, ",")
                            : parseFloat(summaryDetails.rebars_weight) === 0.0
                            ? parseFloat(summaryDetails.rebars_weight).toFixed(
                                3
                              )
                            : parseFloat(summaryDetails.rebars_weight).toFixed(
                                2
                              )}
                        </td>
                        {priceShow && <td>54</td>}
                      </tr>
                    )}
                    {summaryDetails.standard_nets > 0 && (
                      <tr>
                        <td>{t("standard nets", { ns: "pdfOrder" })}</td>
                        <td>{summaryDetails.standard_nets}</td>
                        <td>
                          {pdfType === 1
                            ? checkForDecimals(
                                parseFloat(standardNetsTotalPrice)
                              )
                                .toString()
                                .replace(commaSeparated, ",")
                            : parseFloat(
                                summaryDetails.standard_nets_weight
                              ) === 0.0
                            ? parseFloat(
                                summaryDetails.standard_nets_weight
                              ).toFixed(3)
                            : parseFloat(
                                summaryDetails.standard_nets_weight
                              ).toFixed(2)}
                        </td>
                        {priceShow && <td>54</td>}
                      </tr>
                    )}
                    {summaryDetails.special_nets > 0 && (
                      <tr>
                        <td>{t("special nets", { ns: "pdfOrder" })}</td>
                        <td>{summaryDetails.special_nets}</td>
                        <td>
                          {pdfType === 1
                            ? checkForDecimals(
                                parseFloat(specialNetsTotalPrice)
                              )
                                .toString()
                                .replace(commaSeparated, ",")
                            : parseFloat(summaryDetails.special_nets_weight) ===
                              0.0
                            ? parseFloat(
                                summaryDetails.special_nets_weight
                              ).toFixed(3)
                            : parseFloat(
                                summaryDetails.special_nets_weight
                              ).toFixed(2)}
                        </td>
                        {priceShow && <td>54</td>}
                      </tr>
                    )}

                    <tr>
                      <td>{t("pile cage", { ns: "pdfOrder" })}</td>
                      <td>{summaryDetails.piles}</td>
                      <td>
                        {pdfType === 1
                          ? checkForDecimals(parseFloat(specialNetsTotalPrice))
                              .toString()
                              .replace(commaSeparated, ",")
                          : parseFloat(summaryDetails.piles_weight) === 0.0
                          ? parseFloat(summaryDetails.piles_weight).toFixed(3)
                          : parseFloat(summaryDetails.piles_weight).toFixed(2)}
                      </td>
                      {priceShow && <td>54</td>}
                    </tr>

                    <tr>
                      <td>{t("total", { ns: "common" })}</td>
                      <td>
                        {summaryDetails.rebars +
                          summaryDetails.standard_nets +
                          summaryDetails.special_nets +
                          summaryDetails.piles}
                      </td>

                      <td>
                        {pdfType === 1
                          ? (
                              parseFloat(rebarsTotalPrice) +
                              parseFloat(standardNetsTotalPrice) +
                              parseFloat(specialNetsTotalPrice)
                            )
                              .toFixed(1)
                              .toString()
                              .replace(commaSeparated, ",")
                          : (
                              parseFloat(summaryDetails.rebars_weight) +
                              parseFloat(summaryDetails.standard_nets_weight) +
                              parseFloat(summaryDetails.special_nets_weight) +
                              parseFloat(summaryDetails.piles_weight)
                            ).toFixed(2)}
                      </td>
                      {priceShow && <td>99</td>}
                    </tr>
                    <tr>
                      <td></td>
                      <td>{t("units", { ns: "measurements" })}</td>
                      <td className="d-flex align-items-center text-capitalize">
                        <span className={rightToLeft ? "ml-1" : "mr-1"}>
                          {pdfType === 1
                            ? t("Price", { ns: "common" })
                            : t("weight", { ns: "common" })}
                        </span>
                        <span style={{ fontSize: "12px" }}>
                          (
                          {pdfType === 1
                            ? t("ILS", { ns: "measurements" })
                            : t("ton", { ns: "measurements" })}
                          )
                        </span>
                      </td>
                      {priceShow && (
                        <td>{t("Price", { ns: "common" })} (NIS)</td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          {/* {last && <PdfFooter order={order} />} */}
        </div>
        <div
          style={{
            height: "20px",
            background: "#000",
            opacity: "0.5",
          }}
        ></div>
      </>
    );
  };

  const loopinAround = (piles, new_size, count, lastSlice) => {
    const newPiles = piles.slice(lastSlice, piles.length);
    newPiles.forEach((data, index) => {
      if (
        data.production_notes &&
        (data.tool.ring_quantity ||
          data.tool.gama_quantity ||
          data.tool.ear_length)
      ) {
        new_size = new_size + 119;
      } else {
        new_size = new_size + 96;
      }

      if (new_size < 1400) {
        count += 1;
      } else {
        return count;
      }
    });
    return count;
  };

  const loopinAroundSummary = (piles, new_size, lastSlice) => {
    const newPiles = piles.slice(lastSlice, piles.length);
    newPiles.forEach((data, index) => {
      if (
        data.production_notes &&
        (data.tool.ring_quantity ||
          data.tool.gama_quantity ||
          data.tool.ear_length)
      ) {
        new_size = new_size + 119;
      } else {
        new_size = new_size + 96;
      }
    });

    if (
      summaryDetails.rebars > 0 &&
      summaryDetails.standard_nets > 0 &&
      summaryDetails.special_nets > 0
    ) {
      if (new_size + 320 < 1400) {
        return false;
      } else {
        return true;
      }
    } else if (
      (summaryDetails.rebars > 0 && summaryDetails.standard_nets > 0) ||
      (summaryDetails.standard_nets > 0 && summaryDetails.special_nets > 0) ||
      (summaryDetails.rebars > 0 && summaryDetails.special_nets > 0)
    ) {
      if (new_size + 270 < 1400) {
        return false;
      } else {
        return true;
      }
    } else if (
      summaryDetails.rebars > 0 ||
      summaryDetails.standard_nets > 0 ||
      summaryDetails.rebars > 0
    ) {
      if (new_size + 220 < 1400) {
        return false;
      } else {
        return true;
      }
    } else {
      if (new_size + 197 < 1400) {
        return false;
      } else {
        return true;
      }
    }
  };

  if (piles && piles.length) {
    let SLICE, FIRST_SLICE, LAST_SLICE;
    let nextPageFlag = false; //if true order summary moved to the next page
    const dataSlice: any = [];
    let initialSlice = 0,
      firstSlice = true;

    let size_used = 840; //first Page Size Used by Header and other things

    //Order summary to next page (For the first page)
    nextPageFlag = loopinAroundSummary(piles, size_used, 0);

    while (piles.length > initialSlice) {
      const remaining = piles.length - initialSlice;
      let count = 0;

      //FirstPage Checks
      if (remaining > 6 && firstSlice) {
        //If Piles are more than 6 then we are decreasing the used size
        //because last two rows of Quant and weight goes to next page
        count = loopinAround(piles, 772, 0, 0);
        SLICE = count;
        FIRST_SLICE = count;
        LAST_SLICE = count;
      } else if (firstSlice) {
        count = loopinAround(piles, size_used, 0, 0);
        SLICE = count;
        FIRST_SLICE = count;
        LAST_SLICE = count;
      }

      //Second Page onwards checks
      if (remaining >= 7 && remaining <= 8 && !firstSlice) {
        //Edge Case
        count = loopinAround(piles, 614, 0, initialSlice);
        SLICE = count;
        LAST_SLICE = count;
      } else if (!firstSlice) {
        count = loopinAround(piles, size_used, 0, initialSlice);
        SLICE = count;
        LAST_SLICE = count;
      }

      //Order summary to next page (Except the first page)
      if (remaining >= 5 && !firstSlice) {
        const flag = loopinAroundSummary(piles, 614, initialSlice);
        if (flag) {
          nextPageFlag = true;
        }
      } else if (!firstSlice) {
        nextPageFlag = false;
      }

      if (
        piles.length === initialSlice + SLICE ||
        piles.length === initialSlice + SLICE - 1 ||
        piles.length === initialSlice + SLICE - 2 ||
        piles.length === initialSlice + SLICE - 3
      ) {
        dataSlice.push(piles.slice(initialSlice, initialSlice + LAST_SLICE));
        initialSlice = initialSlice + LAST_SLICE;
        if (piles.length > initialSlice)
          dataSlice.push(piles.slice(initialSlice, initialSlice + LAST_SLICE));
      } else {
        dataSlice.push(
          piles.slice(
            initialSlice,
            initialSlice + (firstSlice ? FIRST_SLICE : SLICE)
          )
        );
      }
      initialSlice = initialSlice + (firstSlice ? FIRST_SLICE : SLICE);
      if (firstSlice) firstSlice = false;

      size_used = 546; //second Page size used by header and other things
    }
    if (nextPageFlag) {
      dataSlice.push(-1);
    }

    return (
      <>
        {dataSlice.map((data: any, index: any) =>
          printpage(
            data,
            index,
            index === dataSlice.length - 1,
            dataSlice[index + 1] === -1 || index === dataSlice.length - 1
          )
        )}
      </>
    );
  }
  return <></>;
};

export default PreviewPileTable;
