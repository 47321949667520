import React from "react";
import { Path, Circle, Label } from "react-konva";
import { Text } from "react-konva/lib/ReactKonvaCore";
import {
  getControlPoints,
  calculateMiddlePoint,
  getRadiusTextFactor,
} from "../../../tool-utils/radiusUtils.ts";
import { calculateNextPoint, reverseAngle } from "../../../utils";

const createRoundedPath = (
  p1: { x: number; y: number },
  p2: { x: number; y: number },
  p3: { x: number; y: number },
  cornerRadius: number,
  isShapeDrawn: boolean = false,
  isCircular: boolean = false
) => {
  const [q1, q2] = getControlPoints(p1, p2, p3, cornerRadius);

  let m1,
    m2,
    lineStartPoint,
    linePoint,
    lineEndPoint,
    textSide = {};
  let radiusLinesPath,
    dashedLinesPath = ``;

  let angle;

  if (isShapeDrawn && cornerRadius > 1) {
    m1 = {
      x: (q1.x + p2.x) / 2,
      y: (q1.y + p2.y) / 2,
    };

    m2 = {
      x: (p2.x + q2.x) / 2,
      y: (p2.y + q2.y) / 2,
    };

    //Middle point of the curve from where the line will be starting
    lineStartPoint = {
      x: (m1.x + m2.x) / 2,
      y: (m1.y + m2.y) / 2,
    };

    //Line 1's Legth edge-q1
    const dx = p2.x - p1.x;
    const dy = p2.y - p1.y;
    const dist_1 = Math.sqrt(dx * dx + dy * dy);

    //Line 2's Legth edge-q2
    const dx1 = p3.x - p2.x;
    const dy1 = p3.y - p2.y;
    const dist_2 = Math.sqrt(dx1 * dx1 + dy1 * dy1);

    const dist = dist_1 > dist_2 ? dist_1 : dist_2;

    //Line Angle of the smallest line
    let lineAngle;
    if (dist_1 < dist_2) {
      lineAngle = reverseAngle((Math.atan2(dy, dx) * 180) / Math.PI);
    } else {
      lineAngle = (Math.atan2(dy1, dx1) * 180) / Math.PI;
    }
    const side1 = dist_1 > dist_2 ? p1 : p3;
    const side2 = calculateNextPoint(p2, lineAngle, dist);

    //ending point of the line
    linePoint = {
      x: (side1.x + side2.x) / 2,
      y: (side1.y + side2.y) / 2,
    };

    let dX = linePoint.x - lineStartPoint.x;
    let dY = linePoint.y - lineStartPoint.y;
    let dashedLineAngle = Math.atan2(dY, dX) * (180 / Math.PI);
    lineEndPoint = calculateNextPoint(lineStartPoint, dashedLineAngle, 20);

    //showing the line in between two joining lines
    radiusLinesPath = ` M${lineStartPoint.x},${lineStartPoint.y}
        L${lineEndPoint.x},${lineEndPoint.y}`;

    //dashed line where the actual shape could be
    dashedLinesPath = `M${p2.x},${p2.y}
        L${q1.x},${q1.y}
        M${p2.x},${p2.y}
        L${q2.x},${q2.y}`;

    //angle in which direction text will be shown
    let deltaX, deltaY;
    if (lineEndPoint.x > lineStartPoint.x) {
      deltaX = lineEndPoint.x - lineStartPoint.x;
      deltaY = lineEndPoint.y - lineStartPoint.y;
    } else {
      deltaX = lineStartPoint.x - lineEndPoint.x;
      deltaY = lineStartPoint.y - lineEndPoint.y;
    }
    angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);

    //text factor is applied by checking points
    const textFactor = getRadiusTextFactor(lineStartPoint, lineEndPoint);
    //Points where text will be shown
    textSide = calculateNextPoint(
      lineStartPoint,
      dashedLineAngle + textFactor.angleFactor,
      14
    );
  }

  const path = `M${p1.x},${p1.y}
        L${q1.x},${q1.y}
        Q${p2.x},${p2.y} ${q2.x},${q2.y}
        L${p3.x},${p3.y}`;

  return (
    <>
      {isShapeDrawn && cornerRadius > 1 ? (
        <>
          <Path
            key={Math.random().toString()}
            data={dashedLinesPath}
            stroke="#D7D9DC"
            strokeWidth={0.6}
            dash={[2, 1]}
          />
          {!isCircular && (
            <>
              <Circle
                id={Math.random().toString()}
                x={lineEndPoint.x}
                y={lineEndPoint.y}
                radius={1}
                fill="#878C97"
              />
              <Path
                key={Math.random().toString()}
                data={radiusLinesPath}
                stroke="#878C97"
                strokeWidth={0.4}
                dash={[1, 1]}
              />
              <Label
                key={Math.random().toString()}
                text={`r=${cornerRadius}`}
                x={textSide.x}
                y={textSide.y}
                rotation={angle}
              >
                <Text
                  text={`r=${cornerRadius}`}
                  fontSize={2.5}
                  padding={0}
                  fill="#878C97"
                />
              </Label>
            </>
          )}
        </>
      ) : (
        <></>
      )}
      <Path
        key={Math.random().toString()}
        data={path}
        stroke="black"
        strokeWidth={0.8}
        lineCap="round"
      />
    </>
  );
};

const semiCircularShapeLines = (line1, line2, line3, radius, lineAngle) => {
  const middleLine1 = calculateMiddlePoint(
    line1.x1,
    line1.y1,
    line1.x2,
    line1.y2
  );
  const middleLine2 = calculateMiddlePoint(
    line2.x1,
    line2.y1,
    line2.x2,
    line2.y2
  );
  const middleLine3 = calculateMiddlePoint(
    line3.x1,
    line3.y1,
    line3.x2,
    line3.y2
  );

  const edge1 = { x: line1.x2, y: line1.y2 };
  const edge2 = { x: line2.x2, y: line2.y2 };

  const [q1, q2] = getControlPoints(middleLine1, edge1, middleLine2, radius);
  const [q3, q4] = getControlPoints(middleLine2, edge2, middleLine3, radius);

  //Semi circular Line Points
  const startPoint = { x: q1.x, y: q1.y };
  const midPoint = { x: (q1.x + q4.x) / 2, y: (q1.y + q4.y) / 2 };
  const endPoint = { x: q4.x, y: q4.y };

  //Line2's mid of the first half
  const midPoint1 = {
    x: (line1.x2 + middleLine2.x) / 2,
    y: (line1.y2 + middleLine2.y) / 2,
  };

  //mid of the solid semi circular line
  const midPoint2 = {
    x: (startPoint.x + midPoint.x) / 2,
    y: (startPoint.y + midPoint.y) / 2,
  };

  //calculates the angle for the radius value
  const dX = midPoint1.x - midPoint2.x;
  const dY = midPoint1.y - midPoint2.y;
  const dist = Math.sqrt(dX * dX + dY * dY);
  const radiusLabelAngle = Math.atan2(dY, dX) * (180 / Math.PI);

  //Problem (r=value not in center)
  //To position it in center subtracted a value from the start
  //of the line 2 till mid of line2's half then found the starting
  //point of r value from there
  const rX = line2.x1 - midPoint1.x;
  const rY = line2.y1 - midPoint1.y;
  let rDist = Math.sqrt(rX * rX + rY * rY);
  const newAdjustedPoints = calculateNextPoint(
    { x: line2.x1, y: line2.y1 },
    lineAngle,
    rDist - 3
  );

  //found here exact points by using prev points
  const radiusPoint = calculateNextPoint(
    newAdjustedPoints,
    reverseAngle(radiusLabelAngle),
    dist + 3
  );

  //
  const dX1 = line2.x1 - middleLine2.x;
  const dY1 = line2.y1 - middleLine2.y;
  const dist1 = Math.sqrt(dX1 * dX1 + dY1 * dY1);
  const adjustedCircularTextPoint = calculateNextPoint(
    { x: line2.x1, y: line2.y1 },
    lineAngle,
    dist1 - 7
  );

  const semiCircularTextPoint = calculateNextPoint(
    adjustedCircularTextPoint,
    reverseAngle(radiusLabelAngle),
    dist - 4
  );

  //Solid Line
  const circularLinePath = ` M${startPoint.x},${startPoint.y}
        L${midPoint.x},${midPoint.y}`;

  //Dashed Line
  const circularDashedLinePath = ` M${midPoint.x},${midPoint.y}
        L${endPoint.x},${endPoint.y}`;

  return (
    <>
      <Path
        key={Math.random().toString()}
        data={circularLinePath}
        stroke="#878C97"
        strokeWidth={0.3}
      />
      <Circle
        id={Math.random().toString()}
        x={midPoint.x}
        y={midPoint.y}
        radius={0.9}
        fill="#878C97"
      />
      <Path
        key={Math.random().toString()}
        data={circularDashedLinePath}
        stroke="#878C97"
        strokeWidth={0.3}
        dash={[1, 1]}
      />
      <Label
        key={Math.random().toString()}
        text={`r = ${radius}`}
        x={radiusPoint.x}
        y={radiusPoint.y}
        rotation={lineAngle}
      >
        <Text
          text={`r = ${radius}`}
          fontSize={2.2}
          padding={0}
          fill="#878C97"
        />
      </Label>
      <Label
        key={Math.random().toString()}
        text={`semi circular`}
        x={semiCircularTextPoint.x}
        y={semiCircularTextPoint.y}
        rotation={lineAngle}
      >
        <Text
          text={`semi circular`}
          fontSize={2.8}
          padding={0}
          fill="#878C97"
        />
      </Label>
    </>
  );
};

const RoundedCornerPath = (props) => {
  const { lines, lineEdges, freeLengths, freeAngles, freeLineAngles } = props;

  const firstLine = lines[0];
  const startPoint = { x: firstLine.x1, y: firstLine.y1 };
  const firstLineMiddle = calculateMiddlePoint(
    firstLine.x1,
    firstLine.y1,
    firstLine.x2,
    firstLine.y2
  );
  const pathStart = createRoundedPath(
    startPoint,
    firstLineMiddle,
    firstLineMiddle,
    0
  );

  let circulaShapeLines = [];
  let circularEdges = [];
  freeLengths.forEach((val, index) => {
    if (index < freeLengths.length - 2) {
      const firstShortest =
        val.length < freeLengths[index + 1].length
          ? val
          : freeLengths[index + 1];
      const secondShortest =
        freeLengths[index + 1].length < freeLengths[index + 2].length
          ? freeLengths[index + 1]
          : freeLengths[index + 2];
      if (
        firstShortest.id === secondShortest.id &&
        Math.abs(freeAngles[index].angle) === 90 &&
        Math.abs(freeAngles[index + 1].angle) === 90 &&
        freeLineAngles[index].angle !== freeLineAngles[index + 2].angle
      ) {
        const maxRadius = firstShortest.length / 2;
        if (
          Number(lineEdges[index].cornerRadius) === maxRadius &&
          Number(lineEdges[index + 1].cornerRadius) === maxRadius
        ) {
          circularEdges.push(lineEdges[index], lineEdges[index + 1]);
          circulaShapeLines.push(
            semiCircularShapeLines(
              lines[index],
              lines[index + 1],
              lines[index + 2],
              maxRadius,
              freeLineAngles[index + 1].angle
            )
          );
        }
      }
    }
  });

  const roundedPaths = lineEdges.map((edge) => {
    const [line1, line2] = edge.lineIds.map((id) =>
      lines.find((line) => line.id === id)
    );
    const middle1 = calculateMiddlePoint(
      line1.x1,
      line1.y1,
      line1.x2,
      line1.y2
    );
    const middle2 = calculateMiddlePoint(
      line2.x1,
      line2.y1,
      line2.x2,
      line2.y2
    );

    const checkIfCircular = circularEdges.some((obj) => obj.id === edge.id);
    return createRoundedPath(
      middle1,
      { x: edge.x, y: edge.y },
      middle2,
      edge.cornerRadius,
      true,
      checkIfCircular
    );
  });

  const lastLine = lines[lines.length - 1];
  const lastLineMiddle = calculateMiddlePoint(
    lastLine.x1,
    lastLine.y1,
    lastLine.x2,
    lastLine.y2
  );
  const endPoint = { x: lastLine.x2, y: lastLine.y2 };
  const pathEnd = createRoundedPath(
    lastLineMiddle,
    lastLineMiddle,
    endPoint,
    0
  );

  return (
    <>
      {pathStart}
      {circulaShapeLines}
      {roundedPaths}
      {pathEnd}
    </>
  );
};

export default RoundedCornerPath;
