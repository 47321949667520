import { Modal } from "react-bootstrap";
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";

import "../index.scss";

function SendConfirm({ ...props }) {
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const { t } = useTranslation(["emailOrder", "common"]);

  const { show, onHide, nextPopup } = props;

  const handleNext = () => {
    onHide();
    nextPopup();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        className="send_supplier_email_popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <>
          <div className="container send_email_container">
            <div className="send_email_content">
              <p className="send_email_text">
                {t("sending order line 1", { ns: "emailOrder" })}{" "}
                {rightToLeft && <br />}
                <span style={{ color: "#374051", fontWeight: "700" }}>
                  {t("sending order line 2", { ns: "emailOrder" })}
                </span>
              </p>
              <div
                className="order_share_popup_buttons"
                style={rightToLeft ? { flexDirection: "row-reverse" } : {}}
              >
                <button
                  className="send_email_button "
                  style={{
                    marginRight: "10px",
                    color: "#AFB3B9",
                    borderColor: "#AFB3B9",
                  }}
                  onClick={onHide}
                >
                  {t("cancel", { ns: "common" })}
                </button>

                <button className="send_email_button " onClick={handleNext}>
                  {t("confirm", { ns: "common" })}
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}

export default SendConfirm;
