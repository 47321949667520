import "./index.css";
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert';
import brand_logo from "../../assets/img/logo.svg";
import easybar_desktop_screen from "../../assets/img/login/easybar_screen_half.png";
import easybar_phone_screen from "../../assets/img/login/easybar_screen.png";

import classNames from "classnames"
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import {resendVerificationEmail, signup} from "../../services/user";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "Redux/hooks";
import { timeout_secs} from "../../constants/alert-modal";

import useRefEffect from "../../utils/hooks/use-RefEffect.js"

const noErrors = {
  firstName: false,
  lastName: false,
  jobTitle: false,
  email: false,
  password: false,
  confirmPassword:false,
  phone: false,
  newsPromos: false,
  termsAggrement: false,
};

function LandingPage() {
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const { t } = useTranslation(["common","signup"]);

  const [signUpDetails,setSignUpDetails]=useState({ 
    firstName: "",
    lastName: "",
    jobTitle: "",
    email: "",
    password: "",
    confirmPassword:"",
    phone: "",
    newsPromos: true,
    termsAggrement: false,
    errorMessage:""
  });
  const [error, setError] = useState(noErrors);
  const screens={
    SIGNUP: "signup",
    EMAIL_SENT: "email_sent",
    EMAIL_NOT_RECEIVED: "email_not_received",
    LANDING_SCREEN: "landing_screen",
    CHECK_SPAM: "check_spam"
  }
  const [screenToShow, setScreenToShow] = useState(screens.LANDING_SCREEN);
  const [emailSentAlert, setEmailSentAlert] = useState(false);

  const router = useHistory();

  const emailBoxRef = useRefEffect({ screenToShow }); //custom-Hook

  useEffect(()=>{
    let newErrors = {...error};
    if(!signUpDetails.termsAggrement){
      newErrors={...newErrors, termsAggrement: true};
    }else{
      newErrors={...newErrors, termsAggrement: false};
    }
    if(signUpDetails.password.trim() !== "" && signUpDetails.password.length < 4){
      newErrors={...newErrors, password: true};
    }else{
      newErrors={...newErrors, password: false};
    }
    if(signUpDetails.password.trim() !== "" 
        && signUpDetails.confirmPassword.length < 4 
        && signUpDetails.confirmPassword !== signUpDetails.password
      ){
        newErrors={...newErrors, confirmPassword: true};
    }else{
      newErrors={...newErrors, confirmPassword: false};
    }
    if (signUpDetails.phone!=="" && !(/^0\d{1,2}-?\d{3}-?\d{4}$/.test(signUpDetails.phone))){
      newErrors = { ...newErrors, phone: true };
    } else{
      newErrors = { ...newErrors, phone: false };
    }
    setError(newErrors);

  },[signUpDetails])

  const showLoginScreen=()=>{
    router.push('/login');
  }

  const showTermsScreen=()=>{
    window.open("/terms-and-conditions", "_blank")
  }

  const showPrivacyScreen=()=>{
    window.open("/privacy-policy", "_blank")
  }
  const sendVerificationEmail = async()=>{
    await resendVerificationEmail(signUpDetails.email);
    //checks should be added for different cases
    setScreenToShow(screens.CHECK_SPAM);
    setEmailSentAlert(true)
    setTimeout(() => {
      setEmailSentAlert(false);
    }, timeout_secs);
  }

  const createUserAccount=async(e: React.SyntheticEvent)=>{
    e.preventDefault();
    const res = await signup({
      first_name: signUpDetails.firstName,
      last_name:signUpDetails.lastName,
      job_title: signUpDetails.jobTitle,
      email: signUpDetails.email.toLowerCase(),
      password: signUpDetails.password,
      phone: signUpDetails.phone,
      terms_agreement: signUpDetails.termsAggrement,
      news_promos: signUpDetails.newsPromos
      });

      if(res && res.status === 400){
        setSignUpDetails({...signUpDetails, errorMessage: res.data.email})
        return;
      }

      if (res) {
        setScreenToShow(screens.CHECK_SPAM);
        if(signUpDetails.errorMessage){
          setSignUpDetails({...signUpDetails, errorMessage: ""})
        }
      }
  }
  
  const signupForm = (
    <>
      <div className="signup__screen col-md-10 col-sm-12">
        <img className="brand__icon-cu" src={brand_logo} alt="brand icon" />
        <p className="create__account__text oscar__small_web">
          {t("get started",{ns: "signup"})}
        </p>
        <form onSubmit={createUserAccount}>
          <div className="user__name">
            {/* first name */}
            <div className="flex-one">
              <label className="input__label mb-1">{t("first name", {ns:"signup"})}</label>
              <input
                className={classNames("input__box", {
                  invalid: error.firstName,
                })}
                type="text"
                value={signUpDetails.firstName}
                required
                onChange={(e) =>
                  setSignUpDetails({
                    ...signUpDetails,
                    firstName: e.target.value,
                  })
                }
              ></input>
            </div>
            {/* last name */}
            <div className={"flex-one last_name__margin "+ (rightToLeft ? 'last_name__margin_right':'last_name__margin_left')}>
              <label className="input__label mb-1">{t("last name", {ns:"signup"})}</label>
              <input
                className={classNames("input__box", {
                  invalid: error.lastName,
                })}
                type="text"
                value={signUpDetails.lastName}
                required
                onChange={(e) =>
                  setSignUpDetails({
                    ...signUpDetails,
                    lastName: e.target.value,
                  })
                }
              ></input>
            </div>
          </div>
          {/* email */}
          <div className="mb-3">
            <label className="input__label mb-1">{t("email", {ns:"signup"})}</label>
            <input
              className={classNames("input__box", { invalid: error.email })}
              type="email"
              placeholder="example@email.com"
              value={signUpDetails.email}
              required
              onChange={(e) =>
                setSignUpDetails({ ...signUpDetails, email: e.target.value })
              }
              ref={emailBoxRef}
            ></input>
          </div>
          {/* phone number */}
          <div className="mb-3">
            <label className="input__label mb-1">{t("number", {ns:"signup"})}</label>
            <input
              className={classNames("input__box", { invalid: error.phone })}
              type="number"
              placeholder={t("numbers only", {ns: "signup"})}
              value={signUpDetails.phone}
              required
              onChange={(e) =>
                setSignUpDetails({ ...signUpDetails, phone: e.target.value })
              }
              pattern="^0\d{1,2}-?\d{3}-?\d{4}$"
            ></input>
          </div>
          {/* password */}
          <div className="mb-3">
            <label className="input__label mb-1">{t("password", {ns:"signup"})}</label>
            <input
              className={classNames("input__box", { invalid: error.password })}
              type="password"
              placeholder={t("min char", {ns: "signup"})}
              value={signUpDetails.password}
              required
              onChange={(e) =>
                setSignUpDetails({ ...signUpDetails, password: e.target.value })
              }
            ></input>
          </div>
          {/* password again */}
          <div>
            <label className="input__label mb-1">{t("password again", {ns:"signup"})}</label>
            <input
              className={classNames("input__box", {
                invalid: error.confirmPassword,
              })}
              type="password"
              placeholder={t("password match", {ns:"signup"})}
              value={signUpDetails.confirmPassword}
              required
              onChange={(e) =>
                setSignUpDetails({
                  ...signUpDetails,
                  confirmPassword: e.target.value,
                })
              }
            ></input>
          </div>
          <div className="checkbox">
            <input
              className="signup_checkbox_input"
              type="checkbox"
              checked={signUpDetails.termsAggrement}
              onChange={(e) =>
                setSignUpDetails({
                  ...signUpDetails,
                  termsAggrement: !signUpDetails.termsAggrement,
                })
              }
            ></input>
            <label className={"checkbox__label "+ (rightToLeft ? "checkbox__align_left":"checkbox__align_right")}>
              <div className={rightToLeft ? "checkbox_label_align_left" : "checkbox_label_align_right"}>
                <span>{t("confirm",{ns: "signup"})}</span>{" "}
                <span className="highlighted__text" onClick={showTermsScreen}>
                  {t("terms of use",{ns: "signup"})}
                </span>{" "}
                <span>{t("and",{ns: "signup"})}</span>{" "}
                <span className="highlighted__text" onClick={showPrivacyScreen}>
                  {t("policy",{ns: "signup"})}
                </span>{" "}
                <span>{t("agree",{ns: "signup"})}</span>
              </div>
            </label>
          </div>
          <div className="checkbox">
            <input
              className="signup_checkbox_input"
              id="checkbox"
              type="checkbox"
              checked={signUpDetails.newsPromos}
              onChange={(e) =>
                setSignUpDetails({
                  ...signUpDetails,
                  newsPromos: !signUpDetails.newsPromos,
                })
              }
            ></input>
            <label className={"checkbox__label "+ (rightToLeft ? "checkbox__align_left":"checkbox__align_right")}>
              <div className={rightToLeft ? "checkbox_label_align_left" : "checkbox_label_align_right"}>
                {t("marketing email", { ns: "signup" })}
              </div>
            </label>
          </div>
          <p className="error__message">{signUpDetails.errorMessage}</p>
          <div className="create__account__button">
            <button
              className="next__button my-2"
              type="submit"
              disabled={
                signUpDetails.password.length < 4 ||
                Object.values(error).some((val) => val === true)
              }
            >
              {t("next", {ns: "signup"})}
            </button>
          </div>
          <div className="d-flex create__account__texts mt-3">
            <p className="right__text">{t("already have account", {ns: "signup"})}</p>
            <p
              className="right__text highlighted__text mx-3"
              onClick={showLoginScreen}
            >
              {t("login to account", {ns: "signup"})}
            </p>
          </div>
        </form>
      </div>
    </>
  );
  
  const emailSentText=
  <div className="email__sent__screen  col-md-10 col-sm-12">
    <img className="brand__icon" src={brand_logo} style={{width: '290px',marginBottom: '0px'}} alt="brand icon" />
    <p className="email__sent__text">{t("email verification", {ns: "signup"})}</p>
    <p className="text2 mr-0 ml-0" onClick={()=>setScreenToShow(screens.EMAIL_NOT_RECEIVED) }>{t("not recieved", {ns: "signup"})}</p>
  </div>


  const emailCheckSpam=
  <div className="email__sent__screen  col-md-10">
    <img className="brand__icon" src={brand_logo} style={{width: '290px',marginBottom: '0px'}} alt="brand icon" />
    <p className="email__sent__text">{t("email verification", {ns: "signup"})}</p>
    <p className="text1 mr-0 ml-0">{t("not receive email", {ns: "signup"})}<br/>{t("check spam note", {ns: "signup"})}</p>
    <p className="text1 mr-0 ml-0">{t("not get it", {ns: "signup"})} {signUpDetails.email} ? </p>
    <p className="text2 mr-0 ml-0"  style={{marginTop:'-40px'}} onClick={sendVerificationEmail}>{t("resend email", {ns: "signup"})}</p>
    <p className="text1 mr-0 ml-0">{t("still can't find it", {ns: "signup"})}<br/>{t("send us a message", {ns: "signup"})}
    <a className="text2 mt-4" style={{marginRight:'-9px'}} href="https://api.whatsapp.com/message/E7GHBYJXF4DBN1?autoload=1&app_absent=0" target="_blank" rel="noopener noreferrer">{t("whatsapp", {ns: "signup"})}</a> </p>
  </div>
  
  const emailNotReceived=
  <div className="email__sent__screen  col-md-10">
    <img className="brand__icon" src={brand_logo} style={{width: '290px',marginBottom: '0px'}} alt="brand icon" />
    <p className="email__sent__text">{t("email verification", {ns: "signup"})}</p>
    <p className="text2 mr-0 ml-0">{t("confirm email", {ns: "signup"})}</p>
    <p className="user__email">{signUpDetails.email}</p>
    <div className="">
      <button className="next__button my-2" onClick={()=>setScreenToShow(screens.CHECK_SPAM)}>{t("true", {ns: "signup"})}</button>
      <button className="cancel__button my-2 mx-3" onClick={()=>setScreenToShow(screens.SIGNUP)}>{t("deny", {ns: "signup"})}</button>
    </div>
  </div>

  const mobileLandingscreen = (
    <>
      <div className="flex justify-content-center mobile__screen">
        <img
          className=""
          src={easybar_phone_screen}
          alt="brand icon"
          style={{ width: "100%" }}
        />
      </div>
      <div className="lower__section">
        <img className="mobile__icon mt-5" src={brand_logo} alt="brand icon" />
        <p className="oscar__medium_mobile my-5 mx-4">
          {t("tagline", { ns: "signup" })}
        </p>
        <p className="right__text mx-2">{t("free use", { ns: "signup" })}</p>
        <button
          className="mobile__button1 my-2"
          onClick={() => setScreenToShow(screens.SIGNUP)}
        >
          {t("signup", { ns: "signup" })}
        </button>
        <p className="right__text mt-5 mx-2">{t("already have account", { ns: "signup" })}</p>
        <button className="mobile__button2 my-2" onClick={showLoginScreen}>
          {t("login", { ns: "signup" })}
        </button>
      </div>
    </>
  );

const desktopLandingscreen= 
<>
  <div className="">
    <img
      className="mobile__icon mt-5"
      src={brand_logo}
      alt="brand icon"
    />
    <p className={"oscar__large_web my-5 " + (rightToLeft ? 'ml-5': 'mr-5')}>
      {t("tagline", { ns: "signup" })}  
    </p>
    <div className="d-flex">
      <button className="web__button1 my-2" onClick={()=>setScreenToShow(screens.SIGNUP)}>{t("signup", { ns: "signup" })}</button>
      <button className="web__button2 my-2 mx-3" onClick={showLoginScreen}>{t("login", { ns: "signup" })}</button>
    </div>
    <div className="d-flex mt-5">
      <p className="right__text mx-2">{t("already have account", { ns: "signup" })}</p>
      <p className="right__text mx-2 highlighted__text" onClick={showLoginScreen}>{t("login to account", { ns: "signup" })}</p>
    </div>
  </div>
</>
  
  
  return (
    <div className="main__landing__page">
      {isMobile ? (
        <>
        {  screenToShow === screens.LANDING_SCREEN ? mobileLandingscreen 
            : screenToShow === screens.EMAIL_SENT ? emailSentText 
            : screenToShow === screens.SIGNUP     ? signupForm 
            : screenToShow === screens.CHECK_SPAM ? emailCheckSpam
            : screenToShow === screens.EMAIL_NOT_RECEIVED && emailNotReceived 
          }
        </>
      ) : (
        <div className="row login">
          <>
            <div className="col-sm-6 right__section">
              {emailSentAlert? <Alert variant={'success'}>{t("verification email sent", { ns: "signup" })}</Alert>: <></>}
              { screenToShow === screens.LANDING_SCREEN ? desktopLandingscreen
                : screenToShow === screens.EMAIL_SENT ? emailSentText 
                : screenToShow === screens.SIGNUP   ? signupForm 
                : screenToShow === screens.CHECK_SPAM ? emailCheckSpam
                : screenToShow === screens.EMAIL_NOT_RECEIVED && emailNotReceived
              }
            </div>
            <div className="col-sm-6 left__section pl-0 ml-0">
              <img
                className="easybar__screen"
                src={easybar_desktop_screen}
                alt="brand icon"
              />
            </div>
          </>
        </div>
      )}
    </div>
  );
}

export default LandingPage;
