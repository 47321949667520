import React from "react";
import './loader.scss'
const Loader = () => {
  const index = [];
  for (let i = 0; i < 84; i++) {
    index.push(i);
  }
  return (
    <div className="progressBar">
      <div className="strips">
        {index?.map((i) => (
          <div className={"strip-" + i}></div>
        ))}
      </div>
    </div>
  );
};

export default Loader;
