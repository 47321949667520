import React, { useEffect, useMemo, useState } from "react";
import { Stage, Layer, Shape, Circle, Text } from "react-konva";
import { Html } from "react-konva-utils";
import {
  calculateNextPoint,
  calculateSidePoints,
  calculateSidePointsSplitTwo,
} from "Components/tool/utils";
import { isMobile } from "react-device-detect";

function BaseStage({
  canvasWidth,
  canvasHeight,
  shapeObj,
  base,
  sideSpilt,
  setShape,
}: any) {
  const [dots, setDots] = useState([] as any);

  const center = useMemo(() => {
    return {
      x: canvasWidth / 2,
      y: canvasHeight / 2,
    };
  }, [canvasWidth, canvasHeight]);
  const [innerRadius, setInnerRadius] = useState(0);
  const [outterRadius, setOutterRadius] = useState(0);

  useEffect(() => {
    const radius = shapeObj.cage_diameter / 2;
    setInnerRadius(radius);
    const oRadius = shapeObj.hole_diameter / 2;
    setOutterRadius(oRadius);

    const pad = 6;
    let pointsOfinnerCircle = [];
    if (base && shapeObj.base_amount >= 2) {
      const gap = Math.ceil(360 / shapeObj.base_amount);
      for (let i = 360; i > 1; i = i - gap) {
        pointsOfinnerCircle.push(
          calculateNextPoint({ x: center.x, y: center.y }, i, radius - pad)
        );
      }
    } else {
      if (sideSpilt === 3) {
        pointsOfinnerCircle = calculateSidePoints(
          shapeObj,
          center.x,
          center.y,
          radius,
          pad
        );
      } else {
        pointsOfinnerCircle = calculateSidePointsSplitTwo(
          shapeObj,
          center.x,
          center.y,
          radius,
          pad
        );
      }
    }
    setDots(pointsOfinnerCircle);
  }, [
    base,
    shapeObj,
    shapeObj.base_amount,
    shapeObj.hole_diameter,
    shapeObj.cage_diameter,
    sideSpilt,
    center.x,
    center.y,
  ]);

  return (
    <Stage width={canvasWidth} height={canvasHeight}>
      <Layer>
        <Shape
          sceneFunc={(context, shape) => {
            if (shapeObj && canvasHeight > 150 && canvasWidth > 150) {
              context.beginPath();
              context.arc(
                center.x,
                center.y,
                outterRadius,
                0,
                2 * Math.PI,
                1.5 * Math.PI
              );
              context.fillStrokeShape(shape);
            }
          }}
          stroke="black"
          strokeWidth={2}
        />
        <Shape
          sceneFunc={(context, shape) => {
            if (shapeObj && canvasHeight > 150 && canvasWidth > 150) {
              context.beginPath();
              context.arc(
                center.x,
                center.y,
                innerRadius,
                0,
                2 * Math.PI,
                1.5 * Math.PI
              );
              context.fillStrokeShape(shape);
            }
          }}
          stroke="black"
          strokeWidth={4}
        />
        {dots.map((dt: any, index: number) => (
          <Circle
            key={"baseDot-" + index}
            x={dt.x}
            y={dt.y}
            radius={3}
            fill="black"
          />
        ))}

        <Shape
          sceneFunc={(context, shape) => {
            if (shapeObj && canvasHeight > 150 && canvasWidth > 150) {
              context.beginPath();

              const midX = canvasWidth / 2;
              const midY = canvasHeight / 2;

              // <--------------------- bottom lines------------------------------------->
              //upper horizaontal
              const bottomPoint = { x: midX, y: midY + innerRadius };
              let actualStartingPoint = { x: bottomPoint.x, y: bottomPoint.y };
              let nextPoint = calculateNextPoint(
                actualStartingPoint,
                180,
                1.5 * outterRadius
              );
              context.moveTo(actualStartingPoint.x, actualStartingPoint.y);
              context.lineTo(nextPoint.x, nextPoint.y);

              //vertical line for d'
              actualStartingPoint = nextPoint;
              nextPoint = calculateNextPoint(
                actualStartingPoint,
                90,
                outterRadius - innerRadius
              );
              context.moveTo(actualStartingPoint.x, actualStartingPoint.y);
              context.lineTo(nextPoint.x, nextPoint.y);

              actualStartingPoint = nextPoint;
              nextPoint = calculateNextPoint(
                actualStartingPoint,
                360,
                1.5 * outterRadius
              );
              context.moveTo(actualStartingPoint.x, actualStartingPoint.y);
              context.lineTo(nextPoint.x, nextPoint.y);

              // <--------------------- upper lines------------------------------------->
              //upper horizaontal
              const upperPoint = { x: midX, y: midY - outterRadius };
              actualStartingPoint = { x: upperPoint.x, y: upperPoint.y };
              nextPoint = calculateNextPoint(
                actualStartingPoint,
                360,
                outterRadius
              );
              context.moveTo(actualStartingPoint.x, actualStartingPoint.y);
              context.lineTo(nextPoint.x, nextPoint.y);

              //vertical line
              actualStartingPoint = nextPoint;
              nextPoint = calculateNextPoint(
                actualStartingPoint,
                90,
                2 * outterRadius
              );
              context.moveTo(actualStartingPoint.x, actualStartingPoint.y);
              context.lineTo(nextPoint.x, nextPoint.y);

              actualStartingPoint = nextPoint;
              nextPoint = calculateNextPoint(
                actualStartingPoint,
                180,
                outterRadius
              );
              context.moveTo(actualStartingPoint.x, actualStartingPoint.y);
              context.lineTo(nextPoint.x, nextPoint.y);

              // (!) Konva specific method, it is very important
              context.fillStrokeShape(shape);
            }
          }}
          stroke="grey"
          strokeWidth={1}
        />

        {isMobile && (
          <>
            <Text
              text={shapeObj.hole_diameter}
              x={canvasWidth / 2 + shapeObj.hole_diameter / 2 + 10}
              y={canvasHeight / 2 - 10}
            />
            <Text
              text={"d'" + shapeObj.cover / 2}
              x={canvasWidth / 2 - shapeObj.cage_diameter}
              y={canvasHeight / 2 + shapeObj.hole_diameter / 2 - 10}
            />
          </>
        )}

        {!isMobile && (
          <>
            <Text
              fontSize={14}
              text={"D"}
              x={canvasWidth / 2 + shapeObj.hole_diameter / 2 + 12}
              y={canvasHeight / 2 - 40}
            />
            <Html
              divProps={{
                style: {
                  position: "absolute",
                  top: `${canvasHeight / 2 - 25}px`,
                  left: `${
                    canvasWidth / 2 + shapeObj.hole_diameter / 2 + 30
                  }px`,
                  transform: `rotate(90deg)`,
                },
              }}
            >
              <input
                style={{ width: "50px" }}
                className="inputField"
                type="number"
                onFocus={(e) => e.target.select()}
                value={shapeObj.hole_diameter || ""}
                onChange={(e) =>
                  setShape({
                    ...shapeObj,
                    hole_diameter: parseInt(e.target.value),
                    cover:
                      (parseInt(e.target.value) - shapeObj.cage_diameter) / 2,
                  })
                }
              />
            </Html>

            <Text
              text={"d'"}
              x={canvasWidth / 2 - shapeObj.cage_diameter}
              y={canvasHeight / 2 + shapeObj.hole_diameter / 2 + 7}
            />
            <Html
              divProps={{
                style: {
                  position: "absolute",
                  top: `${canvasHeight / 2 + shapeObj.hole_diameter / 2 + 2}px`,
                  left: `${canvasWidth / 2 - shapeObj.cage_diameter + 10}px`,
                },
              }}
            >
              <input
                style={{ width: "50px" }}
                className="inputField"
                type="number"
                value={shapeObj.cover / 2 || ""}
                onFocus={(e) => e.target.select()}
                onChange={(e) =>
                  setShape({
                    ...shapeObj,
                    cover: parseInt(e.target.value),
                    cage_diameter:
                      shapeObj.hole_diameter - 2 * parseFloat(e.target.value),
                  })
                }
              />
            </Html>
          </>
        )}
      </Layer>
    </Stage>
  );
}

export default BaseStage;
