import CompanyDetails from "./companyDetails";
import SectionHeader from "../section-header/sectionHeader";
import { useEffect } from "react";
import { useAppDispatch,useAppSelector } from "Redux/hooks";
import { fetchBusinesses } from "Redux/middlwares/business";
import { patchUser } from "Redux/middlwares/user";
import { useTranslation } from "react-i18next";
import AddCompany from "./addCompany";

const CompaniesList = (props) => {
  const {
    onNewCompanyModalOpen,
    handleEditCompany,
    setActiveCompanyEdit,
    setCompanyToDelete,
    setActiveCompany,
    activeCompany,
    setDeleteMessageShow,
    userData,
    userBusinesses,
  } = props;
  
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "userProfile"]);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  
  useEffect(() => {
    dispatch(fetchBusinesses());
  }, [dispatch]);

  useEffect(() => {
    if (userBusinesses.length > 0) {
      const data = { current_company: activeCompany, };
      dispatch(patchUser(userData.id, data));
    }
  }, [activeCompany]);

  return (
    <section style={{ background: "white", marginBottom: "50px" }}>
      <SectionHeader
        heading={t("Companies list", { ns: "userProfile" })}
        icon="briefcase"
      />
      <AddCompany
        onNewCompanyModalOpen={onNewCompanyModalOpen}
        userBusinesses={userBusinesses}
        setActiveCompany={setActiveCompany}
        activeCompany={activeCompany}
        userData={userData}
      />
      <div style={{ paddingTop: "50px" }}>
        {userBusinesses.length > 0 ? (
          userBusinesses.map((item, index) => {
            return (
              <CompanyDetails
                key={item.id}
                businessData={item}
                handleEditCompany={handleEditCompany}
                setActiveCompanyEdit={setActiveCompanyEdit}
                setCompanyToDelete={setCompanyToDelete}
                setDeleteMessageShow={setDeleteMessageShow}
              />
            );
          })
        ) : (
          <p className={rightToLeft ? 'noCompany__left':'noCompany__right'}>{t("No Company", { ns: "userProfile" })}</p>
        )}
      </div>
    </section>
  );
};

export default CompaniesList;
