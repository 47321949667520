import React, { useEffect, useMemo, useState } from "react";
import AdminLayout from "../../layouts/admin";
import { Button, Card, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";

import {
  getAdminBusinesses,
  getAdminOrders,
  getAdminUsers,
  getStats,
  getAdminSuppliers,
  getClickableStats,
  getMembersCsv,
  getOrdersCsv,
} from "Services/user-analytics";

import { format } from "date-fns";
import CopyIcon from "../../assets/img/admin/copy.svg";
import CopyBookIcon from "../../assets/img/admin/copy-book.svg";
import { blockUsers } from "Services/user";
import { withTranslation } from "react-i18next";
import { DebounceInput } from "react-debounce-input";
import PdfPreviewModal from "../pdf-preview-modal";
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { getCurrentOrderTools } from "Redux/middlwares/order";
import OrderExcel from "Components/order-excel";
import toast, { Toaster } from "react-hot-toast";
import { OrderPdfType } from "Constants/order";
import CsvDownload from "../../assets/img/admin/csv-download.png";

import "./index.scss";

const UserAnalytics = ({ t }: any) => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([] as any);
  const [userSearchParam, setUserSearchParam] = useState("");
  const [userPage, setUserpage] = useState("");
  const [prevPage, setPrevpage] = useState("");
  const [usersIndex, setUsersIndex] = useState(0);
  const [business, setBusiness] = useState([]);
  const [businessPage, setBusinesspage] = useState("");
  const [prevBusinessPage, setPrevBusinesspage] = useState("");
  const [businessIndex, setBusinessIndex] = useState(0);
  const [emailSupplier, setEmailSupplier] = useState([]);
  const [emailSupplierPage, setEmailSupplierPage] = useState("");
  const [prevEmailSupplierPage, setPrevEmailSupplierPage] = useState("");
  const [emailSupplierIndex, setEmailSupplierIndex] = useState(0);
  const [whatsAppSupplier, setWhatsAppSupplier] = useState([]);
  const [whatsAppSupplierPage, setWhatsAppSupplierPage] = useState("");
  const [prevWhatsAppSupplierPage, setPrevWhatsAppSupplierPage] = useState("");
  const [whatsAppSupplierIndex, setWhatsAppSupplierIndex] = useState(0);
  const [orders, setOrders] = useState([]);
  const [ordersPage, setOrderspage] = useState("");
  const [prevOrdersPage, setPrevOrderspage] = useState("");

  const [bids, setBids] = useState([]);
  const [bidsPage, setBidspage] = useState("");
  const [prevBidsPage, setPrevBidspage] = useState("");
  const [bidsIndex, setBidsIndex] = useState(0);
  const [loading, setIsLoading] = useState(false);
  const [ordersIndex, setOrdersIndex] = useState(0);
  const [stats, setStats] = useState({
    business_count: 0,
    order_count: 0,
    user_count: 0,
    total_order_tons: 0,
  });

  const [pdfPreviewShow, setPdfPreviewShow] = useState(false);
  const [orderPdfDownload, setOrderPdfDownload] = useState(false);
  const [loader, setLoader] = useState(false);

  const [pdfLanguage, setPdfLanguage] = useState("en");
  const [userCsvDown, setUsersCsvDown] = useState(false);
  const [ordersCsvDown, setOrdersCsvDown] = useState(false);

  const dispatch = useAppDispatch();

  const currentOrder = useAppSelector((state) => state.order.currentOrder);
  const currentOrderPdfRebars = useAppSelector(
    (state) => state.rebar.currentOrderPdfRebars
  );
  const currentOrderPdfNets = useAppSelector(
    (state) => state.net.currentOrderPdfNets
  );
  const currentOrderPdfPiles = useAppSelector(
    (state) => state.pile.currentOrderPdfPiles
  );

  const copyBusinessMails = () => {
    navigator.clipboard.writeText(
      business.map((item: any) => item.mail).toString()
    );
  };

  const copyBusinessPhones = () => {
    navigator.clipboard.writeText(
      business.map((item: any) => item.phone).toString()
    );
  };

  const copyUsersEmail = () => {
    navigator.clipboard.writeText(
      users.map((item: any) => item.email).toString()
    );
  };

  const copyUsersPhones = () => {
    navigator.clipboard.writeText(
      users.map((item: any) => item.phone).toString()
    );
  };

  const copyBidUsersPhones = () => {
    navigator.clipboard.writeText(
      bids.map((item: any) => item.user.phone).toString()
    );
  };

  const businessColumns = [
    {
      dataField: "id",
      text: "Sr",
      sort: true,
      formatter: (cell: any, row: any, rowIndex: number) =>
        rowIndex + businessIndex + 1,
    },
    {
      dataField: "name",
      text: "Business Name",
      sort: true,
    },
    {
      dataField: "mail",
      text: "Mail",
      headerFormatter: (column: any, colIndex: number) => (
        <span className="d-flex justify-content-between">
          Mail
          <img
            onClick={copyBusinessMails}
            src={CopyIcon}
            alt="copy icon"
            style={{ cursor: "pointer" }}
          />
        </span>
      ),
    },
    {
      dataField: "phone",
      text: "Phone",
      headerFormatter: (column: any, colIndex: number) => (
        <span className="d-flex justify-content-between">
          Phone
          <img
            onClick={copyBusinessPhones}
            src={CopyIcon}
            alt="copy icon"
            style={{ cursor: "pointer" }}
          />
        </span>
      ),
    },
    {
      dataField: "address",
      text: "Address",
    },
    {
      dataField: "bn_number",
      text: "BN Number",
    },
    {
      dataField: "total_tons",
      text: "Total Tons",
      sort: true,
    },
    {
      dataField: "recent_activity",
      text: "Recent Activity",
      sort: true,
      formatter: (cell: any, row: any) =>
        row && row.recent_activity
          ? format(new Date(row?.recent_activity), "d.M.yyy")
          : "😊",
    },
  ];

  const userColumns = [
    {
      dataField: "id",
      text: "Sr",
      sort: true,
      formatter: (cell: any, row: any, rowIndex: number) =>
        rowIndex + usersIndex + 1,
    },
    {
      dataField: "first_name",
      text: "Name",
      sort: true,
      formatter: (cell: any, row: any, rowIndex: number) =>
        `${row.first_name} ${row.last_name}`,
    },
    {
      dataField: "email",
      text: "Email",
      headerFormatter: (column: any, colIndex: number) => (
        <span className="d-flex justify-content-between">
          Email
          <img
            onClick={copyUsersEmail}
            src={CopyIcon}
            alt="copy icon"
            style={{ cursor: "pointer" }}
          />
        </span>
      ),
    },
    {
      dataField: "phone",
      text: "Phone",
      headerFormatter: (column: any, colIndex: number) => (
        <span className="d-flex justify-content-between">
          Phone
          <img
            onClick={copyUsersPhones}
            src={CopyIcon}
            alt="copy icon"
            style={{ cursor: "pointer" }}
          />
        </span>
      ),
    },
    {
      dataField: "is_active",
      text: "Active",
      formatter: (cell: any, row: any) => (
        <span>{row.is_active ? "Yes" : "No"}</span>
      ),
    },
    {
      dataField: "job_title",
      text: "Job Title",
      sort: true,
    },
    {
      dataField: "roles",
      text: "Business name",
      sort: true,
    },
    {
      dataField: "created",
      text: "Created Date",
      sort: true,
      formatter: (cell: any, row: any) =>
        row && row.created ? format(new Date(row?.created), "d.M.yyy") : "🤫",
    },
    {
      dataField: "number_of_orders",
      text: "Number Of Orders",
      sort: true,
    },
    {
      dataField: "recent_activity",
      text: "Recent Activity",
      sort: true,
      formatter: (cell: any, row: any) =>
        row && row.recent_activity
          ? format(new Date(row?.recent_activity), "d.M.yyy")
          : "😊",
    },
  ];

  const nonSelectableUsers = useMemo(() => {
    if (selectedUsers.length === 1) {
      const selectedUser = users.find(
        (item: any) => selectedUsers[0] === item.id
      ) as any;
      if (selectedUser)
        if (selectedUser.is_active) {
          return users
            .filter((item: any) => !item.is_active)
            .map((item: any) => item.id);
        } else {
          return users
            .filter((item: any) => item.is_active)
            .map((item: any) => item.id);
        }
    }
    return [];
  }, [users, selectedUsers]);

  const supplierEmailColumns = [
    {
      dataField: "id",
      text: "Sr",
      sort: true,
      formatter: (cell: any, row: any, rowIndex: number) =>
        rowIndex + emailSupplierIndex + 1,
    },
    {
      dataField: "created",
      text: "Date",
      sort: true,
      formatter: (cell: any, row: any) =>
        row && row.created ? format(new Date(row?.created), "d.M.yyy") : "😊",
    },
    {
      dataField: "supplier_name",
      text: "Supplier name",
      sort: true,
    },
    {
      dataField: "receipient_email",
      text: "Supplier Email",
    },
    {
      dataField: "sender_name",
      text: "user name (Sender)",
    },
    {
      dataField: "is_esisting_customer",
      text: "customer",
      formatter: (cell: any, row: any) =>
        row.is_esisting_customer ? "existing" : "new",
    },
    {
      dataField: "weight",
      text: "order weight",
    },
    {
      dataField: "document_url",
      text: "Shared Document",
      formatter: (cell: any, row: any) => (
        <div>
          <a href={row.document_url}> {row.order_no} </a>
        </div>
      ),
    },
  ];

  const supplierWhatsAppColumns = [
    {
      dataField: "id",
      text: "Sr",
      sort: true,
      formatter: (cell: any, row: any, rowIndex: number) =>
        rowIndex + whatsAppSupplierIndex + 1,
    },
    {
      dataField: "created",
      text: "Date",
      sort: true,
      formatter: (cell: any, row: any) =>
        row && row.created ? format(new Date(row?.created), "d.M.yyy") : "😊",
    },
    {
      dataField: "sender_name",
      text: "Sender Name",
    },
    {
      dataField: "count",
      text: "Sharing Count",
    },
    {
      dataField: "document_url",
      text: "Shared Document",
      formatter: (cell: any, row: any) => (
        <div>
          <a href={row.document_url}> {row.order_no} </a>
        </div>
      ),
    },
  ];

  const selectRow = {
    mode: "checkbox" as any,
    selected: selectedUsers as any,
    nonSelectable: nonSelectableUsers,
    onSelect: (row: any, isSelect: boolean, rowIndex: number, e: any) => {
      if (isSelect === true) {
        setSelectedUsers([...selectedUsers, row.id] as any);
      } else {
        setSelectedUsers(selectedUsers.filter((i: any) => i !== row.id));
      }
    },
    onSelectAll: (isSelect: boolean, rows: any, e: any) => {
      if (isSelect === true) {
        setSelectedUsers(rows.map((row: any) => row.id));
      } else {
        setSelectedUsers([]);
      }
    },
    hideSelectAll: true,
  };

  const orderColumns = [
    {
      dataField: "id",
      text: "Sr",
      sort: true,
      formatter: (cell: any, row: any, rowIndex: number) =>
        rowIndex + ordersIndex + 1,
    },
    {
      dataField: "email",
      text: "User Email",
      sort: true,
    },
    {
      dataField: "total_tons",
      text: "Total Tons",
      sort: true,
    },
    {
      dataField: "rebar_items",
      text: "Rebar Items",
    },
    {
      dataField: "net_items",
      text: "Net Items",
    },
    {
      dataField: "cage_items",
      text: "Cage Items",
    },
    // {
    //   dataField: 'average_time',
    //   text: 'Average Time to create one item',
    //   sort: true,
    // },
    // {
    //   dataField: 'duration_time',
    //   text: 'Duration of order creation',
    //   sort: true,
    // },
    {
      dataField: "",
      text: "",
      formatter: (cell: any, row: any) => {
        // const copyFunc = () => {
        //   navigator.clipboard.writeText(
        //     Object.keys(row).reduce((prev, item) => (prev + row[item] + ','), '')
        //   );        retrun(
        // };

        const openPDFModal = async (orderId: number) => {
          dispatch(getCurrentOrderTools(true, orderId))
            .then(() => {
              setPdfPreviewShow(true);

              if (setPdfPreviewShow(true)) {
                setOrderPdfDownload(true);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        };

        return (
          <img
            onClick={() => {
              openPDFModal(row.id);
            }}
            src={CopyBookIcon}
            alt="copy icon"
            style={{ cursor: "pointer" }}
          />
        );
      },
    },
    {
      dataField: "",
      text: "",
      formatter: (cell: any, row: any) => {
        return <OrderExcel order={row.id} />;
      },
    },
  ];

  const getUsers = (params = "") => {
    setUserpage(null as any);
    setPrevpage(null as any);
    setUsersIndex(0);
    getAdminUsers("", params || userSearchParam)
      .then((res) => {
        if (res.data.results || res.data.results.length > 0) {
          setUsers([...res.data.results] as any);
          if (res.data.next)
            setUserpage(res.data.next.split("cursor=")[1].split("&")[0]);
          else setUserpage(null as any);
          if (res.data.previous)
            setPrevpage(res.data.previous.split("cursor=")[1].split("&")[0]);
          else setPrevpage(null as any);
        }
      })
      .catch((err) => console.log(err));
  };

  const setSearchUsersState = async (search_param: string) => {
    setUserSearchParam(search_param);
    await getUsers(search_param);
  };

  const getNextUsers = () => {
    if (userPage || userPage === "") {
      getAdminUsers(userPage, userSearchParam)
        .then((res) => {
          if (res.data.results.length > 0) {
            setUsers([...res.data.results] as any);
            if (res.data.next)
              setUserpage(res.data.next.split("cursor=")[1].split("&")[0]);
            else setUserpage(null as any);
            if (res.data.previous)
              setPrevpage(res.data.previous.split("cursor=")[1].split("&")[0]);
            else setPrevpage(null as any);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const getPrevUsers = () => {
    if (prevPage || prevPage === "") {
      getAdminUsers(prevPage, userSearchParam)
        .then((res) => {
          if (res.data.results.length > 0) {
            setUsers([...res.data.results] as any);
            if (res.data.next)
              setUserpage(res.data.next.split("cursor=")[1].split("&")[0]);
            else setUserpage(null as any);
            if (res.data.previous)
              setPrevpage(res.data.previous.split("cursor=")[1].split("&")[0]);
            else setPrevpage(null as any);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const getBusinesses = () => {
    if (businessPage || businessPage === "") {
      getAdminBusinesses(businessPage)
        .then((res) => {
          if (res.data.results.length > 0) {
            setBusiness([...res.data.results] as any);
            if (res.data.next)
              setBusinesspage(res.data.next.split("cursor=")[1].split("&")[0]);
            else setBusinesspage(null as any);
            if (res.data.previous)
              setPrevBusinesspage(
                res.data.previous.split("cursor=")[1].split("&")[0]
              );
            else setPrevBusinesspage(null as any);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const getEmailSuppliers = () => {
    if (emailSupplierPage || emailSupplierPage === "") {
      getAdminSuppliers(emailSupplierPage, "email")
        .then((res) => {
          if (res.data.results || res.data.results.length > 0) {
            setEmailSupplier([...res.data.results] as any);
            if (res.data.next)
              setEmailSupplierPage(
                res.data.next.split("cursor=")[1].split("&")[0]
              );
            else setEmailSupplierPage(null as any);
            if (res.data.previous)
              setPrevEmailSupplierPage(
                res.data.previous.split("cursor=")[1].split("&")[0]
              );
            else setPrevEmailSupplierPage(null as any);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const getWhatsAppSuppliers = () => {
    if (whatsAppSupplierPage || whatsAppSupplierPage === "") {
      getAdminSuppliers(whatsAppSupplierPage, "whatsapp")
        .then((res) => {
          if (res.data.results.length > 0) {
            setWhatsAppSupplier([...res.data.results] as any);
            if (res.data.next)
              setWhatsAppSupplierPage(
                res.data.next.split("cursor=")[1].split("&")[0]
              );
            else setWhatsAppSupplierPage(null as any);
            if (res.data.previous)
              setPrevWhatsAppSupplierPage(
                res.data.previous.split("cursor=")[1].split("&")[0]
              );
            else setPrevWhatsAppSupplierPage(null as any);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const getPrevWhatsAppSuppliers = () => {
    if (prevWhatsAppSupplierPage || prevWhatsAppSupplierPage === "") {
      getAdminSuppliers(prevWhatsAppSupplierPage, "whatsapp")
        .then((res) => {
          if (res.data.results.length > 0) {
            setWhatsAppSupplier([...res.data.results] as any);
            if (res.data.next)
              setWhatsAppSupplierPage(
                res.data.next.split("cursor=")[1].split("&")[0]
              );
            else setWhatsAppSupplierPage(null as any);
            if (res.data.previous)
              setPrevWhatsAppSupplierPage(
                res.data.previous.split("cursor=")[1].split("&")[0]
              );
            else setPrevWhatsAppSupplierPage(null as any);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const getPrevBusinesses = () => {
    if (prevBusinessPage || prevBusinessPage === "") {
      getAdminBusinesses(prevBusinessPage)
        .then((res) => {
          if (res.data.results.length > 0) {
            setBusiness([...res.data.results] as any);
            if (res.data.next)
              setBusinesspage(res.data.next.split("cursor=")[1].split("&")[0]);
            else setBusinesspage(null as any);
            if (res.data.previous)
              setPrevBusinesspage(
                res.data.previous.split("cursor=")[1].split("&")[0]
              );
            else setPrevBusinesspage(null as any);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const getPrevEmailSupplier = () => {
    if (prevEmailSupplierPage || prevEmailSupplierPage === "") {
      getAdminSuppliers(prevEmailSupplierPage, "email")
        .then((res) => {
          if (res.data.results.length > 0) {
            setEmailSupplier([...res.data.results] as any);
            if (res.data.next)
              setEmailSupplierPage(
                res.data.next.split("cursor=")[1].split("&")[0]
              );
            else setEmailSupplierPage(null as any);
            if (res.data.previous)
              setPrevEmailSupplierPage(
                res.data.previous.split("cursor=")[1].split("&")[0]
              );
            else setPrevEmailSupplierPage(null as any);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const getOrders = () => {
    if (ordersPage || ordersPage === "") {
      getAdminOrders(ordersPage)
        .then((res) => {
          if (res.data.results || res.data.results.length > 0) {
            setOrders([...res.data.results] as any);
            if (res.data.next)
              setOrderspage(res.data.next.split("cursor=")[1].split("&")[0]);
            else setOrderspage(null as any);
            if (res.data.previous)
              setPrevOrderspage(
                res.data.previous.split("cursor=")[1].split("&")[0]
              );
            else setPrevOrderspage(null as any);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const getPrevOrders = () => {
    if (prevOrdersPage || prevOrdersPage === "") {
      getAdminOrders(prevOrdersPage)
        .then((res) => {
          if (res.data.results.length > 0) {
            setOrders([...res.data.results] as any);
            if (res.data.next)
              setOrderspage(res.data.next.split("cursor=")[1].split("&")[0]);
            else setOrderspage(null as any);
            if (res.data.previous)
              setPrevOrderspage(
                res.data.previous.split("cursor=")[1].split("&")[0]
              );
            else setPrevOrderspage(null as any);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const blockStatus = useMemo(() => {
    const selectedUsersInstances = users.filter((item: any) =>
      selectedUsers.includes(item.id)
    );
    if (selectedUsersInstances.length > 0) {
      return (selectedUsersInstances[0] as any).is_active;
    } else {
      return true;
    }
  }, [users, selectedUsers]);

  useEffect(() => {
    getUsers();
    getBusinesses();
    getOrders();
    getEmailSuppliers();
    getWhatsAppSuppliers();
    getStats().then((res) => {
      setStats(res.data.data);
    });
  }, []);

  function getFormattedTimestamp() {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day}_${hours}.${minutes}.${seconds}`;
  }

  const downloadCsvFile = (fileData, fileName) => {
    const currentTimestamp = getFormattedTimestamp();
    const blob = new Blob([fileData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName + currentTimestamp + ".csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const getMembersCsvList = () => {
    setUsersCsvDown(true);
    getMembersCsv()
      .then((res) => {
        downloadCsvFile(res.data, "users_report_");
        setUsersCsvDown(false);
      })
      .catch((err) => {
        setOrdersCsvDown(false);
        console.log(err);
      });
  };

  const getOrdersCsvList = () => {
    setOrdersCsvDown(true);
    getOrdersCsv()
      .then((res) => {
        downloadCsvFile(res.data, "orders_report_");
        setOrdersCsvDown(false);
      })
      .catch((err) => {
        setOrdersCsvDown(false);
        console.log(err);
      });
  };

  return (
    <>
      <AdminLayout>
        <div id="user-analytics">
          <div className="d-flex justify-content-between align-items-center py-4">
            <div className="csv-btn-container">
              <p className="text-capitalize pr-2">{t("users")}</p>
              <Button
                disabled={userCsvDown}
                type="button"
                variant="light"
                onClick={getMembersCsvList}
              >
                <span className="csv-btn">
                  {!userCsvDown && (
                    <img src={CsvDownload} alt="csv download icon" />
                  )}
                  {userCsvDown && (
                    <div className="spinner-border spin-size" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                </span>
              </Button>
            </div>
            <p>Total Number of Users: {stats.user_count}</p>
          </div>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-start pb-3">
                <a
                  href={
                    "mailto:" +
                    users
                      .filter((item: any) => selectedUsers.includes(item.id))
                      .map((item: any) => item.email)
                      .toString()
                  }
                >
                  <Button
                    disabled={selectedUsers.length === 0}
                    variant="light"
                    className="mr-2"
                  >
                    Send Mail
                  </Button>
                </a>
                <Button
                  disabled={selectedUsers.length === 0}
                  onClick={() => {
                    const sta = (!blockStatus).toString();
                    blockUsers(sta.charAt(0).toUpperCase() + sta.slice(1), {
                      users: selectedUsers,
                    })
                      .then((res) => {
                        setSelectedUsers([]);
                        setUsers((prev: any) => {
                          return prev.map((item: any) => {
                            if (selectedUsers.includes(item.id)) {
                              return {
                                ...item,
                                is_active: !blockStatus,
                              };
                            } else {
                              return item;
                            }
                          });
                        });
                      })
                      .catch((err) => console.log(err));
                  }}
                  variant="light"
                >
                  {blockStatus ? "Block User" : "Un-block User"}
                </Button>
                <DebounceInput
                  className="px-2"
                  placeholder="search here..."
                  //minLength={2}
                  debounceTimeout={300}
                  forceNotifyByEnter={true}
                  forceNotifyOnBlur={true}
                  onChange={(event) => setSearchUsersState(event.target.value)}
                  style={{ marginLeft: "auto" }}
                />
              </div>
              <BootstrapTable
                keyField="id"
                data={users}
                columns={userColumns}
                selectRow={selectRow}
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {prevPage !== null ? (
                  <Button
                    variant="light"
                    size="sm"
                    className="external-pagination-btn pagination-btn-prev mr-2"
                    onClick={(e) => {
                      setUsersIndex((p) => p - 30);
                      getPrevUsers();
                    }}
                  >
                    Prev
                  </Button>
                ) : null}
                {userPage !== null ? (
                  <Button
                    variant="light"
                    size="sm"
                    className="external-pagination-btn pagination-btn-next"
                    onClick={(e) => {
                      setUsersIndex((p) => p + 30);
                      getNextUsers();
                    }}
                  >
                    Next
                  </Button>
                ) : null}
              </div>
            </Card.Body>
          </Card>
          <div className="d-flex justify-content-between py-4">
            <p>Businesses</p>
            <p>Total Number of Businesses: {stats?.business_count}</p>
          </div>
          <Card>
            <Card.Body>
              <BootstrapTable
                keyField="id"
                data={business}
                columns={businessColumns}
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {prevBusinessPage !== null ? (
                  <Button
                    variant="light"
                    size="sm"
                    className="external-pagination-btn pagination-btn-prev mr-2"
                    onClick={(e) => {
                      setBusinessIndex((p) => p - 30);
                      getPrevBusinesses();
                    }}
                  >
                    Prev
                  </Button>
                ) : null}
                {businessPage !== null ? (
                  <Button
                    variant="light"
                    size="sm"
                    className="external-pagination-btn pagination-btn-next"
                    onClick={(e) => {
                      setBusinessIndex((p) => p + 30);
                      getBusinesses();
                    }}
                  >
                    Next
                  </Button>
                ) : null}
              </div>
            </Card.Body>
          </Card>
          <div className="d-flex justify-content-between align-items-start py-4">
            <div className="csv-btn-container">
              <p className="mr-2">Orders</p>
              <div>
                <Button
                  disabled={ordersCsvDown}
                  type="button"
                  variant="light"
                  onClick={getOrdersCsvList}
                >
                  <span className="csv-btn">
                    {!ordersCsvDown && (
                      <img src={CsvDownload} alt="csv download icon" />
                    )}
                    {ordersCsvDown && (
                      <div className="spinner-border spin-size" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                  </span>
                </Button>
              </div>
            </div>
            <div className="d-flex flex-column">
              <p>
                Total Number of Orders: {stats.order_count} <br /> Total Order
                Weights: {stats.total_order_tons} (tons)
              </p>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="PDF (Hebrew)"
                  checked={pdfLanguage === "he"}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setPdfLanguage("he");
                    } else {
                      setPdfLanguage("en");
                    }
                  }}
                />
              </Form>
            </div>
          </div>
          <Card>
            <Card.Body>
              <BootstrapTable
                keyField="id"
                data={orders}
                columns={orderColumns}
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {prevOrdersPage !== null ? (
                  <Button
                    variant="light"
                    size="sm"
                    className="external-pagination-btn pagination-btn-prev mr-2"
                    onClick={(e) => {
                      setOrdersIndex((p) => p - 30);
                      getPrevOrders();
                    }}
                  >
                    Prev
                  </Button>
                ) : null}
                {ordersPage !== null ? (
                  <Button
                    variant="light"
                    size="sm"
                    className="external-pagination-btn pagination-btn-next"
                    onClick={(e) => {
                      setOrdersIndex((p) => p + 30);
                      getOrders();
                    }}
                  >
                    Next
                  </Button>
                ) : null}
              </div>
            </Card.Body>
          </Card>
          <div className="d-flex justify-content-between py-4">
            <p>Suppliers</p>
            {/* <p>Total Number of Suppliers: {stats?.business_count}</p> */}
          </div>
          <div className="d-flex justify-content-between py-4">
            <p>email</p>
            {/* <p>Total Number of Suppliers: {stats?.business_count}</p> */}
          </div>
          <Card>
            <Card.Body>
              <BootstrapTable
                keyField="id"
                data={emailSupplier}
                columns={supplierEmailColumns}
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {prevEmailSupplierPage !== null ? (
                  <Button
                    variant="light"
                    size="sm"
                    className="external-pagination-btn pagination-btn-prev mr-2"
                    onClick={(e) => {
                      setEmailSupplierIndex((p) => p - 30);
                      getPrevEmailSupplier();
                    }}
                  >
                    Prev
                  </Button>
                ) : null}
                {emailSupplierPage !== null ? (
                  <Button
                    variant="light"
                    size="sm"
                    className="external-pagination-btn pagination-btn-next"
                    onClick={(e) => {
                      setEmailSupplierIndex((p) => p + 30);
                      getEmailSuppliers();
                    }}
                  >
                    Next
                  </Button>
                ) : null}
              </div>
            </Card.Body>
          </Card>
          <div className="d-flex justify-content-between py-4">
            <p>WhatsApp</p>
            {/* <p>Total Number of Suppliers: {stats?.business_count}</p> */}
          </div>
          <Card>
            <Card.Body>
              <BootstrapTable
                keyField="id"
                data={whatsAppSupplier}
                columns={supplierWhatsAppColumns}
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {prevWhatsAppSupplierPage !== null ? (
                  <Button
                    variant="light"
                    size="sm"
                    className="external-pagination-btn pagination-btn-prev mr-2"
                    onClick={(e) => {
                      setWhatsAppSupplierIndex((p) => p - 30);
                      getPrevWhatsAppSuppliers();
                    }}
                  >
                    Prev
                  </Button>
                ) : null}
                {whatsAppSupplierPage !== null ? (
                  <Button
                    variant="light"
                    size="sm"
                    className="external-pagination-btn pagination-btn-next"
                    onClick={(e) => {
                      setWhatsAppSupplierIndex((p) => p + 30);
                      getWhatsAppSuppliers();
                    }}
                  >
                    Next
                  </Button>
                ) : null}
              </div>
            </Card.Body>
          </Card>
        </div>
      </AdminLayout>
      <PdfPreviewModal
        show={pdfPreviewShow}
        onHide={() => {
          setPdfPreviewShow(false);
        }}
        setDownloadFlag={setOrderPdfDownload}
        downloadFlag={orderPdfDownload}
        printCanvas={false}
        generatePdfFlag={false}
        pdfFile={""}
        setLoader={setLoader}
        loader={loader}
        order={currentOrder}
        rebars={currentOrderPdfRebars}
        nets={currentOrderPdfNets}
        piles={currentOrderPdfPiles}
        pdfLanguage={pdfLanguage}
        pdfType={OrderPdfType.ORIGINAL_PDF}
        bidFees={""} //admin panel only prop
        feeType={""} //admin panel only prop
      />
      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
};

export default withTranslation("common")(UserAnalytics);
