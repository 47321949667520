import React from "react";
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import { commaSeparated } from "Constants/general";
import { format } from "date-fns";
import cage_circle from "Assets/img/ticket__create/cage_circle.svg";
import rebar_amount from "Assets/img/ticket__create/rebar_amount.svg";
import diameter from "Assets/img/ticket__create/diameter.svg";
import sideStage_2 from "Assets/img/ticket__create/pt_split_2.svg";
import sideStage_3 from "Assets/img/ticket__create/pt_split_3.svg";
import gap_icon from "Assets/img/ticket__create/pt_gap.svg";
import steel_icon from "Assets/img/ticket__create/pt_steel.svg";
import diameter_icon from "Assets/img/ticket__create/pt_diameter.svg";
import ring_icon from "Assets/img/ticket__create/ring.svg";
import gama_icon from "Assets/img/ticket__create/gama.svg";
import ear_icon from "Assets/img/ticket__create/pt_ear.svg";

import "./index.scss";

const A4TicketCardPiles = ({ ...props }) => {
  const { pile, orderNo, ticketIndex, printTotalCount, printCurrentCount } =
    props;
  const currentOrderPiles = useAppSelector(
    (state) => state.supplier.completeOrderDetails
  );
  const steelTypes = useAppSelector((state) => state.steelType.steelTypes);

  const checkSplit = () => {
    if (pile.tool?.middle_diameter?.weight && pile.tool.middle_amount) {
      return 3;
    } else if (
      pile.tool?.external_diameter?.weight &&
      pile.tool.external_amount
    ) {
      return 2;
    } else {
      return 1;
    }
  };
  const sideSplit = checkSplit();

  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const { t } = useTranslation(["ticketCreator", "common"]);
  return (
    <div className="a4-card">
      <div className="top-left-circle"></div>
      <div className="sketch-box">
        <div
          className={"piles_ticket_piles_data" + (rightToLeft ? "_heb" : "")}
        >
          {/* TOP SECTION */}
          <div className="piles_ticket_top_sec">
            <span className="pt_total_len">
              <span
                className={
                  "pt_light_text " + (rightToLeft ? "pt_pl5" : "pt_pr5")
                }
              >
                {"L"}
              </span>
              <span
                className={
                  "pt_bold_text " + (rightToLeft ? "pt_pl5" : "pt_pr5")
                }
              >
                {pile.tool.l1_length +
                  pile.tool.l2_length +
                  pile.tool.l3_length}
              </span>
              <span className="pt_small_text">
                {t("cm", { ns: "measurements" })}
              </span>
            </span>
            <span className="pt_cage_diameters">
              <img
                src={cage_circle}
                alt="cage_icon"
                style={{
                  height: "20px",
                  width: "20px",
                  ...(rightToLeft
                    ? { marginLeft: "5px" }
                    : { marginRight: "5px" }),
                }}
              />
              <span className="pt_light_text">{pile.tool.hole_diameter}</span>

              <span
                className={
                  "pt_light_text " + (rightToLeft ? "pt_pl5" : "pt_pr5")
                }
              >
                {`/${pile.tool.cage_diameter}`}
              </span>

              <span className="pt_small_text">
                {t("cm", { ns: "measurements" })}
              </span>
            </span>
            <span className="pt_rebar_and_sides">
              {sideSplit === 1 ? (
                <>
                  <img
                    src={rebar_amount}
                    alt="rebar_amount"
                    style={{
                      height: "20px",
                      width: "20px",
                      ...(rightToLeft
                        ? { marginLeft: "2px" }
                        : { marginRight: "2px" }),
                    }}
                  />
                  <p
                    className={
                      "pt_light_text " + (rightToLeft ? "pt_pl5" : "pt_pr5")
                    }
                  >
                    {pile.tool.base_amount}
                  </p>

                  <img
                    src={diameter}
                    alt="diameter"
                    style={{
                      height: "20px",
                      width: "20px",
                      ...(rightToLeft
                        ? { marginLeft: "3px" }
                        : { marginRight: "3px" }),
                    }}
                  />
                  <p className="pt_light_text">
                    {pile.tool.base_diameter.steel_diameter}
                  </p>
                </>
              ) : (
                <>
                  <img
                    src={rebar_amount}
                    alt="rebar_amount"
                    style={{
                      height: "20px",
                      width: "20px",
                      ...(rightToLeft
                        ? { marginLeft: "5px" }
                        : { marginRight: "5px" }),
                    }}
                  />
                  <span className="pt_text_light">
                    {t("mixed", { ns: "ticketCreator" })}
                  </span>
                  <img
                    src={sideSplit === 2 ? sideStage_2 : sideStage_3}
                    alt="side_Stage"
                    style={{
                      height: "19px",
                      width: "25px",
                      ...(rightToLeft
                        ? { marginRight: "5px" }
                        : { marginLeft: "5px" }),
                    }}
                  />
                </>
              )}
            </span>
          </div>

          {/* L1 */}
          <div className="piles_ticket_mid_sec pt_light_bottom">
            <span className="pt_len_l1">
              <p className="pt_bold_text">{pile.tool.l1_length}</p>
            </span>
            <span className="pt_ear_data">
              {pile.tool.ear_length && pile.tool.ear_type && (
                <>
                  <img
                    src={ear_icon}
                    alt="ear_icon"
                    className="sides_diameter_image"
                    style={rightToLeft ? { marginLeft: "5px" } : {}}
                  />
                  {pile.tool.ear_type === 1 ? (
                    <p className="pt_light_text">
                      {t("cardinal", { ns: "tool" })}
                    </p>
                  ) : (
                    <p className="pt_light_text">
                      {t("internal", { ns: "tool" })}
                    </p>
                  )}
                  <p className="pt_light_text">{` /${pile.tool.ear_length}`}</p>
                  <p
                    className="pt_small_text"
                    style={{
                      paddingTop: "5px",
                    }}
                  >
                    {t("cm", { ns: "measurements" })}
                  </p>
                </>
              )}
            </span>
            <span className="pt_external_data">
              {pile.tool.external_amount && pile.tool.external_diameter && (
                <>
                  <span className="pt_side_amount pt_light_text">
                    {pile.tool.external_amount}
                  </span>
                  <span className="pt_side_diameter">
                    <img
                      src={diameter}
                      alt="diameter"
                      className={
                        "sides_diameter_image" + (rightToLeft ? "_heb" : "")
                      }
                    />
                    <p className="pt_light_text">
                      {pile.tool.external_diameter.steel_diameter}
                    </p>
                  </span>
                  <span>{}</span>
                </>
              )}
            </span>
          </div>

          {/* L2 */}
          <div className="piles_ticket_mid_sec pt_light_bottom">
            <span className="pt_len_l2">
              <p className="pt_bold_text">{pile.tool.l2_length}</p>
            </span>
            <span className="pt_l2_details">
              <img
                src={gap_icon}
                alt="gap_icon"
                style={{ height: "25px", width: "25px" }}
              />
              <p
                className={
                  "pt_light_text " + (rightToLeft ? "pt_pl5" : "pt_pr5")
                }
              >
                {pile.tool.l2_gap}
              </p>

              <img
                src={diameter_icon}
                alt="diameter_icon"
                style={{
                  height: "20px",
                  width: "20px",
                  ...(rightToLeft
                    ? { marginLeft: "2px" }
                    : { marginRight: "2px" }),
                }}
              />
              <p
                className={
                  "pt_light_text " + (rightToLeft ? "pt_pl2" : "pt_pr2")
                }
              >
                {pile.tool.spiral_wire_diameter.steel_diameter}
              </p>

              <img
                src={steel_icon}
                alt="steel_icon"
                style={{ height: "25px", width: "25px" }}
              />
              <p className="pt_light_text">
                {steelTypes.length > 0 &&
                  steelTypes.find(
                    (item) => item.id === pile.tool.cage_steel_type
                  ) &&
                  steelTypes.find(
                    (item) => item.id === pile.tool.cage_steel_type
                  ).name[0]}
              </p>
            </span>
            <span className="pt_middle_data">
              {pile.tool.middle_amount &&
              pile.tool.middle_diameter.steel_diameter ? (
                <>
                  <span className="pt_side_amount pt_light_text">
                    {pile.tool.middle_amount}
                  </span>
                  <span className="pt_side_diameter">
                    <img
                      src={diameter}
                      alt="diameter"
                      className={
                        "sides_diameter_image" + (rightToLeft ? "_heb" : "")
                      }
                    />
                    <p className="pt_light_text">
                      {pile.tool.middle_diameter.steel_diameter}
                    </p>
                  </span>
                  <span>{}</span>
                </>
              ) : (
                pile.tool.internal_amount &&
                pile.tool.internal_diameter.steel_diameter && (
                  <>
                    <span className="pt_side_amount pt_light_text">
                      {pile.tool.internal_amount}
                    </span>
                    <span className="pt_side_diameter">
                      <img
                        src={diameter}
                        alt="diameter"
                        className={
                          "sides_diameter_image" + (rightToLeft ? "_heb" : "")
                        }
                      />
                      <p className="pt_light_text">
                        {pile.tool.internal_diameter.steel_diameter}
                      </p>
                    </span>
                    <span>{}</span>
                  </>
                )
              )}
            </span>
          </div>

          {/* L3 */}
          <div className="piles_ticket_mid_sec ">
            <span className="pt_len_l3">
              <p className="pt_bold_text">{pile.tool.l3_length}</p>
            </span>
            <span className="pt_l3_details">
              <img
                src={gap_icon}
                alt="gap_icon"
                style={{ height: "25px", width: "25px" }}
              />
              <p
                className={
                  "pt_light_text " + (rightToLeft ? "pt_pl5" : "pt_pr5")
                }
              >
                {pile.tool.l3_gap}
              </p>

              <img
                src={diameter_icon}
                alt="diameter_icon"
                style={{
                  height: "20px",
                  width: "20px",
                  ...(rightToLeft
                    ? { marginLeft: "2px" }
                    : { marginRight: "2px" }),
                }}
              />
              <p
                className={
                  "pt_light_text " + (rightToLeft ? "pt_pl2" : "pt_pr2")
                }
              >
                {pile.tool.spiral_wire_diameter.steel_diameter}
              </p>

              <img
                src={steel_icon}
                alt="steel_icon"
                style={{ height: "25px", width: "25px" }}
              />
              <p className="pt_light_text">
                {steelTypes.length > 0 &&
                  steelTypes.find(
                    (item) => item.id === pile.tool.cage_steel_type
                  ) &&
                  steelTypes.find(
                    (item) => item.id === pile.tool.cage_steel_type
                  ).name[0]}
              </p>
            </span>
            <span className="pt_internal_data">
              {pile.tool.internal_amount &&
                pile.tool.internal_diameter &&
                sideSplit === 3 && (
                  <>
                    <span className="pt_side_amount pt_light_text">
                      {pile.tool.internal_amount}
                    </span>
                    <span className="pt_side_diameter">
                      <img
                        src={diameter}
                        alt="diameter"
                        className={
                          "sides_diameter_image" + (rightToLeft ? "_heb" : "")
                        }
                      />
                      <p className="pt_light_text">
                        {pile.tool.internal_diameter.steel_diameter}
                      </p>
                    </span>
                    <span>{}</span>
                  </>
                )}
            </span>
          </div>

          {/* RINGS AND GAMMA */}
          {pile.tool.ring_quantity || pile.tool.gama_quantity ? (
            <div className="pt_rings_gama_sec">
              <span className="pt_rings">
                {pile.tool.ring_quantity && (
                  <>
                    <img
                      src={ring_icon}
                      alt="side_Stage"
                      style={{
                        height: "20px",
                        width: "20px",
                        ...(rightToLeft
                          ? { marginLeft: "5px" }
                          : { marginRight: "5px" }),
                      }}
                    />
                    <p
                      className={
                        "pt_light_text " + (rightToLeft ? "pt_pl2" : "pt_pr2")
                      }
                    >
                      {pile.tool.ring_quantity}
                    </p>
                    <p
                      className={
                        "pt_small_text " + (rightToLeft ? "pt_pl5" : "pt_pr5")
                      }
                    >
                      {t("rings", { ns: "ticketCreator" })}
                    </p>
                    <p
                      className={
                        "pt_light_text " + +(rightToLeft ? "pt_pl2" : "pt_pr2")
                      }
                    >
                      {pile.tool.ring_diameter}
                    </p>
                    <p className="pt_small_text">
                      {t("diameter", { ns: "common" })}
                    </p>
                  </>
                )}
              </span>
              <span className="pt_gama">
                {pile.tool.gama_quantity && (
                  <>
                    <img
                      src={gama_icon}
                      alt="side_Stage"
                      style={{
                        height: "20px",
                        width: "20px",
                        ...(rightToLeft
                          ? { marginLeft: "5px" }
                          : { marginRight: "5px" }),
                      }}
                    />
                    <p
                      className={
                        "pt_light_text " + (rightToLeft ? "pt_pl2" : "pt_pr2")
                      }
                    >
                      {pile.tool.gama_quantity}
                    </p>
                    <p
                      className={
                        "pt_small_text " + (rightToLeft ? "pt_pl5" : "pt_pr5")
                      }
                    >
                      {t("gama", { ns: "ticketCreator" })}
                    </p>
                    <p
                      className={
                        "pt_light_text " + (rightToLeft ? "pt_pl2" : "pt_pr2")
                      }
                    >
                      {pile.tool.gama_diameter}
                    </p>
                    <p className="pt_small_text">
                      {t("Tzol", { ns: "ticketCreator" })}
                    </p>
                  </>
                )}
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
        {pile.tool.name && (
          <p className="pile_nickname">
            {t("nickname", { ns: "ticketCreator" }) + ": " + pile.tool.name}
          </p>
        )}
        {sideSplit === 1 ? (
          <p className="net_type_special">
            {t("base cage", { ns: "ticketCreator" })}
          </p>
        ) : (
          <p className="net_type_special">
            {t("side cage", { ns: "ticketCreator" })}
          </p>
        )}
      </div>
      <div className="info-section">
        <p
          className={
            "sketch-Note ticket-border__bottom " +
            (rightToLeft ? "info-pr" : "info-pl")
          }
          style={pile.production_notes === "" ? { paddingBottom: "30px" } : {}}
        >
          {pile?.production_notes}
        </p>
        <div
          className={
            "rebar-detail ticket-border__bottom " +
            (rightToLeft ? "info-pr" : "info-pl")
          }
        >
          <p
            className="detail-title"
            style={
              rightToLeft ? { paddingLeft: "52px" } : { paddingRight: "12px" }
            }
          >
            {t("customer", { ns: "ticketCreator" })}
          </p>
          <p className="detail-sub">{currentOrderPiles.customer_name}</p>
        </div>
        <div
          className={
            "rebar-detail ticket-border__bottom " +
            (rightToLeft ? "info-pr" : "info-pl")
          }
        >
          <p
            className="detail-title"
            style={
              rightToLeft ? { paddingLeft: "12px" } : { paddingRight: "25px" }
            }
          >
            {t("delivery", { ns: "ticketCreator" })}
          </p>
          <p className="detail-sub">
            {format(new Date(currentOrderPiles.delivery_date), "dd/MM/yyy")}
          </p>
        </div>
        <div
          className={
            "rebar-detail ticket-border__bottom " +
            (rightToLeft ? "info-pr" : "info-pl")
          }
        >
          <p
            className="detail-title"
            style={
              rightToLeft ? { paddingLeft: "85px" } : { paddingRight: "60px" }
            }
          >
            {t("site", { ns: "ticketCreator" })}
          </p>
          <p className="detail-sub">{currentOrderPiles.site_address}</p>
        </div>
        <div className="weight-section ticket-border__bottom">
          <p>
            {t("item weight", { ns: "ticketCreator" })}
            {pile.tool.unit_weight.toString().replace(commaSeparated, ",")}
          </p>
          <p>
            {t("total weight", { ns: "ticketCreator" })}
            {(pile.tool.unit_weight * pile.quantity)
              .toFixed(2)
              .toString()
              .replace(commaSeparated, ",")}
          </p>
        </div>
      </div>
      <div className="types-section">
        <div className="detail-box">
          <p>{t("total length", { ns: "ticketCreator" })}</p>
          <div className="info-box">
            <p>
              {pile.tool.l1_length + pile.tool.l2_length + pile.tool.l3_length}
            </p>
          </div>
        </div>
        <div className="detail-box">
          <p>{t("con/cage", { ns: "ticketCreator" })}</p>
          <div className="info-box">
            {rightToLeft ? (
              <p>{`${pile.tool.cage_diameter}/${pile.tool.hole_diameter}`}</p>
            ) : (
              <p>{`${pile.tool.hole_diameter}/${pile.tool.cage_diameter}`}</p>
            )}
          </div>
        </div>
        <div className="detail-box">
          <p>{t("amount", { ns: "ticketCreator" })}</p>
          <div className="info-box">
            <p>{pile.quantity.toString().replace(commaSeparated, ",")}</p>
          </div>
        </div>
      </div>
      <div className="footer-section">
        {rightToLeft ? (
          <div className="order-no">
            <p className="ticket_print_count_txt">
              ({printCurrentCount}/{printTotalCount})
            </p>
            <p>{`${ticketIndex} / ${orderNo}`}</p>
          </div>
        ) : (
          <div className="order-no">
            <p>{`${orderNo} / ${ticketIndex}`}</p>
            <p className="ticket_print_count_txt">
              ({printCurrentCount}/{printTotalCount})
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default A4TicketCardPiles;
