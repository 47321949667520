export const emailOrderHe = {
  "send by mail": "שלח בדואר אלקטרוני",
  "email to send": "ההזמנה תישלח לדואר האלקטרוני הזה",
  "example": "דוגמא@gmail.com",
  "whom to send": "למי אתה שולח את ההזמנה?",
  "Supplier": "ספק",
  "Coworker": "עמית לעבודה",
  "Other": "אחר",
  "supplier name": "שם הספק",
  "type here": "הקלידו את שם הספק",
  "customer type": "בחר איזה סוג לקוח אתה",
  "ExistingCustomer": "קיים",
  "NewCustomer": "חדש",
  "Note": "הערות לספק",
  "message attached": "ההודעה תצורף לדואר האלקטרוני",
  "Send": "שלח",

  "order sent": "ההזמנה נשלחה",
  "receive private email": "תשובה תישלח לדואר אלקטרוני הפרטי שלך",
  "order not sent": "ההזמנה לא נשלחת.",
  "email not recieved": "יש בעיה כלשהי. נסה מאוחר יותר או פנה לתמיכה.",
  "link text": "היי, בקישור אפשר להוריד את הזמנת הברזל שלי%0a",

  "sending order line 1": "עותק ישלח לספק, שינויים בהזמנה יהיו זמינים רק לך.",
  "sending order line 2": "רוצה לבצע שינויים בהזמנה? שלח שוב לאחר העריכה.",
  "share duplicate": "שלח עותק",
  "email input placeholder": "דואר אלקטרוני של הספק",
  "not easybar user line 1": "המייל שהקלדת לא קיים במערכת של איזיבר.",
  "not easybar user line 2": "ההזמנה תישלח כקובץ PDF עם הזמנת הצטרפות למערכת.",
  "send email": "שלח PDF",
  "order has been sent": "ההזמנה נשלחה בהצלחה",
  "order sent by email": "ההזמנה נשלחה בהצלחה",

  "order cant send" : "לא ניתן לשלוח הזמנה",

};
