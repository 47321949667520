export const COLUMNS = ["", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q"]

export const REBAR_HEADERS = [
  ["", "", "", "", "", "", "Weight", ""],
  ["Row Number", "Image Link", "Steel Type", "Diamter", "Length", "Amount", "Unit", "Total"],
  ["", "", "", "mm", "cm", "units", "kg", "kg"]
]
export const NET_HEADERS = [
  ["", "", "", "", "", "", "", "Weight", ""],
  ["Row Number", "X", "Y", "Diamter", "Gap", "SteelType", "Amount", "Unit", "Total"],
  ["", "cm", "cm", "mm", "cm", "", "units", "kg", "kg"]
]
export const SPECIAL_NET_HEADERS = [
  ["", "", "X", "", "", "", "", "", "Y", "", "", "", "", "", "", "Weight", ""],
  ["Row Number", "Steel Type", "Diameter", "Gap", "X", "X2", "X1", "X3", "Diameter", "Gap", "Y", "Y2", "Y1", "Y3", "Amount", "Unit", "Total"],
  ["", "", "mm", "cm", "cm", "", "", "", "mm", "cm", "cm", "", "", "", "units", "kg", "kg"]
]

export const HEADER_FONT = {
  name: 'Arial',
  color: { argb: '000' },
  family: 2,
  size: 10,
  italic: false
};
export const HEADER_FILL = {
  type: 'pattern',
  pattern:'solid',
fgColor:{argb:'f3f3f3'},
}
export const THIN_BORDER = {
  top : {style:'thin', color:'#000'},
  left : {style:'thin', color:'#000'},
  bottom : {style:'thin', color:'#000'},
  right : {style:'thin', color:'#000'},
}
export const HEADING_FONT = {
  name: 'Arial',
  color: { argb: '000' },
  family: 2,
  size: 25,
  bold: true
}