export const warningHe = {
  "delete surety": "האם ברצונך למחוק את ההזמנה לצמיתות?",
  "successfully saved": "האם תרצה לשמור את ההזמנה שלך?",
  // "save alert": "ההזמנה נשמרה",
  "save alert": "ההזמנה נשמרה בהצלחה",
  "download alert": "ההזמנה הורדה",
  "delete alert": "ההזמנה נמחקה",
  "delete failed": "לא ניתן לבצע את הפעולה כרגע",
  "delete company surety": "האם אתה בטוח שברצונך למחוק את החברה?",
  "company deleted alert": "החברה נמחקה",
  "company changes alert": "השינויים נשמרו",
  "company added": "חברה חדשה נוצרה",
  "error login": "אחד הפרטים לא נכונים, נסה שוב",
  "save before share": "כדי לשתף, נא לשמור תחילה את ההזמנה",
  "share options": "שתף את ההזמנה הזו עם",
  "order cannot saved":
    "יש בעיה כלשהי בשמירת ההזמנה שלך. אנא רענן את המסך ונסה שוב או פנה לתמיכה.",
  "cannot save": "לא ניתן לשמור את ההזמנה.",
  "pdf cannot generated": "לא ניתן ליצור pdf",
  "cannot generate": "יש בעיה כלשהי ביצירת pdf. נסה מאוחר יותר או פנה לתמיכה",
  "sure logout": "האם אתה בטוח רוצה לצאת?",
  // translations
  "order note":"הערת הזמנה (לא חובה)",
  "delete supplier surety": "האם ברצונך למחוק את הספק לצמיתות?",
};
