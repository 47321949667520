export const warningEn = {
  "delete surety": "Do you want to delete the order permanently?",
  "successfully saved": "Would you like to save your order?",
  // "save alert": "The order has been saved",
  "save alert": "your order has been saved!",
  "download alert": "The order has been downloaded",
  "delete alert": "The order has been deleted",
  "delete failed": "The action cannot be performed at the moment",
  "delete company surety": "Are you sure you want to delete this company?",
  "company deleted alert": "The Company has been deleted",
  "company changes alert": "The changes have been saved",
  "company added": "A new company has been created",
  "error login": "One of the details is incorrect, try again",
  "save before share": "To share, please save the order first",
  "share options": "Share this order with",
  "order cannot saved":
    "There is some problem in saving your order. Please try again or contact support.",
  "cannot save": "The order cannot be saved.",
  "pdf cannot generated": "Cannot generate pdf",
  "cannot generate":
    "There is some problem in generating pdf. Please try later or contact support.",
  "sure logout": "Are you sure you want to log out?",
  // translations
  "order note": "order note (optional)",
  "delete supplier surety": "Do you want to delete the supplier permanently?",
};
