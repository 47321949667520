import React, { useEffect, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import {ReactComponent as DNDlogo} from '../../../assets/img/admin/dnd-logo.svg';
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { fetchDefaultsPiles } from "Redux/middlwares/pile";
import { deletePile, postPile, updatePile } from "Services/pile";
import { fetchSteelDiameters } from "Redux/middlwares/steelType";

const PilesCard = () => {
  const userData = useAppSelector(state => state.user.userData);
  const defaultCages = useAppSelector(state => state.pile.defaultPiles);
  const steelTypes = useAppSelector(state => state.steelType.steelTypes);
  const steelDiameters = useAppSelector(state => state.steelType.steelDiameters);
  const dispatch = useAppDispatch();
  const [standardCageEdit, setStandardCageEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [standardCages, setStandardCages] = useState([]);

  const handleChangeCageValue = (key: string, val: number, index: number) => {
    const copyOfCage = [...standardCages as any];
    copyOfCage[index] = {
      ...copyOfCage[index],
      [key]: val,
      edit: true,
    };
    setStandardCages(copyOfCage as any);
  }

  const handleCageAddNewRow = () => {
    setStandardCages(
      [
        ...standardCages,
        {
          hole_diameter: '',
          cage_diameter: '',
          l1_length: '',
          l2_length: '',
          l2_gap: '',
          l3_length: '',
          l3_gap: '',
          cage_steel_type: '',
          spiral_wire_diameter: '',
          length_steel_type: '',
          base_diameter: '',
          base_amount: '',
          type: 2,
          subclass_type: "pile",
        }
      ] as any
    );
  }

  const handleSaveCages = () => {
    setLoader(true);
    const copyOfCages = ([...standardCages] as any)
      .filter((item: any) => !(item.id) || item.edit);
    const promises = [];
    for (let i=0; i<copyOfCages.length; i++) {
      if (copyOfCages[i].id) {
        promises.push(updatePile(
          copyOfCages[i].id,
          {
            hole_diameter: copyOfCages[i].hole_diameter,
            cage_diameter: copyOfCages[i].cage_diameter,
            l1_length: copyOfCages[i].l1_length,
            l2_length: copyOfCages[i].l2_length,
            l2_gap: copyOfCages[i].l2_gap,
            l3_length: copyOfCages[i].l3_length,
            l3_gap: copyOfCages[i].l3_gap,
            cage_steel_type: copyOfCages[i].cage_steel_type,
            spiral_wire_diameter: copyOfCages[i].spiral_wire_diameter.id,
            length_steel_type: copyOfCages[i].length_steel_type,
            base_diameter: copyOfCages[i].base_diameter.id,
            base_amount: copyOfCages[i].base_amount,
          }
        ));
      } else {
        promises.push(postPile(copyOfCages[i]));
      }
    }
    Promise.all(promises)
    .then(() => {
      dispatch(fetchDefaultsPiles());
      setStandardCageEdit(false);
    }).finally(() => setLoader(false));
  }

  const handleDeleteCage = (index: number) => {
    const cage = {...standardCages[index] as any};
    const copyOfCages = [...standardCages];
    if (cage.id) {
      deletePile(cage.id)
      .then(() => {
        copyOfCages.splice(index, 1);
        setStandardCages(copyOfCages);
      })
    } else {
      copyOfCages.splice(index, 1);
      setStandardCages(copyOfCages);
    }
  }


  useEffect(() => {
    if (userData) {
      defaultCages.length === 0 && dispatch(fetchDefaultsPiles());
    }
    if (defaultCages.length > 0) {
      setStandardCages(defaultCages as any);
    }
  }, [userData, defaultCages, defaultCages.length, dispatch]);

  const getDiameter = (steel_type: number, steelDiameterID: number) => {
    return steelDiameters[steel_type].find((item: any) => item.id === steelDiameterID);
  }

  return (
    <>
    <p className="py-4">Cage Pool</p>
    <Card>
      <Card.Body>
        <div className="d-flex justify-content-between pb-3">
          <p className="font-weight-bold">Standard Cage</p>
          <div className="d-flex justify-content-around align-items-center">
            <div className="px-2">
              <input type="checkbox" id="order-by-popularity" className="mr-1" />
              <label htmlFor="order-by-popularity">Order by Popularity</label>
            </div>
            <div className="px-2">
              <input type="checkbox" id="edit-text-cage" className="mr-1"
              checked={standardCageEdit}
              onChange={() => setStandardCageEdit(!standardCageEdit)}/>
              <label htmlFor="edit-text-cage">Edit Text</label>
            </div>
            
          </div>
        </div>
        {standardCageEdit &&
          <div className="d-flex justify-content-end pb-3">
            <Button
            variant="light"
            className="mr-2"
            onClick={handleCageAddNewRow}>add new row</Button>
            <Button
            variant="secondary"
            className="mr-2"
            onClick={() => {
              setStandardCageEdit(!standardCageEdit)
            }}>cancel</Button>
            <Button variant="success" onClick={handleSaveCages}
            disabled={loader}>
              save
              {loader &&
                <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                &nbsp;
                </>
              }
            </Button>
          </div>
          }
        <Table striped bordered hover className="admin-piles">
          <thead>
            <tr>
              <th></th>
              <th>D</th>
              <th>d</th>
              <th>L1</th>
              <th>L2</th>
              <th>@1</th>
              <th>L3</th>
              <th>@2</th>
              <th>Spiral Steel Type</th>
              <th>Spiral Wire Diameter</th>
              <th>Length Rebar Steel Type</th>
              <th>Length Rebar Diameter</th>
              <th>Quantity of Length Rebars</th>
              {standardCageEdit && <th></th>}
            </tr>
          </thead>
          <tbody>
            {standardCages.length > 0 && standardCages.map((item: any, index: number) => (
              <tr key={'cages'+index}>
              {standardCageEdit ?
                <>
                  <td>
                    <DNDlogo />
                  </td>
                  <td>
                    <input
                    type="number"
                    className="input-inside"
                    value={item.hole_diameter}
                    onChange={(e) => handleChangeCageValue('hole_diameter', parseInt(e.target.value), index)} />
                  </td>
                  <td>
                    <input
                    type="number"
                    className="input-inside"
                    value={item.cage_diameter}
                    onChange={(e) => handleChangeCageValue('cage_diameter', parseInt(e.target.value), index)} />
                  </td>
                  <td>
                    <input
                    type="number"
                    className="input-inside"
                    value={item.l1_length}
                    onChange={(e) => handleChangeCageValue('l1_length', parseInt(e.target.value), index)} />
                  </td>
                  <td>
                    <input
                    type="number"
                    className="input-inside"
                    value={item.l2_length}
                    onChange={(e) => handleChangeCageValue('l2_length', parseInt(e.target.value), index)} />
                  </td>
                  <td>
                    <input
                    type="number"
                    className="input-inside"
                    value={item.l2_gap}
                    onChange={(e) => handleChangeCageValue('l2_gap', parseInt(e.target.value), index)} />
                  </td>
                  <td>
                    <input
                    type="number"
                    className="input-inside"
                    value={item.l3_length}
                    onChange={(e) => handleChangeCageValue('l3_length', parseInt(e.target.value), index)} />
                  </td>
                  <td>
                    <input
                    type="number"
                    className="input-inside"
                    value={item.l3_gap}
                    onChange={(e) => handleChangeCageValue('l3_gap', parseInt(e.target.value), index)} />
                  </td>
                  <td>
                    <select className="form-control form-control-sm"
                      value={item.cage_steel_type || ''}
                      onChange={({target}) => {
                        const val = parseInt(target.value);
                        handleChangeCageValue('cage_steel_type', val, index);
                        if (!(val in steelDiameters)) dispatch(fetchSteelDiameters(val));
                      }}
                    >
                      <option value={''} disabled>Select</option>
                      {steelTypes.map((st: {id: number, name: string}) =>
                        <option key={'cage-steeltype-options-' + st.id} value={st.id}>{st.name}</option>
                      )}
                    </select>
                  </td>
                  <td>
                    <select className="form-control form-control-sm"
                      value={
                        item.spiral_wire_diameter
                        && item.cage_steel_type in steelDiameters
                        && steelDiameters[item.cage_steel_type].find((reDi: any) => reDi.id === item.spiral_wire_diameter.id)
                        ? item.spiral_wire_diameter.id : ''
                      }
                      onChange={(e) => {
                        const _diameter = getDiameter(item.cage_steel_type, parseInt(e.target.value));
                        if (_diameter) {
                          handleChangeCageValue('spiral_wire_diameter', _diameter, index);
                        }
                      }}
                    >
                      <option value={''} disabled>Select</option>
                      {item.cage_steel_type in steelDiameters
                      && steelDiameters[item.cage_steel_type].map((reDi: any, index: number) =>
                        <option key={'spiral-diameter-options-' + index} value={reDi.id}>{reDi.steel_diameter.toFixed(0)}</option>
                      )}
                    </select>
                  </td>
                  <td>
                    <select className="form-control form-control-sm"
                      value={item.length_steel_type || ''}
                      onChange={({target}) => {
                        const val = parseInt(target.value);
                        handleChangeCageValue('length_steel_type', val, index);
                        if (!(val in steelDiameters)) dispatch(fetchSteelDiameters(val));
                      }}
                    >
                      <option value={''} disabled>Select</option>
                      {steelTypes.map((st: {id: number, name: string}) =>
                        <option key={'length-rebar-steeltype-options-' + st.id} value={st.id}>{st.name}</option>
                      )}
                    </select>
                  </td>
                  <td>
                    <select className="form-control form-control-sm"
                      value={
                        item.base_diameter
                        && item.length_steel_type in steelDiameters
                        && steelDiameters[item.length_steel_type].find((reDi: any) => reDi.id === item.base_diameter.id)
                        ? item.base_diameter.id : ''
                      }
                      onChange={(e) => {
                        const _diameter = getDiameter(item.length_steel_type, parseInt(e.target.value));
                        if (_diameter) {
                          handleChangeCageValue('base_diameter', _diameter, index);
                        }
                      }}
                    >
                      <option value={''} disabled>Select</option>
                      {item.length_steel_type in steelDiameters
                      && steelDiameters[item.length_steel_type].map((reDi: any, index: number) =>
                        <option key={'net-special-diameter-options-' + index} value={reDi.id}>{reDi.steel_diameter.toFixed(0)}</option>
                      )}
                    </select>
                  </td>
                  <td>
                    <input
                    type="number"
                    className="input-inside"
                    value={item.base_amount}
                    onChange={(e) => handleChangeCageValue('base_amount', parseInt(e.target.value), index)} />
                  </td>
                  <td>
                    <button
                      style={{
                        border: 'none',
                        background: 'transparent',
                      }}
                      onClick={() => handleDeleteCage(index)}
                    >&#9587;</button>
                  </td>
                </>
              :
                <>
                  <td>
                    <DNDlogo />
                  </td>
                  <td>{item.hole_diameter}</td>
                  <td>{item.cage_diameter}</td>
                  <td>{item.l1_length}</td>
                  <td>{item.l2_length}</td>
                  <td>{item.l2_gap}</td>
                  <td>{item.l3_length}</td>
                  <td>{item.l3_gap}</td>
                  <td>{steelTypes.length > 0 && item.cage_steel_type && steelTypes.find(itemSt => itemSt.id === item.cage_steel_type).name}</td>
                  <td>{item.spiral_wire_diameter.steel_diameter ? item.spiral_wire_diameter.steel_diameter : ''}</td>
                  <td>{steelTypes.length > 0 && item.length_steel_type && steelTypes.find(itemSt => itemSt.id === item.length_steel_type).name}</td>
                  <td>{item.base_diameter.steel_diameter ? item.base_diameter.steel_diameter : ''}</td>
                  <td>{item.base_amount}</td>
                </>
              }
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
    </>
  );
}

export default PilesCard;