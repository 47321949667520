export const ticketCreatorHe = {
  "order details": "פרטי ההזמנה ",
  "item selected": "פריטים נבחרו",
  "select all": "סמן הכל",
  "mark or unmark": "אפשר להסיר פריטים מההדפסה ע״י מחיקת ה-V בשורה הרצויה",
  "print tickets": "הדפס",
  "ticket_creator": "הדפסת כרטיסיות ייצור",
  "order_no": "מספר ההזמנה",
  "input_placeholder": "הקלד מספר הזמנה כאן",
  "status": "סטטוס",
  "free_trial_status_text": "חודש ניסיון חינם עד ה-",
  "limited_status_text_heb" : "עד ",
  "limited_status_text" : " הזמנות בחודש",
  "unlimited_Status_text" : "חבילה בלתי מוגבלת",
  "free_trial_link" : "קרא פרטים נוספים",
  "limited_link" : "שדרג לחבילה בלתי מוגבלת",
  "unlimited_link" : "ערוך",
  "heb_1": "רוצה לחסוך זמן וכסף?",
  "ticket_create_txt_1": "שלח את הקישור ללקוח",
  "ticket_create_txt_2":
    "הלקוח יקליד את ההזמנות בשבילך אתה תקבל מספר הזמנה ותוכל להדפיס את הכרטיסיות עדיין",
  "need help": " צריכים עזרה שלנו? אנחנו זמינים",
  "whatsapp": "בוואטסאפ",
  "printer type": "בחר את סוג המדפסת",
  "sticker printer": "מדפסת מדבקות",
  "A4 printer": "מדפסת ביתית",
  "a4 description": "מדפסת ביתית שמדפיסה על דפי כרטיסיות (8 כרטיסיות בדף)",
  "sticker description 1": "מדפסת אלחוטית המדפיסה מדבקות מעוצבות מגליל.",
  "sticker description 2": "יתרונות - חיסכון בכרטיסיות מיותרות, ללא חוטי ברזל, ואפשרות לנייד את המדפסת ליד מכונת הייצור.",
  "more details": "אפשר לקרוא פה עוד פרטים",
  "welcome" : "ברוכים הבאים למערכת להדפסת כרטיסיות ייצור",
  "welcome_text": "כדי לחסוך לך זמן הקלדה של הזמנות, בקש מהלקוחות שלך להכין את ההזמנות חינם באיזיבר ולך יישאר להדפיס את הכרטיסיות.",
  "start": "אפשר להתחיל",
  "customer": "שם לקוח",
  "delivery": "תאריך אספקה",
  "site": "אתר",
  "item weight": "משקל פריט: ",
  "total weight": "משקל כולל: ",
  "type|diam": "קוטר | סוג",
  "length": "אורך",
  "amount": "כמות",
  "rebar_supplier" : "ספק ברזל? הדפס",
  "print_card" : "כרטיסיות ייצור בקליק",
  "supplier_name" : "מהו שם הספק?",
  "more_details": "קרא עוד פרטים",
  "start_trial" : "התחל חודש ניסיון חינם",
  "type_name": "הקלד את השם הרישמי",
  "next" : "הבא",
  "add_Link_1": "כדי שנכיר אותך הוסף קישור לאתר/פייסבוק שלך",
  "add_Link_2": "(לא חובה)",
  "paste_link": "העתק את הקישור והדבק פה",
  "your_details": "הפרטים שלך בדרך אלינו, נחזור אליך בקרוב.",
  "soon_print": "עוד מעט תוכל לחסוך זמן ולהדפיס כרטיסיות בקלות.",
  "go_back" :"חזור להזמנות",
  "looks_like": "נראה שניצלת את כל החבילה החודש",
  "package_renew": "החבילה תתחדש ב-",
  "go_unlimited": "מעוניין להמשיך להדפיס? לחץ כאן",
  "share_opinions": "",
  "short_survey" : "שתפו אותנו בדעתכם > סקר קצר על המוצר ",
  "trial_finished": "חודש הניסיון הסתיים. רוצה להמשיך להדפיס באיזיבר?",
  "connect": "צור קשר",
  "print": "הדפס",
  "Load an order": "טען הזמנה",
  "orderNo details": "המספר מופיע בפינה השמאלית העליונה של מסמך ה-PDF",
  "coming soon": "עוד פרטים בקרוב",
  "Deselect all": "בטל את סימון כל הפריטים",
  "special net": "רשת מיוחדת",
  "standard net": "רשת סטנדרטית",
  "Note": "הערה לפריט",
  "steel type" : "סוג מוט",
  "gap" : "פסיעה",
  "type" : "סוג",
  "404 error": "מספר ההזמנה לא קיים, או שאין לך הרשאה להזמנה. בקש מיוצר ההזמנה לשלוח לך הרשאה. נסה שוב או ",
  "404 error link": "פנה אלינו",
  "used": "בשימוש",
  
  "nickname": "כינוי",
  "total length": "אורך כולל",
  "con/cage" : "כלוב/בטון",
  "base cage": "כלונס ביסוס",
  "side cage": "כלונס דיפו",
  "rings": "טבעות",
  "gama": "צינורות גמא",
  "Tzol": "צול",
  "tickets amount": "כמות כרטיסיות",

};
