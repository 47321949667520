import "./index.scss";
import PlusIcon from '../../../assets/img/order-details/plus-thin.svg';
import DropDownIcon from "../../../assets/img/order-details/drop-down.png";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "Redux/hooks";

const AddCompany = (props) => {
  const {
    onNewCompanyModalOpen,
    userBusinesses,
    setActiveCompany,
    activeCompany,
    userData,
  } = props;

  const { t } = useTranslation(["common", "userProfile"]);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);


  return (
    <div className="company__bar">
      <div className="current__company">
        <label htmlFor="" className={rightToLeft ? "label__ml" : "label__mr"}>
          {t("Current company", { ns: "userProfile" })}
        </label>
        <select
          name="activeselectmobile"
          value={activeCompany}
          className={rightToLeft ? "rightToLeft" : ""}
          style={{
            width: "275px",
            backgroundImage: `url(${DropDownIcon})`,
          }}
          onChange={({ target }) => {
            const company = userBusinesses.find(
              (item) => item.id === parseInt(target.value)
            );
            setActiveCompany(company.id);
          }}
        >
          {userData.current_company === null ? (
            <option selected disabled>
              {t("select current company", { ns: "userProfile" })}
            </option>
          ) : (
            ""
          )}
          {userBusinesses.map((pr, index) => (
            <option key={"pr-mob" + index} value={pr.id}>
              {pr.name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <button className="new__company" onClick={onNewCompanyModalOpen}>
          <img src={PlusIcon} alt="" />
          {t("add company", { ns: "userProfile" })}
        </button>
      </div>
    </div>
  );
};

export default AddCompany;
