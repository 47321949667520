import React, { useState } from "react";

import deleteIcon from "Assets/img/blocks/delete.svg";
import duplicateIcon from "Assets/img/blocks/duplicate.svg";

import { useAppSelector } from "Redux/hooks";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import "../index.styled.scss";

const RebarsCard = (props) => {
  const {
    item,
    index,
    deleteTool,
    orderItem,
    copyOrderItem,
    setEditTool,
    setSelectedTool,
    setBoxIndex,
    boxIndex,
    setInProgress,
    inProgress,
    editToolId,
    edit,
  } = props;

  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  const [isHovered, setIsHovered] = useState(0);
  const [hoveredIcon, setHoveredIcon] = useState(false);

  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
  } = useSortable({
    id: props.id,
    disabled: hoveredIcon ? true : false,
  });

  const style = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };

  const deleteItem = () => {
    deleteTool(orderItem);
  };

  const copyItem = () => {
    setInProgress(true);
    setIsHovered(0);
    copyOrderItem(orderItem).then(() => {
      setInProgress(false);
    });
  };

  const overlayStyle = {
    backgroundColor: "rgba(175, 179, 185, 0.2)",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display:
      editToolId !== orderItem.id && edit && editToolId !== -1 ? "block" : "",
    borderRadius: "5px",
  };

  return (
    <div
      className={
        "cardItem_Rebars" +
        (boxIndex === orderItem.id && editToolId === -1 ? " active" : "") +
        (editToolId === orderItem.id
          ? " active"
          : edit && editToolId >= 0
          ? " not-active"
          : "")
      }
      key={"defaultRebars" + index}
      ref={setNodeRef}
      style={
        isDragging
          ? {
              cursor: "grabbing",
              position: "relative",
              ...style,
            }
          : {
              cursor: "grab",
              position: "relative",
              ...style,
            }
      }
      {...attributes}
      {...listeners}
      onMouseEnter={() => {
        setIsHovered(item.id);
      }}
      onMouseLeave={() => setIsHovered(0)}
    >
      {isDragging ? (
        <div style={{ background: "rgba(255, 255, 255, 0.3)", height: "75px" }}>
          &nbsp;
        </div>
      ) : (
        <>
          {isHovered === item.id && !inProgress && (
            <div
              className="d-flex flex-column justify-content-center"
              style={
                rightToLeft
                  ? { position: "absolute", top: "18%", right: "92%" }
                  : { position: "absolute", top: "18%", left: "92%" }
              }
            >
              <div
                className="card-hover-icons delete-Icon mb-2"
                onClick={deleteItem}
                onMouseEnter={() => setHoveredIcon(true)}
                onMouseLeave={() => setHoveredIcon(false)}
              >
                <img src={deleteIcon} alt="delete Icon" />
              </div>
              <div
                className="card-hover-icons duplicate-Icon"
                onClick={copyItem}
                onMouseEnter={() => setHoveredIcon(true)}
                onMouseLeave={() => setHoveredIcon(false)}
              >
                <img src={duplicateIcon} alt="duplicate Icon" />
              </div>
            </div>
          )}
          {editToolId !== orderItem.id && edit && editToolId !== -1 && (
            <div style={overlayStyle}></div>
          )}
          <div
            className="rebs-box-inside"
            onClick={() => {
              setEditTool(true);
              setSelectedTool(orderItem);
              setBoxIndex(orderItem.id);
              //Here set errors to null
            }}
          >
            <img
              src={item.preview_image.file_field}
              style={{ width: "100%", height: "auto" }}
              alt="Rebar sketch"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default RebarsCard;
