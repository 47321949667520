export const NET_SUBCLASS_TYPE = 'net';
export const netDiameterOptions=[5, 5.5, 6, 6.5, 7, 8, 9, 10, 11, 12, 14, 16];
export const netXLengthMin = 80;
export const netXLengthMax = 751;
export const netYLengthMin = 80;
export const netYLengthMax = 751;
export const netXYLengthMax = 320;
export const gapOptions = [5, 10, 15, 20, 25];
export const defaultXYLen = [{x: "370", y: "250"}, { x: "250", y: "600" }];
export const minAmount = 1;
export const minRatio = 0.6;

export const netErrorTypes = {
  MAX: 1,
  MIN: 2,
  MAXMIN: 3,
  AMOUNT: 4,
  DIAMETER_RATIO: 5,
  _X1: 6,_X2: 7,_X3: 8,
  _Y1: 9,_Y2: 10,_Y3: 11,
  
  MIN_Y2PART: 12,
  MIN_Y3PART: 13,
  MIN_X2PART: 14,
  MIN_X3PART: 15,

  GAP_Y1_VALIDATION: 16,
  GAP_X1_VALIDATION: 17,
  GAP_X1Y1_VALIDATION: 18,

  AMOUNT_NEG: 19,
};

export const netsWithoutPrice = {
  gridTemplateColumns: "4% 5% 12% 9% 35% 9% 8% 18%",
};

export const netsWithPrice = {
  gridTemplateColumns: '2.5fr 2fr 1.8fr 1.8fr 1.8fr 1.8fr 0.8fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr',
};

export const lastNetsWithoutPrice = {
  gridTemplateColumns: "1fr 2fr 2fr 7fr 2fr 8% 18%",
};

export const lastNetsWithPrice = {
  gridTemplateColumns: '2.5fr 2fr 1.8fr 1.8fr 1.8fr 1.8fr 0.8fr 1.5fr 3fr 3fr',
};


export const netsTicketWithoutPrice = {
  gridTemplateColumns: "7% 7% 7% 12% 8% 26% 9% 6% 18%",
};

export const netsTicketWithPrice = {
  gridTemplateColumns: '1fr 2.5fr 2fr 1.8fr 1.8fr 1.8fr 1.8fr 0.8fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr',
};

export const lastNetsTicketWithoutPrice = {
  gridTemplateColumns: "5% 5% 9% 10% 8% 30% 9% 6% 18%",
};

export const lastNetsTicketWithPrice = {
  gridTemplateColumns: '3.5fr 2fr 1.8fr 1.8fr 1.8fr 1.8fr 0.8fr 1.5fr 3fr 3fr',
};


export const netSidebarInput = {
  NET_NAME: 1,
  X_WIDTH: 2,
  Y_HEIGHT: 3,
  AMOUNT: 4,
  
  // Standard
  DIAMETER: 5,
  GAP: 6,
  STEEL_TYPE: 7,
  
  // Special
  DIAMETER_X: 8,
  GAP_X: 9,
  STEEL_TYPE_X: 10,
  X_2: 11,
  X_1: 12,
  X_3: 13,

  DIAMETER_Y: 14,
  GAP_Y: 15,
  STEEL_TYPE_Y: 16,
  Y_2: 17,
  Y_1: 18,
  Y_3: 19,

  NOTE: 20,
  QUANTITY: 21,
  ADD: 22,
};

export const specialNetSidebarErrors = [5,6,7,8,9,10,11,12,13,14,15,16,17,18];
export const netsSidesErrors = [12,13,14,15]
export const sidesErrorsX = [6,7,8,14,15,17,18]
export const sidesErrorsY = [9,10,11,12,13,16,18]