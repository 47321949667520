import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { useAppSelector } from 'Redux/hooks';

const GuestOnlyRoute = ({ children, ...rest }: any) => {
    const user = useAppSelector(state => state.user.userData);
    return (
        <Route {...rest} render={(props) => (
            user ?
            <Redirect to='/new-order' />
            : <>{children}</>
        )} />
    );
} 

export default GuestOnlyRoute;