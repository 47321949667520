import close from '../../../assets/img/new__user__form/close.png';
import { Modal, Button } from 'react-bootstrap';
import { useState } from 'react';
import { signUpUser } from 'Services/user';
import { useTranslation } from "react-i18next";
import { useAppSelector } from "Redux/hooks";
import { Link } from 'react-router-dom';

function CreateNewUser (props: any) {
  const passwordRe = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  const rightToLeft = useAppSelector(state => state.root.rightToLeft);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [job, setJob] = useState('');
  const [partOfCompany, setPartOfCompany] = useState(false);
  const [termAgree, setTermAgree] = useState(false);
  const { t } = useTranslation(['common', 'userProfile', 'header']);

  const handleSubmitUser = (e: React.SyntheticEvent) => {
    e.preventDefault();
    signUpUser({
      first_name: name.substring(0, name.indexOf(' ')),
      last_name: name.substring(name.indexOf(' ') + 1),
      email,
      password,
      phone,
      job_title: job,
      is_worker: partOfCompany,
      terms_agreement: termAgree,
    })
    .then(() => {
      props.onHide();
      props.openLogin();
    }).catch((err) => console.log(err));
  }



  return (
    <>
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <section className="forms">
        <div className="container">
          <div className="row py-5 mx-auto">
            <div className="
                                form__header
                                col-sm-12
                                p-4
                                d-flex
                                justify-content-between
                                pt-3
                            ">
              <h1 className="text-white text-uppercase mb-0">
                {t('create your user', {ns: 'header'})}

              </h1>
                <span className="close__btn" onClick={props.onHide}>
                  <img className="w-100" src={close} alt="close icon" />
                </span>
            </div>
            <div className="col-sm-12 bg-white form__body py-4">
              <div className="form__title pb-md-3 border__bottom pb-2">
                <h3 className="text-uppercase mb-0">{t('User Details', {ns: 'userProfile'})}</h3>
                <span className="text-capitalize">{t('required fields', {ns: 'newOrder'})}*</span>
              </div>
              <form className="pt-4" onSubmit={handleSubmitUser}>
                <div className="inputs pb-2">
                  <div className="form-group d-md-flex justify-content-md-between">
                    <label htmlFor="name" className="text-uppercase">{t('Name', {ns: 'common'})}*</label>
                    <input type="text" className="form-control w-100 w-md-70" id="name" aria-describedby="name"
                      placeholder={t('type full name', {ns: 'common'})}
                      value={name || ""}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group d-md-flex justify-content-md-between">
                    <label htmlFor="email" className="text-uppercase">{t('Email', {ns: 'common'})}*</label>
                    <input type="email" className="form-control w-100 w-md-70" id="email" aria-describedby="emailHelp"
                      placeholder={t('type email', {ns: 'common'})}
                      value={email || ""}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group d-md-flex justify-content-md-between">
                    <label htmlFor="password" className="text-uppercase">{t('Password', {ns: 'common'})}*</label>
                    <input type="password" className="form-control w-100 w-md-70" id="password" aria-describedby="passwordHelp"
                      placeholder={t('type password', {ns: 'common'})}
                      value={password || ""}
                      style={
                        password && !(passwordRe.test(password)) ? {
                          borderColor: '#CD2017',
                          boxShadow: '0 0 0 0.2rem rgb(205 32 23 / 25%)'
                        } : {}
                      }
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="form-group d-md-flex justify-content-md-between">
                    <label htmlFor="phone" className="text-uppercase">{t('Phone', {ns: 'common'})}:</label>
                    <input type="text" className="form-control w-100 w-md-70" id="phone" aria-describedby="emailHelp"
                      placeholder={t('type phone number', {ns: 'common'})}
                      value={phone || ""}
                      style={
                        phone && !(/^0\d{1,2}-?\d{3}-?\d{4}$/.test(phone)) ? {
                          borderColor: '#CD2017',
                          boxShadow: '0 0 0 0.2rem rgb(205 32 23 / 25%)'
                        } : {}
                      }
                      onChange={(e) => setPhone(e.target.value)}
                      pattern="^0\d{1,2}-?\d{3}-?\d{4}$"
                      title="Phone number must be 9 or 10 digits"
                    />
                  </div>
                  <div className="form-group d-md-flex justify-content-md-between">
                    <label className="text-uppercase" htmlFor="job">{t('Job', {ns: 'common'})}:</label>
                    <input type="text" className="form-control w-100 w-md-70" id="job" aria-describedby="job"
                      placeholder={t('type job', {ns: 'common'})}
                      value={job || ""}
                      onChange={(e) => setJob(e.target.value)}
                    />
                  </div>
                  <div style={{
                    visibility: password ? 'visible' : 'hidden',
                    height: password ? 82.5 : 0,
                    transition: 'visibility 0s, height 0.5s linear',
                    overflow: 'hidden'
                  }}>
                    <p style={{fontSize: '11px'}}>{t('pswrd inst', {ns: 'userProfile'})}</p>
                    <ul style={{fontSize: '11px'}}>
                      <li>{t('pswrd char', {ns: 'userProfile'})}</li>
                      <li>{t('one letter', {ns: 'userProfile'})}</li>
                      <li>{t('one number', {ns: 'userProfile'})}</li>
                      <li>{t('special char', {ns: 'userProfile'})} (@$!%*#?&)</li>
                    </ul>
                  </div>
                </div>
                <div className="radio__btns">
                  <div className="form-group form-check pt-0">
                    <input type="checkbox" className="form-check-input" id="exampleCheck1"
                      checked={partOfCompany}
                      onChange={() => setPartOfCompany(!partOfCompany)}
                    />
                    <label className={"form-check-label " + (rightToLeft ? "mr-4" : "")} htmlFor="exampleCheck1">{t('work as part of company', {ns: 'userProfile'})}</label>
                  </div>
                  <div className="form-group form-check mb-0">
                    <input type="checkbox" className="form-check-input" id="exampleCheck2"
                      checked={termAgree}
                      onChange={() => setTermAgree(!termAgree)}
                    />
                    <label className={"form-check-label " + (rightToLeft ? "mr-4" : "")} htmlFor="exampleCheck2">
                      {t('terms start', {ns: 'userProfile'})}
                      <Link to="/terms-and-conditions" target="_blank">{t('term conditions', {ns: 'userProfile'})}</Link>
                      {t('terms last', {ns: 'userProfile'})}
                    </label>
                  </div>
                </div>
                <div className="
                                        btn__wrap
                                        py-2
                                        pt-4
                                        d-flex
                                        justify-content-between
                                    ">
                  <Button variant="form" onClick={props.onHide}>{t('cancel', {ns: 'common'})}</Button>
                  <button type="submit" className="btn form__btn__black"
                    disabled={
                      name === ""
                      || email === ""
                      || password === ""
                      || !passwordRe.test(password)
                      || termAgree === false
                    }
                  >
                    {t('Save', {ns: 'common'})}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Modal>
    </>
  );
}

export default CreateNewUser;
