import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { format } from "date-fns";
import { Dropdown } from 'react-bootstrap';
import { getSupplierList, patchSupplierDetails } from "Services/supplier-tickets";
import { withTranslation } from "react-i18next";

const SupplierList = () => {

  // Supplier Table States
  const [suppliers, setSuppliers] = useState([]);
  const [suppliersPage, setSuppliersPage] = useState('');
  const [prevSuppliersPage, setPrevSuppliersPage] = useState('');
  const [suppliersIndex, setSuppliersIndex] = useState(0);

  const supplierStatus = [ "In Review", "Approved", "Suspended"]
  const supplierPackage = [ "Free Trial", "Limited", "Unlimited"]
  const supplierPackageStatus = [ "Operational", "Ceased"]

  const handleSupplierPackage = (supplier_id: number, value: number)=> {
    patchSupplierDetails(supplier_id, {package: value})
    .then(()=>{
      const to_update = document.getElementById(`package_${supplier_id}`);
      if(to_update)
      to_update.innerText = supplierPackage[value]
    })
  }

  const handleSupplierPackageStatus = (supplier_id: number, value: number)=> {
      patchSupplierDetails(supplier_id, {package_status: value})
        .then(()=>{
          const to_update = document.getElementById(`package_status_${supplier_id}`);
          if(to_update)
          to_update.innerText = supplierPackageStatus[value]
        })
    }

    const handleSupplierStatus = (supplier_id: number, value: number)=> {
      patchSupplierDetails(supplier_id, {status: value})
        .then(()=>{
          const to_update = document.getElementById(`status_${supplier_id}`);
          if(to_update)
          to_update.innerText = supplierStatus[value]
        })
    }

  const supplierColumns = [
    {
      dataField: 'id',
      text: 'Sr',
      sort: true,
      formatter: (cell: any, row: any, rowIndex: number) => rowIndex + suppliersIndex + 1
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
    },
    {
      dataField: 'link',
      text: 'Link',
      sort: true,
      formatter: (cell:any, row: any) => {
        if (row.link)
          return <a href={row.link}>link</a> 
        else return "---"}
    },
    {
      dataField: 'email',
      text: 'Original User',
      sort: true,
    },
    {
      dataField: 'created',
      text: 'Sign Date',
      sort: true,
      formatter: (cell: any, row: any) => row && row.created ? format(new Date(row?.created), 'd.M.yyy') : '🤫'
    },
    {
      dataField: 'package',
      text: 'Package',
      sort: true,
      formatter: (cell: any, row: any) => (
        <div className="row justify-content-center px-1">
          <Dropdown onSelect={(eventKey)=> {handleSupplierPackage(row.id, parseInt(eventKey))}}>
            <Dropdown.Toggle variant="success" id={`package_${row.id}`} style={{backgroundColor: 'white', color: 'black', border: 'none'}}>
              {supplierPackage[row.package]}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="0">Free Trial</Dropdown.Item>
              <Dropdown.Item eventKey="1">Limited</Dropdown.Item>
              <Dropdown.Item eventKey="2">Unlimited</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>    
        </div>
      )
    },
    {
      dataField: 'package_status',
      text: 'Package Status',
      sort: true,
      formatter: (cell: any, row: any) => (
        <div className="row justify-content-center px-1">
          <Dropdown onSelect={(eventKey)=> {handleSupplierPackageStatus(row.id, parseInt(eventKey))}}>
            <Dropdown.Toggle variant="success" id={`package_status_${row.id}`} style={{backgroundColor: 'white', color: 'black', border: 'none'}}>
              {supplierPackageStatus[row.package_status]}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="0">Operational</Dropdown.Item>
              <Dropdown.Item eventKey="1">Ceased</Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>    
        </div>
      )
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: (cell: any, row: any) => (
        <div className="row justify-content-center px-1">
          <Dropdown onSelect={(eventKey)=> {handleSupplierStatus(row.id, parseInt(eventKey))}}>
            <Dropdown.Toggle variant="success" id={`status_${row.id}`} style={{backgroundColor: 'white', color: 'black', border: 'none'}}>
              {supplierStatus[row.status]}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="0">{supplierStatus[0]}</Dropdown.Item>
              <Dropdown.Item eventKey="1">{supplierStatus[1]}</Dropdown.Item>
              <Dropdown.Item eventKey="2">{supplierStatus[2]}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>    
        </div>
      )
    },
    {
      dataField: 'printing_count',
      text: 'Printing Count',
      sort: true,
    }, 
  ]

  const getSuppliers = () => {
    setSuppliersPage(null as any);
    setPrevSuppliersPage(null as any);
    if(suppliersPage || suppliersPage === ''){
      getSupplierList(suppliersPage)
        .then((res)=>{
          if(res.data.results.length > 0) {
            setSuppliers([...res.data.results] as any);
            if(res.data.next) setSuppliersPage(res.data.next.split('cursor=')[1].split('&')[0]);
            else  setSuppliersPage(null as any);

            if(res.data.previous) setPrevSuppliersPage(res.data.previous.split('cursor=')[1].split('&')[0]);
            else setPrevSuppliersPage(null as any);
          }
        })
    }
  }


  const getNextSuppliers = () => {
    if (suppliersPage || suppliersPage === '') {
      getSupplierList(suppliersPage)
        .then((res) => {
          if (res.data.results.length > 0) {
            setSuppliers([...res.data.results] as any);
            if (res.data.next) setSuppliersPage(res.data.next.split('cursor=')[1].split('&')[0]);
            else setSuppliersPage(null as any);
            if (res.data.previous) setPrevSuppliersPage(res.data.previous.split("cursor=")[1].split("&")[0]);
            else setPrevSuppliersPage(null as any);
          }
        })
    }
  }

  const getPrevSuppliers = () =>{
    if (prevSuppliersPage || prevSuppliersPage === '') {
      getSupplierList(prevSuppliersPage)
        .then((res) => {
          if(res.data.results.length > 0) {
            setSuppliers([...res.data.results] as any);
            if(res.data.next){
              setSuppliersPage(res.data.next.split('cursor=')[1].split('&')[0]);
            }
            else{
              setSuppliersPage(null as any);
            }

            if(res.data.previous){
              setPrevSuppliersPage(res.data.previous.split('cursor=')[1].split('&')[0]);
            }
            else{
              setPrevSuppliersPage(null as any);
            }
          }
        })
  }
};


  useEffect(() => {
    getSuppliers();
  }, []);

  useEffect(() => {
    
  }, [suppliers]);

  return (
    <div id="supplier-tickets_1">
      <div className="d-flex justify-content-between py-4">
          <p>Suppliers</p>
        </div>
        <Card>
          <Card.Body>
            <BootstrapTable
              keyField='id'
              data={suppliers}
              columns={supplierColumns}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {prevSuppliersPage !== null ? (
                <Button variant='light' size='sm' className="external-pagination-btn pagination-btn-prev mr-2" onClick={(e) => {
                  setSuppliersIndex((p) => p - 30);
                  getPrevSuppliers();
                }}>
                  Prev
                </Button>
              ) : null}
              {suppliersPage !== null ? (
                <Button
                  variant='light'
                  size='sm'
                  className="external-pagination-btn pagination-btn-next"
                  onClick={(e) => {
                    setSuppliersIndex(p => p + 30);
                    getSuppliers();
                  }}
                >
                  Next
                </Button>
              ) : null}
            </div>


            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {prevSuppliersPage !== null ? (
                <Button variant='light' size='sm' className="external-pagination-btn pagination-btn-prev mr-2" onClick={(e) => {
                  setSuppliersIndex((p) => p - 30);
                  getPrevSuppliers();
                }}>
                  Prev
                </Button>
              ) : null}
              {suppliersPage !== null ? (
                <Button
                  variant='light'
                  size='sm'
                  className="external-pagination-btn pagination-btn-next"
                  onClick={(e) => {
                    setSuppliersIndex(p => p + 30);
                    getNextSuppliers();
                  }}
                >
                  Next
                </Button>
              ) : null}
            </div>
          </Card.Body>
        </Card>
      </div>
  );
};

export default withTranslation('common')(SupplierList);