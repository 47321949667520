export const newOrderHe = {
  "Order Details": "פרטי ההזמנה",
  "Create Date": "תאריך יצירת מסמך",
  "Create": "תאריך יצירת ההזמנה",
  "Creator Job": "תפקיד",
  "Business Name": "שם החברה",
  "Project Name": "שם הפרויקט",
  "Project Manager": "מנהל פרויקט",
  "Order Notes": "הערות להזמנה",
  "Order Summary": "סיכום הזמנה",
  "Rebars": "מוטות",
  "Nets": "רשתות",
  "Cages": "כלונסאות",
  "Total": "סה״כ",
  "Typing Note": "הקלד הערות",
  "Units": "יחידות",
  "Weight": "משקל",
  "Ton": "טון",
  "Add New Item": "הוספת פריט חדש",
  "edit item": "עריכת פריט",
  "Add New Rebar": "הוסף מוט חדש",
  "add new net": "הוסף רשת חדשה",
  "add new cage": "הוסף כלונס חדש",
  "Enter your prices": "הכנס את המחירים שלך",
  "sketch": "שרטוט",
  "steel": "ברזל",
  "Ask for Bid": "בקש הצעת מחיר",

  "add new business": "הוסף חברה חדשה",
  "business details": "פרטי החברה",
  "required fields": "שדות חובה",
  "delete this business": "מחק חברה",
  "bn number": "ח.פ חברה",

  "business name": "הקלד שם חברה",
  "business address": "הקלד את כתובת החברה",
  "business city": "הקלד את עיר החברה",
  "business mail": "הקלד דואר אלקטרוני של החברה",
  "phone warning": "המספר צריך להכיל רק 9-10 ספרות",
  "business phone": "הקלד את טלפון החברה",

  "add new project": "הוספת פרויקט חדש",
  "site": "אתר",
  "project details": "פרטי הפרויקט",
  "delete this project": "מחק פרויקט",
  "project name": "שם הפרוייקט",
  "contact": "אנשי קשר",
  "delete": "מחק",
  "add another contact": "הוסף איש קשר נוסף",
  "location": "מיקום",
  "address": "כתובת למשלוח",
  "location notes": "הערות מיקום",
  "notes": "הערות",

  "add buisness name": "הוסף שם עסק",
  "add project name": "הוסף שם פרויקט",

  "ring": "טבעת",
  "gamma": "גמא",
  "ear": "אוזן",

  "preview": "תצוגה מקדימה",
  "download": "הורד",
  "email": "דואר אלקטרוני",

  "add project": "הוסף פרויקט כדי להמשיך",
  "add a company": "הוסף חברה כדי להמשיך",
  "add delivery date": "הוסף תאריך אספקה ​​כדי להמשיך",
  "add created date": "הוסף את תאריך היצירה כדי להמשיך",
  "add one tool": "הוסף פריט אחד לפחות כדי להמשיך",

  "Last update": "עדכון אחרון",
  "Order number": "מספר הזמנה",
  "Order name": "שם ההזמנה",
  "Delivery date": "תאריך אספקה נדרש",
  "Company details": "פרטי החברה",
  "first site": "הוסף את האתר הראשון שלך",
  "add company details": "להוסיף פרטי חברה",
  "before typing": "הקלידו שם שיעזור לכם למצוא את ההזמנה",
  "Order creator": "פרטי המזמין",
  "Delivery details": "פרטי משלוח",
  "Full name": "שם מלא",
  "phone": "טלפון",
  "Job title": "הגדרת תפקיד",
  "site man": "עובד באתר",
  "site manager": "מנהל עבודה",
  "Street": "רחוב",
  "city": "עיר",
  "contact man": "איש קשר בשטח",
  "Driver notes": "הערות לנהג",
  "Edit project": "ערוך פרויקט",
  "the site manager": "אני מנהל האתר",
  "line1": " כתובת 1",
  "line2": " כתובת 2",
  "delivery contacts": "אנשי קשר למשלוח",
  "the delivery contact": "אני איש הקשר למשלוח",
  "Phone num": "מספר טלפון",
  "error marks": "השגיאות מסומנות באדום",
  "Identify project": "כך אתה מזהה את הפרויקט",
  "Numbers only": "מספרים בלבד",
  "Project city": "באיזו עיר ממוקם הפרויקט?",
  "Street NandN": "שם רחוב ומספר",
  "Delivery msg": "הודעה תופיע לנהג המשלוח",
  "Apartment num": "מספר דירה",
  "the Delivery Address": "כתובת המשלוח זהה לכתובת האתר",

  "Edit company": "ערוך חברה",
  "Add new company": "הוסף חברה חדשה",
  "Company name": "שם החברה",
  "Logo": "לוגו",
  "addresss": "כתובת",
  "position": "עמדה",
  "Company position": "מה תפקידך בחברה?",
  "official name": "השם הרשמי של החברה",
  "official email": 'דוא"ל רשמי של החברה',
  "official phone number":"מספר הטלפון הרשמי של החברה",
  "company located": "באיזו עיר ממוקמת החברה?",
  "owner": "בעלים",
  "employee": "שכיר",
  "service provider": "נותן שירות",
  "other": "אחר",
  "company logo": "לחץ כדי להוסיף לוגו חברה",
  "add company logo": "להוסיף לוגו חברה",

  "Duplicate": "שכפל",

  "OWNER": "בעלים",
  "EMPLOYEE": "שכיר",
  "SERVICE_PROVIDER": "נותן שירות",
  "OTHER": "אחר",
  "add order name": "הוסף שם להזמנה",

  "error_rebar_steel": "אנא בחר סוג פלדה",
  "error_rebar_diameter": "אנא בחר קוטר",
  "error_rebar_measure": "המידה חורגת מהתקן",
  "error_rebar_length": "האורך הכללי ארוך מידי",
  "error_rebar_amount": "יש להוסיף כמות פריטים רצויה",
  "error_rebar_radius_min": "הרדיוס המינימלי צריך להיות 1",
  "error_rebar_radius_max": "ערך הרדיוס גדול מהערך המקסימלי",

  "previewNetsInfo": "פירוט של כל המידות יופיע בקובץ הסופי. אפשר ללחוץ על תצוגה מקדימה בתחתית המסך כדי לראות את הקובץ הסופי.",
  "previewMeasInfo": "נתונים מפורטים של המידות יופיעו בקובץ הסופי. אפשר ללחוץ על תצוגה מקדימה בתחתית המסך כדי לראות את הקובץ הסופי.",
  "error_rebar_ear": "לפי התקן קצוות המוט צריכות להיות מינימום פי 10 מקוטר המוט", 

  "edit logo": "ערוך לוגו",
  "add logo": "הוסף לוגו",

  "Deleting an item": "מחיקת פריט",
  "Delete the item?": "האם למחוק את הפריט?",

  "company settings" : "הגדרות חברה",
  "user settings":"הגדרות המזמין/המשתמש",

  "Name" : "כינוי"
};
