import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface OrderState {
  currentOrder: any;
  currentOrderSupplier: any;
  showPrice: boolean;
  value: number;
  isDeleted: boolean;
  reqStatus: any;
  orderId: number;
}

const initialState: OrderState = {
  currentOrder: {},
  currentOrderSupplier: null,
  showPrice: false,
  value: 0,
  isDeleted: false,
  reqStatus: null,
  orderId: 0,
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setCurrentOrder: (state, action: PayloadAction<any>) => {
      state.currentOrder = action.payload;
    },
    setCurrentOrderSupplier: (state, action: PayloadAction<any>) => {
      state.currentOrderSupplier = action.payload;
    },
    setShowPrice: (state, action: PayloadAction<any>) => {
      state.showPrice = action.payload;
    },
    deletedOrder: (state, action: PayloadAction<any>) => {
      state.isDeleted = action.payload;
    },
    setOrderShareStatus: (state, action) => {
      state.reqStatus = action.payload.req;
      state.orderId = action.payload.orderId;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCurrentOrder,
  setCurrentOrderSupplier,
  setShowPrice,
  deletedOrder,
  setOrderShareStatus,
} = orderSlice.actions;

export default orderSlice.reducer