import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { setOrderShareStatus } from "Redux/features/order";

const OrderAcceptance = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const userData = useAppSelector((state) => state.user.userData);

  const queryParams = new URLSearchParams(location.search);
  const requestStatus = queryParams.get("request");
  const orderId = queryParams.get("order");

  const [isLoading, setIsLoader] = useState(true);

  useEffect(() => {
    const updateStore = async () => {
      if (requestStatus && orderId > 0) {
        await dispatch(setOrderShareStatus({ req: requestStatus, orderId }));
        if (userData) {
          setIsLoader(false);
          history.push("/my-order");
        } else {
          setIsLoader(false);
          history.push(`/login`);
        }
      }
    };

    updateStore();
  }, [userData]);

  const loaderContainer = {
    display: "flex",
    justifyContent: "center",
    height: "100vh",
    alignItems: "center",
  };

  return (
    <div>
      {isLoading && (
        <div style={loaderContainer}>
          <div
            className="spinner-grow"
            role="status"
            style={{ width: "5rem", height: "5rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderAcceptance;
