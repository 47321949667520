export const GRID_LAYER = {
  cellSize: 5,
  width: 1270,
  height: 1270,
  stroke: "#D7D9DC",
};

export const LINE_LENGTH_STEP = 5;
export const LINE_ANGLE_STEP = 5;

export const CANVAS_SIZE = {
  width: 867,
  height: 570,
}

export const DRAG_BOUNDARY_POINTS = {
  top: -90,
  bottom: -1300,
  left: -90,
  right: -1470,
}