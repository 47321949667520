export const newPasswordEn = {

    "password reset": "Password Reset",
    "password create": "Please create a new password",
    "new pass": "New Password",
    "confirm pass": "Confirm Password",
    "password confirmation": "passowrd has been successfully reset,",
    "your": "Your",
    "ok": "OK",
    "old password": "Old Password",
    "old pass required": "Old Password is required",
    "New Pass required": "New Password is required",
    "confirm pass required": "Confirm Password is required",
    "cofirm pass not match": "Confirm Password doesn't match",
    "enter email": "Enter Your Email",
    "Enter old password": "Enter old password",
    "Enter new password": "Enter new password",
    "Required Fields": "Required Fields",

    "Change Password": "Change Password",
    "submit": "Submit",

    "forget pass instruction": "Type in your e-mail and we will send you password resend link",

    "Write again for confirmation":"Write again for confirmation"
}