import "./index.css"
import brand_logo from "../../assets/img/logo.svg";
import { useTranslation } from "react-i18next";


export const UnderDevelopment =()=>{
    const { t } = useTranslation(["signup"]);
    return(
        <>
            <div className="verify__email__mobile">
                <img className="verify__email__mobile__icon" src={brand_logo} alt="brand icon" />
                <p className="oscar__large_web">{t("open from computer",{ns: "signup"})}</p>
                <div className="ticker ticker1">
                    <span>{t("under construction", {ns: "signup"})}</span>
                    <span>{t("under construction", {ns: "signup"})}</span>
                </div>
                <div className="ticker ticker2">
                    <span>{t("under construction", {ns: "signup"})}</span>
                    <span>{t("under construction", {ns: "signup"})}</span>
                </div>
                <div className="ticker ticker3">
                    <span>{t("under construction", {ns: "signup"})}</span>
                    <span>{t("under construction", {ns: "signup"})}</span>
                </div>
            </div>
        </>
    );

}