import AdminLayout from "../../layouts/admin"
import SupplierList from "./supplier-list"
import FreeTrialList from "./freetrial-list"

function SupplierTickets() {
  return (
    <AdminLayout>
      <SupplierList/>
      <FreeTrialList/>
    </AdminLayout>
   )
}

export default SupplierTickets
