import React, { useEffect, useRef } from "react";
import {
  Stage,
  Layer,
  Shape,
  Text,
  // Line, Rect
} from "react-konva";
import { Html } from "react-konva-utils";
import { useState } from "react";
import Measure from "react-measure";
import { labels, rebarErrorTypes, sideMaxLength, sideMinLength, } from "Constants/rebar-constants";
import {
  calculateNextPoint,
  drawAngleSymbol,
  resize_image,
  reverseAngle,
} from "../utils";
import { isMobile } from "react-device-detect";
import {
  angleTextSize,
  previewFontSize,
  rebarInputSize,
  sideLengthTextWithoutInput,
} from "../constant";
// import { mPadX, mPadY, padX, padY, pctInnerheight, pctInnerWidth } from "../constant";
import { ReactComponent as InfoIcon} from '../../../assets/img/icon/info.svg'
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import { Overlay, Tooltip } from "react-bootstrap";

function RebarShape({
  shapeObj,
  setAllPoints,
  setSides,
  initiateImage,
  errorText,
  setSymmetrical,
  setShapeChanged,
}: any) {
  const { t } = useTranslation(["tool"]);
  const symmInfo = useRef<HTMLElement>(null);
  const rightToLeft = useAppSelector(state => state.root.rightToLeft);
  const [showToolTip, setShowToolTip] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(30);
  const [canvasHeight, setCanvasHeight] = useState(30);
  const [inputPoints, setInputPoints] = useState([] as any);
  const [angleTextPoints, setAngleTextPoints] = useState([] as any);
  const [resizedImage, setResizedImage] = useState({
    first_point: {
      x: 0,
      y: 0,
    },
    rescale_factor: 1,
    sides: [{ length: 0, angle: 0 }],
  });

  function canvasSizeChanged(contentRect: any) {
    setCanvasHeight(contentRect.bounds.height);
    setCanvasWidth(contentRect.bounds.width);
  }

  useEffect(() => {
    if (
      shapeObj.rebar_side &&
      canvasWidth > 200 &&
      canvasHeight > 200 &&
      shapeObj.rebar_side.length > 0
    ) {
      const sidesMapped = shapeObj.rebar_side.map((item: any) => ({
        angle: item.line_angle,
        length: item.length,
      }));
      const _resizedImage = resize_image(
        canvasWidth,
        canvasHeight,
        sidesMapped,
        isMobile
      );
      setResizedImage(_resizedImage as any);

      if (isMobile)
        _resizedImage.rescale_factor = _resizedImage.rescale_factor - 0.3;

      let previousPoint = _resizedImage.first_point;
      let pointsCalculated = [];
      pointsCalculated.push({ ..._resizedImage.first_point });
      const _midpoints: any[] = [];
      const _anglePoints: any[] = [];
      shapeObj.rebar_side.forEach((side: any, index: number) => {
        const length = _resizedImage.sides[index].length;
        // Calculate Points for saving Image preview
        const currentPoint = calculateNextPoint(
          previousPoint,
          side.line_angle,
          length
        );
        pointsCalculated.push({ ...currentPoint });
        // if the angle is outter angle
        const outterInput =
          side.outer ||
          (index >= 1 &&
            !shapeObj.rebar_side[index - 1].change_previous_point &&
            shapeObj.rebar_side[index - 1].outer);
        // Calculate Points for inputs on the canvas
        // find midpoint of line - 25 as intermediate point
        const invertedLine = previousPoint.x > currentPoint.x; // if difference is negactive b/w Xs
        const inputSizeMid =
          initiateImage || isMobile
            ? sideLengthTextWithoutInput
            : rebarInputSize / 2;
        const intermedia = calculateNextPoint(
          previousPoint,
          side.line_angle,
          invertedLine ? length / 2 + inputSizeMid : length / 2 - inputSizeMid
        );
        // revert the point at 90 degrees to get oposite point
        const pointToInput = calculateNextPoint(
          intermedia,
          outterInput ? side.line_angle - 90 : side.line_angle + 90,
          outterInput || invertedLine ? (initiateImage ? 46 : 27) : (initiateImage ? 12 : 4)
        );
        let pointToText = { x: 0, y: 0 };
        if (invertedLine) {
          pointToText = pointToText = calculateNextPoint(
            pointToInput as any,
            outterInput ? side.line_angle - 150 : side.line_angle + 150,
            22
          );
        } else {
          pointToText = calculateNextPoint(
            pointToInput as any,
            outterInput
              ? (side.line_angle - (initiateImage ? 60 : 32)) % 360
              : (side.line_angle + 51) % 360,
            outterInput ? (initiateImage ? 15 : 23) : initiateImage ? 16 : 32
          );
        }
        _midpoints.push({
          input: pointToInput,
          text: pointToText,
          invertedLine,
        });
        //Calculate angle text point on canvas
        if (shapeObj.rebar_side.length - 1 !== index) {
          // get midpoint between the total angle
          const middleAngle = side.angle / 2;
          const angleOfText = side.outer
            ? (shapeObj.rebar_side[index + 1].line_angle + middleAngle) % 360
            : (shapeObj.rebar_side[index + 1].line_angle - middleAngle) % 360;
          const pointToAngleText = calculateNextPoint(
            currentPoint,
            angleOfText,
            (side.radius
              ? (side.angle >= 100 ? 20 : 35) +
                (side.radius > 20 ? side.radius / 5 : 0)
              : side.angle >= 100
              ? 50
              : 45) * _resizedImage.rescale_factor
          );
          const angle = side.angle;
          _anglePoints.push({
            x: pointToAngleText.x - angle.toString().length * 3.5,
            y: pointToAngleText.y - 6,
          });
        }
        previousPoint = currentPoint;
      });
      setAllPoints(pointsCalculated);
      setInputPoints(_midpoints);
      setAngleTextPoints(_anglePoints);

      return () => {
        setAllPoints([]);
        setInputPoints([]);
        setAngleTextPoints([]);
      };
    }
  }, [
    shapeObj.rebar_side,
    canvasWidth,
    canvasHeight,
    setAllPoints,
    initiateImage,
  ]);

  return (
    <Measure bounds onResize={canvasSizeChanged}>
      {({ measureRef }) => {
        return (
          <div
            className={
              isMobile ? "right__img w-xs-100 mobile-height" : "high__img"
            }
            ref={measureRef}
          >
            <Stage width={canvasWidth} height={canvasHeight}>
              <Layer>
                {/* <Text text={shapeObj.id} /> */}
                <Shape
                  sceneFunc={(context, shape) => {
                    if (
                      shapeObj.rebar_side &&
                      canvasWidth > 200 &&
                      canvasHeight > 200 &&
                      shapeObj.rebar_side.length > 0 &&
                      resizedImage.sides.length === shapeObj.rebar_side.length
                    ) {
                      context.beginPath();

                      if (isMobile)
                        resizedImage.rescale_factor =
                          resizedImage.rescale_factor - 0.3;

                      context.moveTo(
                        resizedImage.first_point.x,
                        resizedImage.first_point.y
                      );
                      let previousPoint = resizedImage.first_point;
                      let pointsCalculated = [];
                      pointsCalculated.push({
                        ...resizedImage.first_point,
                        outer: false,
                        radius: null,
                        angle: 0,
                        lineAngle: 0,
                      });

                      shapeObj.rebar_side.map((side: any, index: number) => {
                        const length = resizedImage.sides[index].length;
                        const currentPoint = calculateNextPoint(
                          previousPoint,
                          side.line_angle,
                          length
                        );
                        pointsCalculated.push({
                          ...currentPoint,
                          outer: side.outer ? side.outer : false,
                          radius: side.radius,
                          angle: side.angle,
                          lineAngle: side.line_angle,
                        });
                        previousPoint = currentPoint;
                        return side.length;
                      });
                      // (!) Konva specific method, it is very important
                      context.fillStrokeShape(shape);
                      // draw the angle symbols on corners
                      for (var i = 0; i < pointsCalculated.length - 2; i++) {
                        if (
                          true
                          // pointsCalculated[i + 1].radius === null ||
                          // pointsCalculated[i + 1].radius === 0
                          // # TODO: Check for usage of radius in code
                        ) {
                          drawAngleSymbol(
                            context,
                            pointsCalculated[i],
                            pointsCalculated[i + 1],
                            pointsCalculated[i + 2],
                            !pointsCalculated[i + 1].outer,
                            pointsCalculated[i + 1].angle === 90,
                            pointsCalculated[i + 1].lineAngle
                          );
                        }
                        }
                      }
                    }
                  }
                />
                <Shape
                  sceneFunc={(context, shape) => {
                    if (
                      shapeObj.rebar_side &&
                      canvasWidth > 200 &&
                      canvasHeight > 200 &&
                      shapeObj.rebar_side.length > 0 &&
                      resizedImage.sides.length === shapeObj.rebar_side.length
                    ) {
                      context.beginPath();

                      if (isMobile)
                        resizedImage.rescale_factor =
                          resizedImage.rescale_factor - 0.3;

                      context.moveTo(
                        resizedImage.first_point.x,
                        resizedImage.first_point.y
                      );
                      let previousPoint = resizedImage.first_point;
                      let pointsCalculated = [];
                      pointsCalculated.push({
                        ...resizedImage.first_point,
                        outer: false,
                        radius: null,
                        angle: 0,
                        lineAngle: 0,
                      });

                      shapeObj.rebar_side.map((side: any, index: number) => {
                        const length = resizedImage.sides[index].length;
                        const currentPoint = calculateNextPoint(
                          previousPoint,
                          side.line_angle,
                          length
                        );
                        pointsCalculated.push({
                          ...currentPoint,
                          outer: side.outer ? side.outer : false,
                          radius: side.radius,
                          angle: side.angle,
                          lineAngle: side.line_angle,
                        });
                        if (
                          // side.radius !== null &&
                          // side.radius > 0 &&
                          index + 1 <=
                          shapeObj.rebar_side.length - 1
                        ) {
                          const radius = resizedImage.rescale_factor * 5;
                          const nextAngle =
                            shapeObj.rebar_side[index + 1].line_angle;
                          const radiusStartPoint = calculateNextPoint(
                            previousPoint,
                            side.line_angle,
                            length - radius
                          );
                          const radiusEndPoint = calculateNextPoint(
                            currentPoint,
                            nextAngle,
                            radius
                          );

                          context.lineTo(
                            radiusStartPoint.x,
                            radiusStartPoint.y
                          );
                          context.quadraticCurveTo(
                            currentPoint.x,
                            currentPoint.y,
                            radiusEndPoint.x,
                            radiusEndPoint.y
                          );
                        } else {
                          context.lineTo(currentPoint.x, currentPoint.y);
                        }
                        previousPoint = currentPoint;
                        return side.length;
                      });
                      // (!) Konva specific method, it is very important
                      context.fillStrokeShape(shape);
                    }
                  }}
                  lineCap="round"
                  stroke="black"
                  strokeWidth={isMobile ? 3 : initiateImage ? 6 : 4}
                />
              </Layer>
              <Layer>
                {inputPoints.length > 0 &&
                  shapeObj.rebar_side &&
                  shapeObj.rebar_side.length > 0 &&
                  !shapeObj.rebar_side.reduce(
                    (prev: any, curr: any) =>
                      prev && (curr.length > 0 ? false : true),
                    true
                  ) &&
                  inputPoints.map((item: any, index: number) => (
                    <React.Fragment key={"rebar_inputs" + index}>
                      {!isMobile ? (
                        <>
                          {!initiateImage && (
                            <Text
                              fontSize={14}
                              text={labels[shapeObj.rebar_side[index]?.label]}
                              x={item.text.x}
                              y={item.text.y}
                              rotation={
                                item.invertedLine
                                  ? reverseAngle(
                                      shapeObj.rebar_side[index]?.line_angle
                                    )
                                  : shapeObj.rebar_side[index]?.line_angle
                              }
                            />
                          )}
                          {initiateImage ? (
                            <Text
                              fontSize={previewFontSize}
                              text={shapeObj.rebar_side[index]?.length.toFixed(
                                0
                              )}
                              x={item.input.x}
                              y={item.input.y}
                              rotation={
                                item.invertedLine
                                  ? reverseAngle(
                                      shapeObj.rebar_side[index]?.line_angle
                                    )
                                  : shapeObj.rebar_side[index]?.line_angle
                              }
                              fill={"#000"}
                            />
                          ) : (
                            <Html
                              divProps={{
                                style: {
                                  position: "absolute",
                                  top: `${item.input.y}px`,
                                  left: `${item.input.x}px`,
                                  transform: `rotate(${
                                    item.invertedLine
                                      ? reverseAngle(
                                          shapeObj.rebar_side[index]?.line_angle
                                        )
                                      : shapeObj.rebar_side[index]?.line_angle
                                  }deg)`,
                                },
                              }}
                            >
                              <input
                                onFocus={(e) => e.target.select()}
                                className="inputField"
                                type="number"
                                style={
                                  (errorText.errorType ===
                                    rebarErrorTypes.SIDE ||
                                    errorText.errorType ===
                                      rebarErrorTypes.EAR) &&
                                  ((shapeObj.diameter.steel_diameter &&
                                    index === 0 &&
                                    shapeObj.rebar_side[index]?.length <
                                      shapeObj.diameter.steel_diameter) ||
                                    (shapeObj.diameter.steel_diameter &&
                                      index ===
                                        shapeObj.rebar_side.length - 1 &&
                                      shapeObj.rebar_side[index]?.length <
                                        shapeObj.diameter.steel_diameter) ||
                                    !(
                                      shapeObj.rebar_side[index]?.length >=
                                        sideMinLength &&
                                      shapeObj.rebar_side[index]?.length <
                                        sideMaxLength
                                    ))
                                    ? {
                                        width: rebarInputSize + "px",
                                        border: "2px solid #f84242",
                                      }
                                    : { width: rebarInputSize + "px" }
                                }
                                value={
                                  shapeObj.rebar_side[index]?.length.toFixed(
                                    0
                                  ) || ""
                                }
                                onChange={(e) => {
                                  setSides(parseInt(e.target.value), index);
                                  setShapeChanged(true);
                                }}
                              />
                            </Html>
                          )}
                        </>
                      ) : (
                        <Text
                          fontSize={14}
                          text={
                            labels[shapeObj.rebar_side[index]?.label] +
                            " " +
                            shapeObj.rebar_side[index]?.length.toFixed(0)
                          }
                          x={item.input.x}
                          y={item.input.y}
                          rotation={shapeObj.rebar_side[index]?.line_angle}
                          fill={"#000"}
                        />
                      )}
                    </React.Fragment>
                  ))}
                {angleTextPoints.length > 0 &&
                  shapeObj.rebar_side &&
                  shapeObj.rebar_side.length > 0 &&
                  angleTextPoints.map((item: any, index: number) => (
                    <React.Fragment key={"rebar_angles" + index}>
                      {(shapeObj.rebar_side[index]?.radius ||
                        shapeObj.rebar_side[index]?.angle !== 90) && (
                          shapeObj.rebar_side[index]?.angle !== 90 && (
                            <Text
                              fontSize={angleTextSize}
                              text={shapeObj.rebar_side[index]?.angle + "°"}
                              x={item.x}
                              y={item.y}
                              fill={initiateImage ? "#899499" : "#000"}
                            />
                          )
                      )}
                    </React.Fragment>
                  ))}
              </Layer>
              {/* <Layer>
                <Line
                  points={[0,canvasHeight/2, canvasWidth, canvasHeight/2]}
                  stroke="#df4b26"
                  strokeWidth={1}
                />
                <Line
                  points={[canvasWidth/2, 0, canvasWidth/2, canvasHeight]}
                  stroke="#df4b26"
                  strokeWidth={1}
                />
                <Rect
                  x={(canvasWidth/2) - (((canvasWidth - padX) * pctInnerWidth)/2)}
                  y={(canvasHeight/2) - (((canvasHeight - padY) * pctInnerheight)/2)}
                  width={(canvasWidth - padX) * pctInnerWidth}
                  height={(canvasHeight - padY) * pctInnerheight}
                  stroke='black'
                  strokeWidth={1}
                />
                <Rect
                  x={(isMobile ? mPadX : padX)/2}
                  y={(isMobile ? mPadY : padY)/2}
                  width={canvasWidth - (isMobile ? mPadX : padX)}
                  height={canvasHeight - (isMobile ? mPadY : padY)}
                  stroke='black'
                  strokeWidth={1}
                />
              </Layer> */}
              {shapeObj.can_be_symmetric && !initiateImage && (
                <Layer>
                  <Html
                    divProps={{
                      style: {
                        position: "absolute",
                        top: "6px",
                        left: "30px",
                        ...(rightToLeft
                          ? { right: "30px", left: "initial" }
                          : {}),
                      },
                      className:
                        "symmetric-checkbox" +
                        (rightToLeft ? " right-to-left" : ""),
                    }}
                  >
                    <input
                      type="checkbox"
                      id="is_symmetrical"
                      checked={shapeObj.is_symmetrical ? true : false}
                      onChange={({ target }) => setSymmetrical(target.checked)}
                    />
                    <label htmlFor="is_symmetrical">
                      {t("symmetrical shape", "tool")}
                    </label>
                    <Overlay
                      target={symmInfo?.current}
                      show={showToolTip}
                      placement="top"
                    >
                      {(props) => (
                        <Tooltip id="overlay-example-1" {...props}>
                          {t("symm info", "tool")}
                        </Tooltip>
                      )}
                    </Overlay>
                    <span
                      ref={symmInfo}
                      onMouseEnter={() => setShowToolTip(true)}
                      onMouseLeave={() => setShowToolTip(false)}
                    >
                      <InfoIcon
                        style={
                          rightToLeft
                            ? { marginRight: "9px" }
                            : { marginLeft: "9px" }
                        }
                      />
                    </span>
                  </Html>
                </Layer>
              )}
            </Stage>
          </div>
        );
      }}
    </Measure>
  );
}

export default React.memo(RebarShape);
