import "./index.css"
import brand_logo from "../../assets/img/logo.svg";
import { useTranslation } from "react-i18next";


export const VerifyEmailMobile =()=>{
const { t } = useTranslation(["signup"]);
    return(
        <>
            <div style={{"background": "#00B38F", height:"100px"}}/>
            <div className="under__development__page">
                <img className="brand__icon mb-5" src={brand_logo} alt="brand icon" />
                <p className="oscar__large_web mb-4">{t("joined", {ns: "signup"})}</p>
                <p className="under__development__text mb-4">{t("mobile instructions", {ns: "signup"})}</p>
                <p className="highlighted__text__no_underline">{t("details emailed", {ns: "signup"})}</p>
            </div>
        </>
    );

}