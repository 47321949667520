export const HeaderHe = {
    "login": "התחבר",
    'new order': 'יצירת הזמנה',
    'my order': 'ההזמנה שלי',
    'my profile': 'הפרופיל שלי',
    'logout': 'להתנתק',
    'menu': 'תפריט',
    'my orders': 'ההזמנות שלי',
    'create your user': 'צור משתמש',
    'visitor note': 'מוזמן להתנסות, רק שים לב שאתה ללא משתמש ולכן חלק מהפעולות לא יהיו זמינות',
    'ticket creator': 'כרטיסיות ייצור',
}
