import {
  catalogRadiusRescaleFactor,
  catalogRadiusRescaleRate,
} from "../constant";

//Beizer Curve Formula Used to create the radius
export const getControlPoints = (
  p1: { x: number; y: number },
  p2: { x: number; y: number },
  p3: { x: number; y: number },
  cornerRadius: number
) => {
  const v1 = { x: p2.x - p1.x, y: p2.y - p1.y };
  const v2 = { x: p2.x - p3.x, y: p2.y - p3.y };

  const l1 = Math.sqrt(v1.x ** 2 + v1.y ** 2);
  const l2 = Math.sqrt(v2.x ** 2 + v2.y ** 2);

  const u1 = { x: v1.x / l1, y: v1.y / l1 };
  const u2 = { x: v2.x / l2, y: v2.y / l2 };

  const q1 = {
    x: p2.x - cornerRadius * u1.x,
    y: p2.y - cornerRadius * u1.y,
  };

  const q2 = {
    x: p2.x - cornerRadius * u2.x,
    y: p2.y - cornerRadius * u2.y,
  };

  return [q1, q2];
};

//Middle point of the Line
export const calculateMiddlePoint = (
  x1: number,
  y1: number,
  x2: number,
  y2: number
) => ({
  x: (x1 + x2) / 2,
  y: (y1 + y2) / 2,
});

const applyingFactor = (radiusRescaleFactor, catalogRadiusRescaleRate) => {
  radiusRescaleFactor = radiusRescaleFactor - catalogRadiusRescaleRate;
  let loopBreakingFlag = false;

  return [radiusRescaleFactor, loopBreakingFlag];
};

export const radiusRescaleFactor = (pointsCalculated) => {
  let radiusRescaleFactor = catalogRadiusRescaleFactor;
  pointsCalculated.forEach((side, index) => {
    if (index < pointsCalculated.length - 2 && pointsCalculated.length !== 2) {
      const line1 = {
        x1: side.x,
        y1: side.y,
        x2: pointsCalculated[index + 1].x,
        y2: pointsCalculated[index + 1].y,
      };
      const line2 = {
        x1: pointsCalculated[index + 1].x,
        y1: pointsCalculated[index + 1].y,
        x2: pointsCalculated[index + 2].x,
        y2: pointsCalculated[index + 2].y,
      };

      const edge = { x: line1.x2, y: line1.y2 };
      const middle1 = calculateMiddlePoint(
        line1.x1,
        line1.y1,
        line1.x2,
        line1.y2
      );
      const middle2 = calculateMiddlePoint(
        line2.x1,
        line2.y1,
        line2.x2,
        line2.y2
      );
      let loopBreakingFlag = true;
      do {
        const [q1, q2] = getControlPoints(
          middle1,
          edge,
          middle2,
          pointsCalculated[index + 1].radius * radiusRescaleFactor
        );

        loopBreakingFlag = true;

        //Right Horizontal
        if (line1.x2 > line1.x1 && line1.y2 === line1.y1 && loopBreakingFlag) {
          if (q1.x < middle1.x) {
            [radiusRescaleFactor, loopBreakingFlag] = applyingFactor(
              radiusRescaleFactor,
              catalogRadiusRescaleRate
            );
          } else {
            loopBreakingFlag = true;
          }
        }
        //Left Horizontal
        else if (
          line1.x2 < line1.x1 &&
          line1.y2 === line1.y1 &&
          loopBreakingFlag
        ) {
          if (q1.x > middle1.x) {
            [radiusRescaleFactor, loopBreakingFlag] = applyingFactor(
              radiusRescaleFactor,
              catalogRadiusRescaleRate
            );
          } else {
            loopBreakingFlag = true;
          }
        }
        //Upper Vertical
        else if (
          line1.x2 === line1.x1 &&
          line1.y2 < line1.y1 &&
          loopBreakingFlag
        ) {
          if (q1.y > middle1.y) {
            [radiusRescaleFactor, loopBreakingFlag] = applyingFactor(
              radiusRescaleFactor,
              catalogRadiusRescaleRate
            );
          } else {
            loopBreakingFlag = true;
          }
        }
        //Lower Vertical
        else if (
          line1.x2 === line1.x1 &&
          line1.y2 > line1.y1 &&
          loopBreakingFlag
        ) {
          if (q1.y < middle1.y) {
            [radiusRescaleFactor, loopBreakingFlag] = applyingFactor(
              radiusRescaleFactor,
              catalogRadiusRescaleRate
            );
          } else {
            loopBreakingFlag = true;
          }
        }
        //1st quadrant
        else if (
          line1.x2 > line1.x1 &&
          line1.y2 < line1.y1 &&
          loopBreakingFlag
        ) {
          if (q1.y > middle1.y || q1.x < middle1.x) {
            [radiusRescaleFactor, loopBreakingFlag] = applyingFactor(
              radiusRescaleFactor,
              catalogRadiusRescaleRate
            );
          } else {
            loopBreakingFlag = true;
          }
        }
        //2nd Quadrant
        else if (
          line1.x2 < line1.x1 &&
          line1.y2 < line1.y1 &&
          loopBreakingFlag
        ) {
          if (q1.y > middle1.y || q1.x > middle1.x) {
            [radiusRescaleFactor, loopBreakingFlag] = applyingFactor(
              radiusRescaleFactor,
              catalogRadiusRescaleRate
            );
          } else {
            loopBreakingFlag = true;
          }
        }
        //3rd Quadrant
        else if (
          line1.x2 < line1.x1 &&
          line1.y2 > line1.y1 &&
          loopBreakingFlag
        ) {
          if (q1.y < middle1.y || q1.x > middle1.x) {
            [radiusRescaleFactor, loopBreakingFlag] = applyingFactor(
              radiusRescaleFactor,
              catalogRadiusRescaleRate
            );
          } else {
            loopBreakingFlag = true;
          }
        }
        //4th Quadrant
        else if (
          line1.x2 > line1.x1 &&
          line1.y2 > line1.y1 &&
          loopBreakingFlag
        ) {
          if (q1.y < middle1.y || q1.x < middle1.x) {
            [radiusRescaleFactor, loopBreakingFlag] = applyingFactor(
              radiusRescaleFactor,
              catalogRadiusRescaleRate
            );
          } else {
            loopBreakingFlag = true;
          }
        } else {
          loopBreakingFlag = true;
        }

        //Left Line Conditions

        //Right Horizontal
        if (line2.x2 > line2.x1 && line2.y2 === line2.y1 && loopBreakingFlag) {
          if (q2.x > middle2.x) {
            [radiusRescaleFactor, loopBreakingFlag] = applyingFactor(
              radiusRescaleFactor,
              catalogRadiusRescaleRate
            );
          } else {
            loopBreakingFlag = true;
          }
        }
        //Left Horizontal
        else if (
          line2.x2 < line2.x1 &&
          line2.y2 === line2.y1 &&
          loopBreakingFlag
        ) {
          if (q2.x < middle2.x) {
            [radiusRescaleFactor, loopBreakingFlag] = applyingFactor(
              radiusRescaleFactor,
              catalogRadiusRescaleRate
            );
          } else {
            loopBreakingFlag = true;
          }
        }
        //Upper Vertical
        else if (
          line2.x2 === line2.x1 &&
          line2.y2 < line2.y1 &&
          loopBreakingFlag
        ) {
          if (q2.y < middle2.y) {
            [radiusRescaleFactor, loopBreakingFlag] = applyingFactor(
              radiusRescaleFactor,
              catalogRadiusRescaleRate
            );
          } else {
            loopBreakingFlag = true;
          }
        }
        //Lower Vertical
        else if (
          line2.x2 === line2.x1 &&
          line2.y2 > line2.y1 &&
          loopBreakingFlag
        ) {
          if (q2.y > middle2.y) {
            [radiusRescaleFactor, loopBreakingFlag] = applyingFactor(
              radiusRescaleFactor,
              catalogRadiusRescaleRate
            );
          } else {
            loopBreakingFlag = true;
          }
        }
        //1st quadrant
        else if (
          line2.x2 > line2.x1 &&
          line2.y2 < line2.y1 &&
          loopBreakingFlag
        ) {
          if (q2.y < middle2.y || q2.x > middle2.x) {
            [radiusRescaleFactor, loopBreakingFlag] = applyingFactor(
              radiusRescaleFactor,
              catalogRadiusRescaleRate
            );
          } else {
            loopBreakingFlag = true;
          }
        }
        //2nd Quadrant
        else if (
          line2.x2 < line2.x1 &&
          line2.y2 < line2.y1 &&
          loopBreakingFlag
        ) {
          if (q2.y < middle2.y || q2.x < middle2.x) {
            [radiusRescaleFactor, loopBreakingFlag] = applyingFactor(
              radiusRescaleFactor,
              catalogRadiusRescaleRate
            );
          } else {
            loopBreakingFlag = true;
          }
        }
        //3rd Quadrant
        else if (
          line2.x2 < line2.x1 &&
          line2.y2 > line2.y1 &&
          loopBreakingFlag
        ) {
          if (q2.y > middle2.y || q2.x < middle2.x) {
            [radiusRescaleFactor, loopBreakingFlag] = applyingFactor(
              radiusRescaleFactor,
              catalogRadiusRescaleRate
            );
          } else {
            loopBreakingFlag = true;
          }
        }
        //4th Quadrant
        else if (
          line2.x2 > line2.x1 &&
          line2.y2 > line2.y1 &&
          loopBreakingFlag
        ) {
          if (q2.y > middle2.y || q2.x > middle2.x) {
            [radiusRescaleFactor, loopBreakingFlag] = applyingFactor(
              radiusRescaleFactor,
              catalogRadiusRescaleRate
            );
          } else {
            loopBreakingFlag = true;
          }
        }
      } while (loopBreakingFlag !== true);
    }
  });
  return radiusRescaleFactor;
};

export const getRadiusTextFactor = (lineStartPoint, lineEndPoint) => {
  if (lineStartPoint.x < lineEndPoint.x) {
    return { angleFactor: 3, lengthFactor: -5 };
  } else if (lineStartPoint.x > lineEndPoint.x) {
    return { angleFactor: -3, lengthFactor: 10 };
  } else {
    return { angleFactor: -3, lengthFactor: 0 };
  }
};
