import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import arrowDownIcon from "../../../assets/img/order-details/arrow-down.svg";
import arrowUpIcon from "../../../assets/img/order-details/arrow-up.svg";
import {ReactComponent as SettingsIcon} from "../../../assets/img/order-details/settings.svg";
import plusIcon from "../../../assets/img/order-details/plus.svg";
import {ReactComponent as EditIcon} from "../../../assets/img/order-details/edit.svg";
import DropDownIcon from "../../../assets/img/order-details/drop-down.png";
import "./index.scss";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { CONTACTS_ENUM } from "Constants/contacts";
import { useHistory } from "react-router-dom";
import { useMemo } from "react";
import { fetchProjects } from "Redux/middlwares/project";
import { useTranslation } from "react-i18next";
import { Overlay, Tooltip } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";

const toolTips = {
  ADD_NEW_PROJECT: 1,
  USER_SETTINGS: 2,
  COMPANY_SETTINGS: 3,
  EDIT_PROJECT: 4,
  EDIT_PROJECT1: 5,
};

const OrderDetails = ({
  onCreateUserModalOpen,
  onNewBusinessModalOpen,
  onProjectModalOpen,
  activeBusiness,
  setActiveBusiness,
  handleEditBusiness,
  activeProject,
  setActiveProject,
  handleEditProject,
  order,
  setOrder,
  setErrorText,
  edit,
  currentCompany,
  setCurrentCompany,
  setShowDetails,
  showDetails,
}) => {
  const { t } = useTranslation(["newOrder", "common"]);
  const dispatch = useAppDispatch();
  // const [showDetails, setShowDetails] = useState(false);
  const [siteManager, setSiteManager] = useState();
  const [toolTipShow, setToolTipShow] = useState();
  const userData = useAppSelector((state) => state.user.userData);
  const userProjects = useAppSelector((state) => state.project.userProjects);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const router = useHistory();

  const projAdd = useRef();
  const companySet = useRef();
  const edit1 = useRef();
  const edit2 = useRef();
  const userSet = useRef();

  useEffect(() => {
    setSiteManager();
    if (activeProject.contacts) {
      const manager = activeProject.contacts.find(
        (item) => item.role === CONTACTS_ENUM.SITE_MANAGER
      );
      if (manager) {
        setSiteManager(manager);
      }
    }
  }, [activeProject]);

  const siteMans = useMemo(() => {
    if (activeProject && activeProject.contacts) {
      const _siteMans = activeProject.contacts.filter(item => item.role === CONTACTS_ENUM.SITE_MAN);
      if (_siteMans.length > 0) {
        return ({
          siteMan1: _siteMans[0].full_name + '  ' + _siteMans[0].phone,
          siteMan2: _siteMans.length === 2 ? _siteMans[1].full_name + '  ' + _siteMans[1].phone : '',
        });
      }
    }
    return ({
      siteMan1: '',
      siteMan2: '',
    });
  }, [activeProject]);

  useEffect(() => {
    if (userData && userProjects.length === 0) dispatch(fetchProjects());
    if (userProjects.length > 0) setActiveProject(userProjects[0]);
    return () => {
      setActiveProject({});
    }
  }, [userData, userProjects, setActiveProject, dispatch])

  return (
    <section id="order-details-new-section">
      <div className="container p-0">
        <div className="detail-header">
          <p>{t("Order Details", { ns: "newOrder" })}</p>
          <p className="regular">
            {t("Create Date", { ns: "newOrder" })}{" "}
            {format(new Date(order.created), "dd/MM/yyyy")} |{" "}
            {t("Last update", { ns: "newOrder" })} {format(new Date(order.updated), "dd/MM/yyyy")} |{" "}
            {t("Order number", { ns: "newOrder" })} {order.order_no}
          </p>
        </div>
        <div className="overview">
          <div className={"overview-item" + (rightToLeft ? " oi-l_1":" oi-r_1")} style={{ width: "359px" }}>
            <label>{t("Order name", { ns: "newOrder" })}</label>
            <DebounceInput
              type="text"
              debounceTimeout={1000}
              value={order.name}
              onChange={({ target }) => {
                if(!edit){
                  localStorage.setItem("order_name", target.value);
                }
                setOrder({ ...order, name: target.value });
                if (order.name === "") {
                  setErrorText("");
                }
              }}
              placeholder={t("before typing", { ns: "newOrder" })}
            />
          </div>
          <div className={"overview-item" + (rightToLeft ? " oi-l_2":" oi-r_2")} style={{ width: "354px" }}>
            <label>{t("Project Name", { ns: "newOrder" })}</label>
            {userProjects.length === 0 ? (
              <button type="button" onClick={onProjectModalOpen}>
                {t("first site", { ns: "newOrder" })}
              </button>
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <select
                  style={{
                    width: "300px",
                    backgroundImage: `url(${DropDownIcon})`,
                  }}
                  name="activeselectmobile"
                  className={rightToLeft ? "rightToLeft" : ""}
                  value={activeProject.id}
                  onChange={({ target }) =>
                    setActiveProject(
                      userProjects.find(
                        (item) => item.id === parseInt(target.value)
                      )
                    )
                  }
                >
                  {userProjects.map((pr, index) => (
                    <option key={"pr-mob" + index} value={pr.id}>
                      {pr.name}
                    </option>
                  ))}
                </select>
                <Overlay
                  target={projAdd.current}
                  show={toolTips.ADD_NEW_PROJECT === toolTipShow}
                  placement="top"
                >
                  {(props) => (
                    <Tooltip id="overlay-example-1" {...props}>
                      {t("add new project", { ns: "newOrder" })}
                    </Tooltip>
                  )}
                </Overlay>
                <button
                  ref={projAdd}
                  className={"add-btn " + (rightToLeft ? "mr-2":"ml-2")}
                  onClick={onProjectModalOpen}
                  onMouseEnter={() => setToolTipShow(toolTips.ADD_NEW_PROJECT)}
                  onMouseLeave={() => setToolTipShow()}
                >
                  <img src={plusIcon} alt="plus icon" />
                </button>
              </div>
            )}
          </div>
          <div className={"overview-item" + (rightToLeft ? " oi-l_3":" oi-r_3")} style={{ width: "170px" }}>
            <label>{t("Delivery date", { ns: "newOrder" })}</label>
            <input
              type="date"
              value={order.delivery_date}
              min={format(new Date(), "yyyy-MM-dd")}
              onChange={({ target }) => {
                setOrder({ ...order, delivery_date: target.value });
                localStorage.setItem("delivery_date", target.value);
                if (order.delivery_date === "") {
                  setErrorText("");
                }
              }}
            />
          </div>
          <div className="overview-item oi_4" style={{ width: "180px" }}>
            <div className="d-flex justify-content-between align-items-center">
              <label>{t("Company details", { ns: "newOrder" })}</label>
              <Overlay
                target={companySet.current}
                show={toolTips.COMPANY_SETTINGS === toolTipShow}
                placement="top"
              >
                {(props) => (
                  <Tooltip id="overlay-example-1" {...props}>
                    {t("company settings", { ns: "newOrder" })}
                  </Tooltip>
                )}
              </Overlay>
              {currentCompany && currentCompany.name && (
                <SettingsIcon
                  ref={companySet}
                  style={{ cursor: "pointer" }}
                  onClick={() => router.push("/view-profile?company-settings=true")}
                  onMouseEnter={() => setToolTipShow(toolTips.COMPANY_SETTINGS)}
                  onMouseLeave={() => setToolTipShow()}
                />
              )}
            </div>
            {currentCompany && currentCompany.name ? (
              <input value={currentCompany.name} readOnly />
            ) : (
              <button type="button" onClick={onNewBusinessModalOpen}>
                {t("add company details", { ns: "newOrder" })}
              </button>
            )}
          </div>
        </div>
        {showDetails && (
          <div className="details">
            <div className="detail-section">
              <div className="detail-item title">
                <p className="label bold-text title">{t("Order creator", { ns: "newOrder" })}</p>
                <Overlay
                  target={userSet.current}
                  show={toolTips.USER_SETTINGS === toolTipShow}
                  placement="top"
                >
                  {(props) => (
                    <Tooltip id="overlay-example-1" {...props}>
                      {t("user settings", { ns: "newOrder" })}
                    </Tooltip>
                  )}
                </Overlay>
                <SettingsIcon
                  ref={userSet}
                  style={{ cursor: "pointer" }}
                  onClick={() => router.push("/view-profile")}
                  onMouseEnter={() => setToolTipShow(toolTips.USER_SETTINGS)}
                  onMouseLeave={() => setToolTipShow()}
                />
              </div>
              <div className="detail-item">
                <p className="label">{t("Full name", { ns: "newOrder" })}</p>
                <p className="value bold-text text-uppercase">
                  {userData.first_name + " " + userData.last_name}
                </p>
              </div>
              <div className="detail-item">
                <p className="label">{t("Job title", { ns: "newOrder" })}</p>
                <p className="value">{userData.job_title || "-"}</p>
              </div>
              <div className="detail-item">
                <p className="label">{t("phone", { ns: "newOrder" })}</p>
                <p className="value">{userData.phone || "-"}</p>
              </div>
              <div className="detail-item">
                <p className="label">{t("email", { ns: "newOrder" })}</p>
                <p className="value">{userData.email || "-"}</p>
              </div>
            </div>
            <div className="detail-section">
              <div className="detail-item title">
                <p className="label bold-text title">{t("project details", { ns: "newOrder" })}</p>
                <Overlay
                  target={edit1.current}
                  show={toolTips.EDIT_PROJECT === toolTipShow}
                  placement="top"
                >
                  {(props) => (
                    <Tooltip id="overlay-example-1" {...props}>
                      {t("edit", { ns: "common" })}
                    </Tooltip>
                  )}
                </Overlay>
                {Object.keys(activeProject).length > 0 && (
                  <EditIcon
                    ref={edit1}
                    className="icon-btn"
                    onClick={handleEditProject}
                    onMouseEnter={() => setToolTipShow(toolTips.EDIT_PROJECT)}
                    onMouseLeave={() => setToolTipShow()}
                  />
                )}
              </div>
              <div className="detail-item">
                <p className="label">{t("city", { ns: "newOrder" })}</p>
                <p className="value">{activeProject.project_city || "-"}</p>
              </div>
              <div className="detail-item">
                <p className="label">{t("Street", { ns: "newOrder" })}</p>
                <p className="value">
                  {activeProject.project_address_line1 || "-"}
                </p>
              </div>
              <div className="detail-item">
                <p className="label">{t("site man", { ns: "newOrder" })}</p>
                <p className="value">{siteManager?.full_name || "-"}</p>
              </div>
              <div className="detail-item">
                <p className="label">{t("phone", { ns: "newOrder" })}</p>
                <p className="value">{siteManager?.phone || "-"}</p>
              </div>
            </div>
            <div className="detail-section">
              <div className="detail-item title">
                <p className="label bold-text title">{t("Delivery details", { ns: "newOrder" })}</p>
                <Overlay
                  target={edit2.current}
                  show={toolTips.EDIT_PROJECT1 === toolTipShow}
                  placement="top"
                >
                  {(props) => (
                    <Tooltip id="overlay-example-1" {...props}>
                      {t("edit", { ns: "common" })}
                    </Tooltip>
                  )}
                </Overlay>
                {Object.keys(activeProject).length > 0 && (
                  <EditIcon
                    ref={edit2}
                    className="icon-btn"
                    onClick={()=>{handleEditProject('delivery_detail_show')}}
                    onMouseEnter={() => setToolTipShow(toolTips.EDIT_PROJECT1)}
                    onMouseLeave={() => setToolTipShow()}
                  />
                )}
              </div>
              <div className="detail-item">
                <p className="label">{t("address", { ns: "newOrder" })}</p>
                <p className="value">
                  {activeProject.delivery_address_line1 || "-"}
                </p>
              </div>
              <div className="detail-item">
                <p className="label">{t("contact man", { ns: "newOrder" })}</p>
                <p className="value">{siteMans.siteMan1 || "-"}</p>
              </div>
              <div className="detail-item">
                <p className="label">{t("contact man", { ns: "newOrder" })}</p>
                <p className="value">{siteMans.siteMan2 || "-"}</p>
              </div>
              <div className="detail-item">
                <p className="label">{t("Driver notes", { ns: "newOrder" })}</p>
                <p className="value">{activeProject.driver_notes || "-"}</p>
              </div>
            </div>
          </div>
        )}
        <div className="collapse-button">
          <button onClick={() => setShowDetails(!showDetails)}>
            <img src={showDetails ? arrowUpIcon : arrowDownIcon} alt="down" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default OrderDetails;
