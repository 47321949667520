import { createSlice } from '@reduxjs/toolkit'

export interface PriceListState {
	userPriceLists: any[];
};

const initialState: PriceListState = {
	userPriceLists: [],
};

export const priceListSlice = createSlice({
  name: 'price-list',
  initialState,
  reducers: {
    setUserPriceLists: (state, action) => {
      return {
        ...state,
        userPriceLists: action.payload
      };
    },
    addToUserPriceLists:  (state: any, action) => {
      state.userPriceLists = [action.payload, ...state.userPriceLists]
    },
    updateToUserPriceLists:  (state: any, action) => {
      state.userPriceLists = [
        action.payload,
        ...state.userPriceLists.filter((item: any) => item.id !== action.payload.id)
      ]
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserPriceLists,
  addToUserPriceLists,
  updateToUserPriceLists,
} = priceListSlice.actions;

export default priceListSlice.reducer;