export const STATUS_CODES = {
    NOT_FOUND: "404",
};

export const STATUS = {
    IN_REVIEW: 0,
    APPROVED: 1,
    SUSPEND: 2,
};

export const PACKAGE = {
    FREE_TRIAL: 0,
    LIMITED: 1,
    UNLIMITED: 2,
};

export const PACKAGE_STATUS = {
    OPERATIONAL: 0,
    CEASED: 1,
};

export const PRINTER_TYPE = {
    A4Printer: 1,
    StickyPrinter: 0,
}

export const WHATSAPP_LINK = "https://wa.me/972508454847";
export const EASYBAR_LINK = "https://www.easybar.co.il"
