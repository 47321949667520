import { setUserData, logoutUser, setProfilePicture } from "Redux/features/user";
import { AppThunk } from 'Redux/store';
import { isAxiosResponse } from "Services/index";
import { getUserData, patchUserData, updateUserPassword, setUserRecentActivity } from "Services/user";
import { getMedia } from "Services/media";

export const fetchUser = (next = (val: boolean, userdt = {}) => {}): AppThunk => async dispatch => {
  try {
    setUserRecentActivity(null);
    const response = await getUserData();
    if (isAxiosResponse(response)) {
      await dispatch(setUserData(response.data.data));
      return next(true, response.data.data);
    }
  } catch (err) {
    return next(false);
  }
}

export const patchUser = (id : any , data : object): AppThunk => async dispatch =>{
   try {
     const response = await patchUserData(id, data);
     dispatch(setUserData(response.data));
     return response;
   }
   catch(err)
   {
    return 'some Error Occurred';
   }
}

export const updatePassword = async (data : object) => {
  try {
    await updateUserPassword(data);
  }
  catch(err)
  {
    return 'some Error Occurred';
  }
}


export const logOut = (): AppThunk => async dispatch => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('delivery_date');
  await dispatch(logoutUser());
}

export const getProfileImg = (profilePic: any): AppThunk => dispatch => {
  getMedia(profilePic).then((res)=>{
        dispatch(setProfilePicture(res.data.file_field));
    }).catch((err) => console.log(err))
}