import Request from "./index";

export const postContact = (data: Object) =>
  Request.post('contact/', data);

export const getContacts = () =>
  Request.get('contact/');

export const patchContact = (contactID: number, data: Object) =>
  Request.patch(`contact/${contactID}/`, data);

export const deleteContact = (contactID: number) =>
  Request.remove(`contact/${contactID}/`);
