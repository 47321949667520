import { Label } from "react-konva";
import { Text } from "react-konva/lib/ReactKonvaCore";
import { getSmallerAngle, getFullAngle } from "../shared/shapesGeometry.ts";
import { calculateNextPoint } from "Components/tool/utils";
import { angles } from "Constants/rebar-constants.js";
import { fullAngle, smallAngle, changeAngle } from "../../../utils";

const LinesAngleLabels = (props) => {
  const { lines, scale, freeLineAngles } = props;


  return (
    <>
      {
        // remove the last line because it doesn't have a next line
        lines.slice(0, lines.length - 1).map((line, index) => {
          let nextLine = lines[index + 1];
          let angle = 0;
          let angleTextPoints = {x:0, y:0};
          let MiddleLineAngle;
          if (
            nextLine &&
            index === lines.indexOf(line) &&
            index + 1 === lines.indexOf(nextLine)
          ) {
            const reversedCurrentLine = {
              ...line,
              x1: line.x2,
              y1: line.y2,
              x2: line.x1,
              y2: line.y1,
            };
            angle = getSmallerAngle(reversedCurrentLine, nextLine);
            if (freeLineAngles[index] && freeLineAngles[index + 1]){
              let angle1 = Number(freeLineAngles[index].angle);
              let inner = false;
              angle1 = changeAngle(angle1);
              angle1 = fullAngle(angle1);
              const smallerAngle = getSmallerAngle(line, nextLine);
              const completeAngle = getFullAngle(line, nextLine);
              if(smallerAngle !== completeAngle){
                inner = true;
              }
              else{
                inner = false;
              }
    
              if(inner){
                MiddleLineAngle = angle1 + (angle/2);
              }
              else{
                MiddleLineAngle = angle1 - (angle/2);
              }
              MiddleLineAngle = smallAngle(MiddleLineAngle)
              const distance = (angle >= 100 ? 50 : 45) * scale;
              angleTextPoints = calculateNextPoint({x:line.x2, y:line.y2}, (MiddleLineAngle), distance);
              angleTextPoints = {
                x: angleTextPoints.x - (angle.toString().length),
                y: angleTextPoints.y - 2,
              }
            }
          }

          const labelText = `${angle || 0}\xB0`;
          const rightAngle = `90\xB0`;
          return (
            // Only compute the angle between the current line and the next line if they are adjacent in the array
            <Label
              key={Math.random().toString()}
              text={labelText !== rightAngle ? labelText : ""}
              x={angleTextPoints.x}
              y={angleTextPoints.y}
              scale={{ x: scale, y: scale }}
            >
              <Text
                text={labelText !== rightAngle ? labelText : ""}
                fontFamily="Calibri"
                fontSize={15}
                padding={3}
                fill="#2C3341"
              />
            </Label>
          );
        })
      }
    </>
  );
};

export default LinesAngleLabels;
