import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { updatePricing } from "Redux/middlwares/order";
import { createUpdatePriceList } from "Redux/middlwares/priceList";
import close from '../../../assets/img/new__user__form/close.png';

const PriceList = (props: any) => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(state => state.user.userData);
  const suppliers = useAppSelector(state => state.supplier.suppliers);
  const userPriceLists = useAppSelector(state => state.priceList.userPriceLists);
  const currentOrderSupplier = useAppSelector(state => state.order.currentOrderSupplier);

  const [loader, setLoader] = useState(false);
  const [currentSupplier, setCurrentSupplier] = useState(0);
  const [editPriceListVals, setEditPriceListVals] = useState(false);
  const [currentPriceList, setCurrentPriceList] = useState({} as any);

  useEffect(() => {
    if (currentOrderSupplier) {
      setCurrentSupplier(currentOrderSupplier);
      const _priceList = userPriceLists.find(item => item.supplier === currentOrderSupplier);
      if (_priceList) {
        setCurrentPriceList(_priceList);
      } else {
        setCurrentPriceList({});
      }
    }
  }, [currentOrderSupplier, userPriceLists]);

  const handleUpdateVal = (key: string, val: string) => {
    if (editPriceListVals === false) setEditPriceListVals(true);
    setCurrentPriceList((prev: any) => ({
      ...prev,
      [key]: parseFloat(val),
    }));
  }


  const closeHandler = () => {
    const exit = userPriceLists.find(item => item.supplier === currentSupplier);
    if (exit) {
      dispatch(updatePricing(exit.supplier));
    }
    setEditPriceListVals(false);
    props.onHide();
  }

  const HandleSavePriceList = (e: any) => {
    e.preventDefault();
    setLoader(true);
    if (Object.keys(currentPriceList).length === 0) return;
    if (editPriceListVals) {
      dispatch(createUpdatePriceList(
        {
          ...currentPriceList,
          user: userData.id,
          supplier: currentSupplier,
        },
        (createUpdated: boolean) => createUpdated ? closeHandler() : '',
      ));
    } else {
      closeHandler();
    }
    setLoader(false);
  }

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size={'lg'}
    >
      <section className="forms">
        <div className="container price-list">
          <div className="row mx-auto">
            <div className="
              form__header
              col-sm-12
              p-4
              d-flex
              justify-content-between
              pt-3
            ">
              <h1 className="text-white text-uppercase mb-0">
                Suppliers Prices
              </h1>
              <span className="close__btn" onClick={props.onHide}>
                <img className="w-100" src={close} alt="close icon" />
              </span>
            </div>
            <div className="col-sm-12 bg-white form__body py-4">
              <div className="form__title pb-md-3 border__bottom pb-2">
                <p className="mb-1">You can enter the prices of your supplier, and see the costs of each item in the document. This way you can also avoid mistakes easily.</p>
                <div className="d-flex justify-content-between">
                  <span style={{fontSize: '12px'}} className="text-capitalize text-muted">* Required field to see price line</span>
                  {/* {edit &&
                    <span
                      className="text-capitalize"
                      style={{cursor: 'pointer'}}
                      onClick={handleDeleteBusiness}
                    ><u>Delete this business</u></span>
                  } */}
                </div>
              </div>
              <div className="form-group d-md-flex justify-content-md-between border__bottom py-4">
                <label htmlFor="name" className="sup" >Suppliers</label>
                <select className="form-control w-50"
                  value={currentSupplier}
                  onChange={(e) => {
                    setCurrentSupplier(parseInt(e.target.value));
                    const exit = userPriceLists.find(item => item.supplier === parseInt(e.target.value));
                    if (exit) setCurrentPriceList(exit);
                    else setCurrentPriceList({});
                  }}
                >
                  <option value={0}>Choose your supplier</option>
                  {suppliers.length > 0 && suppliers.map((item: any, index: number) => (
                    <option key={'supplier'+index} value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>
              <h4 className="font-weight-bold">Price List</h4>
              <form onSubmit={HandleSavePriceList}>
                <div className="inputs py-4 border__bottom">
                  <p className="mb-5 font-weight-bold">Rebars</p>
                  <div className="form-group d-md-flex justify-content-md-between align-items-center">
                    <label htmlFor="name" >Polygonal steel | Diameters: 8-25 mm</label>
                    <input type="number" className="form-control w-30" aria-describedby="name"
                      placeholder="Price per ton" required
                      disabled={currentSupplier === 0}
                      value={currentPriceList.polygon_steel_5_28 || ''}
                      onChange={(e) => handleUpdateVal('polygon_steel_5_28', e.target.value)}
                      />
                  </div>
                  <div className="form-group d-md-flex justify-content-md-between align-items-center">
                    <label htmlFor="name" >Polygonal steel | Diameters: 28-36 mm</label>
                    <input type="number" className="form-control w-30" aria-describedby="name"
                      placeholder="Price per ton" required
                      disabled={currentSupplier === 0}
                      value={currentPriceList.polygon_steel_ab_28 || ''}
                      onChange={(e) => handleUpdateVal('polygon_steel_ab_28', e.target.value)}
                    />
                  </div>
                  <div className="form-group d-md-flex justify-content-md-between align-items-center">
                    <label htmlFor="name" >Round steel | Diameters: 6-12 mm</label>
                    <input type="number" className="form-control w-30" aria-describedby="name"
                      placeholder="Price per ton" required
                      disabled={currentSupplier === 0}
                      value={currentPriceList.round_steel_6_12 || ''}
                      onChange={(e) => handleUpdateVal('round_steel_6_12', e.target.value)}
                    />
                  </div>
                  <div className="form-group d-md-flex justify-content-md-between align-items-center">
                    <label htmlFor="name" >Round steel | Diameters: above 12 mm</label>
                    <input type="number" className="form-control w-30" aria-describedby="name"
                      placeholder="Price per ton" required
                      disabled={currentSupplier === 0}
                      value={currentPriceList.round_steel_abv_12 || ''}
                      onChange={(e) => handleUpdateVal('round_steel_abv_12', e.target.value)}
                    />
                  </div>
                  <div className="form-group d-md-flex justify-content-md-between align-items-center">
                    <label htmlFor="name" >Cuts</label>
                    <input type="number" className="form-control w-30" aria-describedby="name"
                      placeholder="Price per ton" required
                      disabled={currentSupplier === 0}
                      value={currentPriceList.cuts || ''}
                      onChange={(e) => handleUpdateVal('cuts', e.target.value)}
                    />
                  </div>
                  <div className="form-group d-md-flex justify-content-md-between align-items-center">
                    <label htmlFor="name" >Bending</label>
                    <input type="number" className="form-control w-30" aria-describedby="name"
                      placeholder="Price per ton" required
                      disabled={currentSupplier === 0}
                      value={currentPriceList.bending || ''}
                      onChange={(e) => handleUpdateVal('bending', e.target.value)}
                    />
                  </div>
                  <div className="form-group d-md-flex justify-content-md-between align-items-center">
                    <label htmlFor="name" >Extra for 2D hoops</label>
                    <input type="number" className="form-control w-30" aria-describedby="name"
                      placeholder="Price per ton" required
                      disabled={currentSupplier === 0}
                      value={currentPriceList.extra_2d_hops || ''}
                      onChange={(e) => handleUpdateVal('extra_2d_hops', e.target.value)}
                    />
                  </div>
                </div>
                <div className="inputs py-4 border__bottom">
                  <p className="mb-5 font-weight-bold">Nets</p>
                  <div className="form-group d-md-flex justify-content-md-between align-items-center">
                    <label htmlFor="name" >Standard Nets</label>
                    <input type="number" className="form-control w-30" aria-describedby="name"
                      placeholder="Price per ton" required
                      disabled={currentSupplier === 0}
                      value={currentPriceList.standard_nets || ''}
                      onChange={(e) => handleUpdateVal('standard_nets', e.target.value)}
                    />
                  </div>
                  <div className="form-group d-md-flex justify-content-md-between align-items-center">
                    <label htmlFor="name" >Special Nets</label>
                    <input type="number" className="form-control w-30" aria-describedby="name"
                      placeholder="Price per ton" required
                      disabled={currentSupplier === 0}
                      value={currentPriceList.special_nets || ''}
                      onChange={(e) => handleUpdateVal('special_nets', e.target.value)}
                    />
                  </div>
                  <div className="form-group d-md-flex justify-content-md-between align-items-center">
                    <label htmlFor="name" >Extra for bending nets</label>
                    <input type="number" className="form-control w-30" aria-describedby="name"
                      placeholder="Price per ton" required
                      disabled={currentSupplier === 0}
                      value={currentPriceList.extra_bending_nets || ''}
                      onChange={(e) => handleUpdateVal('extra_bending_nets', e.target.value)}
                    />
                  </div>
                </div>
                <div className="inputs py-4 border__bottom">
                  <p className="mb-5 font-weight-bold">Pile cages</p>
                  <div className="form-group d-md-flex justify-content-md-between align-items-center">
                    <label htmlFor="name" >Symmetrical standard pile cage</label>
                    <input type="number" className="form-control w-30" aria-describedby="name"
                      placeholder="Price per ton" required
                      disabled={currentSupplier === 0}
                      value={currentPriceList.standard_pile || ''}
                      onChange={(e) => handleUpdateVal('standard_pile', e.target.value)}
                    />
                  </div>
                  <div className="form-group d-md-flex justify-content-md-between align-items-center">
                    <label htmlFor="name" >Special pile cage</label>
                    <input type="number" className="form-control w-30" aria-describedby="name"
                      placeholder="Price per ton" required
                      disabled={currentSupplier === 0}
                      value={currentPriceList.special_pile || ''}
                      onChange={(e) => handleUpdateVal('special_pile', e.target.value)}
                    />
                  </div>
                  <div className="form-group d-md-flex justify-content-md-between align-items-center">
                    <label htmlFor="name" >Extra for Gama pile | Diameter: 2 inch</label>
                    <input type="number" className="form-control w-30" aria-describedby="name"
                      placeholder="Price per ton" required
                      disabled={currentSupplier === 0}
                      value={currentPriceList.gama_pile_2 || ''}
                      onChange={(e) => handleUpdateVal('gama_pile_2', e.target.value)}
                    />
                  </div>
                  <div className="form-group d-md-flex justify-content-md-between align-items-center">
                    <label htmlFor="name" >Extra for Gama pile | Diameter: 2.5 inch</label>
                    <input type="number" className="form-control w-30" aria-describedby="name"
                      placeholder="Price per ton" required
                      disabled={currentSupplier === 0}
                      value={currentPriceList.gama_pile_2_5 || ''}
                      onChange={(e) => handleUpdateVal('gama_pile_2_5', e.target.value)}
                    />
                  </div>
                </div>
                <div className="
                                        btn__wrap
                                        py-2
                                        pt-4
                                        d-flex
                                        justify-content-between
                                    ">
                  <Button variant="form" onClick={props.onHide}>cancel</Button>
                  <button type="submit" className="btn form__btn__black"
                    disabled={loader || currentSupplier === 0}
                  >
                    {loader &&
                      <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      &nbsp;
                      </>
                    }
                    save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
}

export default PriceList;