import { NET_SUBCLASS_TYPE } from "Constants/net-constants";
import { PILE_SUBCLASS_TYPE } from "Constants/pile-constants";
import { REBAR_SUBCLASS_TYPE } from "Constants/rebar-constants";
import {
  removeCurrentOrderNets,
  setCurrentOrderNets,
  setCurrentOrderNetPage,
  setCurrentOrderPdfNets,
  removeCurrentOrderNet,
  updateCurrentOrderNet,
  addNextPageNetsToCurrentOrder,
  setCurrentOrderNetsCount,
  updatePageNets,
} from "Redux/features/net";
import {
  setCurrentOrderSupplier,
  setShowPrice,
  deletedOrder,
} from "Redux/features/order";
import {
  removeCurrentOrderPiles,
  removeCurrentOrderPile,
  setCurrentOrderPiles,
  setCurrentOrderPdfPiles,
  updateCurrentOrderPile,
  addNextPagePilesToCurrentOrder,
  setCurrentOrderPilePage,
  setCurrentOrderPilesCount,
  updatePagePiles,
} from "Redux/features/pile";
import {
  removeCurrentOrderRebar,
  removeCurrentOrderRebars,
  setCurrentOrderRebars,
  setCurrentOrderRebarPage,
  setCurrentOrderPdfRebars,
  updateCurrentOrderRebar,
  addNextPageRebarsToCurrentOrder,
  setCurrentOrderRebarCount,
  updatePageRebars,
} from "Redux/features/rebar";
import { netStore, pileStore, rebarStore } from "Redux/offline";
import { AppThunk } from 'Redux/store';
import { isAxiosResponse } from "Services/index";
import { deleteOrderItem, deleteOrderItems, deleteOrder, postOrderItem, updateOrderItemPrices, setRowNums, setDupRows, setDelRows, deleteExistingOrderItems, deleteInProgressOrder  } from "Services/order";
import { getUserTools, getUserToolsApiV0 } from "Services/user";
import { fetchCompleteOrder, getEditOrderTools } from "Services/order";
import { setCurrentOrder } from "Redux/features/order";

export const getCurrentOrderTools = (edit: boolean,OrderId: number = 0): AppThunk => async (dispatch, getState) => {
  const user = getState().user.userData;
  if (user) {
    let response;
    if(edit){
      response = await fetchCompleteOrder(OrderId);
      if (isAxiosResponse(response)) {
        await dispatch(setCurrentOrder(response.data.results[0]));
        await dispatch(setCurrentOrderPdfNets(response.data.results[0].order_item.filter((item: any) => item.tool.subclass_type === NET_SUBCLASS_TYPE)));
        await dispatch(setCurrentOrderPdfPiles(response.data.results[0].order_item.filter((item: any) => item.tool.subclass_type === PILE_SUBCLASS_TYPE)));
        await dispatch(setCurrentOrderPdfRebars(response.data.results[0].order_item.filter((item: any) => item.tool.subclass_type === REBAR_SUBCLASS_TYPE)));
      }
      return response.data.results[0];
    } else{
      response = await getUserToolsApiV0();
      if (isAxiosResponse(response)) {
        await dispatch(setCurrentOrder(response.data[0]));
        await dispatch(setCurrentOrderPdfNets(response.data.filter((item: any) => item.tool.subclass_type === NET_SUBCLASS_TYPE)));
        await dispatch(setCurrentOrderPdfPiles(response.data.filter((item: any) => item.tool.subclass_type === PILE_SUBCLASS_TYPE)));
        await dispatch(setCurrentOrderPdfRebars(response.data.filter((item: any) => item.tool.subclass_type === REBAR_SUBCLASS_TYPE)));
      }
    }
  } else {
    await dispatch(setCurrentOrderPdfNets(netStore.getItems()));
    await dispatch(setCurrentOrderPdfPiles(pileStore.getItems()));
    await dispatch(setCurrentOrderPdfRebars(rebarStore.getItems()));
  }
}
export const getCurrentOrderRebars = (editMode: boolean,id: number = 0, pageNum: string = '1'): AppThunk => async (dispatch, getState) => {
  const user = getState().user.userData;
  if (user) {
    let res = null;
    if(editMode){
      res = await getEditOrderTools(id, REBAR_SUBCLASS_TYPE, pageNum);
    } else{
      res = await getUserTools(REBAR_SUBCLASS_TYPE, pageNum);
    }
    if (isAxiosResponse(res)) {
      await dispatch(setCurrentOrderRebarCount(res.data.count));
      const page = res.data.next ? res.data.next.split('page=')[1] : false;
      await dispatch(setCurrentOrderRebarPage(page));
      if(pageNum === "1"){
        await dispatch(setCurrentOrderRebars(res.data.results));
      } else {
        await dispatch(addNextPageRebarsToCurrentOrder(res.data.results));
      }
    }
  } else {
    await dispatch(setCurrentOrderRebars(rebarStore.getItems()));
  }
}

export const getCurrentOrderNets = (editMode: boolean,id: number = 0, pageNum: string = '1'): AppThunk => async (dispatch, getState) => {
  const user = getState().user.userData;
  if (user) {
    let res = null;
    if (editMode) {
      res = await getEditOrderTools(id, NET_SUBCLASS_TYPE, pageNum);
    } else {
      res = await getUserTools(NET_SUBCLASS_TYPE, pageNum);
    }
    if (isAxiosResponse(res)) {
      await dispatch(setCurrentOrderNetsCount(res.data.count));
      const page = res.data.next ? res.data.next.split("page=")[1] : false;
      await dispatch(setCurrentOrderNetPage(page));
      if (pageNum === "1") {
        await dispatch(setCurrentOrderNets(res.data.results));
      } else {
        await dispatch(addNextPageNetsToCurrentOrder(res.data.results));
      }
    }
    
  } else {
    await dispatch(setCurrentOrderNets(netStore.getItems()));
  }
}


export const getCurrentOrderPiles = (editMode: boolean,id: number = 0, pageNum: string = '1'): AppThunk => async (dispatch, getState) => {
  const user = getState().user.userData;
  if (user) {
    let res = null;
    if (editMode) {
      res = await getEditOrderTools(id, PILE_SUBCLASS_TYPE, pageNum);
    } else {
      res = await getUserTools(PILE_SUBCLASS_TYPE, pageNum);
    }
    if (isAxiosResponse(res)) {
      await dispatch(setCurrentOrderPilesCount(res.data.count));
      const page = res.data.next ? res.data.next.split("page=")[1] : false;
      await dispatch(setCurrentOrderPilePage(page));
      if (pageNum === "1") {
        await dispatch(setCurrentOrderPiles(res.data.results));
      } else {
        await dispatch(addNextPagePilesToCurrentOrder(res.data.results));
      }
    }
  } else {
    await dispatch(setCurrentOrderPiles(pileStore.getItems()));
  }
}

export const syncLocalTools = (): AppThunk => (dispatch, getState) => {
  const user = getState().user.userData;
  if (user) {
    const nets = netStore.getItems().map((item: any) => ({...item, user: user.id, tool: {...item.tool, preview_image: item.tool.preview_image.id}}));
    const piles = pileStore.getItems().map((item: any) => ({...item, user: user.id, tool: {...item.tool, preview_image: item.tool.preview_image.id}}));
    const rebars = rebarStore.getItems().map((item: any) => ({...item, user: user.id, tool: {...item.tool, preview_image: item.tool.preview_image.id}}));
    postOrderItem([
      ...nets,
      ...piles,
      ...rebars,
    ]).then((response) => {
      if (response.data) {
        dispatch(setCurrentOrderNets(response.data.filter((item: any) => item.tool.subclass_type === NET_SUBCLASS_TYPE)));
        dispatch(setCurrentOrderPiles(response.data.filter((item: any) => item.tool.subclass_type === PILE_SUBCLASS_TYPE)));
        dispatch(setCurrentOrderRebars(response.data.filter((item: any) => item.tool.subclass_type === REBAR_SUBCLASS_TYPE)));
      }
    });
  }
}

export const removeOrderItem = (orderItem: any, edit = false, editId = 0): AppThunk => async (dispatch, getState) => {
  const response = await deleteOrderItem(orderItem.id);
  if (isAxiosResponse(response)) {
    if (orderItem.tool.subclass_type === REBAR_SUBCLASS_TYPE) {
      const item =  getState().rebar.currentOrderRebars.find(element => element.row_number === orderItem.row_number + 1);
      if (item) {
        const pos_IdObj = {
          'rebar' : {[item.id]: item.row_number}
        }
        await dispatch(setDeleteRows(pos_IdObj));


        // updating the row numbers of rebars after deleting rebar
        const updatedRebars = getState().rebar.currentOrderRebars.map(rebar => {
          if (rebar?.row_number > orderItem.row_number ) {
            return {
              ...rebar,
              row_number: rebar?.row_number - 1,
            };
          }
          return rebar;
        });
        await dispatch(setCurrentOrderRebars(updatedRebars));
      }
      else{
        await dispatch(setCurrentOrderRebarCount(getState().rebar.currentOrderRebarCount - 1));
      }
      dispatch(removeCurrentOrderRebar(orderItem.id));
      const page_num = Math.ceil(getState().rebar.currentOrderRebars.length / 16);
      await dispatch(getCurrentPageRebars(edit, editId, page_num.toString()));

    } else if (orderItem.tool.subclass_type === NET_SUBCLASS_TYPE) {

      const item =  getState().net.currentOrderNets.find(element => element.row_number === orderItem.row_number + 1);
      if (item) {
        const pos_IdObj = {
          'net' : {[item.id]: item.row_number}
        }
        await dispatch(setDeleteRows(pos_IdObj));

        // updating the row numbers of nets after deleting net
        const updatedNets = getState().net.currentOrderNets.map(net => {
          if (net?.row_number > orderItem.row_number ) {
            return {
              ...net,
              row_number: net?.row_number - 1,
            };
          }
          return net;
        });
        await dispatch(setCurrentOrderNets(updatedNets));
      }
      else{
        await dispatch(setCurrentOrderNetsCount(getState().net.currentOrderNetsCount - 1));
      }
      dispatch(removeCurrentOrderNets(orderItem.id));
      const page_num = Math.ceil(getState().net.currentOrderNets.length / 16);
      await dispatch(getCurrentPageNets(edit, editId, page_num.toString()));

    } else if (orderItem.tool.subclass_type === PILE_SUBCLASS_TYPE) {


      const item = getState().pile.currentOrderPiles.find(
        (element) => element.row_number === orderItem.row_number + 1
      );
      if (item) {
        const pos_IdObj = {
          pile: { [item.id]: item.row_number },
        };
        await dispatch(setDeleteRows(pos_IdObj));

        // updating the row numbers of piles after deleting net
        const updatedPiles = getState().pile.currentOrderPiles.map((pile) => {
          if (pile?.row_number > orderItem.row_number) {
            return {
              ...pile,
              row_number: pile?.row_number - 1,
            };
          }
          return pile;
        });
        await dispatch(setCurrentOrderPiles(updatedPiles));
      } else {
        await dispatch(
          setCurrentOrderPilesCount(getState().pile.currentOrderPilesCount - 1)
        );
      }
      dispatch(removeCurrentOrderPiles(orderItem.id));
      const page_num = Math.ceil(getState().pile.currentOrderPiles.length / 16);
      await dispatch(getCurrentPagePiles(edit, editId, page_num.toString()));
    }
  }
}

export const removeOrderItems = (): AppThunk => dispatch => {
    return deleteInProgressOrder()
      .then((result) => {
        dispatch(removeCurrentOrderRebars());
        dispatch(removeCurrentOrderNet());
        dispatch(removeCurrentOrderPile());
        return true;
      })
      .catch((err) => {
        return false;
      });
}

export const removeOrder = (order: any): AppThunk => dispatch => {  
  
  return deleteExistingOrderItems(parseInt(order.id)).then((result) => {
      dispatch(removeCurrentOrderRebars());
      dispatch(removeCurrentOrderNet());
      dispatch(removeCurrentOrderPile())
      dispatch(deletedOrder(true));
      return true;
    }).catch((err) => {
      return false;
    });
  
}

export const removeSavedOrder = (order: any): AppThunk => dispatch => {  
  return deleteExistingOrderItems(parseInt(order.id))
    .then((result) => {
      return true;
    })
    .catch((err) => {
      return false;
    });
}

export const updatePricing = (supplierID: number): AppThunk => (dispatch, getState) => {
  const rebars = getState().rebar.currentOrderRebars;
  const nets = getState().net.currentOrderNets;
  const piles = getState().pile.currentOrderPiles;

  const orderItemIDs = [
    ...rebars.map(item => item.id),
    ...nets.map(item => item.id),
    ...piles.map(item => item.id),
  ];

  updateOrderItemPrices({
    supplier_id: supplierID,
    order_item: orderItemIDs,
  }).then((res) => {
    dispatch(setCurrentOrderSupplier(supplierID));
    const orderItems = [...res.data.data.data];
    orderItems.forEach(item => {
      if (item.tool.subclass_type === REBAR_SUBCLASS_TYPE) {
        dispatch(updateCurrentOrderRebar({...item}));
      } else if (item.tool.subclass_type === NET_SUBCLASS_TYPE) {
        dispatch(updateCurrentOrderNet({...item}));
      } else if (item.tool.subclass_type === PILE_SUBCLASS_TYPE) {
        dispatch(updateCurrentOrderPile({...item}));
      }
    });
    dispatch(setShowPrice(true));
  }).catch((err) => console.log(err));
}

export const setRowNumbers = (data: any): AppThunk => (dispatch) => {
  return setRowNums(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};


export const setDuplicateRows = (data: any): AppThunk => (dispatch) => {
  return setDupRows(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export const setDeleteRows = (data: any): AppThunk => (dispatch) => {
  return setDelRows(data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCurrentPageNets = (editMode: boolean,id: number = 0, pageNum: string = '1'): AppThunk => async (dispatch, getState) => {
  const user = getState().user.userData;
  if (user) {
    let res = null;
    if (editMode) {
      res = await getEditOrderTools(id, NET_SUBCLASS_TYPE, pageNum);
    } else {
      res = await getUserTools(NET_SUBCLASS_TYPE, pageNum);
    }
    if (isAxiosResponse(res)) {
        await dispatch(setCurrentOrderNetsCount(res.data.count))
        await dispatch(updatePageNets(res.data.results));
    }
  }
}

export const getCurrentPageRebars = (editMode: boolean,id: number = 0, pageNum: string = '1'): AppThunk => async (dispatch, getState) => {
  const user = getState().user.userData;
  if (user) {
    let res = null;
    if (editMode) {
      res = await getEditOrderTools(id, REBAR_SUBCLASS_TYPE, pageNum);
    } else {
      res = await getUserTools(REBAR_SUBCLASS_TYPE, pageNum);
    }
    if (isAxiosResponse(res)) {
        await dispatch(setCurrentOrderRebarCount(res.data.count))
        await dispatch(updatePageRebars(res.data.results));

    }
  }
} 

export const getCurrentPagePiles = (editMode: boolean, id: number = 0, pageNum: string = "1"): AppThunk => async (dispatch, getState) => {
    const user = getState().user.userData;
    if (user) {
      let res = null;
      if (editMode) {
        res = await getEditOrderTools(id, PILE_SUBCLASS_TYPE, pageNum);
      } else {
        res = await getUserTools(PILE_SUBCLASS_TYPE, pageNum);
      }
      if (isAxiosResponse(res)) {
        await dispatch(setCurrentOrderPilesCount(res.data.count));
        await dispatch(updatePagePiles(res.data.results));
      }
    }
  }; 