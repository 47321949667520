import { useTranslation } from "react-i18next";
import "./index.styled.scss";

const SideBar = (props: any) => {
  const { tab, setTab, edit, children} = props;
  const { t } = useTranslation(["common", "tool"]);

  return (
    <div className="left-sidebar" style={{position: "relative", zIndex: "15"}}>
      <div className="tool-selector">
        <button
          onClick={!edit ? () => setTab(1) : () => {}}
          className={tab === 1 ? "active" : ""}
        >
          {t("rebar", { ns: "tool" })}
        </button>
        <button
          onClick={!edit ? () => setTab(2) : () => {}}
          className={tab === 2 ? "active" : ""}
        >
          {t("net", { ns: "tool" })}
        </button>
        <button
          onClick={!edit ? () => setTab(3) : () => {}}
          className={tab === 3 ? "active" : ""}
        >
          {t("cage", { ns: "tool" })}
        </button>
      </div>
      <div className="sidebar-content">{children}</div>
    </div>
  );
};

export default SideBar;
