import { useRef,useEffect } from "react";
import { Modal } from 'react-bootstrap';
import close from "../../assets/img/close.svg";
import { useAppSelector } from "Redux/hooks";
import "./index.scss";

function WarningModal({ ...props }: any) {
  const { btnFunctions, btnValues, modalColor, disableSave } = props;
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  const primaryBtn = useRef(null);
  const secondaryBtn = useRef(null);

  useEffect(() => {
    const eventHandler = (e) => {
      downHandler({e,rightToLeft})
    } 
    window.addEventListener("keydown", eventHandler);
    return () => {
      window.removeEventListener("keydown", eventHandler);
    };
  }, []);

  const downHandler = ({e,rightToLeft}) => {
    primaryBtn.current?.classList.remove("prime_btn_");
    primaryBtn.current?.classList.add("no_prime");

    const element = document.getElementById("primary 1");
    if(e.code === "Enter"){
      if (element?.id[8] === "1") {
        primaryBtn.current?.focus();
      } else if (document.activeElement.id === "secondary") {
        element?.setAttribute("id", "primary 2");
        secondaryBtn.current?.focus();
      }
    }
    if(rightToLeft){
      if (e.code === "ArrowRight") {
        element?.setAttribute("id", "primary 2");
        secondaryBtn.current?.focus();
      } else if (e.code === "ArrowLeft") {
        primaryBtn.current?.focus();
      }
    } else{
      if (e.code === "ArrowRight") {
        primaryBtn.current?.focus();
      } else if (e.code === "ArrowLeft") {
        element?.setAttribute("id", "primary 2");
        secondaryBtn.current?.focus();
      }
    }
  }


  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <section
          className="d-flex flex-column p-3 rounded custom__modal"
          style={{ backgroundColor: modalColor }}
        >
          <div className="pb-3">
            <img
              src={close}
              alt="close icon"
              style={{ cursor: "pointer" }}
              onClick={() => props.onHide()}
            />
          </div>
          {/* In btnfunctions prop two functions will be recieved for
          primary and secondary functionality*/}
          <div className="custom__child">
            {props.children}
            <div
              className="
                                        pt-2
                                        pb-4
                                        custom__btn_wrapper
                                    "
            >
              <button
                id="secondary"
                ref={secondaryBtn}
                className={btnValues[0].btnClass + " primary"}
                onClick={btnFunctions.secondary}
              >
                {btnValues[0].text}
              </button>
              <button
                id="primary 1"
                ref={primaryBtn}
                className={btnValues[1].btnClass + " prime_btn_ primary"}
                onClick={btnFunctions.primary}
                disabled={disableSave === true}
              >
                {btnValues[1].text}
              </button>
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
}

export default WarningModal;
