import Request from "./index";

export const postBusiness = (data: Object, company: boolean) =>
  Request.post('business/', data, {}, {set_current_company: company},);

export const getBusinesses = () =>
  Request.get('business/');

export const getBusinessInstance = (businessID: number) =>
  Request.get(`business/${businessID}/`);

export const patchBusiness = (businessID: number, data: Object) =>
  Request.patch(`business/${businessID}/`, data);

export const deleteBusiness = (businessID: number) =>
  Request.remove(`business/${businessID}/`);
