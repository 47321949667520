import React, { useEffect, useState } from "react";
import { NET_SUBCLASS_TYPE } from "Constants/net-constants";
import { REBAR_SUBCLASS_TYPE } from "Constants/rebar-constants";
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { fetchCompleteOrder } from "Services/order";
import { fetchSteelTypes } from "Redux/middlwares/steelType";
import downloadIcon from "Assets/img/save_export/download-dark.svg";
import toast from "react-hot-toast";

import {
  COLUMNS,
  REBAR_HEADERS,
  NET_HEADERS,
  SPECIAL_NET_HEADERS,
  HEADER_FILL,
  HEADER_FONT,
  HEADING_FONT,
  THIN_BORDER,
} from "Constants/order-excel";

const ExcelJS = require("exceljs");

const OrderExcel = ({ order }) => {
  const dispatch = useAppDispatch();

  const steelTypes = useAppSelector((state) => state.steelType.steelTypes);

  useEffect(() => {
    steelTypes.length === 0 && dispatch(fetchSteelTypes());
  }, [steelTypes.length, dispatch]);

  const exportExcelFile = async () => {
    return new Promise((resolve, reject) => {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet("My Sheet", {
        properties: { defaultColWidth: 15, defaultRowHeight: 20 },
      });
      fetchCompleteOrder(order).then((res) => {
        const stats = [
          res.data.results[0].order_no,
          res.data.results[0].created_by.first_name,
          res.data.results[0]?.name,
        ];
        const rebars = res.data.results[0].order_item.filter(
          (item) => item.tool.subclass_type === REBAR_SUBCLASS_TYPE
        );
        const standard_net = res.data.results[0].order_item.filter(
          (item) =>
            item.tool.subclass_type === NET_SUBCLASS_TYPE &&
            item.tool.standard_net
        );
        const special_net = res.data.results[0].order_item.filter(
          (item) =>
            item.tool.subclass_type === NET_SUBCLASS_TYPE &&
            !item.tool.standard_net
        );

        const nets_index = rebars.length > 0 ? rebars.length + 15 : 6;
        const special_nets_index =
          standard_net.length === 0 && rebars.length === 0
            ? 6
            : standard_net.length === 0
            ? nets_index
            : nets_index + 11;

        const promise = Promise.all(
          // Order Stats
          ...["order Number", "User Name", "Order Name"].map((val, index) => [
            (sheet.getCell(`${COLUMNS[1]}${index + 2}`).value = val),
            (sheet.getCell(`${COLUMNS[1]}${index + 2}`).border = THIN_BORDER),
            (sheet.getCell(`${COLUMNS[1]}${index + 2}`).fill = HEADER_FILL),
            (sheet.getCell(`${COLUMNS[2]}${index + 2}`).value = stats[index]),
            (sheet.getCell(`${COLUMNS[2]}${index + 2}`).border = THIN_BORDER),
          ]),
          ...(rebars.length > 0
            ? [
                // Rebar Heading
                (sheet.getCell(`${COLUMNS[1]}6`).value = "Rebars"),
                (sheet.getCell(`${COLUMNS[1]}6`).font = HEADING_FONT),
                (sheet.getRow(6).height = 40),
                // Rebar Table Header
                REBAR_HEADERS.forEach((header, index) => {
                  header.map((val, i)=>{
                    sheet.getCell(`${COLUMNS[i + 1]}${index+8}`).font = HEADER_FONT;
                    sheet.getCell(`${COLUMNS[i + 1]}${index+8}`).fill = HEADER_FILL;
                    sheet.getCell(`${COLUMNS[i + 1]}${index+8}`).border = THIN_BORDER;
                    sheet.getCell(`${COLUMNS[i + 1]}${index+8}`).value =
                      header[i];
                  })

                }),

                // Rebar Table Rows
                rebars.forEach((rebar, index) => {
                  sheet.getCell(`${COLUMNS[1]}${index + 11}`).value =
                    rebar?.row_number;
                  sheet.getCell(`${COLUMNS[2]}${index + 11}`).value = {
                    text: "link",
                    hyperlink: rebar?.tool?.preview_image?.file_field,
                    tooltip: "order link",
                  };
                  sheet.getCell(`${COLUMNS[3]}${index + 11}`).value =
                    steelTypes.find(
                      (type) => type.id === rebar?.tool?.steel_type
                    )?.name;
                  sheet.getCell(`${COLUMNS[4]}${index + 11}`).value =
                    parseFloat(rebar?.tool?.diameter?.steel_diameter);
                  sheet.getCell(`${COLUMNS[5]}${index + 11}`).value =
                    rebar?.tool?.rebar_side?.reduce(
                      (acc, rebar) => acc + parseFloat(rebar?.length || 0),
                      0
                    );
                  sheet.getCell(`${COLUMNS[6]}${index + 11}`).value =
                    rebar?.quantity;
                  sheet.getCell(`${COLUMNS[7]}${index + 11}`).value =
                    parseFloat(rebar?.tool?.unit_weight);
                  sheet.getCell(`${COLUMNS[8]}${index + 11}`).value =
                    rebar?.tool?.unit_weight * rebar?.quantity ||
                    rebar?.tool?.unit_weight ||
                    0;

                  for (let i = 1; i <= 8; i++) {
                    sheet.getCell(`${COLUMNS[i]}${index + 11}`).border =
                      THIN_BORDER;
                  }
                }),

                // Rebar Table Total
                (sheet.getCell(`${COLUMNS[6]}${rebars.length + 11}`).value =
                  "Total:"),
                (sheet.getCell(`${COLUMNS[6]}${rebars.length + 11}`).fill =
                  HEADER_FILL),
                (sheet.getCell(`${COLUMNS[6]}${rebars.length + 11}`).border =
                  THIN_BORDER),
                (sheet.getCell(`${COLUMNS[7]}${rebars.length + 11}`).value =
                  rebars.reduce(
                    (acc, rebar) => acc + parseFloat(rebar.quantity),
                    0
                  )),
                (sheet.getCell(`${COLUMNS[7]}${rebars.length + 11}`).border =
                  THIN_BORDER),
                (sheet.getCell(`${COLUMNS[8]}${rebars.length + 11}`).value = (
                  rebars.reduce(
                    (acc, rebar) =>
                      acc + parseFloat(rebar.tool.unit_weight) * rebar.quantity,
                    0
                  ) / 1000
                ).toFixed(2)),
                (sheet.getCell(`${COLUMNS[8]}${rebars.length + 11}`).border =
                  THIN_BORDER),
                (sheet.getCell(`${COLUMNS[7]}${rebars.length + 12}`).value =
                  "Units"),
                (sheet.getCell(`${COLUMNS[7]}${rebars.length + 12}`).fill =
                  HEADER_FILL),
                (sheet.getCell(`${COLUMNS[7]}${rebars.length + 12}`).border =
                  THIN_BORDER),
                (sheet.getCell(`${COLUMNS[8]}${rebars.length + 12}`).value =
                  "Weight(Ton)"),
                (sheet.getCell(`${COLUMNS[8]}${rebars.length + 12}`).fill =
                  HEADER_FILL),
                (sheet.getCell(`${COLUMNS[8]}${rebars.length + 12}`).border =
                  THIN_BORDER),
              ]
            : []),

          ...(standard_net.length > 0
            ? [
                // Standard Net Heading
                (sheet.getCell(`${COLUMNS[1]}${nets_index}`).value =
                  "Standard Nets"),
                (sheet.getCell(`${COLUMNS[1]}${nets_index}`).font =
                  HEADING_FONT),
                (sheet.getRow(nets_index).height = 40),

                // Standard Net Table Header
                NET_HEADERS.forEach((header, index) => {
                  header.map((val, i)=>{
                    sheet.getCell(`${COLUMNS[i + 1]}${nets_index + index + 2}`).font = HEADER_FONT;
                    sheet.getCell(`${COLUMNS[i + 1]}${nets_index + index + 2}`).fill = HEADER_FILL;
                    sheet.getCell(`${COLUMNS[i + 1]}${nets_index + index + 2}`).border = THIN_BORDER;
                    sheet.getCell(`${COLUMNS[i + 1]}${nets_index + index + 2}`).value =
                      header[i];
                  })
                }),
                // Standard Net Rows
                standard_net.forEach((net, index) => {
                  sheet.getCell(
                    `${COLUMNS[1]}${index + nets_index + 5}`
                  ).value = net?.row_number;
                  sheet.getCell(
                    `${COLUMNS[2]}${index + nets_index + 5}`
                  ).value = net?.tool?.x_length;
                  sheet.getCell(
                    `${COLUMNS[3]}${index + nets_index + 5}`
                  ).value = net?.tool?.y_length;
                  sheet.getCell(
                    `${COLUMNS[4]}${index + nets_index + 5}`
                  ).value = parseFloat(net?.tool?.x_diameter?.steel_diameter);
                  sheet.getCell(
                    `${COLUMNS[5]}${index + nets_index + 5}`
                  ).value = net?.tool?.x_gap;
                  sheet.getCell(
                    `${COLUMNS[6]}${index + nets_index + 5}`
                  ).value = steelTypes.find(
                    (type) => type.id === net?.tool?.steel_type
                  )?.name;
                  sheet.getCell(
                    `${COLUMNS[7]}${index + nets_index + 5}`
                  ).value = net?.quantity;
                  sheet.getCell(
                    `${COLUMNS[8]}${index + nets_index + 5}`
                  ).value = parseFloat(net?.tool?.unit_weight);
                  sheet.getCell(
                    `${COLUMNS[9]}${index + nets_index + 5}`
                  ).value =
                    net?.tool?.unit_weight * net?.quantity ||
                    net?.tool?.unit_weight ||
                    0;

                  for (let i = 1; i <= 9; i++) {
                    sheet.getCell(
                      `${COLUMNS[i]}${index + nets_index + 5}`
                    ).border = THIN_BORDER;
                  }
                }),

                // Standard Net Table Total
                (sheet.getCell(
                  `${COLUMNS[7]}${nets_index + standard_net.length + 5}`
                ).value = "Total:"),
                (sheet.getCell(
                  `${COLUMNS[7]}${nets_index + standard_net.length + 5}`
                ).fill = HEADER_FILL),
                (sheet.getCell(
                  `${COLUMNS[7]}${nets_index + standard_net.length + 5}`
                ).border = THIN_BORDER),
                (sheet.getCell(
                  `${COLUMNS[8]}${nets_index + standard_net.length + 5}`
                ).value = standard_net.reduce(
                  (acc, net) => acc + parseFloat(net.quantity),
                  0
                )),
                (sheet.getCell(
                  `${COLUMNS[8]}${nets_index + standard_net.length + 5}`
                ).border = THIN_BORDER),
                (sheet.getCell(
                  `${COLUMNS[9]}${nets_index + standard_net.length + 5}`
                ).value = Number(
                  standard_net.reduce(
                    (acc, net) =>
                      acc + parseFloat(net.tool.unit_weight) * net.quantity,
                    0
                  ) / 1000
                ).toFixed(2)),
                (sheet.getCell(
                  `${COLUMNS[9]}${nets_index + standard_net.length + 5}`
                ).border = THIN_BORDER),
                (sheet.getCell(
                  `${COLUMNS[8]}${nets_index + standard_net.length + 6}`
                ).value = "Units"),
                (sheet.getCell(
                  `${COLUMNS[8]}${nets_index + standard_net.length + 6}`
                ).fill = HEADER_FILL),
                (sheet.getCell(
                  `${COLUMNS[8]}${nets_index + standard_net.length + 6}`
                ).border = THIN_BORDER),
                (sheet.getCell(
                  `${COLUMNS[9]}${nets_index + standard_net.length + 6}`
                ).value = "Weight(Ton)"),
                (sheet.getCell(
                  `${COLUMNS[9]}${nets_index + standard_net.length + 6}`
                ).fill = HEADER_FILL),
                (sheet.getCell(
                  `${COLUMNS[9]}${nets_index + standard_net.length + 6}`
                ).border = THIN_BORDER),
              ]
            : []),

          ...(special_net.length > 0
            ? [
                // Special Net Heading
                (sheet.getCell(`${COLUMNS[1]}${special_nets_index}`).value =
                  "Special Nets"),
                (sheet.getCell(`${COLUMNS[1]}${special_nets_index}`).font =
                  HEADING_FONT),
                (sheet.getRow(nets_index).height = 40),
                // Special Table Header
                SPECIAL_NET_HEADERS.forEach((header, index) => {
                  header.map((val, i)=>{
                    sheet.getCell(`${COLUMNS[i + 1]}${special_nets_index + index + 2}`).font = HEADER_FONT;
                    sheet.getCell(`${COLUMNS[i + 1]}${special_nets_index + index + 2}`).fill = HEADER_FILL;
                    sheet.getCell(`${COLUMNS[i + 1]}${special_nets_index + index + 2}`).border = THIN_BORDER;
                    sheet.getCell(`${COLUMNS[i + 1]}${special_nets_index + index + 2}`).value =
                      header[i];
                  })
                }),

                special_net.forEach((net, index) => {
                  sheet.getCell(
                    `${COLUMNS[1]}${index + special_nets_index + 5}`
                  ).value = net?.row_number;
                  sheet.getCell(
                    `${COLUMNS[2]}${index + special_nets_index + 5}`
                  ).value = steelTypes.find(
                    (type) => type.id === net?.tool?.steel_type
                  )?.name;
                  sheet.getCell(
                    `${COLUMNS[3]}${index + special_nets_index + 5}`
                  ).value = Number(net?.tool?.x_diameter?.steel_diameter);
                  sheet.getCell(
                    `${COLUMNS[4]}${index + special_nets_index + 5}`
                  ).value = net?.tool?.x_gap;
                  sheet.getCell(
                    `${COLUMNS[5]}${index + special_nets_index + 5}`
                  ).value = net?.tool?.x_length;
                  sheet.getCell(
                    `${COLUMNS[6]}${index + special_nets_index + 5}`
                  ).value = net?.tool?.x_2;
                  sheet.getCell(
                    `${COLUMNS[7]}${index + special_nets_index + 5}`
                  ).value =
                    net?.tool?.x_length - net?.tool?.x_2 - net?.tool?.x_3;
                  sheet.getCell(
                    `${COLUMNS[8]}${index + special_nets_index + 5}`
                  ).value = net?.tool?.x_3;

                  sheet.getCell(
                    `${COLUMNS[9]}${index + special_nets_index + 5}`
                  ).value = Number(net?.tool?.y_diameter?.steel_diameter);
                  sheet.getCell(
                    `${COLUMNS[10]}${index + special_nets_index + 5}`
                  ).value = net?.tool?.y_gap;
                  sheet.getCell(
                    `${COLUMNS[11]}${index + special_nets_index + 5}`
                  ).value = net?.tool?.y_length;
                  sheet.getCell(
                    `${COLUMNS[12]}${index + special_nets_index + 5}`
                  ).value = net?.tool?.y_2;
                  sheet.getCell(
                    `${COLUMNS[13]}${index + special_nets_index + 5}`
                  ).value =
                    net?.tool?.y_length - net?.tool?.y_2 - net?.tool?.y_3;
                  sheet.getCell(
                    `${COLUMNS[14]}${index + special_nets_index + 5}`
                  ).value = net?.tool?.y_3;

                  sheet.getCell(
                    `${COLUMNS[15]}${index + special_nets_index + 5}`
                  ).value = net?.quantity;
                  sheet.getCell(
                    `${COLUMNS[16]}${index + special_nets_index + 5}`
                  ).value = Number(net?.tool?.unit_weight);
                  sheet.getCell(
                    `${COLUMNS[17]}${index + special_nets_index + 5}`
                  ).value =
                    net?.tool?.unit_weight * net?.quantity ||
                    net?.tool?.unit_weight ||
                    0;

                  for (let i = 1; i <= 17; i++) {
                    sheet.getCell(
                      `${COLUMNS[i]}${index + special_nets_index + 5}`
                    ).border = THIN_BORDER;
                  }
                }),

                // Special Net Table Total
                (sheet.getCell(
                  `${COLUMNS[15]}${special_nets_index + special_net.length + 5}`
                ).value = "Total:"),
                (sheet.getCell(
                  `${COLUMNS[15]}${special_nets_index + special_net.length + 5}`
                ).fill = HEADER_FILL),
                (sheet.getCell(
                  `${COLUMNS[15]}${special_nets_index + special_net.length + 5}`
                ).border = THIN_BORDER),
                (sheet.getCell(
                  `${COLUMNS[16]}${special_nets_index + special_net.length + 5}`
                ).value = special_net.reduce(
                  (acc, net) => acc + parseFloat(net.quantity),
                  0
                )),
                (sheet.getCell(
                  `${COLUMNS[16]}${special_nets_index + special_net.length + 5}`
                ).border = THIN_BORDER),
                (sheet.getCell(
                  `${COLUMNS[17]}${special_nets_index + special_net.length + 5}`
                ).value = (
                  special_net.reduce(
                    (acc, net) =>
                      acc + parseFloat(net.tool.unit_weight) * net.quantity,
                    0
                  ) / 1000
                ).toFixed(2)),
                (sheet.getCell(
                  `${COLUMNS[17]}${special_nets_index + special_net.length + 5}`
                ).border = THIN_BORDER),
                (sheet.getCell(
                  `${COLUMNS[16]}${special_nets_index + special_net.length + 6}`
                ).value = "Units"),
                (sheet.getCell(
                  `${COLUMNS[16]}${special_nets_index + special_net.length + 6}`
                ).fill = HEADER_FILL),
                (sheet.getCell(
                  `${COLUMNS[16]}${special_nets_index + special_net.length + 6}`
                ).border = THIN_BORDER),
                (sheet.getCell(
                  `${COLUMNS[17]}${special_nets_index + special_net.length + 6}`
                ).value = "Weight(Ton)"),
                (sheet.getCell(
                  `${COLUMNS[17]}${special_nets_index + special_net.length + 6}`
                ).fill = HEADER_FILL),
                (sheet.getCell(
                  `${COLUMNS[17]}${special_nets_index + special_net.length + 6}`
                ).border = THIN_BORDER),
              ]
            : [])
        );

        promise.then(() => {
          workbook.xlsx.writeBuffer().then(function (data) {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = `${stats[0]}.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
        });
        resolve();
      });
    });
  };

  return (
    <div
      onClick={() => {
        toast.promise(exportExcelFile(), {
          loading: "Downloading File...",
          success: <b>File Downloaded!</b>,
          error: <b>Could not download file.</b>,
        });
      }}
    >
      <img
        style={{ cursor: "pointer" }}
        className="excel_download_icon"
        src={downloadIcon}
        alt="Download Icon"
      />
    </div>
  );
};

export default OrderExcel;
