import { useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { fetchDefaultsRebars } from "Redux/middlwares/rebar";
import { ReactComponent as DeleteIcon } from "../../../assets/img/admin/delete.svg";
import { ReactComponent as EditIcon } from "../../../assets/img/admin/edit.svg";

const RebarsCard = () => {
  const userData = useAppSelector((state) => state.user.userData);
  const defaultRebars = useAppSelector((state) => state.rebar.defaultRebars);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userData) {
      defaultRebars.length === 0 && dispatch(fetchDefaultsRebars());
    }
  }, [userData, defaultRebars.length, dispatch]);

  return (
    <>
      <p className="pb-3">Rebars Pool</p>
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between pb-3">
            <p className="font-weight-bold">Standard Rebars</p>
            <div className="d-flex justify-content-around align-items-center">
              <div className="px-2">
                <input
                  type="checkbox"
                  id="order-by-popularity"
                  className="mr-1"
                />
                <label htmlFor="order-by-popularity">Order by Popularity</label>
              </div>
              <Button variant="light" className="mr-2">
                <EditIcon />
                Edit
              </Button>
              <Button variant="light" className="mr-2">
                <DeleteIcon />
                delete
              </Button>
              <Button variant="light">add new shape</Button>
            </div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${defaultRebars.length} , 1fr)`,
            }}
            className="border border-dark"
          >
            {defaultRebars &&
              defaultRebars.length > 0 &&
              defaultRebars.map((group: any, gindex: number) => (
                <div
                  className={
                    "d-flex flex-column" +
                    (defaultRebars.length - 1 !== gindex ? " border-right border-dark" : "")
                  }
                  key={"rebar-row" + gindex}
                >
                  {group.rebars.map((item: any, index: number) => (
                    <div className="border-bottom border-dark">
                      <div
                        key={"rebar" + index}
                        className="rebar-admin-icon"
                        style={index === 0 ? {background: '#374051', margin: "10px auto" } : {margin: "10px auto" }}
                      >
                        <img
                          className="d-inline-block"
                          src={item.icon.file_field}
                          alt={item.icon.alt_text || "tools images"}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default RebarsCard;
