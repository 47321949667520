import "./index.css";

import { isMobile } from "react-device-detect";
import { useState, useEffect } from "react";
import { verifyUserEmail} from "../../services/user";
import { useHistory } from "react-router-dom";
import Login from "Components/common-components/login";
import { useQuery } from "Components/tool/utils";
import { useAppDispatch } from "Redux/hooks";
import {VerifyEmailMobile} from "./verifyEmailMobile"

function LoginPage() {
  const router = useHistory();
  const dispatch = useAppDispatch();

  const query = useQuery();
  const token = query.get("token");
  
  const [loginShow, setLoginShow] = useState(false);

  const goToNewOrderPage=()=>{
    router.push('/new-order');
  }

  useEffect(()=>{
    
    if(token){
      if(isMobile){
        verifyUserEmail(token, dispatch);
      }else{
        verifyUserEmail(token, dispatch, goToNewOrderPage, ()=>{setLoginShow(true)});
      }
      router.replace({search: ""});
    }else{
      if(isMobile){
        goToNewOrderPage();
      }
      setLoginShow(true);
    }
  },[])
  
  return (
    isMobile ? <VerifyEmailMobile/>
      : ( <div className="background">
            { loginShow 
              ? <Login showCloseButton={false} show={loginShow} onHide={() => setLoginShow(false)} />
              : <span className="spinner-border spinner-border-centered" role="status" aria-hidden="true"></span>
            }
          </div>
        )
  )
}

export default LoginPage;
