import { Label } from "react-konva";
import { Tag, Text } from "react-konva/lib/ReactKonvaCore";
import { getLineLength } from "../shared/shapesGeometry.ts";

//This component is to draw labels and text on the lines.
export const PathLengthLabel = (props) => {
  const { lines, scale } = props;
  return (
    <>
      {lines.map((line) => (
        <>
          <Label
            key={Math.random().toString()}
            text={Math.round(
              getLineLength(
                { x: line.x1, y: line.y1 },
                { x: line.x2, y: line.y2 }
              )
            ).toString()}
            x={(line.x1 + line.x2) / 2}
            y={(line.y1 + line.y2) / 2}
            scale={{ x: scale, y: scale }}
          >
            <Tag
              fill="white"
              pointerDirection="none"
              pointerWidth={10}
              pointerHeight={10}
              lineJoin="round"
              shadowColor="black"
              shadowOffsetX={3}
              shadowOffsetY={3}
              shadowOpacity={0.1}
            />
            <Text
              text={Math.round(
                getLineLength(
                  { x: line.x1, y: line.y1 },
                  { x: line.x2, y: line.y2 }
                )
              ).toString()}
              fontFamily="Heebo"
              fontSize={20}
              padding={5}
              fill="black"
            />
          </Label>
        </>
      ))}
    </>
  );
};
