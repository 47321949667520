import SectionHeader from "../section-header/sectionHeader";
import "./notifications.css";
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { patchUser } from "Redux/middlwares/user";
import { useTranslation } from "react-i18next";
const Notifications = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.userData);
  const { t } = useTranslation(['common', 'userProfile']);
  const rightToLeft = useAppSelector(state => state.root.rightToLeft);
  const handleChange = (e: any, key: any) => {
    const data = {
      [key]: !user[key],
    };
    dispatch(patchUser(user.id, data));
  };

  return (
    <>
      <section style={{ background: "white", marginBottom: "50px" }}>
        <SectionHeader heading={t('Notifications', {ns: 'userProfile'})} icon="bell" />
        <div style={{ padding: "50px" }}>
          <hr></hr>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "18px" }}>
              {t('NewsLetter Flag', {ns: 'userProfile'})}
            </p>{" "}
            <div className={"toggle-switch" + (rightToLeft ? " rightToLeft" : "")}>
              <input
                style={{display: 'none'}}
                type="checkbox"
                className={"checkbox" + (rightToLeft ? " rightToLeft" : "")}
                name="label1"
                id="news_promos"
                onChange={(e) => handleChange(e, "news_promos")}
                checked={user.news_promos}
              />
              <label className="label" htmlFor="news_promos">
                <span className={"inner" + (rightToLeft ? " rightToLeft" : "")} />
                <span className={"switch" + (rightToLeft ? " rightToLeft" : "")} />
              </label>
            </div>
          </div>
          <hr></hr>
          {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>{t('Weekly Report', {ns: 'userProfile'})}</p>{" "}
            <div className="toggle-switch">
              <input
                type="checkbox"
                className="checkbox"
                name="label2"
                id="weekly_report"
                onChange={(e) => handleChange(e, "weekly_report")}
                checked={user.weekly_report}
              />
              <label className="label" htmlFor="weekly_report">
                <span className="inner" />
                <span className="switch" />
              </label>
            </div>
          </div>
          <hr></hr> */}
        </div>
      </section>
    </>
  );
};

export default Notifications;
