import { PI } from 'Constants/general';
import React, {useEffect, useRef, useState} from 'react';
import { isBrowser, isMobile } from "react-device-detect";
import { useAppDispatch, useAppSelector } from 'Redux/hooks';
import { createPile, fetchDefaultsPiles, updatePile } from 'Redux/middlwares/pile';
import { postMedia, updateMedia } from 'Services/media';
import PileMobileContent from './mobile/content';
import html2canvas from 'html2canvas';
import PilesSideBar from "./web/SideBar";
import PileShape from "./shape/shape";


import SideBar from "../blocks/left-sidebar";
import SketchBody from "../blocks/sketch-body";
import LastAddedSidebar from "../blocks/right-sidebar";
import PilesCard from "../blocks/right-sidebar/piles-cards/PilesCards";

import { useTranslation } from "react-i18next";
import {
  CAGE_LEN_MIN,
  CAGE_LEN_MAX,
  pileErrorTypes,
  polygonSteelType,
  DEFAULT_SIDE_DIAMETER,
  DEFAULT_EXTERNAL_AMOUNT,
  DEFAULT_INTERNAL_AMOUNT,
  DEFAULT_MIDDLE_AMOUNT,
} from "Constants/pile-constants";

function Pile ({
  setLoader,
  handleClose,
  edit,
  selectedTool,
  setSelectedTool,
  setIsStopped,
  tab,
  setTab,
  checkRowNums,
  editOrderId,
  editMode,
  deleteTool,
  copyOrderItem,
  setEditTool,
  order,
  editToolId,
}: any) {
  const dispatch = useAppDispatch();
  const stageRef = useRef(null);
  const standardShapes = useAppSelector(state => state.pile.defaultPiles);
  const steelDiameters = useAppSelector(state => state.steelType.steelDiameters);
  const currentOrderPiles = useAppSelector((state) => state.pile.currentOrderPiles);
  const nextOrderPilesPage = useAppSelector((state) => state.pile.orderPilePage);
  const [pileNumber, setPileNumber] = useState("C");
  const [base, setBase] = useState(true);
  const [ring, setRing] = useState(false);
  const [gama, setGama] = useState(false);
  const [ear, setEar] = useState(false);
  const [sideSpilt, setSideSplit] = useState(2);
  const [selectedShape, setSelectedShape] = useState(0);
  const [initiateImage, setInitiateImage] = useState(false);

  const [errorsText, setErrorsText] = useState<any[]>([]);
  const { t } = useTranslation(["common", "tool", "measurements"]);

  const finishButtonRef = useRef(null);
  const addButtonRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const [shapeObj, setShape] = useState({
    ...standardShapes[selectedShape]
  });
  const [orderItem, setOrderItem] = useState({
    quantity: 0,
    production_notes: "",
    position_notes: "",
  });

  const [labelingPoints, setLabelingPoints] = useState({
    l1_start: {x:0, y:0},
    l2_start: {x:0, y:0},
    l3_start: {x:0, y:0},
    l3_end: {x:0, y:0},

    gap1_start: {x:0, y:0},
    gap1_end: {x:0, y:0},

    gap2_start: {x:0, y:0},
    gap2_end: {x:0, y:0},

    ear_start: {x:0, y:0},
    ear_end: {x:0, y:0},
  });


  const [pilesData, setPilesData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [progressBarSelectedShape, setProgressBarSelectedShape] = useState(-1);
  const [isShapeError, setIsShapeError] = useState(false);

  const addError = (newError: any) => {
    if (!errorsText.some((error) => error.errorType === newError.errorType)) {
      setErrorsText((prevErrors) => [...prevErrors, newError]);
    }
  };

  const removeError = (errorType: number) => {
    setErrorsText((prevErrors) =>
      prevErrors.filter((error) => error.errorType !== errorType)
    );
  };

  const setDefaultSplitValues = (split, checkError = false)=>{
    if(split === 2){
      const currentShape = {
        ...shapeObj,
        internal_diameter: shapeObj.internal_diameter?.id
          ? shapeObj.internal_diameter
          : steelDiameters[shapeObj.length_steel_type]?.find(
              (item: any) => item.steel_diameter === DEFAULT_SIDE_DIAMETER
            ),
        external_diameter: shapeObj.external_diameter?.id
          ? shapeObj.external_diameter
          : steelDiameters[shapeObj.length_steel_type]?.find(
              (item: any) => item.steel_diameter === DEFAULT_SIDE_DIAMETER
            ),
        external_amount: shapeObj.external_amount
          ? shapeObj.external_amount
          : DEFAULT_EXTERNAL_AMOUNT,
        internal_amount: shapeObj.internal_amount
          ? shapeObj.internal_amount
          : DEFAULT_INTERNAL_AMOUNT
      };  
      setShape(currentShape);
      if(checkError){
        checkSideAmountErrors(2, currentShape);
      }
    }
    if (split === 3){
      const currentShape = {
        ...shapeObj,
        internal_diameter: shapeObj.internal_diameter?.id
        ? shapeObj.internal_diameter
        : steelDiameters[shapeObj.length_steel_type]?.find(
            (item: any) => item.steel_diameter === DEFAULT_SIDE_DIAMETER
          ),
        external_diameter: shapeObj.external_diameter?.id
          ? shapeObj.external_diameter
          : steelDiameters[shapeObj.length_steel_type]?.find(
              (item: any) => item.steel_diameter === DEFAULT_SIDE_DIAMETER
            ),
        external_amount: shapeObj.external_amount
          ? shapeObj.external_amount
          : DEFAULT_EXTERNAL_AMOUNT,
        internal_amount: shapeObj.internal_amount
          ? shapeObj.internal_amount
          : DEFAULT_INTERNAL_AMOUNT,
        middle_diameter: shapeObj.middle_diameter?.id
        ? shapeObj.middle_diameter
        : steelDiameters[shapeObj.length_steel_type]?.find(
            (item: any) => item.steel_diameter === DEFAULT_SIDE_DIAMETER
          ),
        middle_amount: shapeObj.middle_amount
          ? shapeObj.middle_amount
          : DEFAULT_MIDDLE_AMOUNT,    
      };
      setShape(currentShape);
      if(checkError){
        checkSideAmountErrors(3, currentShape);
      }
    }
  }

  const checkSideAmountErrors = (split, currentShape )=>{
    let isError = false;
    if (
      parseInt(currentShape.external_amount) < 2 ||
      currentShape.external_amount === null
    ) {
      addError({
        errorType: pileErrorTypes.MIN_EXTERNAL_AMOUNT,
        message: t("error_side_min", { ns: "tool" }),
      });
      isError = true;
    } else {
      removeError(pileErrorTypes.MIN_EXTERNAL_AMOUNT);
    }

    if (
      parseInt(currentShape.internal_amount) < 2 ||
      currentShape.internal_amount === null
    ) {
      addError({
        errorType: pileErrorTypes.MIN_INTERNAL_AMOUNT,
        message: t("error_side_min", { ns: "tool" }),
      });
      isError = true;
    } else {
      removeError(pileErrorTypes.MIN_INTERNAL_AMOUNT);
    }
    
    if (split === 3){
      if (
        parseInt(currentShape.middle_amount) < 2 ||
        currentShape.middle_amount === null) 
      {
        addError({
          errorType: pileErrorTypes.MIN_MIDDLE_AMOUNT,
          message: t("error_side_min", { ns: "tool" }),
        });
        isError = true;
      } else {
        removeError(pileErrorTypes.MIN_MIDDLE_AMOUNT);
      }  
    } else {
      removeError(pileErrorTypes.MIN_MIDDLE_AMOUNT);
    }
    return isError;
  }
  

  useEffect(()=>{
    if(!isLoading){
      setDefaultSplitValues(3);
    }
  }, [isLoading])

  useEffect(()=>{
    if (currentOrderPiles) {
      setPilesData(currentOrderPiles);
      if (nextOrderPilesPage !== false) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  }, [currentOrderPiles])

  useEffect(() => {
    if (edit) {
      const ringDiam = getRingDiamObj(selectedTool.tool.ring_diameter);
      const parsedShape = {
        ...selectedTool.tool,
        ring_diameter: ringDiam,
        cover: selectedTool.tool.hole_diameter - selectedTool.tool.cage_diameter
      };
      setShape(parsedShape);
      setOrderItem({
        quantity: selectedTool.quantity,
        production_notes: selectedTool.production_notes,
        position_notes: selectedTool.position_notes,
      });
      setIsLoading(false);
    } else if (standardShapes.length > 0) {
      setShape({...standardShapes[selectedShape]});
      setIsLoading(false);
    }
  }, [
    standardShapes,
    selectedShape,
    edit,
    selectedTool,
    selectedTool.tool,
  ]);

  useEffect(() => {
    if (shapeObj.cage_steel_type && !(steelDiameters[shapeObj.cage_steel_type])) {
      dispatch(fetchSteelDiameters(shapeObj.cage_steel_type));
    }
    if (shapeObj.length_steel_type && !(steelDiameters[shapeObj.length_steel_type])) {
      dispatch(fetchSteelDiameters(shapeObj.length_steel_type));
    }
  }, [shapeObj, steelDiameters, dispatch]);

  useEffect(() => {
    const initalFetch = async () => {
      setLoader(true);
      standardShapes.length === 0 && await dispatch(fetchDefaultsPiles());
      setLoader(false);
    }
    initalFetch();
    setSelectedShape(0);
  }, [dispatch, setLoader, standardShapes.length]);

  async function savePile() {
    if(isShapeError){
      return;
    }
    if (
      parseInt(orderItem.quantity) === 0 ||
      Number.isNaN(parseInt(orderItem.quantity))
    ) {
      addError({
        errorType: pileErrorTypes.QUANTITY,
        message: t("error_cage_amount", { ns: "tool" }),
      });
      return;
    }
    if(!base){
      if (checkSideAmountErrors(sideSpilt, shapeObj)){
        return;
      }
    }
  if (stageRef.current) {
    setInitiateImage(true);
    setIsStopped(false);
    setTimeout(async()=>{
      let previewImg;
      await getHtmlToCanvas(stageRef.current).then((canvas)=>{
        previewImg = (canvas as any).toDataURL({
          mimeType: 'image/png',
          quality: 1,
          pixelRatio: 3,
        });
      })
        let mediaPromise;
        if (edit) {
          mediaPromise = updateMedia(
            selectedTool.tool.preview_image.id,
            previewImg,
            `rebar_preview_${Date.now()}.png`,
            "rebars " + Date.now()
          );
        } else {
          mediaPromise = postMedia(
            previewImg,
            `rebar_preview_${Date.now()}.png`,
            "rebars " + Date.now()
          );
        }
        mediaPromise.then((res) => {
          if (res.data) {
            let newPileShape = {
              ...shapeObj,
              tool_number: pileNumber,
              preview_image: res.data.id,
              base_diameter: shapeObj.base_diameter.id,
              spiral_wire_diameter: shapeObj.spiral_wire_diameter.id,
              external_diameter: shapeObj.external_diameter && shapeObj.external_diameter.id ? shapeObj.external_diameter.id : null,
              middle_diameter: shapeObj.middle_diameter && shapeObj.middle_diameter.id ? shapeObj.middle_diameter.id : null,
              internal_diameter: shapeObj.internal_diameter && shapeObj.internal_diameter.id ? shapeObj.internal_diameter.id : null,
              ring_diameter: shapeObj.ring_diameter && shapeObj.ring_diameter.id ? shapeObj.ring_diameter.id : null,
            };
            let deleteKeys = ['created', 'last_modified', 'cover'];
            if (!ring) deleteKeys = [...deleteKeys, 'ring_diameter', 'ring_quantity'];
            if (!gama) deleteKeys = [...deleteKeys, 'gama_diameter', 'gama_quantity'];
            if (!ear) deleteKeys = [...deleteKeys, 'ear_type', 'ear_length'];
            if (!base) deleteKeys = [...deleteKeys, 'base_diameter', 'base_amount'];
            if (sideSpilt === 2) deleteKeys = [...deleteKeys, 'middle_diameter', 'middle_amount'];
            if (base) deleteKeys = [
              ...deleteKeys,
              'external_diameter',
              'external_amount',
              'middle_diameter',
              'middle_amount',
              'internal_diameter',
              'internal_amount',
            ];
            if (edit) {
              deleteKeys.forEach((k: string) => delete newPileShape[k]);
              dispatch(updatePile(selectedTool.id, newPileShape, orderItem)).then(()=>{
                setTimeout(() => setIsStopped(true), 100);
              })
            } else {
              newPileShape ={
                ...newPileShape,
                type: 1,
                reference_tool: shapeObj.id,
              }
              deleteKeys.push('id');
              deleteKeys.forEach((k: string) => delete newPileShape[k]);
              dispatch(createPile(newPileShape, orderItem, ()=>{}, 0, editOrderId)).then(()=>{
                setTimeout(() => setIsStopped(true), 100);
              })
            }
            if(editToolId >= 0){
              setTimeout(() => {
                handleClose();
              }, 200);
            }
            setDefaultShape();
          }
        });  
    },100)
    }
  }



  const getLengthDiameter = (steelDiameterID: number) => {
    return steelDiameters[shapeObj.length_steel_type].find((item: any) => item.id === steelDiameterID);
  }

  const getSpiralDiameter = (steelDiameterID: number) => {
    return steelDiameters[shapeObj.cage_steel_type].find((item: any) => item.id === steelDiameterID);
  }

  const getPolygonDiameter = (steelDiameterID: number) => {
    return steelDiameters[polygonSteelType].find((item: any) => item.id === steelDiameterID);
  }

  const getRingDiamObj = (ringDiamVal: number) => {
    return steelDiameters[polygonSteelType].find(
      (item: any) => item.steel_diameter === ringDiamVal
    );
  };

  useEffect(
    () => {
      const calculateWeight = () => {

        //gamma pipes are not effecting the unit weights right now
        let lengthsWeight = 0;
        const total_length = (shapeObj.l1_length + shapeObj.l2_length + shapeObj.l3_length) / 100;
        let earWeight = 0;
        if (base) {
          lengthsWeight = total_length * (shapeObj.base_amount || 0) * shapeObj.base_diameter.weight;
          earWeight = ((shapeObj.ear_length || 0) / 100) * (shapeObj.base_amount || 0) * shapeObj.base_diameter.weight;
        } else {
          lengthsWeight += total_length * (shapeObj.external_amount || 0) * (shapeObj.external_diameter.weight || 0);
          lengthsWeight += total_length * (shapeObj.internal_amount || 0) * (shapeObj.internal_diameter.weight || 0);

          earWeight += ((shapeObj.ear_length || 0) / 100) * (shapeObj.external_amount || 0) * (shapeObj.external_diameter.weight || 0);
          earWeight += ((shapeObj.ear_length || 0) / 100) * (shapeObj.internal_amount || 0) * (shapeObj.internal_diameter.weight || 0);

          if (sideSpilt === 3){
            lengthsWeight += total_length * (shapeObj.middle_amount || 0) * (shapeObj.middle_diameter.weight || 0);
            earWeight += ((shapeObj.ear_length || 0) / 100) * (shapeObj.middle_amount || 0) * (shapeObj.middle_diameter.weight || 0);
          }

          //external/internal/middle diameter weights should always be calculated according to the polygon steel types
        }

        const one_hoop_length = shapeObj.cage_diameter * PI;
        const number_of_hoops_l2 = shapeObj.l2_length / shapeObj.l2_gap;
        const number_of_hoops_l3 = shapeObj.l3_length / shapeObj.l3_gap;
        const total_number_of_hoops_length = (number_of_hoops_l2 + number_of_hoops_l3) * one_hoop_length;
        const spiralWeight = (total_number_of_hoops_length / 100) * shapeObj.spiral_wire_diameter.weight;

        const ringsWeight = (shapeObj.ring_quantity || 0) * (one_hoop_length/100) * (shapeObj?.ring_diameter?.weight || 0);

        let unitWeight = lengthsWeight + spiralWeight + earWeight + ringsWeight;

        if (!ear) {
          unitWeight = unitWeight - earWeight;
        }
        if (!ring) {
          unitWeight = unitWeight - ringsWeight;
        }
        setShape((prev: any) => ({...prev, unit_weight: unitWeight.toFixed(2)}));
      }

      if (base && shapeObj.base_diameter && shapeObj.spiral_wire_diameter) {
        calculateWeight();
      } else if (
        shapeObj.external_diameter
        && shapeObj.internal_diameter
        && shapeObj.middle_diameter
      ) {
        calculateWeight();
      }
    },
    [
      base,
      ring,
      ear,
      sideSpilt,
      steelDiameters,
      shapeObj.base_amount,
      shapeObj.base_diameter,
      shapeObj.cage_diameter,
      shapeObj.ear_length,
      shapeObj.external_amount,
      shapeObj.external_diameter,
      shapeObj.internal_amount,
      shapeObj.internal_diameter,
      shapeObj.l1_length,
      shapeObj.l2_gap,
      shapeObj.l2_length,
      shapeObj.l3_gap,
      shapeObj.l3_length,
      shapeObj.middle_amount,
      shapeObj.middle_diameter,
      shapeObj.ring_quantity,
      shapeObj.ring_diameter,
      shapeObj.spiral_wire_diameter
    ]
  );

  const getHtmlToCanvas = (ref: any) => {
    return new Promise((resolve, reject) => {
      html2canvas(ref, { allowTaint: true, useCORS: true, logging: true })
        .then((canvas) => {
          resolve(canvas);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  };


  const setDefaultShape = () => {
    setEditTool(false);
    setProgressBarSelectedShape(-1);
    setSelectedTool({id: 0})
    setErrorsText([]);
    setShape({ ...standardShapes[selectedShape] });
    setShape({
      ...shapeObj,
      internal_amount: DEFAULT_INTERNAL_AMOUNT,
      external_amount: DEFAULT_EXTERNAL_AMOUNT,
      middle_amount: DEFAULT_MIDDLE_AMOUNT,
    })
    setOrderItem({
      quantity: 0,
      production_notes: "",
      position_notes: "",
      row_number: 0,
    });
    setBase(true);
    setSideSplit(1);
    setRing(false);
    setGama(false);
    setEar(false);
    setInitiateImage(false);
  }

  const checkCageLength = (l1, l2, l3, cage_diameter)=>{
    const total = parseInt(l1) + parseInt(l2) + parseInt(l3);
    if (total < CAGE_LEN_MIN){
      addError({
        errorType: pileErrorTypes.MIN_TOTAL_LEN,
        message: t("error_min_total_len", { ns: "tool" }),
      })
      removeError(pileErrorTypes.MAX_TOTAL_LEN)
    }
    else if ( total > CAGE_LEN_MAX ){
      addError({
        errorType: pileErrorTypes.MAX_TOTAL_LEN,
        message: t("error_max_total_len", { ns: "tool" }),
      })
      removeError(pileErrorTypes.MIN_TOTAL_LEN)
    }
    else{
      removeError(pileErrorTypes.MIN_TOTAL_LEN)
      removeError(pileErrorTypes.MAX_TOTAL_LEN)
    }
  }

  return (
    <>
      {isBrowser && (
        <>
          <SideBar tab={tab} setTab={setTab} edit={edit}>
            <PilesSideBar
              shapeObj={shapeObj}
              setShape={setShape}
              base={base}
              setBase={setBase}
              ring={ring}
              setRing={setRing}
              gama={gama}
              setGama={setGama}
              ear={ear}
              setEar={setEar}
              sideSpilt={sideSpilt}
              setSideSplit={setSideSplit}
              selectedShape={selectedShape}
              setSelectedShape={setSelectedShape}
              standardShapes={standardShapes}
              getDiameter={getLengthDiameter}
              edit={edit}
              orderItem={orderItem}
              setOrderItem={setOrderItem}
              savePile={savePile}
              errorsText={errorsText}
              setErrorsText={setErrorsText}
              addError={addError}
              removeError={removeError}
              isShapeError={isShapeError}
              setIsShapeError={setIsShapeError}
              checkCageLength={checkCageLength}
              finishButtonRef={finishButtonRef}
              addButtonRef={addButtonRef}
              setInitiateImage={setInitiateImage}
              checkSideAmountErrors={checkSideAmountErrors}
              setDefaultSplitValues={setDefaultSplitValues}
              getPolygonDiameter={getPolygonDiameter}
            />
          </SideBar>
          {/* <Content
            ear={ear}
            base={base}
            sideSpilt={sideSpilt}
            shapeObj={shapeObj}
            setShape={setShape}
            labelingPoints={labelingPoints}
            setLabelingPoints={setLabelingPoints}
            savePile={savePile}
            orderItem={orderItem}
            setOrderItem={setOrderItem}
            getDiameter={getSpiralDiameter}
            stageRef={stageRef}
            pileNumber={pileNumber}
            setPileNumber={setPileNumber}
          /> */}
          <SketchBody>
            <PileShape
              ear={ear}
              sideSpilt={sideSpilt}
              shapeObj={shapeObj}
              setShape={setShape}
              labelingPoints={labelingPoints}
              setLabelingPoints={setLabelingPoints}
              base={base}
              stageRef={stageRef}
              errorsText={errorsText}
              setErrorsText={setErrorsText}
              addError={addError}
              removeError={removeError}
              isShapeError={isShapeError}
              setIsShapeError={setIsShapeError}
              checkCageLength={checkCageLength}
              isLoading={isLoading}
              isPile={true}
              initiateImage={initiateImage}
            />
          </SketchBody>
          <LastAddedSidebar
            dataLength={pilesData.length}
            hasMore={hasMore}
            setHasMore={setHasMore}
            toolData={pilesData}
            setToolData={setPilesData}
            checkRowNums={checkRowNums}
            editOrderId={editOrderId}
            editMode={editMode}
            saveTool={savePile}
            shapeObj={shapeObj}
            edit={edit}
            finishButtonRef={finishButtonRef}
            addButtonRef={addButtonRef}
            //isShapesError={isShapesError}
            setDefaultShape={setDefaultShape}
            handleClose={handleClose}
            editToolId={editToolId}
          >
            <div
              className="card__list_piles hoveredItems"
              style={editToolId >= 0 ? { marginTop: "40px" } : {}}
            >
              {pilesData.map((item, index) => (
                <PilesCard
                  key={item.id}
                  id={item.id}
                  item={item.tool}
                  orderItem={item}
                  index={index}
                  deleteTool={deleteTool}
                  copyOrderItem={copyOrderItem}
                  setEditTool={setEditTool}
                  setSelectedTool={setSelectedTool}
                  setSelectedShape={setProgressBarSelectedShape}
                  selectedShape={progressBarSelectedShape}
                  setErrorsText={setErrorsText}
                  setInProgress={setInProgress}
                  inProgress={inProgress}
                  editToolId={editToolId}
                  edit={edit}
                />
              ))}
            </div>
          </LastAddedSidebar>
        </>
      )}
      {isMobile && (
        <PileMobileContent
          shapeObj={shapeObj}
          setShape={setShape}
          base={base}
          setBase={setBase}
          ring={ring}
          setRing={setRing}
          gama={gama}
          setGama={setGama}
          ear={ear}
          setEar={setEar}
          labelingPoints={labelingPoints}
          setLabelingPoints={setLabelingPoints}
          orderItem={orderItem}
          setOrderItem={setOrderItem}
          getLengthDiameter={getLengthDiameter}
          getSpiralDiameter={getSpiralDiameter}
          selectedShape={selectedShape}
          setSelectedShape={setSelectedShape}
          standardShapes={standardShapes}
          pileNumber={pileNumber}
          setPileNumber={setPileNumber}
          savePile={savePile}
        />
      )}
    </>
  );
}

export default Pile;
