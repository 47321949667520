import { CONTACTS_ENUM } from "Constants/contacts";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

const Headerdetails = ({ order, rightToLeft }: any) => {
  const { t } = useTranslation(['common', 'pdfOrder','myOrder']);
  return (
    <>
      <div className="bussiness-details">
        <div className="item">
          <p className="label">{t('phone office', {ns: 'pdfOrder'})}:</p>
          <p className="val">{order.business?.phone}</p>
        </div>
        <div className="item">
          <p className="label">{t('mail', {ns: 'pdfOrder'})}:</p>
          <p className="val text-lowercase">{order.business?.mail}</p>
        </div>
        <div className="item">
          <p className="label">{t('address', {ns: 'pdfOrder'})}:</p>
          <p className="val">{order.business?.address + " ," + order.business?.city}</p>
        </div>
        <div className="item">
          <p className="label">{t('document creater', {ns: 'pdfOrder'})}:</p>
          <p className="val">
            {order.created_by &&
              (`${order.created_by.first_name} ${order.created_by.last_name}` || "")}{" "}
            {order.created_by && (order.created_by.phone || "")}
          </p>
        </div>
      </div>
      <div className="divider" style={{ margin: "0 40px" }}></div>
      <p style={{ margin: "0 40px" }}>{t('delivery details', {ns: 'pdfOrder'})}:</p>
      <div className="divider-gray" style={{ margin: "0 40px" }}></div>
      <div className="bussiness-details">
        <div className="item">
          <p className="label">{t('delivery date request', {ns: 'pdfOrder'})}:</p>
          <p className="val-date">
            {order.delivery_date &&
              format(new Date(order.delivery_date), "dd/MM/yyy")}
          </p>
        </div>
        <div className="item">
          <p className="label">{t('site address', {ns: 'pdfOrder'})}:</p>
          <p className="val">{order.project?.project_address_line1} {order.project?.project_city}</p>
        </div>
        <div className="item">
          <p className="label">{t('site manager', {ns: 'myOrder'})}</p>
          <p className="val">
            {order.project?.contacts &&
              <>
                {order.project?.contacts.find(
                  (item: any) => item.role === CONTACTS_ENUM.SITE_MANAGER
                ) &&
                  order.project?.contacts.find(
                    (item: any) => item.role === CONTACTS_ENUM.SITE_MANAGER
                  ).full_name}{" "}
                {order.project?.contacts.find(
                  (item: any) => item.role === CONTACTS_ENUM.SITE_MANAGER
                ) &&
                  order.project?.contacts.find(
                    (item: any) => item.role === CONTACTS_ENUM.SITE_MANAGER
                  ).phone}
              </>
            }
          </p>
        </div>
        <div className="item">
          <p className="label">{t('site man two', {ns: 'pdfOrder'})}</p>
          <p className="val">
          {order.project?.contacts &&
              <>
                {order.project?.contacts.find(
                  (item: any) => item.role === CONTACTS_ENUM.SITE_MAN
                ) &&
                  order.project?.contacts.find(
                    (item: any) => item.role === CONTACTS_ENUM.SITE_MAN
                  ).full_name}{" "}
                {order.project?.contacts.find(
                  (item: any) => item.role === CONTACTS_ENUM.SITE_MAN
                ) &&
                  order.project?.contacts.find(
                    (item: any) => item.role === CONTACTS_ENUM.SITE_MAN
                  ).phone}
              </>
}
          </p>
        </div>
      </div>
      <div className="divider-gray" style={{ margin: "0 40px" }}></div>
      <p style={{ margin: "5px 40px" }}>{t('arrival notes', {ns: 'pdfOrder'})}: {order.project?.driver_notes}</p>
      <div className="divider" style={{ margin: "0 40px" }}></div>
      {order.order_notes &&
        <>
          <p style={{ margin: "5px 40px" }}>{t('order notes', {ns: 'pdfOrder'})}: {order.order_notes}</p>
          <div className="divider" style={{ margin: "0 40px" }}></div>
        </>
      }
      <div className="names">
        <div className="item">
          <p className={"label " + (rightToLeft ? "item__ml":"item__mr")}>{t("order name", { ns: "pdfOrder" })}:</p>
          <p className="val">{order?.name}</p>
        </div>
        <div className="item">
          <p className={"label " + (rightToLeft ? "item__ml":"item__mr")}>{t("project name", { ns: "pdfOrder" })}:</p>
          <p className="val">{order.project?.name}</p>
        </div>
      </div>
      <div className="divider" style={{ margin: "0 40px" }}></div>
    </>
  );
};

export default Headerdetails;
