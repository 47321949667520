import Request from "./index";

export const getSteelTypes = () =>
  Request.get('steel-types/');

export const updateSteelType = (steelTypeID: number, data: any) =>
  Request.patch(`steel-types/${steelTypeID}/`, data);

export const getSteelDiameters = (steelID: number) =>
  Request.get(`steel/weights/${steelID}/list/`);

export const createSteelType = (data: any) =>
  Request.post('steel-types/', data);

export const createSteelDiameter = (data: any) =>
  Request.post('steel/diameter/', data);

export const updateSteelDiameterWeight = (steelWeightID: number, data: any) =>
  Request.patch(`steel/weights/${steelWeightID}/`, data);

export const deleteSteelDiameter = (steelDiameterID: number) =>
  Request.remove(`steel/diameter/${steelDiameterID}/`);

export const deleteSteelType = (steelTypeID: number) =>
  Request.remove(`steel-types/${steelTypeID}/`);
