import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SiteTableRow from "./sitetablerow";
import { isBrowser, isMobile } from "react-device-detect";
import { useAppSelector } from "Redux/hooks";
import { SitesFilterTypes } from "Constants/sites";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { googleMapsAPIKey } from "Services/location";
import GoogleMapReact from "google-map-react";
import { ReactComponent as LocationIcon } from "../../assets/img/icon/location.svg";

const Marker = (props: any) => {
  return <LocationIcon style={{ height: "30px" }} />;
};

const MapComponent = ({ rightToLeft, center, isMarkerShown, marks }: any) => (
  <GoogleMapReact
    bootstrapURLKeys={{
      key: googleMapsAPIKey,
      language: rightToLeft ? "he" : "en",
    }}
    defaultZoom={8}
    center={center}
    options={{
      panControl: true,
      mapTypeControl: false,
      scrollwheel: false,
    }}
  >
    {isMarkerShown &&
      marks.map((item: any, index: number) => (
        <Marker lat={item.latitude} lng={item.longitude} />
      ))}
  </GoogleMapReact>
);

function SiteTable({ openModal, setActiveProject, handleEditProject }: any) {
  const userProjects = useAppSelector((state) => state.project.userProjects);
  const [mobileFilter, setMobileFilter] = useState(SitesFilterTypes.quanity);
  const { t } = useTranslation(["common", "myOrder"]);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  const [mapProps, setMapProps] = useState({
    center: { lat: 32.04806, lng: 34.7520038 },
    isMarkerShown: false,
    marks: [] as any,
  });

  useEffect(() => {
    const results = userProjects
      .filter(
        (x) => typeof x.latitude === "number" && typeof x.longitude === "number"
      )
      .map((x) => ({
        latitude: x.latitude,
        longitude: x.longitude,
      }));

    if (results.length > 0) {
      const max_latitude = results.reduce(function (prev, current) {
        return prev.latitude > current.latitude
          ? prev.latitude
          : current.latitude;
      }, results[0].latitude);
      const min_latitude = results.reduce(function (prev, current) {
        return prev.latitude < current.latitude
          ? prev.latitude
          : current.latitude;
      }, results[0].latitude);
      const max_longitude = results.reduce(function (prev, current) {
        return prev.longitude > current.longitude
          ? prev.longitude
          : current.longitude;
      }, results[0].longitude);
      const min_longitude = results.reduce(function (prev, current) {
        return prev.longitude < current.longitude
          ? prev.longitude
          : current.longitude;
      }, results[0].longitude);
      const center = {
        lat: Number((max_latitude + min_latitude) / 2),
        lng: Number((max_longitude + min_longitude) / 2),
      };

      setMapProps({
        center,
        isMarkerShown: true,
        marks: results,
      });
    }
  }, [userProjects]);

  return (
    <>
      {isBrowser && (
        <section id="myOrderTable" className="tableSection">
          <div className="container py-4 border__bottom">
            <div className="row">
              {/* <!--------table header--------> */}
              <div className="tableHeader d-flex col-12 order-1 my-3">
                <h6
                  className={
                    "text-uppercase text-dark-500 mb-0 " +
                    (rightToLeft ? "ml-3" : "mr-3")
                  }
                >
                  {t("my sites", { ns: "myOrder" })}
                </h6>
                <div
                  className="right d-flex"
                  style={{ cursor: "pointer" }}
                  onClick={openModal}
                >
                  <span
                    className={"icon__wrap " + (rightToLeft ? "ml-3" : "mr-3")}
                  >
                    <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
                  </span>
                  <p className="para text-uppercase">
                    {t("add new site", { ns: "myOrder" })}
                  </p>
                </div>
              </div>
              {/* <!-- table calender column  --> */}
              <div className="map__container col-12 col-xl-3 order-0 order-xl-3">
                <div className="map text-right" style={{ height: "406px" }}>
                  <MapComponent rightToLeft={rightToLeft} {...mapProps} />
                </div>
              </div>
              {/* <!-- table column  --> */}
              <div className="col-12 col-xl-9 table__column mt-xl-0 order-2">
                {/* <!-------- table section  --------> */}
                <table className="table table-striped custom-table-alignment scrollable-table">
                  {/* <!-- table head  --> */}
                  <thead style={{ display: "block" }}>
                    <tr
                      style={{
                        height: "59px",
                        display: "grid",
                        gridTemplateColumns:
                          "0.3fr 1fr 2.2fr 1fr 1fr 1fr 0.6fr",
                      }}
                    >
                      <td></td>
                      <td>
                        <h6 className="text-uppercase text-dark-500 mb-0">
                          {t("site name", { ns: "myOrder" })}
                        </h6>
                      </td>
                      <td>
                        <h6 className="text-uppercase text-dark-500 mb-0">
                          {t("address", { ns: "myOrder" })}
                        </h6>
                      </td>
                      <td>
                        <h6 className="text-uppercase text-dark-500 mb-0 ml-3">
                          {t("site manager", { ns: "myOrder" })}
                        </h6>
                      </td>
                      <td>
                        <h6 className="text-uppercase text-dark-500 mb-0">
                          {t("contact on site", { ns: "myOrder" })}
                        </h6>
                      </td>
                      <td>
                        <h6 className="text-uppercase text-dark-500 mb-0">
                          {t("next delivery", { ns: "myOrder" })}
                        </h6>
                      </td>
                      <td
                        style={
                          rightToLeft
                            ? { borderRight: "2px solid #f3f3f3" }
                            : {}
                        }
                      ></td>
                    </tr>
                  </thead>
                  {/* <!-- tabody  --> */}
                  <tbody
                    style={
                      userProjects.length > 7
                        ? {
                            height: "354px",
                            overflow: "auto",
                            display: "block",
                          }
                        : {}
                    }
                  >
                    {/* <!-- table row  --> */}
                    {userProjects.map((item, index) => (
                      <SiteTableRow
                        key={index}
                        number={index + 1}
                        project={item}
                        setActiveProject={setActiveProject}
                        handleEditProject={handleEditProject}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      )}
      {isMobile && (
        <div className="myOrderTableSm mt-4 mt-md-5">
          {/* <!-- ----------------table header---------------  --> */}
          <div className="btn__icon__head d-flex justify-content-between border__bottom py-3 tableHeader">
            {/* <!-- icon and heading  --> */}
            <div className="icon__head d-flex align-items-center justify-content-center">
              <h3 className="big__para text-uppercase mb-0">
                {t("my sites", { ns: "myOrder" })}
              </h3>
            </div>
            {/* <!-- buttons  --> */}
            <button
              onClick={openModal}
              className="btn bg-dark-500 text-dark d-flex justify-content-between align-items-center cus__btn"
            >
              <span className="icon__wrap">
                <svg
                  className="svg-inline--fa fa-plus fa-w-14"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="plus"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                  ></path>
                </svg>
              </span>
              <p className="ml-2  text-uppercase">
                {t("add new", { ns: "myOrder" })}
              </p>
            </button>
          </div>
          {/* <!-- -----------------table control ------------- --> */}
          <div className="icon__form d-flex justify-content-between align-items-start py-2 py-sm-3 table__control__panel">
            {/* <!-- left section  --> */}
            <div className="left__sec d-flex align-items-center">
              {/* <!-- icon  --> */}
              <div className={"icon__wrap " + (rightToLeft ? "ml-3" : "mr-3")}>
                <FontAwesomeIcon icon="filter"></FontAwesomeIcon>
              </div>
              {/* <!-- para  --> */}
              <p
                onClick={() => setMobileFilter(SitesFilterTypes.quanity)}
                className={
                  "para text-capitalize" +
                  (mobileFilter === SitesFilterTypes.quanity &&
                    "text-dark-500 active")
                }
              >
                quantity of orders
              </p>
            </div>
            {/* <!-- right section  --> */}
            <p
              onClick={() => setMobileFilter(SitesFilterTypes.delivery)}
              className={
                "para text-capitalize" +
                (mobileFilter === SitesFilterTypes.delivery &&
                  "text-dark-500 active")
              }
            >
              next delivery
            </p>
            <p
              onClick={() => setMobileFilter(SitesFilterTypes.weight)}
              className={
                "para text-capitalize " +
                (rightToLeft
                  ? "ml-2 ml-sm-3 ml-md-4"
                  : "mr-2 mr-sm-3 mr-md-4") +
                +(
                  mobileFilter === SitesFilterTypes.weight &&
                  "text-dark-500 active"
                )
              }
            >
              total weights
            </p>
          </div>
          {/* <!-- ---------------main table ------------ --> */}
          <table className="table">
            <thead>
              <tr>
                <th></th>
                <th>
                  <p className="para text-uppercase text-dark">
                    {t("name", { ns: "myOrder" })}
                  </p>
                </th>
                <th>
                  <p className="para text-uppercase text-dark">
                    {mobileFilter === SitesFilterTypes.quanity
                      ? "quantity of orders"
                      : mobileFilter === SitesFilterTypes.delivery
                      ? "Next Delivery"
                      : "Total Weights"}
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {/* <!-- table row  --> */}
              {userProjects.map((item, index) => (
                <SiteTableRow
                  key={index}
                  number={index + 1}
                  project={item}
                  setActiveProject={setActiveProject}
                  handleEditProject={handleEditProject}
                  mobileFilter={mobileFilter}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

export default SiteTable;
