import { Modal } from "react-bootstrap";

function ResponseMsgModal({ ...props }) {
  const { responseStatus, responseMsg } = props;
  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <section
          className="d-flex flex-column p-3 rounded custom__modal"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="custom__child">
            <div className="delete__title pb-md-4 pb-2 pt-4">
              <p style={{ color: responseStatus.color }}>
                {responseStatus.msg}
              </p>
              <p>{responseMsg.msg}</p>
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
}

export default ResponseMsgModal;
