import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import { getRecentSupplierEmails } from "Services/suppliers";
import "../index.scss";

function RecipientEmail({ ...props }) {
  const { t } = useTranslation(["emailOrder", "common"]);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  const [displayList, setDisplayList] = useState(false);
  const [recentEmails, setRecentEmails] = useState([]);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const {
    show,
    onHide,
    recipientEmail,
    setRecipientEmail,
    onShareHandler,
    orderShareId,
  } = props;

  const shareDuplicate = () => {
    if (validateEmail()) {
      onShareHandler(orderShareId, recipientEmail);
      onHide();
    } else {
      setIsValidEmail(false);
    }
  };

  const handleSelectEmail = (event) => {
    setRecipientEmail(event.target.textContent);
    setDisplayList(false);
    // do something with selected email
  };

  const validateEmail = (email) => {
    return recipientEmail.match(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    );
  };

  useEffect(() => {
    getRecentSupplierEmails(recipientEmail).then((res) => {
      setRecentEmails(res.data.emails);
    });
    if (recentEmails.includes(recipientEmail)) {
      setDisplayList(false);
    } else if (recipientEmail.length > 0) {
      setDisplayList(true);
    }
    if (validateEmail) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  }, [recipientEmail]);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        className="send_supplier_email_popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <>
          <div className="container send_email_container">
            <div className="send_email_content">
              <div className="email_input_container">
                <input
                  type="text"
                  value={recipientEmail}
                  className={
                    (isValidEmail
                      ? "send_email_input"
                      : "send_email_input_empty") +
                    (displayList ? "_list_open" : "")
                  }
                  onChange={({ target }) => {
                    setRecipientEmail(target.value);
                  }}
                  onClick={() => {
                    setDisplayList(true);
                  }}
                  onBlur={() => setDisplayList(false)}
                  placeholder={t("email input placeholder", {
                    ns: "emailOrder",
                  })}
                />
                {displayList && (
                  <div className="recent_email_list">
                    {recentEmails?.slice(0, 4).map((email, index) => (
                      <div
                        key={index}
                        className={`recent_email_option`}
                        onMouseDown={handleSelectEmail}
                      >
                        {email}
                      </div>
                    ))}
                    {/* {filteredEmails.length === 0 && (
                            <div className="no_results">No recent emails found</div>
                          )} */}
                  </div>
                )}
              </div>

              <div
                className="order_share_popup_buttons"
                style={rightToLeft ? { flexDirection: "row-reverse" } : {}}
              >
                <button
                  className="send_email_button "
                  style={{ color: "#AFB3B9", borderColor: "#AFB3B9" }}
                  onClick={onHide}
                >
                  {t("cancel", { ns: "common" })}
                </button>

                <button className="send_email_button " onClick={shareDuplicate}>
                  {t("share duplicate", { ns: "emailOrder" })}
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}

export default RecipientEmail;
