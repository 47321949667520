import "./index.styled.scss";
import { useTranslation } from "react-i18next";
import reloadIcon from "Assets/img/blocks/reload.svg";

const SketchBody = (props: any) => {
  const { children, rebarShapeMode, resetFreeFormShape, edit } = props;
  const { t } = useTranslation(["tool"]);

  return (
    <div className="sketch-body">
      <div className="d-flex align-items-center justify-content-between">
        <p>
          {rebarShapeMode === "FREE_FORM_SHAPE"
            ? t("drawing", { ns: "tool" })
            : t("preview", { ns: "tool" })}
        </p>
        {(rebarShapeMode === "FREE_FORM_SHAPE" && (!edit)) ? (
          <button className="refresh_button" onClick={resetFreeFormShape}>
            <img src={reloadIcon} alt="reload button" />
            <span className="reload_font pl-1">{t("refresh", { ns: "tool" })}</span>
          </button>
        ) : (
          <></>
        )}
      </div>
      <div
        className="sketch"
        style={{ padding: children.props?.isPile ? "60px" : "" }}
      >
        {children}
      </div>
    </div>
  );
};

export default SketchBody;
