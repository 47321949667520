import React, {useEffect, useState} from 'react'
import plus from "../../../assets/img/ticket__create/plus.svg"
import minus from "../../../assets/img/ticket__create/minus.svg"
import plus_blue from "../../../assets/img/ticket__create/plus_blue.svg"
import minus_blue from "../../../assets/img/ticket__create/minus_blue.svg"
import './index.scss'

function TicketCounter({itemId, selectedRowCount, setSelectedRowCount}) {
    
    const [count, setCount] = useState(selectedRowCount.find((value) => value?.itemId === itemId)?.countt || 1);
    const [showIncrement, setShowIncrement] = useState(false);
    const [showDecrement, setShowDecrement] = useState(false);
    const [showCount, setShowCount] = useState(true);
    const [showButtons, setShowButtons] = useState(false);

    const [hoverPlus, setHoverPlus] = useState(false);
    const [hoverMinus, setHoverMinus] = useState(false);

    const updateCount = (countt) => {
        let newCount = [...selectedRowCount];

        const newObj = {itemId, countt}

        if (!newCount.find((value) => value?.itemId === itemId)) {
            newCount = newCount.concat(newObj);
        }
        else{
            newCount = selectedRowCount.map((value)=>{
                if (value?.itemId === itemId) {
                  return newObj;
                } else {
                  return value;
                }
              });    
        }

        setSelectedRowCount(newCount);
    }
    
    useEffect(() => {
        if (count > 0) {
          setShowDecrement(true);
          setShowIncrement(true);
          setShowCount(true);
        } else {
          setShowDecrement(false);
          setShowIncrement(false);
          setShowCount(false);
        }
      }, [count]);
      
      useEffect(() => {
        setCount(selectedRowCount.find((value) => value?.itemId === itemId)?.countt || 0);
      }, [selectedRowCount]);

      
    return (
        <div
            className='d-flex justify-content-around'
            onMouseEnter={() => {setShowIncrement(true); setShowButtons(true)}}
            onMouseLeave={() => {
              count === 0 && setShowIncrement(false);
              setShowButtons(false)}}
        >
            <div 
            className="counter_box_decrement"
            onClick={() => {
                updateCount(count -1)
                setCount(prevCount => prevCount - 1)
                }}
                onMouseEnter={() => {
                  setHoverMinus(true);
                }}
                onMouseLeave={() => {
                  setHoverMinus(false);                  
                }}                
            style={{visibility: showDecrement && showButtons ? "visible" : "hidden"}}
            >
                <img src={ hoverMinus ? minus_blue : minus} alt="-"
                    style={{width: "9px"}} />

            </div>

            <div 
              className="counter_box_count"
              style={{borderColor: count > 0 ? "#00B38F" : "#AFB3B9"}}
              onClick={() => {if (count === 0) {
                setCount(1);
                updateCount(1);
              }}}
            >
                <p 
                    className='ticket_count'
                    style={{ 
                        visibility: showCount ? "visible" : "hidden",
                      }}
                      >
                        {count}
                </p>
            </div>

            <div 
                className="counter_box_increment"
                style={{visibility: showIncrement && showButtons ? "visible" : "hidden"}}
                onClick={() => {
                    updateCount(count + 1)
                    setCount(prevCount => prevCount + 1)
                    }}
                    onMouseEnter={() => {
                      setHoverPlus(true);
                    }}
                    onMouseLeave={() => {
                      setHoverPlus(false);
                    }}
                                    >
                    <img src={ hoverPlus ? plus_blue : plus} alt="+"
                    style={{width: "9px"}} />

            </div>
        </div>
    )
}

export default TicketCounter
