import React, { useEffect, useState, useRef, useMemo } from "react";
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";

import cage_1 from "Assets/img/cages/cage_1.svg";
import cage_2 from "Assets/img/cages/cage_2.svg";
import split_2 from "Assets/img/cages/split_2.svg";
import split_3 from "Assets/img/cages/split_3.svg";
import spiral_1 from "Assets/img/cages/spiral_1.svg";
import spiral_2 from "Assets/img/cages/spiral_2.svg";
import concrete_cage from "Assets/img/cages/concrete_cage.svg";
import horizontal_rebars from "Assets/img/cages/horizontal_rebars.svg";
import add from "Assets/img/add_note.svg";
import remove from "Assets/img/remove_note.svg";
import diameter_cage from "Assets/img/cages/diameter_cage.svg";
import lenght_cage from "Assets/img/cages/length_cage.svg";
import error_sign from "Assets/img/error_sign.svg";

import { commaSeparated } from "Constants/general";
import {
  pileErrorTypes,
  D_MIN,
  D_MAX,
  REBAR_MIN_AMOUNT,
  pileSidebarInput,
  CAGE_DIAMETER_ERRORS,
  HOLE_DIAMETER_ERRORS,
  BASE_AMOUNT_ERRORS,
  pileGapOptions,
  L1_MAX,
  L1_MIN,
  L2_MAX,
  L2_MIN,
  L3_MAX,
  L3_MIN,
  pileSidesDiameterOptions,
  pileGamaAmountOptions,
  polygonSteelType,
} from "Constants/pile-constants";

import "./index.scss";

function SideBar({
  shapeObj,
  setShape,
  base,
  setBase,
  ring,
  setRing,
  gama,
  setGama,
  ear,
  setEar,
  sideSpilt,
  setSideSplit,
  selectedShape,
  setSelectedShape,
  standardShapes,
  getDiameter,
  edit,
  orderItem,
  setOrderItem,
  savePile,
  errorsText,
  setErrorsText,
  addError,
  removeError,
  isShapeError,
  setIsShapeError,
  checkCageLength,
  finishButtonRef,
  addButtonRef,
  setInitiateImage,
  checkSideAmountErrors,
  setDefaultSplitValues,
  getPolygonDiameter,
}: any) {
  const steelDiameters = useAppSelector(
    (state) => state.steelType.steelDiameters
  );
  const { t } = useTranslation(["common", "tool", "measurements"]);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  const [earCardinal, setEarCardinal] = useState(true);
  const steelTypes = useAppSelector((state) => state.steelType.steelTypes);

  const [showNote, setShowNote] = useState(false);
  const siderBarRefs = useRef<any[]>([]);

  const editModeClass = useMemo(() => {
    if (edit) {
      return "_updated";
    } else return "";
  }, [edit]);
  
  useEffect(() => {
    siderBarRefs.current[pileSidebarInput.CAGE_NAME].focus();
  }, []);
  const handleCageTypeToggle = (is_base) => {
    setBase(is_base);
    if (is_base) {
      setBase(true);
      setShape({
        ...shapeObj,
        base_amount: shapeObj.base_amount || 16,
      });
      siderBarRefs.current[pileSidebarInput.CAGE_NAME].focus();
      removeError(pileErrorTypes.MIN_EXTERNAL_AMOUNT);
      removeError(pileErrorTypes.MIN_MIDDLE_AMOUNT);
      removeError(pileErrorTypes.MIN_INTERNAL_AMOUNT);
    } else {
      setBase(false);
      setSideSplit(2);
      setDefaultSplitValues(2, true);
      siderBarRefs.current[pileSidebarInput.CAGE_NAME].focus();
    }
  };

  const handleEarTypeToggle = (is_cardinal) => {
    setEarCardinal(is_cardinal);
    if (is_cardinal) {
      setShape({
        ...shapeObj,
        ear_type: "1",
      });
    } else {
      setShape({
        ...shapeObj,
        ear_type: "2",
      });
    }
  };

  const addUpdatedClass = (element_index) => {
    const element = siderBarRefs.current[element_index];
    if (element) {
      const className = element.className;
      if (!className.includes("_updated")) {
        element.classList.remove("cages_sidebar_input");
        element.classList.add("cages_sidebar_input_updated");
      }
    }
  };

  const handleFocus = () => {
    if (isShapeError) {
      addButtonRef.current.classList.add("add_btn_error");
      addButtonRef.current.style.border = "2px solid #CD2017";
    } else {
      addButtonRef.current.classList.remove("add_btn_error");
      addButtonRef.current.style.border = "2px solid #00B38F";
    }
  };

  useEffect(() => {
    if (
      ring &&
      shapeObj.ring_quantity === null &&
      shapeObj.ring_diameter === null
    ) {
      setShape({
        ...shapeObj,
        ring_quantity: Math.floor(
          (parseInt(shapeObj.l2_length) + parseInt(shapeObj.l3_length)) / 100 +
            1
        ),
        ring_diameter: steelDiameters[
          polygonSteelType
        ]?.find(
          (item: any) =>
            item.steel_diameter ===
            12
        )
      });
    }
  }, [ring]);

  useEffect(() => {
    if (ear && shapeObj.ear_type === null && shapeObj.ear_length === null) {
      setShape({
        ...shapeObj,
        ear_type: "1",
        ear_length: "20",
      });
    }
  }, [ear]);

  useEffect(() => {
    if (
      gama &&
      shapeObj.gama_quantity === null &&
      shapeObj.gama_diameter === null
    ) {
      setShape({
        ...shapeObj,
        gama_quantity: "3",
        gama_diameter: "2",
      });
    }
  }, [gama]);

  useEffect(() => {
    setRing(false);
    setEar(false);
    setGama(false);
    setShowNote(false);
    setBase(true);

    if (shapeObj.ring_diameter !== null) {
      setRing(true);
    }
    if (shapeObj.ear_length !== null) {
      setEar(true);
    }
    if (shapeObj.gama_diameter !== null) {
      setGama(true);
    }
    if (orderItem.production_notes.length > 0) {
      setShowNote(true);
    }

    if (shapeObj.middle_amount !== null) {
      setBase(false);
      setSideSplit(3);
      setDefaultSplitValues(3);
    } else if (shapeObj.internal_amount !== null) {
      setBase(false);
      checkSideAmountErrors(2, shapeObj);
      setSideSplit(2);
    }
    else{
      setBase(true);
      setSideSplit(2);
    }
    siderBarRefs.current[pileSidebarInput.CAGE_NAME].focus();

    // siderBarRefs.current.forEach((element) => {
    //   if (element instanceof HTMLElement) {
    //     element.className = element.className.replace("_updated", "");
    //   }
    // });

    setErrorsText([]);
  }, [shapeObj.id]);

  useEffect(() => {
    if (base) {
      if (shapeObj.base_amount < shapeObj.cage_diameter / 10) {
        addError({
          errorType: pileErrorTypes.REBAR_AMOUNT_CAGE,
          message: t("error_rebar_amount_to_cage", {
            ns: "tool",
          }),
        });
      } else {
        removeError(pileErrorTypes.REBAR_AMOUNT_CAGE);
      }

      if (shapeObj.base_amount > (shapeObj.cage_diameter * Math.PI) / 5) {
        addError({
          errorType: pileErrorTypes.MAX_REBAR_AMOUNT,
          message: t("max_error_rebar_amount", { ns: "tool" }),
        });
        removeError(pileErrorTypes.MIN_REBAR_AMOUNT);
      } else {
        removeError(pileErrorTypes.MAX_REBAR_AMOUNT);
      }
    } else {
      removeError(pileErrorTypes.MAX_REBAR_AMOUNT);
      removeError(pileErrorTypes.REBAR_AMOUNT_CAGE);
    }
  }, [base]);
  return (
    <>
      {
        <div className="left" style={{ overflowY: "scroll", height: "710px" }}>
          <div className="sidebar_body">
            <div className="sidebar_container cage_type">
              <h1 className="cages_sidebar_label mb-0">
                {t("cage type", { ns: "tool" })}
              </h1>
              <div className="cage_type_select">
                <h5
                  className={
                    "text-lowercase " +
                    (rightToLeft
                      ? "cage_type_toggle_left"
                      : "cage_type_toggle_right")
                  }
                  style={{
                    backgroundColor: base ? "#5F6674" : "#D7D9DC",
                    fontSize: rightToLeft ? "13.5px" : "10.5px",
                  }}
                  onClick={() => {
                    handleCageTypeToggle(true);
                  }}
                >
                  {t("base", { ns: "common" })}
                </h5>

                <h5
                  className={
                    "text-lowercase " +
                    (rightToLeft
                      ? "cage_type_toggle_right"
                      : "cage_type_toggle_left")
                  }
                  style={{
                    backgroundColor: base ? "#D7D9DC" : "#5F6674",
                    fontSize: rightToLeft ? "13.5px" : "10.5px",
                  }}
                  onClick={() => {
                    handleCageTypeToggle(false);
                  }}
                >
                  {t("side", { ns: "common" })}
                </h5>
              </div>
            </div>

            {!base && (
              <>
                <div className="sidebar_container cage_type">
                  <h1 className="cages_sidebar_label mb-0">
                    {t("split", { ns: "tool" })}
                  </h1>
                  <div className="cage_type_select">
                    <h5
                      className={
                        "text-lowercase " +
                        (rightToLeft
                          ? "cage_type_toggle_left"
                          : "cage_type_toggle_right")
                      }
                      style={{
                        backgroundColor:
                          sideSpilt === 2 ? "#5F6674" : "#D7D9DC",
                        fontSize: rightToLeft ? "13.5px" : "10.5px",
                      }}
                      onClick={() => {
                        setDefaultSplitValues(2, true);
                        setSideSplit(2);
                        siderBarRefs.current[
                          pileSidebarInput.CAGE_NAME
                        ].focus();
                      }}
                    >
                      {t("2", { ns: "common" })}
                    </h5>

                    <h5
                      className={
                        "text-lowercase " +
                        (rightToLeft
                          ? "cage_type_toggle_right"
                          : "cage_type_toggle_left")
                      }
                      style={{
                        backgroundColor:
                          sideSpilt === 2 ? "#D7D9DC" : "#5F6674",
                        fontSize: rightToLeft ? "13.5px" : "10.5px",
                      }}
                      onClick={() => {
                        setDefaultSplitValues(3, true);
                        setSideSplit(3);
                        siderBarRefs.current[
                          pileSidebarInput.CAGE_NAME
                        ].focus();
                      }}
                    >
                      {t("3", { ns: "common" })}
                    </h5>
                  </div>
                </div>

                {sideSpilt === 2 ? (
                  <div
                    className={
                      "cages_sidebar_container cage_section_1 d-flex align-items-center justify-content-"
                    }
                    style={{
                      borderBottom: "1px solid #D9D9D9",
                      height: "148px",
                    }}
                  >
                    <div className={"input_split_2_internal_amount" + (rightToLeft ? "_heb" : "")}>
                      <img
                        className={"label_input_2"}
                        src={lenght_cage}
                        alt=""
                      />
                      <input
                        className={
                          "form-control form-control-sm  cages_sidebar_input" +
                          editModeClass +
                          (errorsText.some(
                            (error) =>
                              error.errorType ===
                              pileErrorTypes.MIN_EXTERNAL_AMOUNT
                          )
                            ? "_error"
                            : "")
                        }
                        type="number"
                        min={0}
                        style={{ width: "60px" }}
                        onFocus={(e) => e.target.select()}
                        value={shapeObj.external_amount || ""}
                        onChange={(e) => {
                          const val = parseInt(e.target.value);
                          setShape({
                            ...shapeObj,
                            external_amount: val || null,
                          });

                          if (
                            parseInt(e.target.value) < 2 ||
                            e.target.value === ""
                          ) {
                            addError({
                              errorType: pileErrorTypes.MIN_EXTERNAL_AMOUNT,
                              message: t("error_side_min", { ns: "tool" }),
                            });
                          } else {
                            removeError(pileErrorTypes.MIN_EXTERNAL_AMOUNT);
                          }
                        }}
                        onBlur={() =>
                          addUpdatedClass(pileSidebarInput.EXTERNAL_AMOUNT)
                        }
                        ref={(el) => {
                          if (el)
                            siderBarRefs.current[
                              pileSidebarInput.EXTERNAL_AMOUNT
                            ] = el;
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp" || e.key === "ArrowLeft") {
                            e.preventDefault();
                          } else if (
                            e.key === "ArrowRight" ||
                            e.key === "Enter"
                          ) {
                            if (e.key === "ArrowRight") {
                              e.preventDefault();
                            }
                            siderBarRefs.current[
                              pileSidebarInput.EXTERNAL_DIAMETER
                            ].focus();
                          } else if (e.key === "ArrowDown") {
                            e.preventDefault();
                            if (sideSpilt === 2) {
                              siderBarRefs.current[
                                pileSidebarInput.EXTERNAL_AMOUNT
                              ].focus();
                            } else {
                              siderBarRefs.current[
                                pileSidebarInput.MIDDLE_AMOUNT
                              ].focus();
                            }
                          }
                        }}
                      />
                    </div>
                    <div className={"input_split_2_internal_diameter"+ (rightToLeft ? "_heb" : "")}>
                      <img
                        className={"label_input_2"}
                        src={diameter_cage}
                        alt=""
                      />
                      <select
                        className={
                          "form-control form-control-sm  cages_sidebar_input" +
                          editModeClass
                        }
                        style={{ width: "60px", paddingRight: "30px" }}
                        value={shapeObj?.external_diameter?.id || ""}
                        onChange={(e) => {
                          const _diameter = getDiameter(
                            parseInt(e.target.value)
                          );
                          if (_diameter) {
                            setShape({
                              ...shapeObj,
                              external_diameter: _diameter,
                            });
                          }
                        }}
                        onBlur={() =>
                          addUpdatedClass(pileSidebarInput.EXTERNAL_DIAMETER)
                        }
                        ref={(el) => {
                          if (el)
                            siderBarRefs.current[
                              pileSidebarInput.EXTERNAL_DIAMETER
                            ] = el;
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp") {
                            e.preventDefault();
                          } else if (
                            e.key === "ArrowRight" ||
                            e.key === "Enter"
                          ) {
                            if (e.key === "ArrowRight") {
                              e.preventDefault();
                            }
                            if (sideSpilt === 2) {
                              siderBarRefs.current[
                                pileSidebarInput.INTERNAL_AMOUNT
                              ].focus();
                            } else {
                              siderBarRefs.current[
                                pileSidebarInput.MIDDLE_AMOUNT
                              ].focus();
                            }
                          } else if (e.key === "ArrowDown") {
                            e.preventDefault();
                            if (sideSpilt === 2) {
                              siderBarRefs.current[
                                pileSidebarInput.INTERNAL_DIAMETER
                              ].focus();
                            } else {
                              siderBarRefs.current[
                                pileSidebarInput.MIDDLE_AMOUNT
                              ].focus();
                            }
                          } else if (e.key === "ArrowLeft") {
                            e.preventDefault();
                            siderBarRefs.current[
                              pileSidebarInput.EXTERNAL_AMOUNT
                            ].focus();
                          }
                        }}
                      >
                        {shapeObj.length_steel_type in steelDiameters &&
                          steelDiameters[shapeObj.length_steel_type].map(
                            (reDi: any, index: number) =>
                              reDi.steel_diameter >= 12 &&
                              reDi.steel_diameter <= 36 && (
                                <option
                                  key={"external-diameter-options-" + index}
                                  value={reDi.id}
                                >
                                  {parseFloat(reDi?.steel_diameter)}
                                </option>
                              )
                          )}
                      </select>
                    </div>
                    <div>
                      <input
                        className={
                          "form-control form-control-sm cages_sidebar_input" +
                          editModeClass +
                          (errorsText.some(
                            (error) =>
                              error.errorType ===
                              pileErrorTypes.MIN_INTERNAL_AMOUNT
                          )
                            ? "_error"
                            : "")
                            + (rightToLeft ? " input_split_2_external_amount_heb" : " input_split_2_external_amount")
                        }
                        type="number"
                        min={0}
                        style={{ width: "60px" }}
                        onFocus={(e) => e.target.select()}
                        value={shapeObj.internal_amount || ""}
                        onChange={(e) => {
                          const val = parseInt(e.target.value);
                          setShape({
                            ...shapeObj,
                            internal_amount: val || null,
                          });
                          if (
                            parseInt(e.target.value) < 2 ||
                            e.target.value === ""
                          ) {
                            addError({
                              errorType: pileErrorTypes.MIN_INTERNAL_AMOUNT,
                              message: t("error_side_min", { ns: "tool" }),
                            });
                          } else {
                            removeError(pileErrorTypes.MIN_INTERNAL_AMOUNT);
                          }
                        }}
                        ref={(el) => {
                          if (el)
                            siderBarRefs.current[
                              pileSidebarInput.INTERNAL_AMOUNT
                            ] = el;
                        }}
                        onBlur={() =>
                          addUpdatedClass(pileSidebarInput.INTERNAL_AMOUNT)
                        }
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp") {
                            e.preventDefault();
                            if (sideSpilt === 2) {
                              siderBarRefs.current[
                                pileSidebarInput.EXTERNAL_AMOUNT
                              ].focus();
                            } else {
                              siderBarRefs.current[
                                pileSidebarInput.MIDDLE_AMOUNT
                              ].focus();
                            }
                          } else if (
                            e.key === "ArrowRight" ||
                            e.key === "Enter"
                          ) {
                            if (e.key === "ArrowRight") {
                              e.preventDefault();
                            }
                            siderBarRefs.current[
                              pileSidebarInput.INTERNAL_DIAMETER
                            ].focus();
                          } else if (e.key === "ArrowDown") {
                            e.preventDefault();
                            siderBarRefs.current[
                              pileSidebarInput.CAGE_NAME
                            ].focus();
                          }
                        }}
                      />
                    </div>

                    <div>
                      <select
                        className={
                          "form-control form-control-sm cages_sidebar_input" +
                          editModeClass
                          + (rightToLeft ? " input_split_2_external_diameter_heb" : " input_split_2_external_diameter")
                        }
                        style={{ width: "60px", paddingRight: "30px" }}
                        value={shapeObj?.internal_diameter?.id || ""}
                        onChange={(e) => {
                          const _diameter = getDiameter(
                            parseInt(e.target.value)
                          );
                          if (_diameter) {
                            setShape({
                              ...shapeObj,
                              internal_diameter: _diameter,
                            });
                          }
                        }}
                        onBlur={() =>
                          addUpdatedClass(pileSidebarInput.INTERNAL_DIAMETER)
                        }
                        ref={(el) => {
                          if (el)
                            siderBarRefs.current[
                              pileSidebarInput.INTERNAL_DIAMETER
                            ] = el;
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp") {
                            e.preventDefault();
                            if (sideSpilt === 2) {
                              siderBarRefs.current[
                                pileSidebarInput.EXTERNAL_DIAMETER
                              ].focus();
                            } else {
                              siderBarRefs.current[
                                pileSidebarInput.MIDDLE_DIAMETER
                              ].focus();
                            }
                          } else if (
                            e.key === "ArrowRight" ||
                            e.key === "Enter"
                          ) {
                            e.preventDefault();
                            siderBarRefs.current[
                              pileSidebarInput.CAGE_NAME
                            ].focus();
                          } else if (e.key === "ArrowDown") {
                            e.preventDefault();
                            siderBarRefs.current[
                              pileSidebarInput.CAGE_NAME
                            ].focus();
                          } else if (e.key === "ArrowLeft") {
                            e.preventDefault();
                            siderBarRefs.current[
                              pileSidebarInput.INTERNAL_AMOUNT
                            ].focus();
                          }
                        }}
                      >
                        {shapeObj.length_steel_type in steelDiameters &&
                          steelDiameters[shapeObj.length_steel_type].map(
                            (reDi: any, index: number) =>
                              reDi.steel_diameter >= 12 &&
                              reDi.steel_diameter <= 36 && (
                                <option
                                  key={"internal-diameter-options-" + index}
                                  value={reDi.id}
                                >
                                  {parseFloat(reDi?.steel_diameter)}
                                </option>
                              )
                          )}
                      </select>
                    </div>
                    <div className={"cage_sidebar_img" + (rightToLeft ? "_heb" : "")}>
                      <img src={split_2} alt="" />
                    </div>
                  </div>
                ) : (
                  <div
                    className={
                      "cages_sidebar_container cage_section_1 d-flex align-items-center justify-content-start"
                    }
                    style={{
                      borderBottom: "1px solid #D9D9D9",
                      height: "156px",
                    }}
                  >
                    <div className={"input_split_3_1" + (rightToLeft ? "_heb" : "")}>
                      <img
                        className={"label_input_2"}
                        src={lenght_cage}
                        alt=""
                      />
                      <input
                        className={
                          "form-control form-control-sm  cages_sidebar_input" +
                          editModeClass +
                          (errorsText.some(
                            (error) =>
                              error.errorType ===
                              pileErrorTypes.MIN_EXTERNAL_AMOUNT
                          )
                            ? "_error"
                            : "")
                        }
                        type="number"
                        min={0}
                        style={{ width: "60px" }}
                        onFocus={(e) => e.target.select()}
                        value={shapeObj.external_amount || ""}
                        onChange={(e) => {
                          const val = parseInt(e.target.value);
                          setShape({
                            ...shapeObj,
                            external_amount: val || null,
                          });
                          if (
                            parseInt(e.target.value) < 2 ||
                            e.target.value === ""
                          ) {
                            addError({
                              errorType: pileErrorTypes.MIN_EXTERNAL_AMOUNT,
                              message: t("error_side_min", { ns: "tool" }),
                            });
                          } else {
                            removeError(pileErrorTypes.MIN_EXTERNAL_AMOUNT);
                          }
                        }}
                        onBlur={() =>
                          addUpdatedClass(pileSidebarInput.EXTERNAL_AMOUNT)
                        }
                        ref={(el) => {
                          if (el)
                            siderBarRefs.current[
                              pileSidebarInput.EXTERNAL_AMOUNT
                            ] = el;
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp" || e.key === "ArrowLeft") {
                            e.preventDefault();
                          } else if (
                            e.key === "ArrowRight" ||
                            e.key === "Enter"
                          ) {
                            if (e.key === "ArrowRight") {
                              e.preventDefault();
                            }
                            siderBarRefs.current[
                              pileSidebarInput.EXTERNAL_DIAMETER
                            ].focus();
                          } else if (e.key === "ArrowDown") {
                            e.preventDefault();
                            if (sideSpilt === 2) {
                              siderBarRefs.current[
                                pileSidebarInput.INTERNAL_AMOUNT
                              ].focus();
                            } else {
                              siderBarRefs.current[
                                pileSidebarInput.MIDDLE_AMOUNT
                              ].focus();
                            }
                          }
                        }}
                      />
                    </div>
                    <div className={"input_split_3_2" + (rightToLeft ? "_heb" : "")}>
                      <img
                        className={"label_input_2"}
                        src={diameter_cage}
                        alt=""
                      />
                      <select
                        className={
                          "form-control form-control-sm  cages_sidebar_input" +
                          editModeClass
                        }
                        style={{ width: "60px", paddingRight: "30px" }}
                        value={shapeObj?.external_diameter?.id || ""}
                        onChange={(e) => {
                          const _diameter = getDiameter(
                            parseInt(e.target.value)
                          );
                          if (_diameter) {
                            setShape({
                              ...shapeObj,
                              external_diameter: _diameter,
                            });
                          }
                        }}
                        onBlur={() =>
                          addUpdatedClass(pileSidebarInput.EXTERNAL_DIAMETER)
                        }
                        ref={(el) => {
                          if (el)
                            siderBarRefs.current[
                              pileSidebarInput.EXTERNAL_DIAMETER
                            ] = el;
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp") {
                            e.preventDefault();
                          } else if (
                            e.key === "ArrowRight" ||
                            e.key === "Enter"
                          ) {
                            if (e.key === "ArrowRight") {
                              e.preventDefault();
                            }
                            if (sideSpilt === 2) {
                              siderBarRefs.current[
                                pileSidebarInput.INTERNAL_AMOUNT
                              ].focus();
                            } else {
                              siderBarRefs.current[
                                pileSidebarInput.MIDDLE_AMOUNT
                              ].focus();
                            }
                          } else if (e.key === "ArrowDown") {
                            e.preventDefault();
                            if (sideSpilt === 2) {
                              siderBarRefs.current[
                                pileSidebarInput.INTERNAL_DIAMETER
                              ].focus();
                            } else {
                              siderBarRefs.current[
                                pileSidebarInput.MIDDLE_AMOUNT
                              ].focus();
                            }
                          } else if (e.key === "ArrowLeft") {
                            e.preventDefault();
                            siderBarRefs.current[
                              pileSidebarInput.EXTERNAL_AMOUNT
                            ].focus();
                          }
                        }}
                      >
                        {shapeObj.length_steel_type in steelDiameters &&
                          steelDiameters[shapeObj.length_steel_type].map(
                            (reDi: any, index: number) =>
                              reDi.steel_diameter >= 12 &&
                              reDi.steel_diameter <= 36 && (
                                <option
                                  key={"internal-diameter-options-" + index}
                                  value={reDi.id}
                                >
                                  {parseFloat(reDi?.steel_diameter)}
                                </option>
                              )
                          )}
                      </select>
                    </div>
                    <div>
                      <input
                        className={
                          "form-control form-control-sm cages_sidebar_input" +
                          editModeClass +
                          (errorsText.some(
                            (error) =>
                              error.errorType ===
                              pileErrorTypes.MIN_MIDDLE_AMOUNT
                          )
                            ? "_error"
                            : "")
                            + (rightToLeft ? " input_split_3_3_heb" : " input_split_3_3")
                        }
                        type="number"
                        min={0}
                        style={{ width: "60px" }}
                        onFocus={(e) => e.target.select()}
                        value={shapeObj.middle_amount || ""}
                        onChange={(e) => {
                          const val = parseInt(e.target.value);
                          setShape({
                            ...shapeObj,
                            middle_amount: val || null,
                          });
                          if (
                            parseInt(e.target.value) < 2 ||
                            e.target.value === ""
                          ) {
                            addError({
                              errorType: pileErrorTypes.MIN_MIDDLE_AMOUNT,
                              message: t("error_side_min", { ns: "tool" }),
                            });
                          } else {
                            removeError(pileErrorTypes.MIN_MIDDLE_AMOUNT);
                          }
                        }}
                        onBlur={() =>
                          addUpdatedClass(pileSidebarInput.MIDDLE_AMOUNT)
                        }
                        ref={(el) => {
                          if (el)
                            siderBarRefs.current[
                              pileSidebarInput.MIDDLE_AMOUNT
                            ] = el;
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp") {
                            e.preventDefault();
                            siderBarRefs.current[
                              pileSidebarInput.EXTERNAL_AMOUNT
                            ].focus();
                          } else if (e.key === "ArrowLeft") {
                            e.preventDefault();
                            siderBarRefs.current[
                              pileSidebarInput.EXTERNAL_DIAMETER
                            ].focus();
                          } else if (
                            e.key === "ArrowRight" ||
                            e.key === "Enter"
                          ) {
                            if (e.key === "ArrowRight") {
                              e.preventDefault();
                            }
                            siderBarRefs.current[
                              pileSidebarInput.MIDDLE_DIAMETER
                            ].focus();
                          } else if (e.key === "ArrowDown") {
                            e.preventDefault();
                            siderBarRefs.current[
                              pileSidebarInput.INTERNAL_AMOUNT
                            ].focus();
                          }
                        }}
                      />
                    </div>

                    <div>
                      <select
                        className={
                          "form-control form-control-sm cages_sidebar_input" +
                          editModeClass
                          + (rightToLeft ? " input_split_3_4_heb" : " input_split_3_4")
                        }
                        style={{ width: "60px", paddingRight: "30px" }}
                        value={shapeObj?.middle_diameter?.id || ""}
                        onChange={(e) => {
                          const _diameter = getDiameter(
                            parseInt(e.target.value)
                          );
                          if (_diameter) {
                            setShape({
                              ...shapeObj,
                              middle_diameter: _diameter,
                            });
                          }
                        }}
                        onBlur={() =>
                          addUpdatedClass(pileSidebarInput.MIDDLE_DIAMETER)
                        }
                        ref={(el) => {
                          if (el)
                            siderBarRefs.current[
                              pileSidebarInput.MIDDLE_DIAMETER
                            ] = el;
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp") {
                            e.preventDefault();
                            siderBarRefs.current[
                              pileSidebarInput.EXTERNAL_DIAMETER
                            ].focus();
                          } else if (e.key === "ArrowLeft") {
                            e.preventDefault();
                            siderBarRefs.current[
                              pileSidebarInput.MIDDLE_AMOUNT
                            ].focus();
                          } else if (
                            e.key === "ArrowRight" ||
                            e.key === "Enter"
                          ) {
                            if (e.key === "ArrowRight") {
                              e.preventDefault();
                            }
                            siderBarRefs.current[
                              pileSidebarInput.INTERNAL_AMOUNT
                            ].focus();
                          } else if (e.key === "ArrowDown") {
                            e.preventDefault();
                            siderBarRefs.current[
                              pileSidebarInput.INTERNAL_DIAMETER
                            ].focus();
                          }
                        }}
                      >
                        {shapeObj.length_steel_type in steelDiameters &&
                          steelDiameters[shapeObj.length_steel_type].map(
                            (reDi: any, index: number) =>
                              reDi.steel_diameter >= 12 &&
                              reDi.steel_diameter <= 36 && (
                                <option
                                  key={"middle-diameter-options-" + index}
                                  value={reDi.id}
                                >
                                  {parseFloat(reDi?.steel_diameter)}
                                </option>
                              )
                          )}
                      </select>
                    </div>

                    <div>
                      <input
                        className={
                          "form-control form-control-sm cages_sidebar_input" +
                          editModeClass +
                          (errorsText.some(
                            (error) =>
                              error.errorType ===
                              pileErrorTypes.MIN_INTERNAL_AMOUNT
                          )
                            ? "_error"
                            : "")
                            + (rightToLeft ? " input_split_3_5_heb" : " input_split_3_5")
                        }
                        type="number"
                        min={0}
                        style={{ width: "60px" }}
                        onFocus={(e) => e.target.select()}
                        value={shapeObj.internal_amount || ""}
                        onChange={(e) => {
                          const val = parseInt(e.target.value);
                          setShape({
                            ...shapeObj,
                            internal_amount: val || null,
                          });
                          if (
                            parseInt(e.target.value) < 2 ||
                            e.target.value === ""
                          ) {
                            addError({
                              errorType: pileErrorTypes.MIN_INTERNAL_AMOUNT,
                              message: t("error_side_min", { ns: "tool" }),
                            });
                          } else {
                            removeError(pileErrorTypes.MIN_INTERNAL_AMOUNT);
                          }
                        }}
                        onBlur={() =>
                          addUpdatedClass(pileSidebarInput.INTERNAL_AMOUNT)
                        }
                        ref={(el) => {
                          if (el)
                            siderBarRefs.current[
                              pileSidebarInput.INTERNAL_AMOUNT
                            ] = el;
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp") {
                            e.preventDefault();
                            if (sideSpilt === 2) {
                              siderBarRefs.current[
                                pileSidebarInput.EXTERNAL_AMOUNT
                              ].focus();
                            } else {
                              siderBarRefs.current[
                                pileSidebarInput.MIDDLE_AMOUNT
                              ].focus();
                            }
                          } else if (
                            e.key === "ArrowRight" ||
                            e.key === "Enter"
                          ) {
                            if (e.key === "ArrowRight") {
                              e.preventDefault();
                            }
                            siderBarRefs.current[
                              pileSidebarInput.INTERNAL_DIAMETER
                            ].focus();
                          } else if (e.key === "ArrowDown") {
                            e.preventDefault();
                            siderBarRefs.current[
                              pileSidebarInput.CAGE_NAME
                            ].focus();
                          } else if (e.key === "ArrowLeft") {
                            e.preventDefault();
                            siderBarRefs.current[
                              pileSidebarInput.MIDDLE_DIAMETER
                            ].focus();
                          }
                        }}
                      />
                    </div>

                    <div>
                      <select
                        className={
                          "form-control form-control-sm cages_sidebar_input" +
                          editModeClass
                          + (rightToLeft ? " input_split_3_6_heb" : " input_split_3_6")
                        }
                        style={{ width: "60px", paddingRight: "30px" }}
                        value={shapeObj?.internal_diameter?.id || ""}
                        onChange={(e) => {
                          const _diameter = getDiameter(
                            parseInt(e.target.value)
                          );
                          if (_diameter) {
                            setShape({
                              ...shapeObj,
                              internal_diameter: _diameter,
                            });
                          }
                        }}
                        onBlur={() =>
                          addUpdatedClass(pileSidebarInput.INTERNAL_DIAMETER)
                        }
                        ref={(el) => {
                          if (el)
                            siderBarRefs.current[
                              pileSidebarInput.INTERNAL_DIAMETER
                            ] = el;
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp") {
                            e.preventDefault();
                            if (sideSpilt === 2) {
                              siderBarRefs.current[
                                pileSidebarInput.EXTERNAL_DIAMETER
                              ].focus();
                            } else {
                              siderBarRefs.current[
                                pileSidebarInput.MIDDLE_DIAMETER
                              ].focus();
                            }
                          } else if (
                            e.key === "ArrowRight" ||
                            e.key === "Enter"
                          ) {
                            e.preventDefault();
                            siderBarRefs.current[
                              pileSidebarInput.CAGE_NAME
                            ].focus();
                          } else if (e.key === "ArrowDown") {
                            e.preventDefault();
                            siderBarRefs.current[
                              pileSidebarInput.CAGE_NAME
                            ].focus();
                          } else if (e.key === "ArrowLeft") {
                            e.preventDefault();
                            siderBarRefs.current[
                              pileSidebarInput.INTERNAL_AMOUNT
                            ].focus();
                          }
                        }}
                      >
                        {shapeObj.length_steel_type in steelDiameters &&
                          steelDiameters[shapeObj.length_steel_type].map(
                            (reDi: any, index: number) =>
                              reDi.steel_diameter >= 12 &&
                              reDi.steel_diameter <= 36 && (
                                <option
                                  key={"external-diameter-options-" + index}
                                  value={reDi.id}
                                >
                                  {parseFloat(reDi?.steel_diameter)}
                                </option>
                              )
                          )}
                      </select>
                    </div>

                    <div className={"cage_sidebar_img" + (rightToLeft ? "_heb" : "")} style={{ paddingTop: "16px" }}>
                      <img src={split_3} alt="" />
                    </div>
                  </div>
                )}
              </>
            )}

            {/* Cage Name */}
            <div
              className="sidebar_container"
              style={{ borderBottom: "1px solid #D9D9D9" }}
            >
              <div className="cage_name">
                <h1 className="cages_sidebar_label mb-0">
                  {t("cage name", { ns: "tool" })}
                </h1>
              </div>

              <input
                type="text"
                min={0}
                className={
                  "para text-center text-ash input-inside cages_sidebar_input" +
                  editModeClass
                }
                style={{
                  width: "145px",
                }}
                value={shapeObj.name || ""}
                onFocus={(e: any) => e.target.select()}
                onChange={(e) =>
                  setShape({ ...shapeObj, name: e.target.value })
                }
                onBlur={() => addUpdatedClass(pileSidebarInput.CAGE_NAME)}
                maxLength={20}
                ref={(el) => {
                  if (el) siderBarRefs.current[pileSidebarInput.CAGE_NAME] = el;
                }}
                onKeyDown={(e) => {
                  if (
                    e.key === "ArrowDown" ||
                    e.key === "Enter" ||
                    e.key === "ArrowRight"
                  ) {
                    e.preventDefault();
                    if (rightToLeft) {
                      siderBarRefs.current[
                        pileSidebarInput.HOLE_DIAMETER
                      ].focus();
                    } else {
                      siderBarRefs.current[
                        pileSidebarInput.CAGE_DIAMETER
                      ].focus();
                    }
                  } else if (e.key === "ArrowUp" || e.key === "ArrowLeft") {
                    e.preventDefault();
                    if (!base)
                      siderBarRefs.current[
                        pileSidebarInput.INTERNAL_DIAMETER
                      ].focus();
                  }
                }}
              />
            </div>

            {/* Concrete Cage Heading */}
            <div className="sidebar_container">
              <div className="cage_name">
                <h1 className="cages_sidebar_label_large mb-0">
                  {t("concrete cage", { ns: "tool" })}
                  <span
                    className={"cages_units " + (rightToLeft ? "pr-1" : "pl-1")}
                    style={{ paddingLeft: "3px" }}
                  >
                    {t("cm", { ns: "tool" })}
                  </span>
                </h1>
              </div>
              <img src={concrete_cage} alt="" />
            </div>

            {/* First Container */}
            <div
              className={
                "cages_sidebar_container cage_section_1 d-flex align-items-center justify-content-start"}
              style={{ borderBottom: "1px solid #D9D9D9", height: "165px" }}
            >
              <div className={"input_d" + (rightToLeft ? "_heb" : "")}>
                <h1 className="cages_sidebar_label mb-0 label_input_1">
                  {t("d", { ns: "common" })}
                </h1>

                <input
                  className={
                    "form-control form-control-sm  cages_sidebar_input" +
                    editModeClass +
                    (CAGE_DIAMETER_ERRORS.some((error) =>
                      errorsText.some((item) => item.errorType === error)
                    )
                      ? "_error"
                      : "")
                  }
                  type="number"
                  min={0}
                  style={{ width: "60px" }}
                  value={shapeObj.cage_diameter || ""}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    const val = parseInt(e.target.value) || 0;
                    if (e.target.value <= shapeObj.hole_diameter) {
                      setShape({
                        ...shapeObj,
                        cage_diameter: val,
                        cover:
                          (shapeObj.hole_diameter - parseInt(e.target.value)) /
                          2,
                      });
                      checkCageLength(
                        shapeObj.l1_length,
                        shapeObj.l2_length,
                        shapeObj.l3_length,
                        val
                      );
                      if (val >= shapeObj.hole_diameter) {
                        addError({
                          errorType: pileErrorTypes.DIAMETER_RATIO,
                          message: t("error_cage_diameter_ratio", {
                            ns: "tool",
                          }),
                        });
                      } else {
                        removeError(pileErrorTypes.DIAMETER_RATIO);
                      }

                      if (shapeObj.base_amount < val / 10) {
                        addError({
                          errorType: pileErrorTypes.REBAR_AMOUNT_CAGE,
                          message: t("error_rebar_amount_to_cage", {
                            ns: "tool",
                          }),
                        });
                      } else {
                        removeError(pileErrorTypes.REBAR_AMOUNT_CAGE);
                      }

                      if (shapeObj.base_amount > (val * Math.PI) / 5) {
                        addError({
                          errorType: pileErrorTypes.MAX_REBAR_AMOUNT,
                          message: t("max_error_rebar_amount", { ns: "tool" }),
                        });
                        removeError(pileErrorTypes.MIN_REBAR_AMOUNT);
                      } else {
                        removeError(pileErrorTypes.MAX_REBAR_AMOUNT);
                      }
                    }
                  }}
                  onBlur={() => addUpdatedClass(pileSidebarInput.CAGE_DIAMETER)}
                  ref={(el) => {
                    if (el)
                      siderBarRefs.current[pileSidebarInput.CAGE_DIAMETER] = el;
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowDown") {
                      e.preventDefault();
                      if (base) {
                        siderBarRefs.current[
                          pileSidebarInput.BASE_AMOUNT
                        ].focus();
                      } else {
                        siderBarRefs.current[pileSidebarInput.L2].focus();
                      }
                    } else if (e.key === "ArrowRight") {
                      e.preventDefault();
                      siderBarRefs.current[
                        pileSidebarInput.HOLE_DIAMETER
                      ].focus();
                    } else if (e.key === "Enter") {
                      if (rightToLeft) {
                        if (base) {
                          siderBarRefs.current[
                            pileSidebarInput.BASE_DIAMETER
                          ].focus();
                        } else {
                          siderBarRefs.current[pileSidebarInput.L3].focus();
                        }
                      } else {
                        siderBarRefs.current[
                          pileSidebarInput.HOLE_DIAMETER
                        ].focus();
                      }
                    } else if (e.key === "ArrowUp") {
                      e.preventDefault();
                      siderBarRefs.current[pileSidebarInput.CAGE_NAME].focus();
                    }
                  }}
                />
              </div>
              <div className={"input_D" + (rightToLeft ? "_heb" : "")}>
                <h1 className="cages_sidebar_label mb-0 label_input_1">
                  {t("D", { ns: "common" })}
                </h1>

                <input
                  className={
                    "form-control form-control-sm  cages_sidebar_input" +
                    editModeClass +
                    (HOLE_DIAMETER_ERRORS.some((error) =>
                      errorsText.some((item) => item.errorType === error)
                    )
                      ? "_error"
                      : "")
                  }
                  type="number"
                  min={0}
                  style={{ width: "60px" }}
                  value={shapeObj.hole_diameter || ""}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    const val = parseInt(e.target.value) || 0;
                    setShape({
                      ...shapeObj,
                      hole_diameter: val,
                      cover:
                        (parseInt(e.target.value) - shapeObj.cage_diameter) / 2,
                    });

                    if (val < D_MIN) {
                      addError({
                        errorType: pileErrorTypes.MIN_D,
                        message: t("min_error_D", { ns: "tool" }),
                      });
                      removeError(pileErrorTypes.MAX_D);
                    } else if (val > D_MAX) {
                      addError({
                        errorType: pileErrorTypes.MAX_D,
                        message: t("max_error_D", { ns: "tool" }),
                      });
                      removeError(pileErrorTypes.MIN_D);
                    } else {
                      removeError(pileErrorTypes.MIN_D);
                      removeError(pileErrorTypes.MAX_D);
                    }

                    if (shapeObj.cage_diameter >= parseInt(e.target.value)) {
                      addError({
                        errorType: pileErrorTypes.DIAMETER_RATIO,
                        message: t("error_cage_diameter_ratio", { ns: "tool" }),
                      });
                    } else {
                      removeError(pileErrorTypes.DIAMETER_RATIO);
                    }
                  }}
                  onBlur={() => addUpdatedClass(pileSidebarInput.HOLE_DIAMETER)}
                  ref={(el) => {
                    if (el)
                      siderBarRefs.current[pileSidebarInput.HOLE_DIAMETER] = el;
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowRight" || e.key === "Enter") {
                      e.preventDefault();
                      if (rightToLeft) {
                        siderBarRefs.current[
                          pileSidebarInput.CAGE_DIAMETER
                        ].focus();
                      } else {
                        if (base) {
                          siderBarRefs.current[
                            pileSidebarInput.BASE_AMOUNT
                          ].focus();
                        } else {
                          siderBarRefs.current[pileSidebarInput.L1].focus();
                        }
                      }
                    } else if (e.key === "ArrowUp") {
                      e.preventDefault();
                      siderBarRefs.current[pileSidebarInput.CAGE_NAME].focus();
                    } else if (e.key === "ArrowLeft") {
                      e.preventDefault();
                      siderBarRefs.current[
                        pileSidebarInput.CAGE_DIAMETER
                      ].focus();
                    } else if (e.key === "ArrowDown") {
                      e.preventDefault();
                      if (base) {
                        siderBarRefs.current[
                          pileSidebarInput.BASE_DIAMETER
                        ].focus();
                      } else {
                        siderBarRefs.current[pileSidebarInput.L3].focus();
                      }
                    }
                  }}
                />
              </div>
              <div className={"cage_sidebar_img" + (rightToLeft ? "_heb" : "")}>
                <img src={cage_1} alt="" />
              </div>
            </div>

            {base && (
              <>
                {/* Horizontal Rebars Heading */}
                <div className="sidebar_container">
                  <div className="cage_name">
                    <h1 className="cages_sidebar_label_large mb-0">
                      {t("horizontal rebars", { ns: "tool" })}
                    </h1>
                  </div>
                  <img src={horizontal_rebars} alt="" />
                </div>

                {/* 2nd Container */}
                <div
                  className={"cages_sidebar_container cage_section_1 d-flex align-items-center justify-content-" + (rightToLeft ? "end" : "start")}
                  style={{ borderBottom: "1px solid #D9D9D9", height: "139px" }}
                >
                  <div className={"input_cage_len" + (rightToLeft ? "_heb" : "")}>
                    <img className={"label_input_2"} src={lenght_cage} alt="" />
                    <input
                      className={
                        "form-control form-control-sm  cages_sidebar_input" +
                        editModeClass +
                        (BASE_AMOUNT_ERRORS.some((error) =>
                          errorsText.some((item) => item.errorType === error)
                        )
                          ? "_error"
                          : "")
                      }
                      type="number"
                      min={0}
                      style={{ width: "60px" }}
                      onFocus={(e) => e.target.select()}
                      value={shapeObj.base_amount || ""}
                      onChange={(e) => {
                        const val = parseInt(e.target.value) || 0;
                        setShape({
                          ...shapeObj,
                          base_amount: val,
                        });

                        if (val < shapeObj.cage_diameter / 10) {
                          addError({
                            errorType: pileErrorTypes.REBAR_AMOUNT_CAGE,
                            message: t("error_rebar_amount_to_cage", {
                              ns: "tool",
                            }),
                          });
                        } else {
                          removeError(pileErrorTypes.REBAR_AMOUNT_CAGE);
                        }

                        if (val < REBAR_MIN_AMOUNT) {
                          addError({
                            errorType: pileErrorTypes.MIN_REBAR_AMOUNT,
                            message: t("min_error_rebar_amount", {
                              ns: "tool",
                            }),
                          });
                          removeError(pileErrorTypes.MAX_REBAR_AMOUNT);
                        } else if (
                          val >
                          (shapeObj.cage_diameter * Math.PI) / 5
                        ) {
                          addError({
                            errorType: pileErrorTypes.MAX_REBAR_AMOUNT,
                            message: t("max_error_rebar_amount", {
                              ns: "tool",
                            }),
                          });
                          removeError(pileErrorTypes.MIN_REBAR_AMOUNT);
                        } else {
                          removeError(pileErrorTypes.MAX_REBAR_AMOUNT);
                          removeError(pileErrorTypes.MIN_REBAR_AMOUNT);
                        }
                      }}
                      onBlur={() =>
                        addUpdatedClass(pileSidebarInput.BASE_AMOUNT)
                      }
                      ref={(el) => {
                        if (el)
                          siderBarRefs.current[pileSidebarInput.BASE_AMOUNT] =
                            el;
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "ArrowRight" || e.key === "Enter") {
                          if (e.key === "ArrowRight") {
                            e.preventDefault();
                          }
                          if (rightToLeft) {
                            siderBarRefs.current[pileSidebarInput.L3].focus();
                          } else {
                            siderBarRefs.current[
                              pileSidebarInput.BASE_DIAMETER
                            ].focus();
                          }
                        } else if (e.key === "ArrowUp") {
                          e.preventDefault();
                          siderBarRefs.current[
                            pileSidebarInput.CAGE_DIAMETER
                          ].focus();
                        } else if (e.key === "ArrowLeft") {
                          e.preventDefault();
                          siderBarRefs.current[
                            pileSidebarInput.HOLE_DIAMETER
                          ].focus();
                        } else if (e.key === "ArrowDown") {
                          e.preventDefault();
                          siderBarRefs.current[pileSidebarInput.L1].focus();
                        }
                      }}
                    />
                  </div>

                  <div className={"input_diameter"+ (rightToLeft ? "_heb" : "")}>
                    <img
                      className={"label_input_2"}
                      src={diameter_cage}
                      alt=""
                    />
                    <select
                      className={
                        "form-control form-control-sm  cages_sidebar_input" +
                        editModeClass
                      }
                      style={{ width: "60px", paddingRight: "30px" }}
                      value={shapeObj?.base_diameter?.id || ""}
                      onChange={(e) => {
                        const _diameter = getDiameter(parseInt(e.target.value));
                        if (_diameter) {
                          setShape({
                            ...shapeObj,
                            base_diameter: _diameter,
                          });
                        }
                      }}
                      onBlur={() =>
                        addUpdatedClass(pileSidebarInput.BASE_DIAMETER)
                      }
                      ref={(el) => {
                        if (el)
                          siderBarRefs.current[pileSidebarInput.BASE_DIAMETER] =
                            el;
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "ArrowRight" || e.key === "Enter") {
                          e.preventDefault();
                          if (rightToLeft) {
                            siderBarRefs.current[
                              pileSidebarInput.BASE_AMOUNT
                            ].focus();
                          } else {
                            siderBarRefs.current[pileSidebarInput.L1].focus();
                          }
                        } else if (e.key === "ArrowUp") {
                          e.preventDefault();
                          siderBarRefs.current[
                            pileSidebarInput.HOLE_DIAMETER
                          ].focus();
                        } else if (e.key === "ArrowLeft") {
                          e.preventDefault();
                          siderBarRefs.current[
                            pileSidebarInput.BASE_AMOUNT
                          ].focus();
                        } else if (e.key === "ArrowDown") {
                          e.preventDefault();
                          siderBarRefs.current[pileSidebarInput.L1].focus();
                        }
                      }}
                    >
                      {shapeObj.length_steel_type in steelDiameters &&
                        steelDiameters[shapeObj.length_steel_type].map(
                          (reDi: any, index: number) => {
                            if (
                              parseFloat(reDi?.steel_diameter) >= 12 &&
                              parseFloat(reDi?.steel_diameter) <= 36 &&
                              parseFloat(reDi?.steel_diameter) !== 24
                            ) {
                              return (
                                <option
                                  key={"length-steel-diameter-options-" + index}
                                  value={reDi.id}
                                >
                                  {parseFloat(reDi?.steel_diameter)}
                                </option>
                              );
                            } else {
                              return null; // Exclude options with diameter <= 24
                            }
                          }
                        )}
                    </select>
                  </div>
                  <div>
                    <select
                      className={
                        "form-control form-control-sm  cages_sidebar_input_grey input_5" + (rightToLeft ? "_heb" : "")
                      }
                      style={{ width: "120px" }}
                      value={shapeObj.length_steel_type}
                      onChange={({ target }) => {
                        const prev_base_diameter = shapeObj.base_diameter;
                        let new_internal_diameter = null;
                        let new_external_diameter = null;
                        let new_middle_diameter = null;

                        const steelDiameterWeight = steelDiameters[
                          parseInt(target.value)
                        ]?.find(
                          (item: any) =>
                            item.steel_diameter ===
                            prev_base_diameter.steel_diameter
                        );

                        if (!base) {
                          const prev_internal_diameter =
                            shapeObj.internal_diameter;
                          const prev_external_diameter =
                            shapeObj.external_diameter;
                          const prev_middle_diameter = shapeObj.middle_diameter;
                          new_internal_diameter = steelDiameters[
                            parseInt(target.value)
                          ]?.find(
                            (item: any) =>
                              item.steel_diameter ===
                              prev_internal_diameter.steel_diameter
                          );

                          new_external_diameter = steelDiameters[
                            parseInt(target.value)
                          ]?.find(
                            (item: any) =>
                              item.steel_diameter ===
                              prev_external_diameter.steel_diameter
                          );

                          if (sideSpilt === 3) {
                            new_middle_diameter = steelDiameters[
                              parseInt(target.value)
                            ]?.find(
                              (item: any) =>
                                item.steel_diameter ===
                                prev_middle_diameter.steel_diameter
                            );
                          }
                        }

                        setShape((prev) => ({
                          ...prev,
                          length_steel_type: parseInt(target.value),
                          base_diameter: steelDiameterWeight,
                          ...(!base
                            ? {
                                internal_diameter: new_internal_diameter,
                                external_diameter: new_external_diameter,
                                middle_diameter: new_middle_diameter,
                              }
                            : {}),
                        }));
                      }}
                      onBlur={() =>
                        addUpdatedClass(pileSidebarInput.LENGTH_STEEL_TYPE)
                      }
                      ref={(el) => {
                        if (el)
                          siderBarRefs.current[
                            pileSidebarInput.LENGTH_STEEL_TYPE
                          ] = el;
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "ArrowDown" || e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                    >
                      {steelTypes.map((st: { id: number; name: string }) => (
                        <option
                          key={"cage-length-steel-options-" + st.id}
                          value={st.id}
                        >
                          {st.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className={"cage_sidebar_img" + (rightToLeft ? "_heb" : "")}>
                    <img src={cage_2} alt="" />
                  </div>
                </div>
              </>
            )}

            {/* Lengths and Gaps Heading */}
            <div className="sidebar_container">
              <div className="cage_name">
                <h1 className="cages_sidebar_label_large mb-0">
                  {t("lengths and gaps", { ns: "tool" })}
                  <span
                    className={"cages_units " + (rightToLeft ? "pr-1" : "pl-1")}
                    style={{ paddingLeft: "3px" }}
                  >
                    {t("cm", { ns: "tool" })}
                  </span>
                </h1>
              </div>
            </div>

            {/* 3rd Container */}
            <div
              className={"sidebar_container cage_section_1 d-flex align-items-center justify-content-start"}
              style={{
                borderBottom: "1px solid #D9D9D9",
                height: "180px",
              }}
            >
              <div className="input_l1">
                <h1 className="cages_sidebar_label mb-0 label_input">
                  {t("L", { ns: "common" })}
                  <span className={"small_txt "}>{t("1", { ns: "tool" })}</span>
                </h1>

                <input
                  className={
                    "form-control form-control-sm  cages_sidebar_input" +
                    editModeClass +
                    (errorsText.some(
                      (error) => error.errorType === pileErrorTypes.MAX_L1
                    ) ||
                    errorsText.some(
                      (error) => error.errorType === pileErrorTypes.MIN_L1
                    )
                      ? "_error"
                      : "")
                  }
                  type="number"
                  min={0}
                  style={{ width: "60px" }}
                  value={shapeObj.l1_length || ""}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    const val = parseInt(e.target.value) || 0;
                    setShape({
                      ...shapeObj,
                      l1_length: val,
                    });
                    checkCageLength(
                      val,
                      shapeObj.l2_length,
                      shapeObj.l3_length,
                      shapeObj.cage_diameter
                    );
                    if (val > L1_MAX) {
                      addError({
                        errorType: pileErrorTypes.MAX_L1,
                        message: t("max_error_l1", { ns: "tool" }),
                      });
                      removeError(pileErrorTypes.MIN_L1);
                    } else if (val < L1_MIN) {
                      addError({
                        errorType: pileErrorTypes.MIN_L1,
                        message: t("min_error_l1", { ns: "tool" }),
                      });
                      removeError(pileErrorTypes.MAX_L1);
                    } else {
                      removeError(pileErrorTypes.MIN_L1);
                      removeError(pileErrorTypes.MAX_L1);
                    }
                  }}
                  onBlur={() => addUpdatedClass(pileSidebarInput.L1)}
                  ref={(el) => {
                    if (el) siderBarRefs.current[pileSidebarInput.L1] = el;
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowRight" || e.key === "Enter") {
                      e.preventDefault();
                      if (rightToLeft) {
                        siderBarRefs.current[pileSidebarInput.GAP_2].focus();
                      } else {
                        siderBarRefs.current[pileSidebarInput.L2].focus();
                      }
                    } else if (e.key === "ArrowUp" || e.key === "ArrowLeft") {
                      e.preventDefault();
                      if (base) {
                        siderBarRefs.current[
                          pileSidebarInput.BASE_DIAMETER
                        ].focus();
                      } else {
                        siderBarRefs.current[
                          pileSidebarInput.HOLE_DIAMETER
                        ].focus();
                      }
                    } else if (e.key === "ArrowDown") {
                      e.preventDefault();
                      siderBarRefs.current[pileSidebarInput.GAP_1].focus();
                    }
                  }}
                />
              </div>

              <div className="input_l2">
                <h1 className="cages_sidebar_label mb-0 label_input">
                  {t("L", { ns: "common" })}
                  <span className={"small_txt "}>{t("2", { ns: "tool" })}</span>
                </h1>

                <input
                  className={
                    "form-control form-control-sm  cages_sidebar_input" +
                    editModeClass +
                    (errorsText.some(
                      (error) => error.errorType === pileErrorTypes.MAX_L2
                    ) ||
                    errorsText.some(
                      (error) => error.errorType === pileErrorTypes.MIN_L2
                    )
                      ? "_error"
                      : "")
                  }
                  type="number"
                  min={0}
                  style={{ width: "60px" }}
                  value={shapeObj.l2_length || ""}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    const val = parseInt(e.target.value) || 0;
                    setShape({
                      ...shapeObj,
                      l2_length: val,
                    });

                    checkCageLength(
                      shapeObj.l1_length,
                      val,
                      shapeObj.l3_length,
                      shapeObj.cage_diameter
                    );
                    if (val > L2_MAX) {
                      addError({
                        errorType: pileErrorTypes.MAX_L2,
                        message: t("max_error_l2", { ns: "tool" }),
                      });
                      removeError(pileErrorTypes.MIN_L2);
                    } else if (val < L2_MIN) {
                      addError({
                        errorType: pileErrorTypes.MIN_L2,
                        message: t("min_error_l2", { ns: "tool" }),
                      });
                      removeError(pileErrorTypes.MAX_L2);
                    } else {
                      removeError(pileErrorTypes.MIN_L2);
                      removeError(pileErrorTypes.MAX_L2);
                    }
                  }}
                  onBlur={() => addUpdatedClass(pileSidebarInput.L2)}
                  ref={(el) => {
                    if (el) siderBarRefs.current[pileSidebarInput.L2] = el;
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowRight" || e.key === "Enter") {
                      e.preventDefault();
                      if (rightToLeft) {
                        siderBarRefs.current[pileSidebarInput.L1].focus();
                      } else {
                        siderBarRefs.current[pileSidebarInput.L3].focus();
                      }
                    } else if (e.key === "ArrowLeft") {
                      e.preventDefault();
                      siderBarRefs.current[pileSidebarInput.L1].focus();
                    } else if (e.key === "ArrowUp") {
                      e.preventDefault();
                      if (base) {
                        siderBarRefs.current[
                          pileSidebarInput.BASE_DIAMETER
                        ].focus();
                      } else {
                        siderBarRefs.current[
                          pileSidebarInput.HOLE_DIAMETER
                        ].focus();
                      }
                    } else if (e.key === "ArrowDown") {
                      e.preventDefault();
                      siderBarRefs.current[pileSidebarInput.GAP_1].focus();
                    }
                  }}
                />
              </div>

              <div className="input_l3">
                <h1 className="cages_sidebar_label mb-0 label_input">
                  {t("L", { ns: "common" })}
                  <span className={"small_txt "}>{t("3", { ns: "tool" })}</span>
                </h1>
                <input
                  className={
                    "form-control form-control-sm  cages_sidebar_input" +
                    editModeClass +
                    (errorsText.some(
                      (error) => error.errorType === pileErrorTypes.MAX_L3
                    ) ||
                    errorsText.some(
                      (error) => error.errorType === pileErrorTypes.MIN_L3
                    )
                      ? "_error"
                      : "")
                  }
                  type="number"
                  min={0}
                  style={{ width: "60px" }}
                  value={shapeObj.l3_length || ""}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    const val = parseInt(e.target.value) || 0;
                    setShape({
                      ...shapeObj,
                      l3_length: val,
                    });
                    checkCageLength(
                      shapeObj.l1_length,
                      shapeObj.l2_length,
                      val,
                      shapeObj.cage_diameter
                    );
                    if (val > L3_MAX) {
                      addError({
                        errorType: pileErrorTypes.MAX_L3,
                        message: t("max_error_l3", { ns: "tool" }),
                      });
                      removeError(pileErrorTypes.MIN_L3);
                    } else if (val < L3_MIN) {
                      addError({
                        errorType: pileErrorTypes.MIN_L3,
                        message: t("min_error_l3", { ns: "tool" }),
                      });
                      removeError(pileErrorTypes.MAX_L3);
                    } else {
                      removeError(pileErrorTypes.MIN_L3);
                      removeError(pileErrorTypes.MAX_L3);
                    }
                  }}
                  onBlur={() => addUpdatedClass(pileSidebarInput.L3)}
                  ref={(el) => {
                    if (el) siderBarRefs.current[pileSidebarInput.L3] = el;
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowRight" || e.key === "Enter") {
                      e.preventDefault();
                      if (rightToLeft) {
                        siderBarRefs.current[pileSidebarInput.L2].focus();
                      } else {
                        siderBarRefs.current[pileSidebarInput.GAP_1].focus();
                      }
                    } else if (e.key === "ArrowLeft") {
                      e.preventDefault();
                      siderBarRefs.current[pileSidebarInput.L2].focus();
                    } else if (e.key === "ArrowUp") {
                      e.preventDefault();
                      if (base) {
                        siderBarRefs.current[
                          pileSidebarInput.BASE_DIAMETER
                        ].focus();
                      } else {
                        siderBarRefs.current[
                          pileSidebarInput.HOLE_DIAMETER
                        ].focus();
                      }
                    } else if (e.key === "ArrowDown") {
                      e.preventDefault();
                      siderBarRefs.current[pileSidebarInput.GAP_2].focus();
                    }
                  }}
                />
              </div>

              <div className="input_at_1">
                {rightToLeft ? (
                  <>
                    <h1 className="cages_sidebar_label mb-1 label_input">
                      <span className={"small_txt "}>{1}</span>
                      {"@"}
                    </h1>
                  </>
                ) : (
                  <>
                    <h1 className="cages_sidebar_label mb-1 label_input">
                      {"@"}
                      <span className={"small_txt "}>{1}</span>
                    </h1>
                  </>
                )}

                <select
                  className={
                    "form-control form-control-sm  cages_sidebar_input" +
                    editModeClass
                  }
                  style={{ width: "60px", paddingRight: "30px" }}
                  value={shapeObj.l2_gap || ""}
                  onChange={(e) =>
                    setShape({
                      ...shapeObj,
                      l2_gap: parseInt(e.target.value),
                    })
                  }
                  onBlur={() => addUpdatedClass(pileSidebarInput.GAP_1)}
                  ref={(el) => {
                    if (el) siderBarRefs.current[pileSidebarInput.GAP_1] = el;
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowRight" || e.key === "Enter") {
                      e.preventDefault();
                      if (rightToLeft) {
                        siderBarRefs.current[
                          pileSidebarInput.SPIRAL_WIRE_DIAMETER
                        ].focus();
                      } else {
                        siderBarRefs.current[pileSidebarInput.GAP_2].focus();
                      }
                    } else if (e.key === "ArrowLeft") {
                      e.preventDefault();
                      siderBarRefs.current[pileSidebarInput.L3].focus();
                    } else if (e.key === "ArrowUp") {
                      e.preventDefault();
                      siderBarRefs.current[pileSidebarInput.L2].focus();
                    } else if (e.key === "ArrowDown") {
                      e.preventDefault();
                      siderBarRefs.current[
                        pileSidebarInput.SPIRAL_WIRE_DIAMETER
                      ].focus();
                    }
                  }}
                >
                  {pileGapOptions.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>

              <div className="input_at_2">
                {rightToLeft ? (
                  <>
                    <h1 className="cages_sidebar_label mb-1 label_input">
                      <span className={"small_txt "}>{2}</span>
                      {"@"}
                    </h1>
                  </>
                ) : (
                  <>
                    <h1 className="cages_sidebar_label mb-1 label_input">
                      {"@"}
                      <span className={"small_txt "}>{2}</span>
                    </h1>
                  </>
                )}

                <select
                  className={
                    "form-control form-control-sm  cages_sidebar_input" +
                    editModeClass
                  }
                  style={{ width: "60px", paddingRight: "30px" }}
                  value={shapeObj.l3_gap || ""}
                  onChange={(e) =>
                    setShape({
                      ...shapeObj,
                      l3_gap: parseInt(e.target.value),
                    })
                  }
                  onBlur={() => addUpdatedClass(pileSidebarInput.GAP_2)}
                  ref={(el) => {
                    if (el) siderBarRefs.current[pileSidebarInput.GAP_2] = el;
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowRight" || e.key === "Enter") {
                      if (e.key === "ArrowRight") {
                        e.preventDefault();
                      }
                      if (rightToLeft) {
                        siderBarRefs.current[pileSidebarInput.GAP_1].focus();
                      } else {
                        siderBarRefs.current[
                          pileSidebarInput.SPIRAL_WIRE_DIAMETER
                        ].focus();
                      }
                    } else if (e.key === "ArrowLeft") {
                      e.preventDefault();
                      siderBarRefs.current[pileSidebarInput.GAP_1].focus();
                    } else if (e.key === "ArrowUp") {
                      e.preventDefault();
                      siderBarRefs.current[pileSidebarInput.L3].focus();
                    } else if (e.key === "ArrowDown") {
                      e.preventDefault();
                      siderBarRefs.current[
                        pileSidebarInput.SPIRAL_WIRE_DIAMETER
                      ].focus();
                    }
                  }}
                >
                  {pileGapOptions.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>

              <div className={"cage_sidebar_img"}>
                <img src={spiral_1} alt="" />
              </div>
            </div>

            {/* Spiral */}
            <div
              className="sidebar_container"
              style={{ borderBottom: "1px solid #D9D9D9" }}
            >
              <div className="spiral">
                <h1 className="cages_sidebar_label_large mb-0">
                  {t("spiral", { ns: "tool" })}
                </h1>
              </div>

              <div className={"cage_sidebar_img" + (rightToLeft ? "_heb" : "")}>
                <img src={spiral_2} alt="" />
              </div>
            </div>

            {/* Diameter */}
            <div className="sidebar_container">
              <div className="total_lengths">
                <h1 className="cages_sidebar_label mb-0">
                  {t("diameter", { ns: "tool" })}
                  <span
                    className={"cages_units " + (rightToLeft ? "pr-1" : "pl-1")}
                    style={{ paddingLeft: "3px" }}
                  >
                    {t("mm", { ns: "tool" })}
                  </span>
                </h1>
              </div>
              <select
                className={
                  "form-control form-control-sm cages_sidebar_input" +
                  editModeClass
                }
                value={shapeObj?.spiral_wire_diameter?.id || ""}
                onChange={(e) => {
                  const _diameter = getDiameter(parseInt(e.target.value));
                  if (_diameter) {
                    setShape({
                      ...shapeObj,
                      spiral_wire_diameter: _diameter,
                    });
                  }
                }}
                onBlur={() =>
                  addUpdatedClass(pileSidebarInput.SPIRAL_WIRE_DIAMETER)
                }
                ref={(el) => {
                  if (el)
                    siderBarRefs.current[
                      pileSidebarInput.SPIRAL_WIRE_DIAMETER
                    ] = el;
                }}
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp") {
                    e.preventDefault();
                    siderBarRefs.current[pileSidebarInput.GAP_2].focus();
                  } else if (e.key === "ArrowDown" || e.key === "Enter") {
                    e.preventDefault();
                    if (ear) {
                      siderBarRefs.current[pileSidebarInput.EAR_LENGTH].focus();
                    } else if (ring) {
                      siderBarRefs.current[
                        pileSidebarInput.RING_QUANTITY
                      ].focus();
                    } else if (gama) {
                      siderBarRefs.current[
                        pileSidebarInput.GAMMA_QUANTITY
                      ].focus();
                    } else if (showNote) {
                      siderBarRefs.current[pileSidebarInput.NOTE].focus();
                    } else {
                      siderBarRefs.current[pileSidebarInput.QUANTITY].focus();
                    }
                  }
                }}
              >
                {shapeObj.cage_steel_type in steelDiameters &&
                  steelDiameters[shapeObj.cage_steel_type].map(
                    (reDi: any, index: number) => {
                      if (
                        parseFloat(reDi?.steel_diameter) >= 8 &&
                        parseFloat(reDi?.steel_diameter) <= 16
                      ) {
                        return (
                          <option
                            key={"length-steel-diameter-options-" + index}
                            value={reDi.id}
                          >
                            {parseFloat(reDi?.steel_diameter)}
                          </option>
                        );
                      } else {
                        return null; // Exclude options with diameter <= 24
                      }
                    }
                  )}
              </select>
            </div>

            {/* Steel Type */}
            <div
              className="sidebar_container"
              style={{ borderBottom: "1px solid #D9D9D9" }}
            >
              <div className="total_lengths">
                <h1
                  className="cages_sidebar_label mb-0"
                  style={{
                    color: "#878C97",
                  }}
                >
                  {t("steel type", { ns: "common" })}
                </h1>
              </div>
              <select
                className="form-control form-control-sm cages_sidebar_input_grey"
                style={{
                  backgroundColor: "#F5F5F5",
                }}
                value={shapeObj.cage_steel_type || ""}
                onChange={({ target }) => {
                  const prev_diameter = shapeObj.spiral_wire_diameter;
                  const steelDiameterWeight = steelDiameters[
                    parseInt(target.value)
                  ]?.find(
                    (item: any) =>
                      item.steel_diameter === prev_diameter.steel_diameter
                  );
                  setShape((prev: any) => ({
                    ...prev,
                    cage_steel_type: parseInt(target.value),
                    spiral_wire_diameter: steelDiameterWeight,
                  }));
                }}
                onBlur={() => addUpdatedClass(pileSidebarInput.CAGE_STEEL_TYPE)}
                ref={(el) => {
                  if (el)
                    siderBarRefs.current[pileSidebarInput.CAGE_STEEL_TYPE] = el;
                }}
                onKeyDown={(e) => {
                  if (e.key === "ArrowDown" || e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
              >
                {steelTypes.map((st: { id: number; name: string }) => (
                  <option key={"rebar-diameter-options-" + st.id} value={st.id}>
                    {st.name}
                  </option>
                ))}
              </select>
            </div>

            {/* EAR */}
            <div
              className="sidebar_container"
              style={{
                height: "25px",
                borderBottom: !ear ? "1px solid #D9D9D9" : "",
              }}
            >
              <div className="cages_note">
                <h1
                  className="cages_sidebar_label mb-0"
                  style={{
                    fontSize: "10.5px",
                    lineHeight: "15px",
                    color: "#878C97",
                  }}
                >
                  {t("ears", { ns: "common" })}
                </h1>
                <div
                  className="ear_img"
                  onClick={() => {
                    setEar(!ear);
                  }}
                >
                  {ear ? (
                    <img
                      src={remove}
                      className="cages_collapsable_img"
                      alt="-"
                    />
                  ) : (
                    <img src={add} className="cages_collapsable_img" alt="+" />
                  )}
                </div>
              </div>
            </div>
            {ear && (
              <>
                {/* EAR Type */}
                <div className="sidebar_container ear_type">
                  <h1 className="cages_sidebar_label mb-0">
                    {t("ear type", { ns: "tool" })}
                  </h1>
                  <div className="cage_type_select">
                    <h5
                      className={
                        "text-lowercase " +
                        (rightToLeft
                          ? "cage_type_toggle_left"
                          : "cage_type_toggle_right")
                      }
                      style={{
                        backgroundColor: earCardinal ? "#5F6674" : "#D7D9DC",
                        fontSize: rightToLeft ? "13.5px" : "10.5px",
                      }}
                      onClick={() => {
                        handleEarTypeToggle(true);
                      }}
                    >
                      {t("cardinal", { ns: "common" })}
                    </h5>

                    <h5
                      className={
                        "text-lowercase " +
                        (rightToLeft
                          ? "cage_type_toggle_right"
                          : "cage_type_toggle_left")
                      }
                      style={{
                        backgroundColor: earCardinal ? "#D7D9DC" : "#5F6674",
                        fontSize: rightToLeft ? "13.5px" : "10.5px",
                      }}
                      onClick={() => {
                        handleEarTypeToggle(false);
                      }}
                    >
                      {t("internal", { ns: "common" })}
                    </h5>
                  </div>
                </div>

                {/* Length */}
                <div className="sidebar_container">
                  <div className="quantity">
                    <h1 className="cages_sidebar_label mb-0">
                      {t("length", { ns: "common" })}
                      <span
                        className={
                          "cages_units " + (rightToLeft ? "pr-1" : "pl-1")
                        }
                        style={{ paddingLeft: "3px" }}
                      >
                        {t("cm", { ns: "tool" })}
                      </span>
                    </h1>
                  </div>
                  <input
                    type="number"
                    min={0}
                    className={
                      "para text-center text-ash input-inside cages_sidebar_input" +
                      editModeClass
                    }
                    value={shapeObj.ear_length || ""}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => {
                      setShape({
                        ...shapeObj,
                        ear_length: e.target.value || 0,
                      });
                    }}
                    onBlur={() => addUpdatedClass(pileSidebarInput.EAR_LENGTH)}
                    ref={(el) => {
                      if (el)
                        siderBarRefs.current[pileSidebarInput.EAR_LENGTH] = el;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp") {
                        e.preventDefault();
                        siderBarRefs.current[
                          pileSidebarInput.SPIRAL_WIRE_DIAMETER
                        ].focus();
                      } else if (e.key === "ArrowDown" || e.key === "Enter") {
                        e.preventDefault();
                        if (ring) {
                          siderBarRefs.current[
                            pileSidebarInput.RING_QUANTITY
                          ].focus();
                        } else if (gama) {
                          siderBarRefs.current[
                            pileSidebarInput.GAMMA_QUANTITY
                          ].focus();
                        } else if (showNote) {
                          siderBarRefs.current[pileSidebarInput.NOTE].focus();
                        } else {
                          siderBarRefs.current[
                            pileSidebarInput.QUANTITY
                          ].focus();
                        }
                      }
                    }}
                  />
                </div>
              </>
            )}

            {/* RINGS */}
            <div
              className="sidebar_container"
              style={{
                height: "25px",
                borderBottom: !ring ? "1px solid #D9D9D9" : "",
                borderTop: ear ? "1px solid #D9D9D9" : "",
              }}
            >
              <div className="cages_note">
                <h1
                  className="cages_sidebar_label mb-0"
                  style={{
                    fontSize: "10.5px",
                    lineHeight: "15px",
                    color: "#878C97",
                  }}
                >
                  {t("rings", { ns: "common" })}
                </h1>
                <div
                  className="ring_img"
                  onClick={() => {
                    setRing(!ring);
                  }}
                >
                  {ring ? (
                    <img
                      src={remove}
                      className="cages_collapsable_img"
                      alt="-"
                    />
                  ) : (
                    <img src={add} className="cages_collapsable_img" alt="+" />
                  )}
                </div>
              </div>
            </div>
            {ring && (
              <>
                {/* Rings Amount */}
                <div className="sidebar_container">
                  <div className="quantity">
                    <h1 className="cages_sidebar_label mb-0">
                      {t("amount", { ns: "common" })}
                    </h1>
                  </div>
                  <input
                    type="number"
                    min={0}
                    className={
                      "para text-center text-ash input-inside cages_sidebar_input" +
                      editModeClass
                    }
                    value={shapeObj.ring_quantity || ""}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => {
                      setShape({
                        ...shapeObj,
                        ring_quantity: parseInt(e.target.value) || 0,
                      });
                    }}
                    onBlur={() =>
                      addUpdatedClass(pileSidebarInput.RING_QUANTITY)
                    }
                    ref={(el) => {
                      if (el)
                        siderBarRefs.current[pileSidebarInput.RING_QUANTITY] =
                          el;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowDown" || e.key === "Enter") {
                        if (e.key === "ArrowDown") {
                          e.preventDefault();
                        }
                        siderBarRefs.current[
                          pileSidebarInput.RING_DIAMETER
                        ].focus();
                      } else if (e.key === "ArrowUp") {
                        e.preventDefault();
                        if (ear) {
                          siderBarRefs.current[
                            pileSidebarInput.EAR_LENGTH
                          ].focus();
                        } else {
                          siderBarRefs.current[
                            pileSidebarInput.SPIRAL_WIRE_DIAMETER
                          ].focus();
                        }
                      }
                    }}
                  />
                </div>

                {/* Rings Diameter */}
                <div className="sidebar_container">
                  <div className="total_lengths">
                    <h1 className="cages_sidebar_label mb-0">
                      {t("diameter", { ns: "tool" })}
                      <span
                        className={
                          "cages_units " + (rightToLeft ? "pr-1" : "pl-1")
                        }
                        style={{ paddingLeft: "3px" }}
                      >
                        {t("mm", { ns: "tool" })}
                      </span>
                    </h1>
                  </div>
                  <select
                    className={
                      "form-control form-control-sm cages_sidebar_input" +
                      editModeClass
                    }
                    value={shapeObj?.ring_diameter?.id || ""}
                    onChange={(e) => {
                      const _diameter = getPolygonDiameter(parseInt(e.target.value));
                      if (_diameter) {
                        setShape({
                          ...shapeObj,
                          ring_diameter: _diameter,
                        });
                      }
                    }}
                    onBlur={() =>
                      addUpdatedClass(pileSidebarInput.RING_DIAMETER)
                    }
                    ref={(el) => {
                      if (el)
                        siderBarRefs.current[pileSidebarInput.RING_DIAMETER] =
                          el;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp") {
                        e.preventDefault();
                        siderBarRefs.current[
                          pileSidebarInput.RING_QUANTITY
                        ].focus();
                      } else if (e.key === "ArrowDown" || e.key === "Enter") {
                        e.preventDefault();
                        if (gama) {
                          siderBarRefs.current[
                            pileSidebarInput.GAMMA_QUANTITY
                          ].focus();
                        } else if (showNote) {
                          siderBarRefs.current[pileSidebarInput.NOTE].focus();
                        } else {
                          siderBarRefs.current[
                            pileSidebarInput.QUANTITY
                          ].focus();
                        }
                      }
                    }}
                  >
                  {shapeObj.cage_steel_type in steelDiameters &&
                  steelDiameters[polygonSteelType].map(
                    (reDi: any, index: number) => {
                      if (
                        parseFloat(reDi?.steel_diameter) >= 12 &&
                        parseFloat(reDi?.steel_diameter) <= 36
                      ) {
                        return (
                          <option
                            key={"ring-steel-diameter-options-" + index}
                            value={reDi.id}
                          >
                            {parseFloat(reDi?.steel_diameter)}
                          </option>
                        );
                      } else {
                        return null;
                      }
                    }
                  )}
                  </select>
                </div>
              </>
            )}

            {/* Gamma Pipes */}
            <div
              className="sidebar_container"
              style={{
                height: "25px",
                borderBottom: !gama ? "1px solid #D9D9D9" : "",
                borderTop: ring ? "1px solid #D9D9D9" : "",
              }}
            >
              <div className="cages_note">
                <h1
                  className="cages_sidebar_label mb-0"
                  style={{
                    fontSize: "10.5px",
                    lineHeight: "15px",
                    color: "#878C97",
                  }}
                >
                  {t("gamma pipes", { ns: "common" })}
                </h1>
                <div
                  className="gama_img"
                  onClick={() => {
                    setGama(!gama);
                  }}
                >
                  {gama ? (
                    <img
                      src={remove}
                      className="cages_collapsable_img"
                      alt="-"
                    />
                  ) : (
                    <img src={add} className="cages_collapsable_img" alt="+" />
                  )}
                </div>
              </div>
            </div>
            {gama && (
              <>
                {/* Gamma Pipes Amount */}
                <div className="sidebar_container">
                  <div className="quantity">
                    <h1 className="cages_sidebar_label mb-0">
                      {t("amount", { ns: "common" })}
                    </h1>
                  </div>
                  <select
                    className={
                      "form-control form-control-sm cages_sidebar_input" +
                      editModeClass
                    }
                    value={shapeObj.gama_quantity || ""}
                    onChange={(e) => {
                      setShape({
                        ...shapeObj,
                        gama_quantity: e.target.value || 0,
                      });
                    }}
                    onBlur={() =>
                      addUpdatedClass(pileSidebarInput.GAMMA_QUANTITY)
                    }
                    ref={(el) => {
                      if (el)
                        siderBarRefs.current[pileSidebarInput.GAMMA_QUANTITY] =
                          el;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowDown" || e.key === "Enter") {
                        if (e.key === "ArrowDown") {
                          e.preventDefault();
                        }
                        siderBarRefs.current[
                          pileSidebarInput.GAMMA_DIAMETER
                        ].focus();
                      } else if (e.key === "ArrowUp") {
                        e.preventDefault();
                        if (ring) {
                          siderBarRefs.current[
                            pileSidebarInput.RING_DIAMETER
                          ].focus();
                        } else if (ear) {
                          siderBarRefs.current[
                            pileSidebarInput.EAR_LENGTH
                          ].focus();
                        } else {
                          siderBarRefs.current[
                            pileSidebarInput.SPIRAL_WIRE_DIAMETER
                          ].focus();
                        }
                      }
                    }}
                  >
                    {pileGamaAmountOptions.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Gamma Pipes Diameter */}
                <div className="sidebar_container">
                  <div className="total_lengths">
                    <h1 className="cages_sidebar_label mb-0">
                      {t("diameter", { ns: "tool" })}
                      <span
                        className={
                          "cages_units " + (rightToLeft ? "pr-1" : "pl-1")
                        }
                        style={{ paddingLeft: "3px" }}
                      >
                        {t("zoll", { ns: "tool" })}
                      </span>
                    </h1>
                  </div>
                  <select
                    className={
                      "form-control form-control-sm cages_sidebar_input" +
                      editModeClass
                    }
                    value={parseFloat(shapeObj.gama_diameter) || ""}
                    onChange={(e) =>
                      setShape({ ...shapeObj, gama_diameter: parseFloat(e.target.value) })
                    }
                    onBlur={() =>
                      addUpdatedClass(pileSidebarInput.GAMMA_DIAMETER)
                    }
                    ref={(el) => {
                      if (el)
                        siderBarRefs.current[pileSidebarInput.GAMMA_DIAMETER] =
                          el;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowDown" || e.key === "Enter") {
                        e.preventDefault();
                        if (showNote) {
                          siderBarRefs.current[pileSidebarInput.NOTE].focus();
                        } else {
                          siderBarRefs.current[
                            pileSidebarInput.QUANTITY
                          ].focus();
                        }
                      } else if (e.key === "ArrowUp") {
                        e.preventDefault();
                        siderBarRefs.current[
                          pileSidebarInput.GAMMA_QUANTITY
                        ].focus();
                      }
                    }}
                  >
                    <option value="2">2</option>
                    <option value="2.5">2.5</option>
                  </select>
                </div>
              </>
            )}

            {/* Note */}
            <div
              className="sidebar_container"
              style={{
                height: "25px",
                borderBottom: !showNote ? "1px solid #D9D9D9" : "",
                borderTop: gama ? "1px solid #D9D9D9" : "",
              }}
            >
              <div className="cages_note">
                <h1
                  className="cages_sidebar_label mb-0"
                  style={{
                    fontSize: "10.5px",
                    lineHeight: "15px",
                    color: "#878C97",
                  }}
                >
                  {t("note", { ns: "common" })}
                </h1>
                <div
                  className="note_img"
                  onClick={() => {
                    setShowNote(!showNote);
                  }}
                >
                  {showNote ? (
                    <img
                      src={remove}
                      className="cages_collapsable_img"
                      alt="-"
                    />
                  ) : (
                    <img src={add} className="cages_collapsable_img" alt="+" />
                  )}
                </div>
              </div>
            </div>

            {showNote && (
              <div className="sidebar_container">
                <input
                  type="text"
                  min={0}
                  className={
                    "form-control cages_sidebar_input" +
                    editModeClass
                  }
                  style={{ width: "240px", height: "30px" }}
                  onFocus={(e) => e.target.select()}
                  value={orderItem.production_notes || ""}
                  onChange={(e) =>
                    setOrderItem({
                      ...orderItem,
                      production_notes: e.target.value,
                    })
                  }
                  onBlur={() => addUpdatedClass(pileSidebarInput.NOTE)}
                  ref={(el) => {
                    if (el) siderBarRefs.current[pileSidebarInput.NOTE] = el;
                  }}
                  onKeyDown={(e) => {
                    if (
                      e.key === "ArrowDown" ||
                      e.key === "Enter" ||
                      e.key === "ArrowRight"
                    ) {
                      e.preventDefault();
                      siderBarRefs.current[pileSidebarInput.QUANTITY].focus();
                    } else if (e.key === "ArrowUp") {
                      e.preventDefault();
                      if (gama) {
                        siderBarRefs.current[
                          pileSidebarInput.GAMMA_DIAMETER
                        ].focus();
                      } else if (ring) {
                        siderBarRefs.current[
                          pileSidebarInput.RING_DIAMETER
                        ].focus();
                      } else if (ear) {
                        siderBarRefs.current[
                          pileSidebarInput.EAR_LENGTH
                        ].focus();
                      } else {
                        siderBarRefs.current[
                          pileSidebarInput.SPIRAL_WIRE_DIAMETER
                        ].focus();
                      }
                    }
                  }}
                  maxLength={50}
                />
              </div>
            )}
          </div>

          <div className="cages_sidebar_footer cage_footer_shadow">
            {/* Weight */}
            <div
              className="sidebar_container cages_weight"
              style={{
                borderBottom: "1px solid #D9D9D9",
                borderTop: "1px solid #D9D9D9",
                height: "35px",
              }}
            >
              <div className="cages_weight_unit">
                <p className="cages_units" style={{ color: "#374051" }}>
                  {t("unit", { ns: "common" })}
                </p>
                <p className="cages_units" style={{ color: "#374051" }}>
                  {shapeObj.l1_length +
                    shapeObj.l2_length +
                    shapeObj.l3_length || 0}
                  <span className={rightToLeft ? "pr-1" : "pl-1"}>
                    {t("cm", { ns: "measurements" })}
                  </span>
                </p>
                <p className="cages_units" style={{ color: "#374051" }}>
                  {shapeObj.unit_weight || 0}
                  <span className={rightToLeft ? "pr-1" : "pl-1"}>
                    {t("kg", { ns: "tool" })}
                  </span>
                </p>
              </div>

              <div
                className="cages_weight_total"
                style={rightToLeft ? { left: "75px" } : { left: "140px" }}
              >
                <p className="cages_units" style={{ color: "#374051" }}>
                  {t("total", { ns: "common" })}
                </p>
                <p className="cages_units" style={{ color: "#374051" }}>
                  {(shapeObj.l1_length +
                    shapeObj.l2_length +
                    shapeObj.l3_length) *
                    orderItem?.quantity ||
                    shapeObj.l1_length +
                      shapeObj.l2_length +
                      shapeObj.l3_length}
                  <span className={rightToLeft ? "pr-1" : "pl-1"}>
                    {t("cm", { ns: "measurements" })}
                  </span>
                </p>
                <p className="cages_units" style={{ color: "#374051" }}>
                  {isNaN(shapeObj.unit_weight * orderItem.quantity) ||
                  orderItem.quantity < 1
                    ? "-"
                    : (shapeObj.unit_weight * orderItem.quantity)
                        .toFixed(0)
                        .toString()
                        .replace(commaSeparated, ",")}
                  <span className={rightToLeft ? "pr-1" : "pl-1"}>
                    {t("kg", { ns: "tool" })}
                  </span>
                </p>
              </div>
            </div>

            {/* Quantity */}
            <div className="sidebar_container">
              <div className="quantity">
                <h1 className="cages_sidebar_label_large mb-0">
                  {t("quantity", { ns: "common" })}
                </h1>
              </div>
              <input
                type="number"
                min={0}
                className={
                  "para text-center text-ash input-inside cages_sidebar_input" +
                  editModeClass
                }
                onFocus={(e) => e.target.select()}
                value={orderItem.quantity || ""}
                onChange={(e) => {
                  setOrderItem({
                    ...orderItem,
                    quantity:
                      parseInt(e.target.value) >= 0
                        ? parseInt(e.target.value)
                        : "",
                  });
                  if (
                    parseInt(e.target.value) === 0 ||
                    Number.isNaN(parseInt(e.target.value))
                  ) {
                    addError({
                      errorType: pileErrorTypes.QUANTITY,
                      message: t("error_cage_amount", { ns: "tool" }),
                    });
                  } else {
                    removeError(pileErrorTypes.QUANTITY);
                  }
                }}
                onBlur={() => addUpdatedClass(pileSidebarInput.QUANTITY)}
                ref={(el) => {
                  if (el) siderBarRefs.current[pileSidebarInput.QUANTITY] = el;
                }}
                onKeyDown={(e) => {
                  if (e.key === "ArrowDown" || e.key === "Enter") {
                    addButtonRef.current.focus();
                    e.preventDefault();
                  } else if (e.key === "ArrowUp") {
                    e.preventDefault();
                    if (showNote) {
                      siderBarRefs.current[pileSidebarInput.NOTE].focus();
                    } else if (gama) {
                      siderBarRefs.current[
                        pileSidebarInput.GAMMA_DIAMETER
                      ].focus();
                    } else if (ring) {
                      siderBarRefs.current[
                        pileSidebarInput.RING_DIAMETER
                      ].focus();
                    } else if (ear) {
                      siderBarRefs.current[pileSidebarInput.EAR_LENGTH].focus();
                    } else {
                      siderBarRefs.current[
                        pileSidebarInput.SPIRAL_WIRE_DIAMETER
                      ].focus();
                    }
                  }
                }}
              />
            </div>

            {/* Error Text */}
            {errorsText.length > 0 ? (
              <div className="cages_errors_text">
                {errorsText.slice(-2).map((error, index) => (
                  <div className="cages_error_continer" key={index}>
                    <img
                      src={error_sign}
                      alt="error"
                      style={{ height: "22px", marginRight: "0px" }}
                    />
                    <p
                      className={
                        "cages_error_text " + (rightToLeft ? "pr-2" : "pl-2")
                      }
                      style={{
                        fontWeight: 400,
                        fontSize: "10.5px",
                        lineHeight: "12px",
                        color: "#878C97",
                      }}
                    >
                      {error.message} {setIsShapeError(true)}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              setIsShapeError(false)
            )}

            <div
              className="sidebar_container add_button"
              style={
                rightToLeft
                  ? { paddingLeft: "169px" }
                  : { paddingRight: "169px" }
              }
            >
              <button
                onClick={async () => {
                  setInitiateImage(true);
                  savePile();
                  setShowNote(false);
                  setTimeout(() => {
                    siderBarRefs.current[pileSidebarInput.CAGE_NAME].focus();
                  }, 100);
                }}
                ref={(el) => {
                  if (el) addButtonRef.current = el;
                }}
                onFocus={handleFocus}
                onBlur={(e) => {
                  e.target.style.border = "none";
                  siderBarRefs.current[
                    pileSidebarInput.CAGE_NAME
                  ].classList.remove("nets_sidebar_input");
                  siderBarRefs.current[pileSidebarInput.CAGE_NAME].classList.add(
                    "nets_sidebar_input_updated"
                  );
                }}
                onKeyDown={(e) => {
                  if (e.key === "ArrowRight") {
                    finishButtonRef.current.focus();
                    e.preventDefault();
                  } else if (e.key === "ArrowUp") {
                    e.preventDefault();
                    siderBarRefs.current[pileSidebarInput.QUANTITY].focus();
                  }
                }}
              >
                {edit
                  ? t("update", { ns: "common" })
                  : t("add", { ns: "common" })}
              </button>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default SideBar;
