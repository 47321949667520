import Request from "./index";
import axios from "axios";


export const postDocumentName = (data: Object) =>
  Request.post("order-pdf-presigned-url/", data);

export const uploadDocument = async (url:any, data: any) => {
  let formData = new FormData();
  formData.append("file", data);
  const response = await axios.put(url, formData, {
    headers: {
      "x-amz-acl": "public-read"
    },
  });
  return response;
}


export const sendEmailData = (data: any) => 
  Request.post("share-order-pdf/", data);

export const sendEmailNonSupplier = (data: any) => 
  Request.post("share-order-pdf/?invite=True", data);