import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSelector } from "Redux/hooks";

const SectionHeader = (props: any) => {
  const rightToLeft = useAppSelector(state => state.root.rightToLeft);

  return (
    <div
      style={{
        borderTopLeftRadius: "15px",
        background: "linear-gradient(to top, #1F252E 0%, #374051 100%)",
        borderTopRightRadius: "15px",
        padding: "14px 30px",
      }}
    >
      <p
        style={{
          color: "white",
          width: "100%",
          textTransform: "uppercase",
          fontSize: "15px",
          fontWeight: "bold",
          textAlign: "start",
        }}
      >
        <FontAwesomeIcon
          style={rightToLeft ? {marginLeft: "10px" } : { marginRight: "10px" }}
          icon={props.icon}
        ></FontAwesomeIcon>{" "}
        {props.heading}
      </p>
    </div>
  );
};

export default SectionHeader;
