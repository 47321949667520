export const myOrderHe = {
    "orders created": "הזמנות שנוצרו",
    "this week": "השבוע",
    "sites delivery": "אתרים מחכים למשלוח",
    "for this week": "בשבוע הקרוב",
    "no delivery": "ללא משלוח",

    "my order": "ההזמנות שלי", 
    "create order": "יצירת הזמנה חדשה",
    "date order create": "תאריך יצירת ההזמנה",
    "order": "שם ההזמנה",
    "order no": "מספר הזמנה",
    "site": "פרויקט",
    "total weight": " סה״כ משקל (טון)",
    "Order Status": "סטטוס",
    "delivery date": "תאריך משלוח",
    "next delivery": "תאריך אספקה מבוקש",
    "add new": "הוסף",
    "weight": "משקל",

    "my sites": "האתרים שלי",
    "add new site": "הוסף אתר חדש",
    "site name": "שם אתר",
    "address": "כתובת",
    "site manager": "מנהל עבודה",
    "site man": "עובד באתר",
    "contact on site": "איש קשר באתר",

    "contact" : "איש קשר",
    "add new contact": "הוסף איש קשר חדש",
    "contacts": "אנשי קשר",
    "name" : "שם",

    "phone number": "טלפון",
    "job title": "תפקיד",
    'create new contact': 'צור איש קשר חדש',
    'contact details': 'פרטי איש קשר',
    'required fields': 'שדות חובה',
    'delete this contact': 'מחק איש קשר',
}