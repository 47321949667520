export const HeaderEn = {
    "login": "Login",
    'new order': 'New Order',
    'my order': 'My Order',
    'my profile': 'My Profile',
    'logout': 'Logout',
    'menu': 'menu',
    'my orders': 'My orders',
    'create your user': 'create your user',
    'visitor note': 'Use freely, just note you are running without a user, some actions will not be possible',
    'ticket creator': 'ticket creator',
}