import React, { useRef, useState, useEffect } from "react";
import brand_logo from "../../assets/img/logo.svg";
import close from '../../assets/img/new__user__form/close.png';
import userProfile from '../../assets/img/new__user/profile-user.png';
import file__icon from "../../assets/img/new__user/file__icon.png";
import {ReactComponent as PinIcon} from "../../assets/img/icon/pin.svg";
import {ReactComponent as FileIcon} from "../../assets/img/icon/file-plus.svg";
import { ReactComponent as TicketIcon } from "../../assets/img/icon/ticket.svg";
import Supplier_En from "../../assets/img/ticket__create/supplier_en.svg";
import Supplier_He from "../../assets/img/ticket__create/supplier_he.svg";
import power from "../../assets/img/icon/power-32.png";
import profile_user from "../../assets/img/new__user/user_profile.svg";
import buttom_arrow from "../../assets/img/new__user/bottom-arrow.png";
import { Link, NavLink } from "react-router-dom";
import { useAppSelector } from "Redux/hooks";
import Login from "./login";
import { setRightToLeft } from "Redux/features/root";
import CreateNewUser from "Components/new-order/create-new-user";
import { useTranslation } from "react-i18next";
import "./header.scss";

function Header({showLoginOption=true,setCheckLogout}) {
  const logoutRef = useRef<HTMLDivElement>(null);
  const userData = useAppSelector(state => state.user.userData);
  const userImg = useAppSelector((state) => state.user.profilePicture);
  const [showLogin, setShowLogin] = useState(false);
  const [userModalShow, setUserModalShow] = useState(false);
  const [showCreateUser, setShowCreateUser] = useState(true);
  const { t } = useTranslation(['common', 'header']);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  return (
    <>
    <header id="head">
      <nav id="navbar" className="w-100 py-3 py-lg-0">
        <div className="container-fluid">
          <div className={"row" + (rightToLeft ? " rightToLeft" : "")}>
            {/* <!-- brand and order links */}
            <div className="header-bar">            
              <div
                className="
                            header-links
                            pl-4
                            pr-4
                            d-flex
                            align-items-center
                        "
              >
                <Link to="/new-order"> 
                  <img
                    className="brand__icon"
                    src={brand_logo}
                    alt="brand icon"
                  />
                </Link>
              </div>
                <NavLink
                  to="/new-order"
                  className="
                              d-none d-lg-flex
                              align-items-lg-center
                              col-3 col-xxl-2
                              py-lg-3 text-muted
                          "
                  activeClassName="active__mark"
                  exact
                >
                  <FileIcon />
                  <p
                    className="
                                  order__text
                                  text-uppercase
                                  d-inline-block
                                  ms-2
                              "
                  >
                    {t('new order', {ns: 'header'})}
                  </p>
                </NavLink>
              {userData &&
              <>
                <NavLink
                  to="/my-order"
                  className="
                              d-none d-lg-flex
                              align-items-lg-center
                              col-3 col-xxl-2
                              py-lg-3 text-muted
                        "
                  activeClassName="active__mark"
                  exact
                >
                  <PinIcon />
                  <p
                    className="
                                  order__text
                                  text-uppercase
                                  d-inline-block
                                  ms-2
                              "
                  >
                    {t('my orders', {ns: 'header'})}
                  </p>
                </NavLink>
                <NavLink
                  to="/ticket-creator"
                  className="
                              d-none d-lg-flex
                              align-items-lg-center
                              align-items-center
                              col-4 col-xxl-3
                              py-lg-3 text-muted
                        "
                  activeClassName="active__mark"
                  exact
                >
                  <TicketIcon />
                  <p
                    className="
                                  order__text
                                  text-uppercase
                                  d-inline-block
                                  ms-2
                              "
                    style={{
                      fontSize: "15px"
                    }}
                  >
                    {t('ticket creator', {ns: 'header'})}
                  </p>
                  <span>
                    {
                      rightToLeft ? (
                        <img style={{width: "48px", height: "21px", marginBottom: "2px"}} src={Supplier_He} alt="Ticket Creator He" />
                      ):(
                        <img style={{width: "57px", height: "19px", paddingLeft: "8px", marginBottom: "3px"}} src={Supplier_En} alt="Ticket Creator En" />
                      )
                    }
                  </span>
                </NavLink>
              </>
              }
            </div>
            {/* <!-- user section  --> */}
            <div
              className="
                            user__section
                            d-none d-lg-flex
                            user-profile
                            justify-content-lg-end
                        "
            >
              {showLoginOption && userData ?
                <>
                <div
                  className="user d-flex flex-row align-items-center"
                  // type="button"
                  data-toggle="collapse"
                  data-target="#userArea"
                  aria-expanded="false"
                  aria-controls="userArea"
                  style={{cursor: 'pointer'}}
                > 
                {
                  userImg ? (
                    <div 
                      className={"img__bg" + (rightToLeft ? " img_ml":" img_mr")} 
                    >
                      <img
                        src={userImg}
                        className="user__pic"
                        alt="profile user icon"
                      />    
                    </div>
                  ):(
                    <img
                    className="user__img"
                    src={profile_user}
                    alt="profile user icon"
                  />
                  )
                }
                  <h3 className={"text-uppercase text-white mb-0 " + (rightToLeft && userImg ? "mr-0" : "")}>{userData.first_name} {userData.last_name}</h3>
                  <img className="arrow" src={buttom_arrow} alt="" />
                </div>
                {/* <!----------------- user area ---------------------  --> */}
                <div className={"collapse" + (rightToLeft ? " rightToLeft" : "")} id="userArea">
                  <ul className="list-group bg-dark">
                    <li className="
                                          list-group-item
                                          bg-dark
                                          text-center
                                          text-capitalize
                                          
                                      "><Link className="text-decoration-none text-white" to="/view-profile">{t('my profile', {ns: 'header'})}</Link></li>
                    <li
                      onClick={()=>setCheckLogout(true)}
                      className="
                                          list-group-item
                                          bg-dark
                                          text-center
                                          text-capitalize
                                          text-white
                                      "
                    >
                      {t('logout', {ns: 'header'})}
                    </li>
                  </ul>
                </div>
                {/* <!-- ------------------ user area -------------------------> */}
                </>
                :
                <div
                  className="user d-flex flex-row align-items-center"
                  // type="button"
                  data-toggle="collapse"
                  data-target="#userArea"
                  aria-expanded="false"
                  aria-controls="userArea"
                >
                  <button className="btn text-uppercase text-white" onClick={() => setShowLogin(true)}>
                    {t('login', {ns: 'header'})}
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
      </nav>
      {/* <!-- small screeen accordion  --> */}
      {showLoginOption && 
        <div className="sm__accordion d-lg-none">
          <div className="container-fuild">
            <div className="accordion" id="accordionExample">
              <div className="card border-0 bg-transparent px-0">
                <div className="card-header p-0 d-flex justify-content-between" id="headingTwo">
                  <button
                    className="
                                        btn btn-link btn-block
                                        text-left
                                        collapsed
                                        px-0
                                        w-xs-30
                                    "
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <img
                      className="file__icon"
                      src={file__icon}
                      alt="file icons"
                    />
                    <span
                      className="
                                            file__texts
                                            text-uppercase text-white
                                        "
                    >
                      {t('menu', {ns: 'header'})}
                    </span>
                  </button>
                  {userData ?
                    <div
                      className="d-flex align-items-center"
                      data-toggle="collapse"
                      data-target="#collapseLogout"
                      aria-expanded="false"
                      aria-controls="collapseLogout"
                    >
                      <img
                        style={{height: '26px'}}
                        className="user__img mr-2"
                        src={profile_user}
                        alt="profile user icon"
                      />
                      <h5 className="text-uppercase text-white mb-0 mr-2">{userData.first_name} {userData.last_name}</h5>
                    </div>
                  :
                    <button className="btn text-uppercase text-white" onClick={() => setShowLogin(true)}>
                      {t('login', {ns: 'header'})}
                    </button>
                  }
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div className="card-body py-0">
                    <NavLink
                      to="/new-order"
                      className="item d-block py-2"
                      exact
                    >
                      <FileIcon />
                      <p
                        className="
                                              sm__file__text
                                              text-white text-uppercase
                                              d-inline-block
                                              ms-2
                                              mb-0
                                          "
                      >
                        {t('new order', {ns: 'header'})}
                      </p>
                    </NavLink>
                  {userData &&
                    <NavLink
                      to="/my-order"
                      className="
                                          item
                                          border-bottom border-white
                                          d-block
                                          py-2
                                      "
                      exact
                    >
                      <PinIcon/>
                      <p
                        className="
                                              sm__file__text
                                              text-white text-uppercase
                                              d-inline-block
                                              ms-2
                                              mb-0
                                          "
                      >
                        {t('My orders', {ns: 'header'})}
                      </p>
                    </NavLink>
                  }
                  </div>
                </div>
                <div
                  ref={logoutRef}
                  id="collapseLogout"
                  className="collapse"
                  data-parent="#accordionExample"
                >
                  <div className="card-body py-0">
                    <div
                      className="
                                          item
                                          border-bottom border-white
                                          d-block
                                          py-2
                                      "
                      onClick={() => {
                        if (logoutRef.current) {
                          const collap = logoutRef.current;
                          collap.className = "collapse";
                        }
                        setCheckLogout(true);
                      }}
                    >
                      <img
                        className="
                                              sm__file__icon
                                              d-inline-block
                                              mr-2
                                          "
                        src={power}
                        alt="file icon"
                      />
                      <p
                        className="
                                              sm__file__text
                                              text-white text-uppercase
                                              d-inline-block
                                              ms-2
                                              mb-0
                                          "
                      >
                        {t('logout', {ns: 'header'})}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </header>
    {/* {showLoginOption && !userData && showCreateUser &&
      <section className="d-flex justify-content-between p-3" style={{background: '#262729'}}>
        <div className="d-flex align-items-center">
          <img height={15} src={close} alt="close icon" style={{cursor: 'pointer'}} onClick={() => setShowCreateUser(false)}/>
          <p className={"text-white " + (rightToLeft ? "mr-5" : "ml-5")}>{t('visitor note', {ns: 'header'})}.</p>
        </div>
        <button
          style={{
            borderRadius: '25px',
            background: '#2BC454',
            padding: '2.5px 17px',
            border: '2px solid #2BC454',
          }}
          className="d-flex align-items-center text-uppercase text-white font-weight-bold"
          onClick={() => setUserModalShow(true)}
        >
          <img src={userProfile} alt="user icon" height={20} className={rightToLeft ? "ml-2" : "mr-2"}/>
          <span ></span>{t('create your user', {ns: 'header'})}
        </button>
      </section>
    } */}
    { showLoginOption && !userData &&
      <>
        <Login show={showLogin}  onHide={() => setShowLogin(!showLogin)} />
        <CreateNewUser
          show={userModalShow}
          openLogin={() => setShowLogin(true)}
          onHide={() => setUserModalShow(false)}
        />
      </>
    }
    </>
  );
}

export default Header;
