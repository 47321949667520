import "./index.css";
import {useState, useEffect} from "react";
import UserDetails from "./user-details/userDetails";
//import CompanyDetails from "./company-details/companyDetails";
import CompaniesList from "./companies-list/index";
import Notifications from "./notifications/notifications";
import AddNewCompany from "../new-order/add-new-business/index-copy";
import Language from "./langauge/language";
import CustomerLayout from "../../layouts/customer";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "Redux/hooks";
import AlertModal from "./../custom-alert-modal";
import DeleteCompanyModal from "./companies-list/delete-company-modal/index";
import { Toaster } from 'react-hot-toast';

const ViewProfile = () => {
  const { t } = useTranslation(["common", "userProfile"]);
  
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const userData = useAppSelector((state) => state.user.userData);
  const userBusinesses = useAppSelector((state) => state.business.userBusinesses);

  const [companyModalShow, setCompanyModalShow] = useState(false);
  const [editCompany, setEditCompany] = useState(false);
  const [activeCompanyEdit, setActiveCompanyEdit] = useState({});
  const [deleteMessageShow, setDeleteMessageShow] = useState(false);
  const [activeCompany, setActiveCompany] = useState(userData.current_company);
  const [companytoDelete, setCompanyToDelete] = useState({});

  return (
    <CustomerLayout>
      <div className="container" style={{ margin: "50px auto" }}>
        <h3
          className={rightToLeft ? "text-right" : "text-left"}
          style={{ margin: "20px 0px", fontWeight: "bold" }}
        >
          {t("Account Settings", { ns: "userProfile" })}
        </h3>
        <UserDetails />
        <CompaniesList
          onNewCompanyModalOpen={() => {
            setCompanyModalShow(true);
            setEditCompany(false);
          }}
          handleEditCompany={() => {
            setCompanyModalShow(true);
            setEditCompany(true);
          }}
          setActiveCompanyEdit={setActiveCompanyEdit}
          setCompanyToDelete={setCompanyToDelete}
          setActiveCompany={setActiveCompany}
          activeCompany={activeCompany}
          setDeleteMessageShow={setDeleteMessageShow}
          userData={userData}
          userBusinesses={userBusinesses}
        />
        <Notifications />
        <Language />
        <AddNewCompany
          show={companyModalShow}
          onHide={() => setCompanyModalShow(false)}
          edit={editCompany}
          business={activeCompanyEdit}
          currentCompany={false}
        />
        <DeleteCompanyModal
          show={deleteMessageShow}
          onHide={() => setDeleteMessageShow(false)}
          userData={userData}
          userBusinesses={userBusinesses}
          companytoDelete={companytoDelete}
        />
        <Toaster
          position="bottom-right"
          reverseOrder={false}
          toastOptions={{
            style: {
              backgroundColor: '#00B38F',
              color: "#fff",
            },
          }}      
      />
      </div>
    </CustomerLayout>
  );
};

export default ViewProfile;
