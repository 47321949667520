import React, { useMemo, useState } from 'react';

import { Stage, Layer, Shape, Text } from 'react-konva';
import { Html } from "react-konva-utils";
import Measure from 'react-measure';
import { calculateNextPoint, calculate_angle_points, calcualte_distance_points } from 'Components/tool/utils';
import BaseStage from './baseStage';
import SideStage from './sideStage';
import { isBrowser, isMobile } from "react-device-detect";

function PileShape({
  ear,
  sideSpilt,
  shapeObj,
  setShape,
  labelingPoints,
  setLabelingPoints,
  base,
  stageRef,
}: any) {
  const [canvasWidth, setCanvasWidth] = useState(30);
  const [canvasHeight, setCanvasHeight] = useState(30);

  const length = useMemo(() => {
    const l1 = parseInt(shapeObj.l1_length);
    const l2 = parseInt(shapeObj.l2_length);
    const l3 = parseInt(shapeObj.l3_length);
    const val = l1 + l2 + l3;
    return val ? val : 0; 
  }, [shapeObj.l1_length,shapeObj.l2_length,shapeObj.l3_length]);

  function canvasSizeChanged(contentRect: any) {
    setCanvasHeight(contentRect.bounds.height);
    setCanvasWidth(contentRect.bounds.width);
  }

  const shouldShowInputFields=()=>{
    if(isMobile){
      return false;
    }
    if(shapeObj.l1_length + shapeObj.l2_length<110){
      return false;
    }

    if(shapeObj.l2_length + shapeObj.l3_length<210){
      return false;
    }
    if(shapeObj.l2_length<40){
      return false;
    }
    if(shapeObj.l3_length<65){
      return false;
    }
    return true;
  }

  const steps = 30;
  const width = labelingPoints.l3_end.x - labelingPoints.l1_start.x;

  return (
    <Measure bounds onResize={canvasSizeChanged}>
      {({ measureRef }) => {
        return (
          <div className={isMobile ? "right__img w-xs-100 mobile-height" : "high__img"} style={{ display: 'flex' }} ref={measureRef}>
            <Stage width={canvasWidth * (isMobile ? 1 : base ? 0.8 : 0.6)} height={canvasHeight} ref={stageRef}>
              <Layer>
                <Shape
                  sceneFunc={(context, shape) => {
                    if (shapeObj && canvasHeight > 250 && canvasWidth > 250) {
                      context.beginPath();

                      const width = canvasWidth * (isMobile ? 1 : base ? 0.8 : 0.6);
                      const height = canvasHeight;

                      let L = length ? length : 0;
                      let rescale_factor = 1

                      let gap_l2 = shapeObj.l2_gap
                      let gap_l3 = shapeObj.l3_gap

                      let diameter = 50;

                      let vertical_gap_1 = shapeObj.cover / 2
                      let vertical_gap_2 = shapeObj.cage_diameter

                      if (L > width) {
                        rescale_factor = (width / L) - 0.1
                      }
                      else if (diameter > height) {
                        rescale_factor = height / diameter
                      }

                      const canvus_mid = { x: width / 2, y: height / 2 };

                      L = L * rescale_factor
                      let L1 = parseInt(shapeObj.l1_length) * rescale_factor
                      let L2 = parseInt(shapeObj.l2_length) * rescale_factor
                      let L3 = parseInt(shapeObj.l3_length) * rescale_factor
                      diameter = diameter * rescale_factor

                      vertical_gap_1 = vertical_gap_1 * rescale_factor
                      vertical_gap_2 = vertical_gap_2 * rescale_factor

                      gap_l2 = gap_l2 * rescale_factor
                      gap_l3 = gap_l3 * rescale_factor

                      // Centering the image
                      const default_first_point = { x: 0, y: 0 };
                      // image mid
                      const object_mid_point = { x: L / 2, y: shapeObj.hole_diameter / 2 };
                      // angle between image center and image starting point
                      let center_to_first_angle = calculate_angle_points(object_mid_point, default_first_point);
                      const center_to_first_distance = calcualte_distance_points(object_mid_point, default_first_point);

                      // New image first_point centered at canvas center
                      let first_point = calculateNextPoint(canvus_mid, center_to_first_angle, center_to_first_distance);
                      // Presicion error corrections
                      const angle = calculate_angle_points(canvus_mid, first_point);
                      first_point = calculateNextPoint(canvus_mid, angle, center_to_first_distance)

                      const second_point = { x: first_point.x, y: first_point.y + vertical_gap_1 }
                      const third_point = { x: first_point.x, y: second_point.y + vertical_gap_2 }
                      const fourth_point = { x: first_point.x, y: third_point.y + vertical_gap_1 }

                      diameter = fourth_point.y - first_point.y

                      setLabelingPoints((previousState: any) => ({
                        ...previousState,
                        l1_start: first_point,
                        l2_start: { x: first_point.x + L1, y: first_point.y },
                        l3_start: { x: first_point.x + L1 + L2, y: first_point.y },
                        l3_end: { x: first_point.x + L1 + L2 + L3, y: first_point.y },

                        gap1_start: { x: first_point.x + L1, y: first_point.y + diameter },
                        gap1_end: { x: first_point.x + L1 + gap_l2, y: first_point.y + diameter },

                        gap2_start: { x: first_point.x + L1 + L2, y: first_point.y + diameter },
                        gap2_end: { x: first_point.x + L1 + L2 + gap_l3, y: first_point.y + diameter },

                        ear_start: { x: first_point.x, y: first_point.y + diameter },
                        ear_end: { x: first_point.x, y: first_point.y + diameter - 5 },
                      }));

                      let points = [first_point, second_point, third_point, fourth_point]

                      let L2_zig_zag = { x: fourth_point.x + L1, y: fourth_point.y }
                      context.moveTo(L2_zig_zag.x, L2_zig_zag.y);

                      let previousXOfL2 = 0, breaked = false;
                      for (let i = 0; i < L2; i = i + gap_l2) {
                        previousXOfL2 = L2_zig_zag.x;
                        let upper_radius_start_point = calculateNextPoint(L2_zig_zag, 270, diameter);
                        context.lineTo(upper_radius_start_point.x, upper_radius_start_point.y);
                        L2_zig_zag.x = L2_zig_zag.x + gap_l2
                        if (L2_zig_zag.x < fourth_point.x + L1 + L2) context.lineTo(L2_zig_zag.x, L2_zig_zag.y);
                        else {
                          breaked = true;
                          break;
                        }
                      }

                      if (breaked) {
                        setLabelingPoints((previousState: any) => ({
                          ...previousState,
                          l3_start: { x: previousXOfL2, y: first_point.y },
                          gap2_start: { x: previousXOfL2, y: first_point.y + diameter },
                          gap2_end: { x: previousXOfL2 + gap_l3, y: first_point.y + diameter },
                        }));
                      }

                      let L3_zig_zag = { x: breaked ? previousXOfL2 : L2_zig_zag.x, y: fourth_point.y }

                      context.moveTo(L3_zig_zag.x, L3_zig_zag.y);

                      let Z = 0;
                      for (let i = 0; i < L3; i = i + gap_l3) {
                        Z = (fourth_point.x + L1 + L2 + L3) - L3_zig_zag.x;
                        let upper_point = calculateNextPoint(L3_zig_zag, 270, diameter);
                        if (!breaked) {
                          context.lineTo(upper_point.x, upper_point.y);
                        } else {
                          context.moveTo(upper_point.x, upper_point.y);
                          breaked = false;
                        }
                        L3_zig_zag.x = L3_zig_zag.x + gap_l3;
                        if (L3_zig_zag.x < fourth_point.x + L1 + L2 + L3) context.lineTo(L3_zig_zag.x, L3_zig_zag.y);
                        else break;
                      }

                      setLabelingPoints((previousState: any) => ({
                        ...previousState,
                        l3_end: { x: first_point.x + L1 + L2 + L3 - Z, y: first_point.y },
                      }));

                      for (let i = 0; i < 4; i++) {
                        let end_point = calculateNextPoint(points[i], 0, L - Z);
                        context.moveTo(points[i].x, points[i].y);
                        context.lineTo(end_point.x, end_point.y);
                      }

                      if (ear) {
                        for (let i = 0; i < 2; i++) {
                          context.moveTo(points[i].x, points[i].y);
                          context.lineTo(points[i].x, points[i].y + 5);
                        }
  
                        for (let i = 2; i < 4; i++) {
                          context.moveTo(points[i].x, points[i].y);
                          context.lineTo(points[i].x, points[i].y - 5);
                        }
                      }

                      context.fillStrokeShape(shape);
                    }
                  }}
                  stroke="black"
                  strokeWidth={2}
                />
              </Layer>

              <Layer>
                <Shape
                  sceneFunc={(context, shape) => {
                    if (shapeObj && canvasHeight > 250 && canvasWidth > 250) {
                      context.beginPath();
                      // <-------------------------------------------------top verticle line------------------------------------->
                      //left line
                      const topLeftPoint = labelingPoints.l1_start
                      let actualStartingPoint = topLeftPoint;
                      let nextPoint = calculateNextPoint(actualStartingPoint, 270, 2.4 * steps);
                      context.moveTo(actualStartingPoint.x, actualStartingPoint.y);
                      context.lineTo(nextPoint.x, nextPoint.y);

                      // upper horizontal line
                      actualStartingPoint = nextPoint;
                      nextPoint = calculateNextPoint(actualStartingPoint, 360, width);
                      context.moveTo(actualStartingPoint.x, actualStartingPoint.y);
                      context.lineTo(nextPoint.x, nextPoint.y);

                      // lower horizontal line
                      actualStartingPoint = { x: topLeftPoint.x, y: topLeftPoint.y - steps };
                      nextPoint = calculateNextPoint(actualStartingPoint, 360, width);
                      context.moveTo(actualStartingPoint.x, actualStartingPoint.y);
                      context.lineTo(nextPoint.x, nextPoint.y);

                      // right line
                      const l3EndPoint = labelingPoints.l3_end
                      actualStartingPoint = l3EndPoint;
                      nextPoint = calculateNextPoint(actualStartingPoint, 270, 2.4 * steps);
                      context.moveTo(actualStartingPoint.x, actualStartingPoint.y);
                      context.lineTo(nextPoint.x, nextPoint.y);

                      // l2 verticle line
                      const l2StartPoint = labelingPoints.l2_start
                      actualStartingPoint = l2StartPoint;
                      nextPoint = calculateNextPoint(actualStartingPoint, 270, steps);
                      context.moveTo(actualStartingPoint.x, actualStartingPoint.y);
                      context.lineTo(nextPoint.x, nextPoint.y);

                      // l3 verticle line
                      const l3StartPoint = labelingPoints.l3_start
                      actualStartingPoint = l3StartPoint;
                      nextPoint = calculateNextPoint(actualStartingPoint, 270, steps);
                      context.moveTo(actualStartingPoint.x, actualStartingPoint.y);
                      context.lineTo(nextPoint.x, nextPoint.y);

                      //<-----------------------------------------bottom verticle line --------------------------------------->
                      //left line
                      const gap1StartPoint = labelingPoints.gap1_start
                      actualStartingPoint = gap1StartPoint;
                      nextPoint = calculateNextPoint(actualStartingPoint, 90, steps);
                      context.moveTo(actualStartingPoint.x, actualStartingPoint.y);
                      context.lineTo(nextPoint.x, nextPoint.y);

                      const gap1EndPoint = labelingPoints.gap1_end
                      actualStartingPoint = gap1EndPoint;
                      nextPoint = calculateNextPoint(actualStartingPoint, 90, steps);
                      context.moveTo(actualStartingPoint.x, actualStartingPoint.y);
                      context.lineTo(nextPoint.x, nextPoint.y);

                      // horizontal line
                      actualStartingPoint = nextPoint;
                      nextPoint = calculateNextPoint(actualStartingPoint, 180, labelingPoints.gap1_end.x - labelingPoints.gap1_start.x);
                      context.moveTo(actualStartingPoint.x, actualStartingPoint.y);
                      context.lineTo(nextPoint.x, nextPoint.y);

                      //right line
                      const gap2StartPoint = labelingPoints.gap2_start
                      actualStartingPoint = gap2StartPoint;
                      nextPoint = calculateNextPoint(actualStartingPoint, 90, steps);
                      context.moveTo(actualStartingPoint.x, actualStartingPoint.y);
                      context.lineTo(nextPoint.x, nextPoint.y);

                      const gap2EndPoint = labelingPoints.gap2_end
                      actualStartingPoint = gap2EndPoint;
                      nextPoint = calculateNextPoint(actualStartingPoint, 90, steps);
                      context.moveTo(actualStartingPoint.x, actualStartingPoint.y);
                      context.lineTo(nextPoint.x, nextPoint.y);

                      // horizontal line
                      actualStartingPoint = nextPoint;
                      nextPoint = calculateNextPoint(actualStartingPoint, 180, labelingPoints.gap2_end.x - labelingPoints.gap2_start.x);
                      context.moveTo(actualStartingPoint.x, actualStartingPoint.y);
                      context.lineTo(nextPoint.x, nextPoint.y);

                      // <--------------------------------------left horizontal line ------------------------------------->
                      if (ear) {
                        // lower vertical
                        const earStartPoint = labelingPoints.ear_start
                        actualStartingPoint = earStartPoint;
                        nextPoint = calculateNextPoint(actualStartingPoint, 90, 2.8*steps);
                        context.moveTo(actualStartingPoint.x, actualStartingPoint.y);
                        context.lineTo(nextPoint.x, nextPoint.y);

                        // upper horizontal lower
                        const earEndPoint = labelingPoints.ear_end
                        actualStartingPoint = earEndPoint;
                        nextPoint = calculateNextPoint(actualStartingPoint, 180, 10);
                        context.moveTo(actualStartingPoint.x, actualStartingPoint.y);
                        context.lineTo(nextPoint.x, nextPoint.y);

                        // upper verticle line
                        actualStartingPoint = nextPoint;
                        nextPoint = calculateNextPoint(actualStartingPoint, 90,labelingPoints.ear_start.y - labelingPoints.ear_end.y + 2.8*steps );
                        context.moveTo(actualStartingPoint.x, actualStartingPoint.y);
                        context.lineTo(nextPoint.x, nextPoint.y);

                        //lower horizontal line

                        actualStartingPoint = nextPoint;
                        nextPoint = calculateNextPoint(actualStartingPoint, 360,10 );
                        context.moveTo(actualStartingPoint.x, actualStartingPoint.y);
                        context.lineTo(nextPoint.x, nextPoint.y);
                      }

                      // (!) Konva specific method, it is very important
                      context.fillStrokeShape(shape);
                    }
                  }}
                  stroke="grey"
                  strokeWidth={1}
                />


                <Text
                  text="@1"
                  x={(labelingPoints.gap1_start.x + labelingPoints.gap1_end.x) / 2 - 8}
                  y={labelingPoints.gap1_start.y + steps + 10}
                />
                  {shouldShowInputFields() &&
                    <Html
                      divProps={{
                        style: {
                          position: "absolute",
                          top: `${labelingPoints.gap1_start.y + steps + 25}px`,
                          left: `${(labelingPoints.gap1_start.x + labelingPoints.gap1_end.x) / 2 - 25}px`,
                        },

                      }}
                    >
                    <input
                    className="inputField"
                    type="number"
                    onFocus={(e) => e.target.select()}
                    value={shapeObj.l2_gap}
                    onChange={(e) => setShape({ ...shapeObj, l2_gap: parseInt(e.target.value) })}
                    />
                    </Html>
                  }
  
                  


                <Text
                  text="@2"
                  x={
                    shapeObj.l2_length > 170
                    ? (labelingPoints.gap2_start.x + labelingPoints.gap2_end.x) / 2 - 8
                    : (labelingPoints.gap2_start.x + labelingPoints.gap2_end.x) / 2 + 12
                  }
                  y={labelingPoints.gap2_start.y + steps + 10}
                />


                {shouldShowInputFields() &&
                  <Html
                    divProps={{
                      style: {
                        position: "absolute",
                        top: `${labelingPoints.gap2_start.y + steps + 25}px`,
                        left: `${
                          shapeObj.l2_length > 170
                          ? (labelingPoints.gap2_start.x + labelingPoints.gap2_end.x) / 2 - 25
                          : (labelingPoints.gap2_start.x + labelingPoints.gap2_end.x) / 2
                        }px`,
                      },

                    }}
                  >
                    <input
                      className="inputField"
                      type="number"
                      onFocus={(e) => e.target.select()}
                      value={shapeObj.l3_gap}
                      onChange={(e) => setShape({ ...shapeObj, l3_gap: parseInt(e.target.value) })}
                    />
                  </Html>
                }

                <Text
                  text="L"
                  x={(labelingPoints.l1_start.x + labelingPoints.l3_end.x) / 2 - 40}
                  y={labelingPoints.l1_start.y - 2.4 * steps - 18}
                />


                {shouldShowInputFields() &&
                  <Html
                    divProps={{
                      style: {
                        position: "absolute",
                        top: `${labelingPoints.l1_start.y - 2.4 * steps - 25}px`,
                        left: `${(labelingPoints.l1_start.x + labelingPoints.l3_end.x) / 2 - 25}px`,
                      },

                    }}
                  >
                    <input
                      className="inputField"
                      style={{ width: "80px" }}
                      value={length}
                      disabled={true}
                    />
                  </Html>
                }

                <Text
                  text="L1"
                  x={labelingPoints.l1_start.x + 15}
                  y={labelingPoints.l1_start.y - steps - 40}
                />


                {shouldShowInputFields() &&
                  <Html
                    divProps={{
                      style: {
                        position: "absolute",
                        top: `${labelingPoints.l1_start.y - steps - 25}px`,
                        left: `${(labelingPoints.l1_start.x + labelingPoints.l2_start.x) / 2 - 25}px`,
                      },

                    }}
                  >
                    <input
                      className="inputField"
                      type="number"
                      onFocus={(e) => e.target.select()}
                      value={shapeObj.l1_length || ""}
                      onChange={(e) => setShape({ ...shapeObj, l1_length: parseInt(e.target.value) })}
                    />
                  </Html>
                }

                <Text
                  text="L2"
                  x={(labelingPoints.l2_start.x + labelingPoints.l3_start.x) / 2 + 18}
                  y={labelingPoints.l2_start.y - steps - 40}
                />


                {shouldShowInputFields() &&
                  <Html
                    divProps={{
                      style: {
                        position: "absolute",
                        top: `${labelingPoints.l1_start.y - steps - 25}px`,
                        left: `${(labelingPoints.l2_start.x + labelingPoints.l3_start.x) / 2}px`,
                      },

                    }}
                  >
                    <input
                      className="inputField"
                      type="number"
                      onFocus={(e) => e.target.select()}
                      value={shapeObj.l2_length || ""}
                      onChange={(e) => setShape({ ...shapeObj, l2_length: parseInt(e.target.value) })}
                    />
                  </Html>
                }

                <Text
                  text="L3"
                  x={(labelingPoints.l3_start.x + labelingPoints.l3_end.x) / 2 + 5}
                  y={labelingPoints.l3_start.y - steps - 40}
                />



                {shouldShowInputFields() &&
                  <Html
                    divProps={{
                      style: {
                        position: "absolute",
                        top: `${labelingPoints.l3_start.y - steps - 25}px`,
                        left: `${(labelingPoints.l3_start.x + labelingPoints.l3_end.x) / 2 - 24}px`,
                      },

                    }}
                  >
                    <input
                      className="inputField"
                      style={{ width: "70px" }}
                      type="number"
                      onFocus={(e) => e.target.select()}
                      value={shapeObj.l3_length || ""}
                      onChange={(e) => setShape({ ...shapeObj, l3_length: parseInt(e.target.value) })}
                    />
                  </Html>
                }
                {/* ear */}

                {shouldShowInputFields() && ear &&
                  <Html
                    divProps={{
                      style: {
                        position: "absolute",
                        top: `${(labelingPoints.ear_start.y + labelingPoints.ear_end.y) / 2  + 2.8*steps}px`,
                        left: `${labelingPoints.ear_start.x  -30}px`,
                      },

                    }}
                  >
                    <input
                      className="inputField"
                      type="number"
                      onFocus={(e) => e.target.select()}
                      value={shapeObj.ear_length || ""}
                      onChange={(e) => setShape({ ...shapeObj, ear_length: parseInt(e.target.value) })}
                    />
                  </Html>
                }


              </Layer>
            </Stage>
            {isBrowser &&
              <>
                <BaseStage
                  canvasWidth={canvasWidth * 0.2}
                  canvasHeight={canvasHeight}
                  shapeObj={shapeObj}
                  base={base}
                  setShape={setShape}
                  sideSpilt={sideSpilt}
                />
                {!base &&
                  <SideStage
                  sideSpilt={sideSpilt}
                  canvasWidth={canvasWidth * (base ? 0 : 0.2)}
                  canvasHeight={canvasHeight}
                  shapeObj={shapeObj}
                  />
                }
              </>
            }
          </div>
        );
      }}
    </Measure>
  );
}

export default PileShape;