import React from "react";
import { Modal } from "react-bootstrap";
import close from '../../../assets/img/icon/cross.svg';
import OrderCreated from '../../../assets/img/icon/order-created.svg';
import brandLogo from "../../../assets/img/logo-dark.svg";
import {
  useHistory,
} from "react-router-dom";

const OrderSuccess = (props: any) => {

  const router = useHistory();

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="bg-light rounded d-flex flex-column px-4 pt-3 pb-4">
        <img src={close} alt="close button" onClick={props.onHide}
        style={{
          cursor: 'pointer',
          width: '21px',
          height: '23px',
        }}
        />
        <div className="d-flex flex-column align-items-center p-2">
          <img src={OrderCreated} alt="Order Created"
          style={{
            width: '203px',
            height: '203px',
          }}
          />
          <p
            style={{
              marginTop: '25px',
              textAlign: 'center',
              width: '278px',
              font: 'normal normal normal 30px/44px Heebo',
              letterSpacing: '0.6px',
              color: '#383B3E',
            }}
          >Your <img src={brandLogo} alt="logo"/> order has been successfully saved.</p>
        </div>
        <div style={{border: '1px solid #F0F0F0'}}></div>
        <p style={{
          marginTop: '11px',
          textAlign: 'center',
          font: 'normal normal normal 15px/22px Heebo',
          letterSpacing: '0.3px',
          color: '#959DAC'
        }}>What’s next?</p>
        <div className="d-flex flex-row justify-content-around">
          <button
            className="btn"
            style={{
              background: '#959DAC 0% 0% no-repeat padding-box',
              borderRadius: '23px',
              textAlign: 'center',
              font: 'normal normal normal 20px/29px Heebo',
              letterSpacing: '0.4px',
              color: '#FFFFFF',
              padding: '8px 30px',
            }}
            onClick={() => router.push('/my-order')}
          >go to my orders</button>
          <button
          className="btn"
          style={{
            background: '#374051 0% 0% no-repeat padding-box',
            borderRadius: '23px',
            textAlign: 'center',
            font: 'normal normal normal 20px/29px Heebo',
            letterSpacing: '0.4px',
            color: '#FFFFFF',
            padding: '8px 20px',
          }}
          onClick={() => props.onHide()}
          >create a new order</button>
        </div>
      </div>
    </Modal>
  );
}

export default OrderSuccess;