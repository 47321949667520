export const commonHe = {
  "Delivery Date": "תאריך משלוח",
  "Phone": "טלפון",
  "Address": "כתובת",
  "Number": "מספר",
  "Mail": "מייל",
  "Site Man": "איש קשר באתר",
  "User": "משתמש",
  "Rebars": "מוטות",
  "Nets": "רשתות",
  "Cages": "כלונסאות",
  "Rebar": "מוט",
  "Net": "רשת",
  "Cage": "כלונס",
  "type": "סוג",
  "diameter": "קוטר",
  "length": "אורך",
  "Amount": "כמות",
  "total": "סה״כ",
  "weight": "משקל",
  "unit": "יחידה",
  "units": "יחידות",
  "Price": "מחיר",
  "Step": "פסיעה",
  "Standard": "סטנדרט",
  "Special": "מיוחדת",
  "site title": "Text with logo",
  "Manage Users": "ניהול משתמשים",
  "Manage Steel Types": "ניהול סוגי מתכת",
  "Manage Diameter and Weight": "ניהול קטרים ומשקלים",
  "Logout": "התנתק",
  "Add a drawing": "הוסף שרטוט",
  "Preview": "תצוגה מקדימה",
  "Please enter values ": "בבקשה הכנס ערך",
  "Bar Number": "מספר מוט",
  "Steel type": "סוג מתכת",
  "Steel diameter": "קוטר",
  "sides": "צלעות",
  "Angled": "זווית",
  "Weight one bar": "משקל יחידה",
  "quantity": "כמות",
  "total weight": "סה״כ משקל",
  "notes": "הערות",
  "Bars": "מוטות",
  "Actions": "פעולות",
  "Edit": "עריכה",
  "Remove": "הסר",
  "select a business first to edit": "בחר עסק לעריכה ראשונית",
  "Company Added SuccessFully": "הוספת חברה נקלטה בהצלחה",
  "approval not checked": "האישור לא נבדק",
  "Business Details": "פרטי החברה",

  "Please Select Business": "בחר חברה",
  "business details show automatically": "פרטי החברה מוצגים אוטומטית",
  "Edit business": "ערוך פרטי החברה",
  "add a business": "הוסף חברה",
  "fill the window will create business card": "הוספת חברה חדשה",
  "name": "שם",
  "Name": "שם",
  "Business": "חברה",
  "Company Manager": "מנהל החברה",
  "Manager": "מנהל",
  "Email": "דואר אלקטרוני",
  "confirm that I work for this company": "אני מאשר שאני עובד עבור חברה זו",
  "Approval of regulations": "אני מאשר את התקנון",
  "Add business": "הוספת חברה",
  "Edit Company": "עריכת פרטי חברה",
  "fill the window will update Company": "עריכת פרטי החברה",
  "Diameter and Weight Added SuccessFully": "קוטר ומשקל הוספו בהצלחה",
  "Are you sure you wish to delete this Diameter and Weight?": "האם אתה בטוח רוצה למחוק קוטר ומשקל זה",
  "Diameters And Weights": "קוטר ומשקל",
  "Add new Diameter with Weight": "הוספת קוטר ומשקל חדש",
  "Diameter": "קוטר",
  "Weight": "משקל",
  "Add Diameter and Weight": "הוספת קוטר ומשקל",
  "fill the window will create new Diameter with Weight": "הוספת קוטר ומשקל חדש",
  "Add Diameter": "הוספת קוטר",
  "Edit Diameter and Weight": "עריכה קוטר ומשקל",
  "fill the window will update Diameter with Weight": "עריכת קוטר ומשקל",
  "Edit Diameter": "עריכת קוטר",
  "Are you sure you wish to delete this item?": "האם אתה בטוח רוצה למחוק פריט זה?",
  "Document Updated SuccessFully": "המסמך עודכן בהצלחה",
  "Document Added SuccessFully": "ההזמנה נוצר בהצלחה",
  "Please select business and site": "בבקשה בחר חברה ואתר",
  "Create Document": "יצירת הזמנה",
  "Document Number": "מספר הזמנה",
  "Order Details": "פרטי ההזמנה",
  "Order Date": "תאריך ההזמנה",

  "Create Date": "תאריך יצירת ההזמנה",
  "Add item": "הוספת פריט",
  "Signature": "חתימה",
  "Document": "הזמנה",
  "Update": "עדכן",
  "Save": "שמור",
  "Print": "הדפס",
  "Documents": "הזמנות",
  "Add new Document":" הוספת הזמנה חדשה",
  "date": "תאריך",
  "The site": "האתר",
  "contact": "אנשי קשר",
  "Share": "שתף",
  "Grid Number": "מספר רשת",
  "Weight one net": "משקל פריט",

  "step": "פסיעה",
  "Grids": "רשת",
  "Log In": "התחבר",
  "Password": "סיסמה",
  "horizontal": "רוחבי",
  "vertical": "אנכי",
  "spiral": "ספירלה",
  "Picket Number": "מספר כלונס",
  "Weight One Picket": "משקל כלונס",
  "Quantity": "כמות",
  "picket diameter": "קוטר הכלונס",
  "spiral diameter": "קוטר הספירלה",
  "total length":" סה״כ אורך",
  "spiral section 1": "ספירלה מקטע 1",
  "spiral section 2": "ספירלה מקטע 2",
  "spiral section end": "ספירלה קוץ",
  "space": "פסיעה",
  "total rounds": "מספר סיבובים",
  "Pickets": "כלונסאות",
  "preview": "תצוגה מקדימה",
  "Preview Document": "תצוגה מקדימה של הזמנה",
  "fill the window will show document preview": "מילוי חלון זה יראה תצוגה מקדימה של ההזמנה",
  "please correct the errors first": "בבקשה תקן את ההערה על מנת להמשיך",
  "update item": "עדכן פריט",
  "add item": "הוספת פריט",
  "fill the window will update item": "מילוי חלון זה יעדכן פריט",
  "fill the window will create new item": "מילוי חלון זה ייצור פריט חדש",
  "Choosen Element": "אלמנט נבחר",
  "Choose Element": "בחר אלמנט ",
  "grid": "רשת",
  "bars": "מוטות",
  "pickets": "כלונסאות",
  "Choosen Shape": "צורה נבחרת",
  "Choose Shape": "בחר צורה",
  "line": "ישר",
  "turn": "אוזן",
  "return": "אוזן חוזרת",
  "dn": "חיט",
  "hoop": "חישוק",
  "longstr": "longstr",
  "figure": "פיגורה",
  "Update item": "עדכון פריט",
  "Add Item": "הוספת פריט",
  "Email Added SuccessFully": "הוספת מייל בוצעה בהצלחה",
  "reload to get link": "טען מחדש לקבלת קישור",
  "Confirmation not checked": "האישור לא נבדק",
  "Are you sure you wish to remove this email?": "האם אתה בטוח שברצונך למחוק את המייל?",
  "sent Documents": "שלח הזמנה",
  "fill the window will share document with emails": "שתף הזמנה עם מיילים נוספים",
  "Add Email To share document": "הוסף מייל על מנת לשתף הזמנה",
  "I allow this email to edit document": "אני מאשר למייל זה לערוך מסמך",
  "Add Email": "הוספת מייל",
  "Link": "קישור",
  "select a building Site first to edit": "הוסף אתר בניה על מנת לערוך",
  "Building site Added SuccessFully": "הוספת אתר בניה בוצעה בהצלחה",
  "Business Site Details": "פרטי אתר בניה",
  "Site Name": "שם אתר הבניה",
  "Please Select Site": " בחר אתר בניה בבקשה",
  "site details show automatically": "פרטי אתר הבניה יוצגו אוטומטית",
  "Edit site": "ערוך אתר בניה",
  "add building site": "הוספת אתר בניה",
  "fill the window will create new building site": "מלא חלון על מנת להוסיף אתר בניה",
  "The Site": "אתר בניה",

  "City": "עיר",
  "street": "רחוב",

  "Note": "הערות",
  "Manager Site Details": "פרטי מנהל האתר",


  "Contact for Field Coordination": "איש קשר לתיאום בשטח",
  "i confirm that details are correct": "אני מאשר שהפרטים נכונים",
  "Edit Building site": "עריכת אתר הבניה",
  "fill the window will update Building site": "עדכון פרטי אתר הבניה",
  "Edit building site": "עריכת אתר הבניה",
  "Steel Type Added SuccessFully": "סוג מתכת נוסף בהצלחה",
  "Are you sure you wish to delete this Steel Type?":"האם אתה בטוח רוצה למחוק את סוג המתכת?",
  "Steel Types": "סוג המתכת",
  "Add new Steel Type": "הוספת סוג מתכת חדשה",
  "Add Steel Type": "הוסף סוג מתכת",
  "fill the window will create new Steel Type": "מילוי פרטים יצור סוג מתכת חדשה",
  "Edit Steel Type": "עריכת סוג המתכת",
  "fill the window will update Steel Type": "מילוי פרטים יעדכן את סוג המתכת",
  "User Added SuccessFully": "משתמש נוסף בהצלחה",
  "Are you sure you wish to delete this User?": "האם אתה בטוח רוצה למחוק משתמש זה?",
  "Users": "משתמשים",
  "Add new User": "הוספת משתמש חדש",
  "Admin": "מנהל",
  "Job": 'תפקיד',

  "Add User": "הוספת משתמש",
  "fill the window will create new User": "מילוי חלון זה יצור משתמש חדש",
  "Edit User":" עריכת משתמש",
  "fill the window will update User": "מילוי פרטים כדח לעדכן משתמש",
  "Creator Job": "תפקיד",

  "Delete": 'מחק',
  "cancel": "ביטול",
  "submit": "שלח",
  "required field": "שדות נדרשים",
  "Full name": "שם מלא",

  "mobileVersionHiddenForNow":"הגרסה לנייד עדיין בעבודה, לבנתיים אתם מוזמנים ליצור הזמנות ברזל במחשב",
  "user details": "פרטי כניסה לחשבון",
  "type password" : "הקלד את הסיסמה שלך",
  "type phone number": "הקלד את מספר הטלפון שלך",
  "type job": "הקלד את התפקיד שלך",
  "type full name": "הקלד את שמך המלא",
  "type email": "הקלד את האימייל שלך",
  "row": "שורה",
  "download": "הורדה",

  'forget pass': 'שכחתי סיסמה',
  'email send': 'מייל נשלח ב-',
  'email check': 'אנא בדוק את המייל שלך',

  "dont delete": "ביטול",
  "confirm delete": "מחק הזמנה",
  'email sent': 'הדוא"ל נשלח',
  "welcome": "ברוכים הבאים",
  "ok": "בסדר",
  "share": "שיתוף",
  "note": "הערה",
  "edit": "עריכה",
  "sure": "בטוח",
  "weight unit": "משקל (ק״ג)",
  "custom": "המותאם אישית",
  "gap": "פסיעה",
  "steel type": "סוג פלדה",
  "details": "פרטים",

  "download pdf": "להוריד PDF",
  "send to supplier": "לשלוח לספק",
  "print tickets": "להדפיס כרטיסים",
  "delete order": "למחוק הזמנה",
  "send msg": "לשלוח הודעה", 


  // translations
  "thank": "תודה",
  "bid":"שלח לי הצעת מחיר",
  "Bid":"קבל הצעת מחיר",
  "not now":"לא עכשיו",
  "request":"הבקשה התקבלה. ההצעה הטובה ביותר תישלח לוואטסאפ שלך",
  "placeholder":"ניתן לכתוב הערות למשלוח, זמני אספקה רצויים וכל דבר שיעזור לנו לתת לך את ההצעה המדוייקת.",
  "bid question" : "האם תרצה לקבל הצעת מחיר?",




  "confirm": "אישור",
  "add": "הוסף",
  "update": "עדכון",

  "lengths": "אורכים",
  "angles": "זוויות",
  "shapes": "מאגר צורות",
  "rebar name" : "כינוי מוט",
  "symmetric": "סימטרי",
  "asymmetric": "א-סימטרי",

  "extras" : "תוספות",
  "edge": "קוץ",
  "base": "בסיס",
  "side": "דיפון",
  "ears": "אוזניים",
  "rings": "טבעות",
  "gamma pipes": "צינרות גמא",
  "cardinal": "קרדינלי",
  "internal": "פנימי",
  "amount": "כמות",

  "diam": "קוטר",
  "qunt":"כמות",
  "radius": "רדיוס"

};