import close from "../../../assets/img/new__user__form/close.png";
import { useState } from "react";
import { useAppSelector } from "Redux/hooks";
import { Modal, Button } from "react-bootstrap";
import { updatePassword } from "Redux/middlwares/user";
import { useTranslation } from "react-i18next";
const EditUserPassword = () => {
  const [data, setData] = useState({
    old_password: "",
    new_password: "",
    confirm_new_password: "",
  });
  const user = useAppSelector((state) => state.user.userData);
  const rightToLeft = useAppSelector(state => state.root.rightToLeft)
  const [editPassword, setEditPassword] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const isEmpty = (value: any) => value.length === "";
  const isMatch = () => data.new_password === data.confirm_new_password;
  const { t } = useTranslation(['common', 'newPassword']);
  const [formInputValidity, setFormInputValidity] = useState({
    new_password: false,
    old_password: false,
    confirm_password: false,
    confirm_passwordMatch: true,
  });

  const handleChange = (e: any, key: any) => {
    setData({
      ...data,
      [key]: e.target.value,
    });
  };

  const passwordFormToggle = () => {
    setEditPassword(!editPassword);
  };

  const passwordFormSubmit = async (e: any) => {
    e.preventDefault();
    setIsSubmit(true);
    const new_passwordEmpty = isEmpty(data.new_password);
    const old_passwordEmpty = isEmpty(data.old_password);
    const confirm_passwordEmpty = isEmpty(data.confirm_new_password);
    const confirm_passwordMatch = isMatch();

    setFormInputValidity({
      new_password: new_passwordEmpty,
      old_password: old_passwordEmpty,
      confirm_password: confirm_passwordEmpty,
      confirm_passwordMatch: confirm_passwordMatch,
    });

    const formIsValid =
      !new_passwordEmpty &&
      !old_passwordEmpty &&
      !confirm_passwordEmpty &&
      confirm_passwordMatch;
    if (!formIsValid) {
      setIsSubmit(false);
      return;
    }

    //submit form
    await updatePassword(data);
    setIsSubmit(false);
    passwordFormToggle();
  };

  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
        flexGrow: 1,
        padding: "0px 40px",
      }}
    >
      <div>
        <p
          onClick={passwordFormToggle}
          style={{
            textAlign: "right",
            textDecoration: "underline",
            fontSize: "15px",
            cursor: "pointer",
            marginBottom: "8px",
            color: "darkgray",
          }}
        >
          {t('Change Password', {ns: 'newPassword'})}
        </p>
      </div>

      <div className="d-sm-flex justify-content-sm-between" style={{ gap: 20 }}>
        <label
          style={{
            textTransform: "uppercase",
            alignSelf: "center",
            margin: 0,
            fontSize: "15px",
            width: "150px",
            textAlign: "start",
          }}
        >
          {t('Mail', {ns: 'userProfile'})}
        </label>
        <input
          type="text"
          className="form-control"
          placeholder={t('enter email', {ns: 'userProfile'})}
          style={{
            margin: "5px",
            border: "0px",
            fontSize: "18px",
            padding: "5px 10px",
            flexGrow: 1,
          }}
          value={user.email}
          disabled={true}
        />
      </div>

      <Modal
        show={editPassword}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <section className="forms">
          <div className="container">
            <div className="row py-5 mx-auto">
              <div
                className="
                                form__header
                                col-sm-12
                                p-4
                                d-flex
                                justify-content-between
                                pt-3
                            "
              >
                <h1 className="text-white text-uppercase mb-0 text-start">
                  {t('Change Password', {ns: 'newPassword'})}
                </h1>
                <span className="close__btn" onClick={passwordFormToggle}>
                  <img className="w-100" src={close} alt="close icon" />
                </span>
              </div>

              <div className="col-sm-12 bg-white form__body py-4">
                <div className={"form__title pb-md-3 border__bottom pb-2 "+(rightToLeft?"text-right":"text-left")}>
                  <span className="text-capitalize">*{t('Required Fields', {ns: 'newPassword'})}</span>
                </div>
                <form className="pt-4">
                  <div className="inputs pb-4">
                    <div className="form-group">
                      <label className="text-uppercase mb-0 text-left">
                        {t('old password', {ns: 'newPassword'})} *
                      </label>
                      <input
                        onChange={(e) => {
                          handleChange(e, "old_password");
                        }}
                        type="password"
                        className="form-control w-100"
                        aria-describedby="passwordHelp"
                        placeholder={t('Enter old password', {ns: 'newPassword'})}
                      />
                      {formInputValidity.old_password ? (
                        <p
                          style={{
                            color: "red",
                            display: "block",
                            textAlign: "right",
                          }}
                        >
                          {t('old pass required', {ns: 'newPassword'})}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group">
                      <label className="text-uppercase mb-0 text-left">
                        {t('new pass', {ns: 'newPassword'})} *
                      </label>
                      <input
                        onChange={(e) => {
                          handleChange(e, "new_password");
                        }}
                        type="password"
                        className="form-control w-100"
                        aria-describedby="passwordHelp"
                        placeholder={t('Enter new password', {ns: 'newPassword'})}
                      />
                      {formInputValidity.new_password ? (
                        <p
                          style={{
                            color: "red",
                            display: "block",
                            textAlign: "right",
                          }}
                        >
                          {t('New Pass required', {ns: 'newPassword'})}*
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group mb-0">
                      <label className="text-uppercase mb-0 text-left">
                        {t('confirm pass', {ns: 'newPassword'})} *
                      </label>
                      <input
                        onChange={(e) => {
                          handleChange(e, "confirm_new_password");
                        }}
                        type="password"
                        className="form-control w-100"
                        aria-describedby="passwordHelp"
                        placeholder={t('confirm pass', {ns: 'newPassword'})}
                      />
                      {formInputValidity.confirm_password && (
                        <p
                          style={{
                            color: "red",
                            display: "block",
                            textAlign: "right",
                          }}
                        >
                          {t('confirm pass required', {ns: 'newPassword'})}
                        </p>
                      )}
                      {!formInputValidity.confirm_passwordMatch &&
                        !formInputValidity.confirm_password && (
                          <p
                            style={{
                              color: "red",
                              display: "block",
                              textAlign: "right",
                            }}
                          >
                            {t('cofirm pass not match', {ns: 'newPassword'})}
                          </p>
                        )}
                    </div>
                  </div>
                  <div
                    className="
                                        btn__wrap
                                        py-2
                                        pt-4
                                        d-flex
                                        justify-content-between
                                    "
                  >
                    <Button variant="form" onClick={passwordFormToggle}>
                      {t('cancel')}
                    </Button>
                    <button
                      onClick={passwordFormSubmit}
                      type="submit"
                      className="btn form__btn__black"
                      disabled={isSubmit}
                    >
                      {isSubmit ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        t("submit", {ns: "newPassword"})
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Modal>
    </form>
  );
};

export default EditUserPassword;
