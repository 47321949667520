import React, { useRef, useEffect, useMemo } from "react";

import PreviewHeader from "../header";
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import { commaSeparated } from "Constants/general";

import SpecialNet from  "../../../assets/img/pdf-preview/special_net.svg";
import Gap from  "../../../assets/img/pdf-preview/gap.svg";
import SteelType from  "../../../assets/img/pdf-preview/steel-type.svg";
import Diameter from  "../../../assets/img/pdf-preview/diameter.svg";
import Quantity from  "../../../assets/img/pdf-preview/quantity.svg";
import UnitWeight from  "../../../assets/img/pdf-preview/unit-weight.svg";
import Weight from  "../../../assets/img/pdf-preview/weight.svg";
import UnitPrice from  "../../../assets/img/pdf-preview/unit-price.svg";
import Price from  "../../../assets/img/pdf-preview/price.svg";

const PreviewSpecialNets = ({
  pageSize,
  order,
  nets,
  handleRefs,
  totalPages,
  priceShow,
  standardNetTran,
  summaryDetails,
  pdfType,
  rebarPricing,
  standardNets,
  rebarsTotalPrice,
  standardNetsTotalPrice,
  specialNetsTotalPrice,
  calculatePrice,
  rightToLeft,
  checkForDecimals,
}: any) => {
  const { t } = useTranslation(["common", "pdfOrder", "measurements"]);
  const pageRef: any = useRef([]);
  const steelTypes = useAppSelector((state) => state.steelType.steelTypes);

  const netUnits = useMemo(
    () =>
      nets.length > 0
        ? nets.reduce((pre: any, curr: any) => pre + curr.quantity, 0)
        : 0,
    [nets]
  );

  const netTons = useMemo(
    () =>
      nets.length > 0
        ? (
            nets.reduce(
              (pre: any, curr: any) =>
                pre + curr.tool.unit_weight * curr.quantity,
              0
            ) / 1000
          ).toFixed(4)
        : "0",
    [nets]
  );

  const specialNetPricing = useMemo(
    () =>
      nets.length > 0
        ? nets
            .reduce(
              (pre: any, curr: any) => pre + curr.unit_price * curr.quantity,
              0
            )
            .toFixed(2)
        : "0",
    [nets]
  );

  const standardNetPricing = useMemo(
    () =>
      standardNets.length > 0
        ? standardNets
            .reduce(
              (pre: any, curr: any) => pre + curr.unit_price * curr.quantity,
              0
            )
            .toFixed(2)
        : "0",
    [standardNets]
  );

  useEffect(() => {
    handleRefs(pageRef);
  });

  const printpage = (
    data: any,
    refIndex: any,
    last: boolean,
    lastLast: boolean
  ) => {
    return (
      <>
        <div
          style={{ height: pageSize + "px" }}
          className={lastLast ? "last-page" : ""}
          ref={(el) => {
            pageRef.current[refIndex] = el;
          }}
        >
          <div>
            <PreviewHeader
              order={order}
              showDetails={refIndex === 0}
              pageNo={
                totalPages.rebars + totalPages.standardNets + 1 + refIndex
              }
              totalPages={totalPages}
              rightToLeft={rightToLeft}
            />
            {data !== -1 && (
              <div className="table-wrapper">
                <div className="table-head">
                  {t("special nets", { ns: "pdfOrder" })}
                </div>
                <div className="special-net-diagram">
                  <img
                    src={SpecialNet}
                    alt="special net"
                  />
                </div>
                <table
                  className={
                    "special-net" +
                    (lastLast ? " last" : "") +
                    (priceShow ? "" : " without-price")
                  }
                >
                  <thead>
                    <tr className="dark-border-bottom dark-border-top">
                      <th
                        className={
                          (rightToLeft
                            ? "dark-border-left"
                            : "dark-border-right") +
                          " d-flex justify-content-center align-items-center"
                        }
                      >
                        <div
                          className={
                            "sub-head_text" + (rightToLeft ? "rightToLeft" : "")
                          }
                        >
                          {t("row", { ns: "common" })}
                        </div>
                      </th>
                      <th
                        className={
                          (rightToLeft
                            ? "dark-border-left"
                            : "dark-border-right") +
                          " d-flex justify-content-center align-items-center"
                        }
                      >
                        <div
                          className={
                            "sub-head_text " +
                            (rightToLeft ? "rightToLeft" : "")
                          }
                        >
                          {t("name", { ns: "pdfOrder" })}
                        </div>
                      </th>
                      <th
                        className={
                          (rightToLeft
                            ? "dark-border-left"
                            : "dark-border-right") +
                          " d-flex flex-column align-items-center justify-content-center"
                        }
                      >
                        <span>
                          <img
                            style={{
                              height: "24px",
                              width: "24px",
                              marginBottom: "4px",
                            }}
                            src={SteelType}
                            alt="steel type logo"
                          />
                        </span>
                        <span className="text-capitalize sub-head_text">
                          {t("steel type", { ns: "pdfOrder" })}
                        </span>
                      </th>
                      <th
                        className={
                          "main_XY " +
                          (rightToLeft
                            ? "dark-border-left"
                            : "dark-border-right")
                        }
                      >
                        <span>X</span>
                        <div className="special-length-container dark-border-top">
                          <div
                            className={
                              "single-column " +
                              (rightToLeft
                                ? "light-border-left"
                                : "light-border-right")
                            }
                          >
                            <span className="last-element">
                              <img
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  paddingTop: "2px",
                                }}
                                src={Diameter}
                                alt="diameter logo"
                              />
                            </span>
                            <span className="light-border-bottom small-font text-capitalize sub-head_text">
                              {t("dmeter", { ns: "pdfOrder" })}
                            </span>
                            <span className="unit">
                              {t("mm", { ns: "measurements" })}
                            </span>
                          </div>
                          <div
                            className={
                              "single-column " +
                              (rightToLeft
                                ? "dark-border-left"
                                : "dark-border-right")
                            }
                          >
                            <span className="last-element">
                              <img
                                style={{ height: "24px", width: "24px" }}
                                src={Gap}
                                alt="length logo"
                              />
                            </span>
                            <span className="light-border-bottom sub-head_text">
                              {t("gap", { ns: "pdfOrder" })}
                            </span>
                            <span className="unit">
                              {t("cm", { ns: "measurements" })}
                            </span>
                          </div>
                          <div className="single-column">
                            <span className="light-border-bottom sub-head_text">
                              {t("length", { ns: "common" })}
                            </span>
                            <div className="division-lenghts light-border-bottom">
                              <span
                                className={
                                  "sub-head_text " +
                                  (rightToLeft
                                    ? "light-border-left"
                                    : "light-border-right")
                                }
                              >
                                X2
                              </span>
                              <span
                                className={
                                  "sub-head_text " +
                                  (rightToLeft
                                    ? "light-border-left"
                                    : "light-border-right")
                                }
                              >
                                X1
                              </span>
                              <span className="sub-head_text">X3</span>
                            </div>
                            <span className="unit">
                              {t("cm", { ns: "measurements" })}
                            </span>
                          </div>
                        </div>
                      </th>
                      <th
                        className={
                          "main_XY " +
                          (rightToLeft
                            ? "dark-border-left"
                            : "dark-border-right")
                        }
                      >
                        <span>Y</span>
                        <div className="special-length-container dark-border-top">
                          <div
                            className={
                              "single-column " +
                              (rightToLeft
                                ? "light-border-left"
                                : "light-border-right")
                            }
                          >
                            <span className="last-element">
                              <img
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  paddingTop: "2px",
                                }}
                                src={Diameter}
                                alt="diameter logo"
                              />
                            </span>
                            <span className="light-border-bottom small-font text-capitalize sub-head_text">
                              {t("dmeter", { ns: "pdfOrder" })}
                            </span>
                            <span className="unit">
                              {t("mm", { ns: "measurements" })}
                            </span>
                          </div>
                          <div
                            className={
                              "single-column " +
                              (rightToLeft
                                ? "dark-border-left"
                                : "dark-border-right")
                            }
                          >
                            <span className="last-element">
                              <img
                                style={{ height: "24px", width: "24px" }}
                                src={Gap}
                                alt="length logo"
                              />
                            </span>
                            <span className="light-border-bottom sub-head_text">
                              {t("gap", { ns: "pdfOrder" })}
                            </span>
                            <span className="unit">
                              {t("cm", { ns: "measurements" })}
                            </span>
                          </div>
                          <div className="single-column">
                            <span className="light-border-bottom sub-head_text">
                              {t("length", { ns: "pdfOrder" })}
                            </span>
                            <div className="division-lenghts light-border-bottom">
                              <span
                                className={
                                  "sub-head_text " +
                                  (rightToLeft
                                    ? "light-border-left"
                                    : "light-border-right")
                                }
                              >
                                Y2
                              </span>
                              <span
                                className={
                                  "sub-head_text " +
                                  (rightToLeft
                                    ? "light-border-left"
                                    : "light-border-right")
                                }
                              >
                                Y1
                              </span>
                              <span className="sub-head_text">Y3</span>
                            </div>
                            <span className="unit">
                              {t("cm", { ns: "measurements" })}
                            </span>
                          </div>
                        </div>
                      </th>
                      <th
                        className={
                          rightToLeft ? "dark-border-left" : "dark-border-right"
                        }
                      >
                        <span className="light-border-bottom d-flex flex-column justify-content-center align-items-center h-100">
                          <img
                            style={{
                              width: "24px",
                              height: "24px",
                              marginBottom: "4px",
                            }}
                            src={Quantity}
                            alt="quantity logo"
                          />
                          <span className="sub-head_text">
                            {t("quantity", { ns: "common" })}
                          </span>
                        </span>
                        <span className="unit">
                          {t("units", { ns: "measurements" })}
                        </span>
                      </th>
                      <th
                        className={
                          priceShow
                            ? rightToLeft
                              ? "dark-border-left"
                              : "dark-border-right"
                            : ""
                        }
                      >
                        <span className="sub-head_text">
                          {pdfType === 1
                            ? t("Price", { ns: "common" })
                            : t("weight", { ns: "common" })}
                        </span>
                        <div className="two-columns dark-border-top flex-grow-1">
                          <div
                            className={
                              (rightToLeft
                                ? "light-border-left"
                                : "light-border-right") + " single-column"
                            }
                          >
                            <span className="last-element">
                              <img
                                style={{
                                  width: "24px",
                                  height: "24px",
                                }}
                                src={UnitWeight}
                                alt="unit weight logo"
                              />
                            </span>
                            <span className="light-border-bottom sub-head_text">
                              {t("unit", { ns: "measurements" })}
                            </span>
                            <span className="unit">
                              {pdfType === 1
                                ? t("ILS", { ns: "measurements" })
                                : t("kg", { ns: "measurements" })}
                            </span>
                          </div>
                          <div className="single-column">
                            <span className="last-element">
                              <img
                                style={{
                                  width: "24px",
                                  height: "24px",
                                }}
                                src={Weight}
                                alt="weight logo"
                              />
                            </span>
                            <span className="light-border-bottom sub-head_text">
                              {t("total", { ns: "common" })}
                            </span>
                            <span className="unit">
                              {pdfType === 1
                                ? t("ILS", { ns: "measurements" })
                                : t("kg", { ns: "measurements" })}
                            </span>
                          </div>
                        </div>
                      </th>
                      {priceShow && (
                        <th>
                          <span>{t("Price", { ns: "common" })}</span>
                          <div className="two-columns dark-border-top flex-grow-1">
                            <div className="single-column light-border-right">
                              <span className="last-element">
                                <img
                                  src={UnitPrice}
                                  alt="unit weight logo"
                                />
                              </span>
                              <span className="light-border-bottom">
                                {t("unit", { ns: "measurements" })}
                              </span>
                              <span className="unit">NIS</span>
                            </div>
                            <div className="single-column">
                              <span className="last-element">
                                <img
                                  src={Price}
                                  alt="weight logo"
                                />
                              </span>
                              <span>&nbsp;&nbsp;</span>
                              <span className="light-border-bottom">
                                {t("total", { ns: "common" })}
                              </span>
                              <span className="unit">NIS</span>
                            </div>
                          </div>
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((itemNet: any, index: number) => (
                      <tr className="dark-border-bottom spec_net-tr">
                        <td
                          className={
                            (rightToLeft
                              ? "dark-border-left"
                              : "dark-border-right") + " height-inherit"
                          }
                        >
                          {itemNet.row_number}
                        </td>
                        <td
                          className={
                            (rightToLeft
                              ? "dark-border-left"
                              : "dark-border-right") + " height-inherit"
                          }
                        >
                          <span
                            style={
                              itemNet.tool.name
                                ? itemNet.tool.name.includes(" ")
                                  ? {}
                                  : {
                                      wordBreak: "break-all",
                                      lineHeight: "1.2",
                                    }
                                : {}
                            }
                          >
                            {itemNet.tool.name || ""}
                          </span>
                        </td>
                        <td
                          className={
                            (rightToLeft
                              ? "dark-border-left"
                              : "dark-border-right") + " height-inherit"
                          }
                          style={
                            itemNet.production_notes
                              ? { paddingBottom: "20px" }
                              : {}
                          }
                        >
                          {steelTypes.length > 0 &&
                            steelTypes.find(
                              (item) => item.id === itemNet.tool.steel_type
                            ) &&
                            steelTypes.find(
                              (item) => item.id === itemNet.tool.steel_type
                            ).name[0]}
                        </td>
                        <td
                          className={
                            (rightToLeft
                              ? "light-border-left"
                              : "light-border-right") + " height-inherit"
                          }
                          style={
                            itemNet.production_notes
                              ? { paddingBottom: "20px" }
                              : {}
                          }
                        >
                          {itemNet.tool.x_diameter.steel_diameter}
                        </td>
                        <td
                          className={
                            (rightToLeft
                              ? "dark-border-left"
                              : "dark-border-right") + " height-inherit"
                          }
                          style={
                            itemNet.production_notes
                              ? { paddingBottom: "20px" }
                              : {}
                          }
                        >
                          {itemNet.tool.x_gap}
                        </td>
                        <td
                          className={
                            (rightToLeft
                              ? "dark-border-left"
                              : "dark-border-right") + " height-inherit"
                          }
                          style={
                            itemNet.production_notes
                              ? { paddingBottom: "20px" }
                              : {}
                          }
                        >
                          <div className="len_container">
                            <div className="len_top">
                              <span
                                className={
                                  itemNet.production_notes ? "" : "pt-1"
                                }
                              >
                                <strong>{itemNet.tool.x_length}</strong>
                              </span>
                            </div>
                            <div className="len_bottom">
                              <span
                                className={
                                  (rightToLeft
                                    ? "light-border-left"
                                    : "light-border-right") +
                                  " height-inherit" +
                                  (itemNet.production_notes ? "" : " pt-2")
                                }
                              >
                                {itemNet.tool.x_2}
                              </span>
                              <span
                                className={
                                  (rightToLeft
                                    ? "light-border-left"
                                    : "light-border-right") +
                                  " height-inherit" +
                                  (itemNet.production_notes ? "" : " pt-2")
                                }
                              >
                                {itemNet.tool.x_length -
                                  itemNet.tool.x_2 -
                                  itemNet.tool.x_3}
                              </span>
                              <span
                                className={
                                  "height-inherit" +
                                  (itemNet.production_notes ? "" : " pt-2")
                                }
                              >
                                {itemNet.tool.x_3}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td
                          className={
                            (rightToLeft
                              ? "light-border-left"
                              : "light-border-right") + " height-inherit"
                          }
                          style={
                            itemNet.production_notes
                              ? { paddingBottom: "20px" }
                              : {}
                          }
                        >
                          {itemNet.tool.y_diameter.steel_diameter}
                        </td>
                        <td
                          className={
                            (rightToLeft
                              ? "dark-border-left"
                              : "dark-border-right") + " height-inherit"
                          }
                          style={
                            itemNet.production_notes
                              ? { paddingBottom: "20px" }
                              : {}
                          }
                        >
                          {itemNet.tool.y_gap}
                        </td>
                        <td
                          className={
                            (rightToLeft
                              ? "dark-border-left"
                              : "dark-border-right") + " height-inherit"
                          }
                          style={
                            itemNet.production_notes
                              ? { paddingBottom: "20px" }
                              : {}
                          }
                        >
                          <div className="len_container">
                            <div className="len_top">
                              <span
                                className={
                                  itemNet.production_notes ? "" : "pt-1"
                                }
                              >
                                <strong>{itemNet.tool.y_length}</strong>
                              </span>
                            </div>
                            <div className="len_bottom">
                              <span
                                className={
                                  (rightToLeft
                                    ? "light-border-left"
                                    : "light-border-right") +
                                  " height-inherit" +
                                  (itemNet.production_notes ? "" : " pt-2")
                                }
                              >
                                {itemNet.tool.y_2}
                              </span>
                              <span
                                className={
                                  (rightToLeft
                                    ? "light-border-left"
                                    : "light-border-right") +
                                  " height-inherit" +
                                  (itemNet.production_notes ? "" : " pt-2")
                                }
                              >
                                {itemNet.tool.y_length -
                                  itemNet.tool.y_2 -
                                  itemNet.tool.y_3}
                              </span>
                              <span
                                className={
                                  "height-inherit" +
                                  (itemNet.production_notes ? "" : " pt-2")
                                }
                              >
                                {itemNet.tool.y_3}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td
                          className={
                            (rightToLeft
                              ? "dark-border-left"
                              : "dark-border-right") + " height-inherit"
                          }
                        >
                          {itemNet.quantity}
                        </td>
                        <td
                          className={
                            (rightToLeft
                              ? "light-border-left"
                              : "light-border-right") + " height-inherit"
                          }
                        >
                          {pdfType === 1
                            ? checkForDecimals(
                                calculatePrice(
                                  itemNet.unit_price,
                                  itemNet.quantity
                                )
                              )
                                .toString()
                                .replace(commaSeparated, ",")
                            : itemNet.tool.unit_weight}
                        </td>
                        <td
                          className={
                            "height-inherit " +
                            (priceShow
                              ? rightToLeft
                                ? "dark-border-left"
                                : "dark-border-right"
                              : "")
                          }
                        >
                          {pdfType === 1
                            ? checkForDecimals(
                                calculatePrice(
                                  itemNet.unit_price,
                                  itemNet.quantity
                                ) * itemNet.quantity
                              )
                                .toString()
                                .replace(commaSeparated, ",")
                            : (
                                itemNet.tool.unit_weight * itemNet.quantity
                              ).toFixed(2)}
                        </td>
                        {priceShow && (
                          <>
                            <td
                              className={
                                (rightToLeft
                                  ? "light-border-left"
                                  : "light-border-right") + " height-inherit"
                              }
                            >
                              2.3
                            </td>
                            <td>1,115</td>
                          </>
                        )}
                        {itemNet.production_notes && (
                          <td
                            className="dark-border-top dark-border-bottom"
                            style={{
                              position: "absolute",
                              marginTop: "64px",
                              alignItems: "normal",
                              justifyContent: "flex-start",
                              height: "30px",
                              width: priceShow ? "73%" : "53.9%",
                              ...(rightToLeft
                                ? {
                                    right: priceShow ? "250px" : "156px",
                                    textAlign: "right",
                                  }
                                : { left: priceShow ? "250px" : "156px" }),
                            }}
                          >
                            <p
                              style={{
                                fontSize: "16px",
                                lineHeight: "30px",
                                ...(rightToLeft
                                  ? { marginRight: "4px" }
                                  : { marginLeft: "4px" }),
                              }}
                            >
                              {t("note")}: {itemNet.production_notes}
                            </p>
                          </td>
                        )}
                      </tr>
                    ))}
                    {lastLast && (
                      <>
                        <tr style={{ border: "none" }}>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>{netUnits}</td>
                          <td>
                            {pdfType === 1
                              ? checkForDecimals(
                                  calculatePrice(
                                    specialNetPricing,
                                    -1, //bcz here we have total price of all items combined
                                    true,
                                    false,
                                    false,
                                    true
                                  )
                                )
                                  .toString()
                                  .replace(commaSeparated, ",")
                              : parseFloat(netTons) === 0.0
                              ? parseFloat(netTons).toFixed(3)
                              : parseFloat(netTons).toFixed(2)}
                          </td>
                          {priceShow && <td>34</td>}
                        </tr>
                        <tr style={{ border: "none" }}>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>{t("quantity", { ns: "common" })}</td>
                          <td className="d-flex align-items-center text-capitalize">
                            <span className={rightToLeft ? "ml-1" : "mr-1"}>
                              {pdfType === 1
                                ? t("Price", { ns: "common" })
                                : t("weight", { ns: "common" })}
                            </span>
                            <span style={{ fontSize: "12px" }}>
                              (
                              {pdfType === 1
                                ? t("ILS", { ns: "measurements" })
                                : t("ton", { ns: "measurements" })}
                              )
                            </span>
                          </td>
                          {priceShow && (
                            <td>{t("price", { ns: "common" })} (NIS)</td>
                          )}
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            )}

            {/* Summary of Special Nets*/}

            {(last && summaryDetails.piles === 0) && (
              <div className="table-wrapper">
                <div className="table-head">
                  {t("order Summary", { ns: "pdfOrder" })}
                </div>
                <table
                  className={
                    "order-summary" +
                    (priceShow ? "" : " without-price") +
                    (rightToLeft ? " rightToLeft" : "")
                  }
                >
                  <tbody>
                    {summaryDetails.rebars > 0 && (
                      <tr>
                        <td>{t("Rebars", { ns: "common" })}</td>
                        <td>{summaryDetails.rebars}</td>
                        <td>
                          {pdfType === 1
                            ? checkForDecimals(parseFloat(rebarsTotalPrice))
                                .toString()
                                .replace(commaSeparated, ",")
                            : parseFloat(summaryDetails.rebars_weight) === 0.0
                            ? parseFloat(summaryDetails.rebars_weight).toFixed(
                                3
                              )
                            : parseFloat(summaryDetails.rebars_weight).toFixed(
                                2
                              )}
                        </td>
                        {priceShow && <td>54</td>}
                      </tr>
                    )}
                    {summaryDetails.standard_nets > 0 && (
                      <tr>
                        <td>{t("standard nets", { ns: "pdfOrder" })}</td>
                        <td>{summaryDetails.standard_nets}</td>
                        <td>
                          {pdfType === 1
                            ? checkForDecimals(
                                parseFloat(standardNetsTotalPrice)
                              )
                                .toString()
                                .replace(commaSeparated, ",")
                            : parseFloat(
                                summaryDetails.standard_nets_weight
                              ) === 0.0
                            ? parseFloat(
                                summaryDetails.standard_nets_weight
                              ).toFixed(3)
                            : parseFloat(
                                summaryDetails.standard_nets_weight
                              ).toFixed(2)}
                        </td>
                        {priceShow && <td>54</td>}
                      </tr>
                    )}
                    <tr>
                      <td>{t("special nets", { ns: "pdfOrder" })}</td>
                      <td>{summaryDetails.special_nets}</td>
                      <td>
                        {pdfType === 1
                          ? checkForDecimals(parseFloat(specialNetsTotalPrice))
                              .toString()
                              .replace(commaSeparated, ",")
                          : parseFloat(summaryDetails.special_nets_weight) ===
                            0.0
                          ? parseFloat(
                              summaryDetails.special_nets_weight
                            ).toFixed(3)
                          : parseFloat(
                              summaryDetails.special_nets_weight
                            ).toFixed(2)}
                      </td>
                      {priceShow && <td>54</td>}
                    </tr>

                    <tr>
                      <td>{t("total", { ns: "common" })}</td>
                      <td>
                        {summaryDetails.rebars +
                          summaryDetails.standard_nets +
                          summaryDetails.special_nets}
                      </td>

                      <td>
                        {pdfType === 1
                          ? (
                              parseFloat(rebarsTotalPrice) +
                              parseFloat(standardNetsTotalPrice) +
                              parseFloat(specialNetsTotalPrice)
                            )
                              .toFixed(1)
                              .toString()
                              .replace(commaSeparated, ",")
                          : (
                              parseFloat(summaryDetails.rebars_weight) +
                              parseFloat(summaryDetails.standard_nets_weight) +
                              parseFloat(summaryDetails.special_nets_weight)
                            ).toFixed(2)}
                      </td>
                      {priceShow && <td>99</td>}
                    </tr>
                    <tr>
                      <td></td>
                      <td>{t("units", { ns: "measurements" })}</td>
                      <td className="d-flex align-items-center text-capitalize">
                        <span className={rightToLeft ? "ml-1" : "mr-1"}>
                          {pdfType === 1
                            ? t("Price", { ns: "common" })
                            : t("weight", { ns: "common" })}
                        </span>
                        <span style={{ fontSize: "12px" }}>
                          (
                          {pdfType === 1
                            ? t("ILS", { ns: "measurements" })
                            : t("ton", { ns: "measurements" })}
                          )
                        </span>
                      </td>
                      {priceShow && (
                        <td>{t("Price", { ns: "common" })} (NIS)</td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            height: "20px",
            background: "#000",
            opacity: "0.5",
          }}
        ></div>
      </>
    );
  };

  if (nets && nets.length) {
    let SLICE = 7,
      FIRST_SLICE = 6,
      LAST_SLICE = 6;
    let nextPageFlag = false;
    //Order summary to next page (For the first page)
    if (nets.length >= 4 && nets.length < 7) {
      nextPageFlag = true;
    }
    const dataSlice: any = [];
    let initialSlice = 0,
      firstSlice = true;
    while (nets.length > initialSlice) {
      const remaining = nets.length - initialSlice;

      if (remaining > 7 && firstSlice) {
        FIRST_SLICE = 7;
        LAST_SLICE = 8;
      }
      if (!firstSlice) {
        SLICE = 9;
        LAST_SLICE = 9;
      }

      //Order summary to next page (Except the first page)
      if (remaining >= 7 && !firstSlice) {
        nextPageFlag = true;
      } else if (!firstSlice) {
        nextPageFlag = false;
      }

      if (
        nets.length === initialSlice + SLICE ||
        nets.length === initialSlice + SLICE - 1 ||
        nets.length === initialSlice + SLICE - 2
      ) {
        dataSlice.push(nets.slice(initialSlice, initialSlice + LAST_SLICE));
        initialSlice = initialSlice + LAST_SLICE;
        if (nets.length > initialSlice)
          dataSlice.push(nets.slice(initialSlice, initialSlice + LAST_SLICE));
      } else {
        dataSlice.push(
          nets.slice(
            initialSlice,
            initialSlice + (firstSlice ? FIRST_SLICE : SLICE)
          )
        );
      }
      initialSlice = initialSlice + (firstSlice ? FIRST_SLICE : SLICE);
      if (firstSlice) firstSlice = false;
    }
    if (nextPageFlag) {
      dataSlice.push(-1);
    }
    return (
      <>
        {dataSlice.map((data: any, index: any) =>
          printpage(
            data,
            index,
            index === dataSlice.length - 1,
            dataSlice[index + 1] === -1 || index === dataSlice.length - 1
          )
        )}
      </>
    );
  }
  return <></>;
};

export default PreviewSpecialNets;
