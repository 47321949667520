import {useState} from "react";
import { useTranslation } from "react-i18next";
import WarningModal from "./../../../custom-modal/index";

function SaveOrderModal(props: any) {
  const { t } = useTranslation(["common", "warning"]);
  const {
    onHide,
    saveBeforeShareShow,
    saveOrder,
  } = props;

  const [disableSave, setDisableSave] = useState(false);

  const btnValues = [
    {
      text: t("cancel", { ns: "common" }),
      btnClass: "custom__btn-secondary",
    },
    {
      text: t("Save", { ns: "common" }),
      btnClass: "custom__btn-primary",
    },
  ];

  const SuccessAlert = () => {
    onHide();
  };

  const SavingOrder = () => {
    setDisableSave(true);
    saveOrder(saveBeforeShareShow)
      .then(() => {
        setDisableSave(false);
      })
      .catch(() => {
        setDisableSave(false);
      });
  };

  const btnFunctions = {
    primary: SavingOrder,
    secondary: SuccessAlert,
  };

  return (
    <WarningModal
      show={props.show}
      onHide={props.onHide}
      dialogClassName={"modal_width"}
      btnFunctions={btnFunctions}
      btnValues={btnValues}
      modalColor={"#fff"}
      disableSave={disableSave}
    >
      <div className="delete__title pb-md-4 pb-2">
        <p className="mb-0">
          {
            t("save before share", { ns: "warning" })
          }
        </p>
      </div>
    </WarningModal>
  );
}

export default SaveOrderModal;
