import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import "../../styles/Dashboard.css";

import logo_white from "Assets/img/logo_white.svg";

const Sidebar = () => {
  const location = useLocation();

  return (
    <>
      <div id="head-admin">
        <div>
          <img src={logo_white} alt="" />
        </div>
        <p className="admin_panel">Admin Panel</p>
      </div>
      <Nav
        id="admin-custom-sidebar"
        className="col-md-12 d-none d-md-block bg-light sidebar-admin"
        activeKey="/home"
        onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
      >
        <div className="sidebar-sticky"></div>
        <NavLink to="/admin" className={"underline_dark"}>
          <Nav.Item>
            <span
              className={`nav-link nav ${
                location.pathname.endsWith("/admin") ||
                location.pathname.endsWith("/admin/")
                  ? "current_nav"
                  : ""
              }`}
            >
              Shapes Pool
            </span>
          </Nav.Item>
        </NavLink>
        <NavLink to="/admin/steel-types" className={"underline_dark"}>
          <Nav.Item>
            <span
              className={`nav-link nav ${
                location.pathname === "/admin/steel-types" ? "current_nav" : ""
              }`}
            >
              Steel Types
            </span>
          </Nav.Item>
        </NavLink>
        <NavLink to="/admin/user-analytics" className={"underline_dark"}>
          <Nav.Item>
            <span
              className={`nav-link nav ${
                location.pathname === "/admin/user-analytics"
                  ? "current_nav"
                  : ""
              }`}
            >
              User Analytics
            </span>
          </Nav.Item>
        </NavLink>
        <NavLink to="/admin/supplier-tickets" className={"underline_dark"}>
          <Nav.Item>
            <span
              className={`nav-link nav ${
                location.pathname === "/admin/supplier-tickets"
                  ? "current_nav"
                  : ""
              }`}
            >
              Supplier Tickets
            </span>
          </Nav.Item>
        </NavLink>
        <NavLink to="/admin/bids-calculator">
          <Nav.Item>
            <span
              className={`nav-link nav ${
                location.pathname === "/admin/bids-calculator"
                  ? "current_nav"
                  : ""
              }`}
            >
              Bids Calculator
            </span>
          </Nav.Item>
        </NavLink>
        {/* <NavLink to="/admin/bids">
          <Nav.Item>
            <span
              className={`nav-link nav ${
                location.pathname === "/admin/bids" ? "current_nav" : ""
              }`}
            >
              Bids
            </span>
          </Nav.Item>
        </NavLink> */}
      </Nav>
    </>
  );
};

export default Sidebar;
