import { createSlice } from '@reduxjs/toolkit'

export interface UserState {
  userData: any;
  profilePicture: string;
}

const initialState: UserState = {
  userData: null,
  profilePicture: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      return {
        ...state,
        userData: action.payload,
      };
    },
    logoutUser: (state: any) => {
      state.userData = null;
    },
    setProfilePicture: (state, action) => {
      state.profilePicture = action.payload;
    },
    setUserAttestedTC: (state) => {
      return {
        ...state,
        userData: {
          ...state.userData,
          terms_agreement: true,
        }
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserData, logoutUser, setProfilePicture, setUserAttestedTC } = userSlice.actions;

export default userSlice.reducer;