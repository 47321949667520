import Request from "./index";

// Order Item
export const postOrderItem = (data: Object) =>
  Request.post('order-item/', data);

export const updateOrderItem = (orderItemID: number, data: Object) =>
  Request.patch(`order-item/${orderItemID}/`, data);

export const updateOrderItemPrices = (data: any) =>
  Request.patch('order-item/price/', data);

export const deleteOrderItem = (orderItemID: number) =>
  Request.remove(`order-item/${orderItemID}/`);

export const deleteOrderItems = (data: any) =>
  Request.remove("order-item/list/delete/", data);

export const updateRebarSketch = (rebarData: any, orderId: number) =>
  Request.post(`/update-sketch/${orderId}/`, rebarData);

// Order
export const getOrders = () =>
  Request.get('user/order/list/');

export const getNextOrders = (data: string) =>
  Request.get(`user/order/list/?cursor=${data}`);

export const fetchOrderNo = () =>
  Request.get('order-number/');

export const fetchCompleteOrder = (id: number) =>
  Request.get(`order/?pk=${id}`);

//v1 api
export const fetchOrder = (id: number) => 
  Request.get(`v1/order/${id}`)

export const getEditOrderTools = (id: number, tool_subclass: string,pageNum: string) =>
  Request.get(`v1/order/${id}/${tool_subclass}/?page=${pageNum}`);

export const postOrder = (data: Object) =>
  Request.post('order/', data);

export const patchOrder = (orderID: number, data: Object) =>
  Request.patch(`order/${orderID}/`, data);

export const deleteOrder = (orderID: number) =>
  Request.remove(`order/${orderID}/`);
  

// Order Stats
export const getWeeklyStats = () =>
  Request.get('stats/orders/');

// Order Rows Update on DnD
export const setRowNums = (data: Object) =>
  Request.post('set-row-number/arrange/', data);

// Order Rows on Duplicate Row
export const setDupRows = (data: Object) =>
  Request.post('set-row-number/increment/', data);

// Order Rows on Delete Row
export const setDelRows = (data: Object) =>
  Request.post('set-row-number/decrement/', data);


// Delete all items of in-progress order
export const deleteInProgressOrder = () =>
  Request.remove(`order-item/delete/in-progress-order/`);

// Delete all items of an existing order
export const deleteExistingOrderItems = (orderID: number) =>
  Request.remove(`order/${orderID}/`);

// Get copy of order to share 
export const getCopyOrder = (orderID: number, mail: any) =>
  Request.post(`order/share/${orderID}/`, {supplier_email: mail});

export const getItemsCount = (subClass: string, orderId: number) =>{
  if(orderId > 0){
    return Request.get(`order-item/row-number/${subClass}/?order_id=${orderId}`);
  }
  else{
    return Request.get(`order-item/row-number/${subClass}/?order_id=`);
  }
}

// get order total weight and quantity
export const getOrderStats = (subClass: string, orderId: number) =>{
  if(orderId && orderId > 0){
    return Request.get(`order-items/stats/${subClass}/?order=${orderId}`);
  }
  else{
    return Request.get(`order-items/stats/${subClass}/`);
  }
}