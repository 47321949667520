import { useState, useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import StaticNetsCard from "./nets-cards/StaticNetsCard";
import StaticRebarsCard from "./rebars-cards/StaticRebarsCard";
import StaticPilesCard from "./piles-cards/StaticPilesCard";
import {
  getCurrentOrderNets,
  getCurrentOrderRebars,
  getCurrentOrderPiles,
} from "Redux/middlwares/order";
import { NET_SUBCLASS_TYPE } from "Constants/net-constants";
import { REBAR_SUBCLASS_TYPE } from "Constants/rebar-constants";

import "./index.styled.scss";

const LastAddedSidebar = (props: any) => {
  const {
    dataLength,
    children,
    toolData,
    setToolData,
    checkRowNums,
    editMode,
    hasMore,
    setHasMore,
    editOrderId,
    saveTool,
    finishButtonRef,
    addButtonRef,
    setDefaultShape,
    handleClose,
    editToolId,
  } = props;

  const dispatch = useAppDispatch();
  const { t } = useTranslation(["tool"]);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const nextOrderNetsPage = useAppSelector((state) => state.net.orderNetPage);
  const nextOrderRebarsPage = useAppSelector(
    (state) => state.rebar.orderRebarPage
  );
  const nextOrderPilesPage = useAppSelector(
    (state) => state.pile.orderPilePage
  );

  const [activeId, setActiveId] = useState();
  const [activeIndex, setActiveIndex] = useState();

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    const row = toolData.find((original) => original.id === activeId);
    return row;
  }, [activeId]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 138,
        tolerance: 3,
      },
    }),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  function handleDragStart(event) {
    setActiveId(event.active.id);
    const activeRow = toolData.filter((data) => data.id === event.active.id);
    const Index = toolData.indexOf(activeRow[0]);

    setActiveIndex(Index);
  }

  const handleDragEnd = async (event) => {
    const { active, over } = event;
    const activeRow = toolData.filter((data) => data.id === active.id);
    const overRow = toolData.filter((data) => data.id === over.id);

    if (active.id !== over.id) {
      let moveData;
      let oldIndex = toolData.indexOf(activeRow[0]);
      let newIndex = toolData.indexOf(overRow[0]);
      setToolData((data) => {
        moveData = arrayMove(data, oldIndex, newIndex);
        return moveData;
      });

      if (moveData) {
        checkRowNums(
          oldIndex,
          newIndex,
          moveData,
          activeRow[0].tool.subclass_type
        );
      }
    }

    setActiveId(null);
    setActiveIndex(null);
  };

  function handleDragCancel() {
    setActiveId(null);
  }

  //Checks should be added for subclass-type when working on the rebars
  const onloadFunction = async () => {
    if (toolData[0].tool.subclass_type === NET_SUBCLASS_TYPE) {
      try {
        if (nextOrderNetsPage !== false && nextOrderNetsPage > 1) {
          if (editMode) {
            await dispatch(
              getCurrentOrderNets(editMode, editOrderId, nextOrderNetsPage)
            );
          } else {
            await dispatch(getCurrentOrderNets(editMode, 0, nextOrderNetsPage));
          }
        }
      } catch (error) {
        setHasMore(false);
      }
    } else if (toolData[0].tool.subclass_type === REBAR_SUBCLASS_TYPE) {
      try {
        if (nextOrderRebarsPage !== false && nextOrderRebarsPage > 1) {
          if (editMode) {
            await dispatch(
              getCurrentOrderRebars(editMode, editOrderId, nextOrderRebarsPage)
            );
          } else {
            await dispatch(
              getCurrentOrderRebars(editMode, 0, nextOrderRebarsPage)
            );
          }
        }
      } catch (error) {
        setHasMore(false);
      }
    } else {
      try {
        if (nextOrderPilesPage !== false && nextOrderPilesPage > 1) {
          if (editMode) {
            await dispatch(
              getCurrentOrderPiles(editMode, editOrderId, nextOrderPilesPage)
            );
          } else {
            await dispatch(
              getCurrentOrderPiles(editMode, 0, nextOrderPilesPage)
            );
          }
        }
      } catch (error) {
        setHasMore(false);
      }
    }
  };

  // const handleFocus = () => {
  //   if (finishButtonRef) {
  //     if (isShapesError) {
  //       finishButtonRef.current.classList.add("error");
  //       finishButtonRef.current.style.border = "2px solid #CD2017";
  //     } else {
  //       finishButtonRef.current.classList.remove("error");
  //       finishButtonRef.current.style.border = "2px solid #00B38F";
  //     }
  //   }
  // };

  const checkMeasurements = (isAddMore: boolean) => {
    //   It is clients requirement to remove this popup for now.
    //   If we add this popup anytime in future this function must be moved
    //   to parent Rebar.tsx and should run for both add/finish buttons.
    // if (
    //   props.shape &&
    //   props.shape.rebar_side &&
    //   props.shape.rebar_side.length > 0 &&
    //   standardShapes.length > 0
    // ) {
    //   if (!props.edit) {
    //     const { gIndex, sIndex } = selectedShape;
    //     const steelType = standardShapes[gIndex].rebars[sIndex].steel_type;
    //     const standardShapeSides =
    //       standardShapes[gIndex].rebars[sIndex].rebar_side;
    //     let isShapeChanged = false;

    //     if (shapeChanged === false) {
    //       props.saveRebar(false, isAddMore);
    //     } else {
    //       if (props.shape.steel_type !== steelType) {
    //         isShapeChanged = true;
    //       }
    //       if (isShapeChanged === false) {
    //         let sidesCheck = props.shape.rebar_side.filter(
    //           (rebarSides: any, index: number) =>
    //             rebarSides.length !== standardShapeSides[index].length
    //         );
    //         if (sidesCheck.length) {
    //           isShapeChanged = true;
    //         }
    //       }
    //       if (isShapeChanged === false) {
    //         let anglesCheck = props.shape.rebar_side.filter(
    //           (rebarAngles: any, index: number) =>
    //             rebarAngles.angle !== standardShapeSides[index].angle
    //         );
    //         if (anglesCheck.length) {
    //           isShapeChanged = true;
    //         }
    //       }
    //       isShapeChanged
    //         ? props.saveRebar(true, isAddMore)
    //         : props.saveRebar(false, isAddMore);
    //     }
    //   } else {
    //     props.saveRebar(true, isAddMore);
    //   }
    // }

    saveTool(true, isAddMore);
  };

  const borderStyles = {
    position: "relative",
    zIndex: "15",
  };

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
    >
      <div
        className="right-sidebar"
        style={{
          ...borderStyles,
          ...(rightToLeft
            ? { borderRight: "2px solid #fff" }
            : { borderLeft: "2px solid #fff" }),
        }}
      >
        <div
          className={editToolId >= 0 ? "box-hidden" : "edit-end-box"}
          onClick={setDefaultShape}
          style={
            toolData[0]?.tool.subclass_type === NET_SUBCLASS_TYPE
              ? { width: "118px", height: "98px" }
              : {
                  width: "121px",
                  height: "75px",
                }
          }
        />
        <div
          className="scrollable-area"
          id="scrollableToolDiv"
          style={{ padding: "15px 19px 19px", scrollbarWidth: "none" }}
        >
          <SortableContext
            items={toolData}
            strategy={verticalListSortingStrategy}
          >
            <InfiniteScroll
              dataLength={dataLength}
              next={onloadFunction}
              hasMore={hasMore}
              loader={
                <div className="d-flex justify-content-center mt-2">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              }
              style={{ overflow: "visible" }}
              scrollableTarget="scrollableToolDiv"
            >
              <>{children}</>
            </InfiniteScroll>
          </SortableContext>
        </div>
        <div className="save-area">
          <button
            onClick={handleClose}
            className="finish_button"
            // onFocus={handleFocus}
            // onBlur={(e) => {
            //   e.target.style.border = "none";
            // }}
            ref={finishButtonRef}
            onKeyDown={(e) => {
              if (rightToLeft) {
                if (e.key === "ArrowRight") {
                  e.preventDefault();
                  if (addButtonRef) {
                    addButtonRef.current.focus({ preventScroll: false });
                  } else {
                    finishButtonRef.current.focus();
                  }
                }
              } else {
                if (e.key === "ArrowLeft") {
                  e.preventDefault();
                  if (addButtonRef) {
                    addButtonRef.current.focus({ preventScroll: false });
                  } else {
                    finishButtonRef.current.focus();
                  }
                }
              }
            }}
          >
            {t("finish", { ns: "tool" })}
          </button>
        </div>
      </div>
      {selectedRow?.tool?.subclass_type === NET_SUBCLASS_TYPE ? (
        <DragOverlay>
          {activeId ? (
            <div className="card__list">
              <StaticNetsCard
                id={selectedRow.id}
                index={activeIndex}
                item={selectedRow.tool}
                edit={props.edit}
              />
            </div>
          ) : null}
        </DragOverlay>
      ) : selectedRow?.tool?.subclass_type === REBAR_SUBCLASS_TYPE ? (
        <DragOverlay>
          {activeId ? (
            <div className="card__list_rebars">
              <StaticRebarsCard
                id={selectedRow.id}
                index={activeIndex}
                item={selectedRow.tool}
                edit={props.edit}
              />
            </div>
          ) : null}
        </DragOverlay>
      ) : (
        <DragOverlay>
          {activeId ? (
            <div className="card__list_piles">
              <StaticPilesCard
                id={selectedRow.id}
                index={activeIndex}
                item={selectedRow.tool}
                edit={props.edit}
              />
            </div>
          ) : null}
        </DragOverlay>
      )}
    </DndContext>
  );
};

export default LastAddedSidebar;
