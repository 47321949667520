import React, { useRef, useEffect } from "react";
import A4TicketCardRebars from "./ticket-card-rebars";
import A4TicketCardSpecialNets from "./ticket-card-special-net";
import A4TicketCardStandardNets from "./ticket-card-standard-net";
import A4TicketCardPiles from "./ticket-card-piles";
import { useAppSelector } from "Redux/hooks";

import { REBAR_SUBCLASS_TYPE } from "../../../../constants/rebar-constants";
import { NET_SUBCLASS_TYPE } from "../../../../constants/net-constants";
import { PILE_SUBCLASS_TYPE } from "Constants/pile-constants";

import "./index.scss";

const PreviewTicketPdf = ({
  pageSize,
  loader,
  orderNo,
  handleRefs,
  totalPages,
  selectedRowCount,
}: any) => {
  const pageRef: any = useRef([]);

  const currentOrderRebars = useAppSelector(
    (state) => state.supplier.ticketOrderDetails
  );
  const currentOrderNets = useAppSelector(
    (state) => state.supplier.ticketOrderNets
  );
  const currentOrderPiles = useAppSelector(
    (state) => state.supplier.ticketOrderPiles
  );

  useEffect(() => {
    handleRefs(pageRef);
  });

  const printpage = (data: any, refIndex: any, ticketIndex: any) => {
    return (
      <>
        <div
          style={{
            height: pageSize + "px",
            width: "1728px",
            justifyContent: loader ? "normal" : "",
          }}
          ref={(el) => {
            pageRef.current[refIndex] = el;
          }}
        >
          <div className="pdf-grid-container">
            {data.map((rebar, index) => {
              currentItemCount.set(
                rebar.id,
                currentItemCount.get(rebar.id) + 1
              );

              if (rebar.tool.subclass_type === REBAR_SUBCLASS_TYPE) {
                return (
                  <A4TicketCardRebars
                    key={"ticket " + index}
                    rebar={rebar}
                    orderNo={orderNo}
                    ticketIndex={rebar.row_number}
                    printTotalCount={itemCounts.get(rebar.id)}
                    printCurrentCount={currentItemCount.get(rebar.id)}
                  />
                );
              } else if (
                rebar.tool.subclass_type === NET_SUBCLASS_TYPE &&
                rebar.tool.standard_net
              ) {
                return (
                  <A4TicketCardStandardNets
                    key={"ticket " + index}
                    net={rebar}
                    orderNo={orderNo}
                    ticketIndex={rebar.row_number}
                    printTotalCount={itemCounts.get(rebar.id)}
                    printCurrentCount={currentItemCount.get(rebar.id)}
                  />
                );
              } else if (
                rebar.tool.subclass_type === NET_SUBCLASS_TYPE &&
                !rebar.tool.standard_net
              ) {
                return (
                  <A4TicketCardSpecialNets
                    key={"ticket " + index}
                    net={rebar}
                    orderNo={orderNo}
                    ticketIndex={rebar.row_number}
                    printTotalCount={itemCounts.get(rebar.id)}
                    printCurrentCount={currentItemCount.get(rebar.id)}
                  />
                );
              } else if (rebar.tool.subclass_type === PILE_SUBCLASS_TYPE) {
                return (
                  <A4TicketCardPiles
                    key={"ticket_piles " + index}
                    pile={rebar}
                    orderNo={orderNo}
                    ticketIndex={rebar.row_number}
                    printTotalCount={itemCounts.get(rebar.id)}
                    printCurrentCount={currentItemCount.get(rebar.id)}
                  />
                );
              } else {
                return <></>;
              }
            })}
          </div>
        </div>
      </>
    );
  };

  const allRows = [
    ...currentOrderRebars,
    ...currentOrderNets,
    ...currentOrderPiles,
  ];
  // Create a map of item IDs to counts
  const itemCounts = new Map();
  const currentItemCount = new Map();
  for (const { itemId, countt } of selectedRowCount) {
    itemCounts.set(itemId, countt);
    currentItemCount.set(itemId, 0);
  }

  // Create a new array with items repeated based on count
  const repeatedItems = [];
  for (const row of allRows) {
    const count = itemCounts.get(row.id) || 0;
    for (let i = 0; i < count; i++) {
      repeatedItems.push(row);
    }
  }

  if (repeatedItems && repeatedItems.length) {
    const SLICE = 8;
    const dataSlice: any = [];
    let initialSlice = 0;
    if (repeatedItems.length < 9) {
      dataSlice.push(repeatedItems.slice(initialSlice, initialSlice + SLICE));
    } else {
      while (repeatedItems.length > initialSlice) {
        dataSlice.push(repeatedItems.slice(initialSlice, initialSlice + SLICE));
        initialSlice = initialSlice + SLICE;
      }
    }

    return (
      <>
        {dataSlice.map((data: any, index: any) =>
          printpage(data, index, index)
        )}
      </>
    );
  }
  return <></>;
};

export default PreviewTicketPdf;
