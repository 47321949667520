import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PileState {
  currentOrderPiles: any[];
  currentOrderPdfPiles: any[];
  defaultPiles: any[];
  orderPilePage: string;
  currentOrderPilesCount: any;
}

const initialState: PileState = {
  currentOrderPiles: [],
  currentOrderPdfPiles: [],
  defaultPiles: [],
  orderPilePage: "1",
  currentOrderPilesCount: 0,
}

export const pileSlice = createSlice({
  name: "pile",
  initialState,
  reducers: {
    addPileToCurrentOrder: (state, action: PayloadAction<any>) => {
      const sortedPiles = [...state.currentOrderPiles, action.payload];
      sortedPiles.sort((a, b) => a.row_number - b.row_number);
      return {
        ...state,
        currentOrderPiles: sortedPiles,
      };
    },

    setDefaultPiles: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        defaultPiles: action.payload,
      };
    },
    setCurrentOrderPiles: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        currentOrderPiles: action.payload,
      };
    },
    setCurrentOrderPdfPiles: (state, action: PayloadAction<any>) => {
      const sortedPiles = action.payload;
      sortedPiles.sort((a, b) => a.row_number - b.row_number);
      return {
        ...state,
        currentOrderPdfPiles: sortedPiles,
      };
    },
    setCurrentOrderPilesCount: (state, action: PayloadAction<any>) => {
      state.currentOrderPilesCount = action.payload;
    },
    removeCurrentOrderPiles: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        currentOrderPiles: [
          ...state.currentOrderPiles.filter(
            (item) => item.id !== action.payload
          ),
        ],
      };
    },
    removeCurrentOrderPile: (state) => {
      return {
        ...state,
        currentOrderPiles: [],
      };
    },

    updateCurrentOrderPile: (state, action: PayloadAction<any>) => {
      const current = [...state.currentOrderPiles];
      const inPlaceIndex = current.map((x) => x.id).indexOf(action.payload.id);
      current[inPlaceIndex] = action.payload;
      state.currentOrderPiles = current;
    },
    updatePagePiles(state, action: PayloadAction<any>) {
      const uniquePiles = action.payload.filter((pile: any) => {
        return !state.currentOrderPiles.some(
          (existingPile: any) => existingPile.id === pile.id
        );
      });
      return {
        ...state,
        currentOrderPiles: [...state.currentOrderPiles, ...uniquePiles],
      };
    },
    addNextPagePilesToCurrentOrder: (state, action: PayloadAction<any>) => {
      const uniqueRowNumbers = new Set(
        state.currentOrderPiles.map((pile) => pile.row_number)
      );
      const uniquePiles = action.payload.filter(
        (pile) => !uniqueRowNumbers.has(pile.row_number)
      );

      return {
        ...state,
        currentOrderPiles: [...state.currentOrderPiles, ...uniquePiles],
      };
    },
    setCurrentOrderPilePage: (state, action: PayloadAction<any>) => {
      state.orderPilePage = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addPileToCurrentOrder,
  removeCurrentOrderPile,
  setDefaultPiles,
  setCurrentOrderPiles,
  setCurrentOrderPdfPiles,
  removeCurrentOrderPiles,
  updateCurrentOrderPile,
  addNextPagePilesToCurrentOrder,
  setCurrentOrderPilePage,
  setCurrentOrderPilesCount,
  updatePagePiles,
} = pileSlice.actions

export default pileSlice.reducer