export const OrderFilterTypes = {
  created: 1,
  delivery: 2,
  sites: 3,
}


export const OrderPdfType = {
  PRICES_PDF: 1,
  ORIGINAL_PDF: 2,
};