import React, { useState,useEffect } from "react";
import { useAppSelector } from "Redux/hooks";
import { commaSeparated, removeBgBorder } from "Constants/general";
import other from "../../../assets/img/order__page/other.png";
import diameterIcon from "../../../assets/img/icon/diameter-blue.svg";
import steelTypeIcon from "../../../assets/img/icon/steeltype-blue.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import NetsOrderTable from "./nets-table";
import PilesOrderTable from "./piles-table";
import TicketCounter from "../ticket-counter";
import "./index.scss";

const RebarImage = ({ preview_image, index }: any) => {
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const [flip, setFlip] = useState(0);
  const [flip1, setFlip1] = useState(0);

  return (
    <div
      style={
        flip > 1.35
          ? {
              transform: "rotate(270deg)",
              transformOrigin: "center",
              display: "flex",
              justifyContent: "center",
              height: "290px",
              width: "70px",
              ...(rightToLeft
                ? { marginRight: "130px" }
                : { marginLeft: "100px" }),
            }
          : {
              width: "290px",
              height: "70px",
              ...(flip >= 0.3 && flip <= 1.349
                ? {
                    display: "flex",
                    justifyContent: "center",
                  }
                : {}),
            }
      }
    >
      <img
        className="img__icon"
        id={"rebarPreviewImage" + index}
        src={preview_image ? preview_image.file_field : other}
        style={flip >= 0.3 && flip <= 1.349 ? { width: flip1 * 70 + "px" } : {}}
        onLoad={(e: any) => {
          const fp = e.target.naturalHeight / e.target.naturalWidth;
          setFlip(fp);
          setFlip1(e.target.naturalWidth / e.target.naturalHeight);
        }}
        alt="map icon"
      />
    </div>
  );
};

const OrderDetails = ({
  priceShow,
  handleImagePreview,
  rebarUnits,
  rebarTons,
  rebarPrice,
  selectedRowCount,
  setSelectedRowCount,
  setPrintPopUpShow,
  netUnits,
  netTons,
  netPrice,
  pileUnits,
  pileTons,
}: //openPriceListModal,
any) => {
  const { t } = useTranslation(["newOrder", "common", "measurements","ticketCreator"]);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const currentOrderRebars = useAppSelector(
    (state) => state.supplier.ticketOrderDetails
  );
  const currentOrderNets = useAppSelector((state) => state.supplier.ticketOrderNets);
  const currentOrderPiles = useAppSelector((state) => state.supplier.ticketOrderPiles);

  const steelTypes = useAppSelector((state) => state.steelType.steelTypes);

  const handleSelectAll = () => {
    const selectAll = selectedRowCount.map((item) => {
      return{
        ...item,
        countt: item.countt > 0 ? item.countt : 1
      }
    });
      setSelectedRowCount(selectAll);
  };
  
  const handleSelectNone = () => {
    const selectAll = selectedRowCount.map((item) => {
      return{
        ...item,
        countt: 0
      }
    });
      setSelectedRowCount(selectAll);
  };



  return (
    <>
      <section id="rebars__table" className="order__tables mt-5">
        <div className="container p-0">
          <div
            className="main__head"
            style={{
              paddingBottom: currentOrderRebars.length > 0 ? "30px" : "0px",
            }}
          >
            <p>{t("order details", { ns: "ticketCreator" })}</p>
          </div>
          {currentOrderRebars.length > 0 && (
            <div className="table__wrapper">
              {/* <!-- table header  --> */}
              <div className="d-flex justify-content-between">
                <div className="table__header d-flex flex-row">
                  {/* <!-- table title  --> */}
                  <div className="table__title d-flex align-items-center justify-content-center">
                    <h2 className="text-center text-white text-uppercase m-0">
                      {t("Rebars", { ns: "common" })}
                    </h2>
                  </div>
                </div>
              </div>
              {/* <!-- real table  --> */}
              <div>
                <table
                  className={
                    "table table mb-0 custom-tool-table" +
                    (rightToLeft ? " rightToLeft" : "")
                  }
                >
                  {/* <!-- table head title section  --> */}
                  <thead>
                    <tr className={priceShow ? "WithPrice" : "WithoutPrice"}>
                      <th
                        scope="col"
                        className={
                          "text-capitalize d-flex justify-content-center align-items-center p-0 "
                        }
                      >
                        <span
                          className="th_norm"
                          style={{ textAlign: "center" }}
                        >
                          {t("tickets amount", { ns: "ticketCreator" })}
                        </span>
                      </th>
                      <th
                        scope="col"
                        className={
                          "text-capitalize d-flex justify-content-center align-items-center p-0 " +
                          (rightToLeft ? "dark-border-right" : "dark-border-left")
                        }
                      >
                        <span className="th_norm">{t("row", { ns: "common" })}</span>
                      </th>
                      <th
                        scope="col"
                        className={
                          "text-capitalize d-flex justify-content-center align-items-center " +
                          (rightToLeft
                            ? "dark-border-right"
                            : "dark-border-left")
                        }
                      >
                        {t("sketch", { ns: "newOrder" })}
                      </th>

                      {/* Name */}
                      <th
                        scope="col"
                        className={
                          "text-capitalize d-flex justify-content-center align-items-center " +
                          (rightToLeft
                            ? "dark-border-right"
                            : "dark-border-left")
                        }
                      >
                        {t("Name", { ns: "newOrder" })}
                      </th>

                      <th
                        scope="col"
                        className={
                          "text-capitalize d-flex align-items-center justify-content-start text-center sm__wrap " +
                          (rightToLeft
                            ? "dark-border-right"
                            : "dark-border-left")
                        }
                      >
                        <img
                          className="hei__icon"
                          src={steelTypeIcon}
                          alt="steel"
                          style={{
                            height: "19px",
                            width: "15px",
                          }}
                        />
                        <span
                          className={
                            "d-flex flex-column align-items-start " +
                            (rightToLeft ? "ml-3" : "mr-3")
                          }
                        >
                          <span>
                            {rightToLeft
                              ? t("type", { ns: "common" })
                              : t("steel", { ns: "newOrder" })}
                          </span>
                          <span>
                            {rightToLeft
                              ? t("steel", { ns: "newOrder" })
                              : t("type", { ns: "common" })}
                          </span>
                        </span>
                      </th>
                      <th
                        scope="col"
                        className={
                          "text-capitalize d-flex align-items-center justify-content-start text-center sm__wrap " +
                          (rightToLeft
                            ? "dark-border-right"
                            : "dark-border-left")
                        }
                      >
                        <span>
                          <img
                            className="img__icon"
                            src={diameterIcon}
                            alt="diameter"
                            style={{
                              height: "15px",
                              width: "15px",
                            }}
                          />
                        </span>
                        <span
                          className={
                            "d-flex flex-column align-items-start  " +
                            (rightToLeft ? "mr-1" : "ml-1")
                          }
                        >
                          <span>
                            {rightToLeft
                              ? t("diameter", { ns: "common" })
                              : t("Rebar", { ns: "common" })}
                          </span>
                          <span>
                            <span>
                              {rightToLeft
                                ? t("Rebar", { ns: "common" })
                                : t("diameter", { ns: "common" })}
                            </span>
                            <span className="text-lowercase unit__sm">
                              ({t("mm", { ns: "measurements" })})
                            </span>
                          </span>
                        </span>
                      </th>
                      <th
                        scope="col"
                        className={
                          "text-capitalize d-flex align-items-center " +
                          (rightToLeft
                            ? "dark-border-right"
                            : "dark-border-left")
                        }
                      >
                        <span className="text-capitalize">
                          {t("length", { ns: "common" })}
                        </span>
                        <span className="text-lowercase unit__sm">
                          ({t("cm", { ns: "measurements" })})
                        </span>
                      </th>
                      <th
                        scope="col"
                        className={
                          "text-capitalize d-flex align-items-center justify-content-start text-center " +
                          (rightToLeft
                            ? "dark-border-right"
                            : "dark-border-left")
                        }
                      >
                        {t("Amount", { ns: "common" })}
                      </th>
                      <th
                        scope="col"
                        className={
                          "text-capitalize d-flex align-items-start justify-content-center text-center flex-column " +
                          (rightToLeft
                            ? "dark-border-right"
                            : "dark-border-left")
                        }
                      >
                        <span>
                          {rightToLeft
                            ? t("weight", { ns: "common" })
                            : t("unit", { ns: "common" })}
                        </span>
                        <span>
                          <span>
                            {rightToLeft
                              ? t("unit", { ns: "common" })
                              : t("weight", { ns: "common" })}
                          </span>
                          <span className="unit__sm">
                            ({t("kg", { ns: "measurements" })})
                          </span>
                        </span>
                      </th>
                      <th
                        scope="col"
                        className={
                          "text-capitalize d-flex align-items-start justify-content-center text-center flex-column " +
                          (rightToLeft
                            ? "dark-border-left"
                            : "dark-border-right")
                        }
                      >
                        <span>
                          {rightToLeft
                            ? t("weight", { ns: "common" })
                            : t("total", { ns: "common" })}
                        </span>
                        <span>
                          <span>
                            {rightToLeft
                              ? t("total", { ns: "common" })
                              : t("weight", { ns: "common" })}
                          </span>
                          <span className="unit__sm">
                            ({t("kg", { ns: "measurements" })})
                          </span>
                        </span>
                      </th>
                      {priceShow && (
                        <>
                          <th
                            scope="col"
                            className="text-capitalize d-flex align-items-start justify-content-start text-center flex-column"
                          >
                            <span>{t("unit", { ns: "common" })}</span>
                            <span>
                              <span>{t("Price", { ns: "common" })}</span>
                              <span className="unit__sm">(NIS)</span>
                            </span>
                          </th>
                          <th
                            scope="col"
                            className="text-capitalize d-flex align-items-start justify-content-start text-center flex-column"
                          >
                            <span>{t("total", { ns: "common" })}</span>
                            <span>
                              <span>{t("Price", { ns: "common" })}</span>
                              <span className="unit__sm">(NIS)</span>
                            </span>
                          </th>
                        </>
                      )}
                    </tr>
                  </thead>
                  {/* <!-- table body data section  --> */}
                  <tbody
                    style={
                      currentOrderRebars.length > 9
                        ? {
                            height: "720px",
                            overflow: "auto",
                            display: "block",
                            scrollbarWidth: "none",
                          }
                        : {
                          scrollbarWidth: "none",
                        }
                    }
                  >
                    {/* <!-- table data row   --> */}
                    {currentOrderRebars.length > 0 &&
                      currentOrderRebars.map((itemRebar, index) => (
                        <tr
                          key={"itemRebar" + index}
                          className={priceShow ? "WithPrice" : "WithoutPrice"}
                          style={{ position: "relative" }}
                        >
                          <td
                            style={{ padding: "0px" }}
                            className="d-flex justify-content-center"
                          >
                            <TicketCounter
                              setSelectedRowCount={setSelectedRowCount}
                              selectedRowCount={selectedRowCount}
                              itemId={itemRebar.id}
                            />
                          </td>
                          <td
                            className={
                              rightToLeft
                                ? "dark-border-right"
                                : "dark-border-left"
                            }
                            style={{
                              padding: "0px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <span className="table__para">{index + 1}</span>
                          </td>

                          <td
                            style={{
                              height: "80px",
                              padding: "0px 6px 0px 15px",
                            }}
                            className={
                              "justify-content-lg-between " +
                              (rightToLeft
                                ? "dark-border-right"
                                : "dark-border-left")
                            }
                          >
                            <RebarImage
                              preview_image={itemRebar.tool.preview_image}
                              index={index}
                            />
                            <span
                              className="search__icon mr-2"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleImagePreview(itemRebar.tool.preview_image)
                              }
                            >
                              <FontAwesomeIcon icon="search"></FontAwesomeIcon>
                            </span>
                          </td>

                          <td
                            className={
                              "justify-content-center " +
                              (rightToLeft
                                ? "dark-border-right"
                                : "dark-border-left")
                            }
                          >
                            <span>
                              <span
                                className={"d-flex justify-content-center "}
                                style={
                                  itemRebar.tool.name
                                    ? itemRebar.tool.name.includes(" ")
                                      ? {textAlign: "center"}
                                      : { wordBreak: "break-all", textAlign: "center" }
                                    : {}
                                }
                              >
                                {itemRebar.tool.name || ""}
                              </span>
                            </span>
                          </td>

                          <td
                            style={
                              itemRebar.production_notes
                                ? { alignItems: "flex-start" }
                                : {}
                            }
                            className={
                              "justify-content-start " +
                              (rightToLeft
                                ? "dark-border-right"
                                : "dark-border-left")
                            }
                          >
                            <span
                              style={
                                itemRebar.production_notes
                                  ? { marginTop: "10px" }
                                  : {}
                              }
                            >
                              {steelTypes.length > 0 &&
                                steelTypes.find(
                                  (item) =>
                                    item.id === itemRebar.tool.steel_type
                                ) &&
                                steelTypes.find(
                                  (item) =>
                                    item.id === itemRebar.tool.steel_type
                                ).name}
                            </span>
                          </td>
                          <td
                            style={
                              itemRebar.production_notes
                                ? { alignItems: "flex-start" }
                                : {}
                            }
                            className={
                              "justify-content-start " +
                              (rightToLeft
                                ? "dark-border-right"
                                : "dark-border-left")
                            }
                          >
                            <span
                              style={
                                itemRebar.production_notes
                                  ? { marginTop: "10px" }
                                  : {}
                              }
                            >
                              {itemRebar.tool.diameter.steel_diameter}
                            </span>
                          </td>
                          <td
                            style={
                              itemRebar.production_notes
                                ? { alignItems: "flex-start" }
                                : {}
                            }
                            className={
                              "justify-content-start " +
                              (rightToLeft
                                ? "dark-border-right"
                                : "dark-border-left")
                            }
                          >
                            <span
                              style={
                                itemRebar.production_notes
                                  ? { marginTop: "10px" }
                                  : {}
                              }
                            >
                              {itemRebar.tool.rebar_side.reduce(
                                (previousValue: number, item: any) =>
                                  previousValue + parseInt(item.length),
                                0
                              )}
                            </span>
                          </td>
                          <td
                            className={
                              "justify-content-start " +
                              (rightToLeft
                                ? "dark-border-right"
                                : "dark-border-left")
                            }
                          >
                            <span
                              style={
                                itemRebar.production_notes
                                  ? { marginBottom: "16px" }
                                  : {}
                              }
                            >
                              {itemRebar.quantity
                                .toString()
                                .replace(commaSeparated, ",")}
                            </span>
                          </td>
                          <td
                            className={
                              "justify-content-start " +
                              (rightToLeft
                                ? "dark-border-right"
                                : "dark-border-left")
                            }
                          >
                            <span>
                              {itemRebar.tool.unit_weight
                                .toString()
                                .replace(commaSeparated, ",")}
                            </span>
                          </td>
                          <td
                            className={
                              "justify-content-start " +
                              (rightToLeft
                                ? "dark-border-left"
                                : "dark-border-right")
                            }
                          >
                            <span>
                              {(itemRebar.tool.unit_weight * itemRebar.quantity)
                                .toFixed(2)
                                .toString()
                                .replace(commaSeparated, ",")}
                            </span>
                          </td>
                          {priceShow && (
                            <>
                              <td className="justify-content-start">
                                <span>
                                  {itemRebar.unit_price
                                    ? parseFloat(itemRebar.unit_price)
                                        .toFixed(2)
                                        .toString()
                                        .replace(commaSeparated, ",")
                                    : "-"}
                                </span>
                              </td>
                              <td className="justify-content-start">
                                <span>
                                  {itemRebar.unit_price
                                    ? (
                                        parseFloat(itemRebar.unit_price) *
                                        itemRebar.quantity
                                      )
                                        .toFixed(0)
                                        .toString()
                                        .replace(commaSeparated, ",")
                                    : "-"}
                                </span>
                              </td>
                            </>
                          )}
                          {itemRebar.production_notes && (
                            <td
                              colSpan={2}
                              className="light-border-top note-td"
                              style={{
                                padding: "0px",
                                position: "absolute",
                                marginTop: "56px",
                                alignItems: "normal",
                                justifyContent: "flex-start",
                                width: priceShow ? "73%" : "33.4%",
                                ...(rightToLeft
                                  ? {
                                      right: priceShow
                                        ? "250px"
                                        : "calc(100% - 51.4%)",
                                      textAlign: "right",
                                    }
                                  : {
                                      left: priceShow
                                        ? "250px"
                                        : "calc(100% - 51.4%)",
                                    }),
                              }}
                            >
                              <p style={{ margin: "0 5px", fontSize: "15px" }}>
                                {t("note", { ns: "common" })}:{" "}
                                {itemRebar.production_notes}
                              </p>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                  <tbody className="last">
                    <tr
                      className={
                        priceShow ? "lastWithPrice" : "lastWithoutPrice"
                      }
                    >
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style={removeBgBorder}></td>
                      <td className="justify-content-center">{rebarUnits}</td>
                      <td className="justify-content-center" colSpan={2}>
                        {rebarTons}
                      </td>
                      {priceShow && <td colSpan={2}>{rebarPrice}</td>}
                    </tr>
                    <tr
                      className={
                        priceShow ? "lastWithPrice" : "lastWithoutPrice"
                      }
                    >
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style={removeBgBorder}></td>
                      <td className="justify-content-center">
                        {t("units", { ns: "common" })}
                      </td>
                      <td
                        className="justify-content-center"
                        colSpan={2}
                        style={{ textTransform: "capitalize" }}
                      >
                        {t("weight", { ns: "common" })} (
                        {t("ton", { ns: "measurements" })})
                      </td>
                      {priceShow && (
                        <td colSpan={2}>
                          {t("Price", { ns: "common" })} (NIS)
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </section>
      {currentOrderNets.length > 0 && (
        <NetsOrderTable
          priceShow={priceShow}
          handleImagePreview={handleImagePreview}
          netUnits={netUnits}
          netTons={netTons}
          netPrice={netPrice}
          selectedRowCount={selectedRowCount}
          setSelectedRowCount={setSelectedRowCount}
        />
      )}

      {currentOrderPiles.length > 0 && (
        <PilesOrderTable
          priceShow={priceShow}
          handleImagePreview={handleImagePreview}
          pileUnits={pileUnits}
          pileTons={pileTons}
          selectedRowCount={selectedRowCount}
          setSelectedRowCount={setSelectedRowCount}
        />

      )}

      <div className="container pb-5">
        <div className="bottom__info">
          <div className="selection__info">
            <p className={"info__text " + (rightToLeft ? "ml-3" : "mr-3")}>
              {selectedRowCount.filter((item) => item.countt > 0).length}/
              {currentOrderRebars.length + currentOrderNets.length + currentOrderPiles.length}{" "}
              {t("item selected", { ns: "ticketCreator" })}
            </p>
            <p className="selectAll__link">
              {selectedRowCount.filter((item) => item.countt > 0).length ===
              selectedRowCount.length ? (
                <span onClick={handleSelectNone}>
                  {t("Deselect all", { ns: "ticketCreator" })}
                </span>
              ) : (
                <span onClick={handleSelectAll}>
                  {t("select all", { ns: "ticketCreator" })}
                </span>
              )}
            </p>
          </div>
          <div className="description">
            <p>{t("mark or unmark", { ns: "ticketCreator" })}</p>
          </div>
          <button
            className="print__tickets-btn text-center"
            onClick={() => setPrintPopUpShow(true)}
            disabled={
              selectedRowCount.filter((item) => item.countt > 0).length === 0
            }
          >
            {t("print tickets", { ns: "ticketCreator" })}
          </button>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
