import React, { useCallback, useState } from "react";
import { Circle } from "react-konva";

const EdgeController = (props) => {
  const {
    edges,
    onEdgeSelected,
    onEdgeDragStart,
    onEdgeDragMove: onEdgeDragged,
    onEdgeDragEnd,
    scale,
    setIsEdgeHovered,
  } = props;

  const [hoveredEdgeId, setHoveredEdgeId] = useState(null);

  const handleEdgeSelected = useCallback(
    (edgeId: string) => (e: any) => {
      e.cancelBubble = true;
      onEdgeSelected(edgeId);
    },
    [onEdgeSelected]
  );

  return (
    <>
      {edges.map((edge) => (
        <Circle
          key={edge.id + "edgeController"}
          id={edge.id}
          x={edge.x}
          y={edge.y}
          radius={hoveredEdgeId === edge.id ? 8 / scale : 5 / scale}
          fill={hoveredEdgeId === edge.id ? "#00B38F" : "#374051"}
          opacity={hoveredEdgeId === edge.id ? 1 : 0.8}
          draggable
          onClick={handleEdgeSelected(edge.id)}
          onTap={handleEdgeSelected(edge.id)}
          onDragStart={(e: any) => {
            e.cancelBubble = true;
            e.evt.preventDefault();
            const edgeId = e.target.id();
            onEdgeDragStart(edges.find((edge) => edge.id === edgeId));
          }}
          onDragMove={(e: any) => {
            e.evt.preventDefault();
            const edgeId = e.target.id();
            onEdgeDragged(edges.find((edge) => edge.id === edgeId));
          }}
          onDragEnd={() => {
            onEdgeDragEnd();
          }}
          onMouseEnter={() => {
            setHoveredEdgeId(edge.id);
            setIsEdgeHovered(true);
          }}
          onMouseLeave={() => {
            setHoveredEdgeId(null);
            setIsEdgeHovered(false);
          }}
        />
      ))}
    </>
  );
};
export default EdgeController;
