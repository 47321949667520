import PdfPreview from "Components/pdf-preview";
import { Modal } from "react-bootstrap";

function PdfPreviewModal ({...props}: any) {
  const {
    downloadFlag,
    setDownloadFlag,
    printCanvas,
    setPrintCanvas,
    setGeneratePdfFlag,
    generatePdfFlag,
    setPdfFile,
    pdfFile,
    setLoader,
    loader,
    setOrderPdfDownload,
    orderPdfDownload,
    onHide,
    order,
    rebars,
    nets,
    piles,
    setPdfType,
    pdfType,
    bidFees,
    pdfLanguage,
    feeType,
  } = props;
  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-document-w"
      >
        <PdfPreview
          downloadFlag={downloadFlag}
          setDownloadFlag={() => setDownloadFlag(false)}
          printCanvas={printCanvas}
          setPrintCanvas={() => setPrintCanvas(false)}
          setGeneratePdfFlag={() => setGeneratePdfFlag(false)}
          generatePdfFlag={generatePdfFlag}
          setPdfFile={setPdfFile}
          pdfFile={pdfFile}
          setLoader={setLoader}
          loader={loader}
          setOrderPdfDownload={() => setOrderPdfDownload(false)}
          orderPdfDownload={orderPdfDownload}
          onHide={onHide}
          order={order}
          rebars={rebars}
          nets={nets}
          piles={piles}
          priceShow={false}
          setPdfType={setPdfType}
          pdfType={pdfType}
          bidFees={bidFees}
          pdfLanguage={pdfLanguage}
          feeType={feeType}
        />
      </Modal>
    </>
  );
}

export default PdfPreviewModal;
