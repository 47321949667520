import axios, {
  AxiosResponse, AxiosError,
} from 'axios';

/* eslint-disable */
export const isAxiosResponse = (response: AxiosResponse<any> | AxiosError<any>): response is AxiosResponse => {
  return (response as AxiosResponse).status !== undefined;
}

export const isAxiosError = (response: AxiosResponse<any> | AxiosError<any>): response is AxiosError => {
  return (response as AxiosError).isAxiosError !== undefined;
}


const http = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_IP || 'http://77.68.49.169/api/',
});

http.interceptors.request.use(
  (config) => {
    const key = localStorage.getItem('access_token');
    if (key) {
      config.headers = {
        Authorization: `Bearer ${key}`,
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refresh_token');
  if (!refreshToken) return;
  return http.post('token/refresh/', { refresh: refreshToken });
};

http.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      ((error.response && error.response.status === 403) ||
        (error.response && error.response.status === 401)) &&
      originalRequest.url !== 'token/refresh/' &&
      !originalRequest._retry
    ) {
      localStorage.removeItem('access_token');
      originalRequest._retry = true;
      try {
        const access_token = await refreshAccessToken();
        if (access_token && isAxiosResponse(access_token)) {
          axios.defaults.headers.common['Authorization'] =
          'Bearer ' + access_token.data.access;
          localStorage.setItem('access_token', access_token.data.access);
          return http(originalRequest);
        }
      } catch (err) {
        localStorage.removeItem('refresh_token');
      }
    }
    return Promise.reject(error);
  }
);

const get = (url: string, headers = {}, params = {}) => {
  return http.get(url, {
    ...params,
    headers: {
      ...headers,
    },
  });
};

const post = (url: string, data: Object, headers = {}, params = {}) => {
  return http.post(url, data, {
    params:{
      ...params,
    },
    headers: {
      ...headers,
    },
  });
};

const put = (url: string, data: Object, headers = {}) => {
  return http.put(url, data, {
    headers: {
      ...headers,
    },
  });
};

const remove = (url: string, params = {}, headers = {}) => {
  return http.delete(url, {
    headers: {
      ...headers,
    },
    data: {
      ...params
    },
  });
};

const patch = (url: string, data: Object, headers = {}) => {
  return http.patch(url, data, {
    headers: {
      ...headers,
    },
  });
};

const Request = {
  get,
  post,
  put,
  remove,
  patch,
};

export default Request;
  