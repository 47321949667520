import React, {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Measure from 'react-measure';

import emptySet from 'Assets/img/icon/set.svg';
import emptySetSide from 'Assets/img/icon/setSide.svg';
import create from 'Assets/img/cages/create.svg';
import ball from 'Assets/img/cages/ball.svg';
import ballBlackGreen from 'Assets/img/cages/ball_black_green.svg';
import ballGreenBlack from 'Assets/img/cages/ball_green_black.svg';
import tunnel from 'Assets/img/cages/tunnel.svg';
import ballFour from 'Assets/img/cages/four__ball.svg';
import fourBars from 'Assets/img/icon/fourBars.svg';
import rings from 'Assets/img/cages/rings.svg';
import gamaImg from 'Assets/img/cages/gama.svg';
import earImg from 'Assets/img/cages/ear.svg';
import length from 'Assets/img/cages/length.svg';
import quantity from 'Assets/img/cages/quantity.svg';
import weight from 'Assets/img/cages/weight.svg';
import writer from 'Assets/img/cages/writer.svg';
import sidePile from 'Assets/img/cages/side-pile.svg';
import internal from 'Assets/img/cages/internet.svg';
import middle from 'Assets/img/cages/middle.svg';
import group  from 'Assets/img/cages/Group 4355.svg';
import eyePreview from 'Assets/img/cages/eye.svg';
import spiralBg from 'Assets/img/icon/spiralBg.svg';

import { pileDiametersOptions } from 'Constants/pile-constants';
import PileShape from "Components/tool/pile/shape/shape";
import BaseStage from "Components/tool/pile/shape/baseStage";
import SideStage from "Components/tool/pile/shape/sideStage";
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";

function PileMobileContent({
  shapeObj,
  setShape,
  base,
  setBase,
  ring,
  setRing,
  gama,
  setGama,
  ear,
  setEar,
  labelingPoints,
  setLabelingPoints,
  orderItem,
  setOrderItem,
  getLengthDiameter,
  getSpiralDiameter,
  selectedShape,
  setSelectedShape,
  standardShapes,
  pileNumber,
  setPileNumber,
  savePile,
}: any) {
  const [canvasWidth, setCanvasWidth] = useState(30);
  const [canvasHeight, setCanvasHeight] = useState(30);
  const { t } = useTranslation(['common', 'tool', 'measurements']);


  function canvasSizeChanged(contentRect: any) {
    setCanvasHeight(contentRect.bounds.height);
    setCanvasWidth(contentRect.bounds.width);
  }

  const steelTypes = useAppSelector(state => state.steelType.steelTypes);
  const steelDiameters = useAppSelector(state => state.steelType.steelDiameters);

  return (
  <>
    {/* <!-- type section  --> */}
    <div className="w-xs-100 d-flex justify-content-between align-items-center bg-white p-3">
      <p className="para text-center text-uppercase text-dark">type</p>
      <div className="btn-group mt-2" role="group" aria-label="Basic example">
        <button
        onClick={() => setBase(true)}
        type="button" className={"btn para para text-uppercase" + (base ? " active" : "")}>base</button>
        <button type="button"
        onClick={() => setBase(false)}
        className={"btn para text-uppercase" + (!base ? " active" : "")}>side</button>
      </div>
    </div>
    {base &&
    <>
      {/* <!-- dark section  --> */}
      <div className="p-3 d-flex dark__head align-items-center bg_gradient">
        <span className="icon__wrap text-success d-flex align-items-center justify-content-center mr-3">
          <FontAwesomeIcon icon={["far", "star"]} />
        </span>
        <h2 className="black__wrap text-uppercase text-left text-white mb-0">Base Pile</h2>
      </div>
      {/* <!-- base section  --> */}
      <div className="p-3 p-sm-4 bg-white">
        {/* <!-- diameter section --> */}
        <div className="icon__form d-flex justify-content-between border__bottom py-3">
          {/* <!-- left section  --> */}
          <div className="left__sec d-flex align-items-center">
            {/* <!-- icon  --> */}
            <div className="icon__wrap mr-3">
              <img src={emptySetSide} alt="set icon"/>
            </div>
            {/* <!-- para  --> */}
            <p className="big__para text-capitalize">diameter</p><span className="unit">({t("mm", { ns: "measurements" })})</span>
          </div>
          {/* <!-- right section  --> */}
          <div className="right__sec d-flex justify-content-end align-items-center w-xs-40">
            {/* <h6 className="big__tittle mb-0"></h6> */}
            <select className="form-control ml-3"
            value={shapeObj?.base_diameter?.id || ''}
            onChange={(e) => {
              const _diameter = getLengthDiameter(parseInt(e.target.value));
              if (_diameter) {
                setShape({...shapeObj, base_diameter: _diameter});
              }
            }}>
              <option value="" disabled>Select Diameter</option>
              {shapeObj.length_steel_type in steelDiameters
              && steelDiameters[shapeObj.length_steel_type].map((reDi: any, index: number) =>
                <option key={'base-diameter-options-' + index} value={reDi.id}>{reDi.steel_diameter.toFixed(0)}</option>
              )}
            </select>
          </div>
        </div>
        {/* <!-- amount section  --> */}
        <div className="icon__form d-flex justify-content-between border__bottom py-3">
          {/* <!-- left section  --> */}
          <div className="left__sec d-flex align-items-center">
            {/* <!-- icon  --> */}
            <div className="icon__wrap mr-3">
              <img src={ballFour} alt="four ball icon"/>
            </div>
            {/* <!-- para  --> */}
            <p className="big__para text-capitalize">amount</p><span className="unit">(unit)</span>
          </div>
          {/* <!-- right section  --> */}
          <div className="right__sec d-flex justify-content-end align-items-center  w-xs-40">
            {/* <h6 className="big__tittle mb-0"></h6> */}
            <input type="text" className="form-control bg-white ml-3"
            value={shapeObj.base_amount}
            onChange={(e) => setShape({...shapeObj, base_amount: e.target.value})} />
          </div>
        </div>
        {/* <!-- icon and form  --> */}
        <div className="icon__form d-flex justify-content-between border__bottom py-3">
          {/* <!-- left section  --> */}
          <div className="left__sec d-flex align-items-center">
            {/* <!-- icon  --> */}
            <div className="icon__wrap mr-3">
              <img src={emptySet} alt="set icon"/>
            </div>
            {/* <!-- para  --> */}
            <p className="big__para text-uppercase">steel type</p>
          </div>
          {/* <!-- right section  --> */}
          <div className="right__sec d-flex justify-content-end align-items-center">
            {/* <h6 className="big__tittle mb-0"></h6> */}
            <select className="form-control w-xs-100 ml-3"
            value={shapeObj.length_steel_type || ''}
            onChange={({target}) => setShape((prev: any) => ({...prev, length_steel_type: parseInt(target.value)}))}
           >
             <option disabled value="">Select Steel Type</option>
             {steelTypes.map((st: {id: number, name: string}) => <option key={'rebar-diameter-options-' + st.id} value={st.id}>{st.name}</option> )}
            </select>
          </div>
        </div>
        <div className="main__img__table mt-3 mt-sm-4 d-flex align-items-center justify-content-center px-2 py-3 rounded">
          <Measure bounds onResize={canvasSizeChanged}>
            {({ measureRef }) => {
              return (
                <div className="right__img w-xs-100 mobile-height"ref={measureRef}>
                  <BaseStage
                  canvasWidth={canvasWidth}
                  canvasHeight={canvasHeight}
                  shapeObj={shapeObj}
                  base={base}
                  />
                </div>
              )}
            }
          </Measure>
        </div>
      </div>
      {/* <!-- dark section  --> */}
      <div className="p-3 d-flex dark__head align-items-center bg_gradient">
        <span className="icon__wrap text-success d-flex align-items-center justify-content-center mr-3">
          <FontAwesomeIcon icon={["far", "star"]} />
        </span>
        <h2 className="black__wrap text-uppercase text-left text-white mb-0">staddard Cages</h2>
      </div>
      {/* <!-- image slider  --> */}
      <div className="img__slider p-3 p-sm-4 p-md-5 bg-white">
        <div className="img__container d-flex pt-1 pl-1 pb-4">
          {standardShapes && standardShapes.map((item: any, index: number) =>
            <div
              key={"default_piles"+index}
              style={selectedShape === index ? {boxShadow: "0 0 0 0.2rem rgb(0 123 255 / 25%)"} : {}}
              className="card__list"
              onClick={() => setSelectedShape(index)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="73.906" height="78.468" viewBox="0 0 73.906 78.468">
                <g id="Group_4272" data-name="Group 4272" transform="translate(-1749.768 -1505.23)">
                  <g id="Group_4271" data-name="Group 4271" transform="translate(1750.984 1528.481)">
                    <line id="Line_3241" data-name="Line 3241" x2="68.094" transform="translate(0 0.348)" fill="none" stroke="#4e5055" stroke-width="2"/>
                    <line id="Line_3242" data-name="Line 3242" x2="68.094" transform="translate(0 5.331)" fill="none" stroke="#4e5055" stroke-width="2"/>
                    <line id="Line_3243" data-name="Line 3243" x2="68.094" transform="translate(0 25.261)" fill="none" stroke="#4e5055" stroke-width="2"/>
                    <line id="Line_3244" data-name="Line 3244" x2="68.094" transform="translate(0 30.243)" fill="none" stroke="#4e5055" stroke-width="2"/>
                    <path id="Path_6339" data-name="Path 6339" d="M-7392.233,558.533l5.7-30.243,4.046,30.243,6.723-30.243,5.383,30.243,6.239-30.243,6.685,30.243,6.2-30.243,5.708,30.243,4.153-30.243,4.48,30.243" transform="translate(7404.303 -528.29)" fill="none" stroke="#4e5055" stroke-width="2"/>
                  </g>
                  <text id="הזמנה_מס_2009" data-name="הזמנה מס׳ 2009" transform="translate(1786.037 1521.23)" fill="#4e5055" font-size="15" font-family="Heebo-Medium, Heebo" font-weight="500" letter-spacing="0.02em">
                    <tspan x="-17.527" y="0">{item.l1_length + item.l2_length + item.l3_length}</tspan>
                  </text>
                  <text id="הזמנה_מס_2009-2" data-name="הזמנה מס׳ 2009" transform="translate(1765.768 1577.698)" fill="#4e5055" font-size="15" font-family="Heebo-Medium, Heebo" font-weight="500" letter-spacing="0.02em">
                    <tspan x="-15.551" y="0">@{item.l2_gap}</tspan>
                  </text>
                  <text id="הזמנה_מס_2009-3" data-name="הזמנה מס׳ 2009" transform="translate(1807.674 1577.698)" fill="#4e5055" font-size="15" font-family="Heebo-Medium, Heebo" font-weight="500" letter-spacing="0.02em">
                    <tspan x="-15.551" y="0">@{item.l3_gap}</tspan>
                  </text>
                </g>
              </svg>
            </div>
          )}
        </div>
      </div>
    </>
    }
    {!base &&
    <>
    {/* <!-- dark section side pile --> */}
    <div className="p-3 d-flex dark__head align-items-center  bg_gradient">
      <span className="icon__wrap text-success d-flex align-items-center justify-content-center mr-3">
        <img src={sidePile} alt="side pile"/>
      </span>
      <h2 className="black__wrap text-uppercase text-left text-white mb-0">side pile</h2>
    </div>
    {/* <!-- internet section  --> */}
    <div className="p-3 p-sm-4 bg-white">
      {/* <!-- internet --> */}
      <div className="icon__form d-flex justify-content-between green__border__bottom pb-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center parent__wrap">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={internal} alt="internet icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize">External</p>
        </div>
      </div>
      {/* <!-- diameter section --> */}
      <div className="icon__form d-flex justify-content-between border__bottom py-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={emptySetSide} alt="set icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize">diameter</p><span className="unit">({t("mm", { ns: "measurements" })})</span>
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-end align-items-center w-xs-40">
          {/* <h6 className="big__tittle mb-0"></h6> */}
          <select className="form-control ml-3"
          value={shapeObj.external_diameter.id || ''}
          onChange={(e) => {
            const _diameter = getLengthDiameter(parseInt(e.target.value));
            if (_diameter) {
              setShape({...shapeObj, external_diameter: _diameter});
            }
          }}>
            <option value="" disabled>Select Diameter</option>
            {shapeObj.length_steel_type in steelDiameters
            && steelDiameters[shapeObj.length_steel_type].map((reDi: any, index: number) =>
              <option key={'internal-diameter-options-' + index} value={reDi.id}>{reDi.steel_diameter.toFixed(0)}</option>
            )}
          </select>
        </div>
      </div>
      {/* <!-- amount section  --> */}
      <div className="icon__form d-flex justify-content-between border__bottom py-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={ballFour} alt="four ball icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize">amount</p><span className="unit">(unit)</span>
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-end align-items-center  w-xs-40">
          {/* <h6 className="big__tittle mb-0"></h6> */}
          <input type="text" className="form-control bg-white ml-3"
          value={shapeObj.external_amount}
          onChange={(e) => setShape({...shapeObj, external_amount: e.target.value})} />
        </div>
      </div>
    </div>
    {/* <!-- middle section  --> */}
    <div className="p-3 p-sm-4 bg-white">
      {/* <!-- internet --> */}
      <div className="icon__form d-flex justify-content-between green__border__bottom pb-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center parent__wrap">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={middle} alt="middle icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize">middle</p>
        </div>
      </div>
      {/* <!-- diameter section --> */}
      <div className="icon__form d-flex justify-content-between border__bottom py-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={emptySetSide} alt="set icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize">diameter</p><span className="unit">({t("mm", { ns: "measurements" })})</span>
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-end align-items-center  w-xs-40">
          {/* <h6 className="big__tittle mb-0"></h6> */}
          <select className="form-control ml-3"
          value={shapeObj.middle_diameter.id || ''}
          onChange={(e) => {
            const _diameter = getLengthDiameter(parseInt(e.target.value));
            if (_diameter) {
              setShape({...shapeObj, middle_diameter: _diameter});
            }
          }}>
            <option value="" disabled>Select Diameter</option>
            {shapeObj.length_steel_type in steelDiameters
            && steelDiameters[shapeObj.length_steel_type].map((reDi: any, index: number) =>
              <option key={'base-diameter-options-' + index} value={reDi.id}>{reDi.steel_diameter.toFixed(0)}</option>
            )}
          </select>
        </div>
      </div>
      {/* <!-- amount section  --> */}
      <div className="icon__form d-flex justify-content-between border__bottom py-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={ballFour} alt="four ball icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize">amount</p><span className="unit">(unit)</span>
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-end align-items-center w-xs-40">
          {/* <h6 className="big__tittle mb-0"></h6> */}
          <input type="text" className="form-control bg-white ml-3" 
          value={shapeObj.middle_amount}
          onChange={(e) => setShape({...shapeObj, middle_amount: e.target.value})} />
        </div>
      </div>
    </div>
    {/* <!-- internal section bottom  --> */}
    <div className="p-3 p-sm-4 bg-white">
      {/* <!-- internet --> */}
      <div className="icon__form d-flex justify-content-between green__border__bottom pb-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center parent__wrap">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={group} alt="internet bottom icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize">Internal</p><span className="unit">({t("cm", { ns: "measurements" })})</span>
        </div>
      </div>
      {/* <!-- diameter section --> */}
      <div className="icon__form d-flex justify-content-between border__bottom py-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={emptySetSide} alt="set icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize">diameter</p><span className="unit">({t("mm", { ns: "measurements" })})</span>
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-end align-items-center  w-xs-40">
          {/* <h6 className="big__tittle mb-0"></h6> */}
          <select className="form-control ml-3"
          value={shapeObj.internal_diameter.id || ''}
          onChange={(e) => {
            const _diameter = getLengthDiameter(parseInt(e.target.value));
            if (_diameter) {
              setShape({...shapeObj, internal_diameter: _diameter});
            }
          }}>
            <option value="" disabled>Select Diameter</option>
            {shapeObj.length_steel_type in steelDiameters
            && steelDiameters[shapeObj.length_steel_type].map((reDi: any, index: number) =>
              <option key={'base-diameter-options-' + index} value={reDi.id}>{reDi.steel_diameter.toFixed(0)}</option>
            )}
          </select>
        </div>
      </div>
      {/* <!-- amount section  --> */}
      <div className="icon__form d-flex justify-content-between border__bottom py-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={ballFour} alt="four ball icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize">amount</p><span className="unit">(unit)</span>
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-end align-items-center  w-xs-40">
          {/* <h6 className="big__tittle mb-0"></h6>/ */}
          <input type="text" className="form-control bg-white ml-3"
          value={shapeObj.internal_amount}
          onChange={(e) => setShape({...shapeObj, internal_amount: e.target.value})} />
        </div>
      </div>
    </div>
    {/* <!-- preview and btn  --> */}
    <div className="p-3 bg-white p-sm-4 p-md-5">
      {/* <!-- btn icon and heading  --> */}
      <div className="btn__icon__head d-flex justify-content-between green__border__bottom pb-3">
        {/* <!-- icon and heading  --> */}
        <div className="icon__head d-flex align-items-center justify-content-center">
          <span className="icon__wrap mr-3 text-dark"><img src={eyePreview} alt="eye icon"/></span>
          <p className="big__para">Preview</p>
        </div>
      </div>
      {/* <!-- image image table  --> */}
      <div className="main__img__table mt-3 mt-sm-4 d-flex align-items-center justify-content-center px-2 py-3 rounded">
        {/* <!-- left image  --> */}
        <div className="left__img w-xs-100">
          <Measure bounds onResize={canvasSizeChanged}>
              {({ measureRef }) => {
                return (
                  <div className="right__img w-xs-100 mobile-height"ref={measureRef}>
                    <SideStage
                    canvasWidth={canvasWidth}
                    canvasHeight={canvasHeight}
                    shapeObj={shapeObj}
                    />
                  </div>
                )}
              }
          </Measure>
        </div>
      </div>
    </div>
    </>
    }
    {/* <!-- dark section  --> */}
    <div className="p-3 d-flex dark__head align-items-center bg_gradient">
      <span className="icon__wrap text-success d-flex align-items-center justify-content-center mr-3">
        <img src={create} alt="create icon"/>
      </span>
      <h2 className="black__wrap text-uppercase text-left text-white mb-0">create shapes</h2>
    </div>
    {/* <!-- preview and btn  --> */}
    <div className="p-3 bg-white p-sm-4 p-md-5">
      {/* <!-- btn icon and heading  --> */}
      <div className="btn__icon__head d-flex justify-content-between">
        {/* <!-- icon and heading  --> */}
        <div className="icon__head d-flex align-items-center justify-content-center">
          <span className="icon__wrap mr-3 text-dark"><img style={{width: '30px'}} src={eyePreview} alt="eye icon"/></span>
          <p className="big__para">Preview</p>
        </div>
        {/* <!-- buttons  --> */}
        <button className="btn bg-dark-500 text-dark d-flex justify-content-between align-items-center cus__btn">
          <span className="icon__wrap"><i className="fas fa-plus"></i></span>
          <p className="ml-2  text-uppercase">add a sketch</p>
        </button>
      </div>
      {/* <!-- image image table  --> */}
      <div className="main__img__table mt-3 mt-sm-4 d-flex align-items-center justify-content-center px-2 py-3 rounded">
        <PileShape
        shapeObj={shapeObj}
        setShape={setShape}
        labelingPoints={labelingPoints}
        setLabelingPoints={setLabelingPoints}
        base={base}
        />
      </div>
      {/* <!-- general size  --> */}
      <div className="sec__img__table">
        {/* Pile Number */}
        <div className="icon__form d-flex justify-content-between py-3 border__bottom">
            {/* <!-- left section  --> */}
            <div className="left__sec d-flex align-items-center">
              {/* <!-- para  --> */}
              <p className="big__para text-capitalize">Cage Number</p>
            </div>
            {/* <!-- right section  --> */}
            <div className="right__sec d-flex justify-content-end align-items-center w-xs-40">
              {/* <h6 className="big__tittle mb-0"></h6> */}
              <input
                type="text"
                className="form-control bg-white border-success"
                value={pileNumber}
                onChange={(e) => {
                  if (e.target.value !== '' && e.target.value[0] === "C") {
                    setPileNumber(e.target.value);
                  }
                }}
              />
            </div>
          </div>
        {/* <!-- btn icon and heading  --> */}
        <div className="btn__icon__head d-flex justify-content-between">
          {/* <!-- icon and heading  --> */}
          <div className="icon__head d-flex align-items-center justify-content-center pt-4">
            <span className="icon__wrap mr-3 text-dark">
              <img src={length} alt="length icon"/>
            </span>
            <p className="big__para">General Size</p><span className="unit">({t("cm", { ns: "measurements" })})</span>
          </div>
        </div>
        {/* <!-- img table  --> */}
        <div className="img__table py-5 net-details"
        style={
          {
            backgroundImage: 'url(' + spiralBg + ')',
            paddingRight: '5px'
          }
        }
        >
          <div className="input__container row">
            {/* <!-- column  --> */}
            <div className="col-4 d-flex align-items-center">
              <label className="text-uppercase mr-2">l1</label>
              <input type="number" className="form-control bg-white border-success input__big"
              value={shapeObj.l1_length || ''}
              onChange={(e) => setShape({...shapeObj, l1_length: parseInt(e.target.value)})}/>
            </div>
            {/* <!-- column  --> */}
            <div className="col-4 d-flex align-items-center">
              <label className="text-uppercase mr-2">l2</label>
              <input type="number" className="form-control bg-white border-success input__big" 
              value={shapeObj.l2_length || ''}
              onChange={(e) => setShape({...shapeObj, l2_length: parseInt(e.target.value)})}/>
            </div>
            {/* <!-- column  --> */}
            <div className="col-4 d-flex align-items-center">
              <label className="text-uppercase mr-2">l3</label>
              <input type="number" className="form-control bg-white border-success input__big"
              value={shapeObj.l3_length || ''}
              onChange={(e) => setShape({...shapeObj, l3_length: parseInt(e.target.value)})}/>
            </div>
          </div>
        </div>
        <div className="img__table net-details">
          <div className="input__container row">
            {/* <!-- column  --> */}
            <div className="col-4 d-flex align-items-center">
            </div>
            {/* <!-- column  --> */}
            <div className="col-4 d-flex align-items-center">
              <label className="text-uppercase mr-2">@1</label>
              <input type="number" className="form-control bg-white border-success input__big"
              value={shapeObj.l2_gap || ''}
              onChange={(e) => setShape({...shapeObj, l2_gap: parseInt(e.target.value)})}/>
            </div>
            {/* <!-- column  --> */}
            <div className="col-4 d-flex align-items-center">
              <label className="text-uppercase mr-2">@2</label>
              <input type="number" className="form-control bg-white border-success input__big"
              value={shapeObj.l3_gap || ''}
              onChange={(e) => setShape({...shapeObj, l3_gap: parseInt(e.target.value)})}/>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <!-- dark section  --> */}
    <div className="p-3 d-flex dark__head align-items-center bg_gradient">
      <span className="icon__wrap text-success d-flex align-items-center justify-content-center mr-3">
        <img src={ball} alt="ball icon"/>
      </span>
      <h2 className="black__wrap text-uppercase text-left text-white mb-0">concrete cage</h2>
    </div>
    {/* <!-- form input list  --> */}
    <div className="form__lists bg-white p-3 p-sm-4">
      {/* <!-- icon and form  --> */}
      <div className="icon__form d-flex justify-content-between border__bottom pb-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={ballBlackGreen} alt="ball icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize">diameter</p><span className="unit">({t("cm", { ns: "measurements" })})</span>
        </div>
        {/* <!-- right section  --> */}
        <h6 className="big__tittle">D</h6>
        <div className="right__sec d-flex justify-content-end align-items-center w-xs-40">
          <input type="number" className="form-control bg-white ml-3"
          value={shapeObj.hole_diameter || ''}
          onChange={(e) => setShape({...shapeObj, hole_diameter: parseInt(e.target.value), cover: parseInt(e.target.value) - shapeObj.cage_diameter})}/>
        </div>
      </div>
      {/* <!-- icon and form  --> */}
      <div className="icon__form d-flex justify-content-between border__bottom py-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={ballGreenBlack} alt="ball icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize">diameter</p> <span className="unit">({t("cm", { ns: "measurements" })})</span>
        </div>
        {/* <!-- right section  --> */}
        <h6 className="big__tittle">d'</h6>
        <div className="right__sec d-flex justify-content-end align-items-center w-xs-40">
          <input type="number" className="form-control bg-white ml-3"
          value={shapeObj.cage_diameter || ''}
          onChange={(e) => setShape({...shapeObj, cage_diameter: parseInt(e.target.value), cover: shapeObj.hole_diameter - parseInt(e.target.value)})}/>
        </div>
      </div>
      {/* <!-- icon and form  --> */}
      <div className="icon__form d-flex justify-content-between pt-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={tunnel} alt="ball icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize">deep</p><span className="unit">({t("cm", { ns: "measurements" })})</span>
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-end align-items-center w-xs-40">
          {/* <h6 className="big__tittle mb-0"></h6> */}
          <input type="number" className="form-control ml-3 bg-white"
          value={shapeObj.hole_depth || ''}
          onChange={(e) => setShape({...shapeObj, hole_depth: parseInt(e.target.value)})}/>
        </div>
      </div>
    </div>
    {/* <!-- dark section  --> */}
    <div className="p-3 d-flex dark__head align-items-center bg_gradient">
      <span className="icon__wrap text-success d-flex align-items-center justify-content-center mr-3">
        <img src={fourBars} alt="length icon"/>
      </span>
      <h2 className="black__wrap text-uppercase text-left text-white mb-0">Spiral</h2>
    </div>
    {/* <!-- length section  --> */}
    <div className="length bg-white p-3 p-sm-4">
      {/* <!-- icon and form  --> */}
      <div className="icon__form d-flex justify-content-between pb-3 border__bottom">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={emptySetSide} alt="set icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize">Diameter</p><span className="unit">({t("mm", { ns: "measurements" })})</span>
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-end align-items-center w-xs-40">
          {/* <h6 className="big__tittle mb-0"></h6> */}
          <select className="form-control ml-3"
          value={shapeObj?.spiral_wire_diameter?.id || ''}
          onChange={(e) => {
            const _diameter = getSpiralDiameter(parseInt(e.target.value));
            if (_diameter) {
              setShape({...shapeObj, spiral_wire_diameter: _diameter});
            }
          }}>
            <option value="" disabled>Select Diameter</option>
            {shapeObj.cage_steel_type in steelDiameters
            && steelDiameters[shapeObj.cage_steel_type].map((reDi: any, index: number) =>
              <option key={'spiral-wire-diameter-options-' + index} value={reDi.id}>{reDi.steel_diameter.toFixed(0)}</option>
            )}
          </select>
        </div>
      </div>
      {/* <!-- icon and form  --> */}
      <div className="icon__form d-flex justify-content-between pt-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={emptySet} alt="set icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-uppercase">steel type</p>
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-end align-items-center">
          {/* <h6 className="big__tittle mb-0"></h6> */}
          <select className="form-control w-xs-100 ml-3"
          value={shapeObj.cage_steel_type || ''}
          onChange={({target}) => setShape((prev: any) => ({...prev, cage_steel_type: parseInt(target.value)}))}
          >
            <option disabled value="">Select Steel Type</option>
            {steelTypes.map((st: {id: number, name: string}) => <option key={'rebar-diameter-options-' + st.id} value={st.id}>{st.name}</option> )}
          </select>
        </div>
      </div>
    </div>
    {/* <!-- dark section  --> */}
    <div className="p-3 d-flex dark__head align-items-center bg_gradient">
      <span className="icon__wrap text-success d-flex align-items-center justify-content-center mr-3">
        <FontAwesomeIcon icon="plus" />
      </span>
      <h2 className="black__wrap text-uppercase text-left text-white mb-0">addition</h2>
    </div>
    {/* <!-- addition section  --> */}
    <div className="length bg-white p-3 p-sm-4">
      {/* <!-- icon and form  --> */}
      <div
        className={"icon__form d-flex justify-content-between pb-3 " +
        (ring ? "green__border__bottom" : "border__bottom")}
      >
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={rings} alt="rings icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize">rings</p>
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-end align-items-center">
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id="rings"
            onChange={() => {
              if (ring === false) {
                const ringsAmount = Math.round((shapeObj.l1_length + shapeObj.l2_length + shapeObj.l3_length)/200);
                setShape({
                  ...shapeObj,
                  ring_diameter: '12',
                  ring_quantity:  ringsAmount.toString(),
                });
              }
              setRing(!ring);
            }}/>
            <label className="custom-control-label" htmlFor="rings"></label>
          </div>
        </div>
      </div>
      {ring &&
      <>
      {/* <!-- icon and form  --> */}
      <div className="icon__form d-flex justify-content-between border__bottom py-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={emptySetSide} alt="set icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize fw-300">diameter</p><span className="unit">({t("mm", { ns: "measurements" })})</span>
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-end align-items-center w-xs-40">
          {/* <h6 className="big__tittle mb-0"></h6> */}
          <select className="form-control ml-3"
          value={shapeObj.ring_diameter} 
          onChange={(e) => setShape({...shapeObj, ring_diameter: e.target.value})}>
            {pileDiametersOptions.map((diameter, index)=><option key={'uniform-options-1' + index} value={diameter}>{diameter}</option> )}
          </select>
        </div>
      </div>
      {/* <!-- quantity  --> */}
      <div className="icon__form d-flex justify-content-between border__bottom py-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={quantity} alt="quantity icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize fw-300">quantity</p>
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-end align-items-center w-xs-40">
          <input type="number" className="form-control ml-3 bg-white"
          value={shapeObj.ring_quantity}
          onChange={(e) => {
            const val = parseInt(e.target.value);
            if (!isNaN(val) && val >= 1 && val < 13) {
              setShape({...shapeObj, ring_quantity: e.target.value});
            }
          }}/>
        </div>
      </div>
      </>
      }
      {/* <!-- gama and toggle  --> */}
      <div
      className={"icon__form d-flex justify-content-between py-3 " +
      (gama ? "green__border__bottom" : "border__bottom")}
      >
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={gamaImg} alt="games icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize">gama</p>
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-end align-items-center">
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id="game"
            onChange={() => {
              if (gama === false) {
                setShape({
                  ...shapeObj,
                  gama_diameter: '2',
                  gama_quantity: shapeObj.hole_diameter <= 70 ? '2' : '3',
                });
              }
              setGama(!gama);
            }}/>
            <label className="custom-control-label" htmlFor="game"></label>
          </div>
        </div>
      </div>
      {gama &&
      <>
      {/* <!-- icon and form  --> */}
      <div className="icon__form d-flex justify-content-between border__bottom py-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={emptySetSide} alt="set icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize fw-300">diameter</p><span className="unit">({t("cm", { ns: "measurements" })})</span>
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-end align-items-center w-xs-40">
          {/* <h6 className="big__tittle mb-0"></h6> */}
          <select className="form-control ml-3"
          value={shapeObj.gama_diameter} 
          onChange={(e) => setShape({...shapeObj, gama_diameter: e.target.value})}>
            <option value="2">2</option>
            {/* {pileDiametersOptions.map((diameter, index)=><option key={'uniform-options-1' + index} value={diameter}>{diameter}</option> )} */}
          </select>
        </div>
      </div>
      {/* <!-- quantity  --> */}
      <div className="icon__form d-flex justify-content-between border__bottom py-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={quantity} alt="quantity icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize fw-300">quantity</p>
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-end align-items-center w-xs-40">
          <input type="number" className="form-control ml-3 bg-white"
          value={shapeObj.gama_quantity}
          onChange={(e) => {
            const val = parseInt(e.target.value);
            if (!isNaN(val) && val >= 2 && val < 5) {
              setShape({...shapeObj, gama_quantity: e.target.value});
            }
          }}/>
        </div>
      </div>
      </>
      }
      {/* <!-- icon and form  --> */}
      <div
      className={"icon__form d-flex justify-content-between py-3 " +
      (ear ? "green__border__bottom" : "border__bottom")}
      >
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={earImg} alt="ear icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-uppercase">ear</p>
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-end align-items-center">
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id="ear"
            onChange={() => {
              if (ear === false) {
                setShape({
                  ...shapeObj,
                  ear_type: 1,
                  ear_length: '20',
                });
              }
              setEar(!ear);
            }}/>
            <label className="custom-control-label bg-success" htmlFor="ear"></label>
          </div>
        </div>
      </div>
      {ear &&
      <>
      {/* <!-- ear type  --> */}
      <div className="icon__form d-flex justify-content-between border__bottom py-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          <p className="para text-capitalize text-dark">Ear Type</p>
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-end align-items-center">
          <div className="btn-group" role="group" aria-label="Basic example">
            <button type="button" className={"btn para" + (shapeObj.ear_type === 1 ? " active": "")}
            onClick={(e) => setShape({...shapeObj, ear_type: 1})}>cartesian</button>
            <button type="button" className={"btn para" + (shapeObj.ear_type === 2 ? " active": "")}
            onClick={(e) => setShape({...shapeObj, ear_type: 2})}>radial</button>
          </div>
        </div>
      </div>
      {/* <!-- length  --> */}
      <div className="icon__form d-flex justify-content-between border__bottom py-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={length} alt="quantity icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize fw-300">Length</p>
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-end align-items-center w-xs-40">
          <input type="number" className="form-control ml-3 bg-white"
          value={shapeObj.ear_length || ""}
          onChange={(e) => {
            const val = parseInt(e.target.value);
            if (!isNaN(val) && val >= 1 && val < 51) {
              setShape({...shapeObj, ear_length: e.target.value});
            }
          }} />
        </div>
      </div>
      </>
      }
    </div>
    {/* <!-- dark section  --> */}
    <div className="p-3 d-flex dark__head align-items-center bg_gradient">
      <h2 className="black__wrap text-uppercase text-left text-white mb-0">quantity and weight</h2>
    </div>
    {/* <!-- quantity and weight section  --> */}
    <div className="p-3 p-sm-4 bg-white bottom__radius">
      {/* <!-- quantity  --> */}
      <div className="icon__form d-flex justify-content-between border__bottom pb-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={quantity} alt="quantity icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize">quantity</p>
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-end align-items-center">
          <input type="text" className="form-control w-xs-30 bg-white"
            value={orderItem.quantity || ''}
            onChange={(e) => setOrderItem({...orderItem, quantity: parseInt(e.target.value)})}
          />
        </div>
      </div>
      {/* <!-- weight  --> */}
      <div className="icon__form d-flex justify-content-between py-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={weight} alt="weight icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize">weight</p><span className="unit">({t("kg", { ns: "measurements" })})</span>
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-between align-items-center w-xs-40">
          <p className="big__para text-capitalize">unit</p>
          <p className="big__para text-capitalize">{shapeObj.unit_weight}</p>
        </div>
      </div>
      {/* <!-- total  --> */}
      <div className="icon__form d-flex justify-content-between pb-3 border__bottom">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-between align-items-center w-xs-40">
          <p className="big__para text-capitalize bold text-dark-500">Total</p>
          <p className="big__para text-capitalize bold text-dark-500">{(orderItem.quantity * shapeObj.unit_weight).toFixed(1)}</p>
        </div>
      </div>
      {/* <!-- item notes  --> */}
      <div className="icon__form d-flex justify-content-between py-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={writer} alt="writer icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize">item notes</p>
        </div>
      </div>
      {/* <!-- note pad  --> */}
      <div className="note__pad">
        <textarea className="form-control" style={{backgroundColor: '#F0F0F0'}} rows={5}
          value={orderItem.production_notes || ''}
          onChange={(e) => setOrderItem({...orderItem, production_notes: e.target.value})}
        ></textarea>
      </div>
      {/* <!-- add item btn  --> */}
      <div className="add__item__btn py-4 py-sm-5 d-flex justify-content-center">
        <button onClick={savePile} className="big__btn text-uppercase bold text-white">add item</button>
      </div>
    </div>
    </>
  );
}

export default PileMobileContent;