import close from '../../../assets/img/new__user__form/close.png';
import { Modal, Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { createContact, removeContact, updateContact } from 'Redux/middlwares/contact';
import { useAppDispatch, useAppSelector } from 'Redux/hooks';
import { useTranslation } from "react-i18next";
import { CONTACTS_ENUM } from "Constants/contacts";

function CreateNewContact (props: any) {
  const { edit, activeContact } = props;
  const dispatch = useAppDispatch();
  const userData = useAppSelector(state => state.user.userData);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [job, setJob] = useState(2);
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation(['common', 'myOrder', 'newOrder']);

  const handleSubmitContact = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoader(true);
    if (edit) {
      dispatch(
        updateContact(
          activeContact.id,
          {
            full_name: name,
            phone,
            role: job,
            user: userData.id,
          },
          (created: boolean) => {
            if (created) {
              props.onHide();
              setLoader(false);
            }
          }
        )
      );
    } else {
      dispatch(createContact(
        {
          full_name: name,
          phone,
          role: job,
          user: userData.id,
        },
        (created: boolean) => {
          if (created) {
            props.onHide();
            setLoader(false);
          }
        }
      ));
    }
  }

  useEffect(() => {
    if (edit) {
      setName(activeContact.full_name);
      setPhone(activeContact.phone);
      setJob(activeContact.role);
    } else {
      setName('');
      setPhone('');
      setJob(2);
    }
  }, [edit, activeContact])

  const handleDeleteContact = () => {
    if (edit) {
      dispatch(
        removeContact(
          activeContact.id,
          (deleted: boolean) => {
            if (deleted) {
              props.onHide();
            }
          }
        )
      );
    }
  }

  return (
    <>
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <section className="forms">
        <div className="container">
          <div className="row py-5 mx-auto">
            <div className="
                                form__header
                                col-sm-12
                                p-4
                                d-flex
                                justify-content-between
                                pt-3
                            ">
              <h1 className="text-white text-uppercase mb-0">
                {t('create new contact', {ns: 'myOrder'})}
              </h1>
                <span className="close__btn" onClick={props.onHide}>
                  <img className="w-100" src={close} alt="close icon" />
                </span>
            </div>
            <div className="col-sm-12 bg-white form__body py-4">
              <div className="form__title pb-md-3 border__bottom pb-2">
                <h3 className="text-uppercase mb-0">{t('contact details', {ns: 'myOrder'})}</h3>
                <div className="d-flex justify-content-between">
                  <span className="text-capitalize">*{t('required fields', {ns: 'myOrder'})}</span>
                  {edit &&
                    <span
                      className="text-capitalize"
                      style={{cursor: 'pointer'}}
                      onClick={handleDeleteContact}
                    ><u>{t('delete this contact', {ns: 'myOrder'})}</u></span>
                  }
                </div>
              </div>
              <form className="pt-4" onSubmit={handleSubmitContact}>
                <div className="inputs pb-4">
                  <div className="form-group d-md-flex justify-content-md-between">
                    <label htmlFor="name" className="text-uppercase">{t('Name')}*</label>
                    <input type="text" className="form-control w-100 w-md-70" id="name" aria-describedby="name"
                      placeholder="Type your full name"
                      value={name || ""}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group d-md-flex justify-content-md-between">
                    <label htmlFor="phone" className="text-uppercase">{t('Phone')}*</label>
                    <input type="number" className="form-control w-100 w-md-70" id="phone" aria-describedby="emailHelp"
                      placeholder="Type your phone number"
                      value={phone || ""}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div className="form-group d-md-flex justify-content-md-between">
                    <label className="text-uppercase" htmlFor="job">{t('Job')}:</label>
                    <select className="form-control w-100 w-md-70" id="job" aria-describedby="job"
                      placeholder="Type your job"
                      value={job || ""}
                      onChange={(e) => setJob(parseInt(e.target.value))}
                    >
                      <option value={CONTACTS_ENUM.SITE_MAN}>{t('site man', {ns: 'newOrder'})}</option>
                      <option value={CONTACTS_ENUM.SITE_MANAGER}>{t('site manager', {ns: 'newOrder'})}</option>
                      <option value={CONTACTS_ENUM.PROJECT_MANAGER}>{t('Project Manager', {ns: 'newOrder'})}</option>
                      <option value={CONTACTS_ENUM.OTHER}>{t('other', {ns: 'newOrder'})}</option>
                    </select>
                  </div>
                </div>
                <div className="
                                        btn__wrap
                                        py-2
                                        pt-4
                                        d-flex
                                        justify-content-between
                                    ">
                  <Button variant="form" onClick={props.onHide}>{t('cancel')}</Button>
                  <button type="submit" className="btn form__btn__black"
                    disabled={
                      loader
                      || name === ""
                      || phone === ""
                    }
                  >
                    {loader &&
                      <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      &nbsp;
                      </>
                    }
                    {t('Save')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Modal>
    </>
  );
}

export default CreateNewContact;
