export const userProfileHe = {
  "User Details": "פרטי המשתמש",
  "Company Details": "פרטי החברה",
  "Notifications": "התראות",
  "Language": "שפה",

  "Profile Pricture": "תמונת פרופיל",
  "First Name": "שם פרטי",
  "Last Name": "שם משפחה",
  "Phone": "טלפון",
  "Job Title": "תפקיד",
  "Mail": "דואר אלקטרוני",
  "Edit Details": "ערוך את פרטי הפרופיל",
  "Change Password": "שנה סיסמה",

  "Compnay": "חברה",
  "Name": "שם",
  "Status": "סטטוס",
  "City": "עיר",
  "Address": "כתובת",
  "BN Number": "ח.פ חברה",
  "Compnay Owner": "אני הבעלים של החברה",
  "Company Part": "אני עובד כחלק מהחברה",
  "Freelancer": "אני נותן שירות",
  "Logo": "לוגו חברה",

  "NewsLetter Flag": "ברצוני לקבל ניוזלטרים, מבצעים וחדשות מאיזיבר",
  "Weekly Report": "ברצוני לקבל דיווח שבועי על פעילות איזיבר",

  "Choose Language": "בחר את השפה שלך",
  "Account Settings": "הגדרות חשבון",

  "Edit Company Details": "עריכת פרטי החברה",
  "No Company": "לא נמצאו נתוני חברה",

  "work as part of company": "אני עובד כחלק מהחברה",
  "term agreemeent": "אני מסכים לתנאי השימוש באתר",

  "terms start": " אני מסכים ל",
  "term conditions": " תנאים",
  "terms last": " של EasyBar ",

  "pswrd inst": "הסיסמה חייבת להכיל",
  "pswrd char": "זה צריך להיות באורך 8 תווים",
  "one letter": "זה צריך להכיל אות אחת",
  "one number": "זה צריך להכיל מספר אחד",
  "special char": "זה צריך להכיל תו מיוחד",
  "Companies list": "רשימת חברות",
  "Current company": "חברה נוכחית",
  "select current company": "בחר חברה נוכחית",
  "add company": "הוספת חברה",
  "company logo": "לוגו חברה",
  "click to add": "לחץ כדי להוסיף לוגו חברה",
  "details": "פרטים",
  "location": "מקום",
  "street": "רחוב",
  "position": "תפקיד",
};
