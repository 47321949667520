import React, { useState } from "react";
import { useAppDispatch } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../layouts/admin";
import CreateBid from "./create-bid/createbid";
import PdfPreviewModal from "../pdf-preview-modal";
import AlertModal from "./../custom-alert-modal/index";
import CrossIcon from "../../assets/img/save_export/delete.svg";
import { getCurrentOrderTools } from "Redux/middlwares/order";
import PriceCard from "./suppliers-pricing/index";
import { REBAR_SUBCLASS_TYPE } from "Constants/rebar-constants";
import { NET_SUBCLASS_TYPE } from "Constants/net-constants";
import { PILE_SUBCLASS_TYPE } from "Constants/pile-constants";
import SupplierPricingDeleteModal from "./supplier-pricing-delete-modal/index";
import { fetchPaginatedPriceList } from "Services/suppliers";
import { Toaster } from 'react-hot-toast';

const BidsCalculator = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common"]);

  const [currentOrderRebars, setCurrentOrderRebars] = useState([]);
  const [currentOrderNets, setCurrentOrderNets] = useState([]);
  const [currentOrderPiles, setCurrentOrderPiles] = useState([]);
  const [pdfPreviewShow, setPdfPreviewShow] = useState(false);
  const [orderPdfDownload, setOrderPdfDownload] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertIcon, setAlertIcon] = useState(CrossIcon);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [generatePdfFlag, setGeneratePdfFlag] = useState(false);
  const [pdfFile, setPdfFile] = useState("");
  const [order, setOrder] = useState({});
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [pdfType, setPdfType] = useState("");
  const [bidFees, setBidFees] = useState({
    stdVal: "",
    easyBarFee: "",
    paymentFee: "",
    totalOrderFee: "",
  });
  const [selectedSupplierList, setSelectedSupplierList] = useState({});
  const [supplierPriceListPage, setSupplierPriceListPage] = useState("");
  const [supplierPriceList, setSupplierPriceList] = useState([]);
  const [pdfLanguage, setPdfLanguage] = useState("en");
  //feeType -> 0:% -> 1:ILs
  const [feeType, setFeeType] = useState(0);

  const onClosePreview = () => {
    setPdfPreviewShow(false);
    setPdfType("");
  };

  const onOpenPreviewHandle = (isPdfDownload: boolean, orderId: number) => {
    dispatch(getCurrentOrderTools(true, orderId))
      .then((res) => {
        setOrder(res);
        setPdfPreviewShow(true);
        if (isPdfDownload) {
          setOrderPdfDownload(true);
        }
        setIsDataFetched(false);
      })
      .catch((err) => {
        setIsDataFetched(false);
      });
  };

  const handleCalculatedPricingData = (data) => {
    if (data) {
      const filtered_rebars = data.order_item.filter(
        (item: any) => item.tool.subclass_type === REBAR_SUBCLASS_TYPE
      );
      const filtered_nets = data.order_item.filter(
        (item: any) => item.tool.subclass_type === NET_SUBCLASS_TYPE
      );
      const filtered_piles = data.order_item.filter(
        (item: any) => item.tool.subclass_type === PILE_SUBCLASS_TYPE
      );
      filtered_rebars.sort((a, b) => a.row_number - b.row_number);
      filtered_nets.sort((a, b) => a.row_number - b.row_number);
      filtered_piles.sort((a, b) => a.row_number - b.row_number);

      setCurrentOrderRebars(filtered_rebars);
      setCurrentOrderNets(filtered_nets);
      setCurrentOrderPiles(filtered_piles);
    }
  };

  //Used when supplier is deleted
  const getInitialSupplierPriceList = () => {
    fetchPaginatedPriceList("")
      .then((res) => {
        if (res.data.results || res.data.results.length > 0) {
          setSupplierPriceList([...res.data.results]);
          if (res.data.next) {
            setSupplierPriceListPage(
              res.data.next.split("cursor=")[1].split("&")[0]
            );
          } else {
            setSupplierPriceListPage(null);
          }
        }
      })
      .catch((err) => {});
  };

  const getSupplierPriceList = () => {
    if (supplierPriceListPage || supplierPriceListPage === "") {
      fetchPaginatedPriceList(supplierPriceListPage)
        .then((res) => {
          if (res.data.results || res.data.results.length > 0) {
            setSupplierPriceList([...supplierPriceList, ...res.data.results]);
            if (res.data.next) {
              setSupplierPriceListPage(
                res.data.next.split("cursor=")[1].split("&")[0]
              );
            } else {
              setSupplierPriceListPage(null);
            }
          }
        })
        .catch((err) => {});
    }
  };

  return (
    <AdminLayout>
      <div
        style={
          isDataFetched
            ? {
                pointerEvents: "none",
                position: "relative",
              }
            : { position: "relative" }
        }
      >
        <CreateBid
          onOpenPreviewHandle={onOpenPreviewHandle}
          setPdfType={setPdfType}
          handleCalculatedPricingData={handleCalculatedPricingData}
          setBidFees={setBidFees}
          bidFees={bidFees}
          setPdfLanguage={setPdfLanguage}
          pdfLanguage={pdfLanguage}
          setFeeType={setFeeType}
          feeType={feeType}
        />
        <PriceCard
          setDeleteModalShow={setDeleteModalShow}
          getSupplierPriceList={getSupplierPriceList}
          setSelectedSupplierList={setSelectedSupplierList}
          supplierPriceListPage={supplierPriceListPage}
          setSupplierPriceList={setSupplierPriceList}
          supplierPriceList={supplierPriceList}
        />
        <PdfPreviewModal
          show={pdfPreviewShow}
          onHide={onClosePreview}
          setDownloadFlag={setOrderPdfDownload}
          downloadFlag={orderPdfDownload}
          printCanvas={false}
          setGeneratePdfFlag={setGeneratePdfFlag}
          generatePdfFlag={generatePdfFlag}
          pdfFile={pdfFile}
          setPdfFile={setPdfFile}
          setLoader={setLoader}
          loader={loader}
          order={order}
          rebars={currentOrderRebars}
          nets={currentOrderNets}
          piles={currentOrderPiles}
          setPdfType={setPdfType}
          pdfType={pdfType}
          bidFees={bidFees}
          pdfLanguage={pdfLanguage}
          feeType={feeType}
        />
        {/* <AlertModal
          show={alertModalShow}
          onHide={() => setAlertModalShow(false)}
          dialogClassName={"custom_alert__width"}
          alertText={alertText}
          alertIcon={alertIcon}
        /> */}
        <Toaster
          position="bottom-right"
          reverseOrder={false}
          toastOptions={{
            style: {
              backgroundColor: '#00B38F',
              color: "#fff",
            },
          }}      
        />
        <SupplierPricingDeleteModal
          show={deleteModalShow}
          onHide={() => setDeleteModalShow(false)}
          supplierList={selectedSupplierList}
          getSupplierPriceList={getInitialSupplierPriceList}
        />
      </div>
    </AdminLayout>
  );
};

export default BidsCalculator;
