import PasswordReset from "Components/new-password";
import ShapePool from "Components/shape-pool";
import UpdateOrder from "Components/update-order";
import UserAnalytics from "Components/user-analytics";

import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
  withRouter,
} from "react-router-dom";
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { fetchUser, getProfileImg } from "Redux/middlwares/user";
import GuestOnlyRoute from "Router/guest-only-guard";
import GuardedRoute from "Router/route-guard";

import MyOrder from "./components/myorder";
import NewOrder from "./components/new-order";
import TicketCreator from "./components/ticket-creator";
import AdminSteelTypes from "./components/steel-types";
import ViewProfile from "Components/user-profile";
import { availablelangauges } from "Constants/general";
import { useTranslation } from "react-i18next";
import { setRightToLeft } from "Redux/features/root";
import AdminRoute from "Router/admin-guard";
import { isMobile } from "react-device-detect";
import Header from "Components/common-components/header";
import TermsAndConiditions from "Components/legal/terms-and-conditions";
import PrivacyPolicy from "Components/legal/privacy-policy";
import LandingPage from "Components/landing-page/index";
import Login from "Components/login";
import { UnderDevelopment } from "Components/under-development";
import SupplierTickets from "Components/supplier-tickets";
import OrderAcceptance from "Components/order-acceptance/index";
import Bids from "Components/bids/Bids";
import BidsCalculator from "Components/bids-calculator/index";
import UpdatedPolicyPopUp from "Components/legal/one-time-popup";

function App() {
  const router = useHistory();
  const dispatch = useAppDispatch();
  const { i18n, t } = useTranslation(["common"]);
  const userData = useAppSelector((state) => state.user.userData);
  const reqStatus = useAppSelector((state) => state.order.reqStatus);
  const [isLoading, setIsLoader] = useState(true);

  useEffect(() => {
    const isAdmin = window.location.href.includes("admin");
    if (!userData){
      dispatch(
        fetchUser((exist: boolean, userDT: any) => {
          setIsLoader(false);
          if (exist && userDT) {
            if (
              (userDT as any).preferred_language === availablelangauges.ENGLISH
            ) {
              i18n.changeLanguage("en");
              dispatch(setRightToLeft(false));
            } else if (
              (userDT as any).preferred_language === availablelangauges.HEBREW && !isAdmin
            ) {
              i18n.changeLanguage("he");
              document.body.setAttribute("dir", "rtl");
              document.body.setAttribute("style", "text-align: right");
              require("./styles/right-to-left.scss");
            }
            if (userDT.profile_picture) {
              dispatch(getProfileImg(userDT.profile_picture));
            }
          } else {
            if (i18n.language === "he" && !isAdmin) {
              dispatch(setRightToLeft(true));
              document.body.setAttribute("dir", "rtl");
              document.body.setAttribute("style", "text-align: right");
              require("./styles/right-to-left.scss");
            } else if (i18n.language === "en") {
              dispatch(setRightToLeft(false));
            }
          }
        })
      );
    }
  }, [userData, i18n, dispatch, window.location.href]);

  const loaderContainer = {
    display: "flex",
    justifyContent: "center",
    height: "100vh",
    alignItems: "center",
  };

  return (
    <div className="App">
      {isLoading && (
        <div style={loaderContainer}>
          <div
            className="spinner-grow"
            role="status"
            style={{ width: "5rem", height: "5rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {!isLoading && (
        <Router>
          <Switch>
            <AdminRoute path="/admin/user-analytics">
              <UserAnalytics />
            </AdminRoute>
            <AdminRoute path="/admin/supplier-tickets">
              <SupplierTickets />
            </AdminRoute>
            {/* <AdminRoute path="/admin/bids">
              <Bids />
            </AdminRoute> */}
            <AdminRoute path="/admin/steel-types">
              <AdminSteelTypes />
            </AdminRoute>
            <AdminRoute path="/admin/bids-calculator">
              <BidsCalculator />
            </AdminRoute>
            <AdminRoute exact path="/admin">
              <ShapePool />
            </AdminRoute>
            <GuestOnlyRoute path="/reset-password">
              <PasswordReset />
            </GuestOnlyRoute>
            <GuardedRoute path="/edit-order/:id">
              {userData && userData.terms_agreement ? (
                <UpdateOrder />
              ) : (
                <UpdatedPolicyPopUp show={true} />
              )}
            </GuardedRoute>
            <GuardedRoute exact path="/view-profile">
              {userData && userData.terms_agreement ? (
                <ViewProfile />
              ) : (
                <UpdatedPolicyPopUp show={true} />
              )}
            </GuardedRoute>

            <GuardedRoute path="/new-order">
              {isMobile ? (
                <UnderDevelopment />
              ) : (userData && userData.terms_agreement) ? (
                <NewOrder />
              ) : (userData && !userData.terms_agreement) ? (
                <UpdatedPolicyPopUp show={true} />
              ) : (
                <Redirect to="/" />
              )}
            </GuardedRoute>

            <Route path="/terms-and-conditions">
              <TermsAndConiditions />
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>

            <GuardedRoute path="/my-order">
              {isMobile ? (
                <UnderDevelopment />
              ) : userData && userData.terms_agreement ? (
                <MyOrder />
              ) : (
                <UpdatedPolicyPopUp show={true} />
              )}
            </GuardedRoute>

            <GuardedRoute path="/ticket-creator">
              {isMobile ? (
                <UnderDevelopment />
              ) : userData && userData.terms_agreement ? (
                <TicketCreator />
              ) : (
                <UpdatedPolicyPopUp show={true} />
              )}
            </GuardedRoute>

            <Route exact path="/order-acceptance">
              <OrderAcceptance />
            </Route>
            
            <Route exact path="/">
              {userData ? <Redirect to="/new-order" /> : <LandingPage/>}
            </Route>
            <Route exact path="/login">
              {(userData && !isMobile && reqStatus) ? <Redirect to="/my-order" /> : (userData && !isMobile) ? <Redirect to="/new-order" /> : <Login/>}
            </Route>
            <Route path="*">
              {userData ? <Redirect to="/new-order" /> : <h1>404 page not found</h1>}
            </Route>
          </Switch>
        </Router>
      )}
    </div>
  );
}

export default App;
