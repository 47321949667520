import Footer from "Components/common-components/footer";
import Header from "Components/common-components/header";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./index.scss";

const TermsAndConiditions = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  const targetSectionRef = useRef(null);
  const targetBuyersSectionRef = useRef(null);

  const scrollToSuppliersSection = () => {
    if (targetSectionRef.current) {
      targetSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToBuyersSection = () => {
    if (targetSectionRef.current) {
      targetBuyersSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Header />
      <div
        dir="rtl"
        style={{
          maxWidth: "950px",
          margin: "100px auto",
          fontSize: "20px",
          textAlign: "justify",
        }}
      >
        <p
          dir="rtl"
          style={{
            textAlign: "center",
          }}
        >
          <strong>
            <u>
              <span>איזי</span>
            </u>
          </strong>
          <strong>
            <u>-</u>
          </strong>
          <strong>
            <u>
              <span>בר&nbsp;</span>
            </u>
          </strong>
        </p>
        <br />
        <p
          dir="ltr"
          style={{
            textAlign: "center",
          }}
        >
          <strong>
            <u>
              <span>תקנון ותנאי שימוש</span>
            </u>
          </strong>
        </p>
        <br />
        <div className="d-flex justify-content-center">
          <p
            dir="ltr"
            style={{
              textAlign: "center",
              marginLeft: "18px",
              cursor: "pointer",
            }}
            onClick={scrollToBuyersSection}
          >
            <u>
              <span>רוכשים</span>
            </u>
          </p>
          <p
            dir="ltr"
            style={{
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={scrollToSuppliersSection}
          >
            <u>
              <span>ספקים</span>
            </u>
          </p>
        </div>

        {/* Buyers TOC */}
        <div
          dir="rtl"
          className="d-flex justify-content-center"
          ref={targetBuyersSectionRef}
          style={{marginTop: "40px"}}
        >
          <u>
            <strong>רוכשים</strong>
          </u>
        </div>

        <ol type="1">
          <li dir="rtl">
            <u>
              <strong>
                <span>כללי</span>
              </strong>
            </u>
            <ol>
              <li dir="rtl">
                <span>חברת איזי-בר 2022 בע&quot;מ (&quot;</span>
                <strong>
                  <span>החברה</span>
                </strong>
                <span>
                  &quot;) הינה הבעלים והמפעילה של מערכת &quot;איזיבר&quot;
                  &ndash; מערכת טכנולוגית לייעול ופישוט הזמנות רכש ברזל (להלן:
                  &quot;
                </span>
                <strong>
                  <span>המערכת</span>
                </strong>
                <span>
                  &quot;). השימוש באתר, במערכת ובתכנים ובשירותים המוצגים באלו,
                  כפוף לתנאי השימוש המפורטים בתקנון זה, ומותנה באישורם (להלן
                  &quot;
                </span>
                <strong>
                  <span>תנאי השימוש</span>
                </strong>
                <span>&quot;).&nbsp;</span>
              </li>
              <li dir="rtl">
                <span>
                  עליך לקרוא את תנאי השימוש בעיון טרם השימוש במערכת. השימוש
                  במערכת ו/או בתכניה ו/או בשירותים המוצעים בה מעיד על הסכמתך
                  ואישורך לעמוד בתנאי שימוש אלו ולקיימם במלואם. תנאי השימוש
                  מהווים הסכם משפטי מחייב וניתן לאכיפה בין החברה לבינך. אם אינך
                  מסכים לתנאים אלה, אנא הימנע מהתקנת המערכת, מהגישה אליה
                  ומהשימוש בה, לרבות הורדה של כל תוכן או מידע מהמערכת, ו/או מכל
                  שימוש אחר, ישיר או עקיף, בשירותים הכלולים בה.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  השימוש בלשון זכר במסגרת תנאי שימוש אלו מובא מטעמי נוחות בלבד,
                  ותנאים אלו יחולו על כל משתמשי המערכת
                </span>
                <span dir="ltr">.</span>
              </li>
              <li dir="rtl">
                <span>
                  תנאי השימוש עשויים להשתנות או להתעדכן מעת לעת, והמשך שימושך
                  במערכת יותנה בהסכמתך לתנאי השימוש המעודכנים, ככל שיעודכנו. כל
                  המאפיינים ו/או התכנים החדשים אשר יועמדו לרשותך באמצעות המערכת
                  נכון לתאריך שבו תנאי השימוש בה עודכנו לאחרונה, יוכפפו באופן
                  אוטומטי לתנאי השימוש המעודכנים. המשך השימוש שלך במערכת לאחר כל
                  שינוי כאמור לעיל, יהווה אות להסכמתך לשינויים אלה
                </span>
                <span dir="ltr">.</span>
              </li>
              <li dir="rtl">
                <span>
                  באישורך את תנאי שימוש אלו ושימושך במערכת, אתה מאשר, מבין
                  ומצהיר, כי המערכת והשירותים הניתנים במסגרתה, נמצאים בשלבי
                  פיתוח שיפור ושדרוג מתמידים, כי החברה אינה מתחייבת שהמערכת תספק
                  את התוצאות המקצועיות המתאימות ו/או המדויקות לך ו/או המשוערות
                  על ידך, וכי בהתאם לכך, ובכפוף לכל דין, השימוש ו/או תוצאות
                  השימוש במערכת, ו/או בתוצריה, הן ותמיד יהיו על אחריותך ו/או
                  אחריות הישות המשפטית בשמה הנך פועל, והכול באופן בלעדי וללא
                  יוצא מן הכלל.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  לתשומת ליבך, תנאי שימוש אלו מיועדים למשתמשי המערכת המעוניינים
                  להפיק סיכומי הזמנת רכש לשימוש עצמי ו/או לקבל הצעות מחיר
                  מבוססות על הסיכומים כאמור ו/או לבצע הזמנות ברזל דרך המערכת.
                  ככל והנך ספק המעוניין להשתמש במערכת לצורך מתן הצעות מחיר ו/או
                  מכירת ברזל, אנא ראה את תנאי השימוש הרלוונטיים אליך בכתובת
                  הבאה:&nbsp;
                </span>
                <span
                  onClick={scrollToSuppliersSection}
                  style={{ cursor: "pointer" }}
                >
                  <u>
                    <strong>תנאים ספקים</strong>
                  </u>
                </span>
              </li>
            </ol>
          </li>
          <li dir="rtl">
            <strong>
              <u>השימוש במערכת וביצוע הזמנות</u>
            </strong>
            <ol type="1">
              <li dir="rtl">
                <span>
                  כשירות בסיסי, המערכת תאפשר לך להפיק סיכומי הזמנות המותאמים
                  לצרכים מקצועיים שונים, שיסייעו לך לבצע הזמנות ברזל ישירות
                  מספקים, או דרך המערכת, בפשטות וביעילות. הפקת סיכום ההזמנה עצמה
                  אינה כרוכה בעלות כלשהי.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  לצורך הפקת סיכומי הזמנה, אתה תידרש להזין נתונים ופרטים טכניים
                  בקשר להזמנה המבוקשת, כגון מידות, סוגים וכמויות. הנתונים שתזין
                  יעובדו על ידי המערכת באופן חכם, בהתבסס על ידע מקצועי
                  ואלגוריתמים חכמים, ויאפשרו הפקה של סיכומי ההזמנה המותאמים
                  לשימושך (להלן: &quot;<strong>סיכום הזמנה</strong>&quot;).
                </span>
              </li>
              <li dir="rtl">
                <span>
                  סיכומי ההזמנה שתפיק במערכת, יהיו זמינים לשליחה לכל אחד מהספקים
                  הרשומים למערכת בנוחות וביעילות. במקרה של ספקים הרשומים לשירות
                  מתקדם ובעלי הרשאה מתאימה, כל סיכום הזמנה כאמור יהיה זמין גם
                  בפורמט של כרטיסיית ייצור.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  כשירות משלים, יהיה באפשרותך לבקש מהמערכת הצעות מחיר קונקרטיות
                  לאספקת ברזל, ביחס לכל סיכום הזמנה שתפיק במערכת ו/או בכלל
                  (להלן: &quot;<strong>הצעת מחיר</strong>&quot;). כמו כן, ככל
                  ותראה לנכון, תוכל לבצע את ההזמנה בהתאם להצעת המחיר, ישירות דרך
                  המערכת, וללא צורך בפנייה ישירה לספק כלשהו. במערכת איזיבר,
                  הצעות המחיר וההזמנות אינן כפופות למגבלת היקף ו/או מחיר כלשהו.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  הצעת מחיר תופק לבקשתך דרך המערכת, תוך 3 ימי עסקים מיום קליטת
                  הבקשה וכלל הנתונים הנדרשים לצורך כך. הצעת המחיר תכלול, בין
                  היתר, מידע אודות מחיר ההזמנה, כמות החומרים (בטון ברזל או כל
                  יחידת מידה אחרת מתאימה), , וזמן האספקה המשוער. אלא אם יצוין
                  אחרת, הצעת המחיר תהיה בתוקף למשך 7 ימי עסקים ממועד הפקתה ולאחר
                  מכן החברה לא תהיה מחויבת אליה.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  הסדרת התשלום הנקוב בהצעת מחיר תוך עד 7 ימים ממועד הפקתה, הינו
                  תנאי לתחילת הטיפול בהזמנות אשר תבחר לאשר באמצעות המערכת. תוכל
                  לבצע את התשלום בכרטיס אשראי, באמצעות לינק לתשלום שתקבל דרך
                  המערכת. לחלופין, יהיה באפשרותך לשלם בהעברה בנקאית, ולטעון
                  במקום המיועד לכך במערכת, את אישור התשלום.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  בקשת הצעת מחיר אינה מהווה את אישורך מראש לתנאי הצעת המחיר, וכל
                  עוד הצעת המחיר לא תאושר על ידך ותשולם בפועל, לא תהיה מחויב לה.
                  ואולם, עם אישורך את הצעת המחיר והסדרת התשלום הנדרש, החברה
                  תמסור לידיך גם את פרטי הספק האחראי להזמנה אשר צפוי לספק אותה
                  בפועל, והצעת המחיר תחייב אותך לכל דבר ועניין.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  יובהר, כי המחיר הנקוב בהצעת המחיר הינו סופי ומלא, ולא תחויב
                  בעמלה נוספת כלשהי כלפי החברה או צד שלישי כלשהו, למעט עמלות
                  סליקת אשראי ו/או עמלות העברה בנקאית, ככל ויחולו עליך. כמו כן,
                  בניגוד למקובל בשוק הברזל, בכפוף לאישור ההזמנה, המחירים שיהיו
                  נקובים בה הינם סופיים, ולא ניתנים לשינוי עקב שינוי במשקל
                  הסחורה בפועל, תנודות במחירי חומרי גלם, מצב שוק, וכיו"ב.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  ואולם, למען הסר ספק, החברה אינה יכולה להתחייב על הפקת הצעת
                  מחיר ביחס לכל סיכום הזמנה ו/או שהיא תוכל לספק הצעת מחיר מספק
                  ספציפי כלשהו ו/או שהצעת מחיר שתופק תתאם לצרכיך ו/או לציפיותיך
                  מבחינת מחיר, וזמני אספקה.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  <u>
                    <strong>
                      כמו כן, יודגש כי החברה אינה ספקית ברזל ואינה הספקית בפועל
                      של תוצרי הזמנתך, אלא מסייעת ברכישה ובאספקה של הזמנתך
                      מספקים חיצוניים, בתור מתווכת טכנולוגית ולוגיסטית בלבד.
                      כנובע מכך, האחריות לטיב, איכות, תקינות, עמידה בתקנים,
                      בטיחות, שלמות, ו/או כל רכיב אחר של תוצרי ההזמנה ו/או כל
                      חלק מהם, הינם באחריות הספק המופיע בהצעת המחיר בלבד, והחברה
                      בכל מקרה לא תישא בכל אחריות לתוצרי ההזמנה.
                    </strong>
                  </u>
                </span>
              </li>
              <li dir="rtl">
                <span>
                  <u>
                    <strong>
                      לתשומת ליבך - ביטול הזמנות אשר אושרו בהתאם להצעת מחיר
                      יתאפשר, אם יתאפשר, בכפוף להוראות חוק הגנת הצרכן והתקנות
                      לפיו, ואולם, הואיל והסחורה אשר תסופק צפויה להיות מותאמת
                      אישית למידות הזמנתך, אזי ייתכן כי זכותך לביטול העסקה תהיה
                      מסויגת, כאמור בתקנה 6(א)(2) לתקנות הגנת הצרכן (ביטול
                      עסקה).
                    </strong>
                  </u>
                </span>
              </li>
            </ol>
          </li>
          <li dir="rtl">
            <strong>
              <u>קניין רוחני / מידע במסגרת המערכת</u>
            </strong>
            <ol type="1">
              <li dir="rtl">
                <span>
                  כל זכויות הקניין הרוחני במערכת ובכל רכיב או חלק שלה, לרבות אך
                  לא רק, זכויות היוצרים, הסימנים והסמלים המסחריים (לרבות כל
                  עיצוב אשר ניתן לרושמו כסימן מסחרי), המדגמים, העיצובים והסודות
                  המסחריים, הכלולים במערכת, הינם רכושה של החברה בלבד, או של
                  צדדים שלישיים אשר נתנו לחברה הסכמה לשימוש בקניינם הרוחני, לפי
                  העניין. זכויות אלה חלות בין היתר על עיצובה הגרפי של המערכת,
                  בסיסי הנתונים בה, התכנים המופיעים במערכת, קוד המחשב, העיצוב
                  הגרפי, בסיסי הנתונים, הטקסט, השרטוטים, הסמלים, התמונות, וכל
                  פרט אחר הקשור בהקמת והפעלת המערכת.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  כל זכויות הקניין הרוחני וכל יתר הזכויות במערכת (לרבות ומבלי
                  להגביל, זכויות בסימני ושמות המסחר, בזכויות היוצרים ובזכויות
                  המוסריות) (להלן: <strong>הקניין הרוחני</strong>"), כל חלק שלהם
                  וכל חומר המתייחס אליהם, במישרין או בעקיפין, לרבות ומבלי לגרוע
                  המוניטין, תוצרי הפיתוח, התאמות ושינויים, עדכונים, תוספות,
                  שדרוגים, שיפורים, וכיוצ"ב, בין אם הושלמו ובין שלא הושלמו ו/או
                  כל חומר אחר, בין שהם כתובים ו/או מודפסים ו/או רשומים ו/או
                  אגורים ובין אם לאו, הינם ויישארו קניינה ורכושה הבלעדי של
                  החברה. הנך מצהיר, מסכים ומתחייב כי תנאי שימוש אלו ו/או השימוש
                  במערכת לא יקנה לך או למי מטעמך כל זכות קניינית או כל זכות אחרת
                  בזכויות הקניין הרוחני של החברה או שימוש בו, שינויו, יישומו,
                  ניצולו ומסחורו.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  בלי לגרוע מהאמור לעיל, הסכמתך לתנאי שימוש אלו, מעניקה לך זכות
                  שימוש מוגבלת בלבד, שאינה בלעדית, ושניתנת לביטול על ידי החברה
                  בכל עת, לעשות שימוש במערכת כלקוח קצה ובהתאם לייעודה המסחרי
                  בלבד (להלן: "<strong>זכות השימוש</strong>"). זכות השימוש כאמור
                  אינה מקנה לך זכות מסחרית כלשהי אחרת, במפורש או במשתמע, בדרך של
                  השתק או בכל דרך אחרת, לגבי אף חלק מערכת ו/או מהקניין הרוחני
                  העומד בבסיסה, אינה מעניקה לך זכות למסחר את המערכת ו/או כל רכיב
                  מרכיביה, ו/או זכות לתוצרים, הכנסות, פירות, תמלוגים ו/או כל
                  זכות אחרת
                </span>
                <span dir="ltr">.</span>
              </li>
              <li dir="rtl">
                <span>
                  מבלי לגרוע מכלליות האמור, באישורך את תנאי השימוש, הנך מסכים
                  שלא להעתיק, לשכפל, להפיץ, למכור, לשווק, להנדס לאחור ו/או לתרגם
                  מידע כלשהו מהמערכת (לרבות סימני מסחר, תמונות, טקסטים
                  וקוד-מחשב) באופן דיגיטלי או בכל אמצעי אחר, בלא קבלת רשותה
                  המפורשת של החברה מראש ובכתב. למען הספק ספק, אין לעשות כל שימוש
                  בנתונים המוצגים ו/או מופקים במערכת ו/או להציגם באתר אינטרנט,
                  במערכת או בשירות אחר כלשהו, בלא לקבל את הסכמתה של החברה מראש
                  ובכתב, ובכפוף לתנאי אותה הסכמה (ככל ותינתן).
                </span>
              </li>
              <li dir="rtl">
                <span>
                  באישורך את תנאי השימוש, הנך מצהיר ומסכים בזאת, כי המערכת, ו/או
                  כל ביטוי או חלק הימנה, מהווה קניין רוחני ייחודי, והינה ביטוי
                  בלעדי של המוצר אשר תואר בס&apos; 2.1 לעיל, והינה רכושה הבלעדי
                  של החברה.&nbsp;
                </span>
              </li>
            </ol>
          </li>
          <li dir="rtl">
            <strong>
              <u>הגבלות שימוש</u>
            </strong>
            <ol type="1">
              <li dir="rtl">
                באישורך את תנאי שימוש אלו, הנך מאשר, מצהיר ומבין כי ביצוע כל אחת
                מהפעולות המנויות בסעיף 2.7 להלן הנו אסור, ומתחייב לעמוד בכך, ללא
                חריגים. אי עמידה מצדך בתנאים המפורטים להלן יגררו השהייה או חסימה
                של שימושך במערכת ו/או בחשבון המשתמש שלך, ועלולים לחשוף אותך
                לאחריות אזרחית ו/או פלילית
                <span dir="ltr">.</span>
              </li>
              <li dir="rtl">
                אינך רשאי בעצמך ו/או באמצעות מי מטעמך בין אם לטובתך ו/או לטובת
                הגוף המשפטי שהינך פועל מטעמו: (1) להעתיק, לשנות, להתאים, לתרגם,
                לבצע כל פעולת הנדסה הפוכה, לפרק כל חלק של המידע במערכת, בכל דרך,
                או להציג בפומבי או לבצע בפומבי כל חלק מהמידע ו/או התכנים במערכת,
                או להפיץ אותו; (2) לעשות כל שימוש במידע בכל אתר אינטרנט או בכל
                סביבת רשתות מחשבים אחרת לכל מטרה, או לשכפל או להעתיק את המידע של
                החברה ו/או את המערכת מבלי לקבל רשות מוקדמת בכתב מהחברה; (3)
                להתחזות לאדם או גוף אחרים, לרבות בין השאר ולא רק, כשליח, נציג או
                סוכן של החברה, להציג בצורה שקרית, או להציג בצורה לא נכונה אחרת
                את הקשר שלך עם כל אדם או גוף אחרים; (4) להעביר או ליצור בכל דרך
                אחרת, בקשר למערכת, כל תוכנה זדונית, לרבות וירוס מחשב, "תולעת",
                סוס טרויאני, time bomb, באג, רוגלה, או כל קוד מחשב, קובץ או
                תוכנית אחרים אשר עשויים להזיק, או נועדו להזיק או לשבש ו/או לחטוף
                את הפעילות של כל חומרה, תוכנה או ציוד תקשורת, או קוד או רכיב,
                שהם מזיקים, שיש בהם פוטנציאל לגרום נזק, שהם מפריעים, או שהם
                פולשניים; (5) לפגוע בפעולתה של המערכת או להפריע לה, או לפגוע
                בפעולה או להפריע לפעולה של שרתים או רשתות אשר מארחים את המערכת
                או מאפשרים את זמינותה, או לא לציית לכל דרישה, נוהל, מדיניות או
                תקנה של שרתים או רשתות אלה; (6) למכור, לתת רישיון, או לנצל למטרה
                מסחרית כלשהי כל שימוש או גישה למידע או למערכת; (7) לתחם (frame)
                או ליצור העתק (mirror) של כל חלק של המערכת ללא הרשאה מוקדמת
                מפורשת בכתב מהחברה; (8) ליצור מאגר מידע על ידי הורדה ואחסון
                שיטתיים של כל או חלק מהמידע אשר מופיע במערכת; (9) להעביר כל מידע
                אשר הופק מהמערכת לצד ג', ללא הרשאה מוקדמת בכתב מהחברה; (10)
                להעביר או להמחות את שם המשתמש או הסיסמה שלך, ולו רק באופן ארעי
                ו/או זמני, לצד שלישי; (11) לגשת או לנסות לגשת לחלק כלשהו של
                המערכת אשר דורש סיסמה ללא שקיבלת לשם כך סיסמה מהחברה, ו/או הדורש
                הרשאה אשר לא ניתנה לך מהחברה; (12) להשתמש במערכת לכל מטרה לא
                חוקית, לא מוסרית או בלתי מורשית.
              </li>
              <li dir="rtl">
                הנך מצהיר ומאשר כי כל הפרטים והנתונים אשר תמסור במסגרת השימוש
                במערכת ו/או בתהליך הרישום הינם נכונים מדויקים ואין בהם בבחינת
                אילו מן הפעולות האסורות בסעיף 2.7 לעיל, של זכויות צד שלישי כלשהו
                ו/או או כל דין. הנך מצהיר ומאשר כי מסירת כל מידע במסגרת המערכת,
                נעשית באופן חוקי, וולונטרי ומרצונך החופשי בלבד
                <span dir="ltr">.</span>
              </li>
              <li dir="rtl">
                החברה רשאית להתיר לך להיכנס למערכת ו/או למנוע ממך את הכניסה
                אליה, בכל זמן ועל פי שיקול דעתה הבלעדי. החברה שומרת על זכותה
                לשנות או להפסיק, בכל זמן, באופן זמני או קבוע, את הפעלתה של
                המערכת (או כל חלק מהם) ו/או לסרב לספק לך שירותים (או חלק מהם) –
                ללא הודעה מוקדמת ועל פי שיקול דעתה הבלעדי. בנוסף לאמור לעיל, אתה
                מאשר כי ייתכן שיחולו שינויים בשירותים ו/או בתכנים ו/או במוצרים
                אשר יסופקו לך כתוצאה מהשימוש במערכת, וכי אפשר ששירותים ו/או
                תכנים ו/או מוצרים אלה ישונו, יצומצמו, יורחבו במונחים של תוכן או
                צורה או כי הם יופסקו בכל זמן. בכפוף לכל דין, אתה מסכים כי החברה
                אינה ולא תהיה אחראית כלפיך או כלפי כל צד שלישי מטעמו הינך פועל,
                בשל כל שינוי, השעיה, או הפסקה של המערכת, השירותים, התכנים או
                המוצרים אשר נכללים במערכת, לרבות מידע מקצועי, מידע ליצירת קשר,
                טקסטים, חישובים, תמונות, הורדות דיגיטליות, סמלים, אייקונים של
                לחצנים, חיבורים של נתונים, קישורים, תיעוד, נתונים, גרפיקה נלווית
                ומאפיינים ותכנים מיוחדים אחרים. הינך מצהיר כי אינך מסתמך ולא
                תסתמך בעתיד על גישה למערכת
                <span dir="ltr">.</span>
              </li>
              <li dir="rtl">
                השימוש במערכת כפוף לרישיון שימוש, במסגרתו החברה מעניקה לך רישיון
                גישה מוגבל למערכת ולעשות בה שימוש פרטני על פי ייעודה המקצועי,
                מאפייניה והפונקציות הקיימות בה המיועדות למשתמשי קצה בלבד, היקף
                המשתמשים שנרכש על ידך ו/או הגוף מטעמו הינך פועל, והכול בהתאם
                לתנאים אלו.
              </li>
            </ol>
          </li>
          <li dir="rtl">
            <strong>
              <u>הגבלת אחריות</u>
            </strong>
            <ol type="1">
              <li dir="rtl">
                הנך מצהיר ומאשר כי השימוש במערכת נעשה על אחריותך הבלעדית והמלאה,
                תוך שכל סיכון הניטל במסגרת השימוש במערכת, ניטל על ידך בלבד. כל
                פלט מידע אשר יופק מהמערכת ו/או כל מידע אשר יוצג בה, ניתנים ללא
                כל התחייבות ו/או מצג, מפורש או משתמע, מצד החברה, לרבות אך ללא
                הגבלה, לאחריות משתמעת בקשר עם סחירות, התאמה למטרה מסוימת או
                לצרכי משתמש מסוים או לאי הפרה.
              </li>
              <li dir="rtl">
                החברה אינה מתחייבת כי המערכת ו/או התוכן או השירות הכלולים או
                המוצעים בה יעמדו בדרישותיך, לא יופרעו או לא יופסקו, יתקיימו
                בבטחה וללא תקלות, טעויות או שגיאות, יהיו חסינים מפני כל גישה
                בלתי מורשית למחשבים, או מפני נזקים, קלקולים או כשלים בחומרה,
                בתוכנה, בקווים ובמערכות התקשורת וכיוצ"ב. החברה לא תישא בכל
                אחריות כלפיך במקרה של שיבושים או בעיות כאמור או בכל מקרה אחר של
                שיבוש. מבלי לגרוע מהאמור לעיל, החברה אינה נותנת כל הבטחה כי
                המערכת ו/או התוכן או השירותים הכלולים או המוצעים בה יהיו זמינים
                (בכל אזור ו/או בכל זמן), בלתי מופרעים, רציפים, מדויקים, מאובטחים
                או נקיים מווירוסים. לפיכך, החברה אינה מבטיחה או מציגה מצג כלשהו
                בקשר אליהם. כמו כן, ידוע לך כי לא ניתן להתאימם לצרכיו של כל אדם
                ואדם וכל עסק ועסק. לא תעלה כל טענה ו/או תביעה ו/או דרישה כלפי
                החברה בגין תכונות של התכנים ו/או התוצרים, יכולותיהם, מגבלותיהם,
                ו/או התאמתם לצרכיך.
              </li>
              <li dir="rtl">
                החברה אינה מתחייבת כי תכנים ושירותים של צדדים שלישיים, לרבות של
                ספקי תוכן ושירותים ושל משתמשים, ככל שיפורסמו במערכת (לרבות
                באמצעות קישוריות) יהיו מלאים, נכונים, חוקיים או מדויקים. מובהר
                כי החברה לא תישא באחריות כלשהי לכל תוצאה ו/או השלכה בקשר אליהם,
                או משימוש בהם, או מהסתמכות עליהם.
              </li>
              <li dir="rtl">
                החברה מעודדת אותך להתייחס באופן זהיר, קפדני וביקורתי לכל פלט
                ו/או מידע אשר יופק מהמערכת ו/או בקשר עמה.
              </li>
              <li dir="rtl">
                בשום מקרה החברה לא תהא אחראית לכל נזק, מכל סוג שהוא, לרבות (אך
                לא רק), נזקים עקיפים, מיוחדים, אגביים או תוצאתיים, בין במסגרת
                תביעה על פי חוזה, בתביעת נזיקין או בכל תביעה אחרת בגין מעשה
                עוולה אשר ינבע מהשימוש במערכת או מחוסר האפשרות להשתמש בה, ללא
                קשר לשאלה האם החברה ו/או מי מטעמה הודיע על האפשרות שנזק כזה עלול
                להיגרם. על אף האמור לעיל, כאשר אחריות היא מנדטורית ויוחלט ע"י
                גוף משפטי רשמי מוסמך כי על אף האמור לעיל החברה אחראית לנזקים
                כאמור, אזי האחריות לנזקים תוגבל לסכום של אלף שקלים חדשים (1,000
                ש"ח) בלבד. במקרה כזה, זכאותך לפיצויים כספיים בסכום זה תבוא במקום
                כל התרופות האחרות אשר עשויות להיות לך נגד החברה.
              </li>
              <li dir="rtl">
                מבלי לגרוע מהאמור לעיל, החברה לא תישא באחריות במקרה של הפסקת
                הגישה למערכת, ו/או הפסקת השירותים המסופקים במסגרתה ו/או בגין
                עיכוב או ו/או כשל בעמידה בחובותיה של החברה, במידה ואלו נגרמו
                כתוצאה מנסיבות שמעבר לשליטתה הסבירה של החברה, לרבות עקב "
                <strong>כוח עליון</strong>". לעניין זה, "
                <u>
                  <strong>כוח עליון</strong>
                </u>
                " - לרבות: רעידת אדמה קשה, מהומות חמורות, מלחמה, שריפה, שיטפון,
                התקוממות לאומית, חבלה, אמברגו, מגיפות, טרור, מזג אוויר קיצוני,
                קשיי תחבורה ניכרים, היעדר זמינות אובייקטיבית, שיבוש או עיכוב
                בשירותי תקשורת משמעותיים או בשירותי צד ג' (לרבות הפצת DNS), כשל
                תוכנה או חומרה של צד ג', או חוסר יכולת להשיג חומרי גלם, מצרכים,
                חשמל או ציוד כנחוץ. למען הסר ספק, ומבלי לגרוע מאחריותם הישירה
                והבלעדית של הספקים הרלוונטיים בקשר לכל הזמנה, האמור לעיל יחול
                גם, אך לא רק, בקשר עם עיכובים באספקת הזמנות אשר ייתכן כי ייגרמו
                בקשר עם ביצוע הזמנות, כמפורט בסעיף 2 לעיל.
              </li>
            </ol>
          </li>
          <li dir="rtl">
            <strong>
              <u>שיפוי</u>
            </strong>
            <ol type="1">
              <li dir="rtl">
                אי מילוי ההוראות אשר מפורטות בתנאי השימוש עלול לגרום להפסקת
                גישתך למערכת ועלול לחשוף אותך לאחריות אזרחית ו/או פלילית. מבלי
                לגרוע מהאמור לעיל ומהוראות כל דין, הנך מתחייב לשפות ולפצות את
                החברה ו/או מי מטעמה, בכל מקרה שבו תפר תנאים אלה, או תפעל בקשר עם
                המערכת בניגוד להוראות כל דין ו/או תוגש תביעה כנגד החברה על ידי
                צד שלישי כל שהוא בשל פעולה שביצעת בניגוד לאמור בתנאי שימוש אלו.
                שיפוי ו/או פיצוי כאמור יכסה כל הוצאה, תשלום, הפסד, אבדן רווח או
                כל נזק אחר, ישיר או עקיף, ממוני או לא ממוני, שייגרמו לחברה ו/או
                למי מטעמה, ולרבות הוצאות משפט ושכר טרחת עורכי דין ו/או מומחים
                <span dir="ltr">.</span>
              </li>
            </ol>
          </li>
          <li dir="rtl">
            <strong>
              <u>קישורים</u>
            </strong>
            <ol type="1">
              <li dir="rtl">
                ייתכן כי יועלו למערכת קישורים (links) לאתרים אחרים, בין אם
                למטרות שיווקיות ו/או מסחריות ו/או למטרות הסדרת תשלום ו/או לכל
                מטרה אחרת. ההוראות שלהלן תחולנה על השימוש בקישורים אלו, ואין בהן
                כדי לגרוע משאר הוראות תנאי השימוש.
              </li>
              <li dir="rtl">
                הקישורים נועדים לנוחיות המשתמש בלבד והשימוש בהם הוא לבחירתו.
              </li>
              <li dir="rtl">
                לעניין קישורים לאתרים חיצונים שאינם שייכים למערכת
                <span dir="ltr">&nbsp;</span>
                <span dir="rtl">(&quot;</span>
                <strong>אתרים חיצוניים</strong>&quot;): אם לא צוין אחרת, אין בין
                החברה לבין בעלי אתרים חיצוניים יחסים משפטיים או מסחריים, ואין
                לחברה כל שליטה, אחריות או זכות בחומר הנמצא באתרים אלו
                <span dir="ltr">.</span>
              </li>
              <li dir="rtl">
                החברה אינה אחראית בשום צורה לתכנים המצויים באתרים חיצוניים
                ולשירותים הניתנים בהם.
              </li>
              <li dir="rtl">
                אין לפרש קישורים לאתרים חיצוניים כמתן אישור, המלצה או העדפה על
                ידי החברה לאותם האתרים המקושרים, לרבות למסמכים וכל חומר אחר
                הנמצאים בהם, למפעילי האתרים או למוצרים המוצגים ו/או הנמכרים בהם.
              </li>
            </ol>
          </li>
          <li dir="rtl">
            <strong>
              <u>פרטיות&nbsp;</u>
            </strong>
            <ol type="1">
              <li dir="rtl">
                החברה מכבדת את פרטיות המשתמשים במערכת. החברה מתחייבת כלפי המשתמש
                לקיים את האמור במדיניות הפרטיות שלה, הזמינה ב-{" "}
                <Link to="/privacy-policy">
                  <span>מדיניות הפרטיות</span>
                </Link>{" "}
                (להלן: &quot;<strong>מדיניות הפרטיות</strong>&quot;). מטרת
                מדיניות הפרטיות היא להסביר את נוהגי החברה ביחס לפרטיות המשתמשים
                במערכת, לרבות האופן החברה תשתמש ו/או עלולה להשתמש במידע שייאסף
                ו/או יעובד ו/או יישמר במערכת ו/או בזיקה לפעילות בה.
              </li>
            </ol>
          </li>
          <li dir="rtl">
            <strong>
              <u>שונות וכללי</u>
            </strong>
            <ol type="1">
              <li dir="rtl">
                תנאי שימוש אלה אינם יוצרים והם לא יתפרשו כאילו הם יוצרים שותפות,
                מיזם משותף, יחסי עובד-מעביד, יחסי שליחות או יחסים בין מעניק
                זיכיון לבין מקבל זיכיון בין הצדדים לתנאי שימוש אלה
                <span dir="ltr">.</span>
              </li>
              <li dir="rtl">
                תנאי שימוש אלו וכל תביעה בקשר למערכת או לשימוש בה תהיה כפופה
                לחוקי מדינת ישראל, ותתפרש על פי חוקים אלה, מבלי ליתן תוקף
                לעקרונות ברירת הדין שלה
                <span dir="ltr">.</span>
              </li>
              <li dir="rtl">
                כל מחלוקת אשר תנבע או תהיה קשורה לשימוש שלך במערכת תובא לדיון
                בבתי המשפט המוסמכים במחוז תל אביב, ישראל, ואתה מסכים בזאת לסמכות
                השיפוט הייחודית והמקומית של בתי משפט אלה<span dir="ltr">.</span>
              </li>
              <li dir="rtl">
                במקרה שייקבע כי הוראה כלשהי מתנאי שימוש אלה אינה חוקית, בטלה או
                שמכל סיבה אין אפשרות לאכוף אותה, אזי יראו הוראה זו כהוראה שניתן
                להפרידה מתנאי שימוש אלה, וההוראה האמורה לא תשפיע על התוקף ועל
                האפשרות לאכוף הוראה כלשהי מבין ההוראות הנותרות של תנאי שימוש
                אלה.
              </li>
              <li dir="rtl">
                שום ויתור של החברה מלאכוף כל הפרה או מחדל על פי תנאי שימוש אלה
                לא ייחשב כוויתור על כל הפרה או מחדל, קודמים או מאוחרים יותר. כל
                כותרת, כותרת של סעיף או כל כותרת אחרת אשר נכללת בתנאי שימוש אלה
                שולבה בהם לצורך נוחות ההתייחסות בלבד, וכותרת כאמור אינה מגדירה
                או מסבירה, בכל דרך, כל סעיף או הוראה אשר נכללו בתנאי שימוש אלה.
              </li>
              <li dir="rtl">
                תנאי שימוש אלה ממצים את מלוא התנאים וההוראות אשר הוסכמו בינך
                לבין החברה בקשר לנושא תנאי שימוש אלה והם גוברים על כל הסכמות או
                הבנות אחרות, קודמות או בו-זמניות, בכתב או בעל-פה בינך לבין החברה
                <span dir="ltr">.</span>
              </li>
              <li dir="rtl">
                אם אתה מרגיש שאחת או חלק מזכויותיך סוכנו במסגרת המערכת או במקרה
                ואתה מבקש לקבלת מידע או לשאול שאלות אנא צור איתנו קשר בדוא"ל{" "}
                <a href="mailto:support@easybar.co.il">
                  <u>
                    <span dir="ltr">support@easybar.co.il</span>
                  </u>
                </a>
                <span dir="rtl">&rlm;</span> , ואנו נעשה את מירב המאמצים על מנת
                לטפל בתלונתך או
                בקשתך.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;
                <br />
                <br />
                אנו מאחלים לך שימוש יעיל, נוח ומקצועי במערכת.
              </li>
            </ol>
          </li>
        </ol>
        <p dir="rtl">
          <span dir="ltr">&nbsp;</span>
        </p>

        {/* Supplier TOC */}
        <div
          dir="rtl"
          className="d-flex justify-content-center"
          ref={targetSectionRef}
        >
          <u>
            <strong>ספקים</strong>
          </u>
        </div>
        <ol type="1">
          {/* 1 */}
          <li dir="rtl">
            <u>
              <strong>
                <span>כללי</span>
              </strong>
            </u>
            <ol>
              <li dir="rtl">
                <span>חברת איזי-בר 2022 בע"מ ("</span>
                <strong>
                  <span>החברה</span>
                </strong>
                <span>
                  ") הינה הבעלים והמפעילה של מערכת "איזיבר" – מערכת טכנולוגית
                  לייעול ופישוט הזמנות רכש ברזל (להלן:"
                </span>
                <strong>
                  <span>המערכת</span>
                </strong>
                <span>
                  " השימוש באתר, במערכת ובתכנים ובשירותים המוצגים באלו, כפוף
                  לתנאי השימוש המפורטים בתקנון זה, ומותנה באישורם (להלן "
                </span>
                <strong>
                  <span>תנאי השימוש</span>
                </strong>
                <span>&quot;).&nbsp;</span>
              </li>
              <li dir="rtl">
                <span>
                  עליך לקרוא את תנאי השימוש בעיון טרם השימוש במערכת. השימוש
                  במערכת ו/או בתכניה ו/או בשירותים המוצעים באמצעותה מעיד על
                  הסכמתך ואישורך לעמוד בתנאי שימוש אלו ולקיימם. תנאי השימוש
                  מהווים הסכם משפטי מחייב וניתן לאכיפה בין החברה לבינך ו/או הגוף
                  המשפטי מטעמו הנך פועל. אם אינך מסכים לתנאי השימוש, אנא מגישה
                  ו/או שימוש במערכת, לרבות הורדה של כל תוכן או מידע מהמערכת,
                  ו/או מכל שימוש אחר, ישיר או עקיף, בשירותים הכלולים בה.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  השימוש בלשון זכר במסגרת תנאי שימוש אלו מובא מטעמי נוחות בלבד,
                  ותנאים אלו יחולו על כל משתמשי המערכת, לרבות על כל ישות ו/או
                  גוף משפטי מטעמו הנך פועל.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  תנאי השימוש עשויים להשתנות ו/או להתעדכן מעת לעת, והמשך השימוש
                  במערכת יותנה בהסכמתך לתנאי השימוש המעודכנים, ככל שיעודכנו. כל
                  המאפיינים ו/או התכנים החדשים אשר יועמדו לרשותך באמצעות המערכת
                  נכון לתאריך שבו תנאי השימוש בה עודכנו לאחרונה, יוכפפו באופן
                  אוטומטי לתנאי השימוש המעודכנים. המשך השימוש במערכת לאחר כל
                  שינוי כאמור, יהווה הסכמתך לשינויים אלה.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  באישורך את תנאי שימוש אלו ובאמצעות שימושך במערכת, אתה מאשר,
                  מבין ומצהיר, כי המערכת והשירותים הניתנים במסגרתה, נמצאים בשלבי
                  פיתוח שיפור ושדרוג מתמידים, כי החברה אינה מתחייבת שהמערכת תספק
                  את התוצאות המקצועיות המתאימות ו/או המדויקות לך ו/או המשוערות
                  על ידך, וכי בהתאם לכך, ובכפוף לכל דין, השימוש ו/או תוצאות
                  השימוש במערכת, ו/או בתוצריה, ו/או בשירותים המסופקים באמצעותה,
                  הן ותמיד יהיו על אחריותך, באופן בלעדי וללא יוצא מן הכלל.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  לתשומת ליבך, תנאי שימוש אלו חלים על משתמשי המערכת הפועלים
                  כספקי ברזל ומוצריו (להלן: "<strong>ספקים</strong>"),
                  המעוניינים להשתמש במערכת לצורך הדפסת כרטיסיות הזמנה חכמות
                  בהתבסס על סיכומי הזמנות ברזל של לקוחות ו/או להשתמש ברכיבי
                  המערכת אשר אינם דורשים תשלום, ו/או לצורך הפקת הצעות מחיר,
                  למטרת מכירה ואספקת הזמנות בפועל ללקוחות קצה, באמצעות המערכת
                  כגורם מתווך, ו/או לצורך כל שימוש אחר ייעודי לספקים במערכת,
                  בהתאם לפונקציות והמאפיינים שהמערכת תציע מעת לעת. שימושים אחרים
                  במערכת, לרבות הפקת סיכומי הזמנה לשימוש עצמי ו/או קבלת הצעות
                  מחיר ו/או ביצוע הזמנות ברזל כלקוח קצה, כפוף לתנאי השימוש
                  הרלוונטיים ללקוחות, אותם ניתן למצוא בכתובת הבאה:&nbsp;
                </span>
                <span
                  onClick={scrollToBuyersSection}
                  style={{ cursor: "pointer" }}
                >
                  <u>
                    <strong> תנאים רוכשים</strong>
                  </u>
                </span>
              </li>
            </ol>
          </li>
          {/* 2 */}
          <li dir="rtl">
            <strong>
              <u>השימוש במערכת וביצוע הזמנות</u>
            </strong>
            <ol type="1">
              <li dir="rtl">
                <span>
                  השימוש הבסיסי במערכת יאפשר לך, בין היתר, לקלוט ולהדפיס סיכומי
                  הזמנות ברזל של לקוחות באופן מסודר ויעיל, וללא כל עלות. ככל
                  ותבחר להצטרף לשירות מתקדם בתשלום כמפורט להלן, המערכת תאפשר לך
                  להדפיס כרטיסיות ייצור חכמות, בהתבסס על סיכומי הזמנות הברזל
                  כאמור.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  בנוסף, מעת לעת ולבקשת המערכת, יתאפשר לך להציע הצעות מחיר
                  תחרותיות לאספקת הזמנות ברזל ללקוחות, בהתאם לסיכומי הזמנות ו/או
                  בקשות להצעות מחיר שיופקו על ידי לקוחות המערכת ו/או באופן ישיר
                  על ידי המערכת. הצעות המחיר יסופקו על ידך בפורמט שיידרש על ידי
                  המערכת, בהתאם לנהלי הזנת הזמנות במערכת כפי שיעודכנו מעת לעת.
                  כל הצעת מחיר שתציע ותאושר על ידי הלקוחות המזמינים, תועבר
                  לביצוע על ידך באופן ישיר בהתאם לתנאיה, והכול בהתאם ובכפוף
                  לתנאי שימוש אלו כמפורט להלן.
                </span>
              </li>
              <div className="mb-3">
                <u>
                  <strong>הפקת כרטיסיות ייצור</strong>
                </u>
              </div>
              <li dir="rtl">
                <span>
                  כספק ברזל המשתמש במערכת, תוכל לקבל מלקוחות המערכת ו/או להפיק
                  באופן עצמאי, סיכומי הזמנות ברזל המבוססים על נתונים ופרטים
                  טכניים, כגון מידות, סוגים וכמויות, כפי שהוזנו על ידי הלקוחות,
                  ולאחר שעובדו באופן חכם על ידי המערכת בהתבסס על ידע מקצועי
                  ואלגוריתמים חכמים (להלן: "<strong>סיכומי ההזמנות</strong>").
                  סיכומי ההזמנות כאמור יסופקו ויהיו ניתנים לצפייה כפלט פנימי דרך
                  המערכת וכן יהיו זמינים לשיתוף עצמי ו/או של צדדי ג' דרך הדואר
                  האלקטרוני. לנוחותך, סיכומי ההזמנה יהיו ניתנים גם להדפסה באופן
                  ישיר דרך המערכת, והכול ללא כל עלות.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  מעבר לכך, בכפוף לרכישת מנוי לשימוש במערכת כמפורט להלן, המערכת
                  תאפשר לך להדפיס כרטיסיות ייצור חכמות, בקבצים ייעודיים המותאמים
                  לשימושך, והניתנים לחילוץ, עריכה, ואחסון מחוץ למערכת (להלן: "
                  <strong>כרטיסיות ייצור</strong>"). האפשרות להפיק כרטיסיות
                  ייצור נועדה לחסוך לך זמן רב של הקלדות נתונים מייגעות, ולייעל
                  עבורך את הליך קבלת ההזמנות והעברתן לייצור באופן מקצועי.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  רכישת מנוי למערכת תתאפשר באופן ישיר, נוח וידידותי דרך המערכת,
                  באמצעות כרטיס אשראי או הוראת קבע. המנוי המוצע במערכת הינו מנוי
                  חודשי (להלן: "<strong>המנוי</strong>"). בסיום תקופת המנוי,
                  המנוי יתחדש באופן אוטומטי לתקופה של חודש נוסף בכל פעם, אלא אם
                  תינתן על ידך הוראה אחרת בכתב לביטול המנוי לפני תום התקופה
                  האמורה.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  מנויים חדשים עשויים להיות זכאים לתקופת ניסיון של חודש בחינם,
                  בהתאם ובכפוף לפרסומים העדכניים במערכת במועד הצטרפותם, כפי
                  שיופיעו מעת לעת.
                </span>
              </li>

              <div className="mb-3">
                <u>
                  <strong>הפקת הצעות מחיר ואספקת הזמנות</strong>
                </u>
              </div>
              <li dir="rtl">
                <span>
                  במסגרת שימושך במערכת וכשירות משלים, החברה עשויה לפנות אליך,
                  דרך המערכת ו/או מחוצה לה, בבקשה לקבל ממך הצעות מחיר לצורך
                  ביצוע הזמנות ברזל עבור לקוחות המערכת שפנו לקבלת הצעות מחיר
                  כאמור (להלן: "<strong>"הצעות מחיר</strong>", ו-"
                  <strong>הלקוחות המזמינים</strong>", לפי העניין). בקשות החברה
                  לקבלת הצעות מחיר יכול שיתבססו על סיכומי הזמנות קונקרטים של
                  הלקוחות המזמינים, אך לכל הפחות יכללו את סוג הסחורה הנדרשת,
                  הכמות, המשקל, המידות והמועד הדרוש לאספקתה. יובהר כי הבקשה
                  לקבלת הצעת מחיר לא תכלול את זהות הלקוח המזמין עד אשר תאושר
                  באופן סופי.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  על מנת שהצעת המחיר תישקל על ידי החברה מול הצעות מחיר נוספות,
                  על הצעת המחיר לכלול את מחיר ההזמנה הסופי, וכן את המועד האחרון
                  לאספקה. ככל והנך סבור כי ישנן הערות או תנאים מיוחדים או נוספים
                  שחלים על הצעת המחיר, יש לציינם באופן ברור ומפורש בהצעת המחיר.
                  כמו כן, תנאי מהותי לבחינת הצעת מחיר והעברתה ללקוחות המזמינים,
                  היא שהצעת המחיר תעמוד בתוקף, לכל הפחות לתקופה של 10 ימי עסקים
                  ממועד הפקתה. בהתאם לכך, מובהר כי הצעת מחיר בה תצוין תקופה קצרה
                  מכך, יכול שלא תילקח בחשבון, בהתאם לשיקול דעתה המלא והבלעדי של
                  החברה.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  הנך מסכים וידוע לך, כי החברה תהיה רשאית להוסיף לכל הצעת מחיר,
                  סכום המגלם עמלת תיווך לה תהיה זכאית החברה במקרה של אישור
                  ההזמנה, וזאת בשיעור שייקבע על ידי החברה בהתאם למדיניות העמלות
                  של החברה, כפי שתעודכן מעת לעת (להלן: "<strong>העמלה</strong>
                  "). מובהר כי סכום העמלה יתווסף להצעת המחיר שתועבר על ידך,
                  ויגולם כתוספת על רכיבי ההזמנה הקיימים והנקובים, ללא הפרדה
                  מובנית בין העמלה לבין יתר רכיבי הצעת המחיר ו/או אינדיקציה אחרת
                  כי הצעת המחיר מגלמת עמלה כלשהי לחברה.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  <u>העמלה</u> תיגבה בפועל מהלקוח באופן ישיר במעמד אישור הצעת
                  המחיר, ובכל מקרה לא תפגע ו/או תגרע מהצעת המחיר שהעברת ו/או
                  מהסכומים שיגיעו לך בהתאם להצעת המחיר, ככל ותאושר.{" "}
                  <u>
                    באישורך את תנאי שימוש אלו, הנך מאשר ומתחייב כי אין ולא יהיו
                    לך כל טענות כנגד החברה בגין הוספת רכיב העמלה להצעת המחיר,
                    ו/או בגין גביית העמלה מהלקוחות בפועל.
                  </u>
                </span>
              </li>
              <li dir="rtl">
                <span>
                  מובהר כי החברה תהיה רשאית לאשר הצעת מחיר ו/או לסרב להצעת מחיר
                  מכל סיבה שתראה לנכון, בין אם הצעה כאמור אושרה על ידי לקוח
                  מזמין פלוני ובין אם לאו. ואולם, ככל והצעת מחיר שהפקת תאושר,
                  החברה תעביר אליך אישור בכתב וכן סיכום רשמי של ההזמנה נשוא הצעת
                  המחיר כפי שהתקבלה, והצעת המחיר שאושרה כאמור תשתכלל לכדי הסכם
                  מחייב בינך לבין החברה ו/או הלקוח המזמין, לפי העניין, מיד במועד
                  אישורה (להלן: "<strong>מועד אישור ההזמנה</strong>"). יובהר, כי
                  פרטי הלקוח המזמין הרלוונטי להצעת המחיר שאושרה, לרבות זהות
                  הלקוח ופרטי יצירת קשר עמו, יועברו אליך לקראת מועד אספקת ההזמנה
                  בלבד, וזאת מבלי לגרוע מאחריותך כלפי החברה ו/או הלקוח המזמין,
                  לפי העניין, ואתה מוותר מראש על כל טענה ו/או תביעה בקשר לכך.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  לתשומת ליבך ומבלי לגרוע מכלליות האמור לעיל, הצעת מחיר לא
                  תשתכלל ולא תהפוך למחייבת עבורך ו/או עבור החברה ו/או עבור
                  הלקוחות, אלא לאחר אישורה הסופי הן על ידי הלקוחות המזמינים והן
                  על ידי החברה, וידוע לך, כי לחברה שיקול הדעת הסופי והמלא בנוגע
                  לאישור ו/או סירוב להצעות מחיר, מכל סיבה שתמצא לנכון ו/או ללא
                  כל סיבה. בהתאם לכך, הנך מוותר וויתור מוחלט וסופי על כל טענה
                  כלפי החברה ו/או כלפי הלקוחות המזמינים בדבר נזקים שייגרמו (אם
                  ייגרמו) בגין הצעת מחיר שלא אושרה ו/או הזמנה שלא בוצעה עקב הצעת
                  מחיר שלא אושרה ו/או גילום העמלה בהצעת מחיר ו/או גביית העמלה.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  במידה והצעת מחיר תאושר כמתואר לעיל, החברה תהיה אחראית לתשלום
                  התמורה הנקובה בהצעת המחיר המאושרת בהתאם לתנאיה(להלן: "
                  <strong>התמורה</strong>"), בתוך 7 ימי עסקים ממועד אספקת ההזמנה
                  בפועל, ובכפוף לאישורה על ידי הלקוח המזמין. יובהר כי סך העמלה
                  תקוזז מהתמורה שתשולם לחברה על ידי הלקוח לאחר אישור הצעת המחיר,
                  כך שהתמורה הסופית שתשולם לספק תהיה בגובה התמורה הנקובה בהצעת
                  המחיר שהוציא הספק בלבד, והספק לא יהיה זכאי לכל חלק ו/או רכיב
                  כלשהו מהעמלה כאמור.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  למען הסר ספק, במידה ותאושר הצעת מחיר שתתקבל ממך כמתואר לעיל,
                  אזי אתה תישא באחריות מלאה ובלעדית לסחורה שתספק ללקוחות
                  המזמינים.{" "}
                  <u>
                    <strong>
                      כנובע מכך, האחריות לטיב, איכות, תקינות, עמידה בתקנים,
                      בטיחות, שלמות, ו/או כל רכיב אחר של החומרים, וכן אספקתם
                      באופן תקין ובמועד, יהיו כולם באחריותך בלבד, והחברה לא
                      תישא, ומסירה מראש כל אחריות בגין טיב, איכות, תקינות, עמידה
                      בתקנים, בטיחות, שלמות, ו/או כל רכיב אחר של החומרים, ובקשר
                      לאספקתם בפועל.
                    </strong>
                  </u>
                </span>
              </li>
            </ol>
          </li>
          {/* 3 */}
          <li dir="rtl">
            <strong>
              <u>תשלום בעבור מנוי במערכת</u>
            </strong>
            <ol type="1">
              <li dir="rtl">
                <span>
                  השימוש בשירותים הבסיסיים במערכת אינו כרוך בתשלום. ואולם,
                  השימוש בשירותים מתקדמים במערכת, ובפרט הפקת כרטיסיות ייצור
                  חכמות, כרוך בתשלום דמי מנוי חודשיים, בסכום הנקוב במעמד ההרשמה
                  בגין מנוי חודשי, בתוספת מע"מ כדין.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  ככל ותרכוש מנוי חודשי, חיובך בגין דמי המנוי יתבצע מדי חודש
                  בחודשו בהוראת קבע, באמצעות פרטי כרטיס האשראי אשר תזין במערכת
                  באמצעות שירות הסליקה שלה, לאחר אישור העסקה ע"י חברות האשראי.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  החברה תהיה רשאית לעדכן את עלות המנוי החודשי, מעת לעת, בשיקול
                  דעתה הבלעדי וכראות עיניה. במידה והחברה תעדכן את עלות המנוי
                  החודשי, תימסר לך הודעה מתאימה על כך, ודמי המנוי המעודכנים
                  ייכנסו לתוקפם בפעם הבאה שתרכוש ו/או תחדש את המנוי החודשי שלך.
                  החברה אינה מתחייבת כי דמי המנוי יעמדו על סכום מינימאלי כלשהו,
                  ובכל מקרה סכום דמי המנוי יהיה הסכום שיצוין במאוחרות מבין
                  ההודעות שיימסרו לך בעניין זה, וכמו כן כפי שיופיע במעמד הרשמתך
                  למערכת
                </span>
                <span dir="ltr">.</span>
              </li>
              <li dir="rtl">
                <span>
                  תוקפו של המנוי שתרכוש יהיה לחודש קלנדרי ממועד ביצוע התשלום
                  בעדו.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  הפרטים כפי שהוזנו בדף ההרשמה וכן רישום העסקה במחשבי החברה יהוו
                  ראיה חלוטה לנכונות הפעולות ולביצוע העסקה.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  באפשרותך לבטל את המנוי במערכת בכל עת, על ידי מסירת הודעה בכתב
                  לחברה בכתובת הבאה:{" "}
                  <a href="mailto:support@easybar.co.il">
                    <u>
                      <span dir="ltr">support@easybar.co.il</span>
                    </u>
                  </a>{" "}
                  (להלן: "<strong>ביטול מנוי</strong>"). ביטול המנוי ייכנס לתוקף
                  תוך חודש ימים מהמועד בו תתקבל הודעת ביטול בפועל על ידי החברה,
                  כאשר במקרה בו הודעת ביטול תתקבל באמצע חודש באופן בו הכניסה
                  לתוקף של ביטול המנוי תהיה באמצע חודש, החברה תהיה רשאית לקבל
                  ממך תמורה בעד חלקו היחסי של החודש בו המנוי יהיה עדיין בתוקף.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  מבלי לגרוע מכלליות האמור, ביטול עסקת רכישת מנוי יתאפשר בכפוף
                  להוראות חוק הגנת הצרכן, תשמ"א-1981 (במידה ואלו יחולו עליך ו/או
                  על החברה).
                </span>
              </li>
              <li>
                <span>
                  במקרה של ביטול המנוי, תתבטל אפשרותך להפיק כרטיסיות הזמנה,
                  כהגדרתן לעיל, ואולם לא יגרע מאפשרותך להשתמש ביתר רכיבי המערכת,
                  לרבות אפשרותך לספק לחברה הצעות מחיר ולהפיק סיכומי הזמנה.
                </span>{" "}
                <span>
                  <u>
                    <strong>
                      למען הסר ספק, לא יהיה בביטול מנוי כדי לגרוע מהתחייבותך
                      לספק כל הצעת מחיר שתמסור ותאושר במערכת קודם לביטול המנוי,
                      ו/או כדי לגרוע מכל התחייבות אשר עולה הימנה.
                    </strong>
                  </u>
                </span>
              </li>
            </ol>
          </li>
          {/* 4 */}
          <li dir="rtl">
            <strong>
              <u>קניין רוחני / מידע במסגרת המערכת</u>
            </strong>
            <ol type="1">
              <li dir="rtl">
                כל זכויות הקניין הרוחני במערכת ובכל רכיב או חלק שלה, לרבות אך לא
                רק, זכויות היוצרים, הסימנים והסמלים המסחריים (לרבות כל עיצוב אשר
                ניתן לרושמו כסימן מסחרי), המדגמים, העיצובים והסודות המסחריים,
                הכלולים במערכת, הינם רכושה של החברה בלבד, או של צדדים שלישיים
                אשר נתנו לחברה הסכמה לשימוש בקניינם הרוחני, לפי העניין. זכויות
                אלה חלות בין היתר על עיצובה הגרפי של המערכת, בסיסי הנתונים בה,
                התכנים המופיעים במערכת, קוד המחשב, העיצוב הגרפי, בסיסי הנתונים,
                הטקסט, השרטוטים, הסמלים, התמונות, וכל פרט אחר הקשור בהקמת והפעלת
                המערכת.
              </li>
              <li dir="rtl">
                <span>
                  כל זכויות הקניין הרוחני וכל יתר הזכויות במערכת (לרבות ומבלי
                  להגביל, זכויות בסימני ושמות המסחר, בזכויות היוצרים ובזכויות
                  המוסריות) (להלן: "<strong>הקניין הרוחני</strong>"), כל חלק
                  שלהם וכל חומר המתייחס אליהם, במישרין או בעקיפין, לרבות ומבלי
                  לגרוע המוניטין, תוצרי הפיתוח, התאמות ושינויים, עדכונים,
                  תוספות, שדרוגים, שיפורים, וכיוצ"ב, בין אם הושלמו ובין שלא
                  הושלמו ו/או כל חומר אחר, בין שהם כתובים ו/או מודפסים ו/או
                  רשומים ו/או אגורים ובין אם לאו, הינם ויישארו קניינה ורכושה
                  הבלעדי של החברה. הנך מצהיר, מסכים ומתחייב כי תנאי שימוש אלו
                  ו/או השימוש במערכת לא יקנה לך או למי מטעמך כל זכות קניינית או
                  כל זכות אחרת בזכויות הקניין הרוחני של החברה או שימוש בו,
                  שינויו, יישומו, ניצולו ומסחורו.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  בלי לגרוע מהאמור לעיל, הסכמתך לתנאי שימוש אלו, מעניקה לך זכות
                  שימוש מוגבלת בלבד, שאינה בלעדית, ושניתנת לביטול על ידי החברה
                  בכל עת, לעשות שימוש במערכת כספק ובהתאם לייעודה המסחרי בלבד
                  (להלן: "<strong>זכות השימוש</strong>"). זכות השימוש כאמור אינה
                  מקנה לך זכות מסחרית אחרת, במפורש או במשתמע, בדרך של השתק או
                  בכל דרך אחרת, לגבי אף חלק מערכת ו/או מהקניין הרוחני העומד
                  בבסיסה, אינה מעניקה לך זכות למסחר את המערכת ו/או כל רכיב
                  מרכיביה, ו/או זכות לתוצרים, הכנסות, פירות, תמלוגים ו/או כל
                  זכות אחרת.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  מבלי לגרוע מכלליות האמור, באישורך את תנאי השימוש, הנך מסכים
                  שלא להעתיק, לשכפל, להפיץ, למכור, לשווק, ו/או לתרגם מידע כלשהו
                  מהמערכת (לרבות סימני מסחר, תמונות, טקסטים וקוד-מחשב) באופן
                  דיגיטלי או בכל אמצעי אחר, בלא קבלת רשותה המפורשת של החברה מראש
                  ובכתב. למען הספק ספק, אין לעשות כל שימוש בנתונים המוצגים ו/או
                  מופקים במערכת ו/או להציגם באתר אינטרנט, במערכת או בשירות אחר
                  כלשהו, בלא לקבל את הסכמתה של החברה מראש ובכתב, ובכפוף לתנאי
                  אותה הסכמה (ככל ותינתן).
                </span>
              </li>
              <li dir="rtl">
                <span>
                  באישורך את תנאי השימוש, הנך מצהיר ומסכים בזאת, כי המערכת, ו/או
                  כל ביטוי או חלק הימנה, מהווה קניין רוחני ייחודי, והינה ביטוי
                  בלעדי של המערכת אשר תוארה בס' 2.1 לעיל, והינה רכושה הבלעדי של
                  החברה.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  באישורך את תנאי השימוש, הינך מסכים בזאת להעניק לחברה רישיון
                  מוגבל בלבד, אשר אינו בלעדי, לעשות שימוש בשמך המסחרי ו/או בסימן
                  המסחר שבבעלותך, וזאת לצורך פרסום דבר התקשרותך עם החברה ו/או
                  שימושך בפלטפורמה, ולצרכי שיווק בלבד.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  באישורך את תנאי השימוש, הנך מצהיר כי למעט הרשיון הספציפי שנרכש
                  על ידך ו/או על ידי הגוף ו/או הישות שהנך פועל מטעמה, על פי
                  תנאיו והיקפו, לא ניתנת לך זכות, במפורש או במשתמע, בדרך של השתק
                  או בכל דרך אחרת, כל זכות שימוש או רישיון לגבי אף חלק מהקניין
                  הרוחני.
                </span>
              </li>
            </ol>
          </li>
          {/* 5 */}
          <li dir="rtl">
            <strong>
              <u>הגבלות שימוש</u>
            </strong>
            <ol type="1">
              <li dir="rtl">
                <span>
                  יובהר כי באישור תנאי שימוש אלו, הנך מאשר, מצהיר ומבין כי ביצוע
                  כל אחת מהפעולות המנויות בסעיף 5.2 להלן הנו אסור, ומתחייב לעמוד
                  בכך, ללא חריגים. אי עמידה מצדך בתנאים המפורטים להלן יגררו
                  השהייה או חסימה של שימושך במערכת ו/או בחשבון המשתמש שלך,
                  ועלולים לחשוף אותך לאחריות אזרחית ו/או פלילית.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  אינך רשאי בעצמך ו/או באמצעות מי מטעמך בין אם לטובתך ו/או לטובת
                  הגוף המשפטי שהינך פועל מטעמו: (1) להעתיק, לשנות, להתאים,
                  לתרגם, לבצע כל פעולת הנדסה הפוכה, לפרק כל חלק של המידע במערכת,
                  בכל דרך, או להציג בפומבי או לבצע בפומבי כל חלק מהמידע ו/או
                  התכנים במערכת, או להפיץ אותו; (2) לעשות כל שימוש במידע בכל אתר
                  אינטרנט או בכל סביבת רשתות מחשבים אחרת לכל מטרה, או לשכפל או
                  להעתיק את המידע של החברה ו/או את המערכת מבלי לקבל רשות מוקדמת
                  בכתב מהחברה; (3) להתחזות לאדם או גוף אחרים, לרבות בין השאר ולא
                  רק, כשליח, נציג או סוכן של החברה, להציג בצורה שקרית, או להציג
                  בצורה לא נכונה אחרת את הקשר שלך עם כל אדם או גוף אחרים; (4)
                  להעביר או ליצור בכל דרך אחרת, בקשר למערכת, כל תוכנה זדונית,
                  לרבות וירוס מחשב, "תולעת", סוס טרויאני, time bomb, באג, רוגלה,
                  או כל קוד מחשב, קובץ או תוכנית אחרים אשר עשויים להזיק, או
                  נועדו להזיק או לשבש ו/או לחטוף את הפעילות של כל חומרה, תוכנה
                  או ציוד תקשורת, או קוד או רכיב, שהם מזיקים, שיש בהם פוטנציאל
                  לגרום נזק, שהם מפריעים, או שהם פולשניים; (5) לפגוע בפעולתה של
                  המערכת או להפריע לה, או לפגוע בפעולה או להפריע לפעולה של שרתים
                  או רשתות אשר מארחים את המערכת או מאפשרים את זמינותה, או לא
                  לציית לכל דרישה, נוהל, מדיניות או תקנה של שרתים או רשתות אלה;
                  (6) למכור, לתת רישיון, או לנצל למטרה מסחרית כלשהי כל שימוש או
                  גישה למידע או למערכת; (7) לתחם (frame) או ליצור העתק (mirror)
                  של כל חלק של המערכת ללא הרשאה מוקדמת מפורשת בכתב מהחברה; (8)
                  ליצור מאגר מידע על ידי הורדה ואחסון שיטתיים של כל או חלק
                  מהמידע אשר מופיע במערכת; (9) להעביר כל מידע אשר הופק מהמערכת
                  ללא הרשאה מוקדמת בכתב מהחברה; (10) להעביר או להמחות את שם
                  המשתמש או הסיסמה שלך, ולו רק באופן ארעי ו/או זמני, לצד שלישי;
                  (11) לגשת או לנסות לגשת לחלק כלשהו של המערכת אשר דורש סיסמה
                  ללא שקיבלת לשם כך סיסמה מהחברה, ו/או הדורש הרשאה אשר לא ניתנה
                  לך מהחברה; (12) להשתמש במערכת לכל מטרה לא חוקית, לא מוסרית או
                  בלתי מורשית.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  הנך מצהיר ומאשר כי כל הפרטים והנתונים אשר תמסור במסגרת השימוש
                  במערכת ו/או בתהליך הרישום הינם נכונים מדויקים ואין בהם בבחינת
                  אילו מן הפעולות האסורות בסעיף 5.2 לעיל, של זכויות צד שלישי
                  כלשהו ו/או או כל דין. הנך מצהיר ומאשר כי מסירת כל מידע במסגרת
                  המערכת, נעשית באופן חוקי, וולונטרי ומרצונך החופשי בלבד.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  החברה רשאית להתיר לך להיכנס למערכת ו/או למנוע ממך את הכניסה
                  אליה, בכל זמן ועל פי שיקול דעתה הבלעדי ומכל סיבה שהיא. החברה
                  שומרת על זכותה לשנות או להפסיק, בכל זמן, באופן זמני או קבוע,
                  את הפעלתה של המערכת (או כל חלק מהם) ו/או לסרב לספק לך שירותים
                  (או חלק מהם) – ללא הודעה מוקדמת ועל פי שיקול דעתה הבלעדי.
                  בנוסף לאמור לעיל, אתה מאשר כי ייתכן שיחולו שינויים בשירותים
                  ו/או בתכנים ו/או במוצרים אשר יסופקו לך כתוצאה מהשימוש במערכת,
                  וכי אפשר ששירותים ו/או תכנים ו/או מוצרים אלה ישונו, יצומצמו,
                  יורחבו במונחים של תוכן או צורה או כי הם יופסקו בכל זמן. בכפוף
                  לכל דין, אתה מסכים כי החברה אינה ולא תהיה אחראית כלפיך או כלפי
                  כל צד שלישי מטעמו הינך פועל, בשל כל שינוי, השעיה, או הפסקה של
                  המערכת, השירותים, התכנים או המוצרים אשר נכללים במערכת, לרבות
                  מידע מקצועי, מידע ליצירת קשר, טקסטים, חישובים, תמונות, הורדות
                  דיגיטליות, סמלים, אייקונים של לחצנים, חיבורים של נתונים,
                  קישורים, תיעוד, נתונים, גרפיקה נלווית ומאפיינים ותכנים מיוחדים
                  אחרים. הינך מצהיר כי אינך מסתמך ולא תסתמך בעתיד על גישה
                  למערכת.
                </span>
              </li>
              <li dir="rtl">
                <span>
                  השימוש במערכת כפוף לרישיון שימוש, במסגרתו החברה מעניקה לך
                  רישיון גישה מוגבל למערכת ולעשות בה שימוש פרטני על פי ייעודה
                  המקצועי, מאפייניה והפונקציות הקיימות בה המיועדות לספקים בלבד,
                  והכול בהתאם לתנאים אלו.
                </span>
              </li>
            </ol>
          </li>
          {/* 6 */}
          <li dir="rtl">
            <strong>
              <u>איסור שידול ותחרות בלתי הוגנת</u>
            </strong>
            <ol type="1">
              <li dir="rtl">
                במידה ותעביר הצעות מחיר לאספקת ברזל באמצעות המערכת, אזי, למשך
                תקופת שימושך במערכת, ובמשך תקופה של 12 חודשים לאחר מכן, הנך
                מתחייב: (i) לא לשדל, לשכנע, לנסות לשדל או לנסות לשכנע, במישרין
                או בעקיפין, כל עובד, קבלן, נותן שירותים, סוכן, מפיץ, לקוח או ספק
                אחר כלשהו של החברה (לרבות, למען הסר ספק - לקוחות או ספקים אחרים
                הרשומים המערכת), לסיים, לצמצם או לשנות את יחסיו עם החברה; (ii)
                לא לשדל, לשכנע, לנסות לשדל או לנסות לשכנע, במישרין או בעקיפין
                ו/או לפנות בכל דרך אחרת, למי מלקוחות החברה אשר בזיקה אליהם
                הועברה הצעת מחיר לאספקת ברזל במסגרת שימושך במערכת, ו/או אשר
                נחשפת לפרטיו בקשר עם תנאי שימוש אלו ו/או נחשפת לפרטיו במסגרת
                התקשרותך עם החברה בכל דרך אחרת, שלא מכוח, בהתאם ובכפוף לתנאי
                שימוש אלו בלבד.
              </li>
              <li dir="rtl">
                הנך מצהיר בזאת כי ידוע לך, שרשימת לקוחותיה של החברה ו/או לקוחות
                הקצה הרשומים במערכת, על כל פרטיה, הינה רכושה הבלעדי של החברה,
                וכי התקשרותה של החברה עמך ניתן בהסתמך על תניית אי-תחרות זו. הנך
                מצהיר כי יש לך את היכולת הפיננסית להתחייב בתניית אי-תחרות זו.
              </li>
            </ol>
          </li>
          {/* 7 */}
          <li dir="rtl">
            <strong>
              <u>הגבלת אחריות</u>
            </strong>
            <ol type="1">
              <li dir="rtl">
                הנך מצהיר ומאשר כי השימוש במערכת נעשה על אחריותך הבלעדית והמלאה,
                תוך שכל סיכון הניטל במסגרת השימוש במערכת, ניטל על ידך בלבד. כל
                פלט מידע אשר יופק מהמערכת ו/או כל מידע אשר יוצג בה, ניתנים ללא
                כל התחייבות ו/או מצג, מפורש או משתמע, מצד החברה, לרבות אך ללא
                הגבלה, לאחריות משתמעת בקשר עם סחירות, התאמה למטרה מסוימת או
                לצרכי משתמש מסוים או לאי הפרה. החברה אינה מתחייבת כי המערכת ו/או
                התוכן או השירות הכלולים או המוצעים בה יעמדו בדרישותיך, לא יופרעו
                או לא יופסקו, יתקיימו בבטחה וללא תקלות, טעויות או שגיאות, יהיו
                חסינים מפני כל גישה בלתי מורשית למחשבים, או מפני נזקים, קלקולים
                או כשלים בחומרה, בתוכנה, בקווים ובמערכות התקשורת וכיוצ"ב. החברה
                לא תישא בכל אחריות כלפיך במקרה של שיבושים או בעיות כאמור או בכל
                מקרה אחר של שיבוש. מבלי לגרוע מהאמור לעיל, החברה אינה נותנת כל
                הבטחה כי המערכת ו/או התוכן או השירותים הכלולים או המוצעים בה
                יהיו זמינים (בכל אזור ו/או בכל זמן), בלתי מופרעים, רציפים,
                מדויקים, מאובטחים או נקיים מווירוסים. לפיכך, החברה אינה מבטיחה
                או מציגה מצג כלשהו בקשר אליהם. כמו כן, ידוע לך כי לא ניתן
                להתאימם לצרכיו של כל אדם ואדם וכל עסק ועסק. לא תעלה כל טענה ו/או
                תביעה ו/או דרישה כלפי החברה בגין תכונות של התכנים ו/או התוצרים,
                יכולותיהם, מגבלותיהם, ו/או התאמתם לצרכיך.
              </li>
              <li dir="rtl">
                החברה אינה מתחייבת כי תכנים ושירותים של צדדים שלישיים, לרבות של
                ספקי תוכן ושירותים ושל משתמשים, ככל שיפורסמו במערכת (לרבות
                באמצעות קישוריות) יהיו מלאים, נכונים, חוקיים או מדויקים. מובהר
                כי החברה לא תישא באחריות כלשהי לכל תוצאה ו/או השלכה בקשר אליהם,
                או משימוש בהם, או מהסתמכות עליהם.
              </li>
              <li dir="rtl">
                החברה מעודדת אותך להתייחס באופן זהיר, קפדני וביקורתי לכל פלט
                ו/או מידע ו/או דוח ו/או כרטיסייה, אשר יופקו מהמערכת ו/או בקשר עם
                השימוש במערכת, והאחריות המקצועיות לבחון את התאמת כל פלט, דוח או
                כרטיסייה, לצרכיו המדויקים של הלקוח, תחול עליך בלבד.
              </li>
              <li dir="rtl">
                בשום מקרה החברה לא תהא אחראית לכל נזק, מכל סוג שהוא, לרבות (אך
                לא רק), נזקים עקיפים, מיוחדים, אגביים או תוצאתיים, בין במסגרת
                תביעה על פי חוזה, בתביעת נזיקין או בכל תביעה אחרת בגין מעשה
                עוולה אשר ינבע מהשימוש במערכת או מחוסר האפשרות להשתמש בה, ללא
                קשר לשאלה האם החברה ו/או מי מטעמה הודיע על האפשרות שנזק כזה עלול
                להיגרם. על אף האמור לעיל, כאשר אחריות היא מנדטורית ויוחלט ע"י
                גוף משפטי רשמי מוסמך כי על אף האמור לעיל החברה אחראית לנזקים
                כאמור, אזי האחריות לנזקים תוגבל לסכום של אלף שקלים חדשים (1,000
                ש"ח) בלבד. במקרה כזה, זכאותך לפיצויים כספיים בסכום זה תבוא במקום
                כל התרופות האחרות אשר עשויות להיות לך נגד החברה.
              </li>
              <li dir="rtl">
                <span>
                  מבלי לגרוע מהאמור לעיל, החברה לא תישא באחריות במקרה של הפסקת
                  הגישה למערכת, ו/או הפסקת השירותים המסופקים במסגרתה ו/או בגין
                  עיכוב או ו/או כשל בעמידה בחובותיה של החברה, במידה ואלו נגרמו
                  כתוצאה מנסיבות שמעבר לשליטתה הסבירה של החברה, לרבות עקב "
                  <strong>כוח עליון</strong>". לעניין זה, "
                  <strong>כוח עליון</strong>" - לרבות: רעידת אדמה קשה, מהומות
                  חמורות, מלחמה, שריפה, שיטפון, התקוממות לאומית, חבלה, אמברגו,
                  מגיפות, טרור, מזג אוויר קיצוני, קשיי תחבורה ניכרים, היעדר
                  זמינות אובייקטיבית, שיבוש או עיכוב בשירותי תקשורת משמעותיים או
                  בשירותי צד ג' (לרבות הפצת DNS), כשל תוכנה או חומרה של צד ג',
                  או חוסר יכולת להשיג חומרי גלם, מצרכים, חשמל או ציוד כנחוץ.
                </span>
              </li>
            </ol>
          </li>
          {/* 8 */}
          <li dir="rtl">
            <strong>
              <u>שיפוי</u>
            </strong>
            <ol type="1">
              <li dir="rtl">
                אי מילוי ההוראות אשר מפורטות בתנאי השימוש עלול לגרום להפסקת
                גישתך למערכת ועלול לחשוף אותך לאחריות אזרחית ו/או פלילית. מבלי
                לגרוע מהאמור לעיל ומהוראות כל דין, הנך מתחייב לשפות ולפצות את
                החברה ו/או מי מטעמה, בכל מקרה שבו תפר תנאים אלה, או תפעל בקשר עם
                המערכת בניגוד להוראות כל דין ו/או תוגש תביעה כנגד החברה על ידי
                צד שלישי כל שהוא בשל פעולה שביצעת בניגוד לאמור בתנאי שימוש אלו.
                שיפוי ו/או פיצוי כאמור יכסה כל הוצאה, תשלום, הפסד, אבדן רווח או
                כל נזק אחר, ישיר או עקיף, ממוני או לא ממוני, שייגרמו לחברה ו/או
                למי מטעמה, ולרבות הוצאות משפט ושכר טרחת עורכי דין ו/או מומחים.
              </li>
              <li dir="rtl">
                בקשר עם כל הזמנות רכש ברזל שתספק מתוקף הצעות מחיר שתספק באמצעות
                ו/או דרך ו/או בקשר עם השימוש במערכת, כמפורט בתנאי שימוש אלו, אתה
                מתחייב באופן סופי, מלא מוחלט ובלתי חוזר, לשפות ולפצות את החברה,
                לרבות מנהליה, בעלי מניותיה, לקוחותיה, ספקיה, נציגיה וכל הבא
                מכוחה ו/או מטעמה, באופן מלא וללא סייג, בגין כל הוצאה, תשלום,
                הפסד, אבדן רווח או כל נזק אחר, ישיר או עקיף, ממוני או לא ממוני,
                שייגרמו ו/או ייפסקו כנגד החברה ו/או מי מטעמה כאמור (לרבות הוצאות
                משפט ושכר טרחת עורכי דין ו/או מומחים מלאים), בקשר עם טענות ו/או
                תביעות של צד שלישי כלשהו (לרבות, אך לא רק, לקוחות החברה
                הרלוונטיים בקשר להזמנה אשר סופקה על ידך), בגין אי עמידתך ו/או
                הפרה על ידך של תנאי הזמנת רכש ברזל ו/או חלק ממנה, ו/או כל פעולה
                שתתבצע על ידך בניגוד לכל דין, תקן או הסכם רלוונטיים, ו/או בשל כל
                מעשה ו/או מחדל מצידך, אשר היו בניגוד להזמנת הרכש האמורה.
              </li>
            </ol>
          </li>
          {/* 9 */}
          <li dir="rtl">
            <strong>
              <u>קישורים</u>
            </strong>
            <ol type="1">
              <li dir="rtl">
                ייתכן כי יועלו למערכת קישורים (links) לאתרים אחרים, בין אם
                למטרות שיווקיות ו/או מסחריות ו/או למטרות הסדרת תשלום ו/או לכל
                מטרה אחרת. ההוראות שלהלן תחולנה על השימוש בקישורים אלו, ואין בהן
                כדי לגרוע משאר הוראות תנאי השימוש.
              </li>
              <li dir="rtl">
                הקישורים נועדים לנוחיות המשתמש בלבד והשימוש בהם הוא לבחירתו.
              </li>
              <li dir="rtl">
                לעניין קישורים לאתרים חיצונים שאינם שייכים למערכת ("
                <strong>אתרים חיצוניים</strong>"): אם לא צוין אחרת, אין בין
                החברה לבין בעלי אתרים חיצוניים יחסים משפטיים או מסחריים, ואין
                לחברה כל שליטה, אחריות או זכות בחומר הנמצא באתרים אלו.
              </li>
              <li dir="rtl">
                החברה אינה אחראית בשום צורה לתכנים המצויים באתרים חיצוניים
                ולשירותים הניתנים בהם.
              </li>
              <li dir="rtl">
                בין היתר ומבלי לגרוע מהאמור לעיל, החברה מפעילה שירות סליקה
                חיצוני במערכת. לחברה אין כל שליטה על פעולות, פונקציות ו/או כל
                היבט אחר של שירותי הסליקה הנ"ל, לרבות בנוגע לאבטחת המידע המוזן
                ו/או נשמר במסגרת שירותי הסליקה, ואלו אף אינם נמצאים באחריותה של
                החברה, אפוא.
              </li>
              <li dir="rtl">
                אין לפרש קישורים לאתרים חיצוניים כמתן אישור, המלצה או העדפה על
                ידי החברה לאותם האתרים המקושרים, לרבות למסמכים וכל חומר אחר
                הנמצאים בהם, למפעילי האתרים או למוצרים המוצגים ו/או הנמכרים בהם.
              </li>
            </ol>
          </li>
          {/* 10 */}
          <li dir="rtl">
            <strong>
              <u>שונות וכללי</u>
            </strong>
            <ol>
              <li dir="rtl">
                תנאי שימוש אלה אינם יוצרים והם לא יתפרשו כאילו הם יוצרים שותפות,
                מיזם משותף, יחסי עובד-מעביד, יחסי שליחות או יחסים בין מעניק
                זיכיון לבין מקבל זיכיון בין הצדדים לתנאי שימוש אלה.
              </li>
              <li dir="rtl">
                תנאי שימוש אלו וכל תביעה בקשר למערכת או לשימוש בה תהיה כפופה
                לחוקי מדינת ישראל, ותתפרש על פי חוקים אלה, מבלי ליתן תוקף
                לעקרונות ברירת הדין שלה.
              </li>
              <li dir="rtl">
                כל מחלוקת אשר תנבע או תהיה קשורה לשימוש שלך במערכת תובא לדיון
                בבתי המשפט המוסמכים במחוז תל אביב, ישראל, ואתה מסכים בזאת לסמכות
                השיפוט הייחודית והמקומית של בתי משפט אלה.
              </li>
              <li dir="rtl">
                במקרה שייקבע כי הוראה כלשהי מתנאי שימוש אלה אינה חוקית, בטלה או
                שמכל סיבה אין אפשרות לאכוף אותה, אזי יראו הוראה זו כהוראה שניתן
                להפרידה מתנאי שימוש אלה, וההוראה האמורה לא תשפיע על התוקף ועל
                האפשרות לאכוף הוראה כלשהי מבין ההוראות הנותרות של תנאי שימוש
                אלה.
              </li>
              <li dir="rtl">
                שום ויתור של החברה מלאכוף כל הפרה או מחדל על פי תנאי שימוש אלה
                לא ייחשב כוויתור על כל הפרה או מחדל, קודמים או מאוחרים יותר. כל
                כותרת, כותרת של סעיף או כל כותרת אחרת אשר נכללת בתנאי שימוש אלה
                שולבה בהם לצורך נוחות ההתייחסות בלבד, וכותרת כאמור אינה מגדירה
                או מסבירה, בכל דרך, כל סעיף או הוראה אשר נכללו בתנאי שימוש אלה.
              </li>
              <li dir="rtl">
                תנאי שימוש אלה ממצים את מלוא התנאים וההוראות אשר הוסכמו בינך
                לבין החברה בקשר לנושא תנאי שימוש אלה, ואלא אם צוין במפורש אחרת
                בהסכם כתוב בינך לבין החברה, הם גוברים על כל הסכמות או הבנות
                אחרות, קודמות או בו-זמניות, בכתב או בעל-פה בינך לבין החברה.
              </li>
              <li dir="rtl">
                אם אתה מרגיש שאחת או חלק מזכויותיך סוכנו במסגרת המערכת או במקרה
                ואתה מבקש לקבלת מידע או לשאול שאלות אנא צור איתנו קשר
                בדוא"ל&nbsp;
                <a href="mailto:support@easybar.co.il">
                  <u>
                    <span dir="ltr">support@easybar.co.il</span>
                  </u>
                </a>
                <span dir="rtl">&rlm;</span> , ואנו נעשה את מירב המאמצים על מנת
                לטפל בתלונתך או
                בקשתך.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;
                <br />
                <br />
                אנו מאחלים לך שימוש יעיל, נוח ומקצועי במערכת.
              </li>
            </ol>
          </li>
        </ol>
        <p dir="rtl">
          <span dir="ltr">&nbsp;</span>
        </p>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConiditions;
