import React, { useEffect, useRef, useState } from "react";
import { isBrowser, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import {
  createNet,
  fetchDefaultsNets,
  updateNet,
  fetchLastUsedNets,
} from "Redux/middlwares/net";
import { fetchSteelDiameters } from "Redux/middlwares/steelType";
import { postMedia, updateMedia } from "Services/media";
import { calculate_partitions } from "../utils";
import NetMobileContent from "./mobile/content";
import {
  netXLengthMax,
  netXLengthMin,
  netYLengthMax,
  netYLengthMin,
  netXYLengthMax,
  netErrorTypes,
} from "Constants/net-constants";
import { STEEL_TYPES } from "Constants/general"
import NetSideBar from "./web/sidebar";
import SideBar from "../blocks/left-sidebar";
import SketchBody from "../blocks/sketch-body";
import NetShape from "./shape";
import LastAddedSidebar from "../blocks/right-sidebar";
import { setCurrentOrderNetsCount } from "Redux/features/net";
import NetsCard from "../blocks/right-sidebar/nets-cards/NetsCard";

function Net({
  setLoader,
  handleClose,
  edit,
  selectedTool,
  setSelectedTool,
  setIsStopped,
  tab,
  setTab,
  checkRowNums,
  editOrderId,
  editMode,
  deleteTool,
  copyOrderItem,
  setEditTool,
  order,
  editToolId,
}: any) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["tool"]);
  const stageRef = useRef(null);
  const finishButtonRef = useRef(null);
  const addButtonRef = useRef(null);
  const [netNumber, setNetNumber] = useState(0);
  const currentOrderNets = useAppSelector(
    (state) => state.net.currentOrderNets
  );
  const nextOrderNetsPage = useAppSelector((state) => state.net.orderNetPage);
  const standardShapes = useAppSelector((state) => state.net.defaultNets);
  const stanShapes = useAppSelector((state) => state.net.defaultShapes);
  const lastUsedShapes = useAppSelector((state) => state.net.lastUsedNets);
  const currentOrderNetsCount = useAppSelector(
    (state) => state.net.currentOrderNetsCount
  );
  const steelDiameters = useAppSelector(
    (state) => state.steelType.steelDiameters
  );

  const [partitionX, setPartitionX] = useState({
    _1: 10,
    _2: 10,
    _3: 20,
  });

  const [partitionY, setPartitionY] = useState({
    _1: 10,
    _2: 10,
    _3: 20,
  });
  const [labelingPoints, setLabelingPoints] = useState({
    topPtStart: { x: 0, y: 0 },
    topPtEnd: { x: 0, y: 0 },

    leftPtStart: { x: 0, y: 0 },
    leftPtEnd: { x: 0, y: 0 },

    bottomPtStart: { x: 0, y: 0 },
    bottomPtSecondLast: { x: 0, y: 0 },
    bottomPtEnd: { x: 0, y: 0 },

    rightPtStart: { x: 0, y: 0 },
    rightPtSecondLast: { x: 0, y: 0 },
    rightPtEnd: { x: 0, y: 0 },
  });
  const [resacleFactor, setRescaleFactor] = useState(1);
  const [selectedShape, setSelectedShape] = useState(-1); //To set the index of the selected item for styling while edit mode
  const [customizeView, setCustomizeView] = useState({
    uniformDiameter: true,
    uniformGap: true,
  });
  const [filterDiameter, setFilterDiameter] = useState({});
  const [filterGap, setFilterGap] = useState("");

  const [shapeObj, setShape] = useState({} as any);
  const [orderItem, setOrderItem] = useState({
    quantity: 0,
    production_notes: "",
    position_notes: "",
    row_number: 0,
  });
  const [errorsText, setErrorsText] = useState<any[]>([]);
  const [addMore, setAddMore] = useState(true);
  const [shapes, setShapes] = useState([]);
  const [stanGaps, setStanGaps] = useState([]);
  const [stanDiams, setStanDiams] = useState([]);
  const [isShapesError, setIsShapesError] = useState(false);

  // dummy states
  const [xUnits, setXUnits] = useState(0);
  const [yUnits, setYUnits] = useState(0);
  // dummy states ends

  const [netsData, setNetsData] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  const [showStandard, setShowStandard] = useState(true);

  const [edgeChanged_X, setEdgeChanged_X] = useState(false);
  const [edgeChanged_Y, setEdgeChanged_Y] = useState(false);

  const [initiateImage, setInitiateImage] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const toggleUniformGap = () => {
    setCustomizeView({
      ...customizeView,
      uniformGap: !customizeView.uniformGap,
    });
  };
  const toggleUniformDiameter = () => {
    setCustomizeView({
      ...customizeView,
      uniformDiameter: !customizeView.uniformDiameter,
    });
  };

  const addError = (newError: any) => {
    if (!errorsText.some((error) => error.errorType === newError.errorType)) {
      setErrorsText((prevErrors) => [...prevErrors, newError]);
    }
  };

  const removeError = (errorType: number) => {
    setErrorsText((prevErrors) =>
      prevErrors.filter((error) => error.errorType !== errorType)
    );
  };

  const checkLengths = () => {
    if (
      shapeObj.x_length < netXLengthMin ||
      shapeObj.y_length < netYLengthMin
    ) {
      addError({
        message: t("error_net_min", { ns: "tool" }),
        errorType: netErrorTypes.MIN,
      });
    }
    if (
      shapeObj.x_length >= netXLengthMax ||
      shapeObj.y_length >= netYLengthMax
    ) {
      addError({
        message: t("error_net_max", { ns: "tool" }),
        errorType: netErrorTypes.MAX,
      });
    }
    if (
      shapeObj.y_length > netXYLengthMax &&
      shapeObj.x_length > netXYLengthMax
    ) {
      addError({
        message: t("error_net_minmax", { ns: "tool" }),
        errorType: netErrorTypes.MAXMIN,
      });
    }
  };

  useEffect(() => {
    if (currentOrderNets) {
      setNetsData(currentOrderNets);
      if (nextOrderNetsPage !== false) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  }, [currentOrderNets]);

  useEffect(() => {
    const setShapeParams = async (shapeObject: any) => {
      if (
        shapeObject?.x_gap !== shapeObject?.y_gap &&
        shapeObject?.x_diameter.steel_diameter !==
          shapeObject?.y_diameter.steel_diameter
      ) {
        setCustomizeView({ uniformDiameter: false, uniformGap: false });
      } else if (shapeObject?.x_gap !== shapeObject?.y_gap) {
        setCustomizeView({ uniformDiameter: true, uniformGap: false });
      } else if (
        shapeObject?.x_diameter.steel_diameter !==
        shapeObject?.y_diameter.steel_diameter
      ) {
        setCustomizeView({ uniformGap: true, uniformDiameter: false });
      } else {
        setCustomizeView({ uniformDiameter: true, uniformGap: true });
      }
      setFilterGap("");
      setFilterDiameter({});
      stanShapes.length === 0 && (await dispatch(fetchDefaultsNets()));
      if (shapeObject && stanShapes.length > 0) {
        setInitialShape(shapeObject);
        setShape({ ...shapeObject });
        setTimeout(() => {
          setIsLoading(false);
        }, 10);
        const selectedNet = stanShapes.find(
          (item) =>
            item.lens.x === parseInt(shapeObject.x_length) &&
            item.lens.y === parseInt(shapeObject.y_length)
        );
        if (selectedNet) {
          setStanGaps(selectedNet.gaps);
          setStanDiams(selectedNet.dia);
          const isDiaExist = selectedNet.dia.filter(
            (item) => item.id === shapeObject.x_diameter
          );
          const isGapExist = selectedNet.gaps.filter(
            (item) => item === shapeObject.x_gap
          );
          if (isGapExist && isDiaExist) {
            setFilterDiameter(shapeObject.x_diameter);
            setFilterGap(parseInt(isGapExist[0]));
          }
        }
      }
    };
    if (edit) {
      setSelectedShape(selectedTool.id);
      setShapeParams(selectedTool.tool);
      setOrderItem({
        quantity: selectedTool.quantity,
        production_notes: selectedTool.production_notes,
        position_notes: selectedTool.position_notes,
        row_number: selectedTool.row_number,
      });
    } else if (lastUsedShapes.length > 0 && selectedShape >= 0) {
      setShapeParams(lastUsedShapes[selectedShape]);
    }
  }, [selectedShape, edit, selectedTool, selectedTool.tool, stanShapes.length]);

  useEffect(() => {
    STEEL_TYPES.forEach(st_type => {
      dispatch(fetchSteelDiameters(st_type));
    });
  }, []);

  useEffect(() => {
    const initalFetch = async () => {
      await dispatch(fetchLastUsedNets());
    };
    initalFetch();
  }, [dispatch, lastUsedShapes.length]);

  const setInitialShape = (shapeInstance: any) => {
    if (shapeInstance.x_2 === null && shapeInstance.x_3 === null) {
      const widthPartitions = calculate_partitions(
        shapeInstance.x_gap,
        shapeInstance.x_length,
        shapeInstance.x_diameter.steel_diameter
      );
      setPartitionX(widthPartitions);
    } else {
      setPartitionX({
        _1: shapeInstance.x_length - shapeInstance.x_2 - shapeInstance.x_3,
        _2: shapeInstance.x_2,
        _3: shapeInstance.x_3,
      });
    }
    if (shapeInstance.y_2 === null && shapeInstance.y_3 === null) {
      const heigthPartitions = calculate_partitions(
        shapeInstance.y_gap,
        shapeInstance.y_length,
        shapeInstance.y_diameter.steel_diameter
      );
      setPartitionY(heigthPartitions);
    } else {
      setPartitionY({
        _1: shapeInstance.y_length - shapeInstance.y_2 - shapeInstance.y_3,
        _2: shapeInstance.y_2,
        _3: shapeInstance.y_3,
      });
    }
  };

  useEffect(() => {
    const initalFetch = async () => {
      setLoader(true);
      stanShapes.length === 0 && (await dispatch(fetchDefaultsNets()));
      setLoader(false);
    };
    initalFetch();

    let shapeInstance = {};
    if (stanShapes.length > 0) {
      setShapes(stanShapes);
      setStanGaps(stanShapes[0].gaps);
      setStanDiams(stanShapes[0].dia);

      if (!edit) {
        const initialDiam = stanShapes[0].dia.find(
          (el) => el.steel_diameter === 8
        );
        const initialGap = stanShapes[0].gaps.find((el) => el === 20);
        setFilterDiameter(initialDiam);
        setFilterGap(initialGap);
        shapeInstance = {
          ...standardShapes[0],
          standard_net: true,
          x_length: parseInt(stanShapes[0].lens.x),
          y_length: parseInt(stanShapes[0].lens.y),
          x_diameter: initialDiam,
          y_diameter: initialDiam,
          x_gap: parseInt(initialGap),
          y_gap: parseInt(initialGap),
          x_2: null,
          x_3: null,
          y_2: null,
          y_3: null,
        };
        setInitialShape(shapeInstance);
        setShape({ ...shapeInstance });
        setIsLoading(false);
      }
    }
  }, [dispatch, setLoader, standardShapes.length, stanShapes.length]);

  useEffect(() => {
    const calculateWeight = () => {
      if (shapeObj.x_length && shapeObj.y_length) {
        const nx = Math.round(partitionX._1 / shapeObj.x_gap + 1);
        const lx = nx * shapeObj.y_length;
        const qx = (lx / 100) * parseFloat(shapeObj.x_diameter.weight);

        const ny = Math.round(partitionY._1 / shapeObj.y_gap + 1);
        const ly = ny * shapeObj.x_length;
        const qy = (ly / 100) * parseFloat(shapeObj.y_diameter.weight);
        const unitWeight = (qx + qy).toFixed(2);

        setShape((prev: any) => ({ ...prev, unit_weight: unitWeight }));
      }
    };

    if (shapeObj.x_diameter && shapeObj.y_diameter) {
      calculateWeight();
    }
  }, [
    shapeObj.x_diameter,
    shapeObj.y_diameter,
    shapeObj.x_gap,
    shapeObj.y_gap,
    shapeObj.y_length,
    shapeObj.x_length,
    partitionX._1,
    partitionY._1,
  ]);

  const validateMidVals = (
    val: number,
    maxVal: number,
    diam: number,
    partRemaining: number,
    partition: any
  ) => {
    const minOtherVals = (diam / 10) * 2 * 2;
    const p2 = parseInt(partRemaining) + parseInt(partition._2);
    const p3 = parseInt(partRemaining) + parseInt(partition._3);
    const sum = Math.abs(val) + Math.abs(p2) + Math.abs(p3);

    const flags = {
      otherChecks: false,
    };

    if (val > maxVal) {
      flags.otherChecks = true;
    }

    if (val + minOtherVals > maxVal) {
      flags.otherChecks = true;
    }

    if (sum > maxVal + 1) {
      flags.otherChecks = true;
    }
    return flags;
  };

  const validateVals = (
    val: number,
    diff: number,
    diam: number,
    otherP: number,
    maxLength: number
  ) => {
    let flags = {
      minValFlag: false,
      otherChecks: false,
      isError: false,
      isMax: false,
    };
    const minVal = (diam / 10) * 2;

    if (val < minVal || isNaN(val)) {
      flags.minValFlag = true;
      flags.isError = true;
    }
    if (Math.abs(val) + Math.abs(otherP) >= maxLength) {
      flags.otherChecks = true;
      flags.isError = true;
      flags.isMax = true;
    }
    if (val > maxLength) {
      flags.otherChecks = true;
      flags.isError = true;
      flags.isMax = true;
    }
    return flags;
  };

  const onX1Change = (e) => {
    const val = parseInt(e.target.value);
    const partionsRemaining = (partitionX._1 - parseInt(e.target.value)) / 2;
    const checkFlags = validateMidVals(
      val,
      shapeObj.x_length,
      shapeObj.x_diameter.steel_diameter,
      partionsRemaining,
      partitionX
    );
    if (val > 0 && !checkFlags.otherChecks) {
      setPartitionX({
        _1: val,
        _2: partitionX._2 + partionsRemaining,
        _3: partitionX._3 + partionsRemaining,
      });
    }

    if (checkFlags.otherChecks) {
      addError({
        errorType: netErrorTypes._X1,
        message: t("error_net_measure", { ns: "tool" }),
      });
    } 
    if (
      val % shapeObj.x_gap !== 0 &&
      partitionY._1 % shapeObj.y_gap !== 0
    ) {
      addError({
        errorType: netErrorTypes.GAP_X1Y1_VALIDATION,
        message: t("error_net_gap_xy", { ns: "tool" }),
      });
    } else if (val % shapeObj.x_gap !== 0) {
      addError({
        errorType: netErrorTypes.GAP_X1_VALIDATION,
        message: t("error_net_gap_xy", { ns: "tool" }),
      });
    } else {
      removeError(netErrorTypes.GAP_X1Y1_VALIDATION);
      removeError(netErrorTypes.GAP_X1_VALIDATION);
    }
  };

  const onX2Change = (e) => {
    setEdgeChanged_X(true);
    const val = parseFloat(e.target.value);
    const diff = partitionX._2 - val;
    const flagChecks = validateVals(
      val,
      diff,
      shapeObj.x_diameter.steel_diameter,
      partitionX._3,
      shapeObj.x_length
    );

    const partX1Val =
      flagChecks.isError || isNaN(e.target.value) || e.target.value === ""
        ? shapeObj.x_length - (isNaN(partitionX._3) ? 0 : partitionX._3)
        : shapeObj.x_length - (isNaN(partitionX._3) ? 0 : partitionX._3) - val;

    if (val > 0 && !flagChecks.otherChecks) {
      setPartitionX({
        ...partitionX,
        _2: e.target.value === "" ? 0 : val,
        _1:
          e.target.value === ""
            ? shapeObj.x_length - (isNaN(partitionX._3) ? 0 : partitionX._3)
            : shapeObj.x_length -
              (isNaN(partitionX._3) ? 0 : partitionX._3) -
              val,
      });
    } else if (!flagChecks.isMax) {
      setPartitionX({
        ...partitionX,
        _1:
          flagChecks.isError || isNaN(e.target.value) || e.target.value === ""
            ? shapeObj.x_length - (isNaN(partitionX._3) ? 0 : partitionX._3)
            : shapeObj.x_length -
              (isNaN(partitionX._3) ? 0 : partitionX._3) -
              val,
        _2: "",
      });
    }
    if (flagChecks.otherChecks || isNaN(val) || val === 0) {
      addError({
        errorType: netErrorTypes._X2,
        message: t("error_net_measure", { ns: "tool" }),
      });
    }
    else{
      removeError(netErrorTypes._X2);
    }
    if (flagChecks.minValFlag) {
      addError({
        errorType: netErrorTypes.MIN_X2PART,
        message: t("error_net_sides", { ns: "tool" }),
      });
    } else {
      removeError(netErrorTypes.MIN_X2PART);
    }
    if (
      partX1Val % shapeObj.x_gap !== 0 &&
      partitionY._1 % shapeObj.y_gap !== 0
    ) {
      addError({
        errorType: netErrorTypes.GAP_X1Y1_VALIDATION,
        message: t("error_net_gap_xy", { ns: "tool" }),
      });
    } else if (partX1Val % shapeObj.x_gap !== 0) {
      addError({
        errorType: netErrorTypes.GAP_X1_VALIDATION,
        message: t("error_net_gap_xy", { ns: "tool" }),
      });
    } else {
      removeError(netErrorTypes.GAP_X1Y1_VALIDATION);
      removeError(netErrorTypes.GAP_X1_VALIDATION);
    }
  };

  const onX3Change = (e) => {
    setEdgeChanged_X(true);
    const val = parseFloat(e.target.value);
    const diff = partitionX._3 - val;
    const flagChecks = validateVals(
      val,
      diff,
      shapeObj.x_diameter.steel_diameter,
      partitionX._2,
      shapeObj.x_length
    );

    const partX1Val =
      flagChecks.isError || isNaN(e.target.value) || e.target.value === ""
        ? shapeObj.x_length - (isNaN(partitionX._2) ? 0 : partitionX._2)
        : shapeObj.x_length - (isNaN(partitionX._2) ? 0 : partitionX._2) - val;
    if (val > 0 && !flagChecks.otherChecks) {
      setPartitionX({
        ...partitionX,
        _3: e.target.value === "" ? 0 : val,
        _1:
          e.target.value === ""
            ? shapeObj.x_length - (isNaN(partitionX._2) ? 0 : partitionX._2)
            : shapeObj.x_length -
              (isNaN(partitionX._2) ? 0 : partitionX._2) -
              val,
      });
    } else if (!flagChecks.isMax) {
      setPartitionX({
        ...partitionX,
        _1:
          flagChecks.isError || isNaN(e.target.value) || e.target.value === ""
            ? shapeObj.x_length - (isNaN(partitionX._2) ? 0 : partitionX._2)
            : shapeObj.x_length -
              (isNaN(partitionX._2) ? 0 : partitionX._2) -
              val,
        _3: "",
      });
    }
    if (flagChecks.otherChecks || isNaN(val) || val === 0) {
      addError({
        errorType: netErrorTypes._X3,
        message: t("error_net_measure", { ns: "tool" }),
      });
    }
    else{
      removeError(netErrorTypes._X3);
    }
    if (flagChecks.minValFlag) {
      addError({
        errorType: netErrorTypes.MIN_X3PART,
        message: t("error_net_sides", { ns: "tool" }),
      });
    } else {
      removeError(netErrorTypes.MIN_X3PART);
    }
    if (
      partX1Val % shapeObj.x_gap !== 0 &&
      partitionY._1 % shapeObj.y_gap !== 0
    ) {
      addError({
        errorType: netErrorTypes.GAP_X1Y1_VALIDATION,
        message: t("error_net_gap_xy", { ns: "tool" }),
      });
    } else if (partX1Val % shapeObj.x_gap !== 0) {
      addError({
        errorType: netErrorTypes.GAP_X1_VALIDATION,
        message: t("error_net_gap_xy", { ns: "tool" }),
      });
    } else {
      removeError(netErrorTypes.GAP_X1Y1_VALIDATION);
      removeError(netErrorTypes.GAP_X1_VALIDATION);
    } 
  };

  const onY1Change = (e) => {
    const val = parseInt(e.target.value);
    const partionsRemaining = (partitionY._1 - parseInt(e.target.value)) / 2;
    const checkFlags = validateMidVals(
      val,
      shapeObj.y_length,
      shapeObj.y_diameter.steel_diameter,
      partionsRemaining,
      partitionY
    );

    if (val > 0 && !checkFlags.otherChecks) {
      setPartitionY({
        _1: val,
        _2: partitionY._2 + partionsRemaining,
        _3: partitionY._3 + partionsRemaining,
      });
    }

    if (checkFlags.otherChecks) {
      addError({
        errorType: netErrorTypes._Y1,
        message: t("error_net_measure", { ns: "tool" }),
      });
    } 
    if (
      val % shapeObj.y_gap !== 0 &&
      partitionX._1 % shapeObj.x_gap !== 0
    ) {
      addError({
        errorType: netErrorTypes.GAP_X1Y1_VALIDATION,
        message: t("error_net_gap_xy", { ns: "tool" }),
      });
    } else if (val % shapeObj.y_gap !== 0) {
      addError({
        errorType: netErrorTypes.GAP_Y1_VALIDATION,
        message: t("error_net_gap_xy", { ns: "tool" }),
      });
    } else {
      removeError(netErrorTypes._Y1);
      removeError(netErrorTypes.GAP_X1Y1_VALIDATION);
      removeError(netErrorTypes.GAP_Y1_VALIDATION);
    }
  };

  const onY2Change = (e) => {
    setEdgeChanged_Y(true);
    const val = parseFloat(e.target.value);
    const diff = partitionY._2 - val;
    const flagChecks = validateVals(
      val,
      diff,
      shapeObj.y_diameter.steel_diameter,
      partitionY._3,
      shapeObj.y_length
    );

    const partYVal =
      flagChecks.isError || isNaN(e.target.value) || e.target.value === ""
        ? shapeObj.y_length - (isNaN(partitionY._3) ? 0 : partitionY._3)
        : shapeObj.y_length - (isNaN(partitionY._3) ? 0 : partitionY._3) - val;

    if (val > 0 && !flagChecks.otherChecks) {
      setPartitionY({
        ...partitionY,
        _2: e.target.value === "" ? 0 : val,
        _1:
          e.target.value === ""
            ? shapeObj.y_length - (isNaN(partitionY._3) ? 0 : partitionY._3)
            : shapeObj.y_length -
              (isNaN(partitionY._3) ? 0 : partitionY._3) -
              val,
      });
    } else if (!flagChecks.isMax) {
      setPartitionY({
        ...partitionY,
        _1:
          flagChecks.isError || isNaN(e.target.value) || e.target.value === ""
            ? shapeObj.y_length - (isNaN(partitionY._3) ? 0 : partitionY._3)
            : shapeObj.y_length -
              (isNaN(partitionY._3) ? 0 : partitionY._3) -
              val,
        _2: "",
      });
    }

    if (flagChecks.otherChecks || isNaN(val) || val === 0) {
      addError({
        errorType: netErrorTypes._Y2,
        message: t("error_net_measure", { ns: "tool" }),
      });
    }
    else{
      removeError(netErrorTypes._Y2);
    }

    if (flagChecks.minValFlag) {
      addError({
        errorType: netErrorTypes.MIN_Y2PART,
        message: t("error_net_sides", { ns: "tool" }),
      });
    } else {
      removeError(netErrorTypes.MIN_Y2PART);
    }
    if (
      partYVal % shapeObj.y_gap !== 0 &&
      partitionX._1 % shapeObj.x_gap !== 0
    ) {
      addError({
        errorType: netErrorTypes.GAP_X1Y1_VALIDATION,
        message: t("error_net_gap_xy", { ns: "tool" }),
      });
    } else if (partYVal % shapeObj.y_gap !== 0) {
      addError({
        errorType: netErrorTypes.GAP_Y1_VALIDATION,
        message: t("error_net_gap_xy", { ns: "tool" }),
      });
    } else {
      removeError(netErrorTypes.GAP_X1Y1_VALIDATION);
      removeError(netErrorTypes.GAP_Y1_VALIDATION);
    }
  };

  const onY3Change = (e) => {
    setEdgeChanged_Y(true);
    const val = parseFloat(e.target.value);
    const diff = partitionY._3 - val;
    const flagChecks = validateVals(
      val,
      diff,
      shapeObj.y_diameter.steel_diameter,
      partitionY._2,
      shapeObj.y_length
    );

    const partYVal =
      flagChecks.isError || isNaN(e.target.value) || e.target.value === ""
        ? shapeObj.y_length - (isNaN(partitionY._2) ? 0 : partitionY._2)
        : shapeObj.y_length - (isNaN(partitionY._2) ? 0 : partitionY._2) - val;

    if (val > 0 && !flagChecks.otherChecks) {
      setPartitionY({
        ...partitionY,
        _3: e.target.value === "" ? 0 : val,
        _1:
          e.target.value === ""
            ? shapeObj.y_length - (isNaN(partitionY._2) ? 0 : partitionY._2)
            : shapeObj.y_length -
              (isNaN(partitionY._2) ? 0 : partitionY._2) -
              val,
      });
    } else if (!flagChecks.isMax) {
      setPartitionY({
        ...partitionY,
        _1:
          flagChecks.isError || isNaN(e.target.value) || e.target.value === ""
            ? shapeObj.y_length - (isNaN(partitionY._2) ? 0 : partitionY._2)
            : shapeObj.y_length -
              (isNaN(partitionY._2) ? 0 : partitionY._2) -
              val,
        _3: "",
      });
    }
    if (flagChecks.otherChecks || isNaN(val) || val === 0) {
      addError({
        errorType: netErrorTypes._Y3,
        message: t("error_net_measure", { ns: "tool" }),
      });
    }
    else{
      removeError(netErrorTypes._Y3);
    }
    if (flagChecks.minValFlag) {
      addError({
        errorType: netErrorTypes.MIN_Y3PART,
        message: t("error_net_sides", { ns: "tool" }),
      });
    } else {
      removeError(netErrorTypes.MIN_Y3PART);
    }
    if (
      partYVal % shapeObj.y_gap !== 0 &&
      partitionX._1 % shapeObj.x_gap !== 0
    ) {
      addError({
        errorType: netErrorTypes.GAP_X1Y1_VALIDATION,
        message: t("error_net_gap_xy", { ns: "tool" }),
      });
    } else if (partYVal % shapeObj.y_gap !== 0) {
      addError({
        errorType: netErrorTypes.GAP_Y1_VALIDATION,
        message: t("error_net_gap_xy", { ns: "tool" }),
      });
    } else {
      removeError(netErrorTypes.GAP_X1Y1_VALIDATION);
      removeError(netErrorTypes.GAP_Y1_VALIDATION);
    }
  };

  function saveNet(isAddMore: boolean, netNum: number) {
    setAddMore(isAddMore);

    if (!orderItem.quantity) {
      addError({
        message: t("error_net_amount", { ns: "tool" }),
        errorType: netErrorTypes.AMOUNT,
      });
      return;
    }
    if (
      shapeObj.x_length < netXLengthMin ||
      shapeObj.y_length < netYLengthMin
    ) {
      addError({
        message: t("error_net_min", { ns: "tool" }),
        errorType: netErrorTypes.MIN,
      });
      return;
    }
    if (
      shapeObj.x_length >= netXLengthMax ||
      shapeObj.x_length >= netYLengthMax
    ) {
      addError({
        message: t("error_net_max", { ns: "tool" }),
        errorType: netErrorTypes.MAX,
      });
      return;
    }
    if (
      shapeObj.y_length > netXYLengthMax &&
      shapeObj.x_length > netXYLengthMax
    ) {
      addError({
        message: t("error_net_minmax", { ns: "tool" }),
        errorType: netErrorTypes.MAXMIN,
      });
      return;
    }

    //Below four if conditions are to check whether XY-sides are
    //in range or not. Range(diam * 2)

    if (partitionX._2 < (shapeObj.x_diameter.steel_diameter / 10) * 2) {
      addError({
        errorType: netErrorTypes.MIN_X2PART,
        message: t("error_net_sides", { ns: "tool" }),
      });
    } else if (partitionX._3 < (shapeObj.x_diameter.steel_diameter / 10) * 2) {
      addError({
        errorType: netErrorTypes.MIN_X3PART,
        message: t("error_net_sides", { ns: "tool" }),
      });
    } else if (partitionY._2 < (shapeObj.x_diameter.steel_diameter / 10) * 2) {
      addError({
        errorType: netErrorTypes.MIN_Y2PART,
        message: t("error_net_sides", { ns: "tool" }),
      });
    } else if (partitionY._3 < (shapeObj.x_diameter.steel_diameter / 10) * 2) {
      addError({
        errorType: netErrorTypes.MIN_Y3PART,
        message: t("error_net_sides", { ns: "tool" }),
      });
    }
    if (partitionX._1 % shapeObj.x_gap !== 0) {
      addError({
        errorType: netErrorTypes.GAP_X1_VALIDATION,
        message: t("error_net_gap_xy", { ns: "tool" }),
      });
      return;
    } else if (partitionY._1 % shapeObj.y_gap !== 0) {
      addError({
        errorType: netErrorTypes.GAP_Y1_VALIDATION,
        message: t("error_net_gap_xy", { ns: "tool" }),
      });
      return;
    }

    if (stageRef.current) {
      setInitiateImage(true);
      setIsStopped(false);
      setTimeout(() => {
        const previewImg = (stageRef.current as any).toDataURL({
          mimeType: "image/png",
          quality: 1,
          pixelRatio: 3,
        });
        let mediaPromise;
        if (edit) {
          mediaPromise = updateMedia(
            selectedTool.tool.preview_image.id,
            previewImg,
            `nets_preview_${Date.now()}.png`,
            "nets " + Date.now()
          );
        } else {
          mediaPromise = postMedia(
            previewImg,
            `nets_preview_${Date.now()}.png`,
            "nets " + Date.now()
          );
        }
        mediaPromise.then((res) => {
          if (res.data) {
            const standardExist = standardShapes.find((nItem) => {
              const stanWidthPartitions = calculate_partitions(
                nItem.x_gap,
                nItem.x_length,
                nItem.x_diameter.steel_diameter
              );
              const stanHeigthPartitions = calculate_partitions(
                nItem.y_gap,
                nItem.y_length,
                nItem.y_diameter.steel_diameter
              );
              return (
                nItem.x_length === shapeObj.x_length &&
                nItem.y_length === shapeObj.y_length &&
                nItem.x_diameter.steel_diameter ===
                  shapeObj.x_diameter.steel_diameter &&
                nItem.y_diameter.steel_diameter ===
                  shapeObj.y_diameter.steel_diameter &&
                nItem.x_gap === shapeObj.x_gap &&
                nItem.y_gap === shapeObj.y_gap &&
                stanWidthPartitions._1 === partitionX._1 &&
                stanWidthPartitions._2 === partitionX._2 &&
                stanWidthPartitions._3 === partitionX._3 &&
                stanHeigthPartitions._1 === partitionY._1 &&
                stanHeigthPartitions._2 === partitionY._2 &&
                stanHeigthPartitions._3 === partitionY._3
              );
            });

            let newNetShape = {
              ...shapeObj,
              x_2: parseInt(partitionX._2),
              x_3: parseInt(partitionX._3),
              y_2: parseInt(partitionY._2),
              y_3: parseInt(partitionY._3),
              tool_number: netNum,
              preview_image: res.data.id,
              x_diameter: shapeObj.x_diameter.id,
              y_diameter: shapeObj.y_diameter.id,
              standard_net: standardExist ? true : false,
            };

            let deleteKeys = ["created", "last_modified"];
            if (edit) {
              if (newNetShape.name === "") {
                newNetShape = {
                  ...newNetShape,
                  name: null,
                };
              }
              deleteKeys.forEach((k: string) => delete newNetShape[k]);
              dispatch(updateNet(selectedTool.id, newNetShape, orderItem)).then(
                () => {
                  setDefaultShape();
                  dispatch(fetchLastUsedNets());
                }
              );
            } else {
              newNetShape = {
                ...newNetShape,
                type: 1,
                reference_tool: shapeObj.id,
              };
              deleteKeys.push("id");
              deleteKeys.forEach((k: string) => delete newNetShape[k]);
              dispatch(
                createNet(
                  newNetShape,
                  orderItem,
                  () => {},
                  0,
                  order.order_id
                )
              ).then(() => {
                dispatch(setCurrentOrderNetsCount(currentOrderNetsCount + 1));
                dispatch(fetchLastUsedNets());
              });
            }
            setDefaultShape();

            setStanGaps(stanShapes[0].gaps);
            setStanDiams(stanShapes[0].dia);
            setFilterDiameter(stanShapes[0].dia[0]);
            setFilterGap(stanShapes[0].gaps[0]);
            setOrderItem({
              quantity: 0,
              production_notes: "",
              position_notes: "",
              row_number: 0,
            });
            setInitiateImage(false);

            setTimeout(() => setIsStopped(true), 3000);
            if (isAddMore === false || addMore === false || editToolId >= 0) {
              setTimeout(() => {
                handleClose();
              }, 3000);
            }
          }
        });
      }, 100);
    }
  }
  const getDiameter = (steelDiameterID: number) => {
    return steelDiameters[shapeObj.steel_type].find(
      (item: any) => item.id === steelDiameterID
    );
  };

  const setDefaultShape = async () => {
    setSelectedTool({ id: 0 });
    setSelectedShape(-1);
    setEditTool(false);
    setErrorsText([]);
    setShape({});
    let shapeInstance = {};
    setShapes(stanShapes);
    setStanGaps(stanShapes[0].gaps);
    setStanDiams(stanShapes[0].dia);

    const initialDiam = stanShapes[0].dia.find((el) => el.steel_diameter === 8);
    const initialGap = stanShapes[0].gaps.find((el) => el === 20);
    setFilterDiameter(initialDiam);
    setFilterGap(initialGap);
    shapeInstance = {
      ...standardShapes[0],
      standard_net: true,
      x_length: parseInt(stanShapes[0].lens.x),
      y_length: parseInt(stanShapes[0].lens.y),
      x_diameter: initialDiam,
      y_diameter: initialDiam,
      x_gap: parseInt(initialGap),
      y_gap: parseInt(initialGap),
      x_2: null,
      x_3: null,
      y_2: null,
      y_3: null,
    };
    setOrderItem({
      quantity: 0,
      production_notes: "",
      position_notes: "",
      row_number: 0,
    });
    await setInitialShape(shapeInstance);
    setShape({ ...shapeInstance });
  };

  return (
    <>
      {isBrowser && (
        <>
          <SideBar tab={tab} setTab={setTab} edit={edit}>
            <NetSideBar
              customizeView={customizeView}
              toggleUniformGap={toggleUniformGap}
              toggleUniformDiameter={toggleUniformDiameter}
              selectedShape={selectedShape}
              setSelectedShape={setSelectedShape}
              filterDiameter={filterDiameter}
              setFilterDiameter={setFilterDiameter}
              filterGap={filterGap}
              setFilterGap={setFilterGap}
              edit={edit}
              partitionX={partitionX}
              partitionY={partitionY}
              setPartitionX={setPartitionX}
              setPartitionY={setPartitionY}
              shapeObj={shapeObj}
              setShape={setShape}
              getDiameter={getDiameter}
              lastUsedShapes={lastUsedShapes}
              shapes={shapes}
              stanGaps={stanGaps}
              setStanGaps={setStanGaps}
              stanDiams={stanDiams}
              setStanDiams={setStanDiams}
              errorsText={errorsText}
              addError={addError}
              removeError={removeError}
              orderItem={orderItem}
              setOrderItem={setOrderItem}
              onX1Change={onX1Change}
              onX2Change={onX2Change}
              onX3Change={onX3Change}
              onY1Change={onY1Change}
              onY2Change={onY2Change}
              onY3Change={onY3Change}
              saveNet={saveNet}
              checkLengths={checkLengths}
              finishButtonRef={finishButtonRef}
              addButtonRef={addButtonRef}
              setIsShapesError={setIsShapesError}
              isShapesError={isShapesError}
              showStandard={showStandard}
              setShowStandard={setShowStandard}
              edgeChanged_X={edgeChanged_X}
              setEdgeChanged_X={setEdgeChanged_X}
              edgeChanged_Y={edgeChanged_Y}
              setEdgeChanged_Y={setEdgeChanged_Y}
              initiateImage={initiateImage}
            />
          </SideBar>
          {/* <Content
            customizeView={customizeView}
            partitionX={partitionX}
            partitionY={partitionY}
            setPartitionX={setPartitionX}
            setPartitionY={setPartitionY}
            shapeObj={shapeObj}
            setShape={setShape}
            labelingPoints={labelingPoints}
            setLabelingPoints={setLabelingPoints}
            resacleFactor={resacleFactor}
            setRescaleFactor={setRescaleFactor}
            saveNet={saveNet}
            orderItem={orderItem}
            setOrderItem={setOrderItem}
            getDiameter={getDiameter}
            stageRef={stageRef}
            setErrorText={setErrorText}
            errorText={errorText}
            validateVals={validateVals}
            validateMidVals={validateMidVals}
            onX1Change={onX1Change}
            onX2Change={onX2Change}
            onX3Change={onX3Change}
            onY1Change={onY1Change}
            onY2Change={onY2Change}
            onY3Change={onY3Change}
            setNetNumber={setNetNumber}
            edit={edit}
            setSelectedShape={setSelectedShape}
          /> */}
          <SketchBody>
            <NetShape
              isLoading={isLoading}
              shapeObj={shapeObj}
              setRescaleFactor={setRescaleFactor}
              partitionX={partitionX}
              setPartitionX={setPartitionX}
              partitionY={partitionY}
              setPartitionY={setPartitionY}
              labelingPoints={labelingPoints}
              setLabelingPoints={setLabelingPoints}
              setShape={setShape}
              stageRef={stageRef}
              customizeView={customizeView}
              setXUnits={setXUnits}
              setYUnits={setYUnits}
              errorsText={errorsText}
              addError={addError}
              removeError={removeError}
              validateVals={validateVals}
              validateMidVals={validateMidVals}
              onX1Change={onX1Change}
              onX2Change={onX2Change}
              onX3Change={onX3Change}
              onY1Change={onY1Change}
              onY2Change={onY2Change}
              onY3Change={onY3Change}
              checkLengths={checkLengths}
              showStandard={showStandard}
              setShowStandard={setShowStandard}
              edgeChanged_X={edgeChanged_X}
              setEdgeChanged_X={setEdgeChanged_X}
              edgeChanged_Y={edgeChanged_Y}
              setEdgeChanged_Y={setEdgeChanged_Y}
              initiateImage={initiateImage}
            />
          </SketchBody>
          <LastAddedSidebar
            dataLength={netsData.length}
            hasMore={hasMore}
            setHasMore={setHasMore}
            toolData={netsData}
            setToolData={setNetsData}
            checkRowNums={checkRowNums}
            editOrderId={editOrderId}
            editMode={editMode}
            saveTool={saveNet}
            shapeObj={shapeObj}
            edit={edit}
            finishButtonRef={finishButtonRef}
            addButtonRef={addButtonRef}
            isShapesError={isShapesError}
            setDefaultShape={setDefaultShape}
            handleClose={handleClose}
            editToolId={editToolId}
          >
            {/* Render last added rebars here */}
            <div
              className="card__list hoveredItems"
              style={editToolId >= 0 ? { marginTop: "40px" } : {}}
            >
              {netsData.map((item, index) => (
                <NetsCard
                  key={item.id}
                  id={item.id}
                  item={item.tool}
                  orderItem={item}
                  index={index}
                  deleteTool={deleteTool}
                  copyOrderItem={copyOrderItem}
                  setEditTool={setEditTool}
                  setSelectedTool={setSelectedTool}
                  setSelectedShape={setSelectedShape}
                  selectedShape={selectedShape}
                  setErrorsText={setErrorsText}
                  setInProgress={setInProgress}
                  inProgress={inProgress}
                  editToolId={editToolId}
                  edit={edit}
                />
              ))}
            </div>
          </LastAddedSidebar>
        </>
      )}
      {isMobile && (
        <NetMobileContent
          shapeObj={shapeObj}
          setRescaleFactor={setRescaleFactor}
          partitionX={partitionX}
          setPartitionX={setPartitionX}
          partitionY={partitionY}
          setPartitionY={setPartitionY}
          labelingPoints={labelingPoints}
          setLabelingPoints={setLabelingPoints}
          setShape={setShape}
          orderItem={orderItem}
          setOrderItem={setOrderItem}
          stageRef={stageRef}
          filterDiameter={filterDiameter}
          setFilterDiameter={setFilterDiameter}
          filterGap={filterGap}
          setFilterGap={setFilterGap}
          selectedShape={selectedShape}
          setSelectedShape={setSelectedShape}
          //standardShapes={filteredStandardShapes}
          getDiameter={getDiameter}
          saveNet={saveNet}
          netNumber={netNumber}
          setNetNumber={setNetNumber}
        />
      )}
    </>
  );
}

export default Net;
