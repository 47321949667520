import { useState } from "react";
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import close from "../../../assets/img/close.svg";
import A4Printer from "../../../assets/img/ticket-print-pops/a4-printer.svg";
import StickyPrinter from "../../../assets/img/ticket-print-pops/sticky-printer.svg";
import A4Ticket_En from "../../../assets/img/ticket-print-pops/a4-printer_en.png";
import A4Ticket_He from "../../../assets/img/ticket-print-pops/a4-printer_he.png";
import StickyTicket from "../../../assets/img/ticket-print-pops/sticky-ticket.svg";
import { PRINTER_TYPE } from "Constants/ticket-creator";
import "./printing-popup.scss";

function PrintPopUp({ ...props }) {
  const {
    onHide,
    setTicketPdfPreviewShow,
    setTicketLoader,
    setPrinterType,
    printerType,
  } = props;
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const { t } = useTranslation(["ticketCreator"]);

  

  const printTicketPdf = () => {
    onHide();
    setPrinterType(PRINTER_TYPE.A4Printer);
    setTicketPdfPreviewShow(true);
    setTicketLoader(true);
  };

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <section
          className="d-flex flex-column p-3 rounded custom__modal"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="pb-3">
            <img
              src={close}
              alt="close icon"
              style={{ cursor: "pointer" }}
              onClick={() => {
                onHide();
              }}
            />
          </div>
          <div className="custom__child">
            <div className="delete__title pb-md-4 pb-2">
              <p className="mb-0">
                {t("printer type", { ns: "ticketCreator" })}
              </p>
            </div>
            {rightToLeft ? (
              <>
                <div className="printer__icons">
                  <div className="printer" onClick={() => setPrinterType(1)}>
                    <img
                      src={A4Printer}
                      alt="a4 printer"
                      style={{ cursor: "pointer" }}
                    />
                    <p
                      className={
                        printerType === PRINTER_TYPE.A4Printer
                          ? "selected__text"
                          : "normal__text"
                      }
                    >
                      {t("A4 printer", { ns: "ticketCreator" })}
                    </p>
                  </div>
                  <div className="printer" onClick={() => setPrinterType(0)}>
                    <img
                      src={StickyPrinter}
                      alt="sticky printer"
                      style={{ cursor: "pointer" }}
                    />
                    <p
                      className={
                        printerType === PRINTER_TYPE.StickyPrinter
                          ? "selected__text"
                          : "normal__text"
                      }
                    >
                      {t("sticker printer", { ns: "ticketCreator" })}
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="printer__icons">
                  <div className="printer" onClick={() => setPrinterType(0)}>
                    <img
                      src={StickyPrinter}
                      alt="sticky printer"
                      style={{ cursor: "pointer" }}
                    />
                    <p
                      className={
                        printerType === PRINTER_TYPE.StickyPrinter
                          ? "selected__text"
                          : "normal__text"
                      }
                    >
                      {t("sticker printer", { ns: "ticketCreator" })}
                    </p>
                  </div>
                  <div className="printer" onClick={() => setPrinterType(1)}>
                    <img
                      src={A4Printer}
                      alt="a4 printer"
                      style={{ cursor: "pointer" }}
                    />
                    <p
                      className={
                        printerType === PRINTER_TYPE.A4Printer
                          ? "selected__text"
                          : "normal__text"
                      }
                    >
                      {t("A4 printer", { ns: "ticketCreator" })}
                    </p>
                  </div>
                </div>
              </>
            )}
            {printerType === PRINTER_TYPE.A4Printer ? (
              <div className={"hr-line_right"}></div>
            ) : (
              <div className={"hr-line_left"}></div>
            )}
            <div className="container_ticket">
              <div className="print__ticket">
                <div className={rightToLeft ? "ticket-ml" : "ticket-mr"}>
                  {printerType === PRINTER_TYPE.A4Printer ? (
                    <>
                      {rightToLeft ? (
                        <img
                          src={A4Ticket_En}
                          alt="a4 ticket_en"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        <img
                          src={A4Ticket_He}
                          alt="a4 ticket_he"
                          style={{ width: "100%" }}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <img
                        src={StickyTicket}
                        alt="sticy ticket"
                        className="blur-effect"
                        style={{ position: "relative" }}
                      />
                      <div
                        className="coming-soon"
                        style={rightToLeft ? { left: "22%" } : {}}
                      >
                        <p className={rightToLeft ? "p-rl" : "p-lr"}>
                          {t("coming soon", { ns: "ticketCreator" })}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="ticket__text">
                {printerType === PRINTER_TYPE.A4Printer ? (
                  <p>{t("a4 description", { ns: "ticketCreator" })}</p>
                ) : (
                  <>
                    <div className="d-flex flex-column blur-effect">
                      <p>
                        {t("sticker description 1", { ns: "ticketCreator" })}
                        <br />
                        {t("sticker description 2", { ns: "ticketCreator" })}
                      </p>
                      <p className="text-underlined">
                        {t("more details", { ns: "ticketCreator" })}
                      </p>
                    </div>
                  </>
                )}
              </div>
              <button
                className={
                  "print__btn text-center" +
                  (printerType === PRINTER_TYPE.StickyPrinter
                    ? " blur-effect"
                    : "")
                }
                onClick={printTicketPdf}
                disabled={printerType === PRINTER_TYPE.StickyPrinter}
              >
                {t("print", { ns: "ticketCreator" })}
              </button>
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
}

export default PrintPopUp;
