import { addToUserContacts, removeFromUserContacts, setUserContacts, updateToUserContacts } from "Redux/features/contact";
import { AppThunk } from 'Redux/store';
import { deleteContact, getContacts, patchContact, postContact } from "Services/contact";
import { isAxiosResponse } from "Services/index";

export const createContact = (contact: any, next: CallableFunction): AppThunk => async dispatch => {
  const response = await postContact(contact);
  if (isAxiosResponse(response)) {
    await dispatch(addToUserContacts(response.data));
    return next(true);
  }
}

export const fetchContacts = (): AppThunk => async dispatch => {
  const response = await getContacts();
  if (isAxiosResponse(response) && response.data.results.length > 0) {
    await dispatch(setUserContacts(response.data.results));
  }
}

export const updateContact = (id: number, contact: any, next: CallableFunction): AppThunk => async dispatch => {
  const response = await patchContact(id, contact);
  if (isAxiosResponse(response)) {
    await dispatch(updateToUserContacts(response.data));
    return next(true);
  }
}

export const removeContact = (id: number, next: CallableFunction): AppThunk => async dispatch => {
  const response = await deleteContact(id);
  if (isAxiosResponse(response)) {
    await dispatch(removeFromUserContacts(id));
    return next(true);
  }
}