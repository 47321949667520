import { createSlice } from '@reduxjs/toolkit'

export interface BusinessState {
  userBusinesses: any[];
}

const initialState: BusinessState = {
  userBusinesses: [],
}

export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    setUserBusinesses: (state: any, action) => {
      state.userBusinesses = action.payload;
    },
    addToUserBusinesses:  (state: any, action) => {
      state.userBusinesses = [action.payload, ...state.userBusinesses]
    },
    updateToUserBusinesses:  (state: any, action) => {
      state.userBusinesses = [
        action.payload,
        ...state.userBusinesses.filter((item: any) => item.id !== action.payload.id)
      ]
    },
    removeFromUserBusinesses: (state: any, action) => {
      state.userBusinesses = [...state.userBusinesses.filter((item: any) => item.id !== action.payload)]
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  addToUserBusinesses,
  setUserBusinesses,
  updateToUserBusinesses,
  removeFromUserBusinesses,
} = businessSlice.actions

export default businessSlice.reducer