import { useAppSelector } from "Redux/hooks";
import { commaSeparated, removeBgBorder } from "Constants/general";
import { useTranslation } from "react-i18next";

import gap_icon from "Assets/img/cages/gap.svg";
import diameter_icon from "Assets/img/cages/diameter.svg";
import extra_on from "Assets/img/cages/extra_on.svg";
import extra_off from "Assets/img/cages/extra_off.svg";
import cage_preview_icon from "Assets/img/cages/cage_preview_icon.svg";
import at_icon_1 from "Assets/img/cages/at_1.svg";
import at_icon_2 from "Assets/img/cages/at_2.svg";

import {
  pilesTicketsWithoutPrice,
  pilesTicketsWithPrice,
  lastTicketsPilesWithoutPrice,
  lastTicketsPilesWithPrice,
} from "Constants/pile-constants";

import TicketCounter from "Components/ticket-creator/ticket-counter";

const PilesOrderTable = ({
  priceShow,
  handleImagePreview,
  pileUnits,
  pileTons,
  selectedRowCount,
  setSelectedRowCount,
}: any) => {
  const { t } = useTranslation(["newOrder", "common", "measurements", "tool"]);
  const currentOrderPiles = useAppSelector(
    (state) => state.supplier.ticketOrderPiles
  );
  const steelTypes = useAppSelector((state) => state.steelType.steelTypes);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  return (
    <section id="nets__table" className="order__tables ">
      <div className="container p-0">
        <div className="table__wrapper">
          {/* <!-- table header  --> */}
          <div className="table__header d-flex flex-row">
            {/* <!-- table title  --> */}
            <div className="table__title d-flex align-items-center justify-content-center">
              <h2 className="text-center text-white text-uppercase m-0">
                {t("Cages", { ns: "newOrder" })}
              </h2>
            </div>
          </div>
          {/* <!-- real table  --> */}
          <table
            className={
              "table table mb-0 custom-tool-table " +
              (rightToLeft ? "rightToLeft" : "")
            }
          >
            {/* <!-- table head title section  --> */}
            <thead>
              <tr
                style={
                  priceShow ? pilesTicketsWithPrice : pilesTicketsWithoutPrice
                }
              >
                <th
                  scope="col"
                  className={
                    "text-capitalize d-flex justify-content-center align-items-center p-0 "
                  }
                >
                  <span style={{ textAlign: "center" }} className="th_norm">{t("tickets amount", { ns: "ticketCreator" })}</span>
                </th>
                <th
                  scope="col"
                  className={
                    "text-capitalize d-flex justify-content-center align-items-center p-0 " +
                    (rightToLeft ? "dark-border-right" : "dark-border-left")
                  }
                >
                  <span className="th_norm" >
                    {t("row", { ns: "common" })}
                  </span>
                </th>
                <th
                  scope="col"
                  className={
                    "text-capitalize d-flex justify-content-center align-items-center p-0 " +
                    (rightToLeft ? "dark-border-right" : "dark-border-left")
                  }
                >
                  <span className="th_norm">
                    {t("sketch", { ns: "newOrder" })}
                  </span>
                </th>
                <th
                  scope="col"
                  className={
                    "text-capitalize d-flex align-items-center justify-content-start text-center " +
                    (rightToLeft ? "dark-border-right" : "dark-border-left")
                  }
                >
                  <span className="th_norm">
                    {t("Name", { ns: "newOrder" })}
                  </span>
                </th>
                <th
                  scope="col"
                  className={
                    "text-capitalize d-flex align-items-center justify-content-around text-center sm__wrap p-0 " +
                    (rightToLeft
                      ? "dark-border-right pr-3"
                      : "dark-border-left pl-3")
                  }
                >
                  <span className="d-flex align-items-center justify-content-start span_50 th_norm ">
                    <p>{t("diameter", { ns: "common" })}</p>
                  </span>

                  <span className="d-flex align-items-center justify-content-start span_50 th_norm ">
                    <p>{t("length", { ns: "common" })}</p>
                  </span>
                </th>
                <th
                  scope="col"
                  className={
                    "text-capitalize d-flex align-items-center justify-content-start text-center sm__wrap p-0 " +
                    (rightToLeft
                      ? "dark-border-right pr-3"
                      : "dark-border-left pl-3")
                  }
                >
                  <span
                    style={{
                      width: "45%",
                      textAlign: rightToLeft ? "right" : "left",
                    }}
                  >
                    <img
                      src={at_icon_1}
                      style={{
                        width: "35px",
                        height: "24px",
                      }}
                      alt="@ 1"
                    />
                  </span>

                  <span
                    style={{
                      width: "45%",
                      textAlign: rightToLeft ? "right" : "left",
                    }}
                  >
                    <img
                      src={at_icon_2}
                      style={{
                        width: "35px",
                        height: "24px",
                      }}
                      alt="@ 2"
                    />
                  </span>

                  <span
                    style={{
                      width: "10%",
                      textAlign: rightToLeft ? "right" : "left",
                    }}
                    className="th_bold"
                  >
                    {t("edge", { ns: "common" })}
                  </span>
                </th>
                <th
                  scope="col"
                  className={
                    "text-capitalize d-flex align-items-center justify-content-center text-center p-0 " +
                    (rightToLeft ? "dark-border-right" : "dark-border-left")
                  }
                >
                  <span
                    className={
                      "d-flex justify-content-center align-item-center p-0 th_norm "
                    }
                  >
                    {t("extras", { ns: "common" })}
                  </span>
                </th>
                <th
                  scope="col"
                  className={
                    "text-capitalize d-flex align-items-center justify-content-center text-center th_norm p-0 " +
                    (rightToLeft ? "dark-border-right" : "dark-border-left")
                  }
                >
                  <span
                    className={
                      "d-flex justify-content-center align-item-center p-0 th_norm "
                    }
                  >
                    {t("quantity", { ns: "common" })}
                  </span>
                </th>
                <th
                  scope="col"
                  className="text-capitalize dark-border-right dark-border-left "
                  style={{ padding: "0px" }}
                >
                  <div className="two__rows_sec">
                    <div className="top__sec">
                      <span className="th_norm">
                        {t("weight", { ns: "common" })}
                      </span>
                    </div>
                    <div
                      className={
                        "d-flex justify-content-start align-items-center bottom_sec " +
                        (rightToLeft ? "pr-3" : "pl-3")
                      }
                    >
                      <span
                        className={"th_norm "}
                        style={{
                          width: "50%",
                          textAlign: rightToLeft ? "right" : "left",
                        }}
                      >
                        {t("unit", { ns: "common" })}
                      </span>
                      <span
                        className="th_norm"
                        style={{
                          width: "50%",
                          textAlign: rightToLeft ? "right" : "left",
                        }}
                      >
                        {t("total", { ns: "common" })}
                      </span>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            {/* <!-- table body data section  --> */}
            <tbody
              style={
                currentOrderPiles.length > 9
                  ? {
                      height: "720px",
                      overflow: "auto",
                      display: "block",
                      scrollbarWidth: "none",
                    }
                  : {
                      scrollbarWidth: "none",
                    }
              }
            >
              {/* <!-- table data row   --> */}
              {currentOrderPiles.length > 0 &&
                currentOrderPiles.map((itemCage, index) => (
                  <tr
                    key={"itemCage" + index}
                    style={
                      priceShow
                        ? { ...pilesTicketsWithPrice, position: "relative" }
                        : { ...pilesTicketsWithoutPrice, position: "relative" }
                    }
                  >
                    <td
                      style={{ padding: "0px" }}
                      className="d-flex justify-content-center"
                    >
                      <TicketCounter
                        setSelectedRowCount={setSelectedRowCount}
                        selectedRowCount={selectedRowCount}
                        itemId={itemCage.id}
                      />
                    </td>
                    <td
                      className={
                        rightToLeft ? "dark-border-right" : "dark-border-left"
                      }
                      style={{
                        padding: "0px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span className="table__para">{index + 1}</span>
                    </td>
                    <td
                      className={
                        "d-flex justify-content-center align-item-center p-0 " +
                        (rightToLeft ? "dark-border-right" : "dark-border-left")
                      }
                    >
                      <img
                        className="cage_sketch_preview"
                        src={cage_preview_icon}
                        alt="previewNet"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleImagePreview(itemCage.tool.preview_image)
                        }
                      />
                    </td>
                    <td
                      className={
                        "d-flex justify-content-center align-item-center p-0 " +
                        (rightToLeft ? "dark-border-right" : "dark-border-left")
                      }
                    >
                      <span
                        //className="td_norm"
                        style={
                          itemCage.tool.name
                            ? itemCage.tool.name.includes(" ")
                              ? {
                                  textAlign: "center",
                                }
                              : {
                                  wordBreak: "break-all",
                                  textAlign: "center",
                                }
                            : {}
                        }
                      >
                        {itemCage.tool.name}
                      </span>
                    </td>

                    <td
                      className={
                        rightToLeft
                          ? "dark-border-right pr-3"
                          : "dark-border-left pl-3"
                      }
                    >
                      <span className="d-flex justify-content-start align-item-center p-0 cage_diameter">
                        {itemCage.tool.cage_diameter}
                      </span>

                      <span className="d-flex justify-content-start align-item-center p-0 cage_length">
                        {itemCage.tool.l1_length +
                          itemCage.tool.l2_length +
                          itemCage.tool.l3_length}
                      </span>
                    </td>

                    <td
                      className={
                        rightToLeft
                          ? "dark-border-right pr-2 pl-0"
                          : "dark-border-left pl-2 pr-0"
                      }
                      style={
                        itemCage.production_notes
                          ? { paddingBottom: "16px" }
                          : {}
                      }
                    >
                      <span
                        className={
                          "d-flex justify-content-start align-item-center p-0 cage_len_1 "
                        }
                      >
                        <span
                          className={
                            "td_bold " + (rightToLeft ? "pl-1" : "pr-1")
                          }
                        >
                          {itemCage.tool.l3_length}
                        </span>

                        <span
                          className={
                            "td_norm " + (rightToLeft ? "pl-1" : "pr-1")
                          }
                        >
                          <img
                            className="img__icon"
                            src={gap_icon}
                            alt="previewNet"
                            style={{ width: "24px", height: "24px" }}
                          />
                          {itemCage.tool.l3_gap}
                        </span>

                        <span
                          className={
                            "td_norm " + (rightToLeft ? "pl-1" : "pr-1")
                          }
                        >
                          <img
                            className="img__icon"
                            src={diameter_icon}
                            alt="previewNet"
                            style={{ width: "24px", height: "24px" }}
                          />
                          {itemCage.tool.base_diameter.steel_diameter}
                        </span>
                      </span>

                      <span className="d-flex justify-content-start align-item-center p-0 cage_len_2">
                        <span
                          className={
                            "td_bold " + (rightToLeft ? "pl-1" : "pr-1")
                          }
                        >
                          {itemCage.tool.l2_length}
                        </span>

                        <span
                          className={
                            "td_norm " + (rightToLeft ? "pl-1" : "pr-1")
                          }
                        >
                          <img
                            className="img__icon"
                            src={gap_icon}
                            alt="previewNet"
                            style={{ width: "24px", height: "24px" }}
                          />
                          {itemCage.tool.l2_gap}
                        </span>

                        <span
                          className={
                            "td_norm " + (rightToLeft ? "pl-1" : "pr-1")
                          }
                        >
                          <img
                            className="img__icon"
                            src={diameter_icon}
                            alt="previewNet"
                            style={{ width: "24px", height: "24px" }}
                          />
                          {itemCage.tool.base_diameter.steel_diameter}
                        </span>
                      </span>

                      <span className="d-flex justify-content-start align-item-center p-0 cage_edge">
                        <span className="td_bold">
                          {itemCage.tool.l1_length}
                        </span>
                      </span>
                    </td>

                    <td
                      className={
                        "d-flex justify-content-center align-items-center p-0 " +
                        (rightToLeft ? "dark-border-right" : "dark-border-left")
                      }
                    >
                      {itemCage.tool.ring_diameter === null &&
                      itemCage.tool.gama_diameter === null &&
                      itemCage.tool.ear_type === null ? (
                        <span
                          className={
                            "d-flex justify-content-center align-items-center extra_img_icon"
                          }
                        >
                          <img
                            className="extra_img_icon"
                            src={extra_off}
                            alt="previewNet"
                          />
                        </span>
                      ) : (
                        <span
                          className={
                            "d-flex justify-content-center align-items-center extra_img_icon"
                          }
                        >
                          <img
                            className="extra_img_icon"
                            src={extra_on}
                            alt="previewNet"
                          />
                        </span>
                      )}
                    </td>
                    <td
                      className={
                        "d-flex justify-content-start " +
                        (rightToLeft
                          ? "dark-border-right pr-3"
                          : "dark-border-left pl-3")
                      }
                    >
                      <span className="td_norm">{itemCage.quantity}</span>
                    </td>

                    <td
                      className={
                        "d-flex justify-content-start dark-border-right dark-border-left " +
                        (rightToLeft ? "pr-3" : "pl-3")
                      }
                    >
                      <span className="td_norm" style={{ width: "50%" }}>
                        {itemCage.tool.unit_weight
                          .toString()
                          .replace(commaSeparated, ",")}
                      </span>
                      <span className="td_norm">
                        {(itemCage.tool.unit_weight * itemCage.quantity)
                          .toFixed(2)
                          .toString()
                          .replace(commaSeparated, ",")}
                      </span>
                    </td>
                    {itemCage.production_notes && (
                      <td
                        colSpan={1}
                        className="light-border-top note-td"
                        style={{
                          padding: "0px",
                          position: "absolute",
                          marginTop: "56px",
                          alignItems: "normal",
                          justifyContent: "flex-start",
                          width: priceShow ? "68%" : "32.9%",
                          ...(rightToLeft
                            ? {
                                right: priceShow
                                  ? "250px"
                                  : "calc(100% - 59.9%)",
                                textAlign: "right",
                              }
                            : {
                                left: priceShow
                                  ? "250px"
                                  : "calc(100% - 59.9%)",
                              }),
                        }}
                      >
                        <p className="mx-3" style={{ fontSize: "15px" }}>
                          {t("note", { ns: "common" })}:{" "}
                          {itemCage.production_notes}
                        </p>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
            <tbody className="last">
              <tr
                style={
                  priceShow
                    ? lastTicketsPilesWithPrice
                    : lastTicketsPilesWithoutPrice
                }
              >
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={removeBgBorder}></td>
                <td className="justify-content-center p-0">{pileUnits}</td>
                <td className="justify-content-center p-0" colSpan={2}>
                  {pileTons}
                </td>
              </tr>
              <tr
                style={
                  priceShow
                    ? lastTicketsPilesWithPrice
                    : lastTicketsPilesWithoutPrice
                }
              >
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={removeBgBorder}></td>
                <td className="justify-content-center p-0">
                  {t("units", { ns: "common" })}
                </td>
                <td
                  className="justify-content-center p-0"
                  colSpan={2}
                  style={{ textTransform: "capitalize" }}
                >
                  {t("weight", { ns: "common" })} (
                  {t("ton", { ns: "measurements" })})
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default PilesOrderTable;
