class StoreInstance {

  // eslint-disable-next-line
  private instance: string;
  
  constructor(instanceName: string) {
    this.instance = instanceName;
    if (localStorage.getItem(instanceName) === null) {
      localStorage.setItem(instanceName, JSON.stringify([]));
    }
  }

  getItems() {
    return JSON.parse(localStorage.getItem(this.instance) || '[]');
  }

  setItem(item: any) {
    const items = this.getItems();
    items.push(item);
    this.clear();
    return localStorage.setItem(this.instance, JSON.stringify(items));
  }

  clear() {
    localStorage.removeItem(this.instance);
  }
}

export const rebarStore = new StoreInstance('rebar');
export const netStore = new StoreInstance('net');
export const pileStore = new StoreInstance('pile');
