import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface NetState {
  currentOrderNets: any[];
  currentOrderPdfNets: any[];
  defaultNets: any[];
  defaultShapes: any[];
  defaultDiameters: number[];
  defaultGaps: number[];
  lastUsedNets: any[];
  orderNetPage: string;
  currentOrderNetsCount: any;
}

const initialState: NetState = {
  currentOrderNets: [],
  currentOrderPdfNets: [],
  defaultNets: [],
  defaultShapes: [],
  defaultDiameters: [],
  defaultGaps: [],
  lastUsedNets: [],
  orderNetPage: '1',
  currentOrderNetsCount: 0,
};

export const netSlice = createSlice({
  name: "net",
  initialState,
  reducers: {
    addNetToCurrentOrder: (state, action: PayloadAction<any>) => {
      const updatedNets = [...state.currentOrderNets, action.payload];
      updatedNets.sort((a, b) => a.row_number - b.row_number);
      return {
        ...state,
        currentOrderNets: updatedNets
      };
    },
    setDefaultNets: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        defaultNets: action.payload,
      };
    },
    setDefaultShapes: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        defaultShapes: action.payload,
      };
    },
    setDefaultDiameters: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        defaultDiameters: action.payload,
      };
    },
    setDefaultGaps: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        defaultGaps: action.payload,
      };
    },
    setCurrentOrderNets: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        currentOrderNets: action.payload,
      };
    },
    setCurrentOrderNetPage: (state, action: PayloadAction<any>) => {
      state.orderNetPage = action.payload;
    },
    setCurrentOrderPdfNets: (state, action: PayloadAction<any>) => {
      const sortedNets = action.payload;
      sortedNets.sort((a, b) => a.row_number - b.row_number);
      return {
        ...state,
        currentOrderPdfNets: sortedNets,
      };
    },
    setCurrentOrderNetsCount: (state, action: PayloadAction<any>) => {
      state.currentOrderNetsCount = action.payload;
    },
    addNextPageNetsToCurrentOrder: (state, action: PayloadAction<any>) => {
      const uniqueRowNumbers = new Set(state.currentOrderNets.map(net => net.row_number));
      const uniqueNets = action.payload.filter(net => !uniqueRowNumbers.has(net.row_number));
    
      return {
        ...state,
        currentOrderNets: [...state.currentOrderNets, ...uniqueNets],
      };
    },
    setLastUsedNets: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        lastUsedNets: action.payload,
      };
    },
    removeLastUsedNets: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        lastUsedNets: [],
      };
    },
    removeCurrentOrderNets: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        currentOrderNets: [
          ...state.currentOrderNets.filter(
            (item) => item.id !== action.payload
          ),
        ],
      };
    },
    removeCurrentOrderNet: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        currentOrderNetsCount: 0,
        currentOrderNets: [],
      };
    },
    updateCurrentOrderNet: (state, action: PayloadAction<any>) => {
      const current = [...state.currentOrderNets];
      const inPlaceIndex = current.map((x) => x.id).indexOf(action.payload.id);
      current[inPlaceIndex] = action.payload;
      state.currentOrderNets = current;
    },
    updatePageNets(state, action: PayloadAction<any>) {
      const uniqueNets = action.payload.filter((net: any) => {
        return !state.currentOrderNets.some(
          (existingNet: any) => existingNet.id === net.id
        );
      });
      return {
        ...state,
        currentOrderNets: [...state.currentOrderNets, ...uniqueNets],
      };
    },
  },   
});

// Action creators are generated for each case reducer function
export const {
  addNetToCurrentOrder,
  setDefaultNets,
  setDefaultShapes,
  setDefaultDiameters,
  setDefaultGaps,
  setCurrentOrderNets,
  setCurrentOrderNetPage,
  setCurrentOrderPdfNets,
  setLastUsedNets,
  removeLastUsedNets,
  removeCurrentOrderNets,
  removeCurrentOrderNet,
  updateCurrentOrderNet,
  addNextPageNetsToCurrentOrder,
  setCurrentOrderNetsCount,
  updatePageNets,
} = netSlice.actions;

export default netSlice.reducer