import React, { useMemo, useRef, useState, useEffect } from "react";
import plus from "../../../assets/img/new__user/plus.png";
import error from "../../../assets/img/icon/error.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { Overlay, Tooltip } from "react-bootstrap";
import RebarsTable from "./rebars";
import NetsTable from "./nets";
import PilesTable from "./piles";
import { useTranslation } from "react-i18next";
import { commaSeparated } from "Constants/general";
import ManageOrder from './../save-preview-delete';
import { setRowNumbers } from "Redux/middlwares/order";
import { getCurrentOrderRebars, getCurrentOrderNets } from "Redux/middlwares/order";

import { NET_SUBCLASS_TYPE } from "Constants/net-constants";
import { REBAR_SUBCLASS_TYPE } from "Constants/rebar-constants";
import { PILE_SUBCLASS_TYPE } from "Constants/pile-constants";
import { setCurrentOrderRebars } from "Redux/features/rebar";
import { setCurrentOrderNets } from "Redux/features/net";
import { setCurrentOrderPiles } from "Redux/features/pile";

function OrderWeb(props: any) {
  const {
    order,
    setOrder,
    saveOrder,
    handleImagePreview,
    selectedTool,
    setSelectedTool,
    openDeleteModal,
    copyOrderItem,
    showTooltip,
    coping,
    tooltipText,
    handleUpdate,
    showTooltipUpd,
    handleDownload,
    priceShow,
    openPriceListModal,
    errorText,
    setErrorText,
    setDeleteMessageShow,
    setSaveOrderMessageShow,
    setSaveBeforeShareShow,
    openPdfPreview,
    setDownloadFlag,
    activeProject,
    activeBusiness,
    setPrintCanvas,
    edit,
    editOrderId,
    handleBidClicked,
    setOrderSaved,
    scrollToElement,
  } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "newOrder"]);
  const target = useRef(null);
  const targetDel = useRef(null);
  const targetUpd = useRef(null);
  const [showTooltipDelete, setShowTooltipDelete] = useState(false);
  const user = useAppSelector((state) => state.user.userData);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const currentOrderRebars = useAppSelector(
    (state) => state.rebar.currentOrderRebars
  );
  const currentOrderNets = useAppSelector(
    (state) => state.net.currentOrderNets
  );
  const currentOrderPiles = useAppSelector(
    (state) => state.pile.currentOrderPiles
  );

  const [rebarData, setRebarData] = useState([]);
  const [netsData, setNetsData] = useState([]);
  const [pilesData, setPilesData] = useState([]);

  const totalToolUnits = useMemo(
    () => {
      let total = 0;
      
      if (currentOrderRebars.length > 0 )
        total += currentOrderRebars.reduce((pre, curr) => pre + curr.quantity, 0);

      // if (currentOrderNets.length > 0 )
      //   total += currentOrderNets.reduce((pre, curr) => pre + curr.quantity, 0);

      // if (currentOrderPiles.length > 0 )
      //   total += currentOrderPiles.reduce((pre, curr) => pre + curr.quantity, 0);

      return total.toString().replace(commaSeparated, ",");
    },
    [
      currentOrderRebars,
      // currentOrderNets,
      // currentOrderPiles
    ]
  )

  const rebarPrice = useMemo(
    () =>
      currentOrderRebars.length > 0
        ? currentOrderRebars
            .reduce(
              (pre, curr) =>
                pre +
                parseFloat(curr.unit_price ? curr.unit_price : 0) *
                  curr.quantity,
              0
            )
            .toFixed(2)
            .toString()
            .replace(commaSeparated, ",")
        : "0",
    [currentOrderRebars]
  );

  const netPrice = useMemo(
    () =>
      currentOrderNets.length > 0
        ? currentOrderNets
            .reduce(
              (pre, curr) =>
                pre +
                parseFloat(curr.unit_price ? curr.unit_price : 0) *
                  curr.quantity,
              0
            )
            .toFixed(2)
            .toString()
            .replace(commaSeparated, ",")
        : "0",
    [currentOrderNets]
  );

  const pileUnits = useMemo(
    () =>
      currentOrderPiles.length > 0
        ? currentOrderPiles
            .reduce((pre, curr) => pre + curr.quantity, 0)
            .toString()
            .replace(commaSeparated, ",")
        : 0,
    [currentOrderPiles]
  );

  const pileTons = useMemo(
    () =>
      currentOrderPiles.length > 0
        ? (
            currentOrderPiles.reduce(
              (pre, curr) => pre + curr.tool.unit_weight * curr.quantity,
              0
            ) / 1000
          )
            .toFixed(2)
            .toString()
            .replace(commaSeparated, ",")
        : "0",
    [currentOrderPiles]
  );

  const pilePrice = useMemo(
    () =>
      currentOrderPiles.length > 0
        ? currentOrderPiles
            .reduce(
              (pre, curr) =>
                pre +
                parseFloat(curr.unit_price ? curr.unit_price : 0) *
                  curr.quantity,
              0
            )
            .toFixed(2)
            .toString()
            .replace(commaSeparated, ",")
        : "0",
    [currentOrderPiles]
  );

  const checkRowNums = async(oldIndex: number, newIndex: number, data: any, subClassType: string) => {
    let currentData;
    if (oldIndex < newIndex) {
      currentData = data.slice(oldIndex, newIndex + 1);
    } else {
      currentData = data.slice(newIndex, oldIndex + 1);
    }

    const pos_IdObj = currentData.reduce((acc,val) => {
      const Index = data.indexOf(val);
      acc[val.id] = Index + 1;
      return acc;
    },{})

    let rowNums = {};
    if(NET_SUBCLASS_TYPE === subClassType){
      rowNums = {
        net: pos_IdObj,
      };
    } else if (REBAR_SUBCLASS_TYPE === subClassType) {
      rowNums = {
        rebar: pos_IdObj,
      };
    }
    else if (PILE_SUBCLASS_TYPE === subClassType) {
      rowNums = {
        pile: pos_IdObj,
      };
    }
    dispatch(setRowNumbers(rowNums)).then(() => {
      if(NET_SUBCLASS_TYPE === subClassType){
        const updatedOrderNets = currentOrderNets.map((element) => {
          if (pos_IdObj[element.id] !== undefined) {
            return {
              ...element,
              row_number: pos_IdObj[element.id]
            };
          } else {
            return element;
          }
        });
        updatedOrderNets.sort((a, b) => a.row_number - b.row_number);
        dispatch(setCurrentOrderNets(updatedOrderNets));
      }

      else if(REBAR_SUBCLASS_TYPE === subClassType){
        const updatedOrderRebars = currentOrderRebars.map((element) => {
          if (pos_IdObj[element.id] !== undefined) {
            return {
              ...element,
              row_number: pos_IdObj[element.id]
            };
          } else {
            return element;
          }
        });
        updatedOrderRebars.sort((a, b) => a.row_number - b.row_number);
        dispatch(setCurrentOrderRebars(updatedOrderRebars));
      }

      
      else if(PILE_SUBCLASS_TYPE === subClassType){
        const updatedOrderPiles = currentOrderPiles.map((element) => {
          if (pos_IdObj[element.id] !== undefined) {
            return {
              ...element,
              row_number: pos_IdObj[element.id]
            };
          } else {
            return element;
          }
        });
        updatedOrderPiles.sort((a, b) => a.row_number - b.row_number);
        dispatch(setCurrentOrderPiles(updatedOrderPiles));
      }

    });
  };

  const deleteTool = () => {
    if (selectedTool.id) {
      openDeleteModal();
    } else {
      setShowTooltipDelete(true);
      setTimeout(() => setShowTooltipDelete(false), 800);
    }
  };

  return (
    <>
      {/* {/* <!------------------------ add new item section ------------------------ --> */}
      {/* <section id="add__new__item" className="order__page__new__item__D">
        <div className="container px-xl-0 d-flex align-items-center">
          <div className={"add d-lg-flex " +  (rightToLeft ? 'ml-5' : 'mr-5')}>
            <button
              className="btn d-flex align-items-center"
              onClick={props.onItemModalOpen}
            >
              <img className="plus__icon" src={plus} alt="plus icon" />
              <h1
                className={
                  "text-uppercase " + (rightToLeft ? "mr-lg-4" : "ml-lg-4")
                }
              >
                {t("Add New Item", { ns: "newOrder" })}
              </h1>
            </button>
          </div>
          <div
            className="control__wrap d-flex align-items-center"
            // style={
            //   user === null ? { pointerEvents: "none", opacity: "0.6" } : {}
            // }
          >
          <span className="cus__btn para d-inline-block mr-2">
            <FontAwesomeIcon icon="paperclip"></FontAwesomeIcon>
            <span className="ml-2">Mark lines and group</span>
          </span>
            <button
              ref={target}
              className="cus__r__btn para d-inline-block mr-2"
              onClick={copyOrderItem}
              disabled={coping}
            >
              <FontAwesomeIcon icon="copy"></FontAwesomeIcon>
            </button>
            <Overlay
              target={target.current}
              show={showTooltip}
              placement="bottom"
            >
              {(props) => (
                <Tooltip id="overlay-example" {...props}>
                  {tooltipText}
                </Tooltip>
              )}
            </Overlay>
            <span
              ref={targetUpd}
              onClick={handleUpdate}
              className="cus__r__btn para d-inline-block mr-2"
            >
              <FontAwesomeIcon icon="pencil-alt"></FontAwesomeIcon>
            </span>
            <Overlay
              target={targetUpd.current}
              show={showTooltipUpd}
              placement="bottom"
            >
              {(props) => (
                <Tooltip id="overlay-example-1" {...props}>
                  Select Tool to update
                </Tooltip>
              )}
            </Overlay>
            <span
              ref={targetDel}
              onClick={deleteTool}
              className="cus__r__btn para d-inline-block mr-2"
            >
              <FontAwesomeIcon icon="trash"></FontAwesomeIcon>
            </span>
            <Overlay
              target={targetDel.current}
              show={showTooltipDelete}
              placement="bottom"
            >
              {(props) => (
                <Tooltip id="overlay-example-2" {...props}>
                  Select Tool for deletion
                </Tooltip>
              )}
            </Overlay>
          </div>
        </div>
      </section> */}
      {/* <!-- ------------------------ rebars table section --------------------------  --> */}
      <RebarsTable
        priceShow={priceShow}
        openItemModal={props.openItemModal}
        selectedTool={selectedTool}
        setSelectedTool={setSelectedTool}
        handleImagePreview={handleImagePreview}
        rebarPrice={rebarPrice}
        openPriceListModal={openPriceListModal}
        copyOrderItem={copyOrderItem}
        handleUpdate={handleUpdate}
        deleteTool={deleteTool}
        setRebarData={setRebarData}
        rebarData={rebarData}
        checkRowNums={checkRowNums}
        edit={edit}
        editOrderId={editOrderId}
      />
      {/* <!-- ------------------------ nets table section --------------------------  --> */}
      <NetsTable
        priceShow={priceShow}
        openItemModal={props.openItemModal}
        selectedTool={selectedTool}
        setSelectedTool={setSelectedTool}
        handleImagePreview={handleImagePreview}
        netPrice={netPrice}
        copyOrderItem={copyOrderItem}
        handleUpdate={handleUpdate}
        deleteTool={deleteTool}
        setNetsData={setNetsData}
        netsData={netsData}
        checkRowNums={checkRowNums}
        edit={edit}
        editOrderId={editOrderId}
      />
      {/* {/* <!-- ------------------------ cages table section --------------------------  --> */}
      <PilesTable
        priceShow={priceShow}
        openItemModal={props.openItemModal}
        selectedTool={selectedTool}
        setSelectedTool={setSelectedTool}
        handleImagePreview={handleImagePreview}
        copyOrderItem={copyOrderItem}
        handleUpdate={handleUpdate}
        deleteTool={deleteTool}
        pilesData = {pilesData}
        setPilesData = {setPilesData}
        checkRowNums={checkRowNums}
        edit={edit}
        editOrderId={editOrderId}
        pileUnits={pileUnits}
        pileTons={pileTons}
        pilePrice={pilePrice}
        scrollToElement={scrollToElement}
      />
      {/* <!-- --------------------------- order summary -----------------------------------  --> */}
      {/* <section id="order__summary" className="pb-5 mt-4">
        <div className="container">
           <div className="row m-0 w-100">
            <div className={"col-6 " + (rightToLeft ? "offset-1" : "")}>
              <h3 className="text-uppercase descrip__title mb-0">
                {t("Order Notes", { ns: "newOrder" })}
              </h3>
              <textarea
                placeholder={t("Typing Note", { ns: "newOrder" })}
                className="bottom__sec bg-white rounded text-dark-500 p-4"
                name=""
                id=""
                cols={30}
                rows={10}
                value={order.order_notes}
                onChange={({ target }) =>
                  setOrder({ ...order, order_notes: target.value })
                }
              ></textarea>
            </div>
            <div
              className={
                "col-5 d-flex flex-column order__summery__container " +
                (rightToLeft ? "" : "offset-1")
              }
            >
              <div className="table__wrapper">
                <div
                  className="table__header d-flex flex-row"
                  style={{ width: "400px" }}
                >
                  <div className="table__title d-flex align-items-center justify-content-center">
                    <h2 className="text-center text-white text-uppercase m-0">
                      {t("Order Summary", { ns: "newOrder" })}
                    </h2>
                  </div>
                </div>
                <table
                  className={
                    "table order-summary-table" + (priceShow ? " price" : "")
                  }
                >
                  <tbody>
                    <tr>
                      <td>{t("Rebars", { ns: "newOrder" })}</td>
                      <td>{rebarUnits}</td>
                      <td>{rebarTons}</td>
                      {priceShow && <td>{rebarPrice}</td>}
                    </tr>
                    <tr>
                      <td>{t("Nets", { ns: "newOrder" })}</td>
                      <td>{netUnits}</td>
                      <td>{netTons}</td>
                      {priceShow && <td>{netPrice}</td>}
                    </tr>
                    <tr>
                      <td>{t("Cages", { ns: "newOrder" })}</td>
                      <td>{pileUnits}</td>
                      <td>{pileTons}</td>
                      {priceShow && <td>{pilePrice}</td>}
                    </tr>
                    <tr>
                      <td className="black-border-right">
                        {t("Total", { ns: "newOrder" })}
                      </td>
                      <td className="black-border-right">{totalToolUnits}</td>
                      <td>
                        {parseFloat(rebarTons)
                          // + parseFloat(netTons)
                          // + parseFloat(pileTons)
                          .toFixed(2)}
                      </td>
                      {priceShow && (
                        <td>
                          {(
                            parseFloat(rebarPrice) +
                            parseFloat(netPrice) +
                            parseFloat(pilePrice)
                          ).toFixed(2)}
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td></td>
                      <td>{t("Units", { ns: "newOrder" })}</td>
                      <td>
                        {t("Weight", { ns: "newOrder" })} (
                        {t("Ton", { ns: "newOrder" })})
                      </td>
                      {priceShow && <td>{t("Price")} (NIS)</td>}
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="btn__list w-lg-55 d-flex justify-content-around align-items-center mt-lg-4 w-xl-100">
                <button
                  className="btn text-uppercase text-center text-white"
                  type="button"
                  data-toggle="collapse"
                  data-target="#moreOptCollapse"
                  aria-expanded="false"
                  aria-controls="moreOptCollapse"
                  style={{ display: "none" }} //added to hide
                >
                  ...
                </button>
                <button
                  disabled={user === null}
                  onClick={saveOrder}
                  className="btn text-uppercase text-center text-white"
                >
                  {t("Save")}
                </button>
                <button
                  disabled={user === null}
                  onClick={props.openPdfPreview}
                  className="btn text-uppercase text-center text-white"
                >
                  {t("preview", { ns: "newOrder" })}
                </button>
                <button className="btn text-uppercase text-center text-white"
                style={{ backgroundColor: '#374051'}}>
                  {t("download", {ns: 'common'})}
                </button>
                <button className="btn text-uppercase text-center text-white">
                  {t("Delete")}
                </button>
              </div>
              <div className="collapse" id="moreOptCollapse">
                <ul className="d-flex">
                  <li onClick={handleDownload}>
                    {t("download", { ns: "newOrder" })}
                  </li>
                  <li>{t("email", { ns: "newOrder" })}</li>
                </ul>
              </div>
              {errorText && (
                <div className="d-flex my-2">
                  <img
                    className="ml-3"
                    src={error}
                    alt={"error"}
                    style={{ height: "22px" }}
                  />
                  <p className="ml-2" style={{ color: "#f84242" }}>
                    {errorText}
                  </p>
                </div>
              )}
              <div className="ask__for__bid w-lg-35 w-xl-100 mt-5 bg-white">
                <button
                  className="btn custom_collapse_btn text-uppercase w-xs-100"
                  type="button"
                  data-toggle="collapse"
                  data-target="#askCollapse"
                  aria-expanded="false"
                  aria-controls="askCollapse"
                >
                  {t("Ask for Bid", { ns: "newOrder" })}
                </button>
                <div className="collapse" id="askCollapse">
                  <p className="mark__all">Mark all</p>
                  <ul>
                    <li>
                      <input type="checkbox" className="form-checkbox" />
                      <h6 className="text-capitalize">hod assaf</h6>
                    </li>
                    <li>
                      <input type="checkbox" className="form-checkbox" />
                      <h6 className="text-capitalize">yehuda group</h6>
                    </li>
                    <li>
                      <input type="checkbox" className="form-checkbox" />
                      <h6 className="text-capitalize">peker</h6>
                    </li>
                    <li>
                      <input type="checkbox" className="form-checkbox" />
                      <h6 className="text-capitalize">s. cohen</h6>
                    </li>
                    <li>
                      <input type="checkbox" className="form-checkbox" />
                      <h6 className="text-capitalize">hod assaf</h6>
                    </li>
                    <li>
                      <input type="checkbox" className="form-checkbox" />
                      <h6 className="text-capitalize">yehuda group</h6>
                    </li>
                    <li>
                      <input type="checkbox" className="form-checkbox" />
                      <h6 className="text-capitalize">peker</h6>
                    </li>
                    <li>
                      <input type="checkbox" className="form-checkbox" />
                      <h6 className="text-capitalize">s. cohen</h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> 
        </div>
      </section>*/}
      <ManageOrder
        setDeleteMessageShow={setDeleteMessageShow}
        setSaveOrderMessageShow={setSaveOrderMessageShow}
        setSaveBeforeShareShow={setSaveBeforeShareShow}
        setDownloadFlag={setDownloadFlag}
        errorText={errorText}
        setErrorText={setErrorText}
        openPdfPreview={openPdfPreview}
        order={order}
        saveOrder={saveOrder}
        activeProject={activeProject}
        activeBusiness={activeBusiness}
        setPrintCanvas={setPrintCanvas}
        edit={edit}
        editOrderId={editOrderId}
        handleBidClicked={handleBidClicked}
        setOrderSaved={setOrderSaved}
      />
    </>
  );
}

export default OrderWeb;
