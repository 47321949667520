import Request from "./index";

export const getSuppliers = (page: string) =>
  Request.get(`supplier/?cursor=${page}`);

export const fetchTicketOrderDetails = (orderNum: number) =>
  Request.get(
    `supplier/order/?order_no=${orderNum}`
  );

// post bid Request
export const postBidRequest = (data: Object) => 
    Request.post('supplier/bid-request/',data)

export const addSupplier = (data: any) =>
Request.post(
  `supplier/create/`, data
);

export const getSupplierStatus = () =>{
  return Request.get(
    `supplier/account/`)
}

export const setWelcomePopupTrue = () =>{
  return Request.post(
    `supplier/set-welcome-popup/`, {})
}

export const printingCount = () => {
  return Request.post(`supplier/print-count/`);
}

// get the recent emails where user shared orders
export const getRecentSupplierEmails = (params: any) => 
  Request.get(`emails-directory/?email=${params}`);

export const fetchSupplierPriceList = (name: any) =>
  Request.get(`supplier/price-list/?supplier_name=${name}`);

export const fetchPaginatedPriceList = (cursor: string) =>
  Request.get(`supplier/price-list/?cursor=${cursor}`);

export const addSupplierPriceList = (data: any) =>
  Request.post('supplier/price-list/', data);

export const patchSupplierPriceList = (id: number, data: any) =>
  Request.patch(`supplier/price-list/${id}/`, data);

export const removeSupplierPriceList = (id: number) =>
  Request.remove(`supplier/price-list/${id}/`);
  
export const calculatedOrderPricing = (orderNum: string, priceListId: number) =>
Request.get(`order/${orderNum}/price/${priceListId}/`);