import React,{ useState } from 'react';
import Footer from '../components/common-components/footer';
import Header from '../components/common-components/header';
import LogoutModal from "./../components/common-components/logout-warning";
import "./customer.scss";

export interface Props {
  children: React.ReactNode;
}

const CustomerLayout: React.FC<Props> = ({children}) => {
  const [ checkLogout, setCheckLogout ] = useState(false);
  
  return (
    <div className="customer-layout">
      <Header setCheckLogout={setCheckLogout}></Header>
        <LogoutModal
          show={checkLogout}
          onHide={() => setCheckLogout(false)}
          dialogClassName={"modal_width"}
        />
      <div className="content">{children}</div>
      <Footer></Footer>
    </div>
  );
}

export default CustomerLayout;