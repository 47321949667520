import React, { useState, useMemo } from "react";
import emtpySet from "Assets/img/icon/empty-set-mathematical-symbol.png";
import email from "Assets/img/icon/email.png";
import cageCircle from "Assets/img/icon/cage-circle.svg";
import cageLength from "Assets/img/icon/cage-length.svg";

import cageCircleGrey from "Assets/img/icon/cage-circle-grey.svg";
import cageLengthGrey from "Assets/img/icon/cage-length-grey.svg";
import emailGrey from "Assets/img/icon/email-grey.svg";
import emptySetGrey from "Assets/img/icon/empty-set-grey.svg";

import deleteIcon from "Assets/img/blocks/delete.svg";
import duplicateIcon from "Assets/img/blocks/duplicate.svg";

import { useAppSelector } from "Redux/hooks";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import "../index.styled.scss";

const PilesCard = (props) => {
  const {
    item,
    index,
    deleteTool,
    orderItem,
    copyOrderItem,
    setEditTool,
    setSelectedTool,
    setSelectedShape,
    selectedShape,
    setErrorsText,
    setInProgress,
    inProgress,
    editToolId,
    edit,
  } = props;

  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  const [isHovered, setIsHovered] = useState(0);
  const [hoveredIcon, setHoveredIcon] = useState(false);

  const editModeChecks = useMemo(() => {
    //returns true for the cards opened from the table / progress bar in edit more
    if ((editToolId === orderItem.id && edit) || !edit || editToolId === -1) {
      return true;
    } else return false; //false if opened from outside and item not selected for edit
  }, [editToolId, edit, orderItem.id]);

  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
  } = useSortable({
    id: props.id,
    disabled: hoveredIcon ? true : false,
  });

  const style = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };

  const deleteItem = () => {
    deleteTool(orderItem);
  };

  const copyItem = () => {
    setInProgress(true);
    setIsHovered(0);
    copyOrderItem(orderItem).then(() => {
      setInProgress(false);
    });
  };

  const overlayStyle = {
    backgroundColor: "rgba(175, 179, 185, 0.2)",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display:
      editToolId !== orderItem.id && edit && editToolId !== -1 ? "block" : "",
    borderRadius: "5px",
  };

  return (
    <div
      className={
        "cardItem_pile" +
        (selectedShape === orderItem.id && editToolId === -1 ? " active" : "") +
        (editToolId === orderItem.id
          ? " active normal-color"
          : edit && editToolId >= 0
          ? " not-active not-active-color"
          : "")
      }
      key={"defaultPiles" + index}
      ref={setNodeRef}
      style={
        isDragging
          ? {
              cursor: "grabbing",
              position: "relative",
              ...style,
            }
          : {
              cursor: "grab",
              position: "relative",
              ...style,
            }
      }
      {...attributes}
      {...listeners}
      onMouseEnter={() => {
        setIsHovered(item.id);
      }}
      onMouseLeave={() => setIsHovered(0)}
    >
      {isDragging ? (
        <div style={{ background: "rgba(255, 255, 255, 0.3)", height: "98px" }}>
          &nbsp;
        </div>
      ) : (
        <>
          {isHovered === item.id && !inProgress && (
            <div
              className="d-flex flex-column justify-content-center"
              style={
                rightToLeft
                  ? { position: "absolute", top: "25%", right: "94%" }
                  : { position: "absolute", top: "25%", left: "94%" }
              }
            >
              <div
                className="card-hover-icons delete-Icon mb-2"
                onClick={deleteItem}
                onMouseEnter={() => setHoveredIcon(true)}
                onMouseLeave={() => setHoveredIcon(false)}
              >
                <img src={deleteIcon} alt="delete Icon" />
              </div>
              <div
                className="card-hover-icons duplicate-Icon"
                onClick={copyItem}
                onMouseEnter={() => setHoveredIcon(true)}
                onMouseLeave={() => setHoveredIcon(false)}
              >
                <img src={duplicateIcon} alt="duplicate Icon" />
              </div>
            </div>
          )}
          {editToolId !== orderItem.id && edit && editToolId !== -1 && (
            <div style={overlayStyle}></div>
          )}
          <div
            className="d-flex flex-column"
            onClick={() => {
              if (selectedShape !== orderItem.id && editToolId === -1) {
                setEditTool(true);
                setSelectedTool(orderItem);
                setSelectedShape(orderItem.id);
                setErrorsText([]);
              }
            }}
          >
            {/* D and L */}
            <div
              className={
                "card-top-sect dark-border_btm " +
                (editModeChecks ? "normal-color" : "not-active-color")
              }
            >
              <div className="top-row">
                <img
                  src={editModeChecks ? cageCircle : cageCircleGrey}
                  alt="Cage Circle"
                  className={rightToLeft ? "pl-1" : "pr-1"}
                />
                <p>{item.hole_diameter}</p>
              </div>
              <div className="top-row">
                <p style={{ fontWeight: "400" }}>L</p>
                <span style={{ fontWeight: "400", margin: "0px 1px" }}>=</span>
                <p>{item.l3_length + item.l2_length + item.l1_length}</p>
              </div>
            </div>
            <div className="card-bottom-sect">
              {/* Card Bottom Left section */}
              <div
                className={
                  "card-bottom-left-sect " +
                  (rightToLeft ? "bdr-left " : "bdr-right ") +
                  (editModeChecks ? "normal-color" : "not-active-color")
                }
              >
                {/* first row */}
                <div className="left-sect-row-bw border__bottom">
                  {/* Horizontal Bars */}
                  <div className="special__pad left-sect-row">
                    <div className={rightToLeft ? "ml-1" : "mr-1"}>
                      <img
                        src={editModeChecks ? cageLength : cageLengthGrey}
                        alt="cage length icon"
                      />
                    </div>
                    <p>
                      {item.internal_amount &&
                      item.external_amount &&
                      item.middle_amount
                        ? item.internal_amount +
                          item.external_amount +
                          item.middle_amount
                        : item.internal_amount && item.external_amount
                        ? item.internal_amount + item.external_amount
                        : item.base_amount}
                    </p>
                  </div>
                  {/* Horizontal Bars -  diameter */}
                  <div className="special__pad left-sect-row">
                    <div className={rightToLeft ? "ml-1" : "mr-1"}>
                      <img
                        src={editModeChecks ? emtpySet : emptySetGrey}
                        alt="empty set icon"
                      />
                    </div>
                    {item.internal_amount ||
                    item.middle_amount ||
                    item.external_amount ? (
                      <p style={{ fontSize: "7px" }}>mixed</p>
                    ) : (
                      <p>{item.base_diameter.steel_diameter}</p>
                    )}
                  </div>
                </div>
                {/* second row */}
                <div className="left-sect-row-bw border__bottom">
                  {/* gap */}
                  <div className="special__pad left-sect-row">
                    <div className={rightToLeft ? "ml-1" : "mr-1"}>
                      <img
                        src={editModeChecks ? emtpySet : emptySetGrey}
                        alt="empty set icon"
                      />
                    </div>
                    <p>{item.spiral_wire_diameter.steel_diameter}</p>
                  </div>
                  {/* diameter */}
                  <div className="special__pad left-sect-row">
                    <div className={rightToLeft ? "ml-1" : "mr-1"}>
                      <img
                        src={editModeChecks ? email : emailGrey}
                        alt="email icon"
                      />
                    </div>
                    <p>{item.l2_gap}</p>
                  </div>
                </div>
                {/* third row */}
                <div className="left-sect-row-bw">
                  {/* gap */}
                  <div className="special__pad left-sect-row">
                    <div className={rightToLeft ? "ml-1" : "mr-1"}>
                      <img
                        src={editModeChecks ? emtpySet : emptySetGrey}
                        alt="empty set icon"
                      />
                    </div>
                    <p>{item.spiral_wire_diameter.steel_diameter}</p>
                  </div>
                  {/* diameter */}
                  <div className="special__pad left-sect-row">
                    <div className={rightToLeft ? "ml-1" : "mr-1"}>
                      <img
                        src={editModeChecks ? email : emailGrey}
                        alt="email icon"
                      />
                    </div>
                    <p>{item.l3_gap}</p>
                  </div>
                </div>
              </div>
              {/* Card Bottom Right Section */}
              <div
                className={
                  "card-bottom-right-sect " +
                  (editModeChecks ? "normal-color" : "not-active-color")
                }
              >
                {/* gap */}
                <div
                  className="section-style border__bottom"
                  style={{ height: "27px", marginTop: "1px" }}
                >
                  <p style={{ paddingBottom: "1px" }}>{item.l1_length}</p>
                </div>
                <div
                  className="section-style border__bottom"
                  style={{ height: "27px", marginTop: "1px" }}
                >
                  <p style={{ paddingBottom: "1px" }}>{item.l2_length}</p>
                </div>
                <div
                  className="section-style align-items-center"
                  style={{ height: "27px" }}
                >
                  <p>{item.l3_length}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PilesCard;
