import React, { useRef, useState, useEffect, useMemo } from "react";
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { removeBgBorder } from "Constants/general";
import fench from "../../../../assets/img/order__page/fench.png";
import steelTypeIcon from "../../../../assets/img/icon/steeltype-new.svg";
import infoIcon from "../../../../assets/img/icon/info.svg";
import {
  lastNetsWithoutPrice,
  lastNetsWithPrice,
  netsWithoutPrice,
  netsWithPrice,
} from "Constants/net-constants";
import { useTranslation } from "react-i18next";
import { Overlay, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCurrentOrderNets } from "Redux/middlwares/order";

import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import InfiniteScroll from "react-infinite-scroll-component";
import BarLoader from "react-bar-loader";
import DraggableTableRows from "./DraggableTableRows";
import StaticTableRow from "./StaticTableRow";
import { getOrderStats } from "Services/order";
import { NET_SUBCLASS_TYPE } from "Constants/net-constants";

import "../index.scss";

const NetsTable = ({
  priceShow,
  openItemModal,
  selectedTool,
  setSelectedTool,
  handleImagePreview,
  netPrice,
  copyOrderItem,
  handleUpdate,
  deleteTool,
  setNetsData,
  netsData,
  checkRowNums,
  edit,
  editOrderId,
}: any) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["newOrder", "common", "measurements", "tool"]);
  const currentOrderNets = useAppSelector(
    (state) => state.net.currentOrderNets
  );
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  const nextOrderNetsPage = useAppSelector((state) => state.net.orderNetPage);

  const [showMeasureTooltip, setShowMeasureTooltip] = useState(false);
  const [showNetTypeTooltip, setShowNetTypeTooltip] = useState(false);

  const [activeId, setActiveId] = useState();
  const [activeIndex, setActiveIndex] = useState();
  const [hasMore, setHasMore] = useState(false);

  const [isMouseOver, setIsMouseOver] = useState(false);
  const [mousePosition, setMousePosition] = useState(0);
  const [inProgress, setInProgress] = useState(false);
  const [netTons, setNetTons] = useState(0);
  const [netUnits, setNetUnits] = useState(0);

  const measurementRef = useRef(null);
  const netTypeRef = useRef(null);
  const NetsPositionRef = useRef(null);
  const NetsTableRef = useRef(null);

  useEffect(() => {
    getOrderStats(NET_SUBCLASS_TYPE, editOrderId)
      .then((res) => {
        setNetUnits(res.data.total_quantity)
        setNetTons((res.data.total_weight/1000).toFixed(2) )
      });
  }, [currentOrderNets, editOrderId]);

      useEffect(() => {
    if (currentOrderNets) {
      setNetsData(currentOrderNets);
      if (nextOrderNetsPage !== false) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  }, [currentOrderNets]);

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    const row = netsData.find((original) => original.id === activeId);
    return row;
  }, [activeId]);

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  function handleDragStart(event) {
    setActiveId(event.active.id);
    const activeRow = netsData.filter((data) => data.id === event.active.id);
    const Index = netsData.indexOf(activeRow[0]);

    setActiveIndex(Index);
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    const activeRow = netsData.filter((data) => data.id === active.id);
    const overRow = netsData.filter((data) => data.id === over.id);

    if (active.id !== over.id) {
      let oldIndex;
      let newIndex;
      let moveData;
      setNetsData((data) => {
        oldIndex = netsData.indexOf(activeRow[0]);
        newIndex = netsData.indexOf(overRow[0]);
        moveData = arrayMove(data, oldIndex, newIndex);
        return moveData;
      });

      checkRowNums(oldIndex, newIndex, moveData, NET_SUBCLASS_TYPE);
    }

    setActiveId(null);
    setActiveIndex(null);
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  const onloadFunction = async () => {
    try {
      if (nextOrderNetsPage !== false && nextOrderNetsPage > 1) {
        if (edit) {
          await dispatch(getCurrentOrderNets(edit, editOrderId, nextOrderNetsPage));
        } else {
          await dispatch(getCurrentOrderNets(edit, 0, nextOrderNetsPage));
        }
      }
    } catch (error) {
      setHasMore(false);
    }
  };

  useEffect(() => {
    const icon = NetsPositionRef.current;
    const table = NetsTableRef.current;
    if (isMouseOver && icon && table) {
      const rect = icon.getBoundingClientRect();
      const top = rect.top + window.pageYOffset;
      const tab = table.getBoundingClientRect();
      const tabtop = tab.top + window.pageYOffset;
      setMousePosition(top - tabtop);
    }
  }, [isMouseOver]);

  return (
    <section id="nets__table" className="order__tables mt-xl-4">
      <div className="container p-0">
        <div className="table__wrapper">
          {/* <!-- table header  --> */}
          <div className="table__header d-flex flex-row">
            {/* <!-- table title  --> */}
            <div className="table__title d-flex align-items-center justify-content-center">
              <h2 className="text-center text-white text-uppercase m-0">
                {t("Nets")}
              </h2>
            </div>
            <div
              className="table__tit__btn d-flex align-items-center justify-content-center"
              style={{ cursor: "pointer" }}
              onClick={() => openItemModal(2)}
            >
              <span className="text-white bg-danger text-center text-uppercase">
                {t("add new net", { ns: "newOrder" })}
              </span>
            </div>
          </div>
          {/* <!-- real table  --> */}
          <DndContext
            sensors={sensors}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
          >
            <table
              className={
                "table table mb-0 custom-tool-table " +
                (rightToLeft ? "rightToLeft" : "")
              }
              style={{ position: "relative" }}
              ref={NetsTableRef}
            >
              {/* <!-- table head title section  --> */}
              <thead>
                <tr style={priceShow ? netsWithPrice : netsWithoutPrice}>
                  <th
                    scope="col"
                    className="text-capitalize d-flex justify-content-center align-items-center p-0"
                  >
                    {t("row", { ns: "common" })}
                  </th>
                  <th
                    scope="col"
                    className={
                      "text-capitalize d-flex justify-content-center align-items-center p-0 " +
                      (rightToLeft ? "dark-border-right" : "dark-border-left")
                    }
                  >
                    {t("sketch", { ns: "newOrder" })}
                  </th>
                  <th
                    scope="col"
                    className={
                      "text-capitalize d-flex align-items-center justify-content-start text-center " +
                      (rightToLeft ? "dark-border-right" : "dark-border-left")
                    }
                  >
                    {t("Name", { ns: "newOrder" })}
                  </th>
                  <th
                    scope="col"
                    className={
                      "text-capitalize d-flex align-items-center justify-content-center text-center sm__wrap p-0 " +
                      (rightToLeft ? "dark-border-right" : "dark-border-left")
                    }
                  >
                    <img
                      className="hei__icon"
                      src={steelTypeIcon}
                      alt="hei icon"
                      style={{
                        height: "20px",
                        width: "15px",
                      }}
                    />
                    <span className="d-flex align-items-start">
                      <p className={"ml-1 mr-1"}>
                        {rightToLeft
                          ? t("type", { ns: "common" })
                          : t("steel", { ns: "newOrder" })}
                      </p>
                      <p>
                        {rightToLeft
                          ? t("steel", { ns: "newOrder" })
                          : t("type", { ns: "common" })}
                      </p>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className={
                      "text-capitalize d-flex align-items-center justify-content-start text-center sm__wrap " +
                      (rightToLeft ? "dark-border-right" : "dark-border-left")
                    }
                  >
                    <span className={rightToLeft ? "ml-3" : "mr-3"}>
                      {t("measurements", { ns: "tool" })}
                    </span>
                    <img
                      src={infoIcon}
                      style={{
                        width: "19px",
                        height: "19px",
                        cursor: "pointer",
                      }}
                      alt="info"
                      ref={measurementRef}
                      onMouseEnter={() => setShowMeasureTooltip(true)}
                      onMouseLeave={() => setShowMeasureTooltip(false)}
                    />
                    <Overlay
                      target={measurementRef.current}
                      show={showMeasureTooltip}
                      placement="top"
                    >
                      {(props) => (
                        <Tooltip id="overlay-example-2" {...props}>
                          {t("previewMeasInfo", { ns: "newOrder" })}
                        </Tooltip>
                      )}
                    </Overlay>
                  </th>
                  <th
                    scope="col"
                    className={
                      "text-capitalize d-flex align-items-center justify-content-start text-center " +
                      (rightToLeft ? "dark-border-right" : "dark-border-left")
                    }
                  >
                    <span className={rightToLeft ? "ml-2" : "mr-2"}>
                      {t("net type", { ns: "tool" })}
                    </span>
                    <img
                      src={infoIcon}
                      style={{
                        width: "19px",
                        height: "19px",
                        cursor: "pointer",
                      }}
                      alt="info"
                      ref={netTypeRef}
                      onMouseEnter={() => setShowNetTypeTooltip(true)}
                      onMouseLeave={() => setShowNetTypeTooltip(false)}
                    />
                    <Overlay
                      target={netTypeRef.current}
                      show={showNetTypeTooltip}
                      placement="top"
                    >
                      {(props) => (
                        <Tooltip id="overlay-example-2" {...props}>
                          {t("previewNetsInfo", { ns: "newOrder" })}
                        </Tooltip>
                      )}
                    </Overlay>
                  </th>
                  <th
                    scope="col"
                    className={
                      "text-capitalize d-flex align-items-center justify-content-start text-center " +
                      (rightToLeft ? "dark-border-right" : "dark-border-left")
                    }
                  >
                    {t("quantity", { ns: "common" })}
                  </th>
                  <th
                    scope="col"
                    className="text-capitalize dark-border-right dark-border-left"
                    style={{ padding: "0px" }}
                  >
                    <div className="two__rows_sec">
                      <div className="top__sec">
                        <span>{t("weight", { ns: "common" })}</span>
                      </div>
                      <div
                        className={
                          "d-flex justify-content-start align-items-center bottom_sec h-100 pb-1 " +
                          (rightToLeft
                            ? "bottom_sec-pr text-right"
                            : "bottom_sec-pl text-left")
                        }
                      >
                        <span style={{ width: "50%" }}>
                          {t("unit", { ns: "common" })}
                        </span>
                        <span>{t("total", { ns: "common" })}</span>
                      </div>
                    </div>
                  </th>
                  {priceShow && (
                    <>
                      <th
                        scope="col"
                        className="text-capitalize d-flex align-items-start justify-content-start text-center flex-column"
                      >
                        <span>{t("unit", { ns: "common" })}</span>
                        <span>
                          <span>{t("Price", { ns: "common" })}</span>
                          <span className="unit__sm">(NIS)</span>
                        </span>
                      </th>
                      <th
                        scope="col"
                        className="text-capitalize d-flex align-items-start justify-content-start text-center flex-column"
                      >
                        <span>{t("total", { ns: "common" })}</span>
                        <span>
                          <span>{t("Price", { ns: "common" })}</span>
                          <span className="unit__sm">(NIS)</span>
                        </span>
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              {/* <!-- table body data section  --> */}
              <tbody
                style={
                  currentOrderNets.length > 9
                    ? {
                        height: "720px",
                        overflow: "auto",
                        display: "block",
                        scrollbarWidth: "none",
                      }
                    : {
                      scrollbarWidth: "none",
                    }
                }
                id="scrollableNetsTable"
              >
                <SortableContext
                  items={netsData}
                  strategy={verticalListSortingStrategy}
                >
                  <InfiniteScroll
                    dataLength={netsData.length}
                    next={onloadFunction}
                    hasMore={hasMore}
                    loader={<BarLoader color="#F84343" height="4" />}
                    scrollableTarget="scrollableNetsTable"
                    style={{
                      scrollbarWidth: "none",
                    }}
                  >
                    {/* <!-- table data row   --> */}
                    {netsData.length > 0 &&
                      netsData.map((itemNet, index) => (
                        <DraggableTableRows
                          index={index}
                          key={itemNet.id}
                          id={itemNet.id}
                          itemNet={itemNet}
                          priceShow={priceShow}
                          handleImagePreview={handleImagePreview}
                          setSelectedTool={setSelectedTool}
                          setIsMouseOver={setIsMouseOver}
                          NetsPositionRef={NetsPositionRef}
                        />
                      ))}
                  </InfiniteScroll>
                </SortableContext>
              </tbody>
              <tbody className="last">
                <tr
                  style={priceShow ? lastNetsWithPrice : lastNetsWithoutPrice}
                >
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={removeBgBorder}></td>
                  <td className="justify-content-center p-0">{netUnits}</td>
                  <td className="justify-content-center p-0" colSpan={2}>
                    {netTons}
                  </td>
                  {priceShow && <td colSpan={2}>{netPrice}</td>}
                </tr>
                <tr
                  style={priceShow ? lastNetsWithPrice : lastNetsWithoutPrice}
                >
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={removeBgBorder}></td>
                  <td className="justify-content-center p-0">
                    {t("units", { ns: "common" })}
                  </td>
                  <td
                    className="justify-content-center p-0"
                    colSpan={2}
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("weight", { ns: "common" })} (
                    {t("ton", { ns: "measurements" })})
                  </td>
                  {priceShow && (
                    <td colSpan={2}>{t("Price", { ns: "common" })} (NIS)</td>
                  )}
                </tr>
              </tbody>
              {isMouseOver && !inProgress && (
                <div
                  className="dropdown-content"
                  style={
                    rightToLeft
                      ? { top: `${mousePosition}px`, right: "45px" }
                      : { top: `${mousePosition}px`, left: "45px" }
                  }
                  onMouseEnter={(e) => {
                    setIsMouseOver(true);
                  }}
                  onMouseLeave={() => setIsMouseOver(false)}
                >
                  <div
                    className="drop-item"
                    onClick={async () => {
                      setIsMouseOver(false);
                      setInProgress(true);
                      copyOrderItem().then(() => {
                        setInProgress(false);
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      className={
                        "item-icon " + (rightToLeft ? "item-ml" : "item-mr")
                      }
                      icon="copy"
                    ></FontAwesomeIcon>
                    <span>{t("Duplicate", { ns: "newOrder" })}</span>
                  </div>
                  <div className="drop-item" onClick={handleUpdate}>
                    <FontAwesomeIcon
                      className={
                        "item-icon " + (rightToLeft ? "item-ml" : "item-mr")
                      }
                      icon="pencil-alt"
                    ></FontAwesomeIcon>
                    <span>{t("edit", { ns: "common" })}</span>
                  </div>
                  <div className="drop-item" onClick={deleteTool}>
                    <FontAwesomeIcon
                      className={
                        "item-icon " + (rightToLeft ? "item-ml" : "item-mr")
                      }
                      icon="trash"
                    ></FontAwesomeIcon>
                    <span>{t("Delete", { ns: "common" })}</span>
                  </div>
                </div>
              )}
            </table>
            <DragOverlay>
              {activeId && (
                <table
                  className={
                    "table table mb-0 custom-tool-table" +
                    (rightToLeft ? " rightToLeft" : "")
                  }
                >
                  <tbody>
                    <StaticTableRow
                      id={selectedRow.id}
                      index={activeIndex}
                      itemNet={selectedRow}
                      priceShow={priceShow}
                      setSelectedTool={setSelectedTool}
                      handleImagePreview={handleImagePreview}
                    />
                  </tbody>
                </table>
              )}
            </DragOverlay>
          </DndContext>
        </div>
      </div>
    </section>
  );
};

export default NetsTable;
