import Request, { isAxiosResponse } from "./index";

export const getPiles = (type: number) =>
  Request.get(`tool/pile/?type=${type}`).then((response) =>{
    if (isAxiosResponse(response) && response.data.results.length > 0) {
      const parsedShapes = response.data.results.map((item: any) => {
        return {
          ...item,
          cover: item.hole_diameter - item.cage_diameter
        };
      });
      return parsedShapes;
    }
    return [];
  }).catch((err) => console.log(err));

export const postPile = (pile: any) =>
  Request.post('tool/pile/create/', pile);

export const updatePile = (pileID: number, pile: any) =>
  Request.patch(`tool/pile/${pileID}/`, pile);

export const deletePile = (pileID: number) =>
  Request.remove(`tool/pile/${pileID}/`)