export const userProfileEn = {
  "User Details": "User Details",
  "Company Details": "Company Details",
  "Notifications": "Notifications",
  "Language": "Language",

  "Profile Pricture": "Profile Pricture",
  "First Name": "First Name",
  "Last Name": "Last Name",
  "Phone": "Phone",
  "Job Title": "Job Title",
  "Mail": "Email",
  "Edit Details": "Edit Profile Details",
  "Change Password": "Change your Password",

  "Compnay": "Compnay",
  "Name": "Name",
  "Status": "Status",
  "City": "City",
  "Address": "Address",
  "BN Number": "BN Number",
  "Compnay Owner": "I owned this company",
  "Company Part": "I work as a part of this company",
  "Freelancer": "I'm a freelancer",
  "Logo": "Company Logo",
  "No Company": "No Company data Found",

  "NewsLetter Flag": "I wish to receive newsletters, promotions and news from Easy-Bar Company",
  "Weekly Report": "I wish to receive a weekly report about my Easy-Bar activity",

  "Choose Language": "Choose Your Language",
  "Account Settings": "Account Settings",
  "Cancel": "Cancel",
  "Edit Company Details": "Edit Company Details",

  "work as part of company": "I work as part of a company",
  "terms start": "I agree to the ",
  "term conditions": "terms and conditions",
  "terms last": " of EasyBar",

  "pswrd inst": "Password must contain the following",
  "pswrd char": "It should be 8 character long",
  "one letter": "It should contain one letter",
  "one number": "It should contain one number",
  "special char": "It should contain a special character",

  "Companies list": "Companies List",
  "Current company": "Current company",
  "select current company": "Select current company",
  "add company": "add a company",
  "company logo":"company logo",
  "click to add": "click to add company logo",
  "details": "Details",
  "location": "Location",
  "street": "Street",
  "position": "Position",
};
