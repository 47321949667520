import {
  calcualte_distance_points,
  calculateImageRectangleCenter,
  calculateNextPoint,
  calculate_angle_points,
  calculate_points,
  checkFirstQuadrant,
  findPointOutOfCanvas,
  Point,
  Side,
} from "../../utils";

export const previewWidth = 870;
export const previewHeight = 240;

export const padX = 50;
export const padY = 50;
export const mPadX = 2.5;
export const mPadY = 2.0;
export const resacleRate = 0.0001;
export const pctInnerheight = 0.62;
export const pctInnerWidth = 0.80;
export const rebarInputSize = 50;
export const sideLengthTextOneDigi = 12;
export const sideLengthTextTwoDigi = 25;
export const sideLengthTextThreeDigi = 35;
export const sideLengthTextfourDigi = 45;
export const previewPad = 5.0;
export const previewFontSize = 40;
export const angleTextSize = 14;

export const sideLengthWithoutTextInput = (digitsLen: number) => {
  switch (digitsLen) {
    case 1:
      return sideLengthTextOneDigi;
    case 2:
      return sideLengthTextTwoDigi;
    case 3:
      return sideLengthTextThreeDigi;
    default:
      return sideLengthTextfourDigi;
  }
}

export function findShapeInsideInnerCanvas(
  canvasWidth: number,
  padX: number,
  canvasHeight: number,
  padY: number,
  points: Point[]
) {
  const midx = canvasWidth / 2;
  const midy = canvasHeight / 2;

  // find the perctentage width of inner canvus and subtracted its half from the center of the canvus
  const halfSmallerAreaWidth = ((canvasWidth - padX) * pctInnerWidth) / 2;
  // find the perctentage height of inner canvus and subtracted its half from the center of the canvus
  const halfSmallerAreaHeight = ((canvasHeight - padY) * pctInnerheight) / 2;
  // if any point is outside Inner range, do not scaleUp (false return) else true
  for (let i = 0; i < points.length; i = i + 1) {
    if (
      points[i].x < midx - halfSmallerAreaWidth ||
      points[i].x > midx + halfSmallerAreaWidth ||
      points[i].y < midy - halfSmallerAreaHeight ||
      points[i].y > midy + halfSmallerAreaHeight
    ) {
      return false;
    }
  }
  return true;
}

// resize image
export function resize_image(
  canvasWidth: number,
  canvasHeight: number,
  sides: Side[],
  isMobile: boolean
) {
  const default_first_point = { x: 0, y: 0 };
  const canvus_mid = { x: canvasWidth / 2, y: canvasHeight / 2 };

  let rescale_factor = 1,
    actualPoints = [],
    first_point = { x: 0, y: 0 },
    doDeduction = false,
    doAddition = false,
    i = 0;

  const allSidesZero = sides.reduce(
    (prev, curr) => prev && (curr.length > 0 ? false : true),
    true
  );
  if (allSidesZero) {
    return {
      first_point,
      rescale_factor,
      sides,
    };
  }

  do {
    if (doDeduction) rescale_factor = rescale_factor - resacleRate;
    else rescale_factor = rescale_factor + resacleRate;
    /* eslint-disable */
    sides = sides.map((item) => ({
      ...item,
      length: rescale_factor * item.length,
    }));
    /* eslint-enable */
    const initial_rescaled_points = calculate_points(
      default_first_point,
      sides
    );
    //check if shape is not in first quadrant
    const display_factor = checkFirstQuadrant(initial_rescaled_points);
    const rescaled_points = initial_rescaled_points.map((pt) => ({
      x: pt.x + display_factor,
      y: pt.y + display_factor,
    }));

    // image mid
    const object_mid_point = calculateImageRectangleCenter(rescaled_points);
    // angle between image center and image starting point
    let center_to_first_angle = calculate_angle_points(
      object_mid_point,
      rescaled_points[0]
    );
    const center_to_first_distance = calcualte_distance_points(
      object_mid_point,
      rescaled_points[0]
    );
    // New image first_point centered at canvas center
    first_point = calculateNextPoint(
      canvus_mid,
      center_to_first_angle,
      center_to_first_distance
    );
    // Presicion error corrections
    const angle = calculate_angle_points(canvus_mid, first_point);
    first_point = calculateNextPoint(
      canvus_mid,
      angle,
      center_to_first_distance
    );

    actualPoints = calculate_points(first_point, sides);
    // ========= Scale Down code ==================
    doDeduction = findPointOutOfCanvas(
      canvasWidth,
      isMobile ? mPadX : padX,
      canvasHeight,
      isMobile ? mPadY : padY,
      actualPoints
    );
    // ========= Scale UP code ==================
    doAddition = findShapeInsideInnerCanvas(
      canvasWidth,
      isMobile ? mPadX : padX,
      canvasHeight,
      isMobile ? mPadY : padY,
      actualPoints
    );
  } while (i < 5000 && (doDeduction || doAddition));

  return {
    first_point,
    rescale_factor,
    sides,
  };
}
