import { Modal } from "react-bootstrap";
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import checkIcon from "../../../assets/img/icon/check.svg";
import { timeout_secs } from "Constants/alert-modal";

import "../index.scss";

function NotEasyBarUser({ ...props }) {
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const { t } = useTranslation(["emailOrder", "common"]);

  const { show, onHide, recipientEmail, orderShareId, sharePDFmail } = props;

  const handleNext = () => {
    onHide();
    sharePDFmail(orderShareId, recipientEmail);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        className="send_supplier_email_popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <>
          <div className="container send_email_container">
            <div className="no_easybar_user_content">
              <p className="send_email_text">
                {t("not easybar user line 1", { ns: "emailOrder" })}
              </p>
              <p className="send_email_text py-0">
                {t("not easybar user line 2", { ns: "emailOrder" })}
              </p>

              <div
                className="order_share_popup_buttons"
                style={rightToLeft ? { flexDirection: "row-reverse" } : {}}
              >
                <button
                  className="send_email_button "
                  style={{ color: "#AFB3B9", borderColor: "#AFB3B9" }}
                  onClick={onHide}
                >
                  {t("cancel", { ns: "common" })}
                </button>
                <button className="send_email_button " onClick={handleNext}>
                  {t("send email", { ns: "emailOrder" })}
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}

export default NotEasyBarUser;
