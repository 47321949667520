import { Modal } from 'react-bootstrap';
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";

import "../index.scss"

function PackageFinished({ ...props }) {
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const { t } = useTranslation(['ticketCreator']);

  const {
    show,
    onHide,
    supplier_renewal_date
  } = props
  
  const handleNext = () =>{
    onHide()
  }

  return (
    <>
      <Modal
        show = {show}
        onHide = {onHide}
        className="package_expire_popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <> 
          <div className="container popup_container">

            <div className="small_popup_content">
              <p className={"pt-5 pb-2 " + ( (rightToLeft ? "package_expire_heading" : "package_expire_heading_heebo")) }>
              {t("looks_like", { ns: "ticketCreator" })}
              </p>
              <p className='package_expire_text'>
              {t("package_renew", { ns: "ticketCreator" })}
              {supplier_renewal_date}
              </p>
              <a href='https://api.whatsapp.com/message/E7GHBYJXF4DBN1?autoload=1&app_absent=0'>
                <button className="popup_button_md my-4" onClick={handleNext}>
                  {t("go_unlimited", { ns: "ticketCreator" })}
                </button>
              </a>
                {rightToLeft ? null: 
                <p className="popup_details_no_hover">
                  {t("share_opinions", { ns: "ticketCreator" })}
                </p>}
                <a href='https://docs.google.com/forms/d/e/1FAIpQLSdiykSS8csHXz8axZnN3p1fp3Un7sVpKvQDpoumedsnz5ndlQ/viewform?pli=1'>
                  <p className="popup_details pb-5">
                    {t("short_survey", { ns: "ticketCreator" })}
                  </p>
                </a>
                
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}

export default PackageFinished;
