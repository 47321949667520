export const pdfOrderHe = {

    'order created by': "בקשה להצעת מחיר",
    'request quote': "בקשה להצעת מחיר",
    'last updated': "תאריך עדכון אחרון",
    'number of pages': "מספר דפים",
    'document number': "מספר הזמנה",
    'client': 'הלקוח (המזמין)',

    'phone office': 'טלפון משרד',
    'mail': 'דואר אלקטרוני',
    'document creater': 'יוצר ההזמנה',
    'address': 'כתובת',
    'delivery details': 'פרטי משלוח',
    'delivery date request': 'תאריך אספקה נדרש',
    'site address': 'כתובת האתר',
    'site man one': 'איש קשר בשטח 1',
    'site man two': 'איש קשר בשטח 2',
    'arrival notes': 'הערות הגעה',
    'order notes': 'הערות להזמנה',

    "sketch": "שרטוט",
    "supplier": "ספק",
    "delivery date confirmation": "אישור תאריך אספקה",
    'signature': "חתימה",

    'qty': 'כמות',
    'lengths': 'אורכים',
    'order Summary': "סיכום הזמנה",
    "standard nets": "רשתות סטנדרטיות",
    "special nets": "רשתות מיוחדות",
    "length": "אורך",
    "gap" : "פסיעה",
    "dmeter": "קוטר",
    "order name": "שם ההזמנה",
    "project name": "שם הפרויקט",
    "steel type": "סוג פלדה",

    "name": "כינוי",

    "ear": "אוזן",
    "gama": "גמא",
    "ring": "טבעת",
    "pile cage": "כלונסאות",
}
