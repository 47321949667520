import CrossIcon from "../../../assets/img/order-details/cross.svg";
import checkIcon from "../../../assets/img/icon/check.svg";
import { Modal } from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { createBusiness, updateBusiness } from "Redux/middlwares/business";
import { fetchUser } from "Redux/middlwares/user";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { DynamicMap } from "Components/dynamic-map";
import { getMedia, postMediaFile } from "Services/media";
import { timeout_secs } from "Constants/alert-modal";
import toast from 'react-hot-toast';

const USER_ROLES = {
  OWNER: 3,
  EMPLOYEE: 4,
  SERVICE_PROVIDER: 2,
  OTHER: 1,
};

function AddNewCompany(props) {
  const {
    edit,
    business,
    currentCompany,
  } = props;


  const userData = useAppSelector((state) => state.user.userData);
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation(["common", "newOrder", "warning"]);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  const dispatch = useAppDispatch();
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [businessNumber, setBusinessNumber] = useState("");
  const [userRole, setUserRole] = useState(USER_ROLES.OWNER);
  const [coordinates, setCoordinates] = useState();
  const [isInputChanges, setIsInputChanges] = useState(false);
  const [error, setError] = useState(false);
  const [uploadedLogo, setUploadedLogo] = useState("");

  const [logoId, setLogoId] = useState("");
  const logoUploader = useRef(null);
  
  useEffect(()=>{
    let newErrors = error;
    if (phone !== "" && !/^0\d{1,2}-?\d{3}-?\d{4}$/.test(phone)){
      newErrors = true;
    } else {
      newErrors = false;
    }
    setError(newErrors);
  },[phone])

  useEffect(() => {
    if (edit) {
      setName(business.name);
      setAddress(business.address);
      setCity(business.city);
      setMail(business.mail);
      setPhone(business.phone);
      setBusinessNumber(business.bn_number);
      setUserRole(business.user_role);
      setLogoId(business.logo);
    } else {
      setName("");
      setAddress("");
      setCity("");
      setMail("");
      setPhone("");
      setBusinessNumber("");
      setUserRole("");
      setLogoId("");
      setUploadedLogo("");
    }

    if(business.logo && edit){
      getMedia(business.logo).then((res)=>{
        setUploadedLogo(res.data.file_field);
    }).catch((err) => console.log(err))
    } else {
      setUploadedLogo("");
    }
  }, [edit, business]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setLoader(true);
    const data = {
      user: userData.id,
      name,
      address,
      city,
      mail: mail.toLowerCase(),
      phone,
      bn_number: businessNumber,
      user_role: userRole,
      logo: logoId,
    };
    const callback = (created) => {
      if (created) {
        dispatch(fetchUser())
        props.onHide();
      }
      toast(t("company added", { ns: "warning" }), {
        icon: <img src={checkIcon} alt="alert icon" />,
      });
      setLoader(false);
    };
    const callbackEdit = (created) => {
      if (created) {
        toast((t("company changes alert", { ns: "warning" })), {
          icon: <img src={checkIcon} alt="alert icon" />,
        });
        props.onHide();
      }
      setLoader(false);
    };
    if (edit) {
      dispatch(updateBusiness(business.id, data, callbackEdit));
    } else {
      dispatch(createBusiness(data, currentCompany, callback));
    }
  };

  const addressChange = (data) => {
    setCity(data.city);
    setAddress(data.st_address);
  };

  const logoUpload = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      const reader = await new FileReader();
      reader.onload = (event: any) => {
        setUploadedLogo(event.target.result);
      };
      reader.readAsDataURL(file);
    }

    postMediaFile(file, name).then((res) => {
      setLogoId(res.data.id);
    }).catch((err)=>console.log(err))
  }

  const deleteLogo = () => {
    setUploadedLogo("");
    setLogoId("");
  }

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={"project-modal"}
    >
      <div className="head">
        <h4>
          {edit
            ? t("Edit company", { ns: "newOrder" })
            : t("Add new company", { ns: "newOrder" })}
        </h4>
        <img
          src={CrossIcon}
          alt="cross"
          style={{ cursor: "pointer" }}
          onClick={props.onHide}
        />
      </div>
      <form className="body" onSubmit={(e) => onSubmitHandler(e)}>
        <h5 className="main-head">
          {t("Company details", { ns: "newOrder" })}
        </h5>
        <div className="custom-form-group">
          <label for="project-name">
            {t("Company name", { ns: "newOrder" })}
          </label>
          <input
            type="text"
            id="company-name"
            aria-describedby="name"
            placeholder={t("official name", {
              ns: "newOrder",
            })}
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="custom-form-group">
          <label for="project-name">{t("bn number", { ns: "newOrder" })}</label>
          <input
            type="number"
            id="phoneNumber"
            aria-describedby="phone number area"
            placeholder={t("XXXXXXX", { ns: "newOrder" })}
            required
            value={businessNumber}
            onChange={(e) => setBusinessNumber(e.target.value)}
          />
        </div>
        <div className="custom-form-group">
          <label for="project-name">
            {t("email", {
              ns: "newOrder",
            })}
          </label>
          <input
            type="email"
            id="mail"
            aria-describedby="mail area"
            placeholder={t("official email", { ns: "newOrder" })}
            required
            value={mail}
            onChange={(e) => setMail(e.target.value)}
          />
        </div>
        <div className="custom-form-group">
          <label for="project-name">{t("phone", { ns: "newOrder" })}</label>
          <input
            required
            type="text"
            className={error ? "invalid" : ""}
            id="phoneNumber"
            aria-describedby="phone number area"
            placeholder={t("official phone number", {
              ns: "newOrder",
            })}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            pattern="^0\d{1,2}-?\d{3}-?\d{4}$"
            title={t("phone warning", { ns: "newOrder" })}
          />
        </div>
        <div className="custom-form-group">
          <div className="logo_label">
            <label htmlFor="project-name">
              {t("Logo", { ns: "newOrder" })}
            </label>
            {uploadedLogo ? (
              <p className="delete_btn" onClick={deleteLogo}>
                {t("Delete", { ns: "common" })}
              </p>
            ) : (
              ""
            )}
          </div>
          <div
            className="company-logo"
            onClick={() => logoUploader.current.click()}
          >
            <input
              id="logo-upload"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              onChange={logoUpload}
              style={{ display: "none" }}
              ref={logoUploader}
            />
            {uploadedLogo ? (
              <img
                src={uploadedLogo}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                  margin: "0 auto",
                  position: "absolute",
                }}
              />
            ) : (
              <p className="underlined">
                {t("company logo", { ns: "newOrder" })}
              </p>
            )}
            <div className="edit-text">
              <h2 className="text">
                {edit
                  ? t("edit logo", { ns: "newOrder" })
                  : t("add logo", { ns: "newOrder" })}
              </h2>
            </div>
          </div>
        </div>
        <h5 className="main-head">{t("location", { ns: "newOrder" })}</h5>
        <div className="custom-form-group">
          <label htmlFor="project-name">{t("city", { ns: "newOrder" })}</label>
          <input
            type="text"
            id="city"
            aria-describedby="city area"
            placeholder={t("company located", {
              ns: "newOrder",
            })}
            required
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
              setIsInputChanges(true);
            }}
          />
        </div>
        <div className="custom-form-group">
          <label htmlFor="project-name">
            {t("addresss", {
              ns: "newOrder",
            })}
          </label>
          <input
            type="text"
            id="address"
            aria-describedby="address area"
            placeholder={t("Street NandN", { ns: "newOrder" })}
            required
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
              setIsInputChanges(true);
            }}
          />
        </div>
        <DynamicMap
          coordinates={coordinates}
          setCoordinates={setCoordinates}
          addressChange={addressChange}
          deps={[city, address, city, ""]}
          isInputChanges={isInputChanges}
          setIsInputChanges={setIsInputChanges}
        />
        <h5 className="main-head">{t("position", { ns: "newOrder" })}</h5>
        <div className="custom-form-group">
          <label htmlFor="project-name" className="mb-3">
            {t("Company position", { ns: "newOrder" })}
          </label>
          {Object.entries(USER_ROLES).map(([key, val], index) => (
            <div className="checkbox__input">
              <input
                key={"user-role " + index}
                type="checkbox"
                checked={val === userRole}
                onChange={() => {
                  setUserRole(val);
                }}
                defaultChecked={false}
                className={rightToLeft ? "checkbox__ml" : "checkbox__mr"}
              />
              <label htmlFor={"radio "} className="mb-0">
                {t(key, { ns: "newOrder" })}
              </label>
              <br />
            </div>
          ))}
        </div>
        <div className="save-wrapper">
          <button type="submit" className="save-btn">
            {loader && (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                &nbsp;
              </>
            )}
            {t("Save")}
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default AddNewCompany;
