export const toolHe = {

    "item": "פריט",
    'add new': "הוסף חדש",
    'edit': 'עריכה',
    'filters': 'סינון צורות',
    'add radius': 'הוספת רדיוס כיפוף',
    "instruction": "בחר את הצורה הרצויה והזן נתונים בטבלה",
    "instruction net": "בחר את הצורה הרצויה והזן נתונים בטבלה",
    "uniform gap": "פסיעה זהה",
    "uniform diameter": "קוטר זהה",
    "shape": "צורות",
    "Add New Item": "הוספת פריט חדש",
    'edit item':'עריכת פריט',
    'ear': 'אוזן',

    "preview": "תצוגה מקדימה",
    "data entry": "ניתן להזין נתונים בטבלה או בשרטוט",
    "sketch": "הוסף שרטוט",
    "net": "רשת",
    "cage": "כלונסאות",
    "concrete cage": "כלונס בטון",
    "diameter": "קוטר",
    "deep": "עומק",
    "Reinforced Concerte": "בטון מזויין",

    "spiral": "ספירלה",
    "cover": "כיסוי",
    "steel": "פלדה",
    "type": "סוג",
    "length": "אורך",
    "amount": "כמות",
    "Select Diameter": "בחר קוטר",
    "Select Steel Type": "בחר סוג פלדה",

    "number": "מספר",
    "net size": "גודל רשת",
    "steel type": "סוג פלדה",
    "units": "יחידות",
    "weight": "משקל",
    "unit": "יחידה",
    "total": "סה״כ",
    "notes": "הערות",

    "net type": "סוג רשת",
    "save and add": "שמור והוסף",
    "save and finish": "שמור וסיים",
    "finish": "סיים",
    "save":  "שמור",

    "instruction pile": "על מנת ליצור פריט חדש, הזן נתונים בשרטוט או בחר מרשימת הצורות הסטנדרטיות",
    "cage type": "סוג כלונס",
    "base": "בסיס",
    "Split": "דיפון",
    "internal": "פּנימי",
    "middle": "אמצעי",
    "external": "חיצוני",
    "standard shapes": "צורות סטנדרטיות",
    "adjustments": "תופסות",
    "RING": "טבעות",
    "GAMA": "צינורות גאמה",
    "cartesian": "קרטזי",
    "radial": "רדיאלי",

    "rebar": "מוטות",
    "rebar-singular" : "מוט",
    "bend": "כיפוף",
    "degrees": "מעלות",
    'weights': 'משקולות',
    "sides": "צלעות",
    "side" : "דיפון",
    "all" : "הכל",
    "use arrow to move": "השתמש בחץ כדי לזוז",
    "measurements warning": "שמנו לב שלא שינית את המידות של הצורה,",
    "measurements surety":"האם אלו המידות של הצורה?",
    "I'm sure":"אני בטוח",
    "go back":"ביטול",
    "symmetrical shape": "צורה סימטרית",
    "symm info": ".בטל את הסימון כדי לשנות את כל המידות",

    "measurements": "אורך כללי | קוטר | פסיעה",
    "diff gap": "שתי פסיעות שונות",
    "diff diameters": "שני קטרים ​​שונים",
    "size": "גודל",
    "last used": "בשימוש אחרון",

    "error_net_min": "הגודל המינימלי של רשת יכול להיות 80 ס״מ",
    "error_net_max": "הגודל המקסימלי של רשת יכול להיות 750/320 ס״מ",
    "error_net_minmax": 'הגודל המרבי של רשת יכול להיות 750/320 או 320/750 ס"מ',
    
    "error_net_amount": "יש להוסיף כמות פריטים רצויה",
    "error_net_amount_min": "הכמות לא צריכה להיות קטנה מ-1",
    "error_net_diameter": "יחס הקוטר צריך להיות מעל 0.6, אנא שנה את קוטר X או Y.",

    "error_net_sides": "אורך קצוות הרשת לא מתאים לתקן",
    "error_net_measure": "מידה בלתי אפשרית",

    "error_net_gap_xy": "המספרים המסומנים מאדום לא מתאימים זה לזה",
    
    "net name" : "כינוי רשת",
    "total lengths" : "אורכים",
    "cm" : "(ס״מ)",
    "mm" : "(מ״מ)",
    "kg" : "ק״ג",
    "xwidth" : "X - רוחב",
    "ywidth" : "Y - גובה",

    "horizontal rebars": "מוטות אופקיים",
    "lengths and gaps": "אורכים ופסיעות",
    "ear type": "סוג אוזן",
    "zoll": "(צול)",
    "cage name": "כינוי כלונס",
    "split": "חלוקה",

    "min_error_D": "קוטר הכלונס המינימלי הוא 30 ס״מ",
    "max_error_D": "קוטר הכלונס המקסימלי הוא 150 ס״מ",
    "error_cage_diameter_ratio": "קוטר הספירלה לא יכול להיות קטן מקוטר הכלונס",

    "error_rebar_amount_to_cage": "כמות מוטות הזיון מועט ביחס לקוטר הכלונס",
    "min_error_rebar_amount": "כמות מוטות הזיון המינימליות הן 5",
    "max_error_rebar_amount": "כמות מוטות הזיון גדול מידיי ביחס לקוטר הכלונס",

    "min_error_l1": "L1 min is 5 cm",
    "max_error_l1": "L1 max is 200 cm",

    "min_error_l2": "L2 min is 50 cm",
    "max_error_l2": "L2 max is 2,355 cm",

    "min_error_l3": "L3 min is 50 cm",
    "max_error_l3": "L3 max is 2,355 cm",

    "error_min_total_len": "אורך הכלונס המינימלי הוא 250 ס״מ",
    "error_max_total_len": "אורך הכלונס המקסימלי הוא 2,400 ס״מ",
    "error_total_len_ratio": "היחס בין אורך הכלונס לקוטר הכלונס לא עומד בתקן",

    "error_cage_amount": "יש להוסיף כמות פריטים רצויה",
    "error_side_min": "כמות המוטות המינימלית היא 2",

    "drawing": "שרטוט חופשי: צייר ולחץ פעמיים על העכבר כדי לסיים את הצורה",
    "refresh": "רענן"
}