import Request, { isAxiosResponse } from "./index";

export const getDefaultNets = (type: number) =>
  Request.get(`tool/net/?type=${type}`).then((response) =>{
    if (isAxiosResponse(response) && response.data.results.length > 0) {
      return response.data.results;
    }
    return [];
  }).catch((err) => console.log(err));

export const getLastUsedShapes = () => 
  Request.get('recently-used-nets/').then((response) =>{
    if (isAxiosResponse(response) && response.data.length > 0) {
      return response.data;
    }
    return [];
  }).catch((err) => console.log(err));

export const postNet = (net: any) =>
  Request.post('tool/net/create/', net);

export const updateNet = (netID: number, net: any) =>
  Request.patch(`tool/net/${netID}/`, net);

export const deleteNet = (netID: number) =>
  Request.remove(`tool/net/${netID}/`)