import {
  smallAngle,
  fullAngle,
  calculateNextPoint,
  changeAngle,
  transfromAngle,
} from "../utils";
import { sideLengthWithoutTextInput } from "../rebar/preview/preview-constants";
import {
  getSmallerAngle,
  getFullAngle,
} from "../rebar/free-forms-shape/shared/shapesGeometry";
import { SHAPE_MODE } from "Constants/rebar-constants";

export const calculateInputPoints = (
  rebarShapeMode,
  lines,
  shapeObj,
  index,
  isRotatable,
  allPoints,
  _resizedImage,
  reverse = false,
) =>{
  if (rebarShapeMode === SHAPE_MODE.catalog){
    return calculateInputPointCatalog(
      shapeObj, index, isRotatable, _resizedImage, allPoints, reverse
    )
  }else{
    return calculateInputPointFreeForm(
      lines, shapeObj, index, isRotatable, allPoints, _resizedImage, reverse
    )
  }
}
export const calculateInputPointCatalog = (
  shapeObj,
  index,
  isRotatable,
  _resizedImage,
  allPoints,
  reverse = false,
) => {
  const previousPoint = allPoints[index];
  const currentPoint = allPoints[index+1];
  const invertedLine = previousPoint.x > currentPoint.x;
  const length = _resizedImage.sides[index].length;
  const side = shapeObj.rebar_side[index];
  const sideAngle = getSideAngle(side, SHAPE_MODE.catalog, isRotatable);
  let outterInput = true;
  outterInput =
    side.outer ||
    (index >= 1 &&
      !shapeObj.rebar_side[index - 1].change_previous_point &&
      shapeObj.rebar_side[index - 1].outer);

  const digitsLen = side.length.toString().length;
  const inputSizeMid = sideLengthWithoutTextInput(digitsLen);
  const intermediate = calculateNextPoint(
    previousPoint,
    sideAngle,
    invertedLine ? length / 2 + inputSizeMid : length / 2 - inputSizeMid
  );
  const inputAngle = reverse ? (outterInput ? sideAngle + 90 : sideAngle - 90) : (outterInput ? sideAngle - 90 : sideAngle + 90);
  const inputDistance = reverse ? (outterInput || invertedLine ? 12 : 46)  : (outterInput || invertedLine ? 46 : 12);
  return calculateNextPoint(intermediate, inputAngle, inputDistance);
};

export const calculateInputPointFreeForm = (
  lines,
  shapeObj,
  index,
  isRotatable,
  allPoints,
  _resizedImage,
  reverse = false,
) => {
  let inner = false;
  const previousPoint = allPoints[index];
  const currentPoint = allPoints[index+1];
  const invertedLine = previousPoint.x > currentPoint.x;
  const length = _resizedImage.sides[index].length;
  const side = shapeObj.rebar_side[index];
  const sideAngle = getSideAngle(side, SHAPE_MODE.freeform, isRotatable);

  if (index < lines?.length - 1) {
    let line1 = lines[index];
    let line2 = lines[index + 1];

    const smallerAngle = getSmallerAngle(line1, line2);
    const fullAngle = getFullAngle(line1, line2);

    if (smallerAngle !== fullAngle) {
      inner = true;
    } else {
      inner = false;
    }
  } else if (lines?.length > 1 && index === lines?.length - 1) {
    let lastLine = lines[index];
    let previousLine = lines[index - 1];
    const smallerAngle = getSmallerAngle(lastLine, previousLine);
    const fullAngle = getFullAngle(lastLine, previousLine);
    if (smallerAngle === fullAngle) {
      inner = true;
    } else {
      inner = false;
    }
  }

  const digitsLen = side.length.toString().length;
  const inputSizeMid = sideLengthWithoutTextInput(digitsLen);
  const intermediate = calculateNextPoint(
    previousPoint,
    sideAngle,
    invertedLine ? length / 2 + inputSizeMid : length / 2 - inputSizeMid
  );
  let inputAngle;
  let inputDistance;

  if (invertedLine) {
    inputAngle = reverse ? (inner ? Number(sideAngle) - 90 : Number(sideAngle) + 90) : (inner ? Number(sideAngle) + 90 : Number(sideAngle) - 90);
    inputDistance = reverse ? (inner ? 8 : 38) : (inner ? 38 : 8);
  } else {
    inputAngle = reverse ? (inner ? Number(sideAngle) - 90 : Number(sideAngle) + 90) : (inner ? Number(sideAngle) + 90 : Number(sideAngle) - 90);
    inputDistance = reverse ? (inner ? 38 : 8) : (inner ? 8 : 38);
  }
  return calculateNextPoint(intermediate, inputAngle, inputDistance);
};

export const calculateAnglePoints = (
  shapeObj,
  side,
  index,
  lines,
  rebarShapeMode,
  currentPoint,
  _resizedImage
) => {
  let outterInput = isOuter(rebarShapeMode, shapeObj, side, index, lines);
  let pointToAngleText;
  let inner = !outterInput;

  let MiddleLineAngle;
  let angle1 = Number(shapeObj.rebar_side[index].line_angle);
  angle1 = changeAngle(angle1);
  angle1 = fullAngle(angle1);

  if (inner) {
    MiddleLineAngle = angle1 + side.angle / 2;
  } else {
    MiddleLineAngle = angle1 - side.angle / 2;
  }
  MiddleLineAngle = smallAngle(MiddleLineAngle);

  //Angles calculated and if the image is rotatable angles are also
  //transformed vertical to horizontal
  // """TODO: to rotate shape for free from screenshot"""
  // const angleOfText = side.outer
  //   ? (isRotatable
  //       ? transfromAngle(shapeObj.rebar_side[index + 1].line_angle) +
  //         middleAngle
  //       : shapeObj.rebar_side[index + 1].line_angle + middleAngle) % 360
  //   : (isRotatable
  //       ? transfromAngle(shapeObj.rebar_side[index + 1].line_angle) -
  //         middleAngle
  //       : shapeObj.rebar_side[index + 1].line_angle - middleAngle) %
  //     360;

  // Calculating X and Y point of the Angle text
  pointToAngleText = calculateNextPoint(
    currentPoint,
    MiddleLineAngle,
    (side.radius
      ? (side.angle >= 100 ? 20 : side.angle < 45 ? 36 : 26) +
        (side.radius > 20 ? side.radius / 5 : 0)
      : side.angle >= 100
      ? 20
      : side.angle < 45
      ? 36
      : 26) * _resizedImage.rescale_factor
  );
  pointToAngleText = {
    ...pointToAngleText,
    y: MiddleLineAngle > 0
    ? pointToAngleText.y - 6
    : pointToAngleText.y - 3,
  }
  return pointToAngleText;
};

export const getSideAngle = (side, rebarShapeMode, isRotatable) => {
  let sideAngle;
  if (rebarShapeMode === SHAPE_MODE.catalog) {
    sideAngle = isRotatable ? transfromAngle(side.line_angle) : side.line_angle;
  } else {
    sideAngle = side.line_angle;
  }
  return sideAngle;
};

export const isOuter = (rebarShapeMode, shapeObj, side, index, lines) => {
  let outterInput;
  if (rebarShapeMode === SHAPE_MODE.catalog) {
    outterInput =
      side.outer ||
      (index >= 1 &&
        !shapeObj.rebar_side[index - 1].change_previous_point &&
        shapeObj.rebar_side[index - 1].outer);
  } else if (
    rebarShapeMode === SHAPE_MODE.freeform &&
    index < lines.length - 1
  ) {
    let line1 = lines[index];
    let line2 = lines[index + 1];

    const smallerAngle = getSmallerAngle(line1, line2);
    const fullAngle = getFullAngle(line1, line2);

    if (smallerAngle !== fullAngle) {
      outterInput = false;
    } else {
      outterInput = true;
    }
  } else if (
    rebarShapeMode === SHAPE_MODE.freeform &&
    lines?.length > 1 &&
    index === lines?.length - 1
  ) {
    let lastLine = lines[index];
    let previousLine = lines[index - 1];
    const smallerAngle = getSmallerAngle(lastLine, previousLine);
    const fullAngle = getFullAngle(lastLine, previousLine);
    if (smallerAngle === fullAngle) {
      outterInput = false;
    } else {
      outterInput = true;
    }
  }
  return outterInput;
};


export const getRectangleCorners = (x, y, width, height, angleInDegrees) => {
  // Calculate the coordinates of the other three corners
  // let topLeft  = {x, y};
  // let topRight  = calculateNextPoint({x,y},angleInDegrees,width);
  // let bottomLeft  = calculateNextPoint({x,y},angleInDegrees+90,height);
  // let bottomRight  = calculateNextPoint({x: topRight.x, y: topRight.y},angleInDegrees+90,height);
  let pointOne = calculateNextPoint({x,y}, angleInDegrees+90, 5);
  let pointTwo = calculateNextPoint({x,y},angleInDegrees,width);
  let pointThree = calculateNextPoint({x,y},angleInDegrees+90,height);
  let pointFour = calculateNextPoint({x: pointTwo.x, y: pointTwo.y},angleInDegrees+90,height);

  // Find the minimum and maximum x and y values
  let minX = Math.floor(Math.min(pointOne.x, pointTwo.x, pointThree.x, pointFour.x));
  let maxX = Math.floor(Math.max(pointOne.x, pointTwo.x, pointThree.x, pointFour.x));
  let minY = Math.floor(Math.min(pointOne.y, pointTwo.y, pointThree.y, pointFour.y));
  let maxY = Math.floor(Math.max(pointOne.y, pointTwo.y, pointThree.y, pointFour.y));

  // Calculate top-left and bottom-right corners
  let topLeft = { x: minX, y: minY };
  let bottomRight = { x: maxX, y: maxY };

  // Calculate top-right and bottom-left corners
  let topRight = { x: maxX, y: minY };
  let bottomLeft = { x: minX, y: maxY };

  // Reduce the size of calculated rectangle points.
  // Moving all point towards inside the rectangle.
  topLeft = calculateNextPoint({x:topLeft.x, y:topLeft.y}, 45, 6)
  bottomRight = calculateNextPoint({x:bottomRight.x, y:bottomRight.y}, -135, 6)
  topRight = calculateNextPoint({x:topRight.x, y:topRight.y}, 135, 6)
  bottomLeft = calculateNextPoint({x:bottomLeft.x, y:bottomLeft.y}, -45, 6)

  return {
      topLeft,
      topRight,
      bottomLeft,
      bottomRight,
  };
}

function haveIntersection(a, b) {
  return !(
    b.topLeft.x > a.topRight.x ||
    b.topRight.x  < a.topLeft.x ||
    b.topLeft.y > a.bottomRight.y ||
    b.bottomRight.y < a.topLeft.y
  );
}

export const isOverlap = (newBox, existingBoxes) => {
  let maxArea = 0;
  let maxAreaIndex = -1;

  existingBoxes.forEach((existingBox, index) => {
    if (newBox !== existingBox) {
      const area = overlappingArea(newBox, existingBox);
      if (area > maxArea) {
        maxArea = area;
        maxAreaIndex = index;
      }
    }
  });

  return { index: maxAreaIndex, area: maxArea };
};

const overlappingArea = (a, b) => {
  if (!haveIntersection(a,b))
    return 0;

  //  Lengths of sides of overlapping area of rectangles
  let x_dist = Math.min(a.topRight.x, b.topRight.x) - Math.max(a.topLeft.x, b.topLeft.x);
  let y_dist = Math.min(a.bottomLeft.y, b.bottomLeft.y) - Math.max(a.topLeft.y, b.topLeft.y);

  let areaI = 0;
  if (x_dist > 0 && y_dist > 0) {
      areaI = x_dist * y_dist;
  }
  return areaI;
}