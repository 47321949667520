import { createSlice } from '@reduxjs/toolkit'

export interface SupplierState {
  suppliers: any[];
  ticketOrderDetails: any[];
  ticketOrderNets: any[];
  ticketOrderPiles: any[];
  completeOrderDetails: any[];
  supplierStatus: any;
};

const initialState: SupplierState = {
  suppliers: [],
  ticketOrderDetails: [],
  ticketOrderPiles: [],
  ticketOrderNets: [],
  completeOrderDetails: [],
  supplierStatus: {},
};

export const suppliersSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {
    setSuppliers: (state, action) => {
      return {
        ...state,
        suppliers: action.payload,
      };
    },
    ticketOrder: (state, action: PayloadAction<any>) => {
      if (action.payload.length === 0) {
        state.ticketOrderDetails = [];
        state.completeOrderDetails = [];
      } else {
        state.ticketOrderDetails = action.payload[0].order_item;
        state.completeOrderDetails = action.payload[0];
      }
    },
    ticketOrderNets: (state, action: PayloadAction<any>) => {
      state.ticketOrderNets = action.payload;
    },
    ticketOrderPiles: (state, action) => {
      state.ticketOrderPiles = action.payload;
    },
    setSupplierStatus: (state, action) => {
      state.supplierStatus = action.payload;
    },
    updateSupplierStatus: (state, action) => {
      state.supplierStatus.status = action.payload.data.status;
      state.supplierStatus.printing_count = action.payload.data.printing_count;
      state.supplierStatus.package_status = action.payload.data.package_status;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSuppliers,
  ticketOrder,
  setSupplierStatus,
  updateSupplierStatus,
  ticketOrderNets,
  ticketOrderPiles,
} = suppliersSlice.actions;

export default suppliersSlice.reducer;