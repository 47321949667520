import React from "react";
import brand_logo from "../../assets/img/logo.svg";
import facebook from "../../assets/img/socials/fb.svg";
import linkedin from "../../assets/img/socials/lin.svg";
import whatsapp from "../../assets/img/socials/whatsapp.svg";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "Redux/hooks";
import { Link } from "react-router-dom";

function Footer() {
  const { t } = useTranslation(["common", "footer"]);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  return (
    <footer className="d-none d-lg-block">
      <div className="container">
        <div className="row justify-content-lg-between">
          {/* <!-- left section  --> */}
          <div className="col-4 brand__slogan">
            <div className="wrap">
              <img className="brand__icon" src={brand_logo} alt="brand icon" />
              <h3
                className="text-uppercase text-white"
                style={{ fontWeight: 400 }}
              >
                COMMUNICATING REBARS
              </h3>
            </div>
          </div>
          {/* <!-- right side  --> */}
          <div className="col-8 link__list">
            <div className="row">
              <div className="col-lg-4">
                <h3 className="title text-uppercase text-white">
                  {t("support", { ns: "footer" })}
                </h3>
                <ul className="list-style-none m-0 p-0">
                  <li>
                    <a
                      href="https://easybar.co.il/#qa"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("question", { ns: "footer" })}
                    </a>
                  </li>
                  <li>
                    <a href="mailto: support@easybar.co.il">
                      {t("contact us", { ns: "footer" })}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4">
                <h3 className="title text-uppercase text-white">
                  {t("partners", { ns: "footer" })}
                </h3>
                <ul className="m-0 p-0">
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.easybar.co.il/comingsoon"
                    >
                      {t("add as supplier", { ns: "footer" })}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4">
                <h3 className="title text-uppercase text-white">
                  {t("talk to us", { ns: "footer" })}
                </h3>
                <a href="mailto: support@easybar.co.il">
                  <h3 className="title text-uppercase text-white">
                    support@easybar.co.il
                  </h3>
                </a>
                <div className="d-flex">
                  <a
                    href="https://www.facebook.com/Easybar2022"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={facebook} alt="fb icon" />
                  </a>
                  <a
                    style={
                      rightToLeft
                        ? { marginRight: "10px" }
                        : { marginLeft: "10px" }
                    }
                    rel="noreferrer"
                    href="http://www.linkedin.com/company/80744605"
                    target="_blank"
                  >
                    <img src={linkedin} alt="linkedin icon" />
                  </a>
                  <a
                    style={
                      rightToLeft
                        ? { marginRight: "10px" }
                        : { marginLeft: "10px" }
                    }
                    rel="noreferrer"
                    href="https://wa.me/972508454847"
                    target="_blank"
                  >
                    <img src={whatsapp} alt="whatsapp icon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-center text-white pb-2"
        style={{ fontSize: "13px" }}
      >
        <div className="d-flex" style={{ columnGap: "12px" }}>
          <Link to="/terms-and-conditions">Terms &#38; Conditions</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <span>{new Date().getFullYear()} &#169; EasyBar</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
