import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import rootStateReducer from './features/root';
import rebarReducer from './features/rebar';
import pileReducer from './features/pile';
import netReducer from './features/net';
import steelTypeReducer from './features/steelType';
import userReducer from './features/user';
import businessReducer from './features/business';
import contactReducer from './features/contact';
import projectReducer from './features/project';
import orderReducer from './features/order';
import supplierReducer from './features/suppliers';
import priceListReducer from './features/priceList';

const appReducer = combineReducers({
  root: rootStateReducer,
  rebar: rebarReducer,
  pile: pileReducer,
  net: netReducer,
  steelType: steelTypeReducer,
  user: userReducer,
  business: businessReducer,
  project: projectReducer,
  contact: contactReducer,
  order: orderReducer,
  supplier: supplierReducer,
  priceList: priceListReducer,
})

const rootReducer = (state: any, action: any) => {
  if (action.type === 'user/logoutUser') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
}

export const store = configureStore({
  reducer: rootReducer,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, unknown, AnyAction>