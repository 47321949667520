import React from "react";
import { useSortable } from "@dnd-kit/sortable";

import "../index.styled.scss";

const StaticRebarsCard = (props) => {
  const { item, index, edit } = props;

  const { isDragging } = useSortable({
    id: props.id,
  });

  return (
    <div
      className={"cardItem_Rebars static-table_row " + (edit ? " active" : "")}
      key={"defaultRebars" + index}
      style={
        !isDragging
          ? {
              cursor: "grabbing",
              position: "relative",
            }
          : { cursor: "grab", position: "relative" }
      }
    >
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <img
          src={item.preview_image.file_field}
          style={{ width: "100%", height: "auto" }}
          alt="Rebar sketch"
        />
      </div>
    </div>
  );
};

export default StaticRebarsCard;
