export const commonEn = {
  "Delivery Date": "Delivery Date",
  "Phone": "Phone",
  "Address": "Address",
  "Number": "Number",
  "Mail": "Mail",
  "Site Man": "Site Man",
  "User": "User",
  "Rebars": "Rebars",
  "Nets": "Nets",
  "Cages": "Cages",
  "Rebar": "Rebar",
  "Net": "Net",
  "Cage": "Cage",
  "type": "type",
  "diameter": "diameter",
  "length": "length",
  "Amount": "Amount",
  "total": "total",
  "weight": "weight",
  "unit": "unit",
  "units": "units",
  "Price": "Price",
  "Step": "Step",
  "Standard": "Standard",
  "Special": "Special",
  "Save": "Save",
  "Print": "Print",
  "Delete": "Delete",
  "cancel": "cancel",
  "submit": "Submit",
  "required field": "Required field",
  "Full name": "full name",
  "Job": 'Job',
  "Name": "name",
  "City": "city",

  "sides": "Sides",
  "name": "Name",
  "mobileVersionHiddenForNow":"Mobile version is on her way, meanwhile you can see us on desktop.",

  "quantity": "Quantity",

  "user details": "user details",

  "type password": "Type your password",
  "type phone number": "Type your phone number",
  "type job": "Type your job",
  "type full name": "Type your full name",
  "type email": "Type your email",
  "row": "Row",
  "download": "Download",
  "Preview": "Preview",

  'forget pass': 'Forget Password?',

  'email send': 'An email has been sent on',
  'email check': 'Please check your email',

  "dont delete": "Cancel",
  "confirm delete": "Delete Order",
  "email sent": "Email Sent",
  "welcome": "Welcome",
  "ok": "OK",
  "share": "Share",
  "note": "Note",
  "edit": "Edit",
  "sure": "sure",
  "weight unit": "Weight (kg)",
  "custom": "custom",
  "gap": "gap",
  "steel type": "steel type",
  "details": "Details",

  "download pdf": "download PDF",
  "send to supplier": "send to supplier",
  "print tickets": "print tickets",
  "delete order": "delete order",
  "send msg": "send message",

  //translations
  "thank": "thank you",
  "bid":"get bid",
  "Bid":"Get bid",
  "not now":"not now",
  "request":"The request was accepted. The best offer will be send to your WhatsApp.",
  "placeholder":"you can write delivery needs, extra needs, or anything we need to know for give you the right offer.",
  "bid question" : "would you like to get bid?",
  
  "confirm": "confirm",
  "Steel type": "Steel Type",
  "add": "Add",
  "update": "Update",

  "lengths": "lengths",
  "angles": "angles",
  "shapes": "shapes",
  "rebar name" : "rebar name",
  "symmetric": "Symmetrical",
  "asymmetric": "Asymmetrical",

  "extras" : "extras",
  "edge" : "edge",
  "base": "base",
  "side": "side",
  "ears": "ears",
  "rings": "rings",
  "gamma pipes": "gamma pipes",
  "cardinal": "cardinal",
  "internal": "internal",
  "amount": "amount",

  "diam": "diam",
  "qunt": "qunt",
  "radius": "radius",
};