import { Modal } from "react-bootstrap";
import Tool from "../tool-new";

function NewItem({
  show,
  onHide,
  tab,
  setTab,
  edit,
  setEditTool,
  selectedTool,
  setSelectedTool,
  editOrderId,
  editMode,
  order,
  editToolId,
}: any) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-new-item-custome"
    >
      <Tool
        onHide={onHide}
        tab={tab}
        setTab={setTab}
        edit={edit}
        setEditTool={setEditTool}
        selectedTool={selectedTool}
        setSelectedTool={setSelectedTool}
        editOrderId={editOrderId}
        editMode={editMode}
        order={order}
        editToolId={editToolId}
      />
    </Modal>
  );
}

export default NewItem;
