import { useState, useRef, useMemo } from "react";
import deleteIcon from "../../../assets/img/save_export/delete.svg";
import downloadIcon from "../../../assets/img/save_export/download.svg";
import printerIcon from "../../../assets/img/save_export/printer.svg";
import previewIcon from "../../../assets/img/save_export/preview.svg";
import shareIcon from "../../../assets/img/save_export/share.svg";
import error from "../../../assets/img/save_export/warning.svg";
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import { Overlay, Tooltip } from "react-bootstrap";
import { getCurrentOrderTools } from "Redux/middlwares/order";
import "./save-preview.scss";

const ManageOrder = ({ ...props }: any) => {
  const {
    setDeleteMessageShow,
    setSaveOrderMessageShow,
    setSaveBeforeShareShow,
    errorText,
    setErrorText,
    openPdfPreview,
    setDownloadFlag,
    order,
    activeProject,
    activeBusiness,
    setPrintCanvas,
    edit,
    editOrderId,
    saveOrder,
    handleBidClicked,
    setOrderSaved,
  } = props;

  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "newOrder"]);
  const userData = useAppSelector(state => state.user.userData);
  const [showDelTooltip, setShowDelTooltip] = useState(false);
  const [showPrevTooltip, setShowPrevTooltip] = useState(false);
  const [showPrintTooltip, setShowPrintTooltip] = useState(false);
  const [showDownTooltip, setShowDownTooltip] = useState(false);
  const [showShareTooltip, setShowShareTooltip] = useState(false);
  const targetDel = useRef(null);
  const targetPrev = useRef(null);
  const targetPrint = useRef(null);
  const targetDown = useRef(null);
  const targetShare = useRef(null);

  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const currentOrderRebars = useAppSelector(state => state.rebar.currentOrderRebars);
  const currentOrderNets = useAppSelector(state => state.net.currentOrderNets);
  const currentOrderPiles = useAppSelector(state => state.pile.currentOrderPiles);

  const [bidBtnClicked] = useState(true)
  const [disableSave, setDisableSave] = useState(false);

  const isExportOptionsDisabled = useMemo(() => {
    if (
      currentOrderRebars.length === 0 &&
      currentOrderNets.length === 0 &&
      currentOrderPiles.length === 0
    ) {
      return true;
    } else return false;
  }, [
    currentOrderRebars.length,
    currentOrderNets.length,
    currentOrderPiles.length,
  ]);

  const orderSave = (checkSaveOption:boolean) => {
    if(order.name === ''){
      setErrorText(t("add order name", {ns: "newOrder"}));
      return;
    }

    if (order.created === '') {
      setErrorText(t("add created date", { ns: "newOrder" }));
      return;
    }

    if (order.delivery_date === '') {
      setErrorText(t("add delivery date", { ns: "newOrder" }));
      return;
    }

    if (!('id' in activeProject)) {
      setErrorText(t("add project", { ns: "newOrder" }));
      return;
    }

    if (!userData.current_company) {
      setErrorText(t("add a company", { ns: "newOrder" }));
      return;
    }
    
    const netIds = [...currentOrderNets].map((item: any) => item.id);
    const pileIds = [...currentOrderPiles].map((item: any) => item.id);
    const rebarIds = [...currentOrderRebars].map((item: any) => item.id);

    if (
      netIds.length === 0
      && pileIds.length === 0
      && rebarIds.length === 0
    ) {
      setErrorText(t("add one tool", { ns: "newOrder" }));
      return;
    }

    if (checkSaveOption) {
      setDisableSave(true);
      saveOrder()
        .then(() => {
          setDisableSave(false);
          setOrderSaved(true);
        })
        .catch(() => {
          setDisableSave(false);
          setOrderSaved(false);
        });
    } else {
      setSaveOrderMessageShow(true);
      setSaveBeforeShareShow(true);
    }
  };


  //function for calling order's note popup
  const getBid = (checkSaveOption:boolean) => {
    if(order.name === ''){
      setErrorText(t("add order name", {ns: "newOrder"}));
      return;
    }

    if (order.created === '') {
      setErrorText(t("add created date", { ns: "newOrder" }));
      return;
    }

    if (order.delivery_date === '') {
      setErrorText(t("add delivery date", { ns: "newOrder" }));
      return;
    }

    if (!('id' in activeProject)) {
      setErrorText(t("add project", { ns: "newOrder" }));
      return;
    }

    if (!userData.current_company) {
      setErrorText(t("add a company", { ns: "newOrder" }));
      return;
    }

    const netIds = [...currentOrderNets].map((item: any) => item.id);
    const pileIds = [...currentOrderPiles].map((item: any) => item.id);
    const rebarIds = [...currentOrderRebars].map((item: any) => item.id);

    if (
      netIds.length === 0
      && pileIds.length === 0
      && rebarIds.length === 0
    ) {
      setErrorText(t("add one tool", { ns: "newOrder" }));
      return;
    }

    if(checkSaveOption) {
      handleBidClicked(bidBtnClicked);
    }else{
      setSaveOrderMessageShow(true);
      setSaveBeforeShareShow(true);
    }
  };

  const downloadPdf = () => {
    dispatch(getCurrentOrderTools(edit,editOrderId)).then(() => {
      setDownloadFlag(true);
      openPdfPreview();
    });
  }

  const printCanvas = () => {
    dispatch(getCurrentOrderTools(edit, editOrderId)).then(() => {
      setPrintCanvas(true);
      openPdfPreview();
    });
  };

  const previewPdf = () => {
    dispatch(getCurrentOrderTools(edit, editOrderId)).then(() => {
      openPdfPreview();
    });
  }

  const shareOrder = () => {
    dispatch(getCurrentOrderTools(edit, editOrderId)).then(() => {
      orderSave(false);
    });
  }

  return (
    <section id="order__summary" className="mb-5 mt-2">
      <div className="container p-0" style={{ display: "block" }}>
        <div className="error__wrapper">
          {errorText && (
            <div className="d-flex justify-content-center align-content-center">
              <img className="save_error_img" src={error} alt={"error"} />
              <p className="save__error">{errorText}</p>
            </div>
          )}
        </div>
        <div className="export__wrapper">
          <div className="text__area"></div>
          <div className="icon__area">
            <button
              className={
                "export__icons " +
                (rightToLeft ? "icons__right" : "icons__left")
              }
              onClick={() => setDeleteMessageShow(true)}
              ref={targetDel}
              onMouseEnter={() => setShowDelTooltip(true)}
              onMouseLeave={() => setShowDelTooltip(false)}
              disabled={isExportOptionsDisabled}
            >
              <img className="m__icon" src={deleteIcon} alt="delete icon" />
            </button>
            <Overlay
              target={targetDel.current}
              show={showDelTooltip}
              placement="top"
            >
              {(props) => (
                <Tooltip id="overlay-example-1" {...props}>
                  {t("Delete", { ns: "common" })}
                </Tooltip>
              )}
            </Overlay>
            <button
              className={
                "export__icons " +
                (rightToLeft ? "icons__right" : "icons__left")
              }
              onClick={previewPdf}
              ref={targetPrev}
              onMouseEnter={() => setShowPrevTooltip(true)}
              onMouseLeave={() => setShowPrevTooltip(false)}
              disabled={isExportOptionsDisabled}
            >
              <img className="m__icon" src={previewIcon} alt="preview icon" />
            </button>
            <Overlay
              target={targetPrev.current}
              show={showPrevTooltip}
              placement="top"
            >
              {(props) => (
                <Tooltip id="overlay-example-2" {...props}>
                  {t("Preview", { ns: "common" })}
                </Tooltip>
              )}
            </Overlay>
            <button
              className={
                "export__icons " +
                (rightToLeft ? "icons__right" : "icons__left")
              }
              ref={targetPrint}
              onMouseEnter={() => setShowPrintTooltip(true)}
              onMouseLeave={() => setShowPrintTooltip(false)}
              disabled={isExportOptionsDisabled}
              onClick={printCanvas}
            >
              <img className="m__icon" src={printerIcon} alt="printer icon" />
            </button>
            <Overlay
              target={targetPrint.current}
              show={showPrintTooltip}
              placement="top"
            >
              {(props) => (
                <Tooltip id="overlay-example-3" {...props}>
                  {t("Print", { ns: "common" })}
                </Tooltip>
              )}
            </Overlay>
            <button
              className={
                "export__icons " +
                (rightToLeft ? "icons__right" : "icons__left")
              }
              onClick={downloadPdf}
              ref={targetDown}
              onMouseEnter={() => setShowDownTooltip(true)}
              onMouseLeave={() => setShowDownTooltip(false)}
              disabled={isExportOptionsDisabled}
            >
              <img className="m__icon" src={downloadIcon} alt="download icon" />
            </button>
            <Overlay
              target={targetDown.current}
              show={showDownTooltip}
              placement="top"
            >
              {(props) => (
                <Tooltip id="overlay-example-4" {...props}>
                  {t("download", { ns: "common" })}
                </Tooltip>
              )}
            </Overlay>
            <button
              className={
                "export__icons " +
                (rightToLeft ? "icons__right" : "icons__left")
              }
              onClick={shareOrder}
              ref={targetShare}
              onMouseEnter={() => setShowShareTooltip(true)}
              onMouseLeave={() => setShowShareTooltip(false)}
              disabled={isExportOptionsDisabled}
            >
              <img className="m__icon" src={shareIcon} alt="share icon" />
            </button>
            <Overlay
              target={targetShare.current}
              show={showShareTooltip}
              placement="top"
            >
              {(props) => (
                <Tooltip id="overlay-example-4" {...props}>
                  {t("share", { ns: "common" })}
                </Tooltip>
              )}
            </Overlay>
            {/* <button
              className={rightToLeft ? "btn__left" : "bid__btn"}
              onClick={() => getBid(true)}
              disabled={isExportOptionsDisabled}
            >
              {t("Bid", { ns: "common" })}
            </button> */}

            <button
              className="save__btn text-center"
              onClick={() => orderSave(true)}
              disabled={isExportOptionsDisabled || disableSave}
            >
              {t("Save", { ns: "common" })}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ManageOrder;
