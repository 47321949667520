import { Modal } from 'react-bootstrap';
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import "../index.scss"

function PrintCard({ ...props }) {
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const { t } = useTranslation(['ticketCreator']);
  
  const {
    show,
    onHide,
    close,
    nextpopup
  } = props

  const handleCLick = ()=> {
    close()
    nextpopup()
  }
  return (
    <>
      <Modal
        show = {show}
        onHide = {onHide}
        className="supplier_popups"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <>
          <div className="container popup_container">
            <div className="row popup_header">
            </div>
            <div className="popup_content">
              <h1 className={"py-5 " +  (rightToLeft ? "popup_heading" : "popup_heading_heebo")}  >
              {t("rebar_supplier", { ns: "ticketCreator" })}<br />{t("print_card", { ns: "ticketCreator" })}
              </h1>
                <button className="popup_button mb-5" onClick={handleCLick}>
                  {t("start_trial", { ns: "ticketCreator" })}
                </button>
                <a href='https://www.easybar.co.il/suppliers' target="_blank" rel="noreferrer">
                  <p className="popup_details pb-5">
                    {t("more_details", { ns: "ticketCreator" })}
                  </p>
                </a>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}

export default PrintCard;
