export const pdfOrderEn = {

    'order created by': "THIS ORDER WAS CREATED BY",
    'request quote': "REQUEST FOR QUOTE",
    'last updated': "Last updated",
    'number of pages': "Number Of pages",
    'document number': "Document number",
    'client': 'Client',

    'phone office': 'Phone Office',
    'mail': 'Mail',
    'document creater': 'Document Creator',
    'address': 'Address',
    'delivery details': 'Delivery details',
    'delivery date request': 'Delivery date request',
    'site address': 'Site adress',
    'site man one': 'Site man contact 1',
    'site man two': 'Site man contact 2',
    'arrival notes': 'Arrival notes',
    'order notes': 'Order notes',

    "sketch": "Sketch",
    "supplier": "Supplier",
    "delivery date confirmation": "Confirmation of delivery date",
    'signature': "Signature",

    'qty': 'Qty',
    'lengths': 'Lengths',
    'order Summary': "Order Summary",
    "standard nets": "Standard Nets",
    "special nets": "Special Nets",
    "length": "length",
    "gap" : "Gap",
    "dmeter": "dmeter",
    "order name": "order name",
    "project name": "project name",
    "steel type": "steel type",

    "name": "name",

    "ear": "ear",
    "gama": "gama",
    "ring": "ring",
    "pile cage": "Pile Cage",

}