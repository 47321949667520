export const myOrderEn = {
    "orders created": "orders created",
    "this week": "this week",
    "sites delivery": "sites delivery",
    "for this week": "for this week",
    "no delivery": "no delivery",

    
    "my order" : "my order",
    "create order": "create new order",
    "date order create": "Creation date",
    "order": "Order name",
    "order no": "Order number",
    "site": "Site",
    "total weight": "Total weights (Ton)",
    "Order Status": "Order Status",
    "delivery date": "Delivery date",
    "next delivery": "next delivery",
    "add new": "add new",
    "weight": "weight",

    "my sites": "my sites",
    "add new site": "add new site",
    "site name": "site name",
    "address": "address",
    "site manager": "site manager",
    "site man": "site man",
    "contact on site": "contact on site",

    "contact": "contact",
    "add new contact": "add new contact",
    "contacts": "contacts",
    "name" : "name",

    "phone number": "phone number",
    "job title": "job title",
    'create new contact': 'create new contact',
    'contact details': 'Contact details',
    'required fields': 'Required fields',
    'delete this contact': 'Delete this contact',

    'completed' : 'completed',
    
}