import { setSteelTypes, addSteelDiameters, addAdminSteelDiameters, removeAdminSteelType, updateSteelTypeIns } from "Redux/features/steelType";
import { AppThunk } from 'Redux/store';
import { isAxiosResponse } from "Services/index";
import { deleteSteelType, getSteelDiameters, getSteelTypes, updateSteelType } from "Services/steelType";

export const fetchSteelTypes = (): AppThunk => async dispatch => {
  const response = await getSteelTypes();
  if (isAxiosResponse(response)) {
    await dispatch(setSteelTypes(response.data.results));
  }
}

export const fetchSteelDiameters = (steelID: number, next = (val: any[]) => {}): AppThunk => async (dispatch, getState) => {
  try {
    const stateSteelDiameters = getState().steelType.steelDiameters;
    if (!(steelID in Object.keys(stateSteelDiameters))) {
      const response = await getSteelDiameters(steelID);
      if (isAxiosResponse(response)) {
        const weightsWithDi = response.data.sort((a: any, b: any) => a.steel_diameter - b.steel_diameter)
        await dispatch(addSteelDiameters({
          steelID,
          steelDiameters: weightsWithDi
        }));
        next(weightsWithDi);
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export const fetchAdminSteelDiameters = (steelID: number): AppThunk => async (dispatch, getState) => {
  try {
    const response = await getSteelDiameters(steelID);
    if (isAxiosResponse(response)) {
      await dispatch(addAdminSteelDiameters(response.data));
    }
  } catch (err) {
    console.log(err);
  }
}

export const removeSteelType = (steelTypeID: number): AppThunk => async dispatch => {
  const response = await deleteSteelType(steelTypeID);
  if (isAxiosResponse(response)) {
    await dispatch(removeAdminSteelType(steelTypeID));
  }
}

export const updateSteelTypeName = (steelTypeID: number, data: any): AppThunk => async dispatch => {
  const response = await updateSteelType(steelTypeID, data);
  if (isAxiosResponse(response)) {
    await dispatch(updateSteelTypeIns(response.data));
  }
}
