export const REBAR_SUBCLASS_TYPE = 'rebar';

export const labels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'];
export const angles = ['a', 'b', 'c', 'd', 'e', 'f', 'g'];

export const rebarsWithoutPriceHead = {
  gridTemplateColumns: '4% 28% 10.5% 10.5% 10.5% 10.5% 8% 18%',
};
export const rebarsWithoutPriceBody = {
  gridTemplateColumns: '4% 28% 10.5% 10.5% 10.5% 10.5% 8% 9% 9%',
};

export const rebarsWithPrice = {
  gridTemplateColumns: '2.5fr 2fr 4fr 4fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr',
};

export const lastRebarsWithoutPrice = {
  gridTemplateColumns: '1fr 5fr 2fr 2fr 2fr 8% 18%',
};


export const lastRebarsWithPrice = {
  gridTemplateColumns: '2.5fr 2fr 4fr 4fr 1.5fr 3fr 3fr',
};

export const polygonSteelType = 9;
export const roundSteelType = 3;

export const rebarErrorTypes = {
  STEEL_TYPE: 1,
  DIAMETER: 2,
  AMOUNT: 3,
  SIDE: 4,
  RADIUS: 5,
  TOTAL_LENGTH: 6,
  EAR: 7,
  RADIUS_MAX: 8,
}

export const sideMinLength = 5; //cm
export const sideMaxLength = 2401; //cm
export const radiusMin = 10;
export const ALL_Filter = 'All';

export const rebarSidebarInput = {
  REBAR_NAME: 1,  
  DIAMETER: 2,
  STEEL_TYPE: 3,
  HEIGHT: 4,
  NOTE: 5,
  QUANTITY: 6,
};

export const standardDiameter = {
  id: 24,
  steel_diameter: 5,
  steel_diameter_id: 1,
  steel_type: 9,
  weight: "0.154",
}

export const SHAPE_MODE = {
  catalog: "CATALOG_SHAPE",
  freeform: "FREE_FORM_SHAPE",
};