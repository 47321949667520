import axios from 'axios';

const geocoding = "AIzaSyCDvzSe6P4MpVoh0ImQINXVeHwMr1UXOwA";

export const decodingAddress = async (address: any) => {
  const response = await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${geocoding}`
  );
  return response.data;
};

export const getAddresByGeo = async (data: any) => {
   const response = await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${data.lat}, ${data.lng}&key=${geocoding}`
  );
  return response.data;
}