import { useRef, useState } from "react";
import { useAppSelector } from "Redux/hooks";
import { commaSeparated, removeBgBorder } from "Constants/general";
import diameterIcon from "../../../../assets/img/icon/diameter-blue.svg";
import steelTypeIcon from "../../../../assets/img/icon/steeltype-new.svg";
import previewNet from "../../../../assets/img/nets/view_net.svg";
import infoIcon from "../../../../assets/img/icon/info.svg";
import {
  lastNetsTicketWithoutPrice,
  lastNetsTicketWithPrice,
  netsTicketWithoutPrice,
  netsTicketWithPrice,
} from "Constants/net-constants";
import { useTranslation } from "react-i18next";
import { Overlay, Tooltip } from "react-bootstrap";
import TicketCounter from "Components/ticket-creator/ticket-counter";

const NetsOrderTable = ({
  priceShow,
  handleImagePreview,
  netUnits,
  netTons,
  netPrice,
  selectedRowCount,
  setSelectedRowCount,
}: any) => {
  const { t } = useTranslation(["newOrder", "common", "measurements", "tool"]);
  const currentOrderNets = useAppSelector(
    (state) => state.supplier.ticketOrderNets
  );
  const steelTypes = useAppSelector((state) => state.steelType.steelTypes);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  const [showMeasureTooltip, setShowMeasureTooltip] = useState(false);
  const [showNetTypeTooltip, setShowNetTypeTooltip] = useState(false);
  const measurementRef = useRef(null);
  const netTypeRef = useRef(null);

  return (
    <section id="nets__table" className="order__tables ">
      <div className="container p-0">
        <div className="table__wrapper">
          {/* <!-- table header  --> */}
          <div className="table__header d-flex flex-row">
            {/* <!-- table title  --> */}
            <div className="table__title d-flex align-items-center justify-content-center">
              <h2 className="text-center text-white text-uppercase m-0">
                {t("Nets")}
              </h2>
            </div>
          </div>
          {/* <!-- real table  --> */}
          <table
            className={
              "table table mb-0 custom-tool-table " +
              (rightToLeft ? "rightToLeft" : "")
            }
          >
            {/* <!-- table head title section  --> */}
            <thead>
              <tr
                style={priceShow ? netsTicketWithPrice : netsTicketWithoutPrice}
              >
                <th
                  scope="col"
                  className={
                    "text-capitalize d-flex justify-content-center align-items-center p-0 "
                  }
                  style={{textAlign: "center"}}
                >
                  <span
                    className="th_norm"
                    style={{ textAlign: "center" }}
                  >
                    {t("tickets amount", { ns: "ticketCreator" })}
                  </span>
                </th>
                <th
                  scope="col"
                  className={
                    "text-capitalize d-flex justify-content-center align-items-center p-0 " +
                    (rightToLeft ? "dark-border-right" : "dark-border-left")
                  }
                >
                  <span className="th_norm">{t("row", { ns: "common" })}</span>
                </th>
                <th
                  scope="col"
                  className={
                    "text-capitalize d-flex justify-content-center align-items-center p-0 " +
                    (rightToLeft ? "dark-border-right" : "dark-border-left")
                  }
                >
                  {t("sketch", { ns: "newOrder" })}
                </th>

                <th
                  scope="col"
                  className={
                    "text-capitalize d-flex justify-content-center align-items-center p-0 " +
                    (rightToLeft ? "dark-border-right" : "dark-border-left")
                  }
                >
                  {t("Name", { ns: "newOrder" })}
                </th>

                <th
                  scope="col"
                  className={
                    "text-capitalize d-flex align-items-center justify-content-center text-center sm__wrap p-0 " +
                    (rightToLeft ? "dark-border-right" : "dark-border-left")
                  }
                >
                  <img
                    className="hei__icon"
                    src={steelTypeIcon}
                    alt="hei icon"
                    style={{
                      height: "20px",
                      width: "15px",
                    }}
                  />
                  <span className="d-flex align-items-start">
                    <p className={rightToLeft ? "ml-1" : "mr-1"}>
                      {rightToLeft
                        ? t("type", { ns: "common" })
                        : t("steel", { ns: "newOrder" })}
                    </p>
                    <p>
                      {rightToLeft
                        ? t("steel", { ns: "newOrder" })
                        : t("type", { ns: "common" })}
                    </p>
                  </span>
                </th>
                <th
                  scope="col"
                  className={
                    "text-capitalize d-flex align-items-center justify-content-start text-center sm__wrap " +
                    (rightToLeft ? "dark-border-right" : "dark-border-left")
                  }
                >
                  <span className={rightToLeft ? "ml-3" : "mr-3"}>
                    {t("measurements", { ns: "tool" })}
                  </span>
                  <img
                    className="hei__icon"
                    src={infoIcon}
                    alt="hei icon"
                    style={{
                      height: "19px",
                      width: "19px",
                      cursor: "pointer",
                    }}
                    ref={measurementRef}
                    onMouseEnter={() => setShowMeasureTooltip(true)}
                    onMouseLeave={() => setShowMeasureTooltip(false)}
                  />
                  <Overlay
                    target={measurementRef.current}
                    show={showMeasureTooltip}
                    placement="top"
                  >
                    {(props) => (
                      <Tooltip id="overlay-example-2" {...props}>
                        {t("previewMeasInfo", { ns: "newOrder" })}
                      </Tooltip>
                    )}
                  </Overlay>
                </th>
                <th
                  scope="col"
                  className={
                    "text-capitalize d-flex align-items-center justify-content-start text-center " +
                    (rightToLeft ? "dark-border-right" : "dark-border-left")
                  }
                >
                  <span className={rightToLeft ? "ml-3" : "mr-3"}>
                    {t("net type", { ns: "tool" })}
                  </span>
                  <img
                    className="hei__icon"
                    src={infoIcon}
                    alt="hei icon"
                    style={{
                      height: "19px",
                      width: "19px",
                      cursor: "pointer",
                    }}
                    ref={netTypeRef}
                    onMouseEnter={() => setShowNetTypeTooltip(true)}
                    onMouseLeave={() => setShowNetTypeTooltip(false)}
                  />
                  <Overlay
                    target={netTypeRef.current}
                    show={showNetTypeTooltip}
                    placement="top"
                  >
                    {(props) => (
                      <Tooltip id="overlay-example-2" {...props}>
                        {t("previewNetsInfo", { ns: "newOrder" })}
                      </Tooltip>
                    )}
                  </Overlay>
                </th>
                <th
                  scope="col"
                  className={
                    "text-capitalize d-flex align-items-center justify-content-start text-center " +
                    (rightToLeft ? "dark-border-right" : "dark-border-left")
                  }
                >
                  {t("Amount", { ns: "common" })}
                </th>
                <th
                  scope="col"
                  className="text-capitalize dark-border-right dark-border-left"
                  style={{ padding: "0px" }}
                >
                  <div className="two__rows_sec">
                    <div className="top__sec">
                      <span>{t("weight unit", { ns: "common" })}</span>
                    </div>
                    <div
                      className={
                        "d-flex justify-content-start align-items-center bottom_sec h-100 pb-1 " +
                        (rightToLeft
                          ? "bottom_sec-pr text-right"
                          : "bottom_sec-pl text-left")
                      }
                    >
                      <span style={{ width: "50%" }}>
                        {t("unit", { ns: "common" })}
                      </span>
                      <span>{t("total", { ns: "common" })}</span>
                    </div>
                  </div>
                </th>
                {priceShow && (
                  <>
                    <th
                      scope="col"
                      className="text-capitalize d-flex align-items-start justify-content-start text-center flex-column"
                    >
                      <span>{t("unit", { ns: "common" })}</span>
                      <span>
                        <span>{t("Price", { ns: "common" })}</span>
                        <span className="unit__sm">(NIS)</span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="text-capitalize d-flex align-items-start justify-content-start text-center flex-column"
                    >
                      <span>{t("total", { ns: "common" })}</span>
                      <span>
                        <span>{t("Price", { ns: "common" })}</span>
                        <span className="unit__sm">(NIS)</span>
                      </span>
                    </th>
                  </>
                )}
              </tr>
            </thead>
            {/* <!-- table body data section  --> */}
            <tbody
              style={
                currentOrderNets.length > 9
                  ? {
                      height: "720px",
                      overflow: "auto",
                      display: "block",
                      scrollbarWidth: "none",
                    }
                  : {
                    scrollbarWidth: "none",
                  }
              }
            >
              {/* <!-- table data row   --> */}
              {currentOrderNets.length > 0 &&
                currentOrderNets.map((itemNet, index) => (
                  <tr
                    key={"itemNet" + index}
                    style={
                      priceShow
                        ? { ...netsTicketWithPrice, position: "relative" }
                        : { ...netsTicketWithoutPrice, position: "relative" }
                    }
                  >
                    <td
                      style={{ padding: "0px" }}
                      className="d-flex justify-content-center"
                    >
                      <TicketCounter
                        setSelectedRowCount={setSelectedRowCount}
                        selectedRowCount={selectedRowCount}
                        itemId={itemNet.id}
                      />
                    </td>
                    <td
                      className={
                        rightToLeft ? "dark-border-right" : "dark-border-left"
                      }
                      style={{
                        padding: "0px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span className="table__para">{index + 1}</span>
                    </td>
                    <td
                      className={
                        "d-flex justify-content-center align-item-center p-0 " +
                        (rightToLeft ? "dark-border-right" : "dark-border-left")
                      }
                    >
                      <img
                        className="img__icon"
                        src={previewNet}
                        alt="previewNet"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleImagePreview(itemNet.tool.preview_image)
                        }
                      />
                    </td>

                    <td
                      className={
                        "d-flex justify-content-center p-0 " +
                        (rightToLeft ? "dark-border-right" : "dark-border-left")
                      }
                    >
                      <span
                        style={
                          itemNet.tool.name
                            ? itemNet.tool.name.includes(" ")
                              ? { textAlign: "center" }
                              : { wordBreak: "break-all", textAlign: "center" }
                            : {}
                        }
                      >
                        {itemNet.tool.name || ""}
                      </span>
                    </td>

                    <td
                      className={
                        "d-flex justify-content-center p-0 " +
                        (rightToLeft ? "dark-border-right" : "dark-border-left")
                      }
                    >
                      <span>
                        {steelTypes.length > 0 &&
                          steelTypes.find(
                            (item) => item.id === itemNet.tool.steel_type
                          ) &&
                          steelTypes.find(
                            (item) => item.id === itemNet.tool.steel_type
                          ).name}
                      </span>
                    </td>
                    <td
                      className={
                        rightToLeft ? "dark-border-right" : "dark-border-left"
                      }
                    >
                      <span
                        style={
                          itemNet.production_notes
                            ? { paddingBottom: "16px" }
                            : {}
                        }
                        className="d-flex justify-content-center align-items-center"
                      >
                        <span
                          className={
                            "d-flex justify-content-center text__bold " +
                            (rightToLeft ? "ml-4" : "mr-4")
                          }
                        >
                          {itemNet.tool.x_length}
                        </span>
                        <span
                          style={{ width: "70px" }}
                          className={rightToLeft ? "ml-4" : "mr-4"}
                        >
                          {itemNet.tool.x_gap === itemNet.tool.y_gap &&
                          itemNet.tool.x_diameter.id ===
                            itemNet.tool.y_diameter.id ? (
                            ""
                          ) : (
                            <span className="d-flex justify-content-between align-items-center">
                              <img
                                className="hei__icon"
                                src={diameterIcon}
                                alt="hei icon"
                              />
                              <span>
                                {itemNet.tool.x_diameter.steel_diameter}
                              </span>
                              <span
                                className="hei__icon"
                                style={{ width: "15px", color: "#353d4a" }}
                              >
                                <strong>@</strong>
                              </span>
                              {itemNet.tool.x_gap}
                            </span>
                          )}
                        </span>
                        <span
                          className={
                            "d-flex justify-content-center text__bold " +
                            (rightToLeft ? "ml-4" : "mr-4")
                          }
                        >
                          <strong>{itemNet.tool.y_length}</strong>
                        </span>
                        <span style={{ width: "90px" }}>
                          <span className="d-flex justify-content-between align-items-center">
                            <img
                              className="hei__icon"
                              src={diameterIcon}
                              alt="hei icon"
                            />
                            <span>
                              {itemNet.tool.y_diameter.steel_diameter}
                            </span>
                            <span
                              className="hei__icon"
                              style={{ width: "15px", color: "#353d4a" }}
                            >
                              <strong>@</strong>
                            </span>
                            {itemNet.tool.y_gap}
                          </span>
                        </span>
                      </span>
                    </td>
                    <td
                      className={
                        "d-flex justify-content-start " +
                        (rightToLeft ? "dark-border-right" : "dark-border-left")
                      }
                    >
                      {itemNet.tool.standard_net ? (
                        <span
                          style={
                            itemNet.production_notes
                              ? { paddingBottom: "16px" }
                              : {}
                          }
                        >
                          {t("Standard", { ns: "common" })}
                        </span>
                      ) : (
                        <span
                          style={
                            itemNet.production_notes
                              ? { paddingBottom: "16px" }
                              : {}
                          }
                        >
                          {t("Special", { ns: "common" })}
                        </span>
                      )}
                    </td>
                    <td
                      className={
                        "d-flex justify-content-start " +
                        (rightToLeft ? "dark-border-right" : "dark-border-left")
                      }
                    >
                      <span>{itemNet.quantity}</span>
                    </td>
                    <td className="d-flex justify-content-start dark-border-right dark-border-left">
                      <span style={{ width: "50%" }}>
                        {itemNet.tool.unit_weight
                          .toString()
                          .replace(commaSeparated, ",")}
                      </span>
                      <span>
                        {(itemNet.tool.unit_weight * itemNet.quantity)
                          .toFixed(2)
                          .toString()
                          .replace(commaSeparated, ",")}
                      </span>
                    </td>
                    {priceShow && (
                      <>
                        <td className="justify-content-start">
                          <span>
                            {itemNet.unit_price
                              ? parseFloat(itemNet.unit_price)
                                  .toFixed(2)
                                  .toString()
                                  .replace(commaSeparated, ",")
                              : "-"}
                          </span>
                        </td>
                        <td className="justify-content-start">
                          <span>
                            {itemNet.unit_price
                              ? (
                                  parseFloat(itemNet.unit_price) *
                                  itemNet.quantity
                                )
                                  .toFixed(0)
                                  .toString()
                                  .replace(commaSeparated, ",")
                              : "-"}
                          </span>
                        </td>
                      </>
                    )}
                    {itemNet.production_notes && (
                      <td
                        colSpan={1}
                        className="light-border-top note-td"
                        style={{
                          padding: "0px",
                          position: "absolute",
                          marginTop: "56px",
                          alignItems: "normal",
                          justifyContent: "flex-start",
                          width: priceShow ? "68%" : "34.9%",
                          ...(rightToLeft
                            ? {
                                right: priceShow
                                  ? "250px"
                                  : "calc(100% - 58.9%)",
                                textAlign: "right",
                              }
                            : {
                                left: priceShow
                                  ? "250px"
                                  : "calc(100% - 58.9%)",
                              }),
                        }}
                      >
                        <p style={{ margin: "0 5px", fontSize: "15px" }}>
                          {t("note", { ns: "common" })}:{" "}
                          {itemNet.production_notes}
                        </p>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
            <tbody className="last">
              <tr
                style={
                  priceShow
                    ? lastNetsTicketWithPrice
                    : lastNetsTicketWithoutPrice
                }
              >
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={removeBgBorder}></td>
                <td className="justify-content-center p-0">{netUnits}</td>
                <td className="justify-content-center p-0" colSpan={2}>
                  {netTons}
                </td>
                {priceShow && <td colSpan={2}>{netPrice}</td>}
              </tr>
              <tr
                style={
                  priceShow
                    ? lastNetsTicketWithPrice
                    : lastNetsTicketWithoutPrice
                }
              >
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={removeBgBorder}></td>
                <td className="justify-content-center p-0">
                  {t("units", { ns: "common" })}
                </td>
                <td
                  className="justify-content-center p-0"
                  colSpan={2}
                  style={{ textTransform: "capitalize" }}
                >
                  {t("weight", { ns: "common" })} (
                  {t("ton", { ns: "measurements" })})
                </td>
                {priceShow && (
                  <td colSpan={2}>{t("Price", { ns: "common" })} (NIS)</td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default NetsOrderTable;
