import { REBAR_SUBCLASS_TYPE } from "Constants/rebar-constants";
import { NET_SUBCLASS_TYPE } from "Constants/net-constants";
import { PILE_SUBCLASS_TYPE } from "Constants/pile-constants";
import { AppThunk } from 'Redux/store';
import { isAxiosResponse } from "Services/index";
import {
  getSuppliers,
  fetchTicketOrderDetails,
  addSupplier,
  getSupplierStatus,
  setWelcomePopupTrue,
  printingCount,
} from "Services/suppliers";
import {
  setSuppliers,
  ticketOrder,
  setSupplierStatus,
  updateSupplierStatus,
  ticketOrderNets,
  ticketOrderPiles,
} from "Redux/features/suppliers";

export const fetchSuppliers = (): AppThunk => async dispatch => {
  const response = await getSuppliers('');
  if (isAxiosResponse(response) && response.data.results.length > 0) {
    await dispatch(setSuppliers(response.data.results));
  }
}

export const fetchTicketOrder = (orderNum: number): AppThunk => async (dispatch) => {
  const response = await fetchTicketOrderDetails(orderNum);
  if (isAxiosResponse(response) && response.data.results.length > 0) {
    const filtered_rebars = response.data.results[0].order_item.filter((item: any) => item.tool.subclass_type === REBAR_SUBCLASS_TYPE)
    const filtered_nets = response.data.results[0].order_item.filter((item: any) => item.tool.subclass_type === NET_SUBCLASS_TYPE)
    const filtered_piles = response.data.results[0].order_item.filter((item: any) => item.tool.subclass_type === PILE_SUBCLASS_TYPE)
    
    filtered_rebars.sort((a, b) => a.row_number - b.row_number);
    filtered_nets.sort((a, b) => a.row_number - b.row_number);
    filtered_piles.sort((a, b) => a.row_number - b.row_number);

    let orderObj = response.data.results;
    orderObj[0].order_item = filtered_rebars;
    await dispatch(ticketOrder(orderObj)); //ticketOrder = ticketOrderRebars
    await dispatch(ticketOrderNets(filtered_nets));
    await dispatch(ticketOrderPiles(filtered_piles))

  }
  else{
    await dispatch(ticketOrder([]));
    await dispatch(ticketOrderNets([]));
    await dispatch(ticketOrderPiles([]));
  };
  return response;
};

export const addSuppier = (data: any): AppThunk => async (dispatch) => {
  await addSupplier(data);
};

export const fetchSupplierStatus = (): AppThunk => async (dispatch) => {
  let response = null
  try {
    response = await getSupplierStatus();
    await dispatch(setSupplierStatus(response.data.data));
  } catch (error) {
      await dispatch(setSupplierStatus("404"))
    }
};

export const setWelcomePopup = (): AppThunk => async (dispatch) => {
  await setWelcomePopupTrue();
};


export const getPrintingCount = (): AppThunk => async(dispatch) => {
  try {
    const response = await printingCount();
    await dispatch(updateSupplierStatus(response.data));
  } catch (error) {
    await dispatch(updateSupplierStatus("404"));
  }
}