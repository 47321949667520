import { useEffect, useRef } from "react";
import { useAppSelector } from "Redux/hooks";

export default function useRefEffect(props) {
  const { screenToShow } = props;
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const emailBoxRef = useRef();

  useEffect(() => {
    emailBoxRef?.current?.setAttribute("dir", "ltr");
    if (rightToLeft) {
      emailBoxRef?.current?.setAttribute("style", "text-align: right");
    } else {
      emailBoxRef?.current?.setAttribute("style", "text-align: left");
    }
  }, [screenToShow, rightToLeft]);

  return emailBoxRef;
}
