import React from "react";
import { Layer, Line } from "react-konva";

const GridLayer = (props) => {
  const { cellSize, width, height, stroke, strokeWidth } = props;
  
  const generateGrid = () => {
    const gridLines = [];

    for (let i = 0; i < width; i += cellSize) {
      gridLines.push(
        <Line
          key={`v-${i}`}
          points={[i, 0, i, height]}
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
      );
    }

    for (let i = 0; i < height; i += cellSize) {
      gridLines.push(
        <Line
          key={`h-${i}`}
          points={[0, i, width, i]}
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
      );
    }

    return gridLines;
  };

  return <Layer>{generateGrid()}</Layer>;
};

export default GridLayer;