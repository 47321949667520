import { useState, useRef } from "react";
import { OrderFilterTypes } from "Constants/order";
import { format } from "date-fns";
import { isMobile } from "react-device-detect";
import eye from "../../assets/img/order__page/Icon ionic-md-eye.svg";
import { useAppSelector } from "Redux/hooks";

import infoIcon from "../../assets/img/save_export/info.svg";
import previewIcon from "../../assets/img/save_export/preview.svg";
import incomingIcon from "../../assets/img/save_export/incoming.svg";

import { Overlay, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./icons.scss";

function OrderTableRow({
  order,
  number,
  mobileFilter = 1,
  onOpenPreviewHandle,
  setIsMouseOver,
  OrderPositionRef,
  setSelectedOrder,
  setIsDataFetched,
}: any) {
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const { t } = useTranslation(["common", "newOrder", "measurements"]);
  const [showPrevTooltip, setShowPrevTooltip] = useState(false);
  const [isHovered, setIsHovered] = useState(-1);

  const targetPrev = useRef(null);

  let row = (
    <tr
      className="grid-table-row"
      onMouseEnter={() => {
        setIsHovered(order.id);
        setSelectedOrder(order);
      }}
      onMouseLeave={() => setIsHovered(-1)}
    >
      <td
        onMouseLeave={() => {
          setIsMouseOver(false);
        }}
      >
        <div className="form-check form-check-inline">
          {isHovered === order.id ? (
            <div className="order-dropsdown">
              <img
                src={infoIcon}
                alt={"options Icon"}
                onMouseEnter={(e) => {
                  setIsMouseOver(true);
                }}
                ref={OrderPositionRef}
              />
            </div>
          ) : (
            <label className="form-check-label" htmlFor="inlineCheckbox1">
              {number}
            </label>
          )}
        </div>
      </td>
      <td>
        <p className="para">{format(new Date(order.created), "d.M.yyy")}</p>
      </td>
      <td>
        <p className="para">
          <span>{order.order_no && order.order_no.split(".")[0]}</span>.
          {order.order_no && order.order_no.split(".")[1]}
        </p>
      </td>
      <td className="justify-content-start">
        <div
          className={
            "d-flex align-items-center w-100 " + (rightToLeft ? "ml-2" : "mr-2")
          }
        >
          {!order.is_creater && (
            <img
              src={incomingIcon}
              alt="Incoming Icon"
              className={rightToLeft ? "ml-2" : "mr-2"}
            />
          )}
          <p className={"para"}>{order.name}</p>
        </div>
      </td>
      <td>
        <p className="para text-capitalize">{order.site ? order.site : ""}</p>
      </td>
      <td>
        <p className="para text-uppercase">
          {(order.weight / 1000).toFixed(2)}
        </p>
      </td>
      <td>
        <p className="para">
          {format(new Date(order.delivery_date), "d.M.yyy")}
        </p>
      </td>
      <td style={rightToLeft ? { borderRight: "2px solid #f3f3f3" } : {}}>
        {isHovered === order.id ? (
          <div className="d-flex iconGroup">
            <button
              className={
                "export__icons " +
                (rightToLeft ? "icons__right" : "icons__left")
              }
              onClick={() => {
                setIsDataFetched(true);
                onOpenPreviewHandle(order.id, false);
              }}
              ref={targetPrev}
              onMouseEnter={() => setShowPrevTooltip(true)}
              onMouseLeave={() => setShowPrevTooltip(false)}
            >
              <img className="m__icon" src={previewIcon} alt="preview icon" />
            </button>
            <Overlay
              target={targetPrev.current}
              show={showPrevTooltip}
              placement="top"
            >
              {(props) => (
                <Tooltip id="overlay-example-2" {...props}>
                  {t("Preview", { ns: "common" })}
                </Tooltip>
              )}
            </Overlay>
          </div>
        ) : (
          ""
        )}
      </td>
    </tr>
  );
  if (isMobile) {
    row = (
      <tr>
        <td>
          <p className="para">{number}</p>
        </td>
        <td>
          <p className="para bold">
            {mobileFilter === OrderFilterTypes.created ||
            mobileFilter === OrderFilterTypes.sites
              ? format(new Date(order.created), "d.M.yyy")
              : format(new Date(order.delivery_date), "d.M.yyy")}
          </p>
        </td>
        <td>
          <p className="para text-capitalize">
            {order.business ? order.business.name : ""}
          </p>
        </td>
        <td>
          <p className="para text-uppercase">
            {order.order_item.length > 0
              ? (
                  order.order_item.reduce(
                    (pre: number, curr: any) =>
                      pre + curr.tool.unit_weight * curr.quantity,
                    0
                  ) / 1000
                ).toFixed(1)
              : 0}
            &nbsp;ton
          </p>
        </td>
        <td className="d-flex alingn-items-center justify-content-center">
          <span className={"icon__wrap " + (rightToLeft ? "ml-0" : "mr-0")}>
            <img src={eye} alt="eye icon" />
          </span>
        </td>
      </tr>
    );
  } else {
  }
  return <>{row}</>;
}

export default OrderTableRow;
