export const CONTACTS_ENUM = {
  SITE_MAN: 1,
  SITE_MANAGER: 2,
  PROJECT_MANAGER: 3,
  OTHER: 4
};

export const ContactFilterTypes = {
  phone: 1,
  job: 2,
};