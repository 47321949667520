import CrossIcon from "../../../assets/img/order-details/cross.svg";
import { Modal } from "react-bootstrap";
import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import {
  createProject,
  removeProject,
  // removeProject,
  updateProject,
} from "Redux/middlwares/project";
import { deleteProjectContact } from "Services/project";
import { CONTACTS_ENUM } from "Constants/contacts";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { DynamicMap } from "Components/dynamic-map";
import classNames from "classnames";

const initalProjectState = {
  name: "",
  project_address_line1: "",
  project_address_line2: "",
  project_city: "",

  address: "",
  location_notes: "",
  general_notes: "",

  delivery_address_line1: "",
  delivery_address_line2: "",
  delivery_city: "",
  driver_notes: "",
  longitude: "",
  latitude: "",
};

const noErrors = {
  name: false,
  project_address_line1: false,
  project_city: false,

  delivery_address_line1: false,
  delivery_city: false,

  siteManagerName: false,
  siteManagerPhone: false,
};

const defaultSiteManager = {
  phone: "",
  full_name: "",
  role: CONTACTS_ENUM.SITE_MANAGER,
};

const defaultsContacts = [
  {
    phone: "",
    full_name: "",
    role: CONTACTS_ENUM.SITE_MAN,
  },
];

function AddNewProject(props) {
  const {
    edit,
    project,
    show,
    scrollDetaildelivery,
  } = props;
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.user.userData);
  const { t } = useTranslation(["common", "newOrder"]);

  const [loader, setLoader] = useState(false);
  const [projectData, setProjectData] = useState(initalProjectState);
  const [siteManager, setSiteManager] = useState(defaultSiteManager);
  const [projectContacts, setProjectContacts] = useState(defaultsContacts);
  const [coordinates, setCoordinates] = useState();
  const [isInputChanges, setIsInputChanges] = useState(false);
  const [error, setError] = useState(noErrors);


  const errorExist = useMemo(() => {
    return Object.entries(error).reduce(
      (prev, [_, val]) => prev || val,
      false
    );
  }, [error]);

  const handleProjectDataChange = (key, value) => {
    if (
      [
        "delivery_address_line1",
        "delivery_city",
        "delivery_address_line2",
      ].includes(key)
    ) {
      setIsInputChanges(true);
    }
    if (key in error && error[key] && value) {
      setError({
        ...error,
        [key]: false,
      });
    }
    setProjectData({
      ...projectData,
      [key]: value,
    });
  };

  const addressChange = (data) => {
    setProjectData({
      ...projectData,
      delivery_address_line1: data.st_address,
      delivery_city: data.city,
      delivery_address_line2: data.apt_address,
    });
  };

  const handleIamManager = () => {
    setSiteManager({
      phone: userData.phone,
      full_name: userData.first_name + " " + userData.last_name,
      role: CONTACTS_ENUM.SITE_MANAGER,
    });
  };

  const handleDeliveryAddress = () => {
    setProjectData({
      ...projectData,
      delivery_city: projectData.project_city,
      delivery_address_line1: projectData.project_address_line1,
      delivery_address_line2: projectData.project_address_line2,
    });
  }

  const handleIamDelivery = () => {
    if (projectContacts.length === 1 && projectContacts[0].phone === "") {
      setProjectContacts([
        {
          phone: userData.phone,
          full_name: userData.first_name + " " + userData.last_name,
          role: CONTACTS_ENUM.SITE_MAN,
        },
      ]);
    } else {
      const exist = projectContacts.find(
        (item) => item.phone === userData.phone
      );
      if (!exist) {
        setProjectContacts([
          ...projectContacts,
          {
            phone: userData.phone,
            full_name: userData.first_name + " " + userData.last_name,
            role: CONTACTS_ENUM.SITE_MAN,
          },
        ]);
      }
    }
  };

  useEffect(() => {
    if (edit && Object.keys(project).length > 0) {
      setProjectData(project);
      const manager = project.contacts.find(
        (item) => item.role === CONTACTS_ENUM.SITE_MANAGER
      );
      if (manager) {
        setSiteManager(manager);
      }
      setProjectContacts([
        ...project.contacts.filter(
          (item) => item.role === CONTACTS_ENUM.SITE_MAN
        ),
      ]);
      if (project.latitude && project.longitude) {
        setCoordinates({
          lat: project.latitude,
          lng: project.longitude,
        });
      }
    } else {
      setProjectData(initalProjectState);
      setSiteManager(defaultSiteManager);
      setProjectContacts(defaultsContacts);
    }
  }, [edit, project]);

  useEffect(()=>{
    if(scrollDetaildelivery){
      const data = document.getElementById("delivery_details_id")
      setTimeout(() => {
        data && data.scrollIntoView({
          behavior: 'smooth'
        });
      }, 200);
    }
  },[show]) 

  const validateForm = () => {
    let newErrors = { ...error };
    let _errorExist = false;
    const keys = ['name', 'project_address_line1', 'project_city', 'delivery_address_line1', 'delivery_city'];
    keys.forEach((_key) => {
      newErrors = {
        ...newErrors,
        [_key]: projectData[_key] ? false : true,
      };
      if (!projectData[_key]) {
        _errorExist = true;
      }
    });
    newErrors = {
      ...newErrors,
      siteManagerName: siteManager.full_name ? false : true,
      siteManagerPhone: siteManager.phone ? false : true
    };
    if (!siteManager.full_name || !siteManager.phone) {
      _errorExist = true;
    }
    setError(newErrors);
    return _errorExist;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (validateForm()) return;
    setLoader(true);
    let data = {
      ...projectData,
      user: userData.id,
    };
    if (coordinates) {
      data = {
        ...data,
        longitude: coordinates.lng,
        latitude: coordinates.lat,
      };
    } else {
      delete data.longitude;
      delete data.latitude;
    }
    const callback = (created) => {
      if (created) {
        props.onHide();
      }
      setLoader(false);
    };
    if (edit) {
      data["contacts"] = [...projectContacts, { ...siteManager }]
        .filter((item) => item.full_name && item.phone)
        .map((item) => ("id" in item ? item : { ...item, user: userData.id }));
      dispatch(updateProject(project.id, data, callback));
    } else {
      dispatch(
        createProject(
          data,
          [...projectContacts, { ...siteManager }]
            .filter((item) => item.full_name && item.phone)
            .map((item) => ({ ...item, user: userData.id })),
          callback
        )
      );
    }
  };

  // Delivery Contacts
  const setContactName = (name, index) => {
    const copyProjectContacts = [...projectContacts];
    copyProjectContacts[index] = {
      ...copyProjectContacts[index],
      full_name: name,
    };
    setProjectContacts(copyProjectContacts);
  };

  const setContactphone = (phone, index) => {
    const copyProjectContacts = [...projectContacts];
    copyProjectContacts[index] = {
      ...copyProjectContacts[index],
      phone,
    };
    setProjectContacts(copyProjectContacts);
  };

  const addSiteMan = () => {
    setProjectContacts([
      ...projectContacts,
      {
        phone: "",
        full_name: "",
        role: CONTACTS_ENUM.SITE_MAN,
      },
    ]);
  };

  const handleDeleteContact = (index) => {
    const currentContact = projectContacts[index];
    const copyOfProjectContacts = [...projectContacts];
    copyOfProjectContacts.splice(index, 1);
    if (edit && currentContact.id) {
      deleteProjectContact(currentContact.id)
        .then((res) => {
          setProjectContacts(copyOfProjectContacts);
        })
        .catch((err) => console.log(err));
    } else {
      setProjectContacts(copyOfProjectContacts);
    }
  };
  // end Delivery Contacts

  const handleDeleteProject = () => {
    if (edit) {
      dispatch(
        removeProject(project.id, (deleted) => {
          if (deleted) {
            props.onHide();
          }
        })
      );
    }
  };

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName={"project-modal"}
      >
        <div className="head">
          <h4>
            {edit
              ? t("Edit project", { ns: "newOrder" })
              : t("add new project", { ns: "newOrder" })}
          </h4>
          <img
            style={{ cursor: "pointer" }}
            src={CrossIcon}
            alt="cross"
            onClick={props.onHide}
          />
        </div>
        <form className="body" onSubmit={onSubmitHandler}>
          <h5 className="main-head">
            {t("project details", { ns: "newOrder" })}
          </h5>
          <div className="custom-form-group">
            <label htmlFor="project-name">
              {t("Project Name", { ns: "newOrder" })}
            </label>
            <input
              id="project-name"
              className={classNames({ invalid: error.name })}
              type="text"
              placeholder={t("Identify project", { ns: "newOrder" })}
              maxLength={48}
              value={projectData.name}
              onChange={(e) => handleProjectDataChange("name", e.target.value)}
            />
          </div>
          <h5 className="main-head">{t("site man", { ns: "newOrder" })}</h5>
          <p className="underlined" onClick={handleIamManager}>
            {t("the site manager", { ns: "newOrder" })}
          </p>
          <div className="custom-form-group">
            <label htmlFor="project-name">
              {t("Full name", { ns: "newOrder" })}
            </label>
            <input
              id="project-name"
              type="text"
              className={classNames({ invalid: error.siteManagerName })}
              value={siteManager.full_name}
              onChange={(e) => {
                setSiteManager({ ...siteManager, full_name: e.target.value });
                if (error.siteManagerName && e.target.value) {
                  setError({
                    ...error,
                    siteManagerName: false,
                  });
                }
              }}
            />
          </div>
          <div className="custom-form-group">
            <label htmlFor="project-name">
              {t("phone", { ns: "newOrder" })}
            </label>
            <input
              id="project-name"
              type="number"
              placeholder={t("Numbers only", { ns: "newOrder" })}
              pattern="^0\d{1,2}-?\d{3}-?\d{4}$"
              title="Phone number must be 9 or 10 digits"
              className={classNames({ invalid: error.siteManagerPhone })}
              value={siteManager.phone}
              onChange={(e) => {
                setSiteManager({ ...siteManager, phone: e.target.value });
                if (
                  /^0\d{1,2}-?\d{3}-?\d{4}$/.test(siteManager.phone) &&
                  error.siteManagerPhone
                ) {
                  setError({
                    ...error,
                    siteManagerPhone: false,
                  });
                }
              }}
              onBlur={() => {
                if (!/^0\d{1,2}-?\d{3}-?\d{4}$/.test(siteManager.phone)) {
                  setError({
                    ...error,
                    siteManagerPhone: true,
                  });
                } else {
                  setError({
                    ...error,
                    siteManagerPhone: false,
                  });
                }
              }}
            />
          </div>
          <h5 className="main-head">{t("location", { ns: "newOrder" })}</h5>
          <div className="custom-form-group">
            <label htmlFor="project-name">
              {t("city", { ns: "newOrder" })}
            </label>
            <input
              id="project-name"
              type="text"
              placeholder={t("Project city", { ns: "newOrder" })}
              className={classNames({ invalid: error.project_city })}
              value={projectData.project_city}
              onChange={(e) =>
                handleProjectDataChange("project_city", e.target.value)
              }
            />
          </div>
          <div className="custom-form-group">
            <label htmlFor="project-name">
              {t("line1", { ns: "newOrder" })}
            </label>
            <input
              id="project-name"
              type="text"
              placeholder={t("Street NandN", { ns: "newOrder" })}
              value={projectData.project_address_line1}
              className={classNames({ invalid: error.project_address_line1 })}
              onChange={(e) =>
                handleProjectDataChange("project_address_line1", e.target.value)
              }
            />
          </div>
          <div className="custom-form-group">
            <label htmlFor="project-name">
              {t("line2", { ns: "newOrder" })}
            </label>
            <input
              id="project-name"
              type="text"
              placeholder={t("Apartment num", { ns: "newOrder" })}
              value={projectData.project_address_line2}
              onChange={(e) =>
                handleProjectDataChange("project_address_line2", e.target.value)
              }
            />
          </div>
          <h5 className="main-head" id="delivery_details_id">
            {t("Delivery details", { ns: "newOrder" })}
          </h5>
          <p className="underlined" onClick={handleDeliveryAddress}>
            {t("the Delivery Address", { ns: "newOrder" })}
          </p>
          <div className="custom-form-group">
            <label htmlFor="project-name">
              {t("city", { ns: "newOrder" })}
            </label>
            <input
              id="project-name"
              type="text"
              placeholder={t("Project city", { ns: "newOrder" })}
              value={projectData.delivery_city}
              className={classNames({ invalid: error.delivery_city })}
              onChange={(e) =>
                handleProjectDataChange("delivery_city", e.target.value)
              }
            />
          </div>
          <div className="custom-form-group">
            <label htmlFor="project-name">
              {t("line1", { ns: "newOrder" })}
            </label>
            <input
              id="project-name"
              type="text"
              placeholder={t("Street NandN", { ns: "newOrder" })}
              className={classNames({ invalid: error.delivery_address_line1 })}
              value={projectData.delivery_address_line1}
              onChange={(e) =>
                handleProjectDataChange(
                  "delivery_address_line1",
                  e.target.value
                )
              }
            />
          </div>
          <div className="custom-form-group">
            <label htmlFor="project-name">
              {t("line2", { ns: "newOrder" })}
            </label>
            <input
              id="project-name"
              type="text"
              placeholder={t("Apartment num", { ns: "newOrder" })}
              value={projectData.delivery_address_line2}
              onChange={(e) =>
                handleProjectDataChange(
                  "delivery_address_line2",
                  e.target.value
                )
              }
            />
          </div>
          <div className="custom-form-group">
            <label htmlFor="project-name">
              {t("Driver notes", { ns: "newOrder" })}
            </label>
            <input
              id="project-name"
              type="text"
              placeholder={t("Delivery msg", { ns: "newOrder" })}
              value={projectData.driver_notes}
              onChange={(e) =>
                handleProjectDataChange("driver_notes", e.target.value)
              }
            />
          </div>
          <DynamicMap
            coordinates={coordinates}
            setCoordinates={setCoordinates}
            addressChange={addressChange}
            deps={[
              projectData.delivery_city,
              projectData.delivery_address_line1,
              projectData.delivery_city,
              projectData.delivery_address_line2,
            ]}
            isInputChanges={isInputChanges}
            setIsInputChanges={setIsInputChanges}
          />
          <h5 className="main-head">
            {t("delivery contacts", { ns: "newOrder" })}
          </h5>
          <p className="underlined" onClick={handleIamDelivery}>
            {t("the delivery contact", { ns: "newOrder" })}
          </p>
          <div id="project-contacts">
            {projectContacts.map((item, index) => (
              <React.Fragment key={"contact" + index}>
                <div className="custom-form-group">
                  <div className="d-flex flex-row justify-content-between align-item-center">
                    <label htmlFor="project-name">
                      {t("Full name", { ns: "newOrder" })}
                    </label>
                    {index > 0 && (
                      <span
                        className="delete-btn"
                        onClick={() => handleDeleteContact(index)}
                      >
                        {t("delete", { ns: "newOrder" })}
                      </span>
                    )}
                  </div>
                  <input
                    id="project-name"
                    type="text"
                    maxLength={48}
                    value={item.full_name}
                    onChange={(e) => setContactName(e.target.value, index)}
                  />
                </div>
                <div className="custom-form-group">
                  <label htmlFor="project-name">
                    {t("Phone num", { ns: "newOrder" })}
                  </label>
                  <input
                    id="project-name"
                    type="number"
                    pattern="^0\d{1,2}-?\d{3}-?\d{4}$"
                    title="Phone number must be 9 or 10 digits"
                    value={item.phone}
                    onChange={(e) => setContactphone(e.target.value, index)}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
          {projectContacts.length < 2 && (
            <p className="underlined" onClick={addSiteMan}>
              {t("add another contact", { ns: "newOrder" })}
            </p>
          )}
          <div className="save-wrapper">
            {errorExist && (
              <p className="error-mark">
                {t("error marks", { ns: "newOrder" })}
              </p>
            )}
            <button
              type="submit"
              className={classNames("save-btn", {
                "justify-content-center": loader,
              })}
              disabled={loader}
              style={
                loader
                  ? {
                      width: "81.5px",
                      height: "40px",
                    }
                  : {}
              }
            >
              {loader ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <>{t("Save")}</>
              )}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default AddNewProject;
