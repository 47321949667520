import Request from "./index";

export const postPriceList = (data: Object) =>
  Request.post('price-list/', data);

export const getPriceLists = () =>
  Request.get('price-list/');

export const patchPriceList = (priceListID: number, data: Object) =>
  Request.patch(`price-list/${priceListID}/`, data);

export const deletePriceList = (priceListID: number) =>
  Request.remove(`price-list/${priceListID}/`);