export const emailOrderEn = {
  "send by mail": "You got an Easybar order",
  "email to send": "Send the order to this email",
  "example": "example@gmail.com",
  "whom to send": "Who do you want to sent it to?",
  "Supplier": "A Supplier",
  "Coworker": "A Co-Worker",
  "Other": "Other",
  "supplier name": "Supplier Name",
  "type here": "type it here",
  "customer type":"Customer Type",
  "ExistingCustomer": "An Existing Customer",
  "NewCustomer": "A New Customer",
  "Note": "Note",
  "message attached": "The message will be attached to the email",
  "Send": "Send",

  "order sent": "The order is sent.",
  "receive private email": "You will receive a private email with order details.",
  "order not sent": "The order is not sent.",
  "email not recieved": "There is some problem. Please try later or contact support.",
  "link text": "Hello, rebar order is attached in that link.%0a",

  "sending order line 1": "Sending order will create an order duplication for the recipient. ",
  "sending order line 2": "The recipient won't be able to see the changes you makes from now on.",
  "share duplicate": "share a duplication",
  "email input placeholder": "type the recipient email here",
  "not easybar user line 1": "this email is not an Easybar user.",
  "not easybar user line 2": "do you want to send an invitation with your order attached?",
  "send email": "send email",
  "order has been sent": "the order has been sent",
  "order sent by email": "the order sent by email",
  "order cant send" : "Could not send order",

};
