import Request from "./index";

function dataURLtoFile(dataurl: any, filename: string) {
 
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
      
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  
  return new File([u8arr], filename, {type:mime});
}


export const postMedia = (previewImg: any, filename: string, alt_text: string) => {
  const formData = new FormData();
  formData.append('file_field', dataURLtoFile(previewImg, filename));
  formData.append('alt_text', alt_text);
  return Request.post('media/', formData, {
    'Content-Type': 'multipart/form-data',
  });
}

export const postMediaFile = (previewImg: any, alt_text: string) => {
  const formData = new FormData();
  formData.append("file_field", previewImg);
  formData.append("alt_text", alt_text);
  return Request.post("media/", formData, {
    "Content-Type": "multipart/form-data",
  });
};

export const getMedia = (mediaID: number) =>
  Request.get(`media/${mediaID}/`);

export const updateMedia = (mediaID: number, previewImg: any, filename: string, alt_text: string) => {
  const formData = new FormData();
  formData.append('file_field', dataURLtoFile(previewImg, filename));
  formData.append('alt_text', alt_text);
  return Request.patch(`media/${mediaID}/`, formData, {
    'Content-Type': 'multipart/form-data',
  });
}
