import { isBrowser, isMobile } from "react-device-detect";
import { useState, useEffect, useRef } from "react";

import {
  calculateNextPoint,
  calculateCurrentPoint,
  reverseAngle,
  calculateLineAngle,
  isRebarRotatable,
} from "../utils";

import RebarCatalog from "./web/sidebar";

import MobileContent from "./mobile/content";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import {
  createRebar,
  fetchDefaultsRebars,
  fetchRebarFilters,
  updateRebar,
  changeRebarSketch,
} from "Redux/middlwares/rebar";
import { fetchSteelDiameters } from "Redux/middlwares/steelType";
import { setCurrentOrderRebarCount } from "Redux/features/rebar";
import { postMedia, updateMedia } from "Services/media";
import {
  ALL_Filter,
  radiusMin,
  rebarErrorTypes,
  sideMaxLength,
  sideMinLength,
  standardDiameter,
  SHAPE_MODE,
} from "Constants/rebar-constants";
import { STEEL_TYPES } from "Constants/general";
import ChangeMeasurementsModal from "./../change-measurements/index";
import { useTranslation } from "react-i18next";
import { previewHeight, previewWidth } from "./preview/preview-constants";
import SideBar from "../blocks/left-sidebar";
import SketchBody from "../blocks/sketch-body";
import RebarShape from "./shape";
import RebarShapeSmall from "./preview/preview-shape";
import LastAddedSidebar from "../blocks/right-sidebar";
import RebarsCard from "./../blocks/right-sidebar/rebars-cards/RebarsCard";
import InputSidebar from "./web/input-sidebar/inputSidebar";

import FreeFormShape from "./free-forms-shape/index";
import {
  getLineLength as linel,
  getLinesLengthsForDrawing,
  getAnglesBetweenLines,
  rotateLineAroundPoint,
  CalculateFreeShapeLineAngle,
  getSmallerAngle,
  getAngle,
  getFullAngle,
  calculateSecondLinePoint
} from "../rebar/free-forms-shape/shared/shapesGeometry.ts";
import {
  calculateHeight,
  lengthOnChangingHeight,
} from "../tool-utils/heightUtils.ts";

function Rebar({
  loader,
  setLoader,
  handleClose,
  edit,
  selectedTool,
  setSelectedTool,
  setIsStopped,
  tab,
  setTab,
  checkRowNums,
  editOrderId,
  editMode,
  deleteTool,
  copyOrderItem,
  setEditTool,
  order,
  editToolId,
}: any) {
  const dispatch = useAppDispatch();
  const stageRef = useRef(null);
  const [initial, setInitial] = useState(true);
  const [allPoints, setAllPoints] = useState([]);
  const standardShapes = useAppSelector((state) => state.rebar.defaultRebars);
  const filters = useAppSelector((state) => state.rebar.rebarFilters);
  const [activeFilters, setActiveFilters] = useState([ALL_Filter]);
  const steelDiameters = useAppSelector(
    (state) => state.steelType.steelDiameters
  );
  const nextOrderRebarPage = useAppSelector(
    (state) => state.rebar.orderRebarPage
  );
  const currentOrderRebars = useAppSelector(
    (state) => state.rebar.currentOrderRebars
  );
  const currentOrderRebarCount = useAppSelector(
    (state) => state.rebar.currentOrderRebarCount
  );

  const [rebarNumber, setRebarNumber] = useState("B");
  const [selectedShape, setSelectedShape] = useState({
    gIndex: -1,
    sIndex: -1,
  });
  const [shapeObj, setShape] = useState({} as any);
  const [orderItem, setOrderItem] = useState({
    quantity: 0,
    production_notes: "",
    position_notes: "",
    row_number: 0,
  });
  const [sidesTotal, setSidesTotal] = useState(0);
  const [errorsText, setErrorsText] = useState<any[]>([]);

  const [isShapesError, setIsShapesError] = useState(true);
  const finishButtonRef = useRef(null);

  // Filters
  const [onlyRightAngles, setOnlyRightAngles] = useState(false);
  const [addRadius, setAddRadius] = useState(false);
  const [initiateImage, setInitiateImage] = useState(false);
  const [addMore, setAddMore] = useState(true);
  const [isRotatable, setIsRotatable] = useState(false);

  // User Keyboard entries
  const [isStopped, setIsStoppedHandle] = useState(true);
  const rebarContainer = useRef<HTMLDivElement>();
  const steelTypeRef = useRef(null);
  const diameterRef = useRef(null);
  const sidesRef = useRef<any[]>([]);
  const heightsRef = useRef<any[]>([]);
  const amountRef = useRef(null);
  const saveButton = useRef(null);

  const [rebarImg, setRebarImg] = useState(0);
  const [warningShow, setWarningShow] = useState(false);
  const { t } = useTranslation(["newOrder"]);
  const [prevId, setPrevId] = useState(0);

  const [rebarsData, setRebarsData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [boxIndex, setBoxIndex] = useState(-1); //To set the index of the selected item for styling while edit mode

  const [showCatalog, setShowCatalog] = useState(true);
  const [inProgress, setInProgress] = useState(false);

  //For freeForm and catalog shapes
  const [rebarHeight, setRebarHeight] = useState([]);
  const [showHeight, setShowHeight] = useState(false);
  const [hasDisplayedMin, setHasDisplayedMin] = useState([]);

  //Rebar Free Shape Mode
  const [rebarShapeMode, setRebarShapeMode] = useState(SHAPE_MODE.freeform); // two types: catalog|freeform
  const [path, setPath] = useState({ id: "path", lines: [], edges: [] });
  const [linesHistory, setLinesHistory] = useState([{ path: path, index: 0 }]);
  const [linesHistoryIndex, setLinesHistoryIndex] = useState(0);
  const [freeAngles, setFreeAngles] = useState([]);
  const [freeLengths, setFreeLengths] = useState([]);
  const [freeLineAngles, setFreeLineAngles] = useState([]);
  const [totalFreeSides, setTotalFreeSides] = useState(0);
  const [totalFreeLength, setTotalFreeLength] = useState(0);
  const [resetClicked, setResetClicked] = useState(false);
  const [savedAngle, setSavedAngle] = useState([]);
  const freeStageRef = useRef(null);
  const [previousShape, setPreviousShape] = useState(null);

  useEffect(() => {
    if (currentOrderRebars) {
      setRebarsData(currentOrderRebars);
      if (nextOrderRebarPage !== false) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  }, [currentOrderRebars]);

  const addError = (newError: any) => {
    if (!errorsText.some((error) => error.errorType === newError.errorType)) {
      setErrorsText((prevErrors) => [...prevErrors, newError]);
    }
  };

  const removeError = (errorType: number) => {
    setErrorsText((prevErrors) =>
      prevErrors.filter((error) => error.errorType !== errorType)
    );
  };

  function computeTotalLenght(sides: any[]): any {
    return sides.reduce((sum: any, b: any) => sum + b.length, 0);
  }

  function setAngle(value: string, index: number): any {
    const newSides = [...shapeObj.rebar_side];
    let currentPoint = shapeObj.startpoint;
    let angle = isNaN(parseInt(value)) ? 1 : parseInt(value);
    if (angle !== null && angle > 0 && angle <= 360) {
      if (newSides[index].change_previous_point) {
        // calculate point to the current location
        currentPoint = calculateCurrentPoint(
          shapeObj.rebar_side.slice(0, index + 1),
          shapeObj.startpoint
        );

        // calculate the line angle and previous point
        let lineAngle = calculateLineAngle(
          newSides[index].clockwise,
          newSides[index].shifted_angle,
          angle,
          newSides[index].change_previous_point
        );

        // Calculating currentPoint to re-draw the image
        currentPoint = calculateNextPoint(
          currentPoint,
          reverseAngle(lineAngle),
          newSides[index].length
        );

        newSides[index] = {
          ...newSides[index],
          angle: value === "" ? value : (angle as any),
          line_angle: lineAngle,
        };

        // change shifted angle of previous points
        for (let i = index - 1; i >= 0; i--) {
          // the actual angle starts from the previous line
          // it referes to 0 degree so new shifted axis is previous line angle
          const shiftedAngle = lineAngle;

          lineAngle = calculateLineAngle(
            newSides[i].clockwise,
            shiftedAngle,
            newSides[i].angle,
            newSides[i].change_previous_point
          );

          // Calculating currentPoint to re-draw the image
          currentPoint = calculateNextPoint(
            currentPoint,
            reverseAngle(lineAngle),
            newSides[i].length
          );

          newSides[i] = {
            ...newSides[i],
            shifted_angle: shiftedAngle as any,
            line_angle: lineAngle,
          };
        }
      } else {
        newSides[index] = {
          ...newSides[index],
          angle: value === "" ? value : (angle as any),
        };

        let lineAngle = calculateLineAngle(
          newSides[index].clockwise,
          newSides[index].shifted_angle,
          angle,
          newSides[index].change_previous_point
        );

        // change line angle of next point
        newSides[index + 1] = {
          ...newSides[index + 1],
          line_angle: lineAngle,
        };

        // change shifted angle of next points
        if (index + 2 < newSides.length) {
          for (let i = index + 1; i < newSides.length - 1; i++) {
            // the actual angle starts from the previous line
            // it referes to 0 degree so new shifted axis is previous line angle
            const shiftedAngle = reverseAngle(lineAngle);
            newSides[i] = {
              ...newSides[i],
              shifted_angle: shiftedAngle,
            };
            lineAngle = calculateLineAngle(
              newSides[i].clockwise,
              shiftedAngle,
              newSides[i].angle,
              newSides[i].change_previous_point
            );
            newSides[i + 1] = {
              ...newSides[i + 1],
              line_angle: lineAngle,
            };
          }
        }
      }
    }
    setShape({
      ...shapeObj,
      rebar_side: newSides,
      startpoint: currentPoint,
    });
  }

  const radiusMinMaxValidation = (
    rebarSide,
    radius,
    index,
    minLength,
    isFreeForm = false
  ) => {
    if (radius <= 0 && radius !== "") {
      addError({
        message: t("error_rebar_radius_min"),
        errorType: rebarErrorTypes.RADIUS,
      });
    } else if (radius > minLength / 2) {
      addError({
        message: t("error_rebar_radius_max"),
        errorType: rebarErrorTypes.RADIUS_MAX,
      });
    } else {
      let minError = false;
      let maxError = false;

      //For freeform rebarSide state is having the list of lengths
      rebarSide.forEach((side, ind) => {
        if (ind < rebarSide.length - 1) {
          let maxLength =
            side.length < rebarSide[ind + 1].length
              ? side.length
              : rebarSide[ind + 1].length;
          let prevRadius = isFreeForm
            ? path.edges[ind].cornerRadius
            : rebarSide[ind].radius;
          let rad = index === ind ? radius : prevRadius;
          if (rad > maxLength / 2) {
            maxError = true;
          } else if (rad === 0) {
            minError = true;
          }
        }
      });
      if (!minError) removeError(rebarErrorTypes.RADIUS);
      if (!maxError) removeError(rebarErrorTypes.RADIUS_MAX);
    }
  };

  //Checks For radius box and min
  const radiusChecks = (radius, value, index) => {
    //separating number from min text and showing it instead of min
    const numberRegex = /(\d+(\.\d*)?|\.\d+)/;
    if (typeof value === "string") {
      let result = value.match(numberRegex);
      let numbers;
      if (result) {
        numbers = result[0];
      }
      radius = numbers;
    }

    //for each box have the states by default false and if
    //value changed from min changing state of that box to true
    const newCheckboxes = [...hasDisplayedMin];
    newCheckboxes[index] = true;
    if (radius >= 1 && !hasDisplayedMin[index])
      setHasDisplayedMin(newCheckboxes);

    //showing empty box so user can type an number
    if (typeof radius === "undefined" || radius === "" || radius === 0) {
      radius = "";
    }

    return radius;
  };

  function setRadius(value: string, index: number): any {
    const newSides = shapeObj.rebar_side.slice();

    let radius = parseFloat(value);
    if (radius < 0) return;

    radius = radiusChecks(radius, value, index);

    const line1 = newSides[index].length;
    const line2 = newSides[index + 1].length;

    let minLength = line1 < line2 ? line1 : line2;
    if (radius !== null && radius >= 0) {
      newSides[index] = {
        ...newSides[index],
        radius: radius as any,
      };
    }
    radiusMinMaxValidation(shapeObj.rebar_side, radius, index, minLength);
    setShape({
      ...shapeObj,
      rebar_side: newSides,
    });
  }

  function setSides(value: string, index: number): any {
    const newSides = shapeObj.rebar_side.slice();
    const val = isNaN(parseInt(value)) ? 0 : parseInt(value);

    if (val >= 0) {
      // Apply the check here
      if (
        shapeObj.can_be_symmetric &&
        shapeObj.is_symmetrical &&
        newSides[index].symmetrical_side
      ) {
        const _symmetrical_side = newSides.find(
          (sItem: any) => sItem.id === newSides[index].symmetrical_side
        );
        if (_symmetrical_side) {
          const _sIndex = newSides.indexOf(_symmetrical_side);
          if (_sIndex || _sIndex === 0) {
            newSides[_sIndex] = {
              ...newSides[_sIndex],
              length: val,
            };
          }
          newSides[index] = {
            ...newSides[index],
            length: val,
          };
        }
      } else {
        newSides[index] = {
          ...newSides[index],
          length: val,
        };
      }
      const total_length = computeTotalLenght(newSides);
      setShape({
        ...shapeObj,
        rebar_side: newSides,
        unit_weight: (
          (total_length / 100) *
          parseFloat(shapeObj.diameter.weight)
        ).toFixed(2),
      });
      setSidesTotal(computeTotalLenght(newSides));

      if (shapeObj.rebar_side.length > 1) {
        const cmDiameter = shapeObj.diameter.steel_diameter;
        if (index === 0 && val < cmDiameter) {
          addError({
            message: t("error_rebar_ear"),
            errorType: rebarErrorTypes.EAR,
          });
        } else if (
          index === shapeObj.rebar_side.length - 1 &&
          val < cmDiameter
        ) {
          addError({
            message: t("error_rebar_ear"),
            errorType: rebarErrorTypes.EAR,
          });
        } else {
          removeError(rebarErrorTypes.EAR);
        }
      }

      if (!(val >= sideMinLength && val < sideMaxLength) || val < 0) {
        addError({
          message: t("error_rebar_measure"),
          errorType: rebarErrorTypes.SIDE,
        });
      } else {
        shapeObj.rebar_side.forEach((side) => {
          if (
            (side.length >= sideMinLength && side.length < sideMaxLength) ||
            side.length < 0
          ) {
            removeError(rebarErrorTypes.SIDE);
          }
        });
      }
    }
  }

  //this
  // function changeAddRadius(val: boolean) {
  //   if (!val) {
  //     const newSides = shapeObj.rebar_side
  //       .slice()
  //       .map((side: any) => ({ ...side, radius: 0 }));
  //     newSides[newSides.length - 1].radius = null;
  //     setShape({
  //       ...shapeObj,
  //       rebar_side: newSides,
  //     });
  //   }
  //   setAddRadius(val);
  // }

  const getDefaultRebars = async () => {
    setLoader(true);
    await dispatch(fetchDefaultsRebars());
    setLoader(false);
  };

  useEffect(() => {
    STEEL_TYPES.forEach((st_type) => {
      dispatch(fetchSteelDiameters(st_type));
    });
  }, []);

  useEffect(() => {
    if (shapeObj?.diameter?.steel_type === null) {
      setShape({
        ...shapeObj,
        diameter: standardDiameter,
        unit_weight: (
          (shapeObj.rebar_side.reduce((sum: any, b: any) => sum + b.length, 0) /
            100) *
          parseFloat(standardDiameter.weight)
        ).toFixed(2),
      });
    }
  }, [showCatalog]);

  useEffect(() => {
    const initalFetch = async () => {
      setLoader(true);
      standardShapes.length === 0 && (await dispatch(fetchDefaultsRebars()));
      filters.length === 0 && (await dispatch(fetchRebarFilters()));
      setLoader(false);
      if (
        standardShapes.length > 0 &&
        standardShapes[0].rebars.length > 0 &&
        initial &&
        !edit
      ) {
        setInitial(false);
        const total_length = computeTotalLenght(
          standardShapes[0].rebars[0].rebar_side
        );
        setShape({
          ...standardShapes[0].rebars[0],
          unit_weight: (
            (total_length / 100) *
            parseFloat(standardShapes[0].rebars[0].diameter.weight)
          ).toFixed(2),
        });
        const numberOfRadius = standardShapes[0].rebars[0].rebar_side.reduce(
          (prev: number, curr: any) => {
            return prev + (curr.radius > 0 ? 1 : 0);
          },
          0
        );
        setAddRadius(numberOfRadius > 0 ? true : false);
        setSidesTotal(total_length);
      }
    };
    initalFetch();
  }, [
    dispatch,
    setLoader,
    standardShapes,
    standardShapes.length,
    filters.length,
    initial,
    edit,
  ]);

  useEffect(() => {
    if (
      edit &&
      selectedTool.tool.shape_type === SHAPE_MODE.catalog &&
      standardShapes.length > 0
    ) {
      setRebarShapeMode(SHAPE_MODE.catalog);
      setShowCatalog(false);
      setBoxIndex(selectedTool.id);
      setPrevId(selectedTool.tool.id);
      const sides = selectedTool.tool.rebar_side.map((sideItem: any) => ({
        id: sideItem.id,
        label: sideItem.label,
        clockwise: sideItem.clockwise,
        change_previous_point: sideItem.change_previous_point,
        radius: sideItem.radius,
        length: sideItem.length ? parseInt(sideItem.length) : sideItem.length,
        line_angle: sideItem.line_angle
          ? parseInt(sideItem.line_angle)
          : sideItem.line_angle,
        shifted_angle: sideItem.shifted_angle
          ? parseInt(sideItem.shifted_angle)
          : sideItem.shifted_angle,
        angle: sideItem.angle ? parseInt(sideItem.angle) : sideItem.angle,
        outer: sideItem.outer_angle,
        symmetrical_side: sideItem.symmetrical_side,
      }));
      const parsedShape = {
        ...selectedTool.tool,
        startpoint: { x: 300, y: 50 },
        rebar_side: sides,
      };
      setShape(parsedShape);
      setSidesTotal(computeTotalLenght(parsedShape.rebar_side));
      const numberOfRadius = parsedShape.rebar_side.reduce(
        (prev: number, curr: any) => {
          return prev + (curr.radius > 0 ? 1 : 0);
        },
        0
      );
      setAddRadius(numberOfRadius > 0 ? true : false);
      setOrderItem({
        row_number: selectedTool.row_number,
        quantity: selectedTool.quantity,
        production_notes: selectedTool.production_notes,
        position_notes: selectedTool.position_notes,
      });

      if (selectedTool.tool.reference_tool) {
        for (let i = 0; i < standardShapes.length; i++) {
          const _pTool = standardShapes[i].rebars.find(
            (item: any) => item.id === selectedTool.tool.reference_tool
          );
          if (_pTool) {
            setSelectedShape({
              gIndex: i,
              sIndex: standardShapes[i].rebars.indexOf(_pTool),
            });
            break;
          }
        }
      }
    }
  }, [edit, selectedTool, selectedTool.tool, standardShapes]);

  function saveRebar(isShapeChanged: boolean, isAddMore: boolean) {
    setAddMore(isAddMore);

    if (!shapeObj.diameter.id) {
      addError({
        message: t("error_rebar_diameter"),
        errorType: rebarErrorTypes.DIAMETER,
      });
      return;
    }

    for (let i = 0; i < shapeObj.rebar_side.length; i++) {
      const item = shapeObj.rebar_side[i];
      if (!(item.length >= sideMinLength && item.length < sideMaxLength)) {
        addError({
          message: t("error_rebar_measure"),
          errorType: rebarErrorTypes.SIDE,
        });
        return;
      }
    }

    const [maxError, minError] = onSaveRadiusVal(shapeObj.rebar_side, false);

    if (minError || maxError) return;

    if (!orderItem.quantity) {
      addError({
        message: t("error_rebar_amount"),
        errorType: rebarErrorTypes.AMOUNT,
      });
      return;
    }

    const xValues = allPoints.map((item: any) => item.x);
    const yValues = allPoints.map((item: any) => item.y);
    setIsRotatable(isRebarRotatable(xValues, yValues));

    if (stageRef.current) {
      setInitiateImage(true);
      if (isShapeChanged === true) {
        setIsStopped(false);
      }
      setTimeout(() => {
        const previewImg = (stageRef.current as any).toDataURL({
          mimeType: "image/png",
          x: 0,
          y: 0,
          width: previewWidth,
          height: previewHeight,
          quality: 1,
          pixelRatio: 3,
        });
        setRebarImg(previewImg);
        if (isShapeChanged === false) {
          setInitiateImage(false);
          setWarningShow(true);
          return;
        }

        let mediaPromise;
        if (edit) {
          if (prevId !== shapeObj.Id) {
            mediaPromise = postMedia(
              previewImg,
              `rebar_preview_${Date.now()}.png`,
              "rebars " + Date.now()
            );
          } else {
            mediaPromise = updateMedia(
              selectedTool.tool.preview_image.id,
              previewImg,
              `rebar_preview_${Date.now()}.png`,
              "rebars " + Date.now()
            );
          }
        } else {
          mediaPromise = postMedia(
            previewImg,
            `rebar_preview_${Date.now()}.png`,
            "rebars " + Date.now()
          );
        }
        mediaPromise.then((res) => {
          if (res.data) {
            const sidesItems = [
              ...shapeObj.rebar_side.map((item: any) => ({ ...item })),
            ];
            if (!edit || prevId !== shapeObj.id) {
              for (let i = 0; i < sidesItems.length; i++) {
                delete sidesItems[i].id;
              }
            }
            let newRebarShape = {
              ...shapeObj,
              rebar_side: sidesItems,
              diameter: shapeObj.diameter.id,
              preview_image: res.data.id,
              tool_number: rebarNumber,
              start_point: {
                x_value: shapeObj.startpoint.x,
                y_value: shapeObj.startpoint.y,
              },
            };

            let deleteKeys = [
              "startpoint",
              "icon",
              "created",
              "last_modified",
              "rebar_filter",
              "tool_ordering",
              "grouping",
              "can_be_symmetric",
            ];

            if (edit) {
              if (newRebarShape.name === "") {
                newRebarShape = {
                  ...newRebarShape,
                  name: null,
                };
              }
              if (prevId !== shapeObj.id) {
                newRebarShape = {
                  ...newRebarShape,
                  type: 1,
                  reference_tool: shapeObj.id,
                };
                let orderItemId = 0;
                currentOrderRebars.forEach((rebar) => {
                  if (rebar.tool.id === prevId) {
                    orderItemId = rebar.id;
                  }
                });

                deleteKeys.push("id");
                deleteKeys.forEach((k: string) => delete newRebarShape[k]);
                dispatch(
                  changeRebarSketch(newRebarShape, orderItemId, orderItem)
                ).then(() => {
                  setDefaultShape();
                });
              } else {
                deleteKeys.forEach((k: string) => delete newRebarShape[k]);
                dispatch(
                  updateRebar(selectedTool.id, newRebarShape, orderItem)
                ).then(() => {
                  setDefaultShape();
                });
              }
            } else {
              newRebarShape = {
                ...newRebarShape,
                type: 1,
                reference_tool: shapeObj.id,
              };

              deleteKeys.push("id");
              deleteKeys.forEach((k: string) => delete newRebarShape[k]);
              // Adding New Row
              dispatch(
                createRebar(
                  newRebarShape,
                  orderItem,
                  () => {},
                  0,
                  order.order_id
                )
              ).then(() => {
                dispatch(setCurrentOrderRebarCount(currentOrderRebarCount + 1));
              });
            }
            // handleClose();

            // Initial State of the tool
            setAllPoints([]);
            setActiveFilters([ALL_Filter]);
            handleChangeShape(-1, -1);
            setSelectedShape({gindex: -1, index: -1});
            setPreviousShape(null);
            setOrderItem({
              quantity: 0,
              production_notes: "",
              position_notes: "",
              row_number: 0,
            });

            // Remove all errors
            setErrorsText([]);

            setInitiateImage(false);
            setIsStoppedHandle(false);
            rebarContainer.current?.focus();
            setTimeout(() => setIsStopped(true), 3000);
            (saveButton.current as any).id = 1;
            setShowCatalog(true);
            if (
              isShapeChanged === true &&
              (isAddMore === false || addMore === false || editToolId >= 0)
            ) {
              setTimeout(() => {
                handleClose();
              }, 3000);
            }
          }
        });
      }, 100);
    }
  }

  const handleChangeDiamter = (e: any) => {
    if (e.fake) {
      setShape((prev: any) => ({
        ...prev,
        diameter: { weight: null, steel_type: null },
        unit_weight: NaN,
      }));
      return;
    }
    const steelDiameterID = parseInt(e.target.value);
    let total_length = 0;
    if (rebarShapeMode === SHAPE_MODE.catalog) {
      total_length = computeTotalLenght(shapeObj.rebar_side);
    } else if (
      rebarShapeMode === SHAPE_MODE.freeform &&
      freeLengths.length > 0
    ) {
      total_length = computeTotalLenght(freeLengths);
    }
    const steelDiameterWeight = steelDiameters[shapeObj.steel_type].find(
      (item: any) => item.id === steelDiameterID
    );
    if (steelDiameterWeight) {
      setShape((prev: any) => ({
        ...prev,
        diameter: steelDiameterWeight,
        unit_weight: (
          (total_length / 100) *
          parseFloat(steelDiameterWeight.weight)
        ).toFixed(2),
      }));
    }
    removeError(rebarErrorTypes.DIAMETER);
  };

  const handleOnlyRightAngles = (val: boolean) => {
    if (val) {
      shapeObj.rebar_side.forEach((_: any, index: number) => {
        if (index !== shapeObj.rebar_side.length - 1) {
          setAngle((90).toString(), index);
        }
      });
    }
    setOnlyRightAngles(val);
  };

  const handleChangeShape = (gval: number, val: number) => {
    if(gval === -1 && val === -1){
      setRebarShapeMode(SHAPE_MODE.freeform);
    }
    else{
      setRebarShapeMode(SHAPE_MODE.catalog);
      setSelectedShape({
        gIndex: gval,
        sIndex: val,
      });
      if (standardShapes.length > 0) {
        const total_length = computeTotalLenght(
          standardShapes[gval].rebars[val].rebar_side
        );

      const minDisplayed = [];
      for (let i = 0; i < shapeObj?.rabar_side?.length - 1; i++) {
        minDisplayed.push(false);
      }
      setHasDisplayedMin(minDisplayed);

        setShape({
          ...standardShapes[gval].rebars[val],
          unit_weight: (
            (total_length / 100) *
            parseFloat(standardShapes[gval].rebars[val].diameter.weight)
          ).toFixed(2),
        });
        const numberOfRadius = standardShapes[gval].rebars[val].rebar_side.reduce(
          (prev: number, curr: any) => {
            return prev + (curr.radius > 0 ? 1 : 0);
          },
          0
        );
        setAddRadius(numberOfRadius > 0 ? true : false);
        setSidesTotal(total_length);  
    }


      // Remove all errors
      setErrorsText([]);

    }
  };

  const setDefaultShape = () => {
    handleChangeShape(-1, -1);
    setBoxIndex(-1);
    setEditTool(false);
    setRebarShapeMode(SHAPE_MODE.freeform);
    setPath({ id: "path", lines: [], edges: [] });
    setAllPoints([]);
    setActiveFilters([ALL_Filter]);
    setSelectedTool({ id: 0 });
    setOrderItem({
      quantity: 0,
      production_notes: "",
      position_notes: "",
      row_number: 0,
    });
    
    // Remove all errors
    setErrorsText([]);
    rebarContainer.current?.focus();
    setShowCatalog(true);
  };

  useEffect(() => {
    if (!shapeObj.steel_type) {
      addError({
        message: t("error_rebar_steel"),
        errorType: rebarErrorTypes.STEEL_TYPE,
      });
    } else {
      removeError(shapeObj.steel_type);
    }

    if (shapeObj?.rebar_side?.length > 1) {
      const cmDiameter = shapeObj?.diameter?.steel_diameter;
      if (
        shapeObj.rebar_side[0]?.length < cmDiameter &&
        shapeObj.rebar_side[0]?.length > 0
      ) {
        addError({
          message: t("error_rebar_ear"),
          errorType: rebarErrorTypes.EAR,
        });
      } else if (
        shapeObj?.rebar_side[shapeObj?.rebar_side.length - 1]?.length <
          cmDiameter &&
        shapeObj.rebar_side[0]?.length > 0
      ) {
        addError({
          message: t("error_rebar_ear"),
          errorType: rebarErrorTypes.EAR,
        });
      } else {
        removeError(rebarErrorTypes.EAR);
      }
    }

    if (shapeObj?.diameter?.steel_diameter <= 18 && sidesTotal > 1200) {
      addError({
        message: t("error_rebar_length"),
        errorType: rebarErrorTypes.TOTAL_LENGTH,
      });
    } else if (shapeObj?.diameter?.steel_diameter > 18 && sidesTotal > 2400) {
      addError({
        message: t("error_rebar_length"),
        errorType: rebarErrorTypes.TOTAL_LENGTH,
      });
    } else {
      removeError(rebarErrorTypes.TOTAL_LENGTH);
    }
  }, [shapeObj.steel_type, shapeObj.rebar_side, shapeObj.diameter, sidesTotal]);

  const handleHeightChange = (value, index, id, angle) => {
    const val = isNaN(parseInt(value)) ? 0 : parseInt(value);
    const rebarSide = shapeObj.rebar_side.find((side) => side.id === id);
    const Index = shapeObj.rebar_side.indexOf(rebarSide);

    if (val > 0) {
      rebarHeight[index] = {
        ...rebarHeight[index],
        height: val,
      };
      setRebarHeight(rebarHeight);
      const newLength = lengthOnChangingHeight(val, angle);

      if (rebarShapeMode === SHAPE_MODE.catalog) {
        setSides(newLength, Index);
      } else {
        const newLineLength = {
          id: id,
          length: newLength,
        };
        handleLineLengthChange(newLineLength);
      }
    }
  };

  useEffect(() => {
    const computeAllSideHeights = (shape) => {
      const accumulatedRebarHeight = [];
      shape.forEach((side, index) => {
        let lineAngle = Math.abs(parseInt(side.line_angle));
        if (
          lineAngle !== 0 &&
          lineAngle !== 90 &&
          lineAngle !== 180 &&
          lineAngle !== 270 &&
          lineAngle !== 360
        ) {
          let height, angle;
          if (side.angle === null) {
            if (rebarShapeMode === SHAPE_MODE.catalog) {
              angle = shapeObj.rebar_side[index - 1]?.angle;
            } else {
              angle = freeAngles[index - 1]?.angle;
            }
            height = calculateHeight(side.length, angle);
          } else {
            angle = side.angle;
            height = calculateHeight(side.length, angle);
          }
          const sideHeight = {
            id: side.id,
            height: height,
            angle: angle,
          };
          accumulatedRebarHeight.push(sideHeight);
        }
        setRebarHeight([...accumulatedRebarHeight]);
      });
    };

    if (
      rebarShapeMode === SHAPE_MODE.catalog &&
      shapeObj?.rebar_side?.length > 1
    ) {
      computeAllSideHeights(shapeObj.rebar_side);
    } else if (
      (rebarShapeMode === SHAPE_MODE.freeform && path && path?.lines.length > 1,
      freeLengths.length > 0,
      freeLineAngles.length > 0)
    ) {
      const free_sides = [];
      freeLineAngles.forEach((item, index) => {
        let newObj = {};
        newObj = {
          id: item.id,
          label: index,
          length: freeLengths[index]?.length,
          line_angle: Number(item.angle).toFixed(0),
          angle:
            index < freeAngles.length
              ? Number(freeAngles[index]?.angle).toFixed(0)
              : null,
        };
        free_sides.push(newObj);
      });

      computeAllSideHeights(free_sides);
    }
  }, [
    shapeObj.rebar_side,
    path,
    freeLineAngles,
    freeLengths,
    freeAngles,
    rebarShapeMode,
  ]);

  //Called in Catalog/freeform save funcs to validate radius
  const onSaveRadiusVal = (rebarSide, isFreeForm) => {
    let minError, maxError;
    rebarSide.forEach((side, index) => {
      if (index < rebarSide.length - 1) {
        let maxLength =
          side.length < rebarSide[index + 1].length
            ? side.length
            : rebarSide[index + 1].length;
        let rad = isFreeForm ? path.edges[index].cornerRadius : side.radius;
        if (rad > maxLength / 2) {
          maxError = true;
          addError({
            message: t("error_rebar_radius_max"),
            errorType: rebarErrorTypes.RADIUS_MAX,
          });
        } else if (rad <= 0) {
          minError = true;
          addError({
            message: t("error_rebar_radius_min"),
            errorType: rebarErrorTypes.RADIUS,
          });
        }
      }
    });
    return [maxError, minError];
  };

  //-------------------------------------------------------------------------
  //Below Funcs are related to the Free Forms of Rebars (Undo/Redo & SideBar)

  //clearing the regular rebars object when coming in free form mode
  useEffect(() => {
    if (!edit && rebarShapeMode === SHAPE_MODE.freeform && !loader) {
      const freeFormShape = {
        ...shapeObj,
        rebar_side: [],
        unit_weight: 0,
      };
      setShape(freeFormShape);
    }
  }, [rebarShapeMode, loader]);

  //whenever the path of free-form changes or new side added
  //calculationg again the below values and unit weight
  useEffect(() => {
    if (path && path?.lines.length > 0) {
      const lines_length = getLinesLengthsForDrawing(path.lines);
      //line lengths
      setFreeLengths(lines_length);
      //angle b/w the lines
      setFreeAngles(getAnglesBetweenLines(path.lines, freeAngles, freeLineAngles));
      //angles of the lines
      setFreeLineAngles(CalculateFreeShapeLineAngle(path.lines, freeLineAngles));

      const total_length = computeTotalLenght(lines_length);
      setTotalFreeLength(total_length);
      setShape({
        ...shapeObj,
        unit_weight: (
          (total_length / 100) *
          parseFloat(shapeObj.diameter?.weight)
        ).toFixed(2),
      });
    } else {
      setFreeLengths([]);
      setFreeAngles([]);
    }
  }, [path]);

  //when steel type changes calculating the unit weight here
  useEffect(() => {
    if (path && path?.lines.length > 0) {
      setShape({
        ...shapeObj,
        unit_weight: (
          (totalFreeLength / 100) *
          parseFloat(shapeObj.diameter?.weight)
        ).toFixed(2),
      });
    }
  }, [shapeObj.steel_type]);

  //If it's free forms edit mode
  useEffect(() => {
    if (edit && selectedTool.tool.shape_type === SHAPE_MODE.freeform) {
      setRebarShapeMode(SHAPE_MODE.freeform);
      setShowCatalog(false);
      setPrevId(selectedTool.tool.id);
      const sides = [];
      selectedTool.tool.rebar_side.forEach((sideItem: any, index: number) => {
        if (index > 0) {
          const newObj = {
            id: sideItem.id,
            x1: parseInt(
              selectedTool.tool.rebar_side[index - 1].end_point.x_value
            ),
            y1: parseInt(
              selectedTool.tool.rebar_side[index - 1].end_point.y_value
            ),
            x2: parseInt(sideItem.end_point.x_value),
            y2: parseInt(sideItem.end_point.y_value),
          };
          sides.push(newObj);
        } else {
          const newObj = {
            id: sideItem.id,
            x1: parseInt(selectedTool.tool.start_point.x_value),
            y1: parseInt(selectedTool.tool.start_point.y_value),
            x2: parseInt(sideItem.end_point.x_value),
            y2: parseInt(sideItem.end_point.y_value),
          };
          sides.push(newObj);
        }
      });
      setTotalFreeSides(sides.length);
      const newPath = {
        id: "path",
        lines: sides,
        edges: getLinesEdges(sides, true),
      };
      handlePathChange(newPath);
      setShape(selectedTool.tool);
      setOrderItem({
        row_number: selectedTool.row_number,
        quantity: selectedTool.quantity,
        production_notes: selectedTool.production_notes,
        position_notes: selectedTool.position_notes,
      });
    }
  }, [edit, selectedTool, selectedTool.tool]);

  //managing the history states here
  const updateHistory = (path: Path) => {
    const newHistory = linesHistory.slice(0, linesHistoryIndex + 1);
    const newHistoryIndex = linesHistoryIndex + 1;
    const newHistoryItem = { path: path, index: newHistoryIndex };
    setLinesHistory([...newHistory, newHistoryItem]);
    setLinesHistoryIndex(newHistoryIndex);
  };

  // debounce the lines change callback
  const handlePathChange = (newPath: Path) => {
    if (newPath.lines.length > path.lines?.length) {
      const updatedList = [...hasDisplayedMin, false];
      setHasDisplayedMin(updatedList);
    }
    setPath(newPath);
    updateHistory(newPath);
  };

  // undo functionality
  const handleUndo = () => {
    if (linesHistoryIndex > 0) {
      const newHistoryIndex = linesHistoryIndex - 1;
      const newPath = linesHistory[newHistoryIndex].path;
      setPath(newPath);
      setLinesHistoryIndex(newHistoryIndex);
    }
  };

  // redo functionality
  const handleRedo = () => {
    if (linesHistoryIndex < linesHistory.length - 1) {
      const newHistoryIndex = linesHistoryIndex + 1;
      const newLines = linesHistory[newHistoryIndex].path;
      setPath(newLines);
      setLinesHistoryIndex(newHistoryIndex);
    }
  };

  //returning the edges of the lines
  const getLinesEdges = (lines: Line[], inEditMode: boolean = false) => {
    // line edges are the points where two lines meet
    const linesEdges: LineEdge[] = [];
    lines.forEach((line, index) => {
      const nextLine = lines[index + 1];
      if (nextLine) {
        if (line.x2 === nextLine.x1 && line.y2 === nextLine.y1) {
          linesEdges.push({
            id: Math.random().toString(),
            lineIds: [line.id, nextLine.id],
            x: line.x2,
            y: line.y2,
            cornerRadius: inEditMode
              ? index < selectedTool.tool.rebar_side.length
                ? selectedTool.tool.rebar_side[index].radius
                : null
              : path.edges[index].cornerRadius,
          });
        }
      }
    });
    return linesEdges;
  };
  
  const handleLineLengthChange = (lineLength: LineLength) => {
    // Find the line that matches the given line length ID
    const line = path.lines.find((line) => line.id === lineLength.id || line.id === parseInt(lineLength.id));

    if (line) {
      // Calculate the delta between the new length and the old length
      const oldLength = linel(
        { x: line.x1, y: line.y1 },
        { x: line.x2, y: line.y2 }
      );
      const delta = lineLength.length - oldLength;

      // Calculate the angle of the line
      let angle = Math.atan2(line.y2 - line.y1, line.x2 - line.x1);
      const Index = path.lines.indexOf(line);

      if (oldLength === 0 && lineLength.length !== 0) {
        // Save the previous angle when length goes from 0 to non-zero
        angle = parseFloat(freeLineAngles[Index].angle) * (Math.PI / 180);
      }

      // Calculate the new X2 and Y2 coordinates of the line
      const newX2 = line.x2 + delta * Math.cos(angle);
      const newY2 = line.y2 + delta * Math.sin(angle);

      // Update the X2 and Y2 coordinates of the line and all subsequent lines
      const lineIndex = path.lines.findIndex(
        (line) => line.id === lineLength.id || line.id === parseInt(lineLength.id)
      );
      const deltaVector = { x: newX2 - line.x2, y: newY2 - line.y2 };
      const newLines = path.lines.map((currentLine, currentIndex) => {
        if (currentLine.id === lineLength.id || currentLine.id === parseInt(lineLength.id)) {
          // Update the X2 and Y2 coordinates of the line that matches the given ID
          return { ...currentLine, x2: newX2, y2: newY2 };
        } else if (currentIndex > lineIndex) {
          // Add the delta vector to the X1, Y1, X2, and Y2 coordinates of all subsequent lines
          return {
            ...currentLine,
            x1: currentLine.x1 + deltaVector.x,
            y1: currentLine.y1 + deltaVector.y,
            x2: currentLine.x2 + deltaVector.x,
            y2: currentLine.y2 + deltaVector.y,
          };
        } else {
          // Return the current line for all previous lines
          return currentLine;
        }
      });

      // Set the new lines state and update the history
      const newPath = {
        ...path,
        lines: newLines,
        edges: getLinesEdges(newLines),
      };
      setPath(newPath);
      updateHistory(newPath);
    }
  };

  // when and angle between lines is changed, update each line's x2 and y2
  // coordinates with the delta angle between the lines
  const handleAngleBetweenLinesChange = (angleBetweenLines, prevAngle) => {
    
    const line2 = path.lines.find(
      (line) => line.id === angleBetweenLines.line2Id || line.id === parseInt(angleBetweenLines.line2Id) 
    );
    // rotate line2 around start point of line2
    if (line2) {
      const line1 = path.lines.find(
        (line) => line.id === angleBetweenLines.line1Id || line.id === parseInt(angleBetweenLines.line1Id)
      );
      if (line1) {
        // Change Angle if line length is zero
        if((line1.x1 === line1.x2 && line1.y1 === line1.y2) || (line2.x1 === line2.x2 && line2.y1 === line2.y2)){
          angleBetweenLines.angle = angleBetweenLines.angle > 180 ? 360 - angleBetweenLines.angle : angleBetweenLines.angle;
          if(angleBetweenLines.angle <= 360 && angleBetweenLines.angle >= 0){
            // Check Angle Between line1 and line2 for smaller angle
            const updatedArray = [...freeAngles];
            const index = updatedArray.findIndex(obj => angleBetweenLines.id === obj.id);
            if (index !== -1) {
              updatedArray[index] = angleBetweenLines;
              setFreeAngles(updatedArray);
            }  
          }
        }
        //this function calculates the new line angles based on the change in angle b/w lines
        //then gives the new {x2,y2} points

        const newLine2 = rotateLineAroundPoint(
          line1,
          line2,
          angleBetweenLines.angle,
          Number(prevAngle),
          freeLineAngles,
        );
        let newLines = path.lines.map((line) => {
          if (line.id === line2.id || line.id === parseInt(line2.id)) {
            return newLine2[0];
          }
          return line;
        });

        // Update the Free Line Angle of current line wrt change in angle
        // setFreeLineAngles(prev => prev.map(obj => (obj.id === line2.id || obj.id === parseInt(line2.id)) ? { ...obj, angle: parseFloat(obj.angle) + newLine2[1] } : obj));
        setFreeLineAngles((prev) =>
          prev.map((obj) => {
            if (obj.id === line2.id || obj.id === parseInt(line2.id)) {
              let newAngle = parseFloat(obj.angle) + parseFloat(newLine2[1]);
              return { ...obj, angle: newAngle };
            } else {
              return obj;
            }
          })
        );

        let newPath = {
          ...path,
          lines: newLines,
          edges: getLinesEdges(newLines),
        };

        //this loop first updates the {x1,y1} points then updates the line angles based on the
        //change in line angle from the line (we are updating angle) and then recalculates their
        //{x2,y2} points.
        const Index = path.lines.indexOf(line2);
        if (Index < path.lines.length - 1) {
          for (let i = Index; i < path.lines.length - 1; i++) {
            //updating the {x1,y1} points with the previous lines {x2,y2}
            newLines[i + 1] = {
              ...newLines[i + 1],
              x1: newLines[i].x2,
              y1: newLines[i].y2,
            };

            //updating the line angle
            const newLineAngle =
              (Number(freeLineAngles[i + 1].angle) + Number(newLine2[1])) *
              (Math.PI / 180);

            //recalculating the {x2,y2} points of the line
            const points = {
              x: Number(newLines[i + 1].x1),
              y: Number(newLines[i + 1].y1),
            };
            const newPs = calculateNextPoint(
              points,
              (newLineAngle * 180) / Math.PI,
              Number(freeLengths[i + 1].length)
            );
            freeLineAngles[i + 1].angle = (newLineAngle * 180) / Math.PI;
            newLines[i + 1] = {
              ...newLines[i + 1],
              x2: newPs.x,
              y2: newPs.y,
            };
            //storing in path obj
            newPath = {
              ...newPath,
              lines: newLines,
              edges: getLinesEdges(newLines),
            };
          }
        }
        setPath(newPath);
        updateHistory(newPath);
      }
    }
  };

  const resetFreeFormShape = () => {
    setPath({ id: "path", lines: [], edges: [] });
    setLinesHistoryIndex(0);
    setLinesHistory([{ path: { id: "path", lines: [], edges: [] }, index: 0 }]);
    setRebarHeight([]);
    setResetClicked(true);
  };

  //Returns the Label toDisplay for the length
  const indexToChar = (index: number): string => {
    if (index < 26) {
      return String.fromCharCode("A".charCodeAt(0) + index);
    } else {
      const firstLetter = String.fromCharCode(
        "A".charCodeAt(0) + Math.floor(index / 26) - 1
      );
      const secondLetter = String.fromCharCode(
        "A".charCodeAt(0) + (index % 26)
      );
      return `${firstLetter}${secondLetter}`;
    }
  };

  //Function to save the free forms
  const saveFreeFormShapes = (isShapeChanged: boolean, isAddMore: boolean) => {
    const diameterWeight = parseFloat(shapeObj.diameter.weight);
    setAddMore(isAddMore);

    //Checking for the errors
    if (!shapeObj.diameter.id) {
      addError({
        message: t("error_rebar_diameter"),
        errorType: rebarErrorTypes.DIAMETER,
      });
      return;
    }

    if (!orderItem.quantity) {
      addError({
        message: t("error_rebar_amount"),
        errorType: rebarErrorTypes.AMOUNT,
      });
      return;
    }

    const [maxError, minError] = onSaveRadiusVal(freeLengths, true);
    if (minError || maxError) return;

    //making the object in the right format to save
    const free_sides = [];
    const start_point = {
      x_value: path?.lines[0]?.x1,
      y_value: path?.lines[0]?.y1,
    };
    path.lines.forEach((item, index) => {
      let x2 = item.x2;
      let y2 = item.y2;
      let newObj = {};
      if (edit && path.lines.length === totalFreeSides) {
        newObj = {
          id: item.id,
          end_point: { x_value: x2, y_value: y2 },
          label: index,
          length: freeLengths[index].length,
          line_angle: freeLineAngles[index].angle.toFixed(2),
          angle: index < freeAngles.length ? freeAngles[index].angle : null,
          clockwise: false,
          radius:
            index < path.edges.length ? path.edges[index].cornerRadius : null,
        };
      } else {
        newObj = {
          end_point: { x_value: x2, y_value: y2 },
          label: index,
          length: freeLengths[index].length,
          line_angle: freeLineAngles[index].angle.toFixed(2),
          angle: index < freeAngles.length ? freeAngles[index].angle : null,
          clockwise: false,
          shifted_angle: null,
          change_previous_point: false,
          radius:
            index < path.edges.length ? path.edges[index].cornerRadius : null,
          outer: false,
          symmetrical_side: null,
        };
      }
      free_sides.push(newObj);
    });

    //sides length error check
    for (let i = 0; i < free_sides.length; i++) {
      const item = free_sides[i];
      if (!(item.length >= sideMinLength && item.length < sideMaxLength)) {
        addError({
          message: t("error_rebar_measure"),
          errorType: rebarErrorTypes.SIDE,
        });
        return;
      }
    }
    handleChangeShape(-1, -1);
    setSelectedShape({gindex: -1, index: -1});
    setPreviousShape(null);
    //radius error not added yet

    setShape({
      ...shapeObj,
      rebar_side: free_sides,
    });
    //needs to spend some time on it
    const xValues = allPoints.map((item: any) => item.x);
    const yValues = allPoints.map((item: any) => item.y);
    setIsRotatable(isRebarRotatable(xValues, yValues));

    if (stageRef.current) {
      setInitiateImage(true);
      if (isShapeChanged === true) {
        setIsStopped(false);
      }
      setTimeout(() => {
        //screen_shot creation here
        const previewImg = (stageRef.current as any).toDataURL({
          mimeType: "image/png",
          x: 0,
          y: 0,
          width: previewWidth,
          height: previewHeight,
          quality: 1,
          pixelRatio: 3,
        });
        setRebarImg(previewImg);
        if (isShapeChanged === false) {
          setInitiateImage(false);
          setWarningShow(true);
          return;
        }

        //image saving here by using media apis
        let mediaPromise;
        if (edit) {
          if (path.lines.length !== totalFreeSides) {
            mediaPromise = postMedia(
              previewImg,
              `rebar_preview_${Date.now()}.png`,
              "rebars " + Date.now()
            );
          } else {
            mediaPromise = updateMedia(
              selectedTool.tool.preview_image.id,
              previewImg,
              `rebar_preview_${Date.now()}.png`,
              "rebars " + Date.now()
            );
          }
        } else {
          mediaPromise = postMedia(
            previewImg,
            `rebar_preview_${Date.now()}.png`,
            "rebars " + Date.now()
          );
        }

        //on the return of promise creating the saving image to rebars object and saving it
        mediaPromise.then((res) => {
          if (res.data) {
            let newRebarShape = {
              default_image: null,
              is_symmetrical: false,
              type: 0,
              subclass_type: "rebar",
              ...shapeObj,
              preview_image: res.data.id,
              tool_number: rebarNumber,
              diameter: shapeObj.diameter.id,
            };

            let deleteKeys = [
              "icon",
              "created",
              "last_modified",
              "rebar_filter",
              "tool_ordering",
              "grouping",
              "can_be_symmetric",
              "startpoint",
            ];

            if (edit) {
              if (path.lines.length !== totalFreeSides) {
                newRebarShape = {
                  ...newRebarShape,
                  type: 1,
                  start_point: start_point,
                  rebar_side: free_sides,
                  shape_type: "FREE_FORM_SHAPE",
                  reference_tool: null,
                };
                let orderItemId = 0;
                currentOrderRebars.forEach((rebar) => {
                  if (rebar.tool.id === prevId) {
                    orderItemId = rebar.id;
                  }
                });

                deleteKeys.push("id");
                deleteKeys.forEach((k: string) => delete newRebarShape[k]);
                dispatch(
                  changeRebarSketch(newRebarShape, orderItemId, orderItem)
                ).then(() => {
                  setDefaultShape();
                });
              } else {
                const total_length = computeTotalLenght(free_sides);
                newRebarShape = {
                  ...newRebarShape,
                  start_point: start_point,
                  rebar_side: free_sides,
                  unit_weight: (
                    (parseFloat(total_length) / 100) *
                    diameterWeight
                  ).toFixed(2),
                };
                deleteKeys.forEach((k: string) => delete newRebarShape[k]);
                dispatch(
                  updateRebar(selectedTool.id, newRebarShape, orderItem)
                ).then(() => {
                  setDefaultShape();
                });
              }
            } else {
              newRebarShape = {
                ...newRebarShape,
                start_point: start_point,
                rebar_side: free_sides,
                type: 1,
                shape_type: "FREE_FORM_SHAPE",
                reference_tool: null,
              };

              deleteKeys.push("id");
              deleteKeys.forEach((k: string) => delete newRebarShape[k]);
              // Adding New Row
              dispatch(
                createRebar(
                  newRebarShape,
                  orderItem,
                  () => {},
                  0,
                  order.order_id
                )
              ).then(() => {
                dispatch(setCurrentOrderRebarCount(currentOrderRebarCount + 1));
              });
            }

            setAllPoints([]);
            setActiveFilters([ALL_Filter]);
            handleChangeShape(-1, -1);
            setOrderItem({
              quantity: 0,
              production_notes: "",
              position_notes: "",
              row_number: 0,
            });

            // Remove all errors
            setErrorsText([]);
            setDefaultShape();
            setInitiateImage(false);
            setIsStoppedHandle(false);
            //rebarContainer.current?.focus();
            setTimeout(() => setIsStopped(true), 3000);
            //setShowCatalog(true);
            if (
              isShapeChanged === true &&
              (isAddMore === false || addMore === false)
            ) {
              setTimeout(() => {
                handleClose();
              }, 3000);
            }
          }
        });
      }, 100);
    }
  };

  // Rebar Free Form Error Conditions
  const checkFreeFormHeigth = () =>{
    
    let totalLength = 0;
    if(freeLengths.length > 0){
      freeLengths.forEach(freeLength => totalLength += freeLength.length);
    }
    if (shapeObj?.diameter?.steel_diameter <= 18 && totalLength > 1200) {
      addError({
        message: t("error_rebar_length"),
        errorType: rebarErrorTypes.TOTAL_LENGTH,
      });
    } else if (shapeObj?.diameter?.steel_diameter > 18 && totalLength > 2400) {
      addError({
        message: t("error_rebar_length"),
        errorType: rebarErrorTypes.TOTAL_LENGTH,
      });
  }else{
    removeError(rebarErrorTypes.TOTAL_LENGTH)
  }
  }


  const checkFreeFromEar = () =>{
    if (freeLengths.length > 1){
      const cmDiameter = shapeObj?.diameter?.steel_diameter;
      if((freeLengths[0].length < cmDiameter) || (freeLengths[freeLengths.length -1].length < cmDiameter)){
        addError({
          message: t("error_rebar_ear"),
          errorType: rebarErrorTypes.EAR,
        });
      }
      else{
        removeError(rebarErrorTypes.EAR);
      }
    }
    else{
      removeError(rebarErrorTypes.EAR);
    }
  }

  const checkFreeFormSides = ()=>{

    if(freeLengths.length > 0){
      let showError = false;
      freeLengths.forEach((freeLength)=>{
        if (!(freeLength.length >= sideMinLength && freeLength.length < sideMaxLength) || freeLength.length < 0){
          showError = true;
        }
      })

      if(showError){
        addError({
          message: t("error_rebar_measure"),
          errorType: rebarErrorTypes.SIDE,
        });
      }else{
        removeError(rebarErrorTypes.SIDE);
      }
    }
  }



useEffect(()=>{
  if (rebarShapeMode === SHAPE_MODE.freeform){
    checkFreeFormHeigth();
    checkFreeFromEar();
    checkFreeFormSides();
  }

}, [freeLengths, shapeObj])

  //Above few funcs are related to the Rebars Free Form

  return (
    <>
      {isBrowser && (
        <>
          <SideBar tab={tab} setTab={setTab} edit={edit}>
            {!showCatalog ? (
              <InputSidebar
                shape={shapeObj}
                stageRef={stageRef}
                setShape={setShape}
                onlyRightAngles={onlyRightAngles}
                sidesTotal={sidesTotal}
                setAngle={(value: string, index: number) =>
                  setAngle(value, index)
                }
                setSides={(value: string, index: number) =>
                  setSides(value, index)
                }
                saveRebar={saveRebar}
                handleProductionNote={(val: string) =>
                  setShape({ ...shapeObj, production_notes: val })
                }
                handlePositionNote={(val: string) =>
                  setShape({ ...shapeObj, position_notes: val })
                }
                orderItem={orderItem}
                setOrderItem={setOrderItem}
                handleChangeDiamter={handleChangeDiamter}
                setAllPoints={setAllPoints}
                rebarNumber={rebarNumber}
                setRebarNumber={setRebarNumber}
                initiateImage={initiateImage}
                addError={addError}
                removeError={removeError}
                steelTypeRef={steelTypeRef}
                diameterRef={diameterRef}
                sidesRef={sidesRef}
                heightsRef={heightsRef}
                amountRef={amountRef}
                addButtonRef={saveButton}
                finishButtonRef={finishButtonRef}
                selectedShape={selectedShape}
                standardShapes={standardShapes}
                setSymmetrical={(val: boolean) =>
                  setShape({
                    ...shapeObj,
                    is_symmetrical: val,
                  })
                }
                edit={edit}
                isRotatable={isRotatable}
                errorsText={errorsText}
                setErrorsText={setErrorsText}
                isShapesError={isShapesError}
                setIsShapesError={setIsShapesError}
                setShowCatalog={setShowCatalog}
                setDefaultShape={setDefaultShape}
                setRebarShapeMode={setRebarShapeMode}
                rebarShapeMode={rebarShapeMode}
                freeAngles={freeAngles}
                freeLengths={freeLengths}
                onLineLengthChange={handleLineLengthChange}
                onAngleChange={handleAngleBetweenLinesChange}
                setPath={setPath}
                path={path}
                saveFreeFormShapes={saveFreeFormShapes}
                setRebarHeight={setRebarHeight}
                rebarHeight={rebarHeight}
                handleHeightChange={handleHeightChange}
                setShowHeight={setShowHeight}
                showHeight={showHeight}
                resetFreeFormShape={resetFreeFormShape}
                setPreviousShape={setPreviousShape}
                changeShape={handleChangeShape}
                setIsStopped={setIsStoppedHandle}
                previousShape={previousShape}
                setSelectedShape={setSelectedShape}
                  setRadius={setRadius}
                radiusMinMaxValidation={radiusMinMaxValidation}
                setHasDisplayedMin={setHasDisplayedMin}
                hasDisplayedMin={hasDisplayedMin}
                radiusChecks={radiusChecks}
                indexToChar={indexToChar}
              />
            ) : (
              <RebarCatalog
                standardShapes={standardShapes}
                shape={shapeObj}
                selectedShape={selectedShape}
                changeShape={handleChangeShape}
                onlyRightAngles={onlyRightAngles}
                handleOnlyRightAngles={handleOnlyRightAngles}
                edit={edit}
                loader={loader}
                errorsText={errorsText}
                getDefaultRebars={getDefaultRebars}
                filters={filters}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                rebarContainer={rebarContainer}
                steelTypeRef={steelTypeRef}
                isStopped={isStopped}
                setIsStopped={setIsStoppedHandle}
                showCatalog={showCatalog}
                setShowCatalog={setShowCatalog}
                previousShape={previousShape}
                setPreviousShape={setPreviousShape}
                setRebarShapeMode={setRebarShapeMode}
                setSelectedShape={setSelectedShape}
              />
            )}
          </SideBar>

          <SketchBody
            rebarShapeMode={rebarShapeMode}
            resetFreeFormShape={resetFreeFormShape}
            edit={edit}
          >
            <div
              style={{
                height: "425px",
                justifyContent: "center",
                alignItems: "center",
                display: initiateImage ? "flex" : "none",
              }}
            >
              <div
                style={{
                  width: previewWidth + "px",
                  height: previewHeight + "px",
                  // background: "wheat",
                }}
              >
                <RebarShapeSmall
                  shapeObj={shapeObj}
                  stageRef={stageRef}
                  setAllPoints={setAllPoints}
                  allPoints={allPoints}
                  initiateImage={true}
                  isRotatable={isRotatable}
                  rebarShapeMode={rebarShapeMode}
                  path={path}
                />
              </div>
            </div>

            {rebarShapeMode === SHAPE_MODE.catalog && (
              <div style={{ display: initiateImage ? "none" : "block" }}>
                <RebarShape
                  setShape={setShape}
                  shapeObj={shapeObj}
                  setAllPoints={setAllPoints}
                  setSides={setSides}
                  initiateImage={initiateImage}
                  errorsText={errorsText}
                  setSymmetrical={(val: boolean) =>
                    setShape({
                      ...shapeObj,
                      is_symmetrical: val,
                    })
                  }
                  // setShapeChanged={setShapeChanged}
                  setRebarShapeMode={setRebarShapeMode}
                  setShowCatalog={setShowCatalog}
                  setRebarHeight={setRebarHeight}
                  rebarHeight={rebarHeight}
                  showHeight={showHeight}
                  handleHeightChange={handleHeightChange}
                  loader={loader}
                />
              </div>
            )}
            {rebarShapeMode === SHAPE_MODE.freeform &&
              !initiateImage &&
              !loader && (
                <FreeFormShape
                  path={path}
                  onPathChange={handlePathChange}
                  onUndo={handleUndo}
                  onRedo={handleRedo}
                  freeLengths={freeLengths}
                  freeLineAngles={freeLineAngles}
                  freeAngles={freeAngles}
                  stageRef={freeStageRef}
                  onLineLengthChange={handleLineLengthChange}
                  setResetClicked={setResetClicked}
                  resetClicked={resetClicked}
                  setAllPoints={setAllPoints}
                  setShowCatalog={setShowCatalog}
                  pointsCalculated={allPoints}
                  indexToChar={indexToChar}
                />
              )}
          </SketchBody>
          <LastAddedSidebar
            dataLength={rebarsData.length}
            hasMore={hasMore}
            setHasMore={setHasMore}
            toolData={rebarsData}
            setToolData={setRebarsData}
            checkRowNums={checkRowNums}
            editOrderId={editOrderId}
            editMode={editMode}
            saveTool={saveRebar}
            shapeObj={shapeObj}
            edit={edit}
            finishButtonRef={finishButtonRef}
            addButtonRef={saveButton}
            isShapesError={isShapesError}
            setDefaultShape={setDefaultShape}
            handleClose={handleClose}
            editToolId={editToolId}
          >
            {/* Render last added rebars here */}
            <div
              className="card__list_rebars hoveredItems"
              style={editToolId >= 0 ? { marginTop: "40px" } : {}}
            >
              {rebarsData.map((item, index) => (
                <RebarsCard
                  key={item.id}
                  id={item.id}
                  item={item.tool}
                  orderItem={item}
                  index={index}
                  deleteTool={deleteTool}
                  copyOrderItem={copyOrderItem}
                  setEditTool={setEditTool}
                  setSelectedTool={setSelectedTool}
                  setBoxIndex={setBoxIndex}
                  boxIndex={boxIndex}
                  setInProgress={setInProgress}
                  inProgress={inProgress}
                  editToolId={editToolId}
                  edit={edit}
                />
              ))}
            </div>
          </LastAddedSidebar>

          {/* <Content
            shape={shapeObj}
            stageRef={stageRef}
            setShape={setShape}
            onlyRightAngles={onlyRightAngles}
            sidesTotal={sidesTotal}
            setAngle={(value: string, index: number) => setAngle(value, index)}
            setSides={(value: string, index: number) => setSides(value, index)}
            saveRebar={saveRebar}
            handleProductionNote={(val: string) =>
              setShape({ ...shapeObj, production_notes: val })
            }
            handlePositionNote={(val: string) =>
              setShape({ ...shapeObj, position_notes: val })
            }
            orderItem={orderItem}
            setOrderItem={setOrderItem}
            handleChangeDiamter={handleChangeDiamter}
            setAllPoints={setAllPoints}
            rebarNumber={rebarNumber}
            setRebarNumber={setRebarNumber}
            initiateImage={initiateImage}
            errorText={errorText}
            setErrorText={setErrorText}
            steelTypeRef={steelTypeRef}
            diameterRef={diameterRef}
            sidesRef={sidesRef}
            amountRef={amountRef}
            saveButton={saveButton}
            selectedShape={selectedShape}
            standardShapes={standardShapes}
            setSymmetrical={(val: boolean) =>
              setShape({
                ...shapeObj,
                is_symmetrical: val,
              })
            }
            edit={edit}
            isRotatable={isRotatable}
          /> */}
          <ChangeMeasurementsModal
            show={warningShow}
            onHide={() => setWarningShow(false)}
            saveRebar={saveRebar}
            rebarImg={rebarImg}
          />
        </>
      )}
      {isMobile && (
        <MobileContent
          shapeObj={shapeObj}
          stageRef={stageRef}
          setShape={setShape}
          setSides={setSides}
          selectedShape={selectedShape}
          changeShape={handleChangeShape}
          onlyRightAngles={onlyRightAngles}
          setOnlyRightAngles={(val: boolean) => setOnlyRightAngles(val)}
          setAngle={(value: string, index: number) => setAngle(value, index)}
          setRadius={(value: string, index: number) => setRadius(value, index)}
          addRadius={addRadius}
          setAddRadius={(val: boolean) => changeAddRadius(val)}
          orderItem={orderItem}
          setOrderItem={setOrderItem}
          standardShapes={standardShapes}
          handleChangeDiamter={handleChangeDiamter}
          allPoints={allPoints}
          setAllPoints={setAllPoints}
          rebarNumber={rebarNumber}
          setRebarNumber={setRebarNumber}
          saveRebar={saveRebar}
          initiateImage={initiateImage}
        />
      )}
    </>
  );
}

export default Rebar;
