import React, { useEffect, useRef, useState } from 'react';
import { isBrowser, isMobile } from "react-device-detect";
import CustomerLayout from "../../layouts/customer";
import AddNewCompany from "./add-new-business/index-copy";
import AddNewProject from "./add-new-project/index-copy";
import CreateNewUser from "./create-new-user";
import NewItem from "./newItemtool";
import OrderMobile from "./order-mobile";
import OrderWeb from "./order-web/orderWeb";
import { useAppDispatch, useAppSelector } from 'Redux/hooks';
import { getCurrentOrderRebars, getCurrentOrderNets, getCurrentOrderPiles, } from "Redux/middlwares/order";
import { fetchOrderNo, patchOrder, postOrder } from 'Services/order';
import { fetchSteelTypes } from 'Redux/middlwares/steelType';
import { fetchSteelDiameters } from "Redux/middlwares/steelType";
import ImagePreview from './tool-preview';
import PdfPreviewModal from '../pdf-preview-modal';
import DeleteOrderItemModal from './delele-order-item';
import { createRebar } from 'Redux/middlwares/rebar';
import { REBAR_SUBCLASS_TYPE } from 'Constants/rebar-constants';
import { NET_SUBCLASS_TYPE } from 'Constants/net-constants';
import { PILE_SUBCLASS_TYPE } from 'Constants/pile-constants';
import { createNet } from 'Redux/middlwares/net';
import { createPile } from 'Redux/middlwares/pile';
import PriceList from './price-list';
import OrderSuccess from './order-success';
import { format } from 'date-fns/esm';
import Login from 'Components/common-components/login';
import PdfPreview from 'Components/pdf-preview';
import DeleteOrderModal from './save-preview-delete/delete-order-modal/index';
import SaveOrderModal from "./save-preview-delete/save-order-modal/index";
import ShareOptionsModal from "./save-preview-delete/share-options-modal";
import SendbyMail from "./send-by-mail/index";
import AlertModal from "./../custom-alert-modal";
import checkIcon from '../../assets/img/icon/check.svg';
import OrderDetails from './order-details';
import { getBusinessInstance } from "Services/business";
import ResponseMsgModal from './../success-warning-modal/index';
import { useTranslation } from "react-i18next";
import { postDocumentName, uploadDocument } from "Services/order-pdf";
import { timeout_secs } from "Constants/alert-modal";
import { postMediaFile } from "Services/media";

import { useParams } from 'react-router-dom';

import { getCurrentOrderTools } from "Redux/middlwares/order";
import { setDuplicateRows } from 'Redux/middlwares/order';
import { addRebarToCurrentOrder, setCurrentOrderRebars, setCurrentOrderRebarCount } from 'Redux/features/rebar';
import { addNetToCurrentOrder, setCurrentOrderNets, setCurrentOrderNetsCount } from 'Redux/features/net';


import { postBidRequest } from 'Services/suppliers';
import PopUpModal from 'Components/custom-popup-modal';
import popUpIcon from '../../assets/img/save__popup/save__popup.svg'
import PopUpModalCopy from '../custom-popup-modal/index-copy';
import RequestIcon from '../../assets/img/request/request__accepted.svg'
import { useHistory } from 'react-router-dom';
import { getClickables } from 'Services/user-analytics';
import { postClickables } from 'Services/user-analytics';
import { OrderPdfType } from "Constants/order";
import { Toaster } from 'react-hot-toast';
import { setCurrentOrderPiles } from 'Redux/features/pile';

function NewOrder({
  orderData,
  edit,
}: any) {
  const { t } = useTranslation(["common", "warning"]);
  const dispatch = useAppDispatch();
  const navigate = useHistory();
  const user = useAppSelector(state => state.user.userData);
  document.body.classList.add('my-order-screen');
  const [tab, setTab] = useState(1);
  const pdfRef = useRef(null);
  const [businessModalShow, setBusinessModalShow] = useState(false);
  const [userModalShow, setUserModalShow] = useState(false);
  const [loginShow, setLoginShow] = useState(false);
  const [projectModalShow, setProjectModalShow] = useState(false);
  const [itemModalShow, setItemModalShow] = useState(false);
  const [imagePreviewShow, setImagePreviewShow] = useState(false);
  const [imagePreview, setImagePreview] = useState({});
  const [pdfPreviewShow, setPdfPreviewShow] = useState(false);
  const [activeBusiness, setActiveBusiness] = useState({});
  const [editBusiness, setEditBusiness] = useState(false);
  const [currentCompany, setCurrentCompany] = useState();
  const [activeProject, setActiveProject] = useState({});
  const [editProject, setEditProject] = useState(false);
  const [selectedTool, setSelectedTool] = useState({
    id: 0
  });
  const [deleteOrderItemShow, setDeleteOrderItemShow] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipText, setTooltipText] = useState('');
  const [coping, setCoping] = useState(false);
  const [showTooltipUpd, setShowTooltipUpd] = useState(false);
  const [editTool, setEditTool] = useState(false);
  const priceShow = useAppSelector(state => state.order.showPrice);
  const [priceShowModal, setPriceShowModal] = useState(false);
  const [orderSuccessFullyCreated, setOrderSuccessFullyCreated] = useState(false);
  const [deleteMessageShow, setDeleteMessageShow] = useState(false);
  const [saveOrderMessageShow, setSaveOrderMessageShow] = useState(false);
  const [saveBeforeShareShow, setSaveBeforeShareShow] = useState(false);
  const [shareOptionsShow, setShareOptionsShow] = useState(false);
  const [sendByEmailShow, setSendByEmailShow] = useState(false);
  const [downloadFlag, setDownloadFlag] = useState(false);
  const [printCanvas, setPrintCanvas] = useState(false);
  const [generatePdfFlag, setGeneratePdfFlag] = useState(false);  //generates pdf if true
  const [pdfFile, setPdfFile] = useState('');   //stores generated pdf file as blob
  const [documentUrl, setDocumentUrl] = useState('');  //stores document url from the response of aws
  const [sharableOrderId, setSharableOrderId] = useState('');  //stores the order no
  const [responseStatus, setResponseStatus] = useState({});  //response status msg & color on email (on order sent through email)
  const [responseMsg, setResponseMsg] = useState({});  //msg to dispaly with status on response (on order sent through email)
  const [responseMsgModalShow, setResponseMsgModalShow] = useState(false); //if true response modal displayed
  const [loader, setLoader] = useState(false); //state for loader used in pdf (download | share)

  const [scrollDetailDelivery,setScrollDetailDelivery] = useState(false);

  const [orderIdforShare, setOrderIdforShare] = useState(0); //state to save order ID for the share options
  const [showDetails, setShowDetails] = useState(false);


  const [popUpText, setPopUpText] = useState("")
  const [popUpModalShow, setPopUpModalShow] = useState(false);
  const [getBidCalled, setGetBidCalled] = useState(false);
  const [getNextBidCall, setGetNextBidCall] = useState(false);
  const [notesChange, setNotesChange] = useState("");
  const [clickableId, setClickableId] = useState(0);
  const [slug,setSlug] = useState("");
  const [orderSaved, setOrderSaved] = useState(false);
  
  const [errorText, setErrorText] = useState("");

  const [editToolId, setEditToolId] = useState(-1);
  

  const { id } = (useParams() as any);

  const userData = useAppSelector(state => state.user.userData);
  const currentOrderRebars = useAppSelector(state => state.rebar.currentOrderRebars);
  const currentOrderNets = useAppSelector(state => state.net.currentOrderNets);
  const currentOrderPiles = useAppSelector(state => state.pile.currentOrderPiles);

  //For Pdfs using v-0 api
  const currentOrderPdfRebars = useAppSelector(state => state.rebar.currentOrderPdfRebars);
  const currentOrderPdfNets = useAppSelector(state => state.net.currentOrderPdfNets);
  const currentOrderPdfPiles = useAppSelector(state => state.pile.currentOrderPdfPiles);

  const steelTypes = useAppSelector(state => state.steelType.steelTypes);
  const suppliers = useAppSelector(state => state.supplier.suppliers);
  const userPriceLists = useAppSelector(state => state.priceList.userPriceLists);
  const currentOrderRebarCount = useAppSelector(state => state.rebar.currentOrderRebarCount);
  const currentOrderNetsCount = useAppSelector(state => state.net.currentOrderNetsCount);

  const orderInitialState = {
    order_no: "",
    order_id: 0,
    name: "",
    created: format(new Date(), "yyyy-MM-dd"),
    updated: format(new Date(), "yyyy-MM-dd"),
    delivery_date: "",
    business: 0,
    project: 0,
    order_notes: "",
    weight_concentration: false,
    units_concentration: false,
    sketch_concentration: false,
    processing_concentration: false,
  };

  const [order, setOrder] = useState(orderInitialState);

  useEffect(() => {
    steelTypes.length === 0 && dispatch(fetchSteelTypes());
  }, [steelTypes.length, dispatch]);

  // useEffect(() => {
  //   if (userData && suppliers.length === 0) dispatch(fetchSuppliers());
  // }, [userData, suppliers.length, dispatch]);

  // useEffect(() => {
  //   if (userData && userPriceLists.length === 0) dispatch(fetchUserPriceLists());
  // }, [userData, userPriceLists.length, dispatch]);

  useEffect(() => {
    if (steelTypes.length > 0) {
      steelTypes.forEach((st_type) => {
        dispatch(fetchSteelDiameters(st_type.id));
      });
    }
  }, [steelTypes, dispatch]);

  useEffect(() => {
    if (orderData) {
      setOrder((prev) => ({
        ...prev,
        order_id: orderData.id,
        order_no: orderData.order_no,
        name: orderData.name,
        created: format(new Date(orderData.created), "yyyy-MM-dd"),
        delivery_date: format(new Date(orderData.delivery_date), "yyyy-MM-dd"),
        business: orderData.business?.id,
        project: orderData.project.id,
        order_notes: orderData.order_notes,
        updated: format(new Date(orderData.last_modified), "yyyy-MM-dd"),
      }));

      if (edit) {
        setActiveBusiness({...orderData.business});
        setActiveProject({...orderData.project});
      }
    }
  }, [orderData, edit]);

  useEffect(() => {
    const initalFetch = async () => {
      if (user && !edit) {
        const date = localStorage.getItem('delivery_date');
        const orderName = localStorage.getItem("order_name");

        if (date) setOrder(prev => ({...prev, delivery_date: date}));
        if (orderName) setOrder((prev) => ({ ...prev, name: orderName }));

        await dispatch(getCurrentOrderRebars(edit));
        await dispatch(getCurrentOrderNets(edit));
        await dispatch(getCurrentOrderPiles(edit));
        fetchOrderNo()
        .then(res => {
          setOrder(prev => ({...prev, order_no: res.data.order_no}));
        }).catch(err => console.log(err));
      }
    }
    initalFetch();
  }, [user, edit, dispatch]);

  const handleUserKeyPress = (event:any) => {
    if ((event.ctrlKey || event.metaKey) && (event.key === "p" || event.charCode === 16 || event.charCode === 112 || event.keyCode === 80)) {
        event.preventDefault();
        dispatch(getCurrentOrderTools(edit, orderData?.id)).then(() => {
          if(currentOrderRebars.length > 0 || currentOrderNets.length > 0 || currentOrderPiles.length > 0){
            setPrintCanvas(true);
            setPdfPreviewShow(true);
          }
        });
    };
  }

  useEffect(()=>{
    window.addEventListener('keydown', handleUserKeyPress)

    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  },[currentOrderRebars, currentOrderNets])

  const PDFGeneratingResponse = (isSuccessfull:boolean) => {
    setLoader(false);
    setPdfPreviewShow(false);
    if (isSuccessfull){
      setShareOptionsShow(true);
    } else{
      setShareStatestoInitial();
      setResponseStatus({
        msg: t("pdf cannot generated", { ns: "warning" }),
        color: "#CD2017",
      });
      setResponseMsg({
        msg: t("cannot generate", { ns: "warning" }),
        color: "#2C3341",
      });
      setResponseMsgModalShow(true);
      setTimeout(() => {
        setResponseMsgModalShow(false);
      }, timeout_secs);
    }
  };

  useEffect(() => {
    if (pdfFile) {
      const data = {
        document_name: sharableOrderId + ".pdf",
      };
      postDocumentName(data)
        .then((res) => {
          const url = res.data.data.presigned_url;
          setDocumentUrl(res.data.data.document_url);
          uploadDocument(url, pdfFile)
            .then((res) => {
              PDFGeneratingResponse(true);
            })
            .catch((err) => {
              PDFGeneratingResponse(false);
            });
        })
        .catch((err) => {
          PDFGeneratingResponse(false);
        });
    }
  }, [pdfFile]);

  const openItemModal = (type: number) => {
    setTab(type);
    setItemModalShow(true);
  }

  const errorOnSave = () => {
    setResponseStatus({
      msg: t("cannot save", { ns: "warning" }),
      color: "#CD2017",
    });
    setResponseMsg({
      msg: t("order cannot saved", { ns: "warning" }),
      color: "#2C3341",
    });
    setResponseMsgModalShow(true);
    setTimeout(() => {
      setResponseMsgModalShow(false);
    }, timeout_secs);
    setSaveOrderMessageShow(false);
  }

  const setShareStatestoInitial = () => {
    setPdfFile("");
    setSharableOrderId("");
  };

  const setOrdertoInitial = () => {
    setOrder(orderInitialState);
    fetchOrderNo()
      .then((res) => {
        setOrder((prev) => ({ ...prev, order_no: res.data.order_no }));
      })
      .catch((err) => console.log(err));
    dispatch(getCurrentOrderRebars(false));
    dispatch(getCurrentOrderNets(false));
    dispatch(getCurrentOrderPiles(false));
  }

  // Auto-Save Order Name
  useEffect(() => {
    const autoSave = () => {
      if (edit && orderData.name !== order.name) { 
        patchOrder(id, { name: order.name }).then((res) => {
          setOrder((prev) => ({
            ...prev,
            updated: format(new Date(res.data.last_modified), "yyyy-MM-dd"),
          }));
        });
      } else{
        return;
      }
    }

    if (order.name !== "") {
      autoSave();
    }

  }, [order.name,dispatch])

  

  const saveOrder = (isSharedTrue:boolean) => {
    return new Promise((resolve, reject) =>{
    //if (!user.current_company) return;
    const orderDatalocal = {
      ...order,
      name: order.name,
      created: new Date(order.created).toISOString(),
      delivery_date: new Date(order.delivery_date).toISOString(),
      business: user.current_company,
      project: (activeProject as any).id,
      user: user.id,

    };
    let orderPromise;
    let isEditMode;
    if (edit) {
      orderPromise = patchOrder(orderData.id, orderDatalocal);
      isEditMode = true;
    } else {
      orderPromise = postOrder(orderDatalocal);
      localStorage.removeItem("order_name");
      isEditMode = false;
    }
    return orderPromise.then((res) => {
      if (res.status === 200 || res.status === 201) {
        resolve();
        //setOrderSuccessFullyCreated(true);
        setSharableOrderId(res.data.order_no);
        setOrderIdforShare(res.data.id);
        if (isSharedTrue) {
          setSaveOrderMessageShow(false);
          setPdfPreviewShow(true);
          setGeneratePdfFlag(true);
        } else {
          setOrdertoInitial();
          // setAlertText(t("save alert", { ns: "warning" }));
          // setAlertIcon(checkIcon);
          setSaveOrderMessageShow(false);

          // modal pop-up on save button Click
          setPopUpText(t("save alert", { ns: "warning" }))
          //these two states are for the bids popup modals
          //setPopUpModalShow(true);
          //setGetBidCalled(false)

          // setAlertModalShow(true);
          // setTimeout(() => {
          //   setAlertModalShow(false);
          // }, timeout_secs);
        }
        return;
      }
      errorOnSave();
      reject();
    }).catch(err => {
      errorOnSave();
      reject();
    });
    })
  }

  const handleImagePreview = (preview: any) => {
    setImagePreview(preview);
    setImagePreviewShow(true);
  }

  const modifyRowNumbers = async (row: number, type: string, orderId: number) => {

    const itemCollections = {
      [REBAR_SUBCLASS_TYPE]: currentOrderRebars,
      [NET_SUBCLASS_TYPE]: currentOrderNets,
      [PILE_SUBCLASS_TYPE]: currentOrderPiles,
    };
    const setItems = {
      [REBAR_SUBCLASS_TYPE]: setCurrentOrderRebars,
      [NET_SUBCLASS_TYPE]: setCurrentOrderNets,
      [PILE_SUBCLASS_TYPE]: setCurrentOrderPiles,
    }
    
    const item = itemCollections[type]?.find((element) => element.row_number === row);
    if(item){
      const rowNums = {
        [type]: { [item.id]: item.row_number },
      };
      await dispatch(setDuplicateRows(rowNums));
    }
    const updatedItems = itemCollections[type].map(item => {
      if (item?.row_number > row) {
        return {
          ...item,
          row_number: item?.row_number + 1
        };
      }
      return item;
    });
    await dispatch(setItems[type](updatedItems));
  };

  const copyOrderItem = async() => {
    return new Promise((resolve, reject) => {
      const _toolItem = (selectedTool as any);
      if (_toolItem.id !== 0) {
        setCoping(true);
        setTooltipText('Copied!');
  
        const callBack = (val: boolean) => {
          if (val) {
            setCoping(false);
            setShowTooltip(true);
            setTimeout(() => setShowTooltip(false), 500);
          }
        }
  
        const _tool = {..._toolItem.tool};
        const deleteKeysTool = ['id', 'created', 'last_modified', 'default_image'];
        deleteKeysTool.forEach((k: string) => delete _tool[k]);
  
        _tool.preview_image = _tool.preview_image.id;
        const _orderItem = { ..._toolItem };
        const deleteKeys = [
          "id",
          "tool",
          "created",
          "last_modified",
          "order",
        ];
        deleteKeys.forEach((k: string) => delete _orderItem[k]);
        if (_toolItem.tool.subclass_type === REBAR_SUBCLASS_TYPE) {
          delete _tool.icon;
          delete _tool.rebar_filter;
          _tool.diameter = _tool.diameter.id;
          _tool.rebar_side = [..._tool.rebar_side];
          for (let i = 0; i < _tool.rebar_side.length; i = i + 1) {
            const copySide = { ..._tool.rebar_side[i] };
            delete copySide.id;
            delete copySide.rebar;
            _tool.rebar_side[i] = copySide;
          }
          modifyRowNumbers(_toolItem.row_number, REBAR_SUBCLASS_TYPE, order.order_id)
            .then(() => {
              // Creating Duplicate Row
              dispatch(createRebar(_tool,  _orderItem, callBack, _toolItem.row_number + 1, order.order_id))
              .then(() => {
                dispatch(setCurrentOrderRebarCount(currentOrderRebarCount + 1));
                resolve(true);
              });;
            })
  
        } else if (_toolItem.tool.subclass_type === NET_SUBCLASS_TYPE) {
          _tool.x_diameter = _tool.x_diameter.id;
          _tool.y_diameter = _tool.y_diameter.id;
          let num = currentOrderNets.length + 1;
          _tool.tool_number = num;
          modifyRowNumbers(_toolItem.row_number, NET_SUBCLASS_TYPE, order.order_id)
          .then(() => {
            dispatch(createNet(_tool,  _orderItem, callBack, _toolItem.row_number + 1, order.order_id))
            .then(() => {
              dispatch(setCurrentOrderNetsCount(currentOrderNetsCount + 1));
              resolve(true);
            });
          })

        } else if (_toolItem.tool.subclass_type === PILE_SUBCLASS_TYPE) {
          _tool.spiral_wire_diameter = _tool.spiral_wire_diameter.id;
          let deleteKeysPile = [] as any;
          if (!_tool.ring_diameter)
            deleteKeysPile = [
              ...deleteKeysPile,
              "ring_diameter",
              "ring_quantity",
            ];
          if (!_tool.gama_diameter)
            deleteKeysPile = [
              ...deleteKeysPile,
              "gama_diameter",
              "gama_quantity",
            ];
          if (!_tool.ear_type)
            deleteKeysPile = [...deleteKeysPile, "ear_type", "ear_length"];
  
          if (_tool.base_diameter.id) {
            deleteKeysPile = [
              ...deleteKeysPile,
              "external_diameter",
              "external_amount",
              "middle_diameter",
              "middle_amount",
              "internal_diameter",
              "internal_amount",
            ];
            _tool.base_diameter = _tool.base_diameter.id;
          } else if (
            _tool.external_diameter.id &&
            _tool.middle_diameter.id &&
            _tool.internal_diameter.id
          ) {
            deleteKeysPile = [
              ...deleteKeysPile,
              "base_diameter",
              "base_amount",
            ];
            _tool.external_diameter = _tool.external_diameter.id;
            _tool.middle_diameter = _tool.middle_diameter.id;
            _tool.internal_diameter = _tool.internal_diameter.id;
          }
          else if (
            _tool.external_diameter.id &&
            _tool.internal_diameter.id
          ) {
            deleteKeysPile = [
              ...deleteKeysPile,
              "base_diameter",
              "base_amount",
              "middle_diameter",
              "middle_amount",
            ];
            _tool.external_diameter = _tool.external_diameter.id;
            _tool.internal_diameter = _tool.internal_diameter.id;
          }

          deleteKeysPile.forEach((k: string) => delete _tool[k]);
  
          modifyRowNumbers(_toolItem.row_number, PILE_SUBCLASS_TYPE, order.order_id)
          .then(() => {
            dispatch(createPile(_tool, _orderItem, callBack, _toolItem.row_number + 1, order.order_id))
            .then(() => {
              resolve(true);
            });
          })
        }
        
      } else {
        setTooltipText('Select tool to make a copy');
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 1000);
        resolve(true);
      }

    });
};


  const handleUpdate = () => {
    const _toolItem = (selectedTool as any);
    if (_toolItem.id !== 0) {
      setEditTool(true);
      setEditToolId(_toolItem.id);
      if (_toolItem.tool.subclass_type === REBAR_SUBCLASS_TYPE) {
        setTab(1);
      } else if (_toolItem.tool.subclass_type === NET_SUBCLASS_TYPE) {
        setTab(2);
      } else if (_toolItem.tool.subclass_type === PILE_SUBCLASS_TYPE) {
        setTab(3);
      }
      setItemModalShow(true);
    } else {
      setShowTooltipUpd(true);
      setTimeout(() => setShowTooltipUpd(false), 800);
    }
  }

  const handleDownload = () => {
    if (pdfRef.current) (pdfRef.current as any).print()
  }

  useEffect(() => {
    if (userData && userData.current_company) {
      getBusinessInstance(userData.current_company).then((res) => {
        setCurrentCompany(res.data);
      });
    }
  }, [userData]);

  // hide popup
  const handleCancel = () => {
    setPopUpModalShow(false)
  }

  //  function for placing the bid order
  const handleNextBid = () => {
    // calling the clickables api
    getClickables().then(res => {
      if (res.status === 200) {
        setClickableId(res.data.results[0].id)
        setSlug(res.data.results[0].slug); 
      }
    })
    setGetNextBidCall(true)
    if (edit) {
      postBidRequest({
        order: orderData.id,
        note: notesChange || null
      })
    } else {
      postBidRequest({
        order: orderIdforShare,
        note: notesChange || null
      })
    }
    // orderNotes analytics post request
    if (clickableId !== null && slug === 'get_bid') {
      postClickables({
        clickable: clickableId
      })
    }
  }

  useEffect(() => {
    if (clickableId !== null && slug === 'get_bid') {
      postClickables({
        clickable: clickableId
      })
    }
  }, [clickableId, slug])
  

  //navigating in useEffect for now (coz bids popups are commented)
  useEffect(()=>{
    if (orderSaved) {
      navigate.push("/my-order");
    }
  }, [orderSaved])

  // route to my-order page
  const changeRoute = () => {
    // if(orderSaved){
    //   navigate.push("/my-order");
    // }
  }

  const changeRouteOnShare = () => {
    navigate.push(`/edit-order/${orderIdforShare}`)
  }

  // Initial setup for thank-you page
  const nextBidBtn = <button onClick={() => handleNextBid()}>  {t("bid", { ns: "common" })}</button>
  const iconRequest = <img src={RequestIcon} alt="Icon" />
  const accept_text = <p>{t("request", { ns: "common" })}</p>
  const thankBtn = <button onClick={changeRoute}>{t("thank", { ns: "common" })}</button> //redirect to my-order
  

  const BidOrderCalled = () => {
    // getting the bars id's.
    const netIds = [...currentOrderNets].map((item: any) => item.id);
    const pileIds = [...currentOrderPiles].map((item: any) => item.id);
    const rebarIds = [...currentOrderRebars].map((item: any) => item.id);

    //calling the order-save api.
    const orderDatalocal = {
      ...order,
      name: order.name,
      created: new Date(order.created).toISOString(),
      delivery_date: new Date(order.delivery_date).toISOString(),
      business: userData.current_company,
      project: (activeProject as any).id,
      user: userData.id,
      order_items: [
        ...netIds,
        ...pileIds,
        ...rebarIds,
      ],
    };

    let orderPromise;
    let isEditMode;
    if (edit) {
      orderPromise = patchOrder(orderData.id, orderDatalocal);
      isEditMode = true;
    } else {
      orderPromise = postOrder(orderDatalocal);
      isEditMode = false;
    }
    return orderPromise.then((res) => {
      if (res.status === 200 || res.status === 201) {
        setOrderIdforShare(res.data.id);
      }

    })

  }
  //function for calling order notes modal
  const handleBidClicked = (bidBtnClicked) => {

    bidBtnClicked && BidOrderCalled()
    getClickables().then(res => {
      if (res.status === 200) {
        setClickableId(res.data.results[0].id)
        setSlug(res.data.results[0].slug);
      }
    })
    //  


    setGetBidCalled(true)
    setPopUpModalShow(true)
    setPopUpText(t("order note", { ns: "warning" }))
  }

  const scrollToElement = ()=>{
    const fragment = window.location.hash;
    if (fragment) {
      const targetId = fragment.slice(1);
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
  // Initial setup for asking user-question
  const bidBtn = <button onClick={() => handleBidClicked()}>  {t("bid", { ns: "common" })}</button>
  const notNow = <button onClick={() => {handleCancel(); changeRoute();}}>{t("not now", { ns: "common" })}</button>
  const cancel = <button onClick={() => {handleCancel(); changeRoute();}}>{t("cancel", { ns: "common" })}</button>
  const icon = <img src={popUpIcon} alt="Icon" />
  const question = <p>{t("bid question", { ns: "common" })}</p>

  // order-notes 
  const handleNotesChange = (e) => {
    setNotesChange(e.target.value)
  }

  const notes = <textarea onChange={handleNotesChange}
    cols="50"
    rows="9"
    placeholder={t("placeholder", { ns: "common" })}>

  </textarea>

  return (
    <CustomerLayout>
      <OrderDetails
        onCreateUserModalOpen={() => setUserModalShow(true)}
        onNewBusinessModalOpen={() => {
          setBusinessModalShow(true);
          setEditBusiness(false);
        }}
        onProjectModalOpen={() => {
          setProjectModalShow(true);
          setEditProject(false);
        }}
        activeBusiness={activeBusiness}
        setActiveBusiness={setActiveBusiness}
        handleEditBusiness={() => {
          setBusinessModalShow(true);
          setEditBusiness(true);
        }}
        activeProject={activeProject}
        setActiveProject={setActiveProject}
        handleEditProject={(data: string) => {
          if (data === "delivery_detail_show") {
            setScrollDetailDelivery(true);
          }
          setProjectModalShow(true);
          setEditProject(true);
        }}
        order={order}
        setOrder={setOrder}
        setErrorText={setErrorText}
        edit={edit}
        currentCompany={currentCompany}
        setCurrentCompany={setCurrentCompany}
        setShowDetails={setShowDetails}
        showDetails={showDetails}
      />
      {/* <p style={pad}></p> */}
      {isBrowser && (
        <OrderWeb
          onItemModalOpen={() => setItemModalShow(true)}
          openItemModal={openItemModal}
          order={order}
          setOrder={setOrder}
          saveOrder={saveOrder}
          handleImagePreview={handleImagePreview}
          openPdfPreview={() => setPdfPreviewShow(true)}
          selectedTool={selectedTool}
          setSelectedTool={setSelectedTool}
          openDeleteModal={() => setDeleteOrderItemShow(true)}
          copyOrderItem={copyOrderItem}
          showTooltip={showTooltip}
          coping={coping}
          tooltipText={tooltipText}
          handleUpdate={handleUpdate}
          showTooltipUpd={showTooltipUpd}
          handleDownload={handleDownload}
          priceShow={priceShow}
          openPriceListModal={() => setPriceShowModal(true)}
          errorText={errorText}
          setErrorText={setErrorText}
          setDeleteMessageShow={setDeleteMessageShow}
          setSaveOrderMessageShow={setSaveOrderMessageShow}
          setSaveBeforeShareShow={setSaveBeforeShareShow}
          setDownloadFlag={setDownloadFlag}
          activeProject={activeProject}
          activeBusiness={activeBusiness}
          setPrintCanvas={setPrintCanvas}
          edit={edit}
          editOrderId={orderData?.id}
          handleBidClicked={handleBidClicked}
          setOrderSaved={setOrderSaved}
          scrollToElement={scrollToElement}
        />
      )}
      {isMobile && (
        <OrderMobile
          onItemModalOpen={() => setItemModalShow(true)}
          order={order}
          setOrder={setOrder}
          saveOrder={saveOrder}
        />
      )}
      <CreateNewUser
        show={userModalShow}
        openLogin={() => setLoginShow(true)}
        onHide={() => setUserModalShow(false)}
      />
      <Login show={loginShow} onHide={() => setLoginShow(false)} />
      <AddNewCompany
        show={businessModalShow}
        onHide={() => setBusinessModalShow(false)}
        edit={editBusiness}
        business={activeBusiness}
        currentCompany={true}
      />
      <AddNewProject
        show={projectModalShow}
        scrollDetaildelivery={scrollDetailDelivery}
        onHide={() => {
          setProjectModalShow(false);
          setScrollDetailDelivery(false);
        }}
        edit={editProject}
        project={activeProject}
      />
      <NewItem
        show={itemModalShow}
        onHide={() => {
          setItemModalShow(false);
          setEditTool(false);
          setSelectedTool({
            id: 0,
          });
          setEditToolId(-1);
        }}
        tab={tab}
        setTab={setTab}
        edit={editTool}
        setEditTool={setEditTool}
        editOrderId={orderData?.id}
        editMode={edit}
        selectedTool={selectedTool}
        setSelectedTool={setSelectedTool}
        order={order}
        editToolId={editToolId}
      />
      <ImagePreview
        show={imagePreviewShow}
        onHide={() => {
          setImagePreviewShow(false);
          setImagePreview({});
        }}
        imageSrc={imagePreview ? (imagePreview as any).file_field : ""}
        alt={imagePreview ? (imagePreview as any).alt_text : ""}
      />
      <PdfPreviewModal
        show={pdfPreviewShow}
        onHide={() => setPdfPreviewShow(false)}
        setDownloadFlag={setDownloadFlag}
        downloadFlag={downloadFlag}
        printCanvas={printCanvas}
        setPrintCanvas={setPrintCanvas}
        setGeneratePdfFlag={setGeneratePdfFlag}
        generatePdfFlag={generatePdfFlag}
        setPdfFile={setPdfFile}
        pdfFile={pdfFile}
        setLoader={setLoader}
        loader={loader}
        order={{
          ...order,
          user,
          business: currentCompany,
          project: activeProject,
        }}
        rebars={currentOrderPdfRebars}
        nets={currentOrderPdfNets}
        piles={currentOrderPdfPiles}
        pdfType={OrderPdfType.ORIGINAL_PDF}
        bidFees={""} //admin panel only prop
        pdfLanguage={""} //admin panel only prop
        feeType={""} //admin panel only prop
      />
      <DeleteOrderItemModal
        show={deleteOrderItemShow}
        onHide={() => setDeleteOrderItemShow(false)}
        seletedOrderItem={selectedTool}
        setSelectedTool={setSelectedTool}
        edit={edit}
        editId={orderData?.id}
      />
      <PriceList
        show={priceShowModal}
        onHide={() => setPriceShowModal(false)}
      />
      <OrderSuccess
        show={orderSuccessFullyCreated}
        onHide={() => setOrderSuccessFullyCreated(false)}
      />
      {/* <div className='modal-document-w' style={{display: 'block'}}>
        <PdfPreview
          order={order}
          rebars={currentOrderRebars}
          nets={currentOrderNets}
          piles={currentOrderPiles}
          priceShow={false}
        />
      </div> */}
      <DeleteOrderModal
        show={deleteMessageShow}
        onHide={() => setDeleteMessageShow(false)}
        rebars={currentOrderRebars}
        nets={currentOrderNets}
        setOrder={() => setOrder(orderInitialState)}
        editMode={edit}
        orderData={orderData}
      />
      <SaveOrderModal
        show={saveOrderMessageShow}
        onHide={() => setSaveOrderMessageShow(false)}
        saveBeforeShareShow={saveBeforeShareShow}
        saveOrder={saveOrder}
      />
      <ShareOptionsModal
        show={shareOptionsShow}
        onHide={(flag = "") => {
          setShareOptionsShow(false);
          setShareStatestoInitial();
          if (flag !== "emailModal") {
            changeRouteOnShare();
          }
        }}
        dialogClassName={"modal_width"}
        setSendByEmailShow={setSendByEmailShow}
        documentUrl={documentUrl}
      />
      <SendbyMail
        show={sendByEmailShow}
        onHide={() => setSendByEmailShow(false)}
        documentUrl={documentUrl}
        orderId={orderIdforShare}
        userData={userData}
        setResponseMsgModalShow={setResponseMsgModalShow}
        setResponseStatus={setResponseStatus}
        setResponseMsg={setResponseMsg}
        setShareStatestoInitial={setShareStatestoInitial}
        setOrdertoInitial={setOrdertoInitial}
        setDocumentUrl={setDocumentUrl}
        changeRouteOnshare={changeRouteOnShare}
      />
      <ResponseMsgModal
        show={responseMsgModalShow}
        onHide={() => setResponseMsgModalShow(false)}
        dialogClassName={"modal_width"}
        responseStatus={responseStatus}
        responseMsg={responseMsg}
      />
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            backgroundColor: '#00B38F',
            color: "#fff",
          },
        }}      
      />

      {getBidCalled ? (
        <PopUpModal
          show={popUpModalShow}
          onHide={() => {
            setPopUpModalShow(false);
            changeRoute();
          }}
          popUpText={popUpText}
          nextBidBtn={nextBidBtn}
          cancel={cancel}
          notes={notes}
          txt__color="#374051"
        />
      ) : (
        <PopUpModal
          show={popUpModalShow}
          onHide={() => {
            setPopUpModalShow(false);
            changeRoute();
          }}
          popUpText={popUpText}
          icon={icon}
          bidBtn={bidBtn}
          notNow={notNow}
          question={question}
          txt__color="#00B38F"
        />
      )}

      {/* Displaying Thank-You page */}
      {getNextBidCall ? (
        <PopUpModal
          show={popUpModalShow}
          onHide={() => setPopUpModalShow(false)}
          iconRequest={iconRequest}
          accept_text={accept_text}
          thankBtn={thankBtn}
          txt__color="#374051"
        />
      ) : null}
    </CustomerLayout>
  );
}

export default NewOrder;
