import { Modal } from "react-bootstrap";
import { useAppSelector } from "Redux/hooks";
import "./popup__modal.scss";

function PopUpModal({ ...props }: any) {
  const {
    popUpText,
    icon,
    bidBtn,
    nextBidBtn,
    cancel,
    notNow,
    notes,
    question,
    txt__color,
    accept_text,
    iconRequest,
    thankBtn,
  } = props;

  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <section
          className={
            rightToLeft
              ? "custom__popup__modal__left"
              : "custom__popup__modal__right"
          }
          style={{ backgroundColor: "#EBECEE", borderRadius: "23px" }}
        >
          <div className={rightToLeft ? "text_left" : "text_right"}>
            <p className="popup__text" style={{ color: txt__color }}>
              {popUpText}
            </p>

            {notes && <p className="notes">{notes}</p>}
          </div>

          <div className="bid__icon">{icon}</div>

          {iconRequest && (
            <div className="bid__icon__request">{iconRequest}</div>
          )}
          <div
            className={rightToLeft ? "get_bid_text_left" : "get_bid_text_right"}
          >
            {question && (
              <p className={rightToLeft ? "bid_txt_left" : "bid_txt_right"}>
                {question}
              </p>
            )}

            {accept_text && (
              <p
                className={rightToLeft ? "accept_txt_left" : "accept_txt_right"}
              >
                {accept_text}
              </p>
            )}
          </div>
          <div className={rightToLeft ? "bid_btns_left" : "bid_btns_right"}>
            <span
              className={
                rightToLeft ? "bid__cancel__btn_left" : "bid__cancel__btn_right"
              }
            >
              {cancel}
            </span>
            {notNow && (
              <span
                className={
                  rightToLeft
                    ? "bid__cancel__btn_left"
                    : "bid__cancel__btn_right"
                }
              >
                {notNow}
              </span>
            )}

            <span
              className={rightToLeft ? "get__bid__left" : "get__bid__right"}
            >
              {bidBtn}
            </span>

            {nextBidBtn && (
              <span
                className={rightToLeft ? "get__bid__left" : "get__bid__right"}
              >
                {nextBidBtn}
              </span>
            )}
            {thankBtn && (
              <span
                className={
                  rightToLeft ? "get__bid__left" : "get__bid__right thankBtn"
                }
              >
                {thankBtn}
              </span>
            )}
          </div>
        </section>
      </Modal>
    </>
  );
}

export default PopUpModal;
