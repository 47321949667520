import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { angles, labels } from "Constants/rebar-constants";

import icon1 from 'Assets/img/add__item/sm_icon__list/icon__2.svg';
// import emptySet from 'Assets/img/icon/empty-set-mathematical-symbol.png';
// import sliderImage from 'Assets/img/add__item/sliderImg.svg';
import create from 'Assets/img/cages/create.svg';
import length from 'Assets/img/cages/length.svg';
import quantity from 'Assets/img/cages/quantity.svg';
import weight from 'Assets/img/cages/weight.svg';
import writer from 'Assets/img/cages/writer.svg';
import setSide from 'Assets/img/icon/setSide.svg';
import angle from 'Assets/img/icon/angle.svg';
import setEmpty from 'Assets/img/icon/setempty.svg';

import RebarShape from "Components/tool/rebar/shape";
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";

function MobileContent({
  shapeObj,
  stageRef,
  setShape,
  setSides,
  selectedShape,
  changeShape,
  onlyRightAngles,
  setOnlyRightAngles,
  setAngle,
  setRadius,
  addRadius,
  setAddRadius,
  orderItem,
  setOrderItem,
  standardShapes,
  handleChangeDiamter,
  allPoints,
  setAllPoints,
  rebarNumber,
  setRebarNumber,
  saveRebar,
  initiateImage,
}: any) {
  const steelTypes = useAppSelector(state => state.steelType.steelTypes);
  const steelDiameters = useAppSelector(state => state.steelType.steelDiameters);
  const { t } = useTranslation(["newOrder", "common", "measurements"]);


  return (
    <>
      {/* <!-- eight icon rows  --> */}
      <div className="twoRowEightIcon bg-white py-4">
      {/* <!-- first row  --> */}
      <div className="row mx-0">
        {standardShapes
          && standardShapes.length > 0
          && standardShapes.map((defaultShape: any, index: number) =>
            <div 
              key={"standardShape"+index}
              onClick={() => changeShape(index)}
              className={"col-3 d-flex align-items-center justify-content-center" + (index >= 4 ? " mt-4": "")}
            >
              <span className={"icon__wrap" + (selectedShape === index ? " active-bg" : "")}>
                <img
                  src={defaultShape.icon.file_field || icon1}
                  alt={defaultShape.icon.alt_text || "tools images"}
                />
              </span>
            </div>
          )}
      </div>
      </div>
      {/* <!-- dark section  --> */}
      {/* <div className="p-3 d-flex dark__head align-items-center bg_gradient">
      <span className="icon__wrap text-success d-flex align-items-center justify-content-center mr-3">
        <FontAwesomeIcon icon={["far", "star"]} />
      </span>
      <h2 className="black__wrap text-uppercase text-left text-white mb-0">staddard Rebar</h2>
      </div> */}
      {/* <!-- image slider  --> */}
      {/* <div className="img__slider p-3 p-sm-4 p-md-5 bg-white">
      <div className="icon__form d-flex justify-content-between py-3 bg-white">
        <div className="left__sec d-flex align-items-center mb-1">
          <div className="icon__wrap mr-3">
            <img src={emptySet} alt="set icon" />
          </div>
          <select className="form-control w-xs-100 ml-3">
            <option>12</option>
            <option>10</option>
            <option>5</option>
          </select>
        </div>
        <div className="right__sec d-flex justify-content-end align-items-center">
        </div>
      </div>
      <div className="img__container d-flex pb-4">
        <div className="card__list">
          <img src={sliderImage} alt="scroll images"/>
        </div>
        <div className="card__list">
          <img src={sliderImage} alt="scroll images"/>
        </div>
        <div className="card__list">
          <img src={sliderImage} alt="scroll images"/>
        </div>
        <div className="card__list">
          <img src={sliderImage} alt="scroll images"/>
        </div>
        <div className="card__list">
          <img src={sliderImage} alt="scroll images"/>
        </div>
      </div>
      </div> */}
      {/* <!-- dark section  --> */}
      <div className="p-3 d-flex dark__head align-items-center bg_gradient">
      <span className="icon__wrap text-success d-flex align-items-center justify-content-center mr-3">
        <img src={create} alt="create icon"/>
      </span>
      <h2 className="black__wrap text-uppercase text-left text-white mb-0">create shapres</h2>
      </div>
      {/* <!-- preview and btn  --> */}
      <div className="bg-white p-4">
      {/* <!-- btn icon and heading  --> */}
      <div className="btn__icon__head d-flex justify-content-between">
        {/* <!-- icon and heading  --> */}
        <div className="icon__head d-flex align-items-center justify-content-center">
          <p className="big__para">Preview</p>
        </div>
        {/* <!-- buttons  --> */}
        <button className="btn bg-dark-500 text-dark d-flex justify-content-between align-items-center cus__btn">
          <span className="icon__wrap"><FontAwesomeIcon icon="plus" /> </span>
          <p className="ml-2  text-uppercase">add a sketch</p>
        </button>
      </div>
      {/* <!-- image image table  --> */}
      <div className="main__img__table mt-3 mt-sm-4 d-flex align-items-center justify-content-center rounded">
        {/* <!-- right image  --> */}
        <RebarShape
          shapeObj={shapeObj}
          allPoints={allPoints}
          setAllPoints={setAllPoints}
          stageRef={stageRef}
          initiateImage={initiateImage}
        />
      </div>
      {/* <!-- general size  --> */}
      <div className="sec__img__table">
        {/* <!-- btn icon and heading  --> */}
        <div className="btn__icon__head d-flex justify-content-between">
          {/* <!-- icon and heading  --> */}
          <div className="icon__head d-flex align-items-center justify-content-center py-4">
            <span className="icon__wrap mr-3 text-dark">
              <img src={length} alt="length icon"/>
            </span>
            <p className="big__para">Preview</p>
          </div>
        </div>
        {/* <!-- steel type  --> */}
        <div className="icon__form d-flex justify-content-between border__bottom py-3">
          {/* <!-- left section  --> */}
          <div className="left__sec d-flex align-items-center">
            {/* <!-- para  --> */}
            <p className="big__para">Rebar Number</p>
          </div>
          {/* <!-- right section  --> */}
          <div className="right__sec d-flex justify-content-end align-items-center w-xs-40">
            <input
              type="text"
              className="form-control bg-white mid__bold text-center border-success"
              value={rebarNumber}
              onChange={(e) => {
                if (e.target.value !== '' && e.target.value[0] === "B") {
                  setRebarNumber(e.target.value);
                }
              }}
            />
          </div>
        </div>
        {/* <!-- img table  --> */}
        <div className="img__table border__bottom mt-3">
          <div className="input__container row mb-3">
            {shapeObj.rebar_side && shapeObj.rebar_side.map((side: any, index: number) => (
              <div className="col-4 d-flex align-items-center mb-2" key={'len-d'+side.id}>
                <h5 className="text-uppercase bold mr-2">{labels[index]}</h5>
                <input
                  type="number"
                  className="form-control bg-white border-success input__big"
                  value={side.length || ''}
                  onChange={(e) => setSides(e.target.value, index)}
                />
              </div>
            ))}

            {/* <!-- column  --> */}
            {/* <div className="col-4 d-flex align-items-center mt-4 justify-content-between">
              <button className="cus__btn"><FontAwesomeIcon icon="minus"/></button>
              <button className="cus__btn"><FontAwesomeIcon icon="plus" /></button>
            </div> */}
          </div>
        </div>
        {/* <!-- steel type  --> */}
        <div className="icon__form d-flex justify-content-between border__bottom py-3">
          {/* <!-- left section  --> */}
          <div className="left__sec d-flex align-items-center">
            {/* <!-- icon  --> */}
            <div className="icon__wrap mr-3">
              <img src={setEmpty} alt="set icon"/>
            </div>
            {/* <!-- para  --> */}
            <p className="big__para text-uppercase">steel type</p>
          </div>
          {/* <!-- right section  --> */}
          <div className="right__sec d-flex justify-content-end align-items-center">
            <select className="form-control w-xs-100 ml-3 mid__bold"
              value={shapeObj.steel_type || ''}
              onChange={({target}) => setShape((prev: any) => ({...prev, steel_type: parseInt(target.value)}))}
            >
              {steelTypes.map((st: {id: number, name: string}) =>
                <option key={'rebar-steeltype-options-' + st.id} value={st.id}>{st.name}</option>
              )}
            </select>
          </div>
        </div>
        {/* <!-- diameter  --> */}
        <div className="icon__form d-flex justify-content-between border__bottom py-3">
          {/* <!-- left section  --> */}
          <div className="left__sec d-flex align-items-center">
            {/* <!-- icon  --> */}
            <div className="icon__wrap mr-3">
              <img src={setSide} alt="set icon"/>
            </div>
            {/* <!-- para  --> */}
            <p className="big__para text-capitalize">diameter</p><span className="unit">({t("cm", { ns: "measurements" })})</span>
          </div>
          {/* <!-- right section  --> */}
          <div className="right__sec d-flex justify-content-end align-items-center w-xs-40">
            <select className="form-control ml-3 mid__bold"
              value={shapeObj?.diameter?.id || ''}
              onChange={handleChangeDiamter}
            >
              {shapeObj.steel_type in steelDiameters
              && steelDiameters[shapeObj.steel_type].map((reDi: any, index: number) =>
                <option key={'rebar-diameter-options-' + index} value={reDi.id}>{reDi.steel_diameter.toFixed(0)}</option>
              )}
            </select>
          </div>
        </div>
        {/* <!-- mix and 90% button  --> */}
        <div className="icon__form border__bottom py-3">
          <div className="d-flex justify-content-between">
            {/* <!-- left section  --> */}
            <div className="left__sec d-flex align-items-center">
              {/* <!-- icon  --> */}
              <div className="icon__wrap mr-3">
                <img src={angle} alt="set icon"/>
              </div>
              {/* <!-- para  --> */}
              <p className="big__para text-capitalize">angle</p>
            </div>
            {/* <!-- right section  --> */}
            {/* <div className="right__sec d-flex justify-content-end align-items-center">
              <div className="btn-group" role="group" aria-label="Basic example">
                <button
                type="button"
                onClick={() => setOnlyRightAngles(false)}
                className={"btn text-dark para" + (onlyRightAngles? "": " text-white active")}>mix</button>
                <button
                type="button"
                onClick={() => setOnlyRightAngles(true)}
                className={"btn text-dark para text-uppercase mid__bold" + (onlyRightAngles? " text-white active": "")}>90</button>
              </div>
            </div> */}
          </div>
          {!onlyRightAngles &&
            <div className="input__container row mt-3">
              {shapeObj.rebar_side && shapeObj.rebar_side.map((side: any, index: number) => side.angle !== null && (
                <div className="col-4 d-flex align-items-center mb-2" key={'len-d'+side.id}>
                  <h5 className="bold mr-2">{angles[index]}</h5>
                  <input
                    type="number"
                    className="form-control bg-white border-success input__big"
                    value={side.angle || ''}
                    onChange={(e) => setAngle(e.target.value, index)}
                  />
                </div>
              ))}
            </div>
          }
        </div>
        {/* <!-- radius  --> */}
        <div className="icon__form border__bottom py-3">
          <div className="d-flex justify-content-between">
            {/* <!-- left section  --> */}
            <div className="left__sec d-flex align-items-center">
              {/* <!-- icon  --> */}
              <div className="icon__wrap mr-3">
                <img src={angle} alt="set icon"/>
              </div>
              {/* <!-- para  --> */}
              <p className="big__para text-capitalize">radius</p>
            </div>
            {/* <!-- right section  --> */}
            <div className="right__sec d-flex justify-content-end align-items-center">
              <div className="btn-group" role="group" aria-label="Basic example">
                <button
                type="button"
                onClick={() => setAddRadius(true)}
                className={"btn text-dark para" + (addRadius? " text-white active": "")}>mix</button>
                <button
                type="button"
                onClick={() => setAddRadius(false)}
                className={"btn text-dark para text-uppercase mid__bold" + (addRadius? "": " text-white active")}>0</button>
              </div>
            </div>
          </div>
          {addRadius &&
            <div className="input__container row mt-3">
              {shapeObj.rebar_side && shapeObj.rebar_side.map((side: any, index: number) => side.radius !== null && (
                <div className="col-4 d-flex align-items-center mb-2" key={'len-d'+side.id}>
                  <h5 className="bold mr-2">{angles[index]}</h5>
                  <input
                    type="number"
                    className="form-control bg-white border-success input__big"
                    value={side.radius || ''}
                    onChange={(e) => setRadius(e.target.value, index)}
                  />
                </div>
              ))}
            </div>
          }
        </div>
      </div>
      </div>
      {/* <!-- dark section  --> */}
      <div className="p-3 d-flex dark__head align-items-center  bg_gradient">
      <h2 className="black__wrap text-uppercase text-left text-white mb-0">quantity and weight</h2>
      </div>
      {/* <!-- quantity and weight section  --> */}
      <div className="p-3 p-sm-4 bg-white bottom__radius">
      {/* <!-- quantity  --> */}
      <div className="icon__form d-flex justify-content-between border__bottom pb-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={quantity} alt="quantity icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize">quantity</p>
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-end align-items-center">
          <input type="text" className="form-control w-xs-30 bg-white mid__bold text-center"
            value={orderItem.quantity || ''}
            onChange={(e) => setOrderItem({...orderItem, quantity: parseInt(e.target.value)})}
          />
        </div>
      </div>
      {/* <!-- weight  --> */}
      <div className="icon__form d-flex justify-content-between py-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={weight} alt="weight icon" />
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize">weight</p><span className="unit">({t("kg", { ns: "measurements" })})</span>
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-between align-items-center w-xs-40">
          <p className="big__para text-capitalize">unit</p>
          <p className="big__para text-capitalize">{shapeObj.unit_weight}</p>
        </div>
      </div>
      {/* <!-- total  --> */}
      <div className="icon__form d-flex justify-content-between pb-3 border__bottom">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
        </div>
        {/* <!-- right section  --> */}
        <div className="right__sec d-flex justify-content-between align-items-center w-xs-40 total__big">
          <p className="big__para text-capitalize bold text-dark-500">Total</p>
          <p className="big__para text-capitalize bold text-dark-500">{(shapeObj.unit_weight*orderItem.quantity).toFixed(2)}</p>
        </div>
      </div>
      {/* <!-- item notes  --> */}
      <div className="icon__form d-flex justify-content-between py-3">
        {/* <!-- left section  --> */}
        <div className="left__sec d-flex align-items-center">
          {/* <!-- icon  --> */}
          <div className="icon__wrap mr-3">
            <img src={writer} alt="writer icon"/>
          </div>
          {/* <!-- para  --> */}
          <p className="big__para text-capitalize">item notes</p>
        </div>
      </div>
      {/* <!-- note pad  --> */}
      <div className="note__pad">
        <textarea className="form-control" style={{backgroundColor: '#F0F0F0'}} rows={5}
          value={orderItem.production_notes || ''}
          onChange={(e) => setOrderItem({...orderItem, production_notes: e.target.value})}
        ></textarea>
      </div>
      {/* <!-- add item btn  --> */}
      <div className="add__item__btn py-4 py-sm-5 d-flex justify-content-center">
        <button onClick={saveRebar} className="big__btn text-uppercase bold text-white">add item</button>
      </div>
      </div>
    </>
  );
}

export default MobileContent;