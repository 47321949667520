import Request from "./index";

export const getAdminUsers = (page: string, search_param: string = '') =>
  Request.get(`member-list/?cursor=${page}&search_param=${search_param}`);

export const getAdminBusinesses = (page: string) =>
  Request.get(`business-list/?cursor=${page}`);

export const getAdminOrders = (page: string) =>
  Request.get(`order-list/?cursor=${page}`);

export const getStats = () =>
  Request.get('stats/totals/');

export const getAdminSuppliers = (page: string, mode: string) =>
  Request.get(`shared-order-details/?mode=${mode}&cursor=${page}`);


export const getBidsListData = (page: string) => 
  Request.get(`supplier/bids/list/?cursor=${page}`)

export const getClickables = () =>
  Request.get('analytics/clickables/')

export const postClickables = (data: Object) =>
  Request.post('analytics/add-clicks/',data)

export const getClickableStats = () => 
  Request.get('analytics/clickable/stats/')


export const getMembersCsv = () => 
  Request.get("member-list/export/");

export const getOrdersCsv = () => 
  Request.get("order-list/export/");