import { Modal } from 'react-bootstrap';

function ImagePreview (props: any) {
  const {
    imageSrc,
    alt,
  } = props;
  return (
    <Modal
      {...props}
      className="image-preview"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-80w"
    >
      <span
        style={{
          top: '0',
          right: '0',
          width: '30px',
          margin: "6px 10px",
          background: 'transparent',
          cursor: 'pointer',
        }}
        onClick={props.onHide}
      >&#10006;</span>
      <div style={{
        height: '530px',
        padding: '0px 20px 28px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <img
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
          }}
          src={imageSrc}
          alt={alt}
        />
      </div>
    </Modal>
  );
}

export default ImagePreview;