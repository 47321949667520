import { useTranslation } from "react-i18next";
import deleteIcon from "../../../assets/img/save_export/delete.svg";
import WarningModal from "./../../custom-modal/index";
import { useAppDispatch } from "Redux/hooks";
import { removeSavedOrder } from "Redux/middlwares/order";
import { timeout_secs } from "Constants/alert-modal";
import toast from "react-hot-toast"

function SavedOrderDeleteModal(props) {
  const {
    onHide,
    orderData,
    initalOrdersFetch,
  } = props;
  const { t } = useTranslation(["common", "warning"]);
  const dispatch = useAppDispatch();

  const deleteOrderItems = () => {
    dispatch(removeSavedOrder(orderData))
      .then((result) => {
        if (result) {
          onHide();
          toast((t("delete alert", { ns: "warning" })), {
            icon: <img src={deleteIcon} alt="alert icon" />,
          });
          initalOrdersFetch();
        } else {
          toast((t("delete failed", { ns: "warning" })), {
            icon: <img src={deleteIcon} alt="alert icon" />,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const btnValues = [
    {
      text: t("dont delete", { ns: "common" }),
      btnClass: "custom__btn-secondary",
    },
    {
      text: t("confirm delete", { ns: "common" }),
      btnClass: "custom__btn-primary",
    },
  ];

  const btnFunctions = {
    primary: deleteOrderItems,
    secondary: onHide,
  };

  return (
    <WarningModal
      show={props.show}
      onHide={props.onHide}
      dialogClassName={"modal_width"}
      btnFunctions={btnFunctions}
      btnValues={btnValues}
      modalColor={"#fff"}
    >
      <div className="delete__title pb-md-4 pb-2">
        <p className="mb-0">{t("delete surety", { ns: "warning" })}</p>
      </div>
    </WarningModal>
  );
}

export default SavedOrderDeleteModal;
