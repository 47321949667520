import React from "react";
import AdminLayout from "../../layouts/admin";
import PilesCard from "./pile-card";
import NetsCard from "./net-card";
import RebarsCard from "./rebar-card";

const ShapePool = () => {

  return (
    <>
      <AdminLayout>
        <RebarsCard />
        <NetsCard />
        <PilesCard />
      </AdminLayout>
    </>
  );
}

export default ShapePool;