import React, { useEffect, useState, useRef } from "react";
import { Button, Card, Form, Table } from "react-bootstrap";

import {
  addSupplierPriceList,
  patchSupplierPriceList,
} from "Services/suppliers";

import { timeout_secs } from "Constants/alert-modal";
import InfiniteScroll from "react-infinite-scroll-component";

import infoIcon from "../../../assets/img/save_export/info.svg";
import deleteIcon from "../../../assets/img/save_export/delete-dark.svg";

import { useAppSelector } from "Redux/hooks";

const PriceCard = (props) => {
  const {
    setDeleteModalShow,
    getSupplierPriceList,
    setSelectedSupplierList,
    supplierPriceListPage,
    setSupplierPriceList,
    supplierPriceList,
  } = props;
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  const [supplierPriceListEdit, setSupplierPriceListEdit] = useState(false);
  const [newRow, setNewRow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [backupState, setBackupState] = useState(false);

  const [hasMore, setHasMore] = useState(false);
  const [supplierName, setSupplierName] = useState("");
  const [showError, setShowError] = useState(false);

  const [suppliersToUpdate, setSuppliersToUpdate] = useState([{}]);
  const [isHovered, setIsHovered] = useState(-1);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [mousePosition, setMousePosition] = useState(0);

  const PricingPositionRef = useRef(null);
  const PricingTableRef = useRef(null);

  useEffect(() => {
    const icon = PricingPositionRef.current;
    const table = PricingTableRef.current;
    if (isMouseOver && icon && table) {
      const rect = icon.getBoundingClientRect();
      const top = rect.top + window.pageYOffset;
      const tab = table.getBoundingClientRect();
      const tabtop = tab.top + window.pageYOffset;
      setMousePosition(top - tabtop + 60);
    }
  }, [isMouseOver]);

  useEffect(() => {
    if (supplierPriceListPage !== null) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [supplierPriceListPage]);

  useEffect(() => {
    getSupplierPriceList(supplierPriceListPage);
  }, []);

  const handleSaveCages = () => {
    // update the values changes
    setSupplierPriceListEdit(false);
    setBackupState([]);
    suppliersToUpdate.forEach((item) => {
      if (item.id) {
        patchSupplierPriceList(item.id, { [item.key]: item.val });
      }
    });
  };

  const handleChangeValue = (key, val, index, id) => {
    const copyofSuppliers = [...supplierPriceList];
    copyofSuppliers[index] = {
      ...copyofSuppliers[index],
      [key]: parseFloat(val.toFixed(2)),
    };
    setSupplierPriceList(copyofSuppliers);

    setSuppliersToUpdate((prevUpdatedSuppliers) => {
      const updatedSuppliersMap = new Map(
        prevUpdatedSuppliers.map((supplier) => [
          `${supplier.key}-${supplier.id}`,
          supplier,
        ])
      );
      updatedSuppliersMap.set(`${key}-${id}`, { key: key, val: parseFloat(val.toFixed(2)), id: id });
      const updatedSuppliers = Array.from(updatedSuppliersMap.values());
      return updatedSuppliers;
    });
  };

  const handleAddNewSupplier = async (e) => {
    e.preventDefault();
    const newSupplier = {
      name: supplierName,
      polygon_steel_8_25: 0.0,
      polygon_steel_abv_25: 0.0,
      round_steel_6_12: 0.0,
      round_steel_abv_14: 0.0,
      cuts: 0.0,
      bending: 0.0,
      extra_2d_hops: 0.0,
      standard_nets: 0.0,
      special_nets: 0.0,
      extra_bending_nets: 0.0,
      symmetrical_standard_pile: 0.0,
      special_pile: 0.0,
      gama_pile_2: 0.0,
      gama_pile_2_5: 0.0,
    };

    addSupplierPriceList(newSupplier)
      .then((res) => {
        if (res.status === 201) {
          setSupplierPriceList((prevSupplierPriceList) => [
            res.data,
            ...prevSupplierPriceList,
          ]);
          setNewRow(false);
        } else {
          throw new Error("Unexpected response status");
        }
      })
      .catch((error) => {
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, timeout_secs);
      });
  };

  const onloadFunction = () => {
    getSupplierPriceList();
  };

  const deleteSuppliersPrice = () => {
    setDeleteModalShow(true);
  };

  return (
    <>
      <p className="py-4">Suppliers pricing</p>
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between pb-3">
            <p className="font-weight-bold">Supplier Pricing</p>
            <div className="d-flex justify-content-around align-items-center">
              <div className="px-2">
                <Button
                  variant="light"
                  className="mr-2"
                  onClick={() => {
                    setSupplierPriceListEdit(false);
                    setNewRow(true);
                  }}
                  disabled={supplierPriceListEdit}
                >
                  add Supplier
                </Button>
              </div>

              <div className="px-2 d-flex align-items-center justify-content-center">
                <input
                  type="checkbox"
                  id="edit-text-cage"
                  className="mr-1"
                  checked={supplierPriceListEdit}
                  onChange={() => {
                    setNewRow(false);
                    if(supplierPriceListEdit) {
                      setSupplierPriceList(backupState);
                      setBackupState([]);
                    }
                    else{
                      setBackupState(supplierPriceList);
                    }
                    setSupplierPriceListEdit(!supplierPriceListEdit);
                  }}
                  style={{ cursor: "pointer" }}
                />
                <label htmlFor="edit-text-cage" style={{ marginBottom: "0px" }}>
                  Edit Text
                </label>
              </div>
            </div>
          </div>

          {newRow && (
            <>
              <div className="d-flex justify-content-end pb-3">
                <Form onSubmit={handleAddNewSupplier}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Supplier Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Supplier Name"
                      value={supplierName || ""}
                      maxLength={25}
                      onChange={(e) => setSupplierName(e.target.value)}
                    />
                  </Form.Group>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setNewRow(false);
                      setSupplierName("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    className="ml-2"
                    disabled={supplierName === ""}
                  >
                    Add
                  </Button>
                </Form>
              </div>

              {showError && (
                <div>
                  <p className="supplier_name_error">
                    A supplier with this name already Exist!
                  </p>
                </div>
              )}
            </>
          )}

          {supplierPriceListEdit && (
            <div className="d-flex justify-content-end pb-3">
              <Button
                variant="secondary"
                className="mr-2"
                onClick={() => {
                  setSupplierPriceListEdit(!supplierPriceListEdit);
                  setSupplierPriceList(backupState);
                  setBackupState([]);
                }}
              >
                cancel
              </Button>
              <Button
                variant="success"
                onClick={handleSaveCages}
                disabled={loader}
              >
                save
                {loader && (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    &nbsp;
                  </>
                )}
              </Button>
            </div>
          )}
          <InfiniteScroll
            dataLength={supplierPriceList.length}
            next={onloadFunction}
            hasMore={hasMore}
            scrollableTarget="scrollableTable"
            className="supplier_pricing_table_scroll"
          >
            <Table
              striped
              bordered
              hover
              className="supplier_pricelist_table"
              id="scrollableTable"
              style={{
                borderCollapse: "separate",
                borderSpacing: "0",
                position: "relative",
              }}
              ref={PricingTableRef}
            >
              <thead className="supplier_table_header">
                <tr>
                  <th>sr</th>
                  <th>
                    <div>Supplier Name</div>
                  </th>
                  <th>
                    <div>
                      <span>Polygonal steel</span>
                      <span>8-25 mm</span>
                    </div>
                  </th>
                  <th>
                    <div>
                      <span>Polygonal steel</span>
                      <span>above 25 mm</span>
                    </div>
                  </th>
                  
                  <th>
                    <div>
                      <span>Round steel</span> <span>6-12 mm</span>
                    </div>
                  </th>

                  <th>
                    <div>
                      <span>Round steel</span>
                      <span>above 14 mm</span>
                    </div>
                  </th>

                  <th>
                    <div>Cuts</div>
                  </th>
                  <th>
                    <div>Bending</div>
                  </th>

                  <th>
                    <div>
                      <span>Extra for</span>
                      <span>2D hoops</span>
                    </div>
                  </th>

                  <th>
                    <div>Standard nets</div>
                  </th>
                  <th>
                    <div>Special nets</div>
                  </th>

                  <th>
                    <div>
                      <span>Extra for</span>
                      <span>bending nets</span>
                    </div>
                  </th>

                  <th>
                    <div>
                      <span>Symmetrical</span> <span>standard pile</span>
                      <span>cage</span>
                    </div>
                  </th>
                  <th>
                    <div>
                      <span>Special Pile</span>
                      <span>cage</span>
                    </div>
                  </th>

                  <th>
                    <div>
                      <span>Extra for</span>
                      <span>Gama Pile</span>
                      <span>2 Tzol</span>
                    </div>
                  </th>

                  <th>
                    <div>
                      <span>Extra for</span>
                      <span>Gama Pile</span>
                      <span>2.5 Tzol</span>
                    </div>
                  </th>

                  {supplierPriceListEdit}
                </tr>
              </thead>
              <tbody
                id=""
                style={
                  supplierPriceList.length > 9
                    ? {
                        height: "720px",
                        width: "1765px",
                      }
                    : {}
                }
              >
                {supplierPriceList.length > 0 &&
                  supplierPriceList.map((item, index) => (
                    <tr
                      key={"suppliers" + index}
                      onMouseEnter={() => {
                        setIsHovered(item.id);
                        setSelectedSupplierList(item);
                      }}
                      onMouseLeave={() => setIsHovered(-1)}
                    >
                      {supplierPriceListEdit ? (
                        <>
                          <td>{index + 1}</td>
                          <td>
                            <input
                              type="text"
                              className="input-inside"
                              value={item.name}
                              maxLength={25}
                              onChange={(e) =>
                                handleChangeValue(
                                  "name",
                                  e.target.value,
                                  index,
                                  item.id
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="input-inside"
                              value={item.polygon_steel_8_25}
                              onChange={(e) =>
                                handleChangeValue(
                                  "polygon_steel_8_25",
                                  parseFloat(e.target.value),
                                  index,
                                  item.id
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="input-inside"
                              value={item.polygon_steel_abv_25}
                              onChange={(e) =>
                                handleChangeValue(
                                  "polygon_steel_abv_25",
                                  parseFloat(e.target.value),
                                  index,
                                  item.id
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="input-inside"
                              value={item.round_steel_6_12}
                              onChange={(e) =>
                                handleChangeValue(
                                  "round_steel_6_12",
                                  parseFloat(e.target.value),
                                  index,
                                  item.id
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="input-inside"
                              value={item.round_steel_abv_14}
                              onChange={(e) =>
                                handleChangeValue(
                                  "round_steel_abv_14",
                                  parseFloat(e.target.value),
                                  index,
                                  item.id
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="input-inside"
                              value={item.cuts}
                              onChange={(e) =>
                                handleChangeValue(
                                  "cuts",
                                  parseFloat(e.target.value),
                                  index,
                                  item.id
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="input-inside"
                              value={item.bending}
                              onChange={(e) =>
                                handleChangeValue(
                                  "bending",
                                  parseFloat(e.target.value),
                                  index,
                                  item.id
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="input-inside"
                              value={item.extra_2d_hops}
                              onChange={(e) =>
                                handleChangeValue(
                                  "extra_2d_hops",
                                  parseFloat(e.target.value),
                                  index,
                                  item.id
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="input-inside"
                              value={item.standard_nets}
                              onChange={(e) =>
                                handleChangeValue(
                                  "standard_nets",
                                  parseFloat(e.target.value),
                                  index,
                                  item.id
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="input-inside"
                              value={item.special_nets}
                              onChange={(e) =>
                                handleChangeValue(
                                  "special_nets",
                                  parseFloat(e.target.value),
                                  index,
                                  item.id
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="input-inside"
                              value={item.extra_bending_nets}
                              onChange={(e) =>
                                handleChangeValue(
                                  "extra_bending_nets",
                                  parseFloat(e.target.value),
                                  index,
                                  item.id
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="input-inside"
                              value={item.symmetrical_standard_pile}
                              onChange={(e) =>
                                handleChangeValue(
                                  "symmetrical_standard_pile",
                                  parseFloat(e.target.value),
                                  index,
                                  item.id
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="input-inside"
                              value={item.special_pile}
                              onChange={(e) =>
                                handleChangeValue(
                                  "special_pile",
                                  parseFloat(e.target.value),
                                  index,
                                  item.id
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="input-inside"
                              value={item.gama_pile_2}
                              onChange={(e) =>
                                handleChangeValue(
                                  "gama_pile_2",
                                  parseFloat(e.target.value),
                                  index,
                                  item.id
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="input-inside"
                              value={item.gama_pile_2_5}
                              onChange={(e) =>
                                handleChangeValue(
                                  "gama_pile_2_5",
                                  parseFloat(e.target.value),
                                  index,
                                  item.id
                                )
                              }
                            />
                          </td>
                        </>
                      ) : (
                        <>
                          <td
                            onMouseLeave={() => {
                              setIsMouseOver(false);
                            }}
                          >
                            <>
                              {isHovered === item.id ? (
                                <div className="pricing-dropsdown">
                                  <img
                                    src={infoIcon}
                                    alt={"options Icon"}
                                    onMouseEnter={(e) => {
                                      setIsMouseOver(true);
                                    }}
                                    ref={PricingPositionRef}
                                  />
                                </div>
                              ) : (
                                <div>
                                  <p> {index + 1}</p>
                                </div>
                              )}
                            </>
                          </td>
                          <td>
                            <div>
                              <p> {item.name}</p>
                            </div>
                          </td>
                          <td>{item.polygon_steel_8_25 || 0.00}</td>
                          <td>{item.polygon_steel_abv_25 || 0.00}</td>
                          <td>{item.round_steel_6_12 || 0.00}</td>
                          <td>{item.round_steel_abv_14 || 0.00}</td>
                          <td>{item.cuts || 0.00}</td>
                          <td>{item.bending || 0.00}</td>
                          <td>{item.extra_2d_hops || 0.00}</td>
                          <td>{item.standard_nets || 0.00}</td>
                          <td>{item.special_nets || 0.00}</td>
                          <td>{item.extra_bending_nets || 0.00}</td>
                          <td>{item.symmetrical_standard_pile || 0.00}</td>
                          <td>{item.special_pile || 0.00}</td>
                          <td>{item.gama_pile_2 || 0.00}</td>
                          <td>{item.gama_pile_2_5 || 0.00}</td>
                        </>
                      )}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </InfiniteScroll>
          {isMouseOver && (
            <div
              className="pricing-dropdown-content"
              style={
                rightToLeft
                  ? { top: `${mousePosition}px`, right: "52px" }
                  : { top: `${mousePosition}px`, left: "52px" }
              }
              onMouseEnter={(e) => {
                setIsMouseOver(true);
              }}
              onMouseLeave={() => setIsMouseOver(false)}
            >
              <div className="drop-item" onClick={deleteSuppliersPrice}>
                <img
                  className={
                    "item-icon " + (rightToLeft ? "item-ml" : "item-mr")
                  }
                  src={deleteIcon}
                  alt="Delete Icon"
                />
                <span className="text-capitalize">Delete Supplier</span>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default PriceCard;
