import { useTranslation } from "react-i18next";
import WarningModal from "./../custom-modal";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { availablelangauges } from "Constants/general";
import { logOut } from "Redux/middlwares/user";

function LogoutModal(props) {
  const { t } = useTranslation(["common", "warning"]);
  const { onHide } = props;

  const router = useHistory();
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.user.userData);

  const btnValues = [
    {
      text: t("cancel", { ns: "common" }),
      btnClass: "custom__btn-secondary",
    },
    {
      text: t("sure", { ns: "common" }),
      btnClass: "custom__btn-primary",
    },
  ];

  const handleLogout = () => {
    let lang = "";
    if (userData.preferred_language === availablelangauges.ENGLISH) {
      lang = "en";
    } else if (userData.preferred_language === availablelangauges.HEBREW) {
      lang = "he";
    }
    dispatch(logOut());
    router.push(`/?lng=${lang}`);
  };

  const Cancel = () => {
    onHide();
  };


  const btnFunctions = {
    primary: handleLogout,
    secondary: Cancel,
  };

  return (
    <WarningModal
      show={props.show}
      onHide={props.onHide}
      dialogClassName={"modal_width"}
      btnFunctions={btnFunctions}
      btnValues={btnValues}
      modalColor={"#fff"}
    >
      <div className="delete__title pb-md-4 pb-2">
        <p className="mb-0">
          {t("sure logout", { ns: "warning" })}
        </p>
      </div>
    </WarningModal>
  );
}

export default LogoutModal;
