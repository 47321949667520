import {
  externalEndDegrees,
  externalStartDegrees,
  internalEndDegrees,
  internalStartDegrees,
} from "Constants/pile-constants";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  mPadX,
  mPadY,
  padX,
  padY,
  pctInnerheight,
  pctInnerWidth,
  previewPad,
  resacleRate,
} from "./constant";

export interface Point {
  x: number;
  y: number;
}

export interface Side {
  angle: number;
  length: number;
}

export function degreesToRadians(degrees: number) {
  var pi = Math.PI;
  return degrees * (pi / 180);
}

export function radiansTodegrees(radians: number) {
  var pi = Math.PI;
  return radians * (180 / pi);
}

export function calculateNextPoint(
  point: { x: number; y: number },
  angle: number,
  distance: number
) {
  let next_point = { x: 0, y: 0 };
  next_point.x = point.x + distance * Math.cos(degreesToRadians(angle));
  next_point.y = point.y + distance * Math.sin(degreesToRadians(angle));

  return next_point;
}

export function drawLabel(
  ctx: any,
  text: string,
  p1: Point,
  p2: Point,
  alignment: string = "center",
  padding: number = 0
) {
  var dx = p2.x - p1.x;
  var dy = p2.y - p1.y;
  var len = Math.sqrt(dx * dx + dy * dy);

  let p, pad;
  if (alignment === "center") {
    p = p1;
    pad = 1 / 2;
  } else {
    var left = alignment === "left";
    p = left ? p1 : p2;
    pad = (padding / len) * (left ? 1 : -1);
  }

  ctx.font = "12pt Arial";

  ctx.save();
  ctx.textAlign = alignment;
  ctx.translate(p.x + dx * pad + 15, p.y + dy * pad + 30);
  ctx.rotate(Math.atan2(dy, dx));
  ctx.fillText(text, 0, 0);
  ctx.restore();
  return { x: p.x + dx * pad + 10, y: p.y + dy * pad + 20 };
}

export function reverseAngle(angle: number) {
  if (angle > 180) return angle - 180;
  return 180 + angle;
}

export function calculateCurrentPoint(arr: any[], startPoint: Point): Point {
  return arr.reduce(
    (previousPoint: Point, side: any) =>
      calculateNextPoint(previousPoint, side.line_angle, side.length),
    startPoint
  );
}

export function calculateLineAngle(
  clockwise: boolean,
  shiftedAngle: number | null,
  angle: number | null,
  changePreviousAngle: boolean
) {
  let lineAngle = 0;
  if ((shiftedAngle || shiftedAngle === 0) && angle) {
    lineAngle = clockwise ? shiftedAngle + angle : shiftedAngle - angle;
    if (lineAngle > 360) lineAngle = lineAngle % 360;
    if (lineAngle < 0) lineAngle = 360 + lineAngle;
    if (changePreviousAngle) lineAngle = reverseAngle(lineAngle);
  }
  return lineAngle;
}

export function drawAngleSymbol(
  ctx: any,
  pt3: Point,
  pt2: Point,
  pt1: Point,
  inner: boolean,
  rightAngle = false,
  lineAngle = 0
) {
  const dx1 = pt1.x - pt2.x;
  const dy1 = pt1.y - pt2.y;
  const dx2 = pt3.x - pt2.x;
  const dy2 = pt3.y - pt2.y;
  const a1 = Math.atan2(dy1, dx1);
  const a2 = Math.atan2(dy2, dx2);
  ctx.font = "12pt Arial";
  // draw angleSymbol
  ctx.save();
  ctx.beginPath();

  if (rightAngle) {
    const dist = rightAngle ? 16 : 20;
    // pt2 goes "dist" steps behind
    const startPoint = calculateNextPoint(pt2, reverseAngle(lineAngle), dist);
    ctx.moveTo(startPoint.x, startPoint.y);
    // draw line normal to startPoint
    const mid = calculateNextPoint(
      startPoint,
      inner ? lineAngle - 90 : lineAngle + 90,
      dist
    );

    ctx.lineTo(mid.x, mid.y);
    const end = calculateNextPoint(mid, lineAngle, dist);
    ctx.lineTo(end.x, end.y);
  } else {
    if (inner) {
      ctx.arc(pt2.x, pt2.y + 1, 18, a2, a1);
    } else {
      ctx.arc(pt2.x, pt2.y, 20, a1, a2);
    }
  }
  ctx.lineWidth = 1.5;
  ctx.globalAlpha = 0.75;
  ctx.strokeStyle = "#9A9A9A";
  ctx.stroke();
  ctx.restore();
}

// Calculate distance between points
export function calcualte_distance_points(pt_1: Point, pt_2: Point) {
  var a = pt_1.x - pt_2.x;
  var b = pt_1.y - pt_2.y;
  return Math.sqrt(a * a + b * b);
}

// calculate angle of start_point from mid_point
export function calculate_angle_points(mid_point: Point, start_point: Point) {
  var angle =
    180 -
    (((-(
      Math.atan2(mid_point.x - start_point.x, mid_point.y - start_point.y) *
      (180 / Math.PI)
    ) %
      360) +
      360) %
      360);
  return angle;
}

// calculate mid point of line
export function calculate_mid_points(pt_1: Point, pt_2: Point) {
  const x_mid = (pt_1.x + pt_2.x) / 2;
  const y_mid = (pt_1.y + pt_2.y) / 2;
  const mid = { x: x_mid, y: y_mid };
  return mid;
}

// Calculate center of image
export function image_bycenter(points: Point[]) {
  let distance = [];
  let mid = [];
  let denomenator = 0;
  let y_mid = 0;
  let x_mid = 0;

  for (let i = 0; i < points.length - 1; i++) {
    distance[i] = calcualte_distance_points(points[i], points[i + 1]);
    denomenator = denomenator + distance[i];
    mid[i] = calculate_mid_points(points[i], points[i + 1]);
  }

  for (let i = 0; i < mid.length; i++) {
    y_mid = y_mid + mid[i].y * distance[i];
    x_mid = x_mid + mid[i].x * distance[i];
  }
  y_mid = y_mid / denomenator;
  x_mid = x_mid / denomenator;
  const center = { x: x_mid, y: y_mid };

  return center;
}

export function calculate_points(start_point: Point, sides: Side[]) {
  let points = [start_point];
  let current_point = start_point;
  sides.forEach((item) => {
    current_point = calculateNextPoint(current_point, item.angle, item.length);
    points.push(current_point);
  });
  return points;
}

export function findPointOutOfCanvas(
  canvasWidth: number,
  padX: number,
  canvasHeight: number,
  padY: number,
  points: Point[]
) {
  // if any point is outside outter range, scaleDown (True return) else false
  for (let i = 0; i < points.length; i = i + 1) {
    if (
      points[i].x < padX ||
      points[i].x > canvasWidth - padX ||
      points[i].y < padY ||
      points[i].y > canvasHeight - padY
    ) {
      return true;
    }
  }
  return false;
}

export function findShapeInsideInnerCanvas(
  canvasWidth: number,
  padX: number,
  canvasHeight: number,
  padY: number,
  points: Point[]
) {
  const midx = canvasWidth / 2;
  const midy = canvasHeight / 2;

  // find the perctentage width of inner canvus and subtracted its half from the center of the canvus
  const halfSmallerAreaWidth = ((canvasWidth - padX) * pctInnerWidth) / 2;
  // find the perctentage height of inner canvus and subtracted its half from the center of the canvus
  const halfSmallerAreaHeight = ((canvasHeight - padY) * pctInnerheight) / 2;
  // if any point is outside Inner range, do not scaleUp (false return) else true
  for (let i = 0; i < points.length; i = i + 1) {
    if (
      points[i].x < midx - halfSmallerAreaWidth ||
      points[i].x > midx + halfSmallerAreaWidth ||
      points[i].y < midy - halfSmallerAreaHeight ||
      points[i].y > midy + halfSmallerAreaHeight
    ) {
      return false;
    }
  }
  return true;
}

export function checkFirstQuadrant(points: Point[]) {
  const ptValues = points.map((pt) => pt.x).concat(points.map((pt) => pt.y));
  let min = ptValues[0];

  for (let i = 1; i < ptValues.length; i = i + 1) {
    if (min > ptValues[i]) {
      min = ptValues[i];
    }
  }

  return min < 0 ? min * -1 : min;
}

export function calculateImageRectangleCenter(points: Point[]) {
  let xMin = points[0].x,
    yMin = points[0].y,
    xMax = points[0].x,
    yMax = points[0].y;

  for (let i = 1; i < points.length; i = i + 1) {
    // Mins
    if (xMin > points[i].x) {
      xMin = points[i].x;
    }
    if (yMin > points[i].y) {
      yMin = points[i].y;
    }
    //Maxs
    if (xMax < points[i].x) {
      xMax = points[i].x;
    }
    if (yMax < points[i].y) {
      yMax = points[i].y;
    }
  }

  const _points = [
    { x: xMin, y: yMin },
    { x: xMax, y: yMin },
    { x: xMin, y: yMax },
    { x: xMax, y: yMax },
  ];

  return image_bycenter(_points);
}

// resize image
export function resize_image(
  canvasWidth: number,
  canvasHeight: number,
  sides: Side[],
  isMobile: boolean
) {
  const default_first_point = { x: 0, y: 0 };
  const canvus_mid = { x: canvasWidth / 2, y: canvasHeight / 2 };

  let rescale_factor = 1,
    actualPoints = [],
    first_point = { x: 0, y: 0 },
    doDeduction = false,
    doAddition = false,
    i = 0;

  const allSidesZero = sides.reduce((prev, curr) => prev && (curr.length > 0 ? false : true), true);
  if (allSidesZero) {
    return {
      first_point,
      rescale_factor,
      sides,
    };
  }

  do {
    if (doDeduction) rescale_factor = rescale_factor - resacleRate;
    else rescale_factor = rescale_factor + resacleRate;
    /* eslint-disable */
    sides = sides.map((item) => ({
      ...item,
      length: rescale_factor * item.length,
    }));
    /* eslint-enable */
    const initial_rescaled_points = calculate_points(
      default_first_point,
      sides
    );
    //check if shape is not in first quadrant
    const display_factor = checkFirstQuadrant(initial_rescaled_points);
    const rescaled_points = initial_rescaled_points.map((pt) => ({
      x: pt.x + display_factor,
      y: pt.y + display_factor,
    }));

    // image mid
    const object_mid_point = calculateImageRectangleCenter(rescaled_points);
    // angle between image center and image starting point
    let center_to_first_angle = calculate_angle_points(
      object_mid_point,
      rescaled_points[0]
    );
    const center_to_first_distance = calcualte_distance_points(
      object_mid_point,
      rescaled_points[0]
    );
    // New image first_point centered at canvas center
    first_point = calculateNextPoint(
      canvus_mid,
      center_to_first_angle,
      center_to_first_distance
    );
    // Presicion error corrections
    const angle = calculate_angle_points(canvus_mid, first_point);
    first_point = calculateNextPoint(
      canvus_mid,
      angle,
      center_to_first_distance
    );

    actualPoints = calculate_points(first_point, sides);
    // ========= Scale Down code ==================
    doDeduction = findPointOutOfCanvas(
      canvasWidth,
      isMobile ? mPadX : padX,
      canvasHeight,
      isMobile ? mPadY : padY,
      actualPoints
    );
    // ========= Scale UP code ==================
    doAddition = findShapeInsideInnerCanvas(
      canvasWidth,
      isMobile ? mPadX : padX,
      canvasHeight,
      isMobile ? mPadY : padY,
      actualPoints
    );
  } while (i < 5000 && (doDeduction || doAddition));

  return {
    first_point,
    rescale_factor,
    sides,
  };
}

function calculate_net_rescale_factor(
  x_points: number[],
  y_points: number[],
  current_canvas: Point
) {
  const x_max = Math.max.apply(Math, x_points);
  const y_max = Math.max.apply(Math, y_points);

  const x_axis = current_canvas.x / x_max;
  const y_axis = current_canvas.y / y_max;
  let factor = 1;
  if (x_axis > y_axis) {
    factor = y_axis;
  } else if (x_axis === y_axis) {
    return 0.8;
  } else {
    factor = x_axis;
  }

  // if (factor > 1) {
  //   return 1;
  // }
  return factor;
}

export function calculateRebarSSPoints(xValues: any, yValues: any) {
  const xPt =
    Math.min(...(xValues as any)) - previewPad < 0
      ? 0
      : Math.min(...(xValues as any)) - previewPad;
  const xDist = Math.max(...(xValues as any)) - Math.min(...(xValues as any));

  const yPt =
    Math.min(...(yValues as any)) - previewPad < 0
      ? 0
      : Math.min(...(yValues as any)) - previewPad;
  const yDist = Math.max(...(yValues as any)) - Math.min(...(yValues as any));

  return {
    x: xPt,
    y: yPt,
    width: xDist + 2 * previewPad,
    height: yDist + 2 * previewPad,
  };
}

export function calculate_refactored_net_coordinates(
  x_length: number,
  y_length: number,
  canvasWidth: number,
  canvasHeight: number,
  isMobile: boolean
) {
  // creating a reactangular
  let first_point = { x: 0, y: 0 };

  let yPad = 150;
  let xPad = 270;
  if (isMobile) {
    yPad = 70;
    xPad = 50;
  }
  const current_canvas = { x: canvasWidth - xPad, y: canvasHeight - yPad };
  const canvus_mid = { x: canvasWidth / 2, y: canvasHeight / 2 };

  let second_point = { x: first_point.x + x_length, y: first_point.y };
  let third_point = {
    x: first_point.x + x_length,
    y: first_point.y + y_length,
  };
  let fourth_point = { x: first_point.x, y: first_point.y + y_length };
  let fifth_point = { x: first_point.x, y: first_point.y };

  let points = [
    first_point,
    second_point,
    third_point,
    fourth_point,
    fifth_point,
  ];

  // resacling reactangular
  const rescale_factor = calculate_net_rescale_factor(
    points.map((pt) => Math.abs(pt.x)),
    points.map((pt) => Math.abs(pt.y)),
    current_canvas
  );

  second_point = {
    x: first_point.x + x_length * rescale_factor,
    y: first_point.y,
  };
  third_point = {
    x: first_point.x + x_length * rescale_factor,
    y: first_point.y + y_length * rescale_factor,
  };
  fourth_point = {
    x: first_point.x,
    y: first_point.y + y_length * rescale_factor,
  };
  fifth_point = { x: first_point.x, y: first_point.y };

  points = [first_point, second_point, third_point, fourth_point, fifth_point];
  // image center
  const object_mid_point = image_bycenter(points);
  // angle from image center to starting point
  const imageAngle = calculate_angle_points(object_mid_point, first_point);
  const calcualte_distance = calcualte_distance_points(
    object_mid_point,
    first_point
  );

  // first point of the real image form canvas center
  first_point = calculateNextPoint(canvus_mid, imageAngle, calcualte_distance);
  const angle = calculate_angle_points(canvus_mid, first_point);
  // correction
  first_point = calculateNextPoint(canvus_mid, angle, calcualte_distance);

  first_point.y = first_point.y + 25;

  return { first_point: first_point, rescale_factor: rescale_factor };
}

export function calculate_partitions(
  gap: number,
  distance: number,
  diameter: number,
  rescale_factor?: number
) {
  // const Z = Math.floor((distance-(10*rescale_factor)) / gap)
  const min = (((diameter/10)*2)*2);
  const Z = Math.floor((distance - min) / gap);
  const _1 = Z * gap;
  const _2 = (distance - _1) / 2;
  const _3 = _2;

  return {
    _1: _1,
    _2: _2,
    _3: _3,
  };
}

export function calculateSidePoints(
  shapeObj: any,
  midX: number,
  midY: number,
  radius: number,
  pad: number
) {
  let upperpoints = [];
  let points = 0;

  if (shapeObj.external_amount) {
    // external dots in the circle
    const externalGap = (
      ((externalStartDegrees - externalEndDegrees) +  (shapeObj.external_amount <= 10 ? 24 : (shapeObj.external_amount <= 40 ? 12 : 0))) /
        (shapeObj.external_amount + 1)
    );
    for (
      let i = externalStartDegrees - externalGap  + (shapeObj.external_amount <= 10 ? 12 : (shapeObj.external_amount <= 40 ? 6 : 0));
      i > externalEndDegrees - externalGap;
      i = i - externalGap
    ) {
      upperpoints.push(
        calculateNextPoint({ x: midX, y: midY }, i, radius - pad)
      );
      points++;
    }
    if (points > shapeObj.external_amount){
      upperpoints.pop();
    }
    points = 0;
  }

  if (shapeObj.internal_amount) {
    // internal dots in the circle
    const internalGap = (
      ((internalStartDegrees - internalEndDegrees) + (shapeObj.internal_amount <= 10 ? 24 : (shapeObj.internal_amount <= 30 ? 12 : 0))) /
        (shapeObj.internal_amount + 1)
    );
    for (
      let i = internalStartDegrees - internalGap +  (shapeObj.internal_amount <= 10 ? 12 : (shapeObj.internal_amount <= 30 ? 6 : 0));
      i > internalEndDegrees - internalGap;
      i = i - internalGap
    ) {
      upperpoints.push(
        calculateNextPoint({ x: midX, y: midY }, i, radius - pad)
      );
      points++;
    }
    if (points > shapeObj.internal_amount) {
      upperpoints.pop();
    }
    points = 0
  }

  if (shapeObj.middle_amount >= 2) {
    // middle dots in the circle
    const singlePortionDotCount = Math.floor(shapeObj.middle_amount / 2);
      const portionGap = (
        (360 - externalStartDegrees + internalEndDegrees - 12)  /
          (singlePortionDotCount + 1) 
      );      
      for (
        let i = externalStartDegrees + 6 + portionGap, j= 1;
        j <= singlePortionDotCount;
        i = i + portionGap, j = j + 1
      ){
        upperpoints.push(
          calculateNextPoint({ x: midX, y: midY }, i, radius - pad)
        );
      }
      for (
        let i = externalEndDegrees - 6 - portionGap, k= 1;
        k <= singlePortionDotCount;
        i = i - portionGap, k = k + 1
      ){
        upperpoints.push(
          calculateNextPoint({ x: midX, y: midY }, i, radius - pad)
        );
      }
  }
  return upperpoints;
}

export function calculateSidePointsSplitTwo(
  shapeObj: any,
  midX: number,
  midY: number,
  radius: number,
  pad: number
) {
  let points = 0;
  let upperpoints = [];
  const _externalStartDegrees = 360,
    _externalEndDegrees = 180;
  if (shapeObj.external_amount) {
    // external dots in the circle
    const externalGap = ((_externalStartDegrees - _externalEndDegrees ) + 12) / (parseInt(shapeObj.external_amount) + 1);
    for (
      let i = _externalStartDegrees - externalGap + 6;
      i > _externalEndDegrees;
      i = i - (externalGap)
    ) {
      upperpoints.push(
        calculateNextPoint({ x: midX, y: midY }, i, radius - pad)
      );
      points += 1;
    }
    if (points > shapeObj.external_amount){
      upperpoints.pop();
    }
    points = 0;
  }

  if (shapeObj.internal_amount) {
    const _internalStartDegrees = 180,
      _internalEndDegrees = 0;
    // internal dots in the circle
    const internalGap = (
      ((_internalStartDegrees - _internalEndDegrees) + 12) /
        (parseInt(shapeObj.internal_amount) + 1)
    );
    for (
      let i = _internalStartDegrees - internalGap + 6;
      i > _internalEndDegrees;
      i = i - internalGap
    ) {
      upperpoints.push(
        calculateNextPoint({ x: midX, y: midY }, i, radius - pad)
      );
      points += 1;
    }
    if (points > shapeObj.internal_amount){
      upperpoints.pop();
    }
  }
  return upperpoints;
}

export const useQuery= ()=> {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const useClearAllQueryParams =()=> {
  const history = useHistory();
  return history.replace({search: ""});
}

export const transfromAngle = (_angle: number) => {
  return ((_angle+270)%360);
}

export const isRebarRotatable = (xValues: any, yValues: any) => {
  const xDist = Math.max(...(xValues as any)) - Math.min(...(xValues as any));
  const yDist = Math.max(...(yValues as any)) - Math.min(...(yValues as any));

  const flip = yDist / xDist;
  return flip > 1.35 ? true : false;
}

export function fullAngle(angle : number){
  if (angle < 0){
    return (angle + 360)
  }
  return angle;
}

export function smallAngle(angle: number){
  if(angle > 180){
    return (angle - 360)
  }
  return angle;
}

export function changeAngle(angle: number){
  angle += 180;
  if (angle > 180){
    angle -= 360;
  }
  return angle;
}