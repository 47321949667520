import { createSlice } from '@reduxjs/toolkit'

export interface ContactState {
  userContacts: any[];
}

const initialState: ContactState = {
  userContacts: [],
}

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    setUserContacts: (state: any, action) => {
      state.userContacts = action.payload;
    },
    addToUserContacts:  (state: any, action) => {
      state.userContacts = [action.payload, ...state.userContacts]
    },
    updateToUserContacts:  (state: any, action) => {
      state.userContacts = [
        action.payload,
        ...state.userContacts.filter((item: any) => item.id !== action.payload.id)
      ]
    },
    removeFromUserContacts: (state: any, action) => {
      state.userContacts = [...state.userContacts.filter((item: any) => item.id !== action.payload)]
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setUserContacts,
  addToUserContacts,
  updateToUserContacts,
  removeFromUserContacts,
} = contactSlice.actions

export default contactSlice.reducer