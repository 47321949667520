import OrderTableRow from "./ordertablerow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isBrowser, isMobile } from "react-device-detect";
import { NavLink } from "react-router-dom";
import BarLoader from "react-bar-loader";
import { useAppSelector } from "Redux/hooks";
import { OrderFilterTypes } from "Constants/order";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { getNextOrders } from "Services/order";

import previewIcon from "../../assets/img/save_export/preview-dark.svg";
import editIcon from "../../assets/img/save_export/edit-dark.svg";
import downloadIcon from "../../assets/img/save_export/download-dark.svg";
import shareIcon from "../../assets/img/save_export/share-dark.svg";
import deleteIcon from "../../assets/img/save_export/delete-dark.svg";
import ticketIcon from "../../assets/img/save_export/tickets.svg";
import whatsappIcon from "../../assets/img/save_export/whatsapp.svg";

function OrderTable({
  onOpenPreviewHandle,
  setSelectedOrder,
  selectedOrder,
  setDeleteModalShow,
  setIsDataFetched,
  setOrderShareId,
  setShowSendConfirm,
  setNextOrderPage,
  nextOrderPage,
  setUserOrders,
  userOrders,
}: any) {
  const [mobileFilter, setMobileFilter] = useState(OrderFilterTypes.created);
  const { t } = useTranslation(["common", "myOrder"]);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  const [hasMore, setHasMore] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [mousePosition, setMousePosition] = useState(0);

  const OrderPositionRef = useRef(null);
  const OrderTableRef = useRef(null);

  useEffect(() => {
    if (userOrders.length === 0 && nextOrderPage === 0) {
      setHasMore(true);
    } else if (userOrders.length === 0 && nextOrderPage === null) {
      setHasMore(false);
    } else if (userOrders.length > 0 && nextOrderPage !== null) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [userOrders, nextOrderPage]);

  useEffect(() => {
    const icon = OrderPositionRef.current;
    const table = OrderTableRef.current;
    if (isMouseOver && icon && table) {
      const rect = icon.getBoundingClientRect();
      const top = rect.top + window.pageYOffset;
      const tab = table.getBoundingClientRect();
      const tabtop = tab.top + window.pageYOffset;
      setMousePosition(top - tabtop);
    }
  }, [isMouseOver]);

  const onloadFunction = async () => {
    if (nextOrderPage) {
      const response = await getNextOrders(nextOrderPage);
      let nextPage = null;

      if (response.data.results.length > 0) {
        if (response.data.next !== null) {
          nextPage = response.data.next.split("cursor=");
          setNextOrderPage(nextPage[1]);
        } else {
          setNextOrderPage(nextPage);
        }
        setUserOrders([...userOrders, ...response.data.results]);
      }
    }
  };

  const deleteOrder = () => {
    setDeleteModalShow(true);
  };

  const shareOrder = () => {
    setOrderShareId(selectedOrder.id);
    setShowSendConfirm(true);
  }

  return (
    <>
      {isBrowser && (
        <section id="myOrderTable" className="tableSection mt-5">
          <div className="container border__bottom py-4">
            {/* <!--------table header--------> */}
            <div className="tableHeader d-flex mb-3">
              <h6
                className={
                  "text-uppercase text-dark-500 mb-0 " +
                  (rightToLeft ? "ml-3" : "mr-3")
                }
              >
                {t("my order", { ns: "myOrder" })}
              </h6>
              <NavLink to="/new-order">
                <div className="right d-flex">
                  <span
                    className={"icon__wrap  " + (rightToLeft ? "ml-2" : "mr-2")}
                  >
                    <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
                  </span>
                  <p className="para text-uppercase">
                    {t("create order", { ns: "myOrder" })}
                  </p>
                </div>
              </NavLink>
            </div>
            {/* <!-------- table section  --------> */}
            <table
              className="table table-striped custom-table-alignment orders-table"
              style={{ position: "relative" }}
              ref={OrderTableRef}
            >
              {/* <!-- table head  --> */}
              <thead>
                <tr className="grid-table-row">
                  <td></td>
                  <td>
                    <h6 className="text-dark-500 mb-0">
                      {t("date order create", { ns: "myOrder" })}
                    </h6>
                  </td>
                  <td>
                    <h6 className="text-dark-500 mb-0">
                      {t("order no", { ns: "myOrder" })}
                    </h6>
                  </td>
                  <td>
                    <h6 className="text-dark-500 mb-0">
                      {t("order", { ns: "myOrder" })}
                    </h6>
                  </td>
                  <td>
                    <h6 className="text-dark-500 mb-0 ">
                      {t("site", { ns: "myOrder" })}
                    </h6>
                  </td>
                  <td>
                    <h6 className="text-dark-500 mb-0">
                      {t("total weight", { ns: "myOrder" })}
                    </h6>
                  </td>
                  <td>
                    <h6 className="text-dark-500 mb-0">
                      {t("delivery date", { ns: "myOrder" })}
                    </h6>
                  </td>
                  <td
                    style={
                      rightToLeft ? { borderRight: "2px solid #f3f3f3" } : {}
                    }
                  ></td>
                </tr>
              </thead>
              {/* <!-- tabody  --> */}
              <tbody
                className="tableScroll"
                style={
                  userOrders.length > 9
                    ? {
                        height: "580px",
                        overflow: "auto",
                        display: "block",
                        scrollbarWidth: "none",
                      }
                    : {
                      scrollbarWidth: "none",
                    }
                }
                id="scrollableOrderTable"
              >
                <InfiniteScroll
                  dataLength={userOrders.length}
                  next={onloadFunction}
                  hasMore={hasMore}
                  loader={<BarLoader color="#F84343" height="4" />}
                  style={{ overflow: "visible" }}
                  scrollableTarget="scrollableOrderTable"
                  scrollThreshold={0.6}
                >
                  {/* <!-- table row  --> */}
                  {userOrders.length > 0 &&
                    userOrders.map((orderItem: any, index: number) => (
                      <OrderTableRow
                        key={"order" + index}
                        number={index + 1}
                        order={orderItem}
                        onOpenPreviewHandle={onOpenPreviewHandle}
                        setIsMouseOver={setIsMouseOver}
                        OrderPositionRef={OrderPositionRef}
                        setSelectedOrder={setSelectedOrder}
                        setIsDataFetched={setIsDataFetched}
                      />
                    ))}
                </InfiniteScroll>
              </tbody>
              {isMouseOver && (
                <div
                  className="order-dropdown-content"
                  style={
                    rightToLeft
                      ? { top: `${mousePosition}px`, right: "65px" }
                      : { top: `${mousePosition}px`, left: "65px" }
                  }
                  onMouseEnter={(e) => {
                    setIsMouseOver(true);
                  }}
                  onMouseLeave={() => setIsMouseOver(false)}
                >
                  <div
                    className="drop-item"
                    onClick={() => {
                      setIsMouseOver(false);
                      setIsDataFetched(true);
                      onOpenPreviewHandle(selectedOrder.id, false);
                    }}
                  >
                    <img
                      className={
                        "item-icon " + (rightToLeft ? "item-ml" : "item-mr")
                      }
                      src={previewIcon}
                      alt="Preview Icon"
                    />
                    <span className="text-capitalize">
                      {t("preview", { ns: "newOrder" })}
                    </span>
                  </div>
                  {selectedOrder.is_creater && (
                    <NavLink
                      to={`/edit-order/${selectedOrder.id}`}
                      className="drop-item"
                    >
                      <img
                        className={
                          "item-icon " + (rightToLeft ? "item-ml" : "item-mr")
                        }
                        src={editIcon}
                        alt="Edit Icon"
                      />
                      <span className="text-capitalize">
                        {t("edit", { ns: "common" })}
                      </span>
                    </NavLink>
                  )}
                  <div
                    className="drop-item"
                    onClick={() => {
                      setIsMouseOver(false);
                      setIsDataFetched(true);
                      onOpenPreviewHandle(selectedOrder.id, true);
                    }}
                  >
                    <img
                      className={
                        "item-icon " + (rightToLeft ? "item-ml" : "item-mr")
                      }
                      src={downloadIcon}
                      alt="Download Icon"
                    />
                    <span className="text-capitalize">
                      {t("download pdf", { ns: "common" })}
                    </span>
                  </div>
                  {!selectedOrder.is_creater && (
                    <a
                      href={`http://wa.me/972${selectedOrder.creator_contact_number.slice(
                        1
                      )}`}
                      className="drop-item"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className={
                          "item-icon " + (rightToLeft ? "item-ml" : "item-mr")
                        }
                        src={whatsappIcon}
                        alt="Whatsapp Icon"
                      />
                      <span className="text-capitalize">
                        {t("send msg", { ns: "common" })}
                      </span>
                    </a>
                  )}
                  {selectedOrder.is_creater && (
                    <div 
                      className="drop-item"
                      onClick={shareOrder}>
                      <img
                        className={
                          "item-icon " + (rightToLeft ? "item-ml" : "item-mr")
                        }
                        src={shareIcon}
                        alt="Share Icon"
                      />
                      <span className="text-capitalize">
                        {t("send to supplier", { ns: "common" })}
                      </span>
                    </div>
                  )}
                  <NavLink
                    to={`/ticket-creator/?order_no=${selectedOrder.order_no}`}
                    className="drop-item"
                  >
                    <img
                      className={
                        "item-icon " + (rightToLeft ? "item-ml" : "item-mr")
                      }
                      src={ticketIcon}
                      alt="Print Icon"
                    />
                    <span className="text-capitalize">
                      {t("print tickets", { ns: "common" })}
                    </span>
                  </NavLink>
                  <div className="drop-item" onClick={deleteOrder}>
                    <img
                      className={
                        "item-icon " + (rightToLeft ? "item-ml" : "item-mr")
                      }
                      src={deleteIcon}
                      alt="Delete Icon"
                    />
                    <span className="text-capitalize">
                      {t("delete order", { ns: "common" })}
                    </span>
                  </div>
                </div>
              )}
            </table>
          </div>
        </section>
      )}
      {isMobile && (
        <div className="myOrderTableSm">
          {/* <!-- ----------------table header---------------  --> */}
          <div className="btn__icon__head d-flex justify-content-between border__bottom py-3 tableHeader">
            {/* <!-- icon and heading  --> */}
            <div className="icon__head d-flex align-items-center justify-content-center">
              <h3 className="big__para text-uppercase mb-0">
                {t("my orders", { ns: "myOrder" })}
              </h3>
            </div>
            {/* <!-- buttons  --> */}
            <NavLink to="/new-order">
              <button className="btn bg-dark-500 text-dark d-flex justify-content-between align-items-center cus__btn">
                <span className="icon__wrap">
                  <svg
                    className="svg-inline--fa fa-plus fa-w-14"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="plus"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                    ></path>
                  </svg>
                </span>
                <p className="ml-2  text-uppercase">
                  {t("add new", { ns: "myOrder" })}
                </p>
              </button>
            </NavLink>
          </div>
          {/* <!-- -----------------table control ------------- --> */}
          <div className="icon__form d-flex justify-content-between align-items-start py-2 py-sm-3 table__control__panel">
            {/* <!-- left section  --> */}
            <div className="left__sec d-flex align-items-center">
              {/* <!-- icon  --> */}
              <div className={"icon__wrap  " + (rightToLeft ? "ml-3" : "mr-3")}>
                <FontAwesomeIcon icon="filter"></FontAwesomeIcon>
              </div>
              {/* <!-- para  --> */}
              <p
                onClick={() => setMobileFilter(OrderFilterTypes.created)}
                className={
                  "para text-capitalize mb-0" +
                  (mobileFilter === OrderFilterTypes.created &&
                    "text-dark-500 active")
                }
              >
                created date
              </p>
            </div>
            {/* <!-- right section  --> */}
            <p
              onClick={() => setMobileFilter(OrderFilterTypes.delivery)}
              className={
                "para text-capitalize" +
                (mobileFilter === OrderFilterTypes.delivery &&
                  "text-dark-500 active")
              }
            >
              delivery date
            </p>
            <p
              onClick={() => setMobileFilter(OrderFilterTypes.sites)}
              className={
                "para text-capitalize " +
                (rightToLeft
                  ? "ml-2 ml-sm-3 ml-md-4"
                  : "mr-2 mr-sm-3 mr-md-4") +
                (mobileFilter === OrderFilterTypes.sites &&
                  "text-dark-500 active")
              }
            >
              sites
            </p>
          </div>
          {/* <!-- ---------------main table ------------ --> */}
          <table className="table">
            <thead>
              <tr>
                <th></th>
                <th>
                  <p className="para text-uppercase text-dark">
                    {mobileFilter === OrderFilterTypes.created ||
                    mobileFilter === OrderFilterTypes.sites
                      ? "created"
                      : "Delivery"}
                  </p>
                </th>
                <th>
                  <p className="para text-uppercase text-dark">
                    {t("site", { ns: "myOrder" })}
                  </p>
                </th>
                <th>
                  <p className="para text-uppercase text-dark">
                    {t("weight", { ns: "myOrder" })}
                  </p>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {/* <!-- table row  --> */}
              {userOrders.length > 0 &&
                userOrders.map((orderItem: any, index: number) => (
                  <OrderTableRow
                    key={"order" + index}
                    number={index + 1}
                    order={orderItem}
                    mobileFilter={mobileFilter}
                  />
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

export default OrderTable;
