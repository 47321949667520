import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import emptySet from 'Assets/img/icon/empty-set-mathematical-symbol.png';
import setEmpty from 'Assets/img/icon/setempty.svg';
import create from 'Assets/img/cages/create.svg';
import email from 'Assets/img/add__item/email.svg';
import length from 'Assets/img/cages/length.svg';
import quantity from 'Assets/img/cages/quantity.svg';
import weight from 'Assets/img/cages/weight.svg';
import writer from 'Assets/img/cages/writer.svg';
import xDetails from 'Assets/img/add__item/blue x details.svg';
import yDetails from 'Assets/img/add__item/y.svg';
import details from 'Assets/img/nets/details.svg';
import setSide from 'Assets/img/icon/setSide.svg';

import NetShape from "../shape";
import { gapOptions, netXLengthMax, netXLengthMin, netYLengthMax, netYLengthMin  } from 'Constants/net-constants';
import { useAppSelector } from "Redux/hooks";
import { DebounceInput } from "react-debounce-input";
import { useTranslation } from "react-i18next";

function NetMobileContent({
  shapeObj,
  setRescaleFactor,
  partitionX,
  setPartitionX,
  partitionY,
  setPartitionY,
  labelingPoints,
  setLabelingPoints,
  setShape,
  orderItem,
  setOrderItem,
  stageRef,
  filterDiameter,
  setFilterDiameter,
  filterGap,
  setFilterGap,
  selectedShape,
  setSelectedShape,
  standardShapes,
  getDiameter,
  saveNet,
  netNumber,
  setNetNumber,
}: any) {
  const defaultDiameters = useAppSelector(state => state.net.defaultDiameters);
  const defaultGaps = useAppSelector(state => state.net.defaultGaps);
  const steelDiameters = useAppSelector(state => state.steelType.steelDiameters);
  const steelTypes = useAppSelector(state => state.steelType.steelTypes);
  const { t } = useTranslation(["newOrder", "common", "measurements"]);

  return (
    <>
      {/* <!-- type 2d or 3d  --> */}
      {/* <div className="w-xs-100 d-flex justify-content-between align-items-center bg-white p-3">
        <p className="big__para text-center text-capitalize text-dark">type</p>
        <div className="btn-group" role="group" aria-label="Basic example">
          <button type="button" className="btn text-dark para text-uppercase mid__bold" id="base__btn">3d</button>
          <button type="button" className="btn para text-white text-uppercase mid__bold active">2d</button>
        </div>
      </div> */}
      {/* <!-- dark section  --> */}
      <div className="p-3 d-flex dark__head align-items-center  bg_gradient">
        <span className="icon__wrap text-success d-flex align-items-center justify-content-center mr-3">
          <FontAwesomeIcon icon={["far", "star"]} />
        </span>
        <h2 className="black__wrap text-uppercase text-left text-white mb-0">staddard nets</h2>
      </div>
      {/* <!-- image slider  --> */}
      <div className="img__slider p-3 p-sm-4 p-md-5 bg-white">
        {/* <!-- input box  --> */}
        <div className="icon__form d-flex justify-content-between py-3 bg-white">
          {/* <!-- left section  --> */}
          <div className="left__sec d-flex align-items-center w-xs-40">
            {/* <!-- icon  --> */}
            <div className="icon__wrap">
              <img src={setSide} alt="set icon"/>
            </div>
            {/* <!-- para  --> */}
            <select
              className="form-control ml-3"
              value={filterDiameter || ""}
              onChange={(e) => setFilterDiameter(parseInt(e.target.value))}
            >
              <option value="">All</option>
              {defaultDiameters.map((dm: any) => <option key={'net-default-diameter-' + dm} value={dm}>{dm}</option> )}
            </select>
          </div>
          {/* <!-- right section  --> */}
          <div className="right__sec d-flex justify-content-end align-items-center w-xs-40">
            {/* <!-- icon  --> */}
            <div className="icon__wrap">
              <FontAwesomeIcon icon="at" />
            </div>
            {/* <!-- para  --> */}
            <select
              className="form-control ml-3"
              value={filterGap || ""}
              onChange={(e) => setFilterGap(parseInt(e.target.value))}
            >
              <option value="">All</option>
              {defaultGaps.map((gp: any) => <option key={'net-default-gap-' + gp} value={gp}>{gp}</option> )}
            </select>
          </div>
        </div>
        {/* <!-- image slider box  --> */}
        <div className="img__container d-flex py-4 px-1">
          {/* <!-- image slide  --> */}
          {standardShapes && standardShapes.length > 0 && standardShapes.map((item: any, index: number) => 
            <div
              className="card__list"
              style={selectedShape === index ? {boxShadow: "0 0 0 0.2rem rgb(0 123 255 / 25%)"}: {}}
              key={'defaultNets'+index}
              onClick={() => setSelectedShape(index)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="99.296" height="96.663" viewBox="0 0 99.296 96.663">
                <line id="Line_2791" data-name="Line 2791" y2="44.069" transform="translate(7.066, 50)" fill="none" stroke="#4e5055" stroke-width="2"/>
                <line id="Line_2792" data-name="Line 2792" y2="43.88" transform="translate(14.01, 50)" fill="none" stroke="#4e5055" stroke-width="2"/>
                <line id="Line_2793" data-name="Line 2793" y2="44.069" transform="translate(22.439, 50)" fill="none" stroke="#4e5055" stroke-width="2"/>
                <line id="Line_2794" data-name="Line 2794" y2="44.588" transform="translate(30.859, 50)" fill="none" stroke="#4e5055" stroke-width="2"/>
                <line id="Line_2795" data-name="Line 2795" y2="44.069" transform="translate(37.605, 50)" fill="none" stroke="#4e5055" stroke-width="2"/>
                <line id="Line_2796" data-name="Line 2796" y2="45.664" transform="translate(45.664 57.348) rotate(90)" fill="none" stroke="#4e5055" stroke-width="2"/>
                <line id="Line_2797" data-name="Line 2797" y2="45.664" transform="translate(45.664 64.975) rotate(90)" fill="none" stroke="#4e5055" stroke-width="2"/>
                <line id="Line_2798" data-name="Line 2798" y2="45.664" transform="translate(45.664 74.602) rotate(90)" fill="none" stroke="#4e5055" stroke-width="2"/>
                <line id="Line_2799" data-name="Line 2799" y2="45.664" transform="translate(45.664 82.23) rotate(90)" fill="none" stroke="#4e5055" stroke-width="2"/>
                <text id="הזמנה_מס_2009" data-name="הזמנה מס׳ 2009" transform="translate(15 18)" fill="#4e5055" font-size="17" font-family="Heebo-Medium, Heebo" font-weight="500" letter-spacing="0.02em"><tspan x="-14.856" y="0">{item.x_length}</tspan></text>
                <text id="הזמנה_מס_2009-2" data-name="הזמנה מס׳ 2009" transform="translate(53.296 68.27)" fill="#4e5055" font-size="17" font-family="Heebo-Medium, Heebo" font-weight="500" letter-spacing="0.02em"><tspan x="0" y="0">{item.y_length}</tspan></text>
                <text id="הזמנה_מס_2009-3" data-name="הזמנה מס׳ 2009" transform="translate(23 37.435)" fill="#4e5055" font-size="17" font-family="Heebo-Medium, Heebo" font-weight="500" letter-spacing="0.02em"><tspan x="-22.632" y="0">{item.x_diameter.steel_diameter}@{item.x_gap}</tspan></text>
                <text id="הזמנה_מס_2009-4" data-name="הזמנה מס׳ 2009" transform="translate(53.296 89.663)" fill="#4e5055" font-size="17" font-family="Heebo-Medium, Heebo" font-weight="500" letter-spacing="0.02em"><tspan x="0" y="0">{item.y_diameter.steel_diameter}@{item.y_gap}</tspan></text>
              </svg>
            </div>
          )}
        </div>
      </div>
      {/* <!-- dark section  --> */}
      <div className="p-3 d-flex dark__head align-items-center bg_gradient">
        <span className="icon__wrap text-success d-flex align-items-center justify-content-center mr-3">
          <img src={create} alt="create icon"/>
        </span>
        <h2 className="black__wrap text-uppercase text-left text-white mb-0">create shapes</h2>
      </div>
      {/* <!-- preview and btn  --> */}
      <div className="bg-white p-4">
        {/* <!-- btn icon and heading  --> */}
        <div className="btn__icon__head d-flex justify-content-between">
          {/* <!-- icon and heading  --> */}
          <div className="icon__head d-flex align-items-center justify-content-center">
            <p className="big__para">Preview</p>
          </div>
          {/* <!-- buttons  --> */}
          <button className="btn bg-dark-500 text-dark d-flex justify-content-between align-items-center cus__btn">
            <span className="icon__wrap"><i className="fas fa-plus"></i></span>
            <p className="ml-2  text-uppercase">add a sketch</p>
          </button>
        </div>
        {/* <!-- image image table  --> */}
        <div className="main__img__table mt-3 mt-sm-4 d-flex align-items-center justify-content-center px-2 py-3 rounded">
          {/* <!-- right image  --> */}
          {/* <div className="right__img w-xs-100 d-flex align-items-center p-3">
            <img className="netAddMainTableImage w-xs-95" src={netMain} alt="images right"/>
          </div> */}
          <NetShape
          shapeObj={shapeObj}
          setRescaleFactor={setRescaleFactor}
          partitionX={partitionX}
          setPartitionX={setPartitionX}
          partitionY={partitionY}
          setPartitionY={setPartitionY}
          labelingPoints={labelingPoints}
          setLabelingPoints={setLabelingPoints}
          setShape={setShape}
          stageRef={stageRef}
          />
        </div>
        {/* <!-- general size  --> */}
        <div className="sec__img__table">
          {/* Net Number */}
          <div className="icon__form d-flex justify-content-between py-3 border__bottom">
            {/* <!-- left section  --> */}
            <div className="left__sec d-flex align-items-center">
              {/* <!-- para  --> */}
              <p className="big__para text-capitalize">Net Number</p>
            </div>
            {/* <!-- right section  --> */}
            <div className="right__sec d-flex justify-content-end align-items-center w-xs-40">
              {/* <h6 className="big__tittle mb-0"></h6> */}
              <input
                type="text"
                className="form-control bg-white border-success"
                value={netNumber}
                onChange={(e) => {
                  if (e.target.value !== '' && e.target.value[0] === "N") {
                    setNetNumber(e.target.value);
                  }
                }}
              />
            </div>
          </div>
          {/* <!-- btn icon and heading  --> */}
          <div className="btn__icon__head d-flex justify-content-between py-3 border__bottom">
            {/* <!-- icon and heading  --> */}
            <div className="icon__head d-flex align-items-start justify-content-center">
              <span className="icon__wrap mr-3 text-dark">
                <img src={length} alt="length icon"/>
              </span>
              <p className="big__para">Net SIZE</p><span className="unit">({t("cm", { ns: "measurements" })})</span>
            </div>
            {/* <!-- right side form input  --> */}
            <div className="right__side w-xs-40 d-flex flex-column">
              {/* <!-- column  --> */}
              <div className="d-flex align-items-center">
                <h5 className="text-uppercase bold mr-2">X</h5>
                <DebounceInput
                type="number"
                debounceTimeout={700}
                className={"form-control bg-white border-success input__big"
                + (shapeObj.x_length < netXLengthMin || shapeObj.x_length >= netXLengthMax ? " error-input" : "")}
                value={shapeObj.x_length || ''}
                onChange={(e) => setShape({...shapeObj, x_length: parseInt(e.target.value)})}/>
              </div>
              {/* <!-- column  --> */}
              <div className="d-flex align-items-center mt-2">
                <h5 className="text-uppercase bold mr-2">Y</h5>
                <DebounceInput
                type="number"
                debounceTimeout={700}
                className={"form-control bg-white border-success input__big"
                + (shapeObj.y_length < netYLengthMin || shapeObj.y_length >= netYLengthMax ? " error-input" : "")}
                value={shapeObj.y_length || ''}
                onChange={(e) => setShape({...shapeObj, y_length: parseInt(e.target.value)})}/>
              </div>
            </div>
          </div>
          {/* <!-- Steel Type  --> */}
          <div className="icon__form d-flex justify-content-between pt-3">
            {/* <!-- left section  --> */}
            <div className="left__sec d-flex align-items-center">
              {/* <!-- icon  --> */}
              <div className="icon__wrap mr-1">
                <img src={setEmpty} alt="set icon"/>
              </div>
              {/* <!-- para  --> */}
              <p className="big__para text-capitalize">Steel Type</p>
            </div>
            {/* <!-- right section  --> */}
            <div className="right__sec d-flex justify-content-end align-items-center w-xs-60">
              {/* <h6 className="big__tittle mb-0"></h6> */}
              <select className="form-control ml-3 mid__bold"
              value={shapeObj.steel_type || ''}
              onChange={({target}) => {
                const stType = parseInt(target.value);
                if (stType in steelDiameters) {
                  setShape(
                    (prev: any) => ({
                      ...prev,
                      steel_type: parseInt(target.value),
                      x_diameter: steelDiameters[stType][0],
                      y_diameter: steelDiameters[stType][0],
                    })
                  );
                } else {
                  setShape((prev: any) => ({...prev, steel_type: stType}));
                }
              }}
              >
                {steelTypes.map((st: {id: number, name: string}) =>
                  <option key={'rebar-steeltype-options-' + st.id} value={st.id}>{st.name}</option>
                )}
              </select>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- x details  --> */}
      <div className="p-3 d-flex dark__head align-items-center justify-content-between  bg_gradient">
        {/* <!-- left side  --> */}
        <div className="left d-flex">
          <span className="img__wrap mr-2">
            <img src={xDetails} alt=""/>
          </span>
          <h2 className="black__wrap text-uppercase text-left text-white mb-0">x details</h2>
        </div>
        {/* <!-- right side  --> */}
        <div className="right">
          <p className="para text-white" style={{color: 'white!important'}}>x {shapeObj.x_length || ''}</p>
        </div>
      </div>
      {/* <!-- details content  --> */}
      <div className="p-3 py-4 bg-white">
        {/* <!-- diameter  --> */}
        <div className="icon__form d-flex justify-content-between border__bottom py-3">
          {/* <!-- left section  --> */}
          <div className="left__sec d-flex align-items-center">
            {/* <!-- icon  --> */}
            <div className="icon__wrap mr-3">
              <img src={emptySet} alt="set icon"/>
            </div>
            {/* <!-- para  --> */}
            <p className="big__para text-capitalize">diameter</p><span className="unit">({t("cm", { ns: "measurements" })})</span>
          </div>
          {/* <!-- right section  --> */}
          <div className="right__sec d-flex justify-content-end align-items-center w-xs-40">
            {/* <h6 className="big__tittle mb-0"></h6> */}
            <select className="form-control ml-3 mid__bold"
              value={shapeObj.x_diameter ? shapeObj.x_diameter.id : ''}
              onChange={(e) => {
                const _diameter = getDiameter(parseInt(e.target.value));
                if (_diameter) {
                  setShape({...shapeObj, x_diameter: _diameter});
                }
              }}
            >
              {shapeObj.steel_type in steelDiameters
              && steelDiameters[shapeObj.steel_type].map((reDi: any, index: number) =>
                <option key={'net-x-diameter-options-' + index} value={reDi.id}>{reDi.steel_diameter.toFixed(0)}</option>
              )}
            </select>
          </div>
        </div>
        {/* <!-- gap  --> */}
        <div className="icon__form d-flex justify-content-between border__bottom py-3">
          {/* <!-- left section  --> */}
          <div className="left__sec d-flex align-items-center">
            {/* <!-- icon  --> */}
            <div className="icon__wrap mr-3">
              <img src={email} alt="email icon"/>
            </div>
            {/* <!-- para  --> */}
            <p className="big__para text-uppercase">gap</p><span className="unit">({t("cm", { ns: "measurements" })})</span>
          </div>
          {/* <!-- right section  --> */}
          <div className="right__sec d-flex justify-content-end align-items-center w-xs-40">
            <select className="form-control form-control-sm bg-white"
            value={shapeObj.x_gap || ''}
            onChange={(e) => setShape({...shapeObj, x_gap: parseInt(e.target.value)})
            }
            >
              {gapOptions.map((item, index) =>
                <option key={"gap-options-1"+index}>{item}</option>
              )}
            </select>
          </div>
        </div>
        <div className="img__table py-5 net-details"
        style={{backgroundImage: 'url(' + details + ')'}}>
          <div className="input__container row">
            {/* <!-- column  --> */}
            <div className="col-4 d-flex align-items-center">
              <label className="text-uppercase mr-2">x2</label>
              <DebounceInput
                type="number"
                className="form-control bg-white border-success input__big"
                value={partitionX._2 || ''}
                debounceTimeout={300}
                onChange={(e) => {
                  const val = parseInt(e.target.value);
                  if (val >= 0 || e.target.value === '') {
                    const diff = partitionX._2 - val;
                    setPartitionX({
                      ...partitionX,
                      _2: e.target.value === '' ? 0 : val,
                      _1: e.target.value === '' ? partitionX._1 + partitionX._2 : partitionX._1 + diff,
                    });
                  }
                }}
              />
            </div>
            {/* <!-- column  --> */}
            <div className="col-4 d-flex align-items-center">
              <label className="text-uppercase mr-2">x1</label>
              <DebounceInput
                type="number"
                className="form-control bg-white border-success input__big"
                value={partitionX._1.toFixed(0) || ''}
                debounceTimeout={300}
                onChange={(e) => {
                  const val = parseInt(e.target.value);
                  if (val < shapeObj.x_length && val > 0) {
                    const partionsRemaining = (partitionX._1 - parseInt(e.target.value))/2;
                    setPartitionX({
                      _1: val,
                      _2: partitionX._2 + partionsRemaining,
                      _3: partitionX._3 + partionsRemaining,
                    });
                  }
                }}
              />
            </div>
            {/* <!-- column  --> */}
            <div className="col-4 d-flex align-items-center">
              <label className="text-uppercase mr-2">x3</label>
              <DebounceInput
              type="number"
              className="form-control bg-white border-success input__big"
              value={partitionX._3 || ''}
              debounceTimeout={300}
              onChange={(e) => {
                const val = parseInt(e.target.value);
                if (val > 0 || e.target.value === '') {
                  const diff = partitionX._3 - val;
                  setPartitionX({
                    ...partitionX,
                    _3: e.target.value === '' ? 0 : val,
                    _1: e.target.value === '' ? partitionX._1 + partitionX._3 : partitionX._1 + diff,
                  });
                }
              }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- y details  --> */}
      <div className="p-3 d-flex dark__head align-items-center justify-content-between  bg_gradient">
        {/* <!-- left side  --> */}
        <div className="left d-flex align-items-center justify-content-center">
          <span className="img__wrap mr-2">
            <img className="yImg" src={yDetails} alt="y details"/>
          </span>
          <h2 className="black__wrap text-uppercase text-left text-white mb-0">y details</h2>
        </div>
        {/* <!-- right side  --> */}
        <div className="right">
          <p className="para text-white" style={{color: 'white!important'}}>x {shapeObj.y_length}</p>
        </div>
      </div>
      {/* <!-- details content  --> */}
      <div className="p-3 py-4 bg-white">
        {/* <!-- diameter  --> */}
        <div className="icon__form d-flex justify-content-between border__bottom py-3">
          {/* <!-- left section  --> */}
          <div className="left__sec d-flex align-items-center">
            {/* <!-- icon  --> */}
            <div className="icon__wrap mr-3">
              <img src={emptySet} alt="set icon"/>
            </div>
            {/* <!-- para  --> */}
            <p className="big__para text-capitalize">diameter</p><span className="unit">({t("cm", { ns: "measurements" })})</span>
          </div>
          {/* <!-- right section  --> */}
          <div className="right__sec d-flex justify-content-end align-items-center w-xs-40">
            {/* <h6 className="big__tittle mb-0"></h6> */}
            <select className="form-control ml-3 mid__bold"
              value={shapeObj.y_diameter ? shapeObj.y_diameter.id : ''}
              onChange={(e) => {
                const _diameter = getDiameter(parseInt(e.target.value));
                if (_diameter) {
                  setShape({...shapeObj, y_diameter: _diameter});
                }
              }}
            >
              {shapeObj.steel_type in steelDiameters
              && steelDiameters[shapeObj.steel_type].map((reDi: any, index: number) =>
                <option key={'net-y-diameter-options-' + index} value={reDi.id}>{reDi.steel_diameter.toFixed(0)}</option>
              )}
            </select>
          </div>
        </div>
        {/* <!-- gap  --> */}
        <div className="icon__form d-flex justify-content-between border__bottom py-3">
          {/* <!-- left section  --> */}
          <div className="left__sec d-flex align-items-center">
            {/* <!-- icon  --> */}
            <div className="icon__wrap mr-3">
              <img src={email} alt="email icon"/>
            </div>
            {/* <!-- para  --> */}
            <p className="big__para text-uppercase">gap</p><span className="unit">({t("cm", { ns: "measurements" })})</span>
          </div>
          {/* <!-- right section  --> */}
          <div className="right__sec d-flex justify-content-end align-items-center w-xs-40">
            <select className="form-control form-control-sm bg-white"
            value={shapeObj.y_gap || ''}
            onChange={(e) => setShape({...shapeObj, y_gap: parseInt(e.target.value)})
            }
            >
              {gapOptions.map((item, index) =>
                <option key={"gap-options-1"+index}>{item}</option>
              )}
            </select>
          </div>
        </div>
        <div className="img__table py-5 net-details"
        style={{backgroundImage: 'url(' + details + ')'}}>
          <div className="input__container row">
            {/* <!-- column  --> */}
            <div className="col-4 d-flex align-items-center">
              <label className="text-uppercase mr-2">y2</label>
              <DebounceInput
              type="number"
              className="form-control bg-white border-success input__big"
              value={partitionY._2 || ''}
              debounceTimeout={300}
              onChange={(e) => {
                const val = parseInt(e.target.value);
                const diff = partitionY._2 - val;
                if (val > 0 || e.target.value === '') {
                  setPartitionY({
                    ...partitionY,
                    _2: e.target.value === '' ? 0 : val,
                    _1: e.target.value === '' ? partitionY._1 + partitionY._2 : partitionY._1 + diff,
                  });
                }
              }}
              />
            </div>
            {/* <!-- column  --> */}
            <div className="col-4 d-flex align-items-center">
              <label className="text-uppercase mr-2">y1</label>
              <DebounceInput
              type="number"
              className="form-control bg-white border-success input__big"
              value={partitionY._1.toFixed(0) || ''}
              debounceTimeout={300}
              onChange={(e) => {
                const val = parseInt(e.target.value);
                if (val < shapeObj.y_length && val > 0) {
                  const partionsRemaining = (partitionY._1 - parseInt(e.target.value))/2;
                  setPartitionY({
                    _1: val,
                    _2: partitionY._2 + partionsRemaining,
                    _3: partitionY._3 + partionsRemaining,
                  });
                }
              }}
              />
            </div>
            {/* <!-- column  --> */}
            <div className="col-4 d-flex align-items-center">
              <label className="text-uppercase mr-2">y3</label>
              <DebounceInput
                type="number"
                className="form-control bg-white border-success input__big"
                value={partitionY._3 || ''}
                debounceTimeout={300}
                onChange={(e) => {
                  const val = parseInt(e.target.value);
                  if (val > 0 || e.target.value === '') {
                    const diff = partitionY._3 - val;
                    setPartitionY({
                      ...partitionY,
                      _3: e.target.value === '' ? 0 : val,
                      _1: e.target.value === '' ? partitionY._1 + partitionY._3 : partitionY._1 + diff
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- dark section  --> */}
      <div className="p-3 d-flex dark__head align-items-center  bg_gradient">
        <h2 className="black__wrap text-uppercase text-left text-white mb-0">quantity and weight</h2>
      </div>
      {/* <!-- quantity and weight section  --> */}
      <div className="p-3 p-sm-4 bg-white bottom__radius">
        {/* <!-- quantity  --> */}
        <div className="icon__form d-flex justify-content-between border__bottom pb-3">
          {/* <!-- left section  --> */}
          <div className="left__sec d-flex align-items-center">
            {/* <!-- icon  --> */}
            <div className="icon__wrap mr-3">
              <img src={quantity} alt="quantity icon"/>
            </div>
            {/* <!-- para  --> */}
            <p className="big__para text-capitalize">quantity</p>
          </div>
          {/* <!-- right section  --> */}
          <div className="right__sec d-flex justify-content-end align-items-center">
            <input type="text" className="form-control w-xs-30 bg-white mid__bold text-center"
              value={orderItem.quantity || ''}
              onChange={(e) => setOrderItem({...orderItem, quantity: parseInt(e.target.value)})}
            />
          </div>
        </div>
        {/* <!-- weight  --> */}
        <div className="icon__form d-flex justify-content-between py-3">
          {/* <!-- left section  --> */}
          <div className="left__sec d-flex align-items-center">
            {/* <!-- icon  --> */}
            <div className="icon__wrap mr-3">
              <img src={weight} alt="weight icon"/>
            </div>
            {/* <!-- para  --> */}
            <p className="big__para text-capitalize">weight</p><span className="unit">({t("kg", { ns: "measurements" })})</span>
          </div>
          {/* <!-- right section  --> */}
          <div className="right__sec d-flex justify-content-between align-items-center w-xs-40">
            <p className="big__para text-capitalize">unit</p>
            <p className="big__para text-capitalize">{shapeObj.unit_weight}</p>
          </div>
        </div>
        {/* <!-- total  --> */}
        <div className="icon__form d-flex justify-content-between pb-3 border__bottom">
          {/* <!-- left section  --> */}
          <div className="left__sec d-flex align-items-center">
          </div>
          {/* <!-- right section  --> */}
          <div className="right__sec d-flex justify-content-between align-items-center w-xs-40 total__big">
            <p className="big__para text-capitalize bold text-dark-500">Total</p>
            <p className="big__para text-capitalize bold text-dark-500">{(shapeObj.unit_weight*orderItem.quantity).toFixed(1)}</p>
          </div>
        </div>
        {/* <!-- item notes  --> */}
        <div className="icon__form d-flex justify-content-between py-3">
          {/* <!-- left section  --> */}
          <div className="left__sec d-flex align-items-center">
            {/* <!-- icon  --> */}
            <div className="icon__wrap mr-3">
              <img src={writer} alt="writer icon"/>
            </div>
            {/* <!-- para  --> */}
            <p className="big__para text-capitalize">item notes</p>
          </div>
        </div>
        {/* <!-- note pad  --> */}
        <div className="note__pad">
          <textarea className="form-control" style={{backgroundColor: '#F0F0F0'}} rows={5}
            value={orderItem.production_notes || ''}
            onChange={(e) => setOrderItem({...orderItem, production_notes: e.target.value})}
          ></textarea>
        </div>
        {/* <!-- add item btn  --> */}
        <div className="add__item__btn py-4 py-sm-5 d-flex justify-content-center">
          <button onClick={saveNet} className="big__btn text-uppercase bold text-white">add item</button>
        </div>
      </div>
    </>
  );
}

export default NetMobileContent;
