//CONSTANTS for the height lines on the shapes
const horizontalLine = 30;
const verticalLine = 25;

//Input Boxes
const inputBoxLeftGap = 85;
const inputBoxRightGap = 35;

//Text Labels
const labelLeftGap = 68;
const labelRightGap = 53;
const labelTopGap = 25;

const leftPadding = 4;
const rightPadding = 6;
const rightPaddingSpecial = 16;

export const calculateHeight = (length, angle) => {
  angle = angle * (Math.PI / 180);
  const height = length * Math.cos(angle);
  return Math.abs(height.toFixed(0));
};

export const lengthOnChangingHeight = (height, angle) => {
  angle = angle * (Math.PI / 180);
  const newLength = height / Math.cos(angle);
  return Math.abs(newLength.toFixed(0));
};

//Firstly there could be a line with outter true/false
//In false there are 4 types of lines (I guess each in every axis) and in true there are true

//Conditions are added for each line which tells me which points of the line is shorter/larger on the canvas ({x1,x2},{y1,y2})

// (1) By using that if I have to go to the left I am using the smallest point and I am subtracting a fixed number(30) which gives
//me second point(first line is drawn) and for the second line I know the first point which is the other end of the line and from
//there I am going to the point which I got earlier.

// (2) Similarly I f I have to go to the right I am adding that fixed point to the largest point and by using that I am
// getting second line similar to the point (1)

// (3) For the middle line in case of left side I am using the smallest point and for the right side i am using the largest side and
// subtracting a fixed number from it and getting the ({x1,y1},{x2,y2})

// (4) Other points are straight forward.

// (5) Overlapping Fixed by taking the difference of the vertical line's x-point  and text label's
// x-point. for each condition there is a diff point where overlapping occurs and paddings are also defined

const middlePoint = (point1, point2) => {
  return (point1 + point2) / 2;
};

const addValues = (value, padding, diff) => {
  return value + padding + diff;
};

const adjustOverlappingLines = (
  hzLine,
  vtLine,
  boxGap,
  labelGap,
  padding,
  diff
) => {
  const newHzLine = addValues(hzLine, padding, diff);
  const newVtLine = addValues(vtLine, padding, diff);
  const newBoxGap = addValues(boxGap, padding, diff);
  const newLabelGap = addValues(labelGap, padding, diff);
  return {
    hzLine: newHzLine,
    vtLine: newVtLine,
    boxGap: newBoxGap,
    labelGap: newLabelGap,
  };
};

const line1 = (linePoints, lineFactor) => {
  return {
    x1: linePoints.x,
    y1: linePoints.y,
    x2: linePoints.x - lineFactor,
    y2: linePoints.y,
  };
};

const line2 = (linePoints1, linePoints2, lineFactor) => {
  return {
    x1: linePoints1.x,
    y1: linePoints1.y,
    x2: linePoints2.x - lineFactor,
    y2: linePoints1.y,
  };
};

const inpBoxAndArrows = (xPoint, yPoint) => {
  return {
    x: xPoint,
    y: yPoint,
  };
};

export const shapeLinesForHeight = (
  side,
  rebar_side,
  currentPoint,
  previousPoint,
  index,
  inputPoints
) => {
  const outterInput =
    side.outer ||
    (index >= 1 &&
      !rebar_side[index - 1].change_previous_point &&
      rebar_side[index - 1].outer);

  let firstLine,
    secondLine,
    midLine,
    inputBox,
    label = {};
  let topArrow,
    bottomArrow = {};
  if (!outterInput) {
    //x2 < x1
    //y2 > y1
    if (currentPoint.x < previousPoint.x && currentPoint.y > previousPoint.y) {
      let lineFactors = {};

      let diff = Math.abs(
        Number(currentPoint.x - verticalLine) -
          Number(inputPoints[index].text.x)
      );

      if (diff < 15) {
        lineFactors = adjustOverlappingLines(
          horizontalLine,
          verticalLine,
          inputBoxLeftGap,
          labelLeftGap,
          leftPadding,
          diff
        );
      } else {
        lineFactors = {
          hzLine: horizontalLine,
          vtLine: verticalLine,
          boxGap: inputBoxLeftGap,
          labelGap: labelLeftGap,
        };
      }

      firstLine = line1(currentPoint, lineFactors.hzLine);
      secondLine = line2(previousPoint, currentPoint, lineFactors.hzLine);
      midLine = {
        x1: currentPoint.x - lineFactors.vtLine,
        y1: currentPoint.y,
        x2: currentPoint.x - lineFactors.vtLine,
        y2: previousPoint.y,
      };
      const len = middlePoint(currentPoint.y, previousPoint.y);
      inputBox = inpBoxAndArrows(currentPoint.x - lineFactors.boxGap, len);
      label = inpBoxAndArrows(
        currentPoint.x - lineFactors.labelGap,
        len - labelTopGap
      );
      topArrow = inpBoxAndArrows(
        currentPoint.x - lineFactors.vtLine,
        previousPoint.y
      );
      bottomArrow = inpBoxAndArrows(
        currentPoint.x - lineFactors.vtLine,
        currentPoint.y
      );
    }
    //x2 > x1
    //y2 > y1
    else if (
      currentPoint.x > previousPoint.x &&
      currentPoint.y > previousPoint.y
    ) {
      let lineFactors = {};

      let diff = Math.abs(
        Number(previousPoint.x - verticalLine) -
          Number(inputPoints[index].text.x) +
          20
      );
      if (diff < 24) {
        lineFactors = adjustOverlappingLines(
          horizontalLine,
          verticalLine,
          inputBoxLeftGap,
          labelLeftGap,
          leftPadding,
          diff
        );
      } else {
        lineFactors = {
          hzLine: horizontalLine,
          vtLine: verticalLine,
          boxGap: inputBoxLeftGap,
          labelGap: labelLeftGap,
        };
      }

      firstLine = line1(previousPoint, lineFactors.hzLine);
      secondLine = line2(currentPoint, previousPoint, lineFactors.hzLine);

      midLine = {
        x1: previousPoint.x - lineFactors.vtLine,
        y1: currentPoint.y,
        x2: previousPoint.x - lineFactors.vtLine,
        y2: previousPoint.y,
      };
      const len = middlePoint(currentPoint.y, previousPoint.y);
      inputBox = inpBoxAndArrows(previousPoint.x - lineFactors.boxGap, len);
      label = inpBoxAndArrows(
        previousPoint.x - lineFactors.labelGap,
        len - labelTopGap
      );
      topArrow = inpBoxAndArrows(
        previousPoint.x - lineFactors.vtLine,
        previousPoint.y
      );
      bottomArrow = inpBoxAndArrows(
        previousPoint.x - lineFactors.vtLine,
        currentPoint.y
      );
    }
    //x1 > x2
    //y1 > y2
    else if (
      previousPoint.x > currentPoint.x &&
      previousPoint.y > currentPoint.y
    ) {
      let lineFactors = {};

      let diff = Math.abs(
        Number(inputPoints[index].text.x) -
          Number(previousPoint.x + verticalLine)
      );
      if (diff < 15) {
        lineFactors = adjustOverlappingLines(
          horizontalLine,
          verticalLine,
          inputBoxRightGap,
          labelRightGap,
          rightPadding,
          diff
        );
      } else {
        lineFactors = {
          hzLine: horizontalLine,
          vtLine: verticalLine,
          boxGap: inputBoxRightGap,
          labelGap: labelRightGap,
        };
      }

      //passing in -ve coz -- is +ve
      firstLine = line1(previousPoint, -lineFactors.hzLine);
      secondLine = line2(currentPoint, previousPoint, -lineFactors.hzLine);
      midLine = {
        x1: previousPoint.x + lineFactors.vtLine,
        y1: currentPoint.y,
        x2: previousPoint.x + lineFactors.vtLine,
        y2: previousPoint.y,
      };
      const len = middlePoint(currentPoint.y, previousPoint.y);
      inputBox = inpBoxAndArrows(previousPoint.x + lineFactors.boxGap, len);
      label = inpBoxAndArrows(
        previousPoint.x + lineFactors.labelGap,
        len - labelTopGap
      );
      topArrow = inpBoxAndArrows(
        previousPoint.x + lineFactors.vtLine,
        currentPoint.y
      );
      bottomArrow = inpBoxAndArrows(
        previousPoint.x + lineFactors.vtLine,
        previousPoint.y
      );
    }
    // x1 < x2
    // y1 > y2
    else if (
      currentPoint.x > previousPoint.x &&
      previousPoint.y > currentPoint.y
    ) {
      let newHorizontalLine = horizontalLine;
      let newVerticalLine = verticalLine;
      let newInputBoxRightGap = inputBoxRightGap;
      let newLabelRightGap = labelRightGap;

      let diff = Math.abs(
        Number(inputPoints[index].text.x) -
          Number(currentPoint.x + verticalLine)
      );
      if (diff < 24) {
        newHorizontalLine =
          diff < 5
            ? newHorizontalLine + rightPaddingSpecial + diff
            : diff > 14
            ? newHorizontalLine + rightPadding + diff / 2
            : newHorizontalLine + rightPadding + diff;
        newVerticalLine =
          diff < 5
            ? newVerticalLine + rightPaddingSpecial + diff
            : diff > 14
            ? newVerticalLine + rightPadding + diff / 2
            : newVerticalLine + rightPadding + diff;
        newInputBoxRightGap =
          diff < 5
            ? newInputBoxRightGap + rightPaddingSpecial + diff
            : diff > 14
            ? newInputBoxRightGap + rightPadding + diff / 2
            : newInputBoxRightGap + rightPadding + diff;
        newLabelRightGap =
          diff < 5
            ? newLabelRightGap + rightPaddingSpecial + diff
            : diff > 14
            ? newLabelRightGap + rightPadding + diff / 2
            : newLabelRightGap + rightPadding + diff;
      }

      firstLine = line1(currentPoint, -newHorizontalLine);
      secondLine = line2(previousPoint, currentPoint, -newHorizontalLine);
      midLine = {
        x1: currentPoint.x + newVerticalLine,
        y1: currentPoint.y,
        x2: currentPoint.x + newVerticalLine,
        y2: previousPoint.y,
      };
      const len = middlePoint(currentPoint.y, previousPoint.y);
      inputBox = inpBoxAndArrows(currentPoint.x + newInputBoxRightGap, len);
      label = inpBoxAndArrows(
        currentPoint.x + newLabelRightGap,
        len - labelTopGap
      );
      topArrow = inpBoxAndArrows(
        currentPoint.x + newVerticalLine,
        currentPoint.y
      );
      bottomArrow = inpBoxAndArrows(
        currentPoint.x + newVerticalLine,
        previousPoint.y
      );
    }
  }
  //Outter
  else {
    //x2 > x1
    //y2 > y1
    if (currentPoint.x > previousPoint.x && currentPoint.y > previousPoint.y) {
      let lineFactors = {};

      let diff =
        Number(currentPoint.x + verticalLine) -
        Number(inputPoints[index].text.x);
      if (diff < 3) {
        diff = Math.abs(diff);
        lineFactors = adjustOverlappingLines(
          horizontalLine,
          verticalLine,
          inputBoxRightGap,
          labelRightGap,
          rightPadding,
          diff
        );
      } else {
        lineFactors = {
          hzLine: horizontalLine,
          vtLine: verticalLine,
          boxGap: inputBoxRightGap,
          labelGap: labelRightGap,
        };
      }

      firstLine = line1(currentPoint, -lineFactors.hzLine);
      secondLine = line2(previousPoint, currentPoint, -lineFactors.hzLine);
      midLine = {
        x1: currentPoint.x + lineFactors.vtLine,
        y1: currentPoint.y,
        x2: currentPoint.x + lineFactors.vtLine,
        y2: previousPoint.y,
      };
      const len = middlePoint(currentPoint.y, previousPoint.y);
      inputBox = inpBoxAndArrows(currentPoint.x + lineFactors.boxGap, len);
      label = inpBoxAndArrows(
        currentPoint.x + lineFactors.labelGap,
        len - labelTopGap
      );
      topArrow = inpBoxAndArrows(
        currentPoint.x + lineFactors.vtLine,
        previousPoint.y
      );
      bottomArrow = inpBoxAndArrows(
        currentPoint.x + lineFactors.vtLine,
        currentPoint.y
      );
    }
    //x2 > x1
    //y2 < y1
    else if (
      currentPoint.x > previousPoint.x &&
      previousPoint.y > currentPoint.y
    ) {
      let lineFactors = {};

      let diff =
        Number(inputPoints[index].text.x) -
        Number(previousPoint.x - verticalLine);
      if (diff < 1) {
        diff = Math.abs(diff);
        lineFactors = adjustOverlappingLines(
          horizontalLine,
          verticalLine,
          inputBoxLeftGap,
          labelLeftGap,
          leftPadding,
          diff
        );
      } else {
        lineFactors = {
          hzLine: horizontalLine,
          vtLine: verticalLine,
          boxGap: inputBoxLeftGap,
          labelGap: labelLeftGap,
        };
      }

      firstLine = line1(previousPoint, lineFactors.hzLine);
      secondLine = line2(currentPoint, previousPoint, lineFactors.hzLine);
      midLine = {
        x1: previousPoint.x - lineFactors.vtLine,
        y1: currentPoint.y,
        x2: previousPoint.x - lineFactors.vtLine,
        y2: previousPoint.y,
      };
      const len = middlePoint(currentPoint.y, previousPoint.y);
      inputBox = inpBoxAndArrows(previousPoint.x - lineFactors.boxGap, len);
      label = inpBoxAndArrows(
        previousPoint.x - lineFactors.labelGap,
        len - labelTopGap
      );
      topArrow = inpBoxAndArrows(
        previousPoint.x - lineFactors.vtLine,
        currentPoint.y
      );
      bottomArrow = inpBoxAndArrows(
        previousPoint.x - lineFactors.vtLine,
        previousPoint.y
      );
    }
  }
  return {
    firstLine: firstLine,
    secondLine: secondLine,
    midLine: midLine,
    inputBox: inputBox,
    label: label,
    topArrow: topArrow,
    bottomArrow: bottomArrow,
  };
};
