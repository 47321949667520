export const ticketCreatorEn = {
  "order details": "Order Details",
  "item selected": "item are selected",
  "select all": "Mark all items",
  "mark or unmark": "you can mark/un mark the rows according to what you need to print",
  "print tickets": "print tickets",
  "ticket_creator" : "Ticket Creator",
  "order_no" : "order number",
  "input_placeholder": "type the order number from easybar PDF",
  "status" : "Status",
  "free_trial_status_text" : "Free trial available till ",
  "limited_status_text_heb" : "",
  "limited_status_text" : " orders per month",
  "unlimited_Status_text" : "unlimited use",
  "free_trial_link" : "read more details",
  "limited_link" : "go unlimited",
  "unlimited_link" : "more information",
  "heb_1": "",
  "ticket_create_txt_1" : "Do you want to get order number and convert it to ticket\n without typing it again? send this link to your customers >",
  "ticket_create_txt_2" : " and you will be able to enjoy readable orders, without  unnecessary questions.",
  "need help": "Need help? we are available on this",
  "whatsapp" : "Whatsapp",
  "printer type":"Select the printer type",
  "sticker printer": "sticker printer",
  "A4 printer": "A4 printer",
  "a4 description": "A home printer of A4 paper (8 tickets on a page).",
  "sticker description 1": "A WIFI printer, you can put next to the production machines.",
  "sticker description 2": "You can print stickers, so you don’t have to deal with wires for attachment.",
  "more details": "Read more details",
  "welcome" : "Wellcome to Easybar’s tickets creator",
  "welcome_text": "To save you typing orders, ask your customers to create the orders for free in Easybar and and all you have to do is print the tickets.",
  "start" : "Let’s start",
  "rebar_supplier" : "Rebar's supplier?",
  "print_card" : "Print a production cards with a click",
  "supplier_name" : "Supplier's name",
  "more_details": "read more details",
  "start_trial" : "Start free trial",
  "type_name": "Type the legal name",
  "next" : "next",
  "add_Link_1": "To get to know you, add a link to",
  "add_Link_2": "your website/Facebook (optional)",
  "paste_link": "Copy link and paste here",
  "your_details": "Your details are on their way to us, we will contact to you soon.",
  "soon_print": "Soon you will be able to save time and print cards easily.",
  "go_back" :"go back to create orders",
  "looks_like": "Looks like you used the whole package this month",
  "package_renew": "The package will be renewed on ",
  "go_unlimited": "Go unlimited",
  "share_opinions": "Share ypur opinion with us",
  "short_survey" : "a short survey about the product",
  "trial_finished": "The trial month is over. Do you want to continue using Easybar?",
  "connect": "connect us",
  "customer": "Customer",
  "delivery": "Delivery",
  "site": "Site",
  "item weight": "Item Weight: ",
  "total weight": "Total Weight: ",
  "type|diam": "Type | Diam",
  "length": "Length",
  "amount": "Amount",
  "print": "Print",
  "Load an order": "Load an order",
  "orderNo details": "The number is on the right top corner of the PDF.",
  "Deselect all": "Un-mark All Items",
  "404 error": "The order number doesn't exist or you don't have permission to this order number. Ask the Order's creator to share with you. try again or ",
  "404 error link": "reach us.",
  "special net": "special net",
  "standard net": "standard net",
  "gap" : "gap",
  "type" : "type",
  "coming soon": "Coming Soon",
  "Note" : "Note",
  "steel type" : "steel type",
  "used": "used",
  "error_admin_order": "The order number doesn't exist. Try again or ",
  "error_link": "reach us.",

  "nickname": "nickname",
  "total length": "Total Length",
  "con/cage" : "Con/Cage",
  "base cage": "Base Cage",
  "side cage": "Side Cage",
  "rings": "rings",
  "gama": "gama",
  "Tzol": "Tzol",
  "tickets amount": "tickets amount",


};