import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import { createAttestation } from "Services/user";
import { CLAIM_TYPES } from "Constants/general";
import { setUserAttestedTC } from "Redux/features/user";
import policy_background_img from "Assets/img/policy_popup_background.png";
import "./index.scss";

function UpdatedPolicyPopUp({ ...props }) {
  const { show, onHide } = props;
  const dispatch = useAppDispatch();
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const { t } = useTranslation(["signup", "common"]);

  const [agreeBox, setAgreeBox] = useState(false);
  const handleConfirmation = async () => {
    if (agreeBox) {
      const data = {
        is_claimed: true,
      };
      await createAttestation(data, CLAIM_TYPES.TERMS_AND_CONDITIONS)
        .then((res) => {
          dispatch(setUserAttestedTC());
        })
        .catch((err) => {});
      dispatch(setUserAttestedTC());
    }
  };

  return (
    <div className="one_time_popup_outer_div">
      <img
        src={policy_background_img}
        alt=""
        className="onetime_popup_background"
      ></img>
      <Modal
        show={show}
        onHide={onHide}
        className="updated_policy_popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="container policy-container">
          <div className="updated_policy_content">
            <p className="policy_text">
              {t("greetings customers", { ns: "signup" })}
            </p>
            <p
              className="policy_oscar_text pt-2"
              style={{ padding: rightToLeft ? "" : "0 45px" }}
            >
              {t("updated policy", { ns: "signup" })}
            </p>
            <p className="policy_text pt-4">
              {t("what's new_1", { ns: "signup" })}
            </p>
            {rightToLeft && (
              <p className="policy_text px-3">
                {t("what's new_2", { ns: "signup" })}
              </p>
            )}

            <p className="pt-3">
              <span className="policy_text_2">
                {t("to continue approve", { ns: "signup" })}
              </span>
              {!rightToLeft && <br />}
              <span
                className="cursor_pointer terms_link"
                onClick={() => {
                  window.open("/terms-and-conditions", "_blank");
                }}
              >
                <span className="policy_text_changed">
                  {t("terms", { ns: "signup" })}
                </span>
              </span>

              <span className="policy_text_2">
                {t("accept it", { ns: "signup" })}
              </span>
            </p>

            <div className="policy_confirmation">
              <input
                type="checkbox"
                checked={agreeBox}
                className="cursor_pointer"
                onChange={() => setAgreeBox(!agreeBox)}
              />
              <p className={rightToLeft ? "pr-2" : "pl-2"}>
                {t("read terms of use", { ns: "signup" })}
              </p>
            </div>

            <button onClick={handleConfirmation} className="policy_agree_btn">
              {t("agree btn", { ns: "signup" })}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default UpdatedPolicyPopUp;
