import React, { useEffect, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { fetchDefaultsNets } from "Redux/middlwares/net";
import { fetchSteelDiameters, fetchSteelTypes } from "Redux/middlwares/steelType";
import { deleteNet, postNet, updateNet } from "Services/net";
import {ReactComponent as DNDlogo} from '../../../assets/img/admin/dnd-logo.svg';

const NetsCard = () => {
  const [standardNetsEdit, setStandardNetsEdit] = useState(false);
  const [standardNets, setStandardNets] = useState([]);

  const [specialNetsEdit, setSpecialNetsEdit] = useState(false);
  const [specialNets, setSpeciallNets] = useState([]);

  const [loader, setLoader] = useState(false);

  const userData = useAppSelector(state => state.user.userData);
  const steelTypes = useAppSelector(state => state.steelType.steelTypes);
  const steelDiameters = useAppSelector(state => state.steelType.steelDiameters);
  const defaultNets = useAppSelector(state => state.net.defaultNets);
  const dispatch = useAppDispatch();

  const handleChangeNetValue = (key: string, val: any, index: number, stand: boolean) => {
    const copyOfNet = [...(stand ? standardNets : specialNets) as any];
    if (key === 'x_diameter') {
      copyOfNet[index] = {
        ...copyOfNet[index],
        x_diameter: val,
        y_diameter: val,
        edit: true,
      };
    } else if (key === 'x_2') {
      copyOfNet[index] = {
        ...copyOfNet[index],
        x_2: val,
        x_3: val,
        y_2: val,
        y_3: val,
        edit: true,
      };
    } else if (key === 'x_gap') {
      copyOfNet[index] = {
        ...copyOfNet[index],
        x_gap: val,
        y_gap: val,
        edit: true,
      };
    } else {
      copyOfNet[index] = {
        ...copyOfNet[index],
        [key]: val,
        edit: true,
      };
    }
    if (stand) {setStandardNets(copyOfNet as any)} else {setSpeciallNets(copyOfNet as any)}
  }

  const handleNetAddNewRow = (stand: boolean) => {
    const net = {
      x_length: '',
      y_length: '',
      x_gap: '',
      y_gap: '',
      x_diameter: '',
      y_diameter: '',
      steel_type: '',
      x_2: null,
      x_3: null,
      y_2: null,
      y_3: null,
      type: 2,
      subclass_type: "net",
    };
    if (stand) {
      setStandardNets([...standardNets, {...net, standard_net: true}] as any);
    } else {
      setSpeciallNets([...specialNets, net] as any);
    }
  }

  const handleSaveStandardNets = (stand: boolean) => {
    setLoader(true);
    const copyOfNets = ([...(stand ? standardNets : specialNets)] as any)
      .filter((item: any) => !(item.id) || item.edit);
    const promises = [];
    for (let i=0; i<copyOfNets.length; i++) {
      if (copyOfNets[i].id) {
        promises.push(updateNet(
          copyOfNets[i].id,
          {
            x_length: copyOfNets[i].x_length,
            y_length: copyOfNets[i].y_length,
            x_gap: copyOfNets[i].x_gap,
            y_gap: copyOfNets[i].y_gap,
            x_diameter: copyOfNets[i].x_diameter.id,
            y_diameter: copyOfNets[i].y_diameter.id,
            steel_type: copyOfNets[i].steel_type,
            x_2: copyOfNets[i].x_2,
            x_3: copyOfNets[i].x_3,
            y_2: copyOfNets[i].y_2,
            y_3: copyOfNets[i].y_3,
            standard_net: stand ? true : false,
          }
        ));
      } else {
        promises.push(postNet({
          ...copyOfNets[i],
          x_diameter: copyOfNets[i].x_diameter.id,
          y_diameter: copyOfNets[i].y_diameter.id,
        }));
      }
    }
    Promise.all(promises)
    .then(() => {
      dispatch(fetchDefaultsNets());
      if (stand) {
        setStandardNetsEdit(false);
      } else {
        setSpecialNetsEdit(false);
      }
    }).finally(() => setLoader(false));
  }

  const handleDeleteNet = (index: number, stand: boolean) => {
    const net = {...(stand ? standardNets : specialNets)[index] as any};
    const copyOfNets = [...(stand ? standardNets : specialNets)];
    if (net.id) {
      deleteNet(net.id)
      .then(() => {
        copyOfNets.splice(index, 1);
        if (stand) {setStandardNets(copyOfNets)} else {setSpeciallNets(copyOfNets)}
      })
    } else {
      copyOfNets.splice(index, 1);
      if (stand) {setStandardNets(copyOfNets)} else {setSpeciallNets(copyOfNets)}
    }
  }

  const getDiameter = (steel_type: number, steelDiameterID: number) => {
    return steelDiameters[steel_type].find((item: any) => item.id === steelDiameterID);
  }

  useEffect(() => {
    if (userData) {
      steelTypes.length === 0 && dispatch(fetchSteelTypes())
      defaultNets.length === 0 && dispatch(fetchDefaultsNets());
    }
    if (defaultNets.length > 0) {
      const allSteelTypes = new Set(defaultNets.map(item => item.steel_type));
      setStandardNets((defaultNets.filter(item => item.standard_net)) as any);
      setSpeciallNets((defaultNets.filter(item => !item.standard_net)) as any);
      allSteelTypes.forEach(itemSt => {
        dispatch(fetchSteelDiameters(itemSt));
      });
    }
  }, [userData, steelTypes, defaultNets, defaultNets.length, dispatch]);

  return (
    <>
      <p className="py-3">Nets Pool</p>
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between pb-3">
            <p className="font-weight-bold">Standard Nets</p>
            <div className="d-flex justify-content-around align-items-center">
              <div className="px-2">
                <input type="checkbox" id="order-by-popularity" className="mr-1" />
                <label htmlFor="order-by-popularity">Order by Popularity</label>
              </div>
              <div className="px-2">
                <input type="checkbox" id="edit-text" className="mr-1"
                checked={standardNetsEdit}
                onChange={() => setStandardNetsEdit(!standardNetsEdit)}/>
                <label htmlFor="edit-text">Edit Text</label>
              </div>
            </div>
          </div>
          {standardNetsEdit &&
            <div className="d-flex justify-content-end pb-3">
              <Button
              variant="light"
              className="mr-2"
              onClick={() => handleNetAddNewRow(true)}
              >add new row</Button>
              <Button
              variant="secondary"
              className="mr-2"
              onClick={() => {
                setStandardNetsEdit(!standardNetsEdit)
              }}>cancel</Button>
              <Button variant="success" onClick={() => handleSaveStandardNets(true)}
              disabled={loader}>
                save
                {loader &&
                  <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  &nbsp;
                  </>
                }
              </Button>
            </div>
          }
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>X</th>
                <th>Y</th>
                <th>Steel Type</th>
                <th>Diameter</th>
                <th>@</th>
                {standardNetsEdit && <th></th>}
              </tr>
            </thead>
            <tbody>
            {standardNets.length > 0 && standardNets.map((item: any, index: number) => (
              <tr key={'cages'+index}>
                {standardNetsEdit
                ?
                  <>
                  <td>
                    <DNDlogo />
                  </td>
                  <td>
                    <input
                    type="number"
                    className="input-inside"
                    value={item.x_length}
                    onChange={(e) => handleChangeNetValue('x_length', parseInt(e.target.value), index, true)} />
                  </td>
                  <td>
                    <input
                    type="number"
                    className="input-inside"
                    value={item.y_length}
                    onChange={(e) => handleChangeNetValue('y_length', parseInt(e.target.value), index, true)} />
                  </td>
                  <td>
                    <select className="form-control form-control-sm"
                      value={item.steel_type || ''}
                      onChange={({target}) => {
                        const val = parseInt(target.value);
                        handleChangeNetValue('steel_type', val, index, true)
                        if (!(val in steelDiameters)) dispatch(fetchSteelDiameters(val));
                      }}
                    >
                      <option value={''} disabled>Select</option>
                      {steelTypes.map((st: {id: number, name: string}) =>
                        <option key={'rebar-steeltype-options-' + st.id} value={st.id}>{st.name}</option>
                      )}
                    </select>
                  </td>
                  <td>
                    <select className="form-control form-control-sm"
                      value={
                        item.x_diameter
                        && item.steel_type in steelDiameters
                        && steelDiameters[item.steel_type].find((reDi: any) => reDi.id === item.x_diameter.id)
                        ? item.x_diameter.id : ''
                      }
                      onChange={(e) => {
                        const _diameter = getDiameter(item.steel_type, parseInt(e.target.value));
                        if (_diameter) {
                          handleChangeNetValue('x_diameter', _diameter, index, true);
                        }
                      }}
                    >
                      <option value={''} disabled>Select</option>
                      {item.steel_type in steelDiameters
                      && steelDiameters[item.steel_type].map((reDi: any, index: number) =>
                        <option key={'net-stand-diameter-options-' + index} value={reDi.id}>{reDi.steel_diameter.toFixed(0)}</option>
                      )}
                    </select>
                  </td>
                  <td>
                    <input
                    type="number"
                    className="input-inside"
                    value={item.x_gap}
                    onChange={(e) => {
                      handleChangeNetValue('x_gap', parseInt(e.target.value), index, true);
                    }} />
                  </td>
                  <td>
                    <button
                      style={{
                        border: 'none',
                        background: 'transparent',
                      }}
                      onClick={() => handleDeleteNet(index, true)}
                    >&#9587;</button>
                  </td>
                  </>
                :
                  <>
                  <td>
                    <DNDlogo />
                  </td>
                  <td>{item.x_length}</td>
                  <td>{item.y_length}</td>
                  <td>{steelTypes.length > 0 && item.steel_type && steelTypes.find(itemSt => itemSt.id === item.steel_type).name}</td>
                  <td>{item.x_diameter.steel_diameter}</td>
                  <td>{item.x_gap}</td>
                  </>
                }
              </tr>
            ))}
            </tbody>
          </Table>

          <div className="d-flex justify-content-between pb-3">
            <p className="font-weight-bold">Special Nets</p>
            <div className="d-flex justify-content-around align-items-center">
              <div className="px-2">
                <input type="checkbox" id="order-by-popularity" className="mr-1" />
                <label htmlFor="order-by-popularity">Order by Popularity</label>
              </div>
              <div className="px-2">
                <input type="checkbox" id="edit-text-special" className="mr-1"
                checked={specialNetsEdit}
                onChange={() => setSpecialNetsEdit(!specialNetsEdit)} />
                <label htmlFor="edit-text-special">Edit Text</label>
              </div>
            </div>
          </div>
          {specialNetsEdit &&
            <div className="d-flex justify-content-end pb-3">
              <Button
              variant="light"
              className="mr-2"
              onClick={() => handleNetAddNewRow(false)}
              >add new row</Button>
              <Button
              variant="secondary"
              className="mr-2"
              onClick={() => {
                setSpecialNetsEdit(!specialNetsEdit)
              }}>cancel</Button>
              <Button variant="success" onClick={() => handleSaveStandardNets(false)}
              disabled={loader}>
                save
                {loader &&
                  <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  &nbsp;
                  </>
                }
              </Button>
            </div>
          }
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>X</th>
                <th>Y</th>
                <th>x2,y2</th>
                <th>Steel Type</th>
                <th>Diameter</th>
                <th>@</th>
                {specialNetsEdit && <th></th>}
              </tr>
            </thead>
            <tbody>
              {specialNets.length > 0 && specialNets.map((item: any, index: number) => (
                <tr key={'cages'+index}>
                  {specialNetsEdit
                  ?
                    <>
                    <td>
                      <DNDlogo />
                    </td>
                    <td>
                      <input
                      type="number"
                      className="input-inside"
                      value={item.x_length}
                      onChange={(e) => handleChangeNetValue('x_length', parseInt(e.target.value), index, false)} />
                    </td>
                    <td>
                      <input
                      type="number"
                      className="input-inside"
                      value={item.y_length}
                      onChange={(e) => handleChangeNetValue('y_length', parseInt(e.target.value), index, false)} />
                    </td>
                    <td>
                      <input
                      type="number"
                      className="input-inside"
                      value={item.x_2 || ''}
                      onChange={(e) => {
                        handleChangeNetValue('x_2', parseInt(e.target.value), index, false);
                      }} />
                    </td>
                    <td>
                      <select className="form-control form-control-sm"
                        value={item.steel_type || ''}
                        onChange={({target}) => {
                          const val = parseInt(target.value);
                          handleChangeNetValue('steel_type', val, index, false);
                          if (!(val in steelDiameters)) dispatch(fetchSteelDiameters(val));
                        }}
                      >
                        <option value={''} disabled>Select</option>
                        {steelTypes.map((st: {id: number, name: string}) =>
                          <option key={'rebar-steeltype-options-' + st.id} value={st.id}>{st.name}</option>
                        )}
                      </select>
                    </td>
                    <td>
                      <select className="form-control form-control-sm"
                        value={
                          item.x_diameter
                          && item.steel_type in steelDiameters
                          && steelDiameters[item.steel_type].find((reDi: any) => reDi.id === item.x_diameter.id)
                          ? item.x_diameter.id : ''
                        }
                        onChange={(e) => {
                          const _diameter = getDiameter(item.steel_type, parseInt(e.target.value));
                          if (_diameter) {
                            handleChangeNetValue('x_diameter', _diameter, index, false);
                          }
                        }}
                      >
                        <option value={''} disabled>Select</option>
                        {item.steel_type in steelDiameters
                        && steelDiameters[item.steel_type].map((reDi: any, index: number) =>
                          <option key={'net-special-diameter-options-' + index} value={reDi.id}>{reDi.steel_diameter.toFixed(0)}</option>
                        )}
                      </select>
                    </td>
                    <td>
                      <input
                      type="number"
                      className="input-inside"
                      value={item.x_gap}
                      onChange={(e) => {
                        handleChangeNetValue('x_gap', parseInt(e.target.value), index, false);
                      }} />
                    </td>
                    <td>
                      <button
                        style={{
                          border: 'none',
                          background: 'transparent',
                        }}
                        onClick={() => handleDeleteNet(index, false)}
                      >&#9587;</button>
                    </td>
                    </>
                  :
                    <>
                    <td>
                      <DNDlogo />
                    </td>
                    <td>{item.x_length}</td>
                    <td>{item.y_length}</td>
                    <td>{item.x_2 || ''}</td>
                    <td>{steelTypes.length > 0 && item.steel_type && steelTypes.find(itemSt => itemSt.id === item.steel_type).name}</td>
                    <td>{item.x_diameter.steel_diameter}</td>
                    <td>{item.x_gap}</td>
                    </>
                  }
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
}

export default NetsCard;