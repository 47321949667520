export const signupEn = {
  "tagline": "With easybar it is easy to create, manage and track your iron orders",
  "signup": "Signup",
  "login": "Login",
  "already have account": "Do you already have an account?",
  "login to account": "Login to your account",
  "get started": "Few details to start",
  "first name": "First Name",
  "last name": "Last Name",
  "email": "Email",
  "number": "Phone Number",
  "password": "Password",
  "password again": "Password Again",
  "numbers only": "Enter numbers only",
  "min char": "Minimum 4 characters",
  "password match": "Make sure passwords match",
  "confirm": "I confirm that I have read, and I understand",
  "terms of use": "terms of use",
  "and": "and",
  "policy": "privacy policy",
  "agree": "of system, and agree to them.",
  "marketing email": "I agree to receive professional information and marketing material on my e-mail.",
  "next": "Next",
  "email verification": "We have sent you a link to the email, click on it to finish the process.",
  "verification email sent": "Verification Email sent!",
  "not recieved": "I didn't get the email",
  "confirm email": "Confirm that this is your email:",
  "true": "True",
  "deny": "Deny",
  "free use": "Use for free",

  "open from computer": "Log in through the computer and start creating iron orders",
  "under construction": "The mobile phone version is in the development",

  "joined": "Glad you joined",
  "mobile instructions": "From today you can enjoy creating iron orders quickly and conveniently. All your orders will be saved with your username, and will be accessible to you from any computer.",
  "details emailed": "We have sent you an e-mail with all the details you need.",

  "whatsapp": "WhatsApp",
  "not receive email": "Did you not receive the email?",
  "not get it": "Didn't get it at",
  "resend email": "resend email",
  "check spam note": "Check spam or sales promotions, maybe we are there by mistake.",
  "still can't find it" : "Still can't find it?",
  "send us a message": "Send us a message",

  "greetings customers": "Hey,",
  "updated policy": "We've introduced new functionalities and made revisions to our terms of use.",
  "what's new_1": "The Easybar for suppliers is now up and running :)",
  "to continue approve": "To proceed, please pursue the updated ",
  "terms": "terms of use ",
  "accept it": "and check the designated box.",
  "read terms of use": "I confirm understanding of the revised terms of use.",
  "agree btn": "Agree and Continue",

};
