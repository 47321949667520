import { useTranslation } from 'react-i18next';
import WarningModal from './../../custom-modal/index';


function ChangeMeasurementsModal(props: any) {
  const { t } = useTranslation(['tool','common']);
  const { onHide, saveRebar } = props;

  const saveOrder = () => {
    saveRebar(true);
    onHide();
  };

  const btnValues = [
    {
      text: t('cancel', { ns: 'common' }),
      btnClass: 'custom__btn-secondary',
    },
    {
      text: t("I'm sure", { ns: 'tool' }),
      btnClass: 'custom__btn-primary',
    },
  ];

  const btnFunctions = {
    primary: saveOrder,
    secondary: props.onHide,
  }

  return (
    <WarningModal
      show={props.show}
      onHide={props.onHide}
      dialogClassName={'custom__width'}
      btnFunctions={btnFunctions}
      btnValues={btnValues}
      modalColor={'#fff'}
    >
      <div className="custom__title pb-md-4 pb-2">
        <p className="mb-0">
          {t('measurements warning', { ns: 'tool' })}
          <span style={{ color: '#CD2017' }}>
            {' '}
            {t('measurements surety', { ns: 'tool' })}
          </span>
        </p>
      </div>
      <div className="custom__img__wrapper">
        <img className="img-fluid custom__img" src={props.rebarImg} alt="map icon" />
      </div>
    </WarningModal>
  );
}

export default ChangeMeasurementsModal;
