import { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { format, formatDistanceToNowStrict, formatDistanceStrict, addDays, isFuture} from "date-fns";
import { getFreeTrialList, patchSupplierDetails } from "Services/supplier-tickets";
import { withTranslation } from "react-i18next";


const SupplierList = () => {

  // Supplier Table States
  const [freeTrialSuppliers, setFreeTrialSuppliers] = useState([]);
  const [freeTrialSuppliersPage, setFreeTrialSuppliersPage] = useState('');
  const [prevFreeTrialSuppliersPage, setPrevFreeTrialSuppliersPage] = useState('');
  const [freeTrialSuppliersIndex, setFreeTrialSuppliersIndex] = useState(0);



  const handleTrialDateChange = (supplier_id: number, date_of_expiry: any, approved_date: any)=> {

      if(isFuture(new Date(date_of_expiry))){
        const free_trial_days = formatDistanceStrict(new Date(date_of_expiry), new Date(approved_date), {
          unit: 'day',
          roundingMethod: 'ceil'
        }).split(' ')[0]
        patchSupplierDetails(supplier_id, {free_trail_days : free_trial_days})
          .then(()=>{
            const to_update = document.getElementById(`remaining_days_${supplier_id}`);
            if(to_update)
            to_update.innerText = getRemaningDays(approved_date, free_trial_days)
          })
  }
  else{
    patchSupplierDetails(supplier_id, {free_trail_days : 0})
          .then(()=>{
            const to_update = document.getElementById(`remaining_days_${supplier_id}`);
            if(to_update)
            to_update.innerText = "0"
          })
  }
  }
    const getEndDate = (days: number, id : number, approved_date: any)=> { 
      const endDate = addDays(new Date(approved_date), days)

        return (
        <Form.Group controlId="formBasicDate">
          
          <Form.Control 
            type="date"
            placeholder="Select date"
            defaultValue={(new Date(endDate)).toISOString().substring(0, 10)}
            onBlur={(e) => handleTrialDateChange(id , e.target.value, approved_date)}
          />
        </Form.Group>)
    }

    const getRemaningDays = (approved_at: any, days: number) => {
      
      const endDate = addDays(new Date(approved_at), days) // date of exipry
      if (isFuture(endDate)) {
        const days_from_endDate = formatDistanceToNowStrict(new Date(endDate), {
          unit: 'day',
          roundingMethod: 'ceil'
        })  // days from today to date of expiry
        return days_from_endDate.split(' ')[0]
      }
      else return 0
    }

  const freeTrialSupplierColumns = [
    {
      dataField: 'id',
      text: 'Sr',
      sort: true,
      formatter: (cell: any, row: any, rowIndex: number) => rowIndex + freeTrialSuppliersIndex + 1
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
    },

    {
      dataField: 'account_approved_at',
      text: 'Free Trial Start Date',
      sort: true,
      formatter: (cell: any, row: any) => row && row.account_approved_at ? format(new Date(row?.account_approved_at), 'd.M.yyy') : '🤫'
    },
    {
      dataField: 'account_expiry',
      text: 'Free Trial End Date',
      sort: true,
      formatter: (cell: any, row: any) => getEndDate(row?.free_trail_days, row?.id, row?.account_approved_at)
    },
    {
      dataField: 'remaning_days',
      text: 'Trial Remaning Days',
      sort: true,
      formatter: (cell: any, row: any) => 
      <p id={`remaining_days_${row.id}`}>{getRemaningDays(row.account_approved_at, row.free_trail_days)}</p>  
    },

  ]

  const getFreeTrialSuppliers = () => {
    setFreeTrialSuppliersPage(null as any);
    setPrevFreeTrialSuppliersPage(null as any);
    if(freeTrialSuppliersPage || freeTrialSuppliersPage === ''){
      getFreeTrialList(freeTrialSuppliersPage)
        .then((res)=>{
          if(res.data.results.length > 0) {
            setFreeTrialSuppliers([...res.data.results] as any);
            if(res.data.next) setFreeTrialSuppliersPage(res.data.next.split('cursor=')[1].split('&')[0]);
            else  setFreeTrialSuppliersPage(null as any);

            if(res.data.previous) setPrevFreeTrialSuppliersPage(res.data.previous.split('cursor=')[1].split('&')[0]);
            else setPrevFreeTrialSuppliersPage(null as any);
          }
        })
    }
  }


  const getNextFreeTrialSuppliers = () => {
    if (freeTrialSuppliersPage || freeTrialSuppliersPage === '') {
      getFreeTrialList(freeTrialSuppliersPage)
        .then((res) => {
          if (res.data.results.length > 0) {
            setFreeTrialSuppliers([...res.data.results] as any);
            if (res.data.next) setFreeTrialSuppliers(res.data.next.split('cursor=')[1].split('&')[0]);
            else setFreeTrialSuppliers(null as any);
            if (res.data.previous) setPrevFreeTrialSuppliersPage(res.data.previous.split("cursor=")[1].split("&")[0]);
            else setPrevFreeTrialSuppliersPage(null as any);
          }
        })
    }
  }

  const getPrevFreeTrialSuppliers = () =>{
    if (prevFreeTrialSuppliersPage || prevFreeTrialSuppliersPage === '') {
      getFreeTrialList(prevFreeTrialSuppliersPage)
        .then((res) => {
          if(res.data.results.length > 0) {
            setFreeTrialSuppliers([...res.data.results] as any);
            if(res.data.next){
              setFreeTrialSuppliersPage(res.data.next.split('cursor=')[1].split('&')[0]);
            }
            else{
              setFreeTrialSuppliersPage(null as any);
            }

            if(res.data.previous){
              setPrevFreeTrialSuppliersPage(res.data.previous.split('cursor=')[1].split('&')[0]);
            }
            else{
              setPrevFreeTrialSuppliersPage(null as any);
            }
          }
        })
  }
};


  useEffect(() => {
    getFreeTrialSuppliers()
  }, []);

  return (
    <div id="free-trial-suppliers-list">
      <div className="d-flex justify-content-between py-4">
          <p>Free Trial Suppliers</p>
        </div>
        <Card>
          <Card.Body>
            <BootstrapTable
              keyField='id'
              data={freeTrialSuppliers}
              columns={freeTrialSupplierColumns}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {prevFreeTrialSuppliersPage !== null ? (
                <Button variant='light' size='sm' className="external-pagination-btn pagination-btn-prev mr-2" onClick={(e) => {
                  setFreeTrialSuppliersIndex((p) => p - 30);
                  getPrevFreeTrialSuppliers();
                }}>
                  Prev
                </Button>
              ) : null}
              {freeTrialSuppliersPage !== null ? (
                <Button
                  variant='light'
                  size='sm'
                  className="external-pagination-btn pagination-btn-next"
                  onClick={(e) => {
                    setFreeTrialSuppliersIndex(p => p + 30);
                    getFreeTrialSuppliers();
                  }}
                >
                  Next
                </Button>
              ) : null}
            </div>


            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {prevFreeTrialSuppliersPage !== null ? (
                <Button variant='light' size='sm' className="external-pagination-btn pagination-btn-prev mr-2" onClick={(e) => {
                  setFreeTrialSuppliersIndex((p) => p - 30);
                  getPrevFreeTrialSuppliers();
                }}>
                  Prev
                </Button>
              ) : null}
              {freeTrialSuppliersPage !== null ? (
                <Button
                  variant='light'
                  size='sm'
                  className="external-pagination-btn pagination-btn-next"
                  onClick={(e) => {
                    setFreeTrialSuppliersIndex(p => p + 30);
                    getNextFreeTrialSuppliers();
                  }}
                >
                  Next
                </Button>
              ) : null}
            </div>
          </Card.Body>
        </Card>


      </div>
  );
};

export default withTranslation('common')(SupplierList);