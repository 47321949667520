import SectionHeader from "../section-header/sectionHeader";
import EditUserDetail from "./editUserDetail";
import EditUserPassword from "./editUserPassword";
import { useTranslation } from "react-i18next";

const UserDetails = () => {
  const { t } = useTranslation(['common', 'userProfile']);
  return (
    <section style={{ background: "white", marginBottom: "50px" }}>
      <SectionHeader heading={t('User Details', {ns: 'userProfile'})} icon="user" />
      <div style={{ padding: "50px", display: "flex", flexWrap: "wrap" }}>
        <EditUserDetail />
        <EditUserPassword />
      </div>
    </section>
  );
};

export default UserDetails;
