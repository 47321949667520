import { createSlice } from '@reduxjs/toolkit'

export interface ProjectState {
  userProjects: any[];
}

const initialState: ProjectState = {
  userProjects: [],
}

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setUserProjects: (state: any, action) => {
      state.userProjects = action.payload;
    },
    addToUserProjects:  (state: any, action) => {
      state.userProjects = [action.payload, ...state.userProjects]
    },
    updateToUserProjects:  (state: any, action) => {
      state.userProjects = [
        action.payload,
        ...state.userProjects.filter((item: any) => item.id !== action.payload.id)
      ]
    },
    removeFromUserProjects: (state: any, action) => {
      state.userProjects = [...state.userProjects.filter((item: any) => item.id !== action.payload)]
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  addToUserProjects,
  setUserProjects,
  updateToUserProjects,
  removeFromUserProjects,
} = projectSlice.actions

export default projectSlice.reducer