import { AppThunk } from 'Redux/store';
import { isAxiosResponse } from "Services/index";
import { getPriceLists, patchPriceList, postPriceList } from 'Services/price-list';
import { addToUserPriceLists, setUserPriceLists, updateToUserPriceLists } from 'Redux/features/priceList';

export const fetchUserPriceLists = (): AppThunk => async dispatch => {
  const response = await getPriceLists();
  if (isAxiosResponse(response) && response.data.results.length > 0) {
    await dispatch(setUserPriceLists(response.data.results));
  }
}

export const createUpdatePriceList = (
  priceList: any,
  next: CallableFunction
) : AppThunk => async (dispatch, getState) => {
  const userPriceLists = getState().priceList.userPriceLists;
  let response, edit = false;
  if (
    userPriceLists.length > 0 &&
    priceList.id &&
    userPriceLists.find(item => item.id === priceList.id)
  ) {
    edit = true;
    const priceListID = priceList.id;
    delete priceList.id;
    response = await patchPriceList(priceListID, priceList);
  } else {
    response = await postPriceList(priceList);
  }
  if (isAxiosResponse(response)) {
    await dispatch(edit ? updateToUserPriceLists(response.data) : addToUserPriceLists(response.data));
    return next(true);
  }
}
