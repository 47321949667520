import { useState, useRef } from "react";
import { CONTACTS_ENUM } from "Constants/contacts";
import { SitesFilterTypes } from "Constants/sites";
import { format } from "date-fns";
import { useCallback } from "react";
import { isMobile, isBrowser } from "react-device-detect";
import editIcon from "../../assets/img/save_export/edit.svg";
import { Overlay, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "Redux/hooks";
import "./icons.scss";

function SiteTableRow({
  number,
  project,
  setActiveProject,
  handleEditProject,
  mobileFilter = 1,
}: any) {
  const { t } = useTranslation(["common"]);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);
  const [showEditTooltip, setShowEditTooltip] = useState(false);
  const targetEdit = useRef(null);

  const projectContactByType = useCallback(
    (type: number) => {
      let retVal = "";
      if (Object.keys(project).length > 0) {
        const contact = project.contacts.find(
          (item: any) => item.role === type
        );
        if (contact)
          retVal = `${contact.full_name.split(" ")[0]} ${contact.phone}`;
      }
      return retVal;
    },
    [project]
  );

  return (
    <>
      {isBrowser && (
        <tr
          style={{
            height: "59px",
            display: "grid",
            gridTemplateColumns: "0.3fr 1fr 2.2fr 1fr 1fr 1fr 0.6fr",
          }}
        >
          <td>
            <p className="para text-capitalize">{number}</p>
          </td>
          <td>
            <p className="para text-capitalize">{project.name}</p>
          </td>
          <td>
            <p className="para text-capitalize">
              {project.delivery_address_line1}, {project.delivery_city}
            </p>
          </td>
          <td>
            <p className="para text-capitalize">
              {projectContactByType(CONTACTS_ENUM.SITE_MANAGER)}
            </p>
          </td>
          <td>
            <p className="para text-capitalize">
              {projectContactByType(CONTACTS_ENUM.SITE_MAN)}
            </p>
          </td>
          <td>
            <p className="para">
              {project.next_delivery
                ? format(new Date(project.next_delivery), "d.M.yyy")
                : "-"}
            </p>
          </td>
          <td
            style={
              rightToLeft
                ? { paddingRight: "18px", borderRight: "2px solid #f3f3f3" }
                : { paddingLeft: "18px" }
            }
          >
            <button
              className="export__icons"
              onClick={() => {
                setActiveProject(project);
                handleEditProject();
              }}
              ref={targetEdit}
              onMouseEnter={() => setShowEditTooltip(true)}
              onMouseLeave={() => setShowEditTooltip(false)}
            >
              <img className="m__icon" src={editIcon} alt="edit icon" />
            </button>
            <Overlay
              target={targetEdit.current}
              show={showEditTooltip}
              placement="top"
            >
              {(props) => (
                <Tooltip id="overlay-example-4" {...props}>
                  {t("edit", { ns: "common" })}
                </Tooltip>
              )}
            </Overlay>
          </td>
        </tr>
      )}
      {isMobile && (
        <tr>
          <td>
            <p className="para">{number}</p>
          </td>
          <td>
            <p className="para text-capitalize">{project.name}</p>
          </td>
          <td>
            <p className="para text-capitalize">
              {mobileFilter === SitesFilterTypes.quanity ? (
                <>
                  <span className="bold">26 </span>orders (3 upcoming))
                </>
              ) : mobileFilter === SitesFilterTypes.delivery ? (
                <span className="bold">{format(new Date(), "d.m.yyyy")}</span>
              ) : (
                <>
                  <span className="bold">14.9 </span>Ton (26 orders)
                </>
              )}
            </p>
          </td>
        </tr>
      )}
    </>
  );
}

export default SiteTableRow;
