import React, {useEffect, useMemo, useState} from "react";
import { Stage, Layer, Shape, Circle, Line } from 'react-konva';
import { Html } from "react-konva-utils";
import { useAppSelector } from 'Redux/hooks';
import { calculateNextPoint, calculateSidePoints, calculateSidePointsSplitTwo } from 'Components/tool/utils';
import { externalStartDegrees, internalEndDegrees } from 'Constants/pile-constants';
import rebar_logo from "Assets/img/cages/horizontal_rebars.svg";
import diameter_cage from "Assets/img/cages/diameter_cage.svg";
import "./index.scss";

function SideStage({
  sideSpilt,
  canvasWidth,
  canvasHeight,
  shapeObj,
}: any) {
  const [sideDots, setSideDots] = useState([] as any);
  const [circleLine1Points, setcircleLine1Points] = useState([] as any);
  const [circleLine2Points, setcircleLine2Points] = useState([] as any);
  const [external, setExternal] = useState({ x: 0, y: 0 });
  const [middle, setMiddle] = useState({ x: 0, y: 0 });
  const [internal, setInternal] = useState({ x: 0, y: 0 });
  const rightToLeft = useAppSelector(state => state.root.rightToLeft);
  

  const center = useMemo(() => {
    const circleLeftOffset = 110;
    return {
      x: (canvasWidth-circleLeftOffset) / 2,
      y: canvasHeight / 2,
    };  
  }, [canvasWidth, canvasHeight]);
  const [innerRadius, setInnerRadius] = useState(0);
  const [outterRadius, setOutterRadius] = useState(0);

  useEffect(() => {

    const rescale_factor = (canvasWidth*0.5) / shapeObj.hole_diameter;
    const radius = (shapeObj.cage_diameter / 2)*rescale_factor;
    setInnerRadius(radius);
    const oRadius = (shapeObj.hole_diameter / 2)*rescale_factor;
    setOutterRadius(oRadius);


    const pad = 7;
    const buffer = 4;
    if (sideSpilt === 3) {
      const internalLinePoint = calculateNextPoint({ x: center.x, y: center.y }, internalEndDegrees, radius - pad);
      setcircleLine1Points([center.x - oRadius , internalLinePoint.y - buffer, internalLinePoint.x + 138, internalLinePoint.y - buffer]);
  
      const externalLinePoint = calculateNextPoint({ x: center.x, y: center.y }, externalStartDegrees, radius - pad);
      setcircleLine2Points([center.x - oRadius, externalLinePoint.y + buffer, externalLinePoint.x + 135, externalLinePoint.y + buffer]);

      const xApart = 100;
      const yApart = 12;
      setExternal({ x:  (canvasWidth - internalLinePoint.x - xApart), y: externalLinePoint.y - yApart });
      setMiddle({ x: (canvasWidth - internalLinePoint.x - xApart) , y: (internalLinePoint.y + externalLinePoint.y)/2 -(yApart/2) });
      setInternal({ x: (canvasWidth - internalLinePoint.x - xApart) , y: internalLinePoint.y });

      const pointsOfinnerCircle = calculateSidePoints(shapeObj, center.x, center.y, radius, pad);
      setSideDots(pointsOfinnerCircle);
    } else {
      const internalLinePoint = calculateNextPoint({ x: center.x, y: center.y }, 0, radius - pad);
      setcircleLine1Points([center.x - oRadius, internalLinePoint.y, internalLinePoint.x + 135, internalLinePoint.y]);

      const pointsOfinnerCircle = calculateSidePointsSplitTwo(shapeObj, center.x, center.y, radius, pad);
      setSideDots(pointsOfinnerCircle);

      const xApart = 92
      setExternal({ x: (canvasWidth - internalLinePoint.x - xApart) , y: internalLinePoint.y - 15 });
      setInternal({ x: (canvasWidth - internalLinePoint.x - xApart) , y: internalLinePoint.y + 2 });
    }


  }, [
    shapeObj,
    shapeObj.base_amount,
    shapeObj.hole_diameter,
    shapeObj.cage_diameter,
    sideSpilt,
    center.x,
    center.y,
  ]);
  
  return (
    <Stage width={canvasWidth} height={canvasHeight}>
      <Layer>
        <Shape
          sceneFunc={(context, shape) => {
            if (shapeObj && canvasHeight > 150 && canvasWidth > 150) {
              context.beginPath();
              context.arc(center.x, center.y, outterRadius, 0, 2 * Math.PI, 1.5 * Math.PI);
              context.fillStrokeShape(shape);
            }
          }}
          stroke="black"
          strokeWidth={2}
        />
        <Shape
          sceneFunc={(context, shape) => {
            if (shapeObj && canvasHeight > 150 && canvasWidth > 150) {
              context.beginPath();
              context.arc(center.x, center.y, innerRadius, 0, 2 * Math.PI, 1.5 * Math.PI);
              context.fillStrokeShape(shape);
            }
          }}
          stroke="black"
          strokeWidth={4}
        />
        {sideDots.map((dt: any, index: number) =>
          <Circle key={'sideDot-'+index} x={dt.x} y={dt.y} radius={4} fill="black" />
        )}
        {sideSpilt === 3 ? (
          <>
            <Line points={circleLine1Points} strokeWidth={1} stroke="grey" />
            <Line points={circleLine2Points} strokeWidth={1} stroke="grey" />
          </>
        ) : (
          <Line points={circleLine1Points} strokeWidth={1} stroke="grey" />
        )}
        
        {/* Side Stage External Measurments */}
        { shapeObj.external_amount && shapeObj.external_diameter.steel_diameter &&
          <Html
            divProps={{
              style: {
                position: "absolute",
                top: `${external.y - 2}px`,
                right: `0px`,
              },
            }}
          >
            <div style={{display: "flex", alignItems: "start", position:"relative", gap:"2px", justifyContent:(rightToLeft ? "flex-start" : "flex-end")}}>
              <p className="side_stage_text">
                {shapeObj.external_amount}
              </p>
              <img className="side_stage_logo_amount" src={rebar_logo} alt="" />
              <p className="side_stage_text">
                {shapeObj.external_diameter.steel_diameter}
              </p>
              <img className="side_stage_logo_diameter" src={diameter_cage} alt="" />
            </div>
          </Html>
        }


      { sideSpilt === 3 && shapeObj.middle_amount && shapeObj.middle_diameter.steel_diameter &&
          <Html
            divProps={{
              style: {
                position: "absolute",
                top: `${middle.y}px`,
                right: `0px`,
              },
            }}
          >
            <div style={{display: "flex", alignItems: "start", position:"relative", gap:"2px", justifyContent:(rightToLeft ? "flex-start" : "flex-end")}}>
              <p className="side_stage_text">
                {shapeObj.middle_amount}
              </p>
              <img className="side_stage_logo_amount" src={rebar_logo} alt="" />
              <p className="side_stage_text">
                {shapeObj.middle_diameter.steel_diameter}
              </p>
              <img className="side_stage_logo_diameter" src={diameter_cage} alt="" />
            </div>
          </Html>
        }


        {/* Side Stage Internal Measurments */}
        { shapeObj.internal_amount && shapeObj.internal_diameter.steel_diameter &&
          <Html
            divProps={{
              style: {
                position: "absolute",
                top: `${internal.y + 5}px`,
                right: `0px`,
              },
            }}
          >
            <div style={{display: "flex", alignItems: "start", position:"relative", gap:"2px", justifyContent:(rightToLeft ? "flex-start" : "flex-end")}}>
              <p className="side_stage_text">
                {shapeObj.internal_amount}
              </p>
              <img className="side_stage_logo_amount" src={rebar_logo} alt="" />
              <p className="side_stage_text">
                {shapeObj.internal_diameter.steel_diameter}
              </p>
              <img className="side_stage_logo_diameter" src={diameter_cage} alt="" />
            </div>
          </Html>
        }
      </Layer>
    </Stage>
  );
}

export default SideStage;