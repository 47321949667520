import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState, useRef } from "react";
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { patchUser, getProfileImg } from "Redux/middlwares/user";
import { Button } from "react-bootstrap";
import cameraImage from "../../../assets/img/camera.png";
import { useTranslation } from "react-i18next";
import "./editUserDetail.css";
import { postMediaFile } from "Services/media";

const EditUserDetail = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const user = useAppSelector((state) => state.user.userData);
  const userImg = useAppSelector((state) => state.user.profilePicture);
  const dispatch = useAppDispatch();
  const [disable, setDisable] = useState(true);
  const { t } = useTranslation(['common', 'userProfile']);
  const rightToLeft = useAppSelector(state => state.root.rightToLeft);
  const [uploadedImage,setUploadedImage] = useState("")
  const imageUploader = useRef(null);
  const [data, setData] = useState({
    first_name: user.first_name || "",
    last_name: user.last_name || "",
    phone: user.phone || "",
    job_title: user.job_title || "",
    profile_picture: user.profile_picture || "",
  });

  useEffect(() => {
    setData({
      first_name: user.first_name || "",
      last_name: user.last_name || "",
      phone: user.phone || "",
      job_title: user.job_title || "",
      profile_picture: user.profile_picture || "",
    });

  }, [user]);

  useEffect(() => {
    setUploadedImage(userImg);
  },[userImg])

  const editForm = () => {
    setDisable(!disable);
  };

  const handleChange = (e: any, key: any) => {
    setData({
      ...data,
      [key]: e.target.value,
    });
  };

  const submitForm = async (event: any) => {
    event.preventDefault();
    setIsSubmit(true);
    await dispatch(patchUser(user.id, data));
    setIsSubmit(false);
    setDisable(true);
  };

  const imageUpload = async (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      const reader = await new FileReader();
      reader.onload = (event: any) => {
        setUploadedImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }

    postMediaFile(file, user.first_name).then((res) => {
      dispatch(
        patchUser(user.id, {
          ...data,
          profile_picture: res.data.id,
        })
      ).then((res) => {
        dispatch(getProfileImg(res.data.profile_picture));
      }).catch((err) => console.log(err));
    }).catch((err)=>console.log(err))
  }

  return (
    <form className="profile-edit-form">
      <div className="image-section">
        <div
          className="profile-pic"
          onClick={() => imageUploader.current.click()}
        >
          <input
            id="photo-upload"
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            onChange={imageUpload}
            style={{ display: "none" }}
            ref={imageUploader}
          />
          <div className="image-background">
            {userImg ? (
              <img
                className="user-img"
                src={uploadedImage}
                alt=""
                style={{
                  width: "100%",
                  height: "auto",
                  margin: "0 auto",
                  position: "absolute",
                }}
              />
              ) : (
              <img src={cameraImage} className="camera" alt="camera" />
            )
          }
            <div className="edit-text">
              <h2 className="text">{t("edit",{ns:"common"})}</h2>
            </div>
          </div>
        </div>
        <p
          className="mt-2"
          style={{ fontWeight: 800, fontSize: "18px", color: "darkgray" }}
        >
          {t('Profile Pricture', {ns: 'userProfile'})}
        </p>
      </div>
      <div className="profile-info-section">
        <div>
          {disable && (
            <p
              onClick={editForm}
              className={rightToLeft ? "text-left" : "text-right"}
              style={{
                textDecoration: "underline",
                fontSize: "15px",
                cursor: "pointer",
                marginBottom: "8px",
                color: "darkgray",
              }}
            >
              {t('Edit Details', {ns: 'userProfile'})}{" "}
              <span
                className={rightToLeft ? "mr-3" : "ml-3"}
                style={{
                  background:
                    "linear-gradient(to top, #1F252E 0%, #374051 100%)",
                  padding: "7px 8px",
                  borderRadius: "50%",
                  color: "white",
                }}
              >
                <FontAwesomeIcon icon="pencil-alt"></FontAwesomeIcon>
              </span>
            </p>
          )}
        </div>
        <div
          className="d-sm-flex justify-content-sm-between"
          style={{ gap: 20 }}
        >
          <label
            style={{
              textTransform: "uppercase",
              margin: "5px",
              fontSize: "15px",
              width: "150px",
              alignSelf: "center",
              textAlign: "start",
            }}
          >
            {t('First Name', {ns: 'userProfile'})}
          </label>
          <input
            disabled={disable}
            onChange={(e) => handleChange(e, "first_name")}
            value={data.first_name}
            type="text"
            placeholder="Enter Your First Name"
            style={{
              margin: "5px",
              border: "0px",
              fontSize: "18px",
              padding: "5px 10px",
              flexGrow: 1,
            }}
            className="form-control custom-input"
          />
        </div>
        <div
          className="d-sm-flex justify-content-sm-between"
          style={{ gap: 20 }}
        >
          <label
            style={{
              textTransform: "uppercase",
              margin: "5px",
              fontSize: "15px",
              width: "150px",
              alignSelf: "center",
              textAlign: "start",
            }}
          >
            {t('Last Name', {ns: 'userProfile'})}
          </label>
          <input
            disabled={disable}
            onChange={(e) => handleChange(e, "last_name")}
            value={data.last_name}
            type="text"
            placeholder="Enter Your Last Name"
            style={{
              margin: "5px",
              border: "0px",
              fontSize: "18px",
              padding: "5px 10px",
              flexGrow: 1,
            }}
            className="form-control"
          />
        </div>
        <div
          className="d-sm-flex justify-content-sm-between"
          style={{ gap: 20 }}
        >
          <label
            style={{
              textTransform: "uppercase",
              margin: "5px",
              fontSize: "15px",
              width: "150px",
              alignSelf: "center",
              textAlign: "start",
            }}
          >
             {t('Phone', {ns: 'userProfile'})}
          </label>
          <input
            disabled={disable}
            onChange={(e) => handleChange(e, "phone")}
            value={data.phone}
            type="text"
            placeholder="Enter Your Phone Number"
            pattern="^0\d{1,2}-?\d{3}-?\d{4}$"
            style={
              data.phone && !(/^0\d{1,2}-?\d{3}-?\d{4}$/.test(data.phone)) ? {
                borderColor: '#CD2017',
                boxShadow: '0 0 0 0.2rem rgb(205 32 23 / 25%)',
                    margin: "5px",
                    border: "0px",
                    fontSize: "18px",
                    padding: "5px 10px",
                    flexGrow: 1,
              } : {
                    margin: "5px",
                    border: "0px",
                    fontSize: "18px",
                    padding: "5px 10px",
                    flexGrow: 1
                  }
            }
            title="Phone number must be 9 or 10 digits"
            className="form-control"
          />
        </div>
        <div
          className="d-sm-flex justify-content-sm-between"
          style={{ gap: 20 }}
        >
          <label
            style={{
              textTransform: "uppercase",
              margin: "5px",
              fontSize: "15px",
              width: "150px",
              alignSelf: "center",
              textAlign: "start",
            }}
          >
            {t('Job Title', {ns: 'userProfile'})}
          </label>
          <input
            disabled={disable}
            onChange={(e) => {
              handleChange(e, "job_title");
            }}
            value={data.job_title}
            type="text"
            placeholder="Enter Your Job Title"
            style={{
              margin: "5px",
              border: "0px",
              fontSize: "18px",
              padding: "5px 10px",
              flexGrow: 1,
            }}
            className="form-control"
          />
        </div>
        {!disable && (
          <div style={{ textAlign: "right" }}>
            <Button
              variant="form"
              style={{
                margin: "5px",
                padding: "5px 20px",
                width: "fit-content",
              }}
              onClick={editForm}
            >
              {t('Cancel', {ns: 'userProfile'})}
            </Button>
            <button
              disabled={isSubmit}
              type="submit"
              className="btn form__btn__black"
              onClick={submitForm}
              style={{
                margin: "5px",
                padding: "5px 20px",
                width: "fit-content",
              }}
            >
              {isSubmit ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                t('submit', {ns: 'common'})
              )}
            </button>
          </div>
        )}
      </div>
    </form>
  );
};

export default EditUserDetail;
