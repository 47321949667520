import React, { useState, useMemo, useEffect } from "react";
import CustomerLayout from "../../layouts/customer";
import OrderDetails from "./order-details/index";
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { commaSeparated } from "Constants/general";
import ImagePreview from "../new-order/tool-preview";
import CreatorDetails from "./creator-details";
import PrintPopUp from "./printing-popups/index";
import TicketPdfPreviewModal from "./ticket-pdf-modal/index";
import SupplierPopup from "./supplier-account-approval";
import SupplierPopups from "./supplier-onboarding-poups";
import { format, addMonths} from "date-fns";
import { fetchSupplierStatus } from "Redux/middlwares/suppliers";
import { setWelcomePopup } from "Redux/middlwares/suppliers";
import { fetchSteelTypes } from "Redux/middlwares/steelType";
import { STATUS_CODES, STATUS, PACKAGE, PACKAGE_STATUS } from "Constants/ticket-creator";
import { PRINTER_TYPE } from "Constants/ticket-creator";

const TicketCreator = () => {
  const dispatch = useAppDispatch();
  const supplier_status = useAppSelector(
    (state) => state.supplier.supplierStatus
  );

  const currentOrderRebars = useAppSelector(
    (state) => state.supplier.ticketOrderDetails
  );

  const currentOrderNets = useAppSelector(
    (state) => state.supplier.ticketOrderNets
  );

  const currentOrderPiles = useAppSelector(
    (state) => state.supplier.ticketOrderPiles
  );

  const steelTypes = useAppSelector((state) => state.steelType.steelTypes);
  const userData = useAppSelector((state) => state.user.userData);

  const [imagePreviewShow, setImagePreviewShow] = useState(false);
  const [imagePreview, setImagePreview] = useState({});
  const [selectedRowCount, setSelectedRowCount] = useState([]);
  const [showPage, setShowPage] = useState(false);

  const [printPopUpShow, setPrintPopUpShow] = useState(false);
  const [ticketPdfPreviewShow, setTicketPdfPreviewShow] = useState(false);
  const [ticketLoader, setTicketLoader] = useState(false);
  const [orderNo, setOrderNo] = useState("");

  //PrinterTypes => 0:Sticky Printer || 1: A4 Printer 
  const [printerType, setPrinterType] = useState(PRINTER_TYPE.A4Printer);

  // Supplier Onboarding Popups
  const [showApprovalPopup, setShowApprovalPopup] = useState(true);
  const [inReview, setInReview] = useState(false);
  const [packageExpire, setpackageExpire] = useState(false);
  const [trialExpire, settrialExpire] = useState(false);
  const [noData, setNoData] = useState(false);
  
  useEffect(() => {
    async function setStates() {
      await dispatch(fetchSupplierStatus());
    }
    setStates();
  }, []);
  
  useEffect(() => {
    steelTypes.length === 0 && dispatch(fetchSteelTypes());
  }, [steelTypes, dispatch]);

  useEffect(() => {
    handlePopupsToDisplay();
  }, [supplier_status]);

  const handlePopupsToDisplay = async () => {
    const { status, package_status, welcome_popup } = supplier_status;
    const user_package = await supplier_status.package;
    setShowApprovalPopup(!welcome_popup);

    if ((await supplier_status) === STATUS_CODES.NOT_FOUND) {
      setNoData(true);
    }
    // In Review or Suspend
    else if (status === STATUS.IN_REVIEW || status === STATUS.SUSPEND) {
      setInReview(true);
    }
    // Package is ceased
    else if (package_status === PACKAGE_STATUS.CEASED) {
      // Free Trial
      if (user_package === PACKAGE.FREE_TRIAL) {
        settrialExpire(true);
      }
      // Limited
      else if (user_package === PACKAGE.LIMITED) {
        setpackageExpire(true);
      }
    } else if (package_status === PACKAGE_STATUS.OPERATIONAL && status === STATUS.APPROVED) {
      setShowPage(true);
    }
  };

  const getRenewalDate = (approved_date: any)=> { 
    const renewal_date = addMonths(new Date(approved_date), 1)
    return format(new Date(renewal_date), 'M/d/yyy')
  }

  const rebarUnits = useMemo(
    () =>
      currentOrderRebars.length > 0
        ? currentOrderRebars
            .reduce((pre, curr) => pre + curr.quantity, 0)
            .toString()
            .replace(commaSeparated, ",")
        : 0,
    [currentOrderRebars]
  );

  const rebarTons = useMemo(
    () =>
      currentOrderRebars.length > 0
        ? (
            currentOrderRebars.reduce(
              (pre, curr) => pre + curr.tool.unit_weight * curr.quantity,
              0
            ) / 1000
          )
            .toFixed(2)
            .toString()
            .replace(commaSeparated, ",")
        : "0",
    [currentOrderRebars]
  );

  const rebarPrice = useMemo(
    () =>
      currentOrderRebars.length > 0
        ? currentOrderRebars
            .reduce(
              (pre, curr) =>
                pre +
                parseFloat(curr.unit_price ? curr.unit_price : 0) *
                  curr.quantity,
              0
            )
            .toFixed(2)
            .toString()
            .replace(commaSeparated, ",")
        : "0",
    [currentOrderRebars]
  );

  const netUnits = useMemo(
    () =>
      currentOrderNets.length > 0
        ? currentOrderNets
            .reduce((pre, curr) => pre + curr.quantity, 0)
            .toString()
            .replace(commaSeparated, ",")
        : 0,
    [currentOrderNets]
  );

  const netTons = useMemo(
    () =>
      currentOrderNets.length > 0
        ? (
            currentOrderNets.reduce(
              (pre, curr) => pre + curr.tool.unit_weight * curr.quantity,
              0
            ) / 1000
          )
            .toFixed(2)
            .toString()
            .replace(commaSeparated, ",")
        : "0",
    [currentOrderNets]
  );

  const pileUnits = useMemo(
    () =>
      currentOrderPiles.length > 0
        ? currentOrderPiles
            .reduce((pre, curr) => pre + curr.quantity, 0)
            .toString()
            .replace(commaSeparated, ",")
        : 0,
    [currentOrderPiles]
  );

  const pileTons = useMemo(
    () =>
    currentOrderPiles.length > 0
        ? (
          currentOrderPiles.reduce(
              (pre, curr) => pre + curr.tool.unit_weight * curr.quantity,
              0
            ) / 1000
          )
            .toFixed(2)
            .toString()
            .replace(commaSeparated, ",")
        : "0",
    [currentOrderPiles]
  );

  const netPrice = useMemo(
    () =>
      currentOrderNets.length > 0
        ? currentOrderNets
            .reduce(
              (pre, curr) =>
                pre +
                parseFloat(curr.unit_price ? curr.unit_price : 0) *
                  curr.quantity,
              0
            )
            .toFixed(2)
            .toString()
            .replace(commaSeparated, ",")
        : "0",
    [currentOrderNets]
  );

  useEffect(() => {
    const initialRowCount = [];

    currentOrderRebars.forEach(item => {
      initialRowCount.push({ itemId: item.id, countt: 1 });
    });

    currentOrderNets.forEach(item => {
      initialRowCount.push({ itemId: item.id, countt: 1 });
    });

    currentOrderPiles.forEach(item => {
      initialRowCount.push({ itemId: item.id, countt: 1 });
    });

      setSelectedRowCount(initialRowCount);
  }, [currentOrderRebars, currentOrderNets, currentOrderPiles]);

  const handleImagePreview = (preview: any) => {
    setImagePreview(preview);
    setImagePreviewShow(true);
  };


  if (!showPage) {
    return (
      <>
        <CustomerLayout>
          {(!packageExpire && !trialExpire && !noData && !inReview) && (
            <CreatorDetails
              supplierStatus={supplier_status}
              setOrder={setOrderNo}
              inReview={inReview}
              packageExpire={packageExpire}
              trialExpire={trialExpire}
              noData={noData}
            />
          )}
          {(inReview || packageExpire || trialExpire || noData) && (
            <SupplierPopups
              inReview={inReview}
              packageExpire={packageExpire}
              trialExpire={trialExpire}
              noData={noData}
              supplier_renewal_date={
                supplier_status.package_renewed_at &&
                getRenewalDate(supplier_status.package_renewed_at)
              }
            />
          )}
        </CustomerLayout>
      </>
    );
  } else {
    return (
      <CustomerLayout>
        <CreatorDetails
          supplierStatus={supplier_status}
          setOrder={setOrderNo}
          inReview={inReview}
          packageExpire={packageExpire}
          trialExpire={trialExpire}
          noData={noData}
        />
        {(currentOrderRebars.length > 0 || currentOrderNets.length > 0 || currentOrderPiles.length > 0) && (
          <OrderDetails
            handleImagePreview={handleImagePreview}
            rebarUnits={rebarUnits}
            rebarTons={rebarTons}
            rebarPrice={rebarPrice}
            selectedRowCount={selectedRowCount}
            setSelectedRowCount={setSelectedRowCount}
            setPrintPopUpShow={setPrintPopUpShow}
            netUnits={netUnits}
            netTons={netTons}
            netPrice={netPrice}
            pileUnits={pileUnits}
            pileTons={pileTons}
          />
        )}
        <TicketPdfPreviewModal
          show={ticketPdfPreviewShow}
          onHide={() => setTicketPdfPreviewShow(false)}
          setTicketLoader={setTicketLoader}
          ticketLoader={ticketLoader}
          selectedRowCount={selectedRowCount}
          orderNo={orderNo}
          dialogClassName={"ticket-modal-document"}
        />
        <ImagePreview
          show={imagePreviewShow}
          onHide={() => {
            setImagePreviewShow(false);
            setImagePreview({});
          }}
          imageSrc={imagePreview ? (imagePreview as any).file_field : ""}
          alt={imagePreview ? (imagePreview as any).alt_text : ""}
        />
        {/* Supplier Account Approval Popup  */}
        <SupplierPopup
          show={showApprovalPopup}
          onHide={() => {
            setShowApprovalPopup(false);
          }}
          onSubmit={() => {
            dispatch(setWelcomePopup());
          }}
        />
        <PrintPopUp
          show={printPopUpShow}
          onHide={() => {
            setPrintPopUpShow(false);
            setPrinterType(PRINTER_TYPE.A4Printer);
          }}
          setTicketLoader={setTicketLoader}
          setTicketPdfPreviewShow={setTicketPdfPreviewShow}
          setPrinterType={setPrinterType}
          printerType={printerType}
          dialogClassName={"ticket__print_modal_width"}
        />
      </CustomerLayout>
    );
  }
};

export default TicketCreator;