import {
  addRebarToCurrentOrder,
  setDefaultRebars,
  setRebarFilters,
  setRebarPage,
  updateCurrentOrderRebar,
  removeCurrentOrderRebar,

} from "Redux/features/rebar";
import { rebarStore } from "Redux/offline";
import { AppThunk } from 'Redux/store';
import { isAxiosResponse } from "Services/index";
import { postOrderItem, updateOrderItem, updateRebarSketch, getItemsCount } from "Services/order";
import { getDefaultRebars, getFilters } from "Services/rebar";
import { REBAR_SUBCLASS_TYPE } from "Constants/rebar-constants";
import { getMedia } from "Services/media";
// import { MediaHost } from "Constants/general";

//NOTE: row_number is 0 when creating new item and > 0 when duplicating item
export const createRebar = (rebar: any, orderItem: any, next = (val: boolean) => {}, row_num = 0, orderId = 0): AppThunk => async (dispatch, getState) => {
  return new Promise(async (resolve, rejects) => {
    const user = getState().user.userData;
    if (user) {
      getItemsCount(REBAR_SUBCLASS_TYPE, orderId).then(
        async (result)=>{
          orderItem.row_number = row_num > 0 ? row_num : result.data.row_number + 1;
          let res;
          if (orderId > 0){
            res = await postOrderItem({
              ...orderItem, 
              order: orderId,
              tool: rebar,
              user: user.id,
            })
          }
          else{
            res = await postOrderItem({
              ...orderItem, 
              tool: rebar,
              user: user.id,
            })
          }
          
          if (isAxiosResponse(res)) {
            const previewID = res.data.tool.preview_image;
            const resMe = await getMedia(previewID);
            if (isAxiosResponse(resMe)) {
              const preview = {...resMe.data};
              // preview.file_field = preview.file_field.replace(MediaHost, '');
              const orderIt = {
                ...res.data,
                tool: {
                  ...res.data.tool,
                  preview_image: preview,
                }
              };
      
              const rebars = getState().rebar.currentOrderRebars
              if(rebars.length === 0 || res.data.row_number - rebars.slice(-1)[0].row_number === 1 || res.data.row_number < rebars.slice(-1)[0].row_number){
                await dispatch(addRebarToCurrentOrder(orderIt));
              }
              resolve();
              return next(true);
            }
          }
        }
      )
    } else {
      const rebarData = {
        ...orderItem,
        tool: rebar,
      };
      const previewID = rebarData.tool.preview_image;
      const resMe = await getMedia(previewID);
      if (isAxiosResponse(resMe)) {
        const preview = {...resMe.data};
        // preview.file_field = preview.file_field.replace(MediaHost, '');
        const orderIt = {
          ...rebarData,
          tool: {
            ...rebarData.tool,
            preview_image: preview,
          }
        };
        rebarStore.setItem(orderIt);
        await dispatch(addRebarToCurrentOrder(orderIt));
      }
    }
  })

}

export const updateRebar = (
  orderItemID: number,
  rebar: any,
  orderItem: any,
  next = (val: boolean) => {}
): AppThunk => async (dispatch, getState) => {
  const user = getState().user.userData;
  if (user) {
    const res = await updateOrderItem(orderItemID, {
      ...orderItem,
      tool: rebar,
    });
    if (isAxiosResponse(res)) {
      const previewID = res.data.tool.preview_image;
      const resMe = await getMedia(previewID);
      if (isAxiosResponse(resMe)) {
        const preview = {...resMe.data};
        // preview.file_field = preview.file_field.replace(MediaHost, '');
        const orderIt = {
          ...res.data,
          tool: {
            ...res.data.tool,
            preview_image: preview,
          }
        };
        await dispatch(updateCurrentOrderRebar(orderIt));
        return next(true);
      }
    }
  }
}

export const fetchDefaultsRebars = (): AppThunk => async (dispatch, getState) => {
  const rebarPage = getState().rebar.page;
  if (rebarPage || rebarPage === '') {
    const res = await getDefaultRebars(rebarPage);
    if (res.results.length > 0) {
      const tools = getState().rebar.defaultRebars;
      const page = res.next ? res.next.split('cursor=')[1].split('&')[0] : false;
      await dispatch(setRebarPage(page));
      await dispatch(setDefaultRebars([...tools, ...res.results.sort((a: any, b: any) => a.id - b.id)]));
    }
  } else {
    await dispatch(setRebarPage(''));
  }
}

export const fetchRebarFilters = (): AppThunk => async dispatch => {
  const response = await getFilters();
  if (isAxiosResponse(response)) {
    await dispatch(setRebarFilters(response.data));
  }
}

export const changeRebarSketch = (rebarData: any, orderId:number, orderItem:any): AppThunk => async (dispatch,getState) => {
  const user = getState().user.userData;
  const res = await updateRebarSketch(
    {
      ...orderItem,
      tool:rebarData,
      user: user.id,
    },
    orderId
  );
  if (isAxiosResponse(res)) {
    const previewID = res.data.tool.preview_image;
    const resMe = await getMedia(previewID);
    if (isAxiosResponse(resMe)) {
      const preview = { ...resMe.data };
      // preview.file_field = preview.file_field.replace(MediaHost, '');
      const orderIt = {
        ...res.data,
        tool: {
          ...res.data.tool,
          preview_image: preview,
        },
      };
      await dispatch(removeCurrentOrderRebar(orderId));
      await dispatch(addRebarToCurrentOrder(orderIt));
    }
  }
};

