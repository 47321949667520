import React from "react";
import emtpySet from "Assets/img/icon/empty-set-mathematical-symbol.png";
import email from "Assets/img/icon/email.png";
import { useAppSelector } from "Redux/hooks";

import { useSortable } from "@dnd-kit/sortable";

import "../index.styled.scss";

const StaticNetsCard = (props) => {
  const { item, index, edit } = props;

  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  const { isDragging } = useSortable({
    id: props.id,
  });

  return (
    <div
      className={"cardItem static-table_row " + (edit ? " active" : "")}
      key={"defaultNets" + index}
      style={
        !isDragging
          ? {
              cursor: "grabbing",
              position: "relative",
            }
          : { cursor: "grab", position: "relative" }
      }
    >
      <div className="d-flex flex-column">
        {/* X/Y Size */}
        <div className="d-flex justify-content-center border__bottom">
          <p className="card__text">
            {item.x_length}/{item.y_length}
          </p>
        </div>
        {/* Two types of cards based on the standard/special */}
        {item.x_gap === item.y_gap &&
        item.x_diameter.id === item.y_diameter.id ? (
          <div className="d-flex flex-column justify-content-center">
            {/* first row gap */}
            <div className="standard__pad d-flex justify-content-center align-items-center border__bottom">
              <div className={rightToLeft ? "ml-2" : "mr-2"}>
                <img src={emtpySet} alt="empty set icon" />
              </div>
              <p>{item.x_diameter.steel_diameter}</p>
            </div>
            {/* second row diameter */}
            <div className="standard__pad d-flex justify-content-center align-items-center">
              <div className={rightToLeft ? "ml-2" : "mr-2"}>
                <img src={email} alt="email icon" />
              </div>
              <p>{item.x_gap}</p>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column justify-content-center">
            {/* first row */}
            <div className="d-flex justify-content-center align-items-center border__bottom">
              {/* gap */}
              <div className="special__pad d-flex justify-content-center align-items-center">
                <div className={rightToLeft ? "ml-2" : "mr-2"}>
                  <img src={emtpySet} alt="empty set icon" />
                </div>
                <p>{item.x_diameter.steel_diameter}</p>
              </div>
              {/* diameter */}
              <div className="special__pad d-flex justify-content-center align-items-center">
                <div className={rightToLeft ? "ml-2" : "mr-2"}>
                  <img src={email} alt="empail icon" />
                </div>
                <p>{item.x_gap}</p>
              </div>
            </div>
            {/* second row */}
            <div className="d-flex justify-content-center align-items-center">
              {/* gap */}
              <div className="special__pad d-flex justify-content-center align-items-center">
                <div className={rightToLeft ? "ml-2" : "mr-2"}>
                  <img src={emtpySet} alt="empty set icon" />
                </div>
                <p>{item.y_diameter.steel_diameter}</p>
              </div>
              {/* diameter */}
              <div className="special__pad d-flex justify-content-center align-items-center">
                <div className={rightToLeft ? "ml-2" : "mr-2"}>
                  <img src={email} alt="email icon" />
                </div>
                <p>{item.y_gap}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StaticNetsCard;
