import NewOrder from "Components/new-order";
import { useEffect, useState } from "react";
import {
  useParams
} from "react-router-dom";
// import { setShowPrice } from "Redux/features/order";
import { useAppDispatch } from "Redux/hooks";
import { fetchOrder } from "Services/order";
import { getCurrentOrderRebars, getCurrentOrderNets, getCurrentOrderPiles } from "Redux/middlwares/order";

const UpdateOrder = () => {
  const { id } = (useParams() as any);
  const dispatch = useAppDispatch();
  const [order, setOrder] = useState();
  const [isLoading, setIsLoader] = useState(true);

  useEffect(() => {
    if (id) {
      fetchOrder(id)
      .then(res => {
        if (res.data) {
          setIsLoader(false);
          const orderData = res.data;
          setOrder(orderData);
          // dispatch(setShowPrice(true));
        }
      }).catch(err => {
        setIsLoader(false);
      });
      dispatch(getCurrentOrderRebars(true,id))
      dispatch(getCurrentOrderNets(true, id));
      dispatch(getCurrentOrderPiles(true, id));
    }
  }, [id, dispatch]);


  const loaderContainer = {
    display: "flex",
    justifyContent: "center",
    height: "100vh",
    alignItems: "center",
  };

  return (
    <>
      {isLoading && (
        <div style={loaderContainer}>
          <div
            className="spinner-grow"
            role="status"
            style={{ width: "5rem", height: "5rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {!isLoading && order ? <NewOrder edit={true} orderData={order} /> : <></>}
    </>
  );
};

export default UpdateOrder;