import editIcon from "../../../assets/img/order-details/edit.svg";
import deleteIcon from "../../../assets/img/order-details/delete.svg";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { useState, useEffect, useRef } from "react";
import { getMedia, postMediaFile } from "Services/media";
import { updateBusiness } from "Redux/middlwares/business";
import "./index.scss";

const CompanyDetails = (props) => {
  const {
    handleEditCompany,
    setActiveCompanyEdit,
    setCompanyToDelete,
    setDeleteMessageShow,
    businessData,
  } = props;

  const { t } = useTranslation(["common", "userProfile", "newOrder"]);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  const userRole = ["", "OTHER", "EMPLOYEE", "OWNER", "SERVICE_PROVIDER"];

  const dispatch = useAppDispatch();

  const [logo, setLogo] = useState("");
  const logoUploader = useRef(null);

  useEffect(() => {
    if (businessData.logo) {
      getMedia(businessData.logo)
        .then((res) => {
          setLogo(res.data.file_field);
        })
        .catch((err) => console.log(err));
    } else {
      setLogo("");
    }
  }, [businessData]);

  const selectCompanytoEdit = () => {
    setActiveCompanyEdit(props.businessData);
    handleEditCompany();
  }

  const logoUploadData = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (file) {
      const reader = await new FileReader();
      reader.onload = (event: any) => {
        setLogo(event.target.result);
      };
      reader.readAsDataURL(file);

      postMediaFile(file)
        .then((res) => {
          const newBusinessData = { ...businessData, logo: res.data.id };
          const callbackEdit = () => {};
          dispatch(updateBusiness(businessData.id, newBusinessData,callbackEdit));
        })
        .catch((err) => console.log(err));
    }
  };

  
  const DeleteCompany = () => {
    setCompanyToDelete(props.businessData);
    setDeleteMessageShow(true);
  }

  return (
    <>
      <div style={{ flexGrow: 1 }}>
        <hr className="mb-4" style={{ width: "92%" }}></hr>
        <div className="detailss" style={{ background: "transparent" }}>
          <div className="detail-section">
            <div className="detail-item title">
              <p className="label bold-text title">{props.businessData.name}</p>
              <img
                className="icon-btn"
                src={editIcon}
                alt="edit Icon"
                onClick={selectCompanytoEdit}
              />
              <img
                className="icon-btn"
                src={deleteIcon}
                alt="edit Icon"
                onClick={DeleteCompany}
              />
            </div>
            <div className="detail-item logo-detail">
              <p className="label">
                {t("company logo", { ns: "userProfile" })}
              </p>
              <div className="company-logo" onClick={() => logoUploader.current.click()} >
              <input
              id="logo-upload"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              onChange={logoUploadData}
              style={{ display: "none" }}
              ref={logoUploader}
            />
                {logo ? (
                  <img
                    src={logo}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                      position: "absolute",
                      margin: "0 auto",
                    }}
                  />
                ) : (
                  <p className="underlined">
                    {t("add company logo", { ns: "newOrder" })}
                  </p>
                )}
                <div className="edit-text">
                  <h2 className="text">
                    {t("edit logo", { ns: "newOrder" })}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="detail-section">
            <div className="detail-item title">
              <p className="label bold-text title">
                {t("details", { ns: "userProfile" })}
              </p>
            </div>
            <div className="detail-item">
              <p className="label">{t("BN Number", { ns: "userProfile" })}</p>
              <p className="value">{businessData.bn_number}</p>
            </div>
            <div className="detail-item">
              <p className="label">{t("Phone", { ns: "userProfile" })}</p>
              <p className="value">{businessData.phone}</p>
            </div>
            <div className="detail-item">
              <p className="label">{t("Mail", { ns: "userProfile" })}</p>
              <p className="value">{businessData.mail}</p>
            </div>
          </div>
          <div className="detail-section">
            <div className="detail-item title">
              <p className="label bold-text title">
                {t("location", { ns: "userProfile" })}
              </p>
            </div>
            <div className="detail-item">
              <p className="label">{t("City", { ns: "userProfile" })}</p>
              <p className="value">{businessData.city}</p>
            </div>
            <div className="detail-item">
              <p className="label">{t("street", { ns: "userProfile" })}</p>
              <p className="value">{businessData.address}</p>
            </div>
            <div className="detail-item">
              <p className="label">{t("position", { ns: "userProfile" })}</p>
              <p className="value">{t(userRole[businessData.user_role], { ns: "newOrder" })}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;
