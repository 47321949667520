import { useTranslation } from "react-i18next";
import deleteIcon from "../../../assets/img/save_export/delete.svg";
import WarningModal from "./../../custom-modal/index";
import { timeout_secs } from "Constants/alert-modal";
import { removeSupplierPriceList } from "Services/suppliers";
import toast from "react-hot-toast"

function SupplierPricingDeleteModal(props) {
  const {
    onHide,
    supplierList,
    getSupplierPriceList,
  } = props;
  const { t } = useTranslation(["common", "warning"]);

  const deleteOrderItems = () => {
    removeSupplierPriceList(supplierList.id)
      .then((result) => {
        if (result) {
          onHide();
          toast((t("Supplier Deleted Successfully!")), {
            icon: <img src={deleteIcon} alt="alert icon" />,
          });
          getSupplierPriceList();
        } else {
          toast((t("delete failed", { ns: "warning" })), {
            icon: <img src={deleteIcon} alt="alert icon" />,
          });
          onHide();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const btnValues = [
    {
      text: t("dont delete", { ns: "common" }),
      btnClass: "custom__btn-secondary",
    },
    {
      text: "Delete Supplier",
      btnClass: "custom__btn-primary",
    },
  ];

  const btnFunctions = {
    primary: deleteOrderItems,
    secondary: onHide,
  };

  return (
    <WarningModal
      show={props.show}
      onHide={props.onHide}
      dialogClassName={"modal_width"}
      btnFunctions={btnFunctions}
      btnValues={btnValues}
      modalColor={"#fff"}
    >
      <div className="delete__title pb-md-4 pb-2">
        <p className="mb-0">{t("delete supplier surety", { ns: "warning" })}</p>
      </div>
    </WarningModal>
  );
}

export default SupplierPricingDeleteModal;
