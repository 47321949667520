export const PILE_SUBCLASS_TYPE = 'pile';

export const pileDiametersOptions = [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 14, 16];
export const pileWireDiametersOptions = [6, 7.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 14, 16];
export const pileSidesDiameterOptions = [12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36];
export const pileGamaAmountOptions = [1,2,3,4,5];

export const pileGapOptions = [5,10,15,20,25];

export const baseAmountMin = 2;
export const externalStartDegrees = 330;
export const externalEndDegrees = 210;
export const internalStartDegrees = 140;
export const internalEndDegrees = 40;

export const pilesWithoutPrice = {
  gridTemplateColumns: "4% 5% 12% 12% 37% 4% 8% 18%",
};

export const pilesTicketsWithoutPrice = {
  gridTemplateColumns: "7% 4% 5% 12% 12% 33% 4% 6% 17%",
};

export const pilesWithPrice = {
  gridTemplateColumns: '2.5fr 1.66fr 1.66fr 1.66fr 2.5fr 2.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr',
};

export const pilesTicketsWithPrice = {
  gridTemplateColumns: '2.5fr 1.66fr 1.66fr 1.66fr 2.5fr 2.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr',
};

export const lastPilesWithoutPrice = {
  gridTemplateColumns: "1fr 2fr 2fr 7fr 2fr 8% 18%",
};

export const lastTicketsPilesWithoutPrice = {
  gridTemplateColumns: "7% 4% 5% 12% 12% 33% 4% 6% 17%",
};

export const lastPilesWithPrice = {
  gridTemplateColumns: '2.5fr 1.66fr 1.66fr 1.66fr 2.5fr 2.5fr 1.5fr 3fr 3fr',
};

export const lastTicketsPilesWithPrice = {
  gridTemplateColumns: '2.5fr 1.66fr 1.66fr 1.66fr 2.5fr 2.5fr 1.5fr 3fr 3fr',
};


export const pileErrorTypes = {
  MIN_D : 1,
  MAX_D : 2,
  DIAMETER_RATIO: 3,

  REBAR_AMOUNT_CAGE: 4,
  MIN_REBAR_AMOUNT: 5,
  MAX_REBAR_AMOUNT: 6,

  MIN_L1: 7,
  MAX_L1: 8,

  MIN_L2: 9,
  MAX_L2: 10,

  MIN_L3: 11,
  MAX_L3: 12,

  MIN_TOTAL_LEN: 13,
  MAX_TOTAL_LEN: 14,
  RATIO_TOTAL_LEN: 15,

  QUANTITY: 16,
  
  MIN_INTERNAL_AMOUNT: 17,
  MIN_MIDDLE_AMOUNT: 18,
  MIN_EXTERNAL_AMOUNT: 19,
};


export const D_MIN = 30;
export const D_MAX = 150;
export const REBAR_MIN_AMOUNT = 4;
export const L1_MIN = 5;
export const L1_MAX = 200;
export const L2_MIN = 50;
export const L2_MAX = 2355;
export const L3_MIN = 50;
export const L3_MAX = 2355;
export const CAGE_LEN_MIN = 250;
export const CAGE_LEN_MAX = 2400;

export const EarTypes = ["cardinal", "internal", "קרדינלי", "פנימי"];


export const pileSidebarInput = {
  CAGE_NAME: 1,

  // SIDE
  INTERNAL_AMOUNT: 2,
  INTERNAL_DIAMETER: 3,
  MIDDLE_AMOUNT: 4,
  MIDDLE_DIAMETER: 5,
  EXTERNAL_AMOUNT: 6,
  EXTERNAL_DIAMETER: 7,
  
  // CONCRETE CAGE
  CAGE_DIAMETER: 8,
  HOLE_DIAMETER: 9,
  
  // HORIZONTAL REBARS
  BASE_AMOUNT: 10,
  BASE_DIAMETER: 11,
  LENGTH_STEEL_TYPE: 12,

  // LENGTHS AND GAPS
  L1 :13,
  L2 :14,
  L3 :15,
  GAP_1 :16,
  GAP_2 :17,

  // SPIRAL
  SPIRAL_WIRE_DIAMETER: 18,
  CAGE_STEEL_TYPE: 19,

  // EAR
  EAR_LENGTH: 20,

  //RINGS
  RING_QUANTITY: 21,
  RING_DIAMETER: 22,

  // GAMMA PIPES
  GAMMA_QUANTITY: 23,
  GAMMA_DIAMETER: 24,

  NOTE: 25,
  QUANTITY: 26,
};


export const CAGE_DIAMETER_ERRORS = [3, 4, 6, 15];
export const HOLE_DIAMETER_ERRORS = [1, 2, 3];
export const BASE_AMOUNT_ERRORS = [4, 5, 6];

export const polygonSteelType = 9;
export const roundSteelType = 3;

export const DEFAULT_EXTERNAL_AMOUNT = 6;
export const DEFAULT_MIDDLE_AMOUNT = 2;
export const DEFAULT_INTERNAL_AMOUNT = 4;
export const DEFAULT_SIDE_DIAMETER = 12;