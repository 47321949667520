
import { fetchUser } from "Redux/middlwares/user";
import Request, { isAxiosResponse } from "./index";

export const login = (credentials: {email: string, password: string}) => {
  return Request.post('token/', credentials)
  .then((res) => {
    if (isAxiosResponse(res)) {
      localStorage.setItem('access_token', res.data.access);
      localStorage.setItem('refresh_token', res.data.refresh);
    }
  }).catch((err) => console.log(err));
}

export const signup = async (signupDetails: {
  first_name: string,
  last_name: string,
  job_title: string,
  email: string,
  password: string,
  phone: string,
  terms_agreement: boolean,
  news_promos: boolean
}) => {
  try{
    return await Request.post('signup/', signupDetails);
  }
  catch(err: any) {
    return err.response;
  }
}

export const verifyUserEmail = async (token: string, dispatch: any, goToNewOrderPage: any = ()=>{}, elseFunction: any = ()=>{}) => {
  try{
    const res = await Request.post('verify-token/', {token});
    if (isAxiosResponse(res) && res?.data?.access) {
      localStorage.setItem('access_token', res.data.access);
      localStorage.setItem('refresh_token', res.data.refresh);
      dispatch(fetchUser((next)=> goToNewOrderPage()));
    }else{
      elseFunction();
    }
    return res;
  }
  catch(err: any){
    elseFunction();
    console.log(err);
    return err.response
  }
}

export const resendVerificationEmail = async (email: string) => {
  try{
    return await Request.post('resend-authentication-token/', {email});
  }
  catch(err: any){
    console.log(err);
    return err.response
  }
}

export const updateUserPassword = (data : any) =>
Request.post(`/change/password/`,data);

export const patchUserData = (id : any,data : any) =>
Request.patch(`/user/${id}/`,data);

export const getUserData = () =>
  Request.get('user/');

export const setUserRecentActivity = (data: any) =>
  Request.post('set/recent-activity/', data);

export const signUpUser = (data: Object) =>
  Request.post('signup/', data);

//v-0
export const getUserToolsApiV0 = () =>
  Request.get('get-user-tool/');

//v-1
export const getUserTools = (tool_subclass: string, pageNum: string) =>
  Request.get(`v1/get-user-tool/${tool_subclass}/?page=${pageNum}`);

export const blockUsers = (status: string, data: any) =>
  Request.post(`user-block-status/?status=${status}`, data)

export const forgetPasswordCall = (data: any) =>
  Request.post('password_reset/', data);

export const resetPasswordCall = (data: any) =>
  Request.post('password_reset/confirm/', data);

export const createAttestation = (data: any, claim_type: string) =>
  Request.post(`attestations/create/${claim_type}/`, data);