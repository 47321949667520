import TicketPdfPreview from "../ticket-pdf";
import { Modal } from "react-bootstrap";
import "./index.scss";

function TicketPdfPreviewModal({ ...props }) {
  const { setTicketLoader, ticketLoader, onHide, rebars, orderNo, selectedRowCount } = props;
  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <TicketPdfPreview
          setTicketLoader={setTicketLoader}
          ticketLoader={ticketLoader}
          onHide={onHide}
          rebars={rebars}
          orderNo={orderNo}
          selectedRowCount = {selectedRowCount}
        />
      </Modal>
    </>
  );
}

export default TicketPdfPreviewModal;
