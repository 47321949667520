import React, { useRef, useEffect, useMemo } from "react";
import rebarDefault from "../../../assets/img/order__page/other.png";
import { useAppSelector } from "Redux/hooks";
import PreviewHeader from "../header";
import { useTranslation } from "react-i18next";
import { commaSeparated } from "Constants/general";
import SteelType from  "../../../assets/img/pdf-preview/steel-type.svg";
import Diameter from  "../../../assets/img/pdf-preview/diameter.svg"
import Length from  "../../../assets/img/pdf-preview/length.svg"
import Quantity from  "../../../assets/img/pdf-preview/quantity.svg"
import UnitWeight from  "../../../assets/img/pdf-preview/unit-weight.svg"
import Weight from  "../../../assets/img/pdf-preview/weight.svg"
import UnitPrice from  "../../../assets/img/pdf-preview/unit-price.svg"
import Price from  "../../../assets/img/pdf-preview/price.svg"

const PreviewRebarTable = ({
  pageSize,
  loader,
  order,
  rebars,
  standardNets,
  specialNets,
  handleRefs,
  totalPages,
  priceShow,
  summaryDetails,
  pdfType,
  rebarPricing,
  bidFees,
  rebarsTotalPrice,
  calculatePrice,
  rightToLeft,
  checkForDecimals,
}: any) => {
  const { t } = useTranslation(["common", "pdfOrder", "measurements"]);

  const steelTypes = useAppSelector((state) => state.steelType.steelTypes);
  const pageRef: any = useRef([]);

  useEffect(() => {
    handleRefs(pageRef);
  });

  const rebarUnits = useMemo(
    () =>
      rebars.length > 0
        ? rebars.reduce((pre: any, curr: any) => pre + curr.quantity, 0)
        : 0,
    [rebars]
  );

  const rebarTons = useMemo(
    () =>
      rebars.length > 0
        ? (
            rebars.reduce(
              (pre: any, curr: any) =>
                pre + curr.tool.unit_weight * curr.quantity,
              0
            ) / 1000
          ).toFixed(4)
        : "0",
    [rebars]
  );

  //last is only used for order summary
  //for other conditions lastLast created
  const printpage = (
    data: any,
    refIndex: any,
    last: boolean,
    lastLast: boolean
  ) => {
    return (
      <>
        <div
          style={{
            height: pageSize + "px",
            justifyContent: loader ? "normal" : "",
          }}
          className={lastLast ? "last-page" : ""}
          ref={(el) => {
            pageRef.current[refIndex] = el;
          }}
        >
          <div>
            <PreviewHeader
              order={order}
              showDetails={refIndex === 0}
              pageNo={refIndex + 1}
              totalPages={totalPages}
              rightToLeft={rightToLeft}
            />
            {data !== -1 && (
              <div className="table-wrapper">
                <div className="table-head">{t("Rebar", { ns: "common" })}</div>
                <table
                  className={
                    "rebar" +
                    (lastLast ? " last" : "") +
                    (priceShow ? "" : " without-price")
                  }
                >
                  <thead>
                    <tr className="dark-border-bottom dark-border-top ">
                      <th
                        className={
                          (rightToLeft
                            ? "dark-border-left "
                            : "dark-border-right ") +
                          " d-flex justify-content-center align-items-center number-container"
                        }
                        style={{ paddingBottom: "35px" }}
                      >
                        <div className={rightToLeft ? "rightToLeft" : ""}>
                          {t("Number", { ns: "common" })}
                        </div>
                      </th>
                      <th
                        className={
                          (rightToLeft
                            ? "dark-border-left "
                            : "dark-border-right ") +
                          " d-flex justify-content-center align-items-center"
                        }
                      >
                        {t("sketch", { ns: "pdfOrder" })}
                      </th>
                      <th
                        className={
                          (rightToLeft
                            ? "dark-border-left "
                            : "dark-border-right ") +
                          " d-flex justify-content-center align-items-center"
                        }
                      >
                        {t("name", { ns: "pdfOrder" })}
                      </th>
                      <th
                        className={
                          rightToLeft
                            ? "light-border-left"
                            : "light-border-right"
                        }
                      >
                        <span className="last-element">
                          <img
                            style={{ height: "32px" }}
                            src={SteelType}
                            alt="steel type logo"
                          />
                        </span>
                        <span
                          className="light-border-bottom pb-1"
                          style={{ lineHeight: "15px" }}
                        >
                          {t("Steel type", { ns: "common" })}
                        </span>
                        <span className="unit">&nbsp;&nbsp;</span>
                      </th>
                      <th
                        className={
                          rightToLeft
                            ? "light-border-left"
                            : "light-border-right"
                        }
                      >
                        <span className="last-element">
                          <img
                            src={Diameter}
                            alt="diameter logo"
                          />
                        </span>
                        <span className="light-border-bottom">
                          {t("dmeter", { ns: "pdfOrder" })}
                        </span>
                        <span className="unit">
                          {t("mm", { ns: "measurements" })}
                        </span>
                      </th>
                      <th
                        className={
                          rightToLeft ? "dark-border-left" : "dark-border-right"
                        }
                      >
                        <span className="last-element">
                          <img
                            src={Length}
                            alt="length logo"
                          />
                        </span>
                        <span className="light-border-bottom">
                          {t("length", { ns: "common" })}
                        </span>
                        <span className="unit">
                          {t("cm", { ns: "measurements" })}
                        </span>
                      </th>
                      <th
                        className={
                          rightToLeft ? "dark-border-left" : "dark-border-right"
                        }
                      >
                        <span className="last-element">
                          <img
                            src={Quantity}
                            alt="quantity logo"
                          />
                        </span>
                        <span className="light-border-bottom">
                          {t("quantity", { ns: "common" })}
                        </span>
                        <span className="unit">
                          {t("units", { ns: "measurements" })}
                        </span>
                      </th>
                      <th
                        className={
                          priceShow
                            ? rightToLeft
                              ? "dark-border-left"
                              : "dark-border-right"
                            : ""
                        }
                      >
                        <span className="d-flex align-items-center justify-content-center h-100">
                          {pdfType === 1
                            ? t("Price", { ns: "common" })
                            : t("weight", { ns: "common" })}
                        </span>
                        <div className="two-columns light-border-top">
                          <div
                            className={
                              "single-column " +
                              (rightToLeft
                                ? "light-border-left"
                                : "light-border-right")
                            }
                          >
                            {pdfType !== 1 && (
                              <span>
                                <img
                                  src={UnitWeight}
                                  alt="unit weight logo"
                                />
                              </span>
                            )}
                            <span className="light-border-bottom">
                              {t("unit", { ns: "measurements" })}
                            </span>
                            <span className="unit">
                              {pdfType === 1
                                ? t("ILS", { ns: "measurements" })
                                : t("kg", { ns: "measurements" })}
                            </span>
                          </div>
                          <div className="single-column">
                            {pdfType !== 1 && (
                              <span>
                                <img
                                  src={Weight}
                                  alt="weight logo"
                                />
                              </span>
                            )}
                            <span className="light-border-bottom">
                              {t("total", { ns: "common" })}
                            </span>
                            <span className="unit">
                              {pdfType === 1
                                ? t("ILS", { ns: "measurements" })
                                : t("kg", { ns: "measurements" })}
                            </span>
                          </div>
                        </div>
                      </th>
                      {priceShow && (
                        <th>
                          <span>{t("Price", { ns: "common" })}</span>
                          <div className="two-columns light-border-top">
                            <div className="single-column light-border-right">
                              <span>
                                <img
                                  src={UnitPrice}
                                  alt="unit price logo"
                                />
                              </span>
                              <span className="light-border-bottom">
                                {t("total", { ns: "common" })}
                              </span>
                              <span className="unit">NIS</span>
                            </div>
                            <div className="single-column">
                              <span>
                                <img
                                  src={Price}
                                  alt="price logo"
                                />
                              </span>
                              <span className="light-border-bottom">
                                {t("total", { ns: "common" })}
                              </span>
                              <span className="unit">NIS</span>
                            </div>
                          </div>
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 &&
                      data.map((itemRebar: any, index: number) => (
                        <tr className="light-border-bottom rebar-tr">
                          <td
                            className={
                              (rightToLeft
                                ? "dark-border-left"
                                : "dark-border-right") + " height-inherit"
                            }
                          >
                            {itemRebar.row_number}
                          </td>
                          <td
                            className={
                              (rightToLeft
                                ? "dark-border-left"
                                : "dark-border-right") +
                              " height-inherit preview"
                            }
                            style={{ height: "inherit", padding: "3px 0px" }}
                          >
                            <img
                              src={
                                itemRebar.tool.preview_image
                                  ? itemRebar.tool.preview_image.file_field.replace(
                                      "https://easybar-s3.s3.amazonaws.com",
                                      "https://app.easybar.co.il"
                                    )
                                  : rebarDefault
                              }
                              alt="map icon"
                            />
                          </td>
                          <td
                            className={
                              (rightToLeft
                                ? "dark-border-left"
                                : "dark-border-right") + " height-inherit"
                            }
                          >
                            <span
                              style={
                                itemRebar.tool.name
                                  ? itemRebar.tool.name.includes(" ")
                                    ? {}
                                    : {
                                        wordBreak: "break-all",
                                        lineHeight: "1.2",
                                      }
                                  : {}
                              }
                            >
                              {itemRebar.tool.name || ""}
                            </span>
                          </td>
                          <td
                            className={
                              (rightToLeft
                                ? "light-border-left"
                                : "light-border-right") + " height-inherit"
                            }
                            style={
                              itemRebar.production_notes
                                ? { height: "72px" }
                                : {}
                            }
                          >
                            {steelTypes.length > 0 &&
                              steelTypes.find(
                                (item) => item.id === itemRebar.tool.steel_type
                              ) &&
                              steelTypes.find(
                                (item) => item.id === itemRebar.tool.steel_type
                              ).name[0]}
                          </td>
                          <td
                            className={
                              (rightToLeft
                                ? "light-border-left"
                                : "light-border-right") + " height-inherit"
                            }
                            style={
                              itemRebar.production_notes
                                ? { height: "72px" }
                                : {}
                            }
                          >
                            {itemRebar.tool.diameter.steel_diameter}
                          </td>
                          <td
                            className={
                              (rightToLeft
                                ? "dark-border-left"
                                : "dark-border-right") + " height-inherit"
                            }
                            style={
                              itemRebar.production_notes
                                ? { height: "72px" }
                                : {}
                            }
                          >
                            {itemRebar.tool.rebar_side.reduce(
                              (previousValue: number, item: any) =>
                                previousValue + parseInt(item.length),
                              0
                            )}
                          </td>
                          <td
                            className={
                              (rightToLeft
                                ? "dark-border-left"
                                : "dark-border-right") + " height-inherit"
                            }
                            style={
                              itemRebar.production_notes
                                ? { height: "72px" }
                                : {}
                            }
                          >
                            {itemRebar.quantity}
                          </td>
                          <td
                            className={
                              (rightToLeft
                                ? "light-border-left"
                                : "light-border-right") + " height-inherit"
                            }
                            style={
                              itemRebar.production_notes
                                ? { height: "72px" }
                                : {}
                            }
                          >
                            {pdfType === 1
                              ? checkForDecimals(
                                  calculatePrice(
                                    itemRebar.unit_price,
                                    itemRebar.quantity
                                  )
                                )
                                  .toString()
                                  .replace(commaSeparated, ",")
                              : itemRebar.tool.unit_weight}
                          </td>
                          <td
                            className={
                              !priceShow
                                ? index !== data?.length - 1
                                  ? "height-inherit light-border-bottom"
                                  : "height-inherit dark-border-bottom" //Because on Last rows last column border is not coming
                                : (rightToLeft
                                    ? "light-border-left"
                                    : "light-border-right") + " height-inherit"
                            }
                            style={
                              itemRebar.production_notes
                                ? { height: "72px" }
                                : {}
                            }
                          >
                            {pdfType === 1
                              ? checkForDecimals(
                                  calculatePrice(
                                    itemRebar.unit_price,
                                    itemRebar.quantity
                                  ) * itemRebar.quantity
                                )
                                  .toString()
                                  .replace(commaSeparated, ",")
                              : (
                                  itemRebar.tool.unit_weight *
                                  itemRebar.quantity
                                ).toFixed(2)}
                          </td>
                          {priceShow && (
                            <>
                              <td className="light-border-right height-inherit">
                                2.3
                              </td>
                              <td
                                className={
                                  itemRebar.production_notes
                                    ? ""
                                    : "light-border-bottom"
                                }
                              >
                                1,115
                              </td>
                            </>
                          )}
                          {itemRebar.production_notes && (
                            <td
                              className="light-border-top"
                              style={{
                                position: "absolute",
                                marginTop: "70px",
                                alignItems: "normal",
                                justifyContent: "flex-start",
                                height: "34px",
                                width: priceShow ? "73%" : "44%",
                                ...(rightToLeft
                                  ? {
                                      right: priceShow ? "240px" : "517px",
                                      textAlign: "right",
                                    }
                                  : { left: priceShow ? "250px" : "517px" }),
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "16px",
                                  lineHeight: "30px",
                                  ...(rightToLeft
                                    ? { marginRight: "4px" }
                                    : { marginLeft: "4px" }),
                                }}
                              >
                                {t("note")}: {itemRebar.production_notes}
                              </p>
                            </td>
                          )}
                        </tr>
                      ))}
                    {lastLast && (
                      <>
                        <tr style={{ border: "none" }}>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>{rebarUnits}</td>
                          <td>
                            {pdfType === 1
                              ? checkForDecimals(
                                  calculatePrice(rebarPricing, -1, true, true)
                                ) //bcz here we have total price of all items combined
                                  .toString()
                                  .replace(commaSeparated, ",")
                              : rebarTons}
                          </td>
                          {priceShow && <td>34</td>}
                        </tr>
                        <tr style={{ border: "none" }}>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>{t("quantity", { ns: "common" })}</td>
                          <td className="d-flex align-items-center text-capitalize">
                            <span className={rightToLeft ? "ml-1" : "mr-1"}>
                              {pdfType === 1
                                ? t("Price", { ns: "common" })
                                : t("weight", { ns: "common" })}
                            </span>
                            <span style={{ fontSize: "12px" }}>
                              (
                              {pdfType === 1
                                ? t("ILS", { ns: "measurements" })
                                : t("ton", { ns: "measurements" })}
                              )
                            </span>
                          </td>
                          {priceShow && <td>Price (NIS)</td>}
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            {last &&
              summaryDetails.special_nets === 0 &&
              summaryDetails.standard_nets === 0 &&
              summaryDetails.piles === 0 && (
                <div className="table-wrapper">
                  <div className="table-head">
                    {t("order Summary", { ns: "pdfOrder" })}
                  </div>
                  <table
                    className={
                      "order-summary" +
                      (priceShow ? "" : " without-price") +
                      (rightToLeft ? " rightToLeft" : "")
                    }
                  >
                    <tbody>
                      <tr>
                        <td>{t("Rebars", { ns: "common" })}</td>
                        <td>{summaryDetails.rebars}</td>
                        <td>
                          {pdfType === 1
                            ? checkForDecimals(parseFloat(rebarsTotalPrice))
                                .toString()
                                .replace(commaSeparated, ",")
                            : parseFloat(summaryDetails.rebars_weight) === 0.0
                            ? parseFloat(summaryDetails.rebars_weight).toFixed(
                                3
                              )
                            : parseFloat(summaryDetails.rebars_weight).toFixed(
                                2
                              )}
                        </td>
                        {priceShow && <td>54</td>}
                      </tr>

                      <tr>
                        <td>{t("total", { ns: "common" })}</td>
                        <td>{summaryDetails.rebars}</td>

                        <td>
                          {pdfType === 1
                            ? checkForDecimals(parseFloat(rebarsTotalPrice))
                                .toString()
                                .replace(commaSeparated, ",")
                            : parseFloat(summaryDetails.rebars_weight).toFixed(
                                2
                              )}
                        </td>
                        {priceShow && <td>99</td>}
                      </tr>
                      <tr>
                        <td></td>
                        <td>{t("units", { ns: "measurements" })}</td>
                        <td className="d-flex align-items-center text-capitalize">
                          <span className={rightToLeft ? "ml-1" : "mr-1"}>
                            {pdfType === 1
                              ? t("Price", { ns: "common" })
                              : t("weight", { ns: "common" })}
                          </span>
                          <span style={{ fontSize: "12px" }}>
                            (
                            {pdfType === 1
                              ? t("ILS", { ns: "measurements" })
                              : t("ton", { ns: "measurements" })}
                            )
                          </span>
                        </td>
                        {priceShow && (
                          <td>{t("Price", { ns: "common" })} (NIS)</td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
          </div>
        </div>
        <div
          style={{
            height: "20px",
            background: "#000",
            opacity: "0.5",
          }}
        ></div>
      </>
    );
  };

  if (rebars && rebars.length) {
    let SLICE = 10;
    let FIRST_SLICE = 7;
    let nextPageFlag = false; //flag to check if order summary has to be moved to the next page

    //Order summary to next page (For the first page only when there are no nets)
    if (standardNets.length === 0 && specialNets.length === 0) {
      if (rebars.length > 5 && rebars.length <= 8) {
        nextPageFlag = true;
      }
    }
    let LAST_SLICE = 8;
    const dataSlice: any = [];
    let initialSlice = 0,
      firstSlice = true;
    while (rebars.length > initialSlice) {
      const remaining = rebars.length - initialSlice;

      //Order summary to next page (Except the first page)
      if (standardNets.length === 0 && specialNets.length === 0) {
        if (remaining > 7 && remaining < 10 && !firstSlice) {
          nextPageFlag = true;
        } else if (!firstSlice) {
          nextPageFlag = false;
        }
      }
      if (remaining > 8 && firstSlice) {
        FIRST_SLICE = 8;
      }
      if (remaining > 10 && !firstSlice) {
        LAST_SLICE = 10;
      }
      if (rebars.length === initialSlice + SLICE) {
        dataSlice.push(rebars.slice(initialSlice, initialSlice + LAST_SLICE));
        initialSlice = initialSlice + LAST_SLICE;
        if (rebars.length > initialSlice)
          dataSlice.push(rebars.slice(initialSlice, initialSlice + LAST_SLICE));
      } else {
        dataSlice.push(
          rebars.slice(
            initialSlice,
            initialSlice + (firstSlice ? FIRST_SLICE : SLICE)
          )
        );
      }
      LAST_SLICE = 9;
      initialSlice = initialSlice + (firstSlice ? FIRST_SLICE : SLICE);
      if (firstSlice) firstSlice = false;
    }
    if (nextPageFlag) {
      dataSlice.push(-1);
    }

    return (
      <>
        {dataSlice.map((data: any, index: any) =>
          printpage(
            data,
            index,
            index === dataSlice.length - 1,
            dataSlice[index + 1] === -1 || index === dataSlice.length - 1
          )
        )}
      </>
    );
  }
  return <></>;
};

export default PreviewRebarTable;
