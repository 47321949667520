import React, { useEffect, useState } from "react";
import {
  getLinesLengthsForDrawing,
  CalculateFreeShapeLineAngle,
} from "../shared/shapesGeometry.ts";
import { Html } from "react-konva-utils";

import { calculateNextPoint, reverseAngle } from "../../../utils.ts";
import { getSmallerAngle, getFullAngle } from "../shared/shapesGeometry";
//This component is to draw labels and text on the lines.
const LinesLengthLabels = (props) => {
  const {
    path,
    scale,
    freeLengths,
    freeLineAngles,
    stageRef,
    onLineLengthChange,
    canvasWidth,
    canvasHeight,
    setIsCanvasHovered,
    setZooming,
    zooming,
    isDraggingCanvas,
    setAllPoints,
    indexToChar,
    lineAngles,
  } = props;

  const [inputPoints, setInputPoints] = useState([] as any);
  const stage = stageRef.current.getStage();

  useEffect(() => {
    if (
      (path.lines.length > 0 && canvasWidth > 200 && canvasHeight > 200) ||
      zooming
    ) {
      const freeLengths = getLinesLengthsForDrawing(path.lines);
      const freeLineAngles = CalculateFreeShapeLineAngle(path.lines, lineAngles);

      const lines = path.lines;
      const initiateImage = false;

      let previousPoint = { x: path.lines[0].x1, y: path.lines[0].y1 };
      let pointsCalculated = [];
      pointsCalculated.push(previousPoint);
      let currentPoint = {};
      const _midpoints: any[] = [];
      let inner = false;

      lines.forEach((side: any, index: number) => {
        const length = freeLengths[index]?.length;

        // Calculate Points for saving Image preview
        currentPoint = calculateNextPoint(
          previousPoint,
          freeLineAngles[index]?.angle,
          length
        );
        pointsCalculated.push({ ...currentPoint });

        if (index < lines.length - 1){
          const line1 = lines[index];
          const line2 = lines[index + 1];
          const smallerAngle = getSmallerAngle(line1, line2);
          const fullAngle = getFullAngle(line1, line2);
  
          inner = smallerAngle !== fullAngle;
        }

        // if the angle is outter angle
        const outterInput = true;
        // side.outer ||
        // (index >= 1 &&
        //   !shapeObj.rebar_side[index - 1].change_previous_point &&
        //   shapeObj.rebar_side[index - 1].outer);
        // Calculate Points for inputs on the canvas
        // find midpoint of line - 25 as intermediate point
        const invertedLine = previousPoint.x > currentPoint.x; // if difference is negactive b/w Xs
        const inputSizeMid = 5;
        const intermedia = calculateNextPoint(
          previousPoint,
          freeLineAngles[index]?.angle,
          invertedLine ? length / 2 + inputSizeMid : length / 2 - inputSizeMid
        );

        let pointToInput = { x: 0, y: 0 };
        let inputAngle;
        let inputDistance;;
        if (invertedLine) {
          inputAngle = Number(freeLineAngles[index]?.angle) + (inner ? (90) : (-90)) 
          inputDistance = inner ? 6 : 6
        } else {
          inputAngle = Number(freeLineAngles[index]?.angle) + (inner ? (90) : (-90)) 
          inputDistance = inner ? 2 : 10
        }

        pointToInput = calculateNextPoint(
          intermedia,
          inputAngle,
          inputDistance,        
          );


        const scaledPTI = {
          x: pointToInput.x * stage.scaleX() + stage.x(),
          y: pointToInput.y * stage.scaleY() + stage.y(),
        };

        //Labels are on the right positions now
        let pointToText = { x: 0, y: 0 };
        if (invertedLine) {
          pointToText = calculateNextPoint(
            scaledPTI as any,
            outterInput
              ? freeLineAngles[index]?.angle - 40
              : freeLineAngles[index]?.angle + 150,
            -24
          );
        } else {
          pointToText = calculateNextPoint(
            scaledPTI as any,
            outterInput
              ? (freeLineAngles[index]?.angle - (initiateImage ? 60 : -50)) %
                  360
              : (freeLineAngles[index]?.angle + 51) % 360,
            outterInput ? (initiateImage ? 15 : 32) : initiateImage ? 16 : 6
          );
        }

        _midpoints.push({
          input: pointToInput,
          text: pointToText,
          invertedLine,
        });

        previousPoint = currentPoint;
      });
      setAllPoints(pointsCalculated);
      setInputPoints(_midpoints);

      return () => {
        setInputPoints([]);
        setZooming(false);
      };
    }
  }, [path, canvasWidth, canvasHeight, stage, zooming, isDraggingCanvas]);
  //stage Added in dependency list

  const handleLineLengthChange = (event) => {
    const newLineLength = {
      id: event.target.name,
      length: Math.round(Number(event.target.value)),
    };
    onLineLengthChange(newLineLength);
  };

  const isBoxInsidetheSketch = (x, y) => {
    if (x < 0 || y < 0) {
      return true;
    } else if (x > 795) {
      return true;
    } else if (y + 20 > 520) {
      return true;
    }
  };

  const isLabelInsidetheSketch = (x, y, textX, textY) => {
    if (textX < 0 || textY < 0 || x < 0 || y < 0) {
      return true;
    } else if (textX > 795 || x > 795) {
      return true;
    } else if (textY > 520 || y + 20 > 520) {
      return true;
    }
  };

  return (
    <>
      {!isDraggingCanvas && (
        <>
          {inputPoints.map((item, index) => (
            <React.Fragment key={"rebar_inputs" + index}>
              <Html
                divProps={{
                  style: {
                    display: isLabelInsidetheSketch(
                      item.input.x * stage.scaleX() + stage.x() - 25,
                      item.input.y * stage.scaleY() + stage.y() - 25,
                      item.text.x - 10,
                      item.text.y
                    )
                      ? "none"
                      : "block",
                    position: "absolute",
                    top: `${item.text.y}px`,
                    left: `${item.text.x}px`,
                    transform: `rotate(${
                      item.invertedLine
                        ? reverseAngle(Number(freeLineAngles[index]?.angle))
                        : freeLineAngles[index]?.angle
                    }deg)`,
                  },
                }}
              >
                <p
                  style={{ fontSize: "16px" }}
                  onMouseEnter={() => setIsCanvasHovered(true)}
                >
                  {indexToChar(index)}
                </p>
              </Html>
              <Html
                divProps={{
                  style: {
                    display: isBoxInsidetheSketch(
                      item.input.x * stage.scaleX() + stage.x() - 25,
                      item.input.y * stage.scaleY() + stage.y() - 25
                    )
                      ? "none"
                      : "block",
                    position: "absolute",
                    top: `${item.input.y * stage.scaleY() + stage.y()}px`,
                    left: `${item.input.x * stage.scaleX() + stage.x()}px`,
                    transform: `rotate(${
                      item.invertedLine
                        ? reverseAngle(Number(freeLineAngles[index]?.angle))
                        : freeLineAngles[index]?.angle
                    }deg)`,
                  },
                }}
                onMouseEnter={() => setIsCanvasHovered(true)}
              >
                <input
                  name={freeLengths[index]?.id}
                  onFocus={(e) => e.target.select()}
                  className={"inputField "}
                  type="number"
                  style={{ width: "50px" }}
                  value={parseInt(freeLengths[index]?.length.toFixed(0)) || ""}
                  onChange={handleLineLengthChange}
                  onMouseEnter={() => setIsCanvasHovered(true)}
                />
              </Html>
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
};

export default LinesLengthLabels;
