import { addPileToCurrentOrder, setDefaultPiles, updateCurrentOrderPile } from "Redux/features/pile";
import { pileStore } from "Redux/offline";
import { AppThunk } from 'Redux/store';
import { isAxiosResponse } from "Services/index";
import { postOrderItem, updateOrderItem, getItemsCount } from "Services/order";
import { getPiles } from "Services/pile";
import { getMedia } from "Services/media";
import { PILE_SUBCLASS_TYPE } from "Constants/pile-constants";
// import { MediaHost } from "Constants/general";

//NOTE: row_number is 0 when creating new item and > 0 when duplicating item
export const createPile = (pile: any, orderItem: any, next = (val: boolean) => {}, row_num: number, orderId = 0): AppThunk => async (dispatch, getState) => {
  return new Promise(async(resolve, reject) => {
    
  const user = getState().user.userData;
  if (user) {
    getItemsCount(PILE_SUBCLASS_TYPE, orderId).then(
      async (result)=>{
        orderItem.row_number = row_num > 0 ? row_num : result.data.row_number + 1;
        let res;
        if (orderId > 0){
          res = await postOrderItem({
            ...orderItem,
            order: orderId,
            tool: pile,
            user: user.id,
          });
        }
        else{
          res = await postOrderItem({
            ...orderItem,
            tool: pile,
            user: user.id,
          });
        }
        if (isAxiosResponse(res)) {
          const previewID = res.data.tool.preview_image;
          const resMe = await getMedia(previewID);
          if (isAxiosResponse(resMe)) {
            const preview = {...resMe.data};
            // preview.file_field = preview.file_field.replace(MediaHost, '');
            const orderIt = {
              ...res.data,
              tool: {
                ...res.data.tool,
                preview_image: preview,
              }
            };
    
            const piles = getState().pile.currentOrderPiles
            if(piles.length === 0 || res.data.row_number - piles.slice(-1)[0].row_number === 1 || res.data.row_number < piles.slice(-1)[0].row_number){
              await dispatch(addPileToCurrentOrder(orderIt));
            }
            resolve();
            return next(true);
          }
        }
      }
    )
  } else {
    const pileData = {
      ...orderItem,
      tool: pile,
    };
    const previewID = pileData.tool.preview_image;
    const resMe = await getMedia(previewID);
    if (isAxiosResponse(resMe)) {
      const preview = {...resMe.data};
      // preview.file_field = preview.file_field.replace(MediaHost, '');
      const orderIt = {
        ...pileData,
        tool: {
          ...pileData.tool,
          preview_image: preview,
        }
      };
      pileData.setItem(orderIt);
      await dispatch(addPileToCurrentOrder(orderIt));
    }
  }
  });
}

export const updatePile = (
  orderItemID: number,
  pile: any,
  orderItem: any,
  next = (val: boolean) => {}
): AppThunk => async (dispatch, getState) => {
  const user = getState().user.userData;
  if (user) {
    const res = await updateOrderItem(orderItemID, {
      ...orderItem,
      tool: pile,
    });
    if (isAxiosResponse(res)) {
      const previewID = res.data.tool.preview_image;
      const resMe = await getMedia(previewID);
      if (isAxiosResponse(resMe)) {
        const preview = {...resMe.data};
        // preview.file_field = preview.file_field.replace(MediaHost, '');
        const orderIt = {
          ...res.data,
          tool: {
            ...res.data.tool,
            preview_image: preview,
          }
        };
        await dispatch(updateCurrentOrderPile(orderIt));
        return next(true);
      }
    }
  }
}

export const fetchDefaultsPiles = (): AppThunk => async dispatch => {
  const shapes = await getPiles(2);
  if (shapes.length > 0) {
    await dispatch(setDefaultPiles(shapes))
  }
}
