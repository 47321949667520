import React, { useState } from "react";
import { useAppSelector } from "Redux/hooks";
import { commaSeparated} from "Constants/general";
import diameterIcon from "../../../../assets/img/icon/diameter-blue.svg";
import MoreInfoIcon from "../../../../assets/img/icon/drug.svg";
import previewNet from "../../../../assets/img/nets/view_net.svg";

import {
  netsWithoutPrice,
  netsWithPrice,
} from "Constants/net-constants";
import { useTranslation } from "react-i18next";

import { useSortable } from "@dnd-kit/sortable";

const StaticTableRow = ({ ...props }) => {
  const {
    index,
    itemNet,
    priceShow,
    handleImagePreview,
    setSelectedTool,
  } = props;

  const { t } = useTranslation(["newOrder", "common", "measurements", "tool"]);
  const steelTypes = useAppSelector((state) => state.steelType.steelTypes);
  const rightToLeft = useAppSelector((state) => state.root.rightToLeft);

  const [isHovered, setIsHovered] = useState(-1);

  const { isDragging } = useSortable({
    id: props.id,
  });

  return (
    <tr
      className="static-table_row"
      key={"itemNet" + index}
      style={
        priceShow
          ? { ...netsWithPrice, position: "relative" }
          : {
              ...netsWithoutPrice,
              position: "relative",
              borderTop: "2px solid #E68F8B",
            }
      }
      onMouseEnter={() => {
        setSelectedTool(itemNet);
        setIsHovered(index);
      }}
      onMouseLeave={() => setIsHovered(-1)}
    >
      <td
        className="d-flex justify-content-center p-0"
        style={{ cursor: "pointer" }}
      >
        {!isDragging ? (
          <div className="dropsdown">
            <img
              src={MoreInfoIcon}
              alt={"icon for options"}
              style={
                !isDragging
                  ? { cursor: "grabbing", width: "22px" }
                  : { cursor: "grab", width: "22px" }
              }
            />
          </div>
        ) : (
          <span className="table__para">{itemNet.row_number}</span>
        )}
      </td>
      <td
        className={
          "d-flex justify-content-center align-item-center p-0 " +
          (rightToLeft ? "dark-border-right" : "dark-border-left")
        }
      >
        <img
          className="img__icon"
          src={previewNet}
          alt="previewNet"
          style={{ cursor: "pointer" }}
          onClick={() => handleImagePreview(itemNet.tool.preview_image)}
        />
      </td>
      <td
        className={
          "justify-content-start " +
          (rightToLeft ? "dark-border-right" : "dark-border-left")
        }
      >
        <span className="name_header_font">
          <span
            style={
              itemNet.tool.name
                ? itemNet.tool?.name.includes(" ")
                  ? {}
                  : { wordBreak: "break-all" }
                : {}
            }
          >
            {itemNet.tool.name}
          </span>
        </span>
      </td>
      <td
        className={
          "d-flex justify-content-center p-0 " +
          (rightToLeft ? "dark-border-right" : "dark-border-left")
        }
      >
        <span>
          {steelTypes.length > 0 &&
            steelTypes.find((item) => item.id === itemNet.tool.steel_type) &&
            steelTypes.find((item) => item.id === itemNet.tool.steel_type).name}
        </span>
      </td>
      <td className={rightToLeft ? "dark-border-right" : "dark-border-left"}>
        <span
          style={itemNet.production_notes ? { paddingBottom: "16px" } : {}}
          className="d-flex justify-content-center align-items-center"
        >
          <span
            className={
              "d-flex justify-content-center text__bold " +
              (rightToLeft ? "ml-4" : "mr-4")
            }
          >
            {itemNet.tool.x_length}
          </span>
          <span
            style={{ width: "90px" }}
            className={rightToLeft ? "ml-4" : "mr-4"}
          >
            {itemNet.tool.x_gap === itemNet.tool.y_gap &&
            itemNet.tool.x_diameter.id === itemNet.tool.y_diameter.id ? (
              ""
            ) : (
              <span className="d-flex justify-content-between align-items-center">
                <img
                  style={{ width: "16px", height: "16px" }}
                  src={diameterIcon}
                  alt="hei icon"
                />
                <span>{itemNet.tool.x_diameter.steel_diameter}</span>
                <span
                  className="hei__icon"
                  style={{ width: "15px", color: "#353d4a" }}
                >
                  <strong>@</strong>
                </span>
                {itemNet.tool.x_gap}
              </span>
            )}
          </span>
          <span
            className={
              "d-flex justify-content-center text__bold " +
              (rightToLeft ? "ml-4" : "mr-4")
            }
          >
            <strong>{itemNet.tool.y_length}</strong>
          </span>
          <span style={{ width: "90px" }}>
            <span className="d-flex justify-content-between align-items-center">
              <img
                style={{ width: "16px", height: "16px" }}
                src={diameterIcon}
                alt="hei icon"
              />
              <span>{itemNet.tool.y_diameter.steel_diameter}</span>
              <span
                className="hei__icon"
                style={{ width: "15px", color: "#353d4a" }}
              >
                <strong>@</strong>
              </span>
              {itemNet.tool.y_gap}
            </span>
          </span>
        </span>
      </td>
      <td
        className={
          "d-flex justify-content-start " +
          (rightToLeft ? "dark-border-right" : "dark-border-left")
        }
      >
        {itemNet.tool.standard_net ? (
          <span>{t("Standard", { ns: "common" })}</span>
        ) : (
          <span>{t("Special", { ns: "common" })}</span>
        )}
      </td>
      <td
        className={
          "d-flex justify-content-start " +
          (rightToLeft ? "dark-border-right" : "dark-border-left")
        }
      >
        <span>{itemNet.quantity}</span>
      </td>
      <td className="d-flex justify-content-start dark-border-right dark-border-left">
        <span style={{ width: "50%" }}>
          {itemNet.tool.unit_weight.toString().replace(commaSeparated, ",")}
        </span>
        <span>
          {(itemNet.tool.unit_weight * itemNet.quantity)
            .toFixed(2)
            .toString()
            .replace(commaSeparated, ",")}
        </span>
      </td>
      {priceShow && (
        <>
          <td className="justify-content-start">
            <span>
              {itemNet.unit_price
                ? parseFloat(itemNet.unit_price)
                    .toFixed(2)
                    .toString()
                    .replace(commaSeparated, ",")
                : "-"}
            </span>
          </td>
          <td className="justify-content-start">
            <span>
              {itemNet.unit_price
                ? (parseFloat(itemNet.unit_price) * itemNet.quantity)
                    .toFixed(0)
                    .toString()
                    .replace(commaSeparated, ",")
                : "-"}
            </span>
          </td>
        </>
      )}
      {itemNet.production_notes && (
        <td
          colSpan={1}
          className="light-border-top note-td"
          style={{
            padding: "0px",
            position: "absolute",
            marginTop: "56px",
            alignItems: "normal",
            justifyContent: "flex-start",
            width: priceShow ? "68%" : "34.9%",
            ...(rightToLeft
              ? {
                  right: priceShow ? "250px" : "calc(100% - 69.9%)",
                  textAlign: "right",
                }
              : {
                  left: priceShow ? "250px" : "calc(100% - 69.9%)",
                }),
          }}
        >
          <p style={{ margin: "0 5px", fontSize: "15px" }}>
            {t("note", { ns: "common" })}: {itemNet.production_notes}
          </p>
        </td>
      )}
    </tr>
  );
};

export default StaticTableRow;
