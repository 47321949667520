import { useState, useEffect, useRef } from "react";
import { Stage, Shape, Layer, Line as KonvaLine, Circle } from "react-konva";

import GridLayer from "./grid-Layer/gridLayer";
import LinesLengthLabels from "./lines-length-labels/LinesLengthLabels";
import LinesAngleLabels from "./lines-angle-labels/LinesAngleLabels";
import { PathLengthLabel } from "./path-length-label/PathLengthLabel";
import EdgeController from "./edge-Controllers/EdgeController";
import CornerEdgeController from "./corner-Controllers/CornerEdgeCotroller";
import RoundedCornerPath from "./rounded-corner-path/RoundedCornerPath";

import {
  snapPosToLineLengthStep,
  snapNewPosToLengthMultiple,
  getSmallerAngle,
  getFullAngle,
} from "./shared/shapesGeometry";
import { calculateNextPoint, reverseAngle } from "../../utils.ts";
import penCursor from "Assets/img/icon/pen.svg";

import { debounce } from "lodash";

import {
  GRID_LAYER,
  LINE_LENGTH_STEP,
  LINE_ANGLE_STEP,
  CANVAS_SIZE,
  DRAG_BOUNDARY_POINTS,
} from "./constants";

import Measure from "react-measure";
import { isMobile } from "react-device-detect";

const FreeFormShape = (props) => {
  const {
    onPathChange,
    onUndo,
    onRedo,
    freeLengths,
    freeLineAngles,
    freeAngles,
    stageRef,
    onLineLengthChange,
    setResetClicked,
    resetClicked,
    setAllPoints,
    setShowCatalog,
    pointsCalculated,
    indexToChar,
  } = props;

  // const stageRef = useRef(null);

  const [path, setPath] = useState(props.path);
  const [stageScale, setStageScale] = useState(5);
  const [isDrawing, setIsDrawing] = useState(false);
  const [firstPointerDownPos, setFirstPointerDownPos] = useState(null);
  const [spaceHeld, setSpaceHeld] = useState(false);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [draggedEdgeId, setDraggedEdgeId] = useState(null);
  const [canvasWidth, setCanvasWidth] = useState(30);
  const [canvasHeight, setCanvasHeight] = useState(30);
  const [isCanvasHovered, setIsCanvasHovered] = useState(false);
  const [isDraggingCanvas, setIsDraggingCanvas] = useState(false);
  const [isEdgeHovered, setIsEdgeHovered] = useState(false);
  const [isDragging, setIsDragging] = useState(false); //Edge Dragging
  const [zooming, setZooming] = useState(false);

  const { lines, edges } = path;

  useEffect(() => {
    if (props.path !== path) {
      setPath(props.path);
    }
  }, [props.path]);

  useEffect(() => {
    if (resetClicked) {
      setPath({ id: "path", lines: [], edges: [] });
      setIsDrawing(false);
      setFirstPointerDownPos(null);
      setStageScale(5);
      setResetClicked(false);
    }
  }, [resetClicked]);

  //In this function we are getting position of current new points and creating Line
  //from the previous points to current
  const addNewLineFromLastLineEndToPos = (pos: { x: number; y: number }) => {
    const lastPos =
      lines.length > 0
        ? { x: lines[lines.length - 1].x2, y: lines[lines.length - 1].y2 }
        : firstPointerDownPos;
    if (!lastPos || (lastPos.x === pos.x && lastPos.y === pos.y)) {
      return;
    }

    const newLine: Line = {
      id: Math.random().toString(),
      x1: lastPos.x,
      y1: lastPos.y,
      x2: pos.x,
      y2: pos.y,
    };

    const newLines = [...lines, newLine];
    const newLinesEdges =
      newLines.length > 1
        ? [
            ...edges,
            {
              id: Math.random().toString(),
              x: newLine.x1,
              y: newLine.y1,
              lineIds: [newLines[newLines.length - 2].id, newLine.id] as [
                string,
                string
              ],
              cornerRadius: 1,
            },
          ]
        : edges;
    const newPath = { ...path, lines: newLines, edges: newLinesEdges };
    setPath(newPath); //this object is saving complete shape
    onPathChange(newPath);
  };

  //getting scaled pointer positions
  const getPointerPosScaledAndSnappedToGrid = (stage: any) => {
    const pointerPos = stage.getPointerPosition();

    const scaledPointerPos = {
      x: (pointerPos.x - stage.x()) / stage.scaleX(),
      y: (pointerPos.y - stage.y()) / stage.scaleY(),
    };

    if (lines.length === 0) {
      return snapPosToLineLengthStep(scaledPointerPos, LINE_LENGTH_STEP);
    }

    const prevPos = {
      x: lines[lines.length - 1].x2,
      y: lines[lines.length - 1].y2,
    };
    let scaledAndSnappedPointerPos = snapNewPosToLengthMultiple(
      prevPos,
      scaledPointerPos,
      LINE_LENGTH_STEP,
      LINE_ANGLE_STEP
    );
    return scaledAndSnappedPointerPos;
  };

  const snapToMultiple = (num: number, multiple: number) => {
    return Math.round(num / multiple) * multiple;
  };

  const nextDrawingPoint = () => {
    if (!stageRef.current) {
      return null;
    }
    const stage = stageRef.current.getStage();
    let nextDrawingPoint = getPointerPosScaledAndSnappedToGrid(stage);
    if (isDrawing) {
      if (spaceHeld) {
        const prevPoint = {
          x: lines[lines.length - 1].x2,
          y: lines[lines.length - 1].y2,
        };
        // calculate the distance between the last point and the current point on the x and y axis
        const xDist = Math.abs(nextDrawingPoint.x - prevPoint.x);
        const yDist = Math.abs(nextDrawingPoint.y - prevPoint.y);

        if (xDist > yDist) {
          // update the distance between nextDrawingPoint.x and prevPoint.x to be a multiple of LINE_LENGTH_STEP while keeping the same y value
          // this will make the line snap to a 90 degree angle
          const nextDrawingPointX = snapToMultiple(
            nextDrawingPoint.x,
            LINE_LENGTH_STEP
          );

          nextDrawingPoint = { x: nextDrawingPointX, y: prevPoint.y };
        } else {
          const nextDrawingPointY = snapToMultiple(
            nextDrawingPoint.y,
            LINE_LENGTH_STEP
          );
          nextDrawingPoint = { x: prevPoint.x, y: nextDrawingPointY };
        }
      }
    }
    return nextDrawingPoint;
  };

  //lines that comes before drawing and gives info of line in the dashed form
  const getLastLine = (): Line => {
    const points =
      lines.length > 0
        ? [
            lines[lines.length - 1].x2,
            lines[lines.length - 1].y2,
            nextDrawingPoint()!.x,
            nextDrawingPoint()!.y,
          ]
        : [
            firstPointerDownPos ? firstPointerDownPos.x : 0,
            firstPointerDownPos ? firstPointerDownPos.y : 0,
            nextDrawingPoint()!.x,
            nextDrawingPoint()!.y,
          ];
    return {
      id: Math.random().toString(),
      x1: points[0],
      y1: points[1],
      x2: points[2],
      y2: points[3],
      strokeWidth: 2,
      selected: false,
      hovered: false,
      color: "#000",
    };
  };

  const onEdgeDragStart = (lineEdge: LineEdge) => {
    setDraggedEdgeId(lineEdge.id);
    setIsDragging(true);
  };

  function correctDragPosition(
    mousePosition,
    point1,
    point2,
    lengthConstraint: number,
    angleConstraint: number
  ) {
    // Calculate vectors
    const vector1 = {
      x: mousePosition.x - point1.x,
      y: mousePosition.y - point1.y,
    };
    const vector2 = {
      x: mousePosition.x - point2.x,
      y: mousePosition.y - point2.y,
    };

    // Calculate angle between vectors
    let angle = Math.abs(
      Math.atan2(vector2.y, vector2.x) - Math.atan2(vector1.y, vector1.x)
    );
    angle = Math.min(angle, 2 * Math.PI - angle);

    // Round angle to nearest multiple of angleConstraint
    angle = Math.round(angle / angleConstraint) * angleConstraint;

    // Calculate new lengths
    const length1 = Math.sqrt(vector1.x * vector1.x + vector1.y * vector1.y);
    const length2 = Math.sqrt(vector2.x * vector2.x + vector2.y * vector2.y);
    const newLength1 =
      Math.round(length1 / lengthConstraint) * lengthConstraint;
    const newLength2 =
      Math.round(length2 / lengthConstraint) * lengthConstraint;

    // Calculate corrected position of the shared edge
    const correctedPosition = {
      x:
        (point1.x + newLength1 * Math.cos(Math.atan2(vector1.y, vector1.x))) *
          0.5 +
        (point2.x +
          newLength2 * Math.cos(Math.atan2(vector2.y, vector2.x) - angle)) *
          0.5,
      y:
        (point1.y + newLength1 * Math.sin(Math.atan2(vector1.y, vector1.x))) *
          0.5 +
        (point2.y +
          newLength2 * Math.sin(Math.atan2(vector2.y, vector2.x) - angle)) *
          0.5,
    };

    return correctedPosition;
  }

  const onEdgeDragMove = (lineEdge) => {
    const stage = stageRef.current?.getStage();
    if (!stage) {
      return;
    }
    const pointerPos = stage.getPointerPosition();
    if (!pointerPos) {
      return;
    }

    // convert pointer position to stage coordinates
    const scaledPointerPos = {
      x: (pointerPos.x - stage.x()) / stage.scaleX(),
      y: (pointerPos.y - stage.y()) / stage.scaleY(),
    };

    // update both lines that are connected to the edge that was dragged
    const firstLine = lines.find((line) => line.id === lineEdge.lineIds[0]);
    const secondLine = lines.find((line) => line.id === lineEdge.lineIds[1]);
    if (!firstLine || !secondLine) {
      return;
    }
    const dragPosition = correctDragPosition(
      scaledPointerPos,
      { x: secondLine.x2, y: secondLine.y2 },
      { x: firstLine.x1, y: firstLine.y1 },
      LINE_LENGTH_STEP,
      LINE_ANGLE_STEP
    );

    // update first line x2 and y2 and second line x1 and y1
    const newLines = lines.map((line) => {
      if (line.id === firstLine.id) {
        return {
          ...line,
          x2: dragPosition.x,
          y2: dragPosition.y,
        };
      }
      if (line.id === secondLine.id) {
        return {
          ...line,
          x1: dragPosition.x,
          y1: dragPosition.y,
        };
      }
      return line;
    });
    const newLinesEdges = edges.map((lineEdge) => {
      if (lineEdge.id === draggedEdgeId) {
        return {
          ...lineEdge,
          x: dragPosition.x,
          y: dragPosition.y,
        };
      }
      return lineEdge;
    });

    const newPath = { ...path, lines: newLines, edges: newLinesEdges };
    setPath(newPath);
    onPathChange(newPath);
  };

  const debounceOnEdgeDragMove = debounce(onEdgeDragMove, 1);

  const onEdgeDragEnd = () => {
    onPathChange(path);
    setDraggedEdgeId(null);
    setIsDragging(false);
  };

  const onCornerEdgeDragMove = (lineEdge) => {
    const stage = stageRef.current?.getStage();
    if (!stage) {
      return;
    }
    const pointerPos = stage.getPointerPosition();
    if (!pointerPos) {
      return;
    }

    // convert pointer position to stage coordinates
    const scaledPointerPos = {
      x: (pointerPos.x - stage.x()) / stage.scaleX(),
      y: (pointerPos.y - stage.y()) / stage.scaleY(),
    };

    let draggedLine = {};
    if (lines.length === 1) {
      draggedLine = lines[0];
    } else {
      draggedLine = lines.find((line) => line.id === lineEdge.id);
    }

    if (!draggedLine) {
      return;
    }

    const newLines = lines.map((line, index) => {
      if (lines.length === 1) {
        if (lineEdge.id === "_left_point") {
          return {
            ...line,
            x1: scaledPointerPos.x,
            y1: scaledPointerPos.y,
          };
        } else {
          return {
            ...line,
            x2: scaledPointerPos.x,
            y2: scaledPointerPos.y,
          };
        }
      } else {
        if (line.id === draggedLine.id && lines.length - 1 === index) {
          return {
            ...line,
            x2: scaledPointerPos.x,
            y2: scaledPointerPos.y,
          };
        } else if (line.id === draggedLine.id) {
          return {
            ...line,
            x1: scaledPointerPos.x,
            y1: scaledPointerPos.y,
          };
        }
      }
      return line;
    });

    const newPath = { ...path, lines: newLines };
    setPath(newPath);
    onPathChange(newPath);
  };

  const debounceOnCornerEdgeDragMove = debounce(onCornerEdgeDragMove, 1);

  //Below Functions handle all the canvas related events
  const handleCanvasClick = () => {
    if (!stageRef.current) {
      return;
    }

    if (!firstPointerDownPos) {
      //Here 1st time
      setFirstPointerDownPos(
        getPointerPosScaledAndSnappedToGrid(stageRef.current.getStage())
      );
    }

    if (!isDrawing) {
      //Drawing after break
      setIsDrawing(true);
    } else {
      //Drawing with no break
      addNewLineFromLastLineEndToPos(
        nextDrawingPoint() as { x: number; y: number }
      );
    }
  };

  //stopping drawing on clicking twice
  const handleCanvasDoubleClick = () => {
    if (isDrawing) {
      setIsDrawing(false);
    }
  };

  const handleCanvasDragStart = () => {
    setIsDraggingCanvas(true);
  };

  //Needs to add some fixes here
  const handleCanvasDragMove = (e) => {
    const node = e.target;
    const x = node.x();
    const y = node.y();

    //left and top
    if (x > DRAG_BOUNDARY_POINTS.left && y > DRAG_BOUNDARY_POINTS.top) {
      node.stopDrag();
    }
    //right and top
    else if (x < DRAG_BOUNDARY_POINTS.right && y > DRAG_BOUNDARY_POINTS.top) {
      node.stopDrag();
    }
    //left and bottom
    else if (x > DRAG_BOUNDARY_POINTS.left && y < DRAG_BOUNDARY_POINTS.bottom) {
      node.stopDrag();
    }
    //right and bottom
    else if (
      x < DRAG_BOUNDARY_POINTS.right &&
      y < DRAG_BOUNDARY_POINTS.bottom
    ) {
      node.stopDrag();
    }
    //left
    else if (x > DRAG_BOUNDARY_POINTS.left) {
      node.stopDrag();
    }
    //top
    else if (y > DRAG_BOUNDARY_POINTS.top) {
      node.stopDrag();
    }
    //right
    else if (x < DRAG_BOUNDARY_POINTS.right) {
      node.stopDrag();
    }
    //bottom
    else if (y < DRAG_BOUNDARY_POINTS.bottom) {
      node.stopDrag();
    }
  };

  const handleCanvasDragEnd = () => {
    setIsDraggingCanvas(false);
  };

  const handleCanvasWheel = (e: any) => {
    // stop default scrolling
    e.evt.preventDefault();
    if (!stageRef.current) {
      return;
    }

    setZooming(true);

    let checkLayerCords = false;
    if (e.target.x() >= -90 || e.target.y() >= -90) {
      checkLayerCords = true;
    }

    const stage = stageRef.current.getStage();
    const oldScale = stage.scaleX();
    const pointer = stage.getPointerPosition();

    if (!pointer) {
      return;
    }

    //getting the scaled pointer values
    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    // how to scale? Zoom in? Or zoom out? if > 0 zoomIn else zoomOut
    let direction = e.evt.deltaY > 0 ? 1 : -1;

    // when we zoom on trackpad, e.evt.ctrlKey is true
    // in that case lets revert direction
    if (e.evt.ctrlKey) {
      direction = -direction;
    }

    //Checking if grid layer coords are okay then allowing zoom out
    //oldScale * factor = zoomIns and oldScale/factor = zoomOuts
    let newScale;
    if (checkLayerCords) {
      newScale = direction > 0 ? oldScale * 1.02 : oldScale;
    } else {
      newScale = direction > 0 ? oldScale * 1.02 : oldScale / 1.02;
    }

    // clamp scale to 2 - 10 (allowing the zoom to stay in b/w 2-10)
    newScale = Math.min(Math.max(2, newScale), 10);

    stage.scale({ x: newScale, y: newScale });

    // set position of stage to center of the screen
    const newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };

    stage.position(newPos);
    setStageScale(stage.scaleX());
  };

  const handleCanvasMouseMove = (e: any) => {
    const stage = e.target.getStage();
    const scaledAndSnappedPointerPos =
      getPointerPosScaledAndSnappedToGrid(stage);
    setMousePos(scaledAndSnappedPointerPos);
  };

  const handleKeyDown = (e: any) => {
    //e.preventDefault();
    if (e.key === " ") {
      setSpaceHeld(true);
    } else if (
      (e.ctrlKey || e.metaKey) &&
      e.shiftKey &&
      (e.key === "z" || e.key === "Z")
    ) {
      onRedo();
    } else if ((e.ctrlKey || e.metaKey) && (e.key === "z" || e.key === "Z")) {
      onUndo();
    }
  };

  const handleKeyUp = (e: any) => {
    if (e.key === " ") {
      setSpaceHeld(false);
    }
  };

  function canvasSizeChanged(contentRect: any) {
    setCanvasHeight(contentRect.bounds.height);
    setCanvasWidth(contentRect.bounds.width);
  }

  function drawAngleSymbol(
    ctx: any,
    line1,
    line2,
    pt3: Point,
    pt2: Point,
    pt1: Point,
    inner: boolean,
    rightAngle = false,
    lineAngle = 0
  ) {
    ctx.font = "12pt Arial";
    // draw angleSymbol
    ctx.save();
    ctx.beginPath();

    let startDist = 4;
    let dist = 4;
    let endDist = 4;

    if (lineAngle < "0.00") {
      lineAngle = -Number(Math.abs(lineAngle));
    }

    let startAngle = reverseAngle(Number(lineAngle));
    let midAngle;
    let endAngle = Number(lineAngle);

    if (line2.x2 < line2.x1 && line1.y1 < line1.y2 && lineAngle > "0.00") {
      midAngle = Number(lineAngle) + 90;
    } else if (
      line2.x2 < line2.x1 &&
      line1.y1 > line1.y2 &&
      lineAngle < "0.00"
    ) {
      midAngle = Number(lineAngle) - 90;
    }

    if (line2.x2 > line2.x1 && line1.y1 < line1.y2 && lineAngle > "0.00") {
      midAngle = Number(lineAngle) - 90;
    } else if (
      line2.x2 > line2.x1 &&
      line1.y1 > line1.y2 &&
      lineAngle < "0.00"
    ) {
      midAngle = Number(lineAngle) + 90;
    }

    if (line1.x2 > line1.x1 && line2.y1 < line2.y2 && lineAngle >= "0.00") {
      midAngle = Number(lineAngle) + 90;
    } else if (
      line1.x2 > line1.x1 &&
      line2.y1 > line2.y2 &&
      lineAngle <= "0.00"
    ) {
      midAngle = Number(lineAngle) - 90;
    }

    if (line1.x2 < line1.x1 && line2.y1 < line2.y2 && lineAngle >= "0.00") {
      midAngle = Number(lineAngle) - 90;
    } else if (
      line1.x2 < line1.x1 &&
      line2.y1 > line2.y2 &&
      lineAngle >= "0.00"
    ) {
      midAngle = Number(lineAngle) + 90;
    }
    if (rightAngle) {
      const startPoint = calculateNextPoint(pt2, startAngle, startDist);
      const mid = calculateNextPoint(startPoint, midAngle, dist);
      const end = calculateNextPoint(mid, endAngle, endDist);
      // pt2 goes "dist" steps behind
      ctx.moveTo(startPoint.x, startPoint.y);
      // draw line normal to startPoint
      ctx.lineTo(mid.x, mid.y);
      ctx.lineTo(end.x, end.y);
    }else{
      const a1 = Math.atan2((line1.y1 - line1.y2), (line1.x1 - line1.x2));
      const a2 = Math.atan2((line2.y2 - line2.y1), (line2.x2 - line2.x1));
      const outer = getSmallerAngle(line1, line2) !== getFullAngle(line1, line2);  
      
      ctx.arc(line1.x2, line1.y2, 4, outer ? a1 : a2, outer ? a2 : a1);
    }
    ctx.lineWidth = 0.5;
    ctx.globalAlpha = 0.5;
    ctx.strokeStyle = "#9A9A9A";
    ctx.stroke();
    ctx.restore();
  }

  return (
    <Measure bounds onResize={canvasSizeChanged}>
      {({ measureRef }) => {
        return (
          <div
            className={
              isMobile ? "right__img w-xs-100 mobile-height" : "high__img"
            }
            ref={measureRef}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            tabIndex={0}
            onMouseEnter={() => setIsCanvasHovered(true)}
            onMouseLeave={() => setIsCanvasHovered(false)}
            onClick={()=> {setShowCatalog(false)}}
          >
            <Stage
              width={canvasWidth}
              height={canvasHeight}
              onClick={handleCanvasClick}
              onDblClick={handleCanvasDoubleClick}
              onMousemove={handleCanvasMouseMove}
              onDragStart={handleCanvasDragStart}
              //onDragMove={handleCanvasDragMove}
              onDragEnd={handleCanvasDragEnd}
              onKeyDown={handleKeyDown}
              onKeyUp={handleKeyUp}
              onWheel={handleCanvasWheel}
              draggable={true}
              scaleX={stageScale}
              scaleY={stageScale}
              x={-1000}
              y={-1000}
              ref={stageRef}
              style={{
                cursor: isDraggingCanvas ? "grabbing" : "",
              }}
              className={
                isEdgeHovered && isDragging
                  ? "canvas-container-grabing"
                  : isEdgeHovered
                  ? "canvas-container-grab"
                  : "canvas-container"
              }
            >
              <GridLayer
                cellSize={GRID_LAYER.cellSize}
                width={GRID_LAYER.width}
                height={GRID_LAYER.height}
                stroke={GRID_LAYER.stroke}
                strokeWidth={0.3 / stageScale}
              />
              <Layer>
                {isDrawing && (
                  <>
                    <KonvaLine
                      key={
                        lines.length > 0 ? lines[lines.length - 1].id : "first"
                      }
                      points={
                        getLastLine()
                          ? [
                              getLastLine().x1,
                              getLastLine().y1,
                              getLastLine().x2,
                              getLastLine().y2,
                            ]
                          : []
                      }
                      stroke="#2C3341"
                      strokeWidth={5 / stageScale}
                      dash={[10 / stageScale, 10 / stageScale]}
                      tension={0.5}
                      lineCap="round"
                    />
                    {/* Label of the Line that comes before drawing */}
                    <PathLengthLabel
                      lines={[getLastLine()]}
                      scale={1 / stageScale}
                    />
                  </>
                )}
                {lines.length > 0 && (
                  <>
                    {/* Rounds the edge of the two lines */}
                    <RoundedCornerPath
                      lines={lines}
                      lineEdges={edges}
                      freeLengths={freeLengths}
                      freeAngles={freeAngles}
                      freeLineAngles={freeLineAngles}
                    />

                    {/* only activates when we are inside the canvas */}
                    {isCanvasHovered && (
                      //by using them we can drag and change the edge angles
                      <>
                        <EdgeController
                          edges={edges}
                          onEdgeSelected={(edgeId: string) => {}}
                          onEdgeDragStart={onEdgeDragStart}
                          onEdgeDragEnd={onEdgeDragEnd}
                          onEdgeDragMove={debounceOnEdgeDragMove}
                          scale={stageScale}
                          setIsEdgeHovered={setIsEdgeHovered}
                        />
                        <CornerEdgeController
                          lines={lines}
                          onCornerEdgeSelected={(edgeId: string) => {}}
                          onEdgeDragStart={onEdgeDragStart}
                          onEdgeDragEnd={onEdgeDragEnd}
                          onCornerEdgeDragMove={debounceOnCornerEdgeDragMove}
                          scale={stageScale}
                          setIsEdgeHovered={setIsEdgeHovered}
                        />
                      </>
                    )}

                    {/* Lines Length Input Box and label */}
                    <LinesLengthLabels
                      path={path}
                      canvasWidth={canvasWidth}
                      canvasHeight={canvasHeight}
                      freeLengths={freeLengths}
                      freeLineAngles={freeLineAngles}
                      scale={1 / stageScale}
                      stageRef={stageRef}
                      onLineLengthChange={onLineLengthChange}
                      setIsCanvasHovered={setIsCanvasHovered}
                      setZooming={setZooming}
                      zooming={zooming}
                      isDraggingCanvas={isDraggingCanvas}
                      setAllPoints={setAllPoints}
                      indexToChar={indexToChar}
                      lineAngles={freeLineAngles}
                    />
                    <LinesAngleLabels lines={lines} scale={1 / stageScale} freeLineAngles={freeLineAngles} />

                    <Shape
                      sceneFunc={(context, shape) => {
                        if (freeAngles?.length > 0 && path?.lines?.length > 1 && pointsCalculated?.length === (freeLineAngles?.length + 1)) {
                          for (var i = 0; i < pointsCalculated.length - 2; i++) {
                            drawAngleSymbol(
                              context,
                              path.lines[i],
                              path.lines[i + 1],
                              pointsCalculated[i],
                              pointsCalculated[i + 1],
                              pointsCalculated[i + 2],
                              true,
                              freeAngles[i].angle === 90,
                              freeLineAngles[i].angle
                            );
                          }
                        }
                      }}
                    />
                  </>
                )}
                {isDrawing && (
                  <Circle
                    id="lastPointToPointerCircle"
                    x={mousePos.x}
                    y={mousePos.y}
                    radius={5 / stageScale}
                    fill="#374051"
                  />
                )}
              </Layer>
            </Stage>
          </div>
        );
      }}
    </Measure>
  );
};

export default FreeFormShape;
