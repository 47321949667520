import bottomArrow from '../../../assets/img/new__user/bottom-arrow.png';
import plus from '../../../assets/img/new__user/plus.png';
import checkMarkBlackOutline from '../../../assets/img/new__user/check-mark-black-outline.png';
import letterX from '../../../assets/img/new__user/letter-x.png';
import hei from '../../../assets/img/order__page/hei.png';
import heiTop from '../../../assets/img/order__page/hei__top.png';
import bar from '../../../assets/img/order__page/bar.png';
import note from '../../../assets/img/icon/note.svg';
import buffer from '../../../assets/img/icon/buffer.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'Redux/hooks';
import other from '../../../assets/img/order__page/other.png';
import fench from '../../../assets/img/order__page/fench.png';
import { useTranslation } from "react-i18next";

function OrderMobile (props: any) {
  const {
    order,
    setOrder,
    saveOrder,
  } = props;
  const display = {
    display: 'none !important' 
  };
  // const user = useAppSelector(state => state.user.userData);
  const currentOrderRebars = useAppSelector(state => state.rebar.currentOrderRebars);
  const currentOrderNets = useAppSelector(state => state.net.currentOrderNets);
  const currentOrderPiles = useAppSelector(state => state.pile.currentOrderPiles);
  const { t } = useTranslation(['common', 'newOrder']);

    return (
      <>
      {/* <!------------------------ add new item section ------------------------ --> */}
      <section id="add__new__item" className="order__page__new__item__D">
        <div className="container px-xl-0 d-flex flex-column align-items-center">
          <div className="add d-lg-flex mr-0" onClick={props.onItemModalOpen}>
            <img className="plus__icon" src={plus} alt="plus icon" />
            <h1 className="text-uppercase">{t('Add New Item', {ns: 'newOrder'})}</h1>
          </div>
          {/* <!-- other control  --> */}
          <div className="
                            control__wrap
                            d-flex
                            align-items-center
                            flex-column flex-sm-row
                        " style={display}>
            {/* <span className="cus__btn para d-inline-block mr-2"><FontAwesomeIcon icon="paperclip"></FontAwesomeIcon>
              <span>Mark lines and group</span></span> */}
            {/* <!-- small btn wrapper  --> */}
            <div className="
                                sm__btn__wrap
                                d-flex
                                justify-content-between
                                mt-3 mt-sm-0
                            ">
              <span className="cus__r__btn para d-inline-block mr-2"><FontAwesomeIcon icon="copy"></FontAwesomeIcon></span>
              <span className="cus__r__btn para d-inline-block mr-2"><FontAwesomeIcon icon="pencil-alt"></FontAwesomeIcon></span>
              {/* <span className="cus__r__btn para d-inline-block mr-2"><FontAwesomeIcon icon="trash"></FontAwesomeIcon></span> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ------------------------ rebars table section --------------------------  --> */}
      <section id="rebars__table__sm" className="order__table__sm">
        <div className="container first-container">
          <div className="table__accordion__sm">
            <div className="com_acco_head_sm accordion__header" data-toggle="collapse"
              data-target="#rebarsSmTable" aria-expanded="true" aria-controls="rebarsSmTable">
              <div className="
                                    sm__title
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                ">
                <h1 className="
                                        text-center text-uppercase text-white
                                        mb-0
                                    ">
                  rebars
                </h1>
                <span className="accordion__icon">
                  <img src={bottomArrow} alt="bottom arrow" />
                </span>
              </div>
            </div>
            {/* <!-- accordion body  --> */}
            <div id="rebarsSmTable" className="collapse accordion__body__sm show" aria-labelledby="user"
              data-parent="#company__accordion">
              <div className="
                                    d-flex
                                    flex-column
                                    justify-content-center
                                    align-items-center
                                ">
                {/* <!-- main row 1 --> */}
                {currentOrderRebars.length > 0 && currentOrderRebars.map((itemRebar, index) => (
                <div className="table__row w-xs-100" key={"rebar-mob"+index}>
                  {/* <!-- table top row  --> */}
                  <div className="top__row w-xs-100">
                    {/* <!-- cell no 1  --> */}
                    <div className="
                                                cell cell-1
                                                d-flex
                                                align-items-center
                                                justify-content-center
                                            ">
                      <p className="para text-center">{index+1}</p>
                    </div>
                    {/* <!-- cell no  2  --> */}
                    <div className="cell cell-2">
                      <span className="
                                                    d-flex
                                                    align-items-start
                                                    justify-content-center
                                                ">
                        <span className="
                                                        d-flex
                                                        flex-column
                                                        unit__icon__wrap
                                                    ">
                          <span className="img__wrap">
                            <img src={heiTop} alt="hei icon" />
                          </span>
                          <p className="
                                                            sm__unit
                                                            text-uppercase
                                                            text-center
                                                        ">
                            mm
                          </p>
                        </span>
                        <p className="para">{itemRebar.tool.diameter.steel_diameter}</p>
                      </span>
                    </div>
                    {/* <!-- cell no  3  --> */}
                    <div className="cell cell-3">
                      <span className="
                                                    d-flex
                                                    align-items-start
                                                    justify-content-center
                                                ">
                        <span className="
                                                        d-flex
                                                        flex-column
                                                        unit__icon__wrap
                                                    ">
                          <span className="icon__wrap">
                          <FontAwesomeIcon icon="expand-alt"></FontAwesomeIcon>
                          </span>
                          <p className="
                                                            sm__unit
                                                            text-uppercase
                                                            text-center
                                                        ">
                            cm
                          </p>
                        </span>
                        <p className="para">{itemRebar.tool.rebar_side.reduce((previousValue: number, item: any) => previousValue + parseInt(item.length), 0)}</p>
                      </span>
                    </div>
                    {/* <!-- cell no  4  --> */}
                    <div className="cell cell-4">
                      <span className="
                                                    d-flex
                                                    align-items-start
                                                    justify-content-center
                                                ">
                        <span className="
                                                        d-flex
                                                        flex-column
                                                        unit__icon__wrap
                                                    ">
                          <span className="icon__wrap">
                          <img className="bag" src={buffer} alt="unit icon" />
                          </span>
                          <p className="
                                                            sm__unit
                                                            text-uppercase
                                                            text-center
                                                        ">
                            unt
                          </p>
                        </span>
                        <p className="para">{itemRebar.quantity}</p>
                      </span>
                    </div>
                    {/* <!-- cell no  5  --> */}
                    <div className="cell cell-5">
                      <span className="
                                                    d-flex
                                                    align-items-start
                                                    justify-content-center
                                                ">
                        <span className="
                                                        d-flex
                                                        flex-column
                                                        unit__icon__wrap
                                                    ">
                          <span className="img__wrap">
                            <img className="bag" src={bar} alt="bar icon" />
                          </span>
                          <p className="
                                                            sm__unit
                                                            text-uppercase
                                                            text-center
                                                        ">
                            kg
                          </p>
                        </span>
                        <p className="para">{(itemRebar.tool.unit_weight * itemRebar.quantity).toFixed(1)}</p>
                      </span>
                    </div>
                  </div>
                  {/* <!-- table bottom row  --> */}
                  <div className="bottom__row m-0 row bg-white">
                    {/* <!-- image wrap  --> */}
                    <div className="
                                                img__wrap
                                                col-9
                                                text-center
                                                d-flex
                                                align-items-center
                                                justify-content-center
                                            ">
                      <svg className="w-xs-65 d-inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164.341 96.803">
                        <defs>
                          <clipPath id="a">
                            <path data-name="Rectangle 1069" fill="#fff" stroke="#707070" d="M0 0h164.341v96.803H0z" />
                          </clipPath>
                        </defs>
                        <g data-name="Mask Group 1" clipPath="url(#a)">
                          <g data-name="Group 1984">
                            <text data-name="הזמנה מס׳ 2009" transform="translate(74.956 59.787)" fill="#4e5055"
                              fontSize="20" fontFamily="Heebo-Medium, Heebo" fontWeight="500" letterSpacing=".02em">
                              <tspan x="0" y="0">
                                60
                              </tspan>
                            </text>
                            <text data-name="הזמנה מס׳ 2009" transform="translate(18.663 32.587)" fill="#4e5055"
                              fontSize="20" fontFamily="Heebo-Medium, Heebo" fontWeight="500" letterSpacing=".02em">
                              <tspan x="0" y="0">
                                10
                              </tspan>
                            </text>
                            <text data-name="הזמנה מס׳ 2009" transform="rotate(45 -59.978 60.63)" fill="#4e5055"
                              fontSize="20" fontFamily="Heebo-Medium, Heebo" fontWeight="500" letterSpacing=".02em">
                              <tspan x="0" y="0">
                                20
                              </tspan>
                            </text>
                            <path data-name="Path 735" d="M22.168 41.587h17.876l23.6 26.4h48.036l15.814-26.4h15.874"
                              fill="none" stroke="#4e5055" strokeWidth="2" />
                          </g>
                        </g>
                      </svg>
                      <img
                      className="img-fluid img__icon"
                      src={itemRebar.tool.preview_image ? itemRebar.tool.preview_image.file_field : other}
                      alt="map icon"
                    />
                    </div>
                    {/* <!-- edit icon   --> */}
                    <div className="
                                                edit__wrap
                                                col-3
                                                mt-3 mt-sm-4 mt-md-5
                                            ">
                      <button className="edit__btn">
                        <FontAwesomeIcon icon="pen"></FontAwesomeIcon>
                      </button>
                      <button className="edit__btn mt-2">
                        <FontAwesomeIcon icon="trash"></FontAwesomeIcon>
                      </button>
                    </div>
                  </div>
                </div>
                ))}
              </div>
            </div>
            {/* <!-- accordion footer  --> */}
            <div className="accordion__footer row m-0">
              <div className="col-4">
                <span className="red text-center">{currentOrderRebars.length}</span><span className="thin text-uppercase">types</span>
              </div>
              <div className="col-4">
                <span className="red text-center">
                  {currentOrderRebars.length > 0 ? currentOrderRebars.reduce((pre, curr) => pre + curr.quantity, 0) : 0}
                </span>
                <span className="thin text-uppercase">units</span>
              </div>
              <div className="col-4">
                <span className="red text-center">
                  {currentOrderRebars.length > 0 ? (currentOrderRebars.reduce((pre, curr) => pre + (curr.tool.unit_weight * curr.quantity), 0) / 1000).toFixed(2) : 0}
                </span>
                <span className="thin text-uppercase">ton</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ------------------------ nets table section --------------------------  --> */}
      <section id="nets__table__sm" className="order__table__sm">
        <div className="container">
          <div className="table__accordion__sm">
            <div className="com_acco_head_sm accordion__header" data-toggle="collapse"
              data-target="#netsSmTable" aria-expanded="true" aria-controls="netsSmTable">
              <div className="
                                    sm__title
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                ">
                <h1 className="
                                        text-center text-uppercase text-white
                                        mb-0
                                    ">
                  nets
                </h1>
                <span className="accordion__icon">
                  <img src={bottomArrow} alt="bottom arrow" />
                </span>
              </div>
            </div>
            {/* <!-- accordion body  --> */}
            <div id="netsSmTable" className="collapse accordion__body__sm show" aria-labelledby="user"
              data-parent="#company__accordion">
              <div className="
                                    d-flex
                                    flex-column
                                    justify-content-center
                                    align-items-center
                                ">
                {/* <!-- main row 1 --> */}
                {currentOrderNets.length > 0 && currentOrderNets.map((itemNet, index) => (
                  <div className="table__row w-xs-100" key={"net-mobile"+index}>
                    {/* <!-- table top row  --> */}
                    <div className="top__row w-xs-100">
                      {/* <!-- cell no 1  --> */}
                      <div className="
                                                  cell cell-1
                                                  d-flex
                                                  align-items-center
                                                  justify-content-center
                                              ">
                        <p className="para text-center">{index+1}</p>
                      </div>
                      {/* <!-- cell no  2  --> */}
                      <div className="
                                                  cell
                                                  double__row__cell
                                                  cell-2
                                                  d-flex
                                                  flex-column
                                                  align-items-center
                                                  justify-content-center
                                              ">
                        {/* <!-- top row  --> */}
                        <div className="
                                                      d__cell__row
                                                      cell__row
                                                      d-flex
                                                      justify-content-center
                                                      align-items-center
                                                  ">
                          <span>x {itemNet.tool.x_length}</span>
                          <img src={hei} alt="hei icon" />
                          <span>{itemNet.tool.x_diameter.steel_diameter} @ {itemNet.tool.x_gap}</span>
                        </div>
                        {/* <!-- bottom row  --> */}
                        <div className="
                                                      d__cell__row
                                                      cell__row
                                                      d-flex
                                                      justify-content-center
                                                      align-items-center
                                                  ">
                          <span>y {itemNet.tool.y_length} </span><img src={hei} alt="hei icon" /><span>{itemNet.tool.y_diameter.steel_diameter} @ {itemNet.tool.y_gap}</span>
                        </div>
                      </div>
                      {/* <!-- cell no  4  --> */}
                      <div className="
                                                  cell cell-4
                                                  d-flex
                                                  align-items-center
                                                  justify-content-center
                                              ">
                        <span className="d-flex flex-column">
                          <span className="
                                                          d-flex
                                                          justify-content-center
                                                          unit__icon__wrap
                                                          margin-bottom
                                                      ">
                            <span className="
                                                              icon__wrap
                                                              margin-right
                                                          ">
                                                        <img className="bag" src={buffer} alt="unit icon" />

                            </span>
                            <p className="
                                                              sm__unit
                                                              text-uppercase
                                                              text-center
                                                          ">
                              unt
                            </p>
                          </span>
                          <p className="para text-center">{itemNet.quantity}</p>
                        </span>
                      </div>
                      {/* <!-- cell no  5  --> */}
                      <div className="
                                                  cell cell-5
                                                  d-flex
                                                  align-items-center
                                                  justify-content-center
                                              ">
                        <span className="d-flex flex-column">
                          <span className="
                                                          d-flex
                                                          unit__icon__wrap
                                                          margin-bottom
                                                      ">
                            <span className="
                                                              img__wrap
                                                              margin-right
                                                          ">
                              <img className="bag" src={bar} alt="bar icon" />
                            </span>
                            <p className="
                                                              sm__unit
                                                              text-uppercase
                                                              text-center
                                                          ">
                              kg
                            </p>
                          </span>
                          <p className="para text-center">{(itemNet.tool.unit_weight * itemNet.quantity).toFixed(1)}</p>
                        </span>
                      </div>
                    </div>
                    {/* <!-- table bottom row  --> */}
                    <div className="bottom__row m-0 row bg-white">
                      {/* <!-- image wrap  --> */}
                      <div className="
                                                  img__wrap
                                                  col-9
                                                  text-center
                                                  d-flex
                                                  align-items-center
                                                  justify-content-center
                                              ">
                        <img
                          className="img-fluid img__icon"
                          src={itemNet.tool.preview_image ? itemNet.tool.preview_image.file_field : fench}
                          alt={itemNet.tool.preview_image ? itemNet.tool.preview_image.alt_text : "map icon"}
                        />
                      </div>
                      {/* <!-- edit icon   --> */}
                      <div className="
                                                  edit__wrap
                                                  col-3
                                                  mt-3 mt-sm-4 mt-md-5
                                              ">
                        <button className="edit__btn">
                        <FontAwesomeIcon icon="pen"></FontAwesomeIcon>
                        </button>
                        <button className="edit__btn mt-2">
                          <FontAwesomeIcon icon="trash"></FontAwesomeIcon>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <!-- accordion footer  --> */}
            <div className="accordion__footer row m-0">
              <div className="col-4">
                <span className="red text-center">{currentOrderNets.length}</span><span className="thin text-uppercase">types</span>
              </div>
              <div className="col-4">
                <span className="red text-center">
                  {currentOrderNets.length > 0 ? currentOrderNets.reduce((pre, curr) => pre + curr.quantity, 0) : 0}
                </span>
                <span className="thin text-uppercase">units</span>
              </div>
              <div className="col-4">
                <span className="red text-center">
                  {currentOrderNets.length > 0 ? (currentOrderNets.reduce((pre, curr) => pre + (curr.tool.unit_weight * curr.quantity), 0) / 1000).toFixed(2) : 0}
                </span>
                <span className="thin text-uppercase">ton</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ------------------------ piles table section --------------------------  --> */}
      <section id="piles__table__sm" className="order__table__sm">
        <div className="container">
          <div className="table__accordion__sm">
            <div className="com_acco_head_sm accordion__header" data-toggle="collapse"
              data-target="#pilesSmTable" aria-expanded="true" aria-controls="pilesSmTable">
              <div className="
                                    sm__title
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                ">
                <h1 className="
                                        text-center text-uppercase text-white
                                        mb-0
                                    ">
                  piles
                </h1>
                <span className="accordion__icon">
                  <img src={bottomArrow} alt="bottom arrow" />
                </span>
              </div>
            </div>
            {/* <!-- accordion body  --> */}
            <div id="pilesSmTable" className="collapse accordion__body__sm show" aria-labelledby="user"
              data-parent="#company__accordion">
              <div className="
                                    d-flex
                                    flex-column
                                    justify-content-center
                                    align-items-center
                                ">
                {/* <!-- main row 1 --> */}
                {currentOrderPiles.length > 0 && currentOrderPiles.map((itemPile, index) => (
                <div className="table__row w-xs-100" key={"cage-mobile"+index}>
                  {/* <!-- table top row  --> */}
                  <div className="top__row w-xs-100">
                    {/* <!-- cell no 1  --> */}
                    <div className="
                                                cell cell-1
                                                d-flex
                                                align-items-center
                                                justify-content-center
                                            ">
                      <p className="para text-center">{index+1}</p>
                    </div>
                    {/* <!-- cell no  2  --> */}
                    <div className="
                                                cell
                                                double__row__cell
                                                cell-2
                                                d-flex
                                                flex-column
                                                align-items-center
                                                justify-content-center
                                            ">
                      {/* <!-- top row  --> */}
                      <div className="
                                                    d__cell__row
                                                    cell__row
                                                    d-flex
                                                    justify-content-center
                                                    align-items-center
                                                ">
                        <span>@1</span>
                      </div>
                      {/* <!-- bottom row  --> */}
                      <div className="
                                                    d__cell__row
                                                    cell__row
                                                    d-flex
                                                    justify-content-center
                                                    align-items-center
                                                ">@2</div>
                    </div>
                    {/* <!-- cell no  2  --> */}
                    <div className="
                                                cell
                                                double__row__cell
                                                cell-2
                                                d-flex
                                                flex-column
                                                align-items-center
                                                justify-content-center
                                            ">
                      {/* <!-- top row  --> */}
                      <div className="
                                                    d__cell__row
                                                    cell__row
                                                    d-flex
                                                    justify-content-center
                                                    align-items-center
                                                ">
                        <span>{itemPile.tool.l2_length}</span>
                      </div>
                      {/* <!-- bottom row  --> */}
                      <div className="
                                                    d__cell__row
                                                    cell__row
                                                    d-flex
                                                    justify-content-center
                                                    align-items-center
                                                ">
                        {itemPile.tool.l3_length}
                      </div>
                    </div>
                    {/* <!-- cell no  2  --> */}
                    <div className="
                                                cell
                                                double__row__cell
                                                cell-2
                                                d-flex
                                                flex-column
                                                align-items-center
                                                justify-content-center
                                            ">
                      {/* <!-- top row  --> */}
                      <div className="
                                                    d__cell__row
                                                    cell__row
                                                    d-flex
                                                    justify-content-center
                                                    align-items-center
                                                ">
                        <img src={hei} alt="hei icon" />
                        <span>{itemPile.tool.spiral_wire_diameter.steel_diameter} @ {itemPile.tool.l2_gap}</span>
                      </div>
                      {/* <!-- bottom row  --> */}
                      <div className="
                                                    d__cell__row
                                                    cell__row
                                                    d-flex
                                                    justify-content-center
                                                    align-items-center
                                                ">
                      <img src={hei} alt="hei icon" />
                      {itemPile.tool.spiral_wire_diameter.steel_diameter} @ {itemPile.tool.l3_gap}
                  </div>
                    </div>
                    {/* <!-- cell no  4  --> */}
                    <div className="
                                                cell cell-4
                                                d-flex
                                                align-items-center
                                                justify-content-center
                                            ">
                      <span className="d-flex flex-column">
                        <span className="
                                                        d-flex
                                                        justify-content-center
                                                        unit__icon__wrap
                                                        margin-bottom
                                                    ">
                          <span className="
                                                            icon__wrap
                                                            margin-right
                                                        ">
                                                      <img className="bag" src={buffer} alt="unit icon" />

                          </span>
                          <p className="
                                                            sm__unit
                                                            text-uppercase
                                                            text-center
                                                        ">
                            unt
                          </p>
                        </span>
                        <p className="para text-center">{itemPile.quantity}</p>
                      </span>
                    </div>
                    {/* <!-- cell no  5  --> */}
                    <div className="
                                                cell cell-5
                                                d-flex
                                                align-items-center
                                                justify-content-center
                                            ">
                      <span className="d-flex flex-column">
                        <span className="
                                                        d-flex
                                                        unit__icon__wrap
                                                        margin-bottom
                                                    ">
                          <span className="
                                                            img__wrap
                                                            margin-right
                                                        ">
                            <img className="bag" src={bar} alt="bar icon" />
                          </span>
                          <p className="
                                                            sm__unit
                                                            text-uppercase
                                                            text-center
                                                        ">
                            kg
                          </p>
                        </span>
                        <p className="para text-center">{(itemPile.tool.unit_weight * itemPile.quantity).toFixed(1)}</p>
                      </span>
                    </div>
                  </div>
                  {/* <!-- table bottom row  --> */}
                  <div className="bottom__row m-0 row bg-white">
                    {/* <!-- image wrap  --> */}
                    <div className="
                                                img__wrap
                                                col-4
                                                text-center
                                                d-flex
                                                align-items-center
                                                justify-content-center
                                            ">
                      <svg xmlns="http://www.w3.org/2000/svg" width="56" height="100.518" viewBox="0 0 56 100.518">
                        <text id="הזמנה_מס_2009" data-name="הזמנה מס׳ 2009" transform="translate(23 92)" font-size="20" font-family="Heebo-Regular, Heebo" letter-spacing="0.02em"><tspan x="-11.439" y="0">{itemPile.tool.cage_diameter}</tspan></text>
                        <text id="הזמנה_מס_2009-2" data-name="הזמנה מס׳ 2009" transform="translate(9 21)" font-size="20" font-family="Heebo-Regular, Heebo" letter-spacing="0.02em">
                          <tspan x="-5.62" y="0">
                            {itemPile.tool.base_amount
                            ? itemPile.tool.base_amount
                            : itemPile.tool.external_amount + itemPile.tool.internal_amount + itemPile.tool.middle_amount
                            }
                          </tspan>
                        </text>

                        
                        <circle id="Ellipse_334" data-name="Ellipse 334" cx="4.756" cy="4.756" r="4.756" transform="translate(20 7.967)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                        <line id="Line_2989" data-name="Line 2989" y2="14.489" transform="translate(25 5.633)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>
                        <line id="Line_2990" data-name="Line 2990" x2="9.513" transform="translate(20 5.5)" fill="none" stroke="#161615" stroke-miterlimit="10" stroke-width="1"/>

                        
                        <text id="הזמנה_מס_2009-3" data-name="הזמנה מס׳ 2009" transform="translate(44 21)" font-size="20" font-family="Heebo-Regular, Heebo" letter-spacing="0.02em"><tspan x="-11.439" y="0">{itemPile.tool.base_diameter.steel_diameter}</tspan></text>
                        <g id="Ellipse_337" data-name="Ellipse 337" transform="translate(8 34)" fill="none" stroke="#4e5055" stroke-width="2">
                          <circle cx="15" cy="15" r="15" stroke="none"/>
                          <circle cx="15" cy="15" r="16" fill="none"/>
                        </g>
                        <g id="Ellipse_338" data-name="Ellipse 338" transform="translate(2 28)" fill="none" stroke="#4e5055" stroke-width="2">
                          <circle cx="21" cy="21" r="21" stroke="none"/>
                          <circle cx="21" cy="21" r="22" fill="none"/>
                        </g>
                        <circle id="Ellipse_339" data-name="Ellipse 339" cx="2.069" cy="2.069" r="2.069" transform="translate(21.431 33.518)" fill="#4e5055"/>
                        <circle id="Ellipse_340" data-name="Ellipse 340" cx="2.069" cy="2.069" r="2.069" transform="translate(21.431 59.38)" fill="#4e5055"/>
                        <circle id="Ellipse_341" data-name="Ellipse 341" cx="2.069" cy="2.069" r="2.069" transform="translate(8.5 50.587) rotate(-90)" fill="#4e5055"/>
                        <circle id="Ellipse_342" data-name="Ellipse 342" cx="2.069" cy="2.069" r="2.069" transform="translate(34.362 50.587) rotate(-90)" fill="#4e5055"/>
                        <circle id="Ellipse_343" data-name="Ellipse 343" cx="2.069" cy="2.069" r="2.069" transform="translate(14.356 60.587) rotate(-135)" fill="#4e5055"/>
                        <circle id="Ellipse_344" data-name="Ellipse 344" cx="2.069" cy="2.069" r="2.069" transform="translate(32.644 42.3) rotate(-135)" fill="#4e5055"/>
                        <circle id="Ellipse_345" data-name="Ellipse 345" cx="2.069" cy="2.069" r="2.069" transform="translate(35.57 57.661) rotate(135)" fill="#4e5055"/>
                        <circle id="Ellipse_346" data-name="Ellipse 346" cx="2.069" cy="2.069" r="2.069" transform="translate(17.282 39.374) rotate(135)" fill="#4e5055"/>
                      </svg>



                    </div>
                    {/* <!-- image wrap  --> */}
                    <div className="
                                                img__wrap
                                                col-4
                                                text-center
                                                d-flex
                                                align-items-center
                                                justify-content-center
                                            ">
                      <svg xmlns="http://www.w3.org/2000/svg" width="91.468" height="82.981" viewBox="0 0 91.468 82.981">
                        <text id="הזמנה_מס_2009" data-name="הזמנה מס׳ 2009" transform="translate(43.659 21)" font-size="20" font-family="Heebo-Regular, Heebo" letter-spacing="0.02em">
                          <tspan x="-25.243" y="0">
                            {itemPile.tool.l1_length + itemPile.tool.l2_length + itemPile.tool.l3_length}
                          </tspan>
                        </text>
                        <text id="הזמנה_מס_2009-2" data-name="הזמנה מס׳ 2009" transform="translate(21 74.981)" font-size="20" font-family="Heebo-Regular, Heebo" letter-spacing="0.02em"><tspan x="-20.612" y="0">@{itemPile.tool.l2_gap}</tspan></text>
                        <text id="הזמנה_מס_2009-3" data-name="הזמנה מס׳ 2009" transform="translate(70.468 74.981)" font-size="20" font-family="Heebo-Regular, Heebo" letter-spacing="0.02em"><tspan x="-20.612" y="0">@{itemPile.tool.l3_gap}</tspan></text>
                        <line id="Line_3053" data-name="Line 3053" x2="83" transform="translate(0 27.958)" fill="none" stroke="#707070" stroke-width="2"/>
                        <line id="Line_3055" data-name="Line 3055" x2="83" transform="translate(0 47.458)" fill="none" stroke="#707070" stroke-width="2"/>
                        <line id="Line_3054" data-name="Line 3054" x2="83" transform="translate(0 32.458)" fill="none" stroke="#707070" stroke-width="2"/>
                        <line id="Line_3056" data-name="Line 3056" x2="83" transform="translate(0 51.958)" fill="none" stroke="#707070" stroke-width="2"/>
                        <g id="Polygon_54" data-name="Polygon 54" transform="translate(25.914 27.004)" fill="none" stroke-linecap="round">
                          <path d="M4.171,0,8.341,25.936H0Z" stroke="none"/>
                          <path d="M 4.170554637908936 12.59745216369629 L 2.347294807434082 23.93603706359863 L 5.993814468383789 23.93603706359863 L 4.170554637908936 12.59745216369629 M 4.170554637908936 -1.9073486328125e-06 L 8.341104507446289 25.93603706359863 L 4.76837158203125e-06 25.93603706359863 L 4.170554637908936 -1.9073486328125e-06 Z" stroke="none" fill="#707070"/>
                        </g>
                        <g id="Polygon_55" data-name="Polygon 55" transform="translate(11.914 27.004)" fill="none" stroke-linecap="round">
                          <path d="M4.171,0,8.341,25.936H0Z" stroke="none"/>
                          <path d="M 4.170554637908936 12.59745216369629 L 2.347294807434082 23.93603706359863 L 5.993814468383789 23.93603706359863 L 4.170554637908936 12.59745216369629 M 4.170554637908936 -1.9073486328125e-06 L 8.341104507446289 25.93603706359863 L 4.76837158203125e-06 25.93603706359863 L 4.170554637908936 -1.9073486328125e-06 Z" stroke="none" fill="#707070"/>
                        </g>
                        <g id="Polygon_53" data-name="Polygon 53" transform="translate(32.659 27.004)" fill="none" stroke-linecap="round">
                          <path d="M4.171,0,8.341,25.936H0Z" stroke="none"/>
                          <path d="M 4.170554637908936 12.59745216369629 L 2.347294807434082 23.93603706359863 L 5.993814468383789 23.93603706359863 L 4.170554637908936 12.59745216369629 M 4.170554637908936 -1.9073486328125e-06 L 8.341104507446289 25.93603706359863 L 4.76837158203125e-06 25.93603706359863 L 4.170554637908936 -1.9073486328125e-06 Z" stroke="none" fill="#707070"/>
                        </g>
                        <g id="Polygon_56" data-name="Polygon 56" transform="translate(18.659 27.004)" fill="none" stroke-linecap="round">
                          <path d="M4.171,0,8.341,25.936H0Z" stroke="none"/>
                          <path d="M 4.170554637908936 12.59745216369629 L 2.347294807434082 23.93603706359863 L 5.993814468383789 23.93603706359863 L 4.170554637908936 12.59745216369629 M 4.170554637908936 -1.9073486328125e-06 L 8.341104507446289 25.93603706359863 L 4.76837158203125e-06 25.93603706359863 L 4.170554637908936 -1.9073486328125e-06 Z" stroke="none" fill="#707070"/>
                        </g>
                        <g id="Polygon_51" data-name="Polygon 51" transform="translate(41 27.004)" fill="none" stroke-linecap="round">
                          <path d="M7,0l7,25.936H0Z" stroke="none"/>
                          <path d="M 7 7.675445556640625 L 2.61135196685791 23.93603706359863 L 11.38864707946777 23.93603706359863 L 7 7.675445556640625 M 7 -1.9073486328125e-06 L 14 25.93603706359863 L 0 25.93603706359863 L 7 -1.9073486328125e-06 Z" stroke="none" fill="#707070"/>
                        </g>
                        <g id="Polygon_52" data-name="Polygon 52" transform="translate(55 27.004)" fill="none" stroke-linecap="round">
                          <path d="M7,0l7,25.936H0Z" stroke="none"/>
                          <path d="M 7 7.675445556640625 L 2.61135196685791 23.93603706359863 L 11.38864707946777 23.93603706359863 L 7 7.675445556640625 M 7 -1.9073486328125e-06 L 14 25.93603706359863 L 0 25.93603706359863 L 7 -1.9073486328125e-06 Z" stroke="none" fill="#707070"/>
                        </g>
                      </svg>

                    </div>
                    {/* <!-- edit icon   --> */}
                    <div className="
                                                edit__wrap
                                                col-3
                                                mt-3 mt-sm-4 mt-md-5
                                            ">
                      <button className="edit__btn">
                      <FontAwesomeIcon icon="pen"></FontAwesomeIcon>
                      </button>
                      <button className="edit__btn mt-2">
                        <FontAwesomeIcon icon="trash"></FontAwesomeIcon>
                      </button>
                    </div>
                  </div>
                </div>
                ))}
              </div>
            </div>
            {/* <!-- accordion footer  --> */}
            <div className="accordion__footer row m-0">
              <div className="col-4">
                <span className="red text-center">{currentOrderPiles.length}</span><span className="thin text-uppercase">types</span>
              </div>
              <div className="col-4">
                <span className="red text-center">
                {currentOrderPiles.length > 0 ? currentOrderPiles.reduce((pre, curr) => pre + curr.quantity, 0) : 0}
                </span><span className="thin text-uppercase">units</span>
              </div>
              <div className="col-4">
                <span className="red text-center">
                  {currentOrderPiles.length > 0 ? (currentOrderPiles.reduce((pre, curr) => pre + (curr.tool.unit_weight * curr.quantity), 0) / 1000).toFixed(2) : 0}
                </span>
                <span className="thin text-uppercase">ton</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- order summery title section  --> */}
      <section id="order__summery__tittle">
        {/* <!-- symmery container  --> */}
        <div className="container p-3 p-sm-4">
          <h1 className="text-uppercase text-white">
            order summery
          </h1>
        </div>
      </section>
      {/* <!-- order details section  --> */}
      <section id="order__details" className="mb-5">
        <div className="container summ__detai__container">
          <h2 style={{color: '#374051'}}><strong>TOTAL</strong></h2>
          <div className="row m-0">
            <span className="col-4 box">{currentOrderRebars.length + currentOrderNets.length + currentOrderPiles.length} TYPES</span>
            <span className="col-4 box">
              {
                (currentOrderRebars.length > 0 ? currentOrderRebars.reduce((pre, curr) => pre + curr.quantity, 0) : 0)
                + (currentOrderNets.length > 0 ? currentOrderNets.reduce((pre, curr) => pre + curr.quantity, 0) : 0)
                + (currentOrderPiles.length > 0 ? currentOrderPiles.reduce((pre, curr) => pre + curr.quantity, 0) : 0)
              }
              &nbsp;UNITS
            </span>
            <span className="col-4 box">
              {
                ((currentOrderRebars.length > 0 ? (currentOrderRebars.reduce((pre, curr) => pre + (curr.tool.unit_weight * curr.quantity), 0) / 1000) : 0)
                + (currentOrderPiles.length > 0 ? (currentOrderPiles.reduce((pre, curr) => pre + (curr.tool.unit_weight * curr.quantity), 0) / 1000) : 0)
                + (currentOrderPiles.length > 0 ? (currentOrderPiles.reduce((pre, curr) => pre + (curr.tool.unit_weight * curr.quantity), 0) / 1000) : 0))
                .toFixed(1)
              }
              &nbsp;TON
            </span>
          </div>
          {/* <!-- untittle list  --> */}
          <ul>
            <li className="d-flex flex-column border__bottom">
              <div className="left w-xs-50 d-flex align-items-center">
                <span className="icon__wrap"><img src={note} alt="note pen" /></span>
                <p className="para text-capitalize">item notes</p>
              </div>
            </li>
            <li className="d-flex flex-column border__bottom">
              <textarea
              value={order.order_notes}
              onChange={({target}) => setOrder({...order, order_notes: target.value})}
              style={{
                border: '1px solid #d3d3d3'
              }} cols={30} rows={5}></textarea>
            </li>
          </ul>
          {/* <!-- order button  --> */}
          <div className="save__cancel">
            <button id="save__btn" onClick={saveOrder}><img src={checkMarkBlackOutline} alt="check mark icon" />
              <p className="capitalize text-center text-white">save</p>
            </button>
            <button id="cancel__btn"><img src={letterX} alt="cross icon" />
              <p className="text-capitalize text-capitalize">cancel</p>
            </button>
          </div>
        </div>
      </section>
      </>
    );
}

export default OrderMobile;